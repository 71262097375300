import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CurveType } from '@shared/services/gql.service';
import { Option } from '@shared/types/components.type';
import {
  ForecastTableGridMethodChange,
  ForecastTableGridSiteCurveInterface,
} from '../../models/forecast-table-grid.model';

@Component({
  selector: 'aux-forecast-table-grid-period-site',
  templateUrl: './forecast-table-grid-period-site.component.html',
  styleUrls: ['./forecast-table-grid-period-site.component.scss'],
})
export class ForecastTableGridPeriodSiteComponent implements OnChanges {
  @ViewChild('gridPeriodSite') public input!: NgSelectComponent;

  @Input() categoryId = '';

  @Input() primarySettingsId = '';

  @Input() isChild = false;

  @Input() placeholder = '';

  @Input() tooltip = '';

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() onMethodChange!: ForecastTableGridMethodChange;

  @Input() siteCurves: ForecastTableGridSiteCurveInterface[] = [];

  @Input() periodSiteOptions: Option<CurveType>[] = [];

  @Input() driverSettingId = '';

  selectedPeriodSiteOption = '';

  ngOnChanges(): void {
    this.initSitePeriod();
  }

  initSitePeriod(): void {
    if (!this.driverSettingId) {
      this.setSitePeriod(CurveType.NET);
      return;
    }

    const siteCurve = this.siteCurves.find((curve) => {
      return curve.value === this.driverSettingId;
    });

    this.setSitePeriod(siteCurve?.curveType || CurveType.NET);
  }

  setSitePeriod(curveType: CurveType): void {
    this.selectedPeriodSiteOption = curveType;
  }

  onModelChange(event: string): void {
    const siteCurveByDriver = this.siteCurves.find((curve) => {
      return curve.value === this.driverSettingId;
    })?.label;

    const siteCurve = this.siteCurves.find((curve) => {
      return curve.curveType === event && curve.label === siteCurveByDriver;
    });

    if (siteCurve?.value) {
      this.onMethodChange(siteCurve.value, this.primarySettingsId, {
        name: !this.isChild ? 'category' : 'activity',
        prop: 'driver_setting',
        id: this.categoryId,
      });
    }
  }
}
