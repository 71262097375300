<div class="flex flex-col items-center space-y-4 w-full" style="pointer-events: auto !important">
  <span class="text-2xl font-bold">Add your first Vendor!</span>
  <span class="text-lg"
    >Once you have added your Vendors, you'll be able to easily manage your Vendor Budgets in
    Auxilius.</span
  >
  <div class="flex flex-row items-center justify-center space-x-4 w-full">
    <aux-button
      [disabled]="ctx.disabledUI()"
      [auxTooltip]="ctx.vendorActionTooltip()"
      variant="secondary"
      label="Add New Vendor"
      (clickEmit)="onNewVendor(params)"
      icon="Plus"
    />
    <aux-button
      [disabled]="ctx.disabledUI()"
      [auxTooltip]="ctx.vendorActionTooltip()"
      variant="secondary"
      label="Add Multiple Vendors"
      (clickEmit)="onAddMultipleVendors(params)"
      icon="Plus"
    />
  </div>
</div>
