import { computed, Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EventStore, EventStoreState } from './event.store';

import { MainQuery } from '@shared/store/main/main.query';
import { map, Observable } from 'rxjs';
import { EventStatus, EventType } from '@shared/services/gql.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class EventQuery extends QueryEntity<EventStoreState> {
  events = toSignal(this.selectAll(), {
    requireSync: true,
  });

  constructor(
    protected store: EventStore,
    private mainQuery: MainQuery
  ) {
    super(store);
  }

  getEntitySignal(eventType: EventType, id?: string) {
    return computed(() => {
      const { trialKey } = this.mainQuery.mainState();
      const key = `${trialKey}-${eventType}${id ? `-${id}` : ''}`;
      return this.events().find((event) => event._id === key);
    });
  }

  selectProcessingEvent(eventType: EventType, id?: string) {
    return computed(() => {
      const event = this.getEntitySignal(eventType, id)();

      if (!event) {
        return null;
      }

      const { event_status } = event;

      return (
        event_status === EventStatus.EVENT_STATUS_IN_PROGRESS ||
        event_status === EventStatus.EVENT_STATUS_PENDING
      );
    });
  }

  isEventProcessing(eventType: EventType): boolean {
    const trial = this.mainQuery.getValue().trialKey;
    const events = this.store.getValue().entities || {};
    const event = events[`${trial}-${eventType}`];

    return !!event;
  }

  /**
   * @deprecated use `selectProcessingEvent` instead
   */
  selectProcessingEvent$(eventType: EventType, id?: string): Observable<boolean | null> {
    const trial = this.mainQuery.getValue().trialKey;
    const key = `${trial}-${eventType}${id ? `-${id}` : ''}`;

    return this.selectEntity(key).pipe(
      map((event) => {
        if (!event) {
          return null;
        }

        const { event_status } = event;

        return (
          event_status === EventStatus.EVENT_STATUS_IN_PROGRESS ||
          event_status === EventStatus.EVENT_STATUS_PENDING
        );
      })
    );
  }
}
