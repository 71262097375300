import { Component, EventEmitter, forwardRef, input, Input, Optional, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
  FormControl,
  ReactiveFormsModule,
  FormGroupDirective,
} from '@angular/forms';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { InputErrorMessageComponent } from '../input-error-message/input-error-message.component';
import { FormControlConstants } from '@shared/constants/form-control.constants';

@Component({
  selector: 'aux-date-picker',
  template: `
    <div
      *ngIf="{
        showError: control.invalid && (control.dirty || control.touched || fg?.submitted)
      } as obj"
    >
      @if (label) {
        <span class="block mb-1 text-xs">{{ label }}</span>
      }
      <input
        type="date"
        [min]="min"
        [max]="max"
        class="block text-sm h-10 w-full placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue text-gray-400"
        [ngClass]="{ 'is-invalid': obj.showError }"
        (blur)="onBlur()"
        [formControl]="control"
        [disabled]="control.disabled"
      />
      @if (obj.showError) {
        <aux-input-error-message [errors]="control.errors" />
      }
    </div>
  `,
  styleUrls: ['../input.component.css'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
  imports: [ReactiveFormsModule, NgClass, NgIf, InputErrorMessageComponent, JsonPipe],
})
export class DatePickerComponent implements ControlValueAccessor, Validator {
  @Input() control!: FormControl;

  @Input() label = '';

  customErrorMessages = input<Record<string, string>>({});

  @Output() blured = new EventEmitter<string | null>();

  protected readonly min = FormControlConstants.MIN_VALUE.DATE;

  protected readonly max = FormControlConstants.MAX_VALUE.DATE;

  constructor(@Optional() protected fg?: FormGroupDirective) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: unknown) => {};

  onTouched = () => {};

  onValidatorChange = () => {};

  onBlur() {
    this.control.updateValueAndValidity();
    this.onValidatorChange();
    this.blured.emit(this.control.value);
  }

  writeValue(value: unknown): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.errors;
  }
}
