<ng-container *ngIf="(paymentMilestoneQuery.selectLoading() | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(paymentMilestoneQuery.selectLoading() | async) === false">
  <div class="flex justify-end mb-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === false"
      variant="secondary"
      label="Edit"
      icon="Pencil"
      [onClick]="editGrid.bind(this)"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="X"
      [onClick]="cancelEditMode.bind(this)"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      class="ml-1"
      icon="Check"
      [disabled]="!hasChanges"
      [onClick]="onSaveAll"
    />
  </div>

  <ag-grid-angular
    id="paymentMilestonesGrid"
    class="ag-theme-aux tabular-nums mb-4"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    (gridReady)="gridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  />

  <div class="mt-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Payment Milestone"
      [onClick]="onAddPaymentMilestone.bind(this)"
    />
  </div>
</div>
