import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { Component, ChangeDetectionStrategy, ViewChild, signal } from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { File } from '@shared/components/file-manager/state/file.model';
import { ApiService, FileMetadata } from '@shared/services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OverlayService } from '@shared/services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { BudgetType, EntityType, EventType, GqlService } from '@shared/services/gql.service';
import { EventService } from '@models/event/event.service';
import { formatDate } from '@angular/common';
import { batchPromises } from '@shared/utils';
import dayjs from 'dayjs';
import { Utils } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BudgetQuery } from '@models/budget/budget.query';

Utils.extendDayjs();

type FileProcessingStatus = {
  fileId: string;
  originalBucketKey: string;
  destinationBucketKey: string;
  eventTrackerId?: string;
};

@Component({
  selector: 'aux-quarter-close-add-upload',
  templateUrl: './add-vendor-estimate-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVendorEstimateUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = signal('');

  availableMonths$ = this.getAvailableMonths();

  selectedMonth = new FormControl(this.availableMonths$[0].date);

  selectedVendor = new FormControl('');

  shortName = new FormControl('');

  notes = new FormControl('');

  totalAmount = new FormControl('');

  showEstimateWarningBanner$ = this.launchDarklyService.select$(
    (flags) => flags.vendor_estimate_warn_banner
  );

  constructor(
    public ref: CustomOverlayRef<unknown, { selectedVendor: string; selectedMonth: string }>,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private budgetQuery: BudgetQuery,
    public vendorsQuery: OrganizationQuery,
    private gqlService: GqlService,
    private eventService: EventService,
    private launchDarklyService: LaunchDarklyService
  ) {
    if (ref.data) {
      const { selectedVendor, selectedMonth } = ref.data;
      this.selectedVendor = new FormControl(selectedVendor || '');
      this.selectedMonth = new FormControl(selectedMonth || this.availableMonths$[0].date);
    }
  }

  pathFn: () => string = () => '';

  getFilePath(month: string) {
    // const date = formatDate(new Date(), 'MMM-YYYY', 'en-US');
    const formatted_current_month = formatDate(month, 'MMMM-y', 'en-US');
    const trialId = this.mainQuery.getValue().trialKey;
    // vendor/<vendor uuid>/in-month/<MON-YYYY>/<file name goes here>
    return `trials/${trialId}/vendors/vendor-estimate/${formatted_current_month}/`;
  }

  private async cleanUpTempFiles(fileReferences: FileProcessingStatus[]) {
    if (!this.fileManager || !Array.isArray(fileReferences)) {
      return;
    }
    const fileRemovePromises = [];
    for (const fileRef of fileReferences) {
      this.fileManager.fileStore.update(fileRef.fileId, {
        bucket_key: fileRef.originalBucketKey,
      });
      if (!fileRef.eventTrackerId) {
        fileRemovePromises.push(this.apiService.removeFile(fileRef.destinationBucketKey));
      } else {
        this.fileManager.fileStore.remove(fileRef.fileId);
      }
    }
    const removalErrors: Error[] = [];
    try {
      (await batchPromises(fileRemovePromises, (p) => p)).forEach((r) => {
        if (r instanceof Error) {
          removalErrors.push(r);
        }
      });
    } catch (err) {
      removalErrors.push(err instanceof Error ? err : new Error(String(err)));
    }
    if (removalErrors.length) {
      console.error('Failed to remove some of the temp files', removalErrors);
    }
  }

  async onUpload() {
    if (!this.fileManager || this.loading$.getValue()) {
      return;
    }
    if (!this.selectedVendor.value) {
      this.overlayService.error('Please select a vendor');
      return;
    }
    this.errorMessage.set('');

    const { budget_info } = this.budgetQuery.getValue();
    const date = this.selectedMonth.value || budget_info[0].current_month;
    if (!date) {
      this.errorMessage.set('No in month found!');
      return;
    }

    const files = this.fileManager.fileQuery.getAll();
    if (!files.length) {
      this.errorMessage.set('You need to upload a file!');
      return;
    }

    let processingFailed = false;
    const fileStatuses: FileProcessingStatus[] = [];
    this.loading$.next(true);

    try {
      const filePath = this.getFilePath(date);
      for (const file of files) {
        const fileStatus: FileProcessingStatus = {
          fileId: file.id,
          originalBucketKey: file.bucket_key,
          destinationBucketKey: `${filePath}${file.bucket_key}`,
        };
        fileStatuses.push(fileStatus);
        this.fileManager.fileStore.update(file.id, {
          bucket_key: fileStatus.destinationBucketKey,
          uploaded: false,
        });
      }

      const metadata: FileMetadata = { admin: '1' };
      if (!(await this.fileManager.fileService.uploadFiles(metadata))) {
        processingFailed = true;
        return;
      }

      const eventSubmitCommands = fileStatuses.map((fileProcessingStatus) => async () => {
        const result = await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value || '',
            bucket_key: `public/${fileProcessingStatus.destinationBucketKey}`,
            payload: JSON.stringify({
              budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
              month: this.selectedMonth.value,
              amount: this.totalAmount.value,
              notes: this.notes.value,
              short_name: this.shortName.value,
            }),
          })
        );
        if (result.success && result.data?.id) {
          fileProcessingStatus.eventTrackerId = result.data.id;
          return;
        }
        throw new Error(result.errors[0] || 'An error occurred');
      });
      const eventSubmitPromises = eventSubmitCommands.map((cmd) => cmd());
      const eventSubmitResults = await batchPromises(eventSubmitPromises, (p) => p);
      if (eventSubmitResults.some((r) => r instanceof Error)) {
        processingFailed = true;
        console.error('Some events failed', eventSubmitResults);
      } else {
        this.overlayService.success();
        this.ref.close(true);
      }
    } catch (err) {
      processingFailed = true;
      console.error('Failed to process files', err);
    } finally {
      if (processingFailed) {
        await this.cleanUpTempFiles(fileStatuses);
        this.overlayService.error('Error uploading files.');
      }
      this.loading$.next(false);
    }
  }

  removeFile(file: File) {
    if (this.fileManager) {
      this.fileManager.removeFile(file);
    }
  }

  getAvailableMonths() {
    const { budget_info } = this.budgetQuery.getValue();
    let date = dayjs(budget_info[0].current_month);
    const months = [];
    const current_quarter = date.quarter();

    while (date.quarter() === current_quarter) {
      months.push({ name: date.format('MMMM YYYY'), date: date.format('MM-DD-YYYY') });
      date = date.add(1, 'months');
    }

    return months;
  }

  async onLinkClick() {
    const vendorList = await firstValueFrom(this.vendorsQuery.allVendors$);
    const vendorId = this.selectedVendor.value ? this.selectedVendor.value : vendorList[0].id;
    this.ref.close({ vendorId });
  }
}
