import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MainQuery } from '@shared/store/main/main.query';
import { SitesService } from '@models/sites/sites.service';
import { switchMap } from 'rxjs/operators';
import { SitesQuery } from '@models/sites/sites.query';
import { Option } from '@shared/types/components.type';
import {
  DocumentType,
  FetchDocumentsInput,
  listDocumentsQuery,
} from '@shared/services/gql.service';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { DEFAULT_FETCH_DOCUMENTS_INPUT, ExtractorService } from './extractor.service';
import { File } from '@shared/components/file-manager/state/file.model';
import { cloneDeep, take } from 'lodash-es';
import { ConvertedFilesComponent } from './converted-files/converted-files.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterEntity, TableService } from '@shared/services/table.service';

@Component({
  selector: 'aux-extractor',
  templateUrl: './extractor.component.html',
  styleUrls: ['extractor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ExtractorService],
})
export class ExtractorComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild(FileManagerComponent) fileManager?: FileManagerComponent;

  @ViewChild(ConvertedFilesComponent) convertedFiles?: ConvertedFilesComponent;

  documentTypeFormControl = new FormControl(DocumentType.DOCUMENT_SITE_AGREEMENTS);

  siteFormControl = new FormControl();

  documentLibraryFormControl = new FormControl();

  siteOptions: Option[] = [];

  documentsFilteredByExtensions: listDocumentsQuery['items'] = [];

  documentLibraryOptions: Option[] = [];

  uploadedFiles: File[] = [];

  isConvertedFilesVisible = false;

  readonly documentTypesOptions: Option[] = [
    {
      label: 'Site Contracts',
      value: DocumentType.DOCUMENT_SITE_AGREEMENTS,
    },
    {
      label: 'Invoices',
      value: DocumentType.DOCUMENT_INVOICE,
    },
    {
      label: 'Vendor Budgets',
      value: DocumentType.DOCUMENT_VENDOR_BUDGET,
    },
  ];

  constructor(
    private mainQuery: MainQuery,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery,
    private extractorService: ExtractorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.onTrialChange();
  }

  ngAfterViewInit(): void {
    this.fileManager?.fileQuery.selectAll().subscribe((files) => {
      this.uploadedFiles = files;
    });

    this.onDocumentTypeValueChange();
  }

  getBucketKey = () => {
    return `document-storage/upload/${this.documentTypeFormControl.value}`;
  };

  isSiteFormControlVisible(): boolean {
    return this.documentTypeFormControl.value === DocumentType.DOCUMENT_SITE_AGREEMENTS;
  }

  clearAll(): void {
    this.documentLibraryFormControl.setValue('', { emitEvent: false });
    this.fileManager?.fileStore.remove(() => true);
  }

  async extract(): Promise<void> {
    this.updateBucketKey();

    const s3Uploaded = await this.fileManager?.fileService.uploadFiles();

    if (s3Uploaded) {
      const extracted = await this.extractorService.extractFiles(
        this.uploadedFiles,
        this.documentTypeFormControl.value || DocumentType.DOCUMENT_SITE_AGREEMENTS,
        this.siteFormControl.value || null
      );

      if (extracted) {
        this.convertedFiles?.refreshGridData();
        this.clearAll();
      }
    }
  }

  addDocLibraryItem(item: string): void {
    const document = this.documentsFilteredByExtensions.find((doc) => {
      return doc.id === item;
    });
    const file = {
      ...document,
      fileName: document?.name || '',
    };

    if (document) {
      this.fileManager?.fileStore.add(file as unknown as File);
    }
  }

  removeDocLibraryItem(item: { [key: string]: boolean | number | string; value: string }): void {
    this.fileManager?.fileStore.remove(item.value);
  }

  removeFromDocLibraryControl(file: File): void {
    if (
      this.documentLibraryFormControl.value.length > 0 &&
      this.documentLibraryFormControl.value.includes(file.id)
    ) {
      const items = cloneDeep(this.documentLibraryFormControl.value);
      items.splice(items.indexOf(file.id), 1);
      this.documentLibraryFormControl.setValue(items, { emitEvent: false });
    }
  }

  private onTrialChange(): void {
    const filterModel: Record<string, FilterEntity> = {
      document_type_id: {
        type: 'text',
        value: [
          DocumentType.DOCUMENT_BUDGET_SUPPORT,
          DocumentType.DOCUMENT_CHANGE_ORDER_SUPPORT,
          DocumentType.DOCUMENT_INVOICE,
          DocumentType.DOCUMENT_INVOICE_SUPPORT,
          DocumentType.DOCUMENT_SITE_AGREEMENTS,
          DocumentType.DOCUMENT_VENDOR_CONTRACT,
          DocumentType.DOCUMENT_VENDOR_ESTIMATE_SUPPORT,
        ],
      },
    };
    const filter_model = JSON.stringify(TableService.getServerSideFilters(filterModel));
    const input: FetchDocumentsInput = {
      ...DEFAULT_FETCH_DOCUMENTS_INPUT,
      sort_model: [
        {
          descending: true,
          field: 'create_date',
        },
      ],
      filter_model,
    };

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(async () => {
          this.isConvertedFilesVisible = false;
          return this.sitesService
            .get()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.initSiteOptions());
        }),
        switchMap(async () =>
          this.extractorService
            .getDocuments(input)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((documents) => this.initDocumentLibraryOptions(documents))
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private onDocumentTypeValueChange(): void {
    this.documentTypeFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.siteFormControl.setValue(null, { emitEvent: false });
      });
  }

  private initSiteOptions(): void {
    this.isConvertedFilesVisible = true;
    this.siteOptions = this.sitesQuery.getAll().map((site) => ({
      value: site.id,
      label: site.name || '',
    }));
    this.changeDetectorRef.detectChanges();
  }

  private initDocumentLibraryOptions(documents: listDocumentsQuery['items']): void {
    const MAX_OPTIONS_LENGTH = 100;
    this.documentsFilteredByExtensions = documents.filter((document) =>
      document.bucket_key.endsWith('.pdf')
    );

    this.documentLibraryOptions = take(
      this.documentsFilteredByExtensions.map((document) => ({
        value: document.id,
        label: document.name,
      })),
      MAX_OPTIONS_LENGTH
    );
  }

  private updateBucketKey(): void {
    this.uploadedFiles.forEach((file) => {
      if (file?.rawFile) {
        this.fileManager?.fileStore.update(file.id, {
          ...file,
          bucket_key: `document-storage/upload/${this.documentTypeFormControl.value}/${file.entity_id}/${file.fileName}`,
        });
      }
    });
  }
}
