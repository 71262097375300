<ng-container
  *ngIf="{
    changeOrder: changeOrder$ | async,
    loading: loading$ | async,
    organization: organization$ | async,
    isPendingReview: isPendingReview$ | async,
    isPendingApproval: isPendingApproval$ | async,
    isApproved: isApproved$ | async,
    isDeclined: isDeclined$ | async,
    isApproveDisabled:
      (isApproveButtonDisable$ | async) ||
      (isApproveButtonDisableManually$ | async) ||
      hasMissingActivitiesWithActuals,
    isUploadCODisabled: (isUploadCOBudgetButtonDisable$ | async),
    hasUploadCOPermission: (hasUploadCOPermission$ | async)
  } as obj"
>
  <ng-container *ngIf="obj.loading">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!obj.loading">
    <ng-container *ngIf="obj.changeOrder">
      <div class="flex items-center text-aux-blue-light-200 mb-6" [routerLink]="[changeOrdersLink]">
        <aux-icon name="ArrowRight" class="mr-2 transform rotate-180 cursor-pointer" />
        <div class="cursor-pointer">Back to Change Orders</div>
      </div>

      <div class="flex items-center justify-between mb-4">
        <div class="text-xl font-bold">
          {{ obj.organization?.name }} - Change Order {{ obj.changeOrder.change_order_no }}
        </div>
        <div>
          @if (obj.isPendingReview) {
            <aux-button icon="Pencil" label="Edit" (clickEmit)="onEditChangeOrder()" />
          }
        </div>
      </div>

      <div
        class="border px-4"
        [ngClass]="{
          'bg-aux-blue-light-50 text-aux-black': obj.isPendingReview || obj.isPendingApproval,
          'bg-aux-green-dark': obj.isApproved,
          'bg-aux-red-dark pb-4': obj.isDeclined,
          'text-white': obj.isApproved || obj.isDeclined
        }"
      >
        <div class="flex items-center justify-between h-16">
          <div>
            <span>Status:</span>
            <span class="font-bold ml-2">{{ status$ | async }}</span>
            <span *ngIf="approvedBy$ | async as approvedBy" class="font-bold">
              {{ approvedBy }}
            </span>
          </div>
          <div class="space-x-2 flex items-center">
            @if (obj.isPendingReview) {
              <aux-button
                variant="negative"
                [disabled]="isChangeOrdersWorkflowLocked()"
                [auxTooltip]="deleteBtnTooltip(isChangeOrdersWorkflowLocked())"
                (clickEmit)="onDelete()"
                [loading]="isBtnLoading('delete') | async"
                label="Delete"
              />
            }

            @if (obj.isPendingApproval && (showApprovalReminderLink$ | async)) {
              <aux-button
                variant="hyperlink"
                classList="btn-nr"
                (clickEmit)="onSendingPendingApprovalReminder()"
                label="Resend Reminder Email"
              />
            }
            <aux-button
              variant="secondary"
              (clickEmit)="onDownloadCO()"
              [loading]="isBtnLoading('download') | async"
              label="Download CO"
            />
            @if (obj.isPendingReview) {
              <aux-button
                variant="secondary"
                (clickEmit)="onDownloadLREBudget()"
                [loading]="isBtnLoading('lre-download') | async"
                label="Download LRE Budget"
              />

              <aux-button
                variant="secondary"
                (clickEmit)="onApprove(true)"
                [disabled]="
                  obj.isApproveDisabled ||
                  !$any(userHasApprovePermission$ | async) ||
                  isChangeOrdersWorkflowLocked()
                "
                [auxTooltip]="
                  approveBtnTooltip(
                    $any(userHasApprovePermission$ | async),
                    isChangeOrdersWorkflowLocked(),
                    obj.isApproveDisabled
                  )
                "
                label="Force Approve"
              />

              <aux-button
                variant="success"
                [disabled]="obj.isApproveDisabled"
                (clickEmit)="onSendApproval()"
                [loading]="isBtnLoading('approval') | async"
                label="Send for Approval"
                [auxTooltip]="
                  hasMissingActivitiesWithActuals
                    ? 'Template uploaded is missing activities with actuals. Re-upload with missing activities to Send for Approval.'
                    : obj.isApproveDisabled
                      ? 'Change Order Budget must be entered to Send for Approval'
                      : ''
                "
              />
            }

            @if (obj.isPendingApproval) {
              <aux-button
                variant="secondary"
                (clickEmit)="onReturnToAdminReview()"
                [loading]="isBtnLoading('admin-review') | async"
                label="Return to Admin Review"
              />
              <aux-button
                variant="success"
                (clickEmit)="onApprove()"
                [disabled]="
                  obj.isApproveDisabled ||
                  !$any(userHasApprovePermission$ | async) ||
                  isChangeOrdersWorkflowLocked()
                "
                [auxTooltip]="
                  approveBtnTooltip(
                    $any(userHasApprovePermission$ | async),
                    isChangeOrdersWorkflowLocked(),
                    obj.isApproveDisabled
                  )
                "
                label="Approve"
                [loading]="isBtnLoading('approve') | async"
              />
              <aux-button
                variant="negative"
                (clickEmit)="onDecline()"
                [disabled]="!$any(userHasApprovePermission$ | async)"
                [auxTooltip]="
                  !$any(userHasApprovePermission$ | async)
                    ? messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                    : ''
                "
                [loading]="isBtnLoading('decline') | async"
                label="Decline"
              />
              <aux-button
                variant="negative"
                [disabled]="isChangeOrdersWorkflowLocked()"
                [auxTooltip]="deleteBtnTooltip(isChangeOrdersWorkflowLocked())"
                (clickEmit)="onDelete()"
                [loading]="isBtnLoading('delete') | async"
                label="Delete"
              />
            }
          </div>
        </div>

        <div *ngIf="obj.isDeclined" class="bg-aux-gray-light mt-4 p-2 text-aux-black">
          <div class="font-bold">Reason for Decline</div>
          <div>{{ obj.changeOrder.decline_reason }}</div>
        </div>
      </div>

      <hr class="mb-3 mt-6" />

      <div class="grid grid-cols-2 gap-5 tabular-nums">
        <div>
          <div class="grid grid-cols-2">
            <div>
              <div class="font-bold mb-1">Vendor</div>
              <div>{{ obj.organization?.name || zeroHyphen }}</div>
            </div>
            <div>
              <div class="font-bold mb-1">Change Order #</div>
              <div>{{ obj.changeOrder.change_order_no || zeroHyphen }}</div>
            </div>
          </div>
          <hr class="my-4" />
          <div class="grid grid-cols-2">
            <div>
              <div class="font-bold mb-1">Effective Date</div>
              <div>{{ obj.changeOrder.effective_date || zeroHyphen }}</div>
            </div>
            <div>
              <div class="font-bold mb-1">Date Received</div>
              <div>{{ obj.changeOrder.date_received || zeroHyphen }}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="font-bold mb-1">Change Order Notes</div>
          <div class="respect_newline">
            {{ obj.changeOrder.notes || zeroHyphen }}
          </div>
        </div>
      </div>
      <hr class="mt-3" />

      <div class="my-6 flex gap-[15px]">
        <div
          class="border border-[#E9EAEE] rounded-[8px] p-[10px] w-[200px] flex flex-col justify-between h-[75px]"
        >
          <div class="text-sm text-aux-gray-dark-100">Activities w/ Increased Cost</div>
          <div class="text-2xl font-bold text-aux-gray-darkest">
            {{ analyticsCard().co_count_activities_with_increased_cost || zeroHyphen }}
          </div>
        </div>
        <div
          class="border border-[#E9EAEE] rounded-[8px] p-[10px] w-[200px] flex flex-col justify-between h-[75px]"
        >
          <div class="text-sm text-aux-gray-dark-100">Activities w/ Increased Units</div>
          <div class="text-2xl font-bold text-aux-gray-darkest">
            {{ analyticsCard().co_count_activities_with_increased_units || zeroHyphen }}
          </div>
        </div>
        <div
          class="border border-[#E9EAEE] rounded-[8px] p-[10px] w-[200px] flex flex-col justify-between h-[75px]"
        >
          <div class="text-sm text-aux-gray-dark-100">Total Cost Impact</div>
          <div class="text-2xl font-bold text-aux-gray-darkest">
            {{ analyticsCard().variance_total_cost_AC || zeroHyphen }}
          </div>
        </div>
      </div>

      <aux-progress-tracker
        [trackerId]="changeOrderBudgetTemplateUploadProgressId()"
        (trackerIdChanged)="onTrackerIdChanged($event)"
        [validationInProgressCaption]="'Import Change Order Template - Validation Progress'"
        [dataLoadInProgressCaption]="'Import Change Order Template - Data Load Progress'"
        class="my-8 block"
      />

      <div class="mb-[15px]">
        <div class="text-lg font-bold mb-[5px]">Change Order Budget Updates</div>
        <div>
          To update your budget based on the Change Order, start by either selecting Edit > Add New
          Activities or by selecting the existing activities you wish to modify in the table and
          clicking Edit > Edit Activities to modify them.
        </div>
      </div>

      <aux-table-panel>
        <div class="p-[15px] flex items-center justify-end gap-[15px]">
          @if (toBudgetVersion()) {
            <aux-checkbox
              id="hide_unchanged"
              class="text-sm"
              [disabled]="grid()?.showOnlyMissingActivities() || false"
              [checked]="!grid()?.showUnchangedActivities() || false"
              (customChange)="grid()?.showUnchangedActivities?.set(!$event)"
            >
              Hide Unchanged
            </aux-checkbox>
          }

          <aux-button-group
            variant="primary"
            label="Edit"
            [options]="editOptions()"
            [disabled]="!obj.isPendingReview || (hasUpdateChangeOrderPermissions$ | async) !== true"
            [disabledTooltip]="
              !obj.isPendingReview ? editButtonDisabledTooltip : doNotHavePermissionMessage
            "
            [position]="buttonGroupOverlayPosition"
          />

          <aux-button-group
            iconName="DotsVertical"
            [showDropdownIcon]="false"
            [options]="kebabOptions()"
            disabledTooltip="Please select at least one row to edit"
            [position]="buttonGroupOverlayPosition"
          />
        </div>
        <div
          *ngIf="
            grid()?.doesBudgetDataHaveMissingActivities() === true &&
            (authQuery.adminUser$ | async) === true
          "
          class="m-[15px] flex justify-between bg-aux-error/25 my-4 rounded-md border-aux-red-dark border-2"
        >
          <div class="py-4 pl-4 flex items-center text-aux-red-dark">
            <aux-icon name="AlertTriangle" />
            <span class="font-bold pl-1">
              This Change Order is missing activities that are in the current budget. Please review
              below.
            </span>
          </div>
          <aux-checkbox
            id="show_missing_activities"
            class="text-sm py-4 pr-4"
            [checked]="!!grid()?.showOnlyMissingActivities()"
            (customChange)="grid()?.showOnlyMissingActivities?.set($event)"
          >
            Show Missing Activities
          </aux-checkbox>
        </div>

        @if (fromBudgetVersion() && organization()) {
          <aux-change-order-grid
            [fromBudgetVersion]="fromBudgetVersion()!"
            [toBudgetVersion]="toBudgetVersion()!"
            [organization]="organization()!"
            [rowSelection]="rowSelection()"
            [changeOrderID]="changeOrder()!.id"
          />
        } @else {
          <div>
            <!-- todo AUXI-6868 -->
          </div>
        }
      </aux-table-panel>
    </ng-container>
  </ng-container>
</ng-container>
