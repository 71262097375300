import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { CanDeactivateGuard } from '@shared/guards/can-deactivate.guard';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { EventService } from '@models/event/event.service';
import { EventType } from '@shared/services/gql.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ScenarioComponent } from '@pages/scenario/scenario.component';
import { DocumentsComponent } from '@pages/documents/documents.component';
import { ScenarioManagerComponent } from '@pages/scenario/scenario-manager/scenario-manager.component';
import { DesignSystemComponent } from '@pages/design-system/design-system.component';
import { PortfolioDashboardComponent } from '@pages/portfolio-dashboard/portfolio-dashboard.component';
import { AuditHistoryComponent } from '@pages/audit-history/audit-history.component';
import { buildBudgetRoutes } from '@pages/budget-page/budget-page.routing';
import { buildPeriodCloseRoutes } from '@pages/closing-page/closing-page.routing';
import { buildVendorPaymentsRoutes } from '@pages/vendor-payments-page/vendor-payments-page.routing';
import { buildSettingsRoutes } from '@pages/settings/settings.routing';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { RiskAnalyticsComponent } from '@pages/risk-analytics/risk-analytics.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { buildForecastRoutes } from '@pages/forecast-accruals-page/forecast.routing';
import { buildAccountRoutes } from '@pages/account/account.routing';
import { MainQuery } from '@shared/store/main/main.query';
import { CommonConstants } from '@shared/constants/common.constants';
import { buildInvestigatorRoutes } from '@pages/investigator/investigator.routing';
import { combineLatest, EMPTY } from 'rxjs';
import { buildTrialInsightsRoutes } from '@pages/trial-insights/trial-insights.routing';
import { reflectOnFeatureFlagChange } from '@shared/services/routing.service';
import { HttpParams } from '@angular/common/http';
import { OpsAdminComponent } from '@pages/ops-admin/ops-admin.component';
import { pairwise, startWith, switchMap } from 'rxjs/operators';
import { QaTestingComponent } from '@pages/qa-testing/qa-testing.component';
import { fromPairs, toPairs, isEqual, differenceWith } from 'lodash';
import { FeatureFlag } from '@models/feature-flag.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const buildRoutes = (featureFlags: Flags | null, lastUrl: string): Routes => [
  {
    path: ROUTING_PATH.LOGIN,
    loadComponent: () => import('./pages/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: ROUTING_PATH.FORGOT_PASSWORD,
    loadComponent: () =>
      import('./pages/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
  {
    path: ROUTING_PATH.CONFIRMATION,
    loadComponent: () =>
      import('./pages/confirmation/confirmation.component').then((c) => c.ConfirmationComponent),
  },
  {
    path: ROUTING_PATH.ONBOARDING.INDEX,
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./pages/onboarding/onboarding-routing.module').then((m) => m.OnboardingRoutingModule),
  },
  {
    path: ROUTING_PATH.SYSTEM_MAINTENANCE,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/system-maintenance/system-maintenance.component').then(
        (c) => c.SystemMaintenanceComponent
      ),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      reflectOnFeatureFlagChange(
        'nav_trial_insights',
        featureFlags,
        ROUTING_PATH.TRIAL_INSIGHTS.INDEX,
        buildTrialInsightsRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_budget',
        featureFlags,
        ROUTING_PATH.BUDGET.INDEX,
        buildBudgetRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_forecast',
        featureFlags,
        ROUTING_PATH.FORECAST_ROUTING.INDEX,
        buildForecastRoutes
      ),
      reflectOnFeatureFlagChange(
        'tab_forecast_in_month',
        featureFlags,
        ROUTING_PATH.CLOSING.INDEX,
        buildPeriodCloseRoutes,
        lastUrl
      ),
      reflectOnFeatureFlagChange(
        'nav_investigator',
        featureFlags,
        ROUTING_PATH.INVESTIGATOR.INDEX,
        buildInvestigatorRoutes
      ),
      reflectOnFeatureFlagChange(
        'nav_invoices',
        featureFlags,
        ROUTING_PATH.VENDOR_PAYMENTS.INDEX,
        buildVendorPaymentsRoutes,
        lastUrl
      ),
      buildSettingsRoutes(featureFlags),
      buildAccountRoutes(featureFlags),
      {
        path: ROUTING_PATH.HOME,
        component: PortfolioDashboardComponent,
      },
      {
        path: ROUTING_PATH.MANAGER,
        component: ScenarioComponent,
        children: [
          { path: '', component: ScenarioManagerComponent },
          { path: '**', redirectTo: '' },
        ],
      },
      reflectOnFeatureFlagChange(
        'nav_audit_history',
        featureFlags,
        ROUTING_PATH.AUDIT_HISTORY,
        () => ({
          path: ROUTING_PATH.AUDIT_HISTORY,
          component: AuditHistoryComponent,
        })
      ),
      reflectOnFeatureFlagChange(
        'nav_document_library',
        featureFlags,
        ROUTING_PATH.DOCUMENTS,
        () => ({
          path: ROUTING_PATH.DOCUMENTS,
          component: DocumentsComponent,
          canDeactivate: [CanDeactivateGuard],
        })
      ),
      {
        path: ROUTING_PATH.RISK_ANALYTICS,
        component: RiskAnalyticsComponent,
      },
      {
        path: ROUTING_PATH.DASHBOARD,
        component: DashboardComponent,
      },
      reflectOnFeatureFlagChange(
        'nav_design_system',
        featureFlags,
        ROUTING_PATH.DESIGN_SYSTEM,
        () => ({
          path: ROUTING_PATH.DESIGN_SYSTEM,
          component: DesignSystemComponent,
          loadChildren: () =>
            import('./pages/design-system/design-system.module').then((m) => m.DesignSystemModule),
        })
      ),
      reflectOnFeatureFlagChange(
        'nav_ops_admin',
        featureFlags,
        ROUTING_PATH.OPS_ADMIN.INDEX,
        () => ({
          path: ROUTING_PATH.OPS_ADMIN.INDEX,
          component: OpsAdminComponent,
          loadChildren: () =>
            import('./pages/ops-admin/ops-admin-routing.module').then(
              (m) => m.OpsAdminRoutingModule
            ),
        })
      ),
      reflectOnFeatureFlagChange(
        'nav_qa_testing',
        featureFlags,
        ROUTING_PATH.QA_TESTING.INDEX,
        () => ({
          path: ROUTING_PATH.QA_TESTING.INDEX,
          component: QaTestingComponent,
          loadChildren: () =>
            import('./pages/qa-testing/qa-testing-routing.module').then(
              (m) => m.QaTestingRoutingModule
            ),
        })
      ),
      {
        path: '**',
        redirectTo: featureFlags?.nav_portfolio ? 'home' : 'budget',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
})
export class AppRoutingModule {
  private appRouteList: string[] = [];

  private lastUrl = '';
  constructor(
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute
  ) {
    this.launchDarklyService.loaded$
      .pipe(
        switchMap((loaded) => {
          if (!loaded) {
            return EMPTY;
          }
          return this.launchDarklyService.flags$;
        }),
        startWith(this.launchDarklyService.$flags()),
        pairwise()
      )
      .subscribe(([prevFlags, flags]) => {
        this.updateRoutesAndInitNavigation(buildRoutes(flags, this.lastUrl), prevFlags);
        this.systemMaintenanceRedirect();
      });

    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lastUrl = event.urlAfterRedirects;
        const trialId = this.mainQuery.getValue().trialKey;

        if (trialId) {
          this.setTrialIdQueryParameter(trialId);
        }
      }
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([{ trial_id }, trialKey]) => {
        const trialId = trialKey ? trialKey : trial_id;
        this.setTrialIdQueryParameter(trialId);
      });
  }

  private setTrialIdQueryParameter(trialId: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [CommonConstants.TRIAL_ID_QUERY_PARAMETER_NAME]: trialId,
        utm_source: null,
        utm_campaign: null,
        utm_medium: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private systemMaintenanceRedirect(): void {
    const nav =
      this.launchDarklyService.flags$.getValue().nav_system_maintenance_with_messages.messages;
    const route = `/${ROUTING_PATH.SYSTEM_MAINTENANCE}`;
    // sometimes `router.url` doesn't give us the correct url,
    // so instead I'm looking at the window location here
    const isRouteMaintenance = window.location.pathname.includes(route);

    if (nav && !isRouteMaintenance) {
      this.router.navigate([route]);
    } else if (!nav && isRouteMaintenance) {
      this.router.navigate([`/`]);
    }
  }

  private getAppRouteList(parent: string, config: Routes): void {
    for (let i = 0; i < config.length; i++) {
      const route = config[i];

      if (!route.redirectTo) {
        this.appRouteList = [...this.appRouteList, `${parent}/${route.path}`];
      }

      if (route.children) {
        const currentPath = route.path ? `${parent}/${route.path}` : parent;
        this.getAppRouteList(currentPath, route.children);
      }
    }
  }

  private updateAppRouteList(): void {
    this.appRouteList = [];
    this.getAppRouteList('', this.router.config);
  }

  private updateRoutesAndInitNavigation(routes: Routes, prevFlags?: Flags): void {
    this.router.resetConfig([...routes]);
    this.updateAppRouteList();

    const urlParts = this.router.url.split('?');
    if (urlParts[0] !== '/') {
      if (!this.appRouteList.includes(urlParts[0])) {
        if (urlParts[1]) {
          const httpParams = new HttpParams({ fromString: urlParts[1] });
          const queryParams = httpParams.keys().reduce((result, k) => {
            return {
              ...result,
              [k]: httpParams.get(k),
            };
          }, {});
          this.router.navigate([urlParts[0]], { queryParams });
        } else {
          this.router.navigate([this.router.url]);
        }
      } else {
        this.refreshCanActivateGuards(prevFlags);
      }
    }
  }

  private refreshCanActivateGuards(prevFlags?: Flags): void {
    if (!prevFlags) {
      return;
    }

    const flags = this.launchDarklyService.$flags();
    const flagsChanges = Object.keys(
      fromPairs(differenceWith(toPairs(prevFlags), toPairs(flags), isEqual))
    );

    const pathsToRefresh = [
      {
        value: ROUTING_PATH.CLOSING.CHECKLIST,
        flag: 'checklist_tab',
      },
      {
        value: ROUTING_PATH.CLOSING.ADJUSTMENTS,
        flag: FeatureFlag.IN_MONTH_ADJUSTMENTS,
      },
      {
        value: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
        flag: FeatureFlag.MONTH_AND_QUARTER_CLOSE,
      },
      {
        value: ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY,
        flag: 'forecast_methodology_tab',
      },
      {
        value: ROUTING_PATH.FORECAST_ROUTING.CUSTOM_DRIVERS,
        flag: 'custom_drivers',
      },
    ];

    if (
      pathsToRefresh.some(
        (path) => this.router.url.includes(path.value) && flagsChanges.includes(path.flag)
      )
    ) {
      this.router.navigateByUrl(this.router.url);
    }
  }
}
