import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface VarContext<T> {
  $implicit: T;
  ngVar: T;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngVar]',
  standalone: true,
})
export class VarDirective<T> {
  @Input()
  set ngVar(context: T) {
    this.context = {
      $implicit: context,
      ngVar: context,
    };
    this.updateView();
  }

  context: VarContext<T> = { $implicit: null as unknown as T, ngVar: null as unknown as T };

  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<VarContext<T>>
  ) {}

  updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
