import { Component, computed, effect, inject, model, signal } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { InvoicesGridFormatterService } from '@pages/vendor-payments-page/tabs/invoices/invoices-grid-formatter.service';
import { Utils } from '@shared/utils/utils';
import { Option } from '@shared/types/components.type';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { PermissionType, TrialImplementationStatus } from '@shared/services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

export interface BulkEditInvoicesResponse {
  success: boolean;
  data: {
    vendor: string | null;
    status: string | null;
    paymentStatus: string | null;
    accrualPeriod: string | null;
    servicesPeriod: string | null;
    purchaseOrder: string | null;
  };
}

@Component({
  standalone: true,
  templateUrl: 'bulk-edit-invoices.component.html',
  imports: [NgClass, NgSelectModule, FormsModule, ButtonComponent, TooltipDirective],
})
export class BulkEditInvoicesComponent {
  ref = inject(CustomOverlayRef<BulkEditInvoicesResponse>);
  invoiceService = inject(InvoiceService);
  invoicesGridFormatterService = inject(InvoicesGridFormatterService);
  launchDarklyService = inject(LaunchDarklyService);
  authService = inject(AuthService);
  mainQuery = inject(MainQuery);

  showInvoicesPaymentStatus = this.launchDarklyService.$select(
    (flags) => flags.invoices_payment_status
  );
  userHasApproveInvoicePermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  vendor = model<string | null>(null);
  status = model<string | null>(null);
  paymentStatus = model<string | null>(null);
  accrualPeriod = model<string | null>(null);
  servicesPeriod = model<string | null>(null);
  purchaseOrder = model<string | null>(null);

  vendorOptions = computed(() => {
    return this.invoiceService.vendorOptions;
  });
  invoiceStatusOptions = computed(() => {
    const hasPermission = this.userHasApproveInvoicePermission();

    return hasPermission
      ? this.invoiceService.invoiceStatusOptions.map((status) => ({
          value: status,
          ...InvoicesGridFormatterService.getInvoiceStatusStyles(status),
        }))
      : [];
  });
  paymentStatusOptions = computed(() => {
    return this.invoiceService.paymentStatusOptions.map((status) => ({
      value: status || '',
      ...InvoicesGridFormatterService.getInvoicePaymentStatusStyles(status),
    }));
  });
  accrualPeriodOptions = computed(() => {
    const { currentOpenMonth } = this.mainQuery.mainState();
    const trial = this.mainQuery.selectedTrial();
    const isTrialArchivedOrLive =
      trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
      trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE;
    const options = isTrialArchivedOrLive
      ? this.invoiceService.accrualPeriodOptions.filter((option) => {
          return `${option.value}-01` >= currentOpenMonth;
        })
      : this.invoiceService.accrualPeriodOptions;
    return [{ value: '', label: Utils.zeroHyphen }, ...options];
  });
  servicesPeriodOptions = computed(() => {
    return [{ value: '', label: Utils.zeroHyphen }, ...this.invoiceService.accrualPeriodOptions];
  });
  purchaseOrderOptions = signal<Option<string | null>[]>([{ value: '', label: Utils.zeroHyphen }]);

  isApplyButtonDisabled = computed(() => {
    return (
      this.vendor() == null &&
      this.status() == null &&
      this.paymentStatus() == null &&
      this.accrualPeriod() == null &&
      this.servicesPeriod() == null &&
      this.purchaseOrder() == null
    );
  });

  updatePOWhenVendorChanges = effect(
    () => {
      this.purchaseOrder.set(null);

      if (this.vendor()) {
        this.purchaseOrderOptions.set([
          { value: '', label: Utils.zeroHyphen },
          ...this.invoiceService.purchaseOrdersQuery
            .getAll()
            .filter((order) => order.organization?.id === this.vendor())
            .map(({ id }) => ({
              value: id,
              label: this.invoicesGridFormatterService.getFormattedPurchaseOrder(id),
            })),
        ]);
      } else {
        this.purchaseOrderOptions.set([{ value: '', label: Utils.zeroHyphen }]);
      }
    },
    {
      allowSignalWrites: true,
    }
  );

  onSubmit() {
    this.ref.close({
      success: true,
      data: {
        vendor: this.vendor(),
        status: this.status(),
        paymentStatus: this.paymentStatus(),
        accrualPeriod: this.accrualPeriod(),
        servicesPeriod: this.servicesPeriod(),
        purchaseOrder: this.purchaseOrder(),
      },
    });
  }
}
