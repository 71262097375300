import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { QuarterCloseNotesState, QuarterCloseNotesStore } from './quarter-close-notes.store';
import { WorkflowStepType as WorkflowStep } from '@shared/store/workflow/workflow.const';

export const ALLOWED_STEPS = [
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_DISCOUNTS,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_TIMELINE,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES,
  WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS,
];

@Injectable({ providedIn: 'root' })
export class QuarterCloseNotesQuery extends Query<QuarterCloseNotesState> {
  private readonly workflowQuery = inject(WorkflowQuery);

  selectedPeriod$ = this.select('selectedPeriod');

  selectedPeriod = toSignal(this.select('selectedPeriod'), { requireSync: true });

  notes = toSignal(this.select('notes'), { requireSync: true });

  taskInstruction$ = (workflowId: string) =>
    this.select('notes').pipe(map((notes) => notes[workflowId]?.taskInstruction));

  monthlyReviewNotes$ = (workflowId: string) =>
    this.select('notes').pipe(map((notes) => notes[workflowId]?.monthlyReviewNotes || []));

  activeWorkflowList = computed(() => {
    return this.workflowQuery
      .selectWorkflowList()
      .filter((workflow) => ALLOWED_STEPS.includes(workflow.step_type));
  });

  someNoteEditorHasUnsavedChanges = computed(() => {
    return this.activeWorkflowList().some(
      (workflow) => this.notes()[workflow.id]?.dirty?.someEditorDirty
    );
  });

  constructor(protected store: QuarterCloseNotesStore) {
    super(store);
  }
}
