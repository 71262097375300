import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { UntypedFormControl, FormBuilder } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
  BudgetType,
  Currency,
  DriverType,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { EventService } from '@models/event/event.service';
import { MainQuery } from '@shared/store/main/main.query';
import { PatientGroupsService } from 'src/app/pages/forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@shared/store/auth/auth.query';
import dayjs from 'dayjs';
import { CategoryService } from './state/category/category.service';
import { CategoryQuery } from './state/category/category.query';
import { ForecastSettingsStore } from './state/settings/forecast-settings.store';
import { PatientCurveService } from './state/patient-curve/patient-curve.service';
import { PatientCurveQuery } from './state/patient-curve/patient-curve.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { CategoryStore } from './state/category/category.store';
import { SiteCurveQuery } from './drivers/forecast-sites/site-curve/site-curve.query';
import { ForecastTableGridComponent } from './components/forecast-table/forecast-table-grid.component';
import { ExportType } from '@shared/utils/utils';
import { ActivityQuery } from './state/activity/activity.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { QuickForecastModalComponent } from './components/quick-forecast-modal/quick-forecast-modal.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { ForecastTableGridDataInterface } from './components/forecast-table/models/forecast-table-grid.model';
import { groupBy } from 'lodash-es';
import { ForecastSettingsQuery } from './state/settings/forecast-settings.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-forecast',
  templateUrl: './forecast.component.html',
  styles: [
    `
      ::ng-deep .ng-select.ng-select-disabled .ng-arrow-wrapper {
        display: none;
      }

      ::ng-deep .ng-select.ng-select-disabled > .ng-select-container {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .ng-select.ng-select-disabled .ng-value {
        color: var(--aux-gray-dark-100);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild(ForecastTableGridComponent) forecastTableGridComponent!: ForecastTableGridComponent;

  hasUnforecastedCustomDrivers = computed(() => {
    const settings = this.forecastSettingsQuery.settings() || [];

    const unforecastedCustomDrivers = settings.filter(
      (setting) => setting.driver === DriverType.DRIVER_CUSTOM && !setting.driver_setting_id
    );

    return !!unforecastedCustomDrivers.length;
  });

  saveChangesTooltip = computed(() => {
    if (this.hasUnforecastedCustomDrivers()) {
      return 'Select a Custom Curve in Method dropdown to Save Changes.';
    }

    return '';
  });

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY;

  patientDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`;

  siteDriversLink = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`;

  isPatientDriverAvailable = false;

  isSiteDriverAvailable = false;

  isPatientDriversLinkVisible = false;

  isSiteDriversLinkVisible = false;

  discountType$ = new BehaviorSubject('DISCOUNT_PERCENTAGE');

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  saveCheck$ = new BehaviorSubject(false);

  hideDiscounts$ = new BehaviorSubject(true);

  successForecast$ = new BehaviorSubject(true);

  changeDiscountTotalAmount = new UntypedFormControl(0);

  isAdminUser = false;

  selectedVendor = new UntypedFormControl('');

  orgCurrency = Currency.USD;

  userHasLockForecastMethodologyPermission = false;

  categoryTypes: string[] = [];

  isQuickForecastProcessing = this.eventQuery.selectProcessingEvent(
    EventType.BUDGET_FORECAST_SETTINGS_UPDATED
  );

  lockForecastMethodologyTooltip = computed(() => {
    const isFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName)();

    return isFinalized ? MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : '';
  });

  hasModifyForecastMethodologyPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_FORECAST_METHODOLOGY],
  });

  noModifyForecastMethodologyPermissionTooltip = computed(() => {
    return this.hasModifyForecastMethodologyPermission()
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  });

  constructor(
    private route: ActivatedRoute,
    public organizationQuery: OrganizationQuery,
    public activityQuery: ActivityQuery,
    public categoryQuery: CategoryQuery,
    private overlayService: OverlayService,
    private categoryService: CategoryService,
    public categoryStore: CategoryStore,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private forecastSettingsStore: ForecastSettingsStore,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private patientGroupsService: PatientGroupsService,
    private patientCurveService: PatientCurveService,
    private patientCurveQuery: PatientCurveQuery,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    private eventService: EventService,
    private siteCurveQuery: SiteCurveQuery,
    private gqlService: GqlService,
    private authService: AuthService,
    private router: Router,
    private eventQuery: EventQuery,
    private fb: FormBuilder,
    private forecastSettingsQuery: ForecastSettingsQuery
  ) {
    this.setUserPermissions();

    effect(() => {
      const isProcessing = this.isQuickForecastProcessing();

      // fetch new categories after event finished
      if (isProcessing === false) {
        firstValueFrom(this.categoryService.getCategories());
      }
    });
  }

  isForecastFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType$(this.workflowName);

  changeDiscountTotalPercent = new UntypedFormControl({
    value: 0,
    disabled: this.isForecastFinalized$,
  });

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  showAnalyticsSection$: Observable<boolean> = this.launchDarklyService.select$(
    (flags) => flags.section_forecast_analytics
  );

  isSelectedVendorHasManualForecast$ = this.organizationQuery.selectActive().pipe(
    map((org) => {
      this.orgCurrency = org?.currency || Currency.USD;
      return !!this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.manual_forecast;
    })
  );

  topLevelActivities$ = this.activityQuery.selectAll({
    filterBy: (a) => {
      return !a.category_id;
    },
  });

  ngOnInit(): void {
    this.patientGroupsService.get().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.patientCurveService.get().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.categoryService.getCategories().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.authQuery.adminUser$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isAdminUser) => {
      this.isAdminUser = isAdminUser;
    });

    this.initVendorFilter().subscribe(([{ vendor_id }]) => {
      this.processVendorFilter(vendor_id);
    });

    this.initForecastChanges().subscribe(([isFinalized, iCloseMonthsProcessing]) => {
      this.processForecastChanges(isFinalized, iCloseMonthsProcessing);
    });

    this.initTrialKey().subscribe();

    this.initTrialChanges().subscribe(() => {
      this.processTrialChanges();
    });

    let selectedVendorId = localStorage.getItem('forecastVendor');

    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params.vendor) {
        selectedVendorId = params.vendor;
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });

    if (selectedVendorId) {
      this.setVendorId(selectedVendorId);
    }
  }

  async canDeactivate(modalData?: { text: string; goNextText: string }): Promise<boolean> {
    if (this.saveCheck$.getValue()) {
      const result = this.overlayService.openUnsavedChangesConfirmation(modalData ?? undefined);
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  beforeSelectVendor = () => {
    return this.canDeactivate();
  };

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
    this.saveCheck$.next(false);
    this.forecastTableGridComponent.initialSettings = {};
    this.forecastTableGridComponent.ToggleService.reset();
    this.processVendorFilter(orgId);
  }

  onHideActivitiesWithNoRemainingCost(bool: boolean) {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(bool);
  }

  onShowOnlyUnforecasted(bool: boolean) {
    this.categoryStore.hideForecastedActivities$.next(bool);
  }

  clearFilters() {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(false);
    this.categoryStore.hideForecastedActivities$.next(false);
  }

  onGridValueChange(value: ForecastTableGridDataInterface[]) {
    const groupedValues = groupBy(value, 'costCategoryType');

    this.categoryTypes = Object.keys(groupedValues);
  }

  setVendorId(vendorId: string) {
    this.selectedVendor.setValue(vendorId);
    this.organizationStore.setActive(vendorId);
    this.setDiscountType(vendorId);
    localStorage.setItem(`forecastVendor`, vendorId);
  }

  async setDiscountType(vendorId: string) {
    const primary = await firstValueFrom(
      this.gqlService.listBudgetVersions$([BudgetType.BUDGET_PRIMARY], vendorId)
    );

    this.discountType$.next((primary.data || [])?.[0]?.discount_type || '');
  }

  initVendorFilter() {
    return combineLatest([this.route.queryParams, this.organizationService.get()]).pipe(
      takeUntilDestroyed(this.destroyRef)
    );
  }

  initForecastChanges() {
    return combineLatest([this.isForecastFinalized$, this.iCloseMonthsProcessing$]).pipe(
      takeUntilDestroyed(this.destroyRef)
    );
  }

  initTrialKey() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.hideDiscounts$.next(true);
        this.forecastSettingsStore.ui.update(() => ({ showError: false }));
        return combineLatest([
          this.patientCurveQuery.selectAll(),
          this.siteCurveQuery.selectAll(),
        ]).pipe(
          tap(([patientCurveResults, siteCurveResults]) => {
            this.isPatientDriverAvailable = !!patientCurveResults?.length;
            this.isSiteDriverAvailable = !!siteCurveResults?.length;
          })
        );
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  initTrialChanges() {
    return combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ]).pipe(takeUntilDestroyed(this.destroyRef));
  }

  processVendorFilter(vendorId: string): void {
    const orgId = this.organizationQuery.getActive()?.id;

    if (orgId) {
      this.setVendorId(orgId);
    } else {
      const vendors = this.organizationQuery.getAllVendors();

      if (vendors.length) {
        const vendorFromQueryUrl = vendors.find(({ id }) => id === vendorId);

        this.setVendorId(vendorFromQueryUrl?.id || vendors[0].id);
      }
    }
  }

  processForecastChanges(isFinalized: boolean, iCloseMonthsProcessing: boolean | null): void {
    if (isFinalized || iCloseMonthsProcessing) {
      this.changeDiscountTotalPercent.disable();
    } else {
      this.changeDiscountTotalPercent.enable();
    }
  }

  processTrialChanges(): void {
    this.saveCheck$.next(false);
    this.forecastTableGridComponent.initialSettings = {};
    this.forecastTableGridComponent.ToggleService.reset();
  }

  onSaveCheck(newValue: boolean): void {
    this.saveCheck$.next(newValue);
  }

  onIsPatientDriversLinkVisible(newValue: boolean): void {
    this.isPatientDriversLinkVisible = newValue;
  }

  onIsSiteDriversLinkVisible(newValue: boolean): void {
    this.isSiteDriversLinkVisible = newValue;
  }

  onHideDiscounts(newValue: boolean): void {
    this.hideDiscounts$.next(newValue);
  }

  onSuccessForecast(newValue: boolean): void {
    this.successForecast$.next(newValue);
  }

  onChangeDiscountTotalAmount(newValue: number): void {
    this.changeDiscountTotalAmount.setValue(newValue);
  }

  onChangeDiscountTotalPercent(newValue: number): void {
    this.changeDiscountTotalPercent.setValue(newValue);
  }

  onChangeDiscountTotalAmountEnable(enable: boolean): void {
    if (enable) {
      this.changeDiscountTotalAmount.enable();
    } else {
      this.changeDiscountTotalAmount.disable();
    }
  }

  onChangeDiscountTotalPercentEnable(enable: boolean): void {
    if (enable) {
      this.changeDiscountTotalPercent.enable();
    } else {
      this.changeDiscountTotalPercent.disable();
    }
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  navigateToBudgetForecast() {
    this.router.navigate([ROUTING_PATH.BUDGET.INDEX, ROUTING_PATH.BUDGET.INDEX], {
      queryParams: { scrollTo: 'forecast', vendorId: this.selectedVendor.value },
    });
  }

  async openQuickForecastModal() {
    const canDeactivate = await this.canDeactivate({
      text: 'Are you sure that you want to discard these changes?',
      goNextText: 'Discard Changes',
    });

    if (!canDeactivate) {
      return;
    }

    this.forecastTableGridComponent.onDiscardChanges();

    this.overlayService.openPopup({
      modal: QuickForecastModalComponent,
      data: {
        vendorId: this.selectedVendor.value,
        categoryTypes: this.categoryTypes,
        customCurves: this.forecastTableGridComponent.customCurves(),
      },
      settings: {
        fullWidth: true,
        preventAutoCloseModal: true,
        header: 'Apply Drivers in Bulk',
      },
    });
  }

  async onDownloadForecastSettings() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.FORECAST_SETTINGS,
          filename: `${trialName}_Forecast Methodology_${dateStr}`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }

    this.btnLoading$.next(false);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_FORECAST_METHODOLOGY],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasLockForecastMethodologyPermission]) => {
        this.userHasLockForecastMethodologyPermission = userHasLockForecastMethodologyPermission;
      });
  }
}
