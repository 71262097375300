import { Injectable } from '@angular/core';
import { CompareGridData } from '../compare-budget.component';

@Injectable()
export class CompareBudgetService {
  constructor() {}

  getCompareDiffs = (
    arr: CompareGridData[]
  ): {
    from_total_cost: number;
    to_total_cost: number;
    variance_total_cost: number;
    variance_total_cost_percentage: number;
    delta_services: number;
    delta_pass_through: number;
    delta_investigator: number;
    delta_services_positive: number;
    delta_pass_through_positive: number;
    delta_investigator_positive: number;
    delta_services_negative: number;
    delta_pass_through_negative: number;
    delta_investigator_negative: number;
    max_diff_activity: string;
    max_activity_diff: number;
    category_amount_differences: { [key: string]: number };
    category_activity_amount_differences: {
      services_positive: { [key: string]: number };
      services_negative: { [key: string]: number };
      pass_through_positive: { [key: string]: number };
      pass_through_negative: { [key: string]: number };
      investigator_positive: { [key: string]: number };
      investigator_negative: { [key: string]: number };
    };
    count_activities_with_increased_units: number;
    count_activities_with_increased_cost: number;
  } => {
    const diffs = {
      from_total_cost: 0,
      to_total_cost: 0,
      variance_total_cost: 0,
      variance_total_cost_percentage: 0,
      delta_services: 0,
      delta_pass_through: 0,
      delta_investigator: 0,
      delta_services_positive: 0,
      delta_pass_through_positive: 0,
      delta_investigator_positive: 0,
      delta_services_negative: 0,
      delta_pass_through_negative: 0,
      delta_investigator_negative: 0,
      max_diff_activity: '',
      max_activity_diff: 0,
      category_amount_differences: {} as { [key: string]: number },
      category_activity_amount_differences: {
        services_positive: {},
        services_negative: {},
        pass_through_positive: {},
        pass_through_negative: {},
        investigator_positive: {},
        investigator_negative: {},
      } as {
        services_positive: { [key: string]: number };
        services_negative: { [key: string]: number };
        pass_through_positive: { [key: string]: number };
        pass_through_negative: { [key: string]: number };
        investigator_positive: { [key: string]: number };
        investigator_negative: { [key: string]: number };
      },
      count_activities_with_increased_units: 0,
      count_activities_with_increased_cost: 0,
    };

    for (const compareItem of arr) {
      diffs.from_total_cost += compareItem.from_total_cost;
      diffs.to_total_cost += compareItem.to_total_cost;
      diffs.variance_total_cost += compareItem.variance_total_cost;
      diffs.count_activities_with_increased_units += compareItem.variance_unit > 0 ? 1 : 0;
      diffs.count_activities_with_increased_cost += compareItem.variance_total_cost > 0 ? 1 : 0;

      if (compareItem.group0 && compareItem.variance_total_cost > 0) {
        if (!diffs.category_amount_differences[compareItem.group0]) {
          diffs.category_amount_differences[compareItem.group0] = 0;
        }
        diffs.category_amount_differences[compareItem.group0] += compareItem.variance_total_cost;
      }

      if (Math.abs(compareItem.variance_total_cost) > Math.abs(diffs.max_activity_diff)) {
        diffs.max_activity_diff = compareItem.variance_total_cost;
        diffs.max_diff_activity = compareItem.activity_name || '';
      }

      if (compareItem.cost_category === 'Services') {
        diffs.delta_services += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_services_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (!diffs.category_activity_amount_differences.services_positive[compareItem.group0]) {
              diffs.category_activity_amount_differences.services_positive[compareItem.group0] = 0;
            }
            diffs.category_activity_amount_differences.services_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_services_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (!diffs.category_activity_amount_differences.services_negative[compareItem.group0]) {
              diffs.category_activity_amount_differences.services_negative[compareItem.group0] = 0;
            }
            diffs.category_activity_amount_differences.services_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      } else if (compareItem.cost_category === 'Investigator') {
        diffs.delta_investigator += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_investigator_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.investigator_positive[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.investigator_positive[compareItem.group0] =
                0;
            }
            diffs.category_activity_amount_differences.investigator_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_investigator_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.investigator_negative[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.investigator_negative[compareItem.group0] =
                0;
            }
            diffs.category_activity_amount_differences.investigator_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      } else if (compareItem.cost_category === 'Pass-through') {
        diffs.delta_pass_through += compareItem.variance_total_cost;
        if (compareItem.variance_total_cost > 0) {
          diffs.delta_pass_through_positive += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.pass_through_positive[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.pass_through_positive[compareItem.group0] =
                0;
            }
            diffs.category_activity_amount_differences.pass_through_positive[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        } else {
          diffs.delta_pass_through_negative += compareItem.variance_total_cost;
          if (compareItem.group0) {
            if (
              !diffs.category_activity_amount_differences.pass_through_negative[compareItem.group0]
            ) {
              diffs.category_activity_amount_differences.pass_through_negative[compareItem.group0] =
                0;
            }
            diffs.category_activity_amount_differences.pass_through_negative[compareItem.group0] +=
              compareItem.variance_total_cost;
          }
        }
      }
    }

    diffs.variance_total_cost_percentage = diffs.variance_total_cost / (diffs.from_total_cost || 1);

    return diffs;
  };
}
