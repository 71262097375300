import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DistributionTimelineService } from '../services/distribution-timeline.service';
import { BehaviorSubject } from 'rxjs';
import { IconComponent } from '@shared/components/icon/icon.component';
import { NgIf } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { BadgeComponent } from '@shared/components/badge/badge.component';
import dayjs from 'dayjs';

export interface AgMonthCellComponentParams extends ICellRendererParams {
  timeline$: BehaviorSubject<{ startDate?: string; endDate?: string }>;
  noMonthInTimelineTooltipMessage: string;
  currentOpenMonth$?: BehaviorSubject<string>;
}

@Component({
  template: `<div class="flex items-center">
    <aux-icon
      *ngIf="isMonthNoLongerInTimeline"
      name="AlertTriangle"
      class="text-aux-red-dark mr-2"
      [auxTooltip]="params.noMonthInTimelineTooltipMessage"
    />
    <span>{{ value }}</span>
    @if (shouldDisplayOpenMonthBadge) {
      <aux-badge title="OPEN MONTH" variant="blue" />
    }
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TooltipDirective, IconComponent, BadgeComponent],
})
export class AgMonthCellComponent implements ICellRendererAngularComp {
  params!: AgMonthCellComponentParams;

  value!: string;

  cellClass!: string;

  isMonthNoLongerInTimeline = false;

  agInit(params: AgMonthCellComponentParams) {
    this.params = params;

    const cellValue = this.params.valueFormatted || this.params.value;
    this.value = cellValue;

    this.isMonthNoLongerInTimeline = DistributionTimelineService.isMonthNoLongerInTimeline(
      params,
      this.params.value,
      this.params.timeline$
    );
  }

  refresh() {
    return false;
  }

  get shouldDisplayOpenMonthBadge(): boolean {
    if (!this.params?.currentOpenMonth$?.value) {
      return false;
    }

    return (
      dayjs(this.params.currentOpenMonth$.value).format('YYYY-MM') ===
      dayjs(this.params.value).format('YYYY-MM')
    );
  }
}
