import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  CreatePaymentMilestoneInput,
  GqlService,
  UpdatePaymentMilestoneInput,
} from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { OverlayService } from '@shared/services/overlay.service';
import { PaymentMilestoneStore } from './payment-milestone.store';
import { batchPromises } from '@shared/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentMilestoneService {
  constructor(
    private paymentMilestoneStore: PaymentMilestoneStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.paymentMilestoneStore.setLoading(true);
        this.paymentMilestoneStore.remove(() => true);

        return this.gqlService.listPaymentMilestones$().pipe(
          map(({ errors, success, data }) => {
            if (success && data) {
              this.paymentMilestoneStore.set(data);
            } else {
              this.overlayService.error(errors);
            }

            this.paymentMilestoneStore.setLoading(false);
          })
        );
      })
    );
  }

  async upsert(upsertData: (CreatePaymentMilestoneInput & { id: string | null })[]) {
    const proms: Promise<{ success: boolean; errors: string[] }>[] = [];
    const allErrors: string[][] = [];

    upsertData.forEach((mil) => {
      const { id, amount, name, organization_id, target_date } = mil;
      if (id) {
        const prom = firstValueFrom(
          this.gqlService
            .updatePaymentMilestone$({ id, amount, name, organization_id, target_date })
            .pipe(
              tap(({ success, data, errors }) => {
                if (success && data) {
                  this.paymentMilestoneStore.update(data.id, data);
                } else {
                  allErrors.push(errors);
                }
              })
            )
        );
        proms.push(prom);
      } else {
        const prom = firstValueFrom(
          this.gqlService
            .createPaymentMilestone$({ amount, name, organization_id, target_date })
            .pipe(
              tap(({ success, data, errors }) => {
                if (success && data) {
                  this.paymentMilestoneStore.add(data);
                } else {
                  allErrors.push(errors);
                }
              })
            )
        );
        proms.push(prom);
      }
    });

    await batchPromises(proms, (p) => p);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }

    return !!allErrors.length;
  }

  async add(paymentMilestone: CreatePaymentMilestoneInput) {
    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createPaymentMilestone$(paymentMilestone)
    );
    if (success && data) {
      this.paymentMilestoneStore.add(data);
    } else {
      this.overlayService.error(errors);
    }

    return {
      success,
      errors,
      data,
    };
  }

  async update(paymentMilestone: UpdatePaymentMilestoneInput) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updatePaymentMilestone$(paymentMilestone)
    );
    if (success && data) {
      this.paymentMilestoneStore.update(data.id, data);
    } else {
      this.overlayService.error(errors);
    }

    return {
      success,
      errors,
      data,
    };
  }

  async remove(ids: string[]) {
    const proms: Promise<unknown>[] = [];
    const allErrors: string[][] = [];
    ids.forEach((id) => {
      proms.push(
        firstValueFrom(
          this.gqlService.removePaymentMilestone$(id).pipe(
            tap(({ success, errors, data }) => {
              if (success && data) {
                this.paymentMilestoneStore.remove(id);
              } else {
                allErrors.push(errors);
              }
            })
          )
        )
      );
    });

    await batchPromises(proms, (p) => p);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }

    return !!allErrors.length;
  }
}
