<div class="flex items-center justify-between w-full text-base">
  <div class="flex items-center">
    <div *ngIf="params.month">
      {{ params.month.iso | date: 'MMMM y' }}
      {{ params.mode === 'future' ? '' : params.mode === 'open' ? '(Open)' : '(Closed)' }}
    </div>
    <div *ngIf="params.headerName && !params.month">
      {{ params.headerName }}
    </div>

    <button
      class="flex items-center no-underline aux-link ml-1"
      [ngClass]="{
        'text-aux-black': params.mode !== 'open',
        'text-white': params.mode === 'open'
      }"
      (click)="onShowClick()"
    >
      <aux-icon [name]="(showChild$ | async) ? 'ChevronLeft' : 'ChevronRight'" />
    </button>
  </div>

  <ng-container
    *ngVar="{
      isMonthCloseAvailable: (params.isMonthCloseAvailable$ | async)!,
      isWorkflowAvailable: (params.isWorkflowAvailable$ | async)!,
      isQuarterCloseEnabled: (params.isQuarterCloseEnabled$ | async)!,
      userHasClosingPermission: (params.userHasClosingPermission$ | async)!,
      processing: (params.processing$ | async)!
    } as obj"
  >
    <div
      [auxTooltip]="
        !obj.processing
          ? obj.isQuarterCloseEnabled &&
            closeQuarterService.getCloseQuarterTooltip(
              obj.isMonthCloseAvailable,
              obj.isWorkflowAvailable,
              obj.userHasClosingPermission
            )
          : ''
      "
    >
      <button
        *ngIf="params.mode === 'open'"
        class="btn btn--success text-sm font-normal"
        [disabled]="!obj.isMonthCloseAvailable || obj.processing || !obj.userHasClosingPermission"
        (click)="params.monthButtonClick()"
      >
        <aux-icon name="Check" class="mr-1" />
        <span>Close Month</span>
      </button>
    </div>
  </ng-container>
</div>
