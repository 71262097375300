import { Component, inject } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputComponent } from '@shared/components/input/input.component';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { NgStyle } from '@angular/common';

export interface ConfirmationActionModalData {
  executeActionText?: string;
  skipKeywordConfirmation?: boolean;
  hideConfirmationHint?: boolean;
  keywordToExecuteAction?: string;
  message?: string;
  width?: string;
}

@Component({
  standalone: true,
  template: `
    <aux-modal
      [primaryButtonDisabled]="confirmationFc.invalid && !ref.data?.skipKeywordConfirmation"
    >
      <form
        class="w-[520px]"
        [ngStyle]="{ width: ref.data?.width }"
        (keydown.enter)="$event.preventDefault()"
      >
        <div [innerHTML]="ref.data?.message"></div>

        @if (!ref.data?.hideConfirmationHint) {
          <p class="mt-6 italic font-medium" [class.mb-6]="!!ref.data?.skipKeywordConfirmation">
            @if (ref.data?.executeActionText) {
              {{ ref.data?.executeActionText }}
            } @else {
              To prevent accidental changes, please type “{{ ref.data?.keywordToExecuteAction }}” in
              the field below to confirm.
            }
          </p>
        }

        @if (!ref.data?.skipKeywordConfirmation) {
          <aux-input
            [label]="ref.data?.keywordToExecuteAction ?? ''"
            [placeholder]="ref.data?.keywordToExecuteAction ?? ''"
            class="text-xs mt-4"
            validators="required"
            [formControl]="confirmationFc"
          />
        }
      </form>
    </aux-modal>
  `,
  imports: [ModalComponent, InputComponent, ReactiveFormsModule, NgStyle],
})
export class ConfirmationActionModalComponent {
  ref = inject(CustomOverlayRef<undefined, ConfirmationActionModalData>);

  confirmationFc = new FormControl(null, [
    Validators.required,
    CustomValidators.mustEqualWith(this.ref.data?.keywordToExecuteAction ?? ''),
  ]);
}
