import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlag } from '@models/feature-flag.model';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { BasicRedirectToNextTabGuard } from '@shared/guards/basic-redirect-to-next-tab.guard';
import { PERIOD_CLOSE_ROUTES_FEATURE_FLAGS } from '../quarter-close-adjustments/quarter-close-adjustments.guard';

@Injectable({
  providedIn: 'root',
})
export class QuarterCloseGuard extends BasicRedirectToNextTabGuard {
  protected prefix = `/${ROUTING_PATH.CLOSING.INDEX}`;

  protected featureFlag = FeatureFlag.MONTH_AND_QUARTER_CLOSE as keyof Flags;

  protected defaultNavigateUrl = `${this.prefix}/${ROUTING_PATH.CLOSING.CHECKLIST}`;

  protected routerPathsAndFeatureFlags = PERIOD_CLOSE_ROUTES_FEATURE_FLAGS;

  constructor(launchDarklyService: LaunchDarklyService, router: Router) {
    super(launchDarklyService, router);
  }
}
