<aux-extendable-options-dropdown
  class="attributes-dropdown"
  [items]="items()"
  [dropdownControl]="attributeControl"
  [openOnInit]="true"
  [resetValueOnEscapePress]="true"
  [initialSearchTerm]="initialSearchTerm"
  (clickOut)="stopEditing()"
  (confirm)="stopEditing()"
  (addOption)="onAddOption($event)"
  (changed)="$event && stopEditing()"
/>
