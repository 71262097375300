import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { TrialStatusMap } from '@shared/constants/status.constants';
import { TrialImplementationStatus } from '@shared/services/gql.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-close-month-audit-package-confirmation',
  standalone: true,
  imports: [],
  template: `
    @if (showWarning() && auxTrialStatus()) {
      <div class="bg-aux-warn2 p-4">
        <p>
          Monthly Audit Package will not be created because Auxilius Trial Status is set to
          {{ trialStatusText() }}
        </p>
      </div>
    }
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseMonthAuditPackageConfirmationComponent {
  private readonly mainQuery = inject(MainQuery);
  private readonly launchDarklyService = inject(LaunchDarklyService);
  auxTrialStatus = signal<TrialImplementationStatus | undefined>(
    this.mainQuery.getSelectedTrial()?.implementation_status as TrialImplementationStatus
  );
  warningFF = computed(() => {
    return this.launchDarklyService.$select((flags) => flags.month_close_warn_no_package)();
  });
  showWarning = computed(() => {
    const trialStatus = this.auxTrialStatus();
    return (
      trialStatus &&
      this.warningFF() &&
      [
        TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED,
        TrialImplementationStatus.IMPLEMENTATION_STATUS_IMPLEMENTATION,
      ].includes(trialStatus)
    );
  });
  trialStatusText = computed(() => {
    const trialStatus = this.auxTrialStatus();
    return trialStatus ? TrialStatusMap[trialStatus].text : '';
  });
}
