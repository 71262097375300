import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Injector,
  OnInit,
  inject,
} from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { ApiService } from '@shared/services/api.service';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { formatDate } from '@angular/common';
import dayjs from 'dayjs';
import {
  AmountType,
  ApprovalType,
  Currency,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  listQuarterCloseExpensesQuery,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { first, get, now } from 'lodash-es';
import { Utils } from '@shared/utils/utils';
import { TrialsQuery } from '@models/trials/trials.query';
import { TableConstants } from '@shared/constants/table.constants';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { MainQuery } from '@shared/store/main/main.query';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { PeriodCloseComponent, QuarterDate } from '../../period-close.component';
import { AddVendorEstimateUploadComponent } from './add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { AgInMonthGroupHeaderComponent } from './ag-in-month-group-header/ag-in-month-group-header.component';
import { AgQuarterCloseApprovalComponent } from './ag-quarter-close-approval.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { QuarterCloseDialogComponent } from './quarter-close-dialog/quarter-close-dialog.component';
import { QuarterCloseProcessingConfirmationComponent } from './quarter-close-processing-confirmation.component';
import { VendorEstimateUploadComponent } from './vendor-estimate-upload/vendor-estimate-upload.component';
import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnGroupShowType,
  ExcelExportParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IProvidedColumn,
  IRowNode,
  ITooltipParams,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  ProcessGroupHeaderForExportParams,
  ProcessHeaderForExportParams,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgExpandableGroupHeaderComponent } from '../quarter-close-adjustments/ag-expandable-group-header.component';
import {
  AgQuarterCloseAdjustmentComponent,
  AgQuarterCloseAdjustmentParams,
} from './ag-quarter-close-adjustment.component';
import {
  AgQuarterCloseCollapsableHeaderComponent,
  AgQuarterCloseCollapsableHeaderParams,
} from './ag-quarter-close-collapsable-header.component';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { Option } from '@shared/types/components.type';
import { ActivatedRoute, Router } from '@angular/router';

import { QuarterCloseBannerHeight } from './quarter-close-banner/quarter-close-banner.component';
import { AuxExcelStyleKeys, AuxExcelStyles, GetExcelStyle } from '@shared/utils';
import { QuarterCloseAdjustmentsService } from '../quarter-close-adjustments/quarter-close-adjustments.service';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { EventService as GlobalEventService } from '@models/event/event.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { BudgetQuery } from '@models/budget/budget.query';
import {
  getAttributeColumns,
  attributeColumnDef,
} from '@features/budget-attributes/services/budget-attributes.service';
import { fetchInProgressEvents } from '@features/progress-tracker/utils/fetch-in-progress-events';
import { AgQuarterCloseGroupHeaderComponent } from './ag-quarter-close-group-header/ag-quarter-close-group-header.component';
import { AgQuarterCloseGroupHeaderComponentParams } from './ag-quarter-close-group-header/ag-quarter-close-group-header.component';

export type QCGridData = Omit<listQuarterCloseExpensesQuery, 'attributes'> & {
  attributes: Array<{
    __typename: 'BudgetActivityAttributes';
    attribute?: string | null;
    attribute_name?: string | null;
    attribute_index?: number | null;
    attribute_value?: string | null;
  }>;
  activity_name: string;
};

// localstorage key for quarter close attributes
const QCAttributesLSKey = 'quarter_close_activities_header';

@Component({
  selector: 'aux-quarter-close',
  templateUrl: './quarter-close.component.html',
  styleUrls: ['./quarter-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  refreshDownload$ = new Subject();

  showGrid = true;

  showQuarterCloseBanner$ = new BehaviorSubject(false);

  showRequestJournalEntryButton$: Observable<boolean>;

  isNPColumnVisible = this.launchDarklyService.$select((flags) => flags.net_position_columns);
  isNPColumnVisible$ = toObservable(this.isNPColumnVisible);

  isAPColumnVisible = this.launchDarklyService.$select(
    (flags) => flags.advanced_payments_ltd_columns
  );
  isAPColumnVisible$ = toObservable(this.isAPColumnVisible);

  private injector = inject(Injector);

  isDownloadVendorEstimateButtonDisabled$ = this.refreshDownload$.pipe(
    startWith(null),
    switchMap(() => combineLatest([this.budgetQuery.select(), this.mainQuery.select('trialKey')])),
    switchMap(([{ budget_info }, trialKey]) => {
      const first_vendor_current_month = budget_info[0]?.current_month;

      if (!first_vendor_current_month) {
        return of(0);
      }
      const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM-y', 'en-US');

      return from(
        this.apiService.fileCounts(
          `trials/${trialKey}/vendors/vendor-estimate/${formatted_current_month}`
        )
      ).pipe(map((count) => !count));
    })
  );

  userHasUploadVendorEstimatePermission = new BehaviorSubject<boolean>(false);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  isCloseMonthAvailable$ = combineLatest([
    this.workflowQuery.getMonthCloseButtonAvailability$(),
    this.periodCloseComponent.monthClosed$.pipe(map((bool) => !bool)),
  ]).pipe(map((arr) => arr.every((bool) => bool)));

  components = {
    approvalRenderer: AgQuarterCloseApprovalComponent,
  };

  budgetTemplateUploadProgressId = fetchInProgressEvents(
    EventType.VENDOR_ESTIMATE_TEMPLATE_UPLOADED,
    this.destroyRef
  );

  private openMonthFirstColumnId = '';

  postProcessPopup: (params: PostProcessPopupParams) => void = (params: PostProcessPopupParams) => {
    const columnId = params.column ? params.column.getId() : undefined;
    if (columnId === 'account' || columnId === 'dept' || columnId === 'po') {
      const ePopup = params.ePopup;
      let oldTopStr = ePopup.style.top;
      let oldLeftStr = ePopup.style.left;
      // remove 'px' from the string (AG Grid uses px positioning)
      oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'));
      oldLeftStr = oldLeftStr.substring(0, oldLeftStr.indexOf('px'));
      const oldTop = parseInt(oldTopStr);
      const oldLeft = parseInt(oldLeftStr);
      const newTop = oldTop + 35;
      const newLeft = oldLeft + 35;
      ePopup.style.top = newTop + 'px';
      ePopup.style.left = newLeft + 'px';
    }
  };

  currencyFormatter = (params: ValueFormatterParams) => {
    let currency = Currency.USD;
    if (params.data === undefined && params.node) {
      currency = this.organizationQuery.getEntity(params.node.key)?.currency || Currency.USD;
    }
    if (params?.data?.activity_name === 'Total') {
      currency = this.periodCloseComponent.currencies.values().next().value || Currency.USD;
    }
    return Utils.agCurrencyFormatterAccounting(
      params,
      this.currencyMap?.[params.data?.vendor_id] || currency
    );
  };

  getCellClass = () => (params: CellClassParams) => {
    const currency = this.currencyMap?.[params?.node?.data?.vendor_id] || 'NoSymbol';
    return [`budgetCost${currency}`, 'ag-cell-align-right'];
  };

  getCellClassForInvoiceCells = () => (params: CellClassParams) => {
    if (params.node.group || params.node.rowPinned === 'bottom') {
      const currency = this.currencyMap?.[params?.node?.data?.vendor_id] || 'NoSymbol';

      return [
        `budgetCost${currency}`,
        'ag-cell-align-right',
        params.value ? 'aux-link' : 'aux-link-zero-hyphen',
        'cursor-pointer',
      ];
    } else {
      const currency = this.currencyMap?.[params?.node?.data?.vendor_id] || 'NoSymbol';

      return [`budgetCost${currency}`, 'ag-cell-align-right'];
    }
  };

  excelOptions = {
    sheetName: 'Quarter Close',
    fileName: 'auxilius-quarter-close.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    skipPinnedBottom: true,
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_id':
          return 280;
        case 'qmonth1_net_change_in_accruals':
        case 'qmonth2_net_change_in_accruals':
        case 'qmonth3_net_change_in_accruals':
        case 'qend_net_change_in_accruals':
          return 170;
        default:
          return 150;
      }
    },
    processGroupHeaderCallback: (params: ProcessGroupHeaderForExportParams): string => {
      const colGroupDef = params.columnGroup.getColGroupDef();
      if (!colGroupDef) {
        return '';
      }
      const headerName = colGroupDef.headerName || '';
      if (headerName && headerName.match(/\d+-\d+/)) {
        return dayjs(headerName).format('MMMM YYYY');
      }
      return headerName;
    },
    processHeaderCallback: (params: ProcessHeaderForExportParams): string => {
      const headerName = params.column.getColDef().headerName || '';
      if (headerName === 'Activities') {
        return 'Cost Category';
      } else {
        return headerName;
      }
    },
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params.column.getColId() === 'vendor_id') {
        return this.organizationQuery.getEntity(params.value)?.name || '';
      }
      if (params.column.getColId() === 'cost_category') {
        let activity_name = params.value;
        switch (params.value) {
          case AmountType.AMOUNT_SERVICE:
            activity_name = 'Services';
            break;
          case AmountType.AMOUNT_PASSTHROUGH:
            activity_name = 'Pass-through';
            break;
          case AmountType.AMOUNT_INVESTIGATOR:
            activity_name = 'Investigator';
            break;
          default:
            break;
        }

        return activity_name;
      }

      return params.value;
    },
  } as ExcelExportParams;

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS;

  adjustmentWorkflow$ = this.workflowQuery.getWorkflowByStepType$(this.workflowName);

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  isWorkflowAvailable$ = this.workflowQuery.isWorkflowAvailable$;

  userHasClosingPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_APPROVE_IN_MONTH],
  });

  shouldShowBanner$ = combineLatest([
    this.showQuarterCloseBanner$,
    this.periodCloseComponent.iCloseMonthsProcessing$,
  ]).pipe(
    map(([show, isClosing]) => show && !isClosing),
    tap((bool) => {
      this.stickyServiceOffset = bool ? QuarterCloseBannerHeight : 0;
    })
  );

  userHasAdjustPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
  });

  showContractAmountColumn = this.launchDarklyService.$select((flags) => flags.close_budget_total);

  activitiesColumnDef: ColDef = {
    headerName: 'Activities',
    minWidth: 250,
    width: 250,
    maxWidth: 400,
    resizable: true,
    field: 'activity_name',
    tooltipField: 'activity_name',
    showRowGroup: true,
    pinned: 'left',
    tooltipValueGetter: (params: ITooltipParams) => {
      return params.node?.group
        ? this.organizationQuery.getEntity(params.value)?.name
        : params.value;
    },
    ...getWrapperCellOptions(),
    cellRendererParams: {
      suppressCount: true,
    },
    comparator: (_, __, nodeA, nodeB) => {
      if (!nodeA.aggData) {
        return 0;
      }
      return nodeA.aggData.current_lre - nodeB.aggData.current_lre;
    },
  };

  // only needed for Excel export
  isTotalHidden = true;

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      hide: true,
      cellClass: ['cost_category'],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Quarter Start',
      headerClass: [
        'attributes-quarterStart-quarterSummary',
        'ag-header-align-center',
        AuxExcelStyleKeys.ALTERNATE,
        AuxExcelStyleKeys.BORDER_LEFT,
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      headerGroupComponent: AgExpandableGroupHeaderComponent,
      headerGroupComponentParams: {
        collapsedByDefault: true,
        filterCols(column: IProvidedColumn) {
          return !['qstart_accrual_ltd', 'qstart_prepaid_ltd'].includes(column.getId());
        },
        expandableCols: ['qstart_wp_ltd', 'qstart_invoiced_ltd'],
      },
      children: [
        {
          headerName: 'WP LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `qstart_wp_ltd`,
          headerClass: AuxExcelStyleKeys.BORDER_LEFT,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          hide: true,
          cellClass: (p) => [...this.getCellClass()(p), AuxExcelStyleKeys.BORDER_LEFT],
        },
        {
          headerName: 'Invoiced LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          hide: true,
          field: `qstart_invoiced_ltd`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          cellClass: this.getCellClassForInvoiceCells(),
          onCellClicked: (event) => {
            if (event.node.group || event.rowPinned === 'bottom') {
              return this.navigateByInvoices(event);
            }
          },
        },
        {
          headerName: 'Accrual LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `qstart_accrual_ltd`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          cellClass: this.getCellClass(),
        },
        {
          headerName: 'Prepaid LTD',
          maxWidth: 150,
          minWidth: 150,
          resizable: false,
          field: `qstart_prepaid_ltd`,
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          headerClass: AuxExcelStyleKeys.BORDER_RIGHT,
          cellClass: (p) => [...this.getCellClass()(p), AuxExcelStyleKeys.BORDER_RIGHT],
        },
      ],
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    components: {
      agQuarterCloseGroupHeader: AgQuarterCloseGroupHeaderComponent,
    },
    tooltipShowDelay: 500,
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 70,
      cellRenderer: AgCellWrapperComponent,
      cellClassRules: {
        [AuxExcelStyleKeys.BORDER_BOTTOM]: (p) => {
          return (
            !p.node.group && p.node.lastChild && !!p.node.parent?.lastChild && this.isTotalHidden
          );
        },
      },
    },
    getRowStyle: (param: RowClassParams) => {
      if (param.node.rowPinned) {
        return <RowStyle>{ 'font-weight': 'bold' };
      }
      return undefined;
    },
    initialGroupOrderComparator: (params) => {
      const a = this.organizationQuery.getEntity(params.nodeA.key)?.name || 0;
      const b = this.organizationQuery.getEntity(params.nodeB.key)?.name || 0;

      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    groupDefaultExpanded: 0,
    suppressAggFuncInHeader: true,
    suppressRowHoverHighlight: false,
    groupDisplayType: 'custom',
    rowSelection: 'single',
    groupSelectsChildren: false,
    columnDefs: [],
    excelStyles: [
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      ...AuxExcelStyles,
      {
        ...GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        id: 'trial_name',
        borders: {
          borderBottom: {
            color: 'black',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
    ],
  });

  budgetInfo$ = this.organizationQuery.selectActiveId().pipe(
    switchMap((org_id) =>
      this.budgetQuery.select('budget_info').pipe(
        map((x) => {
          return x.find((y) => y.vendor_id === org_id);
        })
      )
    )
  );

  showUnpaidColumns$ = new BehaviorSubject(false);

  stickyServiceOffset = 0;

  gridInitializedOnInit = false;

  bottomRowData$ = new BehaviorSubject<Partial<QCGridData>>({});

  currencyMap?: Record<string, Currency>;

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private overlayService: OverlayService,
    private apiService: ApiService,
    private budgetQuery: BudgetQuery,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private organizationQuery: OrganizationQuery,
    private authService: AuthService,
    private gqlService: GqlService,
    private workflowQuery: WorkflowQuery,
    private launchDarklyService: LaunchDarklyService,
    private documentLibraryService: DocumentLibraryService,
    private invoiceService: InvoiceService,
    private router: Router,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private eventService: EventTrackerService,
    private globalEventService: GlobalEventService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.setUserPermissions();
    this.periodCloseComponent.selectedMonth$.next(null);
    this.periodCloseService.selectedQuarterMonthChanged$.next(null);

    combineLatest([this.mainQuery.select('trialKey'), this.gridAPI$.pipe(take(1), startWith(null))])
      .pipe(
        switchMap(() =>
          this.launchDarklyService.select$((flags) => flags.unpaid_invoices_ltd_column)
        )
      )
      .pipe(takeUntilDestroyed())
      .subscribe((bool) => {
        this.showUnpaidColumns$.next(bool);
        if (this.gridAPI) {
          this.gridAPI.setColumnsVisible(
            [
              'qend_unpaid_invoices_ltd',
              'qmonth1_unpaid_invoices_ltd',
              'qmonth2_unpaid_invoices_ltd',
              'qmonth3_unpaid_invoices_ltd',
            ],
            this.showUnpaidColumns$.value
          );
        }
      });

    this.showRequestJournalEntryButton$ = this.launchDarklyService.select$(
      (flags) => flags.request_journal_entry_report_button
    );

    combineLatest([
      this.periodCloseComponent.newQCGridDataLoading$,
      this.isAPColumnVisible$,
      this.isNPColumnVisible$,
    ]).subscribe(() => {
      this.currencyMap = this.organizationQuery.getAllVendors().reduce(
        (acc, v) => {
          if (v.currency) {
            acc[v.id] = v.currency;
          }
          return acc;
        },
        {} as Record<string, Currency>
      );
      this.updateGridOptions();
      this.gridInitializedOnInit = true;
    });
  }

  ngOnInit() {
    this.invoiceService.initialize().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  onAddVendorEstimateUploadClick = async () => {
    const ref = this.overlayService.openPopup<
      undefined,
      { vendorId?: string },
      AddVendorEstimateUploadComponent
    >({
      settings: {
        header: 'Send Vendor Estimate Support to Auxilius',
        primaryButton: {
          label: 'Send Vendor Estimate Support',
          action: (instance) => instance?.onUpload(),
        },
      },
      content: AddVendorEstimateUploadComponent,
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    this.refreshDownload$.next(null);

    const vendorId = resp.data?.vendorId;

    if (vendorId) {
      this.quarterCloseAdjustmentsService.updateFormControlValues('', vendorId);

      this.periodCloseComponent.selectedQuarter.setValue(this.periodCloseComponent.currentQuarter);

      this.router.navigate([`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`], {
        queryParams: {
          vendorId,
          editMode: true,
          currentOpenMonth: dayjs(this.currentMonth).add(2, 'day').format('YYYY-MM-DD'),
        },
      });
    }
  };

  getDynamicExcelParams = (): ExcelExportParams => {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return {};
    }
    const currency = this.periodCloseComponent.currencies.values().next().value;

    const unpaid_invoices_flag = this.showUnpaidColumns$.getValue();

    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    let columnKeys = this.getDashboardIDs().filter((col) =>
      col.endsWith('_unpaid_invoices_ltd') ? unpaid_invoices_flag : true
    );

    const attributes =
      this.gridAPI
        .getColumns()
        ?.map((x) => [x.getColId(), x] as [string, Column])
        ?.filter(([x]: [string, Column]) => {
          return x.startsWith('custom_attr_') || ['account', 'po', 'dept'].includes(x);
        })
        ?.map(([x, c]) => {
          return [c.isVisible(), x] as [boolean, string];
        }) || [];

    const shownAttributes = attributes.filter(([bool]) => bool).length;

    const contractAmountColumnField = 'qmonth1_total_expense_quote';

    const isCAColumnVisible =
      this.gridAPI.getColumn(contractAmountColumnField)?.isVisible() || false;

    const mergeAcrossValue = isCAColumnVisible || shownAttributes === 0 ? 1 : attributes.length + 1;

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: { value: `Total`, type: 'String' },
            mergeAcross: mergeAcrossValue,
            styleId: ['total_row_header', AuxExcelStyleKeys.BORDER_BOTTOM],
          },
        ],
      },
    ];

    const pushTotalValue = (field: string, styles = [] as string[]) => {
      appendContent[0].cells.push({
        data: { value: get(totals, field) || 0, type: 'Number' },
        styleId: [`total_row_${currency}`, AuxExcelStyleKeys.BORDER_BOTTOM, ...styles],
      });
    };

    if (isCAColumnVisible) {
      pushTotalValue(contractAmountColumnField);
      if (shownAttributes) {
        appendContent[0].cells.push({
          data: { value: ``, type: 'String' },
          mergeAcross: shownAttributes - 1,
          styleId: ['total_row_header', AuxExcelStyleKeys.BORDER_BOTTOM],
        });
      }
    }

    if (totals) {
      const quarter_start_columns = [
        'qstart_wp_ltd',
        'qstart_invoiced_ltd',
        'qstart_accrual_ltd',
        'qstart_prepaid_ltd',
      ];
      for (let i = 0; i < quarter_start_columns.length; i++) {
        const column = quarter_start_columns[i];

        appendContent[0].cells.push({
          data: { value: !Number.isNaN(totals[column]) ? totals[column] : 0, type: 'Number' },
          styleId: [
            AuxExcelStyleKeys.BORDER_BOTTOM,
            `total_row_${currency}`,
            i === 0
              ? AuxExcelStyleKeys.BORDER_LEFT
              : i === quarter_start_columns.length - 1
                ? AuxExcelStyleKeys.BORDER_RIGHT
                : '',
          ].filter((z) => z),
        });
      }
    }

    const columnsForMonth = [
      '_monthly_wp_estimate',
      '_monthly_invoices_received',
      '_net_change_in_accruals',
      '_accrual_debit',
      '_accrual_credit',
      '_prepaid_debit',
      '_prepaid_credit',
      '_wp_ltd',
      '_invoiced_ltd',
      '_accrual_ltd',
      '_prepaid_ltd',
    ];

    if (unpaid_invoices_flag) {
      columnsForMonth.push('_unpaid_invoices_ltd');
    }
    if (this.isAPColumnVisible()) {
      columnsForMonth.push('_advanced_payments_ltd');
    }
    if (this.isNPColumnVisible()) {
      columnsForMonth.push('_net_position');
    }

    this.periodCloseComponent.quartersObj[
      this.periodCloseComponent.selectedQuarter.value || ''
    ].forEach((month, monthIndex) => {
      columnsForMonth.forEach((field, colIndex) => {
        pushTotalValue(
          `qmonth${monthIndex + 1}${field}`,
          monthIndex === 1
            ? [
                colIndex === 0
                  ? AuxExcelStyleKeys.BORDER_LEFT
                  : colIndex === columnsForMonth.length - 1
                    ? AuxExcelStyleKeys.BORDER_RIGHT
                    : '',
              ]
            : []
        );
      });
    });

    const summary_columns = [
      'qend_quarterly_wp_estimate',
      'qend_monthly_invoices_received',
      'qend_net_change_in_accruals',
      `qend_wp_ltd`,
      `qend_invoiced_ltd`,
      'qend_accrual_ltd',
      'qend_prepaid_ltd',
    ];

    if (unpaid_invoices_flag) {
      summary_columns.push('qend_unpaid_invoices_ltd');
    }

    for (let i = 0; i < summary_columns.length; i++) {
      const column = summary_columns[i];
      const styles = [
        i === 0
          ? AuxExcelStyleKeys.BORDER_LEFT
          : i === summary_columns.length - 1
            ? AuxExcelStyleKeys.BORDER_RIGHT
            : '',
      ];
      pushTotalValue(column, styles);
    }

    const selectQ = this.periodCloseComponent.selectedQuarter.value || '';

    columnKeys = this.getColumnKeysForExport(
      columnKeys,
      isCAColumnVisible || false,
      shownAttributes,
      attributes
    );

    return <ExcelExportParams>{
      ...this.excelOptions,
      fileName: `${trial.short_name}_${selectQ}.xlsx`,
      columnKeys,
      appendContent: totals ? appendContent : [],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: columnKeys.length - 1,
              styleId: 'trial_name',
            },
          ],
        },
      ],
    };
  };

  getColumnKeysForExport(
    columnKeys: string[],
    isContractAmountVisible: boolean,
    shownAttributes: number,
    attributes: [boolean, string][]
  ) {
    const vendorColumnsNumber = isContractAmountVisible ? 3 : 2;
    const vendorColumns = [...columnKeys.slice(0, vendorColumnsNumber)];
    const attributesColumns = shownAttributes ? [...columnKeys.slice(3, shownAttributes + 3)] : [];
    const remainingColumns = [...columnKeys.slice(4 + attributes.length, columnKeys.length)];
    return [...vendorColumns, ...attributesColumns, ...remainingColumns];
  }

  async onDownloadVendorEstimates() {
    const { trialKey } = this.mainQuery.getValue();

    const currentMonth = this.getCurrentMonthToClose();

    if (!currentMonth) {
      this.overlayService.error('Something went wrong. Please try again later.');
      return;
    }

    const { success, data } = await this.apiService.getS3ZipFile(
      `trials/${trialKey}/vendors/vendor-estimate/${currentMonth.formattedDate}`
    );
    if (success && data) {
      // [insert trial name]_[insert vendor name]_Change Order [insert Change Order #]_[insert date created YYYY.MM.DD]
      const fileName = `vendor-estimate`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
  }

  async onRequestJournalEntryReport() {
    const currentMonth = this.getCurrentMonthToClose();

    if (!currentMonth) {
      this.overlayService.error('Something went wrong. Please try again later.');
      return;
    }

    const result = await firstValueFrom(
      this.globalEventService.processEvent$({
        type: EventType.REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION,
        entity_type: EntityType.DOCUMENT,
        entity_id: '',
        payload: JSON.stringify({
          current_open_month: currentMonth.formattedDate.replace('-', ' '),
        }),
      })
    );
    if (result.success) {
      this.overlayService.success('Request for Journal Entry Report successfully processed');
    } else {
      this.overlayService.error('Error Requesting Journal Entry Report');
    }
  }

  async exportExcelToS3() {
    const currentMonth = this.getCurrentMonthToClose();

    if (!currentMonth) {
      this.overlayService.error('Something went wrong. Please try again later.');
      return;
    }

    const additionalExcelOption = this.getDynamicExcelParams();
    const defaultExcelOptions: ExcelExportParams = {
      author: 'Auxilius',
      fontSize: 11,
      columnWidth: 105,
    };

    const exportedData = this.gridAPI?.getDataAsExcel({
      ...defaultExcelOptions,
      columnKeys: this.getDashboardIDs(),
      ...this.excelOptions,
      ...additionalExcelOption,
    });

    //formatted per req's for month close reports (the only place used)
    const fileFormattedDate = currentMonth.date.format('MMM-YYYY');

    const { trialKey } = this.mainQuery.getValue();
    const fileName = `${fileFormattedDate}_Accrual_Summary.xlsx`;
    const key = `trials/${trialKey}/period-close/${fileName}`;

    const user = await this.authService.getLoggedInUser();

    if (exportedData) {
      const fileToUpload = new File([exportedData], fileName);
      await this.apiService.uploadFile(key, fileToUpload, {
        uploadedBy: user?.getSub() || '',
      });

      await this.documentLibraryService.uploadDocument(
        {
          id: Utils.uuid(),
          uploaded: false,
          eTag: Utils.uuid(),
          bucket_key: key,
          fileName: fileName,
          lastModified: now(),
          size: fileToUpload.size,
          type: 'csv',
        },
        {
          documentType: DocumentType.DOCUMENT_MONTHLY_AUDIT_PACKAGE,
          target_date: currentMonth.date.format('YYYY-MM-DD'),
        },
        key,
        false,
        true
      );
    }
  }

  navigateByInvoices(event: CellClickedEvent, unpaidInvoices = false) {
    const navigateParams: { vendor_id: string; acc_months: Option[] } = {
      vendor_id: '',
      acc_months: [],
    };

    const columnId = event.column.getColId();
    const isTotalRow = event.rowPinned === 'bottom';

    const inQStart = columnId.includes('start');
    const inQSummary = columnId.includes('end') || columnId.includes('total');
    const inQMonth = !inQStart && !inQSummary;

    const vendorId = event.data ? event.data.vendor_id : event.node.key;

    const all_options = this.invoiceService.accrualPeriodOptions;
    const [qtr, year] = this.periodCloseComponent.selectedQuarter.value?.split('-') || [];

    if (!all_options || !qtr || !year) {
      return;
    }

    const selectedQtrDate = new Date(+year, +qtr.slice(1) * 3 - 3, 1);

    navigateParams.vendor_id = isTotalRow ? '' : vendorId;

    if (inQMonth) {
      const isInvoiceReceived = columnId.includes('received');
      const isInvoiceLtd = columnId.includes('ltd');
      const isInvoiceUnpaid = columnId.includes('unpaid');
      const headerName = event.column.getOriginalParent()?.getColGroupDef()?.headerName;
      if (headerName) {
        const [colYear, colMonth] = headerName.split('-') || [];
        if (isInvoiceReceived) {
          const acc_option = this.getAccrualPeriodOptionsForMonths(
            colMonth,
            colYear,
            all_options
          ).acc_option;
          navigateParams.acc_months = [];
          navigateParams.acc_months.push(acc_option);
        }
        if ((isInvoiceLtd && !isInvoiceReceived) || isInvoiceUnpaid) {
          navigateParams.acc_months = this.getAccrualPeriodOptionsForMonths(
            colMonth,
            colYear,
            all_options
          ).before_and_current_options;
        }
      }
    } else if (inQStart) {
      navigateParams.acc_months = this.getAccrualPeriodOptionsForQStart(
        all_options,
        selectedQtrDate
      ).before_acc_options;
    } else if (inQSummary) {
      const isInvoiceReceived = columnId.includes('received');
      const isInvoiceLtd = columnId.includes('ltd');
      const isInvoiceUnpaid = columnId.includes('unpaid');
      if (isInvoiceReceived) {
        navigateParams.acc_months = this.getAccrualPeriodOptionsForQSummary(
          all_options,
          selectedQtrDate
        ).quarter_acc_options;
      }
      if ((isInvoiceLtd && !isInvoiceReceived) || isInvoiceUnpaid) {
        navigateParams.acc_months = this.getAccrualPeriodOptionsForQSummary(
          all_options,
          selectedQtrDate
        ).before_and_quarter_acc_options;
      }
    }

    const acc_mont_values: string[] = [];

    navigateParams.acc_months.forEach((month) => {
      acc_mont_values.push(dayjs(month.value).format('YYYY-MM-DD'));
    });

    this.invoiceService.setAccrualPeriodsAndVendorFilter(acc_mont_values, navigateParams.vendor_id);

    if (unpaidInvoices) {
      this.invoiceService.setShowUnpaidInvoices(unpaidInvoices);
    }

    this.router.navigateByUrl(
      `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`
    );
  }

  getAccrualPeriodOptionsForQStart(all_options: Option[], date: Date) {
    const before_acc_options = all_options.filter((option) =>
      dayjs(`${option.value}`).isBefore(date)
    );
    return { before_acc_options };
  }

  getAccrualPeriodOptionsForQSummary(all_options: Option[], date: Date) {
    const quarter_acc_options = all_options.filter(
      (option) =>
        dayjs(`${option.value}`).quarter() === dayjs(date).quarter() &&
        dayjs(`${option.value}`).year() === dayjs(date).year()
    );
    const before_and_quarter_acc_options = all_options.filter(
      (option) =>
        (dayjs(`${option.value}`).quarter() === dayjs(date).quarter() &&
          dayjs(`${option.value}`).year() === dayjs(date).year()) ||
        dayjs(`${option.value}`).isBefore(date)
    );
    return { quarter_acc_options, before_and_quarter_acc_options };
  }

  getAccrualPeriodOptionsForMonths(month_number: string, year: string, all_options: Option[]) {
    const acc_option: Option = { label: '', value: '' };

    const longMonthNames = Utils.MONTH_NAMES;
    const monthNumber = Number(month_number);
    const longMonthName = longMonthNames[monthNumber - 1];
    acc_option.label = `${longMonthName} ${year}`;
    acc_option.value = `${year}-${month_number}`;

    const date = new Date(+year, monthNumber - 1, 1);

    const before_and_current_options = all_options.filter((option) =>
      dayjs(`${option.value}`).isSameOrBefore(date)
    );

    return { acc_option, before_and_current_options };
  }

  async onInMonthClose() {
    const info = this.budgetQuery.getValue().budget_info;

    const first_vendor_current_month = info[0]?.current_month;
    if (!first_vendor_current_month) {
      return;
    }
    const formatted_current_month = formatDate(first_vendor_current_month, 'MMMM y', 'en-US');

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        unknown,
        { note: string; sendToNetsuite?: boolean } | undefined,
        QuarterCloseDialogComponent
      >({
        content: QuarterCloseDialogComponent,
        settings: {
          header: `Close ${formatted_current_month}`,
          primaryButton: {
            label: `Close ${formatted_current_month}`,
            variant: 'success',
            action: (instance) => {
              instance?.close(true);
            },
            pendoTag: 'close-month',
          },
        },
        data: {
          formatted_current_month,
        },
      }).afterClosed$
    );

    if (!resp.data?.note) {
      return;
    }

    await firstValueFrom(
      this.overlayService.openPopup({
        content: QuarterCloseProcessingConfirmationComponent,
        settings: {
          header: 'The Close Process has begun',
          primaryButton: {
            label: 'OK',
          },
          secondaryButton: {
            hidden: true,
          },
        },
      }).afterClosed$
    );

    const ref = this.overlayService.loading();

    await this.exportExcelToS3();

    const { current_month } = info[0];

    const result = await firstValueFrom(
      this.globalEventService.processEvent$({
        entity_id: '',
        entity_type: EntityType.TRIAL,
        type: EventType.CLOSE_TRIAL_MONTH,
        payload: JSON.stringify({
          note: resp.data?.note,
          event_dates: [current_month],
          sendToNetsuite: resp.data?.sendToNetsuite,
        }),
      })
    );

    ref.close();

    if (result.success) {
      this.eventService.trackEvent(result.data!.id);
      this.periodCloseComponent.monthClosed$.next(true);
      // this.overlayService.success('Month Successfully Closed');
      console.log('Month Successfully Closed');
    } else {
      const allErrors: string[] = [];
      allErrors.push(...result.errors);
      this.overlayService.error(allErrors);
    }
  }

  async onCellValueChanged(event: CellValueChangedEvent) {
    const loading = this.overlayService.loading();
    const user = await this.authService.getLoggedInUser();
    const id = event.column.getColId();
    const [, permission_type] = id.split('::');
    const [node] = this.gridAPI.getSelectedNodes();

    const { success, errors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: permission_type,
        approval_type: ApprovalType.APPROVAL_IN_MONTH,
        entity_id: node.data.vendor_id,
        entity_type: EntityType.TRIALEVENT,
        activity_details: JSON.stringify({
          category: node.data.activity_name,
        }),
      })
    );

    if (success) {
      if (user?.IsSysAdmin()) {
        node.setData({ ...node.data, [`${id}::disabled`]: true });
      } else {
        const columnsToChange = ((this.gridAPI.getColumns() || []) as Column[]).filter((x) =>
          x.getColId().includes(permission_type)
        );

        columnsToChange.forEach((col) => {
          node.setData({ ...node.data, [`${col.getColId()}::disabled`]: true });
        });
      }

      this.overlayService.success('Approved!');
    } else {
      node.setData({ ...node.data, [id]: false });
      this.overlayService.error(errors);
    }

    loading.close();
  }

  async onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);

    const allColumnIds: string[] = [];
    (this.gridAPI.getColumns() || []).forEach((column: Column) => {
      allColumnIds.push(column.getColId());
    });

    this.gridAPI.autoSizeColumns(allColumnIds, false);

    this.updateBottomRow(this.periodCloseComponent.newQCGridData$.getValue());

    this.showQuarterCloseBanner$.next(this.isOpenMonth());

    if (this.openMonthFirstColumnId) {
      setTimeout(() => {
        this.gridAPI.ensureColumnVisible(this.openMonthFirstColumnId, 'start');
      });
    }

    if (this.showGrid && this.gridInitializedOnInit) {
      await this.actionGridByRouteParams();
    }
    api.refreshHeader();
  }

  private async actionGridByRouteParams() {
    const { jeVendorId, jeSelectedMonth } = await firstValueFrom(this.route.queryParams);

    const index = this.periodCloseComponent.quartersObj[
      this.periodCloseComponent.selectedQuarter.value || ''
    ]?.findIndex((x) => x.date == jeSelectedMonth);

    if (index === -1) {
      return;
    }

    if (jeVendorId) {
      this.gridAPI.forEachNode((node) => {
        if (node.field === 'vendor_id' && node.key === jeVendorId) {
          this.gridAPI.setRowNodeExpanded(node, true);
        }
      });
    }

    if (
      !this.openMonthFirstColumnId ||
      this.openMonthFirstColumnId !== `qmonth${index + 1}_monthly_wp_estimate`
    ) {
      setTimeout(() => {
        this.gridAPI.ensureColumnVisible(`qmonth${index + 1}_accrual_ltd`, 'start');
      }, 0);
    }

    this.clearRouterQueryParams();
  }

  private clearRouterQueryParams() {
    this.router.navigate([], {
      queryParams: {
        jeVendorId: null,
        jeSelectedMonth: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  getCurrentMonthToClose(): {
    date: dayjs.Dayjs;
    formattedDate: string;
  } | null {
    if (!this.periodCloseComponent.currentMonth) {
      return null;
    }

    const currentMonthObj = dayjs(this.periodCloseComponent.currentMonth);

    if (!currentMonthObj.isValid()) return null;

    return {
      date: currentMonthObj,
      formattedDate: currentMonthObj.format('MMMM-YYYY'),
    };
  }

  private getMonthColumn({
    headerName,
    isSame,
    isFuture,
    month,
    hideTotal,
    readOnlyAdjustment,
    isSummary = false,
    alternate,
    index,
  }: {
    headerName: string;
    isSame: boolean;
    isFuture: boolean;
    month: QuarterDate | null;
    hideTotal?: boolean;
    readOnlyAdjustment?: boolean;
    isSummary?: boolean;
    alternate: boolean;
    index: number;
  }) {
    const adjustmentColumnProps = !readOnlyAdjustment
      ? {
          cellRenderer: AgQuarterCloseAdjustmentComponent,
          cellRendererParams: <AgQuarterCloseAdjustmentParams>(<unknown>{
            onClicked: (event: ICellRendererParams) => {
              this.periodCloseComponent.onSelectionChanged(event);
            },
            current_month: isSame,
            adjustmentWorkflow$: this.adjustmentWorkflow$,
            isWorkflowAvailable$: this.isWorkflowAvailable$,
            userHasAdjustPermission$: this.userHasAdjustPermission$,
            inMonthTabIsAvailable: this.launchDarklyService.$select(
              (flags) => flags.tab_in_month_adjustments
            ),
          }),
          cellClass: ['cost', '!p-0'],
        }
      : {
          cellClass: ['cost'],
        };

    const getMoneyColumn = () => {
      return {
        maxWidth: 150,
        minWidth: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        columnGroupShow: (isSame ? 'closed' : 'open') as ColumnGroupShowType,
      };
    };
    const currentMonth = `qmonth${index + 1}`;
    const advanced_payments_columns: ColDef[] = [];
    if (!isSummary) {
      if (this.isAPColumnVisible()) {
        advanced_payments_columns.push({
          ...getMoneyColumn(),
          headerName: 'Advanced Payments LTD',
          field: `${currentMonth}_advanced_payments_ltd`,
        });
      }
      if (this.isNPColumnVisible()) {
        advanced_payments_columns.push({
          ...getMoneyColumn(),
          headerName: 'Net Position',
          field: `${currentMonth}_net_position`,
        });
      }
    }

    const debit_credit_columns: ColDef[] = isSummary
      ? []
      : [
          {
            ...getMoneyColumn(),
            headerName: 'Accrual Debit',
            hide: true,
            field: `${currentMonth}_accrual_debit`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Accrual Credit',
            hide: true,
            field: `${currentMonth}_accrual_credit`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Prepaid Debit',
            hide: true,
            field: `${currentMonth}_prepaid_debit`,
          },
          {
            ...getMoneyColumn(),
            headerName: 'Prepaid Credit',
            hide: true,
            field: `${currentMonth}_prepaid_credit`,
          },
        ];

    if (isSame) {
      this.openMonthFirstColumnId = `${currentMonth}_monthly_wp_estimate`;
    }

    const children: ColDef[] = [
      {
        ...getMoneyColumn(),
        ...adjustmentColumnProps,
        headerName: isSummary ? 'Quarterly WP Estimate' : 'Monthly WP Estimate',
        field: isSummary ? 'qend_quarterly_wp_estimate' : `${currentMonth}_monthly_wp_estimate`,
        cellClass: (p) => [
          ...this.getCellClass()(p),
          alternate ? AuxExcelStyleKeys.BORDER_LEFT : '',
        ],
      },
      {
        ...getMoneyColumn(),
        headerName: isSummary ? 'Quarterly Invoices Received' : 'Monthly Invoices Received',
        field: isSummary
          ? 'qend_monthly_invoices_received'
          : `${currentMonth}_monthly_invoices_received`,
        cellClass: this.getCellClassForInvoiceCells(),
        onCellClicked: (event) => {
          if (event.node.group || event.rowPinned === 'bottom') {
            return this.navigateByInvoices(event);
          }
        },
      },
      {
        headerComponent: AgQuarterCloseCollapsableHeaderComponent,
        headerComponentParams: {
          template: `Net Change in <br/> Accruals / (Prepaid)`,
          columns: isSummary
            ? []
            : [
                `${currentMonth}_accrual_debit`,
                `${currentMonth}_accrual_credit`,
                `${currentMonth}_prepaid_debit`,
                `${currentMonth}_prepaid_credit`,
              ],
        } as AgQuarterCloseCollapsableHeaderParams,
        headerName: 'Net Change in Accruals / (Prepaid)',
        maxWidth: 210,
        minWidth: 210,
        resizable: false,
        field: isSummary ? 'qend_net_change_in_accruals' : `${currentMonth}_net_change_in_accruals`,
        columnGroupShow: isSame ? 'closed' : 'open',
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        headerClass: 'net_accruals_header',
      },
      ...debit_credit_columns,
      {
        ...getMoneyColumn(),
        headerName: 'WP LTD',
        field: isSummary ? `qend_wp_ltd` : `${currentMonth}_wp_ltd`,
      },
      {
        ...getMoneyColumn(),
        headerName: 'Invoiced LTD',
        onCellClicked: (event) => {
          if (event.node.group || event.rowPinned === 'bottom') {
            return this.navigateByInvoices(event);
          }
        },
        cellClass: this.getCellClassForInvoiceCells(),
        field: isSummary ? `qend_invoiced_ltd` : `${currentMonth}_invoiced_ltd`,
      },
      {
        minWidth: 150,
        width: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: this.getCellClass(),
        // columnGroupShow: isSame ? 'closed' : 'open',
        headerName: 'Accrual LTD',
        columnGroupShow: undefined,
        field: isSummary ? 'qend_accrual_ltd' : `${currentMonth}_accrual_ltd`,
      },
      {
        minWidth: 150,
        width: 150,
        resizable: false,
        valueFormatter: this.currencyFormatter,
        aggFunc: Utils.agSumFunc,
        cellClass: (p) => [
          ...this.getCellClass()(p),
          this.showUnpaidColumns$.getValue() ? '' : alternate ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        // columnGroupShow: isSame ? 'closed' : 'open',
        headerName: 'Prepaid LTD',
        columnGroupShow: undefined,
        field: isSummary ? 'qend_prepaid_ltd' : `${currentMonth}_prepaid_ltd`,
      },
      {
        ...getMoneyColumn(),
        headerName: 'Unpaid Invoices LTD',
        field: isSummary ? 'qend_unpaid_invoices_ltd' : `${currentMonth}_unpaid_invoices_ltd`,
        hide: !this.showUnpaidColumns$.getValue(),
        onCellClicked: (event) => {
          if (event.node.group || event.rowPinned === 'bottom') {
            return this.navigateByInvoices(event, true);
          }
        },
        cellRenderer: (params: ICellRendererParams) => {
          return `<div data-pendo-id="unpaid-invoices-ltd">${params.valueFormatted}</div>`;
        },
        cellClass: this.getCellClassForInvoiceCells(),
      },
      ...advanced_payments_columns,
    ];
    return {
      headerName,
      headerClass: [
        isSame
          ? 'open gray-dark-header-group'
          : headerName.includes('Summary')
            ? 'attributes-quarterStart-quarterSummary'
            : 'closed',
        ...(alternate
          ? [
              AuxExcelStyleKeys.ALTERNATE,
              AuxExcelStyleKeys.BORDER_LEFT,
              AuxExcelStyleKeys.BORDER_RIGHT,
            ]
          : ['']),
      ],
      headerGroupComponent: AgInMonthGroupHeaderComponent,
      headerGroupComponentParams: {
        processing$: this.periodCloseComponent.iCloseMonthsProcessing$,
        isMonthCloseAvailable$: this.isCloseMonthAvailable$,
        isQuarterCloseEnabled$: this.isQuarterCloseEnabled$,
        isWorkflowAvailable$: this.isWorkflowAvailable$,
        userHasClosingPermission$: this.userHasClosingPermission$,
        bottomRowData$: this.periodCloseComponent.bottomRowData$,
        budgetInfo$: this.budgetInfo$,
        mode: isFuture ? 'future' : isSame ? 'open' : 'closed',
        month,
        hideTotal,
        headerName,
        monthButtonClick: () => {
          if (isSame) {
            this.onInMonthClose();
          }
        },
      },
      minWidth: 200,
      children,
    };
  }

  private isColGroup(cell: ColDef | ColGroupDef): cell is ColGroupDef {
    return !!(cell as ColGroupDef)?.children;
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return !!(col as ColDef).colId;
  }

  private getChildrenGroupIds(cols: (ColDef | ColGroupDef)[]): string[] {
    return cols.filter(this.isColDef).map((col) => col.colId) as string[];
  }

  private getDashboardIDs(): string[] {
    if (!this.gridAPI) {
      return [];
    }

    return (this.gridAPI?.getColumnDefs() || [])
      .filter((cel) => !!cel.headerName)
      .reduce<string[]>((accum, cell: ColDef | ColGroupDef) => {
        if (this.isColGroup(cell)) {
          const res = this.getChildrenGroupIds(cell.children);

          accum = accum.concat(res || []);
        }

        if (this.isColDef(cell)) {
          accum.push(cell.colId || '');
        }

        return accum;
      }, []);
  }

  onFilterChanged(e: FilterChangedEvent) {
    const notFilteredParentRowAndChilds: {
      whichVendor: string;
      whichChilds: string[];
    }[] = [];

    e.api.forEachNodeAfterFilter((row) => {
      if (row.group) {
        const key = row.key || '';
        const whichChilds: string[] = [];
        row.childrenAfterFilter?.forEach((children) => {
          whichChilds.push(children.data.cost_category);
        });
        notFilteredParentRowAndChilds.push({ whichVendor: key, whichChilds: whichChilds });
      }
    });

    const filteredData: QCGridData[] = this.periodCloseComponent.newQCGridData$
      .getValue()
      .filter((rowData) => {
        return notFilteredParentRowAndChilds.some(
          (rowGroup) =>
            rowGroup.whichVendor === rowData.vendor_id &&
            rowGroup.whichChilds.includes(<string>rowData.cost_category)
        );
      });
    this.updateBottomRow(filteredData);
  }

  updateBottomRow(filteredData: QCGridData[]) {
    if (this.periodCloseComponent.currencies.size === 1) {
      this.isTotalHidden = false;
      const obj: Record<string, number> = {
        qstart_wp_ltd: 0,
        qstart_invoiced_ltd: 0,
        qstart_accrual_ltd: 0,
        qstart_prepaid_ltd: 0,
        qend_quarterly_wp_estimate: 0,
        qend_monthly_invoices_received: 0,
        qend_net_change_in_accruals: 0,
        qend_wp_ltd: 0,
        qend_invoiced_ltd: 0,
        qend_accrual_ltd: 0,
        qend_prepaid_ltd: 0,
        qend_unpaid_invoices_ltd: 0,
        qmonth1_total_expense_quote: 0,
      };
      const monthColumns = [
        '_accrual_debit',
        '_accrual_credit',
        '_prepaid_debit',
        '_prepaid_credit',
        '_monthly_wp_estimate',
        '_monthly_invoices_received',
        '_net_change_in_accruals',
        '_wp_ltd',
        '_invoiced_ltd',
        '_accrual_ltd',
        '_prepaid_ltd',
        '_unpaid_invoices_ltd',
      ];
      if (this.isAPColumnVisible()) {
        monthColumns.push('_advanced_payments_ltd');
      }
      if (this.isNPColumnVisible()) {
        monthColumns.push('_net_position');
      }
      monthColumns.forEach((field) => {
        obj[`qmonth1${field}`] = 0;
        obj[`qmonth2${field}`] = 0;
        obj[`qmonth3${field}`] = 0;
      });

      const columnsToCalc = Object.keys(obj) as (keyof Omit<
        listQuarterCloseExpensesQuery,
        'vendor_id' | 'vendor_name' | '__typename' | 'cost_category' | 'attributes'
      >)[];

      for (const row of filteredData) {
        for (const column of columnsToCalc) {
          obj[column] += row[column] || 0;
        }
      }

      this.bottomRowData$.next({
        ...obj,
        activity_name: 'Total',
      });

      this.gridAPI.setGridOption('pinnedBottomRowData', [this.bottomRowData$.getValue()]);
    } else {
      this.gridAPI.setGridOption('pinnedBottomRowData', []);
      this.isTotalHidden = true;
    }
  }

  updateGridOptions() {
    this.showGrid = false;
    this.changeDetectorRef.detectChanges();

    let columns = [];

    const isCurrentQuarter =
      this.periodCloseComponent.selectedQuarter.value === this.periodCloseComponent.currentQuarter;

    const attributes = getAttributeColumns({
      attributes: this.periodCloseComponent.newQCGridData$.getValue().map((z) => z.attributes),
    });

    const attr = attributeColumnDef(attributes, QCAttributesLSKey);
    attr.headerClass = ['attributes-quarterStart-quarterSummary', 'ag-header-align-center'];

    const initialColumns = this.getInitialColumns(isCurrentQuarter, this.activitiesColumnDef, attr);

    columns = [initialColumns, attr, ...this.defaultColumns];

    const addEmptyColumn = () => {
      columns.push(TableConstants.SPACER_COLUMN);
    };

    this.openMonthFirstColumnId = '';

    (
      this.periodCloseComponent.quartersObj[
        this.periodCloseComponent.selectedQuarter.value || ''
      ] || []
    ).forEach((month, index) => {
      const isAfter = dayjs(this.periodCloseComponent.currentMonth)
        .date(1)
        .isAfter(dayjs(month.iso).date(1));
      const isSame = dayjs(this.periodCloseComponent.currentMonth)
        .date(1)
        .isSame(dayjs(month.iso).date(1));
      const isFuture = !(isSame || isAfter);
      addEmptyColumn();

      const slicedDate = month.iso.slice(0, 7);

      const monthColumn = this.getMonthColumn({
        headerName: slicedDate,
        isSame: isSame,
        isFuture: isFuture,
        month: month,
        alternate: index === 1,
        index,
      });

      columns.push(monthColumn);
    });
    addEmptyColumn();

    columns.push(
      this.getMonthColumn({
        headerName: `${
          this.periodCloseComponent.selectedQuarter.value?.replace('-', ' ') || ''
        } Summary`,
        isSame: false,
        isFuture: true,
        month: null,
        hideTotal: true,
        readOnlyAdjustment: true,
        isSummary: true,
        alternate: true,
        index: 0,
      })
    );

    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: [...columns],
    });

    setTimeout(() => {
      this.showGrid = true;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  getInitialColumns(
    hasCurrentQuarter: boolean,
    activitiesColumnDef: ColDef,
    attr: ColGroupDef
  ): ColGroupDef {
    return {
      headerName: 'Vendors',
      headerGroupComponent: AgQuarterCloseGroupHeaderComponent,
      hide: false,
      headerGroupComponentParams: {
        expandLevel: () => -1,
        template: `Vendors`,
        columnsToCollapse: attr.children.map((x: ColDef) => x.colId || x.field),
        localStorageKey: QCAttributesLSKey,
        quickFilterText: true,
        alignHeaderOneRowHeight: true,
      } as AgQuarterCloseGroupHeaderComponentParams,
      headerClass: [
        'ag-header-align-center',
        'justify-center',
        AuxExcelStyleKeys.ALTERNATE,
        AuxExcelStyleKeys.BORDER_LEFT,
        AuxExcelStyleKeys.BORDER_RIGHT,
        'attributes-quarterStart-quarterSummary',
      ],
      children: [
        {
          headerName: 'Vendor',
          field: 'vendor_id',
          resizable: false,
          rowGroup: true,
          valueFormatter: (params: ValueFormatterParams) => {
            return this.organizationQuery.getEntity(params.value)?.name || '';
          },
          hide: true,
          cellClass: ['vendor_id'],
        },
        activitiesColumnDef,
        {
          headerName: 'Contract Amount',
          field: 'qmonth1_total_expense_quote',
          width: 150,
          minWidth: 150,
          resizable: true,
          pinned: 'left',
          hide: !this.showContractAmountColumn() || !hasCurrentQuarter,
          headerClass: ['ag-header-align-center'],
          valueFormatter: this.currencyFormatter,
          aggFunc: Utils.agSumFunc,
          cellClass: this.getCellClass(),
        },
      ],
    } as ColGroupDef;
  }

  agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  onFirstDataRendered({ api }: FirstDataRenderedEvent) {
    const rowNodes: IRowNode[] = [];
    api.forEachNode((rowNode) => rowNodes.push(rowNode));
    first(rowNodes)?.setSelected(true, true);
  }

  onVendorEstimateUploadClick() {
    this.overlayService.openPopup({
      content: VendorEstimateUploadComponent,
      settings: {
        header: 'Upload Vendor Estimate',
        primaryButton: {
          label: 'Upload',
          action: async (instance: VendorEstimateUploadComponent | null) => {
            await instance?.onUpload();
          },
        },
      },
      data: {
        onSuccess: (processEventId: string) => {
          this.budgetTemplateUploadProgressId.set(processEventId);
        },
      },
    });
  }

  onTrackerIdChanged(trackerId: string) {
    this.budgetTemplateUploadProgressId.set(trackerId);
    if (!trackerId) {
      this.periodCloseComponent.refresh$.next(true);
    }
  }

  refreshRows(): void {
    this.gridAPI?.redrawRows();
  }

  get currentMonth(): string {
    return this.budgetQuery.getValue().budget_info[0]?.current_month || '';
  }

  private isOpenMonth(): boolean {
    let isSame = false;
    (
      this.periodCloseComponent.quartersObj[
        this.periodCloseComponent.selectedQuarter.value || ''
      ] || []
    ).forEach((month) => {
      if (dayjs(this.periodCloseComponent.currentMonth).date(1).isSame(dayjs(month.iso).date(1))) {
        isSame = true;
      }
    });
    return isSame;
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPLOAD_VENDOR_ESTIMATE],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasUploadVendorEstimatePermission]) => {
        this.userHasUploadVendorEstimatePermission.next(userHasUploadVendorEstimatePermission);
      });
  }

  protected readonly QuarterCloseBannerHeight = QuarterCloseBannerHeight;
}
