import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

type AgLoadingCell = ILoadingCellRendererParams;

@Component({
  selector: 'aux-ag-loading-cell',
  template: ` <div class="h-8 w-8 m-auto spinner mt-2"></div> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AgLoadingCellComponent implements ILoadingCellRendererAngularComp {
  params!: AgLoadingCell;

  agInit(params: AgLoadingCell): void {
    this.params = params;
  }
}
