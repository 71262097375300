<div class="flex">
  <div class="flex flex-col-reverse mr-1">
    @if (invoice().expense_amounts.invoice_total.value === totals().invoice_total) {
      <aux-icon name="Check" class="text-aux-green-dark" />
    } @else {
      <aux-icon
        name="AlertTriangle"
        class="text-aux-red-dark"
        [auxTooltip]="messagesConstants.MAP_ACTIVITIES.INVOICE_ALLOCATION_NOT_MATCH"
      />
    }
  </div>

  <div class="mr-5">
    <div class="text-aux-gray-dark-100">Invoice Total</div>
    <div>
      {{
        utils.currencyFormatter(
          invoice().expense_amounts.invoice_total.value,
          {},
          invoice().organization.currency
        )
      }}
      | {{ utils.currencyFormatter(totals().invoice_total, {}, invoice().organization.currency) }}
    </div>
  </div>

  <div class="mr-5">
    <div class="text-aux-gray-dark-100">Allocated Services Total</div>
    <div>
      {{
        utils.currencyFormatter(
          invoice().expense_amounts.services_total.value,
          {},
          invoice().organization.currency
        )
      }}
      |
      {{ utils.currencyFormatter(totals().ACTIVITY_SERVICE, {}, invoice().organization.currency) }}
    </div>
  </div>

  <div class="mr-5">
    <div class="text-aux-gray-dark-100">Allocated Discount Total</div>
    <div>
      {{
        utils.currencyFormatter(
          invoice().expense_amounts.discount_total.value,
          {},
          invoice().organization.currency
        )
      }}
      |
      {{ utils.currencyFormatter(totals().ACTIVITY_DISCOUNT, {}, invoice().organization.currency) }}
    </div>
  </div>

  <div class="mr-5">
    <div class="text-aux-gray-dark-100">Allocated Passthrough Total</div>
    <div>
      {{
        utils.currencyFormatter(
          invoice().expense_amounts.pass_thru_total.value,
          {},
          invoice().organization.currency
        )
      }}
      |
      {{
        utils.currencyFormatter(totals().ACTIVITY_PASSTHROUGH, {}, invoice().organization.currency)
      }}
    </div>
  </div>

  <div class="mr-5">
    <div class="text-aux-gray-dark-100">Allocated Investigator Total</div>
    <div>
      {{
        utils.currencyFormatter(
          invoice().expense_amounts.investigator_total.value,
          {},
          invoice().organization.currency
        )
      }}
      |
      {{
        utils.currencyFormatter(totals().ACTIVITY_INVESTIGATOR, {}, invoice().organization.currency)
      }}
    </div>
  </div>
</div>
