<div class="px-7 py-4 bg-aux-gray-light flex flex-col border-b">
  <aux-processing-loader
    *ngIf="iCloseMonthsProcessing$ | async"
    class="mb-7 mt-3"
    [message]="closeMonthProcessingMessage"
  />

  <aux-period-close-header
    [quarters]="quarterOptions"
    [months$]="monthsStat$"
    [selectedQuarter]="selectedQuarter"
  />
</div>

<aux-route-tab-group class="bg-aux-gray-light" [tabs]="tabs" />

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto my-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <router-outlet />
</ng-container>
