import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormGroupDirective,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import dayjs from 'dayjs';
import { NgSelectModule } from '@ng-select/ng-select';

import { MessagesConstants } from '@shared/constants/messages.constants';
import { InputComponent } from '@shared/components/input/input.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { PurchaseOrdersQuery } from '@pages/vendor-payments-page/tabs/purchase-orders/state/purchase-orders.query';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { AuthService } from '@shared/store/auth/auth.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import {
  EventType,
  PermissionType,
  TrialImplementationStatus,
  WorkflowStep,
} from '@shared/services/gql.service';
import { EventQuery } from '@models/event/event.query';
import { MainQuery } from '@shared/store/main/main.query';

import { InvoiceNotesComponent } from './invoice-notes.component';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { invoiceDisabledTooltip } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';
import { isEqual } from 'lodash-es';
import { InvoiceForm } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.model';
import { InvoiceFormUtils } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.utils';
import { FormErrorDirective } from '@shared/directives/form-error.directive';

const initialFormValues: InvoiceForm = {
  selectedPOReference: '',
  due_date: '',
  notes: '',
  accrual_period: '',
  services_period: '',
  vendor_id: '',
  invoice_no: '',
  invoice_date: '',
  invoice_total: '',
  investigator_total: '',
  services_total: '',
  discount_total: '',
  pass_thru_total: '',
  invoice_total_trial_currency: '',
  pass_thru_total_trial_currency: '',
  services_total_trial_currency: '',
  discount_total_trial_currency: '',
  investigator_total_trial_currency: '',
};

@Component({
  standalone: true,
  selector: 'aux-invoice-form',
  templateUrl: 'invoice-form.component.html',
  imports: [
    InputComponent,
    NgSelectModule,
    ReactiveFormsModule,
    TooltipDirective,
    NgClass,
    FormsModule,
    InvoiceNotesComponent,
    FormErrorDirective,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFormComponent {
  formBuilder = inject(NonNullableFormBuilder);

  workflowQuery = inject(WorkflowQuery);

  orgQuery = inject(OrganizationQuery);

  purchaseOrdersQuery = inject(PurchaseOrdersQuery);

  authQuery = inject(AuthQuery);

  authService = inject(AuthService);

  eventQuery = inject(EventQuery);

  mainQuery = inject(MainQuery);

  invoiceService = inject(InvoiceService);

  private cdr = inject(ChangeDetectorRef);

  protected readonly messagesConstants = MessagesConstants;

  invoiceFormRef = viewChild.required<FormGroupDirective>('invoiceFormRef');

  invoice = input.required<InvoiceModel>();
  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;
  formValues = signal(initialFormValues);
  initializing = signal(true);

  hasEditPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);
  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  filteredPONumbers = computed(() => {
    const invoice = this.invoice();
    const { vendor_id } = this.formValues();
    const purchaseOrders = this.purchaseOrdersQuery.$selectAll();

    if (vendor_id) {
      return purchaseOrders.filter((po) => po.organization_id === vendor_id);
    }

    return purchaseOrders.filter((po) => po.id === invoice.organization.id);
  });

  isDecline = computed(() => this.invoice().invoice_status === 'STATUS_DECLINED');

  accrualMinDate = computed(() => {
    const { currentOpenMonth } = this.mainQuery.mainState();
    return dayjs(currentOpenMonth).format('YYYY-MM');
  });

  maxDate = computed(() => {
    const { trialEndDate } = this.mainQuery.mainState();
    return dayjs(trialEndDate).format('YYYY-MM');
  });

  isAccrualPeriodInClosedMonth = computed(() => {
    const trial = this.mainQuery.selectedTrial();
    const { currentOpenMonth } = this.mainQuery.mainState();
    const invoice = this.invoice();

    return InvoiceFormUtils.isAccrualPeriodInClosedMonth({ invoice, trial, currentOpenMonth });
  });

  isFormDisabled = computed(() => {
    const isAdmin = this.authQuery.adminUser();
    return (
      (!isAdmin && !this.hasEditPermission()) ||
      this.isInvoiceFinalized() ||
      !!this.iCloseMonthsProcessing()
    );
  });

  formTooltip(field = '') {
    if (!this.hasEditPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (this.isInvoiceFinalized()) {
      return this.invoiceLockTooltip();
    }

    if (this.isAccrualPeriodInClosedMonth() && field !== 'services_period') {
      return invoiceDisabledTooltip;
    }

    return '';
  }

  vendorIdTooltip = computed(() =>
    this.invoice().has_prepaid ? MessagesConstants.PREPAIDS.DISABLED_VENDOR : ''
  );

  invoiceForm = this.formBuilder.group(initialFormValues);
  submit = false;

  patchForm = effect(
    () => {
      this.initializing.set(true);
      const invoice = this.invoice();
      this.invoiceForm.setValue(InvoiceFormUtils.invoiceModelToForm(invoice), { emitEvent: false });

      this.initialFormValues = this.invoiceForm.getRawValue();
      this.formValues.set(this.invoiceForm.getRawValue());
      this.invoiceForm.markAsPristine();
      this.initializing.set(false);
    },
    {
      allowSignalWrites: true,
    }
  );
  disableFormEffect = effect(() => {
    if (this.isFormDisabled()) {
      this.invoiceForm.disable();
    } else {
      if (this.isAccrualPeriodInClosedMonth()) {
        [
          'selectedPOReference',
          'due_date',
          'notes',
          'accrual_period',
          'vendor_id',
          'invoice_no',
          'invoice_date',
          'invoice_total',
          'investigator_total',
          'services_total',
          'discount_total',
          'pass_thru_total',
          'invoice_total_trial_currency',
          'pass_thru_total_trial_currency',
          'services_total_trial_currency',
          'discount_total_trial_currency',
          'investigator_total_trial_currency',
        ].forEach((controlName) => {
          this.invoiceForm.get(controlName)?.disable();
        });
      } else {
        [
          'selectedPOReference',
          'due_date',
          'notes',
          'accrual_period',
          'vendor_id',
          'invoice_no',
          'invoice_date',
          'invoice_total',
          'investigator_total',
          'services_total',
          'discount_total',
          'pass_thru_total',
          'invoice_total_trial_currency',
          'pass_thru_total_trial_currency',
          'services_total_trial_currency',
          'discount_total_trial_currency',
          'investigator_total_trial_currency',
        ].forEach((controlName) => {
          this.invoiceForm.get(controlName)?.enable();
        });
      }
      this.invoiceForm.enable();
      if (this.invoice().has_prepaid) {
        this.invoiceForm.controls.vendor_id.disable();
      }
    }
  });

  accrualValidators: ValidatorFn[] = [
    (control) => {
      if (!this.submit) {
        return null;
      }
      if (this.isFormDisabled() || this.isAccrualPeriodInClosedMonth()) {
        return null;
      }

      const value = control.value;
      const { currentOpenMonth } = this.mainQuery.mainState();
      const trial = this.mainQuery.selectedTrial();
      const accrualDate = dayjs(`${value}-01`);
      if (
        value &&
        accrualDate.isBefore(currentOpenMonth, 'month') &&
        (trial?.implementation_status ===
          TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
          trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE)
      ) {
        return { before: true };
      }

      return null;
    },
  ];
  servicesPeriodValidators: ValidatorFn[] = [
    (control) => {
      if (!this.submit) {
        return null;
      }
      if (this.isFormDisabled()) {
        return null;
      }

      const value = control.value;
      const servicesPeriodDate = dayjs(`${value}-01`);

      if (
        value &&
        (dayjs(`${servicesPeriodDate}-01`).isBefore(this.mainQuery.getValue().trialStartDate) ||
          dayjs(`${servicesPeriodDate}-01`).isAfter(this.mainQuery.getValue().trialEndDate))
      ) {
        return { outTimeline: true };
      }

      return null;
    },
  ];
  customErrorMessages = {
    before: '',
  };
  customErrorServicesPeriodMessages = {
    before: '',
    outTimeline: '',
  };

  // on initialize or when invoice changes, we are updating the form values.
  // While we are updating the form values we shouldn't get any event in the valueChanges subscription.
  // in order to fix that we are using initializing signal.
  private initializing$ = toObservable(this.initializing);
  // needed for checking if the form values are changed or not.
  private initialFormValues = initialFormValues;

  constructor() {
    this.initializing$
      .pipe(
        takeUntilDestroyed(),
        filter((value) => !value),
        switchMap(() => this.invoiceForm.valueChanges),
        debounceTime(100)
      )
      .subscribe(() => {
        const next = this.invoiceForm.getRawValue();

        this.formValues.set(next);
      });
  }

  refreshView() {
    this.cdr.detectChanges();
  }

  onVendorChange() {
    const control = this.invoiceForm.get('selectedPOReference');

    if (control) {
      (control as AbstractControl<string | undefined>).setValue(undefined);
    }
  }

  async saveNotes() {
    const invoice = this.invoice();
    const { notes } = this.formValues();

    if (invoice.notes?.length) {
      await this.invoiceService.updateInvoiceNote(invoice, notes);
    } else {
      await this.invoiceService.createInvoiceNote(invoice, notes);
    }
    this.invoiceForm.controls.notes.markAsPristine();
  }

  cancelNotes() {
    const invoice = this.invoice();
    this.invoiceForm.controls.notes.setValue(invoice.notes?.[0]?.message || '');
    this.invoiceForm.controls.notes.markAsPristine();
  }

  canDeactivate() {
    return isEqual(this.invoiceForm.getRawValue(), this.initialFormValues);
  }
}
