import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'aux-no-prepaids-overlay',
  templateUrl: './no-prepaids-overlay.component.html',
  standalone: true,
  imports: [RouterLink],
})
export class NoPrepaidsOverlayComponent implements ICellRendererAngularComp {
  readonly invoiceLibraryPage = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`;

  agInit(): void {}

  refresh(): boolean {
    return false;
  }
}
