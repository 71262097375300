import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[auxClickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() auxClickOutside = new EventEmitter<HTMLElement>();

  @Input() skipInitial = true;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (!this.skipInitial) {
        this.auxClickOutside.emit(target);
      }
      this.skipInitial = false;
    }
  }
}
