import { Injectable } from '@angular/core';
import { AuthService } from '@shared/store/auth/auth.service';
import { GqlService, PermissionType } from '@shared/services/gql.service';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, skip } from 'rxjs/operators';
import { TimelineService } from '../../../../timeline-group/timeline/state/timeline.service';
import { SiteCurveService } from '../../../drivers/forecast-sites/site-curve/site-curve.service';
import { PatientCurveQuery } from '../../../state/patient-curve/patient-curve.query';
import { ForecastTableGridCategoryToggleService } from './forecast-table-grid-category-toggle.service';
import { ForecastTableGridCategoryService } from './forecast-table-grid-category.service';
import { ForecastTableGridMethodService } from './forecast-table-grid-method.service';
import { ForecastTableGridPeriodService } from './forecast-table-grid-period.service';
import {
  ForecastTableGridDataInterface,
  ForecastTableGridSiteCurveInterface,
  ForecastTableGridTimelineMilestoneInterface,
  ForecastTableGridTimelinePhaseInterface,
} from '../models/forecast-table-grid.model';
import { EditableListDropdownItem } from '@shared/components/editable-list-dropdown/editable-list-dropdown-item.model';

@Injectable()
export class ForecastTableGridService {
  constructor(
    private CategoryService: ForecastTableGridCategoryService,
    private ToggleService: ForecastTableGridCategoryToggleService,
    private MethodService: ForecastTableGridMethodService,
    private PeriodService: ForecastTableGridPeriodService,
    private patientCurveQuery: PatientCurveQuery,
    private siteCurveService: SiteCurveService,
    private timelineService: TimelineService,
    private authService: AuthService,
    private gqlService: GqlService
  ) {}

  getRowData(): Observable<[ForecastTableGridDataInterface[], ForecastTableGridDataInterface[]]> {
    return combineLatest([
      this.CategoryService.getServiceCategories(),
      this.CategoryService.getCostCategories(),
      this.ToggleService.getToggleStatus(),
    ]).pipe(map(this.CategoryService.filterCategoryView.bind(this)));
  }

  getSiteCurves(): Observable<ForecastTableGridSiteCurveInterface[]> {
    return this.siteCurveService.get().pipe(map(this.MethodService.parseSiteCurves.bind(this)));
  }

  getPatientCurves(): Observable<EditableListDropdownItem[]> {
    return this.patientCurveQuery
      .selectAll()
      .pipe(map(this.MethodService.parsePatientCurves.bind(this)));
  }

  getCustomCurves() {
    return this.gqlService.listDriverCustomGroupsWithData$().pipe(
      map(({ data }) => {
        return data || [];
      })
    );
  }

  getTimelineItems(): Observable<
    [ForecastTableGridTimelinePhaseInterface[], ForecastTableGridTimelineMilestoneInterface[]]
  > {
    return this.timelineService
      .getTimelineItems()
      .pipe(map(this.PeriodService.parseTimelineItems.bind(this.PeriodService)));
  }

  getAuthorization(): Observable<boolean> {
    return this.authService.isAuthorized$({
      sysAdminsOnly: false,
      permissions: [PermissionType.PERMISSION_MODIFY_FORECAST_METHODOLOGY],
    });
  }

  getChangeDiscountChanges(
    changeDiscountTotalAmountValueChanges: Observable<unknown>,
    changeDiscountTotalPercentValueChanges: Observable<unknown>
  ) {
    return combineLatest([
      changeDiscountTotalAmountValueChanges,
      changeDiscountTotalPercentValueChanges,
    ]).pipe(skip(2), debounceTime(1000));
  }
}
