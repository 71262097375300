<form [formGroup]="fg" (submit)="onUpload()">
  <aux-modal primaryButtonType="submit" [loading]="loading()">
    <div class="font-inter w-screen max-w-2xl">
      <div class="grid grid-cols-2 gap-5">
        <div class="flex flex-col justify-start">
          <div>
            <div class="mb-2 text-xs">Vendor</div>
            <ng-select
              placeholder="Select"
              formControlName="organization_id"
              class="select select__big"
              label="Vendor"
              auxFormError
              [searchable]="true"
              [clearable]="false"
              bindLabel="name"
              bindValue="id"
            >
              <ng-template let-item="item" ng-option-tmp>
                <div
                  [auxTooltip]="!item.current_budget_versions?.length ? tooltip : ''"
                  [auxTooltipPositions]="optionTooltipPositions"
                >
                  {{ item.name }}
                </div>
              </ng-template>

              @for (vendor of vendorQuery.allVendors$ | async; track vendor.name) {
                <ng-option [value]="vendor" [disabled]="!vendor.current_budget_versions?.length">
                  {{ vendor.name }}
                </ng-option>
              }
            </ng-select>
          </div>

          <div>
            <aux-input
              class="mt-2 text-xs"
              formControlName="change_order_no"
              label="Change Order #"
              [validators]="changeOrderNoValidators"
            />
          </div>

          <div>
            <aux-input
              class="mt-2 text-xs"
              formControlName="date_received"
              label="Date Received"
              placeholder="YYYY-MM-DD"
              validators="required"
              [type]="'date'"
            />
          </div>

          <div>
            <aux-input
              class="mt-2 text-xs"
              formControlName="effective_date"
              label="Effective Date"
              placeholder="YYYY-MM-DD"
              validators="required"
              [type]="'date'"
            />
          </div>

          <div>
            <aux-input
              label="Change Order Notes"
              formControlName="notes"
              class="col-span-6 mt-2 text-xs"
              [textArea]="true"
              [rows]="3"
            />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="mb-2 text-xs ml-2">Upload Change Order</div>
          <aux-file-manager #fileManager class="h-56 ml-2 mb-2" [eager]="false" [path]="''" />
          <div class="ml-2 text-xs">Uploaded Files</div>
          <ng-container *ngIf="fileManager.fileQuery.selectAll() | async as files">
            <ng-container *ngIf="files.length">
              <ng-container *ngFor="let file of files; let last = last" class="border-b">
                <div
                  class="flex justify-between items-center py-2"
                  [ngClass]="{ 'border-b': !last }"
                >
                  <div class="items-start text-xs break-all pl-2">
                    {{ file.fileName }}
                  </div>

                  <button
                    class="btn rounded-full border-0 shadow-none p-0 w-6 h-6"
                    type="button"
                    (click)="removeFile(file)"
                  >
                    <aux-icon name="Trash" class="text-aux-error" />
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div *ngIf="errorMessage()" class="bg-aux-error mt-4 p-4 rounded-md text-white">
        <div>{{ errorMessage() }}</div>
      </div>
    </div>
  </aux-modal>
</form>
