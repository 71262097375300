import { InvestigatorAveragesService, InvestigatorForecastService } from '../../../services';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
  DestroyRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InvestigatorForecastForm } from '../../../investigator-forecast.types';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { decimalDifference, decimalRoundingToNumber } from '@shared/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-investigator-forecast-overview-averages',
  templateUrl: './investigator-forecast-overview-averages.component.html',
  styles: [
    `
      ::ng-deep .averages-form input:not([type='radio']) {
        color: black;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastOverviewAveragesComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() form!: FormGroup<InvestigatorForecastForm>;

  private maxPercent = 100;

  averagesValues$ = this.investigatorForecastService.averagesValues$;

  editMode$ = this.investigatorAveragesService.editMode$;

  formFieldLabels$ = this.averagesValues$.pipe(
    map((values) => {
      const { current_average_patient_cost, total_forecasted_patients } =
        values.find(({ last_updated }) => last_updated === 'Current') || {};

      return {
        totalForecastedPatients: total_forecasted_patients,
        averagePatientCostPerSite: current_average_patient_cost,
      };
    })
  );

  constructor(
    private investigatorForecastService: InvestigatorForecastService,
    private investigatorAveragesService: InvestigatorAveragesService
  ) {}

  ngOnInit(): void {
    this.recalculateAverageForm();
    this.roundInvoiceablesField('otherInvoiceablesPerc', 'overheadInvoiceablesPerc');
    this.roundInvoiceablesField('overheadInvoiceablesPerc', 'otherInvoiceablesPerc');
    this.updateAutoPopulateFieldsOnSiteChange();
  }

  getSiteContract(contractedSitesDate: string) {
    return (
      this.averagesValues$
        .getValue()
        .find(({ last_updated }) => last_updated === contractedSitesDate)?.site_count || 0
    );
  }

  private updateAutoPopulateFieldsOnSiteChange() {
    this.form
      .get(['averages', 'contractedSites'])
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((contractedSiteDate) => {
          const averages = this.averagesValues$
            .getValue()
            .find(({ last_updated }) => last_updated === contractedSiteDate);

          if (averages) {
            const { current_average_patient_cost, average_patient_cost } = averages;

            const averagePatientCost =
              averages.last_updated === 'Current'
                ? current_average_patient_cost
                : average_patient_cost;

            this.form.controls.averages.patchValue({
              totalForecastedPatients: averages.total_forecasted_patients,
              averagePatientCost: decimalRoundingToNumber(averagePatientCost),
            });
          }
        })
      )
      .subscribe();
  }

  private roundInvoiceablesField(formControlName: string, dependingControlName: string) {
    const formControl = this.form.get(['averages', formControlName]);
    const dependingControl = this.form.get(['averages', dependingControlName]);

    if (formControl) {
      formControl.valueChanges
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          distinctUntilChanged(isEqual),
          tap((percentage: number) => {
            const newVal = decimalDifference(this.maxPercent, percentage, 2);

            dependingControl?.patchValue(newVal, { emitEvent: false });
          })
        )
        .subscribe();
    }
  }

  private recalculateAverageForm() {
    const formGroup = this.form.controls.averages;

    formGroup?.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(isEqual),
        tap((values) => {
          if (this.investigatorForecastService.isLoadingSettings$.getValue()) {
            return;
          }

          const forecastedVisitCosts = this.investigatorAveragesService.getForecastedVisitCost(
            values.totalForecastedPatients,
            values.averagePatientCost
          );

          const forecastedInvoiceables = this.investigatorAveragesService.getForecastedInvoiceables(
            forecastedVisitCosts,
            values.invoiceables
          );

          const totalInvestigatorCosts = this.investigatorAveragesService.getTotalInvestigatorCost(
            forecastedVisitCosts,
            forecastedInvoiceables
          );

          const remainingAmount = this.investigatorAveragesService.getRemainingAmount(
            totalInvestigatorCosts,
            this.form.get('contracted')?.value.spendToDateAmount || 0
          );

          formGroup.patchValue(
            {
              forecastedVisitCosts,
              forecastedInvoiceables,
              totalInvestigatorCosts,
              remainingAmount,
            },
            { emitEvent: false }
          );
        })
      )
      .subscribe();
  }
}
