import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { DocumentType, EntityType, EventType } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { MainQuery } from '@shared/store/main/main.query';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DownloadTemplateButtonComponent } from '@features/download-template-button/download-template-button.component';
import { SharedModule } from '@shared/shared.module';
import { FileManagerUploadedFilesComponent } from '@features/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { FileViewerComponent } from '@features/file-viewer/file-viewer.component';
import { Workflow } from '@shared/store/workflow/workflow.store';
import dayjs from 'dayjs';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { QuarterCloseChecklistPeriodCloseService } from '../../../services/quarter-close-checklist-period-close.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { EventService } from '@models/event/event.service';
import { File } from '@shared/components/file-manager/state/file.model';
import { EDCItemStatus } from './edc-document-status';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-checklist-upload-edc-document',
  standalone: true,
  imports: [
    CommonModule,
    DownloadTemplateButtonComponent,
    SharedModule,
    FileManagerUploadedFilesComponent,
    FileViewerComponent,
  ],
  templateUrl: './checklist-upload-edc-document.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistUploadEdcDocumentComponent {
  ref = inject(CustomOverlayRef);

  private readonly mainQuery = inject(MainQuery);

  fileManager = viewChild(FileManagerComponent);

  private readonly overlayService = inject(OverlayService);

  private readonly workflowService = inject(WorkflowService);

  private readonly authQuery = inject(AuthQuery);

  private readonly quarterCloseChecklistPeriodCloseService = inject(
    QuarterCloseChecklistPeriodCloseService
  );

  filesNotUploaded = computed(() => {
    return (this.fileManager() as FileManagerComponent).fileQuery.filesNotUploaded() || [];
  });

  removedFiles$ = new BehaviorSubject<File[]>([]);

  unsavedChanges$ = new BehaviorSubject<boolean>(false);

  documentTypeId = DocumentType.DOCUMENT_MANUAL_TRANSACTION_SUPPORT;

  currentOpenMonth = signal<string>('');

  loading = false;

  workflow: undefined | Workflow = undefined;

  date = signal<string>('');

  workflowStatus = '';

  constructor(private eventService: EventService) {
    this.ref.canDeactivate = this.canDeactivate;
    effect(() => {
      if (this.fileManager()) {
        (this.fileManager() as FileManagerComponent).fileQuery
          .selectAll()
          .pipe(takeUntilDestroyed())
          .subscribe((files) => {
            this.setFileManagerHeight(files);
          });
      }
    });
    if (this.ref.data) {
      if (this.ref.data.workflow) {
        this.workflow = this.ref.data.workflow as Workflow;
        this.currentOpenMonth.set(dayjs(this.workflow.properties.month).format('YYYY-MM-DD'));
        this.date.set(dayjs(this.workflow.properties.month).format('MMMM YYYY'));
      }
    }
  }

  canDeactivate = async (): Promise<boolean> => {
    const filesNotUploaded = this.filesNotUploaded();

    if ((filesNotUploaded && filesNotUploaded.length > 0) || this.removedFiles$.getValue().length) {
      const closeModal = await this.openUnsavedChanges();
      return closeModal;
    }
    return true;
  };

  async closeModal(success = false) {
    this.ref.close({ success: success });
  }

  isWFStatusChanged(wf: Workflow, new_wf_status: EDCItemStatus) {
    return (
      !wf.properties.edc_data_status ||
      !(wf.properties.edc_data_status === (new_wf_status as string))
    );
  }

  async updateWorkflow() {
    const timestamp = dayjs().format();
    const userSub = this.authQuery.user().sub;
    const files =
      this.fileManager()
        ?.fileQuery.getAll()
        .filter((file) => file.uploaded) || [];
    if (this.workflow) {
      const newEDCStatus = this.getWorkflowStatus(this.workflow, files.length);
      const isWFStatusChanged = this.isWFStatusChanged(this.workflow, newEDCStatus);
      const edcProperties = {
        edc_data_status: newEDCStatus,
        edc_data_status_update_date: files.length > 0 ? timestamp : undefined,
        edc_data_status_updated_by: files.length > 0 ? userSub : undefined,
        last_customer_notified_date: isWFStatusChanged
          ? undefined
          : this.workflow.properties.last_customer_notified_date,
        last_customer_notified_by: isWFStatusChanged
          ? undefined
          : this.workflow.properties.last_customer_notified_by,
      };
      await this.workflowService.updateWorkflowPropertiesForEdcUpload(this.workflow, edcProperties);
    }
  }

  getWorkflowStatus(workflow: Workflow, fileLength: number): EDCItemStatus {
    if (workflow.properties && workflow.properties.edc_data_status) {
      const status = workflow.properties.edc_data_status;
      if (status === EDCItemStatus.STATUS_AVAILABLE) {
        return EDCItemStatus.STATUS_AVAILABLE;
      }
    }
    return fileLength > 0 ? EDCItemStatus.STATUS_PROCESSING : EDCItemStatus.STATUS_AWAITING_UPLOAD;
  }

  defaultPath = () => {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/checklist`;
  };

  pathFN = () => {
    const selectedMonth =
      this.currentOpenMonth() || this.quarterCloseChecklistPeriodCloseService.selectedQuarterMonth;
    return `${this.defaultPath()}/${selectedMonth}/`;
  };

  getBucketKey(bucket_key: string) {
    const timestampAndFileName = bucket_key.split(
      `${this.currentOpenMonth() || this.quarterCloseChecklistPeriodCloseService.selectedQuarterMonth}/`
    )[1];
    return `${this.pathFN()}${timestampAndFileName}`;
  }

  removeFileChange(file: File) {
    if (file.uploaded) {
      const prevRemovedFiles = this.removedFiles$.getValue();
      prevRemovedFiles.push(file);
      this.removedFiles$.next(prevRemovedFiles);
    }
  }

  async removeAllFiles() {
    const fileService = this.fileManager()?.fileService;

    const removedFiles = this.removedFiles$.getValue();

    if (fileService && removedFiles.length) {
      const promises = await fileService.removeFiles(removedFiles);
      if (!promises.every((prom) => prom === true)) {
        promises.forEach((val) => {
          if (typeof val !== 'boolean') {
            console.error('Error removing files:', val);
          }
        });
        this.overlayService.error('Error removing files.');
        return false;
      } else {
        removedFiles.forEach((file) => {
          this.overlayService.success(`${file.fileName} removed!`);
        });
        return true;
      }
    }
    return false;
  }

  setFileManagerHeight(files: File[]): void {
    const fileNum = files.length;
    this.workflowService.edcFileNumber$.next(files.filter((file) => file.uploaded).length);
    const fileManagerHeight = fileNum > 0 ? '100px' : '284px';
    const fileViewerHeight = fileNum > 0 ? '184px' : '0px';
    const fileMan = document.getElementsByTagName('aux-file-manager');
    const fileViewer = document.getElementsByTagName('aux-file-viewer');
    if (fileMan && fileViewer) {
      const fileManElem = fileMan[0].children[0] as HTMLElement;
      const fileViewElem = fileViewer[0] as HTMLElement;
      fileManElem.style.height = fileManagerHeight;
      fileViewElem.style.height = fileViewerHeight;
      fileViewElem.style.maxHeight = fileViewerHeight;
    }
  }

  async openUnsavedChanges(): Promise<boolean> {
    const result = this.overlayService.openUnsavedChangesConfirmation();
    const event = await firstValueFrom(result.afterClosed$);
    return !!event?.data;
  }

  async uploadFiles() {
    if (!this.fileManager) return;

    const fileManager = this.fileManager() as FileManagerComponent;

    const files = fileManager.fileQuery.getAll().filter((file) => !file.uploaded && !!file.rawFile);

    this.loading = true;

    if (this.removedFiles$.getValue().length) {
      const success = await this.removeAllFiles();
      this.removedFiles$.next([]);
      if ((!files || !files.length) && success) {
        await this.updateWorkflow();
        this.closeModal(false);
      }
    }

    if (files && files.length) {
      files.forEach((file) => {
        this.fileManager()?.fileStore.update(file.id, {
          ...file,
          bucket_key: this.getBucketKey(file.bucket_key),
          document_type_id: this.documentTypeId,
        });
      });

      const fileSuccess = await this.fileManager()?.fileService.uploadFiles(
        {
          target_date:
            this.currentOpenMonth() ||
            this.quarterCloseChecklistPeriodCloseService.currentOpenMonth,
          entity_type_id: EntityType.DOCUMENT,
        },
        true,
        true,
        undefined,
        true,
        false,
        false,
        true
      );
      if (fileSuccess) {
        const user = this.authQuery.user();
        const sentFiles = files.map((file) => {
          return {
            ...file,
            create_date: dayjs().format('YYYY-MM-DD'),
            created_by: user.sub,
            uploaded: true,
          };
        });
        this.workflowService.isEDCDocumentSaved$.next(sentFiles);
        await this.updateWorkflow();
        this.loading = false;
        this.closeModal(fileSuccess);
        const fileNames = files
          .map((file) => {
            return file.fileName;
          })
          .join(', ');
        if (!this.authQuery.isAuxAdmin() && !user.email.includes('auxili.us')) {
          await firstValueFrom(
            this.eventService.processEvent$({
              type: EventType.EDC_DOCUMENT_UPLOADED_NOTIFICATION,
              entity_type: EntityType.DOCUMENT,
              entity_id: '',
              payload: JSON.stringify({
                month:
                  this.currentOpenMonth() ||
                  this.quarterCloseChecklistPeriodCloseService.currentOpenMonth,
                uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
                file_names: fileNames,
              }),
            })
          );
        }
        return fileSuccess;
      } else {
        this.overlayService.error('Error uploading files.');
        this.loading = false;
        return false;
      }
    }
    this.loading = false;
    return true;
  }
}
