import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  signal,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonToggleItem } from '@shared/components/button-toggle-group/button-toggle-item.model';
import { Option } from '@shared/types/components.type';
import { EditableListDropdownItem } from '@shared/components/editable-list-dropdown/editable-list-dropdown-item.model';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowStep } from '@shared/services/gql.service';
import { Maybe } from '@shared/utils/utils';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayService } from '@shared/services/overlay.service';

export interface ProtocolForm {
  protocolVersion: string | null;
  patientGroup: string | null;
}

type VersionOption = Option & { effective_date?: Maybe<string> };

@Component({
  selector: 'aux-protocol-section',
  templateUrl: './protocol-section.component.html',
  styleUrls: ['./protocol-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolSectionComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() patientOptions!: ButtonToggleItem[];

  @Input() template?: TemplateRef<unknown>;

  @Input() className = '';

  @Input() alwaysShowSection = false;

  @Input() isAmendmentDisabled = false;

  @Input() isVersionControlEditable = false;

  userHasUpdateProtocolEntryPermission = input(false);

  @Output() editProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Output() deleteProtocolVersion = new EventEmitter<EditableListDropdownItem>();

  @Input() hideAmendment = false;

  @Output() valuesChange = new EventEmitter();

  @Output() formReady = new EventEmitter<FormGroup>();

  @Output() amendmentClick = new EventEmitter<boolean>();

  @Input() labelDirection: 'horizontal' | 'vertical' = 'horizontal';

  @Input() hasChanges = false;

  private _versionOptions!: VersionOption[];

  get versionOptions(): VersionOption[] {
    return this._versionOptions;
  }

  @Input() set versionOptions(value: VersionOption[]) {
    this._versionOptions = value;

    this.enhancedVersionList.set(this.convertOptionToEditableState(value));
  }

  enhancedVersionList = signal<EditableListDropdownItem[]>([]);

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientTrackerLockedTooltip = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  noPermissionTooltip = computed(() =>
    this.userHasUpdateProtocolEntryPermission()
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
  );

  form = this.fb.group<ProtocolForm>({
    protocolVersion: null,
    patientGroup: null,
  });

  constructor(
    private fb: FormBuilder,
    private workflowQuery: WorkflowQuery,
    private overlayService: OverlayService
  ) {
    effect(
      () => {
        this.enhancedVersionList.set(this.convertOptionToEditableState(this.versionOptions));
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    let previousFormValue = this.form.value;

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (formValues) => {
        const canDeactivate = await this.canDeactivate();

        if (!canDeactivate) {
          this.form.patchValue(previousFormValue, { emitEvent: false });
          return;
        }
        previousFormValue = formValues;
        this.valuesChange.emit(formValues);
      });

    this.formReady.emit(this.form);
  }

  onCreateAmendment = () => {
    this.amendmentClick.emit(true);
  };

  getOptionWithVisibility(option: ButtonToggleItem): ButtonToggleItem {
    return !option.show ? { ...option, show: new BehaviorSubject(true) } : option;
  }

  convertOptionToEditableState(
    versionOptions: typeof this.versionOptions
  ): EditableListDropdownItem[] {
    return versionOptions.map((item, i, arr) => ({
      name: item.label,
      value: item.value,
      showLine: i === 1 && arr.length !== 1,
      isEditable: this.userHasUpdateProtocolEntryPermission(),
      isDeletable: i !== 0 && this.userHasUpdateProtocolEntryPermission(),
      bottomText: `Effective Date: ${item.effective_date || ''}`,
      disabled: this.isPatientsFinalized(),
      tooltip: this.patientTrackerLockedTooltip(),
    }));
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }
}
