<ng-container *ngIf="{ trialTasks: (trialTasks$ | async) || [] } as obj">
  <div
    #trigger="cdkOverlayOrigin"
    class="text-white flex items-center text-sm cursor-pointer px-4"
    cdkOverlayOrigin
    [ngClass]="{ 'bg-aux-gray-light text-aux-blue': isOpen }"
    (click)="toggleOpenList(!isOpen)"
    (auxClickOutside)="toggleOpenList(false)"
  >
    <aux-icon name="Checkbox" />
    <span class="mx-5px">Tasks</span>
    <span
      *ngIf="obj.trialTasks.length"
      class="rounded-full text-aux-blue font-bold bg-aux-gray-light border border-aux-blue h-8 w-8 flex justify-center items-center"
    >
      <span>{{ obj.trialTasks.length }}</span>
    </span>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <ng-container *ngIf="trialTasks$ | async as trialTasks">
      <aux-portfolio-task-list
        class="bg-white border-l border-aux-gray-dark shadow overflow-y-scroll"
        style="width: 400px; max-height: 420px"
        [tasks]="trialTasks"
        [noTasksMessage]="noMessage"
        [ngStyle]="{ height: obj.trialTasks.length ? 'auto' : '420px' }"
      />
    </ng-container>
  </ng-template>
</ng-container>
