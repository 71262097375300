import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Params, Router } from '@angular/router';
import {
  EntityType,
  EventType,
  GqlService,
  listInvoicesForReconciliationQuery,
  listNotesQuery,
  NoteType,
  PermissionType,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { ExportType, Utils } from '@shared/utils/utils';
import { find, isEqual, sortBy } from 'lodash-es';
import { distinctUntilChanged, tap, first, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, forkJoin, Observable } from 'rxjs';
import dayjs from 'dayjs';
import { EventService } from '@models/event/event.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { Workflow } from '@shared/store/workflow/workflow.store';
import {
  ChecklistComponentChangeLockFn,
  QuarterCloseChecklistRowComponents,
  QuarterCloseChecklistVendorEstimateSummary,
  QuarterCloseChecklistSection,
  ChecklistMonthlyDropdownDate,
  QuarterCloseChecklistPermissionList,
  QuarterCloseChecklistRowSections,
} from './models/quarter-close-checklist.model';
import { QuarterCloseChecklistToggleService } from './services/quarter-close-checklist-toggle.service';
import { QuarterCloseChecklistWorkflowService } from './services/quarter-close-checklist-workflow.service';
import { QuarterCloseChecklistService } from './services/quarter-close-checklist.service';
import { AddVendorEstimateUploadComponent } from '../quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { AddPoReportUploadComponent } from '../quarter-close/add-po-report-upload/add-po-report-upload.component';
import { MainQuery } from '@shared/store/main/main.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { QuarterCloseAdjustmentsService } from '../quarter-close-adjustments/quarter-close-adjustments.service';
import {
  DEFAULT_TASK_INSTRUCTIONS,
  QuarterCloseNotesStore,
} from '@shared/store/quarter-close-notes/quarter-close-notes.store';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import { selectWithGuardChangesModalCheck } from '@shared/utils/select-option';
import { UnsavedChangesHelperService } from '@shared/services/unsaved-changes-helper.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { QuarterCloseChecklistPeriodCloseService } from './services/quarter-close-checklist-period-close.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-quarter-close-checklist',
  templateUrl: './quarter-close-checklist.component.html',
  styleUrls: ['./quarter-close-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseChecklistComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('selectMonthEl') selectMonthEl?: NgSelectComponent;

  protected readonly PermissionType = PermissionType;

  sections = this.checklistService.Sections;

  sectionTitles = this.checklistService.SectionTitles;

  rows = this.checklistService.Rows;

  rowTitles = this.checklistService.RowTitles;

  rowComponents: QuarterCloseChecklistRowComponents = this.checklistService.RowComponents;

  isAdminUser = false;

  isCurrentQuarterSelected = false;

  isLoadingList = false;

  isLockingAll = false;

  isAdjustmentAvailable = false;

  isClosedMonthsProcessing: boolean | null = null;

  isQuarterCloseEnabled = false;

  gatherDocumentsSectionComplete = false;

  confirmForecastSectionComplete = false;

  closeExpensesRowComplete = false;

  workflowList: Workflow[] = [];

  vendorEstimateSummaries: QuarterCloseChecklistVendorEstimateSummary[] = [];

  selectedQuarterMonthFormControl = new FormControl('', {
    nonNullable: true,
  });

  selectedQuarterMonth = '';

  isPastQuarterMonth = false;

  currentOpenMonth = '';

  selectedPeriodQuery = signal<Params>({});

  quarterMonths: ChecklistMonthlyDropdownDate[] = [];

  ungroupedInvoices: listInvoicesForReconciliationQuery[] = [];

  numberOfInvoices = Utils.zeroHyphen;

  amountInvoiced = Utils.zeroHyphen;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  permissionList: QuarterCloseChecklistPermissionList = {
    userHasChecklistContractsPermission: false,
    userHasChecklistDiscountsPermission: false,
    userHasChecklistForecastMethodologyPermission: false,
    userHasChecklistInvoicesPermission: false,
    userHasChecklistPatientDataPermission: false,
    userHasChecklistPatientSiteCurvePermission: false,
    userHasChecklistTrialTimelinePermission: false,
    userHasChecklistVendorEstimatesPermission: false,
    userHasChecklistVendorExpensesPermission: false,
    userHasChecklistAdminPermission: false,
  };

  quarterCloseChecklistTitle$(workflowList: Workflow[]): Observable<string> {
    return this.periodCloseService.isCurrentQuarterSelected.pipe(
      first(),
      map(() => {
        const workflowLength = this.workflowList.filter(
          (workflow) => workflow.section !== QuarterCloseChecklistRowSections['Close Month']
        ).length;
        const date = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url));
        const isDateValid = date.isValid();
        const dateStr = isDateValid ? date.format('MMMM YYYY') : '';

        return `${dateStr} - Closing Checklist (${this.workflowService.completedTotals(
          workflowList
        )}/${workflowLength} Complete)`;
      })
    );
  }

  constructor(
    private checklistService: QuarterCloseChecklistService,
    public toggleService: QuarterCloseChecklistToggleService,
    public workflowService: QuarterCloseChecklistWorkflowService,
    public originalWorkflowService: WorkflowService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private overlayService: OverlayService,
    private changeDetectorRef: ChangeDetectorRef,
    private workflowQuery: WorkflowQuery,
    private router: Router,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private authService: AuthService,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private quarterCloseNotesStore: QuarterCloseNotesStore,
    private quarterCloseNotesQuery: QuarterCloseNotesQuery,
    private unsavedChangesHelperService: UnsavedChangesHelperService,
    private eventService: EventService
  ) {
    this.setUserPermissions();
  }

  ngOnInit(): void {
    this.checklistService
      .isAdminUser()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAdminUser) => {
        this.isAdminUser = isAdminUser;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isCurrentQuarterSelected()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isCurrentQuarterSelected) => {
        this.resetWorkflow();
        this.configureDates(isCurrentQuarterSelected);
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isClosedMonthsProcessing()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isClosedMonthsProcessing) => {
        this.isClosedMonthsProcessing = isClosedMonthsProcessing;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isQuarterCloseEnabled()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isQuarterCloseEnabled) => {
        this.isQuarterCloseEnabled = isQuarterCloseEnabled;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .workflowAdjustmentAvailable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isAdjustmentAvailable) => {
        this.isAdjustmentAvailable = isAdjustmentAvailable;
        this.changeDetectorRef.detectChanges();

        if (!this.isAdjustmentAvailable) {
          this.workflowQuery.getAll();
        }
      });

    this.checklistService
      .workflowLoading()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoadingList) => {
        this.isLoadingList = isLoadingList;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .workflowList()
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(isEqual))
      .subscribe((workflowList: Workflow[]) => {
        this.workflowList = workflowList;
        this.fetchChecklistNotes();
        this.disabledWorkflowLocks();
        this.configureInvoiceData();

        this.changeDetectorRef.detectChanges();
      });

    this.selectedQuarterMonthFormControl.valueChanges
      .pipe(
        startWith(this.selectedQuarterMonthFormControl.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((selectedQuarterMonth) => {
        this.getVendorEstimateSummaries();
        this.updateSelectedPeriodQuery(selectedQuarterMonth);
      });

    this.selectedQuarterMonthFormControl.valueChanges
      .pipe(
        startWith(this.selectedQuarterMonthFormControl.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((selectedQuarterMonth) => {
        this.configureSelectedQuarterMonthChanges(selectedQuarterMonth, false);
        if (selectedQuarterMonth?.length) {
          this.quarterCloseNotesStore.update((state) => ({
            ...state,
            selectedPeriod: selectedQuarterMonth,
          }));
        }
      });
  }

  private fetchChecklistNotes(): void {
    forkJoin(
      this.quarterCloseNotesQuery.activeWorkflowList().map((workflow) =>
        this.gqlService
          .listNotes$({
            entity_id: workflow.id,
            entity_type: EntityType.WF_DETAIL,
          })
          .pipe(
            tap((response: GraphqlResponse<listNotesQuery[]>) =>
              this.saveNotesToStore(workflow, response)
            )
          )
      )
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.resetWorkflow();
  }

  async selectMonth(event: MouseEvent, value: string, disabled: boolean): Promise<void> {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    await selectWithGuardChangesModalCheck(
      event,
      value,
      this.unsavedChangesHelperService.getUnsavedChangesDecisionByRouterUrl(),
      this.overlayService,
      this.selectedQuarterMonthFormControl,
      this.selectMonthEl
    );
  }

  resetWorkflow(): void {
    const latestMonth = this.periodCloseService.getLatestMonth();

    this.configureSelectedQuarterMonthChanges(latestMonth, true);
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  onExportChecklist = async () => {
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const date = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url));
    const isDateValid = date.isValid();
    const dateStr = isDateValid ? date.format('MMM-YYYY') : '';

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.CLOSING_PERIOD_CHECKLIST,
          filename: `${dateStr}_closing-period-checklist`,
          export_entity_id: 'WF_MONTH_CLOSE_LOCK',
          json_properties: { month: dateStr },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  };

  configureDates(isCurrentQuarterSelected: boolean): void {
    this.isLoadingList = true;
    this.isCurrentQuarterSelected = isCurrentQuarterSelected;
    this.ungroupedInvoices = this.periodCloseService.ungroupedInvoices;
    if (!this.currentOpenMonth) {
      this.currentOpenMonth = this.periodCloseService.currentOpenMonth;
    }
    this.quarterMonths = this.workflowService.configureQuarterMonths(
      this.periodCloseService.quarterMonths,
      this.currentOpenMonth
    );

    const selectedMonth = this.periodCloseService.selectedQuarterMonth;
    const selectedQuarterMonth = this.workflowService.parseToStartOfMonth(selectedMonth);
    const persistedQuarterMonth = this.periodCloseService.getSelectedQuarterMonth(this.router.url);
    const sameMonths = selectedQuarterMonth === persistedQuarterMonth;

    if (!selectedQuarterMonth) {
      return;
    }

    this.selectedQuarterMonth = selectedQuarterMonth;

    this.updateSelectedPeriodQuery(selectedQuarterMonth);

    if (sameMonths) {
      this.selectedQuarterMonthFormControl.setValue(selectedQuarterMonth, { emitEvent: false });
      this.configureSelectedQuarterMonthChanges(selectedQuarterMonth, false);
    } else {
      this.selectedQuarterMonthFormControl.setValue(persistedQuarterMonth, { emitEvent: false });
      this.configureSelectedQuarterMonthChanges(persistedQuarterMonth, false);
    }
    this.quarterCloseNotesStore.update((state) => ({
      ...state,
      selectedPeriod: this.selectedQuarterMonthFormControl.value,
    }));
  }

  async canDeactivate(): Promise<boolean> {
    if (this.quarterCloseNotesQuery.someNoteEditorHasUnsavedChanges()) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }
    return true;
  }

  updateSelectedPeriodQuery(selectedQuarterMonth: string): void {
    const date = dayjs(selectedQuarterMonth);

    this.selectedPeriodQuery.set({
      startDate: date.startOf('month').format('YYYY-MM-DD'),
      endDate: date.endOf('month').format('YYYY-MM-DD'),
    });
  }

  configureInvoiceData(): void {
    const invoiceQuickView = this.workflowService.calculateInvoiceMonthlyQuickView(
      this.selectedQuarterMonth,
      this.ungroupedInvoices
    );

    this.numberOfInvoices = invoiceQuickView.numberOfInvoices;
    this.amountInvoiced = invoiceQuickView.amountInvoiced;
  }

  configureSelectedQuarterMonthChanges(
    selectedQuarterMonth: string | null,
    onDestroy: boolean
  ): void {
    if (!selectedQuarterMonth) {
      this.checklistService.resetWorkflowState();
      return;
    }

    if (selectedQuarterMonth === this.selectedQuarterMonth) {
      return;
    }

    this.selectedQuarterMonth = selectedQuarterMonth;
    this.periodCloseService.selectedQuarterMonth = selectedQuarterMonth;
    this.currentOpenMonth = this.periodCloseService.currentOpenMonth;

    this.isPastQuarterMonth = this.workflowService.isPastQuarterMonth(
      selectedQuarterMonth,
      this.currentOpenMonth
    );

    // Persist selected month
    if (!onDestroy) {
      this.periodCloseService.persistedQuarterMonth = selectedQuarterMonth;
    }

    this.periodCloseService.selectedQuarterMonthChanged$.next(null);

    // Pull selected month
    this.checklistService.onQuarterMonthChange(selectedQuarterMonth);
  }

  reviewVendorEstimateOpened() {
    this.getVendorEstimateSummaries();
  }

  disabledWorkflowLocks(): void {
    if (!this.workflowList.length) {
      return;
    }

    this.gatherDocumentsSectionComplete = this.workflowService.gatherDocumentsSectionCompleted(
      this.workflowList
    );
    this.confirmForecastSectionComplete = this.workflowService.confirmForecastSectionCompleted(
      this.workflowList
    );
    this.closeExpensesRowComplete = this.workflowService.closeExpensesRowCompleted(
      this.workflowList
    );

    this.changeDetectorRef.detectChanges();
  }

  isChecklistBannerDisabled(): boolean {
    return !(
      this.gatherDocumentsSectionComplete &&
      this.confirmForecastSectionComplete &&
      this.closeExpensesRowComplete &&
      this.workflowService.workflow(this.rowTitles.CloseDiscounts, this.workflowList)?.properties
        .locked
    );
  }

  isSelectedMonthOpen(): boolean {
    return dayjs(this.currentOpenMonth).isSame(dayjs(this.selectedQuarterMonth));
  }

  onAddVendorEstimateUploadClick: () => void = async () => {
    const ref = this.overlayService.openPopup<
      undefined,
      { vendorId?: string },
      AddVendorEstimateUploadComponent
    >({
      settings: {
        header: 'Send Vendor Estimate Support to Auxilius',
        primaryButton: {
          label: 'Send Vendor Estimate Support',
          action: (instance) => instance?.onUpload(),
        },
      },
      content: AddVendorEstimateUploadComponent,
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    this.getVendorEstimateSummaries();

    const vendorId = resp.data?.vendorId;

    if (vendorId) {
      this.quarterCloseAdjustmentsService.updateFormControlValues('', vendorId);

      this.router.navigate([`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`], {
        queryParams: {
          vendorId: vendorId,
          editMode: true,
          currentOpenMonth: dayjs(this.currentOpenMonth).add(2, 'day').format('YYYY-MM-DD'),
        },
      });
    }
  };

  onAddPoReportUploadClick: () => void = () => {
    this.overlayService.openPopup({
      content: AddPoReportUploadComponent,
      settings: {
        header: 'Upload PO Report',
        primaryButton: {
          label: 'Upload',
          action: (instance: AddPoReportUploadComponent | null) => instance?.onUpload(),
        },
      },
    });
  };

  getVendorEstimateSummaries(): void {
    const period = this.selectedQuarterMonthFormControl.value;

    if (!period) {
      return;
    }

    const formattedPeriod = dayjs(period).format('MMM-YYYY');

    this.checklistService
      .vendorEstimateSummaries(formattedPeriod)
      .pipe(first())
      .subscribe((vendorEstimateSummaries) => {
        this.vendorEstimateSummaries = sortBy(vendorEstimateSummaries, ['name']);
        this.changeDetectorRef.detectChanges();
      });
  }

  lockAllWorkflows = async (section: QuarterCloseChecklistSection) => {
    this.isLockingAll = true;

    const workflows = this.workflowService.getLockAllWorkflows(section, this.workflowList);

    for (const workflow of workflows) {
      await this.changeLockStatus(true, workflow, false);
    }

    this.isLockingAll = false;
  };

  changeLockStatus: ChecklistComponentChangeLockFn = async (
    shouldLock: boolean,
    workflow: Workflow,
    isAssign: boolean
  ) => {
    return this.originalWorkflowService.changeLockStatus(shouldLock, workflow, isAssign);
  };

  private saveNotesToStore(workflow: Workflow, response: GraphqlResponse<listNotesQuery[]>): void {
    const instruction = find(
      response?.data,
      (listNotesQuery) => listNotesQuery.note_type === NoteType.NOTE_TYPE_TASK_INSTRUCTIONS
    );
    const monthlyReviewNotes =
      response?.data?.filter(
        (listNotesQuery) => listNotesQuery.note_type === NoteType.NOTE_TYPE_MONTHLY_REVIEW_NOTE
      ) || [];

    this.quarterCloseNotesStore.updateTaskInstruction(workflow.id, {
      text:
        instruction?.message ||
        DEFAULT_TASK_INSTRUCTIONS[workflow.step_type as keyof typeof DEFAULT_TASK_INSTRUCTIONS]
          .text,
      isDefault: !instruction?.message,
      updated: instruction?.update_date ? instruction.update_date : undefined,
      id: instruction?.id,
    });

    this.quarterCloseNotesStore.updateMonthlyReviewNotes(
      workflow.id,
      monthlyReviewNotes.map((note) => ({
        createdBy: note.created_by,
        created: note.create_date,
        text: note.message,
        id: note.id,
        workflowId: note.entity_id,
      }))
    );
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_CONTRACTS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_DISCOUNTS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_FORECAST_METHODOLOGY],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_INVOICES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_TRIAL_TIMELINE],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_VENDOR_ESTIMATES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_VENDOR_EXPENSES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_ADMIN],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          userHasChecklistContractsPermission,
          userHasChecklistDiscountsPermission,
          userHasChecklistForecastMethodologyPermission,
          userHasChecklistInvoicesPermission,
          userHasChecklistPatientDataPermission,
          userHasChecklistPatientSiteCurvePermission,
          userHasChecklistTrialTimelinePermission,
          userHasChecklistVendorEstimatesPermission,
          userHasChecklistVendorExpensesPermission,
          userHasChecklistAdminPermission,
        ]) => {
          this.permissionList = {
            userHasChecklistContractsPermission,
            userHasChecklistInvoicesPermission,
            userHasChecklistVendorEstimatesPermission,
            userHasChecklistPatientDataPermission,
            userHasChecklistTrialTimelinePermission,
            userHasChecklistPatientSiteCurvePermission,
            userHasChecklistForecastMethodologyPermission,
            userHasChecklistVendorExpensesPermission,
            userHasChecklistDiscountsPermission,
            userHasChecklistAdminPermission,
          };

          this.changeDetectorRef.markForCheck();
        }
      );
  }
}
