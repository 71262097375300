import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove } from '@datorama/akita';
import { OverlayService } from '@shared/services/overlay.service';
import {
  CreateSpecificationInput,
  CreateSpecificationSettingInput,
  GqlService,
  UpdateSpecificationInput,
  UpdateSpecificationSettingInput,
} from '@shared/services/gql.service';
import { NoNullFields } from '@shared/utils/utils';

import { SpecificationModel, SpecificationStore } from './specification.store';
import { SpecificationSettingStore } from '../specification-setting/specification-setting.store';
import { SpecificationCategoryStore } from '../specification-category/specification-category.store';
import { SpecificationQuery } from './specification.query';
import { batchPromises } from '@shared/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpecificationService {
  constructor(
    private specificationStore: SpecificationStore,
    private gqlService: GqlService,
    private specificationSettingStore: SpecificationSettingStore,
    private specificationCategoryStore: SpecificationCategoryStore,
    private overlayService: OverlayService,
    private specificationQuery: SpecificationQuery
  ) {}

  async add(
    specInput: CreateSpecificationInput,
    setInputs: Omit<CreateSpecificationSettingInput, 'specification_id'>[]
  ) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createSpecification$(specInput)
    );
    if (success && data) {
      const promises = [];
      for (const set of setInputs) {
        const promise = firstValueFrom(
          this.gqlService.createSpecificationSetting$({
            specification_id: data.id,
            ...set,
            setting_key: set.setting_key || null,
          })
        );
        promises.push(promise);
      }

      const savedSettings = await batchPromises(promises, (p) => p);

      const addedSettingsIDs = [];
      for (const resp of savedSettings) {
        if (!(resp instanceof Error) && resp.success && resp.data) {
          this.specificationSettingStore.add({
            ...resp.data,
            specification_type: resp.data.specification_type,
          });
          addedSettingsIDs.push(resp.data.id);
        }
      }

      this.specificationStore.add({ ...data, settings: addedSettingsIDs });

      this.specificationCategoryStore.update(specInput.category_id, ({ specifications }) => {
        return {
          specifications: arrayAdd(specifications, data.id),
        };
      });
    } else {
      this.overlayService.error(errors);
    }

    return {
      success,
      errors,
      data,
    };
  }

  async update(
    specInput: NoNullFields<Required<Omit<UpdateSpecificationInput, 'delete'>>>,
    setInputs: (NoNullFields<Required<Omit<UpdateSpecificationSettingInput, 'delete' | 'id'>>> & {
      id?: string;
    })[]
  ) {
    const spec = this.specificationQuery.getEntity(specInput.id);

    if (spec) {
      const promises: Promise<void>[] = [];
      const setIDs: string[] = [];
      const newSetIDs: string[] = [];
      for (const { specification_type, id: setID, setting_value, setting_key } of setInputs) {
        if (setID) {
          setIDs.push(setID);
          const prom = firstValueFrom(
            this.gqlService.updateSpecificationSetting$({
              id: setID,
              delete: false,
              specification_type,
              setting_key: setting_key || null,
              setting_value,
            })
          ).then(({ success, data }) => {
            if (success && data) {
              this.specificationSettingStore.update(data.id, {
                ...data,
                specification_type: data.specification_type,
              });
            }
          });
          promises.push(prom);
        } else {
          const prom = firstValueFrom(
            this.gqlService.createSpecificationSetting$({
              specification_id: specInput.id,
              specification_type,
              setting_key: setting_key || null,
              setting_value,
            })
          ).then(({ success, data }) => {
            if (success && data) {
              newSetIDs.push(data.id);
              this.specificationSettingStore.add({
                ...data,
                specification_type: data.specification_type,
              });
            }
          });

          promises.push(prom);
        }
      }
      const removedSetIDs = spec.settings.filter((x) => !setIDs.includes(x));

      for (const removedSetID of removedSetIDs) {
        const prom = firstValueFrom(
          this.gqlService.updateSpecificationSetting$({
            id: removedSetID,
            delete: true,
          })
        ).then(({ success }) => {
          if (success) {
            this.specificationSettingStore.remove(removedSetID);
          }
        });

        promises.push(prom);
      }

      const prom = firstValueFrom(
        this.gqlService.updateSpecification$({
          id: specInput.id,
          name: specInput.name,
          delete: false,
          category_id: specInput.category_id,
          input_type: specInput.input_type,
        })
      );

      await batchPromises<Promise<unknown>, unknown>([prom, ...promises], (p) => p);

      this.specificationStore.update(specInput.id, () => {
        return {
          input_type: specInput.input_type,
          name: specInput.name,
          settings: [...setIDs, ...newSetIDs],
        };
      });
    }
  }

  async remove(id: string, cat_id: string) {
    const spec = this.specificationQuery.getEntity(id) as SpecificationModel;
    const { success, data, errors } = await firstValueFrom(
      this.gqlService.updateSpecification$({
        id,
        category_id: cat_id,
        delete: true,
      })
    );
    if (success && data) {
      this.specificationCategoryStore.update(cat_id, ({ specifications }) => ({
        specifications: arrayRemove(specifications, id),
      }));
      this.specificationStore.remove(id);
      this.specificationSettingStore.remove(spec.settings);
    } else {
      this.overlayService.error(errors);
    }

    return { success, data, errors };
  }
}
