import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Query } from '@datorama/akita';
import { CustomCurvesState, CustomCurvesStore } from './custom-curves.state';

@Injectable({ providedIn: 'root' })
export class CustomCurvesQuery extends Query<CustomCurvesState> {
  curves = toSignal(this.select('curves'), { requireSync: true });

  curveToCreateOrEdit = toSignal(this.select('curveToCreateOrEdit'), { requireSync: true });

  selectedCurve = toSignal(this.select('selectedCurve'), { requireSync: true });

  currentDistributions = toSignal(this.select('currentDistributions'), { requireSync: true });

  constructor(protected store: CustomCurvesStore) {
    super(store);
  }
}
