import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { SpecificationSetting, SpecificationSettingType } from '@shared/services/gql.service';
import { RequireSome } from '@shared/utils/utils';

export type SpecificationSettingState = EntityState<
  Omit<RequireSome<Partial<SpecificationSetting>, 'id'>, 'specification_type'> & {
    specification_type: SpecificationSettingType;
  }
>;

export type SpecificationSettingModel = getEntityType<SpecificationSettingState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'specification-setting' })
export class SpecificationSettingStore extends EntityStore<SpecificationSettingState> {
  constructor() {
    super({});
  }
}
