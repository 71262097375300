import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ColDef, GridApi, ICellEditorParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  ExtendableOptionsDropdownComponent,
  Option,
} from '@shared/components/extendable-options-dropdown/extendable-options-dropdown.component';
import { findIndex } from 'lodash';
import { ExistingAttributesService } from '../services/existing-attributes.service';

@Component({
  selector: 'aux-attributes-dropdown',
  templateUrl: 'attributes-dropdown.component.html',
  styleUrl: './attributes-dropdown.component.scss',
  standalone: true,
  imports: [ExtendableOptionsDropdownComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesDropdownComponent implements ICellEditorAngularComp {
  @ViewChild(ExtendableOptionsDropdownComponent)
  dropdownComponent!: ExtendableOptionsDropdownComponent;

  attributeControl = new FormControl<string | null>(null);

  gridApi!: GridApi;

  params!: ICellEditorParams;

  private attributeId!: string;

  private existingAttributeService = inject(ExistingAttributesService);

  private changeDetectorRef = inject(ChangeDetectorRef);

  items = computed<Option[]>(() => {
    return (
      this.existingAttributeService
        .existingAttributes()
        .find((attr) => attr.id === this.attributeId)
        ?.items.map((value) => ({
          id: value,
          value,
        })) || []
    );
  });

  get initialSearchTerm(): string {
    if (typeof this.params?.eventKey === 'string') {
      return this.params.eventKey.length === 1 ? this.params.eventKey : '';
    }

    return '';
  }

  agInit(params: ICellEditorParams): void {
    const attributeId = (params.colDef as ColDef).field as string;
    this.attributeControl.setValue(params.value || null);
    this.gridApi = params.api;
    this.params = params;
    this.attributeId = attributeId || '';
    this.addNewAttributesList();
    this.addNewItemIfNeeded(params.value);
    this.checkAttributeListIsCorrect(params);
  }

  onAddOption(option: Option): void {
    this.existingAttributeService.updateList(this.attributeId, [
      ...this.existingAttributeService.existingAttributes()[this.attributeIndex].items,
      option.value,
    ]);
  }

  getValue(): string {
    return this.attributeControl.value || '';
  }

  close(): void {
    this.dropdownComponent?.close();
    this.dropdownComponent?.detectChanges();
  }

  stopEditing(): void {
    this.gridApi.stopEditing();
    this.gridApi.setFocusedCell(this.params.rowIndex, this.params.column.getColId());
    this.changeDetectorRef.detectChanges();
  }

  private addNewItemIfNeeded(value: string): void {
    if (!value) {
      return;
    }
    const list = this.existingAttributeService
      .existingAttributes()
      .find((attr) => attr.id === this.attributeId);
    if (list && list.items.every((item) => item !== value)) {
      this.existingAttributeService.updateList(this.attributeId, [
        ...this.existingAttributeService.existingAttributes()[this.attributeIndex].items,
        value,
      ]);
    }
  }

  getColumnData(columnField: string, gridApi: GridApi): string[] {
    const columnData = new Set();
    gridApi.forEachNode((node) => {
      // attributes . <columnKey>
      const option = node.data.attributes[columnField.split('.')[1]];
      if (option) {
        columnData.add(option);
      }
    });
    return Array.from(columnData) as string[];
  }

  private addNewAttributesList(): void {
    if (
      this.existingAttributeService
        .existingAttributes()
        .every((attr) => attr.id !== this.attributeId)
    ) {
      this.existingAttributeService.existingAttributes.set([
        ...this.existingAttributeService.existingAttributes(),
        { id: this.attributeId, items: [] },
      ]);
    }
  }

  private checkAttributeListIsCorrect(params: ICellEditorParams): void {
    if (
      this.existingAttributeService
        .existingAttributes()
        .some((attr) => attr.id === this.attributeId)
    ) {
      const list = this.existingAttributeService
        .existingAttributes()
        .find((attr) => attr.id === this.attributeId);
      const columnData = this.getColumnData(params.column.getColId(), params.api);
      if (list && columnData.length) {
        const notShownOptions = columnData.filter((val) => !list.items.includes(val));
        notShownOptions.forEach((opt) => {
          if (opt) {
            this.existingAttributeService.updateList(this.attributeId, [
              ...this.existingAttributeService.existingAttributes()[this.attributeIndex].items,
              opt,
            ]);
          }
        });
      }
    }
  }

  private get attributeIndex(): number {
    return findIndex(
      this.existingAttributeService.existingAttributes(),
      (attr) => attr.id === this.attributeId
    );
  }
}
