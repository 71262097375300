import { computed, Directive, inject } from '@angular/core';
import { PermissionType, WorkflowStep } from '@shared/services/gql.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';

@Directive({ standalone: true })
export class VendorWorkflowDirective {
  private readonly workflowQuery = inject(WorkflowQuery);

  private readonly authService = inject(AuthService);

  userHasVendorPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_VENDORS],
  });

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  disabledUI = computed(
    () => !this.userHasVendorPermission() || this.isChangeOrdersWorkflowLocked()
  );

  vendorActionTooltip = computed(() => {
    if (!this.userHasVendorPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (this.isChangeOrdersWorkflowLocked()) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  });
}
