import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent {
  constructor(public ref: CustomOverlayRef<unknown, { header: string }>) {}

  async onSubmit() {
    this.ref.close();
  }

  async onSecondarySubmit() {
    this.ref.close();
  }

  onCancel() {
    this.ref.close();
  }

  close() {
    this.ref.close();
  }
}
