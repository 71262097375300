import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { RouterLink } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  template: `
    <div class="whitespace-normal text-center">
      <a class="aux-link cursor-pointer" [routerLink]="routerLink">Prepaid</a>
      Impact
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class AgPrepaidHeaderComponent implements IHeaderAngularComp {
  agInit(): void {}

  refresh(): boolean {
    return false;
  }

  protected readonly routerLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PREPAIDS}`;
}
