import { Injectable } from '@angular/core';
import {
  createSitePaymentScheduleMutation,
  Currency,
  GqlService,
  listPatientProtocolsQuery,
  PatientProtocolType,
  updateSitePaymentScheduleMutation,
} from '@shared/services/gql.service';
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { GridRowData } from './column-defs';
import { batchPromises } from '@shared/utils';

@Injectable({ providedIn: 'root' })
export class SiteBudgetGridAmountService {
  constructor(private gqlService: GqlService) {}

  saveGridData(gridData: GridRowData[], siteId: string, siteBudgetVersionId: string) {
    const promises: Promise<
      GraphqlResponse<updateSitePaymentScheduleMutation | createSitePaymentScheduleMutation>
    >[] = [];

    gridData.forEach((row) => {
      if (row.paymentScheduleId) {
        promises.push(
          firstValueFrom(
            this.gqlService.updateSitePaymentSchedule$({
              id: row.paymentScheduleId,
              patient_protocol_id: row.id,
              note: row.note || '',
              amount: row.amount || 0,
            })
          )
        );
      } else {
        promises.push(
          firstValueFrom(
            this.gqlService.createSitePaymentSchedule$({
              site_id: siteId,
              note: row.note,
              amount: row.amount,
              patient_protocol_id: row.id,
              site_budget_version_id: siteBudgetVersionId,
            })
          )
        );
      }
    });

    return batchPromises(promises, async (params) => (await params).errors, 10);
  }

  getGridData(
    patientGroup: string,
    siteId: string,
    protocolVersionId: string,
    siteBudgetId: string
  ): Observable<GridRowData[]> {
    const patientProtocolTypes = this.getPatientProtocolTypes(patientGroup);

    return combineLatest([
      this.gqlService.listPatientProtocols$(patientProtocolTypes, protocolVersionId),
      this.gqlService.listSitePaymentSchedules$(patientProtocolTypes, siteId, protocolVersionId),
    ]).pipe(
      map(([{ data: protocols }, { data: paymentSchedule }]) => {
        if (!protocols || !paymentSchedule) {
          return [];
        }

        const paymentScheduleBySiteBudgetId = paymentSchedule.filter(
          ({ site_budget_version_id }) => site_budget_version_id === siteBudgetId
        );

        return this.getProtocolsToDisplay(protocols, patientGroup).map((protocol) => {
          const payment = paymentScheduleBySiteBudgetId.find(
            ({ patient_protocol }) => patient_protocol.id === protocol.id
          );

          return {
            id: protocol.id,
            name: protocol.name,
            optional: protocol.optional,
            amount: payment?.expense_amount?.amount || 0,
            note: payment?.note || '',
            paymentScheduleId: payment?.id || null,
            currency: (payment?.expense_amount?.amount_curr || Currency.USD) as Currency,
          };
        });
      })
    );
  }

  private getProtocolsToDisplay(
    protocols: listPatientProtocolsQuery[],
    patientGroup: string
  ): listPatientProtocolsQuery[] {
    const isInvoiceablesSelected = patientGroup === 'invoiceables';
    const isPatientVisit = patientGroup === 'visits_costs';

    if (isInvoiceablesSelected) {
      return protocols;
    }

    return protocols.filter(({ patient_group_id }) =>
      isPatientVisit ? !patient_group_id : patient_group_id === patientGroup
    );
  }

  private getPatientProtocolTypes(patientGroup: string): PatientProtocolType[] {
    const isInvoiceablesSelected = patientGroup === 'invoiceables';

    return isInvoiceablesSelected
      ? [
          PatientProtocolType.PATIENT_PROTOCOL_OTHER,
          PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
          PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
          PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
        ]
      : [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT];
  }
}
