import { Injectable } from '@angular/core';
import { fetchAuthSession } from '@aws-amplify/auth';
import { EntityType, EventType, GqlService, RoleType } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { TrialsService } from '@models/trials/trials.service';
import { ScriptLoaderService } from '@shared/services/script-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/store/auth/auth.service';
import { CommonConstants } from '@shared/constants/common.constants';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

import { MainStore } from './main.store';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { EventService } from '@models/event/event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class MainService {
  setTrialIsLoading = new BehaviorSubject(false);

  constructor(
    private mainStore: MainStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private trialsQuery: TrialsQuery,
    private trialUserService: TrialUserService,
    private trialsService: TrialsService,
    private scriptLoaderService: ScriptLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private eventService: EventService
  ) {
    this.authQuery.isLoggedIn$
      .pipe(
        takeUntilDestroyed(),
        switchMap((x: boolean) => {
          if (x) {
            this.mainStore.setLoading(true);
            return this.trialsService.get().pipe(
              tap(async ({ data }) => {
                if (data?.length) {
                  let trialKey: string;
                  const { queryParams } = this.route.snapshot;
                  const trialId = queryParams[CommonConstants.TRIAL_ID_QUERY_PARAMETER_NAME];
                  const isTrialFromQueryParameterExisting = !!data.filter((d) => d.id === trialId)
                    .length;

                  if (trialId && isTrialFromQueryParameterExisting) {
                    trialKey = trialId;
                  } else if (trialId && !isTrialFromQueryParameterExisting) {
                    trialKey = this.authQuery.getValue().trial_id;
                    this.router.navigate([], { queryParams: {}, replaceUrl: true });
                  } else {
                    trialKey = this.authQuery.getValue().trial_id;
                  }

                  const selectedTrial = data.find((trial) => trial.id === trialKey) || data[0];

                  await this.setTrial(selectedTrial.id, false, true);
                } else {
                  this.mainStore.update({ trialKey: '' });
                }
                this.mainStore.setLoading(false);
              })
            );
          }
          this.mainStore.setLoading(false);
          return of([]);
        })
      )
      .subscribe();

    this.authQuery.isLoggedIn$.pipe(takeUntilDestroyed()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.scriptLoaderService.injectJiraWidget();
      }
    });
  }

  showLoadingOverlay() {
    this.setTrialIsLoading.next(true);
    return this.overlayService.loading();
  }

  closeLoadingOverlay(overlay: CustomOverlayRef) {
    overlay.close();
    this.setTrialIsLoading.next(false);
  }

  async setTrial(value: string, showLoading = true, initial = false) {
    if (value) {
      const ref = showLoading && this.showLoadingOverlay();
      this.mainStore.update({ isTrialLoading: true });

      try {
        const { success, errors } = await firstValueFrom(this.gqlService.setTrial$(value));
        if (!success) {
          this.overlayService.error(errors);
        } else {
          await fetchAuthSession({ forceRefresh: true });

          const trialChanged = this.mainStore.getValue().trialKey !== value;
          if (trialChanged) {
            if (!initial || this.authQuery.getValue().permissions.length === 0) {
              await this.authService.setUserAttributes();
            }
          }
        }
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }

      const { sub, given_name, family_name, is_admin, email } = this.authQuery.getValue();
      const trial = this.trialsQuery.getEntity(value);
      if (is_admin && trial) {
        const loggedInUser = initial
          ? {
              success: true,
              data: this.authQuery.getValue(),
              errors: [],
            }
          : await firstValueFrom(this.gqlService.loggedInUser$());

        if (!loggedInUser?.success) {
          this.overlayService.error(loggedInUser.errors);
        }

        const { success, errors } = await this.trialUserService.addToTrial({
          given_name: loggedInUser?.data?.given_name || given_name,
          role: RoleType.ROLE_ADMIN,
          family_name: loggedInUser?.data?.family_name || family_name,
          title: loggedInUser?.data?.title || 'Administrator',
          department: loggedInUser?.data?.department || [],
          trial_id: value,
          user_id: sub,
          user_email: email,
        });
        if (!success) {
          this.overlayService.error(errors);
        }
      }

      if (ref) {
        this.closeLoadingOverlay(ref);
      }

      // if the user first time logging in then send the email after setTrial
      if (localStorage.getItem(LocalStorageKey.SEND_PASSWORD_UPDATED_EMAIL)) {
        const userSet = await this.authService.setUserAttributes();
        await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.USER_PASSWORD_UPDATED,
            entity_type: EntityType.USER,
            entity_id: '',
            payload: JSON.stringify({
              password_changed_for: `${userSet.email}`,
            }),
          })
        );

        localStorage.removeItem(LocalStorageKey.SEND_PASSWORD_UPDATED_EMAIL);
      }

      await this.mainStore.setTrial();
      this.mainStore.update({ isTrialLoading: false });
    }
  }

  getOpenMonth$() {
    return this.gqlService.currentOpenBudgetMonth$().pipe(
      tap(({ data }) => {
        if (!data) {
          return;
        }

        const currentOpenMonth = data.length ? data[0].trial_month_close : '';
        const trialStartDate = data.length ? data[0].trial_start_date : '';
        const trialEndDate = data.length ? data[0].trial_end_date : '';

        this.mainStore.update((state) => ({
          ...state,
          currentOpenMonth,
          trialStartDate,
          trialEndDate,
        }));
      })
    );
  }

  getUserList$() {
    return this.gqlService.listUserNamesWithEmail$(true).pipe(
      tap(({ data }) => {
        this.mainStore.update((state) => ({
          ...state,
          userList: data || [],
        }));
      })
    );
  }
}
