import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { SubscriberFn } from '@shared/utils/utils';
import { TrialInsightsClinicalQueryService } from './trial-insights-clinical-query.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './trial-insights-clinical.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsClinicalComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  trialLoading = true;

  componentsLoading = true;

  constructor(
    private queryService: TrialInsightsClinicalQueryService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.queryService.pageLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.processLoadingState);
  }

  processLoadingState: SubscriberFn<TrialInsightsClinicalQueryService['pageLoading$']> = (
    pageLoading
  ) => {
    const [trialLoading, componentsLoading] = pageLoading;

    this.trialLoading = trialLoading;
    this.componentsLoading = componentsLoading;

    this.cdr.markForCheck();
  };
}
