import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, switchMap, combineLatest, firstValueFrom } from 'rxjs';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { OverlayService } from '@shared/services/overlay.service';
import { OrganizationService } from '@models/organization/organization.service';
import { PurchaseOrdersService } from '@pages/vendor-payments-page/tabs/purchase-orders/state/purchase-orders.service';

import { InvoiceDetailGoBackComponent } from './invoice-detail-go-back.component';
import { InvoiceDetailTitleComponent } from './invoice-detail-title.component';
import { InvoiceDetailStatusBannerComponent } from './invoice-detail-status-banner.component';
import { InvoiceFormComponent } from './invoice-form.component';
import { InvoiceTabsComponent } from './invoice-tabs/invoice-tabs.component';
import { WorkflowService } from '@shared/store/workflow/workflow.service';

@Component({
  standalone: true,
  template: `
    @if (loading()) {
      <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
    } @else if (!invoice()) {
      <div class="text-center mt-40 m-auto">No invoice found</div>
    } @else {
      <div class="pt-5"></div>
      <aux-invoice-detail-go-back />
      <aux-invoice-detail-title [invoice]="invoice()!" />
      <aux-invoice-detail-status-banner
        [invoiceFormComponent]="invoiceForm()!"
        [invoice]="invoice()!"
      />

      @if (authQuery.adminUser() || !isInQueue()) {
        <aux-invoice-form [invoice]="invoice()!" />
        <aux-invoice-tabs [invoice]="invoice()!" />
      }
    }
  `,
  imports: [
    InvoiceDetailGoBackComponent,
    InvoiceDetailTitleComponent,
    InvoiceDetailStatusBannerComponent,
    InvoiceFormComponent,
    InvoiceTabsComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailComponent {
  authQuery = inject(AuthQuery);
  private route = inject(ActivatedRoute);
  private invoiceService = inject(InvoiceService);
  private organizationService = inject(OrganizationService);
  private overlayService = inject(OverlayService);
  private purchaseOrdersService = inject(PurchaseOrdersService);
  private workflowService = inject(WorkflowService);

  invoiceTabs = viewChild(InvoiceTabsComponent);
  invoiceForm = viewChild(InvoiceFormComponent);

  loading = signal(false);
  invoice = signal<InvoiceModel | null>(null);

  isInQueue = computed(() => this.invoice()?.invoice_status === 'STATUS_IN_QUEUE');

  constructor() {
    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();

    this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.invoice.set(null);
          this.loading.set(true);

          let id = params.get('id') || '';
          // check if id has ? or % in it
          if (id.includes('?')) {
            id = id.substring(0, id.indexOf('?'));
          } else if (id.includes('%')) {
            id = id.substring(0, id.indexOf('%'));
          }
          if (!id) {
            this.loading.set(false);
            return EMPTY;
          }

          return combineLatest([
            this.invoiceService.getOne(id),
            this.organizationService.get(),
            this.purchaseOrdersService.get(),
          ]);
        }),
        takeUntilDestroyed()
      )
      .subscribe(([invoice]) => {
        this.invoice.set(invoice || null);
        this.loading.set(false);
      });
  }

  async canDeactivate() {
    const cmp = this.invoiceForm();
    const cmpTabs = this.invoiceTabs();
    if (!cmp) {
      return true;
    }

    const bool = cmp.canDeactivate();
    const boolTabs = cmpTabs ? cmpTabs.canDeactivate() : true;
    if (!bool || !boolTabs) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }

    return true;
  }
}
