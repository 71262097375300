import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'aux-progress-bar',
  template: `
    <div class="aux-progress-background">
      <div
        class="aux-progress-background-bar"
        [style.flex-basis]="getBackgroundBarFlexBasis()"
      ></div>
    </div>
    <div class="aux-progress-bar" [style.transform]="getPrimaryBarTransform()">
      <span class="aux-inner-bar"></span>
    </div>
  `,
  styleUrl: 'progress-bar.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  value = input.required({
    transform: (value: number): number => {
      if (value < 0) {
        return 0;
      }

      if (value > 100) {
        return 100;
      }

      return value;
    },
  });

  getPrimaryBarTransform = computed(() => {
    return `scaleX(${this.value() / 100})`;
  });

  getBackgroundBarFlexBasis = computed(() => {
    // this can be used in the future to dynamically change background bar width
    // for now, it's always full width
    return `${100}%`;
  });
}
