import { Injectable } from '@angular/core';
import { Subscriber, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GqlService } from '@shared/services/gql.service';
import { GenericOperator } from '@shared/services/operator.service';
import { MainQuery } from '@shared/store/main/main.query';
import {
  TrialInsightsPaymentMilestoneResponseType,
  TrialInsightsResponseGeneric,
  TrialInsightsState,
} from '@models/trial-insights/trial-insights-store.model';
import { TrialInsightsStore } from '@models/trial-insights/trial-insights.store';
import { first } from 'lodash-es';

type state = Partial<TrialInsightsState['paymentMilestone']>;
type requestType = 'initial' | 'remaining';
type loadingState = requestType | 'completed';
type responseType = TrialInsightsPaymentMilestoneResponseType;
type response = [
  TrialInsightsResponseGeneric<responseType[]>,
  TrialInsightsResponseGeneric<responseType[]>,
  TrialInsightsResponseGeneric<responseType[]>,
];

@Injectable()
export class TrialInsightsPaymentMilestoneStoreService {
  constructor(
    private store: TrialInsightsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  getPaymentMilestones$() {
    return this.mainQuery.select('trialKey').pipe(
      this.updateLoadingState('initial'),
      switchMap(() =>
        combineLatest([
          this.gqlService.listPaymentMilestoneSummary$([]), // total
          // fetch table data
          this.gqlService.listPaymentMilestoneSummary$([
            'PERIOD_MONTH',
            'ORGANIZATION',
            'MILESTONE',
          ]),
          // fetch chart data
          this.gqlService.listPaymentMilestoneSummary$(['PERIOD_QUARTER']),
        ])
      ),
      this.processResponse(),
      this.updateLoadingState('completed')
    );
  }

  loadingState(type: loadingState): state {
    const loadingState: state = {};

    if (type === 'initial') {
      loadingState.isLoading = true;
      loadingState.data = null;
    } else {
      loadingState.isLoading = false;
      loadingState.isLoadingRemaining = false;
    }

    return loadingState;
  }

  updateLoadingState(type: loadingState) {
    const updates = this.loadingState(type);

    const processFn = (_: unknown, subscriber: Subscriber<unknown>) => {
      this.store.update((state) => {
        return {
          ...state,
          paymentMilestone: {
            ...state.paymentMilestone,
            ...updates,
            data: [...(state.paymentMilestone.data || []), ...(updates.data || [])],
          },
        };
      });

      subscriber.next(updates);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  processResponse() {
    const processFn = (response: response, subscriber: Subscriber<unknown>) => {
      const [{ data: totalData }, { data: tableData }, { data: chartData }] = response;

      this.store.update((state) => {
        return {
          ...state,
          paymentMilestone: {
            ...state.paymentMilestone,
            data: tableData ? tableData : [],
            totalMilestones: first(totalData)?.total_amount || 0,
            chartData: chartData ? chartData : [],
          },
        };
      });

      subscriber.next(tableData);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
