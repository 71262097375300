import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Option } from '@shared/types/components.type';
import { AgControlComponent, AgControlComponentParams, noop } from './ag-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

interface AgDropdownComponentParams extends AgControlComponentParams {
  options: Option[];
  disableTooltip?: string;
  classes?: string;
  searchable?: boolean;
  bindLabel?: string;
}

@Component({
  template: `<div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
    <ng-select
      *ngIf="isReady$ | async"
      placeholder="Select"
      [multiple]="false"
      [appendTo]="'body'"
      [searchable]="!!params.searchable"
      [items]="params.options"
      [bindValue]="'value'"
      [bindLabel]="params.bindLabel || 'label'"
      [clearable]="true"
      [ngClass]="(hasError ? 'invalid ' : '') + params.classes + ' aux-ag-select'"
      [formControl]="formControl"
      [auxTooltip]="formControl.disabled ? params.disableTooltip || '' : ''"
      (change)="changeHandler($event)"
    >
      <ng-template let-item="item" ng-option-tmp>
        <div
          auxTooltip
          [template]="
            optionTitle.offsetWidth < optionTitle.scrollWidth ||
            optionSubtitle.offsetWidth < optionSubtitle.scrollWidth
              ? tooltipTemplate
              : undefined
          "
          class="leading-5 pr-4 text-sm"
        >
          <p class="font-bold truncate" [ngClass]="{ 'font-bold': !!item.subTitle }" #optionTitle>
            {{ item.label || item.title }}
          </p>
          <p class="italic truncate" #optionSubtitle>
            {{ item.subTitle }}
          </p>
        </div>

        <ng-template #tooltipTemplate>
          <div>
            <p>{{ item.label || item.title }}</p>
            <p>{{ item.subTitle }}</p>
          </div>
        </ng-template>
      </ng-template>
    </ng-select>
  </div>`,
  styleUrls: ['./ag-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule, TooltipDirective, AsyncPipe, NgIf, NgFor, NgClass],
})
export class AgDropdownComponent extends AgControlComponent {
  params!: AgDropdownComponentParams;

  agInit(params: AgDropdownComponentParams): void {
    this.params = params;
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler ?? noop;
    this.updateView(params);
  }
}
