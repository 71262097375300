import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { BudgetExpense } from '@shared/services/gql.service';
import { RequireSome } from '@shared/utils/utils';

export type OverrideSettingsState = EntityState<
  Omit<RequireSome<Partial<BudgetExpense>, 'id'>, 'expense_amounts'> & {
    expense_amounts: { units?: number | null }[];
  }
>;

export type OverrideSettingsModel = getEntityType<OverrideSettingsState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'override-settings' })
export class OverrideSettingsStore extends EntityStore<OverrideSettingsState> {
  constructor() {
    super({});
  }
}
