import { MainService } from '@shared/store/main/main.service';
import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  computed,
  DestroyRef,
  inject,
} from '@angular/core';
import { combineLatest, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { OverlayService } from '@shared/services/overlay.service';
import { UserDialogComponent, UserDialogSubmit } from '@features/user-dialog/user-dialog.component';
import { sortByUserName, TrialUserQuery } from '@models/trial-users/trial-user.query';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialUserState, UserModel } from '@models/trial-users/trial-user.store';
import { MainQuery } from '@shared/store/main/main.query';
import { DepartmentType, PermissionType } from '@shared/services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { Utils } from '@shared/utils/utils';
import { getEntityType } from '@datorama/akita';
import { MessagesConstants } from '@shared/constants/messages.constants';

import { injectCopyUsersModal } from './components/copy-users-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'aux-users',
  templateUrl: './users.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent {
  private readonly destroyRef = inject(DestroyRef);

  users$?: Observable<getEntityType<TrialUserState>[]>;

  auxUsers$?: Observable<getEntityType<TrialUserState>[]>;

  showAuxSupport = false;

  filterValue = '';

  loading$ = combineLatest([
    this.trialUserQuery.selectLoading(),
    this.vendorsQuery.selectLoading(),
  ]).pipe(
    map(([users, vendors]) => {
      this.showAuxSupport = false;
      return users || vendors;
    })
  );

  copyUserPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_COPY_USERS_AND_PERMISSIONS],
  });

  copyUserPermissionDisabledTooltip = computed(() => {
    return this.copyUserPermission() ? '' : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  });

  showCopyUsersModal = injectCopyUsersModal();

  userHasUpdateUserPermission = false;

  private _searchSubject: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private overlayService: OverlayService,
    public trialUserQuery: TrialUserQuery,
    private trialUserService: TrialUserService,
    private mainQuery: MainQuery,
    private mainService: MainService,
    private vendorsQuery: OrganizationQuery,
    public authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.setUserPermissions();
    this.setUserLists();
    this.subscribeToSearch();
  }

  async onAddUser() {
    const ref = this.overlayService.openPopup<
      { mode: 'edit' | 'add'; formValue?: unknown },
      UserDialogSubmit
    >({ modal: UserDialogComponent, data: { mode: 'add' }, settings: { header: 'Add User' } });

    const { data } = await firstValueFrom(ref.afterClosed$);

    if (data) {
      const { email, family_name, given_name, title, role, department } = data;

      await this.trialUserService.add({
        trial_id: this.mainQuery.getValue().trialKey,
        given_name,
        department,
        family_name,
        email,
        title,
        role,
      });

      await firstValueFrom(this.mainService.getUserList$());
    }
  }

  getDepartmentString(departments: (DepartmentType | null)[] | null | undefined) {
    if (departments) {
      return departments
        .map((x) => {
          if (x) {
            return Utils.DEPARTMENT_OPTIONS[x];
          }
          return '';
        })
        .join(', ');
    }
    return '';
  }

  async onEditUser(user: UserModel) {
    let user_organization_id = user.organization?.id;
    if (user.email?.includes('@auxili.us')) {
      user_organization_id = 'Aux Support';
    }

    const ref = this.overlayService.openPopup<
      { mode: 'edit' | 'add'; formValue?: unknown },
      UserDialogSubmit
    >({
      modal: UserDialogComponent,
      data: { mode: 'edit', formValue: { ...user, organization_id: user_organization_id } },
      settings: { header: 'Edit User', primaryButton: { label: 'Save Edits' } },
    });

    const { data } = await firstValueFrom(ref.afterClosed$);

    if (data) {
      const { email, family_name, given_name, title, role, department } = data;

      const { success } = await this.trialUserService.update({
        email,
        family_name,
        given_name,
        department,
        role,
        title,
        sub: user.sub as string,
      });

      if (success) {
        this.overlayService.success('User updated successfully!');
      }
    }
  }

  async onRemoveUser(user: UserModel) {
    this.overlayService.openPopup<
      { message: string },
      boolean | undefined,
      ConfirmationModalComponent
    >({
      content: ConfirmationModalComponent,
      data: {
        message: `Are you sure that you want to remove ${user.given_name} ${user.family_name}?`,
      },
      settings: {
        header: 'Remove User',
        primaryButton: {
          label: 'Remove',
          action: async (instance) => {
            await this.trialUserService.remove(user.sub as string);
            instance?.ref.close();
          },
        },
      },
    });
  }

  toggleAuxSupport() {
    this.showAuxSupport = !this.showAuxSupport;
  }

  filterChanges(): void {
    if (this.filterValue !== this._searchSubject.getValue()) {
      this.setUserLists();
    }
  }

  updateSearch(target: EventTarget | null): void {
    const searchText = target as HTMLInputElement;
    if (searchText.value.length > 2 || searchText.value.length === 0) {
      this._searchSubject.next(this.filterValue);
    }
  }

  noPermissionsTooltipText(): string {
    return this.userHasUpdateUserPermission
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  private filterUserByText = (user: getEntityType<TrialUserState>) => {
    const comparedValue = this.filterValue.toLowerCase();
    return (
      (user.given_name && user.given_name.toLowerCase().includes(comparedValue)) ||
      (user.family_name && user.family_name.toLowerCase().includes(comparedValue)) ||
      (user.title && user.title.toLowerCase().includes(comparedValue)) ||
      !!(user.email && user.email.toLowerCase().includes(comparedValue))
    );
  };

  private setUsers$(): void {
    this.users$ = this.trialUserQuery.selectAll({
      filterBy: [
        (user) => (user.email ? !user.email.includes('@auxili.us') : true),
        this.filterUserByText,
      ],
      sortBy: sortByUserName,
    });
  }

  private setAuxUsers$(): void {
    this.auxUsers$ = this.trialUserQuery.selectAll({
      filterBy: [
        (user) => (user.email ? user.email.includes('@auxili.us') : false),
        this.filterUserByText,
      ],
      sortBy: sortByUserName,
    });
  }

  private subscribeToSearch(): void {
    this._searchSubject
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe((searchText) => {
        if (searchText.length > 2 || searchText.length === 0) {
          this.setUserLists();
          this.changeDetector.detectChanges();
        }
      });
  }

  private setUserLists(): void {
    this.setUsers$();
    this.setAuxUsers$();
  }

  async resendInvitation(user: UserModel) {
    const { email } = user;

    if (email) {
      await this.trialUserService.reinvite(email);
    }
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_USERS],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasUpdateUserPermission]) => {
        this.userHasUpdateUserPermission = userHasUpdateUserPermission;
      });
  }
}
