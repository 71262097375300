import { Directive, HostListener, inject, input } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { StickyGridService } from './sticky-grid.service';

@Directive({
  standalone: true,
  providers: [StickyGridService],
  selector: '[auxStickyGrid]',
})
export class StickyGridDirective {
  grid = inject(AgGridAngular);
  stickyElementService = inject(StickyGridService);

  stickyOffset = input(0);

  constructor() {
    merge(
      this.grid.gridReady,
      this.grid.firstDataRendered,
      this.grid.viewportChanged,
      this.grid.gridSizeChanged
    )
      .pipe(debounceTime(0))
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.stickyElementService.configure({ offset: this.stickyOffset() });
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure({ offset: this.stickyOffset() });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure({ offset: this.stickyOffset() });
  }
}
