import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import dayjs from 'dayjs';

import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { Currency, PermissionType, TrialImplementationStatus } from '@shared/services/gql.service';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';
import { Utils } from '@shared/utils/utils';
import { decimalAdd, decimalEquality } from '@shared/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { InvoiceFormUtils } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.utils';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-save-button',
  template: `
    <aux-button
      [auxTooltip]="tooltip()"
      [disabled]="disabled()"
      [onClick]="onSave"
      icon="Pencil"
      variant="secondary"
      label="Save"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice', 'invoiceFormComponent'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailSaveBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);

  organizationQuery = inject(OrganizationQuery);

  hasEditPermission = this.ctx.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });

  disabled = computed(() => {
    return !this.hasEditPermission() || this.ctx.buttonDisabled();
  });

  tooltip = computed(() => {
    if (!this.hasEditPermission()) {
      return this.ctx.dontHavePermission;
    }

    if (this.ctx.buttonDisabled()) {
      return this.ctx.invoiceLockTooltip();
    }

    return '';
  });

  onSave = async () => {
    const { overlayService, invoiceService, mainQuery } = this.ctx;
    const currentOpenMonth = mainQuery.getValue().currentOpenMonth;
    const cmp = this.ctx.invoiceFormComponent();
    const trial = this.ctx.mainQuery.selectedTrial();

    if (!cmp) {
      return;
    }

    cmp.submit = true;

    cmp.invoiceFormRef().onSubmit({} as Event);
    cmp.refreshView();

    const oldInvoice = cmp.invoice();

    const invoice = InvoiceFormUtils.invoiceFormToInvoiceModel(
      cmp.invoiceForm.getRawValue(),
      oldInvoice
    );

    const errors = {
      totals: false,
      accrual_period: false,
      services_period_trial_timeline: false,
    };

    const invoiceTotal = invoice.expense_amounts.invoice_total;
    let otherTotals = 0;
    Object.entries(invoice.expense_amounts)
      .filter(([, expense]) => expense.type !== invoiceTotal.type)
      .forEach(([key, expense]) => {
        if (Utils.isNumber(expense.value) && !key.includes('_trial_currency')) {
          otherTotals = decimalAdd(otherTotals, Number(expense.value));
        }
      });

    if (!decimalEquality(otherTotals, Number(invoiceTotal.value), 2)) {
      errors.totals = true;
    }

    if (
      !this.ctx.isAccrualPeriodInClosedMonth() &&
      (trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
        trial?.implementation_status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE)
    ) {
      if (oldInvoice.accrual_period !== invoice.accrual_period && invoice.accrual_period) {
        if (dayjs(`${invoice.accrual_period}-01`).isBefore(currentOpenMonth)) {
          errors.accrual_period = true;
          cmp.invoiceForm.controls.accrual_period.updateValueAndValidity();
        }
      }
    }

    if (oldInvoice.services_period !== invoice.services_period && invoice.services_period) {
      if (
        dayjs(`${invoice.services_period}-01`).isBefore(mainQuery.getValue().trialStartDate) ||
        dayjs(`${invoice.services_period}-01`).isAfter(mainQuery.getValue().trialEndDate)
      ) {
        errors.services_period_trial_timeline = true;
        cmp.invoiceForm.controls.services_period.updateValueAndValidity();
      }
    }

    if (errors.totals || errors.accrual_period || errors.services_period_trial_timeline) {
      const message: string[] = [];
      if (errors.accrual_period) {
        message.push(
          `Invoice #${invoice.invoice_no || ''} - Posting Period cannot be set to a Closed Month.`
        );
      }
      if (errors.services_period_trial_timeline) {
        message.push(
          `Invoice #${invoice.invoice_no || ''} - Service Period must be set to a date in the trial timeline.`
        );
      }
      if (errors.totals) {
        message.push(
          'Invoice Total must equal the sum of',
          '- Investigator',
          '- Services',
          '- Discount',
          '- Pass-thru'
        );
      }
      overlayService.error(message, undefined, true);

      return false;
    }

    if (cmp.invoiceForm.invalid) {
      return;
    }

    const org = this.organizationQuery.getEntity(invoice.organization.id);

    if (!org) {
      return null;
    }

    await invoiceService.update({
      ...invoice,
      organization: {
        id: invoice.organization.id,
        name: org.name as string,
        currency: org.currency as Currency,
      },
      accrual_period: invoice.accrual_period ? `${invoice.accrual_period}-01` : null,
      services_period: invoice.services_period ? `${invoice.services_period}-01` : null,
      previous_services_period: oldInvoice.services_period || null,
    });
  };
}
