import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationService } from '@models/organization/organization.service';
import { OverlayService } from '@shared/services/overlay.service';
import { BehaviorSubject, combineLatest, firstValueFrom, from, ReplaySubject } from 'rxjs';

import { UntypedFormControl } from '@angular/forms';
import { MainQuery } from '@shared/store/main/main.query';
import { ApiService } from '@shared/services/api.service';
import {
  Currency,
  DocumentType,
  EntityType,
  listDocumentsQuery,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { NewPoDialogComponent, NewPoDialogData } from './new-po-dialog/new-po-dialog.component';
import { PurchaseOrdersService } from './state/purchase-orders.service';
import { PurchaseOrdersQuery } from './state/purchase-orders.query';
import { AgPoActionsComponent, AgPoActionsProps } from './ag-po-actions.component ';
import { AuthService } from '@shared/store/auth/auth.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

interface POGridData {
  organization_name: string;
  organization_id: string;
  currency: Currency;
  po_id: string;
  po_number?: string;
  po_amount?: number;
  invoiced_against?: number;
  po_remaining?: number;
  received_amount: number;
  received_percentage: number;
  paid_percentage: number;
  paid_amount: number;
}

@Component({
  selector: 'aux-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styles: [
    `
      ::ng-deep .ag-theme-alpine .ag-header-cell-text {
        font-size: 8.75px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseOrdersComponent {
  private readonly destroyRef = inject(DestroyRef);

  selectedVendor = new UntypedFormControl('');

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  currencies: Set<Currency> = new Set();

  hasPOPermission$ = new BehaviorSubject<boolean>(false);

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    autoGroupColumnDef: {
      headerName: '',
      minWidth: 100,
      maxWidth: 200,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    getRowStyle: (param) => {
      if (param.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return undefined;
    },
    headerHeight: 40,
    suppressCellFocus: true,
    rowSelection: 'single',
    columnDefs: [],
  });

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'organization_name',
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'ID',
      field: 'po_id',
      hide: true,
    },
    {
      headerName: 'PO #',
      field: 'po_number',
      valueFormatter: (val) => {
        return Utils.dashFormatter(val);
      },
    },
    {
      headerName: 'PO Amount ($)',
      field: 'po_amount',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
    },
    {
      headerName: 'Invoiced Against ($)',
      field: 'invoiced_against',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
    },
    {
      headerName: 'PO Remaining ($)',
      field: 'po_remaining',
      valueFormatter: (params: ValueFormatterParams) =>
        Utils.agCurrencyFormatter(params.value, params.data.currency),
      cellStyle: (params) => {
        if (params.value < 0) {
          return { color: 'red', 'font-weight': 'bold' };
        }
        return null;
      },
    },
  ];

  gridData$ = new BehaviorSubject<POGridData[]>([]);

  components = {
    agPoActionRenderer: AgPoActionsComponent,
  };

  width$ = new BehaviorSubject(0);

  files$ = new BehaviorSubject<listDocumentsQuery['items']>([]);

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  constructor(
    public organizationQuery: OrganizationQuery,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    private purchaseOrdersService: PurchaseOrdersService,
    private apiService: ApiService,
    private authService: AuthService,
    private mainQuery: MainQuery,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.purchaseOrdersService.get().pipe(takeUntilDestroyed()).subscribe();

    this.hasPermission();

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return from(
            this.apiService.getFilesByFilters(
              this.getTrialPOPath(),
              undefined,
              EntityType.PURCHASE_ORDER,
              DocumentType.DOCUMENT_PURCHASE_ORDER
            )
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe((files) => {
        this.files$.next(files);
      });

    combineLatest([
      this.purchaseOrdersQuery.selectAll(),
      this.files$,
      this.selectedVendor.valueChanges,
      this.gridAPI$.pipe(take(1)),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([POs, files]) => {
        this.gridData$.next(
          POs.map(
            ({
              organization_id,
              organization_name,
              currency,
              received_amount,
              received_percentage,
              paid_percentage,
              paid_amount,
              po_amount,
              id,
              invoice_summaries,
              po_number,
            }) => ({
              organization_id,
              organization_name,
              currency,
              received_amount,
              received_percentage,
              paid_percentage,
              paid_amount,
              po_id: id,
              po_number,
              po_amount,
              invoice_summaries,
              invoiced_against: paid_amount + received_amount,
              po_remaining: (po_amount || 0) - (paid_amount + received_amount),
              file: files.findIndex((file) => file.bucket_key.includes(id)) > -1,
            })
          )
        );
        POs.forEach((po) => this.currencies.add(po.currency));
        this.refreshGridFilter();
      });

    this.organizationService
      .get()
      .pipe(takeUntilDestroyed())
      .subscribe(async () => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
        const { modalVendorId } = await firstValueFrom(this.route.queryParams);

        if (modalVendorId) {
          this.onAddPO(modalVendorId);
          this.router.navigate([], {
            queryParams: { modalVendorId: null },
            queryParamsHandling: 'merge',
          });
        }
      });
    this.initializeApproveColumns();

    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();
  }

  filteredGridData$ = this.gridData$.pipe(
    switchMap((listData) => {
      return this.selectedVendor.valueChanges.pipe(
        startWith(this.selectedVendor.value as string),
        map((selected_organization) => {
          if (selected_organization) {
            return listData.filter((po) => {
              return po.organization_id.includes(selected_organization);
            });
          }

          return listData;
        })
      );
    })
  );

  initializeApproveColumns() {
    const approveColumns: ColDef[] = [];
    approveColumns.push({
      headerName: '',
      editable: false,
      maxWidth: 135,
      minWidth: 135,
      resizable: false,
      field: 'action',
      cellRendererParams: <AgPoActionsProps>{
        editClickFN: async ({ rowNode }: { rowNode: IRowNode }) => {
          this.onUpdateLine(rowNode);
        },
        userHasPermission$: this.hasPOPermission$,
        isChangeOrdersWorkflowLocked: this.isChangeOrdersWorkflowLocked,
        deleteClickFN: async ({ rowNode }: { rowNode: IRowNode }) => {
          this.onRemoveLine(rowNode);
        },
        downloadClickFN: async ({ rowNode }: { rowNode: IRowNode }) => {
          this.onDownloadLine(rowNode);
        },
      },
      cellRendererSelector: (params) => {
        if (params.data) {
          if (params.data.organization_names === 'Total') {
            return {};
          }
        }

        return { component: 'agPoActionRenderer', params };
      },
    });

    const options = this.gridOptions$.getValue();
    options.columnDefs = [...approveColumns, ...this.defaultColumns];
    this.gridOptions$.next(options);

    this.refreshGridWidth();
  }

  getFilePath(poID: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/purchaseorders/${poID}/`;
  }

  getTrialPOPath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/purchaseorders/`;
  }

  getSelectedCurrency(): Currency | undefined {
    if (this.currencies.size === 1) {
      return this.currencies.values().next().value;
    } else if (this.selectedVendor.value) {
      return (
        this.gridData$.value.find((row) => row.organization_id === this.selectedVendor.value)
          ?.currency || Currency.USD
      );
    }
    return undefined;
  }

  getSelectedCurrencySymbol(): string | undefined {
    return Utils.getCurrenySymbol(this.getSelectedCurrency() || Currency.USD);
  }

  refreshGridWidth() {
    const div = document.querySelector('.ag-header-container') as HTMLDivElement;
    if (div) {
      const paddingOffset = 2;
      this.width$.next(div.getBoundingClientRect().width + paddingOffset);
    } else {
      this.width$.next(0);
    }
  }

  refreshGridFilter() {
    if (this.getSelectedCurrency()) {
      this.gridAPI?.setGridOption(
        'pinnedBottomRowData',
        this.generatePinnedBottomData(this.gridData$.getValue())
      );
    } else {
      this.gridAPI?.setGridOption('pinnedBottomRowData', []);
    }
    this.gridAPI?.sizeColumnsToFit();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.refreshGridFilter();
  }

  async onAddPO(vendorId?: string) {
    const ref = this.overlayService.openPopup<NewPoDialogData, unknown, NewPoDialogComponent>({
      modal: NewPoDialogComponent,
      settings: {
        header: 'Add Purchase Order',
      },
      data: {
        mode: 'add',
        vendorId,
      },
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    if (resp.data) {
      const files = await this.apiService.getFilesByFilters(
        this.getTrialPOPath(),
        undefined,
        EntityType.PURCHASE_ORDER,
        DocumentType.DOCUMENT_PURCHASE_ORDER
      );
      this.files$.next(files);
    }
  }

  async onUpdateLine(rowNode: IRowNode) {
    const ref = this.overlayService.openPopup<NewPoDialogData>({
      modal: NewPoDialogComponent,
      settings: {
        header: 'Edit Purchase Order',
      },
      data: {
        mode: 'update',
        po_id: rowNode.data.po_id,
      },
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    if (resp.data) {
      const files = await this.apiService.getFilesByFilters(
        this.getTrialPOPath(),
        undefined,
        EntityType.PURCHASE_ORDER,
        DocumentType.DOCUMENT_PURCHASE_ORDER
      );
      this.files$.next(files);
    }
  }

  async onRemoveLine(rowNode: IRowNode) {
    if (!rowNode.data) {
      return;
    }

    const resp = this.overlayService.openPopup({
      content: ConfirmationModalComponent,
      settings: {
        header: 'Remove Purchase Order',
        primaryButton: {
          label: 'Remove',
        },
      },
      data: {
        message: `Are you sure you want to remove Purchase Order ${rowNode.data.po_number}?`,
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data) {
      await this.purchaseOrdersService.deletePurchaseOrder(rowNode.data.po_id);
    }
  }

  async onDownloadLine(rowNode: IRowNode) {
    if (!rowNode.data) {
      return;
    }
    const ref = this.overlayService.loading();
    const { success, data, errors } = await this.apiService.getS3ZipFile(
      this.getFilePath(rowNode.data.po_id)
    );

    if (success && data) {
      const trialName = this.mainQuery.getSelectedTrial()?.short_name;
      const { po_number, organization_name } = rowNode.data;
      const filename = `${trialName}_${organization_name}_Purchase Order ${po_number}`;

      await this.apiService.downloadZipOrFile(data, filename);

      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    ref.close();
  }

  generatePinnedBottomData(rows: POGridData[]) {
    const fields = ['po_amount', 'invoiced_against', 'po_remaining'] as const;
    const data = rows.reduce(
      (acc, val) => {
        fields.forEach((field) => {
          if (
            this.selectedVendor.value.length === 0 ||
            val.organization_id === this.selectedVendor.value
          ) {
            acc[field] += Number(val[field]);
          }
        });
        return acc;
      },
      {
        po_amount: 0,
        invoiced_against: 0,
        po_remaining: 0,
      } as {
        po_amount: number;
        invoiced_against: number;
        po_remaining: number;
        [k: string]: number;
      }
    );

    return [
      {
        ...data,
        organization_names: 'Total',
        po_number: '',
        actions: null,
        currency: this.getSelectedCurrency() || Currency.USD,
      },
    ];
  }
  private hasPermission() {
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_PURCHASE_ORDERS],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((perm) => this.hasPOPermission$.next(perm));
  }

  addPurchaseOrderBtnTooltip(
    hasNoPOPermission: boolean,
    isChangeOrdersWorkflowLocked: boolean
  ): string {
    if (hasNoPOPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }
}
