<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(loading$ | async) === false">
  <div class="flex justify-between mb-[15px]" [ngStyle]="{ width: (width$ | async) + 'px' }">
    <div *ngVar="userHasModifyExpenseDefaultsPermission$ | async as expensePermission">
      <aux-checkbox
        class="text-m font-medium"
        [disabled]="!expensePermission"
        [auxTooltip]="expensePermission ? '' : this.disabledTooltip"
        data-pendo-id="investigator-auto-historical-adjustments-checkbox"
        [(ngModel)]="applyAutoHistoricalAdjustments"
        (customChange)="onClickCheckbox($event)"
      >
        Apply Auto-Historical Adjustments to Investigator
      </aux-checkbox>
    </div>
    <div class="flex justify-between mb-6">
      <div class="flex items-center">
        <div *ngVar="isBtnLoading('export') | async as isExportProcessing">
          <div class="flex items-center flex-row">
            <aux-button
              classList="mr-2"
              variant="secondary"
              label="In-Month Adjustments"
              icon="Eye"
              [onClick]="handleInMonthAdjustmentClick.bind(this)"
              data-pendo-id="back-to-in-month-adjustments"
            />
            <aux-export-excel-button
              className="ml-1"
              [clickFN]="onExportExpenseDefaults.bind(this)"
              [loading]="isExportProcessing"
              [disabled]="isExportProcessing"
              [spinnerSize]="7"
              pendoTag="expense-defaults-excel-export-button"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-16">
    <ag-grid-angular
      class="ag-theme-aux tabular-nums w-full expense-table max-w-full min-w-[685px]"
      domLayout="autoHeight"
      [gridOptions]="gridOptions"
      [rowData]="gridData$ | async"
      (gridReady)="onGridReady($event); onResize()"
      (firstDataRendered)="onResize()"
      (columnResized)="onResize()"
      auxStickyGrid
    />
  </div>

  <!-- Save changes -->
  <aux-save-changes
    *ngIf="(saveCheck$ | async) || false"
    [onSaveChanges]="saveChanges"
    [showDiscardChangesBtn]="true"
    (cancel)="onDiscardChanges()"
  />
</div>
