import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDriverCreateEditModalComponent } from '../custom-driver-create-edit-modal/custom-driver-create-edit-modal.component';
import { CustomCurvesQuery } from '../../state/custom-curves.query';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { PermissionType } from '@shared/services/gql.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { CustomCurve } from '../../state/custom-curves.state';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowStepType } from '@shared/store/workflow/workflow.const';

@Component({
  selector: 'aux-custom-curves-no-rows-overlay',
  templateUrl: './custom-curves-no-rows-overlay.component.html',
  styleUrl: './custom-curves-no-rows-overlay.component.scss',
  standalone: true,
  imports: [ButtonComponent, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCurvesNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  readonly customCurvesQuery = inject(CustomCurvesQuery);

  private readonly overlayService = inject(OverlayService);

  private readonly authService = inject(AuthService);

  private readonly workflowQuery = inject(WorkflowQuery);

  params!: INoRowsOverlayParams;

  isUserAuthorized = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_CUSTOM_CURVE],
  });

  isWFLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStepType.WF_STEP_MONTH_CLOSE_LOCK_CURVES
  );

  hasModifyCustomCurvePermission = computed(() => {
    return this.isUserAuthorized() && !this.isWFLocked();
  });

  noPermTooltip = computed(() => {
    return !this.isUserAuthorized()
      ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
      : this.isWFLocked()
        ? MessagesConstants.SITE_PATIENT_CUSTOM_CURVES_LOCKED_TEXT
        : '';
  });

  curves = computed<CustomCurve[]>(() => {
    return this.customCurvesQuery.curves() || [];
  });

  @HostBinding('style.pointerEvents') get events(): string {
    return 'all';
  }

  agInit(params: INoRowsOverlayParams): void {
    this.params = params;
  }

  onCreateButtonClick(): void {
    type InstanceType = CustomDriverCreateEditModalComponent | null;
    this.overlayService.openPopup({
      content: CustomDriverCreateEditModalComponent,
      settings: {
        header: 'Create a New Driver Curve',
        secondaryButton: { pendoTag: 'custom-curves-create-modal-cancel-button' },
        primaryButton: {
          label: 'Create Curve',
          pendoTag: 'custom-curves-create-modal-confirm-button',
          disabled: (instance: InstanceType) => !!instance?.saveDisabled,
          action: (instance: InstanceType) => instance?.onSave(),
        },
      },
      data: {
        existingNames: this.curves().map((curve) => curve.name),
      },
    });
  }
}
