import { NgClass } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'aux-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, FormsModule],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = '';

  @Input() checked = false;

  @Input() disabled = false;

  @Input() indeterminate = false;

  @Input() hideLabel: boolean | undefined = false;

  @Output() customChange = new EventEmitter<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: boolean): void {
    this.checked = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (p: boolean) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  onTouchedCallback?: () => void;

  onChangeCallback?: (p: boolean) => void;
}
