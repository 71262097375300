import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import { TableConstants } from '@shared/constants/table.constants';
import { AuxExcelStyleKeys } from '@shared/utils';
import { AgIconLinkCellComponent } from '@shared/ag-components/ag-icon-link-cell/ag-icon-link-cell.component';

export const PATIENT_DATE_COLUMNS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Visit Day',
    field: 'target_date_days_out',
    minWidth: 150,
    pinned: 'left',
    valueFormatter: Utils.preserveZeroDashFormatter,
    cellClass: [AuxExcelStyleKeys.NUMBER],
  },
  {
    headerName: 'Visit Window [+/-]',
    field: 'target_tolerance_days_out',
    minWidth: 150,
    pinned: 'left',
    valueFormatter: Utils.dashFormatter,
    cellClass: [AuxExcelStyleKeys.NUMBER],
  },
];

export const getVisitInformationColumn = (): (ColDef | ColGroupDef)[] => [
  {
    headerName: 'Visit Information',
    headerClass: 'ag-header-align-center',
    pinned: 'left',
    children: [
      {
        headerName: 'Name',
        field: 'patient_protocol_name',
        tooltipField: 'patient_protocol_name',
        minWidth: 250,
        cellClass: 'text-left',
        resizable: true,
      },
      {
        headerName: 'Optional',
        field: 'patient_protocol_optional',
        minWidth: 105,
        maxWidth: 105,
        resizable: false,
        cellRenderer: AgIconLinkCellComponent,
        cellRendererParams: (params: ICellRendererParams) => ({
          hideText: true,
          justifyCenter: true,
          icons: [
            {
              showIcon: params.value && params.data.patient_protocol_name !== 'Total',
              icon: 'Check',
              iconClass: 'text-aux-green-dark',
            },
          ],
        }),
      },
    ],
  },
  TableConstants.SPACER_COLUMN,
  {
    headerName: 'patient_protocol_id',
    field: 'patient_protocol_id',
    colId: 'patient_protocol_id',
    hide: true,
  },
];

export const COST_COLUMN_PROPS: ColDef = {
  minWidth: 125,
  aggFunc: 'sum',
  valueFormatter: Utils.agCurrencyFormatter,
  cellClass: ['cost'],
};
