export class RegExpUtils {
  static isUUID(uuid: string) {
    if (uuid) {
      const regex = new RegExp(
        '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
      );
      return regex.test(uuid);
    }
    return false;
  }
}
