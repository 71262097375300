import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  DestroyRef,
  inject,
} from '@angular/core';

import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { differenceWith, isEqual, pick } from 'lodash-es';
import dayjs from 'dayjs';
import { switchMap, tap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { OverlayService } from '@shared/services/overlay.service';

import { Document, DocumentType } from '@shared/services/gql.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IRowNode, IServerSideSelectionState, RowSelectedEvent } from '@ag-grid-community/core';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { DocumentLibraryFile, DocumentLibraryService } from './document-library.service';
import { MainStore } from '@shared/store/main/main.store';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import {
  EditMultipleDocumentsModalComponent,
  EditMultipleDocumentsModalData,
} from './edit-multiple-documents-modal/edit-multiple-documents-modal';
import { DocumentsComponentForm, DocumentsTableRow } from './documents.component.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-document',
  templateUrl: './documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('documentLibraryComponent') documentLibraryComponent!: DocumentLibraryComponent;

  prevFilters: Pick<
    DocumentsComponentForm,
    'documentTypes' | 'vendors' | 'sites' | 'dateFrom' | 'dateTo'
  > | null = null;

  removedRows$ = new BehaviorSubject<string[]>([]);

  selectedRows: Document[] = [];

  initialValue$?: BehaviorSubject<DocumentsTableRow[]>;

  hasChanges$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  isBulkApplyButtonDisabled$ = new BehaviorSubject(true);

  constructor(
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    public documentLibrary: DocumentLibraryService,
    private mainStore: MainStore
  ) {
    this.mainStore.update({ fullPage: true });
  }

  documentLibraryForm = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    documentTypes: new FormControl<DocumentType[]>([], { nonNullable: true }),
    vendors: new FormControl<string[]>([], { nonNullable: true }),
    sites: new FormControl<string[]>([], { nonNullable: true }),
    dateFrom: new FormControl('', { nonNullable: true }),
    dateTo: new FormControl('', { nonNullable: true }),
    table: new FormControl<DocumentsTableRow[]>([], { nonNullable: true }),
  });

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        tap(() => {
          this.resetViewState();
          this.loading$.next(true);
        }),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.documentLibrary.getRequiredDictionaries();
        })
      )
      .subscribe(() => {
        this.loading$.next(false);
      });

    this.subscribeOnFormChange();
  }

  ngOnDestroy(): void {
    this.mainStore.update({ fullPage: false });
  }

  private subscribeOnFormChange() {
    this.documentLibraryForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = this.documentLibraryForm.getRawValue();

        if (!this.initialValue$ && values.table) {
          this.initialValue$ = new BehaviorSubject<DocumentsTableRow[]>(
            this.documentLibraryForm.controls.table.getRawValue()
          );
          return;
        }

        if (!this.initialValue$ || !values.table) {
          return;
        }

        const actualFilters = pick(values, [
          'documentTypes',
          'vendors',
          'sites',
          'dateFrom',
          'dateTo',
        ]);

        if (this.prevFilters == null) {
          this.prevFilters = actualFilters;
        }

        const noFilterChange = isEqual(this.prevFilters, actualFilters);

        const initialRowsCount = this.initialValue$.getValue().length;

        if (initialRowsCount < values.table.length && noFilterChange) {
          const newEntities = this.documentLibraryForm.controls.table
            .getRawValue()
            .slice(initialRowsCount, values.table.length);

          this.updateInitialValues(newEntities);

          return;
        }

        if (!noFilterChange) {
          this.prevFilters = { ...actualFilters };
          return;
        }

        if (this.initialValue$ && values.table) {
          this.updateVisibilityChangeBanner();
        }
      });
  }

  filterChange(
    files: Pick<
      DocumentLibraryFile,
      'description' | 'document_type_id' | 'vendor_id' | 'site_id' | 'id'
    >[]
  ) {
    if (this.initialValue$) {
      const newInitialValues = [...files];
      this.initialValue$.next(newInitialValues);

      this.updateVisibilityChangeBanner();
    }
  }

  updateInitialValues(newDocuments: DocumentsTableRow[]) {
    if (this.initialValue$) {
      const newInitialValues = [...this.initialValue$.getValue(), ...newDocuments];
      this.initialValue$.next(newInitialValues);
    }
  }

  private updateVisibilityChangeBanner() {
    if (this.initialValue$) {
      this.hasChanges$.next(
        !isEqual(
          this.initialValue$.getValue(),
          this.documentLibraryForm.controls.table.getRawValue()
        )
      );
    }
  }

  private resetViewState() {
    this.removedRows$.next([]);

    if (this.initialValue$) {
      this.documentLibraryForm.controls.table.setValue(this.initialValue$.getValue());
    }

    this.hasChanges$.next(false);
    this.documentLibraryComponent?.gridAPI?.redrawRows();
  }

  updateGridData = () => {
    const formValues = this.documentLibraryForm.getRawValue().table;

    const gridData = this.documentLibraryComponent.gridData$
      .getValue()
      .map(({ id, description, site_id, document_type_id, vendor_id }: Document) => ({
        id,
        description,
        site_id,
        document_type_id,
        vendor_id,
      }));
    const changes = differenceWith(formValues, gridData, isEqual);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      const changesForNode = changes.find(({ id }) => id === node.data.id);

      if (changesForNode) {
        node.setData({ ...node.data, ...changesForNode });
      }
    });
  };

  saveAllChanges = async () => {
    if (this.initialValue$) {
      const changesList = differenceWith(
        this.documentLibraryForm.controls.table.getRawValue(),
        this.initialValue$.getValue(),
        isEqual
      );

      await this.documentLibrary.updateDocuments(changesList);

      if (this.removedRows$.getValue().length) {
        await this.documentLibrary.removeDocuments(this.removedRows$.getValue());
        this.removedRows$.next([]);
        this.documentLibraryComponent.gridAPI.refreshServerSide();
      }

      this.documentLibraryComponent.gridAPI.deselectAll();
      this.initialValue$.next(this.documentLibraryForm.controls.table.getRawValue());
      this.hasChanges$.next(false);
    }
  };

  onRemoveRow(id: string) {
    this.removedRows$.next([...this.removedRows$.getValue(), id]);
    this.hasChanges$.next(true);
  }

  onRollbackDeleteRow(row: IRowNode) {
    const updatedList = this.removedRows$.getValue().filter((rowId) => rowId !== row.data.id);

    this.removedRows$.next(updatedList);

    if (!updatedList.length) {
      this.hasChanges$.next(false);
    }

    this.documentLibraryComponent.gridAPI.redrawRows({ rowNodes: [row] });
  }

  async onDocumentUploadClick() {
    const resp = await firstValueFrom(
      this.overlayService.openPopup({
        modal: DocumentUploadComponent,
        settings: {
          header: 'Upload documents',
          primaryButton: {
            label: 'Upload',
          },
        },
        data: { vendors: this.documentLibrary.vendors },
      }).afterClosed$
    );

    if (resp.data) {
      this.documentLibraryComponent.gridAPI.refreshServerSide();
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }
    return true;
  }

  hasFilters = () => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } =
      this.documentLibraryForm.getRawValue();

    return !!(documentTypes.length || vendors.length || sites.length || dateFrom || dateTo);
  };

  shouldRowBeShown = (node: IRowNode<Document>): boolean => {
    const { documentTypes, vendors, sites, dateFrom, dateTo } =
      this.documentLibraryForm.getRawValue();

    if (!node.data) {
      return true;
    }

    const { document_type_id, vendor_id, site_id } = node.data;

    return (
      (!documentTypes.length || (!!document_type_id && documentTypes.includes(document_type_id))) &&
      (!vendors.length || (!!vendor_id && vendors.includes(vendor_id))) &&
      (!sites.length || (!!site_id && sites.includes(site_id))) &&
      (!dateFrom || dayjs(node.data.create_date).isSameOrAfter(dateFrom)) &&
      (!dateTo || dayjs(dateTo).isSameOrAfter(node.data.create_date, 'day'))
    );
  };

  onFilterChange = () => {
    this.documentLibraryComponent.gridAPI?.onFilterChanged();
  };

  onRowSelected = (event?: RowSelectedEvent<Document>) => {
    const selectionState = event?.api?.getServerSideSelectionState() as
      | IServerSideSelectionState
      | undefined;

    const rowsData: Document[] = [];

    const visibleEditableRows = event?.api
      ?.getRenderedNodes()
      .filter(({ data }) => data?.is_metadata_editable);

    // Auto deselect if no selectable rows
    if (!visibleEditableRows?.length) {
      event?.api?.deselectAll();
      return;
    }

    if (selectionState?.selectAll) {
      visibleEditableRows?.forEach(({ data }) => {
        if (data) {
          rowsData.push(data);
        }
      });
    } else {
      const isEveryRowsSelectedManually = visibleEditableRows?.every((node) => node.isSelected());

      if (isEveryRowsSelectedManually && !!visibleEditableRows?.length) {
        event?.api?.selectAll();
        visibleEditableRows?.forEach(({ data }) => {
          if (data) {
            rowsData.push(data);
          }
        });
      } else {
        (event?.api?.getServerSideSelectionState()?.toggledNodes || []).forEach((rowIndex) => {
          const rowNode = event?.api.getRowNode(rowIndex as string);

          if (rowNode?.data) {
            rowsData.push(rowNode.data);
          }
        });
      }
    }

    this.selectedRows = [...rowsData];

    this.isBulkApplyButtonDisabled$.next(!rowsData.length);
  };

  selectRows() {
    const selectedDocumentIds = this.selectedRows.map((document) => document.id);

    this.documentLibraryComponent.gridAPI.forEachNode((node) => {
      if (selectedDocumentIds.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }

  onBulkApplyButtonClick = async () => {
    const modalRef = this.overlayService.openPopup<
      EditMultipleDocumentsModalData,
      { updateGrid: boolean }
    >({
      modal: EditMultipleDocumentsModalComponent,
      settings: {
        header: `Bulk Edit: ${this.selectedRows.length} Selected Documents`,
        primaryButton: { label: 'Apply' },
      },
      data: {
        selectedRows: this.selectedRows,
        formGroup: this.documentLibraryForm,
      },
    });

    const { data } = await firstValueFrom(modalRef.afterClosed$);

    if (data?.updateGrid) {
      this.updateGridData();
    }
  };

  reset(): void {
    this.loading$.next(true);
    this.resetViewState();

    setTimeout(() => {
      this.loading$.next(false);
    });
  }
}
