<div class="flex gap-2">
  @if (isMonthlyReviewNotes) {
    <aux-monthly-review-notes-counter [workflow$]="workflow$" class="shrink-0" />
  } @else {
    <aux-icon [name]="'ClipboardCheck'" class="text-aux-blue-dark shrink-0" />
  }
  <div class="flex-column">
    <h3 class="font-bold">{{ title }}</h3>
    <span class="italic">{{ description }}</span>
  </div>
</div>
<div class="textarea-container relative">
  <aux-input
    [placeholder]="title"
    [textArea]="true"
    [rows]="7"
    resize="none"
    class="mt-4 textarea-input"
    [validators]="validators"
    [formControl]="textarea"
    (input)="onChange($event.target)"
    (blured)="onBlur()"
  />
  @if (textarea.disabled) {
    <div
      class="bg-gray-100 py-2 overflow-y-auto leading-5 px-3 font-medium border border-solid w-full h-full absolute top-0"
    >
      @if (isTaskInstructions) {
        <span class="text whitespace-pre-wrap">{{ textarea.value }}</span>
        <aux-icon
          name="Pencil"
          class="edit my-2 mx-3 right-0 top-0 absolute text-aux-blue-dark"
          (click)="onEdit()"
          [class.prohibited]="
            !checklistAdminPermission || periodCloseService.currentOpenMonth !== selectedPeriod
          "
          [auxTooltip]="noPermissionsTooltipText()"
        />
      } @else {
        <span class="text text-zinc-400 select-none font-normal pointer-events-none italic"
          >Monthly Review Notes</span
        >
      }
    </div>
  }
</div>
<div class="mt-4 flex justify-between">
  <div>
    @if (isTaskInstructions) {
      <span
        >Last Updated:
        {{ taskInstruction?.updated ? (taskInstruction?.updated | date) : 'N/A' }}</span
      >
    }
  </div>
  @if (isActionsVisible) {
    <div class="flex gap-2">
      <aux-button
        label="Save"
        variant="success"
        icon="Check"
        [auxTooltip]="saveButtonTooltip"
        [disabled]="textarea.invalid"
        (click)="!pending && onSave()"
      />
      <aux-button label="Cancel" variant="secondary" class="cancel h-full" (click)="onCancel()" />
    </div>
  }
</div>
@if (isMonthlyReviewNotes) {
  @if (workflow$ | async; as workflow) {
    <aux-monthly-review-notes
      [records]="quarterCloseNotesQuery.monthlyReviewNotes$(workflow.id) | async"
    />
  }
}
