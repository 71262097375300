<span *ngIf="label" class="block mb-1 text-xs multiple-select-dropdown-label"
  >{{ label }} <span *ngIf="requiredLabel" class="text-aux-error font-bold">*</span></span
>
<div class="flex multi-select">
  <ng-select
    class="w-full tabular-nums"
    [ngClass]="classes"
    [multiple]="multiple()"
    [clearable]="clearable"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [formControl]="fc"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [loading]="loading"
    [appendTo]="appendTo"
    (ngModelChange)="onChange(fc.value)"
  >
    <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp class="flex-nowrap">
      <div
        *ngIf="items.length <= maximumItemsToDisplay"
        class="ng-value space-x-2 flex bg-transparent"
        style="background-color: transparent"
      >
        <div *ngFor="let item of items" class="flex" style="background-color: #ebf5ff">
          <span class="ng-value-icon left" aria-hidden="true" (mousedown)="clear(item)"> × </span>

          <span class="ng-value-label overflow-hidden text-ellipsis">
            {{ getOptionLabel(item) }}
          </span>
        </div>
      </div>

      <div *ngIf="items.length > maximumItemsToDisplay" class="ng-value">
        <span class="ng-value-label">{{ items.length }} Selected</span>
      </div>
    </ng-template>

    <ng-template *ngIf="customOption" let-item="item" ng-option-tmp>
      <div
        auxTooltip
        [template]="
          optionTitle.offsetWidth < optionTitle.scrollWidth ||
          optionSubtitle.offsetWidth < optionSubtitle.scrollWidth
            ? tooltipTemplate
            : undefined
        "
        auxTooltipPanelContainerClass="multiple-select-dropdown-option-tooltip"
        class="leading-5 pr-4 text-sm"
      >
        <p class="font-bold truncate" #optionTitle>
          {{ item.title }}
        </p>
        <p class="italic truncate" #optionSubtitle>
          {{ item.subTitle }}
        </p>
      </div>

      <ng-template #tooltipTemplate>
        <div>
          <p>{{ item.title }}</p>
          <p>{{ item.subTitle }}</p>
        </div>
      </ng-template>
    </ng-template>
  </ng-select>
  <div
    *ngIf="prefix"
    class="flex justify-center items-center border rounded border-aux-gray-dark rounded-bl-none rounded-tl-none"
    style="height: 35px; width: 25px; min-width: 25px"
    [ngClass]="{ 'cursor-pointer': !!prefixClick }"
    (click)="prefixClick && prefixClick()"
  >
    {{ prefix }}
  </div>
</div>
