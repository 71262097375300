import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderGroupParams } from '@ag-grid-community/core';
import { AgHeaderExpandComponent } from '../../../budget-page/tabs/budget-enhanced/ag-header-expand.component';

interface AgExpandableGroupHeaderParams extends IHeaderGroupParams {
  collapsedByDefault: boolean;
  filterCols: () => boolean;
  localStorageKey: string;
  eyeIconFunction: () => void;
  eyeIconTooltip?: string;
}

@Component({
  template: `
    <div class="flex items-center">
      <span>{{ params.displayName }}</span>
      <div
        *ngIf="params.eyeIconFunction"
        class="flex rounded-full p-[4px] cursor-pointer"
        [auxTooltip]="params.eyeIconTooltip || ''"
      >
        <aux-icon name="Eye" class="text-aux-blue-dark" (click)="params.eyeIconFunction()" />
      </div>
      <aux-icon
        [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
        class="ml-1 cursor-pointer"
        (click)="toggleExpand()"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgExpandableGroupHeaderComponent extends AgHeaderExpandComponent {
  params!: AgExpandableGroupHeaderParams;

  visible = true;

  agInit(params: AgExpandableGroupHeaderParams): void {
    this.params = params;
    this.filterCols = params.filterCols;
    this.visible = !params.collapsedByDefault;
    this.initializeExpandCols();
  }
}
