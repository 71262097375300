import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';

export interface RemoveDialogInput {
  cannotDeleteMessage: string;
  routeInputs: RouteInput[];
  canDeleteMilestone?: {
    startDate: string | null;
    endDate: string | null;
  };
  clickRouteCallback?: (name: string) => void;
}

export interface RouteInput {
  componentName: string;
  name: string[];
  link: string;
}

@Component({
  template: `
    <div [innerHTML]="cannotDeleteMessage"></div>

    @for (route of routeInputs; track route.link) {
      <div class="mb-4">
        <br />{{ route.componentName }}<br />
        <ul class="list-disc pl-4">
          @for (name of route.name; track name) {
            @if (name) {
              <li>
                <a
                  class="aux-link cursor-pointer"
                  [routerLink]="[route.link]"
                  (click)="onRouteClick(name)"
                >
                  {{ name }}
                </a>
              </li>
            }
          }
        </ul>
      </div>
    }

    @if (canDeleteMilestone?.startDate) {
      <div class="mt-2">
        Trial Timeline cannot be shifted after
        {{ canDeleteMilestone?.startDate | date: 'MM/dd/YYYY' }}
      </div>
    }
    @if (canDeleteMilestone?.endDate) {
      <div class="mt-2">
        Trial Timeline cannot be shifted before
        {{ canDeleteMilestone?.endDate | date: 'MM/dd/YYYY' }}
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, DatePipe],
})
export class RemoveDialogComponent {
  cannotDeleteMessage: string;

  routeInputs: RouteInput[];

  canDeleteMilestone: RemoveDialogInput['canDeleteMilestone'] = { startDate: null, endDate: null };

  constructor(public ref: CustomOverlayRef<unknown, RemoveDialogInput>) {
    this.cannotDeleteMessage = ref.data?.cannotDeleteMessage || '';
    this.routeInputs = ref.data?.routeInputs || [];
    this.canDeleteMilestone = ref.data?.canDeleteMilestone || { startDate: null, endDate: null };
  }

  onRouteClick(name: string): void {
    this.ref.data?.clickRouteCallback?.(name);
    this.ref.close();
  }
}
