<section
  class="w-full flex border-l-4 border-aux-blue"
  [class]="className()"
  [ngClass]="{ 'bg-aux-gray-light': !locked(), 'bg-aux-blue-dark': locked() }"
>
  @if (isLoading()) {
    <div class="border-8 m-auto spinner h-24 w-24"></div>
  } @else {
    <div class="flex flex-row items-center justify-center border border-aux-gray-dark">
      <aux-icon
        name="CircleArrowLeft"
        class="m-1.5"
        [ngClass]="{ 'text-aux-blue': !locked(), 'text-white': locked() }"
      />
      <a
        style="margin-left: -1rem"
        class="btn--hyperlink text-center"
        [ngClass]="{ 'text-aux-blue': !locked(), 'text-white hover:!text-white/90': locked() }"
        [routerLink]="[quarterCloseChecklistLink]"
      >
        Close Checklist
      </a>
    </div>

    <div class="grid grid-cols-1 w-full">
      <aux-workflow-panel-general-item
        [workflowName]="workflowName()"
        [lockAvailable]="!processing()"
        [hasUserPermissions]="hasUserPermissions()"
      />
    </div>
  }
</section>
