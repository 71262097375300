import { OnInit, Directive, Input, signal } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import {
  CreateDriverBlendedPatientDistributionInput,
  createDriverBlendedPatientDistributionMutation,
  CreateDriverBlendedSiteDistributionInput,
  createDriverBlendedSiteDistributionMutation,
  UpdateDriverBlendedPatientDistributionInput,
  updateDriverBlendedPatientDistributionMutation,
  UpdateDriverBlendedSiteDistributionInput,
  updateDriverBlendedSiteDistributionMutation,
} from '@shared/services/gql.service';
import { BlendedCurveModalDataModel } from '../models/blended-curve-modal-data.model';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';

@Directive()
export abstract class BlendedCurveModalDirective<
  T extends { name: string },
  Z extends { id: string },
> implements OnInit
{
  @Input() availableGroups: (T & {
    check: boolean;
  })[] = [];

  selectedCurveIds: string[] = [];

  labelTopic = 'Create';

  labelButton = 'Create';

  blendedCurveForm = this.formBuilder.group({
    blendedCurveName: '',
  });

  loading = signal(false);

  protected constructor(
    public ref: CustomOverlayRef<unknown, BlendedCurveModalDataModel<T>>,
    public formBuilder: UntypedFormBuilder
  ) {}

  abstract constituentGroups: Z[];

  abstract updateData():
    | UpdateDriverBlendedSiteDistributionInput
    | UpdateDriverBlendedPatientDistributionInput;

  abstract createData():
    | CreateDriverBlendedSiteDistributionInput
    | CreateDriverBlendedPatientDistributionInput;

  abstract updateDriverBlendedDistribution(): Promise<
    GraphqlResponse<
      updateDriverBlendedSiteDistributionMutation | updateDriverBlendedPatientDistributionMutation
    >
  >;

  abstract createDriverBlendedDistribution(): Promise<
    GraphqlResponse<
      createDriverBlendedSiteDistributionMutation | createDriverBlendedPatientDistributionMutation
    >
  >;

  abstract getGroupId(item: T): string;

  ngOnInit(): void {
    this.loading.set(true);
    if (this.ref.data) {
      this.init();
    }
    this.loading.set(false);
  }

  setValue(item: T, checked = false): void {
    const index = this.selectedCurveIds.findIndex((x: string) => {
      return x === this.getGroupId(item);
    });
    if (checked) {
      if (index === -1) {
        this.selectedCurveIds.push(this.getGroupId(item));
      }
    } else if (index !== -1) {
      this.selectedCurveIds.splice(index, 1);
    }
  }

  async saveBlendedCurve(): Promise<void> {
    if (this.blendedCurveForm.invalid) {
      return;
    }

    this.loading.set(true);
    let response;
    if (this.ref.data?.blendedCurve) {
      response = await this.updateDriverBlendedDistribution();
    } else {
      response = await this.createDriverBlendedDistribution();
    }
    this.loading.set(false);
    if (response.success) {
      this.ref.close({ data: response?.data });
    }
  }

  get title(): string {
    return this.ref.data?.text.title || '';
  }

  get subTitle(): string {
    return this.ref.data?.text.subTitle || '';
  }

  get validators(): ValidatorFn[] {
    return [
      Validators.required,
      CustomValidators.valueNotInList(
        this.ref.data?.existingCurvesNames || [],
        'duplicatedName',
        true
      ),
    ];
  }

  private init(): void {
    if (this.constituentGroups.length) {
      this.constituentGroups.forEach((x) => {
        this.selectedCurveIds.push(x.id);
      });
    }

    if (this.ref.data?.blendedCurve) {
      this.labelTopic = 'Edit';
      this.labelButton = 'Update';

      const blendedCurveName = this.ref.data.blendedCurve.name;

      this.blendedCurveForm.setValue({ blendedCurveName });

      this.ref.data.availableGroups.forEach((availableGroup) => {
        const curve = this.constituentGroups.find((constituentGroup) => {
          return constituentGroup.id === this.getGroupId(availableGroup);
        });
        this.availableGroups.push({ ...availableGroup, check: !!curve });
      });
    } else {
      this.ref.data?.availableGroups.forEach((availableGroup) => {
        this.availableGroups.push({ ...availableGroup, check: false });
      });
    }
  }
}
