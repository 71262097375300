import { ColGroupDef, ISetFilterParams, ColDef } from '@ag-grid-community/core';

import { BudgetActivityAttributes } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { AuxExcelStyleKeys } from '@shared/utils';
import { BudgetAttributesComponent } from '../budget-attributes.component';
export const getEncodedAttributeName = (attributeName: string) =>
  `custom_attr_${btoa(encodeURIComponent(attributeName))}`;

export const getAttributeColumns = ({
  attributes: allAttributes,
}: {
  attributes: BudgetActivityAttributes[][];
}) => {
  const maxLength = 6;
  const usedColumns: BudgetActivityAttributes[] = [];
  for (const attributes of allAttributes) {
    const usedNames = usedColumns.map((usedAttr) => usedAttr.attribute_name);
    const remainingAttributes = attributes
      .filter((attr) => !usedNames.includes(attr.attribute_name))
      .slice()
      .sort((a, b) => Utils.localeAlphaNumSort(a.attribute_name || '', b.attribute_name || ''));
    if (remainingAttributes.length) {
      usedColumns.push(...remainingAttributes.slice(0, maxLength - usedColumns.length));
    }

    if (usedColumns.length === maxLength) {
      break;
    }
  }

  return usedColumns;
};

export const attributeColumnDef = (
  attributes: BudgetActivityAttributes[],
  localStorageKey: string,
  applyExelStyles?: boolean
): ColGroupDef => {
  const hide = localStorage.getItem(localStorageKey) === 'false';

  const baseChildColumnDef = {
    suppressMenu: false,
    filter: true,
    filterParams: {
      showTooltips: true,
    } as ISetFilterParams,
    menuTabs: ['filterMenuTab'],
    width: 125,
    minWidth: 125,
    resizable: false,
    headerClass: 'ag-header-align-center',
    // sorting used in the compare component
    comparator: (a, b) => {
      return Utils.localeAlphaNumSort(a || '', b || '');
    },
    cellRenderer: BudgetAttributesComponent,
    hide,
  } as ColDef;

  const children: ColDef[] = attributes
    .map((attr, index, array) => {
      const name = attr.attribute_name || '';
      return {
        ...baseChildColumnDef,
        headerName: name,
        field: getEncodedAttributeName(name),
        headerTooltip: name,
        headerClass: [
          'ag-header-align-center',
          name.includes(' ') ? 'ag-header-clamp' : 'ag-header-truncate',
          applyExelStyles && index === 0 ? AuxExcelStyleKeys.BORDER_LEFT : '',
          applyExelStyles && index === array.length - 1 ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        cellRendererParams: {
          custom: {
            name,
          },
          col_index: index,
        },
        cellClass: [
          'grid-cell',
          applyExelStyles && index === 0 ? AuxExcelStyleKeys.BORDER_LEFT : '',
          applyExelStyles && index === array.length - 1 ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
      };
    })
    .filter((z) => z) as ColDef[];

  return {
    headerName: 'Attributes',
    headerClass: ['ag-header-align-center attribute-header'],
    hide: false,
    children,
  } as ColGroupDef;
};
