import { NgModule } from '@angular/core';
import { ToggleCurrencyComponent } from './toggle-currency/toggle-currency.component';
import { CompareBudgetComponent } from './compare-budget/compare-budget.component';
import { TrialTasksComponent } from './trial-tasks/trial-tasks.component';

const sharedComponents = [
  ToggleCurrencyComponent,
  CompareBudgetComponent,
  TrialTasksComponent,
] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class WidgetsModule {}
