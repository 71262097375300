import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  signal,
  DestroyRef,
  inject,
  computed,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OverlayService } from '@shared/services/overlay.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable } from 'rxjs';
import { OrganizationService } from '@models/organization/organization.service';
import { MainQuery } from '@shared/store/main/main.query';
import { FileMetadata } from '@shared/services/api.service';
import {
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { Utils } from '@shared/utils/utils';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { EventService } from '@models/event/event.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { FormErrorDirective } from '@shared/directives/form-error.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileViewerComponent } from '@features/file-viewer/file-viewer.component';
import { InputComponent } from '@shared/components/input/input.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  SuggestionsConfirmationModalComponent,
  SuggestionsModalData,
} from '@shared/components/modals/suggestions-confirmation-modal/suggestions-confirmation-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { SuggestionIconColor } from '@shared/components/modals/suggestions-confirmation-modal/suggestion.component';
import { NavigationExtras, Router } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { VendorWorkflowDirective } from '@pages/vendor-payments-page/directives/vendor-workflow.directive';
import dayjs from 'dayjs';
import { TrimInputDirective } from '@shared/directives/trim-input.directive';

export interface OrganizationDialogData {
  id?: string;
  fetchFilesOnInit?: boolean;
  userHasPermission: boolean;
  currentQuarterMonths?: string[];
}

@Component({
  selector: 'aux-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  providers: [VendorWorkflowDirective],
  standalone: true,
  imports: [
    NgSelectModule,
    InputComponent,
    CommonModule,
    ReactiveFormsModule,
    FormErrorDirective,
    TooltipDirective,
    FormErrorDirective,
    AsyncPipe,
    FileManagerComponent,
    FileViewerComponent,
    CheckboxComponent,
    TrimInputDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationDialogComponent implements OnInit, AfterViewInit {
  readonly currencyOptions = Utils.CURRENCY_OPTIONS;

  readonly vendorsRoutingPath = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.VENDORS}`;

  private readonly destroyRef = inject(DestroyRef);

  private readonly vendorWorkflow = inject(VendorWorkflowDirective);

  @ViewChild('fileManager') fileManager: FileManagerComponent | undefined;

  @ViewChild('fileManager2') fileManager2: FileManagerComponent | undefined;

  @ViewChild('fileManager3') fileManager3: FileManagerComponent | undefined;

  @ViewChild('description') description!: TemplateRef<unknown>;

  hasFormChanges = signal<boolean>(false);

  isThereAnyChangesToFiles = signal<boolean>(false);

  contractHasBeenUploaded = signal<boolean>(false);

  proposalHasBeenUploaded = signal<boolean>(false);

  otherHasBeenUploaded = signal<boolean>(false);

  filesRemoved = signal<boolean>(false);

  saveBtnTooltip = computed(() => {
    return this.hasChanges() ? '' : 'No changes to save.';
  });

  hasChanges = computed(() => {
    return this.hasFormChanges() || this.isThereAnyChangesToFiles() || this.filesRemoved();
  });

  fg = this.formBuilder.group({
    contact_id: '',
    currency: [null, Validators.required],
    given_name: '',
    family_name: '',
    email: ['', CustomValidators.emailValidator()],
    title: '',
    phone_number: '',
    parent_organization_id: '',
    costs_included_in_parent_wo: false,
    is_third_party: false,
    receives_ve: false,
    vendor_type: ['', Validators.required],
    receives_vendor_estimate: true,
  });

  permissionMessage = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  vendorFc = new UntypedFormControl();

  mode: 'edit' | 'add' = 'edit';

  loading$ = new BehaviorSubject(false);

  vendorId: string | null = null;

  ORGANIZATION = EntityType.ORGANIZATION;

  DOCUMENT_VENDOR_CONTRACT = DocumentType.DOCUMENT_VENDOR_CONTRACT;

  DOCUMENT_PROPOSAL = DocumentType.DOCUMENT_PROPOSAL;

  DOCUMENT_OTHER = DocumentType.DOCUMENT_OTHER;

  vendorCurrencyEnabled$: Observable<boolean>;

  vendorTypeOptions = Object.entries(Utils.VendorTypeEnum).map(([key, label]) => ({
    label,
    key,
  }));

  fetchFilesOnInit = true;

  userHasPermission = false;

  initialFgValue = this.fg.value;

  initialVendorVal = this.vendorFc.value;

  suggestionsModal: CustomOverlayRef | undefined;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isInvoicesLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  private featureFlagPOtab = this.launchDarklyService.$select((flags) => flags.tab_purchase_orders);

  private featureFlagInvoicesTab = this.launchDarklyService.$select((flags) => flags.tab_invoices);

  private featureFlagVendorsTab = this.launchDarklyService.$select((flags) => flags.tab_vendors);

  private featureFlagBudgetNav = this.launchDarklyService.$select((flags) => flags.nav_budget);

  private featureFlagInvoicesNav = this.launchDarklyService.$select((flags) => flags.nav_invoices);

  private hasPOPermission = signal(false);

  private hasNoUploadBudgetPermission = signal(true);

  private timelineExist = toSignal(this.mainQuery.selectTimelineExist());

  private addPoSuggestionHidden = computed(() => {
    return !this.featureFlagPOtab() || !this.featureFlagInvoicesNav();
  });

  private addBudgetSuggestionHidden = computed(() => {
    return !this.featureFlagBudgetNav() || this.hasNoUploadBudgetPermission();
  });

  private addInvoiceSuggestionHidden = computed(() => {
    return !this.featureFlagInvoicesNav() || !this.featureFlagInvoicesTab();
  });

  private addVendorSuggestionHidden = computed(() => {
    return !this.featureFlagInvoicesNav() || !this.featureFlagVendorsTab();
  });

  private addBudgetSuggestionDisabled = computed(() => {
    return this.isChangeOrdersWorkflowLocked() || !this.timelineExist();
  });

  private addInvoicesDisabled = computed(() => {
    return this.iCloseMonthsProcessing() || this.isInvoicesLocked();
  });

  private addPODisabled = computed(() => {
    return !this.hasPOPermission() || this.isChangeOrdersWorkflowLocked();
  });

  private addBudgetTooltip = computed(() => {
    if (!this.timelineExist()) {
      return MessagesConstants.TIMELINE_MUST_BE_ENTERED;
    }

    return this.lockedForPeriodCloseTooltip();
  });

  private lockedForPeriodCloseTooltip = computed(() => {
    if (this.isChangeOrdersWorkflowLocked()) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  });

  private addPOTooltip = computed(() => {
    if (!this.hasPOPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.lockedForPeriodCloseTooltip();
  });

  isVendorEstimateExists = false;

  currentOpenMonth = '';

  currentQuarterMonths: string[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: CustomOverlayRef<unknown, OrganizationDialogData>,
    private overlayService: OverlayService,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private authQuery: AuthQuery,
    private ld: LaunchDarklyService,
    private mainQuery: MainQuery,
    public vendorsQuery: OrganizationQuery,
    private eventService: EventService,
    private overlay: Overlay,
    public router: Router,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private eventQuery: EventQuery,
    private launchDarklyService: LaunchDarklyService,
    private gqlService: GqlService
  ) {
    this.fetchFilesOnInit = this.ref.data?.fetchFilesOnInit ?? this.fetchFilesOnInit;
    this.userHasPermission = this.ref.data?.userHasPermission ?? this.userHasPermission;
    this.vendorCurrencyEnabled$ = this.ld.select$((flags) => flags.vendor_currency);
    this.fg.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkChangesFromForm();
    });
    this.vendorFc.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.checkChangesFromForm());
    this.watchPermissions();
  }

  async ngOnInit() {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((currentOpenMonth) => {
        this.currentOpenMonth = dayjs(currentOpenMonth).format('MMM-YYYY').toUpperCase();
      });

    this.mode = 'add';

    if (this.ref.data?.id) {
      this.mode = 'edit';
      this.vendorId = this.ref.data.id;
      this.currentQuarterMonths = [...(this.ref.data.currentQuarterMonths || [])];

      const org = this.organizationQuery.getEntity(this.vendorId);
      if (org) {
        const { id, name, currency, parent_organization, vendor_type, receives_vendor_estimate } =
          org;

        this.loading$.next(true);
        const { success, data } = await firstValueFrom(this.organizationService.getContacts(id));
        if (success && data) {
          this.fg.patchValue({
            ...data,
            name,
            currency,
            receives_vendor_estimate,
            contact_id: data.id,
            parent_organization_id: parent_organization?.id,
            costs_included_in_parent_wo: org.costs_included_in_parent_wo,
            vendor_type: vendor_type,
          });
          this.fg.markAsUntouched({ onlySelf: true });
          this.refreshVendorValidators();
        } else {
          this.fg.patchValue({
            name,
            currency,
            receives_vendor_estimate,
            parent_organization_id: parent_organization?.id,
            costs_included_in_parent_wo: org.costs_included_in_parent_wo,
            vendor_type: vendor_type,
          });
        }
        this.vendorFc.setValue(org.name);
        this.initialVendorVal = org.name;
        if (org.baseline_budget_version) {
          this.fg.get('currency')?.disable();
        }

        await this.initVendorEstimateExist();

        this.loading$.next(false);
      } else {
        this.overlayService.error('User not found!');
      }
    }
  }

  ngAfterViewInit(): void {
    this.checkFileChanges();
  }

  async checkFileChanges() {
    combineLatest(
      this.fileManager?.fileQuery.selectAll() || EMPTY,
      this.fileManager2?.fileQuery.selectAll() || EMPTY,
      this.fileManager3?.fileQuery.selectAll() || EMPTY
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([contracts, proposals, others]) => {
        const isThereNewContract = contracts.filter((contract) => !contract.uploaded).length > 0;
        const isThereNewProposal = proposals.filter((proposal) => !proposal.uploaded).length > 0;
        const isThereNewOthers = others.filter((other) => !other.uploaded).length > 0;
        this.isThereAnyChangesToFiles.set(
          isThereNewContract || isThereNewProposal || isThereNewOthers
        );
      });
  }

  getFc(str: string) {
    return this.fg.get(str);
  }

  refreshVendorValidators(): void {
    const controlNames = [
      'given_name',
      'family_name',
      'email',
      'title',
      'phone_number',
      'vendor_type',
    ];
    const vendorFieldsEdited =
      controlNames.some((name) => {
        const ctl = this.fg.get(name);
        ctl?.markAsTouched({ onlySelf: true });
        return ctl?.value;
      }) || this.fg.get('contact_id')?.value;
    for (const name of ['given_name', 'family_name']) {
      const ctl = this.fg.get(name);
      const validators = vendorFieldsEdited ? [Validators.required] : [];
      ctl?.setValidators(validators);
      ctl?.updateValueAndValidity();
    }
  }

  onCancel() {
    this.ref.close();
  }

  getMetadata(): () => FileMetadata {
    return () => ({});
  }

  getFilePaths(type: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return () => (this.vendorId ? `trials/${trialId}/vendors/${this.vendorId}/${type}/` : '');
  }

  async onSubmit() {
    this.vendorFc.markAsTouched();
    this.vendorFc.updateValueAndValidity();

    Object.keys(this.fg.controls).forEach((key) => {
      this.fg.get(key)?.markAsTouched();
      this.fg.get(key)?.updateValueAndValidity();
    });

    if (this.vendorFc.value && this.fg.valid && this.vendorFc.valid) {
      this.loading$.next(true);
      if (this.mode === 'add') {
        const {
          currency,
          given_name,
          family_name,
          email,
          title,
          phone_number,
          parent_organization_id,
          costs_included_in_parent_wo,
          vendor_type,
          receives_vendor_estimate,
        } = this.fg.value;
        const { success, data, errors } = await this.organizationService.add(
          this.vendorFc.value,
          {
            given_name,
            family_name,
            email,
            title,
            phone_number,
            parent_organization_id,
            costs_included_in_parent_wo,
            vendor_type,
            receives_vendor_estimate,
          },
          currency || null
        );
        if (success && data) {
          this.vendorId = data.id;
          await this.processFiles(this.vendorId);
          this.openSuccessConfirmationModal();
          this.ref.close();
        } else {
          this.overlayService.error(errors);
        }
      } else if (this.vendorId) {
        const {
          currency,
          given_name,
          family_name,
          email,
          title,
          phone_number,
          parent_organization_id,
          costs_included_in_parent_wo,
          vendor_type,
          receives_vendor_estimate,
        } = this.fg.value;

        const { success, errors } = await this.organizationService.update(
          this.vendorId,
          this.vendorFc.value,
          {
            given_name,
            family_name,
            email,
            title,
            phone_number,
            parent_organization_id,
            costs_included_in_parent_wo,
            vendor_type,
            receives_vendor_estimate,
          },
          currency || null
        );
        await this.processFiles(this.vendorId);
        if (success) {
          this.overlayService.success('Vendor successfully updated!');
          this.ref.close();
        } else {
          this.overlayService.error(errors);
        }
      }
    }
    this.loading$.next(false);
  }

  async processFiles(vendor_id: string) {
    await this.upload(this.fileManager, 'contracts', vendor_id);
    await this.upload(this.fileManager2, 'proposals', vendor_id);
    await this.upload(this.fileManager3, 'other', vendor_id);
  }

  openSuccessConfirmationModal(): void {
    this.suggestionsModal = this.overlayService.openPopup({
      content: SuggestionsConfirmationModalComponent,
      settings: {
        header: 'Vendor Added Successfully',
        headerIcon: { name: 'CircleCheck', color: SuggestionIconColor.GREEN },
        panelClasses: ['suggestions-confirmation'],
        backdropClass: ['ttt'],
        positionStrategy: this.overlay.position().global().right('32px').top('80px'),
        hideActions: true,
      },
      data: this.getSuggestionsData(),
    });
  }

  upload = async (
    fileManager: FileManagerComponent | undefined,
    fileType: string,
    vendor_id: string
  ) => {
    if (fileManager) {
      const path = this.getFilePaths(fileType);
      const files = fileManager.fileQuery.getAll();
      let fileNames = '';
      for (const file of files) {
        fileNames += `${file.fileName} `;
        fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${path()}${file.bucket_key}`,
        });
      }
      const currentFiles = fileManager.fileStore.getValue().entities;
      let documentType = DocumentType.DOCUMENT_OTHER;
      let aNewFileWasUploaded = false;
      if (fileType === 'contracts') {
        documentType = DocumentType.DOCUMENT_VENDOR_CONTRACT;
        if (currentFiles) {
          aNewFileWasUploaded = this.contractHasBeenUploaded();
        }
      }
      if (fileType === 'proposals') {
        documentType = DocumentType.DOCUMENT_PROPOSAL;
        if (currentFiles) {
          aNewFileWasUploaded = this.proposalHasBeenUploaded();
        }
      }
      if (fileType === 'other') {
        documentType = DocumentType.DOCUMENT_OTHER;
        if (currentFiles) {
          aNewFileWasUploaded = this.otherHasBeenUploaded();
        }
      }

      const success = await fileManager.fileService.uploadFiles(
        {
          vendor: vendor_id,
          documentType,
          entity_id: vendor_id,
          entity_type_id: EntityType.ORGANIZATION,
        },
        false,
        true
      );

      if (success && !this.authQuery.isAuxAdmin() && aNewFileWasUploaded && fileNames.length > 0) {
        await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.VENDOR_DOCUMENT_UPLOADED_NOTIFICATION,
            entity_type: EntityType.ORGANIZATION,
            entity_id: vendor_id,
            payload: JSON.stringify({
              document_type: documentType,
              uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
              file_name: fileNames,
            }),
          })
        );
      }
    }
  };

  onFilesAdded(fileType: string) {
    if (this.fileManager && fileType === 'contracts') {
      this.contractHasBeenUploaded.set(true);
    }
    if (this.fileManager2 && fileType === 'proposals') {
      this.proposalHasBeenUploaded.set(true);
    }
    if (this.fileManager3 && fileType === 'other') {
      this.otherHasBeenUploaded.set(true);
    }
  }

  onFilesRemoved() {
    this.filesRemoved.set(true);
  }

  checkChangesFromForm() {
    if (this.fg.pristine) {
      this.initialFgValue = this.fg.value;
    }
    if (this.vendorFc.pristine) {
      this.initialVendorVal = this.vendorFc.value;
    }
    const fgControl = !Object.keys(this.initialFgValue).every((key) => {
      const fgValue = this.fg.value[key] ? this.fg.value[key] : null;
      const initialValue = this.initialFgValue[key] ? this.initialFgValue[key] : null;
      return fgValue === initialValue;
    });
    const vendorNameControl = !!(
      this.initialVendorVal !== this.vendorFc.value && this.vendorFc.value !== null
    );
    this.hasFormChanges.set(fgControl || vendorNameControl);
  }

  private async initVendorEstimateExist(): Promise<void> {
    if (this.vendorId) {
      const currentOpenMonthIndex = this.currentQuarterMonths.indexOf(this.currentOpenMonth);
      const monthsForCheck = this.currentQuarterMonths.slice(currentOpenMonthIndex);

      for (const month of monthsForCheck) {
        if (!this.isVendorEstimateExists) {
          const result = await firstValueFrom(this.gqlService.listVendorEstimateSummaries$(month));

          if (this.vendorId && result.success && result.data) {
            this.isVendorEstimateExists = !!result.data.find(
              (vendor) => vendor.organization_id === this.vendorId
            )?.vendor_estimate_exists;
          }
        }
      }
    }
  }

  private watchPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_PURCHASE_ORDERS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPLOAD_BUDGET],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([POPermission, uploadBudgetPermission]) => {
        this.hasPOPermission.set(POPermission);
        this.hasNoUploadBudgetPermission.set(!uploadBudgetPermission);
      });
  }

  private getSuggestionsData(): SuggestionsModalData {
    const navigationExtras: NavigationExtras = {
      queryParams: { modalVendorId: this.vendorId },
    };
    const suggestedSteps = [
      {
        name: 'Add Purchase Order',
        iconName: 'Plus',
        pendoTag: 'add-vendor-modal-add-po',
        iconColor: SuggestionIconColor.GREEN,
        action: () => {
          this.router.navigate(
            [
              `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS}`,
            ],
            navigationExtras
          );
        },
        disabled: this.addPODisabled,
        tooltip: this.addPOTooltip,
        hidden: this.addPoSuggestionHidden,
      },
      {
        name: 'Add Budget',
        iconName: 'FileInvoice',
        pendoTag: 'add-vendor-modal-add-budget',
        iconColor: SuggestionIconColor.GREEN,
        action: () => {
          this.router.navigate(
            [`/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`],
            navigationExtras
          );
        },
        disabled: this.addBudgetSuggestionDisabled,
        tooltip: this.addBudgetTooltip,
        hidden: this.addBudgetSuggestionHidden,
      },
      {
        name: 'Add Invoice',
        iconName: 'FileInvoice',
        pendoTag: 'add-vendor-modal-add-invoice',
        iconColor: SuggestionIconColor.LIGHT_BLUE,
        action: () => {
          this.router.navigate(
            [`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`],
            navigationExtras
          );
        },
        disabled: this.addInvoicesDisabled,
        tooltip: this.workflowQuery.invoiceLockTooltip,
        hidden: this.addInvoiceSuggestionHidden,
      },
      {
        name: 'Add Another Vendor',
        iconName: 'User',
        pendoTag: 'add-vendor-modal-add-vendor',
        iconColor: SuggestionIconColor.PURPLE,
        action: () => {
          this.router.navigate([this.vendorsRoutingPath], {
            queryParams: { openNewVendorModal: true },
          });
        },
        disabled: this.vendorWorkflow.disabledUI,
        tooltip: this.vendorWorkflow.vendorActionTooltip,
        hidden: this.addVendorSuggestionHidden,
      },
    ];

    return {
      // Discussed with Jeremy, commented out for the future
      // description: this.description,
      suggestedSteps,
    };
  }

  vendorNameValidators: ValidatorFn[] = [
    (control) => {
      const value: string = control.value;
      if (!value) {
        return { required: true };
      }

      const vendor = this.vendorsQuery
        .getAll()
        .find((vendor) => vendor.name?.toLowerCase() === value.toLowerCase());

      return vendor && vendor.id !== this.vendorId ? { duplicateVendor: true } : null;
    },
  ];
}
