<div class="font-inter max-w-2xl" [ngClass]="{ 'w-screen': showInvoicesPaymentStatus() }">
  <div class="flex pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Vendor:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [(ngModel)]="vendor"
        (clear)="vendor.set(null)"
      >
        @for (option of vendorOptions(); track option.value) {
          <ng-option [value]="option.value">
            <span [title]="option.label">{{ option.label }}</span>
          </ng-option>
        }
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Status:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [items]="invoiceStatusOptions()"
        [(ngModel)]="status"
        (clear)="status.set(null)"
      >
        <ng-template let-item="item" ng-label-tmp ng-option-tmp>
          <div class="flex items-center whitespace-nowrap">
            <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
            <div [ngClass]="item.textClass">{{ item.label }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    @if (showInvoicesPaymentStatus()) {
      <div class="w-52 pl-4">
        <span class="text-xs mb-1">Payment Status:</span>
        <ng-select
          placeholder="Select"
          bindValue="value"
          [appendTo]="'body'"
          [items]="paymentStatusOptions()"
          [(ngModel)]="paymentStatus"
          (clear)="paymentStatus.set(null)"
        >
          <ng-template let-item="item" ng-label-tmp ng-option-tmp>
            <div class="flex items-center whitespace-nowrap">
              <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
              <div [ngClass]="item.textClass">{{ item.label }}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    }
  </div>
  <div class="flex pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Purchase Order:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [(ngModel)]="purchaseOrder"
        (clear)="purchaseOrder.set(null)"
      >
        @for (option of purchaseOrderOptions(); track option.value) {
          <ng-option [value]="option.value">
            <span [title]="option.label">{{ option.label }}</span>
          </ng-option>
        }
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Posting Period:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="accrualPeriod"
        (clear)="accrualPeriod.set(null)"
      >
        @for (option of accrualPeriodOptions(); track option.value) {
          <ng-option [value]="option.value">
            <span [title]="option.label">{{ option.label }}</span>
          </ng-option>
        }
      </ng-select>
    </div>

    <div class="w-52 pl-4">
      <span class="text-xs mb-1">Service Period:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="servicesPeriod"
        (clear)="servicesPeriod.set(null)"
      >
        @for (option of servicesPeriodOptions(); track option.value) {
          <ng-option [value]="option.value">
            <span [title]="option.label">{{ option.label }}</span>
          </ng-option>
        }
      </ng-select>
    </div>
  </div>
</div>
