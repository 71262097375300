import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  TemplateRef,
  viewChild,
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'aux-tab',
  template: ` <ng-template #tmp><ng-content></ng-content></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuxTabComponent {
  @Input() pendoTag = '';

  label = input.required<string>();
  tmp = viewChild.required<TemplateRef<unknown>>('tmp');
}
