import { Component, input } from '@angular/core';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { Utils } from '@shared/utils/utils';
import { MapActivitiesTotals } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { IconComponent } from '@shared/components/icon/icon.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { MessagesConstants } from '@shared/constants/messages.constants';

@Component({
  standalone: true,
  selector: 'aux-map-activities-modal-total-row',
  templateUrl: 'map-activities-modal-total-row.component.html',
  imports: [IconComponent, NgSelectModule, TooltipDirective],
})
export class MapActivitiesModalTotalRowComponent {
  readonly messagesConstants = MessagesConstants;
  invoice = input.required<InvoiceModel>();
  totals = input.required<MapActivitiesTotals>();
  protected readonly utils = Utils;
}
