import { NgClass } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseFormControlComponent } from '../base-form-control/base-form-control';

@Component({
  selector: 'aux-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
})
export class CheckboxControlComponent extends BaseFormControlComponent<boolean> {
  @Input() id = '';

  @Input() checked = false;

  @Input() disabled = false;

  @Output() customChange = new EventEmitter<boolean>();

  onCheckboxChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.checked = input.checked;
    this.onChange(this.checked);
    this.onTouched();
    this.customChange.emit(this.checked);
  }
}
