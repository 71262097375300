<div
  class="w-full flex items-center my-[30px]"
  [ngClass]="{ 'justify-between': toolbarKebabOpen(), 'justify-start': !toolbarKebabOpen() }"
>
  <div class="flex space-x-[24px] items-center">
    <span class="text-2xl font-bold">Investigator Transactions</span>
    <div
      class="border px-2 py-1 rounded text-sm w-max ml-3 font-bold"
      [ngStyle]="{
        'background-color': edcStatus().bgColor,
        'border-color': edcStatus().borderColor,
        color: edcStatus().color
      }"
    >
      {{ edcStatus().text.toUpperCase() }}
    </div>
    @if (showEdcBanner()) {
      <span>
        {{ edcBanner() }}
      </span>
    }
  </div>
  @if (toolbarKebabOpen()) {
    <aux-menu [showMenuForExternal]="true" #edc_notification_menu>
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
        (click)="onNotifyCustomer(); edc_notification_menu.close()"
      >
        <span>{{ firstKebabOptionName() }}</span>
      </button>
      @if (showEdcDataAvailable()) {
        <button
          type="button"
          role="menuitem"
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
          (click)="onReturnToProcessing(); edc_notification_menu.close()"
        >
          <span>{{ secondKebabOptionName() }}</span>
        </button>
      }
    </aux-menu>
  }
</div>
