<div class="w-80 max-h-180">
  <div class="grid grid-cols-1 gap-5 mb-2">
    <div class="flex flex-col">
      <aux-download-template-button
        [templateType]="siteTemplateType"
        [showIcon]="false"
        label="Download the template"
        class="flex justify-center mb-4"
      />
      <aux-file-manager
        #bulkFileManager
        class="h-48"
        [fetchFilesOnInit]="false"
        [path]="path"
        [eager]="false"
        [showSuccessOnUpload]="true"
      />
    </div>

    <ng-container *ngIf="bulkFiles$ | async as bulkFiles">
      <ng-container *ngIf="bulkFiles?.length">
        <div class="grid grid-cols-12 mt-4">
          <div class="col-span-12 pb-2 text-xs">Uploaded Files</div>

          <ng-container *ngFor="let bulkFile of bulkFiles" class="border-b">
            <div class="flex items-center col-span-11 p-2 border-b break-all">
              {{ bulkFile.fileName }}
            </div>

            <div class="flex justify-end border-b">
              <button class="p-2" (click)="removeBulkFile(bulkFile)">
                <aux-icon name="Trash" class="text-aux-error" />
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="errorMessage()" class="mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage() }}
    </div>
  </div>
</div>
