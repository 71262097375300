<div class="flex items-center mb-8">
  <!-- Header -->
  <div class="flex flex-col flex-grow">
    <span class="text-2xl font-bold">Welcome, {{ userName }}</span>
    <span class="text-base">{{ userOrganization }}</span>
  </div>

  <!-- Nav -->
  <div class="trial-insights-nav-container">
    <a
      *ngIf="showClinOpsButton$ | async"
      [routerLink]="clinicalRouterLink"
      (click)="setActive(RouterLinks.Clinical)"
      [ngClass]="isActive(RouterLinks.Clinical) ? 'btn--primary' : 'btn--secondary'"
    >
      ClinOps
    </a>

    <a
      *ngIf="showFinanceButton$ | async"
      [routerLink]="financeRouterLink"
      (click)="setActive(RouterLinks.Finance)"
      [ngClass]="isActive(RouterLinks.Finance) ? 'btn--primary' : 'btn--secondary'"
    >
      Finance
    </a>
  </div>
</div>
