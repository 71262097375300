@if ($any(timelineQuery.selectLoading() | async) || saving) {
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
} @else {
  @if ((isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)) {
    <aux-workflow-panel
      [workflowName]="workflowName"
      [processing]="(iCloseMonthsProcessing$ | async)!"
      [hasUserPermissions]="userHasLockTrialTimelinePermission()"
    />
  }
  <div class="form-group flex justify-between my-4">
    <aux-input class="w-72" placeholder="Search" icon="Search" [(ngModel)]="nameFilterValue" />
    <div class="flex">
      <aux-export-excel-button
        className="ml-1"
        [clickFN]="onExportTimeline.bind(this)"
        [loading]="isBtnLoading('export') | async"
        [spinnerSize]="7"
        pendoTag="timeline-excel-export-button"
      />
      @if (userHasModifyPermissions$ | async) {
        <aux-button
          variant="primary"
          label="Add Milestone"
          classList="ml-3"
          [disabled]="(isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
          [onClick]="this.addTimelineMilestone"
          [auxTooltip]="
            (isTimeLineFinalized$ | async) || (iCloseMonthsProcessing$ | async)
              ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE
              : ''
          "
        />
      }
    </div>
  </div>
}

<div
  [ngClass]="{
    'invisible h-0 overflow-hidden': saving || loadingTimelineQuery
  }"
>
  <ag-grid-angular
    class="ag-theme-aux tabular-nums"
    domLayout="autoHeight"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [quickFilterText]="nameFilterValue"
    (firstDataRendered)="onDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (rowGroupOpened)="onGroupOpened($event)"
    (rowDataUpdated)="onRowDataChanged()"
    (columnResized)="onColumnResized($event)"
    auxStickyGrid
  />
</div>

@if (hasChanges$ | async) {
  <aux-save-changes [showDiscardChangesBtn]="true" [onSaveChanges]="onSaveAll" (cancel)="reset()" />
}
