import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeOrderStatus } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'aux-ag-change-order-status',
  template: `
    <div class="flex items-center whitespace-nowrap">
      <div class="w-3 h-3 rounded-full mr-1" [ngClass]="bgClass"></div>
      <div [ngClass]="textClass">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderStatusComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const status: ChangeOrderStatus | string = params.data.status || '';

    switch (status) {
      case ChangeOrderStatus.STATUS_PENDING_REVIEW:
        this.bgClass = 'bg-aux-gray-darkest';
        this.textClass = 'text-aux-gray-darkest';
        this.status = 'Pending Review';
        break;
      case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
        this.bgClass = 'bg-aux-blue-light-200';
        this.textClass = 'text-aux-blue-light-200';
        this.status = 'Pending Approval';
        break;
      case ChangeOrderStatus.STATUS_APPROVED:
        this.bgClass = 'bg-aux-green-dark';
        this.textClass = 'text-aux-green-dark';
        this.status = 'Approved';
        break;
      case ChangeOrderStatus.STATUS_DECLINED:
        this.bgClass = 'bg-aux-red-dark';
        this.textClass = 'text-aux-red-dark';
        this.status = 'Declined';
        break;
      default:
        this.bgClass = '';
        this.textClass = '';
        this.status = Utils.zeroHyphen;
        break;
    }
  }
}
