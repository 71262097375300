import { Component, Input, input } from '@angular/core';
import {
  listDriverCustomGroupsQuery,
  listDriverCustomGroupsWithDataQuery,
} from '@shared/services/gql.service';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { NgClass } from '@angular/common';
import { ForecastTableGridMethodChange } from '../../models/forecast-table-grid.model';

@Component({
  selector: 'aux-forecast-table-grid-method-custom-curve',
  templateUrl: './forecast-table-grid-method-custom-curve.component.html',
  styleUrl: './forecast-table-grid-method-custom-curve.component.scss',
  standalone: true,
  imports: [NgSelectModule, FormsModule, TooltipDirective, NgClass],
})
export class ForecastTableGridMethodCustomCurveComponent {
  customCurves = input.required<listDriverCustomGroupsWithDataQuery[]>();

  onMethodChange = input.required<ForecastTableGridMethodChange>();

  categoryId = input.required<string>();

  primarySettingsId = input.required<string>();

  isChild = input(false);

  disabled = input(false);

  tooltip = input('');

  @Input() selectedCurve?: string = undefined;

  @Input() isInvalid = false;

  modelChange(event?: listDriverCustomGroupsQuery): void {
    const driver_setting_id = event?.driver_setting_id || null;

    const change = this.onMethodChange();

    change(driver_setting_id, this.primarySettingsId(), {
      name: !this.isChild() ? 'category' : 'activity',
      prop: 'driver_setting',
      id: this.categoryId(),
    });
  }
}
