<form #invoiceFormRef="ngForm" [formGroup]="invoiceForm">
  <div class="grid grid-cols-5 gap-5 p-5 rounded-t-md border mb-8">
    <div>
      <div class="text-sm mb-1">Vendor</div>
      <ng-select
        class="select"
        placeholder="Select"
        id="vendor"
        formControlName="vendor_id"
        auxFormError
        required
        [searchable]="false"
        [multiple]="false"
        [clearable]="false"
        [auxTooltip]="formTooltip() || vendorIdTooltip()"
        (change)="onVendorChange()"
      >
        @for (vendor of orgQuery.allVendors(); track vendor.id) {
          <ng-option [value]="vendor.id">{{ vendor.name }}</ng-option>
        }
      </ng-select>
    </div>

    <div>
      <div class="text-sm mb-1">Invoice Number</div>
      <aux-input
        formControlName="invoice_no"
        class="mb-4"
        validators="required"
        [auxTooltip]="formTooltip()"
      />
    </div>

    <div>
      <div class="text-sm mb-1">PO Number</div>
      <ng-select
        placeholder="Select"
        id="po_reference"
        [searchable]="false"
        [multiple]="false"
        formControlName="selectedPOReference"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': invoiceForm.controls.selectedPOReference.status === 'INVALID'
        }"
        [auxTooltip]="formTooltip()"
      >
        @for (po of filteredPONumbers(); track po.id) {
          <ng-option [value]="po.id">
            {{ po.po_number }}
          </ng-option>
        }
      </ng-select>
    </div>

    <div></div>
    <div></div>

    <div>
      <div class="text-sm mb-1">Invoice Total</div>
      <aux-input formControlName="invoice_total" [auxTooltip]="formTooltip()" />
    </div>

    <div>
      <div class="text-sm mb-1">Services Total</div>
      <aux-input formControlName="services_total" [auxTooltip]="formTooltip()" />
    </div>

    <div>
      <div class="text-sm mb-1">Discount Total</div>
      <aux-input formControlName="discount_total" [auxTooltip]="formTooltip()" />
    </div>

    <div>
      <div class="text-sm mb-1">Pass-Through Total</div>
      <aux-input formControlName="pass_thru_total" [auxTooltip]="formTooltip()" />
    </div>

    <div>
      <div class="text-sm mb-1">Investigator Total</div>
      <aux-input formControlName="investigator_total" [auxTooltip]="formTooltip()" />
    </div>

    @if (!isDecline()) {
      <div>
        <div class="text-sm mb-1">Posting Period</div>
        <aux-input
          formControlName="accrual_period"
          [disabled]="isFormDisabled()"
          [type]="'month'"
          [max]="maxDate()"
          [min]="accrualMinDate()"
          [auxTooltip]="formTooltip()"
          [validators]="accrualValidators"
          [customErrorMessages]="customErrorMessages"
        />
      </div>
    }

    <div>
      <div class="text-sm mb-1">Service Period</div>
      <aux-input
        formControlName="services_period"
        [type]="'month'"
        [max]="maxDate()"
        [auxTooltip]="formTooltip('services_period')"
        [validators]="servicesPeriodValidators"
        [customErrorMessages]="customErrorServicesPeriodMessages"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Invoice Date</div>
      <aux-input
        formControlName="invoice_date"
        validators="required"
        [type]="'date'"
        [auxTooltip]="formTooltip()"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Due Date</div>
      <aux-input formControlName="due_date" [type]="'date'" [auxTooltip]="formTooltip()" />
    </div>
  </div>

  <aux-invoice-detail-invoice-notes
    class="block mb-8"
    [notes]="invoiceForm.controls.notes.value"
    (notesChange)="invoiceForm.controls.notes.setValue($event)"
    (saveNotes)="saveNotes()"
    (cancelNotes)="cancelNotes()"
  />
</form>
