<div class="text-xl font-bold mb-4">Create New Trial</div>
<form [formGroup]="customFg" (ngSubmit)="createCustomTrial()">
  <div class="grid grid-cols-2 gap-5">
    <div>
      <label for="workspace_type" class="block mb-1 text-xs">
        Workspace Type <span class="text-aux-error font-bold">*</span>
      </label>
      <ng-select
        id="workspace_type"
        class="select h-10 text-sm text-aux-gray-lightest/75"
        formControlName="workspace_type"
        bindLabel="label"
        bindValue="key"
        label="Workspace Type"
        auxFormError
        [items]="workspaceTypeOptions"
        [clearable]="false"
        [searchable]="false"
        [multiple]="false"
        [required]="true"
      />
    </div>
    <div class="flex flex-col">
      <div class="text-xs mb-2">Skip Onboarding</div>
      <aux-toggle formControlName="onboarding_complete" />
    </div>
    <aux-input
      label="Trial Name"
      formControlName="name"
      validators="required"
      [showRequiredAsterisk]="true"
    />
    <aux-input
      label="Trial Short Name"
      formControlName="short_name"
      [validators]="trialShortNameValidators"
      [showRequiredAsterisk]="true"
    />
    <aux-input
      label="Sponsor"
      formControlName="sponsor_organization_name"
      validators="required"
      [showRequiredAsterisk]="true"
    />

    <div class="w-[196px]">
      <aux-multi-select-dropdown
        [items]="therapyAreaOptions"
        label="Therapy Area"
        [requiredLabel]="true"
        formControlName="therapy_area"
        class="w-full"
        [searchable]="true"
        bindLabel="label"
        bindValue="value"
        [required]="true"
        auxFormError
      ></aux-multi-select-dropdown>
    </div>

    <aux-input
      label="Indication"
      formControlName="indication"
      validators="required"
      [showRequiredAsterisk]="true"
    />

    <div>
      <label for="trial_phase" class="block mb-1 text-xs">
        Trial Phase <span class="text-aux-error font-bold">*</span>
      </label>
      <ng-select
        id="trial_phase"
        class="select h-10 text-sm text-aux-gray-lightest/75"
        formControlName="trial_phase"
        bindLabel="label"
        bindValue="value"
        label="Trial Phase"
        data-pendo-id="trial-phase-new-trial"
        auxFormError
        [items]="trialPhaseOptions"
        [clearable]="false"
        [searchable]="true"
        [multiple]="false"
        [required]="true"
      />
    </div>

    <div>
      <label for="trial_status" class="block mb-1 text-xs">
        Auxilius Trial Status <span class="text-aux-error font-bold">*</span>
      </label>
      <ng-select
        id="trial_status"
        class="select h-10 text-sm text-aux-gray-lightest/75"
        formControlName="implementation_status"
        bindLabel="label"
        bindValue="key"
        label="Implementation Status"
        auxFormError
        [items]="implementationStatusOptions"
        [clearable]="false"
        [searchable]="false"
        [multiple]="false"
        [required]="true"
      />
    </div>
    <aux-input
      label="Auxilius Start Date"
      class="grow"
      formControlName="auxilius_start_date"
      placeholder="YYYY-MM-DD"
      [type]="'date'"
    />
    <aux-input label="Program" formControlName="program" />
    <aux-input label="Project" formControlName="project" />
  </div>

  <div class="flex space-x-4 mt-8">
    <button class="btn--primary w-32" type="submit" [disabled]="loading$ | async">
      <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
      <span>Continue</span>
    </button>

    <button class="btn--tertiary" type="button" (click)="ref.close()">Cancel</button>
  </div>
</form>
