import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AuthService } from '@shared/store/auth/auth.service';
import { PermissionType } from '@shared/services/gql.service';

@Component({
  selector: 'aux-warning-vendor-estimate',
  template: `<div
    class="bg-aux-warn2 p-4"
    *ngIf="{
      userHasAdjustPermission: userHasAdjustPermission$ | async,
      userHasEditVEPermission: userHasEditVEPermission$ | async
    } as obj"
  >
    <p>
      Auxilius administrators will begin processing upon receipt, but for faster results you can now
      <span
        class="aux-link cursor-pointer text-aux-blue"
        [auxTooltip]="
          !obj.userHasAdjustPermission && !obj.userHasEditVEPermission ? notPermittedMessage : ''
        "
        (click)="(obj.userHasAdjustPermission || obj.userHasEditVEPermission) && linkClick.emit()"
        [ngClass]="{ 'opacity-80': !obj.userHasAdjustPermission && !obj.userHasEditVEPermission }"
        >add it yourself</span
      >
      and see it instantly for the current month. Copy and paste directly from your Excel tracker!
    </p>
  </div>`,
  imports: [NgIf, NgClass, TooltipDirective, AsyncPipe, CommonModule],
  standalone: true,
})
export class WarningVendorEstimateComponent {
  @Output() linkClick = new EventEmitter();

  notPermittedMessage = 'You do not have permission to edit monthly work performed.';

  userHasAdjustPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
  });

  userHasEditVEPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_EDIT_VENDOR_ESTIMATE],
  });

  constructor(private authService: AuthService) {}
}
