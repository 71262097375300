import { DestroyRef, Directive, ElementRef, HostListener, inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableConstants } from '@shared/constants/table.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const LG_BREAKPOINT = 2000;

@Directive({
  selector: '[auxGridDynamicHeight]',
  standalone: true,
})
export class GridDynamicHeightDirective implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  innerWidth$ = new BehaviorSubject(0);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth$.next(window.innerWidth);
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.innerWidth$.next(window.innerWidth);

    this.innerWidth$.pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.updateGridLayout();
    });
  }

  updateGridLayout() {
    this.elementRef.nativeElement.style.height =
      this.innerWidth$.getValue() > LG_BREAKPOINT
        ? TableConstants.SCROLL.MAX_TABLE_HEIGHT_LG
        : TableConstants.SCROLL.MAX_TABLE_HEIGHT;
  }
}
