import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { DocumentLibraryService } from '../document-library.service';
import { Option } from '@shared/types/components.type';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { isEqual, isNull } from 'lodash-es';
import { FormGroupTyped } from '@shared/utils/utils';
import { DocumentsComponentForm } from '../documents.component.model';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-document-library-filters',
  templateUrl: './document-library-filters.component.html',
  styleUrls: ['./document-library-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryFiltersComponent implements OnInit, AfterViewInit {
  private destroyRef = inject(DestroyRef);

  @Input() onFilterChange!: () => void;

  @Input() form!: FormGroupTyped<DocumentsComponentForm>;

  documentTypeOptions: Option[] = [];

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  private isResetting = false;

  constructor(public documentLibrary: DocumentLibraryService) {}

  resetAllFilters() {
    this.isResetting = true;

    Object.entries(this.form.controls).forEach(([controlName, control]) => {
      if (controlName !== 'table') {
        control.reset();
      }
    });

    this.onFilterChange();

    setTimeout(() => {
      this.isResetting = false;
    }, 500);
  }

  ngOnInit(): void {
    this.documentTypeOptions = this.documentLibrary.getDocumentOptions();

    this.form.controls.search.valueChanges
      .pipe(
        // Skip trigger filter change event if we already click on set filters
        filter((val) => !isNull(val)),
        distinctUntilChanged(isEqual),
        debounceTime(1000)
      )
      .subscribe(() => {
        this.onFilterChange();
      });
  }

  ngAfterViewInit(): void {
    this.form
      .get('sites')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.isResetting) {
          this.onFilterChange();
        }
      });
  }
}
