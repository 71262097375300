import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgIf } from '@angular/common';

@Component({
  template: `
    <div class="flex items-center justify-start text-aux-black">
      <div *ngIf="params?.data?.logo" class="w-32 mr-4">
        <img [alt]="params.data.name" [src]="params.data.logo" />
      </div>
      <div *ngIf="params?.data?.name">{{ params.data.name }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class AgIntegrationsVendorComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  refresh(): boolean {
    return false;
  }

  async agInit(params: ICellRendererParams) {
    this.params = params;
  }
}
