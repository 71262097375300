import { Component, input, model, ViewEncapsulation } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SlicePipe } from '@angular/common';

import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { Option } from '@shared/types/components.type';

@Component({
  standalone: true,
  selector: 'aux-multi-checkbox-select',
  template: `
    <ng-select
      [multiple]="true"
      [searchable]="true"
      [placeholder]="placeholder()"
      [(ngModel)]="selectedItems"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [closeOnSelect]="false"
      bindValue="value"
      groupBy="groupField"
      class="ng-select-multiple-checkbox"
    >
      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="py-[16px] mx-[16px] border-b font-normal">
          <aux-checkbox
            class=""
            [checked]="item$.selected"
            [indeterminate]="
              selectedItems().length > 0 && selectedItems().length < options().length
            "
          >
            <div class="ml-[3px]">Select All</div>
          </aux-checkbox>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <aux-checkbox class="m-[16px]" [checked]="item$.selected">
          <div class="ml-[3px]">{{ item.label }}</div>
        </aux-checkbox>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        @for (item of items | slice: 0 : 2; track $any(item).value) {
          <div class="ng-value">
            <span class="ng-value-label">{{ $any(item).label }}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
        }
        @if (items.length > 2) {
          <div class="ng-value">
            <span class="ng-value-label">{{ items.length - 2 }} more...</span>
          </div>
        }
      </ng-template>
      @for (option of options(); track option.value) {
        <ng-option [value]="option">{{ option.label }}</ng-option>
      }
    </ng-select>
  `,
  imports: [NgSelectModule, FormsModule, CheckboxComponent, SlicePipe],
  styles: [
    `
      .ng-select-multiple-checkbox {
        .ng-optgroup,
        .ng-option.ng-option-child {
          padding: 0 !important;
          color: var(--aux-black);
          line-height: 16px;
        }

        .ng-option,
        .ng-optgroup {
          &::after {
            content: none;
          }
          &.ng-option-marked {
            background-color: var(--aux-hover) !important;
          }
          &.ng-option-selected:not(.ng-option-marked) {
            background-color: #fff;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MultiCheckboxSelectComponent {
  options = input.required({
    transform: this.transformOptions,
  });

  placeholder = input<string>('');

  selectedItems = model<string[]>([]);

  transformOptions(options: Option[]): (Option & { groupField: string })[] {
    // this is a little hack to make ng-select work with groups
    // we need to add a groupField to each option
    // then add groupBy="groupField" in the ng-select template
    // this way we can have the "select all" option
    return options.map((option) => ({ ...option, groupField: 'all' }));
  }
}
