import { Injectable } from '@angular/core';
import { GqlService, VarianceType, listActivityVariancesQuery } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { map } from 'rxjs/operators';
import { mapTitleVariancesByType } from './analytic-card.const';
import { AnalyticData } from './trial-insights-finance-analytic-card.component';
import { Observable } from 'rxjs';
import { SnapshotService } from '@features/budget/services/snapshot.service';

@Injectable({
  providedIn: 'root',
})
export class TrialInsightsFinanceVariancesService {
  constructor(
    private snapshotService: SnapshotService,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  private parseAnalyticCards(variances: listActivityVariancesQuery[]): AnalyticData[] {
    return variances.map((variance) => {
      const prefix = variance.activity_type ? `_${variance.activity_type}` : '';

      const keyName =
        variance.variance_type === VarianceType.VARIANCE_FORECAST
          ? `${variance.variance_type}${prefix}`
          : variance.variance_type;

      const card = mapTitleVariancesByType.get(keyName)!;

      const difference: Pick<AnalyticData, 'differenceAmount' | 'differenceUnit'> = {};

      if (variance.variance_type === VarianceType.VARIANCE_ACTIVITY_UNITS) {
        const units = variance.variance || 0;

        difference.differenceUnit = units >= 0 ? units : `(${Math.abs(units)})`;
      } else {
        difference.differenceAmount = variance.variance || 0;
      }

      return {
        ...card,
        ...difference,
        differencePerc: variance.variance_percentage || 0,
        topTitle: variance.activity_name || '',
      };
    });
  }

  getListActivityVariances(
    vendorId: string | null,
    snapshotName: string,
    fromDate: string,
    toDate: string
  ): Observable<AnalyticData[]> {
    const snapshot = this.snapshotService.budgetVersions$
      .getValue()
      .find(({ budget_name }) => budget_name === snapshotName);

    return this.gqlService
      .listActivityVariances$({
        budget_snapshot_id: snapshot?.budget_snapshot_id || '',
        organization_id: vendorId,
        period_end: toDate,
        period_start: fromDate,
      })
      .pipe(
        map(({ data, errors }) => {
          if (data) {
            return this.parseAnalyticCards(data);
          }

          this.overlayService.error(errors);

          return [];
        })
      );
  }
}
