import { inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { combineLatest, Subject, switchMap } from 'rxjs';
import {
  ActivitySubType,
  ActivityType,
  BudgetActivityAttributes,
  BudgetData,
  BudgetExpenseData,
  BudgetType,
  CategoryType,
  ExpenseType,
  GqlService,
  listCategorySettingsQuery,
} from '@shared/services/gql.service';
import { BeInlineCategoryDropdownOption } from './be-inline-category-dropdown/be-inline-category-dropdown.model';
import {
  BeActivitiesAttributesModalParams,
  BeActivitiesAttributesModalReturnData,
  BeActivitiesAttributesModalRowData,
  BeActivityTypes,
} from './be-activities-attributes-modal/be-activities-attributes-modal.model';
import { BeActivitiesAttributesModalComponent } from './be-activities-attributes-modal/be-activities-attributes-modal.component';
import { OverlayService } from '@shared/services/overlay.service';
import { OrganizationQuery } from '@models/organization/organization.query';

// eslint-disable-next-line local-rules/fsd-folder
import {
  getAttributeColumns,
  getEncodedAttributeName,
} from '@features/budget-attributes/services/budget-attributes.service';
import { ArrElement, Maybe } from '@shared/utils/utils';
import { GridApi, IRowNode } from '@ag-grid-community/core';

export const injectOpenActivitiesModal = (
  gqlService = inject(GqlService),
  overlayService = inject(OverlayService),
  organizationQuery = inject(OrganizationQuery)
) => {
  const vendor_id = signal('');
  const vendor = toSignal(
    toObservable(vendor_id).pipe(
      filter(Boolean),
      switchMap((id) => organizationQuery.selectEntity(id))
    )
  );
  const change_order_id = signal<string | undefined>(undefined);

  const categoriesRefresh$ = new Subject();
  const categoriesLoading = signal(false);
  const categories = toSignal(
    combineLatest([
      toObservable(vendor_id).pipe(filter(Boolean)),
      toObservable(change_order_id),
      categoriesRefresh$.pipe(startWith(null)),
    ]).pipe(
      debounceTime(100),
      switchMap(([vendor_id, change_order_id]) => {
        categoriesLoading.set(true);

        return gqlService
          .listCategorySettings$({
            vendor_id,
            budget_type: change_order_id
              ? BudgetType.BUDGET_CHANGE_ORDER
              : BudgetType.BUDGET_PRIMARY,
            change_order_id,
          })
          .pipe(
            map(mapCategories),
            tap(() => {
              categoriesLoading.set(false);
            })
          );
      })
    ),
    { initialValue: [] }
  );

  const mapSelectedRowsToModalData = (
    gridAPI: GridApi,
    rowOverrideFn?: (node: IRowNode) => Partial<BeActivitiesAttributesModalRowData>
  ) => {
    const categoriesArr = categories();
    return gridAPI.getSelectedNodes().map((node) => {
      const x = node.data;
      let cat: Maybe<ArrElement<typeof categoriesArr>>;
      const root_id = categoriesArr?.[0].id;
      if (x.category_id && x.category_id !== root_id) {
        cat = categoriesArr?.filter((c) => c.id === x.category_id)[0];
      }

      if (!cat) {
        cat = categoriesArr?.find(
          (c) =>
            c.name === (x.group4 || x.group3 || x.group2 || x.group1 || x.group0 || x.cost_category)
        );
      }

      if (x.cost_category === 'Investigator') {
        cat = categoriesArr?.find(
          (c) =>
            c.name === x.activity_name_label &&
            (
              [
                ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS,
                ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES,
                ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES,
              ] as BeActivityTypes[]
            ).includes(c.type)
        );
      }

      const isPercentage = cat?.type === ActivityType.ACTIVITY_DISCOUNT && x.contract_unit_cost > 0;
      return <BeActivitiesAttributesModalRowData>{
        activity_name: x.activity_name_label,
        display_label: x.display_label,
        uom: isPercentage ? 'Percentage' : x.uom,
        unit_num: x.unit_num || 0,
        unit_cost: x.contract_unit_cost || 0,
        category: cat?.id || x.category_id,
        id: x.activity_id,
        isGenerated: false,
        activity_type: cat?.type || '',
        changed: false,
        currencyName: x.contract_unit_cost_currency,
        attributes: ((x.attributes || []) as BudgetActivityAttributes[]).reduce(
          (acc, val) => {
            if (val.attribute_name) {
              acc[getEncodedAttributeName(val.attribute_name)] = val.attribute_value || '';
            }
            return acc;
          },
          {} as Record<string, string>
        ),
        deleted: false,
        deletable: (x.expenses as BudgetExpenseData[])
          .filter((c) => c.expense_type === ExpenseType.EXPENSE_WP)
          .every((z) => z.amount === 0),
        isPercentage,
        ...(rowOverrideFn?.(node) || {}),
      };
    });
  };

  const openModal = ({
    rows,
    budgetData,
    editMode = true,
    isActivityTabVisible = true,
    showNotesSection = true,
    uomHide = false,
    vendorId,
  }: {
    rows: BeActivitiesAttributesModalRowData[];
    budgetData: BudgetData[];
    uomHide?: boolean;
    editMode?: boolean;
    isActivityTabVisible?: boolean;
    showNotesSection?: boolean;
    vendorId?: string;
  }) => {
    const v =
      vendor() ||
      organizationQuery.allVendors()?.find((organization) => organization.id === vendorId);

    if (!v) {
      return;
    }

    const categoriesArr = categories();

    if (!categoriesArr?.length) {
      getDefaultCategories('0', false).forEach((c) => {
        categoriesArr?.push(c);
      });
    }

    const usedCategories = {
      [ActivityType.ACTIVITY_DISCOUNT]: false,
      [ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS]: false,
      [ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES]: false,
      [ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES]: false,
    } as Record<BeActivityTypes, boolean>;

    budgetData?.forEach((x) => {
      if (x.cost_category === 'Investigator') {
        Object.keys(ActivitySubType).forEach((c) => {
          if (x.activity_sub_type === c) {
            usedCategories[c] = true;
          }
        });
      } else if (x.cost_category === 'Discount') {
        usedCategories[ActivityType.ACTIVITY_DISCOUNT] = true;
      }
    });

    const attributes = getAttributeColumns({
      attributes: budgetData.map((z) =>
        (z.attributes || [])
          .filter((z) => z.attribute_name)
          .map((z) => ({ ...z, attribute_value: z.attribute_value || '' }))
      ),
    }) as {
      attribute_name: string;
      attribute_value: string;
    }[];

    return overlayService.openPopup<
      BeActivitiesAttributesModalParams,
      BeActivitiesAttributesModalReturnData,
      BeActivitiesAttributesModalComponent
    >({
      content: BeActivitiesAttributesModalComponent,
      settings: {
        header: (instance) =>
          instance?.isActivitiesTabVisible ? 'Manage Activities' : 'Edit Attributes',
        primaryButton: {
          label: (instance) =>
            instance?.isActivitiesTabVisible ? 'Edit Attributes' : 'Save & Exit',
          tooltip: (instance) =>
            (instance?.isActivitiesTabVisible
              ? (instance?.activitiesTabComponent?.editButtonDisabled() &&
                  instance?.activitiesTabComponent?.editButtonTooltip) ||
                ''
              : instance?.attributesTabComponent?.saveBtnTooltip()) || '',
          disabled: (instance) =>
            instance?.loading() || instance?.isActivitiesTabVisible
              ? !!instance?.activitiesTabComponent?.editButtonDisabled()
              : !!instance?.attributesTabComponent?.isSaveBtnDisabled(),
          action: (instance) =>
            instance?.isActivitiesTabVisible
              ? instance?.activitiesTabComponent?.goToAttributesTab()
              : instance?.attributesTabComponent?.onSave(),
          loading: (instance) => !!instance?.loading(),
        },
        secondaryButton: {
          label: (instance) =>
            instance?.isActivitiesTabVisible ? 'Save & Exit' : 'Edit Activities',
          tooltip: (instance) =>
            (instance?.isActivitiesTabVisible
              ? instance?.activitiesTabComponent?.saveBtnTooltipAct()
              : (instance?.attributesTabComponent?.doesHeaderHasError() &&
                  instance?.attributesTabComponent?.headerErrorTooltip) ||
                '') || '',
          disabled: (instance) =>
            instance?.loading() || instance?.isActivitiesTabVisible
              ? !!instance?.activitiesTabComponent?.isSaveButtonDisabledAct()
              : !!instance?.attributesTabComponent?.doesHeaderHasError(),
          action: (instance) =>
            instance?.isActivitiesTabVisible
              ? instance?.activitiesTabComponent?.onSaveActivities()
              : instance?.attributesTabComponent?.goToActivitiesTab(),
          loading: (instance) => !!instance?.loading(),
        },
        tertiaryButton: {},
      },
      data: {
        isActivityTabVisible,
        rows,
        showNotesSection,
        uomHide,
        vendor: {
          label: v.name || '',
          id: v.id,
        },
        usedCategories,
        currencyName: v.currency || '',
        categories: categoriesArr || [],
        containerClass: ['sm:!pb-4', 'sm:!px-4'],
        editMode,
        attributes,
      },
    });
  };

  return {
    openModal,
    categories,
    categoriesLoading,
    vendor_id,
    change_order_id,
    mapSelectedRowsToModalData,
    refreshCategories: () => categoriesRefresh$.next(null),
  };
};

const mapCategories = ({ data }: GraphqlResponse<listCategorySettingsQuery>) => {
  if (!data) {
    return [];
  }

  const categories: BeInlineCategoryDropdownOption[] = [];
  const rootID = data.id;

  type A = {
    id: string;
    name: string;
    category_type: CategoryType;
    categories?: A[];
    path?: string;
    fullPath?: string;
  };

  const addToCategoriesRecursive = (sc: A, type: BeActivityTypes, indent: number, path: string) => {
    const result = categoryToActivityType(sc.category_type);

    if (result === type) {
      const categoryPath = sc.id === rootID ? '' : path || '';
      const categoryFullPath = categoryPath + '>' + sc.name;
      categories.push({
        id: sc.id,
        name: sc.name,
        type: result,
        indent,
        disabled: false,
        path: categoryPath,
        fullPath: categoryFullPath,
        categoryType: sc.category_type,
      });
      if (sc.categories?.length) {
        sc.categories.forEach((ssc) => {
          addToCategoriesRecursive(ssc, type, indent + 1, categoryFullPath);
        });
      }
    }
  };
  getDefaultCategories(rootID, false).forEach((c) => {
    categories.push(c);
    if (!c.type.startsWith('ACTIVITY_INVESTIGATOR_')) {
      data.categories.forEach((sc) => {
        addToCategoriesRecursive(sc, c.type, 2, '');
      });
    }
  });
  return categories;
};

const categoryToActivityType = (c: CategoryType) => {
  switch (c) {
    case CategoryType.CATEGORY_DISCOUNT:
      return ActivityType.ACTIVITY_DISCOUNT;
    case CategoryType.CATEGORY_INVESTIGATOR:
      return ActivityType.ACTIVITY_INVESTIGATOR;
    case CategoryType.CATEGORY_PASSTHROUGH:
      return ActivityType.ACTIVITY_PASSTHROUGH;
    case CategoryType.CATEGORY_ROOT:
    case CategoryType.CATEGORY_SERVICE:
    case CategoryType.CATEGORY_UNDETERMINED:
      return ActivityType.ACTIVITY_SERVICE;
  }
};

const getDefaultCategories = (rootID: string, disabled: boolean) => {
  return [
    {
      id: rootID,
      name: 'Services',
      type: ActivityType.ACTIVITY_SERVICE,
      indent: 1,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_SERVICE,
    },
    {
      id: rootID,
      name: 'Discount',
      type: ActivityType.ACTIVITY_DISCOUNT,
      indent: 1,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_DISCOUNT,
    },
    {
      id: rootID,
      name: 'Pass-through',
      type: ActivityType.ACTIVITY_PASSTHROUGH,
      indent: 1,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_PASSTHROUGH,
    },
    {
      id: rootID,
      name: 'Investigator',
      type: ActivityType.ACTIVITY_INVESTIGATOR,
      indent: 1,
      disabled: true,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_INVESTIGATOR,
    },
    {
      id: rootID,
      name: 'Patient Visits',
      type: ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS,
      indent: 2,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_INVESTIGATOR,
    },
    {
      id: rootID,
      name: 'Patient Invoiceables',
      type: ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES,
      indent: 2,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_INVESTIGATOR,
    },
    {
      id: rootID,
      name: 'Site Invoiceables',
      type: ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES,
      indent: 2,
      disabled,
      path: '',
      fullPath: '',
      categoryType: CategoryType.CATEGORY_INVESTIGATOR,
    },
  ];
};
