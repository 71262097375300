import {
  Component,
  ChangeDetectionStrategy,
  signal,
  viewChild,
  model,
  inject,
} from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { firstValueFrom } from 'rxjs';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { ChangeOrderStatus, EntityType, EventType, GqlService } from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { OverlayService } from '@shared/services/overlay.service';
import { ApiService } from '@shared/services/api.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { FileViewerComponent } from '@features/file-viewer/file-viewer.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgClass } from '@angular/common';

export const injectChangeOrderBudgetUploadModal = (overlayService = inject(OverlayService)) => {
  return {
    open: (data: ChangeOrderBudgetUploadModalParams) => {
      return overlayService.openPopup<
        ChangeOrderBudgetUploadModalResponse,
        ChangeOrderBudgetUploadModalParams,
        ChangeOrderBudgetUploadModal
      >({
        data,
        content: ChangeOrderBudgetUploadModal,
        settings: {
          header: 'Upload Change Order Budget',
          primaryButton: {
            label: 'Upload Change Order',
            action: (instance) => instance?.onUpload(),
          },
        },
      });
    },
  };
};

export type ChangeOrderBudgetUploadModalParams = {
  change_order_id: string;
  change_order_status: ChangeOrderStatus;
  organization_id: string;
  onSuccess: (processEventId: string) => void;
};

export type ChangeOrderBudgetUploadModalResponse = unknown;

@Component({
  template: `
    <div class="mb-4">
      This will replace any existing Change Order Budget Updates made in this Change Order.
    </div>
    <div>
      <aux-file-manager
        #manager
        class="h-48"
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [showSuccessOnUpload]="true"
        [accept]="'.csv'"
      />
      <div class="max-h-60 overflow-auto" [ngClass]="{ 'mt-4': manager.fileQuery.count() > 0 }">
        <aux-file-viewer
          [fileManager]="manager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        />
      </div>
    </div>

    @if (errorMessage()) {
      <div class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
        {{ errorMessage() }}
      </div>
    }

    <div class="mt-4">
      <aux-checkbox
        class="text-sm font-medium"
        [id]="'bypass-validation'"
        [(ngModel)]="bypassValidation"
      >
        Bypass blank Activity ID check
      </aux-checkbox>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FileManagerComponent,
    FileViewerComponent,
    CheckboxComponent,
    FormsModule,
    ButtonComponent,
    NgClass,
  ],
})
export class ChangeOrderBudgetUploadModal {
  ref = inject(
    CustomOverlayRef<ChangeOrderBudgetUploadModalResponse, ChangeOrderBudgetUploadModalParams>
  );
  mainQuery = inject(MainQuery);
  overlayService = inject(OverlayService);
  apiService = inject(ApiService);
  gqlService = inject(GqlService);

  fileManager = viewChild.required(FileManagerComponent);

  errorMessage = signal('');
  loading = signal(false);

  bypassValidation = model(false);

  pathFn: () => string = () => '';

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  onUpload = async () => {
    this.errorMessage.set('');
    const fileManager = this.fileManager();

    const { organization_id, change_order_id, change_order_status } = this.ref.data;

    if (fileManager && !this.loading()) {
      const files = fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
        return;
      }

      if (files.length > 1) {
        this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
        return;
      }

      const match = files[0].bucket_key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.errorMessage.set('File type must be a .csv!');
        return;
      }

      this.loading.set(true);

      const file = files[0];
      const bucket_key = `${this.getFilePath(organization_id, change_order_id)}${file.bucket_key}`;

      fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key,
      });

      const fileSuccess = await fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors, data } = await firstValueFrom(
          this.gqlService.validateEventFile$({
            type: EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: organization_id,
            bucket_key: `public/${bucket_key}`,
            payload: JSON.stringify({
              change_order_id: change_order_id,
              change_order_status: change_order_status,
              skip_activityno_check: this.bypassValidation(),
            }),
          })
        );
        if (success && data?.id) {
          this.overlayService.success(`Budget is processing. Please wait...`);
          this.ref.data?.onSuccess(data.id);
        } else {
          this.apiService.removeFile(bucket_key);
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading.set(false);
  };
}
