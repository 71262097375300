import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { FormControl, Validators } from '@angular/forms';
import { FileMetadata } from '@shared/services/api.service';
import { Note, User, DocumentType, EntityType } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { File } from '@shared/components/file-manager/state/file.model';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { MainQuery } from '@shared/store/main/main.query';
import { OverlayService } from '@shared/services/overlay.service';

export type AdjustmentModalResponseType = { note: string; documents: number } | undefined;

export type AdjustmentModalDataType = {
  notes: Note[];
  users: Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>;
  vendor_id: string;
  entity_ids: string[];
  current_month: string;
};

@Component({
  selector: 'aux-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdjustmentModalComponent implements OnInit {
  @ViewChild('fileManager') fileManager?: FileManagerComponent;

  metadata: FileMetadata = { documentType: DocumentType.DOCUMENT_ACCRUAL_SUMMARY };

  uploadedFiles$ = of<File[]>([]);

  textarea = new FormControl('', Validators.required);

  notes: (Note & {
    username: string;
  })[] = [];

  constructor(
    public ref: CustomOverlayRef<AdjustmentModalResponseType, AdjustmentModalDataType>,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {
    this.notes = (this.ref.data?.notes || []).map((note) => {
      const user = this.ref.data?.users.get(note.created_by);
      return {
        ...note,
        message: Utils.unscrubUserInput(note.message),
        username: `${user?.given_name} ${user?.family_name}`,
      };
    });
  }

  ngOnInit(): void {
    Promise.resolve().then(() => {
      if (this.fileManager) {
        this.uploadedFiles$ = this.fileManager.fileQuery.selectAll();
      }
    });
  }

  pathFn = () => {
    const trialId = this.mainQuery.getValue().trialKey;

    return () => `trials/${trialId}/month-close-manual-adjustment/${this.ref.data?.current_month}/`;
  };

  onRemoveFile(file: File) {
    const success = this.fileManager?.removeFile(file);

    if (success) {
      this.overlayService.success(`${file.fileName} removed!`);
    }
  }

  close(bool: boolean) {
    const note = this.textarea.value;
    if (bool && !note) {
      this.textarea.markAsTouched();
      this.textarea.markAsDirty();
      this.textarea.updateValueAndValidity();
      return;
    }

    this.uploadFiles().then((uploadedDocuments) => {
      this.ref.close({
        note: note as string,
        documents: uploadedDocuments,
      });
    });
  }

  async uploadFiles(): Promise<number> {
    const trialId = this.mainQuery.getValue().trialKey;

    if (this.fileManager) {
      const entity_ids = this.ref.data?.entity_ids || [];

      const metadata = entity_ids.map((entity_id) => {
        return {
          vendor: this.ref.data?.vendor_id || '',
          documentType: DocumentType.DOCUMENT_MANUAL_ADJUSTMENT,
          entity_type_id: EntityType.ACTIVITY,
          target_date: this.ref.data?.current_month || '',
          entity_id: entity_id,
        };
      });

      await this.fileManager?.fileService.batchUploadFiles(
        metadata,
        false,
        `trials/${trialId}/month-close-manual-adjustment/${this.ref.data?.current_month}/${this.ref.data?.vendor_id}/`,
        false,
        false,
        true
      );

      const uploadedFiles = this.fileManager.fileQuery.getAll({
        filterBy: (entity) => entity.uploaded,
      });

      return uploadedFiles.length;
    }

    return 0;
  }
}
