import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { groupBy, isEqual } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroupDirective,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CurveType,
  ForecastMethodType,
  listDriverCustomGroupsWithDataQuery,
  listDriverSiteGroupsQuery,
} from '@shared/services/gql.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { FormControlConstants } from '@shared/constants/form-control.constants';
import { Option } from '@shared/types/components.type';
import { PatientCurvesDropdownComponent } from '@features/patient-curves-dropdown/patient-curves-dropdown.components';
import { FormErrorDirective } from '@shared/directives/form-error.directive';
import { RadioButtonGroupComponent } from '@shared/components/radio-button/radio-button-group.component';
import { RadioButtonComponent } from '@shared/components/radio-button/radio-button.component';
import { InputComponent } from '@shared/components/input/input.component';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { OverlayService } from '@shared/services/overlay.service';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { PatientCurveQuery } from '../../state/patient-curve/patient-curve.query';
import { TimelineQuery } from '@pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { QuickForecastValidators } from './services/quick-forecast.validators';
import { ForecastSettings, QuickForecastService } from './services/quick-forecast.service';
import { SiteCurveService } from '../../drivers/forecast-sites/site-curve/site-curve.service';
import { CheckboxControlComponent } from '@shared/components/checkbox/checkbox-control.component';
import { DatePickerComponent } from '@shared/components/input/date-picker/date-picker.component';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

interface MilestoneItem {
  id: string;
  milestoneName: string;
  contractStartDate: string;
  contractEndDate: string;
  name: string;
  categoryId: string;
  categoryName: string;
}

@Component({
  templateUrl: './quick-forecast-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styleUrl: './quick-forecast-modal.component.scss',
  imports: [
    ReactiveFormsModule,
    CheckboxControlComponent,
    RadioButtonGroupComponent,
    RadioButtonComponent,
    NgSelectModule,
    AsyncPipe,
    NgTemplateOutlet,
    PatientCurvesDropdownComponent,
    FormErrorDirective,
    InputComponent,
    DatePipe,
    IconComponent,
    DatePickerComponent,
    ModalComponent,
    TooltipDirective,
  ],
  providers: [QuickForecastService],
})
export class QuickForecastModalComponent {
  readonly patientCurveQuery = inject(PatientCurveQuery);

  private readonly overlayService = inject(OverlayService);

  private readonly ref = inject<
    CustomOverlayRef<
      unknown,
      {
        vendorId: string;
        categoryTypes: string[];
        customCurves: listDriverCustomGroupsWithDataQuery[];
      }
    >
  >(CustomOverlayRef);

  private readonly fb = inject(FormBuilder);

  private readonly quickForecastService = inject(QuickForecastService);

  private readonly siteCurveService = inject(SiteCurveService);

  private readonly timelineQuery = inject(TimelineQuery);

  readonly formGroup = viewChild<FormGroupDirective>('ngForm');

  readonly milestonePhases = computed(() => {
    if (!this.timelineQuery.timeline().length) {
      return [];
    }
    const phases = groupBy(this.timelineQuery.timeline(), 'milestone.milestone_category.name');

    return Object.keys(phases).map((key) => {
      const firstPhase = phases[key][0];
      const lastPhase = phases[key][phases[key].length - 1];

      return {
        id: firstPhase.milestone.milestone_category.id,
        categoryName: key,
        contractStartDate: firstPhase.contract_start_date,
        contractEndDate: lastPhase.contract_end_date,
        contractStartDateId: firstPhase.milestone.id,
        contractEndDateId: lastPhase.milestone.id,
      };
    });
  });

  readonly milestones = computed(() => {
    return this.timelineQuery.timeline().reduce<MilestoneItem[]>((accum, item) => {
      accum.push({
        contractStartDate: item.contract_start_date,
        contractEndDate: item.contract_end_date,
        name: item.milestone.name,
        categoryId: item.milestone.milestone_category.id,
        categoryName: item.milestone.milestone_category.name,
        milestoneName: item.milestone.name,
        id: item.milestone.id,
      });

      return accum;
    }, []);
  });

  readonly trialTimelinePeriod = computed(() => {
    const milestones = this.milestones();

    return {
      startDate: milestones[0].contractStartDate,
      endDate: milestones[milestones.length - 1].contractEndDate,
    };
  });

  readonly allSiteCurves = signal<listDriverSiteGroupsQuery[]>([]);

  readonly siteCurves = signal<listDriverSiteGroupsQuery[]>([]);

  readonly customCurves = (this.ref.data?.customCurves || []).map((curve) => ({
    name: curve.name,
    value: curve.driver_setting_id,
  }));

  readonly submitting = signal(false);

  readonly form = this.fb.group({
    applyServices: false,
    applyPassThrough: false,
    applyInvestigator: false,
    driver: ['', Validators.required],
    // if patient driver selected
    patientCurve: [null, QuickForecastValidators.required],
    // if site driver selected
    siteDriverPeriod: [null, QuickForecastValidators.required],
    siteCurve: [null, QuickForecastValidators.required],
    // if time driver selected
    milestone: null as MilestoneItem | null,
    timeMethod: [null as ForecastMethodType | null],
    startMilestone: null,
    endMilestone: null,
    customStartMilestone: new FormControl(null, {
      validators: [
        QuickForecastValidators.isDateInRange(
          this.trialTimelinePeriod().startDate,
          this.trialTimelinePeriod().endDate
        ),
      ],
      updateOn: 'blur',
    }),
    customEndMilestone: new FormControl(null, {
      validators: [
        QuickForecastValidators.isDateInRange(
          this.trialTimelinePeriod().startDate,
          this.trialTimelinePeriod().endDate
        ),
      ],
      updateOn: 'blur',
    }),
    // if custom driver selected
    customCurve: [null, QuickForecastValidators.required],
    // general time validation to render error section
    requiredTimeValidation: null,
  });

  readonly oneOfValidationMessage = FormControlConstants.VALIDATION_MESSAGE.ONE_OF;

  readonly periodSiteOptions: Option<CurveType>[] = [
    {
      value: CurveType.NET,
      label: 'Net Sites',
    },
    {
      value: CurveType.ACTIVATION,
      label: 'Sites Activated',
    },
    {
      value: CurveType.CLOSE_OUT,
      label: 'Sites Closed',
    },
  ];

  readonly methodTimeOptions: Option<ForecastMethodType>[] = [
    {
      value: ForecastMethodType.FORECAST_STRAIGHTLINE,
      label: 'Straight Line',
    },
    {
      value: ForecastMethodType.FORECAST_FRONTLOADED,
      label: 'Frontloaded',
    },
    {
      value: ForecastMethodType.FORECAST_BACKLOADED,
      label: 'Backloaded',
    },
  ];

  private readonly mapValidationForBeforeSubmit = new Map<string, () => boolean>([
    ['patient', () => this.isPatientDriverFormValid()],
    ['site', () => this.isSiteDriverFormValid()],
    ['time', () => this.isTimeDriverFormValid()],
    ['custom', () => this.isCustomDriverFormValid()],
  ]);

  private readonly defaultValues = this.form.getRawValue();

  constructor() {
    this.setValidators();

    this.siteCurveService
      .get()
      .pipe(takeUntilDestroyed())
      .subscribe(({ data }) => {
        if (data) {
          this.allSiteCurves.set(data);
        }
      });

    this.form
      .get('siteDriverPeriod')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe((period) => {
        const siteCurves = this.allSiteCurves().filter(
          ({ curve_type }) => curve_type === (period || CurveType.NET)
        );

        this.siteCurves.set(siteCurves);
      });

    this.form
      .get('driver')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe((driver) => {
        // trigger validation after driver change for date picker in case if it was invalid
        // to keep errors persistent
        if (driver === 'time') {
          setTimeout(() => {
            if (this.isAnyTimelineMilestoneTouched) {
              [
                'startMilestone',
                'endMilestone',
                'customStartMilestone',
                'customEndMilestone',
              ].forEach((field) => this.revalidateMilestoneField(field));
            }
          }, 0);
        }

        this.form.get('requiredTimeValidation')?.setErrors(null);
      });
  }

  isCategoryExists(category: string) {
    return this.ref?.data?.categoryTypes.includes(category);
  }

  revalidateMilestoneField(field: string) {
    this.form.get(field)?.updateValueAndValidity();
    this.form.get('timeMethod')?.updateValueAndValidity();
  }

  revalidateSiteCurveField() {
    this.form.get('siteCurve')?.updateValueAndValidity();
  }

  revalidateCustomMilestoneField(datePicker?: InputComponent) {
    if (datePicker) {
      datePicker.revalidateDatepicker();
    }
  }

  disableMilestonesByCondition(
    value: string | null,
    controlName: string,
    dependingControlName?: string
  ) {
    let shouldDisable = !!value;

    const controlsToDisable = [
      'milestone',
      'startMilestone',
      'endMilestone',
      'customStartMilestone',
      'customEndMilestone',
    ].filter((control) => control !== controlName && control !== dependingControlName);

    if (dependingControlName) {
      const dependingControl = this.form.get(dependingControlName);
      const control = this.form.get(controlName);

      // Check if either the depending control or the current control is touched and has a value
      shouldDisable =
        dependingControl?.touched || control?.touched
          ? shouldDisable || !!dependingControl?.value
          : shouldDisable;
    }

    controlsToDisable.forEach((control) => {
      if (shouldDisable) {
        this.form.get(control)?.disable();
      } else {
        this.form.get(control)?.enable();
      }
    });
  }

  isCategoryIncorrect(): boolean {
    const { applyServices, applyPassThrough, applyInvestigator } = this.form.controls;

    const isAnyActivityTouched =
      applyServices.touched || applyPassThrough.touched || applyInvestigator.touched;

    return (
      (isAnyActivityTouched || !!this.formGroup()?.submitted) &&
      !(applyServices.value || applyPassThrough.value || applyInvestigator.value)
    );
  }

  get isDriverIncorrect(): boolean {
    const driver = this.form.get('driver');

    return !!driver?.touched && !driver?.value;
  }

  get isAnyTimelineMilestoneTouched() {
    const { customStartMilestone, customEndMilestone, milestone, endMilestone, startMilestone } =
      this.form.controls;

    return (
      milestone.touched ||
      startMilestone.touched ||
      endMilestone.touched ||
      customStartMilestone.touched ||
      customEndMilestone.touched
    );
  }

  get isAnyTimelineMilestoneHasValue() {
    const { customStartMilestone, customEndMilestone, milestone, endMilestone, startMilestone } =
      this.form.getRawValue();

    return !!(
      milestone ||
      startMilestone ||
      endMilestone ||
      customStartMilestone ||
      customEndMilestone
    );
  }
  get isAnyActivitySelected() {
    const { applyServices, applyPassThrough, applyInvestigator } = this.form.getRawValue();
    return applyServices || applyPassThrough || applyInvestigator;
  }

  get showTimeFormEmptyWarning() {
    const { driver, requiredTimeValidation } = this.form.controls;

    if (this.isDriverIncorrect || driver.value !== 'time') {
      return false;
    }

    const hasError = requiredTimeValidation?.errors !== null;

    if (
      !this.isAnyTimelineMilestoneHasValue &&
      this.isAnyTimelineMilestoneTouched &&
      this.formGroup()?.submitted &&
      hasError
    ) {
      return true;
    }

    return !this.isAnyTimelineMilestoneTouched && this.formGroup()?.submitted && hasError;
  }

  getTimelinePhaseTooltip(item: MilestoneItem) {
    const datePipe = new DatePipe('en-US');

    const contractStartDate = datePipe.transform(item.contractStartDate, 'longDate');
    const contractEndDate = datePipe.transform(item.contractEndDate, 'longDate');

    return `${item.categoryName} ${contractStartDate} - ${contractEndDate}`;
  }

  getTimelineStartEndMilestoneTooltip(item: MilestoneItem, date: string) {
    const datePipe = new DatePipe('en-US');

    const formattedDate = datePipe.transform(date, 'longDate');

    return `${item.milestoneName} ${formattedDate}`;
  }

  closeModal = async () => {
    if (this.submitting()) {
      return;
    }

    const showUnsavedModal = !isEqual(this.defaultValues, this.form.getRawValue());

    if (showUnsavedModal) {
      const resp = await this.showUnsavedChanges();

      if (resp) {
        this.ref.close();
      }

      return;
    }

    this.ref.close();
  };

  async saveForecastSettings() {
    this.markAsTouchedDriverFieldBeforeSubmit();

    const formValues = this.form.getRawValue();
    const { driver } = formValues;

    if (!driver) {
      return;
    }

    const validate = this.mapValidationForBeforeSubmit.get(driver);

    if (!validate) {
      console.error(`No validation function found for driver: ${driver}`);
      return;
    }

    if (validate()) {
      try {
        this.submitting.set(true);

        const milestoneCategory = driver === 'time' ? formValues.milestone?.id ?? null : null;

        const isSuccess = await this.quickForecastService.saveUpdateBudgetForecastSettings(
          {
            ...formValues,
            siteCurve: this.allSiteCurves().find(
              ({ curve_type, name }) =>
                name === formValues.siteCurve && curve_type === formValues.siteDriverPeriod
            )?.driver_setting_id,
            patientCurve: this.patientCurveQuery
              .patientCurves()
              .find(({ name }) => name === formValues.patientCurve)?.driver_setting_id,
            milestoneCategory,
          } as ForecastSettings,
          this.ref?.data?.vendorId ?? ''
        );

        if (isSuccess) {
          this.overlayService.success('Successfully saved!');
          this.ref.close();
        } else {
          this.overlayService.error('Failed to save settings');
        }
      } catch (error) {
        console.error('Error saving forecast settings:', error);
        this.overlayService.error('An error occurred while saving settings');
      } finally {
        this.submitting.set(false);
      }
    }
  }

  private markAsTouchedDriverFieldBeforeSubmit() {
    if (this.isAnyActivitySelected) {
      this.form.get('driver')?.markAsTouched();
    }
  }

  private isPatientDriverFormValid() {
    return !!this.form.get('patientCurve')?.valid;
  }

  private isSiteDriverFormValid() {
    const { siteDriverPeriod, siteCurve } = this.form.controls;

    siteCurve.updateValueAndValidity();

    return siteDriverPeriod.valid && siteCurve.valid;
  }

  private isTimeDriverFormValid() {
    const {
      startMilestone,
      endMilestone,
      customStartMilestone,
      customEndMilestone,
      timeMethod,
      requiredTimeValidation,
    } = this.form.controls;

    // skip validation if warning is shown
    if (!this.isAnyTimelineMilestoneHasValue) {
      requiredTimeValidation.setErrors({ requiredQuickForecast: true });
      return false;
    }

    let isFormValid = true;

    if (!timeMethod.value) {
      timeMethod.setErrors({ requiredQuickForecast: true });
      isFormValid = false;
    }

    if (customStartMilestone.value && !customEndMilestone.value) {
      customEndMilestone.setErrors({ requiredQuickForecast: true });
      isFormValid = false;
    }

    if (customEndMilestone.value && !customStartMilestone.value) {
      customStartMilestone.setErrors({ requiredQuickForecast: true });
      isFormValid = false;
    }

    if (endMilestone.value && !startMilestone.value) {
      startMilestone.setErrors({ requiredQuickForecast: true });
      isFormValid = false;
    }

    if (startMilestone.value && !endMilestone.value) {
      endMilestone.setErrors({ requiredQuickForecast: true });
      isFormValid = false;
    }

    // Only check for invalid controls to avoid considering disabled controls without errors as invalid
    return (
      isFormValid &&
      !customStartMilestone.invalid &&
      !customEndMilestone.invalid &&
      !startMilestone.invalid &&
      !endMilestone.invalid
    );
  }

  private isCustomDriverFormValid() {
    return !!this.form.get('customCurve')?.valid;
  }

  private setValidators() {
    const {
      startMilestone,
      applyServices,
      applyPassThrough,
      applyInvestigator,
      siteCurve,
      siteDriverPeriod,
      customStartMilestone,
    } = this.form.controls;

    applyServices.addValidators(CustomValidators.oneOf(applyPassThrough, applyInvestigator));

    applyPassThrough.addValidators(CustomValidators.oneOf(applyServices, applyInvestigator));

    applyInvestigator.addValidators(CustomValidators.oneOf(applyServices, applyPassThrough));

    startMilestone.setValidators(QuickForecastValidators.startMilestone(this.form));

    siteCurve.addValidators(
      QuickForecastValidators.siteCurveBeforePeriod(siteDriverPeriod, this.formGroup)
    );

    customStartMilestone.addValidators(QuickForecastValidators.customStartMilestone(this.form));
  }

  private async showUnsavedChanges() {
    const result = this.overlayService.openUnsavedChangesConfirmation();
    const event = await firstValueFrom(result.afterClosed$);
    return !!event.data;
  }
}
