<div>
  <!-- Button Group -->
  <div *ngIf="options.buttons.display" class="trial-insights-table-button-container">
    <button
      *ngFor="let button of options.buttons.data"
      class="trial-insights-table-button"
      [ngClass]="button.key === selectedKey ? 'btn--primary' : 'btn--secondary'"
      (click)="select(button.key)"
    >
      {{ button.value }}
    </button>
  </div>

  <!-- Table Header -->
  <div *ngIf="options.header.display" class="trial-insights-table-header-container">
    <span class="trial-insights-table-header flex-grow">{{ headers[0] }}</span>
    <div class="sort" (click)="sort()">
      <span class="trial-insights-table-header select-none">{{ headers[1] }}</span>

      <!-- Toggle sort direction -->
      <div *ngIf="!isLoadingRemaining" class="flex ml-1">
        <span *ngIf="sortOrder === 'DESC'" class="trial-insights-table-header sorting-icon"
          >&darr;</span
        >
        <span *ngIf="sortOrder === 'ASC'" class="trial-insights-table-header sorting-icon"
          >&uarr;</span
        >
      </div>

      <!-- Toggle spinner -->
      <div *ngIf="isLoadingRemaining" class="trial-insights-table-spinner-container">
        <div class="dot-flashing"></div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="trial-insights-table-row-container">
    <!-- Table Row -->
    <div
      *ngFor="let row of rowData"
      class="trial-insights-table-row"
      [class.compact]="options.rowData.compact"
    >
      <!-- Color Icon -->
      <div
        *ngIf="row.color"
        class="trial-insights-table-row-icon"
        [style.background-color]="row.color ? row.color : ''"
      ></div>

      <!-- Left side -->
      <div class="trial-insights-table-row-text left">
        <span class="header">{{ row.leftHeader }}</span>
        <span class="subheader">{{ row.leftSubheader }}</span>
      </div>

      <!-- Right Side -->
      <div class="trial-insights-table-row-text right">
        <span class="header">{{ row.rightHeader }}</span>
        <span class="subheader">{{ row.rightSubheader }}</span>
      </div>
    </div>
  </div>

  <!-- Link -->
  <div *ngIf="options.link.display" class="trial-insights-table-link-container">
    <a [routerLink]="options.link.url" class="btn--hyperlink">{{ options.link.value }}</a>
  </div>
</div>
