import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { EventService } from '@models/event/event.service';
import { EntityType, EventType } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';

@Component({
  selector: 'aux-qa-testing-email',
  templateUrl: './qa-testing-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaTestingEmailComponent {
  private overlayService = inject(OverlayService);

  constructor(private eventService: EventService) {}

  async onSendingMonthCloseChecklist() {
    const trackerId = await this.eventService.triggerEvent({
      type: EventType.COMPLETE_CHECKLIST_REMINDER,
      entity_type: EntityType.TRIAL,
      entity_id: '',
    });
    if (trackerId) {
      this.overlayService.success(`Sending month close checklist email`);
    }
  }
}
