<div style="max-height: 80vh" class="max-w-screen-lg">
  <div class="w-screen max-w-5xl"></div>
  <ng-container *ngIf="(loading$ | async) === true">
    <div class="border-8 h-32 m-auto my-16 spinner w-32"></div>
  </ng-container>

  <form *ngIf="(loading$ | async) === false" [formGroup]="fg" (ngSubmit)="onSubmit()" class="ml-1">
    <div class="mb-2 text-xl font-semibold">
      {{ mode === 'add' ? 'Add New Vendor' : 'Vendor' }}
    </div>

    <div class="grid grid-cols-5 gap-5 mb-3">
      <aux-input
        class="mt-1"
        label="Entity Name"
        [formControl]="vendorFc"
        [showRequiredAsterisk]="true"
        [validators]="vendorNameValidators"
        auxTrimInput
      />
      <div *ngIf="vendorCurrencyEnabled$ | async">
        <label for="currency" class="text-xs h-5">
          Currency
          <span class="text-aux-error font-bold">*</span>
        </label>
        <ng-select
          id="currency"
          formControlName="currency"
          class="custom-outline select !mt-0"
          bindLabel="label"
          bindValue="key"
          auxFormError
          [fieldName]="'Currency'"
          [items]="currencyOptions"
          [clearable]="true"
          [searchable]="true"
          [appendTo]="'body'"
          [multiple]="false"
          [auxTooltip]="
            fg.get('currency')?.disabled
              ? 'Currency cannot be changed after a budget is uploaded'
              : ''
          "
        />
      </div>
    </div>
    <div class="grid grid-cols-5 gap-5 mb-3">
      <div>
        <label for="vendor_type" class="text-xs h-5">
          Vendor Type
          <span class="text-aux-error font-bold">*</span>
        </label>
        <ng-select
          id="vendor_type"
          formControlName="vendor_type"
          bindLabel="label"
          bindValue="key"
          class="custom-outline select !mt-0"
          auxFormError
          [fieldName]="'Vendor Type'"
          [items]="vendorTypeOptions"
          [searchable]="false"
          [clearable]="false"
          [appendTo]="'body'"
          [multiple]="false"
          [attr.data-pendo-id]="'pendo-vendor-type'"
        />
      </div>
    </div>

    <div *ngIf="getFc('is_third_party')?.value">
      <div class="aux-select">
        <label for="vendor">Select Parent Vendor</label>
        <ng-select
          id="vendor"
          formControlName="parent_organization_id"
          bindValue="id"
          bindLabel="name"
          class="mb-3"
          [clearable]="false"
          [searchable]="false"
          [appendTo]="'body'"
        >
          <ng-option *ngFor="let vendor of organizationQuery.selectAll() | async" [value]="vendor">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-select>
      </div>
      <aux-checkbox class="mb-3 text-sm" formControlName="costs_included_in_parent_wo">
        Costs included in parent WO?
      </aux-checkbox>
    </div>

    <div class="mb-2 text-xl font-semibold">Primary Contact</div>
    <div class="grid grid-cols-5 mb-3 gap-5">
      <div>
        <aux-input
          id="given_name"
          formControlName="given_name"
          label="First Name"
          auxFormError
          (change)="refreshVendorValidators()"
        />
      </div>
      <div>
        <aux-input
          id="family_name"
          formControlName="family_name"
          label="Last Name"
          auxFormError
          (change)="refreshVendorValidators()"
        />
      </div>
      <div>
        <aux-input
          id="email"
          formControlName="email"
          label="Email"
          auxFormError
          (change)="refreshVendorValidators()"
        />
      </div>
      <div>
        <aux-input
          id="title"
          formControlName="title"
          label="Title"
          auxFormError
          (change)="refreshVendorValidators()"
        />
      </div>
      <div>
        <aux-input
          id="phone_number"
          formControlName="phone_number"
          label="Phone Number"
          auxFormError
          (change)="refreshVendorValidators()"
        />
      </div>
    </div>

    <aux-checkbox class="mb-3 text-sm" formControlName="costs_included_in_parent_wo">
      Costs included in parent WO?
    </aux-checkbox>

    <aux-checkbox
      class="mb-3 text-sm w-[230px]"
      formControlName="receives_vendor_estimate"
      [disabled]="isVendorEstimateExists"
      [auxTooltip]="
        isVendorEstimateExists
          ? 'Cannot be unchecked for vendor with estimates for current or future month.'
          : ''
      "
    >
      Provides monthly vendor estimates
    </aux-checkbox>
  </form>

  <ng-container>
    <hr class="mb-4 mt-8 ml-1" />
    <div class="grid grid-cols-4 gap-5 mt-4 ml-1">
      <div>
        <div class="font-medium text-xl">Contracts</div>
        <div class="text-sm h-10 mb-2">Upload Work Orders, MSA, RFP responses</div>
        <aux-file-manager
          #fileManager
          class="h-24 mb-4"
          [eager]="false"
          [pathFn]="getFilePaths('contracts')"
          [fetchFilesOnInit]="fetchFilesOnInit"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_VENDOR_CONTRACT"
          (onFilesAdded)="onFilesAdded('contracts')"
          (filesRemoved)="onFilesRemoved()"
        />
        <aux-file-viewer
          [fileManager]="fileManager"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        />
      </div>
      <div>
        <div class="font-medium text-xl">Proposal</div>
        <div class="text-sm h-10 mb-2"></div>
        <aux-file-manager
          #fileManager2
          class="h-24 mb-4"
          [eager]="false"
          [pathFn]="getFilePaths('proposals')"
          [fetchFilesOnInit]="fetchFilesOnInit"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_PROPOSAL"
          (onFilesAdded)="onFilesAdded('proposals')"
          (filesRemoved)="onFilesRemoved()"
        />
        <aux-file-viewer
          [fileManager]="fileManager2"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        />
      </div>
      <div>
        <div class="font-medium text-xl">Other</div>
        <div class="text-sm h-10 mb-2"></div>
        <aux-file-manager
          #fileManager3
          class="h-24 mb-4"
          [eager]="false"
          [pathFn]="getFilePaths('other')"
          [fetchFilesOnInit]="fetchFilesOnInit"
          [metadataFn]="getMetadata()"
          [document_entity_id]="vendorId"
          [document_entity_type_id]="ORGANIZATION"
          [document_type_id]="DOCUMENT_OTHER"
          (onFilesAdded)="onFilesAdded('other')"
          (filesRemoved)="onFilesRemoved()"
        />
        <aux-file-viewer
          [fileManager]="fileManager3"
          [onlyShowUploaded]="false"
          [disableFirstFileMargin]="true"
        />
      </div>
    </div>
  </ng-container>
</div>

<ng-template #description>
  <span class="mb-[5px]">
    All Vendors can be managed on the
    <span
      class="btn--hyperlink p-0 cursor-pointer"
      (click)="router.navigateByUrl(vendorsRoutingPath); suggestionsModal?.close()"
      >Vendors</span
    >
    page
  </span>
</ng-template>
