<form
  *ngIf="availableGroups as items"
  [formGroup]="blendedCurveForm"
  (ngSubmit)="saveBlendedCurve()"
>
  <aux-modal primaryButtonType="submit" [loading]="loading()">
    <div class="w-screen max-w-[330px]"></div>

    <div class="text-xs font-bold mb-4">{{ subTitle }}</div>

    <div *ngFor="let item of items; index as index" class="text-sm mb-4">
      <aux-checkbox
        class="mb-3 text-sm"
        [checked]="item.check"
        (customChange)="setValue(item, $event)"
      >
        {{ item.name }}
      </aux-checkbox>
    </div>

    <div class="text-sm grid">
      <aux-input
        formControlName="blendedCurveName"
        [validators]="validators"
        [label]="'Blended Curve Name'"
        [showRequiredAsterisk]="true"
      />
    </div>
  </aux-modal>
</form>
