@if (loading()) {
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
} @else if (!prepaid) {
  <div class="text-center mt-40 m-auto">No prepaid found</div>
} @else {
  <div class="px-7 py-4 bg-aux-gray-light">
    <div
      class="flex items-center text-aux-blue-light-200 mb-6 cursor-pointer max-w-max"
      [routerLink]="[prepaidsLink]"
    >
      <aux-icon name="ArrowLeft" class="mr-2" />
      <div>Back to Prepaids</div>
    </div>

    <div class="font-bold text-xl mb-4">
      {{ prepaid.vendor_name }}
    </div>

    <div class="flex">
      <div class="text-aux-gray-dark-100 mr-2">Total Prepaid Balance</div>
      <div class="mr-5">
        {{ utils.currencyFormatter(prepaid.total_prepaid_balance, {}, this.vendorCurrency) }}
      </div>

      <div class="text-aux-gray-dark-100 mr-2">Short Term</div>
      <div class="mr-5">
        {{ utils.currencyFormatter(prepaid.short_term_amount, {}, this.vendorCurrency) }}
      </div>

      <div class="text-aux-gray-dark-100 mr-2">Long Term</div>
      <div>
        {{ utils.currencyFormatter(prepaid.long_term_amount, {}, this.vendorCurrency) }}
      </div>
    </div>
  </div>

  <div class="px-7 py-6">
    <aux-notes
      [title]="'Terms'"
      [notesControl]="notesControl"
      placeholder="Prepaid Terms..."
      [hasEditPermission]="!isNotesDisabled()"
      [disabledTooltipText]="notesTooltipText()"
      (saveNotes)="saveNotes()"
      (cancelNotes)="cancelNotes()"
    />

    <aux-adjustments-to-prepaid
      [isEditDisabled]="isNotesDisabled()"
      class="block mt-6"
      [vendorId]="vendorId"
      [vendorCurrency]="vendorCurrency"
      [vendorName]="vendorName"
      (adjustmentsSaved)="onAdjustmentsSaved()"
    />
  </div>
}
