<div class="flex items-center space-x-2">
  <span class="text-base text-aux-gray-darkest">
    {{ params.displayName }}
  </span>

  <button
    *ngIf="patientGroupsColumns.length"
    class="flex items-center no-underline aux-link"
    (click)="onChangeClick()"
  >
    <aux-icon
      class="text-aux-gray-darkest"
      [name]="(change$ | async) ? 'ChevronLeft' : 'ChevronRight'"
    />
  </button>
</div>
