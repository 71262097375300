import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aux-quarter-close-processing-confirmation',
  template: `<div style="width: 420px" class="font-inter">
    <p>
      You have successfully began the closing of your month/quarter.
      <span class="font-bold"
        >This process can take up to 5 minutes. You can close this window and leave the page.
      </span>
      You will receive an email notification when complete, and an Audit Package will be available
      in the Document Library.
    </p>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class QuarterCloseProcessingConfirmationComponent {}
