@if (multiple()) {
  <aux-multi-select-dropdown
    [classNames]="multipleDropdownClassNames()"
    [label]="label()"
    [placeholder]="placeholder()"
    [formControl]="ngControl.control"
    appendTo=""
    bindLabel="textToSearch"
    bindValue="value"
    [items]="siteOptions()"
    [loading]="loading()"
    [searchable]="true"
    [customOption]="true"
    [getCustomOptionLabel]="getCustomOptionLabel"
  />
} @else {
  @if (label()) {
    <p class="block mb-1 text-xs site-dropdown-label">{{ label() }}</p>
  }
  <ng-select
    class="site-dropdown"
    [ngClass]="{
      'menu-left': menuPosition() === 'left',
      'menu-auto-width': menuPanelWidth() === 'auto',
      'site-dropdown': true
    }"
    [placeholder]="placeholder()"
    [formControl]="ngControl.control"
    [bindLabel]="titleKey() === 'site_no' ? 'title' : 'siteName'"
    bindValue="value"
    [items]="siteOptions()"
    [loading]="loading()"
    [searchable]="true"
    [searchFn]="searchFn"
    [appendTo]="appendTo()"
  >
    <ng-template ng-option-tmp let-item="item">
      <div
        class="pr-4 text-sm leading-5"
        auxTooltip
        [template]="
          siteOptionSubtitle.offsetWidth < siteOptionSubtitle.scrollWidth ||
          siteOptionTitle.offsetWidth < siteOptionTitle.scrollWidth
            ? tooltipTemplate
            : undefined
        "
        auxTooltipPanelContainerClass="site-dropdown-tooltip"
      >
        <p class="font-bold truncate" #siteOptionTitle>
          {{ item.title }}
        </p>
        <p class="italic truncate" #siteOptionSubtitle>
          {{ item.subTitle }}
        </p>
      </div>

      <ng-template #tooltipTemplate>
        <div>
          <p>{{ item.title }}</p>
          <p>{{ item.subTitle }}</p>
        </div>
      </ng-template>
    </ng-template>
  </ng-select>
}
