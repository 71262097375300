<!-- Category -->
<div
  *ngIf="data && (!data.isChild || (data.isChild && data.subCategory))"
  class="flex items-center"
  [attr.auto-qa]="autoTestAttribute"
  [style.margin-left]="!data.isChild ? '16px' : data.indentLevel * 32 + 'px'"
>
  <!-- Toggle row open/closed -->
  <button
    class="flex items-center justify-center"
    (click)="componentParent.ToggleService.toggleCategory(data.id, params.node)"
  >
    <div class="w-[24px] h-[24px] flex items-center justify-center">
      <span
        *ngIf="data.isOpen"
        class="ag-icon ag-icon-tree-open"
        style="font-weight: bold !important"
      ></span>
      <span
        *ngIf="!data.isOpen"
        class="ag-icon ag-icon-tree-closed"
        style="font-weight: bold !important"
      ></span>
    </div>
  </button>

  <div>
    <!-- Category name -->
    <div class="flex items-center text-xs">
      <aux-icon
        name="AlertTriangle"
        class="-ml-0.5 text-aux-red-dark"
        *ngIf="componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async"
      />
      <span class="text-ellipsis overflow-hidden category-name">{{ data.categoryName }}</span>
    </div>
    <!-- Category checkbox -->
    <div class="apply-container">
      <aux-checkbox
        class="text-xs"
        [id]="'cat' + data.id"
        [checked]="data.primarySettingsOverride"
        [disabled]="
          disableCategory ||
          componentParent.isClosedMonthsProcessing ||
          componentParent.isForecastFinalized
        "
        [auxTooltip]="
          componentParent.CheckService.pageLockedTooltipText(
            componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing
          )
        "
        [indeterminate]="
          data.primarySettingsOverride &&
          componentParent.CheckService.isCategoryIndeterminate(data.id)
        "
        (customChange)="
          componentParent.toggleCategoryApplyAll(
            $event,
            data.primarySettingsId,
            data.primarySettingsOverride,
            data.id
          )
        "
      >
        Apply to activities
        <span *ngIf="data.primarySettingsOverride && data.activityCheckedCount" class="text-xxs"
          >({{ data.activityCheckedCount }} Override)</span
        >
      </aux-checkbox>
    </div>
  </div>
</div>

<!-- Activity -->
<div
  *ngIf="data && data.isChild && !data.subCategory"
  class="flex flex-col items-left"
  [style.margin-left]="data.indentLevel * 16 + 40 + 'px'"
>
  <!-- Activity name -->
  <div class="flex items-center">
    <aux-icon
      *ngIf="componentParent.CheckService.isActivityUnforecasted(data.id) | async"
      name="AlertTriangle"
      class="-ml-0.5 text-aux-red-dark"
    />
    <div class="text-xs text-ellipsis line-clamp-3 activity-name">
      {{ data.categoryName }}
    </div>
  </div>
  <!-- Activity checkbox -->
  <div *ngIf="data.parentCategory?.primarySettingsOverride" class="text-xs apply-container">
    <aux-checkbox
      class="text-xs"
      [id]="'cat' + data.id"
      [checked]="data.primarySettingsOverride"
      [disabled]="
        disableCategory ||
        componentParent.isClosedMonthsProcessing ||
        !componentParent.userHasModifyPermissions ||
        componentParent.isForecastFinalized
      "
      [auxTooltip]="
        componentParent.CheckService.pageLockedTooltipText(
          componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing
        )
      "
      (customChange)="
        componentParent.toggleActivityApplyAll($event, data.fullActivity!, data.fullParentCategory!)
      "
    >
      Override
    </aux-checkbox>
  </div>
</div>
