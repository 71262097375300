import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { firstValueFrom, of } from 'rxjs';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { FileMetadata } from '@shared/services/api.service';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { DocumentType, EntityType, EventType } from '@shared/services/gql.service';
import { Option } from '@shared/types/components.type';
import { File } from '@shared/components/file-manager/state/file.model';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { DocumentLibraryService } from '../document-library.service';
import { EventService } from '@models/event/event.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AsyncPipe } from '@angular/common';
import { FileManagerUploadedFilesComponent } from '@features/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { SiteSelectDropdownComponent } from '@features/site-select-dropdown/site-select-dropdown.component';

@Component({
  selector: 'aux-document-upload',
  templateUrl: './document-upload.component.html',
  styles: [
    `
      :host ::ng-deep .site-dropdown-label {
        @apply text-sm mb-2;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    FileManagerComponent,
    IconComponent,
    AsyncPipe,
    FileManagerUploadedFilesComponent,
    SiteSelectDropdownComponent,
    ModalComponent,
  ],
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  vendorsList: Option[] = [];

  uploadedFiles$ = of<File[]>([]);

  loading = signal(false);

  ref = inject<CustomOverlayRef<unknown, { vendors: Option[]; sites: Option[] }>>(CustomOverlayRef);

  private readonly documentLibraryService = inject(DocumentLibraryService);

  private readonly formBuilder = inject(UntypedFormBuilder);

  private readonly authQuery = inject(AuthQuery);

  private readonly eventService = inject(EventService);

  documentTypeList: Option<DocumentType>[] = this.documentLibraryService.getDocumentOptions();

  documentForm = this.formBuilder.group({
    vendor: undefined,
    site: undefined,
    documentType: undefined,
  });

  ngOnInit() {
    this.vendorsList = this.ref.data?.vendors || [];

    Promise.resolve().then(() => {
      if (this.fileManager) {
        this.uploadedFiles$ = this.fileManager.fileQuery.selectAll();
      }
    });
  }

  pathFn: () => string = () => '';

  async onUpload() {
    if (this.fileManager) {
      this.loading.set(true);
      const files = this.fileManager.fileQuery.getAll();

      this.updateFileStoreBeforeUpload(files);
      const successFM = await this.fileManager.fileService.uploadFiles(
        this.metadata,
        false,
        false,
        undefined,
        true,
        true
      );
      if (successFM) {
        const successDL = this.documentLibraryService.uploadDocuments(
          files,
          this.documentForm.value
        );

        const idList = [] as string[];
        const successUpload = await successDL;
        successUpload.forEach((file) => {
          if (file instanceof Error) {
            return;
          }
          if (file.success && file.data?.id) {
            idList.push(file.data?.id);
          }
        });

        if (!this.authQuery.isAuxAdmin() && idList.length > 0) {
          await firstValueFrom(
            this.eventService.processEvent$({
              type: EventType.DOCUMENT_UPLOADED_NOTIFICATION,
              entity_type: EntityType.DOCUMENT,
              entity_id: '',
              payload: JSON.stringify({
                idList,
                uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
                file_name: files[0].fileName,
              }),
            })
          );
        }
        this.ref.close(true);
      }
      this.loading.set(false);
    }
  }

  private updateFileStoreBeforeUpload(files: File[]) {
    files.forEach((file) => {
      this.fileManager?.fileStore.update(file.id, {
        ...file,
        bucket_key: `${this.documentLibraryService.buildS3Path(file.bucket_key)}`,
      });
    });
  }
}
