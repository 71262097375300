<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  [workflowName]="workflowName"
  [processing]="(isCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockPatientDataPermission"
/>

<aux-edc-status-banner></aux-edc-status-banner>

@if (bulkUploadProcessing()) {
  <aux-processing-loader class="block my-3" [message]="bulkProcessingMessage" />
}

<aux-investigator-transactions-header
  [isContractSelected$]="isContractSelected$"
  [editMode$]="editMode$"
  [gridAPI]="gridAPI"
  [totalCost$]="totalCost$"
  [lastSourceRefreshDate$]="lastSourceRefreshDate$"
  [dataStreamInputs]="datasource.currentServerInput"
/>

<aux-progress-tracker
  [trackerId]="progressTrackerId()"
  (trackerIdChanged)="onTrackerIdChanged($event)"
  [validationInProgressCaption]="'Import EDC Data - Validation Progress'"
  [dataLoadInProgressCaption]="'Import EDC Data - Data Load Progress'"
/>

<div class="flex">
  <aux-investigator-transactions-filters
    class="flex-1"
    [filterLoading$]="filterLoading$"
    [showSourceColumn$]="showSourceColumn$"
    [gridLoading$]="datasource.loading$"
    [editMode$]="editMode$"
    [gridFiltersFormGroup]="gridFiltersFormGroup"
  />

  <form
    class="flex items-center space-x-4"
    [formGroup]="transactionForm"
    *ngIf="{
      editMode: editMode$ | async,
      addTransactionDisabled: addTransactionDisabled$ | async,
      gridLoading: datasource.loading$ | async
    } as obj"
  >
    <aux-button
      [onClick]="cancelChanges"
      variant="secondary"
      [disabled]="submitInProgress()"
      icon="X"
      label="Cancel"
      *ngIf="obj.editMode"
    />
    <aux-button
      variant="success"
      label="Save"
      icon="Check"
      [onClick]="saveTransactions"
      [disabled]="submitInProgress()"
      *ngIf="obj.editMode"
    />
    <div class="h-7 w-px bg-aux-gray-dark" *ngIf="obj.editMode"></div>

    <aux-button-group
      label="Add"
      [options]="actionGroupButtons()"
      variant="primary"
      [disabled]="
        obj.addTransactionDisabled ||
        obj.gridLoading ||
        isPatientsFinalized() ||
        obj.editMode === 'edit' ||
        bulkUploadProcessing()
      "
      [auxTooltip]="patientsFinalizedText()"
      [position]="addButtonGroupOverlayPosition"
    />
  </form>
</div>

<aux-pagination-grid
  [gridOptions]="$any(gridOptions$ | async)"
  (gridReady)="onGridReady($event)"
  [dataSource]="datasource"
  [filterForm]="gridFiltersFormGroup"
  [serverSideFilters]="serverSideFilters"
  [loading]="submitInProgress()"
  [filterValues$]="filterValues$"
  [sortModel$]="sortModel$"
  [idTable]="'transactionTable'"
  [hidePinnedRow]="true"
  [getRowId]="getRowId"
  [getContext]="getGridContext"
  (paginationChange)="paginationChange()"
/>

@if (showExportFull()) {
  <aux-export-full-dataset-button
    class="flex justify-end"
    [datasource]="datasource"
    [totalRows]="totalRecords()"
    [processEvent]="onExportInvestigatorTransactions"
  />
}
