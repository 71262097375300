import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AgSetColumnsVisible } from '@shared/utils';

export interface AgQuarterCloseGroupHeaderComponentParams extends IHeaderParams {
  columnsToCollapse: string[];
  localStorageKey: string;
  expandLevel: () => number;
  afterAttrToggle?: () => void;
  alignHeaderOneRowHeight?: boolean;
  templateClasses?: string[];
  quickFilterText: boolean;
}
@Component({
  selector: 'aux-ag-quarter-close-group-header',
  standalone: true,
  imports: [NgClass, IconComponent, TooltipDirective, ReactiveFormsModule, NgIf],
  templateUrl: './ag-quarter-close-group-header.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseGroupHeaderComponent implements IHeaderAngularComp {
  private readonly destroyRef = inject(DestroyRef);

  params!: AgQuarterCloseGroupHeaderComponentParams;

  visible = true;

  templateClasses = ['text-aux-black', 'text-base'];

  searchControl = new FormControl('');

  agInit(params: AgQuarterCloseGroupHeaderComponentParams): void {
    this.params = params;
    if (params.templateClasses) {
      this.templateClasses = params.templateClasses;
    }

    this.visible = params.columnsToCollapse?.some((c) => {
      // sometimes func doesn't return value
      // So collapse doesn't show correctly
      return params.api.getColumn(c)?.isVisible();
    });

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.params.api.setQuickFilter(value || '');
    });
  }

  toggleExpand() {
    this.visible = !this.visible;
    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: this.params.columnsToCollapse,
      visible: this.visible,
    });
    if (this.params.localStorageKey)
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());

    if (this.params.afterAttrToggle) {
      this.params.afterAttrToggle();
    }
  }

  toggleRowExpand(isExpanded: boolean) {
    this.params.api.forEachNode((node) => {
      if (isExpanded) {
        node.expanded = false;
      } else {
        const lvl = this.params.expandLevel();
        node.expanded = lvl !== -1 ? node.level < lvl : true;
      }
    });
    this.params.api.onGroupExpandedOrCollapsed();
  }

  refresh(): boolean {
    return false;
  }
}
