<div class="w-screen max-w-[500px]">
  <aux-download-template-button
    [templateType]="templateType"
    label="Download Bulk Transactions Template"
    class="pb-4 mb-4 block"
  />

  <div>
    <div class="text-sm mb-2">Upload Template</div>
    <aux-file-manager
      #fileManager
      class="h-20 bg-aux-gray-light"
      [eager]="false"
      [accept]="'.csv'"
      [extensions]="['text/csv']"
      [showSpecificError]="true"
      [errorMessage]="errorMessage()"
      (onFilesAdded)="validateFileManager()"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <aux-icon name="Upload" />
        <div class="ml-3">
          <p class="text-sm">Drag & Drop Documents Here or <span class="aux-link">Browse</span></p>
        </div>
      </div>
    </aux-file-manager>
  </div>

  @if (fileManager) {
    <aux-file-manager-uploaded-files
      [titleLess]="true"
      [borderLess]="false"
      [fileManager]="fileManager"
      (removeFile)="validateFileManager()"
    />
  }
</div>
