import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { listDriverPatientDistributionsQuery } from '@shared/services/gql.service';

export type PatientsState = EntityState<listDriverPatientDistributionsQuery, string>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patients' })
export class PatientsStore extends EntityStore<PatientsState> {
  constructor() {
    super({});
  }
}
