import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { BaseFormControlComponent } from '@shared/components/base-form-control/base-form-control';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

@Component({
  selector: 'aux-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: 'multi-select-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgClass, NgFor, NgSelectModule, TooltipDirective],
})
export class MultiSelectDropdownComponent extends BaseFormControlComponent<string | null> {
  multiple = input(true);

  @Input() items: NgOption[] = [];

  @Input() bindLabel = 'label';

  @Input() loading = false;

  @Input() bindValue = 'value';

  @Input() appendTo: string = 'body';

  @Input() labelClassName = '';

  @Input() prefix? = '';

  @Input() prefixClick?: VoidFunction;

  @Input() searchable = false;

  @Input() classNames = '';

  @Input() customOption = false;

  @Input() getCustomOptionLabel?: (option: unknown) => string;

  @Input() maximumItemsToDisplay = 1;

  @Input() disabled = false;

  @Input() clearable = true;

  @Input() requiredLabel = false;

  get classes(): string {
    let classes = '';

    if (this.prefix) {
      classes += 'no-border-right';
    }

    if (this.classNames) {
      classes += ` ${this.classNames}`;
    }

    return classes.trim();
  }

  getOptionLabel(option: NgOption): string {
    if (this.getCustomOptionLabel) {
      return this.getCustomOptionLabel(option);
    }

    if (this.customOption) {
      return option.title || '';
    }

    return option[this.bindLabel] || '';
  }
}
