import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { TrialsService } from '@models/trials/trials.service';
import { TrialImplementationStatus, TrialPhase } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { TrialsQuery } from '@models/trials/trials.query';
import { InputComponent } from '@shared/components/input/input.component';
import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormErrorDirective } from '@shared/directives/form-error.directive';
import { Option } from '@shared/types/components.type';
import { MultiSelectDropdownComponent } from '@shared/components/multi-select-dropdown/multi-select-dropdown.component';

@Component({
  selector: 'aux-new-trial-modal',
  templateUrl: './new-trial-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    ToggleComponent,
    NgSelectModule,
    AsyncPipe,
    NgIf,
    FormErrorDirective,
    MultiSelectDropdownComponent,
  ],
})
export class NewTrialDialogComponent {
  customFg = this.formBuilder.group({
    sponsor_organization_name: '',
    name: '',
    short_name: '',
    indication: '',
    therapy_area: null,
    trial_phase: null,
    auxilius_start_date: null,
    onboarding_complete: true,
    program: null,
    project: null,
    implementation_status: TrialImplementationStatus.IMPLEMENTATION_STATUS_IMPLEMENTATION,
    workspace_type: [null, Validators.required],
  });

  cache: {
    [k: string]: { success: boolean; error?: string; data?: string } | undefined;
  } = {};

  sponsor$ = new BehaviorSubject<{ success: boolean; error?: string; data?: string } | undefined>(
    undefined
  );

  loading$ = new BehaviorSubject(false);

  implementationStatusOptions = Object.entries(Utils.TRIAL_IMPLEMENTATION_STATUS_OPTIONS)
    .map(([key, label]) => ({
      label,
      key,
    }))
    .slice(1);

  therapyAreaOptions: Option[] = [];

  trialPhaseOptions: Option<TrialPhase[]>[] = [
    {
      label: 'Pre Clinical',
      value: [TrialPhase.TRIAL_PHASE_PRE_CLINICAL],
    },
    {
      label: 'Phase 1',
      value: [TrialPhase.TRIAL_PHASE_PHASE_1],
    },
    {
      label: 'Phase 1/2',
      value: [TrialPhase.TRIAL_PHASE_PHASE_1, TrialPhase.TRIAL_PHASE_PHASE_2],
    },
    {
      label: 'Phase 2',
      value: [TrialPhase.TRIAL_PHASE_PHASE_2],
    },
    {
      label: 'Phase 3',
      value: [TrialPhase.TRIAL_PHASE_PHASE_3],
    },
    {
      label: 'Phase 4',
      value: [TrialPhase.TRIAL_PHASE_PHASE_4],
    },
  ];

  workspaceTypeOptions = Object.entries(Utils.TRIAL_WORKSPACE_TYPE_OPTIONS).map(([key, label]) => ({
    label,
    key,
  }));

  constructor(
    public ref: CustomOverlayRef<string>,
    private trialsService: TrialsService,
    private formBuilder: UntypedFormBuilder,
    public trialsQuery: TrialsQuery
  ) {
    this.initTherapyAreaOptions();
  }

  async initTherapyAreaOptions(): Promise<void> {
    this.therapyAreaOptions = await this.trialsService.getTherapyAreaOptions();
  }

  async createCustomTrial() {
    if (this.customFg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);
      const {
        sponsor_organization_name,
        name,
        short_name,
        indication,
        therapy_area,
        trial_phase,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
        implementation_status,
        workspace_type,
      } = this.customFg.value;

      const resp = await this.trialsService.createCustomTrial({
        sponsor_organization_name,
        name,
        short_name,
        indication,
        therapy_area,
        trial_phase,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
        implementation_status,
        workspace_type,
      });

      this.loading$.next(false);

      this.ref.close(resp);
    }
  }

  trialShortNameValidators: ValidatorFn[] = [
    (control) => {
      const value: string = control.value;
      if (!value) {
        return { required: true };
      }

      return this.trialsQuery
        .getAll()
        .find((trial) => trial.short_name?.toLowerCase() === value.toLowerCase())
        ? { duplicateTrial: true }
        : null;
    },
  ];
}
