import { NgModule } from '@angular/core';

import { StageBannerComponent } from './stage-banner/stage-banner.component';
import { StageIndicatorComponent } from './stage-indicator/stage-indicator.component';
import { WorkflowPanelComponent } from './workflow-panel/workflow-panel.component';
import { ExchangeLinkComponent } from './exchange-link/exchange-link.component';
import { TrialDropdownComponent } from './trial-dropdown/trial-dropdown.component';
import { SaveChangesComponent } from './save-changes/save-changes.component';
import { FirstNameShowComponent } from './first-name-show/first-name-show.component';
import { ExportExcelButtonComponent } from './export-excel-button/export-excel-button.component';
import { RouteTabGroupComponent } from './tab-group/route-tab-group.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { FileManagerUploadedFilesComponent } from './file-manager-uploaded-files/file-manager-uploaded-files.component';
import { AgBudgetGroupHeaderComponent } from './ag-budget-group-header/ag-budget-group-header.component';
import { DownloadTemplateButtonComponent } from './download-template-button/download-template-button.component';
import { ExportFullDatasetButtonComponent } from './export-full-dataset-button/export-full-dataset-button.component';
import { SnapshotModalComponent } from './snapshot-modal/snapshot-modal.component';
import { PortfolioTaskListComponent } from './portfolio-task-list/portfolio-task-list.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { SiteSelectDropdownComponent } from './site-select-dropdown/site-select-dropdown.component';

const sharedComponents = [
  StageBannerComponent,
  StageIndicatorComponent,
  WorkflowPanelComponent,
  DownloadTemplateButtonComponent,
  ExchangeLinkComponent,
  TrialDropdownComponent,
  SaveChangesComponent,
  FirstNameShowComponent,
  ExportExcelButtonComponent,
  RouteTabGroupComponent,
  NavigationMenuComponent,
  FileViewerComponent,
  FileManagerUploadedFilesComponent,
  AgBudgetGroupHeaderComponent,
  ExportFullDatasetButtonComponent,
  SnapshotModalComponent,
  PortfolioTaskListComponent,
  ProgressTrackerComponent,
  SiteSelectDropdownComponent,
] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class FeaturesModule {}
