import { Injectable } from '@angular/core';
import { DriverType } from '@shared/services/gql.service';
import { Option } from '@shared/types/components.type';
import { FullActivity, FullCategory } from '../../../state/category/category.query';

@Injectable()
export class ForecastTableGridDriverService {
  DriverOptions: Option<DriverType>[] = [
    {
      value: DriverType.DRIVER_CUSTOM,
      label: 'Custom Curve',
    },
    {
      value: DriverType.DRIVER_PATIENT,
      label: 'Patient',
    },
    {
      value: DriverType.DRIVER_SITE,
      label: 'Site',
    },
    {
      value: DriverType.DRIVER_TIME,
      label: 'Time',
    },
  ];

  parseCategoryDriver(category: FullCategory | FullActivity): string {
    return category.primary_settings.driver || '';
  }

  parseCategoryDriverSettingId(category: FullCategory | FullActivity): string {
    return category.primary_settings.driver_setting_id || '';
  }
}
