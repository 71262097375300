import { File } from '@shared/components/file-manager/state/file.model';
import dayjs from 'dayjs';
import { listDocumentsQuery } from '@shared/services/gql.service';
export enum EDCItemStatus {
  STATUS_AWAITING_UPLOAD = 'STATUS_AWAITING_UPLOAD',
  STATUS_PROCESSING = 'STATUS_PROCESSING',
  STATUS_AVAILABLE = 'STATUS_AVAILABLE',
}

export type EDCItem = { text: string; bgColor: string; borderColor: string; color: string };

export type EDCUploadStatus = { uploaded_by: string; date_uploaded: string };

export function getEDCUploadStatusFromFiles(
  files: File[] | listDocumentsQuery['items']
): EDCUploadStatus {
  const item = { uploaded_by: '', date_uploaded: 'not_date' } as EDCUploadStatus;
  if (!files || files.length === 0) {
    return item;
  }
  const uploadedFiles =
    '__typename' in files[0]
      ? files
      : ((files as File[]).filter(
          (file) => file.uploaded && !!file.rawFile && !!file.create_date && !!file.created_by
        ) as File[]);
  if (!uploadedFiles.length) {
    return item;
  }
  const lastDatedFile = files.reduce((a, b) => {
    return dayjs(a.create_date).isAfter(b.create_date) ? a : b;
  });
  item.uploaded_by = lastDatedFile.created_by || '';
  item.date_uploaded = lastDatedFile.create_date || '';
  return item;
}

export const EDCStatusMap: Record<EDCItemStatus, EDCItem> = {
  [EDCItemStatus.STATUS_AWAITING_UPLOAD]: {
    text: 'Awaiting Upload',
    bgColor: '#E5E7EB    ',
    borderColor: '#D1D5DB',
    color: '#4f6d79',
  },
  [EDCItemStatus.STATUS_PROCESSING]: {
    text: 'Processing',
    bgColor: '#FFE5BF',
    borderColor: '#C44000',
    color: '#C44000',
  },
  [EDCItemStatus.STATUS_AVAILABLE]: {
    text: 'Available',
    bgColor: '#EDFFF1',
    borderColor: '#01CBAD',
    color: '#08675D',
  },
};

export const EDCStatusBannerMap: Record<EDCItemStatus, EDCItem> = {
  [EDCItemStatus.STATUS_AWAITING_UPLOAD]: {
    text: 'Awaiting Upload',
    bgColor: '#F6F6F9',
    borderColor: '#B2B5C7',
    color: '#696F8E',
  },
  [EDCItemStatus.STATUS_PROCESSING]: {
    text: 'Processing',
    bgColor: '#FFE5BF',
    borderColor: '#FDDE68',
    color: '#C44000',
  },
  [EDCItemStatus.STATUS_AVAILABLE]: {
    text: 'Available',
    bgColor: '#C7FFF2',
    borderColor: '#01CBAD',
    color: '#00A48F',
  },
};
