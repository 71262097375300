import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BudgetSnapshotType, EventType } from '@shared/services/gql.service';
import { SnapshotService } from '@features/budget/services/snapshot.service';
import { SnapshotModalComponent } from '@features/snapshot-modal/snapshot-modal.component';
import { BudgetStore } from '@models/budget/budget.store';
import { Overlay } from '@angular/cdk/overlay';
import { switchMap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { EventQuery } from '@models/event/event.query';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { DatePipe, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import {
  BudgetCompareMenuSnapshotChangeFn,
  BudgetCompareMenuSnapshotDisabled,
  BudgetCompareMenuSnapshotFormControl,
  BudgetCompareMenuSnapshotList,
  BudgetCompareMenuSnapshotLoading,
  BudgetCompareMenuSnapshotRefreshFn,
  BudgetCompareMenuSnapshotShowDelete,
  BudgetCompareMenuSnapshotShowEdit,
  BudgetCompareMenuSnapshotValue,
  defaultSnapshotChangeFn,
  defaultSnapshotRefreshFn,
} from '../../models/budget-compare-menu.models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'aux-compare-dropdown-trial-insights',
  templateUrl: './compare-dropdown.component.html',
  styles: [
    `
      ::ng-deep .ng-option-selected::after {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, TooltipDirective, NgIf, IconComponent, DatePipe],
})
export class CompareDropdownComponent implements OnInit, OnChanges {
  private readonly destroyRef = inject(DestroyRef);

  BUDGET_SNAPSHOT_USER_CREATED = BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED;

  @Input() snapshotFormControl: BudgetCompareMenuSnapshotFormControl;

  @Input() snapshotList: BudgetCompareMenuSnapshotList;

  @Input() snapshotValue: BudgetCompareMenuSnapshotValue;

  @Input() snapshotDisabled: BudgetCompareMenuSnapshotDisabled;

  @Input() snapshotShowEdit: BudgetCompareMenuSnapshotShowEdit;

  @Input() snapshotShowDelete: BudgetCompareMenuSnapshotShowDelete;

  @Input() snapshotLoading: BudgetCompareMenuSnapshotLoading;

  @Input() snapshotChangeFn: BudgetCompareMenuSnapshotChangeFn;

  @Input() snapshotRefreshFn: BudgetCompareMenuSnapshotRefreshFn;

  constructor(
    private snapshotService: SnapshotService,
    private overlayService: OverlayService,
    private budgetStore: BudgetStore,
    public overlay: Overlay,
    private eventQuery: EventQuery
  ) {
    this.snapshotFormControl = new FormControl<string>('');
    this.snapshotList = [];
    this.snapshotValue = '';
    this.snapshotDisabled = false;
    this.snapshotShowEdit = true;
    this.snapshotShowDelete = true;
    this.snapshotLoading = false;
    this.snapshotChangeFn = defaultSnapshotChangeFn;
    this.snapshotRefreshFn = defaultSnapshotRefreshFn;
  }

  ngOnInit(): void {
    this.eventQuery
      .selectProcessingEvent$(EventType.CREATE_TRIAL_BUDGET_SNAPSHOT)
      .subscribe((isProcessing) => {
        if (isProcessing === false) {
          this.overlayService.showFieldTooltip(
            'compare-snapshot-dropdown',
            'Snapshot successfully created'
          );
          firstValueFrom(this.snapshotService.getSnapshotList());
        }
      });

    this.snapshotFormControl.setValue(this.snapshotFormControl.defaultValue);

    this.snapshotFormControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((snapshotName) => {
          this.snapshotChangeFn(snapshotName || '');
          if (!snapshotName) {
            this.snapshotService.setOriginalBudgetData();
            return of();
          }

          return this.snapshotService.getBudgetSnapshots(snapshotName);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('snapshotList' in changes) {
      this.processSnapshotList(changes.snapshotList);
    }

    if ('snapshotLoading' in changes) {
      this.processSnapshotLoading(changes.snapshotLoading);
    }
  }

  processSnapshotList(change: SimpleChange): void {
    if ('currentValue' in change) {
      const options = change.currentValue as BudgetCompareMenuSnapshotList;

      const isValueExist = options.find(({ value }) => value === this.snapshotFormControl.value);

      if (!isValueExist) {
        this.snapshotFormControl.setValue(this.snapshotFormControl.defaultValue);
      }
    }
  }

  processSnapshotLoading(change: SimpleChange): void {
    if ('currentValue' in change) {
      const loading = change.currentValue as boolean;

      if (loading || this.snapshotDisabled) {
        this.snapshotFormControl.disable();
      } else {
        this.snapshotFormControl.enable();
      }
    }
  }

  groupByFn(option: {
    label: string;
    value: string;
    create_date: string;
    snapshot_type: string;
    id: string;
  }) {
    switch (option.snapshot_type) {
      case BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED:
        return 'MANUAL SNAPSHOTS';
      default:
        return 'AUTOMATIC SNAPSHOTS ';
    }
  }

  async editSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();
    const name = snapshot.label;
    const { data } = await firstValueFrom(
      this.overlayService.openPopup<{ name?: string }, { name?: string }>({
        modal: SnapshotModalComponent,
        settings: { header: 'Name Budget Snapshot' },
        data: { name },
      }).afterClosed$
    );
    if (data?.name) {
      const n = data.name;
      await this.emitUpdatedValue(
        name,
        n,
        () => {
          return this.snapshotService.updateSnapshots(snapshot, n);
        },
        () => this.snapshotFormControl.setValue(n)
      );
    }
  }

  private async emitUpdatedValue(
    value: string,
    newName: string | undefined,
    callback: () => Promise<void>,
    updateControlCallback?: VoidFunction
  ) {
    if (value === this.snapshotFormControl.value) {
      this.snapshotChangeFn(newName || '');
      this.budgetStore.setLoading(true);

      if (updateControlCallback) {
        updateControlCallback();
      }

      // await callback();
      this.snapshotRefreshFn();
    } else {
      await callback();
    }

    this.budgetStore.setLoading(false);
  }

  async deleteSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();

    this.overlayService.openPopup<
      { message: string },
      boolean | undefined,
      ConfirmationModalComponent
    >({
      content: ConfirmationModalComponent,
      data: {
        message: `Are you sure you want to remove ${snapshot.label} plan?`,
      },
      settings: {
        header: 'Remove Plan',
        primaryButton: {
          label: 'Remove',
          action: async (instance) => {
            instance?.ref.close();
            await this.emitUpdatedValue(snapshot.label, undefined, () =>
              this.snapshotService.removeBudgetSnapshots(snapshot)
            );
          },
        },
      },
    });
  }
}
