import { AsyncPipe, NgIf, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { IconComponent } from '@shared/components/icon/icon.component';
import { isNumber } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-percent-indicator',
  templateUrl: './percent-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, NgIf, AsyncPipe, PercentPipe],
})
export class PercentIndicatorComponent implements OnChanges {
  @Input() value!: number;

  formattedValue$ = new BehaviorSubject(0);

  zeroHyphen = Utils.zeroHyphen;

  isValuePositive = false;

  ngOnChanges(changes: SimpleChanges): void {
    const value = changes.value.currentValue;

    if (isNumber(value)) {
      this.isValuePositive = value >= 0;
      const formattedValue = Math.abs(value);
      this.formattedValue$.next(formattedValue);
    }
  }
}
