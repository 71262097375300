import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputComponent } from '@shared/components/input/input.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

export interface ReasonModalData {
  message: string;
}

export interface ReasonModalResponse {
  reason: string;
}

@Component({
  template: `
    <div class="space-y-4 max-w-[500px]">
      <p>{{ ref.data?.message }}</p>
      <aux-input
        [formControl]="reason"
        [textArea]="true"
        [rows]="2"
        label="Reason"
        validators="required"
      />
    </div>
  `,
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule],
})
export class ReasonModalModalComponent {
  ref = inject<CustomOverlayRef<{ reason: string }, { message: string }>>(CustomOverlayRef);

  reason = new FormControl('', [Validators.required]);

  save() {
    this.reason.markAsTouched();
    this.reason.markAsDirty();
    this.reason.updateValueAndValidity();

    if (this.reason.valid && this.reason.value) {
      this.ref.close({ reason: this.reason.value });
    }
  }
}
