<input
  [id]="id"
  type="checkbox"
  class="size-[16px] text-aux-blue border-aux-gray-dark rounded-[3px] focus:ring-aux-blue"
  [ngClass]="{
    'cursor-pointer': !disabled,
    'cursor-not-allowed bg-gray-100': disabled
  }"
  [disabled]="disabled"
  [indeterminate]="indeterminate"
  [(ngModel)]="checked"
  (ngModelChange)="customChange.emit($event); onChangeCallback?.($event)"
/>
@if (!hideLabel) {
  <label class="block ml-[8px] cursor-pointer" [for]="id"><ng-content></ng-content></label>
}
