import { Injectable } from '@angular/core';

@Injectable()
export class TrialInsightsService {
  changeBackground(init: boolean): void {
    const mainElement = document.querySelector('main');

    if (!mainElement) {
      return;
    }

    if (init) {
      mainElement.style.backgroundColor = '#f3f6f7';
    } else {
      mainElement.style.backgroundColor = '';
    }
  }

  getBackgroundColor(): string {
    const mainElement = document.querySelector('main');

    if (!mainElement) {
      return '';
    }

    return mainElement.style.backgroundColor;
  }
}
