import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { Utils } from '@shared/utils/utils';

@Component({
  standalone: true,
  template: `
    <div
      class="truncate"
      #text
      [auxTooltip]="text.offsetWidth < text.scrollWidth ? params.value : ''"
    >
      {{ params.value || utils.zeroHyphen }}
    </div>
  `,
  imports: [IconComponent, NgForOf, TooltipDirective, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTooltipComponent implements ICellRendererAngularComp {
  readonly utils = Utils;

  params!: ICellRendererParams;

  agInit(params: ICellRendererParams) {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
