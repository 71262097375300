import { ChangeDetectionStrategy, Component, signal, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { FileMetadata } from '@shared/services/api.service';
import { firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OverlayService } from '@shared/services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { EntityType, EventType } from '@shared/services/gql.service';
import { EventService } from '@models/event/event.service';
import { MessagesConstants } from '@shared/constants/messages.constants';

@Component({
  selector: 'aux-specifications-upload',
  template: `
    <div class="w-screen max-w-[280px]"></div>
    <aux-file-manager
      #manager
      class="h-32"
      [fetchFilesOnInit]="false"
      [pathFn]="pathFn"
      [eager]="false"
      [metadata]="metadata"
      [showSuccessOnUpload]="true"
      [accept]="'.csv'"
    />
    <div class="max-h-60 overflow-auto mt-4">
      <aux-file-viewer
        [fileManager]="manager"
        [disableFirstFileMargin]="true"
        [onlyShowUploaded]="false"
      />
    </div>

    <div *ngIf="errorMessage()" class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage() }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificationsUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading = signal(false);

  errorMessage = signal('');

  constructor(
    public ref: CustomOverlayRef<unknown, unknown>,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private eventService: EventService
  ) {}

  pathFn: () => string = () => '';

  getFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/specification/`;
  }

  async onUpload() {
    this.errorMessage.set('');

    if (this.fileManager && !this.loading()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
        return;
      }

      if (files.length > 1) {
        this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
        return;
      }

      const match = files[0].bucket_key.match(/\.([^.]+)$/);
      if (!(match?.[1] === 'csv' || match?.[1] === 'png')) {
        this.errorMessage.set('File type must be .csv or .png!');
        return;
      }

      this.loading.set(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const mode = match?.[1] === 'csv' ? 'csv' : 'png';

        const { success, errors } = await firstValueFrom(
          this.eventService.processEvent$({
            type:
              mode === 'csv'
                ? EventType.SPECIFICATIONS_TEMPLATE_UPLOADED
                : EventType.SPECIFICATIONS_IMAGE_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
        );
        if (success) {
          this.overlayService.success(`Specification successfully uploaded!`);
        } else {
          this.fileManager.apiService.removeFile(`${this.getFilePath()}${file.bucket_key}`);
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading.set(false);
  }
}
