import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';

export type ICheckboxCellRendererParams<T = unknown> = ICellRendererParams<T> & {
  tooltipField?: string;
  checkboxTooltip: (() => string) | ((params: ICheckboxCellRendererParams<T>) => string);
  checkboxContainerClass: string;
};

// todo move this component to shared folder
@Component({
  template: `
    <div class="flex items-center h-full">
      @if (!pinned) {
        <div
          class="flex h-full border-r border-[var(--ag-border-color)]"
          [ngClass]="params.checkboxContainerClass"
        >
          <aux-checkbox
            [disabled]="!selectable"
            [auxTooltip]="checkboxTooltip"
            [checked]="!!isSelected"
            [indeterminate]="isSelected === undefined"
            (customChange)="selectRow()"
            [hideLabel]="true"
          />
        </div>
      }

      <span
        class="ag-cell-value w-full"
        role="presentation"
        [style.paddingLeft.px]="paddingLeft"
        [auxTooltip]="tooltip"
      >
        <span class="ag-cell-wrapper w-full">
          @if (isGroup) {
            @if (params.node.expanded) {
              <span class="ag-group-expanded" (click)="onClick()">
                <span class="ag-icon ag-icon-tree-open" role="presentation"></span>
              </span>
            } @else {
              <span class="ag-group-contracted" (click)="onClick()">
                <span class="ag-icon ag-icon-tree-closed" role="presentation"></span>
              </span>
            }
          }

          <span class="ag-group-value" [ngClass]="{ 'ml-[11px]': !isGroup }">
            {{ params.value }}
          </span>
        </span>
      </span>
    </div>
  `,
  standalone: true,
  styles: [
    `
      :host {
        @apply block h-full w-full;
      }
    `,
  ],
  imports: [TooltipDirective, NgClass, CheckboxComponent],
})
export class AgBeCheckboxGroupRendererComponent implements ICellRendererAngularComp {
  params!: ICheckboxCellRendererParams<Record<string, unknown>>;
  paddingLeft!: number;
  isGroup!: boolean;
  pinned = false;
  tooltip = '';
  checkboxTooltip = '';
  isSelected: boolean | undefined = false;
  selectable = false;

  agInit(params: ICheckboxCellRendererParams<Record<string, unknown>>): void {
    this.params = params;
    this.paddingLeft = params.node.level * 10;
    this.isGroup = !!params.node.group;
    this.pinned = params.node.isRowPinned();
    const tooltipDefault = this.isGroup ? params.value : '';
    this.tooltip =
      params.data && params.tooltipField ? params.data[params.tooltipField] : tooltipDefault;
    this.isSelected = params.node.isSelected();
    this.selectable = params.node.selectable;
    this.checkboxTooltip = params.checkboxTooltip(params);

    this.params.node.addEventListener('selectableChanged', this.onSelectableChanged);
    this.params.node.addEventListener('rowSelected', this.onRowSelected);
  }

  onSelectableChanged = () => {
    this.selectable = this.params.node.selectable;
  };

  onRowSelected = () => {
    this.isSelected = this.params.node.isSelected();
  };

  refresh() {
    return false;
  }

  destroy() {
    this.params.node.removeEventListener('rowSelected', this.onRowSelected);
    this.params.node.removeEventListener('selectableChanged', this.onSelectableChanged);
  }

  onClick() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  selectRow() {
    if (this.params.node.selectable) {
      this.params.node.setSelected(!this.params.node.isSelected());
      this.isSelected = this.params.node.isSelected();
    }
  }
}
