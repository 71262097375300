import { Component, input, isSignal, output, Signal, WritableSignal } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AuxIconName } from '@shared/components/icon/icon';
import { NgClass } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

export enum SuggestionIconColor {
  GREEN = '#24a148',
  BLUE = '#045a9e',
  LIGHT_BLUE = '#009de2',
  PURPLE = '#5359fa',
}

export interface SuggestionData {
  name: string;
  iconName?: string;
  iconColor?: SuggestionIconColor;
  action?: () => void | Promise<void>;
  tooltip?: string | Signal<string>;
  disabled?: boolean | Signal<boolean>;
  hidden?: boolean | Signal<boolean>;
  pendoTag?: string;
}

@Component({
  selector: 'aux-suggestion',
  template: `
    <div
      class="flex items-center shadow-md border border-aux-gray-dark rounded-[8px] h-[48px] p-[12px]"
      [ngClass]="{
        'hover:border-aux-blue': !itemDisabled,
        'hover:bg-aux-blue-lightHover': !itemDisabled,
        'hover:shadow-lg': !itemDisabled,
        'cursor-pointer': !itemDisabled,
        'opacity-70': itemDisabled,
        'cursor-not-allowed': itemDisabled
      }"
      [auxTooltip]="itemTooltip"
      [attr.data-pendo-id]="data()?.pendoTag"
      (click)="!itemDisabled && onAction.emit()"
    >
      @if (data()?.iconName) {
        <aux-icon [name]="iconName" class="mr-2" [style.color]="data()?.iconColor || 'unset'" />
      }
      <div class="font-semibold">{{ data()?.name }}</div>
    </div>
  `,
  standalone: true,
  imports: [IconComponent, NgClass, TooltipDirective],
})
export class SuggestionComponent {
  data = input<SuggestionData>();

  onAction = output();

  get itemDisabled(): boolean {
    if (isSignal(this.data()?.disabled)) {
      return (this.data()?.disabled as WritableSignal<boolean>)();
    }

    return !!this.data()?.disabled;
  }

  get itemTooltip(): string {
    if (isSignal(this.data()?.tooltip)) {
      return (this.data()?.tooltip as WritableSignal<string>)();
    }

    return (this.data()?.tooltip as string) || '';
  }

  get iconName(): AuxIconName {
    return this.data()?.iconName as AuxIconName;
  }

  get iconColor(): SuggestionIconColor {
    return this.data()?.iconColor || SuggestionIconColor.BLUE;
  }
}
