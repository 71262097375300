import { ChangeDetectorRef, DestroyRef, Directive, inject, OnInit } from '@angular/core';
import { SortOrder } from '@shared/services/gql.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TrialInsightsLegendOptions } from '../models/trial-insights-legend.model';
import {
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
} from '../models/trial-insights-table.model';
import { ChartConfiguration } from 'chart.js';
import { TrialInsightsChartModel } from '../models/trial-insights-chart.model';
import { GenericTrialInsightsQuery } from './trial-insights-query.class';
import { ChartType } from 'chart.js/dist/types';

export interface BaseConfig {
  title: string;
  color: string;
  exceedMessage?: string;
}

export interface ComponentConfig<CType extends ChartType, LabelType> extends BaseConfig {
  chartService: TrialInsightsChartModel<CType, LabelType>;
  tableService: TrialInsightsTableModel;
  queryService: GenericTrialInsightsQuery<CType>;
  cdr: ChangeDetectorRef;
}

@Directive()
export class GenericTrialInsightsComponent<CType extends ChartType = ChartType, LabelType = unknown>
  implements OnInit
{
  protected readonly destroyRef = inject(DestroyRef);

  chartService: TrialInsightsChartModel<CType, LabelType>;

  tableService: TrialInsightsTableModel;

  queryService: GenericTrialInsightsQuery<CType>;

  cdr: ChangeDetectorRef;

  title = '';

  color = '';

  total = '';

  isLoading = false;

  isLoadingRemaining = false;

  expectedEnrolled = '';

  expectedEnrolledExceeded = false;

  exceedMessage = '';

  selectedKey: string;

  selectedKey$: BehaviorSubject<string>;

  sortOrder: SortOrder;

  sortOrder$: BehaviorSubject<SortOrder>;

  chartOptions: ChartConfiguration<CType>;

  legendOptions: TrialInsightsLegendOptions;

  tableOptions: TrialInsightsTableOptions;

  constructor(config: ComponentConfig<CType, LabelType>) {
    this.chartService = config.chartService;
    this.tableService = config.tableService;
    this.queryService = config.queryService;
    this.cdr = config.cdr;

    this.title = config.title;
    this.color = config.color;
    this.exceedMessage = config.exceedMessage || '';

    this.tableOptions =
      (config.tableService.createTable && config.tableService.createTable()) ||
      ({} as unknown as TrialInsightsTableOptions);
    this.chartOptions =
      (config.chartService.createChart && config.chartService.createChart()) ||
      ({} as unknown as ChartConfiguration<CType>);
    this.legendOptions =
      (config.chartService.createLegend && config.chartService.createLegend()) ||
      ({} as unknown as TrialInsightsLegendOptions);

    this.selectedKey$ = config.queryService.selectedKey;
    this.sortOrder$ = config.queryService.sortOrder;

    this.selectedKey = this.selectedKey$.getValue();
    this.sortOrder = this.sortOrder$.getValue();
  }

  ngOnInit(): void {
    this.subscribeToOptions();
    this.subscribeToLoadingStates();
    this.subscribeToFilters();

    if (this.subscribeToData) {
      this.subscribeToData();
    }
  }

  subscribeToOptions = () => {
    combineLatest([
      this.queryService.chartOptions,
      this.queryService.legendOptions,
      this.queryService.tableOptions,
      this.queryService.totalAmount,
      this.queryService.expectedEnrolled,
      this.queryService.expectedEnrolledExceeded,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          chartOptions,
          legendOptions,
          tableOptions,
          totalAmount,
          expectedEnrolled,
          expectedEnrolledExceeded,
        ]) => {
          this.chartOptions = chartOptions;
          this.legendOptions = legendOptions;
          this.tableOptions = tableOptions;
          this.total = totalAmount;
          this.expectedEnrolled = expectedEnrolled;
          this.expectedEnrolledExceeded = expectedEnrolledExceeded;

          this.cdr.markForCheck();
        }
      );
  };

  subscribeToLoadingStates = () => {
    combineLatest([this.queryService.isLoading, this.queryService.isLoadingRemaining])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isLoading, isLoadingRemaining]) => {
        this.isLoading = isLoading || false;
        this.isLoadingRemaining = isLoadingRemaining || false;

        this.cdr.markForCheck();
      });
  };

  subscribeToFilters = () => {
    combineLatest([this.selectedKey$, this.sortOrder$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([selectedKey, sortOrder]) => {
        this.selectedKey = selectedKey;
        this.sortOrder = sortOrder;

        this.cdr.markForCheck();
      });
  };

  subscribeToData?: () => void;
}
