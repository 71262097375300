import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface IAgCheckboxParams extends ICellRendererParams {
  getDisabledState?: (params: IAgCheckboxParams) => boolean;
  dontSelectRow?: boolean;
  isHidden?: boolean;
  hideLabel?: boolean;
  tooltip?: string;
  tooltipFN?: (params: IAgCheckboxParams) => string;
}

@Component({
  template: `
    @if (!params.isHidden) {
      <div class="flex items-center justify-center" style="height: 30px">
        <aux-checkbox
          [disabled]="(disabled$ | async) === true"
          [indeterminate]="indeterminate"
          [(ngModel)]="val"
          (ngModelChange)="onChange($event)"
          [auxTooltip]="(disabled$ | async) === true ? tooltip : ''"
          [hideLabel]="params.hideLabel"
        />
      </div>
    }
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CheckboxComponent, FormsModule, TooltipDirective, AsyncPipe],
})
export class AgCheckboxRendererComponent implements ICellRendererAngularComp {
  val!: boolean;

  params!: IAgCheckboxParams;

  disabled$ = new BehaviorSubject(false);

  indeterminate = false;

  tooltip = '';

  refresh(): boolean {
    return false;
  }

  agInit(params: IAgCheckboxParams) {
    this.params = params;
    this.val = params.value;

    if (params.tooltip) {
      this.tooltip = params.tooltip;
    }

    if (params.tooltipFN) {
      this.tooltip = params.tooltipFN(params);
    }

    if (params.value === 'indeterminate') {
      this.indeterminate = true;
      this.val = false;
    }

    if (params.getDisabledState) {
      this.disabled$.next(params.getDisabledState(this.params));
    }
  }

  onChange(e: boolean) {
    this.indeterminate = false;
    if (this.params.colDef?.field) {
      this.params.node.setDataValue(this.params.colDef.field, e);
    }

    if (!this.params.dontSelectRow) {
      this.params.node.setSelected(e);
    }
  }
}
