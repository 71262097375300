<ng-select
  #select
  class="w-full ag-prepaid-type-dropdown"
  [formControl]="formControl"
  [items]="options"
  [clearable]="false"
  bindLabel="label"
  bindValue="value"
  appendTo="body"
  (change)="onChange()"
>
  <ng-template ng-option-tmp let-item="item" let-index="item">
    <div class="text-sm font-bold leading-5" [auxTooltip]="tooltip(item)">
      {{ item.label }}
    </div>
  </ng-template>
</ng-select>
