import { computed, Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { WorkflowStep } from '@shared/services/gql.service';
import { WorkflowState, WorkflowStore } from './workflow.store';
import { QuarterCloseChecklistRowSections } from '@pages/closing-page/tabs/quarter-close-checklist/models/quarter-close-checklist.model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable({ providedIn: 'root' })
export class WorkflowQuery extends QueryEntity<WorkflowState> {
  isQuarterCloseEnabled = this.launchDarklyService.$select(
    (flags) => flags.client_preference_quarter_close_workflow_enabled
  );

  isWorkflowAvailable = computed(() => {
    return this.isQuarterCloseEnabled();
  });

  isQuarterCloseEnabled$ = toObservable(this.isQuarterCloseEnabled);

  isWorkflowAvailable$ = toObservable(this.isWorkflowAvailable);

  invoiceLockTooltip = computed(() => {
    const isInvoiceFinalized = this.getLockStatusByWorkflowStepType(
      WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
    )();
    return isInvoiceFinalized ? 'Invoices are Locked for Period Close' : '';
  });

  prepaidLockTooltip = computed(() => {
    const isInvoiceFinalized = this.getLockStatusByWorkflowStepType(
      WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
    )();
    return isInvoiceFinalized
      ? 'Locked for Period Close. Navigate to Checklist and unlock in order to make changes.'
      : '';
  });

  selectWorkflowList = toSignal(this.selectAll(), { requireSync: true });

  constructor(
    protected store: WorkflowStore,
    private launchDarklyService: LaunchDarklyService
  ) {
    super(store);
  }

  getWorkflowByStepType(stepType: string) {
    return computed(() => {
      const list = this.selectWorkflowList().filter((workFlow) => workFlow.step_type === stepType);
      return list.length ? list[0] : null;
    });
  }

  /**
   * @deprecated use `getWorkflowByStepType` instead
   */
  getWorkflowByStepType$(stepType: string) {
    return this.selectAll({
      filterBy: [(workFlow) => workFlow.step_type === stepType],
    }).pipe(map((workflowList) => (workflowList.length ? workflowList[0] : null)));
  }

  getLockStatusByWorkflowStepType(stepType: string) {
    return computed(() => {
      const isWorkflowPanelAvailable = this.isWorkflowAvailable();
      if (!isWorkflowPanelAvailable) {
        return false;
      }
      const workflow = this.getWorkflowByStepType(stepType)();

      return workflow ? workflow.properties.locked : false;
    });
  }

  /**
   * @deprecated use `getLockStatusByWorkflowStepType` instead
   */
  getLockStatusByWorkflowStepType$(stepType: string): Observable<boolean> {
    return combineLatest([this.isWorkflowAvailable$, this.getWorkflowByStepType$(stepType)]).pipe(
      map(([isWorkflowPanelAvailable, workflow]) => {
        if (!isWorkflowPanelAvailable) {
          return false;
        }

        return workflow ? workflow.properties.locked : false;
      })
    );
  }

  // todo convert to signal later
  getInMonthAdjustmentAvailability$() {
    return this.selectAll().pipe(
      map((workflowList) => {
        return workflowList
          .filter(
            ({ step_type }) => step_type !== WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
          )
          .every(({ properties: { locked } }) => locked);
      })
    );
  }

  // todo convert to signal later
  getMonthCloseButtonAvailability$() {
    return combineLatest([
      this.isQuarterCloseEnabled$,
      this.isWorkflowAvailable$,
      this.selectAll(),
    ]).pipe(
      map(([isQuarterCloseEnabled, isWorkflowAvailable, workflowList]) => {
        if (!isQuarterCloseEnabled) {
          return true;
        }
        return isWorkflowAvailable
          ? workflowList
              .filter(
                (workflow) => workflow.section !== QuarterCloseChecklistRowSections['Close Month']
              )
              .every(({ properties: { locked } }) => locked)
          : isWorkflowAvailable;
      })
    );
  }
}
