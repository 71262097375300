<form [formGroup]="addTimelineMilestoneForm" (ngSubmit)="onSubmit()">
  <aux-modal primaryButtonType="submit">
    <div class="w-screen max-w-3xl"></div>
    <div class="grid grid-cols-2 gap-5">
      <div>
        <div class="text-xs">Category <span class="text-aux-error font-bold">*</span></div>
        <ng-select
          class="select select__big"
          formControlName="milestone_category_id"
          auxFormError
          label="Category"
          [clearable]="false"
          [searchable]="false"
        >
          <ng-option
            *ngFor="let milestoneCategory of milestoneCategoryQuery.selectAll() | async"
            [value]="milestoneCategory.id"
          >
            {{ milestoneCategory.name }}
          </ng-option>
        </ng-select>
      </div>

      <aux-input
        #name
        label="Milestone Name"
        formControlName="name"
        validators="required"
        [showRequiredAsterisk]="true"
      />

      <div class="flex flex-col">
        <aux-input
          label="Start Date"
          formControlName="contract_start_date"
          placeholder="YYYY-MM-DD"
          [type]="'date'"
          [showRequiredAsterisk]="true"
          [validators]="startDateValidators"
          [customErrorMessages]="customErrorMessages"
        />
      </div>

      <div>
        <aux-input
          label="End Date"
          formControlName="contract_end_date"
          placeholder="YYYY-MM-DD"
          [type]="'date'"
          [showRequiredAsterisk]="true"
          [validators]="endDateValidators"
          [customErrorMessages]="customErrorMessages"
        />
      </div>

      <div>
        <div class="text-xs">Track From</div>
        <ng-select
          class="select select__big"
          formControlName="track_from_milestone_id"
          auxFormError
          label="Track From"
          [clearable]="true"
          [searchable]="true"
          [appendTo]="'body'"
        >
          <ng-option *ngFor="let milestone of trackFromMilestones$ | async" [value]="milestone.id">
            {{ milestone.name }}
          </ng-option>
        </ng-select>
      </div>

      <aux-input label="Description" formControlName="description" />
    </div>
  </aux-modal>
</form>
