import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { VendorWorkflowDirective } from '@pages/vendor-payments-page/directives/vendor-workflow.directive';

export type VendorGridOverlayNoRowCellParams = INoRowsOverlayParams & {
  onAddMultipleVendorsFN: undefined | (() => void);
  onNewVendorFN: undefined | (() => void);
};

@Component({
  selector: 'aux-vendor-grid-overlay-no-row-cell',
  standalone: true,
  templateUrl: './vendor-grid-overlay-no-row-cell.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  imports: [TooltipDirective, ButtonComponent, AsyncPipe],
  hostDirectives: [
    {
      directive: VendorWorkflowDirective,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorGridOverlayNoRowCellComponent implements INoRowsOverlayAngularComp {
  public params!: VendorGridOverlayNoRowCellParams;

  ctx = inject(VendorWorkflowDirective);

  refresh(): boolean {
    return false;
  }

  agInit(params: VendorGridOverlayNoRowCellParams): void {
    this.params = params;
  }

  onNewVendor(params: VendorGridOverlayNoRowCellParams) {
    if (params.onNewVendorFN) {
      params.onNewVendorFN();
    }
  }

  onAddMultipleVendors(params: VendorGridOverlayNoRowCellParams) {
    if (params.onAddMultipleVendorsFN) {
      params.onAddMultipleVendorsFN();
    }
  }
}
