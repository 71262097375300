import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartConfiguration } from 'chart.js';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
export type CanvasComponentOptions = ChartConfiguration<'line'> & {
  legend: {
    bottom: boolean;
    right: boolean;
    left: boolean;
    top: boolean;
  };
};

interface Color {
  id: number;
  color?: string;
  hide: boolean;
  type: string;
  label: string;
}

@Component({
  selector: 'aux-canvas-chart',
  templateUrl: './canvas-chart.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, NgFor, AsyncPipe, NgChartsModule],
})
export class CanvasChartComponent {
  @Input() set chartOptions(data: CanvasComponentOptions) {
    this.colors = [];
    data?.data.datasets?.forEach((x, index) => {
      if (x.borderColor) {
        this.colors.push({
          color: x.borderColor?.toString() || '',
          hide: false,
          type: x.type || '',
          label: x.label || '',
          id: index,
        });
      }
    });

    this.colors = this.colors.sort((x, y) => {
      return x.type === 'bar' && y.type === 'line'
        ? -1
        : y.type === 'bar' && x.type === 'line'
          ? 1
          : 0;
    });
    this.defaultChartOptions$.next({ ...data });
  }

  colors: Color[] = [];

  defaultChartOptions$ = new BehaviorSubject<CanvasComponentOptions | null>(null);

  legendAction(color: Color) {
    this.colors = this.colors.map((x) => {
      if (x.color === color.color) {
        return { ...x, hide: !color.hide };
      }
      return x;
    });

    const options = this.defaultChartOptions$.getValue();
    if (!options) {
      return;
    }

    const data = options.data.datasets.map((x, index) => {
      if (index === color.id) {
        return { ...x, hidden: !color.hide };
      }
      return x;
    });

    this.defaultChartOptions$.next({
      ...options,
      data: {
        ...options.data,
        datasets: data,
      },
    });
  }
}
