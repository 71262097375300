import { inject, Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import { GqlService, listBudgetAttributesQuery } from '@shared/services/gql.service';
import { findIndex } from 'lodash';
import { GridApi } from '@ag-grid-community/core';

export interface ExistingAttribute {
  id: string;
  items: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ExistingAttributesService {
  private overlayService = inject(OverlayService);

  private gqlService = inject(GqlService);

  data = signal<listBudgetAttributesQuery[] | null>(null);

  existingAttributes = signal<ExistingAttribute[]>([]);

  initAttributes = (gridApi: GridApi): void => {
    const attributes = (this.data() || []).map((budgetAttribute) => ({
      id:
        gridApi
          .getAllDisplayedColumns()
          .find((col) => col.getColDef().headerName === budgetAttribute.attribute_name)
          ?.getColDef().field || '',
      items: budgetAttribute.attribute_values || [],
    }));
    this.existingAttributes.set(attributes);
  };

  async fetchAttributes(): Promise<void> {
    const { data, success, errors } = await firstValueFrom(this.gqlService.listBudgetAttributes$());

    if (data && success) {
      this.data.set(data);
    } else {
      this.overlayService.error(errors);
    }
  }

  updateList(id: string, items: string[]): void {
    const updated = this.existingAttributes();
    const index = findIndex(this.existingAttributes(), (attr) => attr.id === id);
    updated.splice(index, 1, {
      ...this.existingAttributes()[index],
      items,
    });
    this.existingAttributes.set([...updated]);
  }
}
