import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { GridApi } from '@ag-grid-community/core';
import { SitesService } from '@models/sites/sites.service';
import { MainQuery } from '@shared/store/main/main.query';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { PaymentSchedulesService } from '@models/payment-schedules/payment-schedules.service';
import {
  EntityType,
  EventType,
  GqlService,
  PatientGroupType,
  PatientProtocolType,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { isArray, toArray } from 'lodash-es';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { SitesStore } from '@models/sites/sites.store';
import { SitesQuery } from '@models/sites/sites.query';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';

import { OverlayService } from '@shared/services/overlay.service';
import dayjs from 'dayjs';
import { PatientTableType } from './patient-budget-table/patient-budget-table.component';
import { PatientGroupsQuery } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { PatientGroupsModel } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.model';
import { ExportType } from '@shared/utils/utils';
import { ButtonToggleItem } from '@shared/components/button-toggle-group/button-toggle-item.model';
import { Option } from '@shared/types/components.type';
import { FormGroup } from '@angular/forms';
import { ImportSiteCostDataModelComponent } from './import-site-cost-data-model/import-site-cost-data-model.component';
import { EventService } from '@models/event/event.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { EventQuery } from '@models/event/event.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-patient-protocol',
  templateUrl: './patient-protocol.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProtocolComponent implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  eventQuery = inject(EventQuery);
  patientTableTypes = PatientTableType;

  gridAPI!: GridApi;

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  userHasLockInvestigatorDataPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
  });

  patientGroups: PatientGroupsModel[] = [];

  patientGroupOptions: ButtonToggleItem[] = [];

  patientGroupsLoading$ = new BehaviorSubject(false);

  hideGrid$ = new BehaviorSubject(false);

  importSiteCostFF$ = new BehaviorSubject(false);

  visitCostsFF$ = new BehaviorSubject(false);

  patientSchedulesLoading$ = this.paymentSchedulesQuery.selectLoading();

  isProtocolVersionLoading$ = new BehaviorSubject(true);

  protocolVersionOptions: Option[] = [];

  protocolForm!: FormGroup;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientTrackerLockedTooltip = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  userHasUpdateSiteCostsPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_SITE_COSTS],
  });

  loading$ = combineLatest([
    this.patientGroupsLoading$,
    this.patientSchedulesLoading$,
    this.sitesQuery.selectLoading(),
    this.hideGrid$,
  ]).pipe(map((arr) => arr.some((bool) => bool)));

  constructor(
    private patientProtocolService: PatientProtocolService,
    private paymentSchedulesService: PaymentSchedulesService,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery,
    private patientProtocolQuery: PatientProtocolQuery,
    private sitesStore: SitesStore,
    private patientGroupsQuery: PatientGroupsQuery,
    private patientGroupsService: PatientGroupsService,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private eventService: EventService,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery
  ) {
    this.sitesStore.setActive([]);

    this.launchDarklyService.flags$.pipe(takeUntilDestroyed()).subscribe((flags) => {
      this.importSiteCostFF$.next(flags.import_site_cost_data);
      this.visitCostsFF$.next(flags.visit_costs);
    });

    combineLatest([
      this.mainQuery
        .select('trialKey')
        .pipe(switchMap(() => this.patientProtocolService.getPatientProtocolVersions()))
        .pipe(tap(() => this.isProtocolVersionLoading$.next(true))),
      this.mainQuery.select('trialKey').pipe(tap(() => this.patientGroupsLoading$.next(true))),
      this.patientGroupsService.get([PatientGroupType.PATIENT_GROUP_STANDARD]),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([versions]) => {
        setTimeout(() => {
          this.isProtocolVersionLoading$.next(false);
          this.protocolVersionOptions = versions.map(({ id, name }) => ({
            label: name,
            value: id,
          }));

          this.patientGroupsLoading$.next(false);
          this.patientGroupOptions = [];
          this.patientGroups = [];
          this.patientGroupsQuery
            .getAll()
            .forEach((patientGroup) => this.patientGroups.push(patientGroup));
          this.patientGroupOptions.push(...this.getProtocolGroupOptions(this.patientGroups));

          this.setDefaultProtocolFormValues();
        }, 0);
      });
  }

  ngOnDestroy() {
    this.sitesStore.setActive([]);
    this.paymentSchedulesService.cache.clear();
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  onProtocolFormReady(form: FormGroup) {
    this.protocolForm = form;

    this.subscribeToSitePaymentSchedules();

    this.setDefaultProtocolFormValues();
  }

  getProtocolGroupOptions(patientGroups: PatientGroupsModel[]) {
    const options: { label: string; show: Observable<boolean>; value: string }[] = [];

    patientGroups.forEach((group) => {
      options.push({ label: group.name, show: of(true), value: group.id });
    });

    if (this.protocolVersionOptions.length) {
      options.push(
        {
          label: 'Visit Costs',
          show: this.launchDarklyService.select$((flags) => flags.visit_costs),
          value: 'visits_costs',
        },
        { label: 'Invoiceables', show: of(true), value: 'invoiceables' }
      );
    }

    return options;
  }

  handleGridApi(gridApi: GridApi) {
    this.gridAPI = gridApi;
  }

  onImportSiteCostData(): void {
    this.overlayService.openPopup<
      {
        protocolVersionOptions: Option<string>[];
        patientGroupOptions: ButtonToggleItem[];
        visitCostsFF$: BehaviorSubject<boolean>;
      },
      unknown,
      ImportSiteCostDataModelComponent
    >({
      content: ImportSiteCostDataModelComponent,
      settings: {
        header: 'Upload Site Cost Data',
        primaryButton: {
          label: 'Upload',
          action: (instance) => instance?.onUpload(),
        },
      },
      data: {
        protocolVersionOptions: this.protocolVersionOptions,
        patientGroupOptions: this.patientGroupOptions,
        visitCostsFF$: this.visitCostsFF$,
      },
    });
  }

  onExportPatientBudget = async () => {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.PATIENT_BUDGET,
          filename: `${trialName}_Patient Budget_${dateStr}`,
          export_entity_id: this.protocolForm.controls.protocolVersion.value,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  };

  private subscribeToSitePaymentSchedules() {
    combineLatest([
      this.mainQuery.select('trialKey'),
      this.sitesService.get().pipe(
        tap(() => {
          const sites = this.sitesQuery.getAll();
          this.sitesStore.setActive(sites.length ? [sites[0].id] : []);
        })
      ),
      this.protocolForm.controls.protocolVersion.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((protocolVersion) =>
          this.patientProtocolService.getForPatientBudgetTable(
            protocolVersion,
            toArray(PatientProtocolType)
          )
        )
      ),
      combineLatest([
        this.protocolForm.valueChanges,
        combineLatest([
          this.protocolForm.valueChanges,
          this.sitesQuery.selectActive(),
          this.eventService.select$(EventType.REFRESH_PATIENT_BUDGET).pipe(startWith(null)),
          this.patientProtocolQuery.selectAll(),
        ]).pipe(
          debounceTime(200),
          switchMap(([{ patientGroup, protocolVersion }, site_ids, isRefresh]) => {
            if (!patientGroup || !protocolVersion || !isArray(site_ids)) {
              return of([]);
            }
            this.hideGrid$.next(true);
            return this.paymentSchedulesService.get(
              [
                PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
                PatientProtocolType.PATIENT_PROTOCOL_OTHER,
                PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
                PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
                PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
              ],
              {
                patient_group_id: patientGroup,
                site_ids: site_ids.map((site) => site.id),
                patient_protocol_version_id: protocolVersion,
              },
              isRefresh?.trial_id
            );
          })
        ),
      ]),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        setTimeout(() => {
          this.hideGrid$.next(false);
        }, 0);
      });
  }

  private setDefaultProtocolFormValues() {
    if (this.protocolForm) {
      this.protocolForm.setValue({
        patientGroup: this.patientGroupOptions[0]?.value || null,
        protocolVersion: this.protocolVersionOptions[0]?.value || null,
      });
    }
  }
}
