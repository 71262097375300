<ng-select
  [formControlName]="controlName()"
  [label]="name()"
  bindValue="value"
  bindLabel="name"
  placeholder="Select"
  auxFormError
  [customErrorMessages]="{ siteCurveBeforePeriod: 'Period must be selected before Site Curve.' }"
  class="patient-curves"
  (blur)="onBlur()"
>
  @for (item of patientOptions(); track item.value; let first = $first) {
    <ng-option [value]="item.value">
      @if (item.showLine && !first) {
        <div class="separator relative mt-[3px]"></div>
      }
      {{ item.name }}
    </ng-option>
  }
</ng-select>
