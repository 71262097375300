import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';

interface CustomTooltipParams extends ITooltipParams {
  notShowTooltipForEditMode: boolean;
}

@Component({
  standalone: true,
  template: `
    @if (!ignoreTooltip) {
      <aux-tooltip [text]="params.value || ''" />
    }
  `,
  imports: [TooltipComponent],
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        max-width: 400px;
      }
      :host ::ng-deep div {
        word-break: break-word;
      }
    `,
  ],
})
export class AgTooltipComponent implements ITooltipAngularComp {
  params!: ITooltipParams | CustomTooltipParams;

  ignoreTooltip!: boolean;

  agInit(params: ITooltipParams): void {
    this.params = params;
    const rowIndex = this.params.node?.rowIndex;
    const columtId = this.params.column?.getUniqueId();

    this.ignoreTooltip =
      (this.params as CustomTooltipParams)?.notShowTooltipForEditMode &&
      this.params.api
        .getEditingCells()
        .some((cellDef) => rowIndex === cellDef.rowIndex && columtId === cellDef.column.getId());
  }
}
