<div>
  <div class="pb-1 border-b border-gray-200">
    <div class="flex space-x-[20px] items-center h-12">
      <div class="w-[175px]">
        <div class="font-semibold text-sm" style="color: #676e7a">PATIENT/EDC DATA</div>
      </div>
      @if ((loading$ | async) === true) {
        <div class="flex-grow justify-start">
          <div class="h-5 w-5">
            <div class="border-1 h-5 m-auto spinner w-5"></div>
          </div>
        </div>
      } @else {
        <div
          class="flex-grow justify-start"
          *ngIf="statusItem$ | async as statusItem"
          [ngClass]="{
            'flex flex-row space-x-[28px] items-center': isReviewInvestigatorDataLinkOpen()
          }"
        >
          <div
            class="border px-2 py-1 rounded text-sm w-max"
            [ngStyle]="{
              'background-color': statusItem.bgColor,
              'border-color': statusItem.borderColor,
              color: statusItem.color
            }"
          >
            {{ statusItem.text }}
          </div>
          @if (isReviewInvestigatorDataLinkOpen()) {
            <span class="aux-link cursor-pointer" (click)="onReviewInvestigatorData()"
              >Review Investigator Data</span
            >
          }
        </div>
      }
      <div
        class="w-28 flex flex-col items-start text-sm"
        *ngIf="edcUploadDetails$ | async as details"
      >
        <div class="mb-1">Date Uploaded</div>
        <div>{{ dateFormatter(details.date_uploaded) }}</div>
      </div>
      <div
        class="w-28 flex flex-col items-start text-sm"
        *ngIf="edcUploadDetails$ | async as details"
      >
        <span class="mb-1">Uploaded by</span>
        <div>
          <aux-first-name-show
            [text]="
              userFormatter(details.uploaded_by).length > 30
                ? userFormatter(details.uploaded_by).substring(0, 30)
                : userFormatter(details.uploaded_by)
            "
            [showHyphenIfEmpty]="true"
          />
        </div>
      </div>
      <div class="justify-start w-[345px]">
        <span
          [ngClass]="{
            'opacity-50 cursor-not-allowed': isWorkflowLocked() || monthStatus !== 'open',
            'cursor-pointer': !isWorkflowLocked() && monthStatus === 'open'
          }"
          class="btn--hyperlink"
          (click)="!isWorkflowLocked() && monthStatus === 'open' && openUploadEDCModal()"
          [auxTooltip]="getDisabledTooltip()"
          >Upload EDC Data</span
        >
      </div>
    </div>
  </div>

  <div class="flex space-x-[20px] items-center border-b border-gray-200 h-12">
    <div class="w-[175px] shrink-0">
      <div class="font-semibold text-sm" style="color: #676e7a">PROTOCOL</div>
    </div>
    <div class="flex-grow justify-start">
      <span class="text-aux-gray-dark-100 text-sm italic">Are there any protocol amendments?</span>
    </div>
    <div class="w-56"></div>
    <div class="justify-start w-[345px] shrink-0">
      <span class="btn--hyperlink cursor-pointer" (click)="onProtocolAmendments()"
        >View Protocol</span
      >
    </div>
  </div>

  <div class="flex space-x-[20px] items-center h-12">
    <div class="w-[175px] shrink-0">
      <div class="font-semibold text-sm" style="color: #676e7a">SITES</div>
    </div>
    <div class="flex-grow justify-start">
      <span class="text-aux-gray-dark-100 text-sm italic"
        >Have you reviewed CTAs, site activations, and site closeouts?</span
      >
    </div>
    <div class="w-56"></div>
    <div class="w-[345px] justify-start shrink-0">
      <span class="btn--hyperlink cursor-pointer" (click)="onSites()">View Sites</span>
    </div>
  </div>
</div>
