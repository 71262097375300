import { DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventType, GqlService } from '@shared/services/gql.service';
import { timer, filter, switchMap } from 'rxjs';

export const fetchInProgressEvents = (eventType: EventType, destroyRef: DestroyRef) => {
  const alreadyReportedTrackers = new Set<string>();
  const gqlService = inject(GqlService);
  const progressTrackerId = signal<string | null>(null);

  timer(0, 5000)
    .pipe(
      filter(() => !progressTrackerId()),
      switchMap(() =>
        gqlService.listEventsInProgress$({
          event_type: eventType,
        })
      ),
      takeUntilDestroyed(destroyRef)
    )
    .subscribe((queryResult) => {
      if (queryResult.success && queryResult.data?.length) {
        const { progress_tracker_id } = queryResult.data[0];
        // reporting the detected tracker conditionally here in case something goes wrong on the server-side
        if (!alreadyReportedTrackers.has(progress_tracker_id)) {
          alreadyReportedTrackers.add(progress_tracker_id);
          progressTrackerId.set(progress_tracker_id);
        }
      }
    });

  return progressTrackerId;
};
