<div class="flex justify-between items-start my-5">
  <div class="flex space-x-4">
    <aux-card
      topText="Patient Visits LTD"
      [middleText]="totalPatientVisitsLTD() | money"
      className="min-w-[230px]"
    />

    @if (isPlannedVisitsEnabled()) {
      <aux-card
        [middleText]="plannedThroughCurrentMonth() | money"
        className=" min-w-[230px]"
        [bottomTemplate]="showPlannedVisits"
        [ngClass]="{ 'bg-aux-green-2': showPlannedVisitsControl.value }"
      >
        <div topText class="flex justify-between items-center">
          <div class="text-sm text-aux-gray-dark-100">
            {{ 'Planned through ' + $any(currentOpenMonth$ | async | date: 'MMMM') }}
          </div>
          <aux-icon name="AuxLogo" />
        </div>
      </aux-card>
    }

    <ng-template #showPlannedVisits>
      <aux-checkbox
        class="mr-3"
        [formControl]="showPlannedVisitsControl"
        [ngClass]="{ 'selected-card': showPlannedVisitsControl.value }"
      >
        <div class="text-sm">Show planned visits</div>
      </aux-checkbox>
    </ng-template>
  </div>

  <aux-export-excel-button
    [gridAPI]="exportGridApi"
    [ignoreColsId]="['site_id']"
    [getDynamicExcelParamsCallback]="getDynamicExcelParams"
    pendoTag="patient-tracker-excel-export-button"
  />
</div>

<ng-container *ngIf="(isHandlingUpload$ | async) === false && (isLoadingData$ | async) === false">
  @if (!showGrid()) {
    <ng-container>
      <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
        <aux-icon name="FileDescription" />
        <div class="text-xl font-bold">No Patient Data to Review</div>
      </div>
    </ng-container>
  }

  @if (showGrid()) {
    <ng-container>
      <form
        [formGroup]="gridFiltersFormGroup"
        class="px-2 py-4 rounded-xl border border-[#E5E5E5] bg-[#FDFDFE]"
      >
        <div class="mb-4 flex justify-between items-center space-x-8">
          <div class="flex space-x-8">
            <div class="flex items-center">
              <div class="mr-2 text-xs">Site:</div>
              <aux-site-select-dropdown
                class="w-48 2xl:w-72"
                formControlName="siteIds"
                label=" "
                placeholder="Please select site(s)"
              />
            </div>

            <div class="flex items-center">
              <div class="text-xs mr-2">Protocol Version:</div>
              <ng-select
                class="w-48 2xl:w-72"
                placeholder="Select version"
                [searchable]="true"
                bindValue="value"
                [formControl]="protocolVersionControl"
                [items]="protocolVersionOptions$ | async"
              />
            </div>

            <div class="flex items-center">
              <div class="mr-2 text-xs">Patient Group:</div>
              <ng-select
                class="w-48 2xl:w-72"
                placeholder="Select group"
                [searchable]="true"
                bindValue="value"
                formControlName="patientGroupId"
                [items]="patientGroupOptions$ | async"
              >
              </ng-select>
            </div>

            <div class="flex space-x-2 text-sm items-center">
              <div>Dates</div>
              <aux-toggle
                [value]="isDisplayCosts"
                (change)="display$.next($event ? 'costs' : 'dates')"
              />
              <div>Costs</div>
            </div>

            <div class="flex text-sm items-center">
              <aux-toggle-currency
                [value]="isContractedCurrency"
                (nameSelectedCurrency)="selectedVisibleCurrency$.next($event)"
              />
            </div>
          </div>
        </div>
      </form>

      <aux-pagination-grid
        [gridOptions]="$any(gridOptions$ | async)"
        [exportGridOptions]="(exportGridOptions$ | async)!"
        [dataSource]="datasource"
        [filterForm]="gridFiltersFormGroup"
        [serverSideFilters]="serverSideFilters"
        [filterValues$]="filterValues$"
        [sortModel$]="sortModel$"
        [idTable]="'patientTrackerTable'"
        (paginationChange)="onPaginationChange()"
        (gridReady)="onGridReady($event)"
      />
      <a class="btn--hyperlink mb-16 inline-block" [routerLink]="[protocolEntryLink]"
        >View Schedule of Assessments</a
      >
    </ng-container>
  }
</ng-container>
