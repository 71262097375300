import { Workflow, WorkflowStore } from '@shared/store/workflow/workflow.store';
import { Injectable } from '@angular/core';
import { EventType, GqlService } from '@shared/services/gql.service';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { QuarterCloseChecklistWorkflowService } from './quarter-close-checklist-workflow.service';
import { ChecklistSectionGatherContractsComponent } from '../components/checklist-section-gather-contracts/checklist-section-gather-contracts.component';
import { ChecklistSectionGatherEstimatesComponent } from '../components/checklist-section-gather-estimates/checklist-section-gather-estimates.component';
import {
  ChecklistComponent,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowComponents,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistSection,
  QuarterCloseChecklistSectionTitles,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../models/quarter-close-checklist.model';
import { QuarterCloseChecklistPeriodCloseService } from './quarter-close-checklist-period-close.service';
import { ChecklistSectionDiscountComponent } from '../components/checklist-section-discount/checklist-section-discount.component';
import { EventQuery } from '@models/event/event.query';
import { ChecklistSectionGatherPatientsComponent } from '../components/checklist-section-gather-patients/checklist-section-gather-patients.component';

@Injectable()
export class QuarterCloseChecklistService {
  Sections = QuarterCloseChecklistSection;

  SectionTitles = QuarterCloseChecklistSectionTitles;

  Rows = QuarterCloseChecklistRow;

  RowTitles = QuarterCloseChecklistRowTitles;

  RowComponents: QuarterCloseChecklistRowComponents = {
    GatherContracts: new ChecklistComponent(
      ChecklistSectionGatherContractsComponent,
      {},
      'GatherContracts'
    ),
    GatherEstimates: new ChecklistComponent(
      ChecklistSectionGatherEstimatesComponent,
      {},
      'GatherEstimates'
    ),
    CloseDiscounts: new ChecklistComponent(ChecklistSectionDiscountComponent, {}, 'CloseDiscounts'),
    GatherPatients: new ChecklistComponent(
      ChecklistSectionGatherPatientsComponent,
      {},
      'GatherPatients'
    ),
  };

  // cache invalidate refreshes checklist and so discount component is gonna destroyed
  // this is just to store the latest selected vendor. This way discount component can
  // re-select the vendor after refresh.
  discountSelectedVendor = '';

  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private workflowQuery: WorkflowQuery,
    private workflowStore: WorkflowStore,
    private workflowService: QuarterCloseChecklistWorkflowService,
    private originalWorkflowService: WorkflowService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private eventQuery: EventQuery
  ) {}

  isAdminUser(): Observable<boolean> {
    return this.periodCloseService.isAdminUser;
  }

  isCurrentQuarterSelected(): Observable<boolean> {
    return this.periodCloseService.isCurrentQuarterSelected;
  }

  isClosedMonthsProcessing(): Observable<boolean | null> {
    return this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);
  }

  isQuarterCloseEnabled(): Observable<boolean> {
    return this.workflowQuery.isQuarterCloseEnabled$;
  }

  resetWorkflowState() {
    this.workflowStore.remove(() => true);
    this.workflowStore.update({ loading: false });
  }

  workflowAdjustmentAvailable(): Observable<boolean> {
    return this.workflowQuery.getInMonthAdjustmentAvailability$();
  }

  workflowLoading(): Observable<boolean> {
    return this.workflowQuery.selectLoading();
  }

  workflowList(): Observable<Workflow[]> {
    return this.workflowQuery.selectAll();
  }

  vendorEstimateSummaries(
    period: string
  ): Observable<QuarterCloseChecklistVendorEstimateSummary[]> {
    return this.gqlService
      .listVendorEstimateSummaries$(period)
      .pipe(map(this.workflowService.filterVendorEstimates.bind(this)));
  }

  onQuarterMonthChange(selectedQuarterMonth: string): void {
    if (selectedQuarterMonth) {
      const workflowDate = dayjs(selectedQuarterMonth).format('MMM-YYYY').toUpperCase();

      this.originalWorkflowService.getWorkflowList(workflowDate).pipe(first()).subscribe();
    }
  }
}
