import { Injectable } from '@angular/core';
import { EventStatus, GqlService } from '@shared/services/gql.service';
import { firstValueFrom } from 'rxjs';
import { EventStore } from './event.store';
import { OverlayService } from '@shared/services/overlay.service';
import { LocalStorageKey } from '@shared/constants/localStorageKey';

@Injectable({ providedIn: 'root' })
export class EventTrackerService {
  private pollingInterval = 15000;

  constructor(
    private gqlService: GqlService,
    private eventStore: EventStore,
    private overlayService: OverlayService
  ) {}

  private clearFinishedEvent(id: string) {
    setTimeout(() => {
      this.eventStore.remove(id);
    }, 1000);
  }

  private getEventIdsFromLs(): string[] {
    return JSON.parse(localStorage.getItem(LocalStorageKey.EVENT_TRACKER) ?? '[]') as string[];
  }

  private addEventIdToLs(eventId: string) {
    const eventIds = this.getEventIdsFromLs();

    if (!eventIds.includes(eventId)) {
      const newIdList = JSON.stringify([eventId, ...eventIds]);
      localStorage.setItem(LocalStorageKey.EVENT_TRACKER, newIdList);
    }
  }

  private removeEventFromLs(eventId: string) {
    const eventIds = this.getEventIdsFromLs();

    const newIdList = JSON.stringify(eventIds.filter((id) => id !== eventId));
    localStorage.setItem(LocalStorageKey.EVENT_TRACKER, newIdList);
  }

  private async handleEvent(
    eventId: string,
    interval?: NodeJS.Timeout,
    useEventIdAsKey = false,
    silentOnSuccess = false
  ): Promise<{ finishedImmediately: boolean }> {
    const { data, errors } = await firstValueFrom(this.gqlService.getEventTracker$(eventId));

    const eventStatus = data?.event_status;
    const isProcessing =
      eventStatus === EventStatus.EVENT_STATUS_IN_PROGRESS ||
      eventStatus === EventStatus.EVENT_STATUS_PENDING;
    const isComplete = eventStatus === EventStatus.EVENT_STATUS_COMPLETED;
    const isFailed = eventStatus === EventStatus.EVENT_STATUS_FAILED;
    const eventStoreKey = data
      ? `${data.trial_id}-${data.event_type}${useEventIdAsKey ? `-${eventId}` : ''}`
      : null;

    // if event finished immediately
    if (isComplete && data && !this.eventStore.getValue().ids?.includes(eventStoreKey)) {
      this.eventStore.add({
        ...data,
        _id: eventStoreKey as string,
      });

      this.eventStore.setLoading(false);

      if (interval) {
        clearInterval(interval);
      }

      this.removeEventFromLs(eventId);
      this.clearFinishedEvent(eventStoreKey as string);

      return { finishedImmediately: true };
    }

    if (isProcessing && data && !this.eventStore.getValue().ids?.includes(eventStoreKey)) {
      this.eventStore.add({
        ...data,
        _id: eventStoreKey as string,
      });
    }

    if (isComplete && data) {
      this.eventStore.update(eventStoreKey, (entity) => {
        return {
          ...entity,
          _id: eventStoreKey as string,
          event_status: EventStatus.EVENT_STATUS_COMPLETED,
        };
      });
    }

    if (((isComplete || isFailed) && data) || !!errors.length) {
      this.eventStore.setLoading(false);
      this.clearFinishedEvent(eventStoreKey as string);

      if (interval) {
        clearInterval(interval);
      }

      if (isFailed && data?.error_message) {
        this.overlayService.error(data.error_message);
      }

      if (isComplete && data?.success_message && !silentOnSuccess) {
        this.overlayService.success(data.success_message);
      }

      this.removeEventFromLs(eventId);
    }

    // if event is not exist
    if (!data) {
      this.eventStore.setLoading(false);
      this.clearFinishedEvent(eventStoreKey as string);
      this.removeEventFromLs(eventId);
      if (interval) {
        clearInterval(interval);
      }
    }

    return { finishedImmediately: false };
  }

  async trackEvent(eventId: string, useEventIdAsKey = false, silentOnSuccess = false) {
    this.eventStore.setLoading(true);
    const { finishedImmediately } = await this.handleEvent(
      eventId,
      undefined,
      useEventIdAsKey,
      silentOnSuccess
    );

    if (finishedImmediately) {
      return;
    }

    this.addEventIdToLs(eventId);

    const interval = setInterval(() => {
      this.handleEvent(eventId, interval, useEventIdAsKey, silentOnSuccess);
    }, this.pollingInterval);
  }

  trackEventsFromLs() {
    this.getEventIdsFromLs().forEach((eventId) => this.trackEvent(eventId));
  }
}
