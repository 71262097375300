import { Component, computed, input, Input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AuxIconName } from '@shared/components/icon/icon';
import { NgClass } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

export interface AuxButtonGroup {
  name: string;
  iconName?: AuxIconName;
  iconClass?: string;
  onClick: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
  show?: boolean;
}

@Component({
  standalone: true,
  selector: 'aux-button-group',
  template: `
    <button
      class="flex items-center py-[7px] pl-[10px] pr-[8px] leading-7"
      [ngClass]="buttonClass()"
      (click)="dropdownOpened = !dropdownOpened"
      [disabled]="disabled"
      [auxTooltip]="disabled ? disabledTooltip : ''"
    >
      @if (iconName) {
        <aux-icon [name]="iconName!" [ngClass]="{ 'mr-[5px]': !!label }" />
      }
      @if (label) {
        <div>{{ label }}</div>
      }
      @if (showDropdownIcon()) {
        <aux-icon name="ChevronDown" class="ml-[5px]" />
      }
    </button>

    <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin></div>

    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="dropdownOpened"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'random-class'"
      (detach)="dropdownOpened = false"
      (backdropClick)="dropdownOpened = false"
      [cdkConnectedOverlayPositions]="position"
    >
      <div class="bg-white rounded shadow-[0_2px_4px_0_rgba(24,30,60,0.3)] mt-2  min-w-[200px]">
        @for (option of options; track option.name) {
          @if (option.hasOwnProperty('show') ? option.show : true) {
            <div
              (click)="onOptionClick(option)"
              class="flex items-center p-[8px] "
              [ngClass]="{
                'hover:bg-aux-blue-light-50 cursor-pointer': option.disabled !== true,
                'opacity-50 cursor-not-allowed': option.disabled === true
              }"
              [auxTooltip]="option.disabled ? $any(option.disabledTooltip) : ''"
            >
              @if (option.iconName) {
                <aux-icon [name]="option.iconName" [ngClass]="option.iconClass" class="mr-[12px]" />
              }
              {{ option.name }}
            </div>
          }
        }
      </div>
    </ng-template>
  `,
  imports: [IconComponent, CdkConnectedOverlay, CdkOverlayOrigin, TooltipDirective, NgClass],
  styles: [],
})
export class ButtonGroupComponent {
  @Input() label = '';

  @Input() options: AuxButtonGroup[] = [];

  @Input() disabled = false;

  @Input() disabledTooltip = '';

  @Input() position: ConnectedPosition[] = [];

  defaultClasses = {
    success: 'btn--success',
    primary: 'btn--primary',
    negative: 'btn--negative',
    secondary: 'btn--secondary',
    hyperlink: 'btn--hyperlink',
    custom: '',
  };

  variant = input<'success' | 'primary' | 'negative' | 'secondary' | 'hyperlink' | 'custom'>(
    'secondary'
  );

  showDropdownIcon = input<boolean>(true);

  buttonClass = computed(() => this.defaultClasses[this.variant()]);

  @Input() iconName?: AuxIconName;

  dropdownOpened = false;

  onOptionClick(option: AuxButtonGroup) {
    if (!option.disabled) {
      option.onClick();
      this.dropdownOpened = false;
    }
  }
}
