import { ChangeDetectionStrategy, Component } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { MainQuery } from '@shared/store/main/main.query';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-converter-tool',
  templateUrl: './converter-tool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConverterToolComponent {
  url$ = new BehaviorSubject('');

  constructor(
    private mainQuery: MainQuery,
    private activatedRoute: ActivatedRoute
  ) {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(
        tap((currentOpenMonth) => {
          this.url$.next('');
          const trial = this.mainQuery.getSelectedTrial();
          if (trial && currentOpenMonth) {
            this.url$.next(
              `${this.activatedRoute.snapshot.data.baseUrl}?customer=${encodeURI(
                environment.stage
              )}&trial=${encodeURI(trial.short_name || '')}&current_month=${encodeURI(
                dayjs(currentOpenMonth).endOf('month').format('MM/DD/YYYY')
              )}`
            );
          }
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }
}
