import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ApiService } from '@shared/services/api.service';
import { EventService as GlobalEventService } from '@models/event/event.service';
import { listUserNamesWithEmailQuery } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Utils } from '@shared/utils/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MainQuery } from '@shared/store/main/main.query';
import {
  consolidateEtlValidationErrors,
  EtlDataLoadTaskStatus,
  EtlPhase,
  EtlSubtaskStatus,
  EtlTaskStatus,
  EtlValidationTaskStatus,
} from '@shared/utils/etl-task-tracker';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Subscription } from 'rxjs';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessagesConstants } from '@shared/constants/messages.constants';

dayjs.extend(localizedFormat);

enum OverlayMessageType {
  Important = 0, // do not change the value
  ValidationSuccess,
  ValidationError1,
  ValidationError2,
  DataLoadSuccess,
  DataLoadError1,
}

@Component({
  selector: 'aux-progress-tracker',
  templateUrl: 'progress-tracker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ProgressBarComponent],
})
export class ProgressTrackerComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  private cdr = inject(ChangeDetectorRef);

  private apiService = inject(ApiService);

  private globalEventService = inject(GlobalEventService);

  private overlayService = inject(OverlayService);

  private mainQuery = inject(MainQuery);

  private authQuery = inject(AuthQuery);

  private progress_tracker_id = '';

  private currentSubscription?: Subscription;

  private userLookup = new Map<string, listUserNamesWithEmailQuery>();

  componentVisible = false;

  componentCaption = '';

  triggeredBy = '';

  startedAt = '';

  progressBarMode = 'indeterminate';

  progressBarPercent = 0;

  @Output() trackerFailed = new EventEmitter<string>();

  public get trackerId(): string {
    return this.progress_tracker_id;
  }

  @Input() public set trackerId(value: string | null) {
    if (!value || !Utils.uuidValidate(value)) {
      value = '';
    }
    if (value !== this.progress_tracker_id) {
      this.progress_tracker_id = value;
      this.startListening();
      this.emitTrackerIdChanged(this.progress_tracker_id);
    }
  }

  @Input() initializationInProgressCaption?: string;

  @Input() validationInProgressCaption?: string;

  @Input() dataLoadInProgressCaption?: string;

  @Input() consoleTracker = false;

  @Output() trackerIdChanged = new EventEmitter<string>();

  private getUserInfo(sub: string): string {
    const user = this.userLookup.get(sub);
    if (!user) {
      return '';
    }
    return Utils.agUserFormatter(user, this.authQuery.isAuxAdmin());
  }

  private emitTrackerIdChanged(value: string) {
    try {
      this.trackerIdChanged.emit(value);
    } catch (err) {
      console.warn('Unhandled error (idChanged)', err);
    }
  }

  private emitLog(...args: unknown[]) {
    if (this.consoleTracker) {
      console.log(...args);
    }
  }

  private emitOverlaySuccess(overlayMessageType: OverlayMessageType, message: string) {
    let alreadyShown = false;
    let k = '';
    if (overlayMessageType && this.progress_tracker_id) {
      k = `PROGRESS_TRACKER_SUCCESS/${this.progress_tracker_id}/${overlayMessageType}`;
      alreadyShown = !!sessionStorage.getItem(k);
    }
    if (!alreadyShown) {
      this.overlayService.success(message);
      if (k) {
        sessionStorage.setItem(k, new Date().toISOString());
      }
    }
  }

  private emitOverlayError(overlayMessageType: OverlayMessageType, message: string) {
    let alreadyShown = false;
    let k = '';
    if (overlayMessageType && this.progress_tracker_id) {
      k = `PROGRESS_TRACKER_ERROR/${this.progress_tracker_id}/${overlayMessageType}`;
      alreadyShown = !!sessionStorage.getItem(k);
    }
    if (!alreadyShown) {
      this.overlayService.error(message, undefined, true);
      if (k) {
        sessionStorage.setItem(k, new Date().toISOString());
      }
    }
  }

  private stopListening() {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
      this.currentSubscription = undefined;
    }
    this.componentVisible = !!this.progress_tracker_id;
    this.triggeredBy = '';
    this.startedAt = '';
    this.componentCaption = this.initializationInProgressCaption || 'Initializing';
    this.progressBarMode = 'indeterminate';
    this.progressBarPercent = 0;
  }

  private startListening(): void {
    this.stopListening();
    if (!this.progress_tracker_id) {
      return;
    }
    let finished = false;
    let discardTemplateFileBucketKey = '';
    const localSubscription = this.globalEventService
      .getEtlTaskTracker$(this.progress_tracker_id)
      .subscribe({
        next: (etlTaskStatus: EtlTaskStatus) => {
          if (!this.currentSubscription || this.currentSubscription !== localSubscription) {
            return;
          }
          this.cdr.markForCheck();
          this.progressBarMode = 'determinate';
          if (!this.triggeredBy) {
            this.triggeredBy = this.getUserInfo(etlTaskStatus.created_by);
          }
          if (!this.startedAt && etlTaskStatus.start_date) {
            this.startedAt = dayjs(etlTaskStatus.start_date).format('LTS');
          }
          if (etlTaskStatus.phase === EtlPhase.Validation) {
            this.componentCaption = this.validationInProgressCaption || 'Validation Progress';
            if (etlTaskStatus.is_in_progress || etlTaskStatus.should_update_progress) {
              this.emitLog(
                `ETL-next ${new Date().toISOString()}`,
                ` * validation | progress: ${etlTaskStatus.percent_complete}`
              );
              this.progressBarPercent = etlTaskStatus.percent_complete;
            }
            if (!etlTaskStatus.is_in_progress) {
              const ets = etlTaskStatus as EtlValidationTaskStatus;
              // validation finished; now, check how it went
              discardTemplateFileBucketKey = ets.bucket_key;
              const validationErrorMessages = consolidateEtlValidationErrors(ets.val_errors);
              if (ets.completion_error_message || ets.val_status === EtlSubtaskStatus.Error) {
                const errorMessage = `${MessagesConstants.TEMPLATE_UPLOAD_VALIDATION_ERROR_MESSAGE}<br/>${ets.completion_error_message}${validationErrorMessages ? '<br/>' : ''}${validationErrorMessages}`;
                this.emitLog(
                  `ETL-next ${new Date().toISOString()}`,
                  ` * validation | validation failed due to run-time error: ${errorMessage}`
                );
                if (etlTaskStatus.created_by === this.authQuery.getValue().sub) {
                  this.emitOverlayError(OverlayMessageType.ValidationError1, errorMessage);
                  this.trackerFailed.emit(discardTemplateFileBucketKey);
                }
              } else if (validationErrorMessages || ets.val_status !== EtlSubtaskStatus.Success) {
                const msg =
                  validationErrorMessages ||
                  'received an inconsistent status update from the backend!';
                const errorMessage = `${MessagesConstants.TEMPLATE_UPLOAD_VALIDATION_ERROR_MESSAGE}<br/>${msg}`;
                this.emitLog(
                  `ETL-next ${new Date().toISOString()}`,
                  ` * validation | validation failed gracefully with business error: ${errorMessage}`
                );
                if (etlTaskStatus.created_by === this.authQuery.getValue().sub) {
                  this.emitOverlayError(OverlayMessageType.ValidationError2, errorMessage);
                  this.trackerFailed.emit(discardTemplateFileBucketKey);
                }
              } else {
                discardTemplateFileBucketKey = '';
                this.emitLog(
                  `ETL-next ${new Date().toISOString()}`,
                  ` * validation | validation completed successfully. Hide validation related stuff`
                );
                if (ets.bucket_key) {
                  if (etlTaskStatus.created_by === this.authQuery.getValue().sub) {
                    this.emitOverlaySuccess(
                      OverlayMessageType.ValidationSuccess,
                      'Template file has been validated'
                    );
                  }
                }
                this.componentCaption = this.dataLoadInProgressCaption || 'Data Load Progress';
                this.progressBarPercent = 0;
              }
              if (ets.val_status !== EtlSubtaskStatus.Success && ets.bucket_key) {
                this.apiService.removeFile(ets.bucket_key).catch((err) => {
                  console.warn(`Failed to remove the template file: ${ets.bucket_key}`, err);
                });
              }
            }
          } else {
            if (etlTaskStatus.is_in_progress || etlTaskStatus.should_update_progress) {
              this.emitLog(
                `ETL-next ${new Date().toISOString()}`,
                ` * data-load | progress: ${etlTaskStatus.percent_complete}`
              );
              this.progressBarPercent = etlTaskStatus.percent_complete;
            }
            if (!etlTaskStatus.is_in_progress) {
              const ets = etlTaskStatus as EtlDataLoadTaskStatus;
              // data-load finished; now, check how it went
              if (ets.completion_error_message) {
                this.emitLog(
                  `ETL-next ${new Date().toISOString()}`,
                  ` * data-load | data load failed: ${ets.error_notification_message}`
                );
                if (etlTaskStatus.created_by === this.authQuery.getValue().sub) {
                  this.emitOverlayError(
                    OverlayMessageType.DataLoadError1,
                    ets.error_notification_message
                  );
                  this.trackerFailed.emit(discardTemplateFileBucketKey);
                }
              } else {
                this.emitLog(
                  `ETL-next ${new Date().toISOString()}`,
                  ` * data-load | data load successful: ${ets.success_notification_message}`
                );
                if (etlTaskStatus.created_by === this.authQuery.getValue().sub) {
                  this.emitOverlaySuccess(
                    OverlayMessageType.DataLoadSuccess,
                    ets.success_notification_message
                  );
                }
              }
            }
          }
        },
        error: (err: unknown) => {
          finished = true;
          if (this.currentSubscription) {
            if (this.currentSubscription === localSubscription) {
              this.currentSubscription = undefined;
              this.emitTrackerIdChanged('');
            }
          }
          const errorMessage = `Data Import Error: ${err instanceof Error ? err.message : String(err)}`;
          this.emitLog(
            `ETL-error ${new Date().toISOString()}`,
            'Notify the user of the run-time problem',
            errorMessage
          );
          this.emitOverlayError(OverlayMessageType.Important, errorMessage);
          this.trackerFailed.emit(discardTemplateFileBucketKey);
          if (discardTemplateFileBucketKey) {
            this.apiService.removeFile(discardTemplateFileBucketKey).catch((e) => {
              console.warn(
                `Failed to remove the template file: ${discardTemplateFileBucketKey}`,
                e
              );
            });
          }
        },
        complete: () => {
          finished = true;
          if (this.currentSubscription) {
            if (this.currentSubscription === localSubscription) {
              this.currentSubscription = undefined;
              this.emitTrackerIdChanged('');
            }
          }
          this.emitLog(`ETL-complete ${new Date().toISOString()}`);
        },
      });
    if (!finished) {
      this.currentSubscription = localSubscription;
    }
  }

  ngOnInit(): void {
    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((users) => {
        this.userLookup.clear();
        users.forEach((user: listUserNamesWithEmailQuery) => {
          this.userLookup.set(user.sub, user);
        });
      });
  }

  ngOnDestroy(): void {
    this.stopListening();
    this.trackerIdChanged.complete();
  }
}
