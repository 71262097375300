import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AgActionsComponent } from '@shared/ag-components/ag-actions/ag-actions.component';
import { OverlayService } from '@shared/services/overlay.service';
import { RequireSome, Utils } from '@shared/utils/utils';
import {
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEndEvent,
  RowNode,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { isEqual, uniq } from 'lodash-es';
import { BehaviorSubject, combineLatest, firstValueFrom, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EventService } from '@models/event/event.service';
import { Organization, EventType } from '@shared/services/gql.service';
import { AgSetColumnsVisible } from '@shared/utils';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { SiteGroupsService } from './state/site-groups.service';
import { SiteGroupsQuery } from './state/site-groups.query';
import { SiteGroupModel } from '../models/site-group.model';
import { SiteCurveQuery } from './site-curve/site-curve.query';
import { SiteCurveService } from './site-curve/site-curve.service';
import { TableConstants } from '@shared/constants/table.constants';
import { SiteGroupsStore } from './state/site-groups.store';
import { MainQuery } from '@shared/store/main/main.query';
import { FormControlConstants } from '@shared/constants/form-control.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationActionModalComponent } from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';

@Component({
  selector: 'aux-forecast-site-groups',
  templateUrl: 'forecast-site-groups.component.html',
  styles: [
    `
      ::ng-deep .group-actions .ag-cell-value {
        overflow: initial;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSiteGroupsComponent {
  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      resizable: true,
      editable: () => this.isEditModeEnabled$.getValue(),
      cellStyle: {
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap',
        overflow: 'hidden',
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressMenuHide: true,
    headerHeight: 40,
    rowDragManaged: true,
    suppressCellFocus: false,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    getRowId: ({ data }) => data?.id || data?.randomID,
    columnDefs: [
      {
        headerName: 'id',
        field: 'id',
        hide: true,
      },
      {
        headerName: '',
        suppressFillHandle: true,
        field: TableConstants.FIELDS.ACTIONS,
        cellRenderer: AgActionsComponent,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (!rowNode.data.id) {
              this.gridAPI.applyTransaction({ remove: [rowNode.data] });
              if (this.addedRowscounter === 1) {
                this.reorderedRows.clear();
                this.removedRows = [];
                this.newRowAdded = false;
              } else {
                this.removedRows.pop();
              }
              this.addedRowscounter -= 1;
              this.checkChanges();
            } else {
              this.dialogConfirmRemoveSiteGroup(rowNode);
            }
          },
        },
        cellClass: 'group-actions',
        maxWidth: 75,
        minWidth: 75,
        suppressSizeToFit: true,
        editable: false,
        rowDrag: true,
        hide: true,
      },
      {
        headerName: 'Site Group',
        field: 'name',
        tooltipValueGetter: Utils.getDuplicateNameCellTooltip,
        cellClass: 'text-left',
        cellClassRules: {
          'border-aux-error': (params) => params.data.showError,
        },
        valueSetter: (params: ValueSetterParams) => {
          params.data.name = params.newValue?.toString().trim() || '';

          return true;
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        cellClass: 'text-left',
      },
    ],
  } as GridOptions);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridData$ = this.siteGroupQuery.selectAll().pipe(
    map((value) => {
      return value.map(({ id, name, description, rank_order }) => ({
        id,
        name,
        description,
        rank_order,
      }));
    })
  );

  editedRows = new Set<string>();

  removedRows: SiteGroupModel[] = [];

  newRowAdded = false;

  addedRowscounter = 0;

  reorderQueue: { ids: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  initialValues: SiteGroupModel[] = [];

  hasChanges = false;

  valuesBeforeRemove: SiteGroupModel[] = [];

  isEditModeEnabled$ = new BehaviorSubject(false);

  constructor(
    public overlayService: OverlayService,
    public siteGroupQuery: SiteGroupsQuery,
    public siteGroupsService: SiteGroupsService,
    public siteCurveQuery: SiteCurveQuery,
    private siteCurveService: SiteCurveService,
    private eventService: EventService,
    private siteGroupsStore: SiteGroupsStore,
    private mainQuery: MainQuery,
    private changeDetector: ChangeDetectorRef
  ) {
    this.siteGroupsService.get().pipe(takeUntilDestroyed()).subscribe();
    this.siteCurveService.get().pipe(takeUntilDestroyed()).subscribe();

    this.gridData$.pipe(takeUntilDestroyed()).subscribe((gridData) => {
      this.initialValues = gridData.map((value) => {
        return { ...value, description: value?.description === '' ? null : value.description };
      });

      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setGridOption('rowData', this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges) {
        this.gridAPI?.setGridOption('rowData', this.valuesBeforeRemove);
      }

      this.checkChanges();
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.initialValues = [];
        this.valuesBeforeRemove = [];
        this.hasChanges = false;
      });
  }

  enableEditMode() {
    this.isEditModeEnabled$.next(true);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: true,
    });
    this.gridAPI.sizeColumnsToFit();
    this.gridAPI.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'name',
    });
  }

  cancelEditMode() {
    this.gridAPI.stopEditing();
    this.isEditModeEnabled$.next(false);
    if (this.gridAPI) {
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: [TableConstants.FIELDS.ACTIONS],
        visible: false,
      });
    }

    this.gridAPI.sizeColumnsToFit();

    this.gridData$ = this.siteGroupQuery.selectAll().pipe(
      map((value) => {
        return value
          .map(({ id, name, description, rank_order }) => ({
            id,
            name,
            description,
            rank_order,
          }))
          .sort((a, b) => (a.rank_order > b.rank_order ? 1 : -1));
      })
    );

    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
  }

  getCurrentValues() {
    const currentValues: SiteGroupModel[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        description: data.description?.length === 0 ? null : data.description,
      });
    });

    return currentValues;
  }

  checkChanges() {
    const currentValues = this.getCurrentValues();

    if (currentValues.length || this.removedRows.length) {
      this.hasChanges = !isEqual(this.initialValues, currentValues);
      this.changeDetector.detectChanges();
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    api.sizeColumnsToFit();
  }

  onAddSiteGroups() {
    const ss = this.gridAPI.applyTransaction({
      add: [{ randomID: Utils.uuid() }],
    });
    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'name',
      });
    }
    this.addedRowscounter += 1;
    this.newRowAdded = true;
    this.checkChanges();
  }

  cellEditingStopped(event: CellEditingStoppedEvent): void {
    if (!event.newValue?.trim()) {
      this.getRowsErrors();
    }
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.id) {
      this.editedRows.add(event.data.id);
      this.checkChanges();
    }

    this.getRowsErrors();
  }

  private getRowsErrors(): string[] {
    const errors: string[] = [];
    const uniqueNames = this.gridUniqueNames();

    this.gridAPI.forEachNode((node) => {
      const rowErrors = [];
      const { name } = node.data as SiteGroupModel;
      const trimmedName = name?.trim();

      if (!trimmedName) {
        rowErrors.push(FormControlConstants.VALIDATION_MESSAGE.REQUIRED_SITE_GROUP);
      }

      if (trimmedName && !uniqueNames.includes(trimmedName.toLowerCase())) {
        rowErrors.push(FormControlConstants.VALIDATION_MESSAGE.DUPLICATED_SITE_GROUP);
      }

      node.data.showError = !!rowErrors.length;
      errors.push(...rowErrors);
    });

    this.gridAPI.redrawRows();
    return uniq(errors);
  }

  private gridUniqueNames(): string[] {
    const arr: string[] = [];
    this.gridAPI.forEachNode((node) => {
      arr.push(node.data?.name?.trim().toLowerCase() || '');
    });
    return arr.filter((el) => arr.indexOf(el) === arr.lastIndexOf(el));
  }

  onSaveAll = async () => {
    if (!this.getRowsErrors().length) {
      const updateData: SiteGroupModel[] = [];
      const insertData: SiteGroupModel[] = [];
      const reorderData: SiteGroupModel[] = [];

      this.siteGroupsStore.setLoading(true);

      if (
        this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
        !this.removedRows.length
      ) {
        this.reorderQueue.forEach(({ new_index, ids }) => {
          const pp = this.siteGroupQuery.getEntity(ids);
          if (pp) {
            const { id, name, description } = pp;
            updateData.push({
              id,
              name,
              description,
              rank_order: new_index,
            });
          }
        });
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const { id, name, description } = node.data as RequireSome<
            SiteGroupModel,
            'id' | 'name' | 'description' | 'rank_order'
          >;

          if (!id && !this.editedRows.has(id)) {
            insertData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id && this.editedRows.has(id)) {
            updateData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id) {
            reorderData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }
        });
      } else {
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;
          const { id, name, description } = node.data as RequireSome<
            SiteGroupModel,
            'id' | 'name' | 'description' | 'rank_order'
          >;

          if (
            this.reorderQueue.length ||
            !id ||
            this.editedRows.has(id) ||
            this.removedRows.length
          ) {
            updateData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (!id && !this.editedRows.has(id)) {
            insertData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }

          if (id) {
            reorderData.push({
              id,
              name,
              description,
              rank_order: rowIndex,
            });
          }
        });
      }

      let hasSuccess = true;

      if (updateData.length) {
        for (const { id, name, description, rank_order } of reorderData) {
          await this.siteGroupsService.update({ id, name, description, rank_order });
        }
        await firstValueFrom(this.siteCurveService.get().pipe(take(1)));
      }

      if (insertData.length) {
        for (const patientGroup of insertData) {
          hasSuccess = (await this.siteGroupsService.add(patientGroup)).success;
        }
      }

      if (this.removedRows.length) {
        for (const { id, name, description, rank_order } of this.removedRows) {
          await this.siteGroupsService.remove({ id, name, description, rank_order });
        }
      }

      if (hasSuccess) {
        this.overlayService.success(`Successfully Saved`);

        this.cancelEditMode();
      } else {
        this.overlayService.error(`An error has occurred`);
      }
    } else {
      this.overlayService.error(this.getRowsErrors());
    }

    this.siteGroupsStore.setLoading(false);
  };

  resetChangeIndicators() {
    this.reorderedRows.clear();
    this.editedRows.clear();
    this.removedRows = [];
    this.reorderQueue = [];
    this.newRowAdded = false;
    this.addedRowscounter = 0;
    this.hasChanges = false;
    this.initialValues = [];
    this.valuesBeforeRemove = [];
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        ids: event.node.data.id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.id);
      this.checkChanges();
    }
  }

  async dialogConfirmRemoveSiteGroup(rowNode: RowNode) {
    const site = this.siteCurveQuery.getAll();
    const curves = site.filter((el) => el.site_group_id === rowNode.data.id);

    const organizations_with_forecasts = [...curves].reduce((acc: Organization[], el) => {
      const organizations = el.organizations_with_forecasts;
      organizations.forEach((org: Organization) => {
        if (!acc.find((e) => e.id === org.id)) {
          acc.push(org);
        }
      });
      return acc;
    }, []);

    if (!curves.length) {
      this.overlayService.openPopup<
        { message: string },
        boolean | undefined,
        ConfirmationModalComponent
      >({
        content: ConfirmationModalComponent,
        data: {
          message: `Are you sure you want to remove ${rowNode.data.name}?`,
        },
        settings: {
          header: 'Remove Site Group',
          primaryButton: {
            label: 'Remove',
            action: (instance) => {
              this.removedRows.push(rowNode.data);
              this.gridAPI.applyTransaction({ remove: [rowNode.data] });
              this.valuesBeforeRemove = this.getCurrentValues();
              this.checkChanges();
              instance?.ref.close();
            },
          },
        },
      });
      return;
    }

    let list = `<br>Site Curves:<br><ul class="list-disc pl-4">${curves
      .map(
        ({ name }) =>
          `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}">${name}</a></li>`
      )
      .join('')}</ul>`;
    if (organizations_with_forecasts.length) {
      list += `<br>Forecast Methodology:<br><ul class="list-disc pl-4">${organizations_with_forecasts
        .map(
          ({ name }) =>
            `<li><a class="aux-link" href="/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}">${name}</a></li>`
        )
        .join('')}</ul>`;
    }

    this.overlayService.openPopup({
      modal: ConfirmationActionModalComponent,
      settings: {
        header: 'Remove Site Group',
        primaryButton: {
          label: 'Remove',
          disabled: true,
        },
      },
      data: {
        message: `${rowNode.data.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${rowNode.data.name} can be successfully deleted.<br>${list}`,
        skipKeywordConfirmation: true,
        hideConfirmationHint: true,
      },
    });
  }
}
