import { Component, ChangeDetectionStrategy, inject, DestroyRef, OnDestroy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, IHeaderGroupParams, IProvidedColumn } from '@ag-grid-community/core';
import { AgSetColumnsVisible } from '@shared/utils';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { Subscription } from 'rxjs';

interface IEnhanceHeaderGroupParams extends IHeaderGroupParams {
  dataId?: string;
  localStorageKey: string;
  expandableCols?: string[];
  featureFlags?: { field: string; flagKey: keyof Flags }[];
  onToggle?: () => void;
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <span [attr.data-id]="params.dataId">{{ params.displayName }}</span>
      <button class="flex items-center no-underline aux-link" (click)="toggleExpand()">
        <aux-icon class="text-white" [name]="visible ? 'ChevronLeft' : 'ChevronRight'" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderExpandComponent implements IHeaderGroupAngularComp, OnDestroy {
  launchDarklyService = inject(LaunchDarklyService);

  readonly destroyRef = inject(DestroyRef);

  launchDarklySubscriptions: Subscription[] = [];

  params!: IEnhanceHeaderGroupParams;

  visible = false;

  agInit(params: IEnhanceHeaderGroupParams): void {
    this.params = params;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
    return index && index !== columns.length - 1;
  }

  initializeExpandCols() {
    if (this.params?.expandableCols) {
      let lsVisible = false;

      this.visible = this.params.expandableCols.some((colId) =>
        this.params.api.getColumn(colId)?.isVisible()
      );

      if (localStorage.getItem(this.params.localStorageKey)) {
        lsVisible = localStorage.getItem(this.params.localStorageKey) === 'true';

        if (this.visible !== lsVisible) {
          this.toggleExpand(true);
        }
      }

      if (this.params?.featureFlags) {
        this.unsubscribe();

        this.params?.featureFlags.forEach((item) => {
          this.launchDarklySubscriptions.push(
            this.launchDarklyService
              .select$((flags) => flags[item.flagKey])
              .pipe(
                startWith(false),
                pairwise(),
                filter(([prev, next]) => !isEqual(prev, next))
              )
              .subscribe(([, next]) => {
                if (this.visible && this.params.api.getColumn(item.field)?.isVisible() !== next) {
                  this.params.api.setColumnVisible(item.field, !!next);
                }
              })
          );
        });
      }
    }
  }

  // gets called whenever the user gets the cell to refresh
  refresh() {
    return false;
  }

  toggleExpand(initializing = false) {
    const fields = this.params?.columnGroup
      .getProvidedColumnGroup()
      .getChildren()
      .filter(this.filterCols)
      .filter((column) => {
        const colForFilter =
          this.params.featureFlags?.find(
            (featureFlagsColumn) => column.getId() === featureFlagsColumn.field
          ) || null;
        if (colForFilter) {
          return this.launchDarklyService.$select((flags) => flags[colForFilter.flagKey])();
        }

        return true;
      })
      .map((e) => (e as Column).getColDef().field || '');

    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: fields,
      visible: !this.visible,
    });
    this.visible = !this.visible;
    if (this.params.localStorageKey) {
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());
    }

    if (this.params.onToggle) {
      this.params.onToggle();
    }

    // refresh headers after setting columns visible to fix order elements in DOM after hidding/showing columns
    if (!initializing && this.visible) {
      this.params.api.refreshHeader();
    }
  }

  private unsubscribe(): void {
    this.launchDarklySubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
