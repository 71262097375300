import { Component, ChangeDetectionStrategy, inject, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import dayjs from 'dayjs';
import { PrepaidType } from '@shared/services/gql.service';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { PrepaidTypeLabel } from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.model';
import { MessagesConstants } from '@shared/constants/messages.constants';

type AgPrepaidTypeDropdownOption = {
  value: PrepaidType;
  label: PrepaidTypeLabel;
  disabled: boolean;
};

export const PrepaidTypeOptions: AgPrepaidTypeDropdownOption[] = [
  {
    value: PrepaidType.PREPAID_TYPE_INCREASE,
    label: PrepaidTypeLabel.PREPAID_TYPE_INCREASE,
    disabled: false,
  },
  {
    value: PrepaidType.PREPAID_TYPE_RECLASS,
    label: PrepaidTypeLabel.PREPAID_TYPE_RECLASS,
    disabled: false,
  },
  {
    value: PrepaidType.PREPAID_TYPE_AMORTIZE,
    label: PrepaidTypeLabel.PREPAID_TYPE_AMORTIZE,
    disabled: false,
  },
];

@Component({
  selector: 'aux-ag-prepaid-type-dropdown',
  templateUrl: 'ag-prepaid-type-dropdown.component.html',
  styleUrl: 'ag-prepaid-type-dropdown.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    NgIf,
    FormsModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    TooltipDirective,
  ],
})
export class AgPrepaidTypeDropdownComponent implements ICellRendererAngularComp {
  readonly utils = Utils;

  @ViewChild('select', { static: true }) select!: NgSelectComponent;

  readonly launchDarklyService = inject(LaunchDarklyService);
  readonly mainQuery = inject(MainQuery);

  params!: ICellRendererParams;
  formControl = new FormControl();

  options: AgPrepaidTypeDropdownOption[] = [];

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.options = PrepaidTypeOptions.map((option) => ({
      ...option,
      disabled: this.isOptionDisabled(option),
    }));
    this.formControl.setValue(params.data.prepaid_type);
    setTimeout(() => this.select.open());
  }

  getValue(): string {
    return this.formControl.value;
  }

  tooltip(item: AgPrepaidTypeDropdownOption): string {
    return item.disabled ? MessagesConstants.PREPAIDS.INCREASE_ONLY_PAID_INVOICE : '';
  }

  onChange(): void {
    this.params.node.updateData({
      ...this.params.node.data,
      prepaid_type: this.formControl.value,
    });
  }

  private isOptionDisabled(option: AgPrepaidTypeDropdownOption): boolean {
    if (!this.params.data.invoice_id) {
      return false;
    }

    if (this.launchDarklyService.flags$.getValue().journal_entry_ap_manual_clearing) {
      if (option.value === PrepaidType.PREPAID_TYPE_INCREASE) {
        return (
          !this.params.data.payment_date ||
          dayjs(this.params.data.payment_date).isAfter(
            dayjs(this.mainQuery.getValue().currentOpenMonth).endOf('month')
          )
        );
      }
    }

    return false;
  }
}
