import { AgStatusSettings } from '@shared/ag-components/ag-status/ag-status.model';
import { StoredDocumentProcessStatus } from '@shared/services/gql.service';

export class OpsAdminUtils {
  static getAgStatusParameters(status: StoredDocumentProcessStatus): AgStatusSettings {
    switch (status) {
      case StoredDocumentProcessStatus.STATUS_PROCESSED:
        return {
          bgClass: 'bg-aux-green-dark',
          textClass: 'text-aux-green-dark',
          statusText: 'Complete',
        };
      case StoredDocumentProcessStatus.STATUS_PROCESS:
      case StoredDocumentProcessStatus.STATUS_IN_PROCESS:
        return {
          bgClass: 'bg-aux-warn',
          textClass: 'text-aux-warn',
          statusText: 'In Progress',
        };
      case StoredDocumentProcessStatus.STATUS_ERROR:
        return {
          bgClass: 'bg-aux-red-dark',
          textClass: 'text-aux-red-dark',
          statusText: 'Error',
        };
      case StoredDocumentProcessStatus.STATUS_TIMEOUT:
        return {
          bgClass: 'bg-aux-red-dark',
          textClass: 'text-aux-red-dark',
          statusText: 'Time Out',
        };
      default:
        return {
          bgClass: '',
          textClass: '',
          statusText: '',
        };
    }
  }

  static prepareDownloadLink(bucket_key: string): string {
    let path = bucket_key;

    if (bucket_key && bucket_key.startsWith('public/')) {
      path = bucket_key.replace('public/', '');
    }

    return path;
  }
}
