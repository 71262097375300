var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { _, Autowired, BeanStub, Events, NumberSequence, PostConstruct, PreDestroy, ServerSideTransactionResultStatus } from "@ag-grid-community/core";
import { LazyCache } from "./lazyCache.mjs";
export class LazyStore extends BeanStub {
  constructor(ssrmParams, storeParams, parentRowNode) {
    super();
    this.idSequence = new NumberSequence();
    this.ssrmParams = ssrmParams;
    this.parentRowNode = parentRowNode;
    this.storeParams = storeParams;
    this.level = parentRowNode.level + 1;
    this.group = ssrmParams.rowGroupCols ? this.level < ssrmParams.rowGroupCols.length : false;
    this.leafGroup = ssrmParams.rowGroupCols ? this.level === ssrmParams.rowGroupCols.length - 1 : false;
    this.info = {};
  }
  init() {
    var _a;
    let numberOfRows = 1;
    if (this.level === 0) {
      numberOfRows = (_a = this.storeUtils.getServerSideInitialRowCount()) !== null && _a !== void 0 ? _a : 1;
      this.eventService.dispatchEventOnce({
        type: Events.EVENT_ROW_COUNT_READY
      });
    }
    this.cache = this.createManagedBean(new LazyCache(this, numberOfRows, this.storeParams));
    const usingTreeData = this.gridOptionsService.get('treeData');
    if (!usingTreeData && this.group) {
      const groupColVo = this.ssrmParams.rowGroupCols[this.level];
      this.groupField = groupColVo.field;
      this.rowGroupColumn = this.columnModel.getRowGroupColumns()[this.level];
    }
  }
  destroyRowNodes() {
    this.displayIndexStart = undefined;
    this.displayIndexEnd = undefined;
    this.destroyBean(this.cache);
  }
  /**
   * Given a server response, ingest the rows outside of the data source lifecycle.
   *
   * @param rowDataParams the server response containing the rows to ingest
   * @param startRow the index to start ingesting rows
   * @param expectedRows the expected number of rows in the response (used to determine if the last row index is known)
   */
  applyRowData(rowDataParams, startRow, expectedRows) {
    this.cache.onLoadSuccess(startRow, expectedRows, rowDataParams);
  }
  /**
   * Applies a given transaction to the data set within this store
   *
   * @param transaction an object containing delta instructions determining the changes to apply to this store
   * @returns an object determining the status of this transaction and effected nodes
   */
  applyTransaction(transaction) {
    var _a, _b, _c;
    const idFunc = this.gridOptionsService.getCallback('getRowId');
    if (!idFunc) {
      console.warn('AG Grid: getRowId callback must be implemented for transactions to work. Transaction was ignored.');
      return {
        status: ServerSideTransactionResultStatus.Cancelled
      };
    }
    const applyCallback = this.gridOptionsService.getCallback('isApplyServerSideTransaction');
    if (applyCallback) {
      const params = {
        transaction: transaction,
        parentNode: this.parentRowNode,
        groupLevelInfo: this.info
      };
      const apply = applyCallback(params);
      if (!apply) {
        return {
          status: ServerSideTransactionResultStatus.Cancelled
        };
      }
    }
    let updatedNodes = undefined;
    if ((_a = transaction.update) === null || _a === void 0 ? void 0 : _a.length) {
      updatedNodes = this.cache.updateRowNodes(transaction.update);
    }
    let insertedNodes = undefined;
    if ((_b = transaction.add) === null || _b === void 0 ? void 0 : _b.length) {
      let addIndex = transaction.addIndex;
      if (addIndex != null && addIndex < 0) {
        addIndex = undefined;
      }
      insertedNodes = this.cache.insertRowNodes(transaction.add, addIndex);
    }
    let removedNodes = undefined;
    if ((_c = transaction.remove) === null || _c === void 0 ? void 0 : _c.length) {
      const allIdsToRemove = transaction.remove.map(data => idFunc({
        level: this.level,
        parentKeys: this.parentRowNode.getGroupKeys(),
        data
      }));
      const allUniqueIdsToRemove = [...new Set(allIdsToRemove)];
      removedNodes = this.cache.removeRowNodes(allUniqueIdsToRemove);
    }
    this.updateSelectionAfterTransaction(updatedNodes, removedNodes);
    return {
      status: ServerSideTransactionResultStatus.Applied,
      update: updatedNodes,
      add: insertedNodes,
      remove: removedNodes
    };
  }
  updateSelectionAfterTransaction(updatedNodes, removedNodes) {
    const nodesToDeselect = [];
    updatedNodes === null || updatedNodes === void 0 ? void 0 : updatedNodes.forEach(node => {
      if (node.isSelected() && !node.selectable) {
        nodesToDeselect.push(node);
      }
    });
    removedNodes === null || removedNodes === void 0 ? void 0 : removedNodes.forEach(node => {
      if (node.isSelected()) {
        nodesToDeselect.push(node);
      }
    });
    if (nodesToDeselect.length) {
      this.selectionService.setNodesSelected({
        newValue: false,
        clearSelection: false,
        nodes: nodesToDeselect,
        source: 'rowDataChanged'
      });
    }
  }
  /**
   * Clear the display indexes, used for fading rows out when stores are not being destroyed
   */
  clearDisplayIndexes() {
    this.displayIndexStart = undefined;
    this.displayIndexEnd = undefined;
    this.cache.getNodes().forEach(lazyNode => this.blockUtils.clearDisplayIndex(lazyNode.node));
    if (this.parentRowNode.sibling) {
      this.blockUtils.clearDisplayIndex(this.parentRowNode.sibling);
    }
    this.cache.clearDisplayIndexes();
  }
  /**
   * @returns an index representing the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexStart() {
    return this.displayIndexStart;
  }
  /**
   * @returns the index representing one after the last sequentially displayed row in the grid for this store
   */
  getDisplayIndexEnd() {
    return this.displayIndexEnd;
  }
  /**
   * @returns the virtual size of this store
   */
  getRowCount() {
    if (this.parentRowNode.sibling) {
      return this.cache.getRowCount() + 1;
    }
    return this.cache.getRowCount();
  }
  /**
   * Sets the current row count of the store, and whether the last row index is known
   */
  setRowCount(rowCount, isLastRowIndexKnown) {
    this.cache.setRowCount(rowCount, isLastRowIndexKnown);
  }
  /**
   * Given a display index, returns whether that row is within this store or a child store of this store
   *
   * @param displayIndex the visible index of a row
   * @returns whether or not the row exists within this store
   */
  isDisplayIndexInStore(displayIndex) {
    if (this.cache.getRowCount() === 0) return false;
    return this.displayIndexStart <= displayIndex && displayIndex < this.getDisplayIndexEnd();
  }
  /**
   * Recursively sets up the display indexes and top position of every node belonging to this store.
   *
   * Called after a row height changes, or a store updated event.
   *
   * @param displayIndexSeq the number sequence for generating the display index of each row
   * @param nextRowTop an object containing the next row top value intended to be modified by ref per row
   */
  setDisplayIndexes(displayIndexSeq, nextRowTop) {
    this.displayIndexStart = displayIndexSeq.peek();
    this.topPx = nextRowTop.value;
    // delegate to the store to set the row display indexes
    this.cache.setDisplayIndexes(displayIndexSeq, nextRowTop);
    if (this.parentRowNode.sibling) {
      this.blockUtils.setDisplayIndex(this.parentRowNode.sibling, displayIndexSeq, nextRowTop);
    }
    this.displayIndexEnd = displayIndexSeq.peek();
    this.heightPx = nextRowTop.value - this.topPx;
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachStoreDeep(callback, sequence = new NumberSequence()) {
    callback(this, sequence.next());
    this.cache.getNodes().forEach(lazyNode => {
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachStoreDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeepAfterFilterAndSort
   */
  forEachNodeDeep(callback, sequence = new NumberSequence()) {
    this.cache.getNodes().forEach(lazyNode => {
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeep(callback, sequence);
      }
    });
  }
  /**
   * Recursively applies a provided function to every node
   *
   * For the purpose of exclusively server side filtered stores, this is the same as getNodes().forEachDeep
   */
  forEachNodeDeepAfterFilterAndSort(callback, sequence = new NumberSequence(), includeFooterNodes = false) {
    const orderedNodes = this.cache.getOrderedNodeMap();
    for (let key in orderedNodes) {
      const lazyNode = orderedNodes[key];
      callback(lazyNode.node, sequence.next());
      const childCache = lazyNode.node.childStore;
      if (childCache) {
        childCache.forEachNodeDeepAfterFilterAndSort(callback, sequence, includeFooterNodes);
      }
    }
    if (includeFooterNodes && this.parentRowNode.sibling) {
      callback(this.parentRowNode.sibling, sequence.next());
    }
  }
  /**
   * Removes the failed status from all nodes, and marks them as stub to encourage reloading
   */
  retryLoads() {
    this.cache.getNodes().forEach(({
      node
    }) => {
      if (node.failedLoad) {
        node.failedLoad = false;
        node.__needsRefreshWhenVisible = true;
        node.stub = true;
      }
    });
    this.forEachChildStoreShallow(store => store.retryLoads());
    this.fireStoreUpdatedEvent();
  }
  /**
   * Given a display index, returns the row at that location.
   *
   * @param displayRowIndex the displayed index within the grid to search for
   * @returns the row node if the display index falls within the store, if it didn't exist this will create a new stub to return
   */
  getRowUsingDisplayIndex(displayRowIndex) {
    if (this.parentRowNode.sibling && displayRowIndex === this.parentRowNode.sibling.rowIndex) {
      return this.parentRowNode.sibling;
    }
    return this.cache.getRowByDisplayIndex(displayRowIndex);
  }
  /**
   * Given a display index, returns the row top and height for the row at that index.
   *
   * @param displayIndex the display index of the node
   * @returns an object containing the rowTop and rowHeight of the node at the given displayIndex
   */
  getRowBounds(displayIndex) {
    var _a;
    if (!this.isDisplayIndexInStore(displayIndex)) {
      return null;
    }
    const thisNode = this.cache.getNodeCachedByDisplayIndex(displayIndex);
    if (thisNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(thisNode, displayIndex);
      if (boundsFromRow) {
        return boundsFromRow;
      }
    }
    const {
      previousNode,
      nextNode
    } = (_a = this.cache.getSurroundingNodesByDisplayIndex(displayIndex)) !== null && _a !== void 0 ? _a : {};
    // previous node may equal, or catch via detail node or child of group
    if (previousNode) {
      const boundsFromRow = this.blockUtils.extractRowBounds(previousNode.node, displayIndex);
      if (boundsFromRow != null) {
        return boundsFromRow;
      }
    }
    const defaultRowHeight = this.gridOptionsService.getRowHeightAsNumber();
    // if node after this, can calculate backwards (and ignore detail/grouping)
    if (nextNode) {
      const numberOfRowDiff = (nextNode.node.rowIndex - displayIndex) * defaultRowHeight;
      return {
        rowTop: nextNode.node.rowTop - numberOfRowDiff,
        rowHeight: defaultRowHeight
      };
    }
    // otherwise calculate from end of store
    const lastTop = this.topPx + this.heightPx;
    const numberOfRowDiff = (this.getDisplayIndexEnd() - displayIndex) * defaultRowHeight;
    return {
      rowTop: lastTop - numberOfRowDiff,
      rowHeight: defaultRowHeight
    };
  }
  /**
   * Given a vertical pixel, determines whether this store contains a row at that pixel
   *
   * @param pixel a vertical pixel position from the grid
   * @returns whether that pixel points to a virtual space belonging to this store
   */
  isPixelInRange(pixel) {
    return pixel >= this.topPx && pixel < this.topPx + this.heightPx;
  }
  /**
   * Given a vertical pixel, returns the row existing at that pixel location
   *
   * @param pixel a vertical pixel position from the grid
   * @returns the display index at the given pixel location
   */
  getRowIndexAtPixel(pixel) {
    if (pixel < this.topPx) {
      return this.getDisplayIndexStart();
    }
    if (pixel >= this.topPx + this.heightPx) {
      return this.getDisplayIndexEnd() - 1;
    }
    let distToPreviousNodeTop = Number.MAX_SAFE_INTEGER;
    let previousNode = null;
    let distToNextNodeTop = Number.MAX_SAFE_INTEGER;
    let nextNode = null;
    this.cache.getNodes().forEach(({
      node
    }) => {
      const distBetween = Math.abs(pixel - node.rowTop);
      // previous node
      if (node.rowTop < pixel) {
        if (distBetween < distToPreviousNodeTop) {
          distToPreviousNodeTop = distBetween;
          previousNode = node;
        }
        return;
      }
      // next node
      if (distBetween < distToNextNodeTop) {
        distToNextNodeTop = distBetween;
        nextNode = node;
      }
    });
    // cast these back as typescript doesn't understand the forEach above
    previousNode = previousNode;
    nextNode = nextNode;
    // previous node may equal, or catch via detail node or child of group
    if (previousNode) {
      const indexOfRow = this.blockUtils.getIndexAtPixel(previousNode, pixel);
      if (indexOfRow != null) {
        return indexOfRow;
      }
    }
    const defaultRowHeight = this.gridOptionsService.getRowHeightAsNumber();
    // if node after this, can calculate backwards (and ignore detail/grouping)
    if (nextNode) {
      const nextTop = nextNode.rowTop;
      const numberOfRowDiff = Math.ceil((nextTop - pixel) / defaultRowHeight);
      return nextNode.rowIndex - numberOfRowDiff;
    }
    // otherwise calculate from end of store
    const nextTop = this.topPx + this.heightPx;
    const numberOfRowDiff = Math.floor((nextTop - pixel) / defaultRowHeight);
    return this.getDisplayIndexEnd() - numberOfRowDiff;
  }
  /**
   * Given a path of group keys, returns the child store for that group.
   *
   * @param keys the grouping path to the desired store
   * @returns the child store for the given keys, or null if not found
   */
  getChildStore(keys) {
    return this.storeUtils.getChildStore(keys, this, key => {
      const lazyNode = this.cache.getNodes().find(lazyNode => lazyNode.node.key == key);
      if (!lazyNode) {
        return null;
      }
      return lazyNode.node;
    });
  }
  /**
   * Executes a provided callback on each child store belonging to this store
   *
   * @param cb the callback to execute
   */
  forEachChildStoreShallow(cb) {
    this.cache.getNodes().forEach(({
      node
    }) => {
      if (node.childStore) {
        cb(node.childStore);
      }
    });
  }
  /**
   * Executes after a change to sorting, determines recursively whether this store or a child requires refreshed.
   *
   * If a purge refresh occurs, the row count is preserved.
   *
   * @param params a set of properties pertaining to the sort changes
   */
  refreshAfterSort(params) {
    const serverSortsAllLevels = this.storeUtils.isServerSideSortAllLevels();
    if (serverSortsAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      const oldCount = this.cache.getRowCount();
      this.destroyBean(this.cache);
      this.cache = this.createManagedBean(new LazyCache(this, oldCount, this.storeParams));
      this.fireStoreUpdatedEvent();
      return;
    }
    // call refreshAfterSort on children, as we did not purge.
    // if we did purge, no need to do this as all children were destroyed
    this.forEachChildStoreShallow(store => store.refreshAfterSort(params));
  }
  /**
   * Executes after a change to filtering, determines recursively whether this store or a child requires refreshed.
   *
   * If a refresh occurs, the row count is reset.
   *
   * @param params a set of properties pertaining to the filter changes
   */
  refreshAfterFilter(params) {
    const serverFiltersAllLevels = !this.storeUtils.isServerSideOnlyRefreshFilteredGroups();
    if (serverFiltersAllLevels || this.storeUtils.isServerRefreshNeeded(this.parentRowNode, this.ssrmParams.rowGroupCols, params)) {
      this.refreshStore(true);
      return;
    }
    // call refreshAfterSort on children, as we did not purge.
    // if we did purge, no need to do this as all children were destroyed
    this.forEachChildStoreShallow(store => store.refreshAfterFilter(params));
  }
  /**
   * Marks all existing nodes as requiring reloaded, and triggers a load check
   *
   * @param purge whether to remove all nodes and data in favour of stub nodes
   */
  refreshStore(purge) {
    if (purge) {
      this.destroyBean(this.cache);
      this.cache = this.createManagedBean(new LazyCache(this, 1, this.storeParams));
      this.fireStoreUpdatedEvent();
      return;
    }
    this.cache.markNodesForRefresh();
  }
  /**
   * Used for pagination, given a local/store index, returns the display index of that row
   *
   * @param topLevelIndex the store index of a row
   * @returns the display index for the given store index
   */
  getTopLevelRowDisplayedIndex(topLevelIndex) {
    const displayIndex = this.cache.getDisplayIndexFromStoreIndex(topLevelIndex);
    return displayIndex !== null && displayIndex !== void 0 ? displayIndex : topLevelIndex;
  }
  /**
   * Used for pagination to determine if the last page is known, and for aria to determine if the last grid row is known
   *
   * @returns whether the last index of this store is known, or if lazy loading still required
   */
  isLastRowIndexKnown() {
    return this.cache.isLastRowIndexKnown();
  }
  /**
   * Used by the selection service to select a range of nodes
   *
   * @param firstInRange the first node in the range to find
   * @param lastInRange the last node in the range to find
   * @returns a range of nodes between firstInRange and lastInRange inclusive
   */
  getRowNodesInRange(firstInRange, lastInRange) {
    const result = [];
    let inActiveRange = false;
    // if only one node passed, we start the selection at the top
    if (_.missing(firstInRange)) {
      inActiveRange = true;
    }
    return this.cache.getNodes().filter(({
      node
    }) => {
      return node.rowIndex >= firstInRange.rowIndex && node.rowIndex <= lastInRange.rowIndex;
    }).map(({
      node
    }) => node);
  }
  /**
   * Mutates a given array to add this stores state, and recursively add all the children store states.
   *
   * @param result a mutable results array
   */
  addStoreStates(result) {
    result.push({
      suppressInfiniteScroll: false,
      route: this.parentRowNode.getGroupKeys(),
      rowCount: this.getRowCount(),
      lastRowIndexKnown: this.isLastRowIndexKnown(),
      info: this.info,
      maxBlocksInCache: this.storeParams.maxBlocksInCache,
      cacheBlockSize: this.storeParams.cacheBlockSize
    });
    this.forEachChildStoreShallow(childStore => childStore.addStoreStates(result));
  }
  getIdSequence() {
    return this.idSequence;
  }
  getParentNode() {
    return this.parentRowNode;
  }
  getRowDetails() {
    return {
      field: this.groupField,
      group: this.group,
      leafGroup: this.leafGroup,
      level: this.level,
      parent: this.parentRowNode,
      rowGroupColumn: this.rowGroupColumn
    };
  }
  getSsrmParams() {
    return this.ssrmParams;
  }
  setStoreInfo(info) {
    if (info) {
      Object.assign(this.info, info);
    }
  }
  // gets called 1) row count changed 2) cache purged
  fireStoreUpdatedEvent() {
    // this results in row model firing ModelUpdated.
    // server side row model also updates the row indexes first
    const event = {
      type: Events.EVENT_STORE_UPDATED
    };
    this.eventService.dispatchEvent(event);
  }
  // gets called when row data updated, and no more refreshing needed
  fireRefreshFinishedEvent() {
    const event = {
      type: Events.EVENT_STORE_REFRESHED,
      route: this.parentRowNode.getRoute()
    };
    this.eventService.dispatchEvent(event);
  }
  getBlockStates() {
    return this.cache.getBlockStates();
  }
  getStoreBounds() {
    return {
      topPx: this.topPx,
      heightPx: this.heightPx
    };
  }
}
__decorate([Autowired('ssrmBlockUtils')], LazyStore.prototype, "blockUtils", void 0);
__decorate([Autowired('ssrmStoreUtils')], LazyStore.prototype, "storeUtils", void 0);
__decorate([Autowired('columnModel')], LazyStore.prototype, "columnModel", void 0);
__decorate([Autowired('selectionService')], LazyStore.prototype, "selectionService", void 0);
__decorate([PostConstruct], LazyStore.prototype, "init", null);
__decorate([PreDestroy], LazyStore.prototype, "destroyRowNodes", null);