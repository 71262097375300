import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'aux-how-it-works-modal',
  templateUrl: './how-it-works-modal.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf],
})
export class HowItWorksModalComponent {
  launchDarklyService = inject(LaunchDarklyService);

  isApManualClearingVisible$ = this.launchDarklyService.select$(
    (flags) => flags.journal_entry_ap_manual_clearing
  );
}
