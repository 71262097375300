<div
  class="flex items-center"
  [class.flex-row-reverse]="params.rowReverse"
  [class.justify-center]="params.justifyCenter"
  [class.justify-end]="params.rightAlignment"
>
  @for (icon of params.icons; track icon.icon) {
    <aux-icon
      *ngIf="icon.showIcon"
      [name]="icon.icon"
      [class]="'min-w-[24px] ' + icon.iconClass"
      [ngClass]="{
        'mr-[5px]': !params.rowReverse && !params.hideText,
        'ml-[5px]': params.rowReverse && !params.hideText
      }"
      [auxTooltip]="icon.iconTooltip"
    />
  }
  @if (!params.hideText) {
    <span [auxTooltip]="value" class="truncate" [class.flex-1]="!params.rightAlignment">{{
      value
    }}</span>
  }
</div>
