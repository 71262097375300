<!-- Category periods -->
<div
  *ngIf="data && (!data.isChild || (data.isChild && data.subCategory))"
  [attr.auto-qa]="autoTestAttribute"
>
  <!-- Period if driver=site -->
  <aux-forecast-table-grid-period-site
    *ngIf="data.driver === 'DRIVER_SITE'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="false"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      !data.primarySettingsOverride ||
      disablePeriod ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      !componentParent.isSiteDriverAvailable
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async) ||
      false
    "
    [siteCurves]="componentParent.siteCurves"
    [periodSiteOptions]="componentParent.PeriodService.PeriodSiteOptions"
    [driverSettingId]="data.driverSettingId"
  />

  <!-- Period if driver=patient -->
  <aux-forecast-table-grid-period-patient
    *ngIf="data.driver === 'DRIVER_PATIENT'"
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [periodPatientOptions]="componentParent.PeriodService.PeriodPatientOptions"
    [selectedPeriodPatientOption]="'Patient Curve'"
  />

  <!-- Period if driver=time (category) -->
  <aux-forecast-table-grid-period-time
    *ngIf="data.driver === 'DRIVER_TIME'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="false"
    [onPeriodChange]="componentParent.onPeriodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      !data.primarySettingsOverride ||
      disablePeriod ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async) ||
      false
    "
    [fullSettings]="data.fullCategory!.primary_settings"
    [timelinePhases]="componentParent.timelinePhases"
    [PeriodService]="componentParent.PeriodService"
  />

  <!-- Period if driver=custom curve -->
  @if (data.driver === 'DRIVER_CUSTOM') {
    <aux-forecast-table-grid-period-custom-curve
      [tooltip]="
        componentParent.CheckService.tooltipText(
          componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
          componentParent.userHasModifyPermissions
        )
      "
    />
  }
</div>

<!-- Activity periods -->
<div *ngIf="data && data.isChild && !data.subCategory">
  <!-- Period if driver=site -->
  <aux-forecast-table-grid-period-site
    *ngIf="data.driver === 'DRIVER_SITE'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="true"
    [onMethodChange]="componentParent.onMethodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      disablePeriod ||
      !componentParent.isSiteDriverAvailable
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isActivityUnforecasted(data.id) | async) ||
      false
    "
    [siteCurves]="componentParent.siteCurves"
    [periodSiteOptions]="componentParent.PeriodService.PeriodSiteOptions"
    [driverSettingId]="data.driverSettingId"
  />

  <!-- Period if driver=patient -->
  <aux-forecast-table-grid-period-patient
    *ngIf="data.driver === 'DRIVER_PATIENT'"
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [periodPatientOptions]="componentParent.PeriodService.PeriodPatientOptions"
    [selectedPeriodPatientOption]="'Patient Curve'"
  />

  <!-- Period if driver=time (activity) -->
  <aux-forecast-table-grid-period-time
    *ngIf="data.driver === 'DRIVER_TIME'"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [isChild]="true"
    [onPeriodChange]="componentParent.onPeriodChange"
    [categoryId]="data.id"
    [primarySettingsId]="data.primarySettingsId"
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing ||
      disablePeriod
    "
    [tooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [isInvalid]="
      (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
      (componentParent.CheckService.isActivityUnforecasted(data.id) | async) ||
      false
    "
    [fullSettings]="data.fullActivity!.primary_settings"
    [timelinePhases]="componentParent.timelinePhases"
    [PeriodService]="componentParent.PeriodService"
  />

  <!-- Period if driver=custom curve -->
  @if (data.driver === 'DRIVER_CUSTOM') {
    <aux-forecast-table-grid-period-custom-curve
      [tooltip]="
        componentParent.CheckService.tooltipText(
          componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
          componentParent.userHasModifyPermissions
        )
      "
    />
  }
</div>
