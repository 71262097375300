import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExpenseSourceType } from '@shared/services/gql.service';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

interface AgExpenseDefaultsActionComponentParams extends ICellRendererParams {
  dragDropChangeFN: (params: { rowNode: RowNode }) => void;
  processing$?: Observable<boolean>;
  editable: BehaviorSubject<boolean>;
  disabledTooltip: string;
}

@Component({
  templateUrl: 'ag-expense-defaults-drag.component.html',
  styleUrls: ['ag-expense-defaults-drag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DragDropModule, NgIf, AsyncPipe, NgClass, TooltipDirective, NgFor],
})
export class AgExpenseDefaultsDragComponent implements ICellRendererAngularComp {
  readonly bgClasses: string = 'border rounded-[5px] w-[120px] cursor-move';

  readonly bgClassesNonEditable: string = 'border rounded-[5px] w-[120px] cursor-not-allowed';

  readonly txtClasses: string = 'max-w whitespace-nowrap overflow-hidden text-ellipsis';

  params!: AgExpenseDefaultsActionComponentParams;

  dataCheck$ = new BehaviorSubject(false);

  optionData = new BehaviorSubject<ExpenseSourceType[]>([]);

  lineData: ExpenseSourceType[] = [];

  refresh(): boolean {
    return false;
  }

  agInit(params: AgExpenseDefaultsActionComponentParams) {
    this.params = params;

    this.dataCheck$.next(!!this.params.data);
    if (this.params?.data?.sources) {
      this.lineData = [...this.params.data.sources];
      this.optionData.next(this.lineData);
    }
  }

  onDragDropChange(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.lineData, event.previousIndex, event.currentIndex);
      if (this.params.dragDropChangeFN) {
        this.params.dragDropChangeFN({
          rowNode: {
            ...this.params.node,
            data: { ...this.params.node.data, sources: this.lineData },
          } as RowNode,
        });
      }
    }
  }

  get(timePeriod: ExpenseSourceType, editable: boolean | null) {
    const selectedBgClasses = editable ? this.bgClasses : this.bgClassesNonEditable;
    switch (timePeriod) {
      case ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE:
        return {
          text: 'Vendor Estimate',
          bg: `border-aux-green-dark bg-aux-gray-dark-300 bg-opacity-25 ${selectedBgClasses}`,
          txtClass: `text-aux-green-dark ${this.txtClasses}`,
        };
      case ExpenseSourceType.EXPENSE_SOURCE_FORECAST:
        return {
          text: 'Forecast',
          bg: `border-aux-blue bg-aux-blue-light bg-opacity-25 ${selectedBgClasses}`,
          txtClass: `text-aux-blue ${this.txtClasses}`,
        };
      case ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED:
        return {
          text: 'Evidence Based',
          bg: `border-aux-green-dark bg-white ${selectedBgClasses}`,
          txtClass: `text-aux-green-dark ${this.txtClasses}`,
        };
      case ExpenseSourceType.EXPENSE_SOURCE_INVOICE:
        return {
          text: 'Invoice',
          bg: `border-aux-gray-dark-1 bg-aux-gray-light-2  ${selectedBgClasses}`,
          txtClass: `text-aux-gray-dark-1 ${this.txtClasses}`,
        };
      default:
        return { text: '', bg: '', txtClass: '' };
    }
  }

  noReturnPredicate() {
    return false;
  }
}
