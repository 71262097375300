<form [formGroup]="fg" (ngSubmit)="onAddTrialSite()">
  <aux-modal primaryButtonType="submit">
    <div class="grid grid-cols-2 gap-5 w-screen px-[5px] max-w-2xl max-h-158 overflow-auto">
      <div>
        <div class="flex flex-col justify-between space-y-2">
          <aux-input
            formControlName="name"
            label="Site Name"
            validators="required"
            [showRequiredAsterisk]="true"
          />

          <aux-input
            formControlName="site_no"
            label="Site #"
            [validators]="siteNoValidators"
            [showRequiredAsterisk]="true"
          />

          <aux-input formControlName="address_line_1" label="Address Line 1" />
          <aux-input formControlName="address_line_2" label="Address Line 2" />
          <aux-input formControlName="address_line_3" label="Address Line 3" />
        </div>

        <div class="space-y-2 mt-2">
          <div class="grid grid-cols-2 gap-5">
            <aux-input formControlName="zip" label="Zip" />
            <aux-input formControlName="city" label="City" />
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input formControlName="state" label="State" />
            <div>
              <div class="text-xs">Country <span class="text-aux-error font-bold">*</span></div>
              <ng-select
                class="select select__big"
                formControlName="country"
                appendTo="body"
                label="Country"
                auxFormError
                placeholder="Country"
                [clearable]="false"
              >
                <ng-option *ngFor="let country of countries" [value]="country.value">
                  {{ country.label }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input
              formControlName="target_patients"
              label="Target Patients"
              validators="onlyInteger"
            />
            <div class="aux-select">
              <label for="vendor">Managed By <span class="text-aux-error font-bold">*</span></label>
              <ng-select
                class="select select__big"
                id="vendor"
                formControlName="managed_by_id"
                bindValue="id"
                bindLabel="name"
                label="Managed By"
                appendTo="body"
                auxFormError
                placeholder="—"
                [clearable]="false"
                [searchable]="false"
              >
                <ng-option
                  *ngFor="let organization of organizationQuery.selectAll() | async"
                  [value]="organization"
                >
                  <span [title]="organization.name">{{ organization.name }}</span>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input
              formControlName="site_activation"
              label="Site Activation"
              placeholder="YYYY-MM-DD"
              [type]="'date'"
              validators="minMaxDate"
              (blured)="onBlurSiteActivationPicker(closeoutInput)"
            />
            <div>
              <div class="text-xs">
                Currency
                <span class="text-aux-error font-bold">*</span>
              </div>
              <ng-select
                class="select select__big"
                formControlName="currency"
                bindLabel="label"
                bindValue="key"
                label="Currency"
                auxFormError
                required
                [items]="currencyOptions"
                [clearable]="false"
                [searchable]="true"
                [appendTo]="'body'"
                [multiple]="false"
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <aux-input
              #closeoutInput
              formControlName="closeout_date"
              label="Closeout Date"
              placeholder="YYYY-MM-DD"
              [type]="'date'"
              validators="minMaxDate"
              [dateCompareValidation]="{
                startDate: fg.get('site_activation')!.value ?? '',
                endDate: ''
              }"
              dateCompareError="site_closeout"
            />
          </div>
          <div>
            <div class="font-semibold my-2">Primary Investigator</div>
            <div class="flex space-x-4">
              <aux-input formControlName="given_name" class="flex-1" [label]="'First Name'" />
              <aux-input formControlName="family_name" class="flex-1" [label]="'Last Name'" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="mb-1 text-xs">Contracts & Payment Terms</div>
        <aux-file-manager #fileManager class="h-24" [eager]="false" />

        <div class="max-h-158 overflow-auto mt-4">
          <aux-file-viewer
            [fileManager]="fileManager"
            [onlyShowUploaded]="false"
            [disableFirstFileMargin]="true"
          />
        </div>
      </div>
    </div>
  </aux-modal>
</form>
