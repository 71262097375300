import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  input,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { generateUUID } from '@datadog/browser-core';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from '@shared/components/input/input.component';
import { delay, filter, tap } from 'rxjs';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface Option {
  id: string;
  value: string;
  disabled?: boolean;
}

interface RawSelectCompomnent {
  _handleArrowDown: (event: KeyboardEvent) => void;
  _handleArrowUp: (event: KeyboardEvent) => void;
}

const NEW_OPTION_ID = generateUUID();

const SEARCH_OPTION_ID = generateUUID();

@Component({
  selector: 'aux-extendable-options-dropdown',
  templateUrl: 'extendable-options-dropdown.component.html',
  styleUrl: './extendable-options-dropdown.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSelectModule, ReactiveFormsModule, InputComponent, TooltipDirective],
})
export class ExtendableOptionsDropdownComponent implements AfterViewInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  items = input<Option[]>([]);

  @Input() trackByFn = this.defaultTrackByFn;

  @Input() openOnInit = false;

  @Input() openOnEnter = false;

  @Input() showTooltips = true;

  @Input() resetValueOnEscapePress = false;

  @Input() placeholder = 'Select or add new';

  @Input() initialSearchTerm = '';

  @Input() dropdownControl = new FormControl<string | null>(null);

  @Input() onChangedReturnOption = false;

  @Output() clickOut = new EventEmitter();

  @Output() confirm = new EventEmitter<string | undefined>();

  @Output() changed = new EventEmitter<string | Option | undefined>();

  @Output() addOption = new EventEmitter();

  @ViewChild(NgSelectComponent) selectCompomnent!: NgSelectComponent;

  @ViewChild(InputComponent) searchComponent!: InputComponent;

  filterText = new FormControl<string>('');

  isOpen = false;

  options = computed<Option[]>(() => {
    return [
      {
        id: SEARCH_OPTION_ID,
        value: SEARCH_OPTION_ID,
        disabled: true,
      },
      ...this.dynamicOptions(),
    ];
  });

  private elementRef = inject(ElementRef);

  private changeDetectorRef = inject(ChangeDetectorRef);

  private preventGlobalKeyEvents = false;

  private openOnInitTimer!: number;

  private initialValue!: string;

  private filter = signal<string>('');

  readonly newOptionId = NEW_OPTION_ID;

  readonly searchOptionId = SEARCH_OPTION_ID;

  readonly tooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -11,
      panelClass: 'expandable-options-tooltip',
    },
  ];

  private dynamicOptions = computed<Option[]>(() => {
    const filtered = this.items().filter((item) =>
      item.value.trim().toLowerCase().includes(this.filter().trim().toLowerCase())
    );
    return filtered.some(
      (option) => option.value.trim().toLowerCase() === this.filter().trim().toLowerCase()
    )
      ? filtered
      : ([
          ...filtered,
          this.filter().trim() && {
            id: NEW_OPTION_ID,
            value: this.filter(),
          },
        ].filter(Boolean) as Option[]);
  });

  @HostListener('document:click', ['$event'])
  public onClick(e: MouseEvent): void {
    const panel = document.querySelector('.ng-dropdown-panel.extendable-options-dropdown');
    const clickedInside =
      panel?.contains(e.target as Node | null) || this.elementRef.nativeElement.contains(e.target);
    if (!clickedInside) {
      this.clickOut.emit();
      this.setIsOpen(false);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  @HostListener('document:keydown.enter', ['$event'])
  public onGlobalKeyEnter({ key }: KeyboardEvent): void {
    if (!this.selectCompomnent?.isOpen && !this.preventGlobalKeyEvents) {
      if (this.resetValueOnEscapePress && key === 'Escape') {
        this.dropdownControl.setValue(this.initialValue ?? '');
      }
      this.confirm.emit();
    }
  }

  @HostListener('mousedown')
  public onMouseDown(): void {
    this.setIsOpen(!this.isOpen);
  }

  ngOnDestroy(): void {
    clearTimeout(this.openOnInitTimer);
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setIsOpenRecursively(this.openOnInit);
    this.initialValue = this.dropdownControl.value ?? '';
    this.filterText.setValue(this.initialSearchTerm);
    this.filterText.valueChanges
      .pipe(
        tap((value) => this.filter.set(value || '')),
        delay(0),
        filter(() => !!this.selectCompomnent.itemsList.markedItem),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(this.clearMarked);
  }

  close(): void {
    this.setIsOpen(false);
  }

  setIsOpen(isOpen: boolean): void {
    this.isOpen = isOpen;
    if (isOpen) {
      this.onOpen();
    } else {
      this.onClose();
    }

    setTimeout(() => this.detectChanges());
  }

  onSearchKeyDown(event: KeyboardEvent): void {
    this.preventGlobalKeyEvents = true;

    const { key } = event;
    if (key === 'Escape') {
      this.close();
    }

    if (key === 'Enter' && this.dynamicOptions().length) {
      this.selectCompomnent.itemsList.select(this.selectCompomnent.itemsList.markedItem);
      this.dropdownControl.setValue(this.selectCompomnent.itemsList.markedItem.value.value);
      this.onChange(this.selectCompomnent.itemsList.markedItem.value);
      this.close();
    }

    if (key === 'ArrowDown') {
      this.rawSelectComponentImpl._handleArrowDown(event);
    }

    if (key === 'ArrowUp') {
      this.rawSelectComponentImpl._handleArrowUp(event);
    }

    setTimeout(() => (this.preventGlobalKeyEvents = false));
  }

  onClear(): void {
    this.searchComponent?.input?.nativeElement.focus();
  }

  onClose(): void {
    this.selectCompomnent?.itemsList.unmarkItem();
  }

  onChange(item?: Option): void {
    this.setIsOpen(false);
    if (item?.id === NEW_OPTION_ID) {
      this.addOption.emit({
        id: item.value,
        value: item.value,
      });
      this.filterText.reset('');
    }
    this.changed.emit(this.onChangedReturnOption ? item : item?.value);
  }

  optionMouseDown(opt: Option, event: MouseEvent) {
    if (opt.value === this.dropdownControl.value) {
      event.stopPropagation();
      return;
    }
  }

  onOpen = () =>
    setTimeout(() => {
      const lastMarkedOrSelected =
        this.selectCompomnent?.itemsList.markedItem ||
        this.selectCompomnent?.itemsList.selectedItems?.[0];
      if (lastMarkedOrSelected) {
        this.clearMarked();
        setTimeout(() => this.selectCompomnent.itemsList.markItem(lastMarkedOrSelected));
      }
    });

  getItemValue(item: Option): string {
    return item.id === NEW_OPTION_ID ? item.value + ' (New)' : item.value;
  }

  detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  private setIsOpenRecursively(isOpen: boolean): void {
    if (this.isOpen !== isOpen) {
      this.setIsOpen(this.openOnInit);
      this.openOnInitTimer = setTimeout(() => {
        this.setIsOpenRecursively(isOpen);
      }, 50) as unknown as number;
    }
  }

  private defaultTrackByFn(_: number, option: Option): string {
    return option?.id;
  }

  private clearMarked = (): void => {
    setTimeout(() => {
      if (this.selectCompomnent.itemsList.markedItem) {
        this.selectCompomnent.itemsList.markedItem.selected = false;
      }
      this.selectCompomnent.detectChanges();
      this.changeDetectorRef.detectChanges();
    });
  };

  private get rawSelectComponentImpl(): RawSelectCompomnent {
    return this.selectCompomnent as unknown as RawSelectCompomnent;
  }
}
