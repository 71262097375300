<aux-map-activities-modal-total-row [invoice]="invoice" [totals]="totals()" />

<div class="flex justify-end my-4">
  <aux-button
    variant="secondary"
    icon="Plus"
    label="Add New Activity"
    classList="!border-none h-[35px]"
    (clickEmit)="addNewRow()"
  />
</div>

@if (this.loading) {
  <div class="flex h-[265px] w-[992px]">
    <div class="border-8 h-32 m-auto spinner w-32"></div>
  </div>
} @else {
  <div class="w-[992px]">
    <ag-grid-angular
      class="ag-theme-aux unselectable tabular-nums h-[265px]"
      [rowData]="gridData"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)"
    />
  </div>
}

<div class="w-[500px] mt-[30px]">
  <div class="flex items-center justify-between space-x-1">
    <span class="mr-1">Comments</span>

    <span class="text-aux-gray-dark-2"> {{ this.noteControl.value?.length || 0 }} / 1000 </span>
  </div>

  <aux-input
    placeholder="Enter comments here..."
    [textArea]="true"
    [rows]="5"
    resize="none"
    class="mt-1 textarea-input"
    [maxlength]="1000"
    [formControl]="noteControl"
  />
</div>

@if (ref.data?.lastUpdated) {
  <div class="mt-2">Last Updated: {{ ref.data?.lastUpdated }}</div>
}
