import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { ExportType, Utils } from '@shared/utils/utils';
import { decimalAdd } from '@shared/utils';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { PrepaidsGridOptions } from '@pages/vendor-payments-page/tabs/prepaids/prepaids.constants';
import { Router } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { switchMap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { uniq } from 'lodash-es';
import { EventService } from '@models/event/event.service';
import {
  EntityType,
  EventType,
  getPrepaidsSummaryQuery,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { NgIf } from '@angular/common';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import dayjs from 'dayjs';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { EventQuery } from '@models/event/event.query';
import { AuthService } from '@shared/store/auth/auth.service';

@Component({
  selector: 'aux-prepaids',
  templateUrl: './prepaids.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AgGridAngular,
    NgIf,
    TableSkeletonComponent,
    IconComponent,
    ExportExcelButtonComponent,
    WorkflowPanelComponent,
    StickyGridDirective,
  ],
})
export class PrepaidsComponent {
  router = inject(Router);
  mainQuery = inject(MainQuery);
  gqlService = inject(GqlService);
  eventService = inject(EventService);
  overlayService = inject(OverlayService);
  workflowQuery = inject(WorkflowQuery);
  launchDarklyService = inject(LaunchDarklyService);
  eventQuery = inject(EventQuery);
  authService = inject(AuthService);

  readonly workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  gridAPI!: GridApi;

  gridData = signal<getPrepaidsSummaryQuery[]>([]);

  loadingGridData = signal<boolean>(false);

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  goToPrepaidDetailPage = (event: CellClickedEvent): void => {
    if (event.data?.vendor_id) {
      this.router.navigateByUrl(
        `${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PREPAIDS}/${event.data?.vendor_id}`
      );
    }
  };

  gridOptions: GridOptions = PrepaidsGridOptions(this.goToPrepaidDetailPage);

  userHasLockPrepaidPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_INVOICES],
  });

  constructor() {
    this.onTrialChange();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    api.sizeColumnsToFit();
    this.setBottomData();
  }

  onExport = async () => {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.PREPAID_SUMMARY,
          filename: `${trialName}_auxilius-prepaids_${dateStr}`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  };

  private setBottomData(): void {
    const totals = this.calculatePinnedBottomData();

    if (
      totals.total_prepaid_balance === 0 &&
      totals.long_term_amount === 0 &&
      totals.short_term_amount === 0
    ) {
      this.gridAPI.setGridOption('pinnedBottomRowData', []);
    } else {
      this.gridAPI.setGridOption('pinnedBottomRowData', [
        {
          vendor_id: 'Total',
          ...totals,
        },
      ]);
    }
  }

  private calculatePinnedBottomData(): {
    total_prepaid_balance: number;
    long_term_amount: number;
    short_term_amount: number;
  } {
    const uniqVendorCurrencies = uniq(this.gridData().map((row) => row.vendor_currency));
    const columnsWithAggregation = [
      'total_prepaid_balance',
      'long_term_amount',
      'short_term_amount',
    ] as const;

    if (uniqVendorCurrencies.length > 1) {
      return {
        total_prepaid_balance: 0,
        long_term_amount: 0,
        short_term_amount: 0,
      };
    }

    return this.gridData().reduce(
      (acc, row) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          const additionalVal = row[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = decimalAdd(currentVal, additionalVal);
          }
        }

        return acc;
      },
      {
        total_prepaid_balance: 0,
        long_term_amount: 0,
        short_term_amount: 0,
      }
    );
  }

  onTrialChange(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.loadingGridData.set(true);
          return this.gqlService.getPrepaidsSummary$();
        })
      )
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        if (result.success) {
          this.gridData.set(result.data || []);
        }
        this.loadingGridData.set(false);
      });
  }
}
