<div class="w-screen max-w-4xl">
  <div class="text-xl font-bold mb-4">Create New Scenario</div>
  <div class="text-xxs">Select Type:</div>

  <div class="flex space-x-4 my-5 text-xs">
    <div *ngFor="let type of types; index as i" class="flex items-center flex-1">
      <input
        name="phases"
        type="radio"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        [id]="type"
        [value]="type"
        [(ngModel)]="selectedTab"
        (change)="activeTabIndex = i"
      />
      <label class="ml-2" [for]="type">
        <span class="block text-sm font-medium text-gray-700">{{ type }}</span>
      </label>
    </div>
  </div>

  <div *ngIf="activeTabIndex === 0" class="text-xs">
    <p class="mb-4 font-medium">Select a milestone and edit the start date and length:</p>
    <div class="grid grid-cols-4 gap-5">
      <div>
        <div class="mb-3">Milestone</div>
        <aux-select [options]="milestoneOptions" />
      </div>
      <div>
        <div class="mb-3">Starting Date</div>
        <aux-input class="tabular-nums" [type]="'date'" />
      </div>
      <div>
        <div class="mb-3"># of Days</div>
        <aux-input class="w-16 tabular-nums" [type]="'number'" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-5 mt-5">
      <div
        *ngFor="let table of tables"
        class="rounded border p-4"
        [ngClass]="{ 'bg-aux-gray-light': table.gray }"
      >
        <div
          class="text-sm leading-5 mb-4 font-bold"
          [ngClass]="{
            'text-aux-black': !table.gray,
            'text-aux-blue': table.gray
          }"
        >
          {{ table.header }}
        </div>
        <div class="grid grid-cols-3 gap-5 text-xs tabular-nums">
          <div class="text-aux-gray-lightest text-xxs">Milestone</div>
          <div class="text-aux-gray-lightest text-xxs">Starting Date</div>
          <div class="text-aux-gray-lightest text-xxs">Length</div>

          <ng-container *ngFor="let data of table.data">
            <div>{{ data.milestone }}</div>
            <div>{{ data.date }}</div>
            <div>{{ data.length }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="activeTabIndex === 1">
    <div class="grid grid-cols-2 gap-5 text-xs font-medium w-1/2 items-center">
      <div>Total # of patients</div>
      <aux-input class="w-40 tabular-nums" />

      <div>Enrolled patients per site <br />per month</div>
      <aux-input class="w-40" />

      <div>By Site</div>
      <aux-input class="w-40" />

      <div>Select a preset</div>
      <aux-input class="w-40" />
    </div>
  </ng-container>

  <ng-container *ngIf="activeTabIndex === 2">
    <div class="text-xs mb-4 font-medium">Edit Site Addition Drivers</div>
    <div class="grid grid-cols-12 gap-5 text-xs w-10/12">
      <div class="col-span-2">
        <div class="mb-3 text-xxs"># Sites</div>
        <aux-input class="tabular-nums" [type]="'number'" />
      </div>
      <div class="col-span-5">
        <div class="mb-3 text-xxs">Initiation Time</div>
        <ng-select class="select select__big tabular-nums" />
      </div>
      <div class="col-span-5">
        <div class="mb-3 text-xxs">Enrollment Rate</div>
        <ng-select class="select select__big tabular-nums" />
      </div>

      <div class="col-span-4">
        <div class="mb-3 text-xxs">Per Patient Cost</div>
        <ng-select class="select select__big tabular-nums" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTabIndex === 3">
    <div class="grid grid-cols-4 gap-3 mb-3 text-aux-gray-lightest text-xs font-medium w-8/12">
      <div>Cost<br />Quadrant</div>
      <div>Avg. cost <br />per patient</div>
      <div>Current <br /># sites</div>
      <div>Current <br /># patients</div>
    </div>

    <div class="grid grid-cols-4 gap-3 items-center mb-2 text-xs w-8/12">
      <ng-container *ngFor="let quadrant of quadrants">
        <div class="text-xxs">{{ quadrant }}</div>
        <aux-input />
        <aux-input />
        <aux-input />
      </ng-container>
    </div>
  </ng-container>

  <div class="flex space-x-4 items-center text-xs mb-5 mt-10">
    <button type="button" class="btn btn--blue w-24">Create</button>
    <button class="btn--tertiary" type="button">Cancel</button>
  </div>
</div>
