import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  inject,
  ViewChild,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { BaseFormControlComponent } from '@shared/components/base-form-control/base-form-control';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { TrialStatusMap } from '@shared/constants/status.constants';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { Trial, TrialImplementationStatus } from '@shared/services/gql.service';
import { RequireSome } from '@shared/utils/utils';
import { selectWithGuardChangesModalCheck } from '@shared/utils/select-option';
import { OverlayService } from '@shared/services/overlay.service';
import { UnsavedChangesHelperService } from '@shared/services/unsaved-changes-helper.service';
import { MainQuery } from '@shared/store/main/main.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isObject } from 'lodash';
import { asyncScheduler, filter } from 'rxjs';

@Component({
  selector: 'aux-trial-dropdown',
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, CommonModule, TooltipDirective],
  templateUrl: './trial-dropdown.component.html',
  styleUrls: ['trial-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialDropdownComponent extends BaseFormControlComponent<string | null> {
  @Input() trials: (
    | (Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
        sponsor_organization: { id: string; name: string };
      })
    | {
        id: string | undefined;
        short_name: string | undefined;
        onboarding_complete: boolean | undefined;
        implementation_status?: string;
      }
  )[] = [];

  @Input() searchable = false;

  @Input() trialFormControl = new UntypedFormControl('');

  @Input() mainPage = true;

  @ViewChild('trialDropdownEl') trialDropdownEl!: NgSelectComponent;

  tooltipPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 8,
  } as ConnectedPosition;

  tooltipColor = '';

  tooltipValue = '';

  trialStatusMap = TrialStatusMap;

  private readonly overlayService = inject(OverlayService);

  private readonly unsavedChangesHelperService = inject(UnsavedChangesHelperService);

  constructor(private mainQuery: MainQuery) {
    super();
    this.mainQuery
      .select('trialKey')
      .pipe(takeUntilDestroyed(), filter(Boolean))
      .subscribe(() => this.setSearchTermManualy('', false));
  }

  async selectTrial(event: MouseEvent, value: string): Promise<void> {
    selectWithGuardChangesModalCheck(
      event,
      value,
      this.unsavedChangesHelperService.getUnsavedChangesDecisionByRouterUrl(),
      this.overlayService,
      this.trialFormControl,
      this.trialDropdownEl
    );
  }

  selectionChanged(event: string | { short_name: string }) {
    if (isObject(event)) {
      this.setSearchTermManualy(event.short_name);
      return;
    }
    const existingTrial = this.trials.find((trial) => trial.id === event);
    if (existingTrial) {
      this.setSearchTermManualy(existingTrial.short_name);
    }
  }

  typecastingForTrialStatus(item_status: unknown): TrialImplementationStatus {
    const implementation_status = item_status as TrialImplementationStatus;
    return implementation_status;
  }

  mouseEnter(implementation_status: TrialImplementationStatus) {
    this.tooltipColor = this.trialStatusMap[implementation_status].color;
    this.tooltipValue = this.trialStatusMap[implementation_status].tooltipValue;
  }

  setSearchTermManualy(searchTerm: string | undefined, async = true) {
    if (!this.trialDropdownEl) {
      return;
    }

    const setSearchTerm = () => {
      this.trialDropdownEl.searchTerm = searchTerm || '';
      this.trialDropdownEl.blur();
      this.trialDropdownEl.detectChanges();
    };

    if (async) {
      asyncScheduler.schedule(setSearchTerm);
      return;
    }

    setSearchTerm();
  }
}
