import { inject, Injectable, signal } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@shared/services/overlay.service';
import { flatten, set, isString, uniq, cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';
import {
  AmountType,
  Currency,
  DataSource,
  EntityType,
  GqlService,
  InvoiceStatus,
  listUserNamesWithEmailQuery,
  MonthlyExchangeRate,
  NoteType,
  DocumentType,
  PaymentStatus,
  User,
  WorkflowDetail,
  UpdateWorkflowInput,
  UpdateInvoiceInput,
  Note,
  EventType,
  ApprovalType,
} from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { Utils } from '@shared/utils/utils';
import { CellClickedEvent, GridApi, ValueSetterParams, IRowNode } from '@ag-grid-community/core';
import { ApiService } from '@shared/services/api.service';
import { BehaviorSubject, combineLatest, firstValueFrom, merge } from 'rxjs';
import { Router } from '@angular/router';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { FormControl, FormGroup } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Option } from '@shared/types/components.type';
import { OrganizationService } from '@models/organization/organization.service';
import { InvoiceCard, InvoiceModel } from './invoice.model';
import { InvoiceStore } from './invoice.store';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { PurchaseOrdersService } from '../../purchase-orders/state/purchase-orders.service';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { decimalAddAll, decimalEquality, batchAPIRequest, batchPromises } from '@shared/utils';
import { BudgetCurrencyType } from '@models/budget-currency/budget-currency.model';
import { BudgetCurrencyQuery } from '@models/budget-currency/budget-currency.query';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceQuery } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.query';
import { EventService } from '@models/event/event.service';
import { IInvoicesGridData } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';

interface InvoiceFormGroup {
  search: FormControl<string | null>;
  vendors: FormControl<string[] | null>;
  accrualPeriod: FormControl<string[] | null>;
  invoiceDate: FormControl<string | null>;
  has_prepaid: FormControl<string | null>;
  is_deposit: FormControl<string | null>;
  does_invoice_mappings_match_total: FormControl<string | null>;
  services_period: FormControl<string[] | null>;
}

const getCostAsNumber = (cost: string | number) => Number(Number(cost).toFixed(2));

export const isInvoiceTotalEqual = (data: InvoiceModel) => {
  const invoice_total = getCostAsNumber(data.expense_amounts.invoice_total.value);

  const item_totals = decimalAddAll(
    15,
    getCostAsNumber(data.expense_amounts.services_total.value),
    getCostAsNumber(data.expense_amounts.discount_total.value),
    getCostAsNumber(data.expense_amounts.investigator_total.value),
    getCostAsNumber(data.expense_amounts.pass_thru_total.value)
  );

  return decimalEquality(invoice_total, item_totals, 2);
};

@Injectable({ providedIn: 'root' })
export class InvoiceService {
  invoiceQuery = inject(InvoiceQuery);

  trialMonthClose = '';

  trialStartDate = '';

  trialEndDate = '';

  gridAPI!: GridApi;

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  invoiceDesignationChanged$ = new BehaviorSubject(false);

  filtersForm = new FormGroup<InvoiceFormGroup>({
    search: new FormControl(''),
    vendors: new FormControl([] as string[]),
    accrualPeriod: new FormControl([] as string[]),
    invoiceDate: new FormControl(''),
    has_prepaid: new FormControl(null),
    is_deposit: new FormControl(null),
    does_invoice_mappings_match_total: new FormControl(null),
    services_period: new FormControl([] as string[]),
  });

  showRequireCostBreakdown = signal<boolean>(false);

  showRequireAccrualPeriod = signal<boolean>(false);

  showUnpaidInvoices = signal<boolean>(false);

  showInvalidCostCategorization = signal<boolean>(false);

  vendorOptions: Option[] = [];

  accrualPeriodOptions: Option[] = [];

  servicePeriodOptions: Option[] = [];

  newInvoiceCreated$ = new BehaviorSubject(false);

  invoiceStatusOptions = [
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_DECLINED,
  ];

  paymentStatusOptions = [
    null,
    PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL,
    PaymentStatus.PAYMENT_STATUS_UNPAID,
  ];

  selectedServicePeriodOptions = signal<string[]>([]);

  invoiceUrl = `${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`;

  constructor(
    private apiService: ApiService,
    private invoiceStore: InvoiceStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private router: Router,
    public authQuery: AuthQuery,
    public organizationQuery: OrganizationQuery,
    public organizationService: OrganizationService,
    public purchaseOrdersService: PurchaseOrdersService,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private budgetCurrencyQuery: BudgetCurrencyQuery,
    private eventService: EventService
  ) {
    this.filtersForm.setValue({
      search: '',
      vendors: [],
      accrualPeriod: [],
      invoiceDate: '',
      is_deposit: null,
      has_prepaid: null,
      does_invoice_mappings_match_total: null,
      services_period: [],
    });
    this.resetFiltersAfterTrialChange();
    this.initTrialChanges()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.isInInvoiceDetail(this.router.url)) {
          this.router.navigateByUrl(this.invoiceUrl);
        }
      });
  }

  initTrialChanges() {
    return combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ]);
  }

  isInInvoiceDetail(url: string) {
    return url.includes('invoices/');
  }

  resetFiltersAfterTrialChange() {
    this.eventService.select$(EventType.TRIAL_CHANGED).subscribe(() => {
      this.filtersForm.setValue({
        search: '',
        vendors: [],
        accrualPeriod: [],
        invoiceDate: '',
        is_deposit: null,
        has_prepaid: null,
        does_invoice_mappings_match_total: null,
        services_period: [],
      });
    });
  }

  initialize() {
    return merge(
      this.organizationService.listIdOrganizations$().pipe(
        tap(() => {
          this.vendorOptions = this.organizationQuery
            .getAllVendors()
            .map(({ id, name }) => ({ value: id, label: name || '' }));
        })
      ),
      this.purchaseOrdersService.get(),
      this.gqlService.getTrialInformation$().pipe(
        tap(({ data: trials }) => {
          if (trials?.length) {
            this.trialMonthClose = dayjs(trials[0].trial_month_close).format('YYYY-MM');
            this.trialStartDate = dayjs(trials[0].trial_start_date).format('YYYY-MM');
            this.trialEndDate = dayjs(trials[0].trial_end_date).format('YYYY-MM');
            this.accrualPeriodOptions = this.getAccrualPeriodOptions(
              trials[0].trial_start_date,
              trials[0].trial_end_date
            );
            this.servicePeriodOptions = this.getAccrualPeriodOptions(
              trials[0].trial_start_date,
              trials[0].trial_end_date
            );
          }
        })
      ),
      this.mainQuery.select('userList').pipe(
        tap((_users) => {
          _users.forEach((user: listUserNamesWithEmailQuery) => {
            this.users.set(user.sub, user);
          });
        })
      )
    );
  }

  getOne(id: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.invoiceStore.setLoading(true);

        return this.gqlService.getInvoice$(id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          const { workflow_details } = data;

          const invReq = {
            ...data,
            ...{
              cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
              po_reference: data.purchase_order_id,
              expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
              line_items: JSON.parse(data?.line_items || '[]'),
              ocr_line_items: JSON.parse(data?.ocr_line_items || '[]'),
            },
          } as unknown as InvoiceModel;
          if (data.reasons && data.reasons.length > 0) {
            invReq.decline_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DECLINE_REASON)
                ?.message || ''
            );
            invReq.delete_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DELETE_REASON)
                ?.message || ''
            );
          }
          this.invoiceStore.upsert(invReq.id, invReq);
        } else {
          this.overlayService.error(errors);
        }
        this.invoiceStore.setLoading(false);
      }),
      switchMap(() => {
        return this.invoiceQuery.selectEntity(id);
      })
    );
  }

  setGridApi(api: GridApi) {
    this.gridAPI = api;
  }

  workflowToCards(invoice_workflow: Array<WorkflowDetail>): InvoiceCard[] {
    return invoice_workflow.map((workflow) => {
      const obj = JSON.parse(workflow.properties || '') as { properties: InvoiceCard };

      return { ...obj.properties, id: workflow.id || '' };
    });
  }

  setShowUnpaidInvoices(bool: boolean) {
    this.showUnpaidInvoices.set(bool);
  }

  setSelectedServicePeriodOptions(options: string[]): void {
    this.selectedServicePeriodOptions.set(options);
  }

  setAccrualPeriodsAndVendorFilter(accrualPeriods?: string[], vendor_id?: string) {
    this.filtersForm.setValue({
      search: '',
      vendors: vendor_id ? [vendor_id] : [],
      accrualPeriod: accrualPeriods ? accrualPeriods : [],
      invoiceDate: '',
      is_deposit: null,
      has_prepaid: null,
      does_invoice_mappings_match_total: null,
      services_period: [],
    });
  }

  mapExpenseAmountToObject(
    expense_amounts: {
      amount?: number | null | undefined;
      contract_curr?: string | null | undefined;
      contract_amount?: number | null | undefined;
      exchange_rate?: MonthlyExchangeRate | null | undefined;
      amount_type: string;
    }[]
  ) {
    const getAmount = (amountType: AmountType | string, is_vendor_currency_amount: boolean) => {
      const filteredAmounts = expense_amounts.filter((x) => x.amount_type === amountType)[0];
      const value = is_vendor_currency_amount
        ? filteredAmounts?.contract_amount
        : filteredAmounts?.amount;
      if (is_vendor_currency_amount) {
        return {
          value: value || 0,
          type: amountType,
          is_vendor_currency_amount,
          contract_curr: filteredAmounts?.contract_curr
            ? (filteredAmounts.contract_curr?.replace('CURRENCY_', '') as Currency)
            : Currency.USD,
        };
      } else {
        return {
          value: value || 0,
          type: amountType,
          is_vendor_currency_amount,
          exchange_rate: filteredAmounts?.exchange_rate?.rate || 1,
        };
      }
    };

    return {
      invoice_total: getAmount('AMOUNT_TOTAL', true),
      pass_thru_total: getAmount(AmountType.AMOUNT_PASSTHROUGH, true),
      services_total: getAmount(AmountType.AMOUNT_SERVICE, true),
      discount_total: getAmount(AmountType.AMOUNT_DISCOUNT, true),
      investigator_total: getAmount(AmountType.AMOUNT_INVESTIGATOR, true),
      invoice_total_trial_currency: getAmount('AMOUNT_TOTAL', false),
      pass_thru_total_trial_currency: getAmount(AmountType.AMOUNT_PASSTHROUGH, false),
      services_total_trial_currency: getAmount(AmountType.AMOUNT_SERVICE, false),
      discount_total_trial_currency: getAmount(AmountType.AMOUNT_DISCOUNT, false),
      investigator_total_trial_currency: getAmount(AmountType.AMOUNT_INVESTIGATOR, false),
    };
  }

  async add({
    id,
    organization_id,
    po_reference,
    bucket_keys,
  }: {
    id: string;
    organization_id: string;
    po_reference: string | null;
    bucket_keys: string[];
  }) {
    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createInvoice$({ id, organization_id, po_reference, bucket_keys })
    );
    let invoice: InvoiceModel | null = null;
    if (success && data) {
      const { workflow_details } = data;

      invoice = {
        ...data,
        ...{
          cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
          po_reference: data.purchase_order_id,
          expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
        },
      } as unknown as InvoiceModel;

      this.invoiceStore.add(invoice);
      this.newInvoiceCreated$.next(true);
    }

    return { errors, success, data: invoice };
  }

  async batchSaveInvoices(
    checkIfInvoiceStatusChanged: (invoice: IInvoicesGridData) => boolean,
    getInvoiceStatusChangeReason: (invoice: InvoiceModel, status: InvoiceStatus) => string | null,
    rowsToDelete: { invoice: InvoiceModel; reason: string }[],
    changedRows: IInvoicesGridData[],
    isNewPrepaidImpactRows = false,
    shouldShowMarkedAsUnpaidModal: boolean,
    successSaveModalMessage: string,
    initialValues: IInvoicesGridData[]
  ) {
    const apiErrors: string[] = [];
    const mapError = (result: boolean | { success: boolean; errors: string[] } | Error) => {
      if (result instanceof Error) {
        apiErrors.push(result.message);
      } else if (typeof result === 'object' && result?.success) {
        apiErrors.push(...result.errors);
      }
    };

    if (rowsToDelete.length) {
      const deleteResults = await batchPromises(
        rowsToDelete,
        ({ invoice, reason }) => {
          return this.remove(invoice, reason);
        },
        5
      );

      deleteResults.forEach(mapError);
    }

    if (changedRows.length) {
      const invoicesWithApproveRules = changedRows.filter(
        (invoice) =>
          [InvoiceStatus.STATUS_APPROVED, InvoiceStatus.STATUS_DECLINED].includes(
            invoice.invoice_status
          ) && checkIfInvoiceStatusChanged(invoice)
      );

      const approveResults = await batchPromises(
        invoicesWithApproveRules,
        ({ invoice_status, id }) => {
          return firstValueFrom(
            this.gqlService.approveRule$({
              approved: invoice_status === InvoiceStatus.STATUS_APPROVED,
              comments: '',
              permission: 'PERMISSION_APPROVE_INVOICE',
              approval_type: ApprovalType.APPROVAL_INVOICE,
              entity_id: id,
              entity_type: EntityType.INVOICE,
              activity_details: '{}',
            })
          );
        },
        5
      );

      approveResults.forEach(mapError);

      await this.batchUpdate(
        changedRows.map((invoice) => {
          let formatted_previous_services_period = null;
          const previous_services_period = initialValues.find(
            (value) => value.id === invoice.id
          )?.services_period;
          if (previous_services_period) {
            formatted_previous_services_period =
              dayjs(previous_services_period).format('YYYY-MM-DD');
          }
          return {
            ...invoice,
            po_reference: invoice.po_reference || null,
            accrual_period: invoice.accrual_period
              ? dayjs(invoice.accrual_period).format('YYYY-MM-DD')
              : null,
            services_period: invoice.services_period
              ? dayjs(invoice.services_period).format('YYYY-MM-DD')
              : null,
            previous_services_period: formatted_previous_services_period,
            invoice_date: invoice.invoice_date || null,
            due_date: invoice.due_date || null,
            payment_date: invoice.payment_date || null,
            decline_reason: getInvoiceStatusChangeReason(invoice, InvoiceStatus.STATUS_DECLINED),
            admin_review_reason: getInvoiceStatusChangeReason(
              invoice,
              InvoiceStatus.STATUS_PENDING_REVIEW
            ),
          };
        }),
        false
      );
    }

    if (apiErrors.length) {
      this.overlayService.error(MessagesConstants.SOME_CHANGES_ARE_NOT_SAVED);
    } else {
      this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);

      if (isNewPrepaidImpactRows || shouldShowMarkedAsUnpaidModal) {
        const resp = this.overlayService.openPopup<
          ConfirmationActionModalData,
          boolean,
          ConfirmationActionModalComponent
        >({
          modal: ConfirmationActionModalComponent,
          settings: {
            header: 'Adjust Prepaids?',
            primaryButton: {
              label: 'Adjust Prepaids',
            },
            secondaryButton: {
              label: 'Close',
            },
          },
          data: {
            message: successSaveModalMessage,
            skipKeywordConfirmation: true,
            hideConfirmationHint: true,
          },
        });

        const event = await firstValueFrom(resp.afterClosed$);
        if (event.data) {
          await this.router.navigate([
            `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PREPAIDS}`,
          ]);
        }
      }
    }
  }

  async batchUpdate(invoices: InvoiceModel[], showSuccessMessages = true, setLoading = true) {
    const invoiceMaxBatchSize = 50;
    const workflowMaxBatchSize = 200;

    if (setLoading) {
      this.invoiceStore.setLoading(true);
    }

    const workflowInputs: UpdateWorkflowInput[] = [];
    invoices.forEach((invoice) => {
      invoice.cards.forEach((card) => {
        const { id, lines, status, header, note } = card;
        workflowInputs.push({
          id,
          properties: JSON.stringify({ properties: { lines, status, header, note } }),
        });
      });
    });

    const workflowResponses = await batchAPIRequest(
      workflowInputs,
      this.gqlService.batchUpdateWorkflowDetails$.bind(this),
      workflowMaxBatchSize
    );

    // check if everything is ok
    if (workflowResponses.success) {
      const invoiceInputs: UpdateInvoiceInput[] = [];

      invoices.forEach((invoice) => {
        invoiceInputs.push({
          accrual_period: invoice.accrual_period,
          services_period: invoice.services_period,
          previous_services_period: invoice.previous_services_period,
          id: invoice.id,
          invoice_no: invoice.invoice_no,
          invoice_date: invoice.invoice_date,
          po_reference: invoice.po_reference,
          invoice_status: invoice.invoice_status,
          due_date: invoice.due_date,
          organization_id: invoice.organization.id,
          payment_status: invoice.payment_status,
          payment_date: invoice.payment_date,
          decline_reason: invoice.decline_reason,
          admin_review_reason: invoice.admin_review_reason,
          is_deposit: invoice.is_deposit,
          total_amount: invoice.expense_amounts.invoice_total?.value || 0,
          service_amount: invoice.expense_amounts.services_total?.value || 0,
          investigator_amount: invoice.expense_amounts.investigator_total?.value || 0,
          passthrough_amount: invoice.expense_amounts.pass_thru_total?.value || 0,
          discount_amount: invoice.expense_amounts.discount_total?.value || 0,
          has_prepaid: invoice.has_prepaid,
        });
      });

      const invoiceResponses = await batchAPIRequest(
        invoiceInputs,
        this.gqlService.batchUpdateInvoices$.bind(this),
        invoiceMaxBatchSize
      );

      if (invoiceResponses.success && invoiceResponses.data && invoiceResponses.data.length > 0) {
        if (showSuccessMessages) {
          this.overlayService.success(MessagesConstants.INVOICE.SUCCESSFULLY_UPDATED);
        }

        invoices.forEach((invoice) => {
          const invoiceData = invoiceResponses.data?.find((datum) => datum.id === invoice.id);
          this.invoiceStore.update(
            invoice.id,
            () =>
              ({
                ...invoiceData,
                decline_reason: invoice.decline_reason,
                cards: invoice.cards,
                payment_date: invoice.payment_date ?? null,
                po_reference: invoiceData?.purchase_order_id || '',
                expense_amounts: invoice.expense_amounts,
              }) as unknown as InvoiceModel
          );
        });

        if (setLoading) {
          this.invoiceStore.setLoading(false);
        }

        return true;
      }

      this.overlayService.error(invoiceResponses.errors);
    }
    this.overlayService.error(workflowResponses.errors);

    if (setLoading) {
      this.invoiceStore.setLoading(false);
    }

    return false;
  }

  async update(invoice: InvoiceModel, showSuccessMessages = true, setLoading = true) {
    const cardPromises = [];

    if (setLoading) {
      this.invoiceStore.setLoading(true);
    }

    for (const { id: cardId, lines, status, header, note } of invoice.cards) {
      // update each card
      cardPromises.push(
        firstValueFrom(
          this.gqlService.updateWorkflowDetail$({
            id: cardId,
            properties: JSON.stringify({ properties: { lines, status, header, note } }),
          })
        )
      );
    }

    // send all the request in parallel
    const responses = await Promise.all(cardPromises);
    // check if everything is ok
    if (responses.every((x) => x.success)) {
      const { success, data, errors } = await firstValueFrom(
        this.gqlService.updateInvoice$({
          accrual_period: invoice.accrual_period,
          services_period: invoice.services_period,
          previous_services_period: invoice.previous_services_period || null,
          id: invoice.id,
          invoice_no: invoice.invoice_no,
          invoice_date: invoice.invoice_date,
          po_reference: invoice.po_reference,
          invoice_status: invoice.invoice_status,
          due_date: invoice.due_date,
          organization_id: invoice.organization.id,
          payment_status: invoice.payment_status,
          payment_date: invoice.payment_date,
          total_amount: invoice.expense_amounts.invoice_total?.value || 0,
          service_amount: invoice.expense_amounts.services_total?.value || 0,
          investigator_amount: invoice.expense_amounts.investigator_total?.value || 0,
          passthrough_amount: invoice.expense_amounts.pass_thru_total?.value || 0,
          discount_amount: invoice.expense_amounts.discount_total?.value || 0,
          is_deposit: invoice.is_deposit,
          admin_review_reason: invoice.admin_review_reason,
          decline_reason: invoice.decline_reason,
          audit_record_create: invoice.audit_record_create,
        })
      );
      if (success && data) {
        if (invoice.decline_reason && invoice.decline_reason.length > 0) {
          const {
            errors: cErrors,
            success: cSuccess,
            data: cData,
          } = await firstValueFrom(
            this.gqlService.createNote$({
              entity_id: invoice.id,
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_DECLINE_REASON,
              message: Utils.scrubUserInput(invoice.decline_reason),
            })
          );
          if (cSuccess && cData && showSuccessMessages) {
            this.overlayService.success();
          }

          if (!cSuccess) {
            this.overlayService.error(cErrors);
          }
        }

        if (invoice.admin_review_reason && invoice.admin_review_reason.length > 0) {
          const {
            errors: cErrors,
            success: cSuccess,
            data: cData,
          } = await firstValueFrom(
            this.gqlService.createNote$({
              entity_id: invoice.id,
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_ADMIN_REVIEW_REASON,
              message: Utils.scrubUserInput(invoice.admin_review_reason),
            })
          );
          if (cSuccess && cData && showSuccessMessages) {
            this.overlayService.success();
          }

          if (!cSuccess) {
            this.overlayService.error(cErrors);
          }
        }
      }

      if (success && data) {
        if (showSuccessMessages) {
          this.overlayService.success(MessagesConstants.INVOICE.SUCCESSFULLY_UPDATED);
        }

        this.invoiceStore.update(
          invoice.id,
          () =>
            ({
              ...data,
              decline_reason: invoice.decline_reason,
              cards: invoice.cards,
              po_reference: data.purchase_order_id,
              expense_amounts: invoice.expense_amounts,
            }) as unknown as InvoiceModel
        );

        if (setLoading) {
          this.invoiceStore.setLoading(false);
        }

        return true;
      }

      this.overlayService.error(errors);
    }
    const messages = flatten(responses.filter((x) => !x.success).map((x) => x.errors));
    this.overlayService.error(messages);

    if (setLoading) {
      this.invoiceStore.setLoading(false);
    }

    return false;
  }

  async remove(invoice: InvoiceModel, delete_reason = '') {
    const { success, errors } = await firstValueFrom(
      this.gqlService.removeInvoice$({ id: invoice.id })
    );
    if (success) {
      if (delete_reason.length > 0) {
        const { errors: cErrors, success: cSuccess } = await firstValueFrom(
          this.gqlService.createNote$({
            entity_id: invoice.id,
            entity_type: EntityType.INVOICE,
            note_type: NoteType.NOTE_TYPE_DELETE_REASON,
            message: Utils.scrubUserInput(delete_reason),
          })
        );
        if (!cSuccess) {
          this.overlayService.error(cErrors);
        }
      }
      this.invoiceStore.remove(invoice.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }

  async downloadInvoiceItems(rowNode: IRowNode) {
    let { bucket_key } = rowNode.data?.file || {};
    if (!bucket_key) {
      const trialId = this.mainQuery.getValue().trialKey;
      await this.apiService
        .getFilesByFilters(
          `trials/${trialId}/vendors/`,
          undefined,
          EntityType.INVOICE,
          DocumentType.DOCUMENT_INVOICE,
          rowNode.data?.id
        )
        .then((documents) => {
          bucket_key = documents[0].bucket_key;
        });
      if (!bucket_key) {
        this.overlayService.error(MessagesConstants.INVOICE.DOES_NOT_HAVE_FILE);
        return;
      }
    }
    const pathParts = bucket_key.split('/');
    const formattedKey = `${pathParts.slice(0, pathParts.indexOf('invoices') + 2).join('/')}/`;
    const ref = this.overlayService.loading();
    const { success, data } = await this.apiService.getS3ZipFile(formattedKey);
    if (success && data) {
      const fileName =
        `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_Invoice_` +
        `${rowNode.data.id}_${(rowNode.data.create_date || '').slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
    ref.close();
  }

  async createInvoiceNote(invoice: InvoiceModel, message: string) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createNote$({
        entity_id: invoice.id,
        entity_type: EntityType.INVOICE,
        note_type: NoteType.NOTE_TYPE_GENERAL,
        message: message || '',
      })
    );

    if (success && data) {
      this.overlayService.success('Notes saved');
      this.invoiceStore.update(invoice.id, ({ notes }) => {
        return {
          notes: [...(notes || []), data as Note],
        };
      });
      return data;
    } else {
      this.overlayService.error(errors);
      return;
    }
  }

  async updateInvoiceNote(invoice: InvoiceModel, message: string) {
    const note = invoice.notes?.[0];
    if (!note) {
      return null;
    }

    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateNote$({
        id: note.id,
        message: message || '',
      })
    );

    if (success && data) {
      this.invoiceStore.update(invoice.id, ({ notes }) => {
        return {
          notes: notes?.map((n) => {
            if (n.id === note.id) {
              return { ...n, message };
            }
            return n;
          }),
        };
      });
      this.overlayService.success('Notes saved');
      return data;
    } else {
      this.overlayService.error(errors);
      return;
    }
  }

  getFilePath(invoice_id: string, vendorId: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendorId}/invoices/${invoice_id}/invoice-lines/${uuidv4()}/`;
  }

  downloadInvoiceLines(rowNode: IRowNode) {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name;
    if (rowNode.data.line_items.length > 0) {
      const blob = new Blob([
        this.invoiceLinesToCsv(rowNode.data.line_items, rowNode.data.data_source_id),
      ]);
      const integration_name = `${Utils.readableDataSource(rowNode.data.data_source_id)}`;
      const fileName = `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_${trialShortName}_Auxilius_${integration_name}_Integration_Invoice_Line_Items.csv`;
      this.apiService.downloadBlob(blob, fileName);
    }
    if (rowNode.data.ocr_line_items.length > 0) {
      const blob = new Blob([
        this.invoiceLinesToCsv(rowNode.data.ocr_line_items, DataSource.DATA_SOURCE_AUXILIUS),
      ]);
      const fileName = `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_${trialShortName}_Auxilius_Parsed_Invoice_Line_Items.csv`;
      this.apiService.downloadBlob(blob, fileName);
    }
  }

  invoiceLinesToCsv(data: Record<string, unknown>[], dataSource: DataSource) {
    let amountKey = 'amount';
    let descriptionKey = 'description';
    if (dataSource === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
      amountKey = 'Amount';
      descriptionKey = 'Description';
    } else if (dataSource === DataSource.DATA_SOURCE_DYNAMICS365) {
      amountKey = 'netAmountIncludingTax';
    }
    const csvRows = [];
    if (dataSource === DataSource.DATA_SOURCE_AUXILIUS) {
      csvRows.push(
        'Auxilius AI (Beta) extraction: Data outside of scope for Auxilius QAQC processes; manual review recommended.',
        ''
      );
    }
    const headers = ['Description', 'Amount'];
    csvRows.push(headers.join(','));
    let total = 0;
    for (const row of data) {
      const values = headers.map((header) => {
        let value = String(row[header === 'Description' ? descriptionKey : amountKey]);
        if (header === 'Amount') {
          total += +value;
        }
        if (value.includes(',')) {
          value = `"${value}"`;
        }
        return value;
      });
      csvRows.push(values.join(','));
    }
    csvRows.push(`Total, ${total}`);

    return csvRows.join('\n');
  }

  calculateTotals = () => {
    const rowsToCalculateAgainst = this.gridAPI?.getRenderedNodes();

    const uniqVendorCurrencies = uniq(
      rowsToCalculateAgainst?.map((x) => x.data?.organization.currency)
    );

    const isVendorSelected =
      this.budgetCurrencyQuery.getValue().currency === BudgetCurrencyType.VENDOR;

    let currency = Currency.USD;

    // if there's only one currency, we can use it for formatting
    if (uniqVendorCurrencies.length === 1) {
      currency = uniqVendorCurrencies[0];
    }

    // if there are multiple currencies and we're toggled on vendor currency, we don't need to calculate a total row since we can't directly sum different currencies
    if (uniqVendorCurrencies.length > 1 && isVendorSelected) {
      return {
        expense_amounts: {
          invoice_total_trial_currency: { value: 0 },
          invoice_total: { value: 0 },
        },
      };
    } else {
      return rowsToCalculateAgainst?.reduce(
        (accumulator, row) => ({
          expense_amounts: {
            invoice_total_trial_currency: {
              value:
                accumulator.expense_amounts.invoice_total_trial_currency.value +
                row.data?.expense_amounts.invoice_total_trial_currency.value,
            },
            investigator_total_trial_currency: {
              value:
                accumulator.expense_amounts.investigator_total_trial_currency.value +
                row.data?.expense_amounts.investigator_total_trial_currency.value,
            },
            pass_thru_total_trial_currency: {
              value:
                accumulator.expense_amounts.pass_thru_total_trial_currency.value +
                row.data?.expense_amounts.pass_thru_total_trial_currency.value,
            },
            services_total_trial_currency: {
              value:
                accumulator.expense_amounts.services_total_trial_currency.value +
                row.data?.expense_amounts.services_total_trial_currency.value,
            },
            discount_total_trial_currency: {
              value:
                accumulator.expense_amounts.discount_total_trial_currency.value +
                row.data?.expense_amounts.discount_total_trial_currency.value,
            },
            invoice_total: {
              value:
                accumulator.expense_amounts.invoice_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data?.expense_amounts.invoice_total.value),
              currency,
            },
            investigator_total: {
              value:
                accumulator.expense_amounts.investigator_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data?.expense_amounts.investigator_total.value),
              currency,
            },
            pass_thru_total: {
              value:
                accumulator.expense_amounts.pass_thru_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data?.expense_amounts.pass_thru_total.value),
              currency,
            },
            services_total: {
              value:
                accumulator.expense_amounts.services_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data?.expense_amounts.services_total.value),
              currency,
            },
            discount_total: {
              value:
                accumulator.expense_amounts.discount_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data?.expense_amounts.discount_total.value),
              currency,
            },
          },
        }),
        {
          expense_amounts: {
            invoice_total_trial_currency: { value: 0 },
            investigator_total_trial_currency: { value: 0 },
            pass_thru_total_trial_currency: { value: 0 },
            services_total_trial_currency: { value: 0 },
            discount_total_trial_currency: { value: 0 },
            invoice_total: { value: 0, currency: Currency.USD },
            investigator_total: { value: 0, currency: Currency.USD },
            pass_thru_total: { value: 0, currency: Currency.USD },
            services_total: { value: 0, currency: Currency.USD },
            discount_total: { value: 0, currency: Currency.USD },
          },
        }
      );
    }
  };

  generatePinnedRow() {
    const totals = this.calculateTotals();
    // if the invoice total is 0, then no need for a total row
    if (
      totals?.expense_amounts?.invoice_total.value === 0 &&
      totals?.expense_amounts?.invoice_total_trial_currency.value === 0
    ) {
      this.gridAPI?.setGridOption('pinnedBottomRowData', []);
    } else {
      this.gridAPI?.setGridOption('pinnedBottomRowData', [
        {
          ...totals,
          file: '',
          invoice_no: 'Total',
          organization: { name: Utils.zeroHyphen },
          created_by: '',
        },
      ]);
    }
  }

  setCostValue(params: ValueSetterParams): boolean | undefined {
    if (!params.newValue) {
      set(params.data, params.column.getColDef().field || '', 0);
      return true;
    }
    //// Decimal and commas optional, can be changed to be more strict at future
    const currencyRegex = /(?=.*?\d)^[€£¥₹$¢₽₩₦]?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
    const isNegWithSlash = String(params.newValue).startsWith('-');
    const isNegWithParnth = String(params.newValue).startsWith('(');
    const testVal = isNegWithSlash
      ? String(params.newValue).slice(1)
      : isNegWithParnth
        ? String(params.newValue).slice(2, -1)
        : params.newValue;
    const isCurrency = currencyRegex.test(testVal);
    const numericString = params.newValue.replace(/[^\d.-]/g, '');
    let numberValue = parseFloat(numericString);
    if (!isCurrency && params.newValue !== Utils.zeroHyphen) {
      this.overlayService.error(MessagesConstants.ONLY_NUMERIC_VALUES_ARE_SUPPORTED);
    }
    numberValue = isNegWithParnth ? -numberValue : numberValue;
    const setVal =
      params.newValue === Utils.zeroHyphen ? 0 : isCurrency ? `${numberValue}` : params.oldValue;
    set(params.data, params.column.getColDef().field || '', setVal);
    return true;
  }

  goToInvoiceDetail(event: CellClickedEvent) {
    const id = event.data?.id;
    if (id) {
      this.router.navigateByUrl(
        `${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}/${id}`
      );
    }
  }

  userFormatter = (sub?: string): string => {
    const user = this.users.get(sub || '');

    return Utils.agUserFormatter(user as listUserNamesWithEmailQuery, this.authQuery.isAuxAdmin());
  };

  getAccrualPeriodOptions(startDate: string, endDate: string) {
    const amountOfMonths = Math.round(Number(Utils.getDurationInMonths(startDate, endDate)));
    const accrualPeriodOptions: Option[] = [];

    for (let month = 0; month < amountOfMonths; month++) {
      const date = dayjs(startDate).add(month, 'month');

      accrualPeriodOptions.push({
        label: date.format('MMMM YYYY'),
        value: `${date.format('YYYY-MM')}-01`,
      });
    }

    return accrualPeriodOptions;
  }

  vendorValueSetter = (params: ValueSetterParams) => {
    const newValue = isString(params.newValue)
      ? this.vendorOptions.find(({ label }) => label === params.newValue)
      : params.newValue;

    const newVendor = this.organizationQuery.getVendor(newValue.value)[0];

    const data = cloneDeep(params.data);
    set(data, 'organization.currency', newVendor?.currency);
    set(data, 'organization.name', newValue.label);
    set(data, 'organization.id', newValue.value);
    params.node?.setData(data);

    return true;
  };
}
