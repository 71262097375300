import { UserAuditLogAction, UserAuditLogCategory } from '@shared/services/gql.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export const AuditCategoryStatuses: [UserAuditLogCategory, string][] = [
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET, 'Budget'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CHANGE_ORDER, 'Change Order'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CUSTOM_DRIVER, 'Custom Driver'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_DRIVER, 'Drivers'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_DEFAULTS, 'Expense Defaults'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE, 'Expense Source'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST, 'Forecast'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_IN_MONTH_ADJUSTMENTS, 'In-Month Adjustments'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVESTIGATOR_FORECAST, 'Investigator Forecast'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVOICE, 'Invoice'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT, 'Historical Adjustment'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE, 'Manual Override'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PREPAIDS, 'Prepaids'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY, 'Protocol Entry'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER, 'Patient Driver'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP, 'Patient Group'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER, 'Investigator Data'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE, 'Payment Milestone'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER_PERMISSIONS, 'User Permissions'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY, 'Protocol Entry'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER, 'Purchase Order'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE, 'Site'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_BUDGET, 'Site Budget'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_GROUP, 'Site Group'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_DRIVER, 'Site Driver'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR, 'Vendor'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT, 'Period Close'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_INFO, 'Trial Info'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE, 'Trial Timeline'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER, 'User'],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE, 'Vendor Estimate'],
];

export const AuditActionStatuses: [UserAuditLogAction, string][] = [
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_APPLIED,
    'Actual Patient Visits Applied',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_ACTUAL_PATIENT_VISITS_UPDATED,
    'Actual Patient Visits Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_ACTIVITY_ADDED, 'Budget Activity Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_ACTIVITY_UPDATED, 'Budget Activity Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_ACTIVITY_DELETED, 'Budget Activity Deleted'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_ATTRIBUTE_REMOVED, 'Budget Attribute Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_ATTRIBUTE_RENAMED, 'Budget Attribute Renamed'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_ACTIVITY_ADDED,
    'Change Order Activity Added',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_ACTIVITY_UPDATED,
    'Change Order Activity Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_ACTIVITY_DELETED,
    'Change Order Activity Deleted',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_ATTRIBUTE_REMOVED,
    'Change Order Attribute Removed',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_ATTRIBUTE_RENAMED,
    'Change Order Attribute Renamed',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CATEGORY_ADDED,
    'Change Order Category Added',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CATEGORY_UPDATED,
    'Change Order Category Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_CREATED,
    'Patient (Blended) Curve Created',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_REMOVED,
    'Patient (Blended) Curve Removed',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_NAME_UPDATED,
    'Patient (Blended) Curve Name Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_PATIENT_GROUPS_UPDATED,
    'Patient (Blended) Curve Groups Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_PATIENT_DRIVER_UPDATED,
    'Patient (Blended) Curve Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_CREATED,
    'Site (Blended) Curve Created',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_REMOVED,
    'Site (Blended) Curve Removed',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_NAME_UPDATED,
    'Site (Blended) Curve Name Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_SITE_GROUPS_UPDATED,
    'Site (Blended) Curve Groups Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BLENDED_SITE_DRIVER_UPDATED,
    'Site (Blended) Curve Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_CREATED,
    'Manual Override Added',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_MANUAL_OVERRIDE_UPDATED,
    'Manual Override Modified',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_CREATED,
    'Historical Adjustment Added',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_EXPENSE_HISTORICAL_ADJUSTMENT_UPDATED,
    'Historical Adjustment Modified',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_REMOVED, 'Budget Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_BUDGET_UPLOADED, 'Budget Uploaded'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_BULK_MANUAL_INVESTIGATOR_TRANSACTIONS_UPLOADED,
    'Bulk Investigator Transactions Uploaded',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CATEGORY_ADDED, 'Category Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CATEGORY_UPDATED, 'Category Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_APPROVED, 'Change Order Approved'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_CREATED, 'Change Order Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_DECLINED, 'Change Order Declined'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_PENDING_APPROVAL,
    'Change Order Pending Approval',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDER_REMOVED, 'Change Order Removed'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_LOCKED,
    'Change Orders, Purchase Orders, New Vendors, & Budgets Locked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_CHANGE_ORDERS_UNLOCKED,
    'Change Orders, Purchase Orders, New Vendors, & Budgets Unlocked',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CUSTOM_DRIVER_CREATED, 'Custom Curve Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CUSTOM_DRIVER_UPDATED, 'Custom Curve Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_CUSTOM_DRIVER_REMOVED, 'Custom Curve Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_DISCOUNTS_LOCKED, 'Discounts Locked'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_DISCOUNTS_UNLOCKED, 'Discounts Unlocked'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_LOCKED,
    'Site, Patient, & Custom Drivers Locked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_DRIVER_UNLOCKED,
    'Site, Patient, & Custom Drivers Unlocked',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_EXPENSE_DEFAULTS_UPDATED, 'Expense Defaults Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_EXPENSE_SOURCE_UPDATED, 'Expense Source Updated'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_LOCKED,
    'Forecast Methodology Locked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UNLOCKED,
    'Forecast Methodology Unlocked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_FORECAST_METHODOLOGY_UPDATED,
    'Forecast Methodology Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVESTIGATOR_TRANSACTION_ADDED,
    `Investigator Transaction Added`,
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVESTIGATOR_TRANSACTION_DELETED,
    `Investigator Transaction Deleted`,
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVESTIGATOR_TRANSACTION_UPDATED,
    `Investigator Transaction Updated`,
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_CREATED, `Invoice Added`],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_REMOVED, `Invoice Deleted`],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICE_UPDATED, `Invoice Updated`],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICES_LOCKED,
    'Invoices, Prepaids, and Credit Memos Locked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_INVOICES_UNLOCKED,
    'Invoices, Prepaids, and Credit Memos Unlocked',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_PROTOCOL_UPDATED, 'Protocol Entry Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_CREATED, 'Patient Curve Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_REMOVED, 'Patient Curve Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_DRIVER_UPDATED, 'Patient Curve Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_ADDED, 'Patient Group Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_REMOVED, 'Patient Group Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_GROUP_UPDATED, 'Patient Group Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_LOCKED, 'Investigator Data Locked'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UNLOCKED, 'Investigator Data Unlocked'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_PATIENT_TRACKER_UPDATED,
    'Patient Tracker Data Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_ADDED, 'Payment Milestone Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_REMOVED, 'Payment Milestone Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PAYMENT_MILESTONE_UPDATED, 'Payment Milestone Updated'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_PREPAID_ADJUSTMENT_UPDATED,
    'Prepaid Adjustment Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_PREPAID_ADJUSTMENT_DELETED,
    'Prepaid Adjustment Deleted',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PREPAID_ADJUSTMENT_ADDED, 'Prepaid Adjustment Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_ADDED, 'Protocol Version Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_DELETED, 'Protocol Version Deleted'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PROTOCOL_VERSION_UPDATED, 'Protocol Version Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_ADDED, 'Purchase Order Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_REMOVED, 'Purchase Order Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_PURCHASE_ORDER_UPDATED, 'Purchase Order Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_REMOVED, 'Scenario Budget Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCENARIO_BUDGET_UPLOAD, 'Scenario Budget Uploaded'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_SCHEDULED_SITE_INVOICEABLE_GENERATED,
    'Scheduled Site Invoiceable Generated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_COSTS_UPDATED,
    'Site Budget Amendment Costs Updated',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_AMENDMENT_INFO_UPDATED,
    'Site Budget Amendment Info Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_COSTS_UPDATED, 'Site Budget Costs Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_CREATED, 'Site Budget Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_INFO_UPDATED, 'Site Budget Info Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_ADDED, 'Site Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPDATED, 'Site Budget Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_BUDGET_UPLOADED, 'Site Budget Uploaded'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_APPLIED,
    'Site Contract Source Applied',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_CONTRACT_SOURCE_UPDATED,
    'Site Contract Source Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_DRIVER_CREATED, 'Site Curve Created'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_DRIVER_REMOVED, 'Site Curve Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_DRIVER_UPDATED, 'Site Curve Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_REMOVED, 'Site Removed'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_LOCKED,
    'In-Month Adjustments Locked',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSE_UNLOCKED,
    'In-Month Adjustments Unlocked',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_QUARTER_CLOSED, `Quarter Closed`],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_INFO_UPDATED, 'Trial Info Updated'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_LOCKED, 'Trial Timeline Locked'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_UNLOCKED, 'Trial Timeline Unlocked'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_ADDED,
    'Trial Timeline Milestone Added',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_TIMELINE_EVENT_MONTH_CLOSED, 'Month Closed'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_REMOVED,
    'Trial Timeline Milestone Removed',
  ],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_TRIAL_TIMELINE_MILESTONE_UPDATED,
    'Trial Timeline Milestone Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_ADDED, 'User Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_PERMISSION_ADDED, 'User Permission Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_PERMISSION_REMOVED, 'User Permission Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_USER_REMOVED, 'User Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ADDED, 'Vendor Added'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_CONTRACT_SOURCE_UPDATED,
    'Vendor Contract Source Updated',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_REMOVED, 'Vendor Estimate Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_UPDATED, 'Vendor Estimate Updated'],
  [
    UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATE_UPLOADED,
    'Vendor Estimate Template Uploaded',
  ],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_LOCKED, 'Vendor Estimates Locked'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_ESTIMATES_UNLOCKED, 'Vendor Estimates Unlocked'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_VENDOR_REMOVED, 'Vendor Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_GROUP_ADDED, 'Site Group Added'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_GROUP_REMOVED, 'Site Group Removed'],
  [UserAuditLogAction.USER_AUDIT_LOG_ACTION_SITE_GROUP_UPDATED, 'Site Group Updated'],
];

export const AuditBudgetStatuses: [UserAuditLogCategory, string][] = [
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_BUDGET,
    `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_CUSTOM_DRIVER,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.CUSTOM_DRIVERS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_DRIVER,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVESTIGATOR_FORECAST,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_INVOICE,
    `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY,
    `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_DRIVER,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_GROUP,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.GROUPS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PROTOCOL_ENTRY,
    `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_DEFAULTS,
    `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.EXPENSE_DEFAULTS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_EXPENSE_SOURCE,
    `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_FORECAST,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_MANUAL_OVERRIDE,
    `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_HISTORICAL_ADJUSTMENT,
    `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PATIENT_TRACKER,
    `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PAYMENT_MILESTONE,
    `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_PURCHASE_ORDER,
    `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE,
    `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_BUDGET,
    `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_DRIVER,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_SITE_GROUP,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.GROUPS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TIMELINE_EVENT,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE,
    `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_TIMELINE_MILESTONE,
    `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES}`,
  ],
  [UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_TRIAL_INFO, `/${ROUTING_PATH.SETTINGS.INDEX}`],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER,
    `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.USERS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER_PERMISSIONS,
    `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.USER_PERMISSIONS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_USER_PERMISSIONS,
    `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.USER_PERMISSIONS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR,
    `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.VENDORS}`,
  ],
  [
    UserAuditLogCategory.USER_AUDIT_LOG_CATEGORY_VENDOR_ESTIMATE,
    `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
  ],
];
