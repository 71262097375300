import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';

const ForecastCurvesRoutes = {
  SITE_CURVES: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
  PATIENT_CURVES: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
  CUSTOM_CURVES: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.CUSTOM_DRIVERS}`,
};

@Component({
  selector: 'aux-forecast-curves-row-title',
  template: `
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="ForecastCurvesRoutes.SITE_CURVES"
    >
      Site</span
    >,
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="ForecastCurvesRoutes.PATIENT_CURVES"
      >Patient</span
    >, &
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="ForecastCurvesRoutes.CUSTOM_CURVES"
      >Custom</span
    >
    Curves
  `,
  standalone: true,
  styles: [
    `
      :host {
        flex-grow: 1;

        .link {
          color: rgba(9, 91, 149);
        }
      }
    `,
  ],
  imports: [RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastCurvesRowTitleComponent {
  readonly ForecastCurvesRoutes = ForecastCurvesRoutes;
}
