import { Component, ChangeDetectionStrategy, Signal } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { MessagesConstants } from '@shared/constants/messages.constants';

type ChangeOrderParams = ICellRendererParams & {
  deleteClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  downloadClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  downloadPermission$: BehaviorSubject<boolean>;
  isAdmin: boolean;
  isChangeOrdersWorkflowLocked: Signal<boolean>;
};

@Component({
  selector: 'aux-change-order-actions',
  template: `
    <div class="flex items-center justify-center space-x-2">
      <button
        *ngIf="(params.downloadPermission$ | async) || params.isAdmin"
        class="btn text-aux-blue rounded-full p-0 w-8 h-8"
        type="button"
        auxTooltip="Download"
        (click)="onDownloadClick()"
      >
        <aux-icon name="Download" />
      </button>

      <button
        class="btn rounded-full p-0 w-8 h-8"
        [ngClass]="{
          'opacity-50': params.isChangeOrdersWorkflowLocked()
        }"
        type="button"
        [disabled]="params.isChangeOrdersWorkflowLocked()"
        [auxTooltip]="
          params.isChangeOrdersWorkflowLocked()
            ? MessagesConstants.LOCKED_FOR_PERIOD_CLOSE
            : 'Delete'
        "
        (click)="onDeleteClick()"
      >
        <aux-icon name="Trash" class="text-aux-error" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderActionsComponent implements ICellRendererAngularComp {
  params!: ChangeOrderParams;

  refresh(): boolean {
    return false;
  }

  agInit(params: ChangeOrderParams): void {
    this.params = params;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  protected readonly MessagesConstants = MessagesConstants;
}
