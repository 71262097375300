import dayjs from 'dayjs';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { TrialModel } from '@models/trials/trials.store';
import { InvoiceForm } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.model';
import { TrialImplementationStatus } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { Maybe } from 'graphql/jsutils/Maybe';

export class InvoiceFormUtils {
  static isAccrualPeriodInClosedMonth = ({
    invoice,
    trial,
    currentOpenMonth,
  }: {
    currentOpenMonth: string;
    invoice: InvoiceModel;
    trial: Maybe<TrialModel>;
  }) => {
    if (!trial?.implementation_status) {
      return false;
    }

    if (
      trial.implementation_status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED &&
      trial.implementation_status !== TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
    ) {
      return false;
    }

    if (invoice.invoice_status === 'STATUS_DECLINED') {
      return false;
    }

    if (!invoice.accrual_period) {
      return false;
    }

    let period = invoice.accrual_period || '';
    if (period.split('-').length === 1) {
      period = `${period}-01`;
    }
    return dayjs(period).isBefore(currentOpenMonth);
  };

  static invoiceFormToInvoiceModel = (formValues: InvoiceForm, invoice: InvoiceModel) => {
    return <InvoiceModel>{
      ...invoice,
      due_date: formValues.due_date,
      po_reference: formValues.selectedPOReference,
      notes: formValues.notes ? [{ message: formValues.notes }] : [],
      accrual_period: formValues.accrual_period,
      services_period: formValues.services_period,
      organization: { id: formValues.vendor_id },
      invoice_no: formValues.invoice_no,
      invoice_date: formValues.invoice_date,
      expense_amounts: {
        invoice_total: {
          ...invoice.expense_amounts.invoice_total,
          value: Utils.reverseAccountingFormat(formValues.invoice_total),
        },
        investigator_total: {
          ...invoice.expense_amounts.investigator_total,
          value: Utils.reverseAccountingFormat(formValues.investigator_total),
        },
        services_total: {
          ...invoice.expense_amounts.services_total,
          value: Utils.reverseAccountingFormat(formValues.services_total),
        },
        discount_total: {
          ...invoice.expense_amounts.discount_total,
          value: Utils.reverseAccountingFormat(formValues.discount_total),
        },
        pass_thru_total: {
          ...invoice.expense_amounts.pass_thru_total,
          value: Utils.reverseAccountingFormat(formValues.pass_thru_total),
        },
        invoice_total_trial_currency: {
          ...invoice.expense_amounts.invoice_total_trial_currency,
          value: Utils.reverseAccountingFormat(formValues.invoice_total_trial_currency),
        },
        pass_thru_total_trial_currency: {
          ...invoice.expense_amounts.pass_thru_total_trial_currency,
          value: Utils.reverseAccountingFormat(formValues.pass_thru_total_trial_currency),
        },
        services_total_trial_currency: {
          ...invoice.expense_amounts.services_total_trial_currency,
          value: Utils.reverseAccountingFormat(formValues.services_total_trial_currency),
        },
        discount_total_trial_currency: {
          ...invoice.expense_amounts.discount_total_trial_currency,
          value: Utils.reverseAccountingFormat(formValues.discount_total_trial_currency),
        },
        investigator_total_trial_currency: {
          ...invoice.expense_amounts.investigator_total_trial_currency,
          value: Utils.reverseAccountingFormat(formValues.investigator_total_trial_currency),
        },
      },
    };
  };

  static invoiceModelToForm = (invoice: InvoiceModel): InvoiceForm => {
    return {
      due_date: invoice.due_date,
      selectedPOReference: invoice.po_reference,
      notes: invoice.notes?.[0]?.message || '',
      accrual_period: invoice.accrual_period
        ? dayjs(invoice.accrual_period).format('YYYY-MM')
        : null,
      services_period: invoice.services_period
        ? dayjs(invoice.services_period).format('YYYY-MM')
        : null,
      vendor_id: invoice.organization.id,
      invoice_no: invoice.invoice_no,
      invoice_date: invoice.invoice_date,
      invoice_total: Utils.agCurrencyFormatter(
        invoice.expense_amounts.invoice_total,
        invoice.expense_amounts.invoice_total.contract_curr || invoice.organization.currency
      ).toString(),
      investigator_total: Utils.agCurrencyFormatter(
        invoice.expense_amounts.investigator_total,
        invoice.expense_amounts.investigator_total.contract_curr || invoice.organization.currency
      ).toString(),
      services_total: Utils.agCurrencyFormatter(
        invoice.expense_amounts.services_total,
        invoice.expense_amounts.services_total.contract_curr || invoice.organization.currency
      ).toString(),
      discount_total: Utils.agCurrencyFormatter(
        invoice.expense_amounts.discount_total,
        invoice.expense_amounts.discount_total.contract_curr || invoice.organization.currency
      ).toString(),
      pass_thru_total: Utils.agCurrencyFormatter(
        invoice.expense_amounts.pass_thru_total,
        invoice.expense_amounts.pass_thru_total.contract_curr || invoice.organization.currency
      ).toString(),
      invoice_total_trial_currency: Utils.currencyFormatter(
        invoice.expense_amounts.invoice_total_trial_currency.value
      ).toString(),
      pass_thru_total_trial_currency: Utils.currencyFormatter(
        invoice.expense_amounts.pass_thru_total_trial_currency.value
      ).toString(),
      services_total_trial_currency: Utils.currencyFormatter(
        invoice.expense_amounts.services_total_trial_currency.value
      ).toString(),
      discount_total_trial_currency: Utils.currencyFormatter(
        invoice.expense_amounts.discount_total_trial_currency.value
      ).toString(),
      investigator_total_trial_currency: Utils.currencyFormatter(
        invoice.expense_amounts.investigator_total_trial_currency.value
      ).toString(),
    };
  };
}
