<section class="mt-2 mb-4">
  <form class="flex items-center flex-wrap" [formGroup]="gridFiltersFormGroup">
    <aux-input
      class="mr-3 mt-2"
      formControlName="start_activity_date"
      label="From:"
      placeholder="YYYY-MM-DD"
      [type]="'date'"
      [max]="maxDateForStart"
      [updateOn]="'blur'"
      [noTriggerValueChangeOnBlur]="true"
    />

    <aux-input
      class="mr-3 mt-2"
      formControlName="end_activity_date"
      label="To:"
      placeholder="YYYY-MM-DD"
      [type]="'date'"
      [min]="minDateForEnd"
      [max]="maxDate"
      [updateOn]="'blur'"
      [noTriggerValueChangeOnBlur]="true"
    />

    <aux-site-select-dropdown
      formControlName="site_ids"
      class="w-64 mr-3 mt-2"
      [loading]="(filterLoading$ | async)!"
    />

    <aux-multi-select-dropdown
      [searchable]="true"
      placeholder="Select"
      label="Patient ID:"
      formControlName="patient_ids"
      class="w-40 mr-3 mt-2"
      bindLabel="external_patient_id"
      bindValue="id"
      [items]="$any(patientOptions$ | async)"
      [loading]="(filterLoading$ | async)!"
    />

    <aux-multi-select-dropdown
      *ngFor="let optionalFilter of optionalFilterList$ | async"
      class="w-64 mr-3 mt-2"
      prefix="X"
      placeholder="Select"
      [loading]="(filterLoading$ | async)!"
      [label]="optionalFilter.label"
      [items]="$any(optionalFilter.items | async)"
      [formControlName]="optionalFilter.formControlName"
      [bindLabel]="optionalFilter.bindLabel"
      [prefixClick]="onRemoveOptionalFilter(optionalFilter.label, optionalFilter.formControlName)"
      [searchable]="true"
      [bindValue]="optionalFilter.bindValue"
    />

    <aux-investigator-transactions-add-filter
      class="mt-7.5 mr-3 leading-none"
      [renderedFilters$]="optionalFilterList$"
      [showSourceColumn$]="showSourceColumn$"
      (selectFilterChange)="selectOptionalFilterChange($event)"
    />

    <aux-button
      variant="hyperlink"
      class="block mt-7.5 mr-3 leading-none"
      (clickEmit)="resetFilers()"
      label="Reset Filters"
    />
  </form>
</section>
