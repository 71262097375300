<form [formGroup]="siteForm" (ngSubmit)="updateSite()">
  <div class="space-y-4">
    <aux-input
      formControlName="name"
      label="Name"
      validators="required"
      [showRequiredAsterisk]="true"
    />

    <aux-input
      formControlName="site_no"
      label="Site #"
      [validators]="siteNoValidators"
      [showRequiredAsterisk]="true"
    />

    <aux-input formControlName="target_patients" label="Target Patients" validators="onlyInteger" />

    <div class="grid gap-4 grid-cols-3">
      <div>
        <div class="text-xs block mb-1">
          <span class="text-aux-error font-bold">*</span> Country
        </div>
        <ng-select
          formControlName="country"
          placeholder="Country"
          [bindValue]="'value'"
          [bindLabel]="'label'"
          auxFormError
          required
          [clearable]="false"
          [items]="countryOptions"
        />
      </div>

      <aux-input formControlName="zip" label="Zip" />

      <aux-input formControlName="city" label="City" />
    </div>

    <aux-input formControlName="state" label="State" />
    <aux-input formControlName="address_line_1" label="Address Line 1" />
    <aux-input formControlName="address_line_2" label="Address Line 2" />
    <aux-input formControlName="address_line_3" label="Address Line 3" />

    <aux-input formControlName="given_name" label="Primary Investigator First Name" />
    <aux-input formControlName="family_name" label="Primary Investigator Last Name" />

    <div class="aux-select">
      <label for="vendor"><span class="text-aux-error font-bold">*</span> Managed By</label>

      <ng-select
        class="select select__big"
        id="vendor"
        formControlName="managed_by_id"
        auxFormError
        placeholder="Managed"
        [bindValue]="'value'"
        [bindLabel]="'label'"
        [clearable]="false"
        [searchable]="true"
        [items]="vendorOptions$ | async"
      />
    </div>

    <div>
      <div class="text-xs block mb-1">
        <span class="text-aux-error font-bold">*</span>
        Currency
      </div>

      <ng-select
        class="select select__big"
        formControlName="currency"
        bindLabel="label"
        bindValue="key"
        label="Currency"
        placeholder="Currency"
        auxFormError
        required
        [items]="currencyOptions"
        [searchable]="true"
      />
    </div>

    <div class="grid gap-4 grid-cols-2">
      <aux-input
        formControlName="site_activation"
        label="Site Activation Date"
        placeholder="YYYY-MM-DD"
        [type]="'date'"
        validators="minMaxDate"
        (blured)="onBlurSiteActivationPicker(closeoutInput)"
      />
      <aux-input
        #closeoutInput
        formControlName="closeout_date"
        label="Site Close Out Date"
        placeholder="YYYY-MM-DD"
        validators="minMaxDate"
        [type]="'date'"
        [dateCompareValidation]="{
          startDate: siteForm.get('site_activation')!.value ?? '',
          endDate: ''
        }"
        dateCompareError="site_closeout"
      />
    </div>
  </div>

  <div class="flex justify-between mt-5">
    <aux-button variant="tertiary" icon="X" label="Cancel" (click)="cancelChanges()" />
    <aux-button variant="success" icon="Check" label="Save" type="submit" />
  </div>
</form>
