import { Injectable } from '@angular/core';
import { some } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MainService } from '@shared/store/main/main.service';
import { TrialInsightsQuery } from '@models/trial-insights/trial-insights.query';

@Injectable()
export class TrialInsightsClinicalQueryService {
  componentsLoading$ = combineLatest([
    this.store.select('patientCost').pipe(map((x) => x.isLoading)),
    this.store.select('investigatorCost').pipe(map((x) => x.isLoading)),
    this.store.select('siteCost').pipe(map((x) => x.isLoading)),
    this.store.select('screenFail').pipe(map((x) => x.isLoading)),
  ]).pipe(
    map(([patientCostLoading, investigatorCostLoading, siteCostLoading, screenFailLoading]) => {
      return some([
        patientCostLoading,
        investigatorCostLoading,
        siteCostLoading,
        screenFailLoading,
      ]);
    })
  );

  trialLoading$ = this.mainService.setTrialIsLoading;

  pageLoading$ = combineLatest([this.trialLoading$, this.componentsLoading$]);

  constructor(
    private store: TrialInsightsQuery,
    private mainService: MainService
  ) {}
}
