import { Directive, HostListener, Input } from '@angular/core';
import { isString } from 'lodash-es';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[auxTrimInput]',
  standalone: true,
})
export class TrimInputDirective {
  @Input() formControl = new FormControl();

  @HostListener('blured')
  onBlur() {
    const value = this.formControl.value;
    if (value && isString(value)) {
      this.formControl.setValue(value.trim());
    }
  }
}
