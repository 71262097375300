import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ComparisonCard } from '../patient-curves.constants';
import { CommonModule } from '@angular/common';
import { AgPatientDriverComparisonCardComponent } from '../ag-patient-driver-comparison-card/ag-patient-driver-comparison-card.component';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, AgPatientDriverComparisonCardComponent, IconComponent],
  selector: 'aux-ag-patient-driver-comparison-panel',
  templateUrl: './ag-patient-driver-comparison-panel.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPatientDriverComparisonPanelComponent {
  @Input() comparisonCards$: Observable<ComparisonCard[]> | undefined;
}
