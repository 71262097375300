import { Injectable } from '@angular/core';
import { FullActivity, FullCategory } from '../../../state/category/category.query';

@Injectable()
export class ForecastTableGridUnitsService {
  parseCategoryUnits(category: FullCategory | FullActivity, isChild: boolean): string {
    if (!isChild) {
      return '-';
    }

    if ('unit_num' in category) {
      return `${category.unit_num || ''}`;
    }

    return '-';
  }
}
