<!-- Category driver -->
<div
  *ngIf="
    data && (!data.isChild || (data.isChild && data.subCategory)) && data.primarySettingsOverride
  "
  class="forecast-table-grid-select-container"
  [attr.auto-qa]="autoTestAttribute"
>
  <ng-select
    [searchable]="false"
    [clearable]="true"
    [appendTo]="'body'"
    [ngModel]="data.driver"
    [items]="componentParent.DriverService.DriverOptions"
    bindLabel="label"
    bindValue="value"
    [placeholder]="componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT"
    [disabled]="
      !data.primarySettingsOverride ||
      disableDriver ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [auxTooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [ngClass]="{
      'forecast-table-grid-select forecast-driver-select': true,
      'is-invalid':
        (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
        (componentParent.CheckService.isCategoryUnforecasted(data.primarySettingsId) | async)
    }"
    (ngModelChange)="
      componentParent.onDriverChange($event, data.fullCategory!.primary_settings, {
        name: 'category',
        id: data.id
      })
    "
  >
    <ng-template ng-option-tmp let-item="item">
      <div
        *ngIf="{
          optionNotAvailable: (mapOptionAvailable.get(item.value) | async) === false
        } as obj"
        [auxTooltip]="obj.optionNotAvailable ? getOptionAvailableTooltip(item.value) : ''"
        class="py-[8px] px-[10px]"
        [ngClass]="{
          'cursor-not-allowed opacity-60': obj.optionNotAvailable
        }"
        (click)="obj.optionNotAvailable && $event.stopPropagation()"
      >
        <span [title]="item.label">{{ item.label }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>

<!-- Activity driver -->
<div
  *ngIf="
    data &&
    data.isChild &&
    !data.subCategory &&
    (data.primarySettingsOverride || !data.parentCategory?.primarySettingsOverride)
  "
  class="forecast-table-grid-select-container"
>
  <ng-select
    [searchable]="false"
    [clearable]="true"
    [items]="componentParent.DriverService.DriverOptions"
    bindLabel="label"
    bindValue="value"
    [appendTo]="'body'"
    [ngModel]="data.driver"
    [placeholder]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      !componentParent.userHasModifyPermissions
        ? ''
        : componentParent.CheckService.formControlConstants.PLACEHOLDER.SELECT
    "
    [disabled]="
      (data.parentCategory?.primarySettingsOverride && !data.primarySettingsOverride) ||
      disableDriver ||
      componentParent.isForecastFinalized ||
      componentParent.isClosedMonthsProcessing
    "
    [auxTooltip]="
      componentParent.CheckService.tooltipText(
        componentParent.isForecastFinalized || componentParent.isClosedMonthsProcessing,
        componentParent.userHasModifyPermissions
      )
    "
    [ngClass]="{
      'forecast-table-grid-select forecast-driver-select': true,
      'is-invalid':
        (componentParent.CheckService.isShowError(data.primarySettingsId) | async) ||
        (componentParent.CheckService.isActivityUnforecasted(data.id) | async)
    }"
    (ngModelChange)="
      componentParent.onDriverChange($event, data.fullActivity!.primary_settings, {
        name: 'activity',
        id: data.id
      })
    "
  >
    <ng-template ng-option-tmp let-item="item">
      <div
        *ngIf="{
          optionNotAvailable: (mapOptionAvailable.get(item.value) | async) === false
        } as obj"
        [auxTooltip]="obj.optionNotAvailable ? getOptionAvailableTooltip(item.value) : ''"
        class="py-[8px] px-[10px]"
        [ngClass]="{
          'cursor-not-allowed opacity-60': obj.optionNotAvailable
        }"
        (click)="obj.optionNotAvailable && $event.stopPropagation()"
      >
        <span [title]="item.label">{{ item.label }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
