import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ROUTING_PATH } from '@shared/constants/routingPath';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-go-back',
  template: `
    <div
      class="flex items-center text-aux-blue-light-200 mb-6 cursor-pointer max-w-max"
      [routerLink]="[invoicesLink]"
    >
      <aux-icon name="ArrowLeft" class="mr-2" />
      <div>Back to Invoices</div>
    </div>
  `,
  imports: [IconComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailGoBackComponent {
  invoicesLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`;
}
