import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlag } from '@models/feature-flag.model';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  BasicRedirectToNextTabGuard,
  FeatureFlagTabs,
} from '@shared/guards/basic-redirect-to-next-tab.guard';

export const PERIOD_CLOSE_ROUTES_FEATURE_FLAGS: FeatureFlagTabs = {
  [ROUTING_PATH.CLOSING.CHECKLIST]: 'checklist_tab',
  [ROUTING_PATH.CLOSING.ADJUSTMENTS]: FeatureFlag.IN_MONTH_ADJUSTMENTS,
  [ROUTING_PATH.CLOSING.QUARTER_CLOSE]: FeatureFlag.MONTH_AND_QUARTER_CLOSE,
  [ROUTING_PATH.CLOSING.RECONCILIATION]: 'tab_budget_cash_management',
  [ROUTING_PATH.CLOSING.JOURNAL_ENTRIES]: 'tab_journal_entries',
};

@Injectable({
  providedIn: 'root',
})
export class QuarterCloseAdjustmentsGuard extends BasicRedirectToNextTabGuard {
  protected prefix = `/${ROUTING_PATH.CLOSING.INDEX}`;

  protected featureFlag = FeatureFlag.IN_MONTH_ADJUSTMENTS as keyof Flags;

  protected defaultNavigateUrl = `${this.prefix}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`;

  protected routerPathsAndFeatureFlags = PERIOD_CLOSE_ROUTES_FEATURE_FLAGS;

  constructor(launchDarklyService: LaunchDarklyService, router: Router) {
    super(launchDarklyService, router);
  }
}
