import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  TrialInsightsTableChangeKeyFn,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
  TrialInsightsTableToggleOrderFn,
} from '../../models/trial-insights-table.model';
import { TrialInsightsTableService } from './trial-insights-table.service';

@Component({
  selector: 'aux-trial-insights-table',
  templateUrl: './trial-insights-table.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsTableComponent implements OnInit {
  @Input() options!: TrialInsightsTableOptions;

  @Input() isLoadingRemaining!: boolean;

  @Input() selectedKey!: string;

  @Input() sortOrder!: string;

  @Input() changeSelectedKey!: TrialInsightsTableChangeKeyFn;

  @Input() toggleSortOrder!: TrialInsightsTableToggleOrderFn;

  constructor(public tableService: TrialInsightsTableService) {}

  ngOnInit(): void {
    this.selectedKey = this.options.buttons.defaultButtonKey;
  }

  select(buttonKey: string): void {
    this.changeSelectedKey(buttonKey);
  }

  sort(): void {
    if (this.isLoadingRemaining) {
      return;
    }

    this.toggleSortOrder();
  }

  get headers(): [string, string] {
    const headers = this.options.header.data;
    return this.tableService.headers(headers, this.selectedKey);
  }

  get rowData(): TrialInsightsTableRowData[] {
    const rowData = this.options.rowData.data;
    return this.tableService.rowData(rowData, this.selectedKey);
  }
}
