import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { GqlService } from '@shared/services/gql.service';
import { switchMap, tap } from 'rxjs/operators';
import {
  MilestoneModel,
  MilestoneStore,
} from '@models/milestone-category/milestone/milestone.store';
import { OverlayService } from '@shared/services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';

import { MilestoneCategoryModel, MilestoneCategoryStore } from './milestone-category.store';

@Injectable({ providedIn: 'root' })
export class MilestoneCategoryService {
  constructor(
    private milestoneCategoryStore: MilestoneCategoryStore,
    private milestoneStore: MilestoneStore,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.milestoneCategoryStore.setLoading(true);
        this.milestoneStore.setLoading(true);

        return this.gqlService.getMilestonesAndCategories$().pipe(
          tap(({ success, data, errors }) => {
            if (success && data) {
              this.milestoneCategoryStore.set(
                data.map(({ id, name, __typename, milestones, grouping_order, description }) => {
                  return {
                    __typename,
                    description,
                    grouping_order,
                    name,
                    id,
                    milestone_ids: (milestones || []).map((milestone) => milestone?.id) as string[],
                  };
                })
              );

              const milestoneArr: MilestoneModel[] = [];
              data.forEach(({ id: c_id, milestones }) => {
                (milestones || []).forEach((milestone) => {
                  if (milestone) {
                    const { id: m_id, __typename, name, description } = milestone;
                    milestoneArr.push({
                      __typename,
                      description,
                      id: m_id,
                      name,
                      milestone_category_id: c_id,
                    });
                  }
                });
              });
              this.milestoneStore.set(milestoneArr);
            } else {
              this.overlayService.error(errors);
            }

            this.milestoneCategoryStore.setLoading(false);
            this.milestoneStore.setLoading(false);
          })
        );
      })
    );
  }

  add(milestoneCategory: MilestoneCategoryModel) {
    this.milestoneCategoryStore.add(milestoneCategory);
  }

  addMilestoneToCategory(cat_id: string, milestone_id: string) {
    this.milestoneCategoryStore.update(cat_id, (state) => {
      return {
        milestone_ids: [...state.milestone_ids, milestone_id],
      };
    });
  }

  update(id: string, milestoneCategory: Partial<MilestoneCategoryModel>) {
    this.milestoneCategoryStore.update(id, milestoneCategory);
  }

  remove(id: ID) {
    this.milestoneCategoryStore.remove(id);
  }
}
