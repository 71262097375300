import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { QuarterCloseChecklistDirective } from '../../directives/quarter-close-checklist.directive';
import {
  ChecklistComponent,
  ChecklistComponentAddPoReportUploadFn,
  ChecklistComponentChangeLockFn,
  ChecklistComponentVendorEstimateUploadFn,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowComponent,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../../models/quarter-close-checklist.model';
import { QuarterCloseChecklistToggleService } from '../../services/quarter-close-checklist-toggle.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { Workflow } from '@shared/store/workflow/workflow.store';
import { UntypedFormControl } from '@angular/forms';
import { listUsersQuery, PermissionType } from '@shared/services/gql.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import dayjs from 'dayjs';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { map } from 'rxjs/operators';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { ChecklistNotesComponent } from '../checklist-notes/checklist-notes.component';
import { OverlayService } from '@shared/services/overlay.service';
import { QuarterCloseNotesStore } from '@shared/store/quarter-close-notes/quarter-close-notes.store';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';

@Component({
  selector: 'aux-checklist-row-info',
  templateUrl: './checklist-row-info.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistRowInfoComponent implements OnChanges {
  private readonly destroyRef = inject(DestroyRef);
  @Input() checklistComponent!: ChecklistComponent<QuarterCloseChecklistRowComponent>;

  @Input() rowId!: QuarterCloseChecklistRow;

  @Input() disabledRow = false;

  @Input() disabledLock = false;

  @Input() open = false;

  @Input() workflow!: Workflow;

  @Input() tooltip = '';

  @Input() changeLockStatus!: ChecklistComponentChangeLockFn;

  @Input() vendorEstimateUploadClick!: ChecklistComponentVendorEstimateUploadFn;

  @Input() addPoReportUploadClick!: ChecklistComponentAddPoReportUploadFn;

  @Input() vendorEstimateSummaries!: QuarterCloseChecklistVendorEstimateSummary[];

  @Input() title = '';

  @Input() detailRowContainerClassName = 'checklist-row-info-detail-container w-full mt-4 p-4';

  @Input() selectedMonth = '';

  @Input() hasUserPermissions = false;

  @Input() isPastQuarterMonth = false;

  @Input() checklistAdminPermission = false;

  @Input() queryParams: Params = {};

  @Input() permissionType: PermissionType | null = null;

  @Output() toggleOpened = new EventEmitter<boolean>();

  isAdmin = false;

  usersList$ = new BehaviorSubject<listUsersQuery[]>([]);

  isChecklistTimestampEnabled = this.launchDarklyService.$select(
    (flags) => flags.checklist_timestamp_last_update
  );

  externalUserWithPermissionsList$ = this.usersList$.pipe(
    map((listUsers) =>
      listUsers.filter(
        (user) =>
          !user.email.includes('@auxili.us') &&
          user.permissions.some(
            (permission) =>
              permission.permission_type === this.permissionType &&
              permission.permission_roles === 'E'
          )
      )
    )
  );

  lockedBy = '';

  lockedDate = '';

  locked = false;

  route = '';

  rowTitles = QuarterCloseChecklistRowTitles;

  zeroHyphen = Utils.zeroHyphen;

  assignToFormControl = new UntypedFormControl(null);

  user = '';

  workflow$ = new BehaviorSubject<Workflow | null>(null);

  opened$ = new BehaviorSubject<boolean>(false);

  @ViewChild(QuarterCloseChecklistDirective)
  checklistComponentHost!: QuarterCloseChecklistDirective;

  @ViewChild(ChecklistNotesComponent) notes!: ChecklistNotesComponent;

  originalWorkflow?: Workflow;

  workflowSubscription!: Subscription;

  constructor(
    private toggleService: QuarterCloseChecklistToggleService,
    private workflowQuery: WorkflowQuery,
    private invoiceService: InvoiceService,
    private workflowService: WorkflowService,
    private authQuery: AuthQuery,
    private cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    private quarterCloseNotesStore: QuarterCloseNotesStore,
    private quarterCloseNotesQuery: QuarterCloseNotesQuery,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.open) {
      this.opened$.next(this.open);
    }

    if (this.checklistComponent && this.open) {
      this.initializeChecklistComponent();
    }

    if (this.workflow) {
      this.initializeWorkflow();
      this.workflow$.next(this.workflow);
    } else if (!this.workflow) {
      this.resetWorkflow();
    }
  }

  async toggleRow(): Promise<void> {
    if (this.workflow) {
      if (this.open !== true) {
        this.toggleOpened.emit();
      }
      if (
        this.open === true &&
        this.quarterCloseNotesQuery.notes()[this.workflow.id]?.dirty?.someEditorDirty
      ) {
        const result = this.overlayService.openUnsavedChangesConfirmation();
        const event = await firstValueFrom(result.afterClosed$);

        if (event.data) {
          this.toggleService.toggleRow(this.rowId);
        }
      } else {
        this.toggleService.toggleRow(this.rowId);
      }
    }
  }

  initializeWorkflow() {
    const previousWorkflow = this.originalWorkflow;
    this.originalWorkflow = this.workflowQuery.getEntity(this.workflow.id);
    if (previousWorkflow?.id !== this.originalWorkflow?.id) {
      this.quarterCloseNotesStore.updateMonthlyReviewNotesCount(
        this.workflow.id,
        this.originalWorkflow?.monthly_review_note_count || 0
      );
    }
    this.isAdmin = this.authQuery.isAuxAdmin();
    this.lockedBy = this.workflow.updatedAuthorFullName;
    this.lockedDate = this.workflow.update_date;
    this.locked = this.workflow.properties.locked;
    this.route = this.workflow.properties.route ? `/${this.workflow.properties.route}` : '';
    this.user = '';
    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }
    this.workflowSubscription = this.workflowService.listUsers$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((users) => {
        this.usersList$.next([...users]);

        const selectedUser = users.find(
          (user) =>
            !user.email.includes('@auxili.us') &&
            user?.sub === this.workflow?.properties?.assigned_to &&
            user.permissions.some(
              (permission) =>
                permission.permission_type === this.permissionType &&
                permission.permission_roles === 'E'
            )
        );

        if (selectedUser) {
          this.user = `${selectedUser?.given_name} ${selectedUser?.family_name}`;
        }

        this.assignToFormControl.setValue(selectedUser ? selectedUser.sub : null);
        this.cdr.markForCheck();
      });
  }

  resetWorkflow() {
    this.lockedBy = '';
    this.lockedDate = '';
    this.locked = false;
    this.route = '';
  }

  initializeChecklistComponent(): void {
    setTimeout(() => {
      if (!this.checklistComponent || !this.open || !this.workflow) {
        return;
      }

      const { viewContainerRef } = this.checklistComponentHost;

      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<QuarterCloseChecklistRowComponent>(
        this.checklistComponent.component
      );

      componentRef.instance.parent = this;

      if (this.checklistComponent.id === 'GatherEstimates') {
        componentRef.instance.data = {
          vendorEstimateUploadClick: this.vendorEstimateUploadClick,
          disabledLock: this.disabledLock,
          vendorEstimateSummaries: this.vendorEstimateSummaries,
        };
      }
      if (this.checklistComponent.id === 'GatherContracts') {
        componentRef.instance.data = {
          addPoReportUploadClick: this.addPoReportUploadClick,
          disabledLock: this.locked,
        };
      }

      this.checklistComponentHost.changeDetectorRef.detectChanges();
    }, 0);
  }

  getLockBtnTooltip(): string {
    if (!this.hasUserPermissions) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.disabledLock ? this.tooltip : '';
  }

  getUnlockBtnTooltip(): string {
    return this.hasUserPermissions || this.isPastQuarterMonth
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  async changeAssign(workflow: Workflow) {
    const selectedUser = this.usersList$
      .getValue()
      .find((user) => user.sub === this.assignToFormControl.value);
    const currentUserID = this.authQuery.getValue().sub;
    const updateLockedBy = this.usersList$.getValue().find((user) => user.sub === currentUserID);
    const currenDate = dayjs(new Date()).format('YYYY-MM-DD');
    await this.changeLockStatus(
      this.locked,
      {
        ...workflow,
        updated_by: currentUserID ? currentUserID : workflow.updated_by,
        updatedAuthorFullName: updateLockedBy?.given_name + ' ' + updateLockedBy?.family_name,
        properties: {
          ...workflow.properties,
          assigned_to: selectedUser?.sub ? selectedUser?.sub : '',
          assigned_by: workflow.updated_by,
          assigned_date: currenDate,
        },
      },
      true
    );
  }
}
