import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import {
  CreateDriverBlendedPatientDistributionInput,
  createDriverBlendedPatientDistributionMutation,
  PatientGroup,
  UpdateDriverBlendedPatientDistributionInput,
  updateDriverBlendedPatientDistributionMutation,
} from '@shared/services/gql.service';
import { PatientCurveService } from '../../../state/patient-curve/patient-curve.service';
import { BlendedCurveModalDirective } from '../../blended-curve-modal/blended-curve-modal.component';
import { BlendedCurveModalDataModel } from '../../models/blended-curve-modal-data.model';
import { PatientCurveModel } from '../../../state/patient-curve/patient-curve.store';

@Component({
  selector: 'aux-patient-blended-curve-modal',
  templateUrl: '../../blended-curve-modal/blended-curve-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientBlendedCurveModalComponent extends BlendedCurveModalDirective<
  PatientCurveModel,
  PatientGroup
> {
  constituentGroups: PatientGroup[];

  constructor(
    ref: CustomOverlayRef<unknown, BlendedCurveModalDataModel<PatientCurveModel>>,
    formBuilder: UntypedFormBuilder,
    private patientCurveService: PatientCurveService
  ) {
    super(ref, formBuilder);
    this.constituentGroups = this.ref.data?.blendedCurve?.constituent_patient_groups || [];
  }

  updateData(): UpdateDriverBlendedPatientDistributionInput {
    return {
      blended_group_id: this.ref.data?.blendedCurve?.patient_group_id || '',
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      patient_group_ids: this.selectedCurveIds,
    };
  }

  createData(): CreateDriverBlendedPatientDistributionInput {
    return {
      name: this.blendedCurveForm.controls.blendedCurveName.value,
      patient_group_ids: this.selectedCurveIds,
    };
  }

  updateDriverBlendedDistribution(): Promise<
    GraphqlResponse<updateDriverBlendedPatientDistributionMutation>
  > {
    return this.patientCurveService.update(this.updateData());
  }

  createDriverBlendedDistribution(): Promise<
    GraphqlResponse<createDriverBlendedPatientDistributionMutation>
  > {
    return this.patientCurveService.add(this.createData());
  }

  getGroupId(item: PatientCurveModel): string {
    return item.patient_group_id || '';
  }
}
