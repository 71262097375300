import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  effect,
  model,
  input,
  signal,
} from '@angular/core';
import {
  CellClassParams,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TableConstants } from '@shared/constants/table.constants';
import { Utils } from '@shared/utils/utils';
import { BeInlineCategoryDropdownComponent } from '../../be-inline-category-dropdown/be-inline-category-dropdown.component';
import { ActivitySubType, ActivityType, Currency } from '@shared/services/gql.service';
import { cloneDeep, isEqual, set } from 'lodash-es';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import { AgRequiredHeaderComponent } from '@shared/ag-components/ag-required-header/ag-required-header.component';
import { AgDeleteCellComponent, AgDeleteCellComponentParams } from '../../ag-delete-cell.component';
import { AgTooltipComponent } from '../../ag-tooltip.component';
import { agFormatToNumberValueSetter } from '@shared/utils/ag-value-setter.utils';
import { decimalDivide } from '@shared/utils';
import {
  BeInlineCategoryDropdownOption,
  BeInlineCategoryDropdownParams,
} from '../../be-inline-category-dropdown/be-inline-category-dropdown.model';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  BeActivitiesAttributesModalRowData,
  BeActivityTypes,
  InAppDocumentState,
} from '../be-activities-attributes-modal.model';
import {
  BeActivitiesAttributesModalParams,
  BeActivitiesTabState,
  BeActivitiesTabToSave,
} from '../be-activities-attributes-modal.model';
import { MainQuery } from '@shared/store/main/main.query';
import {
  ConfirmationActionModalData,
  ConfirmationActionModalComponent,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { getEncodedAttributeName } from '@features/budget-attributes/services/budget-attributes.service';

const EMPTY_TABLE_TOOLTIP =
  'To continue, include an Activity with Units, Unit Cost, and location in budget';
const ERROR_TABLE_TOOLTIP = 'Please resolve errors to continue';

@Component({
  standalone: true,
  selector: 'aux-activities-tab',
  templateUrl: 'activities-tab.component.html',
  styleUrls: ['activities-tab.component.scss'],
  imports: [NgSelectModule, FormsModule, AgGridAngular, CommonModule],
})
export class ActivitiesTabComponent implements OnInit {
  @Input() gridData$ = new BehaviorSubject<BeActivitiesAttributesModalRowData[]>([]);

  @Input() data: BeActivitiesAttributesModalParams | null = null;

  @Input() hasExternalChanges = false;

  inAppDocumentState = model<InAppDocumentState>({ notes: '', bucket_keys: [] });

  @Output() toggleTabs = new EventEmitter<BeActivitiesTabState>();

  @Output() save = new EventEmitter<BeActivitiesTabToSave>();

  @Output() closeTab = new EventEmitter<void>();

  showNotesSection = input.required<boolean>();

  rowData: BeActivitiesAttributesModalRowData[] = [];
  filteredRowData: BeActivitiesAttributesModalRowData[] = [];

  saveButtonTooltipForNotes = 'Enter required note to save.';

  gridAPI!: GridApi;

  selectedVendor = '';

  vendors: { id: string; label: string }[] = [];

  columns: (ColDef | ColGroupDef)[] = [];

  headerNames: Record<string, string> = {};

  gridOptions: GridOptions = {};

  categories: BeInlineCategoryDropdownOption[] = [];

  categories$ = new BehaviorSubject<BeInlineCategoryDropdownOption[]>([]);

  isCategoryListChanged = new BehaviorSubject<boolean>(false);

  isThereAnyNotes = new BehaviorSubject<boolean>(false);

  validRows: Record<string, BeActivitiesAttributesModalRowData> = {};
  invalidRows: Record<string, BeActivitiesAttributesModalRowData> = {};
  deletedRows: Record<string, BeActivitiesAttributesModalRowData> = {};

  initialTableEmpty = false;

  firstDataLoad = true;

  saveButtonDisabled = signal(true);
  saveButtonTooltip = EMPTY_TABLE_TOOLTIP;

  editButtonDisabled = signal(false);
  editButtonTooltip = EMPTY_TABLE_TOOLTIP;

  currencyName = '';

  selectedCategories = {} as Record<BeActivityTypes, boolean>;

  constructor(
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
      if (this.inAppDocumentState()) {
        this.setNotes();
      }
    });
  }

  ngOnInit(): void {
    if (this.data) {
      this.categories = cloneDeep(this.data.categories);
      this.categories$.next(this.categories);
      this.currencyName = this.data.currencyName;
      this.editButtonDisabled.set(!this.data.editMode);
      this.vendors = [this.data.vendor];
      this.selectedVendor = this.data.vendor.id;

      this.columns = [
        {
          headerClass: 'two-row-header ag-header-align-center',
          headerName: '',
          field: 'action',
          cellRenderer: AgDeleteCellComponent,
          cellRendererParams: <AgDeleteCellComponentParams>{
            onDelete: async (params: AgDeleteCellComponentParams) => {
              const data = params.node.data;
              if (!data) {
                return;
              }
              if (!data.isGenerated) {
                const ref = this.overlayService.openPopup<
                  ConfirmationActionModalData,
                  boolean,
                  ConfirmationActionModalComponent
                >({
                  modal: ConfirmationActionModalComponent,
                  settings: {
                    header: 'Delete Activity?',
                    primaryButton: {
                      label: 'Delete Activity',
                      variant: 'negative',
                    },
                  },
                  data: {
                    message:
                      'This will permanently delete the selected activity. This action cannot be undone, and аll associated data cannot be recovered.',
                    keywordToExecuteAction: 'Delete Activity',
                  },
                });

                const { data: refData } = await firstValueFrom(ref.afterClosed$);

                if (!refData) {
                  return;
                }
                this.deletedRows[data.id] = data;
              }

              delete this.validRows[data.id];
              delete this.invalidRows[data.id];
              switch (data.activity_type) {
                case ActivityType.ACTIVITY_DISCOUNT:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  delete this.selectedCategories[data.activity_type];
                  break;
                default:
                  break;
              }
              this.gridAPI.applyTransaction({
                remove: [{ id: data.id }],
              });
              this.updateState();
              this.cdr.markForCheck();
            },
            isDisabled: (params: AgDeleteCellComponentParams) => {
              return params.data?.deletable
                ? ''
                : 'Activities with actuals booked or invoices mapped cannot be deleted.';
            },
          },
          width: 32,
          minWidth: 32,
          maxWidth: 32,
          cellClass: '!p-0 h-full',
          editable: false,
        },
        {
          headerName: 'Category',
          field: 'category',
          headerClass: 'two-row-header ag-header-align-center',
          headerComponent: AgRequiredHeaderComponent,
          cellRenderer: BeInlineCategoryDropdownComponent,
          editable: false,
          cellClass: '!p-0',
          cellRendererParams: <BeInlineCategoryDropdownParams>{
            categories$: this.categories$,
            usedCategories: () => {
              const ct = {
                ...this.selectedCategories,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...Object.entries(this.data.usedCategories).reduce(
                  (acc, [key, bool]) => {
                    if (bool) {
                      acc[key as BeActivityTypes] = true;
                    }
                    return acc;
                  },
                  {} as Record<BeActivityTypes, boolean>
                ),
              };

              return {
                ...ct,
                [ActivityType.ACTIVITY_INVESTIGATOR]:
                  ct.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES &&
                  ct.ACTIVITY_INVESTIGATOR_PATIENT_VISITS &&
                  ct.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES,
              };
            },
            onCategoryRename: (newCategoryName: string, option: BeInlineCategoryDropdownOption) => {
              const oldFullPath = option.fullPath;
              const optionIndex = this.categories.findIndex(
                (item) => item.id === option.id && item.name === option.name
              );

              this.categories[optionIndex].name = newCategoryName;
              this.categories[optionIndex].fullPath =
                `${this.categories[optionIndex].path}>${this.categories[optionIndex].name}`;
              this.categories[optionIndex].isRenamed = true;
              this.categories = this.categories.map((category) => {
                if (category.path.startsWith(oldFullPath) && category.type === option.type) {
                  return {
                    ...category,
                    path: category.path.replace(
                      oldFullPath,
                      this.categories[optionIndex].fullPath.replace(/\$/g, '$$$$')
                    ),
                    fullPath: category.fullPath.replace(
                      oldFullPath,
                      this.categories[optionIndex].fullPath.replace(/\$/g, '$$$$')
                    ),
                    isRenamed: true,
                  };
                }
                return category;
              });

              this.categories$.next(this.categories);
              this.isCategoryListChanged.next(true);
            },
            onCategoryAdd: (newCategoryName: string, option: BeInlineCategoryDropdownOption) => {
              const newCategory: BeInlineCategoryDropdownOption = {
                disabled: false,
                id: Utils.uuid(),
                indent: option.indent + 1,
                name: newCategoryName,
                type: option.type,
                path: option.indent === 1 ? '' : option.path + '>' + option.name,
                fullPath:
                  (option.indent === 1 ? '' : option.path + '>' + option.name) +
                  '>' +
                  newCategoryName,
                categoryType: option.categoryType,
              };
              const optionIndex = this.categories.findIndex(
                (item) => item.id === option.id && item.name === option.name
              );
              const index = this.categories.findIndex(
                (item, index) => item.indent <= option.indent && index > optionIndex,
                optionIndex
              );

              if (index !== -1) {
                this.categories.splice(index, 0, newCategory);
              } else {
                this.categories.push(newCategory);
              }

              this.isCategoryListChanged.next(true);
              this.categories$.next(this.categories);
              return newCategory;
            },
            onCategoryChange: (params, id, type) => {
              const data = params.data;
              if (data) {
                this.updateState({ ...data, category: id, activity_type: type });
                switch (type) {
                  case ActivityType.ACTIVITY_DISCOUNT:
                    params.node.updateData(<BeActivitiesAttributesModalRowData>{
                      ...params.node.data,
                      activity_name: 'Services Discount',
                      unit_num: 1,
                    });
                    this.selectedCategories[ActivityType.ACTIVITY_DISCOUNT] = true;
                    break;
                  case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                    params.node.updateData(<BeActivitiesAttributesModalRowData>{
                      ...params.node.data,
                      activity_name: 'Patient Invoiceables',
                    });
                    this.selectedCategories[
                      ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES
                    ] = true;
                    break;
                  case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
                    params.node.updateData(<BeActivitiesAttributesModalRowData>{
                      ...params.node.data,
                      activity_name: 'Site Invoiceables',
                    });
                    this.selectedCategories[
                      ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES
                    ] = true;
                    break;
                  case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
                    params.node.updateData(<BeActivitiesAttributesModalRowData>{
                      ...params.node.data,
                      activity_name: 'Patient Visits',
                    });
                    this.selectedCategories[ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS] =
                      true;
                    break;
                  default:
                    break;
                }
                this.gridAPI.refreshCells({ rowNodes: [params.node] });
              }
            },
          },
          minWidth: 250,
          cellClassRules: {
            'error-outline': (params: CellClassParams<BeActivitiesAttributesModalRowData>) =>
              (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) &&
              !params.value,
          },
        },
        {
          headerName: 'Activities',
          field: 'activity_name',
          headerClass: 'two-row-header ag-header-align-center',
          headerComponent: AgRequiredHeaderComponent,
          tooltipValueGetter: (params: ITooltipParams<BeActivitiesAttributesModalRowData>) => {
            if (params.data) {
              switch (params.data.activity_type) {
                case ActivityType.ACTIVITY_DISCOUNT:
                case ActivityType.ACTIVITY_INVESTIGATOR:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                  return 'Auxilius Product restricts Category and Activity Name for Investigator and Discount.';
                default:
                  break;
              }

              return params.data.activity_name;
            }
            return '';
          },
          editable: (params: EditableCallbackParams<BeActivitiesAttributesModalRowData>) => {
            switch (params.data?.activity_type) {
              case ActivityType.ACTIVITY_DISCOUNT:
              case ActivityType.ACTIVITY_INVESTIGATOR:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                return false;
              default:
                return true;
            }
          },
          cellClass: '!block truncate text-left',
          cellClassRules: {
            'error-outline': (params: CellClassParams<BeActivitiesAttributesModalRowData>) =>
              (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) &&
              (!params.value || !params.value.trim()),
          },
        },
        {
          headerName: 'Overall Budget',
          headerClass:
            'ag-header-align-center bg-aux-gray-dark gray-dark-header-group aux-black border-0',
          children: [
            {
              headerName: 'Display Label',
              field: 'display_label',
              headerClass: 'ag-header-align-center text-sm',
              width: 120,
              maxWidth: 120,
            },
            {
              headerName: 'Unit of Measure',
              headerClass: 'ag-header-align-center text-sm',
              field: 'uom',
              colId: 'uom',
              tooltipValueGetter: (params: ITooltipParams<BeActivitiesAttributesModalRowData>) => {
                if (params.data?.isPercentage) {
                  return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
                }
                return params.data?.uom || '';
              },
              hide: this.data.uomHide,
              cellClass: ['!block', 'truncate', 'text-left'],
              valueFormatter: (params) => params.value || '',
              width: 120,
              maxWidth: 120,
              editable: (params: EditableCallbackParams<BeActivitiesAttributesModalRowData>) => {
                return !params.data?.isPercentage;
              },
            },
            {
              headerName: 'Units',
              headerClass: 'ag-header-align-center text-sm',
              headerComponent: AgRequiredHeaderComponent,
              field: 'unit_num',
              tooltipValueGetter: (params: ITooltipParams<BeActivitiesAttributesModalRowData>) => {
                if (params.data?.isPercentage) {
                  return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
                }
                return '';
              },
              valueFormatter: (params) => {
                const resp = Utils.decimalFormatter(params.data?.unit_num);
                return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                  ? `${params.value}`
                  : resp;
              },
              editable: (params: EditableCallbackParams<BeActivitiesAttributesModalRowData>) => {
                switch (params.data?.activity_type) {
                  case ActivityType.ACTIVITY_DISCOUNT:
                    return false;
                  default:
                    return true;
                }
              },
              cellClass: ['budget-units', 'text-right'],
              width: 120,
              maxWidth: 120,
              valueParser: (p) => {
                if (p.newValue == '') {
                  return '';
                }
                const parsed = parseFloat(p.newValue);
                return Number.isFinite(parsed) ? parsed : p.newValue;
              },
              valueSetter: (params) => {
                params.data.unit_num = params.newValue;
                return true;
              },
              cellClassRules: {
                'error-outline': (params: CellClassParams<BeActivitiesAttributesModalRowData>) =>
                  (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) &&
                  !this.isValidNumber(params.value),
              },
            },
            {
              headerName: 'Unit Cost',
              headerClass: 'ag-header-align-center text-sm',
              headerComponent: AgRequiredHeaderComponent,
              field: 'unit_cost',
              cellDataType: 'text',
              tooltipValueGetter: (params: ITooltipParams<BeActivitiesAttributesModalRowData>) => {
                if (params.data?.isPercentage) {
                  return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
                }
                return '';
              },
              valueFormatter: (
                params: ValueFormatterParams<BeActivitiesAttributesModalRowData>
              ) => {
                if (params.data) {
                  if (params.data.isPercentage) {
                    return Utils.percentageFormatter(decimalDivide(params.value, 100), {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                  }

                  const resp = Utils.agCurrencyFormatter(
                    params,
                    params.data.currencyName as Currency
                  );

                  return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                    ? `${params.value}`
                    : resp;
                }
                return '';
              },
              cellClass: (p) => [`budgetCost${p.data.currencyName}`, 'text-right'],
              width: 120,
              maxWidth: 120,
              valueSetter: (params) =>
                params.newValue === '' || params.newValue == null
                  ? set(params.data, 'unit_cost', '')
                  : agFormatToNumberValueSetter(params, ['$', ','], 'unit_cost'),
              cellClassRules: {
                'error-outline': (params: CellClassParams<BeActivitiesAttributesModalRowData>) => {
                  const data = params.data;
                  if (data) {
                    if (data.isPercentage) {
                      return false;
                    }

                    const isDiscountInvalid =
                      data.activity_type === ActivityType.ACTIVITY_DISCOUNT
                        ? +params.value >= 0
                        : false;

                    return (
                      (data.isGenerated && !this.isRowEmpty(data)
                        ? !this.isValidNumber(params.value)
                        : false) ||
                      (this.isRowEmpty(data) ? false : !this.isValidNumber(params.value)) ||
                      isDiscountInvalid
                    );
                  }
                  return false;
                },
              },
              editable: (params: EditableCallbackParams<BeActivitiesAttributesModalRowData>) => {
                return !params.data?.isPercentage;
              },
            },
            {
              headerName: 'Total Cost',
              headerClass: 'ag-header-align-center text-sm',
              headerComponent: AgRequiredHeaderComponent,
              cellStyle: {
                'text-overflow': 'unset',
              },
              valueGetter: (params: ValueGetterParams<BeActivitiesAttributesModalRowData>) => {
                let val;
                if (params.data) {
                  if (
                    this.isValidNumber(params.data.unit_cost) &&
                    this.isValidNumber(params.data.unit_num)
                  ) {
                    val = params.data.unit_num * params.data.unit_cost;
                  }
                }
                return val ?? '';
              },
              valueFormatter: (
                params: ValueFormatterParams<BeActivitiesAttributesModalRowData>
              ) => {
                if (params.data) {
                  if (params.data.isPercentage) {
                    return Utils.percentageFormatter(decimalDivide(params.value, 100), {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    });
                  }

                  const resp = Utils.agCurrencyFormatter(
                    params,
                    params.data.currencyName as Currency
                  );

                  return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                    ? `${params.value}`
                    : resp;
                }
                return '';
              },
              cellClass: (p) => [
                `budgetCost${p.data.currencyName}`,
                'text-right',
                TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL,
              ],
              width: 120,
              maxWidth: 120,
              cellClassRules: {
                'error-outline': (params: CellClassParams<BeActivitiesAttributesModalRowData>) => {
                  const data = params.data;
                  if (data) {
                    if (data.isPercentage) {
                      return false;
                    }

                    const isDiscountInvalid =
                      data.activity_type === ActivityType.ACTIVITY_DISCOUNT
                        ? +params.value >= 0
                        : false;

                    return (
                      (data.isGenerated && !this.isRowEmpty(data)
                        ? !this.isValidNumber(params.value)
                        : false) ||
                      (this.isRowEmpty(data) ? false : !this.isValidNumber(params.value)) ||
                      isDiscountInvalid
                    );
                  }
                  return false;
                },
              },
              editable: false,
            },
          ],
        },
      ];

      this.data.attributes.forEach((attr) => {
        const field = `attributes.${getEncodedAttributeName(attr.attribute_name)}`;
        this.headerNames[field] = attr.attribute_name;
      });

      this.gridOptions = <GridOptions>{
        ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
        tooltipShowDelay: 0,
        headerHeight: 32,
        stopEditingWhenCellsLoseFocus: true,
        fillOperation: (params) => {
          let index = params.rowNode.rowIndex;
          if (params.column.getColId() === 'category' && index != null) {
            switch (params.direction) {
              case 'up':
                index += 1;
                break;
              case 'down':
                index -= 1;
                break;
              case 'left':
              case 'right':
                return false;
            }
            const firstFocusedRowIndex = params.api.getFocusedCell()?.rowIndex;
            if (
              index < params.api.getDisplayedRowCount() - 1 &&
              firstFocusedRowIndex !== undefined
            ) {
              const starterNode = params.api.getDisplayedRowAtIndex(firstFocusedRowIndex);
              const changeNode =
                params.direction === 'down'
                  ? params.api.getDisplayedRowAtIndex(index + 1)
                  : params.api.getDisplayedRowAtIndex(index - 1);
              const passedActivityTypes = [
                ActivityType.ACTIVITY_DISCOUNT,
                ActivityType.ACTIVITY_INVESTIGATOR,
                ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS,
                ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES,
                ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES,
              ];
              if (changeNode && starterNode) {
                const currentNodeActivityType = changeNode.data.activity_type as
                  | ActivityType
                  | ActivitySubType;
                const starterNodeActivityType = starterNode.data.activity_type as
                  | ActivityType
                  | ActivitySubType;

                if (
                  currentNodeActivityType &&
                  starterNodeActivityType &&
                  currentNodeActivityType !== starterNodeActivityType
                ) {
                  return false;
                }
                if (changeNode.data.category) {
                  if (passedActivityTypes.includes(currentNodeActivityType)) {
                    return false;
                  }
                } else {
                  if (passedActivityTypes.includes(starterNodeActivityType)) {
                    return false;
                  }
                }
              }
              const data = {
                ...params.rowNode.data,
                category: starterNode ? starterNode.data.category : params.rowNode.data.category,
                activity_type: starterNode
                  ? starterNode.data.activity_type
                  : params.rowNode.data.activity_type,
              };
              params.rowNode.updateData(data);
              this.updateState(data);
              this.gridAPI.refreshCells({ rowNodes: [params.rowNode] });
            }
          }
          return false;
        },
        defaultColDef: {
          editable: true,
          tooltipComponent: AgTooltipComponent,
          suppressMenu: true,
        },
        enableFillHandle: true,
        enableRangeSelection: true,
        columnDefs: this.columns,
      };
    }

    this.gridData$.subscribe((value) => {
      this.rowData = value;
      // if the rows are empty or less than 5, add empty rows
      if (this.rowData.length < 6 && !this.data?.editMode) {
        const rowsToAdd = 5 - this.rowData.length;
        for (let i = 0; i < rowsToAdd; i++) {
          this.rowData.push(this.emptyRow());
        }
      }
      if (this.firstDataLoad) {
        this.initialTableEmpty = this.areAllRowsEmpty(this.rowData);
      }

      this.rowData.forEach((row) => {
        if (row.changed) {
          this.updateState(row);
        }
      });

      this.filteredRowData = this.rowData.filter((row) => {
        if (row.deleted) {
          this.deletedRows[row.id] = row;
          return false;
        }
        return true;
      });
    });
  }

  isRowValid(data: BeActivitiesAttributesModalRowData) {
    return (
      data.category &&
      data.activity_name &&
      !!data.activity_name.trim() &&
      this.isValidNumber(data.unit_cost) &&
      this.isValidNumber(data.unit_num)
    );
  }

  isValidNumber(n: unknown): n is number {
    return Number.isFinite(parseFloat(<string>n));
  }

  onGridReady(event: GridReadyEvent) {
    this.gridAPI = event.api;
    this.gridAPI.sizeColumnsToFit();
  }

  addNewRow() {
    this.gridAPI.applyTransaction({
      add: [this.emptyRow()],
    });
    this.gridAPI.sizeColumnsToFit();
  }

  emptyRow = () => {
    return this.addAttributesIfDoesntExist(<BeActivitiesAttributesModalRowData>{
      activity_no: '',
      activity_name: '',
      display_label: '',
      uom: '',
      unit_num: '',
      unit_cost: '',
      category: '',
      id: Utils.uuid(),
      isGenerated: true,
      activity_type: '',
      attributes: {},
      changed: false,
      currencyName: this.currencyName,
      deleted: false,
      deletable: true,
      isPercentage: false,
    });
  };

  getRowId: GetRowIdFunc<BeActivitiesAttributesModalRowData> = (d) => {
    return d.data.id;
  };

  onCellEdit(
    params: Pick<
      CellEditingStoppedEvent<BeActivitiesAttributesModalRowData>,
      'newValue' | 'oldValue' | 'data' | 'node'
    >
  ) {
    if (params.newValue !== params.oldValue) {
      this.updateState(params.data);
      this.gridAPI.refreshCells({ rowNodes: [params.node] });
    }
  }

  setNotes(): void {
    this.isThereAnyNotes.next(!!this.inAppDocumentState().notes);
  }

  updateState(data?: BeActivitiesAttributesModalRowData) {
    if (data) {
      const isEmpty = this.isRowEmpty(data);
      let valid = this.isRowValid(data);
      const discountValid =
        data.activity_type === ActivityType.ACTIVITY_DISCOUNT && !data.isPercentage
          ? this.isValidNumber(data.unit_cost) && +data.unit_cost < 0
          : true;
      if (!discountValid) {
        valid = false;
      }

      if (valid) {
        this.validRows = {
          ...this.validRows,
          [data.id]: data,
        };
        delete this.invalidRows[data.id];
      } else {
        if (!isEmpty) {
          this.invalidRows = {
            ...this.invalidRows,
            [data.id]: data,
          };
        }
        delete this.validRows[data.id];
      }
    }

    const isThereAnyValidRow = !!Object.keys(this.validRows).length;
    const isThereAnyInvalidRow = !!Object.keys(this.invalidRows).length;
    const isThereAnyDeletedRow = !!Object.keys(this.deletedRows).length;
    const isThereAnyRow = this.gridAPI ? this.gridAPI.getDisplayedRowCount() > 0 : true;

    if (isThereAnyInvalidRow) {
      this.saveButtonDisabled.set(true);
      this.editButtonDisabled.set(true);
      this.saveButtonTooltip = ERROR_TABLE_TOOLTIP;
      this.editButtonTooltip = ERROR_TABLE_TOOLTIP;
      return;
    }

    this.saveButtonDisabled.set(false);
    this.editButtonDisabled.set(false);
    this.saveButtonTooltip = EMPTY_TABLE_TOOLTIP;
    this.editButtonTooltip = EMPTY_TABLE_TOOLTIP;

    if (!isThereAnyValidRow) {
      this.saveButtonDisabled.set(!isThereAnyDeletedRow);
      this.editButtonDisabled.set(!this.data?.editMode);
    }

    if (!isThereAnyRow) {
      this.editButtonDisabled.set(true);
    }
  }

  isRowEmpty(data: BeActivitiesAttributesModalRowData) {
    return isEqual({ ...data, id: '', isGenerated: true }, { ...this.emptyRow(), id: '' });
  }

  areAllRowsEmpty(data: BeActivitiesAttributesModalRowData[]) {
    this.firstDataLoad = false;
    return data.every((row) => !this.isRowValid(row));
  }

  addAttributesIfDoesntExist = (row: BeActivitiesAttributesModalRowData) => {
    Object.keys(this.headerNames).forEach((value) => {
      const v = value.replace('attributes.', '');
      if (!row.attributes[v]) {
        row.attributes[v] = '';
      }
    });
    return row;
  };

  getRowData() {
    const ids = new Set(Object.keys(this.validRows));
    const deletedIds = new Set(Object.keys(this.deletedRows));

    const rows: BeActivitiesAttributesModalRowData[] = [];

    this.filteredRowData.forEach((row) => {
      if (!row.isGenerated || (row.isGenerated && row.changed)) {
        const changed = !!this.validRows[row.id];
        const deleted = !!this.deletedRows[row.id];
        ids.delete(row.id);
        deletedIds.delete(row.id);
        rows.push(
          this.addAttributesIfDoesntExist({
            ...row,
            ...this.validRows[row.id],
            changed,
            deleted,
          })
        );
      }
    });
    ids.forEach((id) => {
      rows.push(
        this.addAttributesIfDoesntExist({
          ...this.validRows[id],
          changed: true,
        })
      );
    });
    deletedIds.forEach((id) => {
      rows.push({
        ...this.deletedRows[id],
        deleted: true,
      });
    });

    return rows;
  }

  onCloseActivities(): void {
    this.closeTab.emit();
  }

  isActivitiesTabHasChanges(): boolean {
    return (
      !!Object.keys(this.invalidRows).length ||
      !!Object.keys(this.validRows).length ||
      !!Object.keys(this.deletedRows).length ||
      this.isCategoryListChanged.getValue()
    );
  }

  onCellChanged(event: CellValueChangedEvent<BeActivitiesAttributesModalRowData>) {
    this.onCellEdit(event);
  }

  onSaveActivities(): void {
    this.save.emit({
      rows: this.getRowData(),
      isCategoryListChanged: this.isCategoryListChanged.getValue(),
      temporaryCategories: this.categories,
      notes: this.inAppDocumentState().notes,
      supporting_document_s3_bucket_keys: this.inAppDocumentState().bucket_keys,
    });
  }

  isSaveButtonDisabledAct(): boolean {
    if (
      (this.initialTableEmpty && !this.isActivitiesTabHasChanges()) ||
      (this.showNotesSection() ? !this.isThereAnyNotes.getValue() : false)
    ) {
      return true;
    }
    if (!this.isActivitiesTabHasChanges() && !this.hasExternalChanges) {
      return true;
    }
    return Object.keys(this.invalidRows).length > 0;
  }

  saveBtnTooltipAct(): string {
    if ((this.initialTableEmpty || !this.hasExternalChanges) && !this.isActivitiesTabHasChanges()) {
      return EMPTY_TABLE_TOOLTIP;
    }
    if (Object.keys(this.invalidRows).length > 0) {
      return this.saveButtonTooltip;
    }
    if (this.showNotesSection() && !this.isThereAnyNotes.getValue()) {
      return this.saveButtonTooltipForNotes;
    } else {
      return '';
    }
  }

  goToAttributesTab(): void {
    this.gridData$.next(this.getRowData());
    this.toggleTabs.emit({
      isCategoryListChanged: this.isCategoryListChanged.getValue(),
      categories: this.categories,
    });
  }
  scrollToNotes() {
    if (!this.showNotesSection()) {
      return;
    }

    if (
      !this.inAppDocumentState().notes &&
      !(Object.keys(this.invalidRows).length > 0) &&
      (this.isActivitiesTabHasChanges() || this.hasExternalChanges)
    ) {
      const elem = document.getElementById('notes');
      const auxInputElemId = elem?.children[0].id || '';
      const auxInputElem = document.getElementById(auxInputElemId);
      if (auxInputElem) {
        auxInputElem.focus();
        auxInputElem.blur();
      }
    }
  }
}
