import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';

@Pipe({
  name: 'money',
  standalone: true,
})
export class MoneyPipe implements PipeTransform {
  transform(value?: number | null | undefined, currency?: Currency): string {
    return Utils.currencyFormatter(value || 0, {}, currency);
  }
}
