import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { BasicRedirectToNextTabGuard } from '@shared/guards/basic-redirect-to-next-tab.guard';
import { FORECAST_ROUTES_FEATURE_FLAGS } from '../../constants/forecast-routes-feature-flags.const';

@Injectable({
  providedIn: 'root',
})
export class ForecastGuard extends BasicRedirectToNextTabGuard {
  protected prefix = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}`;

  protected featureFlag = 'forecast_methodology_tab' as keyof Flags;

  protected defaultNavigateUrl = `${this.prefix}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}`;

  protected routerPathsAndFeatureFlags = FORECAST_ROUTES_FEATURE_FLAGS;

  constructor(launchDarklyService: LaunchDarklyService, router: Router) {
    super(launchDarklyService, router);
  }
}
