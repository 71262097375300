import { OverlayModule } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';

export interface AuxSelectConfig {
  options: string[];
  data: string[];
  header: string;

  sub?: string;
  selected?: string;
  showArrowUp?: boolean;
  showArrowDown?: boolean;
}

@Component({
  selector: 'aux-card-select',
  templateUrl: './aux-card-select.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, NgClass, OverlayModule],
})
export class AuxCardSelectComponent implements OnInit {
  _config: AuxSelectConfig = {
    options: [],
    data: [],
    header: '',
  };

  _loading = false;

  get loading() {
    return this._loading;
  }

  @Input() set loading(loading: boolean) {
    this._loading = loading;
  }

  get config(): AuxSelectConfig {
    return this._config;
  }

  @Input() set config(config: AuxSelectConfig) {
    this._config = config;
    if (this.selected == null) {
      this.selectFirstItem();
    }
  }

  highlighted: number | null = null;

  selected: string | undefined;

  selectedIndex = 0;

  optionCount = 0;

  isListOpen = false;

  constructor(private cdr: ChangeDetectorRef) {}

  openList() {
    this.isListOpen = true;
    if (this.selectedIndex != null) {
      this.highlighted = this.selectedIndex;
    } else {
      this.highlighted = 0;
    }
    this.cdr.detectChanges();
  }

  selectFirstItem() {
    let itemIdx = -1;
    if (this.config.selected) {
      itemIdx = this.config.options.indexOf(this.config.selected);
    }
    this.optionCount = this.config.options.length;
    if (this.config.options.length) {
      this.highlightOption(itemIdx === -1 ? 0 : itemIdx);
      this.selectOption();
    }
  }

  closeList() {
    this.isListOpen = false;
    this.highlighted = null;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.selectFirstItem();
  }

  highlightOption(index: number): void {
    this.highlighted = index;
  }

  selectOption(): void {
    const index = this.highlighted;
    if (index != null) {
      this.selected = this.config.options[index];
      this.selectedIndex = index;
      this.closeList();
    }
  }

  onListKeydown(keydownEvent: KeyboardEvent): void {
    keydownEvent.preventDefault();
    // tslint:disable-next-line: deprecation
    switch (keydownEvent.key) {
      case 'Enter':
      case 'Space':
        this.selectOption();
        break;
      case 'ArrowUp':
        if (this.highlighted) {
          const index = this.highlighted - 1 < 0 ? this.optionCount - 1 : this.highlighted - 1;
          this.highlightOption(index);
        }
        break;
      case 'ArrowDown':
        if (this.highlighted) {
          const index2 = this.highlighted + 1 > this.optionCount - 1 ? 0 : this.highlighted + 1;
          this.highlightOption(index2);
        }
        break;
      case 'Escape':
        this.closeList();
        break;
      default:
        break;
    }
  }
}
