<div
  [ngClass]="{
    'mb-4': showNotesSection()
  }"
  [ngStyle]="{
    width: showNotesSection() ? '1200px' : '1220px'
  }"
>
  <ag-grid-angular
    class="ag-theme-aux tabular-nums be-attributes-table"
    [ngStyle]="{
      height: showNotesSection() ? '300px' : '530px'
    }"
    [rowData]="filteredRowData"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [getRowId]="getRowId"
    (cellValueChanged)="onCellChanged($event)"
  />
</div>
