import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { OverlayService } from '@shared/services/overlay.service';
import { Utils } from '@shared/utils/utils';
import {
  BudgetType,
  Currency,
  OrganizationType,
  PermissionType,
} from '@shared/services/gql.service';
import {
  OrganizationDialogComponent,
  OrganizationDialogData,
} from './organization-dialog/organization-dialog.component';
import { MultipleOrganizationsDialogComponent } from './multiple-organizations-dialog/multiple-organizations-dialog';
import { BehaviorSubject, ReplaySubject, combineLatest, firstValueFrom } from 'rxjs';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CellClickedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgCellWrapperComponent } from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { cellSize } from '@pages/budget-page/tabs/budget-enhanced/column-defs';
import { VendorGridActionComponent } from './vendor-grid-action/vendor-grid-action.component';
import { VendorGridOverlayNoRowCellComponent } from './vendor-grid-overlay-no-row-template/vendor-grid-overlay-no-row-cell/vendor-grid-overlay-no-row-cell.component';
import { VendorWorkflowDirective } from '@pages/vendor-payments-page/directives/vendor-workflow.directive';
import { CurrencyToggle } from '@shared/types/components.type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { AgCheckRerenderComponent } from '@shared/ag-components/ag-check-rerender/ag-check-rerender.component';
import { TableConstants } from '@shared/constants/table.constants';
import { BudgetQuery } from '@models/budget/budget.query';
import { BudgetService } from '@features/budget/services/budget.service';

export type VendorsGridData = {
  vendor_id: string;
  vendor_name: string;
  receivesVendorEstimate: boolean;
  contractCurrency: Currency;
  total_budget: number;
};

@Component({
  selector: 'aux-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{ directive: VendorWorkflowDirective }],
})
export class VendorsComponent {
  PermissionType = PermissionType;

  ctx = inject(VendorWorkflowDirective);

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    suppressPaginationPanel: true,
    getRowId: ({ data }) => data.vendor_id,
    cacheBlockSize: 250,
    tooltipShowDelay: 0,
    noRowsOverlayComponent: VendorGridOverlayNoRowCellComponent,
    noRowsOverlayComponentParams: {
      onAddMultipleVendorsFN: () => {
        this.onAddMultipleVendors();
      },
      onNewVendorFN: () => {
        this.onNewVendor();
      },
    },
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      minWidth: 80,
      cellRenderer: AgCellWrapperComponent,
    },
    suppressCellFocus: true,
    columnDefs: [
      {
        headerName: 'vendor_id',
        field: 'vendor_id',
        hide: true,
      },
      {
        headerName: '',
        field: 'actions',
        cellRenderer: VendorGridActionComponent,
        cellRendererParams: {
          editClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.clickEditButton(rowNode);
          },
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.clickRemoveVendor(rowNode);
          },
        },
        cellClass: ['grid-cell', 'action-cell'],
        maxWidth: 85,
        minWidth: 85,
        resizable: false,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        tooltipField: 'vendor_name',
        resizable: true,
        cellClass: [
          'grid-cell',
          'ag-cell-align-left',
          'aux-link',
          'cursor-pointer',
          'truncate',
          '!block',
        ],
        onCellClicked: (event: CellClickedEvent) => {
          this.clickEditButton(event.node as RowNode);
        },
      },
      {
        headerName: 'Total Budget',
        field: 'total_budget',
        width: 150,
        minWidth: 150,
        suppressSizeToFit: true,
        resizable: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agMultipleCurrencyFormatter(
            new BehaviorSubject<CurrencyToggle>(CurrencyToggle.CONTRACTED)
          )(params);
        },
        cellClass: ['grid-cell', 'ag-cell-align-right'],
      },
      {
        headerName: 'Currency',
        field: 'contractCurrency',
        width: cellSize.medium,
        minWidth: cellSize.medium,
        suppressSizeToFit: true,
        resizable: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value;
        },
        cellClass: ['grid-cell', 'ag-cell-align-left'],
      },
      {
        headerName: 'Provides Vendor Estimates',
        field: 'receivesVendorEstimate',
        width: 170,
        suppressSizeToFit: true,
        cellRenderer: AgCheckRerenderComponent,
        cellClass: [
          'grid-cell',
          TableConstants.STYLE_CLASSES.CELL_VERTICAL_HORIZONTAL_ALIGN_CENTER,
        ],
      },
    ],
  } as GridOptions;

  gridData$ = new BehaviorSubject<VendorsGridData[] | undefined>(undefined);

  currentOpenMonth = '';

  currentQuarterMonths: string[] = [];

  constructor(
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    public vendorsQuery: OrganizationQuery,
    public budgetQuery: BudgetQuery,
    private budgetService: BudgetService,
    private workflowService: WorkflowService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.vendorsQuery
      .selectAll()
      .pipe(takeUntilDestroyed())
      .subscribe((vendors) => {
        const vendorGridData: VendorsGridData[] = vendors
          .map((vendor) => {
            if (vendor.organization_type === OrganizationType.ORGANIZATION_VENDOR) {
              return {
                vendor_name: vendor.name,
                vendor_id: vendor.id,
                total_budget: vendorsQuery.getPrimaryBudgetVersion(vendor.id)?.total_budget_amount,
                contractCurrency: vendor.currency,
                receivesVendorEstimate: !!vendor.receives_vendor_estimate,
              } as VendorsGridData;
            }
          })
          .filter((val) => !!val) as VendorsGridData[];
        this.gridData$.next(vendorGridData);
      });

    this.organizationService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(takeUntilDestroyed())
      .subscribe();

    combineLatest([this.route.queryParams, this.vendorsQuery.selectLoading()])
      .pipe(takeUntilDestroyed())
      .subscribe(([params, loading]) => {
        if (loading) {
          return;
        }

        if (params['selectedVendor']) {
          const org = this.vendorsQuery.getEntity(params['selectedVendor']);
          if (org) {
            this.onEdit(org);
          }
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      });

    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (params.openNewVendorModal) {
        this.onNewVendor();
        this.router.navigate([], {
          queryParams: { openNewVendorModal: null },
          queryParamsHandling: 'merge',
        });
      }
    });

    this.budgetService
      .getInMonthBudgetData(null)
      .pipe(takeUntilDestroyed())
      .subscribe((state) => {
        this.currentQuarterMonths =
          state.header_data?.filter((x) => x.group_name === 'Forecast')[0]?.date_headers || [];
      });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.gridAPI$.next(api);
    this.gridAPI.sizeColumnsToFit();
  }

  onFirstDataRendered() {
    this.gridAPI.sizeColumnsToFit();
  }

  updateGridLayout(): void {
    if (!this.gridAPI) return;

    Utils.updateGridLayout(this.gridAPI, 'VendorsGrid', true);
  }

  sizeColumnsToFit(): void {
    this.gridAPI?.sizeColumnsToFit();
  }

  clickEditButton(rowNode: RowNode) {
    const foundOrg = this.vendorsQuery.getAll().find((org) => org.id === rowNode.data.vendor_id);
    if (foundOrg) {
      this.onEdit(foundOrg);
    }
  }

  clickRemoveVendor(rowNode: RowNode) {
    const foundOrg = this.vendorsQuery.getAll().find((org) => org.id === rowNode.data.vendor_id);
    if (foundOrg) {
      this.removeVendor(foundOrg);
    }
  }

  onEdit(org: OrganizationModel) {
    this.overlayService.openPopup<OrganizationDialogData, unknown, OrganizationDialogComponent>({
      settings: {
        header: 'Vendor',
        primaryButton: {
          action: async (instance: OrganizationDialogComponent | null) => {
            await instance?.onSubmit();
          },
          label: 'Save Edits',
          disabled: (instance) => !instance?.userHasPermission || !instance.hasChanges(),
          tooltip: (instance) => {
            return (
              !instance?.userHasPermission
                ? instance?.permissionMessage
                : instance?.saveBtnTooltip()
            ) as string;
          },
        },
      },
      content: OrganizationDialogComponent,
      data: {
        id: org.id,
        fetchFilesOnInit: true,
        userHasPermission: this.ctx.userHasVendorPermission(),
        currentQuarterMonths: this.currentQuarterMonths,
      },
    });
  }

  async removeVendor(vendor: OrganizationModel) {
    if (!vendor) return;

    const modalEvent = this.overlayService.openPopup<ConfirmationActionModalData>({
      modal: ConfirmationActionModalComponent,
      data: {
        keywordToExecuteAction: 'Delete Vendor',
        message:
          'This will permanently delete the selected vendor. This action cannot be undone and will remove all associated data including budget and accruals.',
      },
      settings: {
        header: 'Delete Vendor?',
        primaryButton: {
          label: 'Delete Vendor',
          variant: 'negative',
        },
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    if (resp?.data) {
      await this.organizationService.remove(vendor);
    }
  }

  onNewVendor = () => {
    this.overlayService.openPopup<OrganizationDialogData, unknown, OrganizationDialogComponent>({
      settings: {
        header: 'Add New Vendor',
        primaryButton: {
          action: async (instance) => {
            await instance?.onSubmit();
          },
          label: 'Save Vendor',
          disabled: (instance) => !instance?.userHasPermission || !instance.hasChanges(),
          tooltip: (instance) => {
            return (
              !instance?.userHasPermission
                ? instance?.permissionMessage
                : instance?.saveBtnTooltip()
            ) as string;
          },
        },
      },
      content: OrganizationDialogComponent,
      data: {
        fetchFilesOnInit: false,
        userHasPermission: true,
      },
    });
  };

  onAddMultipleVendors = () => {
    this.overlayService.openPopup({
      content: MultipleOrganizationsDialogComponent,
      settings: {
        header: 'Add Multiple Vendors',
        primaryButton: {
          label: 'Add Vendors',
          action: async (instance: MultipleOrganizationsDialogComponent | null) => {
            await instance?.addVendors();
          },
        },
      },
    });
  };
}
