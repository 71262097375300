import { AsyncPipe } from '@angular/common';
import { Component, inject, Input, input, signal } from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SkeletonLoaderDirective } from '@shared/directives/skeleton-loader.directive';
import { MainQuery } from '@shared/store/main/main.query';
import { ServerSideDatasource } from '@shared/utils/server-side-datasource';
import dayjs from 'dayjs';

@Component({
  selector: 'aux-export-full-dataset-button',
  templateUrl: './export-full-dataset-button.html',
  standalone: true,
  imports: [ButtonComponent, SkeletonLoaderDirective, AsyncPipe],
})
export class ExportFullDatasetButtonComponent<D> {
  private mainQuery = inject(MainQuery);

  private overlayService = inject(OverlayService);

  totalRows = input.required<number>();

  @Input({ required: true }) datasource!: ServerSideDatasource<D>;

  @Input({ required: true }) processEvent!: (
    trialName: string,
    dateStr: string
  ) => Promise<GraphqlResponse<unknown>>;

  isProcessing = signal(false);

  async exportDataset() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs().format('YYYY.MM.DD-HHmmss');

    this.isProcessing.set(true);

    const { success, errors } = await this.processEvent(trialName, dateStr);
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }

    this.isProcessing.set(false);
  }
}
