<div class="mt-4"></div>

<div
  *ngFor="let cat of specificationCategoryQuery.categories$ | async; first as first; last as last"
  class="w-full"
>
  <div class="flex justify-between">
    <div
      class="bg-aux-gray-light px-5 py-3 border text-xxs font-bold flex justify-between items-center sticky top-0 max-w-3xl w-full"
      [ngClass]="{
        'rounded-t-md': first,
        'rounded-b-md': last
      }"
    >
      <div>{{ cat.name }}</div>
      <aux-menu #cat_menu [disabled]="!!showForm()">
        <button
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 px-4 py-2 text-sm w-full flex items-center focus:outline-none"
          role="menuitem"
          (click)="onShowForm(cat.id, cat); cat_menu.close()"
        >
          <aux-icon name="Plus" class="mr-2" />
          <span>Add new specification</span>
        </button>
      </aux-menu>
    </div>
    <button
      *auxAuthorize="{ sysAdminsOnly: true }"
      type="button"
      class="btn--primary"
      (click)="onSpecUploadClick()"
    >
      <aux-icon name="Upload" />
      <span class="ml-2">Upload Specification</span>
    </button>
  </div>

  <div class="text-sm max-w-3xl">
    <ng-container *ngFor="let spec of cat.specifications">
      <!--
      <div
        *ngIf="spec.input_type === 'INPUT_SINGLEVALUE' && (showForm | async) !== spec.id"
        class="border grid grid-cols-12 px-5 py-3"
       >
        <div class="col-span-9">
          {{ spec.name }}
        </div>
        <div class="col-span-3 flex justify-between items-center">
          <div>
            {{ spec.settings[0]?.setting_value }}
          </div>
          <aux-menu #single_menu>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onShowForm(spec.id, cat); single_menu.close()"
            >
              <span
                inlineSVG="edit.svg"
                class="w-5 h-5 mr-2 flex items-center justify-center"
              ></span>
              Edit specification
            </button>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onRemoveSpec(spec.id, cat); single_menu.close()"
            >
              <trash-solid-icon class="mr-2 w-5 h-5"></trash-solid-icon>
              Remove specification
            </button>
          </aux-menu>
        </div>
      </div>
       -->

      <div
        *ngIf="spec.input_type === 'INPUT_MULTIVALUE' && showForm() !== spec.id"
        class="border grid grid-cols-12 px-5 py-3"
      >
        <div class="col-span-12 font-medium flex items-center justify-between">
          <div>{{ spec.name }}:</div>
          <aux-menu #multi_menu>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onShowForm(spec.id, cat); multi_menu.close()"
            >
              <aux-icon name="Pencil" class="text-aux-blue" />
            </button>
            <button
              type="button"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm w-full flex items-center rounded-md"
              (click)="onRemoveSpec(spec.id, cat); multi_menu.close()"
            >
              <aux-icon name="Trash" class="text-aux-error" />
            </button>
          </aux-menu>
        </div>
        <hr class="col-span-12 my-2" />
        <ng-container *ngFor="let setting of spec.settings">
          <div class="col-span-9">{{ setting.setting_key || setting.setting_value }}</div>
          <div class="col-span-3 tabular-nums">
            {{ setting.setting_key ? setting.setting_value : '' }}
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="showForm() === spec.id" [ngTemplateOutlet]="form" />
    </ng-container>

    <ng-container *ngIf="showForm() === cat.id" [ngTemplateOutlet]="form" />
    <ng-template #form>
      <form
        class="grid grid-cols-12 px-5 py-3 gap-5 border"
        [formGroup]="fg"
        (ngSubmit)="onSaveForm(cat)"
      >
        <ng-container *ngIf="$any(fg.get('specType')).value === specTypesEnum.INPUT_MULTIVALUE">
          <div class="col-span-4">
            <aux-input placeholder="Spec Name" formControlName="specName" auxFormError />
          </div>
          <div class="col-span-8"></div>
          <ng-container
            *ngFor="let set of $any(fg.get('settings'))['controls']; index as i"
            formArrayName="settings"
          >
            <div class="flex items-center col-span-12 grid grid-cols-12 gap-5" [formGroupName]="i">
              <div class="col-span-4">
                <aux-input
                  formControlName="label"
                  auxFormError
                  [placeholder]="'Set #' + (i + 1) + ' Name'"
                />
              </div>
              <div class="col-span-4">
                <aux-input
                  formControlName="value"
                  auxFormError
                  [placeholder]="'Set #' + (i + 1) + ' Value'"
                />
              </div>
              <div class="col-span-4">
                <ng-select
                  formControlName="type"
                  bindValue="value"
                  class="col-span-3 select select__big mt-0"
                  [clearable]="false"
                  [searchable]="false"
                  [items]="specSetTypes"
                  [placeholder]="'Set #' + (i + 1) + ' Type'"
                />
              </div>
            </div>
          </ng-container>

          <div class="col-span-12 flex space-x-4">
            <button type="button" class="btn--hyperlink" (click)="onAddNewLine()">
              Add new line
            </button>
            <button
              *ngIf="$any(fg.get('settings')).value.length > 1"
              type="button"
              class="btn--hyperlink"
              (click)="onRemoveLastLine()"
            >
              Remove last line
            </button>
          </div>
        </ng-container>

        <div class="flex space-x-4 col-span-12">
          <button class="btn--primary w-32" type="submit">
            <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
            <span>Save</span>
          </button>
          <button class="btn--tertiary" type="button" (click)="hideForm()">Cancel</button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
