import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TrialInsightsService } from './services/trial-insights.service';
import { TrialInsightsStore } from '@models/trial-insights/trial-insights.store';
import { TimelineService } from '../forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { Observable } from 'rxjs';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './trial-insights.component.html',
  styleUrls: ['./trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsComponent implements OnInit, OnDestroy {
  showFinanceButton$: Observable<boolean>;

  showClinOpsButton$: Observable<boolean>;

  mainElement = document.querySelector('main');

  modified = () => {
    if (
      this.mainElement &&
      !this.trialInsights.getBackgroundColor() &&
      this.router.url.includes('trial-insights')
    ) {
      this.trialInsights.changeBackground(true);
    }
  };

  observer = new MutationObserver(this.modified);

  constructor(
    private trialInsights: TrialInsightsService,
    private store: TrialInsightsStore,
    private timelineService: TimelineService,
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {
    this.showFinanceButton$ = this.launchDarklyService.select$((flags) => flags.finance_dashboard);
    this.showClinOpsButton$ = this.launchDarklyService.select$((flags) => flags.clin_ops_dashboard);
    if (this.mainElement && this.observer) {
      this.observer.observe(this.mainElement, { attributes: true });
    }

    this.timelineService.getTimelineItems().pipe(takeUntilDestroyed()).subscribe();
  }

  ngOnInit(): void {
    this.trialInsights.changeBackground(true);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    this.trialInsights.changeBackground(false);
    this.store.reset();
  }
}
