import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { EMPTY, firstValueFrom, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { InvoiceDetailGoBackComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-detail-go-back.component';
import { InvoiceDetailStatusBannerComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-detail-status-banner.component';
import { InvoiceDetailTitleComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-detail-title.component';
import { InvoiceFormComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.component';
import { InvoiceTabsComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/invoice-tabs.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { MainStore } from '@shared/store/main/main.store';
import { Utils } from '@shared/utils/utils';
import { InvoiceNotesComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-notes.component';
import { NotesComponent } from '@shared/components/notes/notes.component';
import { FormControl } from '@angular/forms';
import { MessagesConstants } from '@shared/constants/messages.constants';
import {
  Currency,
  EventType,
  getPrepaidsSummaryQuery,
  GqlService,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { OverlayService } from '@shared/services/overlay.service';
import { AdjustmentsToPrepaidComponent } from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-prepaid-detail',
  templateUrl: './prepaid-detail.component.html',
  standalone: true,
  imports: [
    InvoiceDetailGoBackComponent,
    InvoiceDetailStatusBannerComponent,
    InvoiceDetailTitleComponent,
    InvoiceFormComponent,
    InvoiceTabsComponent,
    IconComponent,
    RouterLink,
    InvoiceNotesComponent,
    NotesComponent,
    WorkflowPanelComponent,
    AdjustmentsToPrepaidComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrepaidDetailComponent implements OnInit, OnDestroy {
  route = inject(ActivatedRoute);
  mainStore = inject(MainStore);
  authService = inject(AuthService);
  gqlService = inject(GqlService);
  overlayService = inject(OverlayService);
  workflowQuery = inject(WorkflowQuery);
  eventQuery = inject(EventQuery);
  launchDarklyService = inject(LaunchDarklyService);
  changeDetectorRef = inject(ChangeDetectorRef);
  mainQuery = inject(MainQuery);
  router = inject(Router);
  destroyRef = inject(DestroyRef);

  adjustmentsToPrepaid = viewChild(AdjustmentsToPrepaidComponent);

  readonly prepaidsLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PREPAIDS}`;
  readonly utils = Utils;
  readonly workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  prepaidLockTooltip = this.workflowQuery.prepaidLockTooltip;

  hasEditTermsPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_UPDATE_PREPAIDS],
  });

  // can be used for every edit buttons.
  isEditDisabled = computed(() => {
    return !!(
      this.isInvoiceFinalized() &&
      (this.isQuarterCloseEnabled() || this.iCloseMonthsProcessing())
    );
  });

  isNotesDisabled = computed(() => {
    return this.isEditDisabled() || !this.hasEditTermsPermission();
  });

  notesTooltipText = computed(() => {
    if (this.isEditDisabled()) {
      return this.prepaidLockTooltip();
    }
    if (!this.hasEditTermsPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }
    return '';
  });

  notesControl = new FormControl();

  loading = signal<boolean>(true);
  prepaid: getPrepaidsSummaryQuery | null = null;
  vendorId = '';
  trialAlreadyInitialized = false;

  constructor() {
    this.mainStore.update({ fullPage: true });
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          let id = params.get('id') || '';
          // check if id has ? or % in it
          if (id.includes('?')) {
            id = id.substring(0, id.indexOf('?'));
          } else if (id.includes('%')) {
            id = id.substring(0, id.indexOf('%'));
          }
          if (!id) {
            this.loading.set(false);
            return EMPTY;
          }
          this.vendorId = id;

          return this.gqlService.getPrepaidsSummary$();
        }),
        takeUntilDestroyed()
      )
      .subscribe((result) => {
        if (result.success) {
          this.prepaid =
            result.data?.find((prepaid) => prepaid.vendor_id === this.vendorId) || null;
          this.notesControl.setValue(this.prepaid?.terms || '');
        }

        this.loading.set(false);
      });
  }

  ngOnInit() {
    this.mainQuery
      .select('trialKey')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.trialAlreadyInitialized) {
          this.router.navigate([this.prepaidsLink]);
        }
        this.trialAlreadyInitialized = true;
      });
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
  }

  saveNotes(): void {
    const result = this.gqlService.updatePrepaidTerms$({
      vendor_id: this.prepaid?.vendor_id || '',
      terms: this.notesControl.value,
    });
    if (result) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.prepaid.terms = this.notesControl.value;
      this.overlayService.success(MessagesConstants.SAVED);
    }
    this.notesControl.markAsPristine();
  }

  cancelNotes(): void {
    if (this.prepaid && this.prepaid.terms && this.prepaid.terms.length > 0) {
      this.notesControl.setValue(this.prepaid.terms || '');
    } else {
      this.notesControl.setValue('');
    }

    this.notesControl.markAsPristine();
  }

  get vendorCurrency(): Currency {
    if (this.prepaid && this.prepaid.vendor_currency) {
      return this.prepaid.vendor_currency.replace('CURRENCY_', '') as Currency;
    }

    return Currency.USD;
  }

  get vendorName(): string {
    if (this.prepaid) {
      return this.prepaid.vendor_name;
    }
    return '';
  }

  async canDeactivate(): Promise<boolean> {
    if (
      (!this.notesControl.pristine && this.notesControl.value !== (this.prepaid?.terms || '')) ||
      this.adjustmentsToPrepaid()?.hasChanges()
    ) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }

    return true;
  }

  onAdjustmentsSaved(): void {
    this.gqlService.getPrepaidsSummary$().subscribe((result) => {
      if (result.success) {
        const updatedPrepaid =
          result.data?.find((prepaid) => prepaid.vendor_id === this.vendorId) || null;

        if (updatedPrepaid) {
          this.prepaid = updatedPrepaid;
        } else {
          this.prepaid = {
            ...this.prepaid,
            total_prepaid_balance: 0,
            short_term_amount: 0,
            long_term_amount: 0,
          } as getPrepaidsSummaryQuery;
        }

        this.changeDetectorRef.detectChanges();
      }
    });
  }
}
