export { validateAndBuildConfiguration, DefaultPrivacyLevel, serializeConfiguration, INTAKE_SITE_STAGING, INTAKE_SITE_US1, INTAKE_SITE_US1_FED, INTAKE_SITE_EU1 } from './domain/configuration';
export { TrackingConsent, createTrackingConsentState } from './domain/trackingConsent';
export { isExperimentalFeatureEnabled, addExperimentalFeatures, resetExperimentalFeatures, getExperimentalFeatures, ExperimentalFeature } from './tools/experimentalFeatures';
export { trackRuntimeError } from './domain/error/trackRuntimeError';
export { computeStackTrace } from './domain/error/computeStackTrace';
export { defineGlobal, makePublicApi } from './boot/init';
export { displayAlreadyInitializedError } from './boot/displayAlreadyInitializedError';
export { initReportObservable, RawReportType } from './domain/report/reportObservable';
export { startTelemetry, addTelemetryDebug, addTelemetryError, startFakeTelemetry, resetTelemetry, isTelemetryReplicationAllowed, addTelemetryConfiguration } from './domain/telemetry';
export { monitored, monitor, callMonitored, setDebugMode } from './tools/monitor';
export { Observable } from './tools/observable';
export { startSessionManager,
// Exposed for tests
stopSessionManager } from './domain/session/sessionManager';
export { SESSION_TIME_OUT_DELAY // Exposed for tests
} from './domain/session/sessionConstants';
export { createHttpRequest, canUseEventBridge, getEventBridge, startBatchWithReplica, createFlushController } from './transport';
export * from './tools/display';
export { createIdentityEncoder } from './tools/encoder';
export * from './tools/utils/urlPolyfill';
export * from './tools/utils/timeUtils';
export * from './tools/utils/arrayUtils';
export * from './tools/serialisation/sanitize';
export * from './tools/getGlobalObject';
export { AbstractLifeCycle } from './tools/abstractLifeCycle';
export * from './domain/eventRateLimiter/createEventRateLimiter';
export * from './tools/utils/browserDetection';
export { sendToExtension } from './tools/sendToExtension';
export { runOnReadyState } from './browser/runOnReadyState';
export { getZoneJsOriginalValue } from './tools/getZoneJsOriginalValue';
export { instrumentMethod, instrumentSetter } from './tools/instrumentMethod';
export { computeRawError, createHandlingStack, toStackTraceString, getFileFromStackTraceString, NO_ERROR_STACK_PRESENT_MESSAGE } from './domain/error/error';
export { areCookiesAuthorized, getCookie, setCookie, deleteCookie } from './browser/cookie';
export { initXhrObservable } from './browser/xhrObservable';
export { initFetchObservable } from './browser/fetchObservable';
export { createPageExitObservable, PageExitReason, isPageExitReason } from './browser/pageExitObservable';
export * from './browser/addEventListener';
export * from './tools/timer';
export { initConsoleObservable, resetConsoleObservable } from './domain/console/consoleObservable';
export { BoundedBuffer } from './tools/boundedBuffer';
export { catchUserErrors } from './tools/catchUserErrors';
export { createContextManager } from './domain/context/contextManager';
export { storeContextManager, removeStorageListeners } from './domain/context/storeContextManager';
export { createCustomerDataTrackerManager, createCustomerDataTracker, CUSTOMER_DATA_BYTES_LIMIT } from './domain/context/customerDataTracker';
export { ValueHistory, CLEAR_OLD_VALUES_INTERVAL } from './tools/valueHistory';
export { readBytesFromStream } from './tools/readBytesFromStream';
export { STORAGE_POLL_DELAY } from './domain/session/sessionStore';
export { SESSION_STORE_KEY } from './domain/session/storeStrategies/sessionStoreStrategy';
export { willSyntheticsInjectRum, getSyntheticsTestId, getSyntheticsResultId } from './domain/synthetics/syntheticsWorkerValues';
export { checkUser, sanitizeUser } from './domain/user';
export * from './domain/resourceUtils';
export * from './tools/utils/polyfills';
export * from './tools/utils/numberUtils';
export * from './tools/utils/byteUtils';
export * from './tools/utils/objectUtils';
export * from './tools/utils/functionUtils';
export * from './tools/serialisation/jsonStringify';
export * from './tools/mergeInto';
export * from './tools/utils/stringUtils';
export * from './tools/matchOption';
export * from './tools/utils/responseUtils';
export * from './tools/utils/typeUtils';
export { ErrorSource } from './domain/error/error.types';
export * from './domain/deflate';
export * from './domain/connectivity';
