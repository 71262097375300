<form [formGroup]="form" (ngSubmit)="saveForecastSettings()" #ngForm="ngForm">
  <aux-modal
    primaryButtonType="submit"
    [loading]="submitting()"
    [primaryButtonDisabled]="submitting()"
    [closeButtonDisabled]="submitting()"
    [customCloseAction]="closeModal"
  >
    <div>
      <div class="bg-aux-warn2 py-[15px] px-[25px] rounded-[3px] font-bold">
        <p>
          The selections you make will override all existing driver settings for activities in
          selected categories.
        </p>
      </div>

      <div class="flex items-center space-x-2 mb-4 mt-5">
        <h3 class="text-xl">Select cost category/categories</h3>
        @if (isCategoryIncorrect()) {
          <div class="flex items-center text-aux-red-dark">
            <aux-icon name="AlertTriangle" class="mr-2" />
            <p>{{ oneOfValidationMessage }}</p>
          </div>
        }
      </div>

      <div class="flex flex-col space-y-4">
        @if (isCategoryExists('Services')) {
          <div>
            <aux-checkbox-control formControlName="applyServices" id="services">
              Services
            </aux-checkbox-control>
          </div>
        }

        @if (isCategoryExists('Pass-through')) {
          <div>
            <aux-checkbox-control formControlName="applyPassThrough" id="passThrough">
              Pass-through
            </aux-checkbox-control>
          </div>
        }

        @if (isCategoryExists('Investigator')) {
          <div>
            <aux-checkbox-control id="investigator" formControlName="applyInvestigator">
              Investigator
            </aux-checkbox-control>
          </div>
        }
      </div>

      <div class="border-t border-aux-gray-dark my-8"></div>

      @if (isAnyActivitySelected) {
        <div class="flex items-center space-x-2 mb-4">
          <h3 class="text-xl">Select a driver</h3>

          @if (isDriverIncorrect || showTimeFormEmptyWarning) {
            <div class="flex items-center text-aux-red-dark">
              <aux-icon name="AlertTriangle" class="mr-2" />
              <p>Please make selections before saving.</p>
            </div>
          }
        </div>

        <aux-radio-button-group formControlName="driver" display="column" label="Driver">
          <aux-radio-button
            value="custom"
            tabIndex="1"
            label="Custom"
            [disabled]="!customCurves.length"
            [tooltip]="customCurves.length ? '' : 'No Custom Curves exist.'"
          />
          <aux-radio-button value="patient" tabIndex="2" label="Patient" />
          <aux-radio-button value="site" tabIndex="3" label="Site" />
          <aux-radio-button value="time" tabIndex="4" label="Time" />
        </aux-radio-button-group>

        <div class="mt-8 w-4/5">
          @switch (form.get('driver')!.value!) {
            @case ('patient') {
              <ng-container *ngTemplateOutlet="patientCurveDriver" />
            }
            @case ('site') {
              <ng-container *ngTemplateOutlet="siteDriver" />
            }
            @case ('time') {
              <ng-container *ngTemplateOutlet="timeDriver" />
            }
            @case ('custom') {
              <ng-container *ngTemplateOutlet="customDriver" />
            }
          }
        </div>
      }
    </div>
  </aux-modal>

  <!-- Patient Driver Form -->
  <ng-template #patientCurveDriver>
    <div>
      <div class="mb-1 text-xs">Patient curve</div>
      <aux-patient-curves-dropdown
        controlName="patientCurve"
        [curves]="patientCurveQuery.patientCurves()"
        name="Patient curve"
        [curveControl]="form.get('patientCurve')!"
      />
    </div>
  </ng-template>

  <!-- Site Driver Form -->
  <ng-template #siteDriver>
    <div class="flex flex-col space-y-4">
      <div>
        <div class="mb-1 text-xs">Period</div>
        <ng-select
          formControlName="siteDriverPeriod"
          [items]="periodSiteOptions"
          [clearable]="false"
          auxFormError
          bindValue="value"
          placeholder="Select"
          label="Period"
          (change)="revalidateSiteCurveField()"
        />
      </div>

      <div>
        <div class="mb-1 text-xs">Site curve</div>
        <aux-patient-curves-dropdown
          controlName="siteCurve"
          [curves]="siteCurves()"
          name="Site curve"
          [curveControl]="form.get('siteCurve')!"
        />
      </div>
    </div>
  </ng-template>

  <!-- Time Driver Form -->
  <ng-template #timeDriver>
    <h3 class="text-xl mb-2">Select a time period</h3>

    <div class="grid grid-cols-2 gap-4 pb-12">
      <div>
        <p class="mb-1 text-xs">Trial timeline phase</p>
        <ng-select
          [items]="this.milestonePhases()"
          formControlName="milestone"
          [searchable]="false"
          placeholder="Select"
          (change)="disableMilestonesByCondition($event, 'milestone')"
        >
          <ng-template ng-label-tmp let-item="item">
            <ng-container [ngTemplateOutlet]="valueLabel" [ngTemplateOutletContext]="{ item }" />
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <ng-container [ngTemplateOutlet]="valueLabel" [ngTemplateOutletContext]="{ item }" />
          </ng-template>

          <ng-template let-item="item" #valueLabel>
            <div
              class="block space-x-2 overflow-hidden text-ellipsis"
              [auxTooltip]="getTimelinePhaseTooltip(item)"
            >
              <span class="font-bold">{{ item.categoryName }}</span>
              <span>
                {{ item.contractStartDate | date: 'longDate' }} -
                {{ item.contractEndDate | date: 'longDate' }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <!-- empty col -->
      <div></div>

      <span class="col-span-2">OR</span>

      <div>
        <p class="mb-1 text-xs">Starting milestone</p>
        <ng-select
          [items]="this.milestones()"
          formControlName="startMilestone"
          [searchable]="false"
          auxFormError
          placeholder="Select"
          (change)="
            disableMilestonesByCondition($event, 'startMilestone', 'endMilestone');
            revalidateMilestoneField('endMilestone')
          "
        >
          <ng-template ng-label-tmp let-item="item">
            <ng-container
              [ngTemplateOutlet]="startMilestone"
              [ngTemplateOutletContext]="{ item }"
            />
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <ng-container
              [ngTemplateOutlet]="startMilestone"
              [ngTemplateOutletContext]="{ item }"
            />
          </ng-template>

          <ng-template let-item="item" #startMilestone>
            <div
              class="flex items-center space-x-2"
              [auxTooltip]="getTimelineStartEndMilestoneTooltip(item, item.contractStartDate)"
            >
              <span class="font-bold max-w-[70%] text-ellipsis overflow-hidden">{{
                item.milestoneName
              }}</span>
              <span>{{ item.contractStartDate | date: 'longDate' }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div>
        <p class="mb-1 text-xs">Ending milestone</p>
        <ng-select
          [items]="this.milestones()"
          formControlName="endMilestone"
          [searchable]="false"
          auxFormError
          placeholder="Select"
          (change)="
            disableMilestonesByCondition($event, 'endMilestone', 'startMilestone');
            revalidateMilestoneField('startMilestone')
          "
        >
          <ng-template ng-label-tmp let-item="item">
            <ng-container
              [ngTemplateOutlet]="endingMilestone"
              [ngTemplateOutletContext]="{ item }"
            />
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <ng-container
              [ngTemplateOutlet]="endingMilestone"
              [ngTemplateOutletContext]="{ item }"
            />
          </ng-template>

          <ng-template let-item="item" #endingMilestone>
            <div
              class="flex items-center space-x-2"
              [auxTooltip]="getTimelineStartEndMilestoneTooltip(item, item.contractEndDate)"
            >
              <span class="font-bold max-w-[70%] text-ellipsis overflow-hidden">{{
                item.milestoneName
              }}</span>
              <span>{{ item.contractEndDate | date: 'longDate' }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <span class="col-span-2">OR</span>

      <aux-date-picker
        [control]="$any(form.get('customStartMilestone')!)"
        label="Custom start date"
        (blured)="
          disableMilestonesByCondition($event, 'customStartMilestone', 'customEndMilestone')
        "
      />

      <aux-date-picker
        [control]="$any(form.get('customEndMilestone')!)"
        label="Custom end date"
        (blured)="
          disableMilestonesByCondition($event, 'customEndMilestone', 'customStartMilestone');
          revalidateMilestoneField('customStartMilestone')
        "
      />

      <div class="flex items-center space-x-2 text-aux-black col-span-2">
        <aux-icon name="AlertTriangle" />
        <p>Custom start and end dates will not dynamically update with your timeline.</p>
      </div>

      <div class="mt-6">
        <h3 class="text-xl mb-2">Select method</h3>
        <div>
          <p class="mb-1 text-xs">Method</p>
          <ng-select
            formControlName="timeMethod"
            [items]="methodTimeOptions"
            [clearable]="false"
            dropdownPosition="top"
            auxFormError
            bindValue="value"
            placeholder="Select"
          />
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Custom Driver Form -->
  <ng-template #customDriver>
    <div>
      <div class="mb-1 text-xs">Custom curve</div>
      <ng-select
        formControlName="customCurve"
        [items]="customCurves"
        [clearable]="false"
        auxFormError
        bindValue="value"
        bindLabel="name"
        placeholder="Select"
        label="Custom curve"
      />
    </div>
  </ng-template>
</form>
