import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { firstValueFrom } from 'rxjs';
import { Option } from '@shared/types/components.type';
import {
  EntityType,
  GqlService,
  PatientProtocolVersion,
  EventType,
  DocumentType,
} from '@shared/services/gql.service';
import { InputComponent } from '@shared/components/input/input.component';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { File } from '@shared/components/file-manager/state/file.model';

import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { EventService } from '@models/event/event.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FileManagerUploadedFilesComponent } from '@features/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TrimInputDirective } from '@shared/directives/trim-input.directive';

type ProtocolModalView = 'create' | 'edit' | 'amendment';

@Component({
  templateUrl: './protocol-version-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputComponent,
    ButtonComponent,
    IconComponent,
    FileManagerComponent,
    FileManagerUploadedFilesComponent,
    AsyncPipe,
    ReactiveFormsModule,
    NgSelectModule,
    NgClass,
    NgIf,
    TrimInputDirective,
  ],
})
export class ProtocolVersionModalComponent implements OnInit {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata = {
    documentType: DocumentType.DOCUMENT_TRIAL_PROTOCOL,
    entity_type_id: EntityType.TRIAL_PROTOCOL,
  };

  protocolVersionFc = new FormControl('', [Validators.required]);

  effectiveDateFc = new FormControl('');

  copyFromVersionFc = new FormControl(null);

  pathFn = () => {
    const trialId = this.mainQuery.getValue().trialKey;

    const versionId = this.ref.data?.protocolVersion?.id;

    return versionId ? `trials/${trialId}/document-library/${versionId}` : '';
  };

  @ViewChild('protocolVersion') protocolVersionInput!: InputComponent;

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      {
        modalView: ProtocolModalView;
        name: string;
        protocolVersionOptions: Option[];
        protocolVersion: Pick<PatientProtocolVersion, 'name' | 'id' | 'effective_date'>;
      }
    >,
    private gqlService: GqlService,
    private patientProtocolService: PatientProtocolService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.setCopyFromFieldDefaultState();

    const isEditView = this.ref.data?.modalView === 'edit';

    if (isEditView) {
      this.protocolVersionFc.setValue(this.ref.data?.protocolVersion?.name || null);
      this.effectiveDateFc.setValue(this.ref.data?.protocolVersion?.effective_date || null);
    }

    this.protocolVersionFc.valueChanges.subscribe(() => {
      if (!this.protocolVersionFc.invalid) {
        this.copyFromVersionFc.enable();
      } else {
        this.setCopyFromFieldDefaultState();
      }
    });
  }

  onEdit = async () => {
    if (this.protocolVersionFc.value && this.ref.data) {
      const success = await this.patientProtocolService.updatePatientProtocolVersion({
        id: this.ref.data.protocolVersion.id,
        name: this.protocolVersionFc.value,
        effective_date: this.effectiveDateFc.value,
      });

      await firstValueFrom(
        this.eventService.processEvent$({
          type: EventType.UPDATE_INVESTIGATOR_SCHEDULES,
          entity_type: EntityType.SITE,
          entity_id: '',
        })
      );

      if (!success) {
        this.setDuplicateError();

        return;
      } else {
        this.uploadDocuments(this.ref.data.protocolVersion.id);
      }

      this.ref.close({
        success,
        updatedVersionName: this.protocolVersionFc.value,
        effective_date: this.effectiveDateFc.value,
      });
    }
  };

  onSave = async (): Promise<void> => {
    if (this.protocolVersionFc.value) {
      const isAmendment = this.ref.data?.modalView === 'amendment';

      const copyFromParams =
        isAmendment && this.copyFromVersionFc.value
          ? { copy_from_id: this.copyFromVersionFc.value }
          : {};

      const { data, errors } = await this.patientProtocolService.createPatientProtocolVersion({
        ...copyFromParams,
        effective_date: this.effectiveDateFc.value || null,
        name: this.protocolVersionFc.value,
      });

      if (errors.length) {
        this.setDuplicateError();

        return;
      } else {
        this.uploadDocuments(data?.id);
      }

      this.ref.close({
        version: { label: data?.name, value: data?.id, effective_date: data?.effective_date },
      });
    }
  };

  private async uploadDocuments(versionId?: string) {
    if (!this.fileManager || !versionId) return;

    const files = this.fileManager.fileQuery.getAll().filter(({ uploaded }) => !uploaded);

    if (!files.length) return;

    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.TRIAL_PROTOCOL_UPLOADED,
        entity_type: EntityType.DOCUMENT,
        entity_id: '',
        payload: JSON.stringify({
          uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
          file_names: files.map((f) => f.fileName).join(', '),
        }),
      })
    );

    if (this.ref.data?.modalView !== 'edit') {
      this.updateFileStoreBeforeUpload(files, versionId);
    }

    await this.fileManager.fileService.uploadFiles(
      {
        documentType: DocumentType.DOCUMENT_TRIAL_PROTOCOL,
        entity_type_id: EntityType.TRIAL_PROTOCOL,
      },
      true,
      true,
      undefined,
      true,
      true,
      false
    );
  }

  private updateFileStoreBeforeUpload(files: File[], versionId: string) {
    const trialId = this.mainQuery.getValue().trialKey;

    files.forEach((file) => {
      this.fileManager?.fileStore.update(file.id, {
        ...file,
        bucket_key: `trials/${trialId}/document-library/${versionId}/${new Date().toISOString()}_${
          file.fileName
        }`,
      });
    });
  }

  private setDuplicateError() {
    this.protocolVersionFc.markAsTouched();
    this.protocolVersionInput.fc.setErrors({ duplicate_version: true });
  }

  private setCopyFromFieldDefaultState(): void {
    this.copyFromVersionFc.disable();
    this.copyFromVersionFc.reset();
  }
}
