import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import { BehaviorSubject } from 'rxjs';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-qa-testing',
  templateUrl: './qa-testing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaTestingComponent {
  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  activeTabIndex$ = new BehaviorSubject<number>(0);

  tabs: TabGroupConfig[] = [
    {
      label: 'Email',
      route: ROUTING_PATH.QA_TESTING.EMAIL,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (params.activeTabIndex) {
        this.activeTabIndex$.next(parseInt(params.activeTabIndex, 10));
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });
  }
}
