import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { UserExampleModalComponent } from './components/user-form-example-modal.component';
import { ExampleModalComponent } from './components/example-modal.component';

@Component({
  selector: 'aux-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  private readonly overlayService = inject(OverlayService);

  openModalWithTertiaryButton() {
    this.overlayService.openPopup({
      content: ExampleModalComponent,
      settings: {
        header: 'Title',
        tertiaryButton: { label: 'Button' },
        primaryButton: {
          label: 'Button',
        },
        secondaryButton: {
          label: 'Button',
        },
      },
    });
  }

  openModalWithForm() {
    this.overlayService.openPopup({
      modal: UserExampleModalComponent,
      settings: {
        header: 'Modal with form',
        primaryButton: {
          label: 'Button',
        },
        secondaryButton: {
          label: 'Button',
        },
      },
    });
  }

  openFullScreenModal() {
    this.overlayService.openPopup({
      content: ExampleModalComponent,
      data: { widthContainer: '!w-full' },
      settings: {
        fullWidth: true,
        header: 'Title',
        primaryButton: {
          label: 'Button',
        },
        secondaryButton: {
          label: 'Button',
        },
      },
    });
  }

  openModalWithExecutableAction() {
    this.overlayService.openPopup<ConfirmationActionModalData>({
      modal: ConfirmationActionModalComponent,
      data: {
        keywordToExecuteAction: 'Delete Vendor',
        message:
          'This will permanently delete the selected vendor. This action cannot be undone and will remove all associated data including budget and accruals.',
      },
      settings: {
        header: 'Delete Vendor?',
        primaryButton: {
          label: 'Delete Vendor',
          variant: 'negative',
        },
      },
    });
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((_, i) => i + 1);
  }
}
