import { MonthStats } from '../../period-close.component';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { ApiService, AwsFile } from '@shared/services/api.service';
import dayjs from 'dayjs';

@Component({
  selector: 'aux-period-close-header-month',
  template: `<div
    class="border rounded min-w-[230px] max-h-[84px]"
    [ngClass]="{
      'bg-aux-blue-dark': monthStat.status === 'Open',
      'bg-aux-gray-light': monthStat.status !== 'Open'
    }"
  >
    <div
      class="flex justify-between space-x-[10px] mx-[10px] my-[10px] h-full"
      *ngVar="monthStat.auditPackages?.length as isAnyAuditPackagePresent"
    >
      <div
        class="flex flex-col justify-between space-y-1 h-auto"
        [ngClass]="{ 'text-white': monthStat.status === 'Open' }"
      >
        <span class="font-bold text-sm">{{ monthStat.date | date: 'MMMM' }}</span>
        <span
          *ngVar="monthStat.eom_accruals | money as eom_accruals"
          class="text-xl"
          [ngClass]="{
            'text-white': monthStat.status === 'Open',
            'text-aux-gray-darkest': monthStat.status !== 'Open'
          }"
          >{{ eom_accruals }}</span
        >
        <span class="text-xs">{{ monthStat.status }}</span>
      </div>
      <div
        class="flex flex-col justify-between items-center space-y-3 h-full"
        [ngClass]="{
          'opacity-50': monthStat.status !== 'Closed' || !isAnyAuditPackagePresent,
          'text-aux-gray-light': monthStat.status === 'Open'
        }"
      >
        <span
          class="flex items-center w-[40px] h-[40px] border rounded-full"
          (click)="downloadAuditPackage()"
          [ngClass]="{
            'border-inactive-gray': monthStat.status !== 'Closed',
            'cursor-not-allowed': monthStat.status !== 'Closed' || !isAnyAuditPackagePresent,
            'cursor-pointer': monthStat.status === 'Closed' && isAnyAuditPackagePresent
          }"
          [ngStyle]="{
            'border-color': monthStat.allOfThemClosed && isAnyAuditPackagePresent ? '#138EE7' : ''
          }"
          [attr.data-pendo-id]="getPendoId()"
          ><aux-icon
            class="ml-[7px]"
            [ngClass]="{ 'opacity-50': monthStat.status !== 'Closed' || !isAnyAuditPackagePresent }"
            [ngStyle]="{
              color: monthStat.status === 'Closed' && isAnyAuditPackagePresent ? '#138EE7' : ''
            }"
            [name]="'Download'"
          ></aux-icon
        ></span>

        <span class="text-xs whitespace-nowrap">Audit Package</span>
      </div>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseHeaderMonthComponent {
  @Input() monthStat!: MonthStats;

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) {}

  getPendoId(): string {
    const cardNumber = (dayjs(this.monthStat.date).month() % 3) + 1;
    return `monthly-audit-package-card-${cardNumber}`;
  }

  async downloadAuditPackage() {
    if (this.monthStat.status === 'Closed' && this.monthStat.auditPackages) {
      const ref = this.overlayService.loading();

      const files = this.monthStat.auditPackages;

      files.forEach((file) => {
        let fileName = file.name;
        if (fileName.includes('zip')) {
          fileName = fileName.replace('.zip', '');
        }
        let success = true;
        if (fileName.toLowerCase().includes('accrual')) {
          const fileSuccess = this.apiService.downloadFile({
            key: file.bucket_key,
            fileName: fileName,
          } as AwsFile);
          success = !!fileSuccess;
        } else {
          const fileSuccess = this.apiService.downloadZipOrFile(file.bucket_key, fileName);
          success = success && !!fileSuccess;
        }
        if (!success) {
          this.overlayService.error('There was a problem downloading the file!');
        }
      });
      ref.close();
    }
  }
}
