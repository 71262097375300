import {
  CellClassParams,
  GridOptions,
  HeaderClassParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueParserParams,
} from '@ag-grid-community/core';
import { inject, Injectable, Signal, WritableSignal } from '@angular/core';
import { CustomType } from '@shared/services/gql.service';
import { decimalAdd, decimalDivide, decimalMultiply, decimalRoundingToString } from '@shared/utils';
import { Utils } from '@shared/utils/utils';
import { TableConstants } from '@shared/constants/table.constants';
import { CustomCurvesQuery } from '../state/custom-curves.query';
import { CustomCurvesNoRowsOverlayComponent } from '../components/custom-curves-no-rows-overlay/custom-curves-no-rows-overlay.component';
import { AgMonthCellComponent } from '../../forecast/drivers/components/ag-month-cell.component';
import { BehaviorSubject } from 'rxjs';
import {
  TotalDistributionComponentParams,
  TotalDistributionHeaderComponent,
} from '../components/total-distribution-header/total-distribution-header.component';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { Distribution } from '../custom-drivers.component';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class CustomCurvesUtils {
  private readonly customCurvesQuery = inject(CustomCurvesQuery);

  getGridOptions(
    editMode: Signal<boolean>,
    saving: Signal<boolean>,
    calculatedPinnedBottomData: Signal<Partial<Distribution>>,
    saveButtonTooltip: WritableSignal<string>,
    currentOpenMonth$: BehaviorSubject<string>,
    timeline$: BehaviorSubject<{
      startDate?: string | undefined;
      endDate?: string | undefined;
    }>,
    selectedCurveDistributionLabel: Signal<string>,
    onCellValueChanged: () => void
  ): GridOptions {
    return {
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        editable: (params) => editMode() && params.data.distribution_month !== 'TOTAL',
        headerClass: (params: HeaderClassParams): string | string[] =>
          (editMode() || saving()) && params.column?.getColId() === 'custom_amount'
            ? ['ag-header-edit-mode', 'ag-header-align-center']
            : ['ag-header-align-center'],
        cellClass: (params: CellClassParams): string | string[] =>
          params.colDef.field === 'distribution_month' ? 'justify-start' : 'justify-end',
        cellStyle: (params) => {
          switch (params.colDef.field) {
            case 'distribution_remaining':
            case 'distribution_total':
              return editMode() || saving()
                ? { opacity: 0.7, 'justify-items': 'end', cursor: 'not-allowed' }
                : { 'justify-item': 'end' };
            default:
              return undefined;
          }
        },
        cellClassRules: {
          '!bg-aux-error': (params) => {
            if (
              params.colDef.field === 'custom_amount' &&
              params.data.distribution_month === 'TOTAL'
            ) {
              const percentagesInvalid =
                this.customCurvesQuery.selectedCurve()?.custom_type ===
                  CustomType.CUSTOM_GROUP_PERCENTAGE &&
                !!calculatedPinnedBottomData().custom_amount &&
                (calculatedPinnedBottomData().custom_amount as number) !== 100;
              saveButtonTooltip.set(
                percentagesInvalid ? 'Total Percentage % must add to 100%.' : ''
              );
              return percentagesInvalid;
            }

            return false;
          },
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (!params.editing) {
            switch (params.event.key) {
              case 'Backspace':
              case 'Delete':
                if (editMode()) {
                  onCellValueChanged();
                }
            }
          }
          return false;
        },
      },
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
      noRowsOverlayComponent: CustomCurvesNoRowsOverlayComponent,
      pinnedBottomRowData: [],
      processCellFromClipboard: (params) => {
        return Utils.castStringToNumber(params.value).toString();
      },
      columnDefs: [
        {
          headerName: 'Month',
          field: 'distribution_month',
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value === 'TOTAL') {
              return 'TOTAL';
            }
            return params.value
              ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
              : '';
          },
          editable: false,
          sortable: false,
          minWidth: 175,
          cellClass: 'custom-drivers__month-cell',
          cellRenderer: AgMonthCellComponent,
          cellRendererParams: {
            currentOpenMonth$,
            timeline$,
            noMonthInTimelineTooltipMessage:
              'This month is no longer included in the trial timeline. Please adjust your Custom Curve or Trial Timeline to fix this issue.',
          },
        },
        {
          headerValueGetter: (): string => {
            return `Planned ${selectedCurveDistributionLabel()}`;
          },
          field: 'custom_amount',
          aggFunc: 'sum',
          cellDataType: 'text',
          sortable: false,
          minWidth: 175,
          valueFormatter: this.valueFormatter.bind(this),
          valueParser(params: ValueParserParams): number | string {
            const num =
              params.newValue === params.oldValue
                ? params.oldValue
                : Utils.castStringToNumber(params.newValue).toString();
            return (Number.isNaN(num) ? params.oldValue : Math.abs(num)).toString();
          },
        },
        {
          headerName: `Forecast Distribution (Total)`,
          field: 'distribution_total',
          aggFunc: 'sum',
          cellDataType: 'text',
          sortable: false,
          editable: false,
          minWidth: 175,
          valueFormatter: this.valueFormatter.bind(this),
          headerComponent: TotalDistributionHeaderComponent,
          headerComponentParams: {
            template: `Forecast Distribution (Total)`,
            localStorageKey: LocalStorageKey.CUSTOM_CURVES,
            columnsToCollapse: ['distribution_remaining'],
            ignoreIfInvisible: [],
            collapsedByDefault: true,
          } as unknown as TotalDistributionComponentParams,
        },
        {
          headerName: `Forecast Distribution (Remaining)`,
          field: 'distribution_remaining',
          aggFunc: 'sum',
          cellDataType: 'text',
          sortable: false,
          editable: false,
          minWidth: 175,
          valueFormatter: this.valueFormatter.bind(this),
        },
      ],
    } as GridOptions;
  }

  static calculatePlannedTotal(
    distributions: Distribution[],
    type?: CustomType
  ): Partial<Distribution> {
    const isPercentages = type === CustomType.CUSTOM_GROUP_PERCENTAGE;
    const defaultTotal = distributions.some((distribution) => distribution.distribution_total != 0)
      ? 100
      : 0;
    const defaultRemainingTotal = distributions.some(
      (distribution) => distribution.distribution_remaining != 0
    )
      ? 100
      : 0;

    const data = distributions.reduce(
      (previous, current) => {
        const currentVal = previous['custom_amount'];
        const additionalVal = current['custom_amount'];

        if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
          previous['custom_amount'] = decimalAdd(currentVal, additionalVal);

          if (isPercentages) {
            previous['distribution_total'] = previous['custom_amount'];
          }
        }

        return previous;
      },
      {
        custom_amount: 0,
        distribution_total: isPercentages ? 0 : defaultTotal,
        distribution_remaining: defaultRemainingTotal,
      }
    );

    return data;
  }

  static calculateForecastDistributions(
    distributions: Distribution[],
    currentOpenMonth: string,
    type?: CustomType
  ): Distribution[] {
    const sameOrAfterOpenMonth = (distribution: Distribution) =>
      dayjs(distribution.distribution_month).isSameOrAfter(dayjs(currentOpenMonth), 'month');
    const distributionsForRemaining = distributions.filter(sameOrAfterOpenMonth);
    const plannedTotal = this.calculatePlannedTotal(distributions, type);
    const remainingTotal = this.calculatePlannedTotal(distributionsForRemaining, type);

    const calculateValue = (distribution: Distribution, total: Partial<Distribution>) => {
      return total.custom_amount
        ? decimalMultiply(
            decimalDivide(Number(distribution.custom_amount), total.custom_amount),
            100
          )
        : 0;
    };

    return distributions.map((distribution) => {
      const distribution_total =
        type === CustomType.CUSTOM_GROUP_PERCENTAGE
          ? distribution.custom_amount
          : calculateValue(distribution, plannedTotal);
      const distribution_remaining = sameOrAfterOpenMonth(distribution)
        ? calculateValue(distribution, remainingTotal)
        : 0;
      return {
        ...distribution,
        distribution_total,
        distribution_remaining,
      };
    });
  }

  private valueFormatter(val: ValueFormatterParams): string {
    const newValue = val.value === '0' ? 0 : val.value;
    const selectedType = this.customCurvesQuery.selectedCurve()?.custom_type;

    const prefix =
      selectedType === CustomType.CUSTOM_GROUP_DOLLARS && val.colDef.field === 'custom_amount'
        ? '$'
        : '';
    const postfix =
      selectedType === CustomType.CUSTOM_GROUP_PERCENTAGE || val.colDef.field !== 'custom_amount'
        ? '%'
        : '';
    return newValue ? `${prefix}${decimalRoundingToString(newValue)}${postfix}` : Utils.zeroHyphen;
  }
}
