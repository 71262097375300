import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  Column,
  EditableCallbackParams,
  HeaderClassParams,
  ICellRendererParams,
  IProvidedColumn,
  ISetFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import {
  ColumnsWithEditClassesFunction,
  SelectedVendorColumnEditClassesFunction,
  TableService,
  EditClassNamesFunction,
} from '@shared/services/table.service';
import { AgAdjustmentEvidenceBasedHeaderComponent } from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.component';
import { EvidenceBasedHeaderColumnFunction } from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.model';
import { TableConstants } from '@shared/constants/table.constants';
import { AgExpandableGroupHeaderComponent } from './ag-expandable-group-header.component';
import { cellSize } from '@pages/budget-page/tabs/budget-enhanced/column-defs';
import { AgVendorEstimateCellComponent } from './ag-vendor-estimate-cell.component';
import { BehaviorSubject } from 'rxjs';
import { AuxExcelStyleKeys, decimalAdd, decimalDivide, decimalMultiply } from '@shared/utils';
import dayjs from 'dayjs';
import { Currency, InvoiceCategoryTotals } from '@shared/services/gql.service';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { AgIconLinkCellComponent } from '@shared/ag-components/ag-icon-link-cell/ag-icon-link-cell.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AgHeaderLinkComponent } from '@shared/ag-components/ag-header-link/ag-header-link.component';

export const spacerColumn = (colId?: string): ColDef | ColGroupDef => ({
  headerClass: 'ag-invisible border_right',
  cellClass: 'ag-invisible border_right',
  colId: colId,
  children: [
    { headerClass: 'ag-invisible', cellClass: 'ag-invisible', colId, width: 5, maxWidth: 5 },
  ],
});

export const blankActivitiesHeaderClass = 'blank-activities-header-class';

export const uomHide$ = new BehaviorSubject(true);

export const getActivitiesColumnDefs = (
  getClassNames: EditClassNamesFunction,
  currency: Currency,
  showUnitTotals$: BehaviorSubject<boolean>,
  selectedMonth: string
) => [
  {
    headerName: '',
    headerClass: ['ag-header-align-center', blankActivitiesHeaderClass],
    children: [
      {
        headerName: 'Activity ID',
        field: 'activity_no',
        headerClass: ['ag-header-align-center'],
        hide: true,
        width: 70,
        minWidth: 50,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        hide: true,
      },
      {
        headerName: 'Cost Category',
        field: 'cost_category_name',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Category',
        field: 'group0',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'actuals_performed_to_date',
        hide: true,
        aggFunc: 'sum',
      },
      {
        field: 'previous_months_accruals',
        hide: true,
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: 'Overall Budget',
    headerGroupComponent: AgExpandableGroupHeaderComponent,
    headerGroupComponentParams: {
      localStorageKey: 'closing_page_overall_budget',
      collapsedByDefault: true,
      expandableCols: ['uom', 'units', 'tma_unit_cost', 'total_amount'],
      filterCols: (column: Column) => {
        return (
          (!column.getColDef().hide &&
            ['actuals_to_date', 'total_remaining'].indexOf(column.getColId()) === -1) ||
          (column.getColId().includes('uom') && !uomHide$.getValue())
        );
      },
    },
    headerClass: 'ag-header-align-left dark-column-category-header',
    children: [
      {
        field: 'group1',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group2',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group3',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group4',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'activity_type',
        hide: true,
        filter: true,
      },
      {
        headerName: 'Label',
        field: 'display_label',
        headerClass: ['ag-header-align-left'],
        width: 70,
        minWidth: 50,
      },
      {
        headerName: 'Unit of Measure',
        headerClass: 'ag-header-align-right',
        field: 'uom',
        colId: 'uom',
        hide: uomHide$.getValue(),
        width: cellSize.large,
        minWidth: cellSize.large,
        suppressMenu: false,
        filter: true,
        filterParams: {
          showTooltips: true,
        } as ISetFilterParams,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'uom',
        cellClass: ['text-left', 'max-w', 'whitespace-nowrap', 'overflow-hidden', 'text-ellipsis'],
        valueFormatter: (params: { value?: string }) => params.value || '',
      },
      {
        headerName: 'Units',
        field: 'units',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 70,
        valueFormatter: ({ value }: { value: number }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Unit Cost',
        field: 'tma_unit_cost',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        width: 145,
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
      },
      {
        headerName: 'Current (LRE)',
        field: 'total_amount',
        width: 145,
        minWidth: 100,
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
      {
        headerName: `Actuals through ${dayjs(selectedMonth).subtract(1, 'month').format('MMM YYYY')} ($)`,
        field: 'actuals_to_date',
        width: 145,
        minWidth: 130,
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
      {
        headerName: 'Remaining ($)',
        field: 'total_remaining',
        width: 145,
        minWidth: 100,
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames([
          `budgetCost${currency}`,
          'ag-cell-align-right',
          AuxExcelStyleKeys.BORDER_RIGHT,
        ]),
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];

export const getPreviousMonthColumnDefs: ColumnsWithEditClassesFunction = (
  getClassNames,
  currency,
  showUnitTotals$
) => [
  {
    headerName: 'Actuals (Units)',
    field: 'prev_month_unit',
    headerClass: ['ag-header-align-right'],
    cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
    width: 85,
    minWidth: 70,
    valueFormatter: ({ value }) => Utils.decimalFormatter(value),
    aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
  },
  {
    headerName: 'Actuals ($)',
    field: 'prev_month_amount',
    headerClass: ['ag-header-align-right'],
    cellClass: getClassNames([
      `budgetCost${currency}`,
      'ag-cell-align-right',
      AuxExcelStyleKeys.BORDER_RIGHT,
    ]),
    width: 160,
    minWidth: 140,
    valueFormatter: (params: ValueFormatterParams) => {
      return Utils.agCurrencyFormatterAccounting(params, currency);
    },
    aggFunc: 'sum',
  },
];

export const getCurrentForecastColumnDefs: ColumnsWithEditClassesFunction = (
  getClassNames,
  currency,
  showUnitTotals$
) => [
  {
    headerName: 'Current Forecast',
    headerClass: ['ag-header-align-left blue-header header-marker'],
    headerGroupComponent: AgExpandableGroupHeaderComponent,
    headerGroupComponentParams: {
      collapsedByDefault: true,
      filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
        return index !== columns.length - 1;
      },
      localStorageKey: 'closing_page_current_forecast',
      expandableCols: ['current_forecast_percentage', 'current_forecast_unit'],
    },
    children: [
      {
        headerName: 'Monthly % Complete',
        field: 'current_forecast_percentage',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
        minWidth: 100,
        width: 100,
        valueGetter: (params) => {
          // For Category/Group and Discount rows
          if (!params.data || params.data.cost_category_name === 'Discount') {
            const total_amount = !params.data
              ? params.node?.aggData.total_amount
              : params.data.total_amount;

            const current_forecast_amount = !params.data
              ? params.node?.aggData.current_forecast_amount
              : params.data.current_forecast_amount;

            return (current_forecast_amount / total_amount) * 100;
          }

          return params.data.current_forecast_percentage;
        },
        valueFormatter: ({ value }) =>
          Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
      },
      {
        headerName: 'Monthly Units',
        field: 'current_forecast_unit',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 80,
        valueFormatter: ({ value }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Monthly Expense ($)',
        field: 'current_forecast_amount',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames([
          `budgetCost${currency}`,
          'ag-cell-align-right',
          AuxExcelStyleKeys.BORDER_RIGHT,
        ]),
        width: 150,
        minWidth: 145,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];
export const getVendorEstimateColumnDefs: SelectedVendorColumnEditClassesFunction = (
  getClassNames,
  getSelectedVendorCurrency,
  editMode$,
  showUnitTotals$,
  hideVendorEstimatePercentLTD$,
  hideVendorEstimateAmountLTD$,
  hasEditVendorEstimatePermission$,
  isVendorEstimateLocked$
) => {
  const getEditableCellClasses = TableService.getEditableCellClasses(editMode$) || [];

  const getEditableHeaderClasses = TableService.getEditableHeaderClasses(editMode$) || [];

  const editable = hasEditVendorEstimatePermission$.value && !isVendorEstimateLocked$.value;
  return [
    {
      headerName: 'Monthly % Complete',
      field: 'vendor_estimate_percentage',
      headerClass: ['ag-header-align-right'],
      cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
      minWidth: 100,
      width: 100,
      valueGetter: (params) => {
        // For Category/Group and Discount rows
        if (!params.data || params.data.cost_category_name === 'Discount') {
          const total_amount = !params.data
            ? params.node?.aggData.total_amount
            : params.data.total_amount;

          const vendor_estimate_amount = !params.data
            ? params.node?.aggData.vendor_estimate_amount
            : params.data.vendor_estimate_amount;

          return (vendor_estimate_amount / total_amount) * 100;
        }

        return params.data.vendor_estimate_percentage;
      },
      valueFormatter: ({ value }) =>
        Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
    },
    {
      headerName: 'Monthly Units',
      field: 'vendor_estimate_unit',
      headerClass: ['ag-header-align-right'],
      cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
      width: 85,
      minWidth: 80,
      valueFormatter: ({ value }) => Utils.decimalFormatter(value),
      aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
    },
    {
      headerName: 'Monthly Expense ($)',
      field: 'vendor_estimate_amount',
      headerClass: (params: HeaderClassParams) => {
        const classes = ['ag-header-align-right'];

        return editable ? getEditableHeaderClasses(classes)(params) : classes;
      },
      cellClass: (params: CellClassParams) => {
        const classes =
          localStorage.getItem(LocalStorageKey.CLOSING_PAGE_VENDOR_ESTIMATE) === 'false' &&
          hideVendorEstimateAmountLTD$.getValue()
            ? ['ag-cell-align-right', AuxExcelStyleKeys.BORDER_RIGHT]
            : ['ag-cell-align-right'];

        if (editable) {
          return getEditableCellClasses(classes)(params);
        }
        return classes.join(' ');
      },
      editable: (params: EditableCallbackParams) =>
        TableService.isEditableCellWithExtraPermission(editMode$, editable)(params),
      cellRenderer: AgVendorEstimateCellComponent,
      cellRendererParams: {
        editMode$,
        isVendorEstimateLocked$,
        hasEditVendorEstimatePermission$,
        dataPendoId: 'adjustments-vendor-estimate-expense',
      },
      width: 260,
      minWidth: 220,
      cellDataType: 'text',
      valueParser: (params) => {
        return params.newValue === params.oldValue
          ? params.oldValue
          : Utils.castStringToNumber(params.newValue).toString();
      },
      valueFormatter: (value) => {
        const formattedString = Utils.agCurrencyFormatterAccounting(
          value,
          getSelectedVendorCurrency()
        );
        return formattedString;
      },
      aggFunc: 'sum',
    },
    {
      headerName: 'Expense LTD ($)',
      field: 'vendor_estimate_amount_ltd',
      headerClass: (params: HeaderClassParams) => {
        const classes = ['ag-header-align-right'];

        return editable ? getEditableHeaderClasses(classes)(params) : classes;
      },
      cellClass: (params: CellClassParams) => {
        const classes =
          localStorage.getItem(LocalStorageKey.CLOSING_PAGE_VENDOR_ESTIMATE) === 'false'
            ? ['ag-cell-align-right', AuxExcelStyleKeys.BORDER_RIGHT]
            : ['ag-cell-align-right'];

        if (editable) {
          return getEditableCellClasses(classes)(params);
        }
        return classes.join(' ');
      },
      editable: (params: EditableCallbackParams) =>
        TableService.isEditableCellWithExtraPermission(editMode$, editable)(params),
      cellRenderer: AgVendorEstimateCellComponent,
      cellRendererParams: {
        editMode$,
        isVendorEstimateLocked$,
        hasEditVendorEstimatePermission$,
        dataPendoId: 'adjustments-vendor-estimate-expense-ltd',
      },
      width: 260,
      minWidth: 220,
      hide: hideVendorEstimateAmountLTD$.getValue(),
      cellDataType: 'text',
      valueParser: (params) => {
        return params.newValue === params.oldValue
          ? params.oldValue
          : Utils.castStringToNumber(params.newValue).toString();
      },
      valueFormatter: (value) => {
        const formattedString = Utils.agCurrencyFormatterAccounting(
          value,
          getSelectedVendorCurrency()
        );
        return formattedString;
      },
      aggFunc: 'sum',
    },
    {
      headerName: '% Complete LTD',
      field: 'vendor_estimate_percent_ltd',
      headerClass: (params: HeaderClassParams) => {
        const classes = ['ag-header-align-right'];

        return editable ? getEditableHeaderClasses(classes)(params) : classes;
      },
      editable: (params: EditableCallbackParams) =>
        TableService.isEditableCellWithExtraPermission(editMode$, editable)(params),
      cellClass: editable
        ? getEditableCellClasses([
            'budget-units',
            'ag-cell-align-right',
            AuxExcelStyleKeys.BORDER_RIGHT,
          ])
        : getClassNames(['budget-units', 'ag-cell-align-right', AuxExcelStyleKeys.BORDER_RIGHT]),
      minWidth: 130,
      hide: hideVendorEstimatePercentLTD$.getValue(),
      width: 130,
      cellRenderer: AgVendorEstimateCellComponent,
      cellRendererParams: {
        editMode$,
        isVendorEstimateLocked$,
        hasEditVendorEstimatePermission$,
        dataPendoId: 'adjustments-vendor_estimate_percent_ltd',
      },
      valueGetter: (params) => {
        // For Category/Group and Discount rows
        if (!params.data || params.data.cost_category_name === 'Discount') {
          const total_amount = !params.data
            ? params.node?.aggData.total_amount
            : params.data.total_amount;

          const actualsToDate = !params.data
            ? params.node?.aggData?.actuals_performed_to_date
            : params.data?.actuals_performed_to_date;

          const vendor_estimate_amount = !params.data
            ? params.node?.aggData.vendor_estimate_amount
            : params.data.vendor_estimate_amount;

          const previous_months_accruals = !params.data
            ? params.node?.aggData?.previous_months_accruals
            : params.data.previous_months_accruals;

          if (!total_amount) {
            return 0;
          }

          return decimalMultiply(
            decimalDivide(
              decimalAdd(
                decimalAdd(actualsToDate || 0, vendor_estimate_amount),
                previous_months_accruals
              ),
              total_amount
            ),
            100,
            6
          );
        }

        return params.data.vendor_estimate_percent_ltd;
      },
      valueFormatter: ({ value }) =>
        Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
    },
  ] as ColDef[];
};

export const getEvidenceBasedColumnDefs: EvidenceBasedHeaderColumnFunction = (
  getClassNames,
  editMode$,
  getSelectedMonthAndVendor,
  currency,
  showUnitTotals$
) => [
  {
    headerName: 'Evidence Based',
    headerClass: ['ag-header-align-left blue-header header-marker'],
    headerGroupComponent: AgAdjustmentEvidenceBasedHeaderComponent,
    headerGroupComponentParams: {
      editMode$,
      getSelectedMonthAndVendor,
      collapsedByDefault: true,
      localStorageKey: 'closing_page_evidence_based',
      expandableCols: ['evidence_based_percentage', 'evidence_based_unit'],
    },
    children: [
      {
        headerName: 'Monthly % Complete',
        colId: 'evidence_based_percentage',
        field: 'evidence_based_percentage',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
        minWidth: 100,
        width: 100,
        hide: true,
        valueGetter: (params) => {
          // For Category/Group and Discount rows
          if (!params.data || params.data.cost_category_name === 'Discount') {
            const total_amount = !params.data
              ? params.node?.aggData.total_amount
              : params.data.total_amount;

            const evidence_based_amount = !params.data
              ? params.node?.aggData.evidence_based_amount
              : params.data.evidence_based_amount;

            return (evidence_based_amount / total_amount) * 100;
          }

          return params.data.evidence_based_percentage;
        },
        valueFormatter: ({ value }) => Utils.percentageFormatter(decimalDivide(value, 100)),
      },
      {
        headerName: 'Monthly Units',
        colId: 'evidence_based_unit',
        field: 'evidence_based_unit',
        headerClass: ['ag-header-align-right'],
        hide: true,
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 80,
        valueFormatter: ({ value }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Monthly Expense ($)',
        colId: 'evidence_based_amount',
        field: 'evidence_based_amount',
        headerClass: ['ag-header-align-right'],
        cellClass: getClassNames([
          `budgetCost${currency}`,
          'ag-cell-align-right',
          AuxExcelStyleKeys.BORDER_RIGHT,
        ]),
        width: 195,
        minWidth: 185,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];

export const getInvoicesColumnDefs = (
  currency: Currency,
  invoiceCategoryTotals: InvoiceCategoryTotals,
  getClassNames: (classes: string[]) => (cellClassParams: CellClassParams) => string[],
  isOpenMonth: boolean,
  redirectFunction: () => void,
  doesInvoiceMappingsMatchTotal: (params: ICellRendererParams) => boolean
) => {
  const eyeIconTooltip = 'Review Invoices with Service Period set to this month.';
  const childrenColDefs = [
    {
      headerName: 'Monthly % Complete',
      colId: 'invoices_percentage',
      field: 'invoices_percentage',
      headerClass: ['ag-header-align-center'],
      cellClass: getClassNames(['ag-cell-align-right']),
      minWidth: 100,
      width: 100,
      valueGetter: (params: ValueGetterParams) => {
        if (params.node?.isRowPinned()) {
          return 0;
        }

        const total_amount = !params.data
          ? params.node?.aggData.total_amount
          : params.data.total_amount;

        const invoices_amount = !params.data
          ? params.node?.aggData.invoices_amount
          : params.data.invoices_amount;

        return (invoices_amount / total_amount) * 100;
      },
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
      editable: false,
      hide: true,
    },
    {
      headerName: 'Available ($)',
      colId: 'invoices_available',
      field: 'invoices_available',
      headerClass: ['ag-header-align-center', 'not-last-child'],
      headerComponent: AgHeaderLinkComponent,
      headerComponentParams: {
        redirectFunction: redirectFunction,
      },
      cellClass: getClassNames(['ag-cell-align-right']),
      minWidth: 140,
      width: 140,
      cellRenderer: AgIconLinkCellComponent,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          rightAlignment: true,
          icons: [
            {
              showIcon: doesInvoiceMappingsMatchTotal(params),
              icon: 'Check',
              iconClass: 'text-aux-green-dark',
              iconTooltip: MessagesConstants.INVOICE.TOTAL_MAPPED_TO_ACTIVITIES_THIS_PERIOD,
            },
          ],
        };
      },
      valueGetter: (params: ValueGetterParams) => {
        if (params.node?.field === 'cost_category_name') {
          switch (params.node.key) {
            case 'Services':
              return invoiceCategoryTotals.service_total;
            case 'Discount':
              return invoiceCategoryTotals.discount_total;
            case 'Pass-through':
              return invoiceCategoryTotals.passthrough_total;
            case 'Investigator':
              return invoiceCategoryTotals.investigator_total;
            default:
              return 0;
          }
        }

        if (params.node?.isRowPinned()) {
          return params.data.invoices_available;
        }

        return 0;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return Utils.agCurrencyFormatterAccounting(params, currency);
      },
      editable: false,
      hide: true,
    },
    {
      headerName: 'Monthly Expense ($)',
      colId: 'invoices_amount',
      field: 'invoices_amount',
      headerClass: ['ag-header-align-center'],
      cellClass: getClassNames(['ag-cell-align-right', AuxExcelStyleKeys.BORDER_RIGHT]),
      width: 195,
      minWidth: 145,
      valueFormatter: (params: ValueFormatterParams) => {
        return Utils.agCurrencyFormatterAccounting(params, currency);
      },
      aggFunc: 'sum',
      editable: false,
    },
  ];

  return [
    {
      headerName: 'Invoice',
      headerClass: ['ag-header-align-center blue-header'],
      headerGroupComponent: AgExpandableGroupHeaderComponent,
      headerGroupComponentParams: {
        collapsedByDefault: true,
        localStorageKey: 'closing_page_invoices',
        expandableCols: ['invoices_available', 'invoices_percentage'],
        filterCols: (column: Column) => {
          return !['invoices_amount'].includes(column.getId());
        },
        featureFlags: isOpenMonth
          ? [{ field: 'invoices_available', flagKey: 'adjustments_available_invoice' }]
          : [],
        eyeIconFunction: redirectFunction,
        eyeIconTooltip: eyeIconTooltip,
      },
      children: childrenColDefs.filter((column) => {
        if (isOpenMonth) {
          return true;
        }

        return column.field !== 'invoices_available';
      }),
    },
  ];
};
