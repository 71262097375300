import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  signal,
} from '@angular/core';
import {
  DocumentType,
  EventType,
  listDocumentsQuery,
  listUserNamesWithEmailQuery,
  User,
} from '@shared/services/gql.service';
import { EventService } from '@models/event/event.service';
import { WorkflowPanelEdcNotificationConfirmationComponent } from '@features/workflow-panel/workflow-panel-edc-notification-confirmation';
import {
  EDCItem,
  EDCItemStatus,
  EDCStatusBannerMap,
} from '@pages/closing-page/tabs/quarter-close-checklist/components/checklist-section-gather-patients/checklist-upload-edc-document/edc-document-status';
import { QuarterCloseChecklistPeriodCloseService } from '@pages/closing-page/tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { ApiService } from '@shared/services/api.service';
import { EntityType } from '@shared/services/gql.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MainQuery } from '@shared/store/main/main.query';
import { WorkflowStepType } from '@shared/store/workflow/workflow.const';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { Workflow } from '@shared/store/workflow/workflow.store';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { Utils } from '@shared/utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-edc-status-banner',
  standalone: true,
  imports: [NgClass, NgStyle, MenuComponent, AsyncPipe],
  templateUrl: './edc-status-banner.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EdcStatusBannerComponent {
  private readonly destroyRef = inject(DestroyRef);

  workflowService = inject(WorkflowService);

  workflowQuery = inject(WorkflowQuery);

  launchDarklyService = inject(LaunchDarklyService);

  overlayService = inject(OverlayService);

  authQuery = inject(AuthQuery);

  eventService = inject(EventService);

  apiService = inject(ApiService);

  quarterCloseChecklistPeriodCloseService = inject(QuarterCloseChecklistPeriodCloseService);

  mainQuery = inject(MainQuery);

  files = signal<listDocumentsQuery['items']>([]);

  currentWorkflow = computed(() => {
    return this.workflowQuery.getWorkflowByStepType(
      WorkflowStepType.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
    )();
  });

  currentMonth = computed(() => {
    return this.currentWorkflow() &&
      this.currentWorkflow()?.properties.month &&
      dayjs(this.currentWorkflow()?.properties.month).isValid()
      ? dayjs(this.currentWorkflow()?.properties.month).format('YYYY-MM-DD')
      : '';
  });

  edcStatus = computed(() => {
    const wf = this.currentWorkflow();
    if (!wf || !wf.properties.edc_data_status)
      return EDCStatusBannerMap[EDCItemStatus.STATUS_AWAITING_UPLOAD] as EDCItem;
    return EDCStatusBannerMap[wf.properties.edc_data_status as EDCItemStatus];
  });

  toolbarKebabOpen = this.launchDarklyService.$select((flags) => flags.notify_customer);

  edcBanner = computed(() => {
    const wf = this.currentWorkflow();
    if (!wf) return '';
    const lastUpdated = wf.properties.last_customer_notified_date;
    const updatedBy = wf.properties.last_customer_notified_by;
    const edcDataStatus = wf.properties.edc_data_status;
    const firstString =
      edcDataStatus === EDCItemStatus.STATUS_AVAILABLE
        ? 'Notification sent'
        : edcDataStatus === EDCItemStatus.STATUS_PROCESSING
          ? 'Returned to Processing'
          : 'Returned to Awaiting Upload';
    if (lastUpdated && updatedBy) {
      return `${firstString}: ${Utils.dateFormatter(lastUpdated, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' })} by ${this.userFormatter(updatedBy)}`;
    }
    return '';
  });

  firstKebabOptionName = computed(() => {
    return this.edcStatus().text.includes('Available')
      ? 'Notify Customer'
      : 'Change Status to Available and Notify Customer';
  });

  secondKebabOptionName = computed(() => {
    return this.files().length > 0 ? 'Return to Processing' : 'Return to Awaiting Upload';
  });

  showEdcBanner = computed(() => {
    return !!this.edcBanner() && this.toolbarKebabOpen();
  });

  showEdcDataAvailable = computed(() => {
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) return false;
    const status = currentWorkflow.properties?.edc_data_status;
    return status === EDCItemStatus.STATUS_AVAILABLE;
  });

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  constructor() {
    effect(() => {
      if (this.currentMonth()) {
        this.setFiles();
      }
    });
    this.initUserList();
  }

  initUserList() {
    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
  }

  async updateWorkflow(wf: Workflow, status: EDCItemStatus) {
    const userSub =
      status === EDCItemStatus.STATUS_AWAITING_UPLOAD ? undefined : this.authQuery.user().sub;
    const timestamp =
      status === EDCItemStatus.STATUS_AWAITING_UPLOAD ? undefined : dayjs().format();
    const edcProperties = {
      edc_data_status: status,
      edc_data_status_update_date: timestamp,
      edc_data_status_updated_by: userSub,
      last_customer_notified_date: timestamp,
      last_customer_notified_by: userSub,
    };
    const success = await this.workflowService.updateWorkflowPropertiesForEdcUpload(
      wf,
      edcProperties
    );
    return success;
  }

  async onNotifyCustomer() {
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) {
      return;
    }

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        unknown,
        { success: boolean },
        WorkflowPanelEdcNotificationConfirmationComponent
      >({
        content: WorkflowPanelEdcNotificationConfirmationComponent,
        settings: {
          header: 'Confirmation: Notify Customer',
          primaryButton: {
            label: 'Yes, Send Notification',
            action: (instance) => instance?.sendNotification(),
          },
        },
      }).afterClosed$
    );
    if (resp.data?.success) {
      const success = await this.updateWorkflow(currentWorkflow, EDCItemStatus.STATUS_AVAILABLE);
      if (success) {
        this.eventService.processEvent$({
          type: EventType.EDC_DOCUMENT_READY_FOR_REVIEW_NOTIFICATION,
          entity_type: EntityType.DOCUMENT,
          entity_id: '',
          payload: JSON.stringify({
            month: this.currentMonth(),
          }),
        });
      }
    }
  }

  async onReturnToProcessing() {
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) {
      return;
    }
    const isThereAnyUploadedFile = this.files().length > 0;
    const status = isThereAnyUploadedFile
      ? EDCItemStatus.STATUS_PROCESSING
      : EDCItemStatus.STATUS_AWAITING_UPLOAD;
    await this.updateWorkflow(currentWorkflow, status);
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    const firstName = user?.given_name;
    const lastName = user?.family_name;
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (!isUserAuxAdmin || this.authQuery.isAuxAdmin()) {
        return firstName && lastName ? `${firstName} ${lastName[0]}.` : Utils.zeroHyphen;
      } else {
        return 'Auxilius Expert';
      }
    }
    return Utils.zeroHyphen;
  }

  async setFiles() {
    const files = this.currentMonth() ? await this.fetchFiles() : [];
    this.files.set(files);
  }

  async fetchFiles(): Promise<listDocumentsQuery['items']> {
    const files = await this.apiService.getFilesByFilters(
      this.pathFN(),
      undefined,
      undefined,
      DocumentType.DOCUMENT_MANUAL_TRANSACTION_SUPPORT,
      undefined,
      undefined
    );
    return files;
  }

  defaultPath = () => {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/checklist`;
  };

  pathFN = () => {
    return `${this.defaultPath()}/${this.currentMonth()}/`;
  };
}
