import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { listTimelineMilestonesQuery } from '@shared/services/gql.service';

export interface TimelineState {
  items: listTimelineMilestonesQuery[];
  selectedTimeline: string;
  timelineExists: boolean;
}

export function createInitialState(): TimelineState {
  return {
    items: [],
    selectedTimeline: '',
    timelineExists: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'timeline' })
export class TimelineStore extends Store<TimelineState> {
  constructor() {
    super(createInitialState());
  }
}
