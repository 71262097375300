import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { IHeaderParams } from '@ag-grid-community/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AgSetColumnsVisible } from '@shared/utils';

export interface AgQuarterCloseCollapsableHeaderParams extends IHeaderParams {
  columns: string[];
}

@Component({
  standalone: true,
  template: `
    <div class="flex items-center space-x-2">
      <div *ngIf="params.template" [innerHTML]="params.template"></div>

      <button
        *ngIf="columns.length"
        class="flex items-center no-underline aux-link"
        (click)="onToggle()"
      >
        <aux-icon
          class="text-aux-gray-darkest"
          [name]="(collapsed$ | async) ? 'ChevronRight' : 'ChevronLeft'"
        />
      </button>
    </div>
  `,
  imports: [NgIf, AsyncPipe, NgClass, IconComponent],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseCollapsableHeaderComponent implements IHeaderAngularComp {
  params!: AgQuarterCloseCollapsableHeaderParams;

  collapsed$ = new BehaviorSubject(true);

  columns: string[] = [];

  agInit(params: AgQuarterCloseCollapsableHeaderParams) {
    this.params = params;
    this.columns = params.columns;
  }

  refresh(): boolean {
    return false;
  }

  onToggle() {
    const bool = !this.collapsed$.getValue();
    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: this.columns,
      visible: !bool,
    });
    this.collapsed$.next(bool);
  }
}
