import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Utils } from '@shared/utils/utils';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  template: `
    <div class="flex items-center align-middle h-full justify-items-start space-x-2">
      <div class="w-3 h-3 rounded-full mr-1" [ngClass]="bgClass"></div>
      <div [ngClass]="textClass" [attr.auto-qa]="autoTestAttribute">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioStatusComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  params!: ICellWrapperParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const status: string = params.data.status || '';

    switch (status) {
      case 'Open':
        this.bgClass = 'bg-aux-warn';
        this.textClass = 'text-aux-blue-dark underline text-sm';
        this.status = 'Open';
        break;
      case 'Closed':
        this.bgClass = 'bg-aux-green-dark';
        this.textClass = 'text-aux-blue-dark underline text-sm';
        this.status = 'Closed';
        break;
      default:
        this.bgClass = '';
        this.textClass = 'text-aux-black text-sm';
        this.status = Utils.zeroHyphen;
        break;
    }
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
