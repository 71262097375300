import { Injectable } from '@angular/core';
import { EventService } from '@models/event/event.service';
import { EntityType, EventType, GetTemplatePathInput, getTemplatePathQuery } from './gql.service';

/*
In a future attempt, I will add a "Lambda Function Url" endpoint to the backend (and, it will allow requests
to run up to 15 minutes without timing out). And, this service will be able to interact with that endpoint.

Currently the service is faking it via polling (i.e. sending an event to backend and waiting for completion).
*/

@Injectable({
  providedIn: 'root',
})
export class LfuService {
  constructor(private eventService: EventService) {}

  async getTemplatePath(
    input: GetTemplatePathInput
  ): Promise<GraphqlResponse<getTemplatePathQuery>> {
    let success = false;
    const errors: string[] = [];
    let data: getTemplatePathQuery | null = null;

    try {
      const trackerId = await this.eventService.triggerEvent({
        type: EventType.ASYNC_GET_TEMPLATE_PATH_REQUEST,
        entity_type: EntityType.INPUT,
        entity_id: '',
        payload: JSON.stringify(input || {}),
        bucket_key: '*',
      });
      data = await this.eventService.getTrackerResponse<getTemplatePathQuery>(trackerId || '');
      success = true;
    } catch (err) {
      errors.push(err instanceof Error ? err.message : String(err));
    }

    return { success, errors, data };
  }
}
