<form class="flex justify-between items-center my-4" [formGroup]="patientBudgetForm">
  <div class="flex items-center">
    <div class="mr-2 text-xs">Site:</div>
    <aux-site-select-dropdown formControlName="site_ids" label=" " class="w-64 mr-3 mt-2" />
  </div>
  <aux-currency-checkboxes [selectedCurrencies$]="$any(selectedCurrencies$)" />
</form>
<ag-grid-angular
  domLayout="autoHeight"
  class="ag-theme-aux"
  [gridOptions]="$any(gridOptions$ | async)"
  [rowData]="$any(gridData$ | async)"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="onDataRendered()"
  auxStickyGrid
/>
