<div
  class="container relative"
  [class.cursor-pointer]="(opened$ | async) === false"
  (click)="!opened$.value && clicked.emit()"
>
  <aux-icon [name]="'Message'" class="text-aux-blue-dark" />
  @if (monthlyReviewCount()) {
    <div
      class="flex justify-center items-center bg-[#118ee7] rounded-[50%] w-4 h-4 absolute left-[15px] top-[-5px]"
    >
      <span class="text-white text-xs select-none">
        {{ monthlyReviewCount() }}
      </span>
    </div>
  }
</div>
