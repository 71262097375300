import { Signal } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';

export class QuickForecastValidators {
  static isDateInRange(minDate: string, maxDate: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dayjs(control.value).isValid) return null;

      return dayjs(control.value).isAfter(minDate) && dayjs(control.value).isBefore(maxDate)
        ? null
        : { trialTimelinePeriod: true };
    };
  }

  static startMilestone(formGroup: FormGroup) {
    return (startMilestone: AbstractControl): ValidationErrors | null => {
      const endMilestone = formGroup.get('endMilestone');

      if (!endMilestone?.value || !startMilestone?.value) return null;

      const isBefore = dayjs(startMilestone.value?.contractStartDate).isBefore(
        endMilestone.value?.contractEndDate
      );
      const isSame = dayjs(startMilestone.value?.contractStartDate).isSame(
        endMilestone.value?.contractEndDate
      );

      if (!isBefore && isSame) {
        return null;
      }

      return isBefore ? null : { endMilestone: true };
    };
  }

  static required(control: AbstractControl): ValidationErrors | null {
    return control.value ? null : { requiredQuickForecast: true };
  }

  static siteCurveBeforePeriod(
    periodControl: AbstractControl,
    formGroup: Signal<FormGroupDirective | undefined>
  ) {
    return (siteCurveControl: AbstractControl): ValidationErrors | null => {
      const periodSelected = !!periodControl.value;

      if (formGroup()?.submitted && !periodSelected && !siteCurveControl.touched) {
        return null;
      }

      return periodSelected ? null : { siteCurveBeforePeriod: true };
    };
  }

  static customStartMilestone(formGroup: FormGroup) {
    return (control: AbstractControl): ValidationErrors | null => {
      const customEndMilestone = formGroup.get('customEndMilestone');

      if (!customEndMilestone?.value || !control.value) return null;

      const isBefore = dayjs(control.value).isBefore(customEndMilestone.value);
      const isSame = dayjs(control.value).isSame(customEndMilestone.value);

      if (!isBefore && isSame) {
        return null;
      }

      return isBefore ? null : { endMilestone: true };
    };
  }
}
