import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClosingPageQuery } from '@shared/store/closing-page/closing-page.query';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesHelperService {
  private readonly router = inject(Router);

  private readonly closingPageQuery = inject(ClosingPageQuery);

  private readonly quarterCloseNotesQuery = inject(QuarterCloseNotesQuery);

  getUnsavedChangesDecisionByRouterUrl(): boolean {
    let hasUnsavedChanges = false;

    if (
      this.router.url.startsWith(
        `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`
      )
    ) {
      hasUnsavedChanges = this.closingPageQuery.getValue().adjustmentHasUnsavedChanges;
    }

    if (
      this.router.url.startsWith(`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`)
    ) {
      hasUnsavedChanges = this.quarterCloseNotesQuery.someNoteEditorHasUnsavedChanges();
    }

    return hasUnsavedChanges;
  }
}
