import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { GqlService } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { ForecastSettingsModel, ForecastSettingsStore } from './forecast-settings.store';
import { ForecastSettingsQuery } from './forecast-settings.query';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ForecastSettingsService {
  constructor(
    private forecastSettingsStore: ForecastSettingsStore,
    private gqlService: GqlService,
    private forecastSettingsQuery: ForecastSettingsQuery,
    private overlayService: OverlayService
  ) {}

  add(forecastSetting: ForecastSettingsModel) {
    this.forecastSettingsStore.add(forecastSetting);
  }

  async syncSetting(id: string) {
    const set = this.forecastSettingsQuery.getEntity(id);
    if (set) {
      const {
        amount_type,
        category_id,
        activity_id,
        driver_setting_id,
        override,
        forecast_method,
        milestone_category,
        driver,
        period_end_milestone_id,
        period_end_date,
        period_start_milestone_id,
        period_start_date,
        evidence_source,
      } = set;
      const updatedForecastSetting = {
        forecast_method: driver === null ? null : forecast_method,
        category_id,
        activity_id,
        driver_setting_id: driver === null || !driver_setting_id ? null : driver_setting_id,
        driver,
        period_start_milestone_id: driver === null ? null : period_start_milestone_id || null,
        period_end_milestone_id: driver === null ? null : period_end_milestone_id || null,
        period_start_date: driver === null ? null : period_start_date,
        period_end_date: driver === null ? null : period_end_date,
        evidence_source,
        amount_type,
        override,
        milestone_category: driver === null ? null : milestone_category,
        discount: false,
      };
      this.forecastSettingsStore.update(id, updatedForecastSetting);
      return firstValueFrom(this.gqlService.updateBudgetForecastSetting$(updatedForecastSetting));
    }
    return null;
  }

  update(id: string, forecastSetting: Partial<ForecastSettingsModel>) {
    this.forecastSettingsStore.update(id, forecastSetting);
  }

  remove(id: ID) {
    this.forecastSettingsStore.remove(id);
  }
}
