import { ChangeDetectionStrategy, Component, contentChildren, signal } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AuxTabComponent } from '@shared/components/tab-group/aux-tab.component';

@Component({
  standalone: true,
  selector: 'aux-tab-group',
  template: `
    <div class="mt-8 border-b border-gray-200">
      <nav class="flex -mb-px space-x-8" aria-label="Tabs">
        @for (tab of tabChildren(); track $index) {
          <button
            type="button"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
            [ngClass]="{
              'border-aux-blue-light text-aux-blue-light': $index === activeTabIndex(),
              'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                $index !== activeTabIndex()
            }"
            [attr.data-pendo-id]="tab.pendoTag"
            (click)="activeTabIndex.set($index)"
          >
            {{ tab.label() }}
          </button>
        }
      </nav>
    </div>

    @for (tab of tabChildren(); track $index) {
      @if ($index === activeTabIndex()) {
        <ng-container *ngTemplateOutlet="tab.tmp()" />
      }
    }
  `,
  imports: [NgClass, NgTemplateOutlet],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent {
  tabChildren = contentChildren(AuxTabComponent);

  activeTabIndex = signal(0);
}
