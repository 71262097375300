import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  template: `
    <p class="w-[520px]" [ngClass]="ref.data?.widthContainer">
      {{ longText }}
    </p>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class ExampleModalComponent {
  ref = inject(CustomOverlayRef<undefined, { widthContainer?: string }>);

  readonly longText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt utlabore et dolore magna aliqua.'.repeat(
      3
    );
}
