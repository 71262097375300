<form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
  <aux-modal primaryButtonType="submit">
    <div class="grid grid-cols-3 gap-5">
      <aux-input
        label="First Name"
        formControlName="given_name"
        validators="required"
        [showRequiredAsterisk]="true"
      />
      <aux-input
        label="Last Name"
        formControlName="family_name"
        validators="required"
        [showRequiredAsterisk]="true"
      />
      <fieldset [disabled]="mode === 'edit'">
        <aux-input
          label="Email Address"
          formControlName="email"
          validators="required|email"
          [type]="'email'"
          [showRequiredAsterisk]="true"
        />
      </fieldset>

      <div>
        <div class="mb-1 text-xs">Department</div>
        <ng-select
          class="select"
          formControlName="department"
          bindLabel="label"
          bindValue="key"
          label="Department"
          [items]="departmentOptions"
          [clearable]="false"
          [searchable]="false"
          [appendTo]="'body'"
          [multiple]="true"
        />
      </div>

      <aux-input label="Title" formControlName="title" />

      <aux-input
        *ngIf="getFc('company')?.value === 'Other'"
        label="Name of other company"
        placeholder="Company"
        formControlName="companyName"
      />

      <div>
        <div class="mb-1 text-xs">User Type <span class="text-aux-error font-bold">*</span></div>
        <ng-select
          class="select"
          formControlName="role"
          bindLabel="label"
          label="User Type"
          bindValue="value"
          auxFormError
          required
          [items]="userOptions"
          [clearable]="false"
          [searchable]="false"
          [appendTo]="'body'"
        />
      </div>
    </div>
  </aux-modal>
</form>
