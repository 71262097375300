import { inject, Injectable } from '@angular/core';
import {
  AdjustmentsToPrepaidErrorRow,
  AdjustmentsToPrepaidRow,
} from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.model';
import { MessagesConstants } from '@shared/constants/messages.constants';
import {
  ActionType,
  batchModifyPrepaidsMutation,
  ModifyPrepaidInput,
  PrepaidType,
  TrialImplementationStatus,
} from '@shared/services/gql.service';
import dayjs from 'dayjs';
import { ArrElement } from '@shared/utils/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { decimalAdd, decimalAddAll, decimalEquality } from '@shared/utils/floating-math';

@Injectable()
export class AdjustmentsToPrepaidService {
  mainQuery = inject(MainQuery);

  errorsToShowOverlay(errorRows: Record<string, AdjustmentsToPrepaidErrorRow>): string[] {
    const errorsToShowOverlay: string[] = [];

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'sum_cost_category_not_equal_total_impact'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.COST_CAT_NOT_EQUAL_TOTAL_IMPACT);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'cost_category_amortize_increase'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.COST_CAT_AMORTIZE_INCREASE);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'total_impact_to_prepaid_amortize'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.AMORTIZE_NEGATIVE);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) =>
            cause === 'total_impact_to_prepaid_increase' ||
            cause === 'short_term_increase' ||
            cause === 'long_term_increase'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.INCREASE_POSITIVE);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'accrual_period_closed_month'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.ACCRUAL_PERIOD_IN_CLOSED_MONTH);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'payment_date_closed_month'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.PAYMENT_DATE_IN_CLOSED_MONTH);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) => cause === 'total_impact_to_prepaid_reclass'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.TOTAL_IMPACT_FOR_RECLASS);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) =>
            cause === 'amortize_services_amount_positive' ||
            cause === 'amortize_passthrough_amount_positive' ||
            cause === 'amortize_investigator_amount_positive'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.COST_CAT_AMORTIZE_NEGATIVE);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some(
          (cause: string) =>
            cause === 'increase_services_amount_negative' ||
            cause === 'increase_passthrough_amount_negative' ||
            cause === 'increase_investigator_amount_negative'
        );
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.COST_CAT_INCREASE_POSITIVE);
    }

    if (
      Object.keys(errorRows).some((errorRow: string) => {
        return errorRows[errorRow].causes.some((cause: string) => cause === 'increase_no_invoice');
      })
    ) {
      errorsToShowOverlay.push(MessagesConstants.PREPAIDS.INCREASE_MUST_HAVE_PAID_INVOICE);
    }

    return errorsToShowOverlay;
  }

  mapToAdjustmentToSave(
    actionType: ActionType,
    vendorId: string,
    row: AdjustmentsToPrepaidRow,
    removedRowId: string
  ): ModifyPrepaidInput {
    if (actionType === ActionType.ACTION_TYPE_REMOVE) {
      return {
        action_type: ActionType.ACTION_TYPE_REMOVE,
        vendor_id: vendorId,
        id: removedRowId,
        invoice_date: null,
        accrual_period: null,
        services_amount: null,
        investigator_amount: null,
        passthrough_amount: null,
        payment_date: null,
        prepaid_type: PrepaidType.PREPAID_TYPE_INCREASE,
        invoice_id: null,
        short_term_amount: null,
        long_term_amount: null,
        invoice_total: null,
      };
    }

    return {
      action_type: actionType,
      vendor_id: vendorId,
      id: row.isNewAdjustment ? null : row.id,
      invoice_date: row.invoice_date || null,
      accrual_period: row.accrual_period ? dayjs(row.accrual_period).format('YYYY-MM-DD') : null,
      services_amount: row.services_amount || 0,
      investigator_amount: row.investigator_amount || 0,
      passthrough_amount: row.passthrough_amount || 0,
      payment_date: row.payment_date || null,
      prepaid_type: row.prepaid_type || PrepaidType.PREPAID_TYPE_INCREASE,
      invoice_id: row.invoice_id || null,
      short_term_amount: row.short_term_amount || 0,
      long_term_amount: row.long_term_amount || 0,
      invoice_total: row.invoice_total || 0,
    };
  }

  mapInitialGridValue(adjustment: batchModifyPrepaidsMutation): AdjustmentsToPrepaidRow {
    return {
      ...adjustment,
      accrual_period: adjustment.accrual_period
        ? dayjs(adjustment.accrual_period).format('YYYY-MM')
        : null,
      total_impact_to_prepaid: decimalAdd(
        adjustment.short_term_amount || 0,
        adjustment.long_term_amount || 0,
        2
      ),
      total_expense_per_invoice: adjustment.total_expense_per_invoice || 0,
      isNewAdjustment: false,
    };
  }

  getCauses = (
    currentData: AdjustmentsToPrepaidRow,
    initialGridValues: AdjustmentsToPrepaidRow[]
  ) => {
    const openMonth = this.mainQuery.getValue().currentOpenMonth;
    const status = this.mainQuery.getSelectedTrial()?.implementation_status;
    const causes: ArrElement<AdjustmentsToPrepaidErrorRow['causes']>[] = [];
    const initialRowData = initialGridValues.find(
      (row: AdjustmentsToPrepaidRow) => row.id === currentData.id
    );

    if (!currentData.prepaid_type) {
      causes.push('prepaid_type');
    } else if (currentData.prepaid_type === PrepaidType.PREPAID_TYPE_INCREASE) {
      if (currentData.total_impact_to_prepaid && currentData.total_impact_to_prepaid < 0) {
        causes.push('total_impact_to_prepaid_increase');
      }

      if (currentData.short_term_amount && currentData.short_term_amount < 0) {
        causes.push('short_term_increase');
      }

      if (currentData.long_term_amount && currentData.long_term_amount < 0) {
        causes.push('long_term_increase');
      }

      if (currentData.services_amount && currentData.services_amount < 0) {
        causes.push('increase_services_amount_negative');
      }

      if (currentData.passthrough_amount && currentData.passthrough_amount < 0) {
        causes.push('increase_passthrough_amount_negative');
      }

      if (currentData.investigator_amount && currentData.investigator_amount < 0) {
        causes.push('increase_investigator_amount_negative');
      }

      if (!currentData.invoice_id) {
        causes.push('increase_no_invoice');
      }
    } else if (currentData.prepaid_type === PrepaidType.PREPAID_TYPE_AMORTIZE) {
      if (currentData.total_impact_to_prepaid && currentData.total_impact_to_prepaid > 0) {
        causes.push('total_impact_to_prepaid_amortize');
      }

      if (currentData.short_term_amount && currentData.short_term_amount > 0) {
        causes.push('short_term_amortize');
      }

      if (currentData.long_term_amount && currentData.long_term_amount > 0) {
        causes.push('long_term_amortize');
      }

      if (currentData.services_amount && currentData.services_amount > 0) {
        causes.push('amortize_services_amount_positive');
      }

      if (currentData.passthrough_amount && currentData.passthrough_amount > 0) {
        causes.push('amortize_passthrough_amount_positive');
      }

      if (currentData.investigator_amount && currentData.investigator_amount > 0) {
        causes.push('amortize_investigator_amount_positive');
      }
    } else {
      if (currentData.total_impact_to_prepaid && currentData.total_impact_to_prepaid !== 0) {
        causes.push('total_impact_to_prepaid_reclass');
      }
    }

    if (
      currentData.prepaid_type === PrepaidType.PREPAID_TYPE_AMORTIZE ||
      currentData.prepaid_type === PrepaidType.PREPAID_TYPE_INCREASE
    ) {
      if (
        !currentData.services_amount &&
        !currentData.passthrough_amount &&
        !currentData.investigator_amount
      ) {
        causes.push('cost_category_amortize_increase');
      }
    }

    if (
      status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
      status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
    ) {
      if (currentData.accrual_period && dayjs(currentData.accrual_period).isBefore(openMonth)) {
        if (!initialRowData || initialRowData.accrual_period !== currentData.accrual_period) {
          causes.push('accrual_period_closed_month');
        }
      }

      if (currentData.payment_date && dayjs(currentData.payment_date).isBefore(openMonth)) {
        if (!initialRowData || initialRowData.payment_date !== currentData.payment_date) {
          causes.push('payment_date_closed_month');
        }
      }
    }

    if (
      (!!currentData.services_amount ||
        !!currentData.passthrough_amount ||
        !!currentData.investigator_amount) &&
      !decimalEquality(
        decimalAddAll(
          2,
          currentData.services_amount || 0,
          currentData.passthrough_amount || 0,
          currentData.investigator_amount || 0
        ),
        currentData.total_impact_to_prepaid
      )
    ) {
      causes.push('sum_cost_category_not_equal_total_impact');
    }

    return causes;
  };
}
