import { Component, computed, inject, signal, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { File } from '@shared/components/file-manager/state/file.model';
import { DownloadTemplateButtonComponent } from '@features/download-template-button/download-template-button.component';
import { FileManagerUploadedFilesComponent } from '@features/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { EntityType, EventType, TemplateType } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { MainQuery } from '@shared/store/main/main.query';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { ApiService } from '@shared/services/api.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { MainStore } from '@shared/store/main/main.store';
import { InvestigatorTransactionsSaveModalComponent } from '../investigator-transactions-save-modal/investigator-transactions-save-modal.component';
import { EventService } from '@models/event/event.service';

@Component({
  templateUrl: './bulk-upload-modal.component.html',
  standalone: true,
  imports: [
    IconComponent,
    ButtonComponent,
    DownloadTemplateButtonComponent,
    FileManagerComponent,
    FileManagerUploadedFilesComponent,
    AsyncPipe,
  ],
})
export class BulkUploadModalComponent {
  ref = inject(CustomOverlayRef);

  private readonly mainQuery = inject(MainQuery);

  private readonly eventService = inject(EventService);

  private readonly overlayService = inject(OverlayService);

  private readonly apiService = inject(ApiService);

  private readonly mainStore = inject(MainStore);

  readonly templateType = TemplateType.BULK_INVESTIGATOR_TRANSACTIONS_TEMPLATE;

  fileManager = viewChild(FileManagerComponent);

  errorMessage = signal('');

  filesUploaded = computed(() => this.fileManager()?.fileQuery.count());

  uploadDisabled = computed(() => !this.filesUploaded() || !!this.errorMessage());

  closeModal() {
    this.ref.close({ success: false });
  }

  validateFileManager() {
    if (!this.fileManager()) return;
    const fileManager = this.fileManager() as FileManagerComponent;

    if (fileManager.fileQuery?.getAll()?.length > 1) {
      this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
    } else {
      this.errorMessage.set('');
    }
  }

  async uploadTemplate() {
    if (!this.fileManager()) return;

    const fileManager = this.fileManager() as FileManagerComponent;

    const trialId = this.mainQuery.getValue().trialKey;

    const files = fileManager.fileQuery.getAll();

    if (!files.length) {
      this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
      return;
    }

    if (files.length > 1) {
      this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
      return;
    }

    this.errorMessage.set('');

    const [file] = files;

    const templatePath = `investigator-transactions/${trialId}/${file.bucket_key}`;

    fileManager.fileStore.update(file.id, {
      ...file,
      bucket_key: templatePath,
    });

    const [bulkFile] = fileManager.fileQuery.getAll();

    const event = this.overlayService.openPopup<
      undefined,
      { note: string; documents: File[] },
      InvestigatorTransactionsSaveModalComponent
    >({
      content: InvestigatorTransactionsSaveModalComponent,
      settings: {
        header: 'Save Manual Transaction',
        primaryButton: {
          action: (instance) => instance?.save(),
          disabled: (instance) =>
            !!(instance?.noteFormControl.invalid || instance?.uploadingFiles()),
        },
      },
    });

    this.ref.close();

    const resp = await firstValueFrom(event.afterClosed$);

    if (!resp?.data) return;

    const uploadedDocumentBucketPaths = resp.data.documents.map(({ bucket_key }) => bucket_key);

    const fileSuccess = await fileManager.fileService.uploadFile(bulkFile, { admin: '1' });

    if (fileSuccess) {
      this.processEvent(trialId, templatePath, resp.data.note, uploadedDocumentBucketPaths);
    }
  }

  async processEvent(
    trialId: string,
    templatePath: string,
    note: string,
    uploadedDocumentBucketPaths: string[]
  ) {
    this.mainStore.setProcessingLoadingState(
      EventType.MANUAL_INVESTIGATOR_TRANSACTIONS_TEMPLATE_UPLOADED,
      true
    );
    this.overlayService.success('Template uploaded for processing.');

    const { success, errors, data } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.MANUAL_INVESTIGATOR_TRANSACTIONS_TEMPLATE_UPLOADED,
        entity_type: EntityType.TRIAL,
        entity_id: trialId,
        bucket_key: `public/${templatePath}`,
        payload: JSON.stringify({
          note: note,
          supporting_documents: uploadedDocumentBucketPaths,
        }),
      })
    );
    if (success && data?.id) {
      this.ref.close({ success: true });
    } else {
      // remove from s3 all uploaded files if fail event
      await Promise.all(
        [...uploadedDocumentBucketPaths, templatePath].map((filePath) =>
          this.apiService.removeFile(filePath)
        )
      );
      this.overlayService.error(errors, undefined, true);
      this.mainStore.setProcessingLoadingState(
        EventType.MANUAL_INVESTIGATOR_TRANSACTIONS_TEMPLATE_UPLOADED,
        false
      );
      this.ref.close({ success: false });
    }
  }
}
