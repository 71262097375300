<ng-select
  #select
  class="w-full ag-budget-activity-dropdown"
  [ngClass]="{
    'show-placeholder': formControl.value === ''
  }"
  [formControl]="formControl"
  [items]="params.options"
  [clearable]="formControl.value"
  [searchable]="true"
  bindLabel="label"
  bindValue="value"
  appendTo="body"
  [placeholder]="'Select budget activity'"
  (change)="onChange()"
>
  <ng-template ng-option-tmp let-item="item" let-index="item">
    <div
      #text
      class="text-sm truncate option"
      [auxTooltip]="text.offsetWidth < text.scrollWidth ? item.label : ''"
      auxTooltipPanelContainerClass="ag-budget-activity-dropdown-tooltip"
    >
      {{ item.label }}
    </div>
  </ng-template>
</ng-select>
