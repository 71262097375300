import { Component, Input } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { FormsModule } from '@angular/forms';
import { Option } from '@shared/types/components.type';

@Component({
  selector: 'aux-forecast-table-grid-period-custom-curve',
  templateUrl: './forecast-table-grid-period-custom-curve.component.html',
  styleUrl: './forecast-table-grid-period-custom-curve.component.scss',
  standalone: true,
  imports: [NgSelectModule, TooltipDirective, FormsModule],
})
export class ForecastTableGridPeriodCustomCurveComponent {
  @Input() tooltip = '';

  selectedPeriodPatientOption = 'Custom Curve';

  options: Option<string>[] = [
    {
      value: 'Patient Curves',
      label: 'Patient Curves',
    },
  ];
}
