import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-download-button',
  template: `
    <aux-button
      [onClick]="onDownloadInvoice"
      icon="Download"
      variant="secondary"
      classList="bg-white"
      label="Download Invoice"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailDownloadBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);

  onDownloadInvoice = async () => {
    const { overlayService, invoiceQuery } = this.ctx;
    const invoice = this.ctx.invoice();
    const { success, data, errors } = await invoiceQuery.downloadINV(invoice.id);

    if (success && data) {
      overlayService.success();
    } else {
      overlayService.error(errors);
    }
  };
}
