import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

@Component({
  selector: 'aux-radio-button',
  template: `
    <label class="flex items-center" [auxTooltip]="tooltip">
      <input
        type="radio"
        class="mr-1.5 border-aux-gray-dark focus:ring-aux-blue text-aux-blue ring-aux-blue checked:bg-none"
        [value]="value"
        [name]="name"
        (change)="onChange($any($event.target).value)"
        [checked]="value === selectedValue"
        [disabled]="disabled"
      />
      {{ label }}
    </label>
  `,
  styleUrl: './radio-button.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, TooltipDirective],
})
export class RadioButtonComponent {
  @Input() label!: string;

  @Input() value!: string;

  @Input() name!: string;

  @Input() selectedValue!: string;

  @Input() disabled = false;

  @Input() tooltip = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (value: string) => {};

  setSelectedValue(value: string) {
    this.selectedValue = value;
    this.onChange(value);
  }
}
