<div class="trial-insights-component">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <aux-icon name="Briefcase" [ngStyle]="{ color: color }" />
    </ng-container>
  </aux-trial-insights-header>

  <!-- Bar chart -->
  <div style="position: relative; height: 75px; width: 93%; margin: 10px 4px 4px 13px">
    <!-- Chart -->
    <canvas
      *ngIf="!isLoading"
      baseChart
      [type]="chartOptions.type"
      [data]="chartOptions.data"
      [options]="chartOptions.options"
    >
    </canvas>
  </div>

  <!-- Divider -->
  <div class="trial-insights-divider full"></div>

  <!-- Table -->
  <aux-trial-insights-table [options]="tableOptions" />
</div>
