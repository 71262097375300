import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aux-table-panel',
  template: `
    <div class="bg-aux-gray-light rounded-[8px] border border-aux-gray-dark overflow-hidden">
      <ng-content></ng-content>
    </div>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TablePanelComponent {}
