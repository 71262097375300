import { ChangeDetectionStrategy, Component, computed, inject, model, signal } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';

import { OverlayService } from '@shared/services/overlay.service';
import { GqlService } from '@shared/services/gql.service';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MultiCheckboxSelectComponent } from '@shared/components/multi-checkbox-select-dropdown/multi-checkbox-select-dropdown.component';
import { MainQuery } from '@shared/store/main/main.query';

import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { TrialsQuery } from '@models/trials/trials.query';

export interface CopyUsersModalData {
  containerClass: string[];
}

export interface CopyUsersModalResult {
  copy_permissions: boolean;
  aux_user_ids: string[];
  trial_ids: string[];
}

export const injectCopyUsersModal =
  (overlayService = inject(OverlayService), gqlService = inject(GqlService)) =>
  async () => {
    const ref = overlayService.openPopup<CopyUsersModalData, CopyUsersModalResult, CopyUsersModal>({
      content: CopyUsersModal,
      settings: {
        header: 'Copy Users',
        preventAutoCloseModal: true,
        primaryButton: {
          action: (instance) => instance?.onSave(),
        },
      },
      data: {
        containerClass: ['overflow-visible'],
      },
    });

    const { data: refData } = await firstValueFrom(ref.afterClosed$);

    if (refData) {
      const { copy_permissions, aux_user_ids, trial_ids } = refData;
      const {
        success,
        data: response,
        errors,
      } = await firstValueFrom(
        gqlService.copyUsersToTrials$({
          copy_permissions,
          aux_user_ids,
          trial_ids,
        })
      );

      if (success && response) {
        overlayService.success('Users copied successfully');
        return true;
      }
      overlayService.error(errors?.[0] || 'Failed to copy users');
    }

    return false;
  };

@Component({
  standalone: true,
  template: `
    <div class="space-y-[25px]">
      <div class="flex items-center">Select user(s) to copy to other trial(s)</div>

      <div>
        <div class="mb-[4px]">Users</div>
        <aux-multi-checkbox-select
          [options]="userOptions()"
          placeholder="Select users"
          [(selectedItems)]="selectedUserIDs"
        />
      </div>

      <div>
        <div class="mb-[4px]">Trial(s)</div>
        <aux-multi-checkbox-select
          [options]="trialOptions()"
          placeholder="Select trials"
          [(selectedItems)]="selectedTrialIDs"
        />
      </div>

      <div class="relative">
        <aux-checkbox [(ngModel)]="copyPermissions">
          <div>Copy All Permissions for Selected Users</div>
        </aux-checkbox>
        @if (copyPermissions()) {
          <div class="ml-[24px]">
            If a user already exists in a trial, permissions will be overridden.
          </div>
        }
      </div>

      @if (showError() && errorMessage()) {
        <div class="bg-aux-error p-4 rounded-md text-white">{{ errorMessage() }}</div>
      }
    </div>
  `,
  imports: [
    NgSelectModule,
    FormsModule,
    CheckboxComponent,
    ButtonComponent,
    SlicePipe,
    MultiCheckboxSelectComponent,
    IconComponent,
  ],
  styles: [
    `
      :host {
        display: block;
        width: 530px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyUsersModal {
  ref = inject(CustomOverlayRef<CopyUsersModalResult, CopyUsersModalData>);

  userQuery = inject(TrialUserQuery);

  trialsQuery = inject(TrialsQuery);

  authQuery = inject(AuthQuery);

  mainQuery = inject(MainQuery);

  userOptions = computed(() => {
    const users = this.userQuery.users();
    const userSub = this.authQuery.user().sub;

    return users
      .filter((user) => user.sub !== userSub)
      .map((user) => ({
        label: `${user.given_name} ${user.family_name}`,
        value: user.sub,
      }));
  });

  trialOptions = computed(() => {
    const trials = this.trialsQuery.trials();
    const trialKey = this.mainQuery.trialKey();

    return trials
      .filter((trial) => trial.id !== trialKey)
      .map((trial) => ({
        label: trial.short_name || '',
        value: trial.id,
      }));
  });

  selectedUserIDs = model<string[]>([]);
  selectedTrialIDs = model<string[]>([]);
  copyPermissions = model<boolean>(true);

  errorMessage = computed(() => {
    if (this.selectedUserIDs().length === 0) {
      return 'Please select at least one user';
    }

    if (this.selectedTrialIDs().length === 0) {
      return 'Please select at least one trial';
    }
    return '';
  });

  showError = signal(false);

  onSave() {
    this.showError.set(true);

    if (this.errorMessage()) {
      return;
    }

    this.ref.close({
      copy_permissions: this.copyPermissions(),
      aux_user_ids: this.selectedUserIDs(),
      trial_ids: this.selectedTrialIDs(),
    });
  }
}
