<h2 class="mb-4 font-semibold">Modal</h2>
<aux-button label="Launch modal" (click)="openModalWithTertiaryButton()" />
<h3 class="mt-4 font-semibold">Usage</h3>
<div class="flex items-center mb-12">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(15)" class="m-2">{{ i }}</div>
  </div>
  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.openPopup(&#123;</div>
    <div class="m-2 mx-4">content: ExampleModalComponent,</div>
    <div class="m-2 mx-4">settings: &#123;</div>
    <div class="m-2 mx-8">header: 'Title',</div>
    <div class="m-2 mx-8">tertiaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-8">primaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-8">secondaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-4">&#125;,</div>
    <div class="m-2">&#125;);</div>
  </div>
</div>

<h2 class="mb-4 font-semibold">Modal with form</h2>
<aux-button label="Launch modal with form" (click)="openModalWithForm()" />

<h3 class="mt-4 font-semibold">Usage</h3>
<div class="flex items-center mb-12">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(12)" class="m-2">{{ i }}</div>
  </div>
  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.openPopup(&#123;</div>
    <div class="m-2 mx-4">modal: UserExampleModalComponent,</div>
    <div class="m-2 mx-4">settings: &#123;</div>
    <div class="m-2 mx-8">header: 'Modal with form',</div>
    <div class="m-2 mx-8">primaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-8">secondaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-4">&#125;</div>
    <div class="m-2">&#125;);</div>
  </div>
</div>

<h2 class="mb-4 font-semibold">Full screen Modal</h2>
<aux-button label="Launch Full Screen Modal" (click)="openFullScreenModal()" />

<h3 class="mt-4 font-semibold">Usage</h3>
<div class="flex items-center mb-12">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(16)" class="m-2">{{ i }}</div>
  </div>
  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.openPopup(&#123;</div>
    <div class="m-2 mx-4">content: ExampleModalComponent,</div>
    <div class="m-2 mx-4">data: &#123;</div>
    <div class="m-2 mx-8">widthContainer: '!w-full',</div>
    <div class="m-2 mx-4">&#125;,</div>
    <div class="m-2 mx-4">settings: &#123;</div>
    <div class="m-2 mx-8">fullWidth: true,</div>
    <div class="m-2 mx-8">header: 'Title',</div>
    <div class="m-2 mx-8">primaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-8">secondaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Button',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-4">&#125;,</div>
    <div class="m-2">&#125;);</div>
  </div>
</div>

<h2 class="mb-4 font-semibold">Modal with keyword phrase</h2>
<button class="btn--primary" (click)="openModalWithExecutableAction()">Launch Modal</button>

<h3 class="mt-4 font-semibold">Usage</h3>
<div class="flex items-center mb-12">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(14)" class="m-2">{{ i }}</div>
  </div>
  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.openPopup(&#123;</div>
    <div class="m-2 mx-4">modal: ConfirmationActionModalComponent,</div>
    <div class="m-2 mx-4">data: &#123;</div>
    <div class="m-2 mx-8">keywordToExecuteAction: 'Delete Vendor',</div>
    <div class="m-2 mx-8">
      message: 'This will permanently delete the selected vendor. This action cannot be undone and
      will remove all associated data including budget and accruals.',
    </div>
    <div class="m-2 mx-4">&#125;,</div>
    <div class="m-2 mx-4">settings: &#123;</div>
    <div class="m-2 mx-8">header: 'Delete Vendor?',</div>
    <div class="m-2 mx-8">primaryButton: &#123;</div>
    <div class="m-2 mx-12">label: 'Delete Vendor',</div>
    <div class="m-2 mx-12">variant: 'negative',</div>
    <div class="m-2 mx-8">&#125;,</div>
    <div class="m-2 mx-4">&#125;,</div>
    <div class="m-2">&#125;);</div>
  </div>
</div>

<div class="mb-8"></div>

<h3 class="mt-6">Analytic Card</h3>

<div class="w-[230px]">
  <aux-card
    topText="Patient Visits LTD"
    [middleText]="'$80.332.214'"
    className="min-w-[230px]"
    [bottomTemplate]="exampleTemplate"
  />
</div>

<ng-template #exampleTemplate>
  <aux-checkbox class="mr-3">
    <div class="text-sm">Show planned visits</div>
  </aux-checkbox>
</ng-template>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(5)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-card</div>
    <div class="m-2">
      <div class="mx-4">topText="Patient Visits LTD"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[middleText]="'$80.332.214'"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">className="min-w-[230px]"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[bottomTemplate]="exampleTemplate"&gt;&lt;/aux-card&gt;</div>
    </div>
  </div>
</div>
