<div class="gather-estimates-container">
  <!-- Upload section -->
  <div *ngIf="!data.disabledLock" class="gather-estimates-upload-section mb-6">
    <button type="button" class="btn--secondary pl-3" (click)="data.vendorEstimateUploadClick()">
      <aux-icon name="Send" />
      <span class="ml-2">Send Vendor Estimate Support</span>
    </button>
  </div>

  <!-- Upload-disabled section -->
  <div *ngIf="data.disabledLock" class="gather-estimates-upload-section mb-6">
    <button
      type="button"
      class="btn--secondary gather-estimates-upload-section-disabled pl-3"
      disabled
    >
      <aux-icon name="Send" />
      <span class="ml-2">Send Vendor Estimate Support</span>
    </button>
  </div>

  <!-- Vendor section -->
  <div class="gather-estimates-vendor-section flex">
    <div class="gather-estimates-vendor-header gather-estimates-vendor-row flex">
      <div class="flex-grow -mb-2">Vendors</div>
      <div class="flex justify-center w-48 text-center">Available in Auxilius</div>
    </div>

    <div class="gather-estimates-vendor-row-container flex flex-grow flex-col">
      <div
        *ngFor="let vendor of vendorEstimateSummaries"
        class="gather-estimates-vendor-row flex flex-grow"
      >
        <!-- Vendor name -->
        <div class="flex-grow">
          <span
            class="btn--hyperlink px-0 cursor-pointer"
            [auxTooltip]="vendor.name"
            (click)="navigateToVendor(vendor.id)"
            >{{ vendor.name }}</span
          >
        </div>

        <!-- Available in Auxilius -->
        <div class="flex justify-center w-48">
          <aux-icon
            name="Check"
            *ngIf="vendor.vendorEstimateExists"
            class="gather-estimates-vendor-row-check"
          />
          <span *ngIf="!vendor.vendorEstimateExists" class="gather-estimates-vendor-row-x-mark">
            <aux-icon name="X" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
