import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { OrganizationService } from '@models/organization/organization.service';
import { MainStore } from '@shared/store/main/main.store';
import { SitesService } from '@models/sites/sites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom, map } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SitesStore } from '@models/sites/sites.store';
import { SitesQuery } from '@models/sites/sites.query';
import { OverlayService } from '@shared/services/overlay.service';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { getSiteWorkflow } from './site-workflow';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-site',
  templateUrl: './site.component.html',
})
export class SiteComponentComponent implements OnDestroy, OnInit {
  private readonly destroyRef = inject(DestroyRef);

  tabs: TabGroupConfig[] = [
    { label: 'Site Info', route: ROUTING_PATH.INVESTIGATOR.SITES.INFO },
    { label: 'Site Budget', route: ROUTING_PATH.INVESTIGATOR.SITES.SITE_BUDGET },
  ];

  private siteId$ = new BehaviorSubject('');

  siteWorkflow = getSiteWorkflow();

  site$ = combineLatest([this.siteId$, this.sitesQuery.selectAll()]).pipe(
    filter(([siteId]) => !!siteId),
    tap(([siteId]) => this.sitesStore.setActive([siteId])),
    map(([siteId]) => this.sitesQuery.getEntity(siteId))
  );

  constructor(
    private mainStore: MainStore,
    private vendorService: OrganizationService,
    private sitesService: SitesService,
    private route: ActivatedRoute,
    private router: Router,
    private sitesQuery: SitesQuery,
    private sitesStore: SitesStore,
    private overlayService: OverlayService
  ) {
    this.mainStore.update({ fullPage: true });

    this.route?.params.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.siteId$.next(params.id ?? '');
    });

    this.sitesService.get().pipe(takeUntilDestroyed()).subscribe();
    this.vendorService.listIdOrganizations$().pipe(takeUntilDestroyed()).subscribe();
  }

  ngOnInit(): void {
    combineLatest([this.sitesQuery.selectAll(), this.sitesQuery.selectLoading(), this.siteId$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([, loadingSites, siteId]) => {
        if (!this.sitesQuery.hasEntity(siteId) && !loadingSites) {
          this.goToSiteList();
        }
      });
  }

  ngOnDestroy(): void {
    this.mainStore.update({ fullPage: false });
    this.sitesStore.setActive(['']);
  }

  goToSiteList() {
    this.router.navigate([ROUTING_PATH.INVESTIGATOR.INDEX, ROUTING_PATH.INVESTIGATOR.SITES.INDEX]);
  }

  async deleteSite() {
    if (this.siteWorkflow.hasUpdateSitePermission() && !this.siteWorkflow.isPatientsFinalized()) {
      const site = await firstValueFrom(this.site$);

      if (site) {
        const ref = this.overlayService.openPopup<
          ConfirmationActionModalData,
          boolean,
          ConfirmationActionModalComponent
        >({
          modal: ConfirmationActionModalComponent,
          settings: {
            header: 'Delete Site?',
            primaryButton: {
              label: 'Delete Site',
              variant: 'negative',
            },
          },
          data: {
            message:
              'This will permanently delete the selected site. This action cannot be undone and will remove all associated data including visit costs.',
            keywordToExecuteAction: 'Delete Site',
          },
        });

        const { data: refData } = await firstValueFrom(ref.afterClosed$);
        if (refData) {
          await this.sitesService.remove(site);
          this.router.navigate([
            `${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`,
          ]);
        }
      }
    }
  }
}
