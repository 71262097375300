import {
  Component,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewContainerRef,
  signal,
  input,
  computed,
  viewChild,
  inject,
  OnDestroy,
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { AuthService } from '@shared/store/auth/auth.service';

@Component({
  selector: 'aux-menu',
  template: `
    @if (showMenuForExternal() || authService.isSysAdmin()) {
      <div [ngClass]="className()">
        <ng-container *ngTemplateOutlet="container"></ng-container>
      </div>
    }
    <ng-template #container>
      <aux-button
        classList="p-2"
        icon="DotsVertical"
        variant="tertiary"
        [disabled]="disabled()"
        [auxTooltip]="tooltip()"
        (clickEmit)="open($event)"
      />
    </ng-template>
    <ng-template #options>
      <div
        class="mt-4 rounded-md shadow-lg bg-white options"
        (auxClickOutside)="close()"
        (click)="close()"
      >
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    IconComponent,
    TooltipDirective,
    NgTemplateOutlet,
    ButtonComponent,
    ClickOutsideDirective,
  ],
})
export class MenuComponent implements OnDestroy {
  overlay = inject(Overlay);
  viewContainerRef = inject(ViewContainerRef);
  authService = inject(AuthService);

  options = viewChild.required<TemplateRef<Comment>>('options');

  className = input('');

  showMenuForExternal = input(false);

  disabled = input(false);
  disabledTooltip = input('');

  isOpen = signal(false);

  tooltip = computed(() => {
    const disabled = this.disabled();
    const disabledTooltip = this.disabledTooltip();

    return disabled ? disabledTooltip : '';
  });

  positionStrategy = this.overlay
    .position()
    .flexibleConnectedTo({ x: 0, y: 0 })
    .withPositions([
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
    ]);

  overlayRef = this.overlay.create({
    positionStrategy: this.positionStrategy,
    scrollStrategy: this.overlay.scrollStrategies.close(),
  });

  open({ x, y }: MouseEvent) {
    if (this.isOpen()) {
      return;
    }

    this.positionStrategy.setOrigin({ x, y });
    this.overlayRef.updatePositionStrategy(this.positionStrategy);

    this.overlayRef.attach(new TemplatePortal(this.options(), this.viewContainerRef));
    this.isOpen.set(true);
  }

  close() {
    this.overlayRef.detach();
    this.isOpen.set(false);
  }

  ngOnDestroy() {
    this.close();
    this.overlayRef.dispose();
  }
}
