import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnDestroy,
  OnInit,
  signal,
  untracked,
  ViewChild,
} from '@angular/core';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { EventQuery } from '@models/event/event.query';
import { NgOption, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  CanvasChartComponent,
  CanvasComponentOptions,
} from '@shared/components/canvas-chart/canvas-chart.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { asyncScheduler, BehaviorSubject, firstValueFrom, forkJoin, of, Subscription } from 'rxjs';
import { CellValueChangedEvent, GridApi, GridOptions } from '@ag-grid-community/core';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { TimelineQuery } from '../timeline-group/timeline/state/timeline.query';
import { TimelineService } from '../timeline-group/timeline/state/timeline.service';
import { CustomCurvesQuery } from './state/custom-curves.query';
import {
  CustomCurve,
  customCurvesInitialState,
  CustomCurvesStore,
} from './state/custom-curves.state';
import {
  CustomCurvesConstants,
  CustomDriverType,
  DistributionType,
} from './constants/custom-curves.constants';
import { CustomDriverCreateEditModalComponent } from './components/custom-driver-create-edit-modal/custom-driver-create-edit-modal.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { tap, delay } from 'rxjs/operators';
import { ExportType, Utils } from '@shared/utils/utils';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { OverlayService } from '@shared/services/overlay.service';
import {
  CustomType,
  DistributionMode,
  DriverCustomGroup,
  EntityType,
  EventType,
  GqlService,
  listDriverCustomDistributionQuery,
  PermissionType,
  updateDriverCustomDistributionMutation,
  WorkflowStep,
} from '@shared/services/gql.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { find, findIndex, isEqual, orderBy, pick } from 'lodash';
import { AgGridGetData } from '@shared/utils/ag-grid-data.utils';
import { MainQuery } from '@shared/store/main/main.query';
import dayjs from 'dayjs';
import { DistributionTimelineService } from '../forecast/drivers/services/distribution-timeline.service';
import { CustomCurvesUtils } from './utils/custom-curves.utils';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@shared/components/remove-dialog/remove-dialog.component';
import { WorkflowStepType } from '@shared/store/workflow/workflow.const';
import { NgClass } from '@angular/common';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { EventService } from '@models/event/event.service';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { ModalButton } from '@shared/components/overlay/custom-overlay-ref';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';

const emptyDistribution = (distribution_month: string, custom_amount = 0) => ({
  distribution_month,
  custom_amount,
  distribution_total: 0,
  distribution_remaining: 0,
});

export type Distribution = ReturnType<typeof emptyDistribution>;

interface TimeLineDates {
  startDate?: string | undefined;
  endDate?: string | undefined;
}

@Component({
  selector: 'aux-custom-drivers',
  templateUrl: './custom-drivers.component.html',
  styleUrl: './custom-drivers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    WorkflowPanelComponent,
    NgSelectModule,
    ButtonComponent,
    ReactiveFormsModule,
    CanvasChartComponent,
    IconComponent,
    MenuComponent,
    AgGridAngular,
    TooltipDirective,
    NgClass,
    ExportExcelButtonComponent,
    StickyGridDirective,
  ],
})
export class CustomDriversComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild(NgSelectComponent) selectComponent!: NgSelectComponent;

  readonly zeroHyphen = Utils.zeroHyphen;

  readonly overlayLoadingTemplate = '<span></span>';

  readonly customCurvesQuery = inject(CustomCurvesQuery);

  private readonly workflowQuery = inject(WorkflowQuery);

  private readonly launchDarklyService = inject(LaunchDarklyService);

  private readonly authService = inject(AuthService);

  private readonly eventQuery = inject(EventQuery);

  private readonly overlayService = inject(OverlayService);

  private readonly timelineQuery = inject(TimelineQuery);

  private readonly timelineService = inject(TimelineService);

  private readonly customCurvesStore = inject(CustomCurvesStore);

  private readonly mainQuery = inject(MainQuery);

  private readonly distributionTimelineService = inject(DistributionTimelineService);

  private readonly gqlService = inject(GqlService);

  private readonly customCurvesUtils = inject(CustomCurvesUtils);

  private readonly eventService = inject(EventService);

  timelineList$ = new BehaviorSubject<TimeLineDates>({
    startDate: '',
    endDate: '',
  });

  currentOpenMonth$ = new BehaviorSubject<string>('');

  editMode = signal(false);

  saving = signal(false);

  curvesLoading = signal(false);

  calculatedPinnedBottomData = signal<Partial<Distribution>>({});

  saveButtonTooltip = signal('');

  gridAPI = signal<GridApi | null>(null);

  gridData = signal<Distribution[]>([]);

  curves = computed<CustomCurve[]>(() => {
    const curves = this.customCurvesQuery.curves() || [];
    return orderBy(curves, ['name'], ['asc']);
  });

  distributionsRequestInProgress = signal(true);

  wasGridChanged = signal<boolean>(false);

  computedSaveTooltip = computed(() => {
    const tooltip = this.saveButtonTooltip();
    const wasGridChanged = this.wasGridChanged();
    return wasGridChanged ? tooltip : 'You need to make one or more changes to save';
  });

  saveDisabled = computed(() => {
    return !!this.computedSaveTooltip() || this.saving() || !this.wasGridChanged();
  });

  checkIsGridChanged = () => {
    this.wasGridChanged.set(this.didGridHasChanges());
  };

  getTooltipText = computed(() => {
    return !this.curves().length ? 'No custom curves exist. Click +Curve to create a curve.' : '';
  });

  editButtonDisabled = computed(() => {
    return (
      !this.hasModifyCustomCurvePermission() ||
      !this.curves().length ||
      this.curvesLoading() ||
      !this.gridData().length
    );
  });

  exportDisabled = computed(() => {
    return (
      !this.curves().length ||
      this.curvesLoading() ||
      this.saving() ||
      !this.gridData().length ||
      this.editMode()
    );
  });

  multiChartLabels = computed(() =>
    this.gridData().map(({ distribution_month }) =>
      Utils.dateFormatter(distribution_month, { day: undefined, year: '2-digit' })
    )
  );

  noPermTooltip = computed(() => {
    return !this.modifyCustomCurvePerm()
      ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
      : this.isWfLocked()
        ? MessagesConstants.SITE_PATIENT_CUSTOM_CURVES_LOCKED_TEXT
        : '';
  });

  selectedCurveDistributionLabel = computed<string>(() => {
    if (!this.customCurvesQuery?.selectedCurve()) {
      return 'Units / Dollars / %';
    }

    const index = Object.values(CustomDriverType).indexOf(
      (this.customCurvesQuery.selectedCurve() as CustomCurve).custom_type as CustomType
    );
    return Object.keys(DistributionType)[index];
  });

  multiChart = signal<CanvasComponentOptions>(this.getChartData());

  gridOptions = signal<GridOptions>(
    this.customCurvesUtils.getGridOptions(
      this.editMode,
      this.saving,
      this.calculatedPinnedBottomData,
      this.saveButtonTooltip,
      this.currentOpenMonth$,
      this.timelineList$,
      this.selectedCurveDistributionLabel,
      this.checkIsGridChanged
    )
  );

  isWfLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStepType.WF_STEP_MONTH_CLOSE_LOCK_CURVES
  );

  userHasLockCustomCurvePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
  });

  modifyCustomCurvePerm = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_CUSTOM_CURVE],
  });

  hasModifyCustomCurvePermission = computed(() => {
    return this.modifyCustomCurvePerm() && !this.isWfLocked();
  });

  selectedCurve = new FormControl<string | null | undefined>(null);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  timelineRange = toSignal(this.timelineQuery.selectTimelineRange());

  isChartEmpty = computed(() => {
    return !this.gridData().some(({ custom_amount }) => custom_amount > 0);
  });

  private initialValue: Distribution[] = [];

  private selectedGroupId = '';

  private onSaveAndEdit = effect(
    () => {
      this.saving();
      this.editMode();

      untracked(() => {
        this.gridAPI()?.refreshHeader();
      });
    },
    { allowSignalWrites: true }
  );

  private changeCurvesDropdownStatus = effect(
    () => {
      if (this.curves().length) {
        this.selectedCurve.enable();
      } else {
        this.selectedCurve.disable();
      }
    },
    { allowSignalWrites: true }
  );

  private createOrEditCurve = effect(
    () => {
      const curveToCreateOrEdit = this.customCurvesQuery.curveToCreateOrEdit();

      if (curveToCreateOrEdit) {
        this.customCurvesStore.updatePartialy({
          curves: [
            ...this.customCurvesQuery
              .curves()
              .filter((curve) => curve.custom_group_id !== curveToCreateOrEdit?.custom_group_id),
            curveToCreateOrEdit,
          ].filter(Boolean) as Partial<DriverCustomGroup>[],
          curveToCreateOrEdit: null,
        });

        asyncScheduler.schedule(() => this.selectItemByName(curveToCreateOrEdit?.name));
      }
    },
    { allowSignalWrites: true }
  );

  private onCurrentMonthChange = effect(
    () => {
      if (this.mainQuery.currentOpenMonth() && this.timelineRange()) {
        const range = this.getTimelineRange() as string[];
        if (
          range.some((timeline) =>
            dayjs(timeline).isSame(this.mainQuery.currentOpenMonth(), 'month')
          )
        ) {
          this.currentOpenMonth$.next(this.mainQuery.currentOpenMonth());
        } else if (range.length) {
          this.currentOpenMonth$.next(range[0]);
        }

        untracked(() => {
          if (range.length && this.gridData().length) {
            this.cancelEditMode();
            this.setCalculatedCells();
            this.setInitialData();
            this.setPinnedData();
          }
        });
      }
    },
    { allowSignalWrites: true }
  );

  private onCurveSelect = effect(
    () => {
      if (this.customCurvesQuery.selectedCurve() && this.timelineRange()) {
        untracked(() => {
          const isLastFetched =
            this.selectedGroupId === this.customCurvesQuery.selectedCurve()?.custom_group_id;
          if (this.editMode() && !isLastFetched) {
            this.cancelEditMode();
          }

          if (isLastFetched) {
            return;
          }

          this.fetchDistributions();
        });
      }
    },
    { allowSignalWrites: true }
  );

  ngOnInit(): void {
    this.selectedCurve.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), delay(0))
      .subscribe((selectedCurveName: string | null | undefined) => {
        const selectedCurve = this.findItemByName(selectedCurveName)?.value;

        if (selectedCurveName && selectedCurve) {
          this.customCurvesStore.updatePartialy({ selectedCurve });
          this.gridAPI()?.refreshHeader();
        }
      });

    this.timelineQuery
      .selectStartEndDateTrial()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((dates) => {
        this.timelineList$.next(dates);
        this.gridAPI()?.refreshCells({ force: true });
      });
  }

  ngAfterViewInit(): void {
    this.timelineService
      .getTimelineItems()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.getCustomDriverGroups())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.customCurvesStore.update(customCurvesInitialState);
  }

  gridReady(gridAPI: GridApi): void {
    this.gridAPI.set(gridAPI);
    this.setTableLoading(false);
  }

  trackByFn(item: DriverCustomGroup): string {
    return item.name + item.custom_type;
  }

  async setGridData(distributions: listDriverCustomDistributionQuery[] = []): Promise<void> {
    const customCurves: Distribution[] = [];

    await this.createDistributionsIfNeeded(this.generateRows(distributions, customCurves));
    this.gridData.set(customCurves);
    this.setCalculatedCells();
    this.setInitialData();
    this.setPinnedData();
  }

  cellValueChanged(event: CellValueChangedEvent): void {
    if (event.oldValue != event.newValue) {
      this.setCalculatedCells();
    }
    this.checkIsGridChanged();
    this.setPinnedData();
  }

  sizeColumnsToFit(): void {
    this.gridAPI()?.sizeColumnsToFit();
  }

  cancelEditMode(): void {
    this.editMode.set(false);
    this.resetInitialData();
    this.setPinnedData();
    this.multiChart.set(this.getChartData());
  }

  editGrid(): void {
    this.editMode.set(true);
    asyncScheduler.schedule(() => {
      const index = findIndex(
        this.gridData(),
        (distribution) =>
          dayjs(this.currentOpenMonth$.value).format('YYYY-MM') ===
          dayjs(distribution.distribution_month).format('YYYY-MM')
      );
      const rowIndex = index === -1 ? 0 : index;
      this.gridAPI()?.startEditingCell({
        rowIndex,
        colKey: 'custom_amount',
      });
      this.gridAPI()?.refreshCells({ force: true });
    });
    this.checkIsGridChanged();
  }

  async onCreateButtonClick(): Promise<void> {
    if (await this.canDeactivate()) {
      this.cancelEditMode();
      this.overlayService.openPopup({
        content: CustomDriverCreateEditModalComponent,
        settings: {
          header: 'Create a New Driver Curve',
          secondaryButton: { pendoTag: 'custom-curves-create-modal-cancel-button' },
          primaryButton: {
            label: 'Create Curve',
            ...this.createEditModalSaveParams,
          },
        },
        data: {
          existingNames: this.curves().map((curve) => curve.name),
        },
      });
    }
  }

  async onEdit(event: MouseEvent, curve: CustomCurve) {
    event.stopPropagation();
    if (await this.canDeactivate()) {
      this.cancelEditMode();
      this.overlayService.openPopup({
        content: CustomDriverCreateEditModalComponent,
        settings: {
          header: 'Edit Custom Driver Curve',
          secondaryButton: { pendoTag: 'custom-curves-create-modal-cancel-button' },
          primaryButton: {
            label: 'Update Curve',
            ...this.createEditModalSaveParams,
          },
        },
        data: {
          existingNames: this.curves().map((curve) => curve.name),
          type: curve.custom_type,
          name: curve.name,
          id: curve.custom_group_id,
        },
      });
    }
  }

  async onDelete(event: MouseEvent, curveToDelete: CustomCurve): Promise<void> {
    event.stopPropagation();

    const curve = this.curves().find((item) => item.name === curveToDelete.name);

    if (!curve) {
      return;
    }

    const cannotDeleteMessage = `${curve.name} cannot be deleted because it is being used in other areas of the application.<br>Please first remove the dependencies below and then ${curve.name} can be successfully deleted.<br>`;
    const routeInputs = [];
    const organizations = curve.organizations_with_forecasts || [];

    if (organizations.length) {
      routeInputs.push(
        ...[
          {
            componentName: 'Forecast Methodology:',
            name: organizations.map(({ name }) => name),
            link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          },
        ]
      );

      const removeDialogInput: RemoveDialogInput = {
        cannotDeleteMessage,
        routeInputs,
        clickRouteCallback: (name: string) => {
          const organization = find(organizations, { name });
          if (organization) {
            localStorage.setItem(LocalStorageKey.FORECAST_VENDOR, organization.id);
          }
        },
      };

      const resp = this.overlayService.openPopup({
        content: RemoveDialogComponent,
        data: removeDialogInput,
        settings: {
          header: 'Remove Custom Curve',
          primaryButton: {
            disabled: true,
            label: 'Remove',
          },
        },
      });

      await firstValueFrom(resp.afterClosed$);
    } else {
      this.overlayService.openPopup<
        { message: string },
        boolean | undefined,
        ConfirmationModalComponent
      >({
        content: ConfirmationModalComponent,
        data: {
          message: `Are you sure you want to remove Custom Curve ${curve.name}?`,
        },
        settings: {
          header: 'Remove Custom Curve',
          primaryButton: {
            label: 'Remove',
            action: async (instance) => {
              const curveIndex = findIndex(this.curves(), { name: curve.name });
              const nextCurveName = this.curves()[curveIndex + 1]?.name;

              forkJoin([
                this.gqlService.removeCustomGroupDriver$(curve.driver_setting_id as string),
                this.gqlService.removeCustomGroup$(curve.custom_group_id as string),
              ]).subscribe(() => {
                const isSelectedRemoved =
                  curveToDelete.custom_group_id ===
                  this.customCurvesQuery.selectedCurve()?.custom_group_id;
                this.overlayService.success(`${curveToDelete.name} successfully removed!`);
                this.getCustomDriverGroups(
                  isSelectedRemoved,
                  isSelectedRemoved ? nextCurveName : undefined
                );
              });
              instance?.ref.close();
            },
          },
        },
      });
    }
  }

  async onItemSelect(event: MouseEvent, curve: CustomCurve): Promise<void> {
    if (this.selectedCurve.value === curve.name) {
      return;
    }

    event.stopPropagation();
    if (await this.canDeactivate()) {
      this.selectItemByName(curve.name);
    }
  }

  async save(): Promise<void> {
    this.saving.set(true);
    this.editMode.set(false);

    let updateOccurred = false;
    const errors: string[] = [];
    const currentDistributions: listDriverCustomDistributionQuery[] = [];
    const keepData = (data: Partial<GraphqlResponse<updateDriverCustomDistributionMutation>>) => {
      if (data.errors?.length) {
        errors.push(...data.errors);
      } else if (data.data) {
        currentDistributions.push(data.data as listDriverCustomDistributionQuery);
      }
    };

    const requests = this.gridData().map((row) => {
      const existing = find(
        this.customCurvesQuery.currentDistributions() || [],
        (distribution) => distribution.distribution_month === row.distribution_month
      ) as listDriverCustomDistributionQuery;

      const updateData = {
        distribution_month: row.distribution_month,
        custom_amount: row.custom_amount || 0,
        distribution_total: row.distribution_total || 0,
        ...pick(existing, ['distribution_mode', 'id']),
      };
      return updateData.custom_amount == existing.custom_amount &&
        updateData.distribution_total == existing.distribution_total
        ? of(keepData({ data: existing }))
        : this.gqlService.updateDriverCustomDistribution$(updateData).pipe(
            tap(keepData),
            tap(() => (updateOccurred = true))
          );
    });

    await firstValueFrom(forkJoin(requests));
    if (updateOccurred) {
      await firstValueFrom(
        this.eventService.processEvent$({
          type: EventType.CUSTOM_DRIVER_DISTRIBUTION_UPDATED,
          entity_type: EntityType.DRIVER,
          entity_id: this.customCurvesQuery.selectedCurve()?.driver_setting_id as string,
        })
      );
    }

    this.customCurvesStore.updatePartialy({ currentDistributions });

    if (errors.length) {
      this.overlayService.error(errors);
    } else {
      this.overlayService.success('Custom Curves Table updated successfully!');
    }

    this.saving.set(false);
    this.gridData.set([...this.gridData()]);
    this.setInitialData();
    this.setPinnedData();
    this.gridAPI()?.refreshCells({ force: true });
  }

  async onExportCurve(): Promise<void> {
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs().format('YYYY.MM.DD-HHmmss');

    const { success, errors } = await this.processExportEvent(trialName, dateStr);
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  async canDeactivate(): Promise<boolean> {
    const currentValuesToCompare = AgGridGetData(<GridApi>this.gridAPI()).map((curve) =>
      Number((curve as Distribution).custom_amount || 0)
    );
    const initialValuesToCompare = this.initialValue.map((curve) =>
      Number((curve as Distribution).custom_amount || 0)
    );
    const hasChanges = !isEqual(currentValuesToCompare, initialValuesToCompare) && this.editMode();

    if (hasChanges) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  private generateRows(
    distributions: listDriverCustomDistributionQuery[],
    customCurves: Distribution[]
  ): string[] {
    const monthesForDistributionsToBeCreated: string[] = [];

    this.getTimelineRange()?.forEach((month) => {
      const existing = distributions.find(
        (distribution) => distribution.distribution_month === month
      );
      if (!existing) {
        monthesForDistributionsToBeCreated.push(month);
      }
      customCurves.push(
        existing ? this.getDistributionWithDefaultValues(existing) : emptyDistribution(month)
      );
    });

    return monthesForDistributionsToBeCreated;
  }

  private getTimelineRange(): string[] | undefined {
    const startDate = this.timelineList$.value.startDate as string;

    if (!startDate) {
      return;
    }

    const endDate = dayjs(this.timelineList$.value.endDate).format('YYYY-MM-DD');
    const { startDate: _start, endDate: _end } =
      this.distributionTimelineService.getDistributionTimeline(
        { startDate, endDate },
        this.customCurvesQuery.currentDistributions() || []
      );

    let start = Utils.dateParse(`${_start.slice(0, 8)}01`);
    const end = dayjs(_end).toDate();
    const range = [];

    while (start <= end) {
      const month = new Intl.DateTimeFormat('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(start);
      range.push(month);
      start = Utils.addMonths(start, 1);
    }

    return range;
  }

  private async createDistributionsIfNeeded(monthes: string[]): Promise<void> {
    if (!monthes.length) {
      return;
    }

    const createRequests = monthes.map((distribution_month) =>
      this.gqlService.createDriverCustomDistribution$({
        distribution_month,
        custom_amount: 0,
        distribution_total: 0,
        distribution_mode: DistributionMode.DISTRIBUTION_MODE_FORECAST,
        driver_setting_id: this.customCurvesQuery.selectedCurve()?.driver_setting_id as string,
      })
    );

    const createdResponses = await firstValueFrom(forkJoin(createRequests));
    const createdDistributions = createdResponses
      .map((response) => response.data as listDriverCustomDistributionQuery)
      .filter(Boolean);
    this.customCurvesStore.updatePartialy({
      currentDistributions: [
        ...(this.customCurvesQuery.currentDistributions() || []),
        ...createdDistributions,
      ],
    });
  }

  private getChartData(): CanvasComponentOptions {
    return CustomCurvesConstants.multiChartOptions(
      this.selectedCurveDistributionLabel(),
      this.gridData(),
      this.multiChartLabels()
    );
  }

  private setCalculatedCells(): void {
    this.gridData.set(
      CustomCurvesUtils.calculateForecastDistributions(
        this.gridData(),
        this.currentOpenMonth$.value,
        this.customCurvesQuery.selectedCurve()?.custom_type
      )
    );
    this.multiChart.set(this.getChartData());
  }

  private resetPageState(): void {
    this.gridAPI()?.hideOverlay();
    this.gridAPI()?.showNoRowsOverlay();
    this.initialValue = [];
    this.selectedGroupId = '';
    this.gridData.set([]);
    this.setPinnedData();
    this.customCurvesStore.updatePartialy({ selectedCurve: null });
    this.selectedCurve.setValue(null);
    this.curvesLoading.set(false);
    this.gridAPI()?.refreshHeader();
  }

  private getCustomDriverGroups(shouldSelectFirstCurve = true, curveNameToSelect?: string): void {
    this.customCurvesStore
      .getCustomDriverGroups()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((listDriverCustomGroupsQuery) => {
        if (!listDriverCustomGroupsQuery.length) {
          this.resetPageState();
          return;
        }

        if (curveNameToSelect) {
          this.selectItemByName(curveNameToSelect);
        } else if (shouldSelectFirstCurve) {
          this.selectFirstCurve();
        }
      });
  }

  private setInitialData(): void {
    this.initialValue = [];
    this.gridData().forEach((data) => {
      this.initialValue.push({ ...data });
    });
  }

  private resetInitialData(): void {
    const grid: Distribution[] = [];
    this.initialValue.forEach((data) => {
      grid.push({ ...data });
    });
    this.gridData.set(grid);
  }

  private getDistributionWithDefaultValues(
    distribution: listDriverCustomDistributionQuery | Distribution
  ): Distribution {
    return {
      distribution_month: distribution.distribution_month,
      custom_amount: distribution.custom_amount || 0,
      distribution_total: distribution.distribution_total || 0,
      distribution_remaining: (distribution as Distribution).distribution_remaining || 0,
    };
  }

  private fetchDistributions(): void {
    this.selectedGroupId = this.customCurvesQuery.selectedCurve()?.custom_group_id || '';
    this.setTableLoading();
    this.customCurvesStore
      .getDriverCustomDistribution()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (listDistributions) => {
        await this.setGridData(listDistributions);
        this.curvesLoading.set(false);
        this.distributionsRequestInProgress.set(false);
      });
  }

  private calculatePinnedBottomData(): void {
    const data = CustomCurvesUtils.calculatePlannedTotal(
      this.gridData(),
      this.customCurvesQuery.selectedCurve()?.custom_type
    );
    this.calculatedPinnedBottomData.set(data);
  }

  private setPinnedData(data: Distribution = emptyDistribution('TOTAL')): void {
    if (!this.gridData().length) {
      this.gridAPI()?.setGridOption('pinnedBottomRowData', []);
      return;
    }

    this.calculatePinnedBottomData();
    this.gridAPI()?.setGridOption('pinnedBottomRowData', [
      {
        ...data,
        ...this.calculatedPinnedBottomData(),
      },
    ]);
    this.gridAPI()?.refreshCells({ force: true });
  }

  private setTableLoading = (clearPinnedData = true): void => {
    this.curvesLoading.set(true);
    this.gridAPI()?.showLoadingOverlay();
    if (clearPinnedData) {
      this.gridAPI()?.setGridOption('pinnedBottomRowData', []);
    }
  };

  private selectItemByName(name: string | undefined): NgOption | void {
    const item = this.findItemByName(name);
    if (item) {
      this.selectComponent.select(item);
    }
  }

  private findItemByName(name: string | null | undefined): NgOption | undefined {
    const findedSelected = this.selectComponent.itemsList.items.find(
      (item) => item.value.name && item.value.name === name
    );
    if (findedSelected) {
      return findedSelected;
    }
  }

  private selectFirstCurve = (): Subscription =>
    asyncScheduler.schedule(() => {
      if (this.selectComponent?.itemsList?.items?.length) {
        this.selectComponent.select(this.selectComponent.itemsList.items[0]);
      }
    });

  private processExportEvent = (
    trialName: string,
    dateStr: string
  ): Promise<GraphqlResponse<ProcessEventResult>> => {
    return firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.CUSTOM_CURVE,
          filename: `auxilius-${trialName}-custom-curves-${dateStr}`,
        }),
      })
    );
  };

  didGridHasChanges(): boolean {
    const fieldsToCompare = ['custom_amount'];
    const currentValuesToCompare: Partial<{ custom_amount?: number | string }>[] = AgGridGetData(
      <GridApi>this.gridAPI()
    ).map((curve) => pick(curve, fieldsToCompare));
    const initialValuesToCompare: Partial<{ custom_amount?: number | string }>[] =
      this.initialValue.map((curve) => pick(curve, fieldsToCompare));
    const castToNumberArray = (colData: Partial<{ custom_amount?: number | string }>[]) => {
      const castedData = colData;
      castedData.forEach((val) => {
        if (!val.custom_amount || val.custom_amount === '0') {
          val.custom_amount = 0;
        }
        val.custom_amount = Number(val.custom_amount);
      });
      return castedData;
    };

    const castedCurrent = castToNumberArray(currentValuesToCompare);
    const castedInitial = castToNumberArray(initialValuesToCompare);

    return !isEqual(castedCurrent, castedInitial) && this.editMode();
  }

  private get createEditModalSaveParams(): Partial<
    ModalButton<CustomDriverCreateEditModalComponent>
  > {
    type InstanceType = CustomDriverCreateEditModalComponent | null;
    return {
      pendoTag: 'custom-curves-create-modal-confirm-button',
      disabled: (instance: InstanceType) => !!instance?.saveDisabled,
      action: (instance: InstanceType) => instance?.onSave(),
    };
  }
}
