import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TimelineBannerStatus } from '@pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-timeline-warning-banner',
  standalone: true,
  imports: [IconComponent, NgStyle, NgClass],
  templateUrl: './timeline-warning-banner.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineWarningBannerComponent implements OnChanges {
  router = inject(Router);
  private mainQuery = inject(MainQuery);
  @Input() timelineBannerStatus: TimelineBannerStatus = undefined;
  fp = '';
  sp = '';
  ngOnChanges(): void {
    if (this.timelineBannerStatus) {
      this.fp =
        this.timelineBannerStatus === 'warning'
          ? 'Your trial is near the end of the timeline. Please'
          : 'You reached the end of the trial timeline. Please';
      this.sp =
        this.timelineBannerStatus === 'warning'
          ? 'if needed.'
          : 'if trial is still in progress and/or you need to take actions for future months.';
    }
  }
  navigateToTimeline() {
    this.router.navigateByUrl(
      `${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}`
    );
  }
  hideBanner() {
    this.mainQuery.setBannerWarningVisibility(false, LocalStorageKey.TIMELINE_BANNER);
  }
}
