import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ChecklistNotesEditorComponent } from './checklist-notes-editor/checklist-notes-editor.component';
import { Notes } from './notes.enum';
import { BehaviorSubject } from 'rxjs';
import { Workflow } from '@shared/store/workflow/workflow.store';

@Component({
  selector: 'aux-checklist-notes',
  templateUrl: './checklist-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './checklist-notes.component.scss',
  standalone: true,
  imports: [ChecklistNotesEditorComponent],
})
export class ChecklistNotesComponent {
  @ViewChildren(ChecklistNotesEditorComponent) editors!: QueryList<ChecklistNotesEditorComponent>;

  @Input() checklistAdminPermission!: boolean;

  @Input() workflow$!: BehaviorSubject<Workflow | null>;

  readonly Notes = Notes;
}
