<form class="font-inter" [formGroup]="fg" (ngSubmit)="onUpload()">
  <aux-modal [primaryButtonType]="'submit'" [loading]="(loading$ | async) === true">
    <div class="grid grid-cols-2 gap-5 w-[520px]">
      <div class="flex flex-col justify-between">
        <aux-input
          class="tabular-nums"
          formControlName="po_number"
          label="PO #"
          placeholder="#########"
          validators="required"
        />
        <aux-input
          formControlName="po_amount"
          class="tabular-nums"
          label="PO Amount"
          placeholder="$"
          validators="required"
          class="mt-4"
          [type]="'number'"
        />
        <div class="mt-4">
          <div class="text-xs">Vendor</div>
          <ng-select
            placeholder="Select"
            id="vendors"
            class="select select__big"
            auxFormError
            [formControl]="selectedVendor"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
          >
            <ng-option *ngFor="let vendor of vendorQuery.allVendors$ | async" [value]="vendor.id">
              <span [title]="vendor.name">{{ vendor.name }}</span>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mb-1 text-xs">Upload Purchase Order</div>
        <aux-file-manager #fileManager class="h-48" [eager]="false" [pathFn]="pathFn" />
      </div>
      <div></div>
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="fileManager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        />
      </div>
    </div>
  </aux-modal>
</form>
