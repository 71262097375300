import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ApiService } from '@shared/services/api.service';
import { AppInitService } from '@shared/services/app-init.service';
import { AppService } from '@shared/services/app.service';

@Component({
  selector: 'aux-root',
  template: ` <router-outlet /> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private appInitService: AppInitService,
    private appService: AppService
  ) {
    this.changePx(14);
  }

  @HostListener('window:focus')
  sendFocusEvent() {
    this.appService.focusEvent$.next(null);
  }

  changePx(px: number) {
    const html = document.querySelector('html');
    if (html) {
      html.style.fontSize = `${px}px`;
    }
  }
}
