import { Component, EventEmitter, Input, OnInit, Output, viewChild } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { SitesService } from '@models/sites/sites.service';
import { SiteModel } from '@models/sites/sites.store';
import { Country, Currency } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Maybe, Utils } from '@shared/utils/utils';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { SitesQuery } from '@models/sites/sites.query';
import { BehaviorSubject, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isEqual } from 'lodash';
import { InputComponent } from '@shared/components/input/input.component';

interface SiteForm {
  name: Maybe<string>;
  site_no: Maybe<string>;
  target_patients: Maybe<number>;
  country: Maybe<string>;
  zip: Maybe<string>;
  city: Maybe<string>;
  state: Maybe<string>;
  address_line_1: Maybe<string>;
  address_line_2: Maybe<string>;
  address_line_3: Maybe<string>;
  given_name: Maybe<string>;
  family_name: Maybe<string>;
  managed_by_id: Maybe<string>;
  currency: Maybe<string>;
  closeout_date: Maybe<string>;
  site_activation: Maybe<string>;
  investigator_id: Maybe<string>;
  site_id: string | null;
}

@Component({
  selector: 'aux-site-information-edit-view',
  templateUrl: './site-information-edit-view.component.html',
})
export class SiteInformationEditViewComponent implements OnInit {
  @Input({ required: true }) site!: SiteModel & {
    investigator_name?: string;
  };

  @Input({ required: true }) editMode$!: BehaviorSubject<boolean>;

  readonly countryOptions = Utils.getCountriesForSelectOptions();

  readonly currencyOptions = Utils.CURRENCY_OPTIONS;

  readonly closeoutInput = viewChild<InputComponent>('closeoutInput');

  vendorOptions$ = this.vendorQuery
    .selectAll()
    .pipe(map((vendors) => vendors.map((vendor) => ({ label: vendor.name, value: vendor.id }))));

  initialFormState!: SiteForm;

  siteForm = this.fb.group<SiteForm>({
    name: null,
    site_no: null,
    target_patients: null,
    country: null,
    zip: null,
    city: null,
    state: null,
    address_line_1: null,
    address_line_2: null,
    address_line_3: null,
    given_name: null,
    family_name: null,
    managed_by_id: null,
    currency: null,
    closeout_date: null,
    site_activation: null,
    investigator_id: null,
    site_id: null,
  });

  @Output() formChanged = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private vendorQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery
  ) {
    this.siteForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((values) => {
      if (this.initialFormState) {
        this.formChanged.emit(
          !isEqual(this.nullifyEmptyStringValues(<SiteForm>values), this.initialFormState)
        );
      }
    });
  }

  get siteNoValidators(): ValidatorFn[] {
    const siteNumbersToForbid = this.sitesQuery
      .getAll()
      .map((site) => site.site_no)
      .filter((site) => site !== this.site.site_no);

    return [
      Validators.required,
      CustomValidators.valueNotInList(siteNumbersToForbid, 'duplicatedSite'),
    ];
  }

  ngOnInit(): void {
    this.setInitialValues();
  }

  async updateSite() {
    this.siteForm.markAllAsTouched();

    if (this.siteForm.invalid || this.closeoutInput()?.fc.invalid) {
      return;
    }

    const {
      name,
      site_no,
      closeout_date,
      site_activation,
      investigator_id,
      site_id,
      family_name,
      given_name,
      city,
      state,
      zip,
      country,
      managed_by_id,
      target_patients,
      currency,
      address_line_1,
      address_line_2,
      address_line_3,
    } = this.siteForm.getRawValue();

    const siteUpdateResponse = await this.sitesService.update(
      site_id as string,
      {
        id: site_id as string,
        name: name || '',
        site_no: site_no || '',
        city: city || '',
        state,
        zip,
        country: country as Country,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency: currency as Currency,
        address_line_1,
        address_line_2,
        address_line_3,
      },
      {
        id: investigator_id || '',
        family_name: family_name || '',
        given_name: given_name || '',
      },
      false,
      this.site.managed_by_id || undefined
    );

    if (siteUpdateResponse.success) {
      this.editMode$.next(false);
      this.formChanged.emit(false);
      this.overlayService.success(`${name} successfully updated!`);
    }
  }

  cancelChanges() {
    this.editMode$.next(false);
  }

  onBlurSiteActivationPicker(closeoutDate: InputComponent) {
    // mark as touched and trigger validation
    closeoutDate.fc.markAsTouched();
    closeoutDate.fc.markAsDirty();
    closeoutDate.onBlur();
  }

  nullifyEmptyStringValues(formValues: SiteForm) {
    return Object.entries(formValues).reduce((accum, [key, value]) => {
      return {
        ...accum,
        [key]: value === '' ? null : value,
      };
    }, {}) as SiteForm;
  }

  private setInitialValues() {
    this.siteForm.patchValue({
      site_id: this.site.id,
      investigator_id: this.site.investigator?.id || null,
      name: this.site.name || null,
      site_no: this.site.site_no || null,
      target_patients: this.site.target_patients || null,
      country: this.site.country || null,
      zip: this.site.zip || null,
      city: this.site.city || null,
      state: this.site.state || null,
      address_line_1: this.site.address_line_1 || null,
      address_line_2: this.site.address_line_2 || null,
      address_line_3: this.site.address_line_3 || null,
      given_name: this.site.investigator?.given_name || null,
      family_name: this.site.investigator?.family_name || null,
      managed_by_id: this.site.managed_by_id || null,
      currency: this.site.currency || null,
      closeout_date: this.site.closeout_date || null,
      site_activation: this.site.site_activation || null,
    });

    this.initialFormState = this.siteForm.getRawValue();

    this.formChanged.emit(false);
  }
}
