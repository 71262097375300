<form [formGroup]="fgNewCNF" (ngSubmit)="mode === 'create' ? onNewCNFSubmit() : onUpdateClick()">
  <aux-modal primaryButtonType="submit" [loading]="loading()">
    <div *ngIf="mode === 'create'">
      <ng-container *ngFor="let tab of tabs; index as i">
        <button
          *ngIf="tab.show | async"
          type="button"
          class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none mb-2"
          [ngClass]="{
            'border-aux-blue-light text-aux-blue-light': i === activeTabIndex,
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
              i !== activeTabIndex
          }"
          (click)="onTabChange(i)"
        >
          {{ tab.label }}
        </button>
      </ng-container>
    </div>

    <div *ngIf="activeTabIndex === 0">
      <div class="overflow-y-auto max-h-158">
        <div class="grid grid-cols-6 gap-5 ml-1 mr-1 max-w-[520px]">
          <aux-input
            label="CNF #"
            formControlName="cnf_no"
            class="col-span-2"
            placeholder="#"
            [validators]="getCnfValidators"
            [customErrorMessages]="customErrorMessages"
          />
          <aux-input
            label="Request Date"
            formControlName="request_date"
            class="col-span-2"
            placeholder="YYYY-DD-MM"
            [type]="'date'"
            [validators]="getDateValidator"
          />
          <aux-input
            label="Approved Date"
            formControlName="start_date"
            class="col-span-2"
            placeholder="YYYY-DD-MM"
            [type]="'date'"
            [validators]="getDateValidator"
          />
          <aux-input
            label="Description"
            formControlName="description"
            class="col-span-6"
            [textArea]="true"
            resize="none"
          />

          <aux-input
            label="Services"
            formControlName="service_fee"
            placeholder="$"
            class="col-span-2"
            [validators]="getMustNumericValidator"
          />

          <aux-input
            label="Pass-through"
            formControlName="passthrough_fee"
            placeholder="$"
            class="col-span-2"
            [validators]="getMustNumericValidator"
          />

          <aux-input
            label="Investigator"
            formControlName="investigator_fee"
            placeholder="$"
            class="col-span-2"
            [validators]="getMustNumericValidator"
          />

          <div class="aux-select col-span-2">
            <label for="vendor">Requester</label>
            <ng-select
              id="vendor2"
              class="select select__big"
              formControlName="requester"
              bindValue="id"
              bindLabel="requester"
              required
              auxFormError
              label="Requester"
              [clearable]="false"
              [searchable]="false"
            >
              <ng-option [value]="'REQUESTER_VENDOR'"> Vendor </ng-option>
              <ng-option [value]="'REQUESTER_SPONSOR'"> Sponsor </ng-option>
              <ng-option [value]="'REQUESTER_JOINT'"> Joint </ng-option>
            </ng-select>
          </div>

          <div class="aux-select col-span-2">
            <label for="change_order_no">Change Order #</label>
            <ng-select
              id="change_order_no"
              class="select select__big"
              formControlName="change_order_reference"
              bindValue="id"
              bindLabel="change_order_no"
              auxFormError
              label="Change Order No"
              [clearable]="true"
              [searchable]="false"
            >
              <ng-option *ngFor="let changeOrder of changeOrdersNumbers" [value]="changeOrder.id">
                <span [title]="changeOrder.change_order_no">{{ changeOrder.change_order_no }}</span>
              </ng-option>
            </ng-select>
          </div>

          <div class="aux-select col-span-2">
            <label for="change_log_status">Status</label>
            <ng-select
              id="change_log_status"
              class="select select__big"
              formControlName="change_log_item_status"
              bindValue="changeLogStatus"
              bindLabel="status"
              auxFormError
              label="Status"
              [clearable]="false"
              [searchable]="false"
            >
              <ng-option *ngFor="let status of this.statusConstant" [value]="status.value">
                <div class="flex items-center whitespace-nowrap">
                  <div class="w-3 h-3 rounded-full mr-1" [ngClass]="status.circleClass"></div>
                  <div [ngClass]="status.textClass">{{ status.text }}</div>
                </div>
              </ng-option>
            </ng-select>
          </div>

          <aux-input
            label="Cause"
            formControlName="cause"
            placeholder="Cause"
            class="col-span-4"
            [type]="'text'"
          />

          <div class="aux-select col-span-2">
            <label for="vendor">Type</label>
            <ng-select
              id="vendor"
              formControlName="is_planned"
              bindValue="id"
              bindLabel="is_planned"
              [clearable]="false"
              [searchable]="false"
              [appendTo]="'body'"
            >
              <ng-option [value]="true"> Planned </ng-option>
              <ng-option [value]="false"> Unplanned </ng-option>
            </ng-select>
          </div>

          <div class="aux-select col-span-6 h-18">
            <label for="file"> Upload CNF documents here</label>
            <div id="file">
              <aux-file-manager
                #manager
                class="h-24"
                [pathFn]="getFilePathForCisItems()"
                [metadataFn]="getMetadata()"
                [eager]="false"
                [showSuccessOnUpload]="true"
              />
              <div class="h-18 overflow-auto mt-2">
                <aux-file-viewer
                  [fileManager]="manager"
                  [disableFirstFileMargin]="true"
                  [onlyShowUploaded]="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aux-modal>
</form>
