import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-change-order-approved-dialog',
  templateUrl: './change-order-approved-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class ChangeOrderApprovedDialogComponent {
  private router = inject(Router);

  ref = inject(CustomOverlayRef<unknown, { vendor_id?: string }>);

  co_organization_id: string | undefined;

  forecastMethadologyRoute = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`;

  timelineRoute = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}`;

  adjustmentsRoute = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`;

  constructor() {
    if (this.ref.data && this.ref.data.vendor_id) {
      this.co_organization_id = this.ref.data?.vendor_id;
    }
  }

  goFM() {
    if (this.co_organization_id) {
      localStorage.setItem(LocalStorageKey.FORECAST_VENDOR, this.co_organization_id);
    }
    this.router.navigateByUrl(this.forecastMethadologyRoute);
    this.ref.close();
  }

  goTimeline() {
    this.router.navigateByUrl(this.timelineRoute);
    this.ref.close();
  }

  goAdjustments() {
    this.router.navigate([this.adjustmentsRoute], {
      queryParams: { vendorId: this.co_organization_id },
    });
    this.ref.close();
  }
}
