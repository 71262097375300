import { Component, ChangeDetectionStrategy, inject, ChangeDetectorRef } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { AgSetColumnsVisible } from '@shared/utils';
import { ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';
import { NgClass } from '@angular/common';

export interface TotalDistributionComponentParams extends IHeaderParams {
  columnsToCollapse: string[];
  ignoreIfInvisible: string[];
  localStorageKey: string;
  collapsedByDefault: boolean;
  afterToggle?: () => void;
}

@Component({
  template: `
    <div class="flex items-center justify-center space-x-1">
      <div [ngClass]="templateClasses" [innerHTML]="params.template"></div>

      @if (params.columnsToCollapse.length) {
        <aux-icon
          class="cursor-pointer ml-1 shrink-0"
          (click)="onToggle()"
          [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
        />
      }
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, NgClass, ReactiveFormsModule],
})
export class TotalDistributionHeaderComponent implements IHeaderAngularComp {
  params!: TotalDistributionComponentParams;

  visible = true;

  templateClasses = ['text-aux-black', 'text-base', 'whitespace-normal', 'text-center'];

  readonly changeDetectorRef = inject(ChangeDetectorRef);

  agInit(params: TotalDistributionComponentParams): void {
    this.params = params;
    setTimeout(() => {
      this.initChevron();
      AgSetColumnsVisible({
        gridApi: this.params.api,
        keys: this.params.columnsToCollapse,
        visible: this.visible,
      });
      params.api.sizeColumnsToFit();
      this.changeDetectorRef.detectChanges();
    });
  }

  onToggle(): void {
    this.toggleColumnsVisibility();

    if (this.params.afterToggle) {
      this.params.afterToggle();
    }
  }

  refresh(): boolean {
    return false;
  }

  private initChevron(): void {
    const checkIsDependentVisible = this.params.ignoreIfInvisible.every((key) =>
      this.params.api.getColumn(key)?.isVisible()
    );
    switch (localStorage.getItem(this.params.localStorageKey)) {
      case 'true':
        this.visible = checkIsDependentVisible;
        return;
      case 'false':
        this.visible = false;
        return;
      default:
        this.visible = !this.params.collapsedByDefault && checkIsDependentVisible;
    }
  }

  private toggleColumnsVisibility(): void {
    this.visible = !this.visible;
    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: this.params.columnsToCollapse,
      visible: this.visible,
    });
    if (this.params.localStorageKey) {
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());
    }

    this.params.api.sizeColumnsToFit();
  }
}
