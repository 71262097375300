import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Currency } from '@shared/services/gql.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ForecastQuery } from '../../state/forecast/forecast.query';
import { ForecastService } from '../../state/forecast/forecast.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-forecast-analytics-section',
  templateUrl: './forecast-analytics-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastAnalyticsSectionComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  analyticsSectionLoading$ = new BehaviorSubject(false);

  orgCurrency = Currency.USD;

  constructor(
    public forecastQuery: ForecastQuery,
    private forecastService: ForecastService,
    private organizationQuery: OrganizationQuery
  ) {
    this.orgCurrency = this.organizationQuery.getActive()?.currency || Currency.USD;
  }

  ngOnInit(): void {
    this.initAnalyticsSection();
  }

  private initAnalyticsSection(): void {
    this.analyticsSectionLoading$.next(true);
    combineLatest([
      this.forecastService.getActivitiesByDriver(),
      this.forecastService.getUnforecastedCosts(),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.analyticsSectionLoading$.next(false));
  }
}
