import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  ForecastTableGridDataInterface,
  ForecastTableGridParentComponentContext,
} from '../../models/forecast-table-grid.model';
import { ForecastTableGridComponent } from '../../forecast-table-grid.component';

@Component({
  selector: 'aux-forecast-table-grid-category',
  templateUrl: './forecast-table-grid-category.component.html',
  styleUrls: ['./forecast-table-grid-category.component.scss'],
})
export class ForecastTableGridCategoryComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  params!: ICellRendererParams;

  context!: ForecastTableGridParentComponentContext;

  componentParent!: ForecastTableGridComponent;

  data!: ForecastTableGridDataInterface;

  disableCategory = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.componentParent = this.context.componentParent;
    this.data = this.params.data;
    if (!this.componentParent.userHasModifyPermissions) {
      this.disableCategory = true;
    }
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }
}
