import { listPrepaidsQuery } from '@shared/services/gql.service';

export enum PrepaidTypeLabel {
  PREPAID_TYPE_AMORTIZE = 'Amortize',
  PREPAID_TYPE_INCREASE = 'Increase',
  PREPAID_TYPE_RECLASS = 'Reclass',
}

export type AdjustmentsToPrepaidErrorRow = {
  causes: (
    | 'prepaid_type'
    | 'total_impact_to_prepaid_increase'
    | 'total_impact_to_prepaid_amortize'
    | 'total_impact_to_prepaid_reclass'
    | 'short_term_increase'
    | 'short_term_amortize'
    | 'long_term_increase'
    | 'long_term_amortize'
    | 'cost_category_amortize_increase'
    | 'sum_cost_category_not_equal_total_impact'
    | 'accrual_period_closed_month'
    | 'payment_date_closed_month'
    | 'amortize_services_amount_positive'
    | 'amortize_passthrough_amount_positive'
    | 'amortize_investigator_amount_positive'
    | 'increase_services_amount_negative'
    | 'increase_passthrough_amount_negative'
    | 'increase_investigator_amount_negative'
    | 'increase_no_invoice'
  )[];
  adjustment: AdjustmentsToPrepaidRow;
};

export interface AdjustmentsToPrepaidRow extends listPrepaidsQuery {
  total_impact_to_prepaid: number;
  isNewAdjustment: boolean;
}

export interface AdjustmentsToPrepaidTotals {
  total_impact_to_prepaid: number;
  short_term_amount: number;
  long_term_amount: number;
  services_amount: number;
  passthrough_amount: number;
  investigator_amount: number;
  invoice_total: number;
  total_expense_per_invoice: number;
}
