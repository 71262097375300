import { MainQuery } from '@shared/store/main/main.query';
import { FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  CellValueChangedEvent,
  DomLayoutType,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ProcessCellForExportParams,
  RowDragEndEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { isEqual, partition } from 'lodash-es';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { EventService } from '@models/event/event.service';
import {
  CheckPatientProtocolsUniquenessInput,
  CreatePatientProtocolInput,
  EntityType,
  EventType,
  GqlService,
  PatientGroupType,
  PatientProtocolFrequency,
  PatientProtocolSubType,
  PatientProtocolType,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Maybe, RequireSome, Utils } from '@shared/utils/utils';
import { OverlayService } from '@shared/services/overlay.service';
import { PatientProtocolStore } from '@models/patient-protocol/patient-protocol.store';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { TableConstants } from '@shared/constants/table.constants';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { PatientGroupsQuery } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { ButtonToggleItem } from '@shared/components/button-toggle-group/button-toggle-item.model';
import { ProtocolForm } from './components/protocol-section/protocol-section.component';
import { ProtocolVersionModalComponent } from './components/protocol-version-modal/protocol-version-modal.component';
import { Option } from '@shared/types/components.type';
import { EditableListDropdownItem } from '@shared/components/editable-list-dropdown/editable-list-dropdown-item.model';
import { AgSetColumnsVisible } from '@shared/utils';
import { AuthService } from '@shared/store/auth/auth.service';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { AgActionsComponent } from '@shared/ag-components/ag-actions/ag-actions.component';
import { agParseValueFromClipboard } from '@shared/utils/ag-copy-paste.utils';
import { agNumericOnlyValueSetter } from '@shared/utils/ag-value-setter.utils';
import { AgRequiredHeaderComponent } from '@shared/ag-components/ag-required-header/ag-required-header.component';
import { PATIENT_PROTOCOL_VALIDATION_MESSAGE } from './constants/patient-protocol-validation-messages.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AgCheckboxRendererComponent } from '@shared/ag-components/ag-checkbox-rerender/ag-checkbox-renderer.component';

interface PatientProtocolGridData {
  target_tolerance_days_out?: number | undefined;
  target_date_days_out?: number | null | undefined;
  patient_protocol_name?: string | undefined;
  patient_group_id?: string | undefined;
  patient_protocol_id: string;
  patient_protocol_type?: PatientProtocolType | undefined;
  patient_protocol_frequency?: PatientProtocolFrequency | null | undefined;
  patient_protocol_sub_type?: PatientProtocolSubType | null | undefined;
  patient_protocol_sub_type_id?: string | null | undefined;
  order_by: number;
  optional?: boolean;
}

enum PatientProtocolForVisits {
  PATIENT_PROTOCOL_PATIENT_VISIT = 'PATIENT_PROTOCOL_PATIENT_VISIT',
}

enum PatientProtocolForOthers {
  PATIENT_PROTOCOL_DISCONTINUED = 'PATIENT_PROTOCOL_DISCONTINUED',
  PATIENT_PROTOCOL_OTHER = 'PATIENT_PROTOCOL_OTHER',
  PATIENT_PROTOCOL_OVERHEAD = 'PATIENT_PROTOCOL_OVERHEAD',
  PATIENT_PROTOCOL_SCREEN_FAIL = 'PATIENT_PROTOCOL_SCREEN_FAIL',
}

@Component({
  selector: 'aux-patient-protocol-edit',
  templateUrl: './patient-protocol-edit.component.html',
  styleUrls: ['./patient-protocol-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProtocolEditComponent {
  private readonly destroyRef = inject(DestroyRef);

  eventQuery = inject(EventQuery);

  readonly maxVisibleRows = 14;

  patientOptions: ButtonToggleItem[] = [];

  protocolVersionOptions: (Option & { effective_date?: Maybe<string> })[] = [];

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  userHasLockInvestigatorDataPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
  });

  protocolForm!: FormGroup;

  isProtocolVersionLoading$ = new BehaviorSubject(true);

  isProtocolSubTypesLoading$ = new BehaviorSubject(true);

  totalItems = signal(0);

  isInvoiceables = signal(false);

  gridAnimation = false;

  mapFrequencyTitleByType = new Map<PatientProtocolFrequency, string>([
    [PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_MONTHLY, 'Monthly'],
    [PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_ANNUALLY, 'Annually'],
    [PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_ACTIVATION, 'Activation'],
    [PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_CLOSEOUT, 'Closeout'],
    [PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_QUARTERLY, 'Quarterly'],
  ]);

  tableHeightInEditMode = computed(() => {
    if (!this.totalItems()) {
      return '200px';
    }

    if (this.totalItems() > this.maxVisibleRows) {
      return '500px';
    }

    const rowHeight = TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS.rowHeight;
    const additionalGridHeight = 10;

    const tableHeight =
      rowHeight * this.totalItems() + TableConstants.HEADER_HEIGHT + additionalGridHeight;

    return tableHeight + 'px';
  });

  userHasUpdateProtocolEntryPermission = signal(false);

  protocolSubTypes: PatientProtocolSubType[] = [];

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientTrackerLockedTooltip = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  noPermissionTooltip = computed(() =>
    !this.userHasUpdateProtocolEntryPermission()
      ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
      : ''
  );

  private readonly frequencyOptions = [null, ...Object.keys(PatientProtocolFrequency)];

  deleteButtonDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressCellFocus: false,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    enableRangeSelection: true,
    suppressMenuHide: true,
    rowDragManaged: true,
    getRowId: ({ data }) => data?.patient_protocol_id || data?.randomID,
    processCellForClipboard: (params: ProcessCellForExportParams) => {
      return this.subTypeCopyForClipboard(params);
    },
    processCellFromClipboard: (params: ProcessCellForExportParams) => {
      return this.subTypeCopyFromClipboard(params);
    },
    columnDefs: [
      {
        headerName: 'patient_protocol_id',
        field: 'patient_protocol_id',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRenderer: AgActionsComponent,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.deleteButtonDisabled$.next(true);
            if (rowNode.data.patient_protocol_id) {
              this.removedRows.push(rowNode.data.patient_protocol_id);
            } else if (this.addedRowCounter === 1) {
              this.reorderedRows.clear();
              this.removedRows = [];
              this.newRowAdded = false;
            } else {
              this.removedRows.pop();
            }
            this.addedRowCounter -= 1;
            this.totalItems.update((val) => val - 1);
            this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            this.checkChanges();
            // To avoid double-clicking the delete button, disabled this for a while
            setTimeout(() => this.deleteButtonDisabled$.next(false), 300);
          },
          deleteButtonDisabled$: this.deleteButtonDisabled$,
        },
        rowDrag: true,
        hide: true,
      },
      {
        headerName: 'RANDOM ID',
        field: 'randomID',
        hide: true,
      },
      {
        headerName: 'Name',
        headerComponent: AgRequiredHeaderComponent,
        field: 'patient_protocol_name',
        resizable: true,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) => {
            return (
              params.data.errors.missingNameError ||
              params.data.errors.duplicateNameError ||
              params.data.errors.requiredName
            );
          },
        },
        tooltipValueGetter: Utils.getDuplicateNameCellTooltip,
        valueSetter: (params: ValueSetterParams) => {
          params.data.patient_protocol_name = params?.newValue?.toString() || '';

          if (params.data.patient_protocol_name) {
            params.data.errors.requiredName = false;
          }

          return true;
        },
      },
      {
        headerName: 'Visit Day',
        field: 'target_date_days_out',
        minWidth: 150,
        valueFormatter: Utils.preserveZeroDashFormatter,
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        valueParser: (params) => {
          return params.newValue === '' ? null : params.newValue;
        },
        valueSetter: (params) =>
          agNumericOnlyValueSetter(
            params,
            this.overlayService,
            'Visit Day must be an integer number.'
          ),
      },
      {
        headerName: 'Visit Window [+/-]',
        field: 'target_tolerance_days_out',
        minWidth: 150,
        valueFormatter: Utils.dashFormatter,
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        cellDataType: 'text',
        valueSetter: (params) =>
          agNumericOnlyValueSetter(
            params,
            this.overlayService,
            'Visit Window [+/-] must be an integer number.'
          ),
      },
      {
        headerName: 'Optional',
        field: 'optional',
        colId: 'optional',
        width: 105,
        suppressSizeToFit: true,
        cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
        suppressFillHandle: true,
        cellRenderer: AgCheckboxRendererComponent,
        cellRendererParams: () => ({
          getDisabledState: () => !this.editModeGrid$.value,
          hideLabel: true,
          dontSelectRow: true,
        }),
        editable: false,
      },
      {
        headerName: 'patient_group_id',
        field: 'patient_group_id',
        hide: true,
      },
      {
        field: 'patient_protocol_type',
        hide: true,
        cellEditor: 'agRichSelectCellEditor',
        minWidth: 300,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) => params.data.errors.missingTypeError,
        },
        cellEditorParams: () => {
          return {
            values: Object.keys(
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolForVisits
                : PatientProtocolForOthers
            ),
          };
        },
      },
      {
        headerName: 'Type',
        headerComponent: AgRequiredHeaderComponent,
        field: 'patient_protocol_sub_type',
        minWidth: 300,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) =>
            params.data.errors.missingTypeError || params.data.errors.requiredType,
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: () => {
          return {
            values: this.subTypeCellEditorParams(),
          };
        },
        valueGetter: (params: ValueGetterParams) => {
          return this.subTypeGetter(params);
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return this.subTypeFormatter(params);
        },
        valueSetter: (params: ValueSetterParams) => {
          const valueFromDropdown = this.subTypeCellEditorParams().find(
            (val) =>
              val.patient_protocol_type === params.newValue ||
              val.name === params.newValue || // when drag event value serialized as label dropdown
              val.name === params.newValue?.name // when select from dropdown or copy paste
          );

          if (params.newValue !== '' && params.newValue !== null) {
            if (!valueFromDropdown) {
              this.overlayService.error(PATIENT_PROTOCOL_VALIDATION_MESSAGE.TYPE);

              return false;
            }
          }

          return this.subTypeSetter(params, valueFromDropdown);
        },
      },
      {
        headerName: 'Frequency',
        field: 'patient_protocol_frequency',
        cellEditor: 'agRichSelectCellEditor',
        minWidth: 300,
        cellClass: 'text-left',
        cellEditorParams: () => {
          return {
            values: this.frequencyOptions,
          };
        },
        valueFormatter: (node) => this.mapFrequencyTitleByType.get(node.value) || null,
        valueSetter: (params: ValueSetterParams) => {
          const [valueFromDragEvent] =
            [...this.mapFrequencyTitleByType.entries()].find(
              ([, label]) => label === params.newValue
            ) || [];

          const valueFromDropdown = this.frequencyOptions.find((val) => val === params.newValue);

          if (params.newValue !== '' && params.newValue !== null) {
            if (!valueFromDragEvent && !valueFromDropdown) {
              this.overlayService.error(PATIENT_PROTOCOL_VALIDATION_MESSAGE.FREQUENCY);

              return false;
            }
          }

          params.data.patient_protocol_frequency = valueFromDragEvent || valueFromDropdown;

          return true;
        },
      },
    ],
  } as GridOptions);

  loading$ = this.patientProtocolQuery.selectLoading();

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridData$: Observable<PatientProtocolGridData[]> = this.patientProtocolQuery.selectAll().pipe(
    map((value) => {
      return value.map(
        ({
          id,
          name,
          optional,
          patient_protocol_type,
          patient_protocol_sub_type,
          patient_protocol_frequency,
          target_tolerance_days_out,
          target_date_days_out,
          order_by,
        }) => ({
          patient_protocol_id: id,
          patient_protocol_name: name,
          patient_protocol_type,
          patient_protocol_sub_type,
          patient_protocol_frequency,
          target_tolerance_days_out,
          target_date_days_out,
          optional,
          order_by,
          errors: {},
        })
      );
    })
  );

  editedRows = new Set<string>();

  removedRows: string[] = [];

  reorderQueue: { id: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  addedRowCounter = 0;

  newRowAdded = false;

  initialValues: PatientProtocolGridData[] = [];

  hasChanges = false;

  editModeGrid$ = new BehaviorSubject(false);

  patientGroupId$ = new BehaviorSubject<string | null>(null);

  isVisit$ = new BehaviorSubject(false);

  defaultPatientGroup = [
    {
      value: 'visitCosts',
      label: 'Visit Costs',
      show: this.launchDarklyService.select$((flags) => flags.visit_costs),
    },
    {
      value: 'invoiceables',
      label: 'Invoiceables',
    },
  ];

  domLayout: DomLayoutType = 'autoHeight';

  constructor(
    private patientProtocolService: PatientProtocolService,
    private patientProtocolQuery: PatientProtocolQuery,
    private patientProtocolStore: PatientProtocolStore,
    private patientGroupService: PatientGroupsService,
    private patientGroupQuery: PatientGroupsQuery,
    private overlayService: OverlayService,
    private eventService: EventService,
    private gqlService: GqlService,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery
  ) {
    this.setUserPermissions();
    this.launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(takeUntilDestroyed())
      .subscribe((showVisit) => this.isVisit$.next(showVisit));

    this.mainQuery
      .select('trialKey')
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => {
          this.isProtocolVersionLoading$.next(true);

          return this.patientProtocolService.getPatientProtocolVersions();
        })
      )
      .subscribe((versions) => {
        this.isProtocolVersionLoading$.next(false);
        this.protocolVersionOptions = versions.map(({ id, name, effective_date }) => ({
          label: name,
          value: id,
          effective_date,
        }));
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => {
          this.isProtocolSubTypesLoading$.next(true);

          return this.patientProtocolService.getPatientProtocolSubTypes();
        })
      )
      .subscribe((subTypes) => {
        this.isProtocolSubTypesLoading$.next(false);
        this.protocolSubTypes = subTypes;
      });

    this.patientGroupService
      .get([PatientGroupType.PATIENT_GROUP_STANDARD])
      .pipe(
        takeUntilDestroyed(),
        switchMap(({ data: patientGroupList }) => {
          if (!patientGroupList) {
            return of([]);
          }

          const patientGroups = patientGroupList.map((patientGroup) => {
            return { value: patientGroup.id, label: patientGroup.name };
          });

          this.patientOptions = [];

          return patientGroups.length > 0
            ? this.patientProtocolService.get(
                [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
                patientGroups[0].value
              )
            : this.patientProtocolService.get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT]);
        }),
        tap(() => {
          const patientGroups = this.patientGroupQuery.getAll().map((patientGroup) => {
            return { value: patientGroup.id, label: patientGroup.name };
          });

          if (patientGroups.length > 0) {
            this.patientGroupId$.next(patientGroups[0].value);
            this.patientOptions.unshift(...patientGroups, ...this.defaultPatientGroup);
          } else {
            this.patientOptions = this.patientOptions.concat(this.defaultPatientGroup);
          }

          this.setDefaultProtocolForm();
        })
      )
      .subscribe();

    combineLatest([this.isProtocolVersionLoading$, this.patientGroupQuery.selectAll()])
      .pipe(takeUntilDestroyed())
      .subscribe(([isProtocolVersionLoading, patientGroupList]) => {
        if (!isProtocolVersionLoading && patientGroupList.length) {
          this.setDefaultProtocolForm();
        }
      });

    this.gridData$.pipe(takeUntilDestroyed()).subscribe((gridData) => {
      this.initialValues = gridData;
      this.totalItems.set(gridData.length);
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  checkChanges() {
    const currentValues: PatientProtocolGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        target_date_days_out: data.target_date_days_out === '' ? null : data.target_date_days_out,
        target_tolerance_days_out:
          data.target_tolerance_days_out === '' ? 0 : data.target_tolerance_days_out,
      });
    });

    this.hasChanges = !isEqual(this.initialValues, currentValues);
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['optional'],
      visible: !this.isInvoiceables(),
    });
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['target_date_days_out', 'target_tolerance_days_out'],
      visible: !!this.patientGroupId$.getValue() || this.isVisit$.getValue(),
    });
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['patient_protocol_frequency'],
      visible: this.protocolForm?.controls?.patientGroup?.value === 'invoiceables',
    });
    api.sizeColumnsToFit();
  }

  onAddPatientProtocol() {
    const patientGroupId = this.patientGroupId$.getValue();
    let ss;

    const randomID = Utils.uuid();

    if (patientGroupId) {
      ss = this.gridAPI.applyTransaction({
        add: [
          {
            randomID,
            patient_protocol_type:
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT
                : null,
            patient_group_id: patientGroupId || null,
            errors: {},
          },
        ],
      });
    } else {
      ss = this.gridAPI.applyTransaction({
        add: [{ randomID, errors: {} }],
      });
    }

    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'patient_protocol_name',
      });
    }
    this.addedRowCounter += 1;
    this.totalItems.update((val) => val + 1);
    this.newRowAdded = true;
    if (this.totalItems() > this.maxVisibleRows) {
      this.sizeColumnsToFit();
    }
    this.checkChanges();
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.patient_protocol_id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        id: event.node.data.patient_protocol_id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.patient_protocol_id);
      this.checkChanges();
    }
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.patient_protocol_id) {
      this.editedRows.add(event.data.patient_protocol_id);
      this.checkChanges();
    }

    this.clearErrors();
  }

  getDuplicateNames() {
    const names: string[] = [];
    const duplicates: string[] = [];

    this.gridAPI.forEachNode((node) => {
      const name = node.data.patient_protocol_name;

      if (names.includes(name)) {
        duplicates.push(name);
      } else {
        names.push(name);
      }
    });

    return duplicates;
  }

  clearErrors() {
    const duplicateNames = this.getDuplicateNames();

    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_name, patient_protocol_sub_type } =
        node.data as PatientProtocolGridData;

      if (patient_protocol_sub_type) {
        node.data.errors.missingTypeError = false;
      }

      if (patient_protocol_name) {
        node.data.errors.missingNameError = false;

        if (!duplicateNames.includes(patient_protocol_name)) {
          node.data.errors.duplicateNameError = false;
        }
      }
    });

    this.gridAPI.redrawRows();
  }

  showRequiredErrors(): boolean {
    const invalidRows: IRowNode[] = [];
    const errors = { requiredType: false, requiredName: false };

    const fieldNames: { [key: string]: string } = {
      requiredType: 'Type',
      requiredName: 'Name',
    };

    this.gridAPI.forEachNode((node) => {
      if (!node.data.patient_protocol_sub_type) {
        node.data.errors.requiredType = true;
        errors.requiredType = true;

        invalidRows.push(node);
      }

      if (!node.data.patient_protocol_name) {
        node.data.errors.requiredName = true;
        errors.requiredName = true;
        invalidRows.push(node);
      }
    });

    this.gridAPI.applyTransactionAsync({
      update: invalidRows,
    });

    this.gridAPI.clearRangeSelection();
    this.gridAPI.redrawRows({
      rowNodes: invalidRows,
    });

    const requiredFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key]);

    const isOrAre = requiredFields.length > 1 ? 'are' : 'is';

    const errorMessage =
      requiredFields.length > 0 ? `${requiredFields.join(', ')} ${isOrAre} required.` : '';

    if (errorMessage) {
      this.overlayService.error(errorMessage);
    }

    return Object.values(errors).some(Boolean);
  }

  async showDuplicateErrors() {
    const duplicateNames = this.getDuplicateNames();

    this.clearErrors();
    let patient_group_id = this.protocolForm.controls.patientGroup.value;
    let patient_protocol_type = PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT;
    if (patient_group_id === 'invoiceables') {
      // Using PATIENT_PROTOCOL_OTHER as a stand-in for "not-PATIENT_VISIT" since fn_patient_protocol_is_unique only cares whether the type is PATIENT_VISIT or not
      patient_protocol_type = PatientProtocolType.PATIENT_PROTOCOL_OTHER;
      patient_group_id = null;
    } else if (patient_group_id === 'visitCosts') {
      patient_group_id = null;
    }

    let isThereAnyInvalidRow = false;

    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_name, patient_protocol_sub_type } =
        node.data as PatientProtocolGridData;

      if (!patient_protocol_sub_type) {
        isThereAnyInvalidRow = true;
      }

      if (!patient_protocol_name) {
        node.data.errors.missingNameError = true;
        isThereAnyInvalidRow = true;
      } else if (duplicateNames.includes(patient_protocol_name)) {
        node.data.errors.duplicateNameError = true;
        isThereAnyInvalidRow = true;
      }
    });

    const allProtocolsUnique = await this.checkPatientProtocolsUniqueness(
      patient_group_id,
      patient_protocol_type
    );

    if (!allProtocolsUnique) {
      isThereAnyInvalidRow = true;
    }

    this.gridAPI.clearRangeSelection();
    this.gridAPI.redrawRows();
    return isThereAnyInvalidRow;
  }

  private async checkPatientProtocolsUniqueness(
    patient_group_id: string | null,
    patient_protocol_type: PatientProtocolType
  ): Promise<boolean> {
    let allProtocolsUnique = true;
    const protocolsToCheck: Array<CheckPatientProtocolsUniquenessInput> = [];

    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_id, patient_protocol_name, randomID } = node.data;

      if (!patient_protocol_id || this.editedRows.has(patient_protocol_id)) {
        protocolsToCheck.push({
          patient_protocol_id,
          temporary_id: !patient_protocol_id ? randomID : null,
          patient_protocol_name: patient_protocol_name || '',
          patient_group_id: patient_group_id || null,
          patient_protocol_type,
          patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
        });
      }
    });

    if (protocolsToCheck.length) {
      const uniquenessCheckResponse =
        await this.patientProtocolService.checkPatientProtocolsUniqueness(protocolsToCheck);

      this.gridAPI.forEachNode((node) => {
        const { patient_protocol_id, randomID } = node.data;

        const validationResult = patient_protocol_id
          ? uniquenessCheckResponse.find((item) => item.patient_protocol_id === patient_protocol_id)
          : uniquenessCheckResponse.find((item) => item.temporary_id === randomID);

        if (validationResult && !validationResult.is_unique) {
          node.data.errors.duplicateNameError = true;
          allProtocolsUnique = false;
        }
      });
    }

    return allProtocolsUnique;
  }

  onSaveAll = async () => {
    const hasRequiredErrors = this.showRequiredErrors();

    if (hasRequiredErrors) {
      return;
    }

    const isInvalid = await this.showDuplicateErrors();

    if (isInvalid) {
      this.overlayService.error('Name must be unique.');
    }

    if (!isInvalid) {
      this.patientProtocolStore.setLoading(true);

      const upsertData: (CreatePatientProtocolInput & {
        patient_protocol_id: string | null;
      })[] = [];

      const reorderData: {
        patient_protocol_id: string;
        order_by: number;
      }[] = [];

      if (
        this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
        !this.removedRows.length
      ) {
        this.reorderQueue.forEach(({ new_index, id }) => {
          const pp = this.patientProtocolQuery.getEntity(id);
          if (pp) {
            const {
              optional,
              patient_protocol_frequency,
              patient_protocol_sub_type,
              name,
              patient_group_id,
              target_date_days_out,
              target_tolerance_days_out,
            } = pp;

            // Extract subtype id
            const patient_protocol_sub_type_id =
              this.subTypeIdFromGridData(patient_protocol_sub_type);

            upsertData.push({
              optional: !!optional,
              patient_protocol_id: id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              patient_group_id,
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              target_tolerance_days_out,
              order_by: new_index,
            });
          }
        });
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            optional,
            patient_protocol_id,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'target_date_days_out'
            | 'patient_protocol_type'
            | 'patient_protocol_frequency'
            | 'patient_protocol_name'
            | 'patient_group_id'
          >;

          // Extract subtype id
          const patient_protocol_sub_type_id =
            this.subTypeIdFromGridData(patient_protocol_sub_type);

          if (!patient_protocol_id || this.editedRows.has(patient_protocol_id)) {
            upsertData.push({
              optional: !!optional,
              patient_protocol_id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              name: patient_protocol_name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              patient_group_id,
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      } else {
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            optional,
            patient_protocol_id,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'patient_protocol_type'
            | 'patient_protocol_frequency'
            | 'patient_protocol_name'
            | 'patient_group_id'
            | 'target_date_days_out'
          >;

          // Extract subtype id
          const patient_protocol_sub_type_id =
            this.subTypeIdFromGridData(patient_protocol_sub_type);

          if (
            this.reorderQueue.length ||
            !patient_protocol_id ||
            this.editedRows.has(patient_protocol_id) ||
            this.removedRows.length
          ) {
            upsertData.push({
              optional: !!optional,
              patient_protocol_id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              patient_group_id,
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              name: patient_protocol_name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      }

      let hasError = true;

      if (upsertData.length) {
        for (const { patient_protocol_id, order_by } of reorderData) {
          this.patientProtocolStore.update(patient_protocol_id, { order_by });
        }
        hasError = await this.patientProtocolService.upsert(upsertData);
      }

      if (this.removedRows.length) {
        hasError = await this.patientProtocolService.remove(this.removedRows);
      }

      await firstValueFrom(
        this.eventService.processEvent$({
          type: EventType.UPDATE_INVESTIGATOR_SCHEDULES,
          entity_type: EntityType.SITE,
          entity_id: '',
        })
      );

      if (!hasError) {
        this.overlayService.success();
      }

      this.cancelEditMode();
      this.patientProtocolStore.setLoading(false);
    }
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid = (): void => {
    this.gridAnimation = true;
    this.domLayout = 'normal';
    this.editModeGrid$.next(true);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: true,
    });
    this.sizeColumnsToFit();
    this.gridAPI.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'patient_protocol_name',
    });
    setTimeout(() => {
      // to avoid flickering, we hide grid fake scroll elements while drawing the table resize animation in Edit mode
      this.gridAnimation = false;
      this.cdr.detectChanges();
    }, 100);
  };

  cancelEditMode = (): void => {
    this.domLayout = 'autoHeight';
    this.gridAPI.stopEditing();
    this.editModeGrid$.next(false);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: false,
    });
    this.gridData$ = this.patientProtocolQuery.selectAll().pipe(
      map((value) => {
        return value.map(
          ({
            id,
            name,
            optional,
            patient_protocol_type,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
          }) => ({
            optional,
            patient_protocol_id: id,
            patient_protocol_name: name,
            patient_protocol_type,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
            errors: {},
          })
        );
      })
    );
    this.totalItems.set(this.initialValues.length);
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
    setTimeout(() => this.sizeColumnsToFit(), 0);
  };

  private resetChangeIndicators(): void {
    this.reorderedRows.clear();
    this.editedRows.clear();
    this.removedRows = [];
    this.reorderQueue = [];
    this.newRowAdded = false;
    this.addedRowCounter = 0;
    this.hasChanges = false;
  }

  onProtocolFormReady(form: FormGroup) {
    this.protocolForm = form;

    this.setDefaultProtocolForm();
  }

  private setDefaultProtocolForm() {
    setTimeout(() => {
      this.protocolForm?.setValue({
        patientGroup: this.patientOptions[0]?.value || null,
        protocolVersion: this.protocolVersionOptions[0]?.value || null,
      });
    }, 0);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_PROTOCOL_ENTRY],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasUpdateProtocolEntryPermission]) => {
        this.userHasUpdateProtocolEntryPermission.set(userHasUpdateProtocolEntryPermission);
      });
  }

  async onChangePatientGroup({ patientGroup, protocolVersion }: ProtocolForm) {
    this.isInvoiceables.set(patientGroup === 'invoiceables');

    if (!patientGroup || !protocolVersion) {
      return;
    }

    const gData = this.patientGroupQuery.getEntity(patientGroup);

    const protocol_version = protocolVersion || '';

    if (gData) {
      this.patientGroupId$.next(gData.id);

      this.patientProtocolService
        .get(
          [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
          gData.id,
          false,
          protocol_version
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    } else {
      this.patientGroupId$.next(null);

      if (patientGroup === 'visitCosts') {
        this.isVisit$.next(true);

        this.patientProtocolService
          .get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT], '', false, protocol_version)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe();
      } else if (patientGroup === 'invoiceables') {
        this.isVisit$.next(false);

        this.patientProtocolService
          .get(
            [
              PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
              PatientProtocolType.PATIENT_PROTOCOL_OTHER,
              PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
              PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
            ],
            '',
            false,
            protocol_version
          )
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe();
      }
    }

    if (this.editModeGrid$.getValue()) {
      this.cancelEditMode();
    }
  }

  createProtocol = async (isAmendment?: boolean) => {
    const response = await firstValueFrom(
      this.overlayService.openPopup<
        {
          modalView: string;
          protocolVersionOptions: (Option & { effective_date?: Maybe<string> })[];
        },
        { version: Option },
        ProtocolVersionModalComponent
      >({
        content: ProtocolVersionModalComponent,
        settings: {
          header: isAmendment ? 'Create Protocol Amendment' : 'Create Protocol',
          primaryButton: {
            label: 'Create',
            action: (instance) => instance?.onSave(),
            disabled: (instance) => !!instance?.protocolVersionFc.invalid,
          },
        },
        data: {
          modalView: isAmendment ? 'amendment' : 'create',
          protocolVersionOptions: this.protocolVersionOptions,
        },
      }).afterClosed$
    );

    if (response.data?.version) {
      this.protocolVersionOptions = [response.data.version, ...this.protocolVersionOptions];

      this.protocolForm.controls.protocolVersion.setValue(response.data.version.value);

      const successMessage = isAmendment ? 'Amendment Created' : 'Protocol Created';

      this.overlayService.showFieldTooltip('protocolVersion', successMessage);
    }
  };

  // Selects the node.data field to return
  subTypeGetter(params: ValueGetterParams): PatientProtocolSubType {
    return params.data?.patient_protocol_sub_type as PatientProtocolSubType;
  }

  // Formats the UI rendered string (only)
  subTypeFormatter(params: ValueFormatterParams): string {
    if (!params.value) {
      return '';
    }

    const subType = params.value as PatientProtocolSubType;
    return subType.name;
  }

  // Setting new value after editing
  subTypeSetter(params: ValueSetterParams, newValue?: PatientProtocolSubType): boolean {
    params.data.patient_protocol_sub_type = newValue;

    params.data.errors.requiredType = !params.data.patient_protocol_sub_type;

    return true;
  }

  // Process copy value for clipboard (from grid)
  subTypeCopyForClipboard(params: ProcessCellForExportParams): string | number {
    // Return default
    if (typeof params.value !== 'object') {
      return params.value;
    }

    // Return default if object isn't PatientProtocolSubType
    if (!('patient_protocol_type' in params.value)) {
      return params.value;
    }

    // Prefix to find copied PatientProtocolSubType
    // objects on paste
    const prefix = this.getClipboardPrefixForTypeColumn();
    const subType = JSON.stringify(params.value);
    return prefix + subType;
  }

  // Process paste value from clipboard (to grid)
  subTypeCopyFromClipboard(params: ProcessCellForExportParams) {
    return agParseValueFromClipboard<{
      patient_protocol_name: string;
      target_date_days_out: string;
      target_tolerance_days_out: string;
      patient_protocol_sub_type: Record<string, unknown>;
      patient_protocol_frequency: string;
    }>(
      params,
      new Map([
        ['patient_protocol_name', { type: 'alphanumeric' }],
        ['target_date_days_out', { type: 'numeric' }],
        ['target_tolerance_days_out', { type: 'numeric' }],
        [
          'patient_protocol_sub_type',
          {
            type: 'custom',
            parseFn: () => {
              const prefix = this.getClipboardPrefixForTypeColumn();

              if (params.value.includes(prefix)) {
                const subTypeString = params.value.split(prefix).pop();
                return JSON.parse(subTypeString) as PatientProtocolSubType;
              }

              this.overlayService.error(PATIENT_PROTOCOL_VALIDATION_MESSAGE.TYPE);

              return params.node?.data?.patient_protocol_sub_type;
            },
          },
        ],
        [
          'patient_protocol_frequency',
          {
            type: 'custom',
            parseFn: () => {
              if (this.frequencyOptions.indexOf(params.value) !== -1) {
                return params.value;
              }

              this.overlayService.error(PATIENT_PROTOCOL_VALIDATION_MESSAGE.FREQUENCY);

              return params.node?.data?.patient_protocol_frequency;
            },
          },
        ],
      ])
    );
  }

  private getClipboardPrefixForTypeColumn() {
    return this.protocolForm.getRawValue().patientGroup === 'invoiceables'
      ? 'SUBTYPE_INVOICEABLES_COPY_'
      : 'SUBTYPE_COPY_';
  }

  subTypeIdFromGridData(data: PatientProtocolSubType | null | undefined): string {
    return data?.id || '';
  }

  subTypeCellEditorParams(): PatientProtocolSubType[] {
    // Partition between Visits and Other types
    const [visits, others] = partition(this.protocolSubTypes, (subType) => {
      return subType.patient_protocol_type.includes('VISIT');
    });

    // If a group id is selected, or if it's a visit,
    // use visits, otherwise use other types.
    const groupId = !!this.patientGroupId$.getValue();
    const isVisit = this.isVisit$.getValue();

    return groupId || isVisit ? visits : others;
  }

  async editProtocolVersion(version: EditableListDropdownItem) {
    const effectiveDateTemplate = 'Effective Date: ';

    const { data } = await firstValueFrom(
      this.overlayService.openPopup<
        {
          modalView: string;
          protocolVersionOptions: (Option & { effective_date?: Maybe<string> })[];
          protocolVersion: {
            name: string;
            id: string;
            effective_date?: string;
          };
        },
        {
          success: boolean;
          updatedVersionName: string;
          effective_date: string;
        },
        ProtocolVersionModalComponent
      >({
        content: ProtocolVersionModalComponent,
        settings: {
          header: 'Edit Protocol Version',
          primaryButton: {
            label: 'Save',
            action: (instance) => instance?.onEdit(),
            disabled: (instance) => !!instance?.protocolVersionFc.invalid,
          },
        },
        data: {
          modalView: 'edit',
          protocolVersionOptions: this.protocolVersionOptions,
          protocolVersion: {
            name: version.name,
            id: version.value,
            effective_date: version.bottomText?.replace(effectiveDateTemplate, ''),
          },
        },
      }).afterClosed$
    );

    if (data?.success && data?.updatedVersionName) {
      this.protocolVersionOptions = this.protocolVersionOptions.map((v) =>
        v.value === version.value
          ? {
              ...v,
              label: data?.updatedVersionName,
              effective_date: data.effective_date,
            }
          : v
      );

      this.cdr.markForCheck();
    }
  }

  async deleteProtocolVersion(version: EditableListDropdownItem) {
    if (await this.deleteProtocolVersionConfirmation()) {
      const success = await this.patientProtocolService.removePatientProtocolVersion(
        version.value,
        version.name
      );

      const isSelectedVersionDeleted = this.protocolForm.value.protocolVersion === version.value;

      if (success) {
        this.protocolVersionOptions = this.protocolVersionOptions.filter(
          ({ value }) => value !== version.value
        );

        if (isSelectedVersionDeleted) {
          this.protocolForm.controls.protocolVersion.setValue(
            this.protocolVersionOptions[0]?.value || null
          );
        }
      }
    }
  }

  private async deleteProtocolVersionConfirmation(): Promise<boolean | undefined> {
    const modalEvent = this.overlayService.openPopup<
      ConfirmationActionModalData,
      boolean,
      ConfirmationActionModalComponent
    >({
      modal: ConfirmationActionModalComponent,
      settings: {
        header: 'Delete Protocol Version?',
        primaryButton: {
          label: 'Delete Protocol Version',
          variant: 'negative',
        },
      },
      data: {
        message:
          'This will permanently delete the Protocol Version and all site budgets and costs if applicable. This action cannot be undone and will remove all associated investigator data used for actuals.',
        keywordToExecuteAction: 'Delete Protocol Version',
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    return !!resp?.data;
  }

  gridSizeChanged() {
    if (this.totalItems() <= this.maxVisibleRows) {
      setTimeout(() => this.sizeColumnsToFit(), 200);
    }
  }
}
