import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  DestroyRef,
  inject,
  signal,
  AfterViewInit,
} from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { File } from '@shared/components/file-manager/state/file.model';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OrganizationQuery } from '@models/organization/organization.query';
import { filter, firstValueFrom, take, delay, startWith } from 'rxjs';
import { UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { OverlayService } from '@shared/services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';

import { DocumentType, EntityType, GqlService } from '@shared/services/gql.service';
import { ChangeOrderService } from '../state/change-order.service';
import { ChangeOrderQuery } from '../state/change-order.query';
import { ChangeOrderModel } from '../state/change-order.store';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'aux-change-order-upload',
  templateUrl: './change-order-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderUploadComponent implements OnInit, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  @ViewChild(NgSelectComponent) vendorSelect!: NgSelectComponent;

  loading = signal(false);

  errorMessage = signal('');

  tooltip = 'Change Order not supported for vendor without budget.';

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
    },
  ];

  fg = this.formBuilder.group({
    organization_id: ['', Validators.required],
    change_order_no: '',
    date_received: '',
    effective_date: '',
    notes: '',
  });

  edit = false;

  changeOrder!: ChangeOrderModel;

  changeOrderList: ChangeOrderModel[] = [];

  changeOrderNo: string | undefined;

  private uploaded = false;

  constructor(
    public ref: CustomOverlayRef<unknown, { id?: string; vendorId?: string }>,
    public vendorQuery: OrganizationQuery,
    private formBuilder: UntypedFormBuilder,
    private overlayService: OverlayService,
    private changeOrderService: ChangeOrderService,
    private mainQuery: MainQuery,
    private changeOrderQuery: ChangeOrderQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private gqlService: GqlService
  ) {
    if (this.ref.data?.id) {
      const co = this.changeOrderQuery.getEntity(this.ref.data.id);
      if (co) {
        this.changeOrder = co;
        this.changeOrderNo = co.change_order_no;
        this.fg.patchValue({
          organization_id: co.organization_id,
          change_order_no: co.change_order_no,
          date_received: co.date_received,
          effective_date: co.effective_date,
          notes: co.notes,
        });

        this.edit = true;
      }
    }
    this.ref.canDeactivate = this.canDeactivate;
  }

  get changeOrderNoValidators(): ValidatorFn[] {
    const changeOrderNumbersToForbid = this.changeOrderList
      .filter(
        (changeOrder) => changeOrder.organization_id === this.fg.controls.organization_id.value
      )
      .map((changeOrder) => changeOrder.change_order_no)
      .filter(
        (changeOrderNo) =>
          !this.loading() &&
          (changeOrderNo !== this.changeOrderNo ||
            !this.changeOrder ||
            this.changeOrder.organization_id !== this.fg.controls.organization_id.value)
      );

    return [
      Validators.required,
      CustomValidators.valueNotInList(changeOrderNumbersToForbid, 'duplicatedChangeOrder'),
    ];
  }

  ngOnInit(): void {
    this.fg.controls.organization_id.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
        if (this.fg.controls.change_order_no.pristine && this.changeOrder) {
          this.fg.controls.change_order_no.markAllAsTouched();
          this.fg.controls.change_order_no.updateValueAndValidity();
        }
      });

    this.gqlService
      .listChangeOrders$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ success, data, errors }) =>
        success && data
          ? (this.changeOrderList = data.map(this.changeOrderService.toChangeOrderModel))
          : errors?.length && this.overlayService.error(errors)
      );
  }

  ngAfterViewInit(): void {
    this.vendorQuery.allVendors$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.vendorQuery.allVendors()),
        filter((vendors) => !!vendors?.length && !!this.ref.data?.vendorId),
        take(1),
        delay(0)
      )
      .subscribe(() => {
        this.fg.controls['organization_id'].setValue(this.ref.data?.vendorId);
      });
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  async onUpload() {
    if (!this.fileManager) {
      return;
    }

    this.fg.markAllAsTouched();
    this.fg.updateValueAndValidity();
    this.fg.controls.change_order_no.updateValueAndValidity();

    const isFileNotExist = this.fileManager.fileQuery.getCount() === 0;
    if (!this.edit) {
      if (isFileNotExist) {
        this.errorMessage.set('You must upload a file in order to continue.');
      } else {
        this.errorMessage.set('');
      }
    }

    if (this.fg.invalid || this.loading() || (this.edit ? false : isFileNotExist)) {
      return;
    }

    this.loading.set(true);
    const { change_order_no, notes, effective_date, date_received, organization_id } =
      this.fg.value;

    let id = '';
    if (this.edit) {
      id = this.changeOrder.id;
      const { success, errors, data } = await this.changeOrderService.update({
        id: this.changeOrder.id,
        change_order_no,
        notes,
        effective_date,
        date_received,
        organization_id,
      });

      if (!(success && data)) {
        this.overlayService.error(errors, undefined, true);
      }
    } else {
      const { success, errors, data } = await this.changeOrderService.add({
        change_order_no,
        notes,
        effective_date,
        date_received,
        organization_id,
      });

      if (success && data) {
        id = data.id;
      } else {
        this.overlayService.error(errors, undefined, true);
      }
    }

    if (id) {
      const files = this.fileManager.fileQuery.getAll();
      for (const file of files) {
        this.fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${this.getFilePath(organization_id, id)}${file.bucket_key}`,
        });
      }
      const filesSuccess = await this.fileManager.fileService.uploadFiles(
        {
          status: 'Pending Review',
          documentType: DocumentType.DOCUMENT_CHANGE_ORDER_SUPPORT,
          vendor: organization_id,
          entity_id: id,
          entity_type_id: EntityType.CHANGE_ORDER,
        },
        false,
        true
      );
      if (filesSuccess) {
        if (this.edit) {
          this.overlayService.success(`Change Order successfully updated!`);
        } else {
          this.overlayService.success(`Change Order successfully created!`);
        }
        this.uploaded = true;
        this.ref.close(true);
      }
    }
  }

  removeFile(file: File) {
    if (this.fileManager) {
      this.fileManager.removeFile(file);
    }
  }

  canDeactivate = async (): Promise<boolean> => {
    const { change_order_no, notes, effective_date, date_received, organization_id } =
      this.fg.value;
    const hasChanges =
      (change_order_no === ''
        ? undefined
        : change_order_no !== this.changeOrder?.change_order_no) ||
      (notes === '' ? undefined : notes !== this.changeOrder?.notes) ||
      (effective_date === '' ? undefined : effective_date !== this.changeOrder?.effective_date) ||
      (date_received === '' ? undefined : date_received !== this.changeOrder?.date_received) ||
      (organization_id === (this.ref.data?.vendorId ? this.ref.data?.vendorId : '')
        ? undefined
        : organization_id !== this.changeOrder?.organization_id);

    if (hasChanges && !this.uploaded) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }

    return true;
  };
}
