@if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
  <aux-workflow-panel
    className="mb-6"
    [workflowName]="workflowName"
    [processing]="iCloseMonthsProcessing()!"
    [hasUserPermissions]="userHasLockCustomCurvePermission()"
  />
}

<div
  class="custom-drivers__header w-full flex justify-between h-20 items-center py-2.5 px-5 rounded-[5px]"
>
  <span
    #name
    [auxTooltip]="
      name.offsetWidth < name.scrollWidth
        ? customCurvesQuery.selectedCurve()?.name || zeroHyphen
        : ''
    "
    class="text-2xl text-[#3D444B] truncate font-semibold pr-2.5"
  >
    {{ customCurvesQuery.selectedCurve()?.name || zeroHyphen }}
  </span>
  <div class="flex flex-col">
    <div class="mb-1 text-sm">Select a Curve:</div>
    <div class="gap-5 flex items-center">
      <div [auxTooltip]="getTooltipText()">
        <ng-select
          placeholder="Select"
          class="w-64 custom-driver__select"
          [class.opacity-50]="selectedCurve.disabled"
          [trackByFn]="trackByFn"
          [clearable]="false"
          [searchable]="false"
          [formControl]="selectedCurve"
          bindLabel="name"
          bindValue="name"
          [items]="curves()"
        >
          <ng-template ng-label-tmp let-item="item">
            <div
              #label
              class="truncate"
              [auxTooltip]="label.offsetWidth < label.scrollWidth ? item.name : ''"
            >
              {{ item.name }}
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <div
              class="relative flex justify-between text-gray-900 select-none py-[8px] px-[10px] cursor-pointer"
              (click)="onItemSelect($event, item)"
            >
              <div
                #option
                class="flex-1 truncate mr-1"
                [auxTooltip]="option.offsetWidth < option.scrollWidth ? item.name : ''"
              >
                <span> {{ item.name }}</span>
              </div>
              <div class="flex items-center actions">
                <button
                  class="text-aux-blue-dark mr-1"
                  [ngClass]="{ 'opacity-50 cursor-not-allowed': !hasModifyCustomCurvePermission() }"
                  [auxTooltip]="noPermTooltip()"
                  (click)="onEdit($event, item)"
                  [disabled]="!hasModifyCustomCurvePermission()"
                >
                  <aux-icon name="Pencil" />
                </button>

                <button
                  class="text-aux-red-hover"
                  [disabled]="!hasModifyCustomCurvePermission()"
                  [ngClass]="{ 'opacity-50 cursor-not-allowed': !hasModifyCustomCurvePermission() }"
                  [auxTooltip]="noPermTooltip()"
                  (click)="onDelete($event, item)"
                >
                  <aux-icon name="Trash" />
                </button>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <aux-button
        label="Curve"
        icon="Plus"
        class="create-curve__button"
        pendoTag="custom-curves-create-button"
        (click)="onCreateButtonClick()"
        [disabled]="!hasModifyCustomCurvePermission()"
        [auxTooltip]="noPermTooltip()"
      />
    </div>
  </div>
</div>

@if (selectedCurve.value) {
  <div class="border border-[#BACAD0] rounded-[20px] mt-[20px] p-[10px] relative">
    <div class="font-bold text-xl text-aux-black">
      Planned {{ selectedCurveDistributionLabel() }} & Forecast Distribution
    </div>
    <span class="text-sm mb-4">{{ selectedCurveDistributionLabel() }}</span>
    <aux-canvas-chart [chartOptions]="multiChart()" />
    @if (isChartEmpty() && !distributionsRequestInProgress()) {
      <div class="absolute w-full h-full top-0 right-1 flex justify-center items-center">
        <div class="h-72 mt-16 mb-8 w-full ml-2 bg-white opacity-75 absolute"></div>
        <div class="absolute text-center mb-[18px]">
          <aux-icon name="AlertTriangle" />
          <p class="font-bold text-[21px] text-aux-black">No driver data to show in this graph!</p>
          <p class="text-xl text-aux-gray-darkest mt-2">
            Click Edit and enter driver data in the table below.
          </p>
        </div>
      </div>
    }
  </div>
}
<div class="flex justify-between items-center mt-[20px]">
  @if (curvesLoading()) {
    <div class="spinner h-9 w-9 opacity-50 self-end"></div>
  }
  <div class="flex justify-end items-center grow">
    @if (editMode() || saving()) {
      <button
        type="button"
        class="btn btn--secondary"
        [disabled]="saving()"
        (click)="cancelEditMode()"
      >
        <aux-icon name="X" />
        <span class="ml-0.5">Cancel</span>
      </button>

      <aux-button
        label="Save"
        variant="success"
        icon="Check"
        classList="ml-1 btn custom-drivers__save-button"
        [disabled]="saveDisabled()"
        [auxTooltip]="computedSaveTooltip()"
        [spinnerSize]="7"
        [loading]="saving()"
        (click)="save()"
      />
    } @else {
      <aux-button
        label="Edit"
        pendoTag="custom-curves-edit-table-button"
        variant="secondary"
        icon="Pencil"
        [disabled]="editButtonDisabled()"
        [auxTooltip]="noPermTooltip() || getTooltipText()"
        (click)="editGrid()"
      />
    }

    <aux-export-excel-button
      [clickFN]="onExportCurve.bind(this)"
      [disabled]="exportDisabled()"
      [auxTooltip]="curvesLoading() ? '' : getTooltipText()"
      [spinnerSize]="7"
      class="ml-2"
      pendoTag="custom-curves-excel-export-button"
    />
  </div>
</div>

<ag-grid-angular
  class="ag-theme-aux tabular-nums custom-drivers-table mt-4"
  domLayout="autoHeight"
  [style.visibility]="gridAPI() ? 'visible' : 'hidden'"
  [stopEditingWhenCellsLoseFocus]="true"
  [enableFillHandle]="editMode()"
  [rowData]="gridData()"
  [gridOptions]="gridOptions()"
  [suppressDragLeaveHidesColumns]="false"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  (gridReady)="gridReady($event.api)"
  (gridSizeChanged)="sizeColumnsToFit()"
  (cellValueChanged)="cellValueChanged($event)"
  (firstDataRendered)="$event.api.sizeColumnsToFit()"
  auxStickyGrid
/>
