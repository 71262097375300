import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  signal,
} from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { File } from '@shared/components/file-manager/state/file.model';
import { EntityType, EventType, TemplateType } from '@shared/services/gql.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { FileMetadata } from '@shared/services/api.service';
import { OverlayService } from '@shared/services/overlay.service';
import { EventService } from '@models/event/event.service';
import { Utils } from '@shared/utils/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { DownloadTemplateButtonComponent } from '@features/download-template-button/download-template-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'aux-add-bulk-sites-dialog',
  templateUrl: './add-bulk-sites-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DownloadTemplateButtonComponent,
    FileManagerComponent,
    NgIf,
    NgFor,
    AsyncPipe,
    IconComponent,
  ],
})
export class AddBulkSitesDialogComponent implements AfterViewInit {
  @ViewChild('bulkFileManager') bulkFileManager: FileManagerComponent | undefined;

  loading$ = new BehaviorSubject(false);

  siteTemplateType = TemplateType.BULK_SITE_TEMPLATE;

  countries: { label: string; value: string }[];

  errorMessage = signal('');

  path = '';

  bulkFiles$: Observable<File[]> | undefined;

  constructor(
    public ref: CustomOverlayRef,
    public organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private eventService: EventService
  ) {
    this.countries = Utils.getCountriesForSelectOptions();
  }

  getMetadata(): FileMetadata {
    return { onboarding: 'true' };
  }

  removeBulkFile(file: File) {
    if (this.bulkFileManager) {
      this.bulkFileManager.removeFile(file);
    }
  }

  getBulkFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/bulk-site-templates/`;
  }

  async onBulkUpload() {
    this.errorMessage.set('');

    if (this.bulkFileManager && !this.loading$.getValue()) {
      const files = this.bulkFileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
        return;
      }

      if (files.length > 1) {
        this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const bucket_key = `${this.getBulkFilePath()}${file.bucket_key}`;

      this.bulkFileManager.fileStore.update(file.id, {
        ...file,
        bucket_key,
      });

      const fileSuccess = await this.bulkFileManager.fileService.uploadFiles();

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.BULK_SITE_TEMPLATE_UPLOADED,
            entity_type: EntityType.SITE,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${bucket_key}`,
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }

  ngAfterViewInit(): void {
    if (this.bulkFileManager) {
      this.bulkFiles$ = this.bulkFileManager.fileQuery.selectAll();
    }
  }
}
