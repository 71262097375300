import { Component, ChangeDetectionStrategy, ViewChild, signal } from '@angular/core';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { UntypedFormControl } from '@angular/forms';
import { ApiService, FileMetadata } from '@shared/services/api.service';
import { firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OverlayService } from '@shared/services/overlay.service';
import { MainQuery } from '@shared/store/main/main.query';
import { EntityType, EventType, TemplateType } from '@shared/services/gql.service';
import { SiteGroupsQuery } from '../state/site-groups.query';
import { SiteDistributionService } from '../state/site-distribution.service';
import { SiteCurveService } from '../site-curve/site-curve.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { EventService } from '@models/event/event.service';

@Component({
  selector: 'aux-site-driver-upload',
  template: `
    <div class="w-screen max-w-[560px]"></div>

    <div class="grid grid-cols-2 gap-5">
      <div class="flex flex-col">
        <div>
          <div class="mb-2 text-xs">Site Group</div>
          <ng-select
            placeholder="Select"
            id="sites"
            [formControl]="selectedSiteGroup"
            [appendTo]="'body'"
            [searchable]="true"
            [clearable]="false"
          >
            <ng-option value="" />
            <ng-option [value]="st.id" *ngFor="let st of siteGroupQuery.selectAll() | async">
              {{ st.name }}
            </ng-option>
          </ng-select>
        </div>
        <div>
          <div
            *ngIf="errorMessage()"
            class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md"
          >
            {{ errorMessage() }}
          </div>
        </div>
      </div>
      <div>
        <aux-download-template-button
          label="Download the template"
          [templateType]="siteDriverTemplateType"
          class="flex justify-center mb-4"
          [showIcon]="false"
        />

        <aux-file-manager
          class="h-48"
          #manager
          [fetchFilesOnInit]="false"
          [pathFn]="pathFn"
          [eager]="false"
          [metadata]="metadata"
          [showSuccessOnUpload]="true"
        />
      </div>
      <div></div>
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="manager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        />
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteDriverUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  selectedSiteGroup = new UntypedFormControl('');

  metadata: FileMetadata = {};

  loading = signal(false);

  errorMessage = signal('');

  siteDriverTemplateType = TemplateType.SITE_DRIVER_TEMPLATE;

  constructor(
    public ref: CustomOverlayRef,
    private apiService: ApiService,
    private overlayService: OverlayService,
    public siteGroupQuery: SiteGroupsQuery,
    private mainQuery: MainQuery,
    private siteDistributionService: SiteDistributionService,
    private siteCurveService: SiteCurveService,
    private eventService: EventService
  ) {}

  pathFn: () => string = () => '';

  getFilePath(id: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/sites/${id}/site-driver/`;
  }

  async onUpload() {
    this.errorMessage.set('');

    if (this.fileManager && !this.loading()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
        return;
      }

      if (files.length > 1) {
        this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
        return;
      }

      this.loading.set(true);

      const file = files[0];
      const key = `${this.getFilePath(this.selectedSiteGroup.value)}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.SITE_DRIVER_TEMPLATE_UPLOADED,
            entity_type: EntityType.SITE,
            entity_id: this.selectedSiteGroup.value,
            bucket_key: `public/${key}`,
          })
        );
        if (success) {
          this.siteDistributionService
            .getSiteCurveDistributions(this.selectedSiteGroup.value)
            .subscribe();
          this.siteCurveService.get().subscribe();
          this.overlayService.success();
        } else {
          this.apiService.removeFile(this.getFilePath(file.bucket_key));
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading.set(false);
  }
}
