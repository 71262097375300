import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  inject,
  DestroyRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { isArray, isEqual } from 'lodash-es';
import { InvestigatorTransactionsService } from '../../services/investigator-transactions.service';
import { FormControlConstants } from '@shared/constants/form-control.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { sourceFilterOptions } from './investigator-transactions-filters.constants';
import { InvestigatorFilterConfig } from './investigator-transactions-filters.model';
import {
  TransactionMode,
  InvestigatorTransactionsFilterForm,
} from '../../models/investigator-transaction-form.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-investigator-transactions-filters',
  templateUrl: './investigator-transactions-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsFiltersComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() gridLoading$!: Observable<boolean>;

  @Input() filterLoading$!: BehaviorSubject<boolean>;

  @Input() showSourceColumn$!: Observable<boolean>;

  @Input() gridFiltersFormGroup!: FormGroup;

  @Input() editMode$!: BehaviorSubject<TransactionMode>;

  mapFilterConfigByName = new Map<string, InvestigatorFilterConfig>([
    [
      'Patient Group',
      {
        label: 'Patient Group',
        formControlName: 'patient_group_ids',
        bindLabel: 'name',
        bindValue: 'id',
        items: this.investigatorTransactionsService.patientGroupOptions$,
      },
    ],
    [
      'Protocol Version',
      {
        label: 'Protocol Version',
        formControlName: 'patient_protocol_version_ids',
        bindLabel: 'name',
        bindValue: 'id',
        items: this.investigatorTransactionsService.protocolVersionOptions$,
      },
    ],
    [
      'Description',
      {
        label: 'Description',
        formControlName: 'patient_protocol_names',
        bindLabel: 'name',
        bindValue: 'name',
        items: this.investigatorTransactionsService.descriptionOptions$,
      },
    ],
    [
      'Country',
      {
        label: 'Country',
        formControlName: 'countries',
        bindLabel: 'label',
        bindValue: 'value',
        items: this.investigatorTransactionsService.countryOptions$,
      },
    ],
    [
      'Category',
      {
        label: 'Category',
        formControlName: 'patient_protocol_sub_types',
        bindLabel: 'name',
        bindValue: 'name',
        items: this.investigatorTransactionsService.categoryOptions$,
      },
    ],
    [
      'Source',
      {
        label: 'Source',
        formControlName: 'sources',
        bindLabel: 'name',
        bindValue: 'id',
        items: of(sourceFilterOptions),
      },
    ],
  ]);

  patientOptions$ = this.investigatorTransactionsService.patientOptions$;

  optionalFilterList$ = new BehaviorSubject<InvestigatorFilterConfig[]>([]);

  maxDate = FormControlConstants.MAX_VALUE.DATE;

  minDateForEnd = '';

  maxDateForStart = FormControlConstants.MAX_VALUE.DATE;

  constructor(
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    combineLatest([this.route.queryParams, this.patientOptions$, this.gridLoading$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([params, options, gridLoading]) => {
        if (gridLoading) {
          return;
        }

        if (params.external_patient_id && options.length) {
          const patient = options.find((o) => o.external_patient_id === params.external_patient_id);
          if (patient) {
            this.gridFiltersFormGroup.get('patient_ids')?.setValue([patient.id]);
          }
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      });

    this.gridFiltersFormGroup.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        distinctUntilChanged(isEqual),
        tap((filters) => {
          if (filters.site_ids) {
            const new_patient_ids = this.investigatorTransactionsService.dynamicPatientIdFilter(
              filters.site_ids,
              filters.patient_ids
            );
            this.gridFiltersFormGroup.get('patient_ids')?.setValue(new_patient_ids);
          }
          this.minDateForEnd = filters.start_activity_date ? filters.start_activity_date : '';
          this.maxDateForStart = filters.end_activity_date
            ? filters.end_activity_date
            : FormControlConstants.MAX_VALUE.DATE;
        })
      )
      .subscribe();

    this.investigatorTransactionsService.inMonthAdjustmentsFilter$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pendingFilter) => {
        if (!pendingFilter) {
          return;
        }

        this.addInMonthAdjustmentsFilters();
      });
  }

  addInMonthAdjustmentsFilters(): void {
    const [startDate, endDate, siteIds] =
      this.investigatorTransactionsService.getInMonthAdjustmentsFilters();

    if (!endDate) {
      return;
    }

    const updatedFilters: InvestigatorTransactionsFilterForm = {
      ...this.gridFiltersFormGroup.value,
      start_activity_date: startDate || null,
      end_activity_date: endDate || null,
      site_ids: siteIds || null,
    };

    this.gridFiltersFormGroup.setValue(updatedFilters);

    this.investigatorTransactionsService.resetInMonthAdjustmentsFilters();
  }

  resetFilers() {
    if (!this.editMode$.getValue()) {
      this.gridFiltersFormGroup.reset();
    }
  }

  selectOptionalFilterChange(filterName: string) {
    const config = this.mapFilterConfigByName.get(filterName);

    if (config) {
      this.optionalFilterList$.getValue().push(config);
    }
  }

  onRemoveOptionalFilter = (filterName: string, formControlName: string) => () => {
    this.optionalFilterList$.next(
      this.optionalFilterList$.getValue().filter(({ label }) => label !== filterName)
    );

    const control = this.gridFiltersFormGroup.controls[formControlName];

    const shouldReset =
      control && (isArray(control.value) ? !!control.value?.length : control.value);

    if (shouldReset) {
      control.setValue(null);
    }
  };
}
