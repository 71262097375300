import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { Document, DocumentType } from '@shared/services/gql.service';
import { DocumentLibraryService } from '../document-library.service';
import { Option } from '@shared/types/components.type';
import { DocumentsComponentForm } from '../documents.component.model';
import { FormGroupTyped } from '@shared/utils/utils';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

export interface EditMultipleDocumentsModalData {
  selectedRows: Document[];
  formGroup: FormGroupTyped<DocumentsComponentForm>;
}

@Component({
  selector: 'aux-edit-multiple-documents-modal',
  templateUrl: './edit-multiple-documents-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, ButtonComponent, ModalComponent, ReactiveFormsModule],
})
export class EditMultipleDocumentsModalComponent {
  private readonly fb = inject(FormBuilder);

  selectedRows: Document[] = [];

  formGroup!: FormGroupTyped<DocumentsComponentForm>;

  readonly form = this.fb.group({
    documentType: null as DocumentType | null,
    vendor: null as string | null,
    site: null as string | null,
  });

  documentTypeOptions: Option[] = this.documentLibrary.getDocumentOptions();

  constructor(
    public ref: CustomOverlayRef<{ updateGrid: boolean }, EditMultipleDocumentsModalData>,
    public documentLibrary: DocumentLibraryService
  ) {
    if (this.ref.data) {
      this.selectedRows = this.ref.data.selectedRows;
      this.formGroup = this.ref.data.formGroup;
    }
  }

  onApply() {
    const { documentType, vendor, site } = this.form.getRawValue();

    this.selectedRows.forEach((document) => {
      // todo(upgrade) find out if this is still working
      const table = this.formGroup.controls.table.getRawValue();
      const rowIndex = table.findIndex((v) => v.id === document.id);
      if (rowIndex !== -1) {
        const row = table[rowIndex];
        table.splice(rowIndex, 1, {
          ...row,
          document_type_id: documentType,
          vendor_id: vendor,
          site_id: site,
        });
        this.formGroup.controls.table.setValue(table);
      }
    });

    this.ref.close({ updateGrid: true });
  }
}
