import { createHttpRequest, addTelemetryDebug } from '@datadog/browser-core';
import { record } from '../domain/record';
import { startSegmentCollection, SEGMENT_BYTES_LIMIT } from '../domain/segmentCollection';
export function startRecording(lifeCycle, configuration, sessionManager, viewContexts, encoder, httpRequest) {
  var reportError = function (error) {
    lifeCycle.notify(14 /* LifeCycleEventType.RAW_ERROR_COLLECTED */, {
      error: error
    });
    addTelemetryDebug('Error reported to customer', {
      'error.message': error.message
    });
  };
  var replayRequest = httpRequest || createHttpRequest(configuration, configuration.sessionReplayEndpointBuilder, SEGMENT_BYTES_LIMIT, reportError);
  var _a = startSegmentCollection(lifeCycle, configuration, sessionManager, viewContexts, replayRequest, encoder),
    addRecord = _a.addRecord,
    stopSegmentCollection = _a.stop;
  var stopRecording = record({
    emit: addRecord,
    configuration: configuration,
    lifeCycle: lifeCycle,
    viewContexts: viewContexts
  }).stop;
  return {
    stop: function () {
      stopRecording();
      stopSegmentCollection();
    }
  };
}
