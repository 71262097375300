<div
  class="flex items-center"
  auxSkeletonLoader
  [loading]="(datasource.loading$ | async) === true"
  data-pendo-id="export-full-dataset"
>
  <p>Showing 250 of {{ totalRows() }} records</p>
  <aux-button
    class="pr-8 pl-2"
    variant="hyperlink"
    label="Export full dataset"
    (click)="exportDataset()"
    [loading]="isProcessing()"
    [spinnerSize]="5"
  />
</div>
