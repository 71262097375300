import { WorkflowStep } from '@shared/services/gql.service';

export const WORKFLOW_NAMES = {
  WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS: 'Open Month Adjustments',
  WF_STEP_MONTH_CLOSE_LOCK_TIMELINE: 'Trial Timeline',
  WF_STEP_MONTH_CLOSE_LOCK_CURVES: 'Site, Patient, & Custom Curves',
  WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER: 'Confirm Investigator Data',
  WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY: 'Forecast Methodology',
  WF_STEP_MONTH_CLOSE_LOCK_INVOICES: 'Invoices, Prepaids, and Credit Memos',
} as { [k in WorkflowStep]: string };

export const MONTH_ADJUSTMENT_TOOLTIP =
  'All Closing Checklist entries must be confirmed before period can be closed';

export const WorkflowStepType = WorkflowStep;
