import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  Input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SlicePipe } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from '@shared/components/input/input.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { InvoiceService } from '../state/invoice.service';
import { MultiSelectDropdownComponent } from '@shared/components/multi-select-dropdown/multi-select-dropdown.component';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { isBoolean } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type OptionalFilters = {
  is_deposit: boolean;
  has_prepaid: boolean;
  does_invoice_mappings_match_total: boolean;
  services_period: boolean;
};

@Component({
  selector: 'aux-invoice-filters',
  templateUrl: './invoice-filters.component.html',
  styleUrls: ['./invoice-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    NgSelectModule,
    TooltipDirective,
    SlicePipe,
    MultiSelectDropdownComponent,
    CdkOverlayOrigin,
    ClickOutsideDirective,
    CdkConnectedOverlay,
    ButtonComponent,
  ],
})
export class InvoiceFiltersComponent implements OnInit {
  @Input() form!: FormGroup;

  readonly invoiceService = inject(InvoiceService);

  readonly launchDarklyService = inject(LaunchDarklyService);

  private destroyRef = inject(DestroyRef);

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  editMode = input.required<boolean>();

  optionalFilters = signal<OptionalFilters>({
    is_deposit: false,
    has_prepaid: false,
    does_invoice_mappings_match_total: false,
    services_period: false,
  });

  prepaidFilterFF = this.launchDarklyService.$select((flags) => flags.prepaids);
  depositFilterFF = this.launchDarklyService.$select((flags) => flags.invoices_deposits);

  optionalFiltersFeatureFlags = computed<{ [key: string]: boolean }>(() => ({
    is_deposit: this.depositFilterFF(),
    has_prepaid: this.prepaidFilterFF(),
    does_invoice_mappings_match_total: true,
    services_period: true,
  }));

  showAddFilter = computed(() => {
    return Object.entries(this.optionalFilters()).some(
      ([key, value]) => !value && this.optionalFiltersFeatureFlags()[key]
    );
  });

  isAddFiltersOpen = signal(false);

  depositItems = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  prepaidItems = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  mappedToActivitiesItems = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  ngOnInit(): void {
    if (isBoolean(this.form.get('is_deposit')?.value)) {
      this.optionalFilters.update((v) => ({
        ...v,
        is_deposit: this.optionalFiltersFeatureFlags()['is_deposit'],
      }));
    }

    if (isBoolean(this.form.get('has_prepaid')?.value)) {
      this.optionalFilters.update((v) => ({
        ...v,
        has_prepaid: this.optionalFiltersFeatureFlags()['has_prepaid'],
      }));
    }

    if (isBoolean(this.form.get('does_invoice_mappings_match_total')?.value)) {
      this.optionalFilters.update((v) => ({
        ...v,
        does_invoice_mappings_match_total: true,
      }));
    }

    this.initializeServicePeriod();
  }

  initializeServicePeriod() {
    this.form
      .get('services_period')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.invoiceService.setSelectedServicePeriodOptions(val);
      });
    const options = this.invoiceService.selectedServicePeriodOptions();
    if (options && options.length) {
      this.form.get('services_period')?.setValue(options);
      this.optionalFilters.set({ ...this.optionalFilters(), services_period: true });
    }
  }

  private onAddFilterHide = effect(
    () => {
      if (!this.showAddFilter()) {
        this.isAddFiltersOpen.set(false);
      }
    },
    { allowSignalWrites: true }
  );

  private onFeatureFlagsChange = effect(
    () => {
      Object.entries(this.optionalFiltersFeatureFlags()).forEach(([optionKey, featureFlag]) => {
        if (!featureFlag) {
          untracked(this.onRemoveOptionalFilter(optionKey as keyof OptionalFilters));
        }
      });
    },
    { allowSignalWrites: true }
  );

  resetAllFilters() {
    Object.values(this.form.controls).forEach((control) => {
      control.reset();
    });
  }

  onRemoveOptionalFilter = (key: keyof OptionalFilters) => () => {
    this.form.get(key)?.reset();
    this.optionalFilters.update((v) => ({ ...v, [key]: false }));
  };

  onAddOptionalFilter = (key: keyof OptionalFilters) => {
    this.optionalFilters.update((v) => ({ ...v, [key]: true }));
    this.isAddFiltersOpen.set(false);
  };
}
