import { Injectable } from '@angular/core';
import {
  CreateSiteBudgetVersionInput,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  ListSiteBudgetVersionInput,
  NoteType,
  UpdateNoteInput,
  UpdateSiteBudgetVersionInput,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Utils } from '@shared/utils/utils';
import { firstValueFrom, map, tap } from 'rxjs';
import { isDuplicateError, isGraphResponse } from '@shared/utils';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { File } from '@shared/components/file-manager/state/file.model';
import { MainQuery } from '@shared/store/main/main.query';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { EventService } from '@models/event/event.service';

@Injectable({ providedIn: 'root' })
export class SiteBudgetService {
  constructor(
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private documentLibraryService: DocumentLibraryService,
    private authQuery: AuthQuery,
    private eventService: EventService
  ) {}

  getSiteBudgetList$(params: ListSiteBudgetVersionInput) {
    return this.gqlService.listSiteBudgetVersions$(params).pipe(map(({ data }) => data || []));
  }

  createNote$(note: string, siteBudgetId: string) {
    return this.gqlService
      .createNote$({
        entity_id: siteBudgetId,
        entity_type: EntityType.SITE_BUDGET_VERSION,
        note_type: NoteType.NOTE_TYPE_GENERAL,
        message: Utils.scrubUserInput(note),
      })
      .pipe(
        tap(({ errors }) => {
          if (errors.length) {
            this.overlayService.error(errors);
          }
        })
      );
  }

  createSiteBudgetVersion$(params: CreateSiteBudgetVersionInput) {
    return this.gqlService.createSiteBudgetVersion$(params).pipe(
      tap(({ errors }) => {
        const errorsWithoutDuplicateError = errors.filter((error) => !isDuplicateError(error));
        if (errorsWithoutDuplicateError.length) {
          this.overlayService.error(errorsWithoutDuplicateError);
        }
      })
    );
  }

  removeSiteBudgetVersion$(id: string, documentIds: string[]) {
    return this.gqlService.removeSiteBudgetVersion$(id).pipe(
      tap(async ({ errors }) => {
        if (errors.length) {
          this.overlayService.error(errors);
          return;
        }

        await this.documentLibraryService.removeDocuments(documentIds, false);
      })
    );
  }

  async updateSiteBudget(
    params: UpdateSiteBudgetVersionInput,
    note: Partial<Pick<UpdateNoteInput, 'message' | 'id'>>,
    siteId: string,
    fileManager?: FileManagerComponent,
    deletedFiles: File[] = []
  ): Promise<{ errors: string[]; errorSource: 'updateSite' | 'noteOrFiles' | null }> {
    const { errors } = await firstValueFrom(this.gqlService.updateSiteBudgetVersion$(params));

    if (errors.length) {
      return { errors, errorSource: 'updateSite' };
    }

    const { trialKey } = this.mainQuery.getValue();

    const fileIdsToRemoveFromLibrary = deletedFiles
      .filter((file) => !!file.uploaded)
      .map((file) => file.id);

    const promises = [
      note.id
        ? firstValueFrom(this.gqlService.updateNote$(note as UpdateNoteInput))
        : firstValueFrom(this.createNote$(note.message || '', params.id)),
      fileManager?.fileService?.uploadFiles(
        {
          documentType: DocumentType.DOCUMENT_SITE_AGREEMENTS,
          entity_type_id: EntityType.SITE_BUDGET_VERSION,
          site: siteId,
          entity_id: params.id,
        },
        false,
        true,
        `trials/${trialKey}/sites/${siteId}/${params.id}/contracts/`,
        true,
        true,
        false
      ),
      this.documentLibraryService.removeDocuments(fileIdsToRemoveFromLibrary, false),
    ];

    const numFilesUploaded = fileManager?.fileQuery.getAll({
      filterBy: (entity) => !entity.uploaded && !!entity.rawFile,
    }).length;

    if (numFilesUploaded && numFilesUploaded > 0) {
      this.eventService.processEvent$({
        type: EventType.SITE_CONTRACT_UPLOADED,
        entity_type: EntityType.DOCUMENT,
        entity_id: '',
        payload: JSON.stringify({
          site_id: siteId,
          uploaded_by: `${this.authQuery.getFullName()} ${this.authQuery.getEmail()}`,
          file_names: params.files_uploaded,
          site_budget_version_id: params.id,
          reason_for_amendment: params.message,
        }),
      });
    }

    const responses = await Promise.allSettled(promises);

    return responses
      .map((result) => (result.status === 'fulfilled' ? result.value : null))
      .reduce<{ errors: string[]; errorSource: 'updateSite' | 'noteOrFiles' | null }>(
        (result, resp) => {
          if (isGraphResponse(resp)) {
            result.errors = result.errors.concat(resp.errors);
            result.errorSource = 'noteOrFiles';
          }

          return result;
        },
        { errors: [], errorSource: null }
      );
  }
}
