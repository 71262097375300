import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';

@Component({
  selector: 'aux-account',
  templateUrl: './account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Profile & Settings',
      show: this.launchDarklyService.select$((flags) => flags.tab_profile_settings),
      route: ROUTING_PATH.ACCOUNT.SETTINGS,
    },
    {
      label: 'Notification Preferences',
      show: of(true),
      route: ROUTING_PATH.ACCOUNT.NOTIFICATIONS,
    },
  ];

  rightSideContainer = new BehaviorSubject<TemplateRef<unknown> | null>(null);

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
