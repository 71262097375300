import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';
import { Notes } from '../notes.enum';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ButtonComponent } from '@shared/components/button/button.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { InputComponent } from '@shared/components/input/input.component';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import {
  QuarterCloseNotesStore,
  TaskInstruction,
  DirtyStep,
  MonthlyReviewNote,
} from '@shared/store/quarter-close-notes/quarter-close-notes.store';
import { QuarterCloseChecklistPeriodCloseService } from '../../../services/quarter-close-checklist-period-close.service';
import { MonthlyReviewNotesComponent } from '../monthly-review-notes/monthly-review-notes.component';
import { Workflow } from '@shared/store/workflow/workflow.store';
import {
  createNoteMutation,
  EntityType,
  GqlService,
  NoteType,
  updateNoteMutation,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MonthlyReviewNotesCounterComponent } from '../../monthly-review-notes-counter/monthly-review-notes-counter.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const TI_DESCRIPTION = `(Include notes or instructions for how to complete this step.
  Any notes added here will persist month-to-month.)`;
const MRN_DESCRIPTION = `(Use this to add any notes or explanation specific to this step
  for this month's close.)`;

@Component({
  selector: 'aux-checklist-notes-editor',
  templateUrl: './checklist-notes-editor.component.html',
  styleUrl: './checklist-notes-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    InputComponent,
    IconComponent,
    TooltipDirective,
    ButtonComponent,
    DatePipe,
    AsyncPipe,
    MonthlyReviewNotesComponent,
    MonthlyReviewNotesCounterComponent,
  ],
})
export class ChecklistNotesEditorComponent implements AfterViewInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  @Input() type: Notes = Notes.TaskInstructions;

  @Input() checklistAdminPermission!: boolean;

  @Input() workflow$!: BehaviorSubject<Workflow | null>;

  @ViewChild(InputComponent) inputComponent!: InputComponent;

  textarea = new FormControl('');

  pending = false;

  private taskInstructions$!: Subscription;

  readonly Notes = Notes;

  readonly quarterCloseNotesQuery = inject(QuarterCloseNotesQuery);

  readonly periodCloseService = inject(QuarterCloseChecklistPeriodCloseService);

  private readonly quarterCloseNotesStore = inject(QuarterCloseNotesStore);

  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  private readonly gqlService = inject(GqlService);

  private readonly overlayService = inject(OverlayService);

  private readonly authQuery = inject(AuthQuery);

  get title(): string {
    return this.type === Notes.TaskInstructions ? Notes.TaskInstructions : Notes.MonthlyReviewNotes;
  }

  get description(): string {
    return this.type === Notes.TaskInstructions ? TI_DESCRIPTION : MRN_DESCRIPTION;
  }

  get saveButtonTooltip(): string {
    const monthlyReviewNotesTooltip =
      this.textarea.dirty && this.textarea.invalid ? 'Enter Monthly Review Note to Save.' : '';
    return this.isTaskInstructions ? '' : monthlyReviewNotesTooltip;
  }

  get isTaskInstructions(): boolean {
    return this.type === Notes.TaskInstructions;
  }

  get isMonthlyReviewNotes(): boolean {
    return this.type === Notes.MonthlyReviewNotes;
  }

  get selectedPeriod(): string {
    return this.quarterCloseNotesStore.getValue().selectedPeriod as string;
  }

  get workflowId(): string {
    return this.workflow$.value?.id || '';
  }

  get taskInstruction(): TaskInstruction | null {
    return this.quarterCloseNotesStore.getValue().notes[this.workflowId]?.taskInstruction;
  }

  get monthlyReviewNotes(): MonthlyReviewNote[] | null {
    return this.quarterCloseNotesStore.getValue().notes[this.workflowId]?.monthlyReviewNotes;
  }

  get isActionsVisible(): boolean {
    if (this.isTaskInstructions) {
      return this.textarea.enabled;
    }

    return this.textarea.dirty;
  }

  get dirtyStepState(): DirtyStep {
    return this.quarterCloseNotesStore.getValue().notes[this.workflowId].dirty;
  }

  get validators(): ValidatorFn[] {
    return this.isTaskInstructions ? [Validators.required] : [];
  }

  ngAfterViewInit(): void {
    this.textarea.valueChanges
      .pipe(
        filter((value) => !value && this.isMonthlyReviewNotes),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.textarea.markAsPristine());

    this.quarterCloseNotesQuery.selectedPeriod$
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(this.disableOnInit);

    this.workflow$
      .asObservable()
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((workflow: Workflow) => {
        if (this.isTaskInstructions) {
          this.taskInstructions$?.unsubscribe();
          this.taskInstructions$ = this.quarterCloseNotesQuery
            .taskInstruction$((workflow as Workflow)?.id)
            .pipe(
              filter((content) => !!content?.text),
              distinctUntilChanged((prev, curr) => prev?.text === curr?.text),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((content) => this.textarea.setValue(content.text));
        }
      });
    this.disableOnInit();
  }

  ngOnDestroy(): void {
    this.setNotesDirtyState(false, false);
  }

  onBlur(): void {
    if (!this.textarea.value && this.isMonthlyReviewNotes) {
      this.onCancel();
    }
  }

  onChange(target: EventTarget | null): void {
    const value = (target as HTMLTextAreaElement)?.value;
    const monthlyReviewNotesDirtyState = this.isTaskInstructions
      ? !!this.dirtyStepState?.isMonthlyReviewNotesDirty
      : !!value;
    const taskInstructionDirtyState = this.isTaskInstructions
      ? this.taskInstruction?.text !== value
      : !!this.dirtyStepState?.isTaskInstructionDirty;
    this.setNotesDirtyState(monthlyReviewNotesDirtyState, taskInstructionDirtyState);
  }

  noPermissionsTooltipText(): string {
    if (this.periodCloseService.currentOpenMonth !== this.selectedPeriod) {
      return 'Cannot edit for closed months.';
    }
    if (!this.checklistAdminPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }
    return '';
  }

  onEdit(): void {
    if (
      this.periodCloseService.currentOpenMonth === this.selectedPeriod &&
      this.checklistAdminPermission
    ) {
      this.textarea.enable();
      this.inputComponent?.input?.nativeElement.focus();
    }
  }

  onSave(): void {
    this.pending = true;

    if (this.isTaskInstructions) {
      this.updateTaskInstructuion();
      return;
    }

    this.createMonthlyReviewNote();
  }

  onCancel = (): void => {
    if (this.isTaskInstructions) {
      this.textarea.setValue(this.taskInstruction?.text as string);
      this.textarea.disable();
      this.setNotesDirtyState(!!this.dirtyStepState?.isMonthlyReviewNotesDirty, false);
    }

    if (this.isMonthlyReviewNotes) {
      this.textarea.reset();
      this.textarea.markAsPristine();
      this.setNotesDirtyState(false, !!this.dirtyStepState?.isTaskInstructionDirty);
    }
  };

  private disableOnInit = (): void => {
    if (this.isTaskInstructions) {
      this.textarea.disable();
    } else if (this.selectedPeriod !== this.periodCloseService.currentOpenMonth) {
      this.textarea.reset();
      this.textarea.disable();
      this.textarea.markAsPristine();
    } else {
      this.textarea.reset();
      this.textarea.enable({ emitEvent: false });
      this.textarea.markAsPristine();
    }

    this.quarterCloseNotesStore.updateDirtyState(this.workflowId, new DirtyStep(false, false));
    this.changeDetectorRef.detectChanges();
  };

  private setNotesDirtyState(
    monthlyReviewNotesDirty: boolean,
    taskInstructionDirty: boolean
  ): void {
    const updatedDirtyStep = new DirtyStep(monthlyReviewNotesDirty, taskInstructionDirty);
    this.quarterCloseNotesStore.updateDirtyState(this.workflowId, updatedDirtyStep);
  }

  private updateNote$(
    message: string = this.textarea.value as string
  ): Observable<GraphqlResponse<updateNoteMutation>> {
    return this.gqlService.updateNote$({
      id: (this.taskInstruction as TaskInstruction).id as string,
      message,
    });
  }

  private createNote$(
    noteType: NoteType,
    message: string = this.textarea.value as string
  ): Observable<GraphqlResponse<createNoteMutation>> {
    return this.gqlService.createNote$({
      entity_id: (this.workflow$ as BehaviorSubject<Workflow>).value.id,
      entity_type: EntityType.WF_DETAIL,
      note_type: noteType,
      message,
    });
  }

  private updateTaskInstructuion(): void {
    const nowDate = new Date();
    const request$ = this.taskInstruction?.isDefault
      ? this.createNote$(NoteType.NOTE_TYPE_TASK_INSTRUCTIONS)
      : this.updateNote$();

    request$
      .pipe(
        tap(({ errors, data }) => {
          if (errors.length) {
            this.overlayService.error(errors);
          } else if (data) {
            this.quarterCloseNotesStore.updateTaskInstruction(this.workflowId, {
              text: data.message,
              id: data.id,
              updated: nowDate.toString(),
            });
          }
          this.pending = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(this.onTaskInstructionUpdate);
  }

  private createMonthlyReviewNote(): void {
    const nowDate = new Date();
    this.createNote$(NoteType.NOTE_TYPE_MONTHLY_REVIEW_NOTE)
      .pipe(
        tap(({ errors, data }) => {
          if (errors.length) {
            this.overlayService.error(errors);
          } else if (data) {
            this.quarterCloseNotesStore.addMonthlyReviewNote(this.workflowId, {
              createdBy: this.authQuery.user().sub,
              created: nowDate.toString(),
              text: data.message,
              id: data.id,
              workflowId: this.workflowId,
            });
          }
          this.pending = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(this.onCancel);
  }

  private onTaskInstructionUpdate = ({
    errors,
  }: GraphqlResponse<createNoteMutation> | GraphqlResponse<updateNoteMutation>) => {
    if (errors.length) {
      return;
    }

    this.setNotesDirtyState(!!this.dirtyStepState?.isMonthlyReviewNotesDirty, false);
    this.textarea.disable();
    this.changeDetectorRef.detectChanges();
  };
}
