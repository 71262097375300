import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { NgClass, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { AgHeaderLinkComponentParams } from '@shared/ag-components/ag-header-link/ag-header-link.model';

@Component({
  templateUrl: 'ag-header-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TooltipDirective, IconComponent, NgClass, NgIf],
})
export class AgHeaderLinkComponent implements IHeaderAngularComp {
  params!: AgHeaderLinkComponentParams;

  value!: string;

  cellClass!: string;

  router = inject(Router);

  agInit(params: AgHeaderLinkComponentParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onLinkClicked(): void {
    if (this.params.redirectFunction) {
      this.params.redirectFunction();
    } else if (this.params.link) {
      this.router.navigate([this.params.link]);
    }
  }
}
