import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { BudgetType, GqlService } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { BudgetLibraryModel, BudgetLibraryStore } from './budget-library.store';
import { MainQuery } from '@shared/store/main/main.query';
import { firstValueFrom } from 'rxjs';
import {
  ConfirmationActionModalData,
  ConfirmationActionModalComponent,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';

@Injectable({ providedIn: 'root' })
export class BudgetLibraryService {
  constructor(
    private budgetLibraryStore: BudgetLibraryStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.budgetLibraryStore.setLoading(true);
        this.budgetLibraryStore.remove(() => true);
        return this.gqlService
          .listBudgetLibrary$([BudgetType.BUDGET_PRIMARY, BudgetType.BUDGET_SECONDARY], '')
          .pipe(
            map(({ data, success, errors }) => {
              const bLibrary = (data || []).map((val) => {
                return { ...val, id: val.budget_version_id };
              });
              this.budgetLibraryStore.set(bLibrary);

              this.budgetLibraryStore.setLoading(false);
              return { success, errors, data };
            })
          );
      })
    );
  }

  async updateBudgetVersionAsBaseline(id: string, organization_id: string) {
    const ref = this.overlayService.loading();
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.setBudgetVersionAsBaseline$(id, organization_id)
    );
    if (errors.length) {
      this.overlayService.error(errors);
    } else if (success) {
      data?.forEach((x: BudgetLibraryModel) => {
        this.budgetLibraryStore.update(x.budget_version_id, x);
      });
      this.overlayService.success();
      ref.close();
    }
  }

  async updateLastBudgetVersion(item: BudgetLibraryModel) {
    this.budgetLibraryStore.update(item?.budget_version_id, item as BudgetLibraryModel);
  }

  async removeBudgetVersion(item: BudgetLibraryModel) {
    const modalEvent = this.overlayService.openPopup<
      ConfirmationActionModalData,
      boolean,
      ConfirmationActionModalComponent
    >({
      modal: ConfirmationActionModalComponent,
      settings: {
        header: 'Delete Budget Version?',
        primaryButton: {
          label: 'Delete Budget Version',
          variant: 'negative',
        },
      },
      data: {
        message:
          'This will permanently delete the selected budget version. This action cannot be undone and will remove all associated data.',
        keywordToExecuteAction: 'Delete Budget',
      },
    }).afterClosed$;

    const resp = await firstValueFrom(modalEvent);

    if (resp?.data) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removeBudgetVersion$(item.budget_version_id)
      );

      if (success) {
        this.overlayService.success(`${item.budget_name} successfully removed!`);
        this.budgetLibraryStore.remove(item.budget_version_id);
      } else {
        this.overlayService.error(errors);
      }
    }
  }
}
