import {
  CellClassParams,
  ColDef,
  IRowNode,
  ITooltipParams,
  ValueFormatterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import { Utils } from '@shared/utils/utils';
import { Currency, listBudgetActivitiesQuery } from '@shared/services/gql.service';
import { NewValueParams } from '@ag-grid-community/core/dist/esm/es6/entities/colDef';
import { set } from 'lodash-es';
import {
  MapActivitiesGridRow,
  MapActivityType,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { AgRequiredHeaderComponent } from '@shared/ag-components/ag-required-header/ag-required-header.component';
import { AgExtendableOptionsDropdownComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/ag-extendable-options-dropdown/ag-extendable-options-dropdown.component';
import { AgBudgetActivityDropdownComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/ag-budget-activity-dropdown/ag-budget-activity-dropdown.component';
import { RegExpUtils } from '@shared/utils/regExp.utils';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { AgDeleteCellComponent } from '@features/inline-budget/ag-delete-cell.component';

export const getMapActivitiesGridColumns = (
  overlayService: OverlayService,
  vendorCurrency: Currency,
  validateCell: (isInvalidValue: boolean, data: MapActivitiesGridRow, node: IRowNode) => void,
  onCellValueChanged: (params: NewValueParams) => void,
  budgetActivityOptions: listBudgetActivitiesQuery[],
  removeRow: (rowNode: IRowNode) => void,
  isInvalidRow: (rowNode: CellClassParams) => boolean,
  updateTotals: () => void
): ColDef[] => {
  return [
    {
      headerName: 'Invoice Item',
      field: 'invoice_item_description',
      tooltipField: 'invoice_item_description',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {};
        }

        return { component: AgExtendableOptionsDropdownComponent, params };
      },
      minWidth: 240,
      onCellValueChanged: (params: NewValueParams) => {
        onCellValueChanged(params);
      },
      cellRendererParams: () => ({
        validateCell: validateCell,
      }),
      cellClass: (params) => {
        if (params.node.rowPinned) {
          return ['text-left'];
        }

        return ['invoice-item', '!block', 'text-elypsis', 'text-left'];
      },
      comparator: (a, b) => {
        return Utils.localeAlphaNumSort(a || '', b || '');
      },
      editable: false,
    },
    {
      headerName: 'Budget Activity',
      headerComponent: AgRequiredHeaderComponent,
      field: 'activity_id',
      tooltipField: 'activity_name',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {};
        }

        return { component: AgBudgetActivityDropdownComponent, params };
      },
      cellRendererParams: () => {
        return {
          options:
            budgetActivityOptions.map(({ id, name, activity_type }) => ({
              value: id,
              label: name || '',
              activity_type,
            })) || [],
          validateCell: validateCell,
          updateTotals: updateTotals,
        };
      },
      valueSetter: (params: ValueSetterParams) => {
        const newValue = budgetActivityOptions.find(({ name, id }) =>
          RegExpUtils.isUUID(params.newValue) ? id === params.newValue : name === params.newValue
        );

        if (!newValue) {
          set(params.data, 'activity_id', null);
          set(params.data, 'activity_name', null);
          set(params.data, 'activity_type', null);
        } else {
          set(params.data, 'activity_id', newValue.id);
          set(params.data, 'activity_name', newValue.name);
          set(params.data, 'activity_type', newValue.activity_type);
        }

        return true;
      },
      onCellValueChanged: (params: NewValueParams) => {
        onCellValueChanged(params);
      },
      cellClassRules: {
        'border-aux-error': (params: CellClassParams) => {
          return (
            !params.node.rowPinned &&
            (params.data?.isNewRow ? isInvalidRow(params) : true) &&
            !params.value
          );
        },
      },
      cellClass: ['budget-activity-item', '!block', 'text-elypsis', 'text-left'],
      editable: false,
    },
    {
      headerName: 'Cost Category',
      field: 'activity_type',
      tooltipValueGetter: (params: ITooltipParams) =>
        params.data?.activity_type
          ? MapActivityType[params.data?.activity_type as keyof typeof MapActivityType]
          : '',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.activity_type
          ? MapActivityType[params.data?.activity_type as keyof typeof MapActivityType]
          : '';
      },
      minWidth: 180,
      maxWidth: 180,
      cellClass: ['text-elypsis'],
      cellClassRules: {
        'cursor-not-allowed': (params: CellClassParams) => !params.node.rowPinned,
        'ag-cell-not-edit-cell': (params: CellClassParams) => !params.node.rowPinned,
      },
      editable: false,
      suppressFillHandle: true,
    },
    {
      headerName: 'Total',
      headerComponent: AgRequiredHeaderComponent,
      field: 'amount',
      valueFormatter: (params) => {
        return Utils.agCurrencyFormatter(params, vendorCurrency);
      },
      tooltipValueGetter: (params: ITooltipParams) =>
        params.data?.amount ? Utils.agCurrencyFormatter(params.data?.amount, vendorCurrency) : '',
      onCellValueChanged: (params: NewValueParams) => {
        onCellValueChanged(params);
      },
      cellClass: ['ag-cell-align-right', 'text-elypsis'],
      cellClassRules: {
        'border-aux-error': (params: CellClassParams) =>
          !params.node.rowPinned &&
          (params.data?.isNewRow ? isInvalidRow(params) : true) &&
          !params.value,
      },
    },
    {
      headerName: '',
      field: 'action',
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {};
        }

        return { component: AgDeleteCellComponent, params };
      },
      cellRendererParams: () => ({
        onDelete: async (params: NewValueParams) => {
          const data = params.node?.data;

          if (!data || !params.node) {
            return;
          }

          if (!data.isNewRow) {
            const ref = overlayService.openPopup({
              content: ConfirmationModalComponent,
              settings: {
                header: 'Remove Activity',
                primaryButton: {
                  label: 'Remove',
                },
              },
              data: {
                message: 'Are you sure you want to remove this row?',
              },
            });

            const event = await firstValueFrom(ref.afterClosed$);

            if (!event.data) {
              return;
            } else {
              removeRow(params.node);
            }
          } else {
            removeRow(params.node);
          }
        },
      }),
      width: 32,
      minWidth: 32,
      maxWidth: 32,
      cellClass: '!p-0 h-full',
      editable: false,
    },
  ];
};
