import { inject, Injectable } from '@angular/core';
import {
  AmountType,
  CurveType,
  DriverType,
  ForecastMethodType,
  GqlService,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { Utils } from '@shared/utils/utils';
import { firstValueFrom } from 'rxjs';

type Milestone = {
  contractStartDate: string;
  contractEndDate: string;
  name: string;
  categoryId: string;
  categoryName: string;
  id: string;
  contractStartDateId: string;
  contractEndDateId: string;
};

export interface ForecastSettings {
  applyServices: boolean;
  applyPassThrough: boolean;
  applyInvestigator: boolean;
  driver: 'patient' | 'site' | 'time';
  patientCurve: string | null;
  siteDriverPeriod: CurveType | null;
  siteCurve: string | null;
  milestone: Milestone | null;
  startMilestone: Milestone | null;
  endMilestone: Milestone | null;
  customStartMilestone: string | null;
  customEndMilestone: string | null;
  milestoneCategory: string | null;
  timeMethod: ForecastMethodType | null;
  customCurve: string | null;
}

@Injectable()
export class QuickForecastService {
  private readonly gqlService = inject(GqlService);

  private readonly overlayService = inject(OverlayService);

  private readonly eventService = inject(EventTrackerService);

  private readonly mapSaveMethodByDriver = new Map<
    'patient' | 'site' | 'time' | 'custom',
    (
      p: ForecastSettings,
      transactionId: string,
      trackId: string,
      vendorId: string
    ) => Promise<GraphqlResponse<unknown>>
  >([
    [
      'patient',
      (p, t, trackId, vendorId) => this.savePatientForecastSettings(p, t, trackId, vendorId),
    ],
    ['site', (p, t, trackId, vendorId) => this.saveSiteForecastSettings(p, t, trackId, vendorId)],
    ['time', (p, t, trackId, vendorId) => this.saveTimeForecastSettings(p, t, trackId, vendorId)],
    [
      'custom',
      (p, t, trackId, vendorId) => this.saveCustomForecastSettings(p, t, trackId, vendorId),
    ],
  ]);

  private getAmountType({
    applyInvestigator,
    applyPassThrough,
    applyServices,
  }: ForecastSettings): AmountType[] {
    const amountTypes = [];

    if (applyServices) {
      amountTypes.push(AmountType.AMOUNT_SERVICE);
    }

    if (applyPassThrough) {
      amountTypes.push(AmountType.AMOUNT_PASSTHROUGH);
    }

    if (applyInvestigator) {
      amountTypes.push(AmountType.AMOUNT_INVESTIGATOR);
    }

    return amountTypes;
  }

  private saveCustomForecastSettings(
    params: ForecastSettings,
    transactionId: string,
    trackId: string,
    vendorId: string
  ) {
    return firstValueFrom(
      this.gqlService.bulkUpdateBudgetForecastSettings$({
        amount_types: this.getAmountType(params),
        driver: DriverType.DRIVER_CUSTOM,
        driver_setting_id: params.customCurve,
        forecast_method: null,
        milestone_category: null,
        period_end_date: null,
        period_end_milestone_id: null,
        period_start_date: null,
        period_start_milestone_id: null,
        transaction_id: transactionId,
        tracking_id: trackId,
        vendor_id: vendorId,
      })
    );
  }

  private savePatientForecastSettings(
    params: ForecastSettings,
    transactionId: string,
    trackId: string,
    vendorId: string
  ) {
    return firstValueFrom(
      this.gqlService.bulkUpdateBudgetForecastSettings$({
        amount_types: this.getAmountType(params),
        driver: DriverType.DRIVER_PATIENT,
        driver_setting_id: params.patientCurve,
        forecast_method: null,
        milestone_category: null,
        period_end_date: null,
        period_end_milestone_id: null,
        period_start_date: null,
        period_start_milestone_id: null,
        transaction_id: transactionId,
        tracking_id: trackId,
        vendor_id: vendorId,
      })
    );
  }

  private async saveSiteForecastSettings(
    params: ForecastSettings,
    transactionId: string,
    trackId: string,
    vendorId: string
  ) {
    return firstValueFrom(
      this.gqlService.bulkUpdateBudgetForecastSettings$({
        amount_types: this.getAmountType(params),
        driver: DriverType.DRIVER_SITE,
        driver_setting_id: params.siteCurve,
        forecast_method: null,
        milestone_category: null,
        period_end_date: null,
        period_end_milestone_id: null,
        period_start_date: null,
        period_start_milestone_id: null,
        transaction_id: transactionId,
        tracking_id: trackId,
        vendor_id: vendorId,
      })
    );
  }

  private async saveTimeForecastSettings(
    params: ForecastSettings,
    transactionId: string,
    trackId: string,
    vendorId: string
  ) {
    return firstValueFrom(
      this.gqlService.bulkUpdateBudgetForecastSettings$({
        amount_types: this.getAmountType(params),
        driver: DriverType.DRIVER_TIME,
        driver_setting_id: null,
        forecast_method: params.timeMethod,
        milestone_category: params.milestoneCategory,
        period_end_date: params.customEndMilestone || null,
        period_end_milestone_id: params.milestone?.id
          ? params.milestone.contractEndDateId
          : params.endMilestone?.id || null,
        period_start_date: params.customStartMilestone || null,
        period_start_milestone_id: params.milestone?.id
          ? params.milestone.contractStartDateId
          : params.startMilestone?.id || null,
        transaction_id: transactionId,
        tracking_id: trackId,
        vendor_id: vendorId,
      })
    );
  }

  async saveUpdateBudgetForecastSettings(params: ForecastSettings, vendorId: string) {
    const { driver } = params;

    const method = this.mapSaveMethodByDriver.get(driver);

    if (method) {
      const transactionId = Utils.uuid();
      const trackId = Utils.uuid();
      const { errors, success } = await method(params, transactionId, trackId, vendorId);

      if (errors.length) {
        this.overlayService.error(errors);
      }

      if (success) {
        this.eventService.trackEvent(trackId);
      }

      return success;
    }

    return false;
  }
}
