@if ((loading$ | async) === false) {
  @if (type === undefined) {
    <ng-container *ngTemplateOutlet="defaultTemplate"> </ng-container>
  } @else if (type === 'inAppBudget') {
    <ng-container *ngTemplateOutlet="BudgetTemplate"> </ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="EDCTemplate"> </ng-container>
  }
} @else {
  @if (type === 'edc') {
    <ng-container *ngTemplateOutlet="edcLoading"> </ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="defaultLoading"> </ng-container>
  }
}

<ng-template #defaultLoading>
  <div class="border border-blue-200 shadow rounded-md p-2 w-full mt-4 max-w-sm">
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-4 py-1">
        <div class="flex justify-between">
          <div class="h-8 w-7 bg-blue-200 rounded"></div>
          <div class="grow"></div>
          <div class="h-4 bg-blue-200 rounded w-1/6"></div>
        </div>
        <div class="space-y-2">
          <div class="h-3 bg-blue-200 rounded w-3/6"></div>
          <div class="h-0.5"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultTemplate>
  <section
    *ngFor="let file of files$ | async; first as first"
    class="border-2 rounded-md h-32 p-2 relative mt-4"
    [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
  >
    <div class="flex items-center justify-between h-6">
      <aux-icon name="FileDescription" class="-ml-1" />
      <div class="flex items-center justify-between h-6">
        <button
          class="mr-2 w-6 h-6 text-aux-blue flex justify-center items-center"
          type="button"
          auxTooltip="Download"
          (click)="onDownload(file)"
        >
          <aux-icon name="Download" />
        </button>
        <button
          type="button"
          class="btn--hyperlink px-0 focus:outline-none"
          (click)="onRemove(file)"
        >
          Remove
        </button>
      </div>
    </div>
    <div class="h-12 mt-2">
      <div
        class="font-semibold line-clamp-2 block truncate"
        #fileText
        [auxTooltip]="fileText.offsetWidth < fileText.scrollWidth ? file.fileName : ''"
      >
        {{ file.fileName }}
      </div>

      <div
        *ngIf="file.created_by && file.create_date && showUserAndDate"
        class="line-clamp-2 italic"
      >
        Uploaded By: {{ userFormatter(file.created_by) }}
        {{ file.create_date | date }}
      </div>
      <ng-content></ng-content>
    </div>
  </section>
</ng-template>

<ng-template #edcLoading>
  <div class="border border-blue-200 shadow rounded-md p-2 w-full mt-4">
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-4 py-1">
        <div class="flex justify-between">
          <div class="h-8 w-7 bg-blue-200 rounded"></div>
          <div class="grow"></div>
          <div class="h-4 bg-blue-200 rounded w-1/6"></div>
        </div>
        <div class="space-y-2">
          <div class="h-3 bg-blue-200 rounded w-3/6"></div>
          <div class="h-0.5"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #BudgetTemplate>
  <section
    *ngFor="let file of files$ | async; first as first"
    class="border-2 rounded-md min-h-12 px-2 relative mt-4 border-gray-200 flex items-center"
    [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex justify-start items-center">
        <aux-icon name="FileDescription" class="-ml-1 mr-1 min-w-[24px]" />
        <div class="max-w-[280px] h-fit break-all">
          {{ file.fileName }}
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <button
          class="mr-2 w-6 h-6 text-aux-blue flex justify-center items-center"
          type="button"
          auxTooltip="Download"
          (click)="onDownload(file)"
        >
          <aux-icon name="Download" />
        </button>
        <button type="button" class="w-6 h-6 justify-center items-center" (click)="onRemove(file)">
          <aux-icon class="text-aux-error cursor-pointer" name="Trash" />
        </button>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #EDCTemplate>
  <section
    *ngFor="let file of files$ | async; first as first"
    class="border-2 rounded-md min-h-12 p-2 relative mt-4 border-gray-200 flex items-center"
    [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex flex-col space-y-1 justify-between items-start">
        <div class="text-sm font-bold max-w-[280px] h-fit break-all">
          {{ file.fileName }}
        </div>
        <div class="flex flex-col space-y-1 text-sm italic text-aux-gray-dark-100">
          <div *ngIf="file.created_by && file.create_date && showUserAndDate" class="line-clamp-2">
            Uploaded By: {{ userFormatter(file.created_by) }}
            {{ file.create_date | date }}
          </div>
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <button
          class="mr-2 w-6 h-6 text-aux-blue flex justify-center items-center"
          type="button"
          auxTooltip="Download"
          (click)="onDownload(file)"
        >
          <aux-icon name="Download" />
        </button>
        <button
          type="button"
          class="w-6 h-6 justify-center items-center"
          [disabled]="file.created_by !== getloggedInUserSub() && file.uploaded"
          [ngClass]="{
            'cursor-not-allowed opacity-50':
              file.created_by !== getloggedInUserSub() && file.uploaded
          }"
          [auxTooltip]="
            file.created_by !== getloggedInUserSub() && file.uploaded
              ? 'You can only delete your own documents.'
              : ''
          "
          (click)="onRemove(file)"
        >
          <aux-icon class="text-aux-error" name="Trash" />
        </button>
      </div>
    </div>
  </section>
</ng-template>
