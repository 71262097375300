import { round, find, ONE_SECOND, noop } from '@datadog/browser-core';
import { isElementNode } from '../../../browser/htmlDomUtils';
import { supportPerformanceTimingEvent, RumPerformanceEntryType } from '../../../browser/performanceCollection';
import { getSelectorFromElement } from '../../getSelectorFromElement';
/**
 * Track the cumulative layout shifts (CLS).
 * Layout shifts are grouped into session windows.
 * The minimum gap between session windows is 1 second.
 * The maximum duration of a session window is 5 second.
 * The session window layout shift value is the sum of layout shifts inside it.
 * The CLS value is the max of session windows values.
 *
 * This yields a new value whenever the CLS value is updated (a higher session window value is computed).
 *
 * See isLayoutShiftSupported to check for browser support.
 *
 * Documentation:
 * https://web.dev/cls/
 * https://web.dev/evolving-cls/
 * Reference implementation: https://github.com/GoogleChrome/web-vitals/blob/master/src/getCLS.ts
 */
export function trackCumulativeLayoutShift(configuration, lifeCycle, callback) {
  if (!isLayoutShiftSupported()) {
    return {
      stop: noop
    };
  }
  var maxClsValue = 0;
  // if no layout shift happen the value should be reported as 0
  callback({
    value: 0
  });
  var window = slidingSessionWindow();
  var stop = lifeCycle.subscribe(0 /* LifeCycleEventType.PERFORMANCE_ENTRIES_COLLECTED */, function (entries) {
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
      var entry = entries_1[_i];
      if (entry.entryType === RumPerformanceEntryType.LAYOUT_SHIFT && !entry.hadRecentInput) {
        window.update(entry);
        if (window.value() > maxClsValue) {
          maxClsValue = window.value();
          var cls = round(maxClsValue, 4);
          var clsTarget = window.largestLayoutShiftTarget();
          var cslTargetSelector = void 0;
          if (clsTarget &&
          // Check if the CLS target have been removed from the DOM between the time we collect the target reference and when we compute the selector
          clsTarget.isConnected) {
            cslTargetSelector = getSelectorFromElement(clsTarget, configuration.actionNameAttribute);
          }
          callback({
            value: cls,
            targetSelector: cslTargetSelector
          });
        }
      }
    }
  }).unsubscribe;
  return {
    stop: stop
  };
}
function slidingSessionWindow() {
  var value = 0;
  var startTime;
  var endTime;
  var largestLayoutShift = 0;
  var largestLayoutShiftTarget;
  var largestLayoutShiftTime;
  return {
    update: function (entry) {
      var _a, _b;
      var shouldCreateNewWindow = startTime === undefined || entry.startTime - endTime >= ONE_SECOND || entry.startTime - startTime >= 5 * ONE_SECOND;
      if (shouldCreateNewWindow) {
        startTime = endTime = entry.startTime;
        value = entry.value;
        largestLayoutShift = 0;
        largestLayoutShiftTarget = undefined;
      } else {
        value += entry.value;
        endTime = entry.startTime;
      }
      if (entry.value > largestLayoutShift) {
        largestLayoutShift = entry.value;
        largestLayoutShiftTime = entry.startTime;
        if ((_a = entry.sources) === null || _a === void 0 ? void 0 : _a.length) {
          largestLayoutShiftTarget = (_b = find(entry.sources, function (s) {
            return !!s.node && isElementNode(s.node);
          })) === null || _b === void 0 ? void 0 : _b.node;
        } else {
          largestLayoutShiftTarget = undefined;
        }
      }
    },
    value: function () {
      return value;
    },
    largestLayoutShiftTarget: function () {
      return largestLayoutShiftTarget;
    },
    largestLayoutShiftTime: function () {
      return largestLayoutShiftTime;
    }
  };
}
/**
 * Check whether `layout-shift` is supported by the browser.
 */
export function isLayoutShiftSupported() {
  return supportPerformanceTimingEvent(RumPerformanceEntryType.LAYOUT_SHIFT);
}
