import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'aux-exchange-link',
  template: `
    @if (showExchangePageLink()) {
      <a class="btn--hyperlink" [routerLink]="[exchangeCurrencyPage]"> Exchange Rates </a>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class ExchangeLinkComponent {
  launchDarklyService = inject(LaunchDarklyService);

  exchangeCurrencyPage = `/${ROUTING_PATH.SETTINGS.INDEX}/${ROUTING_PATH.SETTINGS.EXCHANGE_RATES}`;

  showExchangePageLink = this.launchDarklyService.$select((flags) => flags.tab_exchange_rates);
}
