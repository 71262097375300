import { Query } from '@datorama/akita';
import { ClosingPageStore, ClosingPageState } from './closing-page.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ClosingPageQuery extends Query<ClosingPageState> {
  constructor(protected store: ClosingPageStore) {
    super(store);
  }
}
