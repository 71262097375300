import { ExpenseType } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ISetFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { round } from 'lodash-es';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { VisibleColumns } from './column-chooser-component/column-chooser.component';
import { TableConstants } from '@shared/constants/table.constants';
import { BehaviorSubject } from 'rxjs';
import { AuxExcelStyleKeys } from '@shared/utils';
import { BudgetCurrencyType } from '@models/budget-currency/budget-currency.model';
import { agBudgetCurrencyFormatter } from './utils/budget-formatters.utils';

export enum cellSize {
  xLarge = 150,
  large = 125,
  medium = 100,
  small = 75,
}

export const uomHide$ = new BehaviorSubject(true);

export const period_sorting = [
  Utils.SHORT_MONTH_NAMES[0],
  Utils.SHORT_MONTH_NAMES[1],
  Utils.SHORT_MONTH_NAMES[2],
  'Q1',
  Utils.SHORT_MONTH_NAMES[3],
  Utils.SHORT_MONTH_NAMES[4],
  Utils.SHORT_MONTH_NAMES[5],
  'Q2',
  Utils.SHORT_MONTH_NAMES[6],
  Utils.SHORT_MONTH_NAMES[7],
  Utils.SHORT_MONTH_NAMES[8],
  'Q3',
  Utils.SHORT_MONTH_NAMES[9],
  Utils.SHORT_MONTH_NAMES[10],
  Utils.SHORT_MONTH_NAMES[11],
  'Q4',
];

export const getCellClass =
  (selectedBudgetCurrencyType: BudgetCurrencyType) => (params: CellClassParams) => {
    if (
      selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR &&
      params?.node?.data?.contract_direct_cost_currency
    ) {
      return `budgetCost${params?.node?.data?.contract_direct_cost_currency}`;
    } else if (selectedBudgetCurrencyType === BudgetCurrencyType.USD) {
      return 'budgetCostUSD';
    }
    return 'budgetCostNoSymbol';
  };

const percentageFormatter = (val: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let fVal = Utils.zeroHyphen;
  if (val) {
    fVal = `${formatter.format(val)}%`;
  }
  return fVal;
};

const shouldHideUnits = (params: ValueFormatterParams): boolean => {
  return !!(
    params?.node?.aggData ||
    params?.data?.activity_name === 'Total' ||
    params?.data?.cost_category === 'Discount'
  );
};

export const actualsToDateColumnDef = (
  visible_columns: VisibleColumns['actuals_to_date'],
  selectedBudgetCurrencyType: BudgetCurrencyType
): ColDef | ColGroupDef => ({
  headerName: 'Actuals to Date',
  headerClass: ['ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark'],
  colId: 'WP',
  children: [
    {
      headerName: 'Unit(s)',
      field: 'wp_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      hide: !visible_columns.units,
      columnGroupShow: 'closed',
      headerClass: [
        'ag-header-align-center',
        AuxExcelStyleKeys.BORDER_LEFT,
        !visible_columns.costs && !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      cellClass: [
        'ag-cell-align-right',
        'budget-units',
        AuxExcelStyleKeys.BORDER_LEFT,
        !visible_columns.costs && !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.wp_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: [
        'ag-header-align-center',
        !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      field: 'wp_cost',
      hide: !visible_columns.costs,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      columnGroupShow: 'closed',
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
    },
    {
      headerName: '%',
      columnGroupShow: 'closed',
      field: 'wp_percentage',
      width: cellSize.small,
      minWidth: cellSize.small,
      hide: !visible_columns.perc,
      headerClass: [
        'ag-header-align-center',
        !visible_columns.units && !visible_columns.costs ? AuxExcelStyleKeys.BORDER_LEFT : '',
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      cellClass: [
        'ag-cell-align-right',
        'budget-percent',
        !visible_columns.units && !visible_columns.costs ? AuxExcelStyleKeys.BORDER_LEFT : '',
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
    },
  ],
});

export const rowGroupsColumnDef: ((ColDef | ColGroupDef) & {
  hideForAllVendorSelection?: boolean;
  children?: (ColDef & { hideForAllVendorSelection?: boolean })[];
})[] = [
  {
    headerName: 'Activity ID',
    headerClass: [AuxExcelStyleKeys.BORDER_LEFT],
    cellClass: [AuxExcelStyleKeys.BORDER_LEFT],
    field: 'activity_no',
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Vendor',
    field: 'vendor_name',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Cost Category',
    field: 'cost_category',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group0',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Label',
    field: 'display_label',
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Activities',
    field: 'activity_name_label',
    tooltipField: 'activity_name',
    valueFormatter: Utils.dashFormatter,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group1',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group2',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group3',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    headerName: 'Category',
    field: 'group4',
    rowGroup: true,
    hide: true,
    hideForAllVendorSelection: true,
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_QUOTE}::LATEST`,
    aggFunc: 'sum',
  },
  {
    hide: true,
    field: `${ExpenseType.EXPENSE_WP}::TO_DATE`,
    aggFunc: 'sum',
  },
  {
    field: 'contract_direct_cost_currency',
    aggFunc: 'first',
    hide: true,
  },
];

const getVarOverallBudgetPercent = (
  params: ValueGetterParams | ValueFormatterParams,
  compareToSelected?: string
) => {
  let percent = params.data?.var_percent;
  if (params.node?.aggData) {
    const { aggData } = params.node;
    const budgetSum = (compareToSelected ? aggData.snapshot_lre : aggData.baseline) || 0;
    const current_lre = aggData.current_lre || 0;
    const var_amount = current_lre - budgetSum;
    let var_percent = 0;
    if (budgetSum) {
      var_percent = budgetSum ? (var_amount / Math.abs(budgetSum)) * 100 : -100;
    }
    percent = round(var_percent, 2);
  }

  return percent;
};

export const overallBudgetColumnDef = (
  visible_columns: VisibleColumns['overall_budget'],
  selectedBudgetCurrencyType: BudgetCurrencyType,
  compareToSelected?: string
): ColDef | ColGroupDef => ({
  headerName: 'Overall Budget',
  headerClass: [
    'ag-header-align-center bg-aux-gray-dark gray-dark-header-group aux-black border-0 border-b-aux-gray-dark border-b-3',
  ],
  children: [
    {
      headerName: 'Unit of Measure',
      headerClass: ['ag-header-align-center', AuxExcelStyleKeys.BORDER_LEFT],
      field: 'uom',
      colId: 'uom',
      hide: uomHide$.getValue(),
      width: cellSize.large,
      minWidth: cellSize.large,
      suppressMenu: false,
      filter: true,
      filterParams: {
        showTooltips: true,
      } as ISetFilterParams,
      menuTabs: ['filterMenuTab'],
      tooltipField: 'uom',
      cellClass: [
        'text-left',
        'max-w',
        'whitespace-nowrap',
        'overflow-hidden',
        'text-ellipsis',
        TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
        AuxExcelStyleKeys.BORDER_LEFT,
      ],
      valueFormatter: (params) => params.value || '',
    },
    {
      headerName: 'Unit(s)',
      headerClass: [
        'ag-header-align-center',
        uomHide$.getValue() ? AuxExcelStyleKeys.BORDER_LEFT : '',
      ],
      field: 'unit_num',
      width: cellSize.large,
      minWidth: cellSize.large,
      hide: !visible_columns.units,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.unit_num);
      },
      cellClass: [
        'ag-cell-align-right',
        'budget-units',
        uomHide$.getValue() ? AuxExcelStyleKeys.BORDER_LEFT : '',
      ],
    },
    {
      headerName: 'Unit Cost',
      headerClass: [
        'ag-header-align-center',
        uomHide$.getValue() && !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
      ],
      field:
        selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR
          ? 'contract_unit_cost'
          : 'unit_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.unit_cost,
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return agBudgetCurrencyFormatter(selectedBudgetCurrencyType)(params);
      },
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        uomHide$.getValue() && !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
      ],
    },
    {
      headerName: 'Current (LRE)',
      headerClass: [
        'ag-header-align-center',
        uomHide$.getValue() && !visible_columns.units && !visible_columns.unit_cost
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
      ],
      field: 'current_lre',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.primary,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        uomHide$.getValue() && !visible_columns.units && !visible_columns.unit_cost
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
      ],
    },
    {
      ...TableConstants.dynamicColumnProps(compareToSelected || ''),
      field: 'snapshot_lre',
      minWidth: cellSize.xLarge,
      hide: !compareToSelected,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      headerClass: [
        uomHide$.getValue() &&
        !visible_columns.units &&
        !visible_columns.unit_cost &&
        !visible_columns.primary
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
        !visible_columns.original && !visible_columns.var_cost && !visible_columns.var_perc
          ? AuxExcelStyleKeys.BORDER_RIGHT
          : '',
      ],
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        uomHide$.getValue() &&
        !visible_columns.units &&
        !visible_columns.unit_cost &&
        !visible_columns.primary
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
        !visible_columns.original && !visible_columns.var_cost && !visible_columns.var_perc
          ? AuxExcelStyleKeys.BORDER_RIGHT
          : '',
      ],
    },
    {
      headerName: 'Baseline',
      headerClass: [
        'ag-header-align-center',
        uomHide$.getValue() &&
        !visible_columns.units &&
        !visible_columns.unit_cost &&
        !visible_columns.primary &&
        !compareToSelected
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
        !visible_columns.var_cost && !visible_columns.var_perc
          ? AuxExcelStyleKeys.BORDER_RIGHT
          : '',
      ],
      field: 'baseline',
      hide: !visible_columns.original || !!compareToSelected,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        uomHide$.getValue() &&
        !visible_columns.units &&
        !visible_columns.unit_cost &&
        !visible_columns.primary &&
        !compareToSelected
          ? AuxExcelStyleKeys.BORDER_LEFT
          : '',
        !visible_columns.var_cost && !visible_columns.var_perc
          ? AuxExcelStyleKeys.BORDER_RIGHT
          : '',
      ],
    },
    {
      headerName: 'Var ($)',
      headerClass: [
        'ag-header-align-center',
        !visible_columns.var_perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      field: `var_amount`,
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.var_cost,
      cellRenderer: VariationStatusComponent,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        !visible_columns.var_perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
    },
    {
      headerName: 'Var (%)',
      headerClass: ['ag-header-align-center', AuxExcelStyleKeys.BORDER_RIGHT],
      field: `var_percent`,
      width: cellSize.medium,
      minWidth: cellSize.medium,
      hide: !visible_columns.var_perc,
      cellRenderer: VariationStatusComponent,
      valueGetter: (params) => getVarOverallBudgetPercent(params, compareToSelected),
      valueFormatter: (params) =>
        percentageFormatter(Math.abs(getVarOverallBudgetPercent(params, compareToSelected))),
      cellClass: ['ag-cell-align-right', 'budget-percent-no-mult', AuxExcelStyleKeys.BORDER_RIGHT],
    },
  ],
});

export const remainingBudgetColDef = (
  visible_columns: VisibleColumns['remaining_budget'],
  selectedBudgetCurrencyType: BudgetCurrencyType
): ColDef | ColGroupDef => ({
  headerName: 'Remaining Budget',
  headerClass: [
    'ag-header-align-center bg-aux-gray-dark aux-black gray-dark-header-group border-0',
  ],
  children: [
    {
      headerName: 'Unit(s)',
      field: 'remaining_unit_num',
      width: cellSize.medium,
      minWidth: cellSize.medium,
      headerClass: [
        'ag-header-align-center',
        AuxExcelStyleKeys.BORDER_LEFT,
        !visible_columns.costs && !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      hide: !visible_columns.units,
      cellClass: [
        'ag-cell-align-right',
        'budget-units',
        AuxExcelStyleKeys.BORDER_LEFT,
        !visible_columns.costs && !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      valueFormatter: (params) => {
        const bool = shouldHideUnits(params);
        if (bool) {
          return Utils.zeroHyphen;
        }

        return Utils.decimalFormatter(params.data?.remaining_unit_num);
      },
    },
    {
      headerName: '$',
      headerClass: [
        'ag-header-align-center',
        !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
      field: 'remaining_cost',
      width: cellSize.xLarge,
      minWidth: cellSize.xLarge,
      hide: !visible_columns.costs,
      valueFormatter: agBudgetCurrencyFormatter(selectedBudgetCurrencyType),
      aggFunc: 'sum',
      cellClass: (p) => [
        getCellClass(selectedBudgetCurrencyType)(p),
        !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        !visible_columns.perc ? AuxExcelStyleKeys.BORDER_RIGHT : '',
      ],
    },
    {
      headerName: '%',
      headerClass: [
        'ag-header-align-center',
        !visible_columns.costs && !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      valueFormatter: ({ value }) => Utils.percentageFormatter(value / 100),
      field: 'remaining_percentage',
      hide: !visible_columns.perc,
      cellClass: [
        'ag-cell-align-right',
        'budget-percent',
        !visible_columns.costs && !visible_columns.units ? AuxExcelStyleKeys.BORDER_LEFT : '',
        AuxExcelStyleKeys.BORDER_RIGHT,
      ],
      width: cellSize.small,
      minWidth: cellSize.small,
    },
  ],
});
