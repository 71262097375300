import { Component } from '@angular/core';
import { ICellRenderer, ICellRendererParams } from '@ag-grid-community/core';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  ForecastTableGridDataInterface,
  ForecastTableGridParentComponentContext,
} from '../../models/forecast-table-grid.model';
import { ForecastTableGridComponent } from '../../forecast-table-grid.component';
import { DriverType } from '@shared/services/gql.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'aux-forecast-table-grid-driver',
  templateUrl: './forecast-table-grid-driver.component.html',
  styleUrls: ['./forecast-table-grid-driver.component.scss'],
})
export class ForecastTableGridDriverComponent
  extends AgCellWrapperComponent
  implements ICellRenderer
{
  params!: ICellRendererParams;

  context!: ForecastTableGridParentComponentContext;

  componentParent!: ForecastTableGridComponent;

  data!: ForecastTableGridDataInterface;

  value!: string;

  autoTestAttribute!: string;

  disableDriver = false;

  mapOptionAvailable = new Map<DriverType, Observable<boolean>>([]);

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.componentParent = this.context.componentParent;

    this.mapOptionAvailable = new Map<DriverType, Observable<boolean>>([
      [DriverType.DRIVER_CUSTOM, this.context.forecastQuery.select('hasCustomCurves')],
      [DriverType.DRIVER_PATIENT, this.context.forecastQuery.select('hasPatientCurves')],
      [DriverType.DRIVER_SITE, this.context.forecastQuery.select('hasSiteCurves')],
      [DriverType.DRIVER_TIME, of(true)],
    ]);

    this.data = this.params.data;
    if (this.data) {
      this.data.driver = this.params.data.driver || undefined;
    }

    if (!this.componentParent.userHasModifyPermissions) {
      this.disableDriver = true;
    }

    this.value = this.params.value;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  getOptionAvailableTooltip(driverType: DriverType): string {
    const mapTooltipLabel = new Map<DriverType, string>([
      [DriverType.DRIVER_CUSTOM, 'Custom Curves'],
      [DriverType.DRIVER_PATIENT, 'Patient Curves'],
      [DriverType.DRIVER_SITE, 'Site Curves'],
    ]);

    const label = mapTooltipLabel.get(driverType);

    return label ? `No ${label} exist.` : '';
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }
}
