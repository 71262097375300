import {
  AmountType,
  Approval,
  Currency,
  DataSource,
  InvoiceStatus,
  PaymentStatus,
  Note,
} from '@shared/services/gql.service';

export interface InvoiceCardLine {
  note?: string;
  type: string;
  label?: string;
  status?: string;
  text?: string;
}

export interface InvoiceCard {
  id: string;
  header: string;
  note: string;
  status: 'Complete' | 'In Process' | 'Up Next';
  lines: Array<InvoiceCardLine>;
}

export interface InvoiceAmounts {
  invoice_total: {
    value: number;
    type: AmountType | string;
    contract_curr: Currency;
    is_vendor_currency_amount: boolean;
  };
  investigator_total: {
    value: number;
    type: AmountType | string;
    contract_curr: Currency;
    is_vendor_currency_amount: boolean;
  };
  services_total: {
    value: number;
    type: AmountType | string;
    contract_curr: Currency;
    is_vendor_currency_amount: boolean;
  };
  discount_total: {
    value: number;
    type: AmountType | string;
    contract_curr: Currency;
    is_vendor_currency_amount: boolean;
  };
  pass_thru_total: {
    value: number;
    type: AmountType | string;
    contract_curr: Currency;
    is_vendor_currency_amount: boolean;
  };
  invoice_total_trial_currency: {
    value: number;
    type: AmountType | string;
    is_vendor_currency_amount: boolean;
  };
  investigator_total_trial_currency: {
    value: number;
    type: AmountType | string;
    is_vendor_currency_amount: boolean;
    exchange_rate?: number;
  };
  services_total_trial_currency: {
    value: number;
    type: AmountType | string;
    is_vendor_currency_amount: boolean;
    exchange_rate?: number;
  };
  discount_total_trial_currency: {
    value: number;
    type: AmountType | string;
    is_vendor_currency_amount: boolean;
    exchange_rate?: number;
  };
  pass_thru_total_trial_currency: {
    value: number;
    type: AmountType | string;
    is_vendor_currency_amount: boolean;
    exchange_rate?: number;
  };
}

export interface InvoiceModel {
  id: string;
  organization: {
    id: string;
    name: string;
    currency: Currency;
  };
  accrual_period: string | null;
  services_period: string | null;
  invoice_date: string | null;
  invoice_no: string;
  invoice_status: InvoiceStatus;
  data_source_id: DataSource;
  line_items?: InvoiceLineItem[] | null;
  ocr_line_items?: InvoiceLineItem[] | null;
  admin_review_reason?: string | null;
  decline_reason: string | null;
  delete_reason: string;
  payment_status?: PaymentStatus | null;
  payment_date: string | null;
  po_reference: string | null;
  due_date: string | null;
  expense_amounts: InvoiceAmounts;
  approvals: Approval[];
  cards: InvoiceCard[];
  is_deposit: boolean;
  notes: Note[] | null;
  has_prepaid: boolean;
  does_invoice_mappings_match_total: boolean;
  audit_record_create: boolean | null;
  previous_services_period?: string | null;
}

export interface InvoiceLineItem {
  amount?: number;
  Amount?: number;
  netAmountIncludingTax?: number;
  Description?: string;
  description?: string;
}

export const INVOICE_STATUSES = {
  STATUS_IN_QUEUE: 'In Queue',
  STATUS_PENDING_REVIEW: 'Pending Review',
  STATUS_PENDING_APPROVAL: 'Pending Approval',
  STATUS_APPROVED: 'Approved',
  STATUS_DECLINED: 'Declined',
} as { [k in InvoiceStatus]: string };
