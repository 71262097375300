<div style="width: 600px">
  <form [formGroup]="documentForm" (ngSubmit)="onUpload()">
    <aux-modal
      primaryButtonType="submit"
      [primaryButtonDisabled]="(uploadedFiles$ | async)?.length === 0 || loading()"
      [loading]="loading()"
    >
      <div class="grid grid-cols-3 gap-3 mb-4">
        <div>
          <div class="mb-2 text-sm">Document Type:</div>
          <ng-select
            placeholder="Select"
            id="documentType"
            formControlName="documentType"
            [appendTo]="'body'"
            [items]="documentTypeList"
            bindValue="value"
            bindLabel="label"
          />
        </div>
        <div>
          <div class="mb-2 text-sm">Vendor:</div>
          <ng-select
            placeholder="Select"
            id="vendor"
            formControlName="vendor"
            [appendTo]="'body'"
            [items]="vendorsList"
            bindValue="value"
            bindLabel="label"
          />
        </div>
        <aux-site-select-dropdown
          [multiple]="false"
          formControlName="site"
          menuPosition="left"
          titleKey="name"
          menuPanelWidth="full"
          [appendTo]="'body'"
        />
      </div>
      <aux-file-manager
        #fileManager
        class="h-28 bg-aux-gray-light"
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
      >
        <div class="flex items-center justify-center text-aux-gray-dark-100">
          <aux-icon name="Upload" />
          <div class="ml-3">
            <p class="text-lg">
              Drag & Drop Documents Here or
              <span
                class="text-aux-blue-link group-hover:text-aux-blue group-active:text-aux-blue-dark"
                >Browse</span
              >
            </p>
          </div>
        </div>
      </aux-file-manager>
      <aux-file-manager-uploaded-files [fileManager]="fileManager" />
    </aux-modal>
  </form>
</div>
