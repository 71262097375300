import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { ExportType, Utils } from '@shared/utils/utils';
import { differenceWith, isBoolean, isEqual } from 'lodash-es';
import {
  ColumnResizedEvent,
  ColumnState,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  RowGroupOpenedEvent,
  RowNode,
} from '@ag-grid-community/core';
import { MilestoneCategoryService } from '@models/milestone-category/milestone-category.service';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import { AgActionsComponent } from '@shared/ag-components/ag-actions/ag-actions.component';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { AuthService } from '@shared/store/auth/auth.service';
import {
  CreateTimelineMilestoneInput,
  EntityType,
  EventType,
  GqlService,
  listTimelineMilestonesQuery,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import {
  AgCellWrapperComponent,
  getWrapperCellOptions,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  TimelineService,
  UpdateDependencyInput,
  UpdateTimelineMilestone,
} from './state/timeline.service';
import { TimelineQuery } from './state/timeline.query';
import { TimelineDialogComponent } from './timeline-dialog/timeline-dialog.component';
import { TimelineDialogFormValue } from './timeline-dialog/timeline-dialog.model';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { TableConstants } from '@shared/constants/table.constants';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { MainQuery } from '@shared/store/main/main.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import {
  RemoveDialogComponent,
  RemoveDialogInput,
} from '@shared/components/remove-dialog/remove-dialog.component';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(isBetween);
dayjs.extend(minMax);
import { EventQuery } from '@models/event/event.query';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { AsyncPipe, NgClass } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AgGridAngular } from '@ag-grid-community/angular';
import { WorkflowPanelComponent } from '@features/workflow-panel/workflow-panel.component';
import { SaveChangesComponent } from '@features/save-changes/save-changes.component';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { EventService } from '@models/event/event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import {
  ConfirmationActionModalComponent,
  ConfirmationActionModalData,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';

export interface TimelineGridData {
  milestone_category_id: string;
  milestone_category_name: string;
  milestone_name: string;
  milestone_id: string;
  organizations_with_forecasts: { id: string; name: string }[];
  track_from_milestone_name: string;
  track_from_milestone_id: string | null;
  contract_start_date: string;
  contract_end_date: string;
  contract_month_difference: number | null;
  revised_start_date: string | null;
  revised_end_date: string | null;
  actual_start_date: string | null;
  actual_end_date: string | null;
  description: string | null;
  id: string;
}

@Component({
  selector: 'aux-timeline',
  templateUrl: './timeline.component.html',
  imports: [
    AsyncPipe,
    InputComponent,
    FormsModule,
    ButtonComponent,
    TooltipDirective,
    NgClass,
    AgGridAngular,
    WorkflowPanelComponent,
    SaveChangesComponent,
    ExportExcelButtonComponent,
    StickyGridDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TimelineComponent {
  private readonly destroyRef = inject(DestroyRef);

  readonly messagesConstants = MessagesConstants;

  gridData$ = new BehaviorSubject<TimelineGridData[]>([]);

  previousGridData: TimelineGridData[] = [];

  expandedState: Record<string, boolean> = {};

  userHasModifyPermissions$ = new BehaviorSubject<boolean>(false);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_TIMELINE;

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  isTimeLineFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType$(this.workflowName);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  hasChanges$ = new BehaviorSubject(false);

  saving = false;

  valuesBeforeRemove: TimelineGridData[] = [];

  createInputs: Omit<CreateTimelineMilestoneInput, 'timeline_id'>[] = [];

  updateInputs: UpdateTimelineMilestone[] = [];

  updateDependencyInputs: UpdateDependencyInput[] = [];

  deleteInputs: string[] = [];

  disabledStateTooltipText$ = new BehaviorSubject('');

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  disabled$ = combineLatest([this.userHasModifyPermissions$, this.isTimeLineFinalized$]).pipe(
    map(([hasPermission, isFinalized]) => {
      const isAdmin = this.authQuery.getValue().is_admin;
      if (isFinalized) {
        this.disabledStateTooltipText$.next(this.messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE);
      } else if (!hasPermission && !isAdmin) {
        this.disabledStateTooltipText$.next(
          this.messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
        );
      } else {
        this.disabledStateTooltipText$.next('');
      }
      return isFinalized || (!hasPermission && !isAdmin);
    })
  );

  gridAPI!: GridApi;

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      minWidth: TableConstants.ACTIONS_WIDTH,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
    suppressAutoSize: true,
    initialGroupOrderComparator: (params) => {
      const a = this.milestoneCategoryQuery.getEntity(params.nodeA.key)?.grouping_order || 0;
      const b = this.milestoneCategoryQuery.getEntity(params.nodeB.key)?.grouping_order || 0;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    columnDefs: [
      {
        headerName: '',
        field: 'actions',
        getQuickFilterText: () => '',
        cellRendererSelector: (params: ICellRendererParams) => {
          return params.node.group || !params.node.parent ? '' : { component: AgActionsComponent };
        },
        cellRendererParams: {
          processing$: this.iCloseMonthsProcessing$,
          disabled$: this.disabled$,
          editClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onUpdateLine(rowNode);
          },
          hideDeleteButton: false,
          deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
            this.onRemoveLine(rowNode);
          },
          tooltipText$: this.disabledStateTooltipText$,
        },
        editable: false,
        width: TableConstants.ACTIONS_WIDTH,
        cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Category',
        field: 'milestone_category_name',
        getQuickFilterText: () => '',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'milestone_name',
        minWidth: 125,
        width: 250,
        resizable: true,
        tooltipField: 'milestone_name',
        cellClass: 'text-left',
        showRowGroup: true,
        cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
        ...getWrapperCellOptions(),
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        getQuickFilterText: () => '',
        minWidth: 125,
        width: 250,
        resizable: true,
        tooltipField: 'description',
        cellClass: 'text-left',
      },
      {
        headerName: 'Track From',
        field: 'track_from_milestone_name',
        getQuickFilterText: () => '',
        minWidth: 100,
        width: 200,
        resizable: true,
        tooltipField: 'track_from_milestone_name',
        cellClass: 'text-left',
      },
      {
        headerName: 'Months',
        field: 'contract_month_difference',
        getQuickFilterText: () => '',
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
        minWidth: 100,
        width: 150,
        resizable: true,
      },
      {
        headerName: 'Start Date',
        getQuickFilterText: () => '',
        minWidth: 105,
        width: 150,
        resizable: true,
        field: 'startDate',
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_start_date, revised_start_date, actual_start_date } = val.data;

          if (actual_start_date) return `${actual_start_date}`;
          if (revised_start_date) return `${revised_start_date}`;
          return `${contract_start_date}`;
        },
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
      {
        headerName: 'End Date',
        getQuickFilterText: () => '',
        minWidth: 105,
        width: 150,
        resizable: true,
        field: 'endDate',
        valueFormatter: (val) => {
          if (!val.data) {
            return '';
          }
          const { contract_end_date, revised_end_date, actual_end_date } = val.data;

          if (actual_end_date) return `${actual_end_date}`;
          if (revised_end_date) return `${revised_end_date}`;
          return `${contract_end_date}`;
        },
        cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
      },
    ],
    getRowClass: Utils.oddEvenRowClass,
  } as GridOptions;

  nameFilterValue = '';

  userHasLockTrialTimelinePermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHECKLIST_TRIAL_TIMELINE],
  });

  loadingTimelineQuery = false;

  lSColumnState: ColumnState[] = [];

  timelineMonths: string[] = [];
  timelineMonthsRelatedToMilestone: Record<string, TimelineGridData[]> = {};

  constructor(
    private timelineService: TimelineService,
    public timelineQuery: TimelineQuery,
    private milestoneCategoryService: MilestoneCategoryService,
    private milestoneQuery: MilestoneQuery,
    private milestoneCategoryQuery: MilestoneCategoryQuery,
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private workflowQuery: WorkflowQuery,
    private authService: AuthService,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private gqlService: GqlService,
    private eventQuery: EventQuery,
    private eventService: EventService
  ) {
    this.getStartDate();
    this.initTimelineListValue();

    this.lSColumnState = this.getColumnStatesFromLS();

    this.timelineQuery
      .select()
      .pipe(takeUntilDestroyed())
      .subscribe(({ items }) => {
        this.gridData$.next(this.convertToTimelineGridData(items));
        this.calculateMonths();
      });

    this.timelineQuery
      .selectLoading()
      .pipe(takeUntilDestroyed())
      .subscribe((value) => (this.loadingTimelineQuery = value));

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_TRIAL_TIMELINE],
      })
      .pipe(takeUntilDestroyed())
      .subscribe((x) => {
        this.userHasModifyPermissions$.next(x);
        this.setColumnState();
      });

    this.gridData$.pipe(takeUntilDestroyed()).subscribe((gridData) => {
      if (this.valuesBeforeRemove.length) {
        setTimeout(() => {
          this.gridAPI?.setGridOption('rowData', this.valuesBeforeRemove);
          this.valuesBeforeRemove = [];
        }, 0);
      }

      if (!this.hasChanges$.getValue()) {
        this.gridAPI?.setGridOption('rowData', gridData);
      }

      this.checkChanges();
    });

    this.isTimeLineFinalized$.pipe(takeUntilDestroyed()).subscribe();
  }

  addTimelineMilestone = async () => {
    const ref = await firstValueFrom(
      this.overlayService.openPopup({
        modal: TimelineDialogComponent,
        settings: {
          header: 'Add Timeline Milestone',
          primaryButton: {
            label: 'Add',
          },
        },
        data: {
          mode: 'add',
          forbiddenNames: this.gridData$.value.map((x) => x.milestone_name),
        },
      }).afterClosed$
    );

    if (ref.data) {
      this.createInputs.push(ref.data);
    }
  };

  async onRemoveLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const { id, milestone_name, milestone_id, organizations_with_forecasts } =
      rowNode.data as TimelineGridData;

    const canDeleteMilestone = this.getDateConstraints(rowNode.data);

    const { items } = this.timelineQuery.getValue();
    const tracking_milestones = items.filter((x) => x.track_from_milestone?.id === milestone_id);
    if (
      organizations_with_forecasts.length > 0 ||
      canDeleteMilestone.startDate ||
      canDeleteMilestone.endDate
    ) {
      const cannotDeleteMessage = `${milestone_name} cannot be deleted because it is used in Forecast Methodology.<br>Please first remove the dependencies below and then ${milestone_name} can be successfully deleted.<br>`;
      const routeInputs = [
        {
          componentName: 'Forecast Methodology:',
          name: organizations_with_forecasts.map(({ name }) => name),
          link: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
        },
      ];

      const removeDialogInput: RemoveDialogInput = {
        cannotDeleteMessage,
        routeInputs,
        canDeleteMilestone,
      };

      const resp = this.overlayService.openPopup({
        content: RemoveDialogComponent,
        data: removeDialogInput,
        settings: {
          header: 'Remove Milestone',
          primaryButton: {
            disabled: true,
            label: 'Remove',
          },
        },
      });

      await firstValueFrom(resp.afterClosed$);
    } else if (tracking_milestones?.length > 0) {
      let milestoneNames = '';
      for (const tracking_milestone of tracking_milestones) {
        milestoneNames += `- ${tracking_milestone.milestone.name}<br>`;
      }

      const resp = await firstValueFrom(
        this.overlayService.openPopup<
          ConfirmationActionModalData,
          boolean,
          ConfirmationActionModalComponent
        >({
          modal: ConfirmationActionModalComponent,
          settings: {
            header: 'Remove Milestone',
            primaryButton: {
              label: 'Remove',
            },
          },
          data: {
            message: `Are you sure that you want to remove ${milestone_name}? The following milestones will no longer track this milestone:<br>${milestoneNames}`,
            skipKeywordConfirmation: true,
            hideConfirmationHint: true,
          },
        }).afterClosed$
      );
      if (resp.data) {
        for (const tracking_milestone of tracking_milestones) {
          const milestone = this.milestoneQuery.getEntity(tracking_milestone.milestone.id);
          const input: UpdateTimelineMilestone = {
            id: tracking_milestone.id,
            name: tracking_milestone.milestone.name,
            milestone_category_id: tracking_milestone.milestone.milestone_category_id,
            milestone_id: tracking_milestone.milestone.id,
            description: milestone?.description || null,
            track_from_milestone_id: null,
            actual_end_date: tracking_milestone.actual_end_date,
            actual_start_date: tracking_milestone.actual_start_date,
            contract_end_date: tracking_milestone.contract_end_date,
            contract_start_date: tracking_milestone.contract_start_date,
            revised_end_date: tracking_milestone.revised_end_date,
            revised_start_date: tracking_milestone.revised_start_date,
          };
          await this.timelineService.updateTimelineMilestone(input);
          this.updateInputs.push(input);
        }
        this.gridAPI.applyTransaction({ remove: [rowNode] });
        await this.timelineService.deleteTimelineMilestone(id);
        this.deleteInputs.push(id);
        this.hasChanges$.next(true);
      }
    } else {
      this.overlayService.openPopup<
        { message: string },
        boolean | undefined,
        ConfirmationModalComponent
      >({
        content: ConfirmationModalComponent,
        data: {
          message: `Are you sure that you want to remove ${milestone_name}?`,
        },
        settings: {
          header: 'Remove Milestone',
          primaryButton: {
            label: 'Remove',
            action: async (instance) => {
              instance?.ref.close();
              this.gridAPI.applyTransaction({ remove: [rowNode] });
              await this.timelineService.deleteTimelineMilestone(id);
              this.deleteInputs.push(id);
              this.hasChanges$.next(true);
            },
          },
        },
      });
    }
  }

  onUpdateLine(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }

    const {
      actual_end_date,
      actual_start_date,
      contract_end_date,
      contract_start_date,
      milestone_category_id,
      track_from_milestone_id,
      revised_end_date,
      revised_start_date,
      milestone_name,
      description,
      id,
      milestone_id,
    } = rowNode.data as TimelineGridData;

    const { startDate, endDate } = this.getDateConstraints(rowNode.data as TimelineGridData);

    const ref = this.overlayService.openPopup<
      {
        mode: 'edit' | 'add';
        forbiddenNames: string[];
        formValue?: TimelineDialogFormValue;
        timeline_milestone_id?: string;
        milestone_id?: string;
        startDate: string | null;
        endDate: string | null;
      },
      { update: UpdateTimelineMilestone; updateDependency: UpdateDependencyInput[] }
    >({
      modal: TimelineDialogComponent,
      settings: {
        header: 'Edit Timeline Milestone',
        primaryButton: {
          label: 'Update',
        },
      },
      data: {
        mode: 'edit',
        forbiddenNames: this.gridData$.value
          .filter((x) => x.milestone_name !== milestone_name)
          .map((x) => x.milestone_name),
        formValue: {
          id,
          description,
          name: milestone_name,
          milestone_id,
          milestone_category_id,
          revised_end_date,
          revised_start_date,
          contract_start_date,
          contract_end_date,
          actual_end_date,
          actual_start_date,
          track_from_milestone_id,
        },
        milestone_id,
        timeline_milestone_id: id,
        startDate,
        endDate,
      },
    });

    ref.afterClosed$.subscribe(async (resp) => {
      if (resp.data) {
        this.updateInputs.push(resp.data.update);
        this.updateDependencyInputs.push(...resp.data.updateDependency);
      }
    });
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    this.setColumnState();
  }

  onDataRendered({ api }: { api: GridApi }) {
    api.forEachNode((node) => {
      if (node.key && !isBoolean(this.expandedState[node.key])) {
        node.setExpanded(true);
      }
    });
    this.setColumnState();
  }

  onGroupOpened({ expanded, node }: RowGroupOpenedEvent) {
    if (node.key) {
      this.expandedState[node.key] = expanded;
    }
  }

  onRowDataChanged() {
    const gridData = this.gridData$.value;
    const changedMilestoneCategoryId = differenceWith(gridData, this.previousGridData, isEqual)[0]
      ?.milestone_category_id;
    this.gridAPI?.forEachNode((rowNode: IRowNode) => {
      if (rowNode.key) {
        const currentExpandedState = isBoolean(this.expandedState[rowNode.key])
          ? this.expandedState[rowNode.key]
          : true;
        const expanded = rowNode.key === changedMilestoneCategoryId ? true : currentExpandedState;

        rowNode.setExpanded(expanded);
      }
    });

    this.setColumnState();
    this.checkChanges();
    this.previousGridData = gridData;
  }

  sizeColumnsToFit(): void {
    this.gridAPI?.sizeColumnsToFit();
  }

  checkChanges() {
    this.hasChanges$.next(
      this.createInputs.length > 0 ||
        this.updateInputs.length > 0 ||
        this.updateDependencyInputs.length > 0 ||
        this.deleteInputs.length > 0
    );
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onSaveAll = async () => {
    this.saving = true;

    if (this.deleteInputs.length > 0) {
      this.deleteInputs.forEach((x) => {
        this.timelineService.deleteTimelineMilestone(x);
      });
    }

    const resp = await this.timelineService.saveChanges(
      this.createInputs,
      this.updateInputs,
      this.updateDependencyInputs,
      this.deleteInputs,
      this.gridData$.getValue()
    );

    if (resp.success) {
      this.overlayService.success(`Successfully Saved`);
      this.timelineService.getTimelineItems().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    } else if (resp.errors.length > 0) {
      this.overlayService.error(resp.errors);
      // allow time for the user to see the error message before refreshing
      await new Promise((resolve) => setTimeout(resolve, 2000));

      location.reload();
    } else {
      this.overlayService.error(`An error has occurred`);
    }

    this.createInputs = [];
    this.updateInputs = [];
    this.updateDependencyInputs = [];
    this.deleteInputs = [];
    this.saving = false;
    this.hasChanges$.next(false);
  };

  saveColumnStates() {
    const api = this.gridAPI;
    const columnStates = api.getColumnState();
    localStorage.setItem(LocalStorageKey.COLUMN_STATES, JSON.stringify(columnStates));
  }

  onColumnResized(event: ColumnResizedEvent) {
    if (event.source === 'uiColumnResized' && event.finished) {
      this.saveColumnStates();
    }
  }

  getColumnStatesFromLS() {
    const stringForColumnStates = localStorage.getItem(LocalStorageKey.COLUMN_STATES);
    const presetColumnStates = stringForColumnStates
      ? (JSON.parse(stringForColumnStates) as ColumnState[])
      : [];
    return presetColumnStates;
  }

  setColumnState() {
    const api = this.gridAPI;
    if (api) {
      const presetColumnStates = this.lSColumnState;
      if (presetColumnStates.length) {
        // normally it should work with just applying the previous column states, but for some
        // reason the grid does not apply the widths of the columns so we have to apply them manually.
        // Just storing and applying widths also does not work, since grid adjusted itself to fit columns to its width,
        // therefore any adjustment applied to widths ruins the grid layout
        api.applyColumnState({ state: presetColumnStates, applyOrder: true });
        presetColumnStates.forEach((colState) => {
          if (colState.width && !colState.hide) {
            api.getColumn(colState.colId)?.setActualWidth(colState.width);
          }
        });
      } else {
        api.sizeColumnsToFit();
      }
    }
  }

  reset(): void {
    this.createInputs = [];
    this.updateInputs = [];
    this.updateDependencyInputs = [];
    this.deleteInputs = [];
    this.hasChanges$.next(false);
    this.initTimelineListValue();
  }

  private initTimelineListValue(): void {
    combineLatest([this.timelineService.getTimelineItems(), this.milestoneCategoryService.get()])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([timelineResponse]) => {
        this.gridData$.next(this.convertToTimelineGridData(timelineResponse?.data || []));
        this.calculateMonths();
      });
  }

  private convertToTimelineGridData(items: listTimelineMilestonesQuery[]): TimelineGridData[] {
    const gridData: TimelineGridData[] = [];

    items.forEach((item) => {
      const track_milestone = this.milestoneQuery.getEntity(item.track_from_milestone?.id);
      const milestone = this.milestoneQuery.getEntity(item.milestone.id);
      const milestone_category_name = this.milestoneCategoryQuery.getEntity(
        item.milestone.milestone_category_id
      );
      const data: TimelineGridData = {
        id: item.id,
        milestone_category_id: item.milestone.milestone_category_id,
        milestone_category_name: milestone_category_name?.name || '',
        milestone_name: milestone?.name || item.milestone.name || '',
        milestone_id: item.milestone.id,
        organizations_with_forecasts: item.milestone.organizations_with_forecasts,
        track_from_milestone_name: track_milestone?.name || '',
        track_from_milestone_id: item.track_from_milestone?.id || null,
        contract_start_date: item.contract_start_date,
        contract_end_date: item.contract_end_date,
        contract_month_difference: item.contract_month_difference || null,
        revised_start_date: item.revised_start_date || null,
        revised_end_date: item.revised_end_date || null,
        actual_start_date: item.actual_start_date || null,
        actual_end_date: item.actual_end_date || null,
        description: milestone?.description || null,
      };
      gridData.push(data);
    });

    return gridData;
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportTimeline() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.TIMELINE,
          filename: `${trialName}_auxilius-timeline__${dateStr}`,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async getStartDate() {
    const { data } = await firstValueFrom(
      this.gqlService.listTrialExpenseTimeline$({
        expense_type_id: 'EXPENSE_WP',
        by_vendor: false,
        nz_expenses_only: true,
      })
    );
    if (data?.length) {
      this.timelineMonths = data.map((x) => x.timeline_month);
      this.calculateMonths();
    }
  }

  calculateMonths() {
    const milestones = this.gridData$.getValue();
    const obj: Record<string, TimelineGridData[]> = {};

    // for each month with actuals, get all milestones that are in that month
    this.timelineMonths.forEach((month) => {
      obj[month] = milestones.filter((x) => {
        if (x.contract_start_date && x.contract_end_date) {
          return dayjs(month).isBetween(x.contract_start_date, x.contract_end_date, 'month', '[]');
        }
        return false;
      });
    });

    this.timelineMonthsRelatedToMilestone = obj;
  }

  getCanDeleteMilestone({
    contract_start_date,
    contract_end_date,
    milestone_id,
  }: TimelineGridData) {
    const arr = this.timelineMonths
      .map((month) => {
        // do we have any actual month between the contract start and end date
        if (dayjs(month).isBetween(contract_start_date, contract_end_date, 'month', '[]')) {
          // if so, do we have any other milestone in that month
          const milestones = this.timelineMonthsRelatedToMilestone[month].filter(
            (x) => x.milestone_id !== milestone_id
          );
          // if we have any other milestone in that month, we can delete this milestone
          return milestones.length ? '' : dayjs(month).endOf('month').format('YYYY-MM-DD');
        }
        return '';
      })
      .filter(Boolean);

    // if we can't delete the milestone because of a month, return that month
    return arr.length ? arr[0] : '';
  }

  getDateConstraints(data: TimelineGridData): {
    startDate: string | null;
    endDate: string | null;
  } {
    const { contract_start_date, contract_end_date, milestone_id } = data;

    let startDate: string | null = null;
    let endDate: string | null = null;

    this.timelineMonths.forEach((month) => {
      // if the month is between the contract start and end date
      if (dayjs(month).isBetween(contract_start_date, contract_end_date, 'month', '[]')) {
        // get all milestones in that month that are not the current milestone
        const milestones = this.timelineMonthsRelatedToMilestone[month].filter(
          (x) => x.milestone_id !== milestone_id
        );
        // if there are no other milestones in that month set the start and end date
        if (!milestones.length) {
          startDate = startDate || dayjs(month).endOf('month').format('YYYY-MM-DD');
          endDate = month;
        }
      }
    });

    const milestones = this.gridData$.getValue();
    if (startDate) {
      for (const x of milestones) {
        if (x.milestone_id === milestone_id) {
          continue;
        }

        // if the milestone is before the start date, set the start date as null
        // this way we don't need to validate the start date
        // because if we change the start date, there is still another milestone that covers the timeline
        if (dayjs(x.contract_start_date).isBefore(startDate)) {
          startDate = null;
          break;
        }
      }
    }

    if (endDate) {
      for (const x of milestones) {
        if (x.milestone_id === milestone_id) {
          continue;
        }

        // if the milestone is after the end date, set the end date as null
        // this way we don't need to validate the end date
        // because if we change the end date, there is still another milestone that covers the timeline
        if (dayjs(x.contract_end_date).isAfter(endDate)) {
          endDate = null;
          break;
        }
      }
    }

    if (endDate == null) {
      const milestones = this.gridData$.getValue();
      const maxEndDate = dayjs.max(
        milestones
          .filter((x) => x.milestone_id !== milestone_id)
          .map((x) => dayjs(x.contract_end_date).startOf('month'))
      );

      if (maxEndDate) {
        for (const month of [...this.timelineMonths].reverse()) {
          if (this.timelineMonthsRelatedToMilestone[month].length === 0) {
            const monthDate = dayjs(month);
            if (monthDate.isAfter(maxEndDate)) {
              endDate = month;
              break;
            }
          }
        }
      }
    }

    return { startDate, endDate };
  }
}
