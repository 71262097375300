import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OverlayService } from '@shared/services/overlay.service';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export async function selectWithGuardChangesModalCheck(
  event: MouseEvent,
  value: string,
  hasUnsavedChanges: boolean,
  overlayService: OverlayService,
  formControl: FormControl,
  element?: NgSelectComponent
) {
  event.preventDefault();
  event.stopPropagation();

  if (value === formControl.value) {
    element?.close();
    return;
  }

  const showUnsavedChangesModal = async () => {
    if (hasUnsavedChanges) {
      const result = overlayService.openUnsavedChangesConfirmation();
      const eventModal = await firstValueFrom(result.afterClosed$);
      return !!eventModal.data;
    }
    return true;
  };
  const resp = await showUnsavedChangesModal();

  if (!resp) {
    return;
  }

  formControl.setValue(value);

  if (element) {
    element.changeEvent?.emit(value);
    element.isOpen = false;
    element.closeEvent?.emit(true);
    element.detectChanges();
  }
}
