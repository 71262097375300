import { NgClass } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SiteOption } from '@models/site-option.model';
import { SitesQuery } from '@models/sites/sites.query';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectDropdownComponent } from '@shared/components/multi-select-dropdown/multi-select-dropdown.component';
import {
  injectNgControl,
  NoopValueAccessorDirective,
} from '@shared/directives/noop-value-accessor.directive';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { isObject } from 'lodash-es';

@Component({
  selector: 'aux-site-select-dropdown',
  templateUrl: './site-select-dropdown.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  standalone: true,
  styleUrls: ['./site-select-dropdown.component.scss'],
  imports: [
    MultiSelectDropdownComponent,
    ReactiveFormsModule,
    NgClass,
    NgSelectModule,
    TooltipDirective,
  ],
})
export class SiteSelectDropdownComponent {
  siteQuery = inject(SitesQuery);

  ngControl = injectNgControl();

  loading = input(false);

  menuPosition = input<'left' | 'right'>('right');

  multiple = input(true);

  menuPanelWidth = input<'auto' | 'full'>('auto');

  titleKey = input<'site_no' | 'name'>('site_no');

  label = input('Site:');

  placeholder = input('Select');

  /**
   * Note: This works only for single site selection.
   */
  appendTo = input<string>('');

  siteOptions = this.siteQuery.selectSiteOptions();

  multipleDropdownClassNames = computed(() => {
    let classNames = 'site-dropdown';

    if (this.menuPanelWidth() === 'auto') {
      classNames += ' menu-auto-width ';
    }

    if (this.menuPosition() === 'left') {
      classNames += ' menu-left ';
    }

    return classNames.trim();
  });

  searchFn = (term: string, item: SiteOption) => {
    return item.textToSearch.toLocaleLowerCase().includes(term.toLocaleLowerCase());
  };

  getCustomOptionLabel = (option: unknown) => {
    if (this.isSiteOption(option)) {
      return this.titleKey() === 'site_no' ? option.title : option.siteName;
    }

    return '';
  };

  private isSiteOption = (option: unknown): option is SiteOption => {
    return isObject(option) && 'textToSearch' in option;
  };
}
