import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

@Component({
  template: ` <div [auxTooltip]="params.tooltip || ''" [innerHTML]="params.template"></div> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TooltipDirective],
})
export class AgHtmlHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams & { tooltip?: string };

  agInit(params: IHeaderParams & { tooltip?: string }): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
