<div
  *ngIf="{ formattedValue: formattedValue$ | async } as obj"
  class="flex items-center justify-end space-x-2"
>
  <ng-container *ngIf="obj.formattedValue !== 0">
    <div *ngIf="{ percentage: obj.formattedValue | percent: '1.0-2' } as percentageObj">
      {{ percentageObj.percentage !== '0%' ? percentageObj.percentage : '0.00%' }}
    </div>
    <aux-icon
      name="CircleArrowUpFilled"
      *ngIf="isValuePositive"
      class="text-aux-error flex-shrink-0"
    />
    <aux-icon
      name="CircleArrowDownFilled"
      *ngIf="!isValuePositive"
      class="text-aux-green flex-shrink-0"
    />
  </ng-container>

  <p *ngIf="obj.formattedValue === 0">{{ zeroHyphen }}</p>
</div>
