import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-checklist-edc-document-confirmation',
  template: `
    <div class="mb-8 flex flex-col items-start space-y-1 w-[500px]">
      <span>Your document(s) have been uploaded successfully!</span>
      <span
        >You will receive an email notification when Auxilius has processed the document(s), and
        investigator data is ready for review.</span
      >
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistEdcDocumentConfirmationComponent {
  constructor(private ref: CustomOverlayRef) {}

  closeModal() {
    this.ref.close();
  }
}
