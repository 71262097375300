import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DefaultExpenseSource } from '@shared/services/gql.service';

export interface ExpenseSourceItems extends DefaultExpenseSource {
  primary: string;
  secondary: string;
  tertiary: string;
  quaternary: string;
  cat: string;
}
export interface ExpenseDefaultsState {
  items: ExpenseSourceItems[];
}

export function createInitialState(): ExpenseDefaultsState {
  return {
    items: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'integrations' })
export class ExpenseDefaultsStore extends Store<ExpenseDefaultsState> {
  constructor() {
    super(createInitialState());
  }
}
