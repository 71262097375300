import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

@Component({
  selector: 'aux-notes',
  templateUrl: 'notes.component.html',
  styleUrls: ['notes.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    NgIf,
    AsyncPipe,
    NgClass,
    ButtonComponent,
    TooltipDirective,
  ],
})
export class NotesComponent implements OnInit {
  @Input() title = '';

  @Input() notesControl = new FormControl();

  @Input() placeholder = 'Add a note...';

  @Input() hasEditPermission = false;

  @Input() disabledTooltipText = '';

  @Output() saveNotes = new EventEmitter();

  @Output() cancelNotes = new EventEmitter();

  @ViewChild('notesTextArea') notesTextArea!: InputComponent;

  classListForEdit = 'py-0.5 mt-[5px]';

  editMode = false;

  ngOnInit(): void {
    this.notesControl.disable();
    this.classListForEdit = this.hasEditPermission
      ? this.classListForEdit
      : `${this.classListForEdit} cursor-not-allowed`;
  }

  onSave(): void {
    this.saveNotes.emit();
    this.editMode = false;
  }

  cancel(): void {
    this.cancelNotes.emit();
    this.editMode = false;
  }

  edit(): void {
    this.editMode = true;
    setTimeout(() => {
      const end = this.notesTextArea.input?.nativeElement.value.length || 0;
      this.notesTextArea.input?.nativeElement.focus();
      this.notesTextArea.input?.nativeElement.setSelectionRange(end, end);
    }, 0);
  }
}
