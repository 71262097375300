import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgControlComponent, AgControlComponentParams, noop } from './ag-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { InputMaskComponent } from '../../components/input-mask/input-mask.component';

interface AgInputMaskComponentParams extends AgControlComponentParams {
  placeholder?: string;

  allowNegative?: boolean;
  currencySign?: string;
}

@Component({
  template: `
    <div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
      <aux-input-mask
        *ngIf="isReady$ | async"
        class="w-full"
        [formControl]="formControl"
        maskType="money"
        [inputClassName]="
          hasError ? 'border-aux-red-dark !rounded ' + controlHeightClass : controlHeightClass
        "
        [placeholder]="placeholder"
        [allowNegativeNumbers]="allowNegative"
        [moneyPrefix]="formControl.value === null ? '' : params.currencySign ?? '$'"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, InputMaskComponent, AsyncPipe, JsonPipe, NgIf],
})
export class AgInputMaskComponent extends AgControlComponent {
  params!: AgInputMaskComponentParams;

  protected placeholder = '';

  protected allowNegative = false;

  agInit(params: AgInputMaskComponentParams): void {
    this.params = params;
    this.value = params.value;
    this.placeholder = params.placeholder ?? '';

    this.allowNegative = !!params.allowNegative;

    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler ?? noop;
    this.updateView(params);
  }
}
