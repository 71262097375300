<ng-container *ngIf="$any(loading$ | async)">
  <ng-template #loader>
    <div class="w-full max-w-sm p-4 mt-8 border border-blue-200 rounded-md shadow">
      <div class="flex space-x-4 animate-pulse">
        <div class="flex-1 py-1 space-y-4">
          <div class="flex justify-between">
            <div class="w-1/6 h-4 bg-blue-200 rounded"></div>
            <div class="grow"></div>
            <div class="w-1/6 h-4 bg-blue-200 rounded"></div>
            <div class="w-1/6 h-4 ml-2 bg-blue-200 rounded"></div>
          </div>
          <div class="space-y-2">
            <div class="w-3/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-2/6 h-3 bg-blue-200 rounded"></div>
            <div class="w-4/6 h-3 bg-blue-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container [ngTemplateOutlet]="loader" />
  <ng-container [ngTemplateOutlet]="loader" />
</ng-container>
<ng-container *ngIf="!$any(loading$ | async)">
  <div class="flex items-center justify-between">
    <aux-input
      class="w-72"
      placeholder="Search"
      icon="Search"
      [(ngModel)]="filterValue"
      (change)="filterChanges()"
      (keyup)="updateSearch($event.target)"
    />

    <div class="space-x-[10px]">
      <aux-button
        [disabled]="!copyUserPermission()"
        [auxTooltip]="copyUserPermissionDisabledTooltip()"
        label="Copy Users"
        icon="Copy"
        variant="secondary"
        [spinnerSize]="7"
        (click)="showCopyUsersModal()"
      />
      <aux-button
        [disabled]="!userHasUpdateUserPermission"
        [auxTooltip]="noPermissionsTooltipText()"
        label="Add New User"
        icon="Plus"
        variant="primary"
        (click)="onAddUser()"
      />
    </div>
  </div>

  <ng-container *ngFor="let userGroup of users$ | async | groupBy: 'organization.name'">
    <div class="mt-4 mb-2 border-b">
      {{ userGroup.key }}
    </div>

    <div class="flex mt-4 flex-wrap">
      <ng-container *ngFor="let user of userGroup.value">
        <div class="w-64 p-4 mt-4 mr-4 border rounded-md flex flex-col justify-between">
          <div class="flex justify-between mb-2">
            <div
              class="py-0.5 px-1 text-xxs rounded-md font-medium"
              [ngClass]="{
                'bg-aux-green text-white': user.role === 'ROLE_ADMIN',
                'bg-aux-gray-dark': user.role === 'ROLE_USER'
              }"
            >
              {{ user.role === 'ROLE_ADMIN' ? 'Admin' : 'User' }}
            </div>
          </div>

          <div class="text-sm break-words">
            <div class="font-bold">{{ user.given_name }} {{ user.family_name }}</div>
            <div>
              {{ user.title ? user.title + ',' : '' }} {{ getDepartmentString(user.department) }}
            </div>
            <a href="#" class="font-medium">{{ user.email }}</a>
            <div *ngIf="!user.last_login_date" class="flex item-center">
              <span class="italic mr-1">Pending</span>
              <div>(</div>
              <aux-button
                variant="hyperlink"
                [auxTooltip]="noPermissionsTooltipText()"
                [disabled]="!userHasUpdateUserPermission"
                (clickEmit)="resendInvitation(user)"
                label="Resend invite"
                classList="leading-5 p-0"
              />
              <div>)</div>
            </div>
            <div *auxAuthorize="{ sysAdminsOnly: true }">
              <div *ngIf="user.last_login_date">
                {{ user.last_login_date | date: 'MM/dd/YYYY HH:mm a' }}
              </div>
            </div>
          </div>

          <div class="flex space-x-2 text-sm font-medium items-center mt-4">
            <aux-button
              classList="btn px-2 h-8"
              [ngClass]="{
                'opacity-50': !userHasUpdateUserPermission
              }"
              [disabled]="!userHasUpdateUserPermission"
              [auxTooltip]="noPermissionsTooltipText()"
              icon="Pencil"
              svgAttributes="text-aux-blue"
              variant="custom"
              (click)="onEditUser(user)"
            />

            <aux-button
              classList="btn px-2 h-8"
              [ngClass]="{
                'opacity-50': !userHasUpdateUserPermission
              }"
              [disabled]="!userHasUpdateUserPermission"
              [auxTooltip]="noPermissionsTooltipText()"
              icon="Trash"
              svgAttributes="text-aux-error"
              variant="custom"
              (click)="onRemoveUser(user)"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div *ngIf="(auxUsers$ | async)?.length">
    <div class="mt-4 mb-2 border-b">
      Auxilius Support
      <button type="button" class="align-top" (click)="toggleAuxSupport()">
        <aux-icon name="ChevronDown" *ngIf="!showAuxSupport" />
        <aux-icon name="ChevronUp" *ngIf="showAuxSupport" />
      </button>
    </div>
    <div *ngIf="showAuxSupport">
      <div class="flex mt-4 flex-wrap">
        <ng-container *ngFor="let user of auxUsers$ | async">
          <div class="w-64 p-4 mt-4 mr-4 border rounded-md flex flex-col justify-between">
            <div class="flex justify-between mb-2">
              <div
                class="py-0.5 px-1 text-xxs rounded-md font-medium"
                [ngClass]="{
                  'bg-aux-green text-white': user.role === 'ROLE_ADMIN',
                  'bg-aux-gray-dark': user.role === 'ROLE_USER'
                }"
              >
                {{ user.role === 'ROLE_ADMIN' ? 'Admin' : 'User' }}
              </div>
            </div>

            <div class="text-sm break-words">
              <div class="font-bold">{{ user.given_name }} {{ user.family_name }}</div>
              <div>
                {{ user.title }}{{ user.title ? ', ' : ''
                }}{{ getDepartmentString(user.department) }}
                Auxilius Support
              </div>
              <a href="#" class="font-medium">{{ user.email }}</a>
              <div *ngIf="!user.last_login_date" class="flex item-center">
                <span class="italic mr-1">Pending</span>
                <div>(</div>
                <aux-button
                  variant="hyperlink"
                  [auxTooltip]="noPermissionsTooltipText()"
                  [disabled]="!userHasUpdateUserPermission"
                  (clickEmit)="resendInvitation(user)"
                  label="Resend invite"
                  classList="leading-5 p-0"
                />
                <div>)</div>
              </div>
              <div *auxAuthorize="{ sysAdminsOnly: true }">
                <div *ngIf="user.last_login_date">
                  {{ user.last_login_date | date: 'MM/dd/YYYY HH:mm a' }}
                </div>
              </div>
            </div>

            <div class="flex space-x-2 text-sm font-medium items-center mt-4">
              <aux-button
                classList="btn px-2 h-8"
                [ngClass]="{
                  'opacity-50': !userHasUpdateUserPermission
                }"
                [disabled]="!userHasUpdateUserPermission"
                [auxTooltip]="noPermissionsTooltipText()"
                icon="Pencil"
                svgAttributes="text-aux-blue"
                variant="custom"
                (click)="onEditUser(user)"
              />

              <aux-button
                classList="btn px-2 h-8"
                [ngClass]="{
                  'opacity-50': !userHasUpdateUserPermission
                }"
                [disabled]="!userHasUpdateUserPermission"
                [auxTooltip]="noPermissionsTooltipText()"
                icon="Trash"
                svgAttributes="text-aux-error"
                variant="custom"
                (click)="onRemoveUser(user)"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
