import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ExcelRow,
  ExcelStyle,
  GridApi,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Maybe, Utils } from '@shared/utils/utils';
import {
  CompareBudgetVersion,
  CompareGridData,
} from '@widgets/compare-budget/compare-budget.component';
import { AgPulseMinusComponent } from '@shared/ag-components/ag-pulse-minus/ag-pulse-minus.component';
import { Currency } from '@shared/services/gql.service';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetGroupHeaderComponent,
} from '@features/ag-budget-group-header/ag-budget-group-header.component';
import { TableConstants } from '@shared/constants/table.constants';
import {
  AgBeCheckboxGroupRendererComponent,
  ICheckboxCellRendererParams,
} from '@pages/budget-page/tabs/budget-enhanced/ag-be-checkbox-group-renderer.component';
import {
  attributeColumnDef,
  getAttributeColumns,
} from '@features/budget-attributes/services/budget-attributes.service';
import { AuxExcelFormats, AuxExcelStyleKeys, AuxExcelStyles } from '@shared/utils';
import { TrialModel } from '@models/trials/trials.store';

const ChangeOrderAttributesLSKey = 'co_compare_activities_header';

type CompareBudgetColumnContext = {
  organizationName: string;
  currency: Currency;
  rowSelection: {
    disabled: boolean;
    disabledTooltip: string;
  };
  budgetData: CompareGridData[];
  toBudgetVersion: Maybe<CompareBudgetVersion>;
};

export const getCompareBudgetColumnDefs = (ctx: CompareBudgetColumnContext) => {
  const attributesColumnGroupDef = getAttributesColumnGroupDef(ctx);
  return {
    hiddenColumns: getHiddenColumns(ctx),
    currentBudgetColumnGroupDef: getCurrentBudgetColumnGroupDef(ctx),
    toBudgetColumnGroupDef: getToBudgetColumnGroupDef(ctx),
    varianceColumnGroupDef: getVarianceColumnGroupDef(ctx),
    attributesColumnGroupDef,
    autoGroupColumnDef: getAutoGroupColumnDef(ctx, attributesColumnGroupDef),
    getExcelStyles,
  };
};

const unitColumn = {
  minWidth: 80,
  width: 150,
  resizable: true,
  headerClass: 'ag-header-align-center',
  cellClass: ['ag-cell-align-right', 'budget-unit'],
  valueFormatter: Utils.unitFormatter,
};

const unitCost = (ctx: CompareBudgetColumnContext, addBorder = false) => ({
  minWidth: 120,
  width: 150,
  resizable: true,
  headerClass: 'ag-header-align-center',
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.value) {
      return Utils.currencyFormatter(Number(params.value), {}, ctx.currency);
    } else {
      return Utils.zeroHyphen;
    }
  },
  cellClass: (params: CellClassParams) => {
    const defaultClasses = ['ag-cell-align-right'];
    if (addBorder) {
      defaultClasses.push(AuxExcelStyleKeys.BORDER_RIGHT);
    }

    if (params?.node?.data?.contract_direct_cost_currency) {
      return [`budgetCost${params?.node?.data?.contract_direct_cost_currency}`, ...defaultClasses];
    }
    return ['budgetCostNoSymbol', ...defaultClasses];
  },
});

const getHiddenColumns = (ctx: CompareBudgetColumnContext): (ColDef | ColGroupDef)[] => [
  {
    headerName: `Vendor: ${ctx.organizationName}`,
    colId: 'trial-vend-name',
    children: [
      { field: 'cost_category', headerName: 'Cost Category', rowGroup: true, hide: true },
      { field: 'group0', headerName: 'Category', rowGroup: true, hide: true },
    ],
  },
  { field: 'group1', rowGroup: true, hide: true },
  { field: 'group2', rowGroup: true, hide: true },
  { field: 'group3', rowGroup: true, hide: true },
  { field: 'group4', rowGroup: true, hide: true },
  { field: 'display_label', headerName: 'Label', hide: true },
  { field: 'activity_name_label', headerName: 'Activities', hide: true },
  {
    headerName: 'Changed',
    field: 'changed',
    hide: true,
    filter: true,
  },
  {
    headerName: 'Missing Activities',
    field: 'missing_activity',
    hide: true,
    filter: true,
  },
];

const getAttributesColumnGroupDef = (
  ctx: CompareBudgetColumnContext
): ColGroupDef<CompareGridData> => {
  const attributes = getAttributeColumns({
    attributes: ctx.budgetData.map((z) => z.attributes || []),
  });

  return attributeColumnDef(attributes, ChangeOrderAttributesLSKey, true);
};

const getAutoGroupColumnDef = (
  ctx: CompareBudgetColumnContext,
  attributesColumnGroupDef: ColGroupDef<CompareGridData>
): ColDef<CompareGridData> => {
  return {
    headerName: 'Activities',
    headerClass: ['activities-header', '!pl-0'],
    headerComponent: AgBudgetGroupHeaderComponent,
    headerComponentParams: <AgBudgetAttributeComponentParams>{
      expandLevel: () => -1,
      template: `Activities`,
      afterAttrToggle: ({ api }) => {
        api.sizeColumnsToFit();
      },
      localStorageKey: ChangeOrderAttributesLSKey,
      columnsToCollapse: attributesColumnGroupDef.children.map((x: ColDef) => x.colId || x.field),
      showCheckbox: {
        tooltip: ctx.rowSelection.disabled ? ctx.rowSelection.disabledTooltip : '',
        disabled: ctx.rowSelection.disabled,
      },
    },
    minWidth: 300,
    width: 300,
    field: 'activity_name',
    cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
    pinned: 'left',
    resizable: true,
    cellRenderer: AgBeCheckboxGroupRendererComponent,
    cellRendererParams: <ICheckboxCellRendererParams<CompareGridData>>{
      tooltipField: 'activity_name',
      checkboxTooltip: (params) => {
        if (ctx.rowSelection.disabled) {
          return ctx.rowSelection.disabledTooltip;
        }
        if (ctx.toBudgetVersion && params.data?.missing_activity) {
          return 'Activity cannot be selected, because it was deleted in this Change Order.';
        }
        return '';
      },
      checkboxContainerClass: 'mr-[10px] pl-[6px] pr-[15px]',
    },
  };
};

const getCurrentBudgetColumnGroupDef = (
  ctx: CompareBudgetColumnContext
): ColGroupDef<CompareGridData> => {
  return {
    headerName: 'Current Budget',
    headerClass: ['ag-header-align-center blue-header header-marker'],
    children: [
      {
        headerName: 'Activity ID',
        field: 'from_activity_no',
        hide: true,
      },
      {
        ...unitColumn,
        headerName: 'Unit(s)',
        field: 'from_unit',
      },
      {
        ...unitCost(ctx),
        headerName: 'Unit Cost',
        field: 'from_unit_cost',
      },
      {
        ...unitCost(ctx, true),
        headerName: 'Total Cost',
        field: 'from_total_cost',
        aggFunc: 'sum',
      },
    ],
  };
};

const getToBudgetColumnGroupDef = (
  ctx: CompareBudgetColumnContext
): ColGroupDef<CompareGridData> => {
  return {
    headerName: 'To Budget',
    headerClass: ['ag-header-align-center green-header header-marker'],
    children: [
      {
        headerName: 'Activity ID',
        field: 'to_activity_no',
        hide: true,
      },
      {
        ...unitColumn,
        headerName: 'Unit(s)',
        field: 'to_unit',
      },
      {
        ...unitCost(ctx),
        headerName: 'Unit Cost',
        field: 'to_unit_cost',
      },
      {
        ...unitCost(ctx, true),
        headerName: 'Total Cost',
        field: 'to_total_cost',
        aggFunc: 'sum',
      },
    ],
  };
};

const getVarianceColumnGroupDef = (
  ctx: CompareBudgetColumnContext
): ColGroupDef<CompareGridData> => {
  return {
    headerName: 'Variance',
    headerClass: ['ag-header-align-center green-header header-marker'],
    children: [
      {
        ...unitColumn,
        headerName: 'Unit(s)',
        field: 'variance_unit',
        cellRenderer: AgPulseMinusComponent,
      },
      {
        ...unitCost(ctx),
        headerName: 'Unit Cost',
        field: 'variance_unit_cost',
        cellRenderer: AgPulseMinusComponent,
        cellRendererParams: {
          org_currency: ctx.currency,
        },
      },
      {
        ...unitCost(ctx),
        headerName: `Total Cost`,
        field: 'variance_total_cost',
        cellRenderer: AgPulseMinusComponent,
        cellRendererParams: {
          org_currency: ctx.currency,
        },
        aggFunc: 'sum',
      },
      {
        headerName: 'Total (%)',
        field: 'variance_total_percent',
        headerClass: 'ag-header-align-center',
        minWidth: 80,
        width: 150,
        resizable: true,
        cellRenderer: AgPulseMinusComponent,
        cellClass: ['ag-cell-align-right', 'budget-percent', AuxExcelStyleKeys.BORDER_RIGHT],
        aggFunc: (params) => {
          let var_total = 0;
          let from_total = 0;
          for (const childRow of params.rowNode.allLeafChildren) {
            var_total += childRow.data?.variance_total_cost || 0;
            from_total += childRow.data?.from_total_cost || 0;
          }
          return from_total ? (var_total || 0) / (from_total || 1) : 0;
        },
      },
    ],
  };
};

const getExcelStyles = (): ExcelStyle[] => {
  return [
    ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
    ...AuxExcelStyles,
    {
      id: 'budgetCostNoSymbol',
      dataType: 'Number',
      numberFormat: { format: AuxExcelFormats.Units },
    },
    {
      id: 'budget-cost',
      dataType: 'Number',
      numberFormat: { format: AuxExcelFormats.Cost },
    },
    {
      id: 'budget-percent',
      dataType: 'Number',
      numberFormat: { format: AuxExcelFormats.Percent },
    },
  ];
};

export const exportChangeOrderBudget = ({
  fileName,
  gridAPI,
  trial,
  org_currency,
  toBudgetVersion,
}: {
  fileName: string;
  gridAPI: GridApi<CompareGridData>;
  trial: TrialModel;
  org_currency: Currency;
  toBudgetVersion?: Maybe<CompareBudgetVersion>;
}) => {
  // first column is the auto column ignore it
  const displayedColumns = gridAPI
    .getAllDisplayedColumns()
    .slice(1)
    .map((col) => col.getColId());
  const fromUnitIndex = displayedColumns.findIndex((col) => col === 'from_unit');
  const attributesColumns = displayedColumns.slice(0, fromUnitIndex);

  const columnKeys = [
    'cost_category',
    'group0',
    'display_label',
    'activity_name_label',

    ...attributesColumns,

    'from_activity_no',
    'from_unit',
    'from_unit_cost',
    'from_total_cost',

    ...(toBudgetVersion
      ? [
          'to_activity_no',
          'to_unit',
          'to_unit_cost',
          'to_total_cost',

          'variance_unit',
          'variance_unit_cost',
          'variance_total_cost',
          'variance_total_percent',
        ]
      : []),
  ];

  const { from_total_cost, to_total_cost, variance_total_cost, variance_total_percent } =
    gridAPI.getGridOption('pinnedBottomRowData')![0] as CompareGridData;

  const appendContent: ExcelRow[] = [
    {
      cells: [
        {
          data: { value: `Total`, type: 'String' },
          mergeAcross: 3 + attributesColumns.length,
          styleId: 'total_row_header',
        },
      ],
    },
  ];

  const addToTotal = (value: number, merge: number) => {
    appendContent[0].cells.push({
      data: { value: `${value}`, type: 'Number' },
      mergeAcross: merge,
      styleId: `total_row_${org_currency}`,
    });
  };

  addToTotal(from_total_cost, 3);

  if (toBudgetVersion) {
    addToTotal(to_total_cost, 3);
    addToTotal(variance_total_cost, 2);
    appendContent[0].cells.push({
      data: {
        value: `${variance_total_percent}`,
        type: 'Number',
      },
      styleId: 'total_row_percent',
    });
  }

  gridAPI.exportDataAsExcel({
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Compare Budgets',
    fileName,
    shouldRowBeSkipped: (params) => !params.node?.data?.cost_category,
    columnWidth: (params) => {
      switch (params.column?.getId()) {
        case 'group0':
          return 280;
        case 'activity_name_label':
          return 490;
        default:
          return 105;
      }
    },
    prependContent: [
      {
        cells: [
          {
            data: { value: `Trial: ${trial.name}`, type: 'String' },
            mergeAcross: 1,
            styleId: 'first_row',
          },
        ],
      },
    ],
    appendContent,
    columnKeys,
  });
};
