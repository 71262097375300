import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import {
  TICICChartLabel,
  TICICChartType,
  TrialInsightsClinicalInvestigatorCostChartService,
} from './investigator-cost-chart.service';
import { TrialInsightsClinicalInvestigatorCostQueryService } from './investigator-cost-query.service';
import { TrialInsightsClinicalInvestigatorCostStoreService } from './investigator-cost-store.service';
import { TrialInsightsClinicalInvestigatorCostTableService } from './investigator-cost-table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-trial-insights-clinical-investigator-cost',
  templateUrl: './investigator-cost.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsClinicalInvestigatorCostQueryService],
})
export class TrialInsightsClinicalInvestigatorCostComponent extends GenericTrialInsightsComponent<
  TICICChartType,
  TICICChartLabel
> {
  constructor(
    public tableService: TrialInsightsClinicalInvestigatorCostTableService,
    public chartService: TrialInsightsClinicalInvestigatorCostChartService,
    public storeService: TrialInsightsClinicalInvestigatorCostStoreService,
    public queryService: TrialInsightsClinicalInvestigatorCostQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Investigator Costs to Date',
      color: '#226262',
      chartService,
      tableService,
      queryService,
      cdr,
    });
  }

  subscribeToData = () => {
    this.queryService
      .processTotalInvestigatorCosts$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
    this.storeService
      .getTotalInvestigatorCosts$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  };
}
