import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BehaviorSubject } from 'rxjs';
import { Workflow } from '@shared/store/workflow/workflow.store';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-monthly-review-notes-counter',
  templateUrl: './monthly-review-notes-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, AsyncPipe],
})
export class MonthlyReviewNotesCounterComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() workflow$!: BehaviorSubject<Workflow | null>;

  @Input() opened$ = new BehaviorSubject<boolean>(true);

  @Output() clicked = new EventEmitter<void>();

  private workflow: WritableSignal<Workflow | null | undefined> = signal(null);

  readonly monthlyReviewCount = computed(() => {
    if (!this.workflow()) {
      return 0;
    }

    return this.quarterCloseNotesQuery.notes()[(this.workflow() as Workflow).id]
      .monthlyReviewNotesCount;
  });

  private readonly quarterCloseNotesQuery = inject(QuarterCloseNotesQuery);

  ngOnInit() {
    this.workflow$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((workflow) => this.workflow.set(workflow));
  }
}
