import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { catchError, map, retryWhen, switchMap, tap } from 'rxjs/operators';
import {
  CommonApiDataType,
  CreateManualTrialInput,
  GqlService,
  TrialImplementationStatus,
  TrialWorkspaceType,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Option } from '@shared/types/components.type';

import { TrialModel, TrialsStore } from './trials.store';
import { defer, firstValueFrom, of, throwError, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TrialsService {
  constructor(
    private trialsStore: TrialsStore,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  get() {
    this.trialsStore.setLoading(true);
    return defer(() => this.gqlService.listTrials$()).pipe(
      tap((x) => {
        if (!x.success) {
          throw new Error('Failed to get list of trials');
        }
      }),
      retryWhen((err) => {
        let numRetries = 0;
        return err.pipe(
          switchMap(() => {
            if (numRetries < 3) {
              numRetries += 1;
              return timer(10 * 1000, 1e6);
            }
            return throwError('Max retry count reached');
          })
        );
      }),
      catchError(() => of({ data: [] })),
      map((x) => {
        this.trialsStore.set(x.data || []);
        this.trialsStore.setLoading(false);
        return x;
      })
    );
  }

  async createCustomTrial({
    sponsor_organization_name,
    name,
    short_name,
    indication,
    therapy_area,
    trial_phase,
    auxilius_start_date,
    onboarding_complete,
    program,
    project,
    implementation_status,
    workspace_type,
  }: CreateManualTrialInput): Promise<string | undefined> {
    this.trialsStore.setLoading(true);

    const { data, errors } = await firstValueFrom(
      this.gqlService.createManualTrial$({
        sponsor_organization_name,
        name,
        short_name,
        indication,
        therapy_area,
        trial_phase,
        auxilius_start_date,
        onboarding_complete,
        program,
        project,
        implementation_status,
        workspace_type,
      })
    );
    this.trialsStore.setLoading(false);

    if (data) {
      this.trialsStore.add(data);
      return data.id;
    }

    this.overlayService.error(errors);

    return undefined;
  }

  async update(id: string, trial: Partial<TrialModel>) {
    this.trialsStore.update(id, trial);
    const { success, errors } = await firstValueFrom(
      this.gqlService.updateTrial$({
        short_name: trial.short_name,
        auxilius_start_date: trial.auxilius_start_date || null,
        indication: trial.indication,
        therapy_area: trial.therapy_area,
        trial_phase: trial.trial_phase,
        program: trial.program || '',
        project: trial.project || '',
        implementation_status:
          trial.implementation_status || TrialImplementationStatus.IMPLEMENTATION_STATUS_BLANK,
        workspace_type: trial.workspace_type || TrialWorkspaceType.WORKSPACE_TYPE_CLINICAL_TRIAL,
      })
    );
    if (!success && errors) {
      this.overlayService.error(errors);
      return false;
    }
    this.overlayService.success();
    return true;
  }

  async updateOnboarding(id: string, trial: Partial<TrialModel>) {
    const { success, errors } = await firstValueFrom(
      this.gqlService.updateTrial$({
        onboarding_complete: true,
        implementation_status:
          trial.implementation_status || TrialImplementationStatus.IMPLEMENTATION_STATUS_BLANK,
        auxilius_start_date: trial.auxilius_start_date,
        workspace_type: trial.workspace_type || TrialWorkspaceType.WORKSPACE_TYPE_CLINICAL_TRIAL,
      })
    );

    if (success) {
      this.trialsStore.update(id, { ...trial, onboarding_complete: true });
      return true;
    }

    alert(errors);
    return false;
  }

  remove(id: ID) {
    this.trialsStore.remove(id);
  }

  async getTherapyAreaOptions(): Promise<Option[]> {
    const { data } = await firstValueFrom(
      this.gqlService.getCommonApiData$(CommonApiDataType.THERAPY_AREA)
    );

    return data
      ? JSON.parse(data.data).options.map((option: { id: string; value: string }) => ({
          value: option.id,
          label: option.value,
        }))
      : [];
  }
}
