<input
  [id]="id"
  type="checkbox"
  class="size-[16px] text-aux-blue border-aux-gray-dark rounded-[3px] cursor-pointer focus:ring-aux-blue"
  [ngClass]="{ 'cursor-not-allowed bg-gray-100': disabled }"
  [checked]="fc.value"
  [disabled]="disabled"
  (change)="onCheckboxChange($event)"
  auxFormError
/>
<label class="block ml-1.5 cursor-pointer" [for]="id"><ng-content></ng-content></label>
