import { ActivityType, VarianceType } from '@shared/services/gql.service';

export const DEFAULT_ANALYTIC_CARDS = [
  {
    title: 'Total Forecast Variance (BvA)',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Services Forecast Variance',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Investigator Forecast Variance',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Pass Through Forecast Variance',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Largest Activity Variance $',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Largest Activity Variance %',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Largest Unit Cost Variance',
    differencePerc: 0,
    differenceAmount: 0,
  },
  {
    title: 'Largest Unit Variance',
    differencePerc: 0,
    differenceAmount: 0,
  },
];

export const mapTitleVariancesByType = new Map<string, { title: string }>([
  [VarianceType.VARIANCE_FORECAST, { title: 'Total Forecast Variance (BvA)' }],
  [
    `${VarianceType.VARIANCE_FORECAST}_${ActivityType.ACTIVITY_INVESTIGATOR}`,
    { title: 'Investigator Forecast Variance' },
  ],
  [
    `${VarianceType.VARIANCE_FORECAST}_${ActivityType.ACTIVITY_SERVICE}`,
    { title: 'Services Forecast Variance' },
  ],
  [
    `${VarianceType.VARIANCE_FORECAST}_${ActivityType.ACTIVITY_PASSTHROUGH}`,
    { title: 'Pass Through Forecast Variance' },
  ],
  [VarianceType.VARIANCE_ACTIVITY_AMOUNT_TOTAL, { title: 'Largest Activity Variance $' }],
  [VarianceType.VARIANCE_ACTIVITY_AMOUNT_PERCENTAGE, { title: 'Largest Activity Variance %' }],
  [VarianceType.VARIANCE_ACTIVITY_UNIT_COST, { title: 'Largest Unit Cost Variance' }],
  [VarianceType.VARIANCE_ACTIVITY_UNITS, { title: 'Largest Unit Variance' }],
]);
