import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  DestroyRef,
  inject,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { difference } from 'lodash-es';
import { InvestigatorFilterConfig } from '../investigator-transactions-filters/investigator-transactions-filters.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-investigator-transactions-add-filter',
  templateUrl: './investigator-transactions-add-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsAddFilterComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  readonly originalOptionalFilters = [
    'Patient Group',
    'Protocol Version',
    'Description',
    'Country',
    'Category',
  ];

  @Input() renderedFilters$!: BehaviorSubject<InvestigatorFilterConfig[]>;

  @Input() showSourceColumn$!: Observable<boolean>;

  @Output() selectFilterChange = new EventEmitter<string>();

  isOpen = false;

  optionalFilters = [...this.originalOptionalFilters];

  toggleOpenList = (value: boolean) => {
    this.isOpen = value;
  };

  selectFilter(filterName: string) {
    this.toggleOpenList(false);
    this.selectFilterChange.emit(filterName);
    this.optionalFilters = this.optionalFilters.filter((name) => name !== filterName);
  }

  ngOnInit(): void {
    this.showSourceColumn$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((val) => {
      delete this.originalOptionalFilters[this.originalOptionalFilters.indexOf('Source', 0)];
      if (val) {
        this.originalOptionalFilters.push('Source');
      }
    });
    this.renderedFilters$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((list) => {
      this.optionalFilters = difference(
        this.originalOptionalFilters,
        list.map(({ label }) => label)
      );
    });
  }
}
