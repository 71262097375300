import { Component, EventEmitter, inject, Input, input, Output } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { MainQuery } from '@shared/store/main/main.query';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { OverlayService } from '@shared/services/overlay.service';
import { MapActivitiesModalComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.component';
import { firstValueFrom } from 'rxjs';
import {
  MapActivitiesModalParams,
  MapActivitiesModalReturnData,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';

@Component({
  standalone: true,
  selector: 'aux-map-activities-no-rows',
  templateUrl: 'map-activities-no-rows.component.html',
  imports: [ButtonComponent, TooltipDirective],
})
export class MapActivitiesNoRowsComponent {
  authService = inject(AuthService);
  workflowQuery = inject(WorkflowQuery);
  mainQuery = inject(MainQuery);
  private overlayService = inject(OverlayService);

  @Input() lastUpdated = '';
  @Input() noteId = '';
  @Input() noteMessage = '';

  @Output() saveActivities = new EventEmitter<MapActivitiesModalReturnData>();

  invoice = input.required<InvoiceModel>();
  btnTooltip = input.required<string>();

  async openMapActivitiesModal(): Promise<void> {
    const ref = this.overlayService.openPopup<
      MapActivitiesModalParams,
      MapActivitiesModalReturnData,
      MapActivitiesModalComponent
    >({
      content: MapActivitiesModalComponent,
      settings: {
        header: 'Map Activities',
        primaryButton: {
          disabled: (instance) => !!instance?.isSaveButtonDisabled(),
          tooltip: (instance) => instance?.saveButtonTooltip() || '',
          action: (instance) => instance?.save(),
        },
      },
      data: {
        invoice: this.invoice(),
        lastUpdated: this.lastUpdated,
        items: [],
        noteId: this.noteId,
        noteMessage: this.noteMessage,
      },
    });

    const { data } = await firstValueFrom(ref.afterClosed$);

    if (data) {
      this.saveActivities.emit(data);
    }
  }
}
