<ng-select
  [placeholder]="placeholder"
  [formControl]="dropdownControl"
  [clearable]="!!dropdownControl.value"
  [searchable]="false"
  [deselectOnClick]="true"
  [openOnEnter]="openOnEnter"
  [items]="options()"
  [trackByFn]="trackByFn"
  [isOpen]="isOpen"
  (clear)="onClear()"
  (change)="onChange($event)"
  bindValue="value"
  bindLabel="value"
  class="extendable-options-dropdown h-full"
  appendTo="body"
>
  <ng-template let-item="item" let-clear="clear" ng-option-tmp class="flex-nowrap">
    @if (item.id === searchOptionId) {
      <div class="h-[32px] w-full">
        <aux-input
          [formControl]="filterText"
          [autofocus]="true"
          (keyDown)="onSearchKeyDown($event)"
          icon="Search"
          autocomplete="off"
          iconPosition="left"
          inputClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100 rounded-[4px] placeholder:text-aux-gray-dark-100 !placeholder:not-italic !pl-10"
          iconClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100"
          placeholder="Search"
        />
      </div>
    } @else {
      <div
        #option
        [auxTooltip]="
          showTooltips && option.offsetWidth < option.scrollWidth ? getItemValue(item) : ''
        "
        [auxTooltipPositions]="tooltipPositions"
        auxTooltipPanelContainerClass="extendable-options-tooltip-panel"
        class="truncate extendable-dropdown-option"
        (mousedown)="optionMouseDown(item, $event)"
      >
        {{ getItemValue(item) }}
      </div>
    }
  </ng-template>
</ng-select>
