import { ValueSetterParams } from '@ag-grid-community/core';
import { isString, set } from 'lodash-es';
import { removeSymbolsFromString } from '@shared/utils/formatter.utils';
import dayjs from 'dayjs';
import { InvoiceStatus, PaymentStatus } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { OverlayService } from '@shared/services/overlay.service';
import { MessagesConstants } from '@shared/constants/messages.constants';

export function agFormatToNumberValueSetter(
  params: ValueSetterParams,
  symbolsToRemove: string[],
  field: string
): boolean {
  const newValue = isString(params.newValue)
    ? removeSymbolsFromString(params.newValue, symbolsToRemove)
    : params.newValue;

  set(params.data, field, Number(newValue));

  return true;
}

export function agFormatToMonthDateValueSetter(params: ValueSetterParams): boolean | undefined {
  const date = dayjs(params.newValue);
  const isDateValid = isNewValDate(String(params.newValue));
  if (!params.newValue || params.newValue === Utils.zeroHyphen) {
    set(params.data, params.column.getColId(), null);
    return true;
  }
  if (isDateValid) {
    set(params.data, params.column.getColId(), date.format('YYYY-MM'));
    return true;
  }
}

export function agFormatToFullDateValueSetter(params: ValueSetterParams): boolean | undefined {
  const date = dayjs(params.newValue);
  const isDateValid = isNewValDate(String(params.newValue));
  console.log(params.newValue);
  if (!params.newValue || params.newValue === Utils.zeroHyphen) {
    set(params.data, params.column.getColId(), null);
    return true;
  }
  console.log(isDateValid);
  console.log(date);
  if (isDateValid) {
    set(params.data, params.column.getColId(), date.format('YYYY-MM-DD'));
    return true;
  }
}

export function agInvoiceStatusValueSetter(params: ValueSetterParams): boolean | undefined {
  const mapStringToInvoiceStatus = new Map<string, InvoiceStatus>([
    ['Approved', InvoiceStatus.STATUS_APPROVED],
    ['Declined', InvoiceStatus.STATUS_DECLINED],
    ['Pending Approval', InvoiceStatus.STATUS_PENDING_APPROVAL],
    ['Pending Review', InvoiceStatus.STATUS_PENDING_REVIEW],
    ['Pending In Queue', InvoiceStatus.STATUS_IN_QUEUE],
  ]);
  const mapInvoiceStatus = new Map<InvoiceStatus, string>([
    [InvoiceStatus.STATUS_APPROVED, 'Approved'],
    [InvoiceStatus.STATUS_DECLINED, 'Declined'],
    [InvoiceStatus.STATUS_PENDING_APPROVAL, 'Pending Approval'],
    [InvoiceStatus.STATUS_PENDING_REVIEW, 'Pending Review'],
    [InvoiceStatus.STATUS_IN_QUEUE, 'Pending In Queue'],
  ]);
  if (mapInvoiceStatus.get(params.newValue)) {
    set(params.data, params.column.getColId(), params.newValue);
    return true;
  }
  if (mapStringToInvoiceStatus.get(params.newValue)) {
    set(params.data, params.column.getColId(), mapStringToInvoiceStatus.get(params.newValue));
    return true;
  }
  if (params.newValue === Utils.zeroHyphen) {
    set(params.data, params.column.getColId(), null);
    return true;
  }
}

export function agPaymentStatusValueSetter(params: ValueSetterParams): boolean | undefined {
  const mapPaymentStatus = new Map<PaymentStatus, string>([
    [PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL, 'Paid'],
    [PaymentStatus.PAYMENT_STATUS_DECLINED, 'Unpaid'],
    [PaymentStatus.PAYMENT_STATUS_PARTIAL_PAYMENT, 'Unpaid'],
    [PaymentStatus.PAYMENT_STATUS_SCHEDULED, 'Unpaid'],
    [PaymentStatus.PAYMENT_STATUS_UNPAID, 'Unpaid'],
  ]);
  const newValAsString = String(params.newValue).toLowerCase();
  if (newValAsString === 'paid') {
    set(params.data, params.column.getColId(), PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL);
    return true;
  }
  if (newValAsString === 'unpaid') {
    set(params.data, params.column.getColId(), PaymentStatus.PAYMENT_STATUS_UNPAID);
    return true;
  }
  if (mapPaymentStatus.get(params.newValue)) {
    set(params.data, params.column.getColId(), params.newValue);
    return true;
  }
  if (params.newValue === Utils.zeroHyphen || !params.newValue) {
    set(params.data, params.column.getColId(), null);
    return true;
  }
}

export function isNewValDate(val: string): boolean {
  if ((val.includes('/') || val.includes('-')) && dayjs(val).isValid()) {
    return true;
  } else if (val.includes(' ')) {
    const splittedVal = val.split(' ');
    return Utils.MONTH_NAMES.includes(splittedVal[0]);
  }
  return false;
}

export function agNumericOnlyValueSetter(
  params: ValueSetterParams,
  overlayService: OverlayService,
  validationMessage = MessagesConstants.ONLY_NUMERIC_VALUES_ARE_SUPPORTED
) {
  const field = params.colDef.field;

  const ANY_NUMERIC_REGEX = /^-?\d+$/gi;

  if (!field) {
    return false;
  }

  if (ANY_NUMERIC_REGEX.test(String(params.newValue)) || params.newValue === null) {
    params.data[field] = params.newValue;

    return true;
  }

  overlayService.error(validationMessage);

  return false;
}
