import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  APP_VERSION: string | undefined;

  SAML_PROVIDERS: string[] = [];
}
