<aux-extendable-options-dropdown
  class="attributes-dropdown"
  [ngClass]="{
    'show-placeholder': formControl.value === ''
  }"
  [items]="mapActivitiesModalService.invoiceItemOptions()"
  [resetValueOnEscapePress]="true"
  [initialSearchTerm]="initialSearchTerm"
  [dropdownControl]="formControl"
  [placeholder]="'Select or add invoice item'"
  [onChangedReturnOption]="true"
  (addOption)="onAddOption($event)"
  (changed)="onChange($event)"
></aux-extendable-options-dropdown>
