import { Component, computed, effect, inject, OnInit } from '@angular/core';
import { ChecklistRowInfoComponent } from '@pages/closing-page/tabs/quarter-close-checklist/components/checklist-row-info/checklist-row-info.component';
import { ChecklistComponentData } from '@pages/closing-page/tabs/quarter-close-checklist/models/quarter-close-checklist.model';
import { Router } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { ButtonComponent } from '@shared/components/button/button.component';
import { OverlayService } from '@shared/services/overlay.service';
import { ChecklistUploadEdcDocumentComponent } from './checklist-upload-edc-document/checklist-upload-edc-document.component';
import {
  EDCItem,
  EDCItemStatus,
  EDCStatusMap,
  EDCUploadStatus,
  getEDCUploadStatusFromFiles,
} from './checklist-upload-edc-document/edc-document-status';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowStepType } from '@shared/store/workflow/workflow.const';
import { QuarterCloseChecklistPeriodCloseService } from '../../services/quarter-close-checklist-period-close.service';
import dayjs from 'dayjs';
import { ApiService } from '@shared/services/api.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import {
  DocumentType,
  User,
  listDocumentsQuery,
  listUserNamesWithEmailQuery,
} from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { ChecklistEdcDocumentConfirmationComponent } from './checklist-edc-document-confirmation.component';
import { EDCProperties, Workflow } from '@shared/store/workflow/workflow.store';
import { File } from '@shared/components/file-manager/state/file.model';
import { FirstNameShowComponent } from '@features/first-name-show/first-name-show.component';
import { pick } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  templateUrl: './checklist-section-gather-patients.component.html',
  imports: [ButtonComponent, CommonModule, TooltipDirective, FirstNameShowComponent],
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistSectionGatherPatientsComponent implements ChecklistComponentData, OnInit {
  router = inject(Router);

  overlayService = inject(OverlayService);

  workflowService = inject(WorkflowService);

  workflowQuery = inject(WorkflowQuery);

  apiService = inject(ApiService);

  mainQuery = inject(MainQuery);

  quarterCloseChecklistPeriodCloseService = inject(QuarterCloseChecklistPeriodCloseService);

  workflow = computed(() => {
    return this.workflowQuery.getWorkflowByStepType(
      WorkflowStepType.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
    )();
  });

  isWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStepType.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  isEDCStatusAvailableFromWorkflow = computed(() => {
    const wf = this.workflow();
    if (!wf) {
      return false;
    }
    const edcProperties = this.selectEDCProperties(wf);
    return !!edcProperties.edc_data_status;
  });

  isReviewInvestigatorDataLinkOpen = computed(() => {
    return this.edcPropertiesFromWorkFlow().edc_data_status === EDCItemStatus.STATUS_AVAILABLE;
  });

  edcPropertiesFromWorkFlow = computed(() => {
    const wf = this.workflow();
    if (!wf) return {} as EDCProperties;
    const edcProperties = this.selectEDCProperties(wf);
    return edcProperties;
  });

  data: unknown;

  parent!: ChecklistRowInfoComponent;

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  statusItem$ = new BehaviorSubject<EDCItem>({ text: '', bgColor: '', borderColor: '', color: '' });

  edcUploadDetails$ = new BehaviorSubject<EDCUploadStatus>({
    uploaded_by: '',
    date_uploaded: 'emptystring',
  });

  loading$ = new BehaviorSubject<boolean>(false);

  id = 'GatherPatients';

  monthStatus: 'closed' | 'open' = 'closed';

  constructor() {
    effect(() => {
      if (
        this.workflow() &&
        this.edcPropertiesFromWorkFlow() &&
        this.isEDCStatusAvailableFromWorkflow()
      ) {
        this.setStatesFromWorkflow();
      }
    });
    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed())
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
    this.monthStatus = dayjs(
      this.quarterCloseChecklistPeriodCloseService.selectedQuarterMonth
    ).isSame(this.quarterCloseChecklistPeriodCloseService.currentOpenMonth)
      ? 'open'
      : 'closed';
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  defaultPath = () => {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/checklist`;
  };

  pathFN = () => {
    const selectedMonth = this.quarterCloseChecklistPeriodCloseService.selectedQuarterMonth;
    return `${this.defaultPath()}/${selectedMonth}/`;
  };

  selectEDCProperties(workflow: Workflow) {
    const edcProperties = {
      ...pick(workflow.properties, [
        'edc_data_status',
        'edc_data_status_update_date',
        'edc_data_status_updated_by',
        'last_customer_notified_date',
        'last_customer_notified_by',
      ]),
    } as EDCProperties;
    return edcProperties;
  }

  async setInitialState() {
    if (!this.workflow() || !this.isEDCStatusAvailableFromWorkflow()) {
      this.loading$.next(true);
      const files = await this.fetchFiles();
      this.setStatesFromFiles(files);
      this.loading$.next(false);
    }
  }

  setStatusState(status: EDCItemStatus): void {
    this.statusItem$.next(EDCStatusMap[status]);
  }

  setStatesFromFiles(savedFiles: File[] | listDocumentsQuery['items']) {
    const files = savedFiles;
    this.setStatusState(
      files.length > 0 ? EDCItemStatus.STATUS_PROCESSING : EDCItemStatus.STATUS_AWAITING_UPLOAD
    );
    this.edcUploadDetails$.next(getEDCUploadStatusFromFiles(files));
  }

  setStatesFromWorkflow() {
    const edcProperties = this.edcPropertiesFromWorkFlow();
    const updateDate = edcProperties.edc_data_status_update_date;
    const updatedBy = edcProperties.edc_data_status_updated_by;
    const status = edcProperties.edc_data_status;
    this.setStatusState(status as EDCItemStatus);
    this.edcUploadDetails$.next({
      uploaded_by: updatedBy || '',
      date_uploaded: updateDate || 'kk',
    });
  }

  async fetchFiles(): Promise<listDocumentsQuery['items']> {
    const files = await this.apiService.getFilesByFilters(
      this.pathFN(),
      undefined,
      undefined,
      DocumentType.DOCUMENT_MANUAL_TRANSACTION_SUPPORT,
      undefined,
      undefined
    );
    return files;
  }

  onProtocolAmendments() {
    this.router.navigateByUrl(
      `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`
    );
  }

  onSites() {
    this.router.navigateByUrl(
      `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`
    );
  }

  onReviewInvestigatorData() {
    this.router.navigateByUrl(
      `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`
    );
  }

  async openUploadEDCModal() {
    const date = dayjs(this.parent.workflow.properties.month).format('MMMM YYYY');

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        { workflow: Workflow },
        {
          success: boolean;
        },
        ChecklistUploadEdcDocumentComponent
      >({
        settings: {
          header: `Upload EDC Documents - ${date}`,
          primaryButton: {
            action: async (instance) => {
              await instance?.uploadFiles();
            },
            disabled: (instance) => {
              if (!instance) {
                return false;
              }

              const filesNotUploaded = instance.filesNotUploaded();
              const removedFiles = instance.removedFiles$.value;

              return (
                !filesNotUploaded ||
                !removedFiles ||
                (!removedFiles.length && !filesNotUploaded.length)
              );
            },
          },
        },
        content: ChecklistUploadEdcDocumentComponent,
        data: { workflow: this.parent.workflow },
      }).afterClosed$
    );

    if (resp.data?.success) {
      this.overlayService.openPopup({
        content: ChecklistEdcDocumentConfirmationComponent,
        settings: {
          header: 'Document(s) Uploaded Successfully!',
          secondaryButton: {
            hidden: true,
          },
          primaryButton: {
            label: 'OK',
          },
        },
      });
    } else {
      this.setInitialState();
    }
  }

  getDisabledTooltip() {
    if (this.monthStatus !== 'open') {
      return 'Can only upload EDC data for current month.';
    }
    if (this.isWorkflowLocked()) {
      return 'Locked for Period Close. Unlock to Upload EDC Data.';
    }
    return '';
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    return user ? `${user.given_name} ${user.family_name}` : Utils.zeroHyphen;
  }

  dateFormatter = (date: string) => Utils.dateFormatter(date);
}
