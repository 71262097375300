import { Component, Input } from '@angular/core';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'aux-checklist-row-info-gather-invoices',
  templateUrl: './checklist-row-info-gather-invoices.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistRowInfoGatherInvoicesComponent {
  @Input() isLoadingList = false;

  @Input() numberOfInvoices = Utils.zeroHyphen;

  @Input() amountInvoiced = Utils.zeroHyphen;
}
