<!-- Info and Detail Row -->
<div
  class="min-h-[70px] border border-t-0 flex flex-col p-4 justify-center text-aux-black"
  [ngClass]="{ 'bg-aux-gray-light': open }"
>
  <!-- Info Row -->
  <div class="flex items-center w-full space-x-[20px]">
    <!-- Dropdown Icon -->
    <div class="mr-2" [style.cursor]="disabledRow ? 'default' : 'pointer'">
      <aux-icon name="ChevronDown" *ngIf="!open && disabledRow" style="color: lightgray" />
      <aux-icon name="ChevronDown" *ngIf="!open && !disabledRow" (click)="toggleRow()" />
      <aux-icon name="ChevronUp" *ngIf="open" (click)="toggleRow()" />
    </div>

    @switch (title) {
      @case (rowTitles.GatherContracts) {
        <aux-gather-contract-row-title class="!ml-0" />
      }
      @case (rowTitles.ForecastCurves) {
        <aux-forecast-curves-row-title class="!ml-0" />
      }
      @case (rowTitles.GatherInvoices) {
        <aux-gather-invoices-row-title
          class="!ml-0"
          [invoiceLink]="route"
          [selectedMonth]="selectedMonth"
        />
      }
      @default {
        <div class="flex-grow !ml-0">
          <span
            *ngIf="route"
            class="btn--hyperlink px-0 cursor-pointer"
            [routerLink]="[route]"
            [queryParams]="queryParams"
          >
            {{ title }}
          </span>
          <span *ngIf="!route" class="checklist-row-info-title">{{ title }}</span>
        </div>
      }
    }

    <!-- Displays extra columns (specific to each checklist item) -->
    <ng-container *ngIf="workflow">
      <ng-content></ng-content>
    </ng-container>

    <!-- Monthly Review Notes Counter -->
    <div *ngIf="originalWorkflow" class="shrink-0 flex items-center justify-center">
      <aux-monthly-review-notes-counter
        [workflow$]="workflow$"
        [opened$]="opened$"
        (clicked)="toggleRow()"
      />
    </div>

    <!-- Last Update -->
    <div *ngIf="originalWorkflow && !isChecklistTimestampEnabled()" class="w-28">
      <div class="text-sm">Last Update</div>

      <div>
        {{ originalWorkflow.last_update_date | date }}
      </div>
    </div>

    <!-- Last Update for QA Testing. Will only appear if feature flag is on -->
    <div *ngIf="originalWorkflow && isChecklistTimestampEnabled()" class="w-28">
      <div class="text-sm">Last Update</div>

      <div>
        {{ originalWorkflow.last_update_date | date: 'MMM d, y HH:mm' }}
      </div>
    </div>

    <!-- Updated by -->
    <div *ngIf="originalWorkflow" class="w-28">
      <div class="text-sm">Updated by</div>

      <aux-first-name-show
        [text]="originalWorkflow.last_updated_by_full_name"
        [showHyphenIfEmpty]="true"
      />
    </div>

    <!-- Locked by -->
    <div *ngIf="workflow && locked" class="w-36">
      <div class="text-sm">Locked by</div>

      <aux-first-name-show [text]="lockedBy" [showHyphenIfEmpty]="true" />
    </div>

    <!-- Assign To -->
    <div *ngIf="workflow && !locked" class="grid w-36">
      <div class="text-sm">Assign To</div>
      <ng-select
        *ngIf="isAdmin || checklistAdminPermission; else noAssignToPermissions"
        placeholder="Select User"
        [clearable]="true"
        [formControl]="assignToFormControl"
        (change)="changeAssign(workflow)"
      >
        <ng-container *ngVar="externalUserWithPermissionsList$ | async as usersList">
          <ng-option *ngFor="let user of usersList" [value]="user.sub">
            <aux-first-name-show
              [text]="
                (user.given_name + ' ' + user.family_name).length > 30
                  ? (user.given_name + ' ' + user.family_name).substring(0, 30)
                  : user.given_name + ' ' + user.family_name
              "
              [showHyphenIfEmpty]="true"
            />
          </ng-option>
        </ng-container>
      </ng-select>

      <ng-template #noAssignToPermissions>
        <aux-first-name-show [text]="user ? user : zeroHyphen" [showHyphenIfEmpty]="true" />
      </ng-template>
    </div>

    <!-- Date Locked -->
    <div *ngIf="workflow" class="w-28">
      <div class="text-sm">Date Locked</div>

      <div>
        {{ locked ? (lockedDate | date) : zeroHyphen }}
      </div>
    </div>

    <div
      *ngIf="locked && workflow"
      class="w-28 pr-7"
      [attr.data-pendo-id]="
        this.title === rowTitles.GatherContracts
          ? 'site-contracts-change-orders-unlock'
          : this.title === rowTitles.GatherInvoices
            ? 'review-invoices-unlock'
            : this.title === rowTitles.GatherEstimates
              ? 'review-vendor-estimates-unlock'
              : this.title === rowTitles.GatherPatients
                ? 'confirm-patient-data-unlock'
                : this.title === rowTitles.ForecastTimeline
                  ? 'confirm-trial-timeline-unlock'
                  : this.title === rowTitles.ForecastCurves
                    ? 'site-and-patient-curves-unlock'
                    : this.title === rowTitles.ForecastMethodology
                      ? 'forecast-methodology-unlock'
                      : this.title === rowTitles.CloseExpenses
                        ? 'confirm-vendor-expenses-unlock'
                        : this.title === rowTitles.CloseDiscounts
                          ? 'confirm-discounts-unlock'
                          : ''
      "
      [auxTooltip]="getUnlockBtnTooltip()"
    >
      <aux-button
        [disabled]="!hasUserPermissions || disabledLock"
        (clickEmit)="changeLockStatus(false, workflow, false)"
        icon="Check"
        label="Unlock"
        variant="hyperlink"
        classList="flex-col"
        svgAttributes="text-aux-green-dark"
      />
    </div>

    <div *ngIf="!locked && workflow" class="w-28">
      <div
        class="flex mr-6"
        [attr.data-pendo-id]="
          this.title === rowTitles.GatherContracts
            ? 'site-contracts-change-orders-lock'
            : this.title === rowTitles.GatherInvoices
              ? 'review-invoices-lock'
              : this.title === rowTitles.GatherEstimates
                ? 'review-vendor-estimates-lock'
                : this.title === rowTitles.GatherPatients
                  ? 'confirm-patient-data-lock'
                  : this.title === rowTitles.ForecastTimeline
                    ? 'confirm-trial-timeline-lock'
                    : this.title === rowTitles.ForecastCurves
                      ? 'site-and-patient-curves-lock'
                      : this.title === rowTitles.ForecastMethodology
                        ? 'forecast-methodology-lock'
                        : this.title === rowTitles.CloseExpenses
                          ? 'confirm-vendor-expenses-lock'
                          : this.title === rowTitles.CloseDiscounts
                            ? 'confirm-discounts-lock'
                            : ''
        "
        [auxTooltip]="getLockBtnTooltip()"
      >
        <aux-button
          [disabled]="!hasUserPermissions || disabledLock"
          (clickEmit)="changeLockStatus(true, workflow, false)"
          icon="Lock"
          label="Lock"
        />
      </div>
    </div>
  </div>

  <!-- Detail Row -->
  <div *ngIf="checklistComponent && open && workflow" [ngClass]="detailRowContainerClassName">
    <ng-template checklistComponentHost></ng-template>
  </div>
  <aux-checklist-notes
    *ngIf="open"
    [checklistAdminPermission]="checklistAdminPermission"
    [workflow$]="workflow$"
  />
</div>
