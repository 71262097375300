import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { BaseFormControlComponent } from '../../../shared/components/base-form-control/base-form-control';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'aux-select',
  templateUrl: './select.component.html',
  styles: [
    `
      :host {
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgClass, IconComponent, OverlayModule, NgFor, NgIf],
})
export class SelectComponent extends BaseFormControlComponent<string | null> implements OnInit {
  @Input() options: string[] = [];

  highlighted: number | null = null;

  selected = '';

  selectedIndex: number | null = null;

  optionCount = 0;

  isListOpen = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  openList(trigger: CdkOverlayOrigin) {
    console.log('trigger', trigger);
    this.isListOpen = true;
    if (this.selectedIndex != null) {
      this.highlighted = this.selectedIndex;
    } else {
      this.highlighted = 0;
    }
    this.cdr.detectChanges();
  }

  closeList() {
    this.isListOpen = false;
    this.highlighted = null;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.optionCount = this.options.length;
    if (this.options.length) {
      // this.highlightOption(0);
      // this.selectOption();
    }
  }

  highlightOption(index: number): void {
    this.highlighted = index;
  }

  selectOption(): void {
    const index = this.highlighted;
    if (index != null) {
      this.selected = this.options[index];
      this.selectedIndex = index;
      this.closeList();
    }
  }

  onListKeydown(keydownEvent: KeyboardEvent): void {
    keydownEvent.preventDefault();
    // tslint:disable-next-line: deprecation
    switch (keydownEvent.key) {
      case 'Enter':
      case 'Space':
        this.selectOption();
        break;
      case 'ArrowUp':
        if (this.highlighted) {
          const index = this.highlighted - 1 < 0 ? this.optionCount - 1 : this.highlighted - 1;
          this.highlightOption(index);
        }
        break;
      case 'ArrowDown':
        if (this.highlighted) {
          const index2 = this.highlighted + 1 > this.optionCount - 1 ? 0 : this.highlighted + 1;
          this.highlightOption(index2);
        }
        break;
      case 'Escape':
        this.closeList();
        break;
      default:
        break;
    }
  }
}
