<div class="font-inter w-screen max-w-2xl">
  <div class="grid grid-cols-2 gap-5">
    <div class="flex flex-col">
      <div class="mb-2">Invoice #: {{ invoiceNumber }}</div>
      <div>Vendor: {{ vendor }}</div>
    </div>
    <div class="flex flex-col">
      <div class="mb-2 text-xs">Upload invoice and supporting docs</div>
      <aux-file-manager
        #fileManager
        class="h-32"
        [eager]="false"
        [document_entity_id]="invoiceId"
        [document_entity_type_id]="INVOICE"
        [pathFn]="getFilePath"
      />
    </div>
  </div>

  <ng-container *ngIf="(loadingPreviousFiles$ | async) === true">
    <div class="border-8 h-10 m-auto mt-10 spinner w-10"></div>
  </ng-container>

  <ng-container *ngIf="files$ | async as files">
    <ng-container *ngIf="files?.length">
      <div class="grid grid-cols-12 mt-4 max-h-[300px] overflow-y-auto">
        <div class="col-span-7 pb-2 text-xs border-b-2">Uploaded Files</div>
        <div class="col-span-5 pb-2 text-xs border-b-2">Designation</div>

        <ng-container *ngFor="let file of files" class="border-b">
          <div class="flex items-center col-span-7 p-2 border-b break-all">
            {{ file.fileName }}
          </div>

          <div class="flex items-center col-span-4 py-2 border-b">
            <div class="flex items-center flex-1" [auxTooltip]="getTooltip(true, file)">
              <input
                type="radio"
                value="DOCUMENT_INVOICE"
                class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                [id]="file.id + 'inc'"
                [name]="file.id"
                [disabled]="isFilePdf(file) === false"
                [(ngModel)]="documentTypes[file.id]"
                (ngModelChange)="updateValidation.next(file)"
              />
              <label class="ml-3" [for]="file.id + 'inc'">
                <span class="block text-sm font-medium text-gray-700">Invoice</span>
              </label>
            </div>

            <div class="flex items-center flex-1" [auxTooltip]="getTooltip(undefined, file)">
              <input
                type="radio"
                value="DOCUMENT_INVOICE_SUPPORT"
                class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                [id]="file.id + 'sup'"
                [name]="file.id"
                [(ngModel)]="documentTypes[file.id]"
                (ngModelChange)="updateValidation.next(file)"
              />
              <label class="ml-3" [for]="file.id + 'sup'">
                <span class="block text-sm font-medium text-gray-700">Supporting Document</span>
              </label>
            </div>
          </div>

          <div class="flex justify-end border-b">
            <button class="p-2" type="button" (click)="removeFile(file)">
              <aux-icon name="Trash" class="text-aux-error" />
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
