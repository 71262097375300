import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { EventService } from '@models/event/event.service';
import {
  ActionType,
  Currency,
  EntityType,
  EventType,
  GqlService,
  listUserNamesWithEmailQuery,
  ModifyPrepaidInput,
  PermissionType,
  PrepaidType,
  TrialImplementationStatus,
  User,
} from '@shared/services/gql.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import {
  CellClassParams,
  Column,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  ITooltipParams,
} from '@ag-grid-community/core';
import { AdjustmentsToPrepaidGridOptions } from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.constants';
import { ButtonComponent } from '@shared/components/button/button.component';
import { cloneDeep, differenceWith, isEqual, isNull } from 'lodash-es';
import { ArrElement, ExportType, Utils } from '@shared/utils/utils';
import { tap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { AgSetColumnsVisible, decimalAdd } from '@shared/utils';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import dayjs from 'dayjs';
import { TableConstants } from '@shared/constants/table.constants';
import { NewValueParams } from '@ag-grid-community/core/dist/esm/es6/entities/colDef';
import {
  AdjustmentsToPrepaidErrorRow,
  AdjustmentsToPrepaidRow,
  AdjustmentsToPrepaidTotals,
} from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.model';
import { OverlayService } from '@shared/services/overlay.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { AdjustmentsToPrepaidService } from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.service';
import { AgActionsComponentParams } from '@shared/ag-components/ag-actions/ag-actions.component';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '@shared/store/auth/auth.service';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { decimalDifference } from '@shared/utils/floating-math';

@Component({
  selector: 'aux-adjustments-to-prepaid',
  templateUrl: 'adjustments-to-prepaid.component.html',
  styleUrl: 'adjustments-to-prepaid.component.scss',
  standalone: true,
  imports: [
    AgGridAngular,
    TableSkeletonComponent,
    ButtonComponent,
    TooltipDirective,
    ExportExcelButtonComponent,
    AsyncPipe,
    StickyGridDirective,
  ],
  providers: [AdjustmentsToPrepaidService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdjustmentsToPrepaidComponent implements OnInit {
  workflowQuery = inject(WorkflowQuery);
  authService = inject(AuthService);

  hasEditTermsPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_UPDATE_PREPAIDS],
  });

  @Input() vendorId = '';
  @Input() vendorName = '';
  @Input() vendorCurrency = Currency.USD;
  isEditDisabled = input.required<boolean>();
  @Output() adjustmentsSaved = new EventEmitter<void>();

  prepaidLockTooltip = computed(() => {
    return !this.hasEditTermsPermission()
      ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
      : this.workflowQuery.prepaidLockTooltip();
  });

  gridData = signal<AdjustmentsToPrepaidRow[]>([]);
  loadingGridData = signal<boolean>(true);
  hasChanges = signal(false);
  errorRows = signal<Record<string, AdjustmentsToPrepaidErrorRow>>({});

  editMode$ = new BehaviorSubject(false);

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  showErrors = false;
  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();
  gridAPI!: GridApi;
  gridOptions: GridOptions = {};
  initialGridValues: AdjustmentsToPrepaidRow[] = [];
  addedGridValues: string[] = [];
  removedGridValues: string[] = [];
  currentOpenMonth = '';
  totals: AdjustmentsToPrepaidTotals = {
    total_impact_to_prepaid: 0,
    short_term_amount: 0,
    long_term_amount: 0,
    services_amount: 0,
    passthrough_amount: 0,
    investigator_amount: 0,
    invoice_total: 0,
    total_expense_per_invoice: 0,
  };

  getAuthor = (id: string): listUserNamesWithEmailQuery => {
    return this.users.get(id || '') as listUserNamesWithEmailQuery;
  };

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private authQuery: AuthQuery,
    private overlayService: OverlayService,
    private adjustmentsToPrepaidService: AdjustmentsToPrepaidService,
    private eventService: EventService
  ) {
    this.setUserList();
    this.initCurrentOpenMonth();

    this.editMode$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.gridAPI) {
        this.gridAPI.refreshHeader();
      }
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.gqlService.listInvoicesForPrepaids$({ vendor_id: this.vendorId }),
      this.gqlService.listPrepaids$({ vendor_id: this.vendorId }),
    ]).subscribe(([invoicesResult, prepaidsResult]) => {
      if (invoicesResult.success && prepaidsResult.success) {
        this.gridOptions = AdjustmentsToPrepaidGridOptions(
          this.vendorCurrency,
          this.getAuthor,
          this.authQuery.isAuxAdmin(),
          this.editMode$,
          invoicesResult.data || [],
          this.getCellClasses,
          this.recalculateCells,
          this.onCellValueChanged,
          this.checkError,
          this.removeRow,
          this.isCellEditable,
          this.tooltipValueGetter,
          this.currentOpenMonth
        );
        this.initialGridValues = prepaidsResult.data
          ? prepaidsResult.data.map((adjustment) =>
              this.adjustmentsToPrepaidService.mapInitialGridValue(adjustment)
            )
          : [];

        this.gridData.set(cloneDeep(this.initialGridValues));
      }

      this.loadingGridData.set(false);
    });
  }

  initCurrentOpenMonth(): void {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(takeUntilDestroyed())
      .subscribe((date) => {
        const openMonth = dayjs(date);

        this.currentOpenMonth = openMonth.isValid() ? openMonth.format('YYYY-MM') : '';
      });
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
    api.sizeColumnsToFit();
    this.setBottomData();
  }

  onEdit = (): void => {
    this.editMode$.next(true);
    this.onEditModeOn();
    const firstItemWithoutPrepaidType = this.gridAPI
      .getRenderedNodes()
      .find((node) => !node.data.prepaid_type);
    const rowIndex =
      firstItemWithoutPrepaidType && !isNull(firstItemWithoutPrepaidType.rowIndex)
        ? firstItemWithoutPrepaidType.rowIndex
        : this.gridAPI.getRenderedNodes().length - 1;

    setTimeout(() => {
      this.gridAPI?.startEditingCell({
        rowIndex: rowIndex,
        colKey: 'prepaid_type',
      });
    }, TableConstants.ANIMATION_TIME);
  };

  onCancel = (): void => {
    this.editMode$.next(false);
    this.gridData.set(cloneDeep(this.initialGridValues));
    setTimeout(() => this.setBottomData());
    this.onEditModeOff();
  };

  onSaveAll = (): void => {
    if (Object.keys(this.errorRows()).length || this.isTotalsNegative()) {
      const errorsToShowOverlay = this.adjustmentsToPrepaidService.errorsToShowOverlay(
        this.errorRows()
      );

      if (this.isTotalsNegative()) {
        errorsToShowOverlay.push(MessagesConstants.PREPAIDS.PREPAID_BALANCE_NEGATIVE);
      }

      if (errorsToShowOverlay.length > 0) {
        this.overlayService.error(errorsToShowOverlay, undefined, true);
      }

      this.showErrors = true;
      this.gridAPI.refreshCells();
      return;
    }

    const currentValues: AdjustmentsToPrepaidRow[] = [];

    this.gridAPI.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
      });
    });

    const changedRows = differenceWith(currentValues, this.initialGridValues, (obj1, obj2) => {
      return isEqual(obj1, obj2);
    });

    const adjustmentsToSave: ModifyPrepaidInput[] = [];

    if (changedRows.length > 0) {
      changedRows.forEach((changedRow) => {
        adjustmentsToSave.push(
          this.adjustmentsToPrepaidService.mapToAdjustmentToSave(
            changedRow.isNewAdjustment
              ? ActionType.ACTION_TYPE_CREATE
              : ActionType.ACTION_TYPE_UPDATE,
            this.vendorId,
            changedRow,
            ''
          )
        );
      });
    }

    if (this.removedGridValues.length > 0) {
      this.removedGridValues.forEach((removedRowId) => {
        adjustmentsToSave.push(
          this.adjustmentsToPrepaidService.mapToAdjustmentToSave(
            ActionType.ACTION_TYPE_REMOVE,
            this.vendorId,
            {} as AdjustmentsToPrepaidRow,
            removedRowId
          )
        );
      });
    }

    this.loadingGridData.set(true);
    this.gqlService.batchModifyPrepaids$(adjustmentsToSave).subscribe((result) => {
      if (result.success) {
        this.initialGridValues = result.data
          ? result.data.map((adjustment) =>
              this.adjustmentsToPrepaidService.mapInitialGridValue(adjustment)
            )
          : [];
        this.gridData.set(cloneDeep(this.initialGridValues));
        this.overlayService.success(MessagesConstants.SAVED);
        this.adjustmentsSaved.emit();
        this.onEditModeOff();
        this.editMode$.next(false);
      } else if (result.errors.length) {
        this.overlayService.error(result.errors);
      }
      this.loadingGridData.set(false);
    });
  };

  onAddAdjustment = (): void => {
    this.editMode$.next(true);
    this.onEditModeOn();
    const newAdjustment = <AdjustmentsToPrepaidRow>{
      id: Utils.uuid(),
      prepaid_type: null,
      create_date: dayjs().toISOString(),
      created_by: this.authQuery.getValue().sub,
      isNewAdjustment: true,
    };

    const transaction = this.gridAPI.applyTransaction({
      add: [
        {
          ...newAdjustment,
        },
      ],
    });

    this.errorRows.update((errors) => {
      return {
        ...errors,
        [newAdjustment.id]: {
          causes: ['prepaid_type'],
          adjustment: newAdjustment,
        },
      };
    });

    setTimeout(() => {
      if (transaction?.add.length && transaction.add[0].rowIndex) {
        this.gridAPI?.startEditingCell({
          rowIndex: transaction.add[0].rowIndex,
          colKey: 'prepaid_type',
        });
      }
    }, TableConstants.ANIMATION_TIME);

    this.checkChanges();
    this.addedGridValues.push(newAdjustment.id);
  };

  removeRow = (rowNode: IRowNode): void => {
    if (rowNode.data.isNewAdjustment) {
      this.addedGridValues.splice(this.addedGridValues.indexOf(rowNode.data.id), 1);
    } else {
      this.removedGridValues.push(rowNode.data.id);
    }

    this.gridAPI.applyTransaction({ remove: [rowNode.data] });
    this.errorRows.update((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [rowNode.data.id]: _, ...rest } = state;
      return {
        ...rest,
      };
    });
    this.checkChanges();
    this.setBottomData();
  };

  onExport = async () => {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.PREPAID_DETAIL,
          filename: `${trialName}_${this?.vendorName}_auxilius-prepaids_${dateStr}`,
          export_entity_id: this.vendorId || '',
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  };

  isCellEditable = (
    params: EditableCallbackParams<AdjustmentsToPrepaidRow> | AgActionsComponentParams
  ): boolean => {
    const status = this.mainQuery.getSelectedTrial()?.implementation_status;
    const openMonth = this.mainQuery.getValue().currentOpenMonth;
    const initialRowData = this.initialGridValues.find(
      (row: AdjustmentsToPrepaidRow) => row.id === params.data?.id
    );

    if (!params.data || !status || !params.column) {
      return true;
    }

    if (params.data.invoice_id) {
      if (
        params.column.getColId() === 'accrual_period' ||
        params.column.getColId() === 'invoice_total' ||
        params.column.getColId() === 'invoice_date' ||
        params.column.getColId() === 'payment_date'
      ) {
        return false;
      }
    }

    if (!initialRowData) {
      return true;
    }

    if (
      status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
      status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
    ) {
      if (params.column.getColId() === 'payment_date') {
        return !(
          initialRowData.payment_date && dayjs(initialRowData.payment_date).isBefore(openMonth)
        );
      }

      if (
        initialRowData.accrual_period &&
        dayjs(initialRowData.accrual_period).isBefore(openMonth)
      ) {
        return false;
      }
    }

    return true;
  };

  tooltipValueGetter = (params: ITooltipParams<AdjustmentsToPrepaidRow>): string => {
    if (params.node && this.editMode$.getValue()) {
      const column = params.column as Column;
      const status = this.mainQuery.getSelectedTrial()?.implementation_status;
      const initialRowData = this.initialGridValues.find(
        (row: AdjustmentsToPrepaidRow) => row.id === params.data?.id
      );
      const openMonth = this.mainQuery.getValue().currentOpenMonth;

      if (!initialRowData || column.isCellEditable(params.node)) {
        return '';
      }

      if (params.data?.invoice_id) {
        if (
          column.getColId() === 'accrual_period' ||
          column.getColId() === 'invoice_total' ||
          column.getColId() === 'invoice_date' ||
          column.getColId() === 'payment_date'
        ) {
          return MessagesConstants.PREPAIDS.INVOICE_POPULATED;
        }
      }

      if (
        status === TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED ||
        status === TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE
      ) {
        if (column.getColId() === 'payment_date') {
          if (
            initialRowData.payment_date &&
            dayjs(initialRowData.payment_date).isBefore(openMonth)
          ) {
            return MessagesConstants.PREPAIDS.PAYMENT_DATE_IN_CLOSED_MONTH_TOOLTIP;
          }

          return '';
        }

        if (
          initialRowData.accrual_period &&
          dayjs(initialRowData.accrual_period).isBefore(openMonth)
        ) {
          return MessagesConstants.PREPAIDS.ADJUSTMENT_IN_CLOSED_MONTH;
        }
      }
    }

    return '';
  };

  private setUserList(): void {
    this.mainQuery
      .select('userList')
      .pipe(
        tap((_users) => {
          _users.forEach((user: listUserNamesWithEmailQuery) => {
            this.users.set(user.sub, user);
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  getCellClasses =
    (classes: string[] = []) =>
    (params: CellClassParams) => {
      const cellClasses = [...classes];
      if (this.editMode$.getValue()) {
        if (params.column.isCellEditable(params.node) || params.node.rowPinned) {
          cellClasses.push(TableConstants.STYLE_CLASSES.EDITABLE_CELL);

          if (
            params.column.getColId() === 'prepaid_type' ||
            params.column.getColId() === 'invoice_id'
          ) {
            cellClasses.push('dropdown-edit-cell ag-cell-align-left');
          }
        } else {
          cellClasses.push(TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL, 'cursor-not-allowed');
        }
      }

      return cellClasses;
    };

  recalculateCells = (params: NewValueParams): void => {
    params.data.total_impact_to_prepaid = decimalAdd(
      params.data.short_term_amount || 0,
      params.data.long_term_amount || 0,
      2
    );

    if (params.data.prepaid_type === PrepaidType.PREPAID_TYPE_AMORTIZE) {
      params.data.total_expense_per_invoice = decimalDifference(
        params.data.invoice_total,
        params.data.total_impact_to_prepaid,
        2
      );
    } else {
      params.data.total_expense_per_invoice = params.data.invoice_total;
    }
  };

  onCellValueChanged = (params: NewValueParams): void => {
    const causes = this.adjustmentsToPrepaidService.getCauses(params.data, this.initialGridValues);

    this.setBottomData();

    if (causes.length > 0) {
      this.errorRows.update((errors) => {
        return {
          ...errors,
          [params.data.id]: { causes, adjustment: params.data },
        };
      });
    } else {
      this.errorRows.update((state) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [params.data.id]: _, ...rest } = state;
        return {
          ...rest,
        };
      });
    }

    if (params.node) {
      params.api.refreshCells({
        rowNodes: [params.node],
        force: true,
      });
    }

    this.checkChanges();
  };

  checkError = (
    params: CellClassParams<AdjustmentsToPrepaidRow>,
    cause: ArrElement<AdjustmentsToPrepaidErrorRow['causes']>
  ): boolean => {
    if (!this.showErrors) {
      return false;
    }

    if (params.node.rowPinned) {
      if (params.column.getColId() === 'short_term_amount') {
        return params.data?.short_term_amount ? params.data.short_term_amount < 0 : false;
      }

      if (params.column.getColId() === 'long_term_amount') {
        return params.data?.long_term_amount ? params.data.long_term_amount < 0 : false;
      }

      if (params.column.getColId() === 'services_amount') {
        return params.data?.services_amount ? params.data.services_amount < 0 : false;
      }

      if (params.column.getColId() === 'passthrough_amount') {
        return params.data?.passthrough_amount ? params.data.passthrough_amount < 0 : false;
      }

      if (params.column.getColId() === 'investigator_amount') {
        return params.data?.investigator_amount ? params.data.investigator_amount < 0 : false;
      }
    }

    const row = this.errorRows()[params.data?.id || ''];

    if (!row) {
      return false;
    }

    return row.causes.includes(cause);
  };

  private isTotalsNegative(): boolean {
    return (
      this.totals.short_term_amount < 0 ||
      this.totals.long_term_amount < 0 ||
      this.totals.services_amount < 0 ||
      this.totals.passthrough_amount < 0 ||
      this.totals.investigator_amount < 0
    );
  }

  private onEditModeOn(): void {
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: true,
    });
    this.gridAPI.sizeColumnsToFit();
    this.gridAPI?.refreshCells({ force: true });
  }

  private onEditModeOff(): void {
    this.showErrors = false;
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: false,
    });
    this.gridAPI?.refreshCells({ force: true });
    this.gridAPI.sizeColumnsToFit();
    this.addedGridValues = [];
    this.removedGridValues = [];
    this.errorRows.set({});
    this.hasChanges.set(false);
  }

  private setBottomData(): void {
    this.totals = this.calculatePinnedBottomData();

    this.gridAPI.setGridOption('pinnedBottomRowData', [
      {
        prepaid_type: 'Total',
        ...this.totals,
      },
    ]);
  }

  private calculatePinnedBottomData(): AdjustmentsToPrepaidTotals {
    const columnsWithAggregation = [
      'total_impact_to_prepaid',
      'short_term_amount',
      'long_term_amount',
      'services_amount',
      'passthrough_amount',
      'investigator_amount',
      'invoice_total',
      'total_expense_per_invoice',
    ] as const;

    this.gridAPI.selectAll();
    return this.gridAPI.getSelectedRows().reduce(
      (acc, row) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const additionalVal = row[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = decimalAdd(currentVal, additionalVal);
          }
        }

        return acc;
      },
      {
        total_impact_to_prepaid: 0,
        short_term_amount: 0,
        long_term_amount: 0,
        services_amount: 0,
        passthrough_amount: 0,
        investigator_amount: 0,
        invoice_total: 0,
        total_expense_per_invoice: 0,
      }
    );
  }

  private checkChanges(): void {
    const currentValues: AdjustmentsToPrepaidRow[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push(data);
    });

    this.hasChanges.set(!isEqual(this.initialGridValues, currentValues));
  }
}
