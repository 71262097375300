import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WORKFLOW_NAMES } from '@shared/store/workflow/workflow.const';
import { MenuComponent } from '@shared/components/menu/menu.component';

const wfNamesToPendoIds = new Map<string, string>([
  ['Review Invoices', 'review-invoices-toolbar'],
  ['Confirm Investigator Data', 'confirm-patient-data-toolbar'],
  ['Confirm Trial Timeline', 'confirm-trial-timeline-toolbar'],
  ['Site, Patient, & Custom Curves', 'site-patient-and-custom-curves-toolbar'],
  ['Forecast Methodology', 'forecast-methodology-toolbar'],
]);

@Component({
  selector: 'aux-workflow-panel-general-item',
  templateUrl: './workflow-panel-general-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgClass,
    IconComponent,
    ButtonComponent,
    TooltipDirective,
    MenuComponent,
    NgStyle,
  ],
})
export class WorkflowPanelGeneralItemComponent {
  workflowService = inject(WorkflowService);

  workflowQuery = inject(WorkflowQuery);

  workflowName = input.required<string>();

  lockAvailable = input<boolean>(true);

  isWorkflowNameVisible = input<boolean>(true);

  hasUserPermissions = input<boolean>(false);

  currentWorkflow = computed(() => {
    return this.workflowQuery.getWorkflowByStepType(this.workflowName())();
  });
  isLocked = computed(() => {
    return !!this.currentWorkflow()?.properties?.locked;
  });
  workflowTitle = computed(() => {
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) {
      return '';
    }

    const workflowName = WORKFLOW_NAMES[currentWorkflow.step_type];

    if (workflowName.includes('Confirm ')) {
      const finalNameIndex = 1;
      return workflowName.split('Confirm ')[finalNameIndex];
    }

    if (workflowName.includes('Review ')) {
      const finalNameIndex = 1;
      return workflowName.split('Review ')[finalNameIndex];
    }

    return workflowName;
  });
  getBtnLabel = computed(() => {
    const currentWorkflow = this.currentWorkflow();
    const text = currentWorkflow?.properties?.locked ? 'Unlock' : 'Lock';
    return `${text} ${this.workflowTitle()}`;
  });
  getBtnVariant = computed(() => {
    return this.isLocked() ? 'secondary' : 'primary';
  });
  getBtnIcon = computed(() => {
    return this.isLocked() ? 'LockOpen' : 'Lock';
  });
  getBtnTooltipText = computed(() => {
    return !this.hasUserPermissions() ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION : '';
  });
  getBtnDataPendoId = computed(() => {
    const currentWorkflow = this.currentWorkflow();
    const isLocked = this.isLocked();

    if (!currentWorkflow) {
      return '';
    }

    const id = wfNamesToPendoIds.get(currentWorkflow.name);
    if (!id) {
      return '';
    }

    return isLocked ? `${id}-unlock` : `${id}-lock`;
  });
  getTitle = computed(() => {
    const isLocked = this.isLocked();
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) {
      return '';
    }

    if (!isLocked) {
      return 'Unlocked - You must lock this section before closing your month.';
    }

    return `Locked by ${currentWorkflow.updatedAuthorFullName} on ${currentWorkflow.update_date}`;
  });

  onToggleLockWorkflow() {
    const currentWorkflow = this.currentWorkflow();
    if (!currentWorkflow) {
      return;
    }
    this.workflowService.changeLockStatus(
      !currentWorkflow.properties.locked,
      currentWorkflow,
      false
    );
  }
}
