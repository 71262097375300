import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { Component } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-stage-indicator',
  template: `<div
    class="bg-white rounded p-1 text-aux-blue-dark text-sm flex space-x-1 items-center"
  >
    <aux-icon name="Box" *ngIf="isSandboxEnv$ | async; else devIcon" />
    <ng-template #devIcon>
      <aux-icon name="SettingsBolt" />
    </ng-template>

    <p>{{ envName$ | async }}</p>
  </div>`,
  standalone: true,
  imports: [AsyncPipe, IconComponent, NgIf],
})
export class StageIndicatorComponent {
  envName$ = this.launchDarklyService.envName$;

  isSandboxEnv$ = new BehaviorSubject(false);

  constructor(private launchDarklyService: LaunchDarklyService) {
    this.envName$.pipe(takeUntilDestroyed()).subscribe((envName) => {
      this.isSandboxEnv$.next(!!envName.match('-sandbox'));
    });
  }
}
