import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { ROUTING_PATH } from '@shared/constants/routingPath';
import { PermissionType } from '@shared/services/gql.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';

import { InvoiceBannerButtonDirective } from './invoice-detail-banner-button.directive';
import {
  ReasonModalData,
  ReasonModalResponse,
  ReasonModalModalComponent,
} from '@shared/components/modals/reason-modal/reason-modal.component';

@Component({
  standalone: true,
  selector: 'aux-invoice-detail-status-banner-delete-button',
  template: `
    <aux-button
      [auxTooltip]="tooltip()"
      [disabled]="disabled()"
      [onClick]="onDelete"
      icon="Trash"
      variant="negative"
      label="Delete"
    />
  `,
  imports: [ButtonComponent, TooltipDirective],
  hostDirectives: [
    {
      directive: InvoiceBannerButtonDirective,
      inputs: ['invoice'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDetailDeleteBtnComponent {
  ctx = inject(InvoiceBannerButtonDirective);
  router = inject(Router);

  hasDeletePermission = this.ctx.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_DELETE_INVOICE],
  });

  disabled = computed(() => {
    return (
      !this.hasDeletePermission() ||
      this.ctx.buttonDisabled() ||
      this.ctx.isAccrualPeriodInClosedMonth()
    );
  });

  tooltip = computed(() => {
    if (!this.hasDeletePermission()) {
      return this.ctx.dontHavePermission;
    }

    if (this.ctx.buttonDisabled()) {
      return this.ctx.invoiceLockTooltip();
    }

    if (this.ctx.isAccrualPeriodInClosedMonth()) {
      return 'Invoice in closed month cannot be deleted.';
    }

    return '';
  });

  onDelete = async () => {
    const { overlayService, invoiceQuery, invoiceService, userTaskService } = this.ctx;
    const invoice = this.ctx.invoice();

    const resp = overlayService.openPopup<
      ReasonModalData,
      ReasonModalResponse,
      ReasonModalModalComponent
    >({
      content: ReasonModalModalComponent,
      settings: {
        header: 'Remove Invoice?',
        primaryButton: {
          label: 'Remove',
          action: (instance: ReasonModalModalComponent | null) => instance?.save(),
        },
      },
      data: {
        message: `Are you sure you want to remove Invoice ${invoice.invoice_no}?`,
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);

    if (event.data) {
      const id = invoiceQuery.getEntity(invoice.id);
      if (!id) {
        return;
      }

      const { success } = await invoiceService.remove(id, event.data?.reason);

      if (success) {
        await userTaskService.triggerUserTaskList$();
        await this.router.navigateByUrl(
          `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`
        );
      }
    }
  };
}
