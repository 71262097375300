import { HeaderClassParams, GridOptions, ICellRendererParams } from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import { Currency } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { AgIconLinkCellComponent } from '@shared/ag-components/ag-icon-link-cell/ag-icon-link-cell.component';

export interface GridRowData {
  id: string;
  name: string;
  optional: boolean;
  amount: number;
  note: string;
  paymentScheduleId: string | null;
  currency: Currency;
}

interface Params {
  getEditableHeaderClasses: (
    cellClasses: string[]
  ) => (cellClassParams: HeaderClassParams) => string[];
  currency: Currency;
}

export const getSiteBudgetGridAmountOptions = (
  editMode: boolean,
  { getEditableHeaderClasses, currency }: Params,
  hideOptional: boolean
): GridOptions<GridRowData> => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  suppressMenuHide: true,
  fillHandleDirection: 'y',
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    editable: editMode,
  },
  columnDefs: [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'paymentScheduleId',
      hide: true,
    },
    {
      field: 'currency',
      hide: true,
    },
    {
      headerName: 'Item',
      field: 'name',
      minWidth: 120,
      editable: false,
      tooltipField: 'name',
    },
    {
      headerName: 'Optional',
      field: 'optional',
      minWidth: 105,
      maxWidth: 105,
      hide: hideOptional,
      resizable: false,
      editable: false,
      cellRenderer: AgIconLinkCellComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        hideText: true,
        justifyCenter: true,
        icons: [
          {
            showIcon: params.value,
            icon: 'Check',
            iconClass: 'text-aux-green-dark',
          },
        ],
      }),
    },
    {
      headerValueGetter: () => `Amount (${currency})`,
      field: 'amount',
      valueFormatter: (params) => Utils.agCurrencyFormatter(params, currency || Currency.USD),
      headerClass: getEditableHeaderClasses(['ag-header-cell']),
      editable: editMode,
      minWidth: 90,
      cellDataType: 'text',
      valueParser: (params) => {
        return params.newValue === params.oldValue
          ? params.oldValue
          : Utils.castStringToNumber(params.newValue).toString();
      },
    },
    {
      headerName: 'Notes',
      field: 'note',
      minWidth: 200,
      headerClass: getEditableHeaderClasses(['ag-header-cell']),
      editable: editMode,
      tooltipField: 'note',
    },
  ],
  processCellFromClipboard: (params) =>
    params.column.getId() === 'amount'
      ? Utils.castStringToNumber(params.value).toString()
      : params.value,
});
