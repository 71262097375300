import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-investigator-forecast-update-modal',
  template: `<div class="font-inter w-[570px]">
    <div class="text-xl font-bold text-aux-blue-dark">Confirm New Investigator Forecast</div>
    <section class="flex flex-col">
      <p class="my-7">
        Please confirm that you are changing your remaining investigator balance source to:
        <span class="font-bold">{{ ref.data?.source }}</span
        >.
      </p>
      <p>
        Saving this change will overwrite the remaining investigator balance on your existing
        budget.
      </p>
      <div class="mt-7 flex justify-between items-center border-t border-aux-gray-dark pt-3">
        <span class="cursor-pointer" (click)="ref.close()">No, Cancel</span>
        <aux-button
          variant="success"
          label="Confirm Investigator Forecast"
          icon="Check"
          [classList]="'text-sm'"
          [onClick]="confirm"
        />
      </div>
    </section>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastUpdateModalComponent {
  constructor(public ref: CustomOverlayRef<unknown, { source: string }>) {}

  confirm = () => {
    this.ref.close({ result: true });
  };
}
