import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import dayjs from 'dayjs';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'aux-gather-invoices-row-title',
  template: `
    <div>
      <span>Review </span>
      <div
        class="btn--hyperlink px-0 cursor-pointer"
        [routerLink]="invoiceLink()"
        (click)="goToInvoices()"
      >
        Invoices
      </div>
      <span>, </span>
      <div
        class="inline-block"
        [ngClass]="{
          'btn--hyperlink px-0 cursor-pointer': prepaidFlag()
        }"
        (click)="goToPrepaid()"
      >
        Prepaids
      </div>
      <span>, and </span>
      <span class="btn--hyperlink px-0 cursor-pointer" [routerLink]="invoiceLink()"
        >Credit Memos</span
      >
    </div>
  `,
  standalone: true,
  styles: [
    `
      :host {
        flex-grow: 1;

        .link {
          color: rgba(9, 91, 149);
        }
      }
    `,
  ],
  imports: [RouterLink, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GatherInvoicesRowTitleComponent {
  invoiceService = inject(InvoiceService);
  launchDarklyService = inject(LaunchDarklyService);
  router = inject(Router);

  selectedMonth = input.required<string>();
  invoiceLink = input.required<string>();

  prepaidFlag = this.launchDarklyService.$select((flags) => flags.prepaids);

  prepaidLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PREPAIDS}`;

  goToInvoices() {
    const currentSelectedMonth = dayjs(this.selectedMonth()).format('YYYY-MM-DD');
    this.invoiceService.setAccrualPeriodsAndVendorFilter([currentSelectedMonth]);
  }

  goToPrepaid() {
    if (this.prepaidFlag()) {
      this.router.navigateByUrl(this.prepaidLink);
    }
  }
}
