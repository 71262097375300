<div class="h-full py-3 w-[500px]">
  <aux-file-manager
    #fileManager
    [eager]="false"
    class="h-[306px]"
    [pathFn]="pathFN"
    [fetchFilesOnInit]="true"
    [document_type_id]="documentTypeId"
    (filesRemoved)="removeFileChange($event)"
  >
  </aux-file-manager>
  @if (fileManager) {
    <div class="mt-4 overflow-y-auto max-h-[200px]">
      <aux-file-viewer
        [fileManager]="fileManager"
        [disableFirstFileMargin]="true"
        [onlyShowUploaded]="false"
        [type]="'edc'"
        [showUserAndDate]="true"
      />
    </div>
  }
</div>
