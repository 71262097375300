<ng-container *ngIf="(siteGroupQuery.selectLoading() | async) === true; else grid">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-template #grid>
  <div class="mb-24 mt-24">
    <div class="flex justify-end mb-4">
      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === false"
        variant="secondary"
        label="Edit"
        icon="Pencil"
        [onClick]="enableEditMode.bind(this)"
      />

      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === true"
        variant="secondary"
        label="Cancel"
        icon="X"
        [onClick]="cancelEditMode.bind(this)"
      />

      <aux-button
        *ngIf="(isEditModeEnabled$ | async) === true"
        variant="success"
        label="Save"
        class="ml-1"
        icon="Check"
        [disabled]="!hasChanges"
        [onClick]="onSaveAll.bind(this)"
      />
    </div>

    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-aux"
      id="patient-protocol-table"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="$any(gridData$ | async)"
      [enableFillHandle]="(isEditModeEnabled$ | async) === true"
      (gridReady)="onGridReady($event)"
      (rowDragEnd)="onRowDragEnd($event)"
      (cellValueChanged)="cellValueChanged($event)"
      (cellEditingStopped)="cellEditingStopped($event)"
    />

    <div *ngIf="isEditModeEnabled$ | async" class="mt-8">
      <button type="button" class="btn--primary" (click)="onAddSiteGroups()">Add Site Group</button>
    </div>
  </div>
</ng-template>
