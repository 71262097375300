import { FeatureFlagTabs } from '@shared/guards/basic-redirect-to-next-tab.guard';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export const FORECAST_ROUTES_FEATURE_FLAGS: FeatureFlagTabs = {
  [ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY]: 'forecast_methodology_tab',
  [ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX]: null,
  [ROUTING_PATH.FORECAST_ROUTING.CUSTOM_DRIVERS]: 'custom_drivers',
  [ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX]: 'tab_forecast_drivers',
  [ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX]: 'tab_forecast_drivers',
  [ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST]: 'tab_forecast_investigator_forecast',
  [ROUTING_PATH.FORECAST_ROUTING.STUDY_SPECIFICATIONS]: 'tab_forecast_studyspecifications',
};
