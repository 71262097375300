import { Injectable, signal } from '@angular/core';
import { Option } from '@shared/components/extendable-options-dropdown/extendable-options-dropdown.component';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { toNumber } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export interface MapActivitiesLineItemOption extends Option {
  isExistingItem?: boolean;
  initialItemValue?: number;
}

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class MapActivitiesModalService {
  invoiceItemOptions = signal<MapActivitiesLineItemOption[]>([]);

  private lineItemsOptions: MapActivitiesLineItemOption[] = [];

  initInvoiceItemOptions(invoice: InvoiceModel): void {
    const options: MapActivitiesLineItemOption[] = [];

    options.push(...this.lineItemsOptions);

    invoice.line_items?.forEach((item) => {
      if (item.description) {
        options.push({
          id: uuidv4(),
          value: item.description,
          isExistingItem: true,
          initialItemValue:
            (Object.hasOwn(item, 'amount') && item.amount) ||
            (Object.hasOwn(item, 'Amount') && item.amount) ||
            0,
        });
      }
    });

    this.invoiceItemOptions.set(options);
  }

  setLineItemOptions(
    gridData: Record<
      string,
      {
        value: number | string;
      }
    >[],
    firstPriceField: string
  ): void {
    this.lineItemsOptions =
      gridData.map((row) => {
        return {
          id: uuidv4(),
          value: row.col_1.value,
          isExistingItem: true,
          initialItemValue: toNumber(row[firstPriceField]?.value || 0),
        } as MapActivitiesLineItemOption;
      }) || [];
  }

  addInvoiceItemOptions(option: Option): void {
    this.invoiceItemOptions.update((options: Option[]) => {
      options.push(option);
      return options;
    });
  }
}
