import { Component, ViewChild } from '@angular/core';
import { ICellRenderer, ICellRendererParams } from '@ag-grid-community/core';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  ForecastTableGridParentComponentContext,
  ForecastTableGridDataInterface,
} from '../../models/forecast-table-grid.model';
import { ForecastTableGridPeriodPatientComponent } from '../forecast-table-grid-period-patient/forecast-table-grid-period-patient.component';
import { ForecastTableGridPeriodSiteComponent } from '../forecast-table-grid-period-site/forecast-table-grid-period-site.component';
import { ForecastTableGridPeriodTimeComponent } from '../forecast-table-grid-period-time/forecast-table-grid-period-time.component';
import { ForecastTableGridComponent } from '../../forecast-table-grid.component';

@Component({
  selector: 'aux-forecast-table-grid-period',
  templateUrl: './forecast-table-grid-period.component.html',
})
export class ForecastTableGridPeriodComponent
  extends AgCellWrapperComponent
  implements ICellRenderer
{
  @ViewChild(ForecastTableGridPeriodSiteComponent)
  siteComponent!: ForecastTableGridPeriodSiteComponent;

  @ViewChild(ForecastTableGridPeriodPatientComponent)
  patientComponent!: ForecastTableGridPeriodPatientComponent;

  @ViewChild(ForecastTableGridPeriodTimeComponent)
  timeComponent!: ForecastTableGridPeriodTimeComponent;

  params!: ICellRendererParams;

  context!: ForecastTableGridParentComponentContext;

  componentParent!: ForecastTableGridComponent;

  data!: ForecastTableGridDataInterface;

  value!: string;

  disablePeriod = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.componentParent = this.context.componentParent;

    if (!this.componentParent.userHasModifyPermissions) {
      this.disablePeriod = true;
    }
    this.data = this.params.data;
    this.value = this.params.value;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }
}
