import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from '@shared/components/input/input.component';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { FormErrorDirective } from '@shared/directives/form-error.directive';

@Component({
  selector: 'aux-user-example-modal',
  template: `
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <aux-modal [primaryButtonType]="'submit'">
        <div class="flex flex-col gap-4 min-w-[520px]">
          <aux-input label="Name" formControlName="name" validators="required" />
          <aux-input
            label="Birth Date"
            formControlName="birthDate"
            [type]="'date'"
            validators="required"
          />
          <div>
            <label for="role" class="block mb-1 text-xs">Role</label>
            <ng-select
              id="role"
              class="select"
              appendTo="body"
              formControlName="role"
              [items]="['admin', 'user', 'guest']"
              auxFormError
            />
          </div>
        </div>
      </aux-modal>
    </form>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    NgSelectModule,
    FormErrorDirective,
    ModalComponent,
  ],
})
export class UserExampleModalComponent {
  private readonly fb = inject(FormBuilder);

  readonly ref = inject(CustomOverlayRef);

  readonly form = this.fb.group({
    name: ['', Validators.required],
    birthDate: ['', Validators.required],
    role: [null, Validators.required],
  });

  submitForm() {
    if (this.form.valid) {
      this.ref.close();
    }
  }
}
