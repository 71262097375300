<div class="h-[200px] flex flex-col items-center justify-center">
  <div class="text-xl font-semibold mb-4">No invoice activities have been mapped</div>

  <div class="text-sm mb-4">Click Map Activities to map invoices to Budget Activities</div>

  <aux-button
    variant="primary"
    label="Map Activities"
    data-pendo-id="map-activities-button"
    [auxTooltip]="btnTooltip()"
    [disabled]="!!btnTooltip()"
    (clickEmit)="openMapActivitiesModal()"
  ></aux-button>
</div>
