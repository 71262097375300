import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  untracked,
  viewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  Activity,
  ActivitySubType,
  ActivityType,
  ApprovalType,
  AuxBudgetCategoryData,
  BudgetActivityAttributes,
  BudgetType,
  CategoryType,
  ChangeOrderStatus,
  Currency,
  EntityType,
  EventType,
  GqlService,
  PermissionType,
  TemplateType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { OverlayService } from '@shared/services/overlay.service';

import { OrganizationStore } from '@models/organization/organization.store';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Utils } from '@shared/utils/utils';
import { memo } from 'helpful-decorators';
import { ApiService } from '@shared/services/api.service';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

import { ExcelExportParams, IRowNode } from '@ag-grid-community/core';
import { EventService } from '@models/event/event.service';
import { ChangeOrderModel } from '../change-order/state/change-order.store';
import { ChangeOrderQuery } from '../change-order/state/change-order.query';
import { ChangeOrderService } from '../change-order/state/change-order.service';
import { ChangeOrderSharedService } from '../change-order/state/change-order-shared.service';
import { ChangeOrderUploadComponent } from '../change-order/change-order-upload/change-order-upload.component';
import { injectChangeOrderBudgetUploadModal } from './components/change-order-budget-upload/change-order-budget-upload-modal.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { OrganizationService } from '@models/organization/organization.service';
import { MainStore } from '@shared/store/main/main.store';
import { ChangeOrderApprovedDialogComponent } from './components/change-order-approved-dialog/change-order-approved-dialog.component';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { isEqual } from 'lodash-es';
import { AuthService } from '@shared/store/auth/auth.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { CompareBudgetService } from '@widgets/compare-budget/services/compare-budget.service';
import {
  CompareBudgetVersion,
  CompareGridData,
} from '@widgets/compare-budget/compare-budget.component';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { LfuService } from '@shared/services/lfu.service';
import { ConfirmationWithReasonModalComponent } from '../components/confirmation-with-reason.component';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { fetchInProgressEvents } from '@features/progress-tracker/utils/fetch-in-progress-events';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ProgressTrackerComponent } from '@features/progress-tracker/progress-tracker.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';
import { TablePanelComponent } from '@shared/components/table-panel/table-panel.components';
import {
  AuxButtonGroup,
  ButtonGroupComponent,
} from '@shared/components/button-group/button-group.component';

import { ChangeOrderGridComponent } from './components/compare-budget/change-order-grid.component';
import { injectOpenActivitiesModal } from '@features/inline-budget/inline-budget-modal';
import { BeActivitiesAttributesModalRowData } from '@features/inline-budget/be-activities-attributes-modal/be-activities-attributes-modal.model';
import { v4 as uuidv4 } from 'uuid';
import { BeInlineCategoryDropdownOption } from '@features/inline-budget/be-inline-category-dropdown/be-inline-category-dropdown.model';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { EventQuery } from '@models/event/event.query';

interface ActivityWithAttributes extends Omit<Activity, '__typename'> {
  attributes: Pick<BudgetActivityAttributes, 'attribute_name' | 'attribute_value'>[];
  activity_sub_type?: ActivitySubType;
  category_full_path: string;
}

const mapSelectedRowToModalData = (node: IRowNode): Partial<BeActivitiesAttributesModalRowData> => {
  const data = node.data as CompareGridData;

  if (data.to_activity_no) {
    if (data.activity_type === 'ACTIVITY_DISCOUNT') {
      const unit_cost = data.to_row.contract_unit_cost || 0;
      const unit_num = data.to_row.unit_num || 0;
      if (unit_cost > 0) {
        return {
          uom: 'Percentage',
          unit_num,
          unit_cost,
          display_label: data.to_display_label,
        };
      }

      return {
        unit_cost: data.to_total_cost,
        unit_num: 1,
        uom: data.to_uom,
        display_label: data.to_display_label,
      };
    }

    return {
      unit_cost: data.to_unit_cost,
      unit_num: data.to_unit,
      uom: data.to_uom,
      display_label: data.to_display_label,
    };
  }

  return {};
};

@Component({
  templateUrl: './change-order-detail.component.html',
  styles: [
    `
      canvas {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompareBudgetService],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    RouterLink,
    IconComponent,
    ButtonComponent,
    NgClass,
    TooltipDirective,
    ProgressTrackerComponent,
    CheckboxComponent,
    FormsModule,
    TablePanelComponent,
    ButtonGroupComponent,
    ChangeOrderGridComponent,
  ],
})
export class ChangeOrderDetailComponent {
  private readonly destroyRef = inject(DestroyRef);

  grid = viewChild(ChangeOrderGridComponent);

  activitiesModal = injectOpenActivitiesModal();

  readonly missingActivitiesWithActualsTooltip =
    'Template uploaded is missing activities with actuals. Re-upload with missing activities to approve.';

  readonly messagesConstants = MessagesConstants;

  budgetLoading$ = new BehaviorSubject(false);

  changeOrdersLink = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`;

  showApprovalReminderLink$ = this.launchDarklyService.select$(
    (flags) => flags.change_order_resend_reminder_link
  );

  analyticsCard = computed(() => {
    const grid = this.grid();
    const toBudgetVersion = this.toBudgetVersion();
    const organization = this.organization();

    if (!grid || !toBudgetVersion || !organization) {
      return {
        co_count_activities_with_increased_units: 0,
        co_count_activities_with_increased_cost: 0,
        variance_total_cost_AC: '',
      };
    }

    const budgetData = grid.budgetData();

    return untracked(() => {
      const diffs = this.compareBudgetService.getCompareDiffs(budgetData || []);
      const org_currency = organization.currency || Currency.USD;

      return {
        co_count_activities_with_increased_units: diffs.count_activities_with_increased_units,
        co_count_activities_with_increased_cost: diffs.count_activities_with_increased_cost,
        variance_total_cost_AC: Utils.currencyFormatter(
          diffs.variance_total_cost,
          {},
          org_currency
        ),
      };
    });
  });

  loading$ = combineLatest([this.changeOrderQuery.selectLoading(), this.budgetLoading$]).pipe(
    map((arr) => arr.some((x) => x))
  );

  changeOrder$ = new BehaviorSubject<ChangeOrderModel | null>(null);

  changeOrder = toSignal(this.changeOrder$, { initialValue: null });

  zeroHyphen = Utils.zeroHyphen;

  editButtonDisabledTooltip = 'Change Order must be in Pending Review to Edit';

  rowSelection = toSignal(
    this.changeOrder$.pipe(
      map((co) => {
        return {
          disabled: co?.change_order_status !== 'STATUS_PENDING_REVIEW',
          disabledTooltip: this.editButtonDisabledTooltip,
        };
      })
    ),
    {
      initialValue: {
        disabled: false,
        disabledTooltip: '',
      },
    }
  );

  organization$ = this.changeOrder$.pipe(
    map((co) => co?.organization_id),
    filter((x) => !!x),
    distinctUntilChanged(),
    switchMap((id) => this.organizationQuery.selectEntity(id)),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );

  organization = toSignal(this.organization$, { initialValue: null });

  primaryBudgetId$ = combineLatest([this.changeOrder$, this.organization$]).pipe(
    map(([co, org]) => {
      return (
        co?.approved_budget_version?.budget_version_id ||
        this.organizationQuery.getPrimaryBudgetVersion(org?.id || '')?.budget_version_id
      );
    })
  );

  fetchChangeOrderBudget$ = new Subject<boolean>();

  changeOrderBudgetId$ = new BehaviorSubject<string | null>(null);

  status$ = this.changeOrder$.pipe(
    map((changeOrder) => {
      let status = '';
      if (changeOrder) {
        switch (changeOrder.change_order_status) {
          case ChangeOrderStatus.STATUS_PENDING_REVIEW:
            status = 'Pending Review';
            break;
          case ChangeOrderStatus.STATUS_PENDING_APPROVAL:
            status = 'Pending Approval';
            break;
          case ChangeOrderStatus.STATUS_APPROVED:
            status = 'Approved';
            break;
          case ChangeOrderStatus.STATUS_DECLINED:
            status = 'Declined';
            break;
          default:
            break;
        }
      }
      return status;
    })
  );

  approvedBy$ = this.changeOrder$.pipe(
    map((co) => {
      if (
        !co ||
        (co.change_order_status !== ChangeOrderStatus.STATUS_DECLINED &&
          co.change_order_status !== ChangeOrderStatus.STATUS_APPROVED)
      ) {
        return '';
      }

      const approval = co.approvals?.[0];

      const userFormat =
        this.changeOrderSharedService.userFormatter(approval?.aux_user_id || '') || '';
      const time = Utils.dateFormatter(approval?.approval_time || '');
      return `by ${userFormat} on ${time}`;
    })
  );

  isPendingReview$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_REVIEW);

  isPendingApproval$ = this.statusCheck(ChangeOrderStatus.STATUS_PENDING_APPROVAL);

  isApproved$ = this.statusCheck(ChangeOrderStatus.STATUS_APPROVED);

  isDeclined$ = this.statusCheck(ChangeOrderStatus.STATUS_DECLINED);

  changeOrderBudgetTemplateUploadProgressId = fetchInProgressEvents(
    EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
    this.destroyRef
  );

  btnLoading$ = new BehaviorSubject<
    | 'approval'
    | 'delete'
    | 'download'
    | 'approve'
    | 'decline'
    | 'admin-review'
    | 'upload'
    | 'replace'
    | 'lre-download'
    | false
  >(false);

  fromBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  fromBudgetVersion = toSignal(this.fromBudgetVersion$, { initialValue: null });

  toBudgetVersion$ = new BehaviorSubject<CompareBudgetVersion | null>(null);

  toBudgetVersion = toSignal(this.toBudgetVersion$, { initialValue: null });

  coId$ = new BehaviorSubject('');

  hasMissingActivitiesWithActuals = false;

  userHasApprovePermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_APPROVE_CHANGE_ORDER],
  });

  hasUploadCOPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_CHANGE_ORDER_UPLOAD_BUDGET],
  });

  hasUploadCOPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_CHANGE_ORDER_UPLOAD_BUDGET],
  });

  hasUpdateChangeOrderPermissions$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_CHANGE_ORDER],
  });

  doNotHavePermissionMessage = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  editButtonsDisabled = computed(() => {
    const grid = this.grid();
    const inlineProcessing = this.inlineEditProcessing();

    if (inlineProcessing || !grid) {
      return true;
    }

    return grid.selectedRowCount() === 0;
  });

  editOptions = computed<AuxButtonGroup[]>(() => {
    const editButtonsDisabled = this.editButtonsDisabled();
    const grid = this.grid();
    return untracked(() => {
      const { openModal, mapSelectedRowsToModalData } = this.activitiesModal;
      return [
        {
          iconName: 'Plus',
          name: 'Add New Activities',
          onClick: () => {
            const ref = openModal({
              rows: [],
              budgetData: grid?.budgetData() || [],
              editMode: false,
              showNotesSection: false,
            });
            if (ref) {
              this.onModalClose(ref);
            }
          },
        },
        {
          iconName: 'Edit',
          name: 'Edit Activities',
          onClick: () => {
            const ref = openModal({
              rows: mapSelectedRowsToModalData(grid!.gridAPI()!, mapSelectedRowToModalData),
              budgetData: grid?.budgetData() || [],
              showNotesSection: false,
            });
            if (ref) {
              this.onModalClose(ref);
            }
          },
          disabled: editButtonsDisabled,
          disabledTooltip: 'Select at least 1 activity to enable this option',
        },
        {
          iconName: 'Hierarchy3',
          name: 'Edit Attributes',
          onClick: () => {
            const ref = openModal({
              rows: mapSelectedRowsToModalData(grid!.gridAPI()!, mapSelectedRowToModalData),
              budgetData: grid?.budgetData() || [],
              isActivityTabVisible: false,
              showNotesSection: false,
            });
            if (ref) {
              this.onModalClose(ref);
            }
          },
          disabled: editButtonsDisabled,
          disabledTooltip: 'Select at least 1 activity to enable this option',
        },
      ];
    });
  });

  kebabOptions = computed<AuxButtonGroup[]>(() => {
    const organization = this.organization();
    const changeOrder = this.changeOrder();
    const grid = this.grid();
    const fromBudgetVersion = this.fromBudgetVersion();
    const trial = this.mainQuery.getSelectedTrial();

    if (!organization || !changeOrder || !trial) {
      return [];
    }

    const isPendingReview =
      changeOrder.change_order_status === ChangeOrderStatus.STATUS_PENDING_REVIEW;

    return [
      {
        iconName: 'FileExport',
        name: 'Export',
        iconClass: 'text-aux-blue-dark',
        onClick: () => {
          grid?.onExport(
            `ChangeOrder-${changeOrder.change_order_no}-${organization.name}-${trial.name}.xlsx`
          );
        },
        disabled: !fromBudgetVersion,
      },
      {
        iconName: 'Download',
        name: 'Download Change Order Supporting Documents',
        iconClass: 'text-aux-blue-dark',
        onClick: () => {
          this.onDownloadCO();
        },
      },
      {
        iconName: 'Download',
        name: 'Download Current Budget',
        iconClass: 'text-aux-blue-dark',
        onClick: () => {
          this.onDownloadLREBudget();
        },
      },
      {
        iconName: 'Upload',
        name: 'Upload Change Order Template',
        iconClass: 'text-aux-blue-dark',
        onClick: () => {
          this.onReplaceBudget();
        },
        disabled: !isPendingReview || !this.hasUploadCOPermission(),
        disabledTooltip: !isPendingReview
          ? 'Change Order must be in Pending Review to Upload'
          : this.doNotHavePermissionMessage,
      },
    ];
  });

  buttonGroupOverlayPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  onModalClose = async (ref: ReturnType<typeof this.activitiesModal.openModal>) => {
    if (!ref) {
      return;
    }

    const closeEvent = await firstValueFrom(ref.afterClosed$);
    const vendor_id = this.activitiesModal.vendor_id();
    if (closeEvent.data) {
      switch (closeEvent.data.type) {
        case 'close':
          break;
        case 'save': {
          const transaction_id = uuidv4();
          const budgetUpdated = await this.updateActivities(
            vendor_id,
            closeEvent.data.rows,
            closeEvent.data.isCategoryListChanged,
            closeEvent.data.temporaryCategories
          );

          if (closeEvent.data.deletedColumns.length || closeEvent.data.renamedColumns.length) {
            await this.updateColumns({
              vendor_id,
              deletedColumns: closeEvent.data.deletedColumns,
              renamedColumns: closeEvent.data.renamedColumns,
              transaction_id,
            });
          }

          if (!budgetUpdated) {
            const tracking_id = uuidv4();
            if (
              await this.eventService.triggerEvent({
                type: EventType.INLINE_EDIT_CHANGE_ORDER,
                entity_type: EntityType.ORGANIZATION,
                entity_id: vendor_id,
                tracking_id,
                transaction_id,
                payload: JSON.stringify({
                  createActivities: [],
                  updateActivities: [],
                  deleteActivities: [],
                  change_order_id: this.changeOrder()!.id,
                }),
              })
            ) {
              this.eventTrackerService.trackEvent(tracking_id);
              this.overlayService.success('Budget update started');
            }
          }
          break;
        }
      }
    }
  };

  // todo(AUXI-6706) this has almost same logic in budget too. Figure out if we should put them in modal injector
  async updateActivities(
    entity_id: string,
    rows: BeActivitiesAttributesModalRowData[],
    isCategoryListChanged: boolean,
    temporaryCategories: BeInlineCategoryDropdownOption[]
  ) {
    const trialId = this.mainQuery.getValue().trialKey;
    const trialVendorPath = `>${trialId}>${entity_id}`;
    const createdActivities: ActivityWithAttributes[] = [];
    const updatedActivities: ActivityWithAttributes[] = [];
    const deletedActivities: ActivityWithAttributes[] = [];

    rows.forEach((row) => {
      let activity_type: ActivityType;
      let activity_sub_type: ActivitySubType | undefined;
      let category_full_path: string;
      if (isCategoryListChanged) {
        category_full_path =
          temporaryCategories.find((category) => category.id === row.category)?.fullPath || '';
      } else {
        category_full_path =
          this.activitiesModal.categories()?.find((category) => category.id === row.category)
            ?.fullPath || '';
      }

      switch (row.activity_type) {
        default:
          activity_type = ActivityType.ACTIVITY_SERVICE;
          break;
        case 'ACTIVITY_SERVICE':
        case 'ACTIVITY_INVESTIGATOR':
        case 'ACTIVITY_PASSTHROUGH':
        case 'ACTIVITY_DISCOUNT':
          activity_type = row.activity_type as ActivityType;
          break;
        case 'ACTIVITY_INVESTIGATOR_PATIENT_VISITS':
        case 'ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES':
        case 'ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES':
          activity_type = ActivityType.ACTIVITY_INVESTIGATOR;
          activity_sub_type = row.activity_type as ActivitySubType;
          break;
      }
      const newData = {
        id: row.id,
        name: row.activity_name || '',
        display_label: row.display_label || '',
        unit_cost: <number>row.unit_cost || 0,
        unit_num: <number>row.unit_num || 0,
        uom: row.uom || '',
        category_id: row.category,
        activity_type,
        activity_sub_type,
        attributes: Object.entries(row.attributes).map(([key, value]) => {
          return {
            attribute_name: key.startsWith('custom_attr_')
              ? decodeURIComponent(atob(key.split('custom_attr_')[1]))
              : key,
            attribute_value: value,
          };
        }),
        category_full_path: `${trialVendorPath}${category_full_path}`,
      };

      if (row.deleted) {
        deletedActivities.push(newData);
        return;
      }

      if (!row.changed || !row.activity_name) {
        return;
      }
      if (row.isGenerated) {
        createdActivities.push({ ...newData, id: '' });
      } else {
        updatedActivities.push(newData);
      }
    });

    localStorage.setItem('anyEditActivities', 'true');

    if (
      createdActivities.length ||
      updatedActivities.length ||
      deletedActivities.length ||
      isCategoryListChanged
    ) {
      const categories: { [key: string]: AuxBudgetCategoryData[] } = {};
      if (isCategoryListChanged) {
        const filteredCategories: BeInlineCategoryDropdownOption[] = temporaryCategories
          .map((category, index) => ({
            ...category,
            sourceIndex: index + 2,
          }))
          .filter((category) => {
            return (
              category.categoryType !== CategoryType.CATEGORY_INVESTIGATOR &&
              category.categoryType !== CategoryType.CATEGORY_DISCOUNT &&
              category.fullPath !== ''
            );
          });

        filteredCategories.forEach((category) => {
          categories[`/${category.categoryType}/${trialVendorPath}${category.fullPath}`] = [
            {
              category_type: category.categoryType || CategoryType.CATEGORY_SERVICE,
              name: category.name,
              full_path: `${trialVendorPath}${category.fullPath}`,
              previous_full_path: category.isRenamed
                ? this.getCategoryPreviousFullPath(category, trialVendorPath)
                : '', // only populate if the name of the category was updated
              parent_path: `${trialVendorPath}${category.path}`,
              trial_id: trialId,
              vendor_id: entity_id || '',
              display_order: 0,
              source_index: category.sourceIndex || 0,
              //fields with default values
              item_label: '0',
              display_label: '',
              item_order: 0,
              item_count: 0,
              direct_expenses_note: '',
              attributes: '[{}]',
              categories: '[{}]',
              __typename: 'AuxBudgetCategoryData',
            },
          ];
        });
      }
      const tracking_id = uuidv4();
      if (
        await this.eventService.triggerEvent({
          type: EventType.INLINE_EDIT_CHANGE_ORDER,
          entity_type: EntityType.ORGANIZATION,
          entity_id,
          tracking_id,
          payload: JSON.stringify({
            change_order_id: this.changeOrder()!.id,
            categories,
            createActivities: createdActivities,
            updateActivities: updatedActivities,
            deleteActivities: deletedActivities,
          }),
        })
      ) {
        this.eventTrackerService.trackEvent(tracking_id);
        this.overlayService.success('Budget update started');
      }

      return true;
    }

    return false;
  }

  async updateColumns({
    deletedColumns,
    renamedColumns,
    vendor_id,
    transaction_id,
  }: {
    deletedColumns: string[];
    renamedColumns: { oldValue: string; newValue: string }[];
    vendor_id: string;
    transaction_id: string;
  }) {
    const proms: Promise<unknown>[] = [];
    if (deletedColumns.length) {
      deletedColumns.forEach((name) => {
        proms.push(
          firstValueFrom(
            this.gqlService.removeBudgetAttribute$({
              vendor_id,
              name,
              budget_type: BudgetType.BUDGET_CHANGE_ORDER,
              change_order_id: this.changeOrder$.getValue()?.id,
              transaction_id,
            })
          )
        );
      });
    }

    if (renamedColumns.length) {
      renamedColumns.forEach(({ oldValue, newValue }) => {
        proms.push(
          firstValueFrom(
            this.gqlService.renameBudgetAttribute$({
              vendor_id,
              name: oldValue,
              new_name: newValue,
              budget_type: BudgetType.BUDGET_CHANGE_ORDER,
              change_order_id: this.changeOrder$.getValue()?.id,
              transaction_id,
            })
          )
        );
      });
    }

    if (proms.length) {
      await Promise.allSettled(proms);
    }
  }

  getCategoryPreviousFullPath(
    category: BeInlineCategoryDropdownOption,
    trialVendorPath: string
  ): string {
    const originalCategory = this.activitiesModal
      .categories()
      ?.find((origCategory) => origCategory.id === category.id);

    return originalCategory ? `${trialVendorPath}${originalCategory.fullPath}` : '';
  }

  changeOrderBudgetUploadModal = injectChangeOrderBudgetUploadModal();

  inlineEditProcessing = this.eventQuery.selectProcessingEvent(EventType.INLINE_EDIT_CHANGE_ORDER);

  constructor(
    private changeOrderQuery: ChangeOrderQuery,
    private changeOrderService: ChangeOrderService,
    private router: Router,
    private route: ActivatedRoute,
    public authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private lfuService: LfuService,
    private changeOrderSharedService: ChangeOrderSharedService,
    private apiService: ApiService,
    private eventService: EventService,
    private launchDarklyService: LaunchDarklyService,
    private mainStore: MainStore,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private compareBudgetService: CompareBudgetService,
    private eventTrackerService: EventTrackerService,
    private eventQuery: EventQuery
  ) {
    this.route.paramMap
      .pipe(
        map((params) => params.get('id')),
        switchMap((id) => {
          // trying to catch an issue where the query param ?trial= is included in the param 'id' value on very rare occassions
          let verifiedId = id;
          this.coId$.next(verifiedId || '');
          if (verifiedId?.includes('?')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('?'));
          } else if (verifiedId?.includes('%')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('%'));
          }

          return this.changeOrderService.getOne(verifiedId || '').pipe(
            switchMap(({ success, data }) => {
              if (success && data) {
                return this.changeOrderQuery.selectEntity(verifiedId);
              }
              return of(null);
            })
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe((changeOrder) => {
        this.organizationStore.setActive(changeOrder?.organization_id || '');

        if (!changeOrder) {
          this.router.navigateByUrl(
            `${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`
          );
          return;
        }

        this.changeOrder$.next(changeOrder || null);
      });

    combineLatest([this.changeOrderBudgetId$, this.changeOrder$])
      .pipe(
        map(([id, co]) => {
          if (!(!!id && !!co)) {
            return null;
          }

          return {
            budget_version_id: id,
            budget_name: `Change Order ${co?.change_order_no}`,
            budget_type: BudgetType.BUDGET_CHANGE_ORDER,
          } as CompareBudgetVersion;
        }),
        startWith({} as CompareBudgetVersion),
        pairwise(),
        filter(([prev, next]) => !isEqual(prev, next)),
        takeUntilDestroyed()
      )
      .subscribe(([, data]) => {
        this.toBudgetVersion$.next(data);

        if (data) {
          this.listMissingChangeOrderActivities(data.budget_version_id);
        }
      });

    combineLatest([this.changeOrder$, this.organization$])
      .pipe(
        map(([co]) => {
          return this.organizationQuery.getBudgetVersion(
            co?.organization_id || '',
            BudgetType.BUDGET_CHANGE_ORDER,
            co?.id || '',
            EntityType.CHANGE_ORDER
          )?.budget_version_id;
        }),
        takeUntilDestroyed()
      )
      .subscribe((data) => {
        this.changeOrderBudgetId$.next(data as string);
      });

    this.primaryBudgetId$
      .pipe(
        filter((id) => !!id),
        map((id) => {
          return {
            budget_version_id: id,
            budget_name: 'Current Budget',
            budget_type: BudgetType.BUDGET_PRIMARY,
          } as CompareBudgetVersion;
        }),
        takeUntilDestroyed()
      )
      .subscribe((data) => {
        this.fromBudgetVersion$.next(data);
      });

    combineLatest([
      this.fetchChangeOrderBudget$.pipe(startWith(true)),
      toObservable(this.inlineEditProcessing).pipe(
        startWith(false),
        filter((s) => this.changeOrderBudgetId$.getValue() == null && s === false)
      ),
    ])
      .pipe(
        switchMap(() => this.organizationService.getListWithTotalBudgetAmount()),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      const isProcessing = this.eventQuery.selectProcessingEvent(
        EventType.INLINE_EDIT_CHANGE_ORDER
      )();

      const isBudgetUploadProcessing = this.eventQuery.selectProcessingEvent(
        EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED
      )();

      if (isProcessing === false || isBudgetUploadProcessing === false) {
        this.activitiesModal.refreshCategories();
      }
    });

    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();

    combineLatest([this.organization$, this.changeOrder$, this.toBudgetVersion$])
      .pipe(debounceTime(500), takeUntilDestroyed())
      .subscribe(([org, co, v]) => {
        if (org && co) {
          this.activitiesModal.vendor_id.set(org.id);
          this.activitiesModal.change_order_id.set(v ? co.id : undefined);
        }
      });
  }

  isApproveButtonDisableManually$ = new BehaviorSubject(false);

  isApproveButtonDisable$ = this.toBudgetVersion$.pipe(map((toBudgetVersion) => !toBudgetVersion));

  isUploadCOBudgetButtonDisable$ = this.fromBudgetVersion$.pipe(
    map((fromBudgetVersion) => !fromBudgetVersion)
  );

  pathFn: () => string = () => '';

  @memo()
  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  statusCheck(status: ChangeOrderStatus) {
    return this.changeOrder$.pipe(map((x) => x?.change_order_status === status));
  }

  listMissingChangeOrderActivities(versionId: string): void {
    this.gqlService
      .listMissingChangeOrderActivities$(versionId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        this.hasMissingActivitiesWithActuals = !!(result && result.data && result.data?.length > 0);
      });
  }

  async onDelete() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('delete');

    const resp = this.overlayService.openPopup<
      { message: string },
      { reason: string } | null,
      ConfirmationWithReasonModalComponent
    >({
      modal: ConfirmationWithReasonModalComponent,
      data: {
        message: `Are you sure you want to remove Change Order ${co.change_order_no}?`,
      },
      settings: {
        header: 'Remove Change Order',
        primaryButton: {
          label: 'Remove',
        },
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.reason) {
      const { success } = await this.changeOrderService.remove(co, event.data.reason);

      if (success) {
        this.router.navigateByUrl(this.changeOrdersLink);
      }
    }
    this.btnLoading$.next(false);
  }

  async onDownloadCO() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('download');
    const { success, data, errors } = await this.changeOrderQuery.downloadCO(co.id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onReturnToAdminReview() {
    const co = this.changeOrder$.getValue()!;

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('admin-review');

    const resp = this.overlayService.openPopup<
      { message: string; textAreaLabel: string },
      { reason: string } | null,
      ConfirmationWithReasonModalComponent
    >({
      modal: ConfirmationWithReasonModalComponent,
      data: {
        message: `Are you sure you want to return Change Order ${co.change_order_no} to Pending Review?`,
        textAreaLabel: 'Reason for return to Admin Review',
      },
      settings: {
        header: 'Return to Pending Review?',
        primaryButton: {
          label: 'Return to Admin Review',
        },
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.reason) {
      const { success, data, errors } = await this.changeOrderService.update({
        id: co.id,
        organization_id: co.organization_id,
        change_order_status: ChangeOrderStatus.STATUS_PENDING_REVIEW,
        admin_review_reason: event.data.reason,
      });

      if (success && data) {
        this.overlayService.success();

        await firstValueFrom(
          this.eventService.processEvent$({
            type: EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW,
            entity_type: EntityType.CHANGE_ORDER,
            entity_id: co.id,
          })
        );
      } else {
        this.overlayService.error(errors);
      }
    }

    this.btnLoading$.next(false);
  }

  async onDownloadLREBudget() {
    if (this.btnLoading$.getValue()) {
      return;
    }
    const vendor_id = this.changeOrder$.getValue()?.organization_id;
    if (!vendor_id) {
      return;
    }

    this.btnLoading$.next('lre-download');

    const { success, errors, data } = await this.lfuService.getTemplatePath({
      template_type: TemplateType.BUDGET_TEMPLATE,
      vendor_id,
    });
    if (!success) {
      this.overlayService.error(errors);
    }
    if (!(success && data)) {
      this.overlayService.error('There was a problem downloading the template');
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }

    this.btnLoading$.next(false);
  }

  getDynamicExcelParams(): ExcelExportParams {
    const org = this.organizationQuery.getEntity(this.changeOrder$.value?.organization_id);

    const currentTrial = this.mainQuery.getSelectedTrial();

    const changeOrderNo = this.changeOrder$.value?.change_order_no;

    const coPendingApprovalDate = this.changeOrder$.value?.create_date?.slice(0, 10);

    return currentTrial && org
      ? {
          fileName: `${currentTrial.short_name}_${org.name}_Change Order ${changeOrderNo}_Compare_${coPendingApprovalDate}.xlsx`,
        }
      : {};
  }

  async onSendApproval() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approval');

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_PENDING_APPROVAL,
    });

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }

    this.mainStore.setProcessingLoadingState(EventType.CHANGE_ORDER_PENDING_APPROVAL, true);

    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.CHANGE_ORDER_PENDING_APPROVAL,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    this.btnLoading$.next(false);
  }

  async onDecline() {
    const co = this.changeOrder$.getValue()!;
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('decline');

    const resp = this.overlayService.openPopup<
      { message: string },
      { reason: string } | null,
      ConfirmationWithReasonModalComponent
    >({
      modal: ConfirmationWithReasonModalComponent,
      data: {
        message: `Are you sure you want to decline Change Order ${co.change_order_no}?`,
      },
      settings: {
        header: 'Decline Change Order?',
        primaryButton: {
          label: 'Decline',
        },
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.reason) {
      this.btnLoading$.next(false);
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.update({
      id: co.id,
      organization_id: co.organization_id,
      change_order_status: ChangeOrderStatus.STATUS_DECLINED,
      decline_reason: event.data.reason,
    });

    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.CHANGE_ORDER_DECLINED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    if (!(success && data)) {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  async onApprove(force = false) {
    const co = this.changeOrder$.getValue()!;
    const org = await firstValueFrom(this.organization$.pipe(first()));
    if (!org) {
      return;
    }

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('approve');

    let resp!: CustomOverlayRef<
      boolean | undefined,
      { message: string },
      ConfirmationModalComponent
    >;

    if (force) {
      resp = this.overlayService.openPopup<
        { message: string; maxWidth: number },
        boolean | undefined,
        ConfirmationModalComponent
      >({
        content: ConfirmationModalComponent,
        data: {
          message: `Selecting Force Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}. Do you want to proceed?`,
          maxWidth: 460,
        },
        settings: {
          header: `Force Approve Change Order?`,
          primaryButton: {
            label: 'Force Approve',
          },
        },
      });
    } else {
      resp = this.overlayService.openPopup<
        { message: string; maxWidth: number },
        boolean | undefined,
        ConfirmationModalComponent
      >({
        content: ConfirmationModalComponent,
        data: {
          message: `Selecting Approve will replace the current ${org.name} budget with Change Order ${co.change_order_no}. Do you want to proceed?`,
          maxWidth: 460,
        },
        settings: {
          header: `Approve Change Order?`,
          primaryButton: {
            label: 'Approve',
          },
        },
      });
    }

    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data) {
      this.btnLoading$.next(false);
      return;
    }

    this.isApproveButtonDisableManually$.next(true);

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_CHANGE_ORDER',
        approval_type: ApprovalType.APPROVAL_CHANGE_ORDER,
        entity_id: co.id,
        entity_type: EntityType.CHANGE_ORDER,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next(false);
      return;
    }

    const { success, data, errors } = await this.changeOrderService.approveChangeOrder({
      id: co.id,
      organization_id: co.organization_id,
    });

    this.mainStore.setProcessingLoadingState(EventType.CHANGE_ORDER_APPROVED, true);

    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.CHANGE_ORDER_APPROVED,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
        payload: JSON.stringify({
          sendChangeOrderApprovedEmail: !force,
        }),
      })
    );

    if (!(success && data)) {
      this.overlayService.error(errors);
    } else {
      if (this.launchDarklyService.flags$.getValue().change_order_success_modal) {
        this.overlayService.openPopup({
          content: ChangeOrderApprovedDialogComponent,
          settings: {
            header: '',
            hideHeader: true,
            primaryButton: { label: 'OK' },
            secondaryButton: { hidden: true },
          },
          data: { vendor_id: co.organization_id },
        });
      }
    }

    this.btnLoading$.next(false);
  }

  async onSendingPendingApprovalReminder() {
    const co = this.changeOrder$.getValue()!;

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.CHANGE_ORDER_PENDING_APPROVAL,
        entity_type: EntityType.CHANGE_ORDER,
        entity_id: co.id,
      })
    );

    if (!success) {
      this.overlayService.error(errors);
    } else {
      this.overlayService.success(['Reminder email sent']);
    }
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  async onReplaceBudget() {
    if (this.btnLoading$.getValue()) {
      return;
    }
    const org = await firstValueFrom(this.organization$.pipe(first()));
    if (!org) {
      return;
    }
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    this.btnLoading$.next('replace');

    const modalResult = this.changeOrderBudgetUploadModal.open({
      change_order_id: co.id,
      change_order_status: co.change_order_status!,
      organization_id: org.id,
      onSuccess: (processEventId) => {
        this.changeOrderBudgetTemplateUploadProgressId.set(processEventId);
      },
    });

    await firstValueFrom(modalResult.afterClosed$);
    this.btnLoading$.next(false);
  }

  async onEditChangeOrder() {
    const co = this.changeOrder$.getValue();
    if (!co) {
      return;
    }
    const result = this.overlayService.openPopup({
      modal: ChangeOrderUploadComponent,
      settings: {
        header: 'Edit Change Order',
      },
      data: { id: co.id },
    });
    await firstValueFrom(result.afterClosed$);
  }

  acPercentFormat(v: number) {
    return Utils.agPercentageFormatterAbsolute(
      {
        value: v,
      },
      { maximumFractionDigits: 0, minimumFractionDigits: 0 }
    );
  }

  onTrackerIdChanged(trackerId: string) {
    this.changeOrderBudgetTemplateUploadProgressId.set(trackerId);

    if (!trackerId) {
      this.fetchChangeOrderBudget$.next(true);
    }
  }

  approveBtnTooltip(
    userHasApprovePermission: boolean,
    isChangeOrdersWorkflowLocked: boolean,
    isApproveDisabled: boolean
  ): string {
    if (this.hasMissingActivitiesWithActuals) {
      return this.missingActivitiesWithActualsTooltip;
    }

    if (isApproveDisabled) {
      return 'Change Order Budget must be entered to Approve';
    }

    if (!userHasApprovePermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }

  deleteBtnTooltip(isChangeOrdersWorkflowLocked: boolean): string {
    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }
}
