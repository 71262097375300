<div *ngIf="isActivitiesTabVisible">
  <div class="flex items-center justify-between mb-4 be-attributes-container">
    <div>
      <ng-select
        [(ngModel)]="activitiesTabComponent.selectedVendor"
        bindLabel="label"
        bindValue="id"
        [disabled]="true"
        [items]="activitiesTabComponent.vendors"
      />
    </div>
    <div>
      <aux-button
        variant="tertiary"
        (clickEmit)="activitiesTabComponent.addNewRow()"
        icon="Plus"
        label="Add New Activity"
      />
    </div>
  </div>
</div>

<div *ngIf="!isActivitiesTabVisible">
  <div class="flex items-center justify-between be-attributes-container mb-4">
    <div>
      <ng-select
        [(ngModel)]="attributesTabComponent.selectedVendor"
        bindLabel="label"
        bindValue="id"
        [disabled]="true"
        [items]="attributesTabComponent.vendors"
      />
    </div>
    <div>
      <aux-button
        variant="secondary"
        (clickEmit)="attributesTabComponent.addNewColumn()"
        icon="Plus"
        label="Add New Attribute"
        classList="!border-none h-[35px]"
        [disabled]="attributesTabComponent.addNewColumnDisabled"
      />
    </div>
  </div>
</div>

<div
  class="w-[1220px] be-attributes-container relative"
  [ngClass]="{
    'max-h-[524px]': showNotesSection,
    'h-[524px]': showNotesSection,
    'overflow-y-auto': showNotesSection
  }"
>
  <aux-activities-tab
    #activitiesTabComponent
    class="block"
    [ngClass]="{
      'invisible h-0 overflow-hidden': !isActivitiesTabVisible
    }"
    [gridData$]="gridData$"
    [data]="ref.data"
    [hasExternalChanges]="attributesTabComponent.isAttributesTabHasChanges()"
    (toggleTabs)="toggleToAttributesTab($event)"
    (save)="saveFromActivitiesTab($event)"
    (closeTab)="close()"
    [inAppDocumentState]="inAppDocumentState()"
    [showNotesSection]="showNotesSection"
  />
  <aux-attributes-tab
    #attributesTabComponent
    class="block"
    [ngClass]="{
      'invisible h-0 overflow-hidden': isActivitiesTabVisible
    }"
    [gridData$]="gridData$"
    [data]="ref.data"
    [categories]="categories"
    [hasExternalChanges]="activitiesTabComponent.isActivitiesTabHasChanges()"
    (toggleTabs)="toggleToActivitiesTab($event)"
    (save)="saveFromAttributesTab($event)"
    (closeTab)="close()"
    [inAppDocumentState]="inAppDocumentState()"
    [showNotesSection]="showNotesSection"
  />
  @if (showNotesSection) {
    <div class="w-[1200px]">
      <ng-container *ngTemplateOutlet="InAppNotesAndDocuments"></ng-container>
    </div>
  }
</div>

<ng-template #InAppNotesAndDocuments>
  <div class="grid grid-cols-3 w-[1200px] h-60">
    <div class="flex flex-col items-start justify-start space-y-3 col-span-2 mr-8">
      <div class="flex items-center justify-start space-x-1">
        <aux-icon [name]="'Message'" />
        <span class="font-bold">
          <span class="mr-1">Notes</span>
          <span class="text-aux-error">*</span>
        </span>
      </div>
      <aux-input
        id="notes"
        class="text-xs w-full pl-1"
        placeholder="Enter notes here..."
        validators="required"
        [inAppBudgetStyle]="true"
        [textArea]="true"
        resize="none"
        [errMessage]="'Notes*'"
        [(ngModel)]="notes"
        (ngModelChange)="noteChange(notes)"
      />
    </div>
    <div class="flex flex-col space-y-3 w-full col-span-1">
      <div class="flex flex-row justify-start items-center space-x-2">
        <span class="font-bold">Supporting Documents</span>
        <span class="italic"> (optional)</span>
      </div>
      <div class="w-full">
        <aux-file-manager
          #manager
          class="h-32"
          [fetchFilesOnInit]="false"
          [eager]="false"
          [pathFn]="getFilePath()"
          [metadataFn]="getMetadata()"
        />
        <div class="mt-4 max-h-[42px] overflow-y-auto">
          <aux-file-viewer
            [fileManager]="manager"
            [disableFirstFileMargin]="true"
            [onlyShowUploaded]="false"
            [type]="'inAppBudget'"
          />
        </div>
      </div>
    </div>
  </div>
</ng-template>
