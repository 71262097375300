import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TrialInsightsStore } from '@models/trial-insights/trial-insights.store';
import { MainQuery } from '@shared/store/main/main.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './trial-insights-finance.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  trialLoading = false;

  componentsLoading = false;

  showInvestigatorSpendCard$: Observable<boolean>;

  constructor(
    private trialInsightsStore: TrialInsightsStore,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.showInvestigatorSpendCard$ = this.launchDarklyService.select$(
      (flags) => flags.finance_dash_remaining_investigator_spend
    );
  }

  ngOnInit(): void {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((currentOpenMonth) => {
          if (!currentOpenMonth) {
            return;
          }

          this.trialInsightsStore.update((state) => ({
            ...state,
            currentOpenMonth,
          }));
        })
      )
      .subscribe();
  }
}
