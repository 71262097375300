import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ActivityType } from '@shared/services/gql.service';
import { BehaviorSubject } from 'rxjs';
import { MessagesConstants } from '@shared/constants/messages.constants';

interface AgAdjustmentDiscountTooltipParams extends ICellRendererParams {
  isInEditMode: BehaviorSubject<boolean>;
  hasPermission: BehaviorSubject<boolean>;
}

@Component({
  selector: 'aux-ag-adjustment-discount-tooltip-column',
  template: `<div
      *ngIf="params.isInEditMode?.getValue()"
      class="absolute w-full h-full bg-transparent"
      auxTooltip
      [template]="
        (params.hasPermission | async) !== true
          ? doNotHavePermissionTmp
          : activityTypeIsDiscount
            ? discountTooltipTemplate
            : undefined
      "
    ></div>
    <ng-template #discountTooltipTemplate>
      <div class="space-y-1">{{ discountTooltipMsg }}</div>
    </ng-template>
    <ng-template #doNotHavePermissionTmp>
      <div class="space-y-1">{{ doNotHavePermissionMsg }}</div>
    </ng-template>
    <div>
      <span>{{ params.valueFormatted }}</span>
    </div>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentDiscountTooltipComponent implements ICellRendererAngularComp {
  params!: AgAdjustmentDiscountTooltipParams;

  doNotHavePermissionMsg = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  discountTooltipMsg = 'Discount can be adjusted in the Closing Checklist';

  activityTypeIsDiscount = false;

  refresh(): boolean {
    return false;
  }

  agInit(params: AgAdjustmentDiscountTooltipParams) {
    this.params = params;
    this.activityTypeIsDiscount =
      this.params?.data?.activity_type === ActivityType.ACTIVITY_DISCOUNT;
  }
}
