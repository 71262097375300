import { Component, inject } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  template: `<p class="min-w-[280px]" [style.maxWidth]="maxWidth">
    {{ ref.data.message }}
  </p>`,
  standalone: true,
})
export class ConfirmationModalComponent {
  readonly ref = inject(CustomOverlayRef);

  get maxWidth(): string {
    return this.ref.data.maxWidth ? `${this.ref.data.maxWidth}px` : '520px';
  }
}
