import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components/icon/icon.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';

import { WorkflowPanelGeneralItemComponent } from './workflow-panel-general-item/workflow-panel-general-item.component';

@Component({
  selector: 'aux-workflow-panel',
  templateUrl: './workflow-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe, IconComponent, RouterLink, WorkflowPanelGeneralItemComponent, NgIf],
})
export class WorkflowPanelComponent {
  workflowService = inject(WorkflowService);
  workflowQuery = inject(WorkflowQuery);
  authQuery = inject(AuthQuery);

  workflowName = input.required<string>();
  className = input<string>('');
  processing = input<boolean | null>(false);
  hasUserPermissions = input<boolean>(false);

  quarterCloseChecklistLink = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`;

  isLoading = toSignal(this.workflowQuery.selectLoading(), { requireSync: true });

  locked = computed(() => {
    const workflow = this.workflowQuery.getWorkflowByStepType(this.workflowName());

    return workflow()?.properties?.locked || false;
  });

  constructor() {
    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed()).subscribe();
  }
}
