import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  signal,
  untracked,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, filter, startWith, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  RowSelectedEvent,
} from '@ag-grid-community/core';
import { OrganizationModel } from '@models/organization/organization.store';
import { Currency, EventType } from '@shared/services/gql.service';
import {
  CompareBudgetVersion,
  CompareGridData,
} from '@widgets/compare-budget/compare-budget.component';
import { TableConstants } from '@shared/constants/table.constants';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { MainQuery } from '@shared/store/main/main.query';

import { exportChangeOrderBudget, getCompareBudgetColumnDefs } from './column-defs';
import { injectChangeOrderGridFetchBudgetData } from './change-order-grid-fetch-budget-data';
import { EventQuery } from '@models/event/event.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { isEqual } from 'lodash-es';
import { AuthQuery } from '@shared/store/auth/auth.query';

@Component({
  selector: 'aux-change-order-grid',
  template: `
    @if (loading()) {
      <aux-table-skeleton />
    } @else {
      <div class="w-full overflow-auto">
        <ag-grid-angular
          style="min-width: 1000px; max-width: 100%"
          domLayout="autoHeight"
          class="ag-theme-aux change-order-detail-grid tabular-nums w-full flat-table-theme"
          [gridOptions]="gridOptions()"
          [rowData]="budgetData()"
          (gridReady)="onGridReady($event)"
          (filterChanged)="onFilterChanged()"
          (rowSelected)="onRowSelected($event)"
          auxStickyGrid
        />
      </div>
    }
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AgGridAngular, TableSkeletonComponent, StickyGridDirective],
  styleUrl: 'change-order-grid.component.scss',
})
export class ChangeOrderGridComponent {
  mainQuery = inject(MainQuery);
  eventQuery = inject(EventQuery);
  organizationQuery = inject(OrganizationQuery);
  authQuery = inject(AuthQuery);

  fromBudgetVersion = input.required<CompareBudgetVersion>();
  toBudgetVersion = input<CompareBudgetVersion | null>();
  organization = input.required<OrganizationModel>();
  rowSelection = input({
    disabled: false,
    disabledTooltip: '',
  });
  changeOrderID = input.required<string>();

  showUnchangedActivities = model(true);
  showOnlyMissingActivities = model(false);

  selectedRowCount = signal(0);

  doesBudgetDataHaveMissingActivities = signal(false);

  inlineEditProcessing = this.eventQuery.selectProcessingEvent(EventType.INLINE_EDIT_CHANGE_ORDER);

  templateUploadProcessing = this.eventQuery.selectProcessingEvent(
    EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED
  );

  organizationLoading = toSignal(this.organizationQuery.selectLoading(), { requireSync: true });
  budgetDataLoading = signal(false);
  refresh$ = new Subject<void>();
  fetchBudgetData = injectChangeOrderGridFetchBudgetData();
  budgetData = toSignal(
    combineLatest([
      toObservable(this.fromBudgetVersion).pipe(distinctUntilChanged(isEqual)),
      toObservable(this.toBudgetVersion).pipe(distinctUntilChanged(isEqual)),
      toObservable(this.organization).pipe(distinctUntilChanged(isEqual)),
      toObservable(this.inlineEditProcessing).pipe(
        filter(() => !!this.toBudgetVersion()),
        startWith(null),
        map(Boolean),
        distinctUntilChanged()
      ),
      toObservable(this.templateUploadProcessing).pipe(map(Boolean), distinctUntilChanged()),
      this.refresh$.pipe(startWith(null)),
      this.organizationQuery.selectLoading(),
    ]).pipe(
      filter(() => !this.organizationLoading()),
      filter(() => {
        // Only fetch budget data when inline edit and template upload are not processing
        return !this.inlineEditProcessing() && !this.templateUploadProcessing();
      }),
      switchMap(() => {
        this.budgetDataLoading.set(true);
        return this.fetchBudgetData({
          organization: this.organization(),
          from: this.fromBudgetVersion(),
          to: this.toBudgetVersion(),
          changeOrderID: this.changeOrderID(),
        });
      }),
      map(({ budget_data, doesBudgetDataHaveMissingActivities }) => {
        this.doesBudgetDataHaveMissingActivities.set(doesBudgetDataHaveMissingActivities);
        // these two can be linkedSignal in v19 for now reset the values when we get new data
        this.showUnchangedActivities.set(true);
        this.showOnlyMissingActivities.set(false);

        if (doesBudgetDataHaveMissingActivities) {
          const isInternalUser = this.authQuery.adminUser();

          // If internal user, disable and select the checkbox
          // If external user, enable the checkbox and unselect it
          this.showOnlyMissingActivities.set(isInternalUser);
          this.showUnchangedActivities.set(!isInternalUser);
        }

        this.budgetDataLoading.set(false);

        return budget_data;
      })
    ),
    {
      initialValue: [],
    }
  );

  optionsLoading = signal(false);

  loading = computed(() => {
    return (
      this.budgetDataLoading() ||
      this.inlineEditProcessing() ||
      this.templateUploadProcessing() ||
      this.organizationLoading() ||
      this.optionsLoading()
    );
  });

  gridAPI = signal<GridApi<CompareGridData> | null>(null);

  gridOptions = computed(() => {
    const budgetData = this.budgetData();
    const toBudgetVersion = this.toBudgetVersion();
    const rowSelection = this.rowSelection();
    const currency = this.organization().currency || Currency.USD;

    return untracked(() => {
      const {
        autoGroupColumnDef,
        hiddenColumns,
        attributesColumnGroupDef,
        currentBudgetColumnGroupDef,
        toBudgetColumnGroupDef,
        varianceColumnGroupDef,
        getExcelStyles,
      } = getCompareBudgetColumnDefs({
        currency,
        budgetData,
        rowSelection,
        organizationName: this.organization().name || '',
        toBudgetVersion,
      });

      const columnDefs: (ColDef | ColGroupDef)[] = [
        ...hiddenColumns,
        attributesColumnGroupDef,
        currentBudgetColumnGroupDef,
      ];
      if (toBudgetVersion) {
        columnDefs.push({
          ...toBudgetColumnGroupDef,
          headerName: toBudgetVersion.budget_name,
        });
        columnDefs.push(varianceColumnGroupDef);
      }

      return <GridOptions<CompareGridData>>{
        groupAllowUnbalanced: true,
        ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
        defaultColDef: {
          ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
          sortable: false,
          resizable: true,
        },
        autoGroupColumnDef,
        headerHeight: 48,
        suppressAggFuncInHeader: true,
        groupDefaultExpanded: -1,
        columnDefs,
        rowSelection: 'multiple',
        groupSelectsChildren: true,
        suppressRowClickSelection: true,
        isRowSelectable: (node) => {
          if (rowSelection.disabled) {
            return !rowSelection.disabled;
          }
          if (toBudgetVersion) {
            return !node.data?.missing_activity;
          }
          return true;
        },
        getRowClass: (params: RowClassParams): string => {
          return params.node.group ? 'category-row' : '';
        },
        getRowHeight: (params) => {
          if (params.node.group || params.data?.activity_name === 'Total') {
            return 48;
          }
          return 40;
        },
        pinnedBottomRowData: this.calculateBottomRowData(budgetData),
        excelStyles: [...getExcelStyles()],
      };
    });
  });

  _syncGridOptions = effect(
    () => {
      // any changes to the grid options will trigger a re-render
      this.gridOptions();

      // don't track the gridAPI
      const gridAPI = untracked(() => this.gridAPI());
      if (!gridAPI) {
        return;
      }

      this.optionsLoading.set(true);
      setTimeout(() => {
        this.optionsLoading.set(false);
      }, 0);
    },
    {
      allowSignalWrites: true,
    }
  );

  _syncFilters = effect(() => {
    const showUnchangedActivities = this.showUnchangedActivities();
    const showOnlyMissingActivities = this.showOnlyMissingActivities();
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }

    const changedFilter = gridAPI.getFilterInstance('changed');
    const missingFilter = gridAPI.getFilterInstance('missing_activity');

    if (showOnlyMissingActivities) {
      missingFilter?.setModel({ values: ['true'] });
      changedFilter?.setModel(null);
    } else {
      missingFilter?.setModel(null);
      changedFilter?.setModel(showUnchangedActivities ? null : { values: ['true'] });
    }

    gridAPI.onFilterChanged();
  });

  onGridReady(event: GridReadyEvent<CompareGridData>) {
    this.gridAPI.set(event.api);
    this.selectedRowCount.set(0);
  }

  calculateBottomRowData(data: CompareGridData[]) {
    const from_total_cost = data.reduce((acc, row) => acc + row.from_total_cost, 0);
    const to_total_cost = data.reduce((acc, row) => acc + row.to_total_cost, 0);
    const variance_total_cost = data.reduce((acc, row) => acc + row.variance_total_cost, 0);
    const variance_total_percent = !from_total_cost ? 0 : variance_total_cost / from_total_cost;

    return [
      {
        activity_name: 'Total',
        from_total_cost,
        to_total_cost,
        variance_total_cost,
        variance_total_percent,
      },
    ];
  }

  onFilterChanged() {
    const gridAPI = this.gridAPI();
    if (!gridAPI) {
      return;
    }

    const data: CompareGridData[] = [];
    gridAPI.forEachNodeAfterFilter((node) => node.data && data.push(node.data));

    gridAPI.setGridOption('pinnedBottomRowData', this.calculateBottomRowData(data));
    gridAPI.sizeColumnsToFit();
  }

  onRowSelected(event: RowSelectedEvent<CompareGridData>) {
    this.selectedRowCount.set(event.api.getSelectedRows().length);
  }

  onExport(fileName: string) {
    const gridAPI = this.gridAPI();
    const trial = this.mainQuery.getSelectedTrial();
    const org_currency = this.organization().currency || Currency.USD;
    const toBudgetVersion = this.toBudgetVersion();
    if (!gridAPI || !trial) {
      return;
    }

    exportChangeOrderBudget({
      fileName,
      gridAPI,
      trial,
      org_currency,
      toBudgetVersion,
    });
  }
}
