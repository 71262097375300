import { ValueFormatterParams } from '@ag-grid-community/core';
import { BudgetCurrencyType } from '@models/budget-currency/budget-currency.model';
import { Currency } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';

export const agChangeOrderCurrencyFormatter =
  (selectedBudgetCurrencyType: BudgetCurrencyType) =>
  (params: ValueFormatterParams): string => {
    let currencyName = Currency.USD;
    if (params?.data) {
      const { vendor_currency } = params.data;
      const isContractedCurrency = selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR;
      currencyName = isContractedCurrency ? vendor_currency : Currency.USD;
    }
    if (params?.value) {
      if (!Number.isNaN(params.value)) {
        return Utils.agCurrencyFormatter(params.value, currencyName);
      }
    }
    return Utils.zeroHyphen;
  };
