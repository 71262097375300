import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { GridApi, ICellEditorParams } from '@ag-grid-community/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormControlConstants } from '@shared/constants/form-control.constants';
import { InputComponent } from '@shared/components/input/input.component';
import dayjs from 'dayjs';
import { OverlayService } from '@shared/services/overlay.service';

interface IAgDatePickerParams extends ICellEditorParams {
  type?: 'date' | 'month';
  min?: string;
  max?: string;
  ignoreValidations?: boolean;
}

@Component({
  templateUrl: './ag-date-picker.component.html',
  styles: [
    `
      :host,
      :host ::ng-deep input,
      :host ::ng-deep label,
      .aux-ag-date-picker {
        height: 100%;
      }

      :host ::ng-deep input {
        font-size: 14px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule],
})
export class AgDatePickerComponent implements ICellEditorAngularComp {
  overlayService = inject(OverlayService);
  readonly formControlConstants = FormControlConstants;

  params!: IAgDatePickerParams;

  fc = new FormControl('');

  api!: GridApi;

  max = '';

  min = '';

  type: 'date' | 'month' = 'date';

  stopEditing!: (suppressNavigateAfterEdit?: boolean | undefined) => void;

  agInit(params: IAgDatePickerParams): void {
    this.params = params;

    if (params.api) {
      this.api = params.api;
    }

    if (params.type) {
      this.type = params.type;
    }

    this.min = params.min || '';
    this.max = params.max || '';
    this.stopEditing = params.stopEditing;
    this.fc.setValue(params.value);
  }

  getValue() {
    // when you select something from the default browser pop-up it doesn't trigger the blur event in the input,
    // so we need to manually check the validation
    if (this.min) {
      if (dayjs(this.fc.value).isBefore(this.min)) {
        this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.MONTH_MIN);
        return null;
      }
    }
    if (this.max) {
      if (dayjs(this.fc.value).isAfter(this.max)) {
        this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.MONTH_MAX);
        return null;
      }
    }
    return this.fc.value;
  }
}
