import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TrialInsightsStore } from '@models/trial-insights/trial-insights.store';
import { TrialInsightsService } from './services/trial-insights.service';
import { TrialInsightsComponent } from './trial-insights.component';
import { TrialInsightsNavComponent } from './components/trial-insights-nav/trial-insights-nav.component';
import { TrialInsightsHeaderComponent } from './components/trial-insights-header/trial-insights-header.component';
import { TrialInsightsLegendComponent } from './components/trial-insights-legend/trial-insights-legend.component';
import { TrialInsightsTableComponent } from './components/trial-insights-table/trial-insights-table.component';
import { TrialInsightsTableService } from './components/trial-insights-table/trial-insights-table.service';
import { TrialInsightsClinicalComponent } from './components/trial-insights-clinical/trial-insights-clinical.component';
import { TrialInsightsClinicalQueryService } from './components/trial-insights-clinical/trial-insights-clinical-query.service';
import { TrialInsightsClinicalTimelineComponent } from './components/trial-insights-clinical-timeline/trial-insights-clinical-timeline.component';
import { TrialInsightsClinicalPatientCostComponent } from './components/trial-insights-clinical-patient-cost/patient-cost.component';
import { TrialInsightsClinicalPatientCostChartService } from './components/trial-insights-clinical-patient-cost/patient-cost-chart.service';
import { TrialInsightsClinicalPatientCostTableService } from './components/trial-insights-clinical-patient-cost/patient-cost-table.service';
import { TrialInsightsClinicalPatientCostStoreService } from './components/trial-insights-clinical-patient-cost/patient-cost-store.service';
import { TrialInsightsClinicalInvestigatorCostComponent } from './components/trial-insights-clinical-investigator-cost/investigator-cost.component';
import { TrialInsightsClinicalInvestigatorCostChartService } from './components/trial-insights-clinical-investigator-cost/investigator-cost-chart.service';
import { TrialInsightsClinicalInvestigatorCostTableService } from './components/trial-insights-clinical-investigator-cost/investigator-cost-table.service';
import { TrialInsightsClinicalInvestigatorCostStoreService } from './components/trial-insights-clinical-investigator-cost/investigator-cost-store.service';
import { TrialInsightsClinicalScreenFailComponent } from './components/trial-insights-clinical-screen-fail/screen-fail.component';
import { TrialInsightsClinicalScreenFailTableService } from './components/trial-insights-clinical-screen-fail/screen-fail-table.service';
import { TrialInsightsClinicalScreenFailChartService } from './components/trial-insights-clinical-screen-fail/screen-fail-chart.service';
import { TrialInsightsClinicalScreenFailStoreService } from './components/trial-insights-clinical-screen-fail/screen-fail-store.service';
import { TrialInsightsClinicalSiteCostComponent } from './components/trial-insights-clinical-site-cost/site-cost.component';
import { TrialInsightsClinicalSiteCostChartService } from './components/trial-insights-clinical-site-cost/site-cost-chart.service';
import { TrialInsightsClinicalSiteCostTableService } from './components/trial-insights-clinical-site-cost/site-cost-table.service';
import { TrialInsightsClinicalSiteCostStoreService } from './components/trial-insights-clinical-site-cost/site-cost-store.service';
import { TrialInsightsFinanceComponent } from './components/trial-insights-finance/trial-insights-finance.component';
import { TrialInsightsFinanceBvaChartMenuComponent } from './components/trial-insights-finance-bva-chart-menu/bva-chart-menu.component';
import { TrialInsightsFinanceComparingBudgetComponent } from './components/trial-insights-finance-comparing-budget/trial-insights-finance-comparing-budget.component';
import { TrialInsightsFinanceAnalyticCardComponent } from './components/trial-insights-finance-comparing-budget/trial-insights-finance-analytic-card.component';
import { TrialInsightsFinanceEnrolledPatientsComponent } from './components/trial-insights-finance-enrolled-patients/trial-insights-finance-enrolled-patients.component';
import {
  TrialInsightsFinancePatientsEnrolledChartService,
  TrialInsightsFinancePatientsEnrolledQueryService,
  TrialInsightsFinancePatientsEnrolledStoreService,
  TrialInsightsFinancePatientsEnrolledTableService,
} from './components/trial-insights-finance-enrolled-patients';
import {
  TrialInsightsFinancePaymentMilestonesComponent,
  TrialInsightsPaymentMilestoneChartService,
  TrialInsightsPaymentMilestoneQueryService,
  TrialInsightsPaymentMilestoneStoreService,
  TrialInsightsPaymentMilestonesTableService,
} from './components/trial-insights-finance-payment-milestones';
import { TrialInsightsFinanceRemainingSpendOverTimeComponent } from './components/trial-insights-finance-remaining-spend-over-time/trial-insights-finance-remaining-spend-over-time.component';
import {
  TrialInsightsRemainingSpendChartService,
  TrialInsightsRemainingSpendQueryService,
  TrialInsightsRemainingSpendStoreService,
  TrialInsightsRemainingSpendTableService,
} from './components/trial-insights-finance-remaining-spend-over-time';
import { NgChartsModule } from 'ng2-charts';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SharedModule } from '@shared/shared.module';
import { PercentIndicatorComponent } from '@features/percent-indicator/percent-indicator.component';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BudgetCompareMenuComponent } from '@widgets/budget-compare-menu/budget-compare-menu.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgChartsModule,
    IconComponent,
    SharedModule,
    PercentIndicatorComponent,
    FileManagerComponent,
    ReactiveFormsModule,
    NgSelectModule,
    BudgetCompareMenuComponent,
  ],
  providers: [
    TrialInsightsService,
    TrialInsightsStore,
    TrialInsightsTableService,
    TrialInsightsClinicalQueryService,
    TrialInsightsClinicalPatientCostChartService,
    TrialInsightsClinicalPatientCostTableService,
    TrialInsightsClinicalPatientCostStoreService,
    TrialInsightsClinicalInvestigatorCostChartService,
    TrialInsightsClinicalInvestigatorCostTableService,
    TrialInsightsClinicalInvestigatorCostStoreService,
    TrialInsightsClinicalScreenFailChartService,
    TrialInsightsClinicalScreenFailTableService,
    TrialInsightsClinicalScreenFailStoreService,
    TrialInsightsClinicalSiteCostChartService,
    TrialInsightsClinicalSiteCostTableService,
    TrialInsightsClinicalSiteCostStoreService,
    TrialInsightsFinancePatientsEnrolledChartService,
    TrialInsightsFinancePatientsEnrolledTableService,
    TrialInsightsFinancePatientsEnrolledQueryService,
    TrialInsightsFinancePatientsEnrolledStoreService,
    TrialInsightsPaymentMilestonesTableService,
    TrialInsightsPaymentMilestoneChartService,
    TrialInsightsPaymentMilestoneQueryService,
    TrialInsightsPaymentMilestoneStoreService,
    TrialInsightsRemainingSpendChartService,
    TrialInsightsRemainingSpendQueryService,
    TrialInsightsRemainingSpendStoreService,
    TrialInsightsRemainingSpendTableService,
  ],
  declarations: [
    TrialInsightsComponent,
    TrialInsightsNavComponent,
    TrialInsightsHeaderComponent,
    TrialInsightsLegendComponent,
    TrialInsightsTableComponent,
    TrialInsightsClinicalComponent,
    TrialInsightsClinicalTimelineComponent,
    TrialInsightsClinicalPatientCostComponent,
    TrialInsightsClinicalInvestigatorCostComponent,
    TrialInsightsClinicalScreenFailComponent,
    TrialInsightsClinicalSiteCostComponent,
    TrialInsightsFinanceComponent,
    TrialInsightsFinanceBvaChartMenuComponent,
    TrialInsightsFinanceComparingBudgetComponent,
    TrialInsightsFinanceAnalyticCardComponent,
    TrialInsightsFinanceEnrolledPatientsComponent,
    TrialInsightsFinancePaymentMilestonesComponent,
    TrialInsightsFinanceRemainingSpendOverTimeComponent,
  ],
  exports: [
    TrialInsightsComponent,
    TrialInsightsHeaderComponent,
    TrialInsightsLegendComponent,
    TrialInsightsTableComponent,
    TrialInsightsClinicalComponent,
    TrialInsightsFinanceComponent,
  ],
})
export class TrialInsightsModule {}
