import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { AuxSelectConfig } from '@shared/components/aux-card-select/aux-card-select.component';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntypedFormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ChartConfiguration, TooltipItem } from 'chart.js';
import { PaymentScheduleService } from './state/payment-schedule.service';
import { PaymentScheduleQuery } from './state/payment-schedule.query';
import { MainQuery } from '@shared/store/main/main.query';
import { VendorPaymentsPageComponent } from '../../vendor-payments-page.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-payment-schedule',
  templateUrl: './payment-schedule.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentScheduleComponent {
  legends = [
    {
      color: '#BACAD0',
      header: 'Milestone Payments',
    },
    {
      color: '#f3f6f7',
      header: 'Unit-Based Payments',
    },
    {
      color: '#094673',
      header: 'WP',
    },
  ];

  chart$: Observable<ChartConfiguration<'bar'>> = this.paymentScheduleQuery
    .select('PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP')
    .pipe(
      map((data) => {
        const quarters = Object.keys(data);
        return <ChartConfiguration<'bar'>>{
          data: {
            labels: quarters,
            datasets: [
              {
                data: Object.values(data).map((s) => s.payment),
                label: 'Payments',
                stack: 'stack 0',
                borderColor: '#BACAD0',
                backgroundColor: '#BACAD0',
                maxBarThickness: 40,
              },
              {
                data: [],
                label: 'Investigator Pre-payment (Forecast)',
                stack: 'stack 0',
                maxBarThickness: 40,
                borderColor: '#f3f6f7',
                backgroundColor: '#f3f6f7',
              },
              {
                data: Object.values(data).map((s) => s.wp),
                label: 'WP',
                stack: 'stack 1',
                maxBarThickness: 40,
                borderColor: '#094673',
                backgroundColor: '#094673',
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: (value) => {
                    return Utils.currencyFormatter(value as number, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 1,
                      minimumFractionDigits: 0,
                      notation: 'compact',
                    });
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label(tooltipItem: TooltipItem<'bar'>): string | string[] | void {
                    if (tooltipItem.raw && typeof tooltipItem.raw === 'number') {
                      return Utils.currencyFormatter(tooltipItem.raw, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    }
                    return `${tooltipItem.raw || ''}`;
                  },
                },
              },
            },
          },
          type: 'bar',
        };
      })
    );

  filter$: Observable<AuxSelectConfig> = this.paymentScheduleQuery
    .select('PAYMENT_SCHEDULE_CASH_REQUIREMENTS')
    .pipe(
      map((arr) => {
        return {
          options: arr.map(({ expense_months_back }) => expense_months_back),
          data: arr.map(({ pm_total }) => Utils.currencyFormatter(pm_total)),
          header: 'Cash Requirements',
        } as AuxSelectConfig;
      })
    );

  loading$ = this.paymentScheduleQuery.selectLoading();

  selectedVendor = new UntypedFormControl('');

  middle$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_PREPAID_BALANCE_IN_ESCROW');

  right$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_NEXT_PAYMENT_MILESTONE');

  table$ = this.paymentScheduleQuery.select('PAYMENT_SCHEDULE_MILESTONES');

  constructor(
    private paymentScheduleService: PaymentScheduleService,
    private paymentScheduleQuery: PaymentScheduleQuery,
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private organizationService: OrganizationService,
    private vendorPaymentsPageComponent: VendorPaymentsPageComponent,
    private mainQuery: MainQuery
  ) {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return this.paymentScheduleService.getData();
        })
      )
      .pipe(takeUntilDestroyed())
      .subscribe();

    this.organizationService
      .get()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        const org = this.organizationQuery.getActive();

        if (org) {
          this.selectedVendor.setValue(org.id);
        }
      });
  }

  onOrganizationSelected(orgId: string) {
    this.organizationStore.setActive(orgId);
  }
}
