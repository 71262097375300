import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExchangeLinkComponent } from '@features/exchange-link/exchange-link.component';
import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { CurrencyToggle } from '@shared/types/components.type';

@Component({
  selector: 'aux-toggle-currency',
  template: `
    <div class="flex items-center">
      <div class="flex items-center space-x-2">
        <div>USD</div>
        <aux-toggle
          [value]="fc.value"
          (change)="
            nameSelectedCurrency.emit($event ? currencyToggle.CONTRACTED : currencyToggle.PRIMARY)
          "
        />
        <div>Contracted Currency</div>
      </div>
      <aux-exchange-link class="ml-4" />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleCurrencyComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [ToggleComponent, ExchangeLinkComponent],
})
export class ToggleCurrencyComponent extends ToggleComponent {
  currencyToggle = CurrencyToggle;

  @Output() nameSelectedCurrency = new EventEmitter();

  @Input() onChangeEvent: (isSelected: boolean) => void = () => {};
}
