import { NgModule } from '@angular/core';

import { CardComponent } from './components/card/card.component';
import { ButtonListComponent } from './components/button-list/button-list.component';
import { PaginationGridComponent } from './components/pagination-grid/pagination-grid.component';
import { TableSkeletonComponent } from './components/table-skeleton/table-skeleton.component';
import { VendorDropdownComponent } from './components/vendor-dropdown/vendor-dropdown.component';
import { PaginationPanelComponent } from './components/pagination-panel/pagination-panel.component';
import { ConfirmationActionModalComponent } from './components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { SkeletonLoaderDirective } from './directives/skeleton-loader.directive';
import { FormErrorDirective } from './directives/form-error.directive';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GridDynamicHeightDirective } from './directives/grid-dynamic-height.directive';
import { VarDirective } from './directives/var.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { InputMaskComponent } from './components/input-mask/input-mask.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SelectComponent } from './components/select/select.component';
import { IconComponent } from './components/icon/icon.component';
import { ProcessingLoaderComponent } from './components/processing-loader/processing-loader.component';
import { AuthorizeDirective } from './directives/authorize.directive';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ButtonToggleGroupComponent } from './components/button-toggle-group/button-toggle-group.component';
import { AuxCardSelectComponent } from './components/aux-card-select/aux-card-select.component';
import { EditableListDropdownComponent } from './components/editable-list-dropdown/editable-list-dropdown.component';
import { FieldNotificationComponent } from './components/field-notification/field-notification.component';
import { SliderComponent } from './components/slider/slider.component';
import { RadioButtonGroupComponent } from './components/radio-button/radio-button-group.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { MenuComponent } from './components/menu/menu.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { CanvasChartComponent } from './components/canvas-chart/canvas-chart.component';
import { AgActionsComponent } from './ag-components/ag-actions/ag-actions.component';
import { AgCellWrapperComponent } from './ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { AgCheckboxRendererComponent } from './ag-components/ag-checkbox-rerender/ag-checkbox-renderer.component';
import { AgCollapsibleHeaderComponent } from './ag-components/ag-collapsible-header/ag-collapsible-header.component';
import { AgDatePickerComponent } from './ag-components/ag-date-picker/ag-date-picker.component';
import { AgDotsComponent } from './ag-components/ag-dots/ag-dots.components';
import { AgHtmlHeaderComponent } from './ag-components/ag-html-header/ag-html-header.component';
import { AgIconLinkCellComponent } from './ag-components/ag-icon-link-cell/ag-icon-link-cell.component';
import { AgPulseMinusComponent } from './ag-components/ag-pulse-minus/ag-pulse-minus.component';
import { AgStatusComponent } from './ag-components/ag-status/ag-status.component';
import { AgLoadingCellComponent } from './ag-components/ag-loading-cell/ag-loading-cell.component';
import { AgSelectEditorOptionRendererComponent } from './ag-components/ag-select-editor-option-renderer/ag-select-editor-option-renderer.component';
import { AgHeaderDropdownComponent } from './ag-components/ag-header-dropdown/ag-header-dropdown.component';
import { GuardWarningComponent } from './components/guard-warning/guard-warning.component';
import { ExtendableOptionsDropdownComponent } from './components/extendable-options-dropdown/extendable-options-dropdown.component';
import { ProgressTrackerComponent } from '../features/progress-tracker/progress-tracker.component';
import { SafePipe } from './pipes/safe.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { RoundPipe } from './pipes/round.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { TablePanelComponent } from './components/table-panel/table-panel.components';
import { ModalComponent } from './components/modals/modal/modal.component';

const sharedDirectives = [
  SkeletonLoaderDirective,
  FormErrorDirective,
  TextareaAutoresizeDirective,
  ClickOutsideDirective,
  GridDynamicHeightDirective,
  VarDirective,
  TooltipDirective,
  AuthorizeDirective,
] as const;

const sharedComponents = [
  CardComponent,
  PaginationGridComponent,
  PaginationPanelComponent,
  TableSkeletonComponent,
  VendorDropdownComponent,
  ButtonListComponent,
  ConfirmationActionModalComponent,
  TooltipComponent,
  ButtonComponent,
  InputComponent,
  InputMaskComponent,
  ToggleComponent,
  SelectComponent,
  IconComponent,
  ProcessingLoaderComponent,
  CheckboxComponent,
  ButtonToggleGroupComponent,
  AuxCardSelectComponent,
  EditableListDropdownComponent,
  FieldNotificationComponent,
  SliderComponent,
  RadioButtonComponent,
  RadioButtonGroupComponent,
  OverlayComponent,
  MenuComponent,
  MultiSelectDropdownComponent,
  FileManagerComponent,
  CanvasChartComponent,
  GuardWarningComponent,
  ExtendableOptionsDropdownComponent,
  TablePanelComponent,
  ProgressTrackerComponent,
  ModalComponent,
] as const;

const agSharedComponents = [
  AgActionsComponent,
  AgCellWrapperComponent,
  AgCheckboxRendererComponent,
  AgCollapsibleHeaderComponent,
  AgDatePickerComponent,
  AgDotsComponent,
  AgHtmlHeaderComponent,
  AgIconLinkCellComponent,
  AgPulseMinusComponent,
  AgStatusComponent,
  AgLoadingCellComponent,
  AgSelectEditorOptionRendererComponent,
  AgHeaderDropdownComponent,
] as const;

const sharedPipes = [SafePipe, GroupByPipe, RoundPipe, MoneyPipe] as const;

@NgModule({
  imports: [...sharedComponents, ...sharedDirectives, ...agSharedComponents, ...sharedPipes],
  exports: [...sharedComponents, ...sharedDirectives, ...agSharedComponents, ...sharedPipes],
})
export class SharedModule {}
