import { Approval } from '@shared/services/gql.service';

export interface ApprovalState {
  approvals: Approval[];
}

export function createInitialState(): ApprovalState {
  return {
    approvals: [],
  };
}
