import { Utils } from '@shared/utils/utils';
import {
  GridOptions,
  IsGroupOpenByDefaultParams,
  ITooltipParams,
  RowClassParams,
  RowStyle,
} from '@ag-grid-community/core';
import { AgHeaderDropdownComponent } from '@shared/ag-components/ag-header-dropdown/ag-header-dropdown.component';
import { TableConstants } from '@shared/constants/table.constants';
import { VariationStatusComponent } from './variation-status.component';

const columnsMenuParams = {
  // hides the Column Filter section
  suppressColumnFilter: true,

  // hides the Select / Un-select all widget
  suppressColumnSelectAll: true,

  // hides the Expand / Collapse all widget
  suppressColumnExpandAll: true,
};

export const compactGridOptions: GridOptions = {
  rowHeight: 35,
  headerHeight: 45,
  autoGroupColumnDef: {
    headerName: 'Activities',
    headerClass: 'ag-header-align-center',
    suppressMenu: true,
    minWidth: 250,
    width: 250,
    field: 'name',
    tooltipField: 'name',
    wrapText: true,
    resizable: true,
    cellRendererParams: {
      suppressCount: true,
    },
    tooltipValueGetter: (params: ITooltipParams) => {
      return params.node?.key;
    },
  },
  isGroupOpenByDefault: (params: IsGroupOpenByDefaultParams) => {
    return params.key === 'Services';
  },
  suppressAggFuncInHeader: true,
  groupIncludeTotalFooter: true,
  columnDefs: [
    {
      field: 'type',
      rowGroup: true,
      hide: true,
      aggFunc: 'sum',
      columnsMenuParams,
    },
    {
      field: 'service',
      rowGroup: true,
      hide: true,
      aggFunc: 'sum',
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Current Budget',
      headerClass: 'ag-header-align-center',
      children: [
        {
          headerName: 'Primary',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: 'tabular-nums',
          suppressMenu: true,
          field: 'primary',
          width: 140,
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
        },
        {
          headerName: 'Original',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          cellClass: 'tabular-nums',
          field: 'original',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 140,
        },
        {
          headerName: 'Var ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          cellClass: 'tabular-nums',
          field: 'var_dollar',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          cellRenderer: VariationStatusComponent,
          width: 150,
        },
        {
          headerName: 'Var (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          cellClass: 'tabular-nums',
          field: 'var_perc',
          aggFunc: 'sum',
          cellRenderer: VariationStatusComponent,
          width: 120,
        },
      ],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: '2020 Q1',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q1_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q1_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q1_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q1_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2020 Q2',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q2_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q2_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q2_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q2_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2020 Q3',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q3_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q3_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q3_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q3_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2020 Q4',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q4_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q4_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q4_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2020_q4_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2021 Q1',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q1_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q1_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q1_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q1_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2021 Q2',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q2_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q2_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q2_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q2_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
    {
      headerName: '2021 Q3',
      headerClass: 'bg-aux-blue-dark ag-header-align-center justify-center text-white',
      headerGroupComponent: AgHeaderDropdownComponent,
      children: [
        {
          headerName: 'Actuals',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q3_actuals',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
        },
        {
          headerName: 'Plan',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q3_plan',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance ($)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q3_var',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
        {
          headerName: 'Variance (%)',
          headerClass: 'ag-header-align-center font-bold',
          suppressMenu: true,
          field: '2021_q3_var_perc',
          cellClass: 'tabular-nums',
          aggFunc: 'sum',
          valueFormatter: Utils.agCurrencyFormatter,
          width: 170,
          hide: true,
        },
      ],
    },
  ],
  rowClassRules: {
    'is-even': (params: RowClassParams) => {
      if (params.node.group || !params.node.parent) {
        return !!((params.node.rowIndex || 0) % 2);
      }
      const parentSiblings = params.node.parent.parent?.childrenAfterGroup;
      const parentIndexWithoutChildrens = (parentSiblings || []).findIndex(
        (p) => p.key === params.node.parent?.key
      );
      return !((parentIndexWithoutChildrens || 0) % 2);
    },
    'is-odd': (params: RowClassParams) => {
      if (params.node.group || !params.node.parent) {
        return !((params.node.rowIndex || 0) % 2);
      }
      const parentSiblings = params.node.parent.parent?.childrenAfterGroup;
      const parentIndexWithoutChildrens = (parentSiblings || []).findIndex(
        (p) => p.key === params.node.parent?.key
      );
      return !!((parentIndexWithoutChildrens || 0) % 2);
    },
  },
  getRowStyle: (params: RowClassParams): RowStyle => {
    if (params.node.footer) {
      return { 'font-weight': 'bold' };
    }
    return {};
  },
};
