import { MainQuery } from '@shared/store/main/main.query';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { File } from '@shared/components/file-manager/state/file.model';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { listUserNamesWithEmailQuery, User } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'aux-file-viewer',
  templateUrl: './file-viewer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    IconComponent,
    DatePipe,
    AsyncPipe,
    NgTemplateOutlet,
    TooltipDirective,
  ],
})
export class FileViewerComponent implements OnInit {
  @Input() fileManager: FileManagerComponent | undefined;

  @Input() onlyShowUploaded = true;

  @Input() disableFirstFileMargin = false;

  @Input() removeOnlyFromStore = false;

  @Input() type?: 'inAppBudget' | 'edc' | undefined = undefined;

  @Output() removeFileChange = new EventEmitter<File>();

  @Input() showUserAndDate = false;

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  private loggedInUser = this.authQuery.user();

  files$: Observable<File[]> | undefined;

  loading$: Observable<boolean> | undefined;

  constructor(
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private mainQuery: MainQuery
  ) {
    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed())
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
  }

  ngOnInit(): void {
    if (this.fileManager) {
      this.files$ = this.fileManager.fileQuery.selectAll({
        filterBy: (entity) => (this.onlyShowUploaded ? entity.uploaded : true),
      });
      this.loading$ = this.fileManager.fileQuery.selectLoading();
    }
  }

  getloggedInUserSub() {
    return this.loggedInUser.sub;
  }

  async onDownload(file: File) {
    if (file) {
      if (!file.rawFile) {
        const fileSuccess = this.fileManager?.apiService.downloadFileFromPath(
          file.bucket_key,
          file.fileName
        );
        if (!fileSuccess) {
          this.overlayService.error('There was a problem downloading the file!');
        }
      } else {
        const newBlob = new Blob([file.rawFile]);
        this.fileManager?.apiService.downloadBlob(newBlob, file.fileName);
      }
    } else {
      this.overlayService.error('There was a problem downloading the file!');
    }
  }

  async onRemove(file: File) {
    this.overlayService.openPopup<
      { message: string },
      boolean | undefined,
      ConfirmationModalComponent
    >({
      content: ConfirmationModalComponent,
      data: {
        message: `Are you sure you want to remove this document?`,
      },
      settings: {
        header: 'Remove Document',
        primaryButton: {
          label: 'Remove',
          action: async (instance) => {
            if (this.type === 'edc') {
              this.fileManager?.removeFileSoft(file);
              instance?.ref.close();
              return;
            }
            if (!this.removeOnlyFromStore) {
              const success = this.fileManager?.removeFile(file);
              if (success) {
                this.overlayService.success(`${file.fileName} removed!`);
              }
              instance?.ref.close();
              return;
            }
            this.fileManager?.fileService.removeFromStore(file);
            this.fileManager?.resetUploadInput();
            this.removeFileChange.emit(file);
            instance?.ref.close();
          },
        },
      },
    });
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }
}
