import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  DestroyRef,
  inject,
} from '@angular/core';
import { GridOptions, RowNode } from '@ag-grid-community/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import dayjs from 'dayjs';
import { DataSource, GqlService } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { startWith, switchMap } from 'rxjs/operators';
import { Utils } from '@shared/utils/utils';
import { AgIntegrationsVendorComponent } from './state/ag-integrations-vendor.component';
import { IntegrationsQuery } from './state/integrations.query';
import { IntegrationsService } from './state/integrations.service';
import { AgIntegrationsConnectComponent } from './state/ag-integrations-connect.component';
import { IntegrationsState } from './state/integrations.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-integrations',
  templateUrl: './integrations.component.html',
  styles: [
    `
      ::ng-deep .integration-table .ag-cell.grid-cell {
        border-top-width: 0 !important;
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        border-bottom-width: 1px !important;
        border-color: var(--aux-gray-dark) !important;
      }

      ::ng-deep .integration-table .ag-header {
        border: 1px solid var(--aux-gray-dark) !important;
        border-radius: 0 0 0 0 !important;
        border-left-width: 0 !important;
      }
      ::ng-deep .integration-table .ag-header-row,
      ::ng-deep .integration-table .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .integration-table .ag-header-cell-text,
      ::ng-deep .integration-table .ag-row {
        font-size: 1rem;
      }
      ::ng-deep .integration-table .ag-header-cell {
        border-left: 1px solid var(--aux-gray-dark) !important;
        text-align: center;
      }
      ::ng-deep .integration-table .ag-header-cell-label {
        justify-content: center;
      }
      ::ng-deep .integration-table .ag-icon-filter {
        width: 0 !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  integrationVendors = [
    {
      name: 'NetSuite',
      type: 'General Ledger / ERP',
      logo: 'assets/img/netsuite.png',
    },
    {
      name: 'Oracle Fusion',
      type: 'General Ledger / ERP',
      logo: 'assets/img/oracle-fusion.png',
    },
    {
      name: 'Quickbooks',
      type: 'Invoices',
      logo: 'assets/img/quickbooks.png',
    },
    {
      name: 'Coupa',
      type: 'Invoices',
      logo: 'assets/img/coupa.png',
    },
    {
      name: 'Beanworks',
      type: 'Invoices',
      logo: 'assets/img/beanworks.png',
    },
    {
      name: 'SAP',
      type: 'ERP',
      logo: 'assets/img/sap.png',
    },
    {
      name: 'Microsoft Dynamics',
      type: 'General Ledger / ERP',
      logo: 'assets/img/microsoft-dynamics.png',
    },
    {
      name: 'Sage Intacct',
      type: 'General Ledger / ERP',
      logo: 'assets/img/sage-intacct.png',
    },
    {
      name: 'Adaptive Planning',
      type: 'General Ledger / ERP',
      logo: 'assets/img/adaptive-planning.png',
    },
    {
      name: 'Rave',
      type: 'EDC',
      logo: 'assets/img/rave.png',
    },
    {
      name: 'Veeva',
      type: 'CDMS',
      logo: 'assets/img/veeva.png',
    },
  ];

  gridData$ = new BehaviorSubject<IntegrationsState['items']>([]);

  loading$ = new BehaviorSubject(false);

  refreshConnections$ = new Subject();

  gridOptions: GridOptions = {
    rowHeight: 50,
    headerHeight: 45,
    overlayNoRowsTemplate:
      '<div class="text-xl">Please select an available integration to configure.</div>',
    columnDefs: [
      {
        headerName: 'Vendor',
        cellClass: 'grid-cell items-center',
        cellRenderer: AgIntegrationsVendorComponent,
        width: 400,
      },
      {
        headerName: 'Type',
        field: 'type',
        width: 200,
        sortable: false,
        cellClass: 'grid-cell items-center',
      },
      {
        headerName: 'Connection Status',
        cellClass: 'grid-cell items-center',
        cellRenderer: AgIntegrationsConnectComponent,
        cellRendererParams: {
          logInClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.oauthOpenLoginWindow(rowNode?.data?.data_source);
          },
          logOutClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.oauthDisconnect(rowNode?.data?.data_source);
          },
          hideRadioButton: true,
        },
        width: 250,
        sortable: false,
      },
      {
        headerName: 'Last Call',
        cellClass: 'grid-cell items-center',
        field: 'last_connection_date',
        valueFormatter: (val) => {
          return val.value
            ? `${dayjs(new Date(val.value)).format('YYYY-MM-DD')} at ${dayjs(
                new Date(val.value)
              ).format('HH:mm')}`
            : Utils.zeroHyphen;
        },
        width: 248,
        sortable: false,
      },
    ],
  };

  constructor(
    private integrationService: IntegrationsService,
    public integrationsQuery: IntegrationsQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  private oauthCallbackOrigin = '';

  private oauthReceiveMessage = async (
    event: MessageEvent<{
      type: string;
      params: string;
    }>
  ) => {
    if (!this.oauthCallbackOrigin || event.origin !== this.oauthCallbackOrigin) {
      return;
    }
    if (event.data.type === 'oauth') {
      const response = await firstValueFrom(this.gqlService.exchangeOAuthCode$(event.data.params));
      if (response.success && response.data) {
        if (response.data.is_connected) {
          this.overlayService.success('Login complete');
          this.refreshConnections$.next(null);
        }
      } else {
        this.overlayService.error(response.errors);
      }
    }
  };

  ngOnInit(): void {
    window.addEventListener('message', this.oauthReceiveMessage, false);

    this.integrationsQuery
      .select()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ items }) => {
        this.gridData$.next(items);
      });

    this.refreshConnections$
      .pipe(
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.integrationService.getIntegrations();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.oauthReceiveMessage);
  }

  async oauthOpenLoginWindow(dataSource: string) {
    const response = await firstValueFrom(
      this.gqlService.getOAuthLoginUrl$(dataSource as DataSource)
    );
    if (response.success && response.data) {
      this.oauthCallbackOrigin = response.data.callback_origin;
      window.open(response.data.login_url);
    } else {
      this.overlayService.error(response.errors);
    }
  }

  async oauthDisconnect(dataSource: string) {
    const response = await firstValueFrom(
      this.gqlService.revokeOAuthToken$(dataSource as DataSource)
    );
    if (response.success && response.data) {
      if (!response.data.is_connected) {
        this.overlayService.success('Logout complete');
        this.refreshConnections$.next(null);
      }
    } else {
      this.overlayService.error(response.errors);
    }
  }
}
