import { ChangeDetectionStrategy, Component, inject, input, viewChild } from '@angular/core';
import { TabGroupComponent } from '@shared/components/tab-group/tab-group.component';
import { AuxTabComponent } from '@shared/components/tab-group/aux-tab.component';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { InvoiceItemsComponent } from './invoice-items.component';
import { InvoiceItemsViaPdfComponent } from './invoice-items-via-pdf.component';
import { Utils } from '@shared/utils/utils';
import { MapActivitiesTabComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-tab.component';
import { MapActivitiesTotals } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { BehaviorSubject } from 'rxjs';
import { DataSource } from '@shared/services/gql.service';

@Component({
  standalone: true,
  selector: 'aux-invoice-tabs',
  templateUrl: 'invoice-tabs.component.html',
  imports: [
    TabGroupComponent,
    AuxTabComponent,
    InvoiceItemsComponent,
    InvoiceItemsViaPdfComponent,
    MapActivitiesTabComponent,
  ],
  template: `
    @if (isOcrItemsEnabled() || isIntegrationItemsEnabled()) {
      <aux-tab-group>
        @if (isOcrItemsEnabled()) {
          <aux-tab label="Line Items (via PDF Parser)">
            <aux-invoice-items-via-pdf [invoice]="invoice()" />
          </aux-tab>
        }
        @if (isIntegrationItemsEnabled()) {
          <aux-tab [label]="getIntegrationTabLabel()">
            <aux-invoice-items-tab [invoice]="invoice()" [items]="invoice().line_items || []" />
          </aux-tab>
        }
      </aux-tab-group>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTabsComponent {
  readonly utils = Utils;

  launchDarklyService = inject(LaunchDarklyService);

  invoice = input.required<InvoiceModel>();

  invoiceItemsViaPdf = viewChild(InvoiceItemsViaPdfComponent);

  isMapActivitiesEnabled = this.launchDarklyService.$select((f) => f.map_invoice_to_activities_tab);
  isOcrItemsEnabled = this.launchDarklyService.$select((f) => f.tab_invoice_line_items_ocr);
  isIntegrationItemsEnabled = this.launchDarklyService.$select(
    (f) => f.tab_invoice_line_items_integration
  );

  invoiceAllocatedTotals$ = new BehaviorSubject<MapActivitiesTotals>({
    invoice_total: 0,
    ACTIVITY_SERVICE: 0,
    ACTIVITY_DISCOUNT: 0,
    ACTIVITY_PASSTHROUGH: 0,
    ACTIVITY_INVESTIGATOR: 0,
  });

  canDeactivate() {
    const cmp = this.invoiceItemsViaPdf();
    return cmp ? cmp.canDeactivate() : true;
  }

  getIntegrationTabLabel(): string {
    const data_source_id = this.invoice().data_source_id;
    const integration_name =
      data_source_id === DataSource.DATA_SOURCE_AUXILIUS
        ? 'Integration'
        : `${Utils.readableDataSource(data_source_id)} Integration`;

    return `Line Items (via ${integration_name})`;
  }
}
