import { Injectable } from '@angular/core';

@Injectable()
export class StickyGridService {
  // To prevent re-sticking/re-unsticking
  // the header on every scroll event
  headerIsSticking = false;

  // To prevent re-sticking/re-unsticking
  // the total row on every scroll event
  totalRowIsSticking = false;

  // To prevent overlapping with total row
  // if visibility changed, we need to re-stick/re-unstick
  scrollBarShown = false;

  // Normally we have a total row for nearly all tables
  // This variable is added for tables like sites which does
  // not have any total row to stick it.
  isThereTotalRow = true;

  gridHeader!: HTMLElement;

  gridTotalRow!: HTMLElement;

  gridBody!: HTMLElement;

  scrollContainer!: HTMLElement;

  lastRow!: HTMLElement;

  offset = 0;

  reset() {
    this.headerIsSticking = false;
    this.totalRowIsSticking = false;
  }

  configure({ offset } = { offset: 0 }, isThereTotalRow = true) {
    this.offset = offset;
    this.gridHeader = document.querySelector('div[ref="gridHeader"]') as HTMLElement;
    this.gridTotalRow = document.querySelector('div[ref="eBottom"]') as HTMLElement;
    this.gridBody = document.querySelector('div[ref="gridBody"]') as HTMLElement;
    this.scrollContainer = document.querySelector('.ag-body-horizontal-scroll') as HTMLElement;
    this.lastRow = document.querySelector('.ag-row-last') as HTMLElement;
    this.isThereTotalRow = isThereTotalRow;

    if (this.gridHeader && this.gridBody) {
      this.configureStickyHeader();
    }

    if ((this.gridTotalRow || !this.isThereTotalRow) && this.gridBody) {
      this.configureStickyBottom();
    }
  }

  // Overriding widths to fix shifting columns issues.
  // Don't remove this one unless the ag-grid team has support for sticky header columns (AG-936).
  overrideHeaderWidths() {
    const row = document.querySelector('.ag-header-container .ag-header-row') as HTMLElement;
    if (row && row.style) {
      row.style.width = '10000px';
    }

    const root = document.querySelector('.ag-root-wrapper-body') as HTMLElement;
    this.gridHeader.style.width = `${root.getBoundingClientRect().width}px`;
  }

  overrideTotalRowWidths() {
    const root = document.querySelector('.ag-root-wrapper-body') as HTMLElement;
    this.gridTotalRow.style.width = `${root.getBoundingClientRect().width}px`;
    this.scrollContainer.style.width = `${root.getBoundingClientRect().width}px`;
  }

  // Sticky Header
  configureStickyHeader(): void {
    const gridHeaderRectangle = this.gridHeader.getBoundingClientRect();
    const gridBodyRectangle = this.gridBody.getBoundingClientRect();

    const gridHeaderHeight = gridHeaderRectangle.height;

    const gridHeaderDistanceFromTop = gridHeaderRectangle.top;
    const gridBodyDistanceFromTop = gridBodyRectangle.top;

    this.overrideHeaderWidths();

    if (!this.headerIsSticking && gridHeaderDistanceFromTop <= 0) {
      this.stickHeader(gridHeaderHeight);
    } else if (this.headerIsSticking && gridBodyDistanceFromTop >= gridHeaderHeight) {
      this.unstickHeader();
    }
  }

  stickHeader(gridHeaderHeight: number): void {
    this.gridHeader.style.position = 'fixed';
    this.gridHeader.style.top = '0';
    this.gridHeader.style.zIndex = '1000';
    this.gridBody.style.marginTop = `${gridHeaderHeight}px`;

    this.headerIsSticking = true;
  }

  unstickHeader(): void {
    this.gridHeader.style.position = 'relative';
    this.gridHeader.style.top = '';
    this.gridHeader.style.zIndex = '';
    this.gridBody.style.marginTop = '';

    this.headerIsSticking = false;
  }

  // Sticky Total Row
  configureStickyBottom() {
    const gridScrollContainerRectangle = this.scrollContainer.getBoundingClientRect();
    const gridTotalRowRectangle = this.gridTotalRow.getBoundingClientRect();
    const gridBodyRectangle = this.gridBody.getBoundingClientRect();
    const gridHeaderRectangle = this.gridHeader.getBoundingClientRect();
    const lastRowRectangle = this.lastRow.getBoundingClientRect();

    const isLastRowVisible =
      lastRowRectangle.top >= 0 && lastRowRectangle.bottom <= window.innerHeight;

    const isScrollVisibilityChanged = this.scrollBarShown != !!gridScrollContainerRectangle.height;
    if (isScrollVisibilityChanged) {
      this.totalRowIsSticking = false;
    }

    if (
      this.totalRowIsSticking &&
      !(
        this.gridTotalRow.style.position === 'fixed' ||
        this.scrollContainer.style.position === 'fixed'
      )
    ) {
      this.totalRowIsSticking = false;
    }

    this.scrollBarShown = !!gridScrollContainerRectangle.height;

    const gridBottomHeight = gridScrollContainerRectangle.height + gridTotalRowRectangle.height;

    const gridHeaderBottomDistanceFromTotalTop =
      window.innerHeight > gridBodyRectangle.y + gridHeaderRectangle.height + gridBottomHeight;

    const gridTotalRowDistanceFromBottom =
      window.innerHeight -
      ((gridScrollContainerRectangle.bottom || gridTotalRowRectangle.bottom) +
        (isScrollVisibilityChanged ? 15 : 0) -
        gridBottomHeight);
    const gridBodyDistanceFromBottom = window.innerHeight - gridBodyRectangle.bottom;

    this.overrideTotalRowWidths();

    if (
      !isLastRowVisible &&
      !this.totalRowIsSticking &&
      gridTotalRowDistanceFromBottom - this.offset <= gridBottomHeight &&
      gridHeaderBottomDistanceFromTotalTop
    ) {
      this.stickTotalRow(gridScrollContainerRectangle);
    } else if (
      isLastRowVisible ||
      (this.totalRowIsSticking && gridBodyDistanceFromBottom - this.offset >= gridBottomHeight) ||
      !gridHeaderBottomDistanceFromTotalTop
    ) {
      this.unstickTotalRow();
    }
  }

  stickTotalRow(gridScrollContainerRectangle: DOMRect): void {
    this.gridTotalRow.style.position = 'fixed';
    this.gridTotalRow.style.bottom = `${
      (gridScrollContainerRectangle.height ? 15 : 0) + this.offset
    }px`;
    this.gridTotalRow.style.zIndex = '1000';
    this.gridTotalRow.style.backgroundColor = 'white';

    this.scrollContainer.style.position = 'fixed';
    this.scrollContainer.style.bottom = `${this.offset}px`;
    this.scrollContainer.style.zIndex = '1000';
    if (!this.isThereTotalRow) {
      this.scrollContainer.style.left = `${this.gridBody.getBoundingClientRect().left}px`;
    }

    this.totalRowIsSticking = true;
  }

  unstickTotalRow(): void {
    this.gridTotalRow.style.position = 'relative';
    this.gridTotalRow.style.bottom = '';
    this.gridTotalRow.style.zIndex = '';
    this.gridTotalRow.style.backgroundColor = '';

    this.scrollContainer.style.position = 'relative';
    this.scrollContainer.style.bottom = '';
    this.scrollContainer.style.zIndex = '';
    if (!this.isThereTotalRow) {
      this.scrollContainer.style.left = ``;
    }

    this.totalRowIsSticking = false;
  }
}
