import { ProcessCellForExportParams } from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';

interface BaseStrategy {
  type: 'numeric' | 'alphanumeric';
}

interface CustomStrategy {
  type: 'custom';
  parseFn: (params: ProcessCellForExportParams) => string;
}

type ParteStrategy = BaseStrategy | CustomStrategy;

export function agParseValueFromClipboard<T>(
  params: ProcessCellForExportParams,
  pasteStrategy: Map<keyof T, ParteStrategy>
) {
  const colId = params.column.getColId() as keyof T | undefined;

  if (!colId) {
    return;
  }

  const strategy = pasteStrategy.get(colId);

  if (!strategy) return params.value;

  if (strategy.type === 'custom') {
    return strategy.parseFn(params);
  }

  if (strategy.type === 'numeric') {
    return Utils.castStringToNumber(params.value).toString();
  }

  if (strategy.type === 'alphanumeric') {
    return params.value;
  }

  return params.value;
}
