import { Injectable } from '@angular/core';
import {
  ContractedInvestigatorAmountValues,
  SiteContractAveragesValues,
} from '../investigator-forecast.types';
import { listSiteContractSettingsQuery } from '@shared/services/gql.service';
import {
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalMultiply,
  decimalRoundingToNumber,
} from '@shared/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvestigatorAveragesService {
  private maxPercent = 100;

  editMode$ = new BehaviorSubject<boolean>(false);

  getForecastedVisitCost(totalForecastedPatients: number, averageVisitCosts: number): number {
    return decimalMultiply(totalForecastedPatients, averageVisitCosts, 2);
  }

  // invoiceablesPerc - from 0 to 100%
  getForecastedInvoiceables(
    forecastedVisitCosts: number,
    invoiceablesPerc?: number | null
  ): number {
    return decimalRoundingToNumber(
      decimalMultiply(forecastedVisitCosts, (invoiceablesPerc || 0) / this.maxPercent)
    );
  }

  getTotalInvestigatorCost(forecastedVisitCosts: number, forecastedInvoiceables: number): number {
    return decimalAdd(forecastedVisitCosts, forecastedInvoiceables);
  }

  getRemainingAmount(investigatorCost: number, spendToDateAmount: number): number {
    return decimalDifference(investigatorCost, spendToDateAmount);
  }

  getInitialSummaryValues(
    averagesValues: listSiteContractSettingsQuery[],
    contractedValues: ContractedInvestigatorAmountValues
  ): SiteContractAveragesValues {
    const averages = averagesValues[0]; // last updated values

    const totalForecastedPatients = averages.total_forecasted_patients;
    const averagePatientCost = decimalRoundingToNumber(averages.average_patient_cost);

    const forecastedVisitCosts = this.getForecastedVisitCost(
      totalForecastedPatients,
      averagePatientCost
    );

    const forecastedInvoiceables = this.getForecastedInvoiceables(
      forecastedVisitCosts,
      averages.invoiceables_percent
    );

    const totalInvestigatorCosts = this.getTotalInvestigatorCost(
      forecastedVisitCosts,
      forecastedInvoiceables
    );

    const remainingAmount = this.getRemainingAmount(
      totalInvestigatorCosts,
      contractedValues.spendToDateAmount
    );

    const defaultOtherInvoiceablesPerc = decimalDivide(
      contractedValues.otherInvoiceables * this.maxPercent,
      contractedValues.totalInvoiceables
    );

    const defaultOverheadInvoiceablesPerc = decimalDifference(
      this.maxPercent,
      defaultOtherInvoiceablesPerc
    );

    return {
      contractedSites: averages.last_updated,
      totalForecastedPatients,
      averagePatientCost,
      forecastedVisitCosts,
      invoiceables: averages.invoiceables_percent || 0,
      forecastedInvoiceables,
      totalInvestigatorCosts,
      spendToDateAmount: contractedValues.spendToDateAmount,
      remainingAmount,
      otherInvoiceablesPerc: decimalRoundingToNumber(
        averages.other_percent || defaultOtherInvoiceablesPerc
      ),
      overheadInvoiceablesPerc: decimalRoundingToNumber(
        averages.overhead_percent || defaultOverheadInvoiceablesPerc
      ),
    };
  }
}
