<div class="w-screen max-w-[300px]"></div>

<aux-input
  class="w-full"
  label="Curve Name"
  [formControl]="nameControl"
  [showRequiredAsterisk]="true"
  [validators]="nameControlValidators"
/>

<span class="block mt-3 mb-1 text-xs"
  >Distribution Type <span class="text-aux-error font-bold">*</span></span
>
<ng-select
  placeholder="Select"
  class="w-full"
  appendTo="body"
  [class.opacity-50]="selectedType.disabled"
  [clearable]="false"
  [searchable]="false"
  [loading]="!!ref.data?.name && fetchingDistributions()"
  [formControl]="selectedType"
>
  @for (type of distributionTypes; track type) {
    <ng-option [value]="type">
      <span> {{ type }}</span>
    </ng-option>
  }
</ng-select>
