<div class="font-inter min-w-[600px] max-w-2xl">
  <div class="mb-4">Separate vendor names by using line breaks</div>

  <ng-container *ngIf="(isLoading$ | async) === true">
    <div class="border-8 h-32 m-auto my-16 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="(isLoading$ | async) === false">
    <aux-input #vendors class="vendors-textarea" [textArea]="true" />
  </ng-container>
</div>
