<ng-container *ngIf="(loading$ | async) === true; else grid">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-template #grid>
  <div class="flex justify-end mb-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === false"
      variant="secondary"
      label="Edit"
      icon="Pencil"
      [onClick]="editGrid.bind(this)"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="secondary"
      label="Cancel"
      icon="X"
      [onClick]="cancelEditMode.bind(this)"
    />

    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="success"
      label="Save"
      class="ml-1"
      icon="Check"
      [disabled]="!hasChanges"
      [onClick]="onSaveAll"
    />

    <aux-export-excel-button
      [className]="'ml-1'"
      [gridAPI]="gridAPI"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      pendoTag="patient-groups-excel-export-button"
    />
  </div>

  <ag-grid-angular
    class="ag-theme-aux"
    id="patientGroupsGrid"
    [rowData]="$any(gridData$ | async)"
    [gridOptions]="$any(gridOptions$ | async)"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (cellEditingStopped)="cellEditingStopped($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (gridSizeChanged)="sizeColumnsToFit()"
  />

  <div class="mt-4">
    <aux-button
      *ngIf="(editModeGrid$ | async) === true"
      variant="primary"
      label="Add Patient Group"
      [onClick]="onAddPatientGroups.bind(this)"
    />
  </div>
</ng-template>
