import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Utils } from '@shared/utils/utils';
import { MONTH_ADJUSTMENT_TOOLTIP } from '@shared/store/workflow/workflow.const';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowService } from '@shared/store/workflow/workflow.service';

@Injectable({
  providedIn: 'root',
})
export class CloseQuarterService {
  constructor(private workflowService: WorkflowService) {}

  getCloseQuarterTooltip(
    isMonthAdjustmentAvailable: boolean,
    isWorkflowAvailable: boolean,
    hasPermission: boolean,
    monthAdjustmentTooltipText = MONTH_ADJUSTMENT_TOOLTIP
  ): string {
    if (!isWorkflowAvailable) {
      const closeDate = dayjs(this.workflowService.trialMonthClose$.getValue());

      const monthName = Utils.MONTH_NAMES[closeDate.get('month')];

      return `${monthName} ${closeDate.get('year')} is not available to close`;
    }

    if (!isMonthAdjustmentAvailable) {
      return monthAdjustmentTooltipText;
    }

    if (!hasPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return '';
  }
}
