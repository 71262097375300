import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';
import { TrialsQuery } from '@models/trials/trials.query';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  GetQuickFilterTextParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { EntityType, EventType, ExpenseType, GqlService } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Router } from '@angular/router';
import { round } from 'lodash-es';
import { AgCellWrapperComponent } from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { MainQuery } from '@shared/store/main/main.query';
import { MainStore } from '@shared/store/main/main.store';
import { PortfolioStatusComponent } from './portfolio-status/portfolio-status.component';
import { PortfolioCompleteComponent } from './portfolio-complete/portfolio-complete.component';
import { NewTrialDialogComponent } from '@features/new-trial-dialog/new-trial-dialog.component';
import { MainService } from '@shared/store/main/main.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { TableConstants } from '@shared/constants/table.constants';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { EventService } from '@models/event/event.service';
import { ChartConfiguration } from 'chart.js';
import { ChartDataset } from 'chart.js/dist/types';
import { AuxExcelFormats, AuxExcelStyles } from '@shared/utils';
import { AgPulseMinusComponent } from '@shared/ag-components/ag-pulse-minus/ag-pulse-minus.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fetchInProgressEvents } from '@features/progress-tracker/utils/fetch-in-progress-events';

export interface PortfolioGridData {
  type: string;
  name: string;
  status: string;
  cmwp: number;
  clre: number;
  base: number;
  var_cost: number;
  percent: string;
  wpltd: number;
  complete: number;
  invoiced: number;
}

@Component({
  selector: 'aux-portfolio-dashboard',
  templateUrl: './portfolio-dashboard.component.html',
  styles: [
    `
      ::ng-deep main {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .portfolio-grid .ag-center-cols-clipper {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .portfolio-grid .ag-row {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .portfolio-grid .ag-cell-value {
        font-size: 12.25px;
      }

      ::ng-deep .portfolio-grid .ag-header-container {
        background-color: var(--aux-gray-light);
      }

      ::ng-deep .portfolio-grid .ag-floating-bottom {
        border-top: 0px solid var(--aux-gray-light);
      }

      ::ng-deep .trial-name.aux-link {
        color: var(--aux-blue);
        cursor: pointer;
        font-weight: normal;
      }

      ::ng-deep .ag-floating-bottom-container .ag-cell {
        font-size: 14px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioDashboardComponent implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  alreadyWentToBudgetPage = false;

  loadingRefreshPortfolioDashboard$ = new BehaviorSubject(false);

  private PERCENTAGE_COLS = ['complete', 'var_percent'];

  excelOptions: ExcelExportParams = {
    sheetName: 'Dashboard',
    fileName: 'auxilius-dashboard.xlsx',
    processCellCallback: (cel) => {
      const colId = cel.column.getColId();

      if (this.PERCENTAGE_COLS.indexOf(colId) !== -1 && cel.value) {
        return cel.value / 100;
      }
      return cel.value;
    },
  };

  cardUsers$ = this.trialUserQuery.selectAll().pipe(
    map((users) => {
      const userId = this.authQuery.getValue().trial_id;
      return users.filter((user) => {
        // todo: remove this when we get superAdmin flag
        return user.id === userId ? user : null;
      });
    })
  );

  trials$ = this.authService
    .isAuthorized$({
      sysAdminsOnly: true,
    })
    .pipe(
      switchMap((hasPermission) => {
        return this.trialsQuery.selectAll().pipe(
          map((value) => {
            if (hasPermission) {
              const trialsWoutSelected = value.filter(
                (x) => x.id !== this.mainQuery.getValue().trialKey
              );
              const { id, short_name, onboarding_complete, implementation_status } =
                this.mainQuery.getSelectedTrial() || {};
              return [
                { id: 'NEW_TRIAL', short_name: '+ Add New Trial', onboarding_complete: true },
                { id, short_name, onboarding_complete, implementation_status },
                ...trialsWoutSelected,
              ];
            }
            return value;
          })
        );
      })
    );

  loading$ = new BehaviorSubject(false);

  priceClass = [
    'ag-cell-align-right',
    'tabular-nums',
    'budget-cost',
    'text-aux-black',
    'justify-end',
    'text-sm',
  ];

  lineChart!: ChartConfiguration<'line'>;

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Current',
      headerClass: 'ag-header-align-center text-aux-black',
      minWidth: 130,
      children: [
        {
          headerName: 'Trial',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: (value) => {
            if (value.data.name === 'Totals') {
              return ['font-bold text-aux-black'];
            }
            return ['trial-name', 'aux-link', '!text-base'];
          },
          cellStyle: { 'text-align': 'start' },
          minWidth: 140,
          field: 'name',
          sort: 'asc',
          onCellClicked: async (event: CellClickedEvent) => {
            if (event.data.name === 'Totals') {
              return null;
            }
            await this.onTrialSelect(event.data.id, false);
            this.route.navigate([`/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`]);
            return event;
          },
        },
        {
          headerName: 'Current Month Status',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: '!justify-start',
          cellStyle: { height: '100%' },
          field: 'status',
          suppressMenu: true,
          minWidth: 180,
          cellRenderer: PortfolioStatusComponent,
          onCellClicked: async (event: CellClickedEvent) => {
            await this.onTrialSelect(event.data.id, false);
            this.route.navigate([
              `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
            ]);
          },
          getQuickFilterText: (params: GetQuickFilterTextParams) => {
            switch (params.value as string) {
              case 'Open':
                return 'Open';
              case 'Closed':
                return 'Closed';
              default:
                return Utils.zeroHyphen;
            }
          },
        },
        {
          headerName: 'Current Month WP',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          field: 'cmwp',
          minWidth: 100,
          suppressMenu: true,
          valueFormatter: this.agCurrencyFormatter,
        },
      ],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Budget',
      headerClass: ['ag-header-align-center', 'text-aux-black'],
      children: [
        {
          headerName: 'Current (LRE)',
          headerClass: 'ag-header-align-center font-bold',
          field: 'clre',
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          minWidth: 100,
          suppressMenu: true,
          valueFormatter: this.agCurrencyFormatter,
        },
        {
          headerName: 'Baseline',
          headerClass: 'ag-header-align-center font-bold',
          field: 'base',
          minWidth: 100,
          suppressMenu: true,
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          valueFormatter: this.agCurrencyFormatter,
        },
        {
          headerName: 'Var ($)',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          field: 'var_cost',
          colId: 'var_cost',
          minWidth: 150,
          suppressMenu: true,
          cellRenderer: AgPulseMinusComponent,
        },
        {
          headerName: 'Var (%)',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: ['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT, '!text-base'],
          field: 'percent',
          colId: 'var_percent',
          minWidth: 80,
          suppressMenu: true,
          cellRenderer: AgPulseMinusComponent,
        },
      ],
    },
    TableConstants.SPACER_COLUMN,
    {
      headerName: 'Work Performed',
      headerClass: ['ag-header-align-center', 'text-aux-black'],
      children: [
        {
          headerName: 'WP LTD',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          field: 'wpltd',
          minWidth: 100,
          suppressMenu: true,
          valueFormatter: this.agCurrencyFormatter,
        },
        {
          headerName: '% Complete',
          headerClass: 'ag-header-align-center font-bold',
          field: 'complete',
          colId: 'complete',
          cellClass: ['items-center', 'complete-percent', '!text-base'],
          suppressMenu: true,
          cellRenderer: PortfolioCompleteComponent,
          minWidth: 150,
        },
        {
          headerName: 'Invoiced LTD',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
          field: 'invoiced',
          minWidth: 100,
          suppressMenu: true,
          valueFormatter: this.agCurrencyFormatter,
        },
      ],
    },
  ];

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: (value) => {
        if (value.data.name === 'Totals') {
          return ['font-bold text-aux-black'];
        } /* This function is for Totals Row style format */
        if (value.colDef.field === 'status') {
          return [
            'items-center',
            'justify-start',
            'cursor-pointer',
            'ag-cell-align-right',
            'h-full',
          ];
        }
        return this.priceClass;
      },
      cellRenderer: AgCellWrapperComponent,
    },
    rowHeight: 35,
    headerHeight: 45,
    columnDefs: this.defaultColumns,
    rowClassRules: {
      'is-even': (params: RowClassParams) => {
        if (params.node.group || !params.node.parent) {
          return !!((params.node.rowIndex || 0) % 2);
        }
        const parentSiblings = params.node.parent.parent?.childrenAfterGroup;
        const parentIndexWithoutChildrens = (parentSiblings || []).findIndex(
          (p) => p.key === params.node.parent?.key
        );
        return !((parentIndexWithoutChildrens || 0) % 2);
      },
      'is-odd': (params: RowClassParams) => {
        if (params.node.group || !params.node.parent) {
          return !((params.node.rowIndex || 0) % 2);
        }
        const parentSiblings = params.node.parent.parent?.childrenAfterGroup;
        const parentIndexWithoutChildrens = (parentSiblings || []).findIndex(
          (p) => p.key === params.node.parent?.key
        );
        return !!((parentIndexWithoutChildrens || 0) % 2);
      },
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', color: 'var(--text-aux-black)' };
      }
      return {};
    },
    excelStyles: [
      ...AuxExcelStyles,
      {
        id: 'trial-name',
        dataType: 'String',
      },
      {
        id: 'var-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: AuxExcelFormats.Percent },
      },
      {
        id: 'complete-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: AuxExcelFormats.Percent },
      },
      {
        id: 'budget-units',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: AuxExcelFormats.Units },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: AuxExcelFormats.Cost },
      },
    ],
  } as GridOptions);

  section_portfolio_analytics_avg_month_close$: Observable<boolean>;

  quicksight_dashboard_portfolio$: Observable<boolean>;

  gridAPI!: GridApi;

  gridData$ = new BehaviorSubject<PortfolioGridData[]>([]);

  cardValues = new BehaviorSubject<PortfolioGridData | undefined>(undefined);

  trialFc = new UntypedFormControl(null);

  showTaskSection$ = this.launchDarklyService.select$((flags) => flags.section_task_list);

  progressTrackerId = fetchInProgressEvents(
    EventType.REFRESH_PORTFOLIO_DASHBOARD_QUICKSIGHT,
    this.destroyRef
  );

  constructor(
    public trialsQuery: TrialsQuery,
    public mainQuery: MainQuery,
    private mainStore: MainStore,
    public authQuery: AuthQuery,
    private trialUserQuery: TrialsQuery,
    private gqlService: GqlService,
    public authService: AuthService,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private mainService: MainService,
    private route: Router,
    private eventService: EventService
  ) {
    this.loading$.next(true);
    setTimeout(() => {
      this.mainStore.update({ fullPage: true, sideBar: false });
    }, 0);

    this.section_portfolio_analytics_avg_month_close$ = launchDarklyService.select$(
      (flags) => flags.section_portfolio_analytics_avg_month_close
    );

    this.quicksight_dashboard_portfolio$ = launchDarklyService.select$(
      (flags) => flags.quicksight_dashboard_portfolio
    );

    this.eventService
      .select$(EventType.REFRESH_PORTFOLIO_DASHBOARD)
      .pipe(
        startWith(null),
        takeUntilDestroyed(),
        switchMap(() =>
          combineLatest([
            this.gqlService.listPortfolioSummaries$(),
            this.gqlService.listPortfolioExpenses$(),
          ])
        )
      )
      .subscribe(([{ success, data, errors }, expenses]) => {
        if (success && data) {
          const prevMonth = Utils.dateParse(this.getPrevMonth());

          this.gridData$.next(
            data.map((row) => {
              const currentMonth = Utils.dateParse(
                this.convertFormattedDateToDate(row.current_month || '')
              );
              const diff = +prevMonth - +currentMonth;
              const status = diff >= 0 ? 'Open' : 'Closed';

              let current_month_wp = row.current_month_work_performed || 0;
              let current_lre = 0;
              let wp_ltd = 0;
              const baseline = row.baseline_budget_estimate || 0;
              const invoiced = row.invoice_total || 0;
              row.current_budget_expenses.forEach((expense) => {
                switch (expense.expense_type) {
                  case ExpenseType.EXPENSE_ACCRUAL_ADJUSTED: {
                    current_month_wp =
                      current_month_wp > 0 ? current_month_wp : expense.amount || 0;
                    break;
                  }
                  case ExpenseType.EXPENSE_QUOTE: {
                    current_lre = expense.amount || 0;
                    break;
                  }
                  case ExpenseType.EXPENSE_WP: {
                    wp_ltd = expense.amount || 0;
                    break;
                  }
                  default:
                    break;
                }
              });
              const var_cost = round(current_lre - baseline, 2);
              const percent = baseline ? round((var_cost / baseline) * 100, 2) : 0;

              return {
                type: 'Active',
                name: row.trial_short_name,
                id: row.trial_id,
                status,
                cmwp: current_month_wp,
                clre: current_lre,
                base: baseline,
                var_cost,
                percent: percent.toFixed(2) !== '0.00' ? percent.toFixed(2) : '',
                wpltd: wp_ltd,
                complete: round((wp_ltd / current_lre) * 100, 2) || 0,
                invoiced,
              } as PortfolioGridData;
            })
          );
        } else {
          this.overlayService.error(errors);
        }

        if (expenses.success && expenses.data) {
          const obj: Record<string, Record<string, number>> = {};

          expenses.data.forEach((row) => {
            obj[row.period || ''] = obj[row.period || ''] || {};

            obj[row.period || ''][row.source || ''] = row.amount || 0;
          });
          const periods = ['', ...Object.keys(obj)];
          const periodsFormatted = this.getLabels(periods);

          const today = new Date();
          const todayQY = `Q${Math.floor(today.getMonth() / 3) + 1} ${today.getFullYear()}`;
          const colors: Partial<ChartDataset<'line'>>[] = ['#138EE7', '#095B95'].map((color) => ({
            backgroundColor: color,
            borderColor: color,
            pointBorderColor: color,
            pointBackgroundColor: 'rgba(0,0,0,0)',
            pointHoverBorderWidth: 6,
            pointRadius: 2,
            pointBorderWidth: 4,
            pointStyle: 'rectRounded',
          }));
          this.lineChart = {
            options: {
              scales: {
                y: {
                  position: 'right',
                  ticks: {
                    callback: (value) => {
                      if (value !== undefined && value && !Number.isNaN(value)) {
                        return Utils.currencyFormatter(value as number, {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                          notation: 'compact',
                        });
                      }
                      return '';
                    },
                  },
                },
                x: {
                  ticks: {
                    autoSkip: false,
                  },
                  grid: {
                    color: periodsFormatted.map((pf) => (pf === todayQY ? '#095b95' : '#e5e5e5')),
                    lineWidth: periodsFormatted.map((pf) => (pf === todayQY ? 3 : 1)),
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  mode: 'index',
                  intersect: false,
                  callbacks: {
                    label(item) {
                      return `${item.label} ${Utils.currencyFormatter(item.parsed.y, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`;
                    },
                    labelColor(tooltipItem) {
                      const color = tooltipItem.datasetIndex
                        ? 'rgba(8, 91, 149, 1)'
                        : 'rgba(19, 142, 231, 1)';
                      return { borderColor: color, backgroundColor: color };
                    },
                  },
                },
                legend: {
                  display: true,
                  labels: {
                    usePointStyle: true,
                    padding: 10,
                    pointStyle: 'line',
                  },
                  position: 'bottom',
                },
              },
            },
            type: 'line',
            data: {
              labels: periodsFormatted,
              datasets: [
                {
                  spanGaps: false,
                  data: this.runningSum(periods.map((period) => obj[period]?.CURRENT || 0)),
                  yAxisID: 'y',
                  cubicInterpolationMode: 'monotone',
                  label: 'Current (LRE)',
                  ...colors[0],
                },
                {
                  spanGaps: false,
                  data: this.runningSum(periods.map((period) => obj[period]?.BASE || 0)),
                  cubicInterpolationMode: 'monotone',
                  label: 'Baseline Budget',
                  ...colors[1],
                },
              ],
            },
          };
        } else {
          this.overlayService.error(expenses.errors);
        }

        this.loading$.next(false);
      });

    this.trialFc.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.onTrialSelect(value, true);
    });
  }

  percentageFormatter(val: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    let fVal = Utils.zeroHyphen;
    if (val) {
      fVal = `${formatter.format(val)}%`;
    }
    return fVal;
  }

  getLabels(data: string[]) {
    const labelSet = new Set<string>();
    for (const d of data) {
      if (d) {
        const label = this.transformPeriodStr(d);
        if (label) {
          labelSet.add(label);
        }
      } else {
        // Handle initial empty string (for first label)
        labelSet.add(d);
      }
    }
    const labelArr = Array.from(labelSet) as string[];
    // Sort by quarters
    return labelArr.sort((a, b) => {
      const year1 = parseInt(a.split(' ')?.[1], 10) || -1;
      const year2 = parseInt(b.split(' ')?.[1], 10) || -1;
      if (year1 !== year2) {
        return year1 > year2 ? 1 : -1;
      }
      return a > b ? 1 : -1;
    });
  }

  transformPeriodStr(period: string) {
    if (period) {
      const split = period.split('-');
      if (split.length > 1) {
        return `${split[1]} ${split[0]}`;
      }
    }
    return '';
  }

  runningSum(nums: number[]) {
    let total = 0;
    return nums.map((num) => (total += num));
  }

  convertFormattedDateToDate(str: string) {
    const d = new Date(`03/${str.replace('-', '/')}`);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-01`;
  }

  async onTrialSelect(value: string, goToBudget: boolean) {
    if (value === 'NEW_TRIAL') {
      const ref = this.overlayService.open<string>({ content: NewTrialDialogComponent });

      ref.afterClosed$.subscribe((newTrialKey) => {
        if (newTrialKey.data) {
          this.trialFc.setValue(newTrialKey.data);
          this.alreadyWentToBudgetPage = true;
          this.goToBudget();
        } else {
          this.trialFc.reset();
        }
      });
    } else if (value) {
      await this.mainService.setTrial(value);

      if (goToBudget && !this.alreadyWentToBudgetPage) {
        this.goToBudget();
      }
    }
  }

  goToBudget() {
    this.route.navigate([`/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`]);
  }

  getPrevMonth() {
    const today = new Date();
    const prev = Utils.addMonths(today, -1);
    const month = (prev.getMonth() + 1).toString().padStart(2, '0');
    const year = prev.getFullYear();
    return `${year}-${month}-01`;
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false, sideBar: true });
  }

  getDynamicExcelParams(): ExcelExportParams {
    const totalData = this.gridAPI.getPinnedBottomRow(0)?.data;

    return {
      shouldRowBeSkipped(params) {
        return params.node?.data?.name === 'Totals';
      },
      appendContent: [
        {
          cells: [
            {
              data: { value: 'Totals', type: 'String' },
              mergeAcross: 1,
              styleId: 'total_row_header',
            },
            {
              data: { value: `${totalData.cmwp}`, type: 'Number' },
              styleId: 'total_row',
            },
            {
              data: { value: `${totalData.clre}`, type: 'Number' },
              styleId: 'total_row',
            },
            {
              data: { value: `${totalData.base}`, type: 'Number' },
              styleId: 'total_row',
            },
            {
              data: { value: `${totalData.var_cost}`, type: 'Number' },
              styleId: 'total_row',
            },
            {
              data: { value: `${totalData.percent / 100}`, type: 'Number' },
              styleId: 'total_row_percent',
            },
            {
              data: { value: `${totalData.wpltd}`, type: 'Number' },
              styleId: 'total_row',
            },
            {
              data: { value: `${totalData.complete / 100}`, type: 'Number' },
              styleId: 'total_row_percent',
            },
            {
              data: { value: `${totalData.invoiced}`, type: 'Number' },
              styleId: 'total_row',
            },
          ],
        },
      ],
    };
  }

  onTrackerIdChanged(trackerId: string) {
    this.progressTrackerId.set(trackerId);
    if (!trackerId) {
      this.loadingRefreshPortfolioDashboard$.next(false);
      window.location.reload();
    }
  }

  onGridReady(e: GridReadyEvent) {
    this.gridAPI = e.api;

    this.gridData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const pinnedBottomData = { ...this.calculatePinnedBottomData(), name: 'Totals' };
      this.gridAPI.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    });
    this.autoSize();
  }

  calculatePinnedBottomData() {
    const columnsWithAggregation = [
      'cmwp',
      'clre',
      'base',
      'var_cost',
      'wpltd',
      'invoiced',
      'complete',
    ] as const;

    const rData = this.gridData$.getValue().reduce(
      (acc, val) => {
        for (const key of columnsWithAggregation) {
          acc[key] += Number(val[key]);
        }
        return acc;
      },
      {
        cmwp: 0,
        clre: 0,
        base: 0,
        var_cost: 0,
        wpltd: 0,
        invoiced: 0,
        complete: 0,
      }
    );

    this.cardValues.next(rData as PortfolioGridData);
    const rrData = { ...rData, percent: ((rData.var_cost / rData.base) * 100).toFixed(2) };
    rrData.complete = Math.round((rData.wpltd / rData.clre) * 100);
    return rrData;
  }

  autoSize() {
    this.gridAPI?.sizeColumnsToFit();
  }

  agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.data) {
      if (val.data.expense_note && (val.colDef.field || '').indexOf('cost') >= 0) {
        return val.data.expense_note;
      }
    }

    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  async triggerRefreshPortfolioDashboard() {
    this.loadingRefreshPortfolioDashboard$.next(true);
    this.quicksight_dashboard_portfolio$.subscribe((flag) =>
      flag ? this.onPortfolioQuicksightRefresh() : this.onPortfolioLegacyRefresh()
    );
    this.overlayService.success(MessagesConstants.REFRESH_PORTFOLIO_DASHBOARD);
  }

  async onPortfolioQuicksightRefresh() {
    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.REFRESH_PORTFOLIO_DASHBOARD_QUICKSIGHT,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          manual: true,
        }),
      })
    );
  }

  async onPortfolioLegacyRefresh() {
    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.REFRESH_PORTFOLIO_DASHBOARD,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({}),
      })
    );
  }
}
