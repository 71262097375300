<form
  class="space-y-5 w-[515px] max-h-180 p-1 overflow-x-scroll site-budget-amendment-modal"
  [formGroup]="createAmendmentForm"
>
  <div>
    <div class="mb-1 text-xs">Protocol Version</div>
    <ng-select
      class="select"
      placeholder="Select Version"
      [searchable]="true"
      [clearable]="false"
      bindValue="value"
      formControlName="protocolVersion"
      [items]="ref.data?.protocolVersionList ?? []"
      auxFormError
      required
    />
  </div>
  <aux-input
    #siteBudgetNameInput
    label="Site Budget Amendment Name"
    placeholder="Amendment Name / Version"
    formControlName="amendmentName"
    validators="required"
  />
  <div>
    <div class="mb-1 text-xs">Upload Site Contract Budget</div>
    <aux-file-manager
      #fileManager
      class="h-28 bg-aux-gray-light"
      [eager]="false"
      [metadata]="{}"
      [showSuccessOnUpload]="true"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <aux-icon name="Upload" />
        <div class="ml-3">
          <p class="text-lg">
            Drag & Drop Documents Here or
            <span
              class="text-aux-blue-link underline group-hover:text-aux-blue group-active:text-aux-blue-dark"
              >Browse</span
            >
          </p>
        </div>
      </div>
    </aux-file-manager>
  </div>

  <aux-file-manager-uploaded-files *ngIf="fileManager" [fileManager]="fileManager" />

  <aux-input label="Reason for Amendment" formControlName="reason" [textArea]="true" [rows]="3" />
</form>
