import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '../ag-cell-wrapper/ag-cell-wrapper.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Currency } from '@shared/services/gql.service';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  template: `
    <div class="flex items-center justify-between w-full">
      @if (pulseValue) {
        <aux-icon name="ArrowUp" class="text-aux-error flex-shrink-0" />
      }

      @if (minusValue) {
        <aux-icon name="ArrowDown" class="text-aux-green flex-shrink-0" />
      }

      @if (!pulseValue && !minusValue) {
        <div class="w-4"></div>
      }

      <div [attr.auto-qa]="autoTestAttribute">{{ rowValue }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply block w-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class AgPulseMinusComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  params!: ICellRendererParams & {
    org_currency?: Currency;
  };

  pulseValue?: boolean;

  minusValue?: boolean;

  rowValue?: string | null;

  agInit(params: ICellWrapperParams): void {
    this.params = params;
    this.getValueToDisplay(params);
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellWrapperParams) {
    // set value into cell again
    this.getValueToDisplay(params);
    return false;
  }

  getValueToDisplay(params: ICellWrapperParams) {
    this.pulseValue =
      params.value &&
      (params.value > 0 || (typeof params.value === 'string' && params.value.indexOf('-') === -1));
    this.minusValue =
      params.value &&
      (params.value < 0 || (typeof params.value === 'string' && params.value.indexOf('-') > -1));
    this.rowValue = params.value || Utils.zeroHyphen;
    const colID = params.column?.getColId()?.toLowerCase() || '';
    if (colID === 'variance_unit') {
      this.rowValue = params.valueFormatted;
    }
    if (colID.indexOf('cost') !== undefined && colID.indexOf('cost') > 0) {
      this.rowValue = this.params.org_currency
        ? Utils.currencyFormatter(params.value, {}, this.params.org_currency)
        : Utils.currencyFormatter(params.value);
    } else if (colID.indexOf('percent') !== undefined && colID.indexOf('percent') > 0) {
      if (params.value.toNumber) {
        this.rowValue = Utils.percentageFormatter(params.value.toNumber());
      } else if (params.value === 0 || params.value === '') {
        this.rowValue = '—';
      } else if (typeof params.value === 'string') {
        this.rowValue = `${this.rowValue}%`;
      } else {
        this.rowValue = Utils.percentageFormatter(params.value);
      }
    }
    this.rowValue = (
      !Number.isNaN(this.rowValue) ? this.rowValue?.toString() : this.rowValue
    )?.replace('-', '');

    this.pulseValue = this.rowValue === Utils.zeroHyphen ? false : this.pulseValue;
    this.minusValue = this.rowValue === Utils.zeroHyphen ? false : this.minusValue;
  }
}
