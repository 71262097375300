import { EventTrackerService } from '@models/event/event-tracker.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  merge as rxMerge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
  QueryList,
  signal,
  TemplateRef,
  untracked,
  ViewChildren,
} from '@angular/core';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  PostProcessPopupParams,
  ProcessCellForExportParams,
  RowClassParams,
  RowStyle,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { PeriodType, RequireSome, Utils } from '@shared/utils/utils';
import { ChartConfiguration } from 'chart.js';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { OverlayService } from '@shared/services/overlay.service';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { groupBy, isArray, isString, merge, round, sumBy, uniq, uniqBy } from 'lodash-es';
import {
  Activity,
  ActivitySubType,
  ActivityType,
  AuxBudgetCategoryData,
  BudgetActivityAttributes,
  BudgetHeader,
  BudgetType,
  CategoryType,
  CreateUserCustomViewInput,
  EntityType,
  EventType,
  GqlService,
  listActivitiesWithInvoiceMappingsQuery,
  OrganizationType,
  PermissionType,
  TrialPreferenceType,
  UpdateUserCustomViewInput,
  UserCustomView,
  ViewLocation,
  WorkflowStep,
} from '@shared/services/gql.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MainQuery } from '@shared/store/main/main.query';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables/variation-status.component';
import { AgHeaderDropdownComponent } from '@shared/ag-components/ag-header-dropdown/ag-header-dropdown.component';

import { AuthService } from '@shared/store/auth/auth.service';
import { EventService } from '@models/event/event.service';

import { BudgetUploadComponent } from './budget-upload/budget-upload.component';
import {
  actualsToDateColumnDef,
  cellSize,
  getCellClass,
  overallBudgetColumnDef,
  period_sorting,
  remainingBudgetColDef,
  rowGroupsColumnDef,
  uomHide$,
} from './column-defs';
import {
  ColumnChooserComponent,
  VisibleColumns,
} from './column-chooser-component/column-chooser.component';

import { BudgetCustomCreateComponent } from './components/budget-custom-create.component';
import { BudgetCustomUpdateComponent } from './components/budget-custom-update.component';
import { TableConstants } from '@shared/constants/table.constants';
import { SnapshotModalComponent } from '@features/snapshot-modal/snapshot-modal.component';
import { SnapshotService } from '@features/budget/services/snapshot.service';
import { BudgetEnhancedHeaderDropdownService } from './budget-enhanced-header-dropdown.service';
import {
  AgSetColumnsVisible,
  AuxExcelFormats,
  AuxExcelStyleKeys,
  AuxExcelStyles,
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalMultiply,
  decimalRoundingToNumber,
  GetExcelStyle,
  isEven,
} from '@shared/utils';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetGroupHeaderComponent,
} from '@features/ag-budget-group-header/ag-budget-group-header.component';
import { ChartDataset } from 'chart.js/dist/types';
import { Dictionary, isEqual } from 'lodash';
import {
  AgBeCheckboxGroupRendererComponent,
  ICheckboxCellRendererParams,
} from './ag-be-checkbox-group-renderer.component';
import { AuxButtonGroup } from '@shared/components/button-group/button-group.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BeInlineCategoryDropdownOption } from '@features/inline-budget/be-inline-category-dropdown/be-inline-category-dropdown.model';
import { BeActivitiesAttributesModalRowData } from '@features/inline-budget/be-activities-attributes-modal/be-activities-attributes-modal.model';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { EventQuery } from '@models/event/event.query';
import { BudgetCurrencyType } from '@models/budget-currency/budget-currency.model';
import { ExtendedBudgetData } from '@models/budget/budget.model';
import { BudgetQuery } from '@models/budget/budget.query';
import { BudgetStore } from '@models/budget/budget.store';
import { BudgetService } from '@features/budget/services/budget.service';
import { BudgetGridService } from '@features/budget/services/budget-grid.service';
import {
  attributeColumnDef,
  getAttributeColumns,
  getEncodedAttributeName,
} from '@features/budget-attributes/services/budget-attributes.service';
import { agBudgetCurrencyFormatter } from './utils/budget-formatters.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { fetchInProgressEvents } from '@features/progress-tracker/utils/fetch-in-progress-events';
import { injectOpenActivitiesModal } from '@features/inline-budget/inline-budget-modal';

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

// localstorage key for Budget attributes
const BEAttributesLSKey = 'budget_enhanced_header';

const checkboxTooltip = 'Select a vendor to select activities';

type BudgetChartData = { data: number; discountData: number }[];

interface ActivityWithAttributes extends Omit<Activity, '__typename'> {
  attributes: Pick<BudgetActivityAttributes, 'attribute_name' | 'attribute_value'>[];
  activity_sub_type?: ActivitySubType;
  category_full_path: string;
}

@Component({
  selector: 'aux-budget-enhanced',
  templateUrl: './budget-enhanced.component.html',
  styleUrls: ['./budget-enhanced.component.css'],
  providers: [BudgetEnhancedHeaderDropdownService],
})
export class BudgetEnhancedComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  activitiesModal = injectOpenActivitiesModal();

  readonly messagesConstants = MessagesConstants;

  editButtonsDisabled = signal(true);

  timelineExist$ = this.mainQuery.selectTimelineExist();

  editOptions = computed<AuxButtonGroup[]>(() => {
    const editAttributesDisabled = this.editButtonsDisabled();
    return untracked(() => [
      {
        iconName: 'Plus',
        name: 'Add New Activities',
        onClick: () => {
          const ref = this.activitiesModal.openModal({
            rows: [],
            budgetData: this.gridData$.getValue(),
            editMode: false,
            uomHide: uomHide$.getValue(),
          });
          if (ref) {
            this.onModalClose(ref);
          }
        },
      },
      {
        iconName: 'Edit',
        name: 'Edit Activities',
        onClick: () => {
          const ref = this.activitiesModal.openModal({
            rows: this.budgetModalRowMap(
              this.activitiesModal.mapSelectedRowsToModalData(this.gridAPI!)
            ),
            budgetData: this.gridData$.getValue(),
            uomHide: uomHide$.getValue(),
          });
          if (ref) {
            this.onModalClose(ref);
          }
        },
        disabled: this.editButtonsDisabled(),
        disabledTooltip: 'Please select activities to edit.',
      },
      {
        iconName: 'Hierarchy3',
        name: 'Edit Attributes',
        onClick: () => {
          const ref = this.activitiesModal.openModal({
            rows: this.budgetModalRowMap(
              this.activitiesModal.mapSelectedRowsToModalData(this.gridAPI!)
            ),
            budgetData: this.gridData$.getValue(),
            isActivityTabVisible: false,
            uomHide: uomHide$.getValue(),
          });
          if (ref) {
            this.onModalClose(ref);
          }
        },
        disabled: editAttributesDisabled,
        disabledTooltip: 'Please select activities to edit.',
      },
    ]);
  });

  budgetModalRowMap = (rows: BeActivitiesAttributesModalRowData[]) => {
    if (this.activitiesWithoutMapping && this.activitiesWithoutMapping.length) {
      rows.forEach((row) => {
        row.deletable =
          row.deletable &&
          !this.activitiesWithoutMapping?.find(
            (val) => val.activity_id === row.id && val.activity_name === row.activity_name
          );
      });
    }
    return rows;
  };

  onModalClose = async (ref: ReturnType<typeof this.activitiesModal.openModal>) => {
    if (!ref) {
      return;
    }
    const closeEvent = await firstValueFrom(ref.afterClosed$);
    const vendor_id = this.activitiesModal.vendor_id();
    if (closeEvent.data) {
      switch (closeEvent.data.type) {
        case 'close':
          break;
        case 'save': {
          const transaction_id = uuidv4();
          const budgetUpdated = await this.updateActivities(
            vendor_id,
            closeEvent.data.rows,
            closeEvent.data.isCategoryListChanged,
            closeEvent.data.temporaryCategories,
            closeEvent.data.notes,
            closeEvent.data.supporting_document_s3_bucket_keys
          );

          if (closeEvent.data.deletedColumns.length || closeEvent.data.renamedColumns.length) {
            await this.updateColumns({
              vendor_id,
              deletedColumns: closeEvent.data.deletedColumns,
              renamedColumns: closeEvent.data.renamedColumns,
              notes: closeEvent.data.notes,
              supporting_document_s3_bucket_keys:
                closeEvent.data.supporting_document_s3_bucket_keys,
              transaction_id,
            });
          }

          if (!budgetUpdated) {
            const tracking_id = uuidv4();
            if (
              await this.eventService.triggerEvent({
                type: EventType.INLINE_EDIT_BUDGET_ACTIVITIES,
                entity_type: EntityType.ORGANIZATION,
                entity_id: vendor_id,
                tracking_id,
                transaction_id,
                payload: JSON.stringify({
                  createActivities: [],
                  updateActivities: [],
                  deleteActivities: [],
                  note: closeEvent.data.notes,
                  supporting_document_s3_bucket_keys:
                    closeEvent.data.supporting_document_s3_bucket_keys,
                }),
              })
            ) {
              this.eventTrackerService.trackEvent(tracking_id);
              this.overlayService.success('Budget update started');
            }
          }

          break;
        }
      }
    }
  };

  visibleColumns: VisibleColumns = {
    overall_budget: {
      primary: true,
      units: true,
      unit_cost: true,
      original: true,
      var_cost: true,
      var_perc: true,
    },
    remaining_budget: {
      perc: true,
      costs: true,
      units: true,
    },
    actuals_to_date: {
      perc: true,
      costs: true,
      units: true,
    },
    current_period: {
      months: true,
      quarters: true,
    },
    historicals: {
      months: true,
      quarters: true,
      years: true,
    },
    forecast: {
      months: true,
      quarters: true,
      years: true,
    },
  };

  progressTrackerId = fetchInProgressEvents(EventType.BUDGET_TEMPLATE_UPLOADED, this.destroyRef);

  userHasUploadBudgetPermission = false;

  selectedVendor = new FormControl('');

  selectedBudgetCurrencyType$ = new BehaviorSubject<BudgetCurrencyType>(BudgetCurrencyType.VENDOR);

  isVendorCurrency = true;

  numberOfVendorCurrencies = 0;

  showEditButton$ = this.launchDarklyService.select$((flags) => flags.budget_edit_button);
  // only needed for Excel export
  isTotalHidden = true;

  defaultColumns: ((ColDef | ColGroupDef) & {
    hideForAllVendorSelection?: boolean;
    children?: ColDef[];
  })[] = [...rowGroupsColumnDef];

  columnDefs: (ColDef | ColGroupDef)[] = [];

  zeroHyphen = Utils.zeroHyphen;

  showSnapshotSection$ = this.launchDarklyService.select$(
    (flags) => flags.section_budget_snapshots
  );

  modelUpdated$ = new BehaviorSubject(false);

  modelUpdatedDebounced$ = new BehaviorSubject(false);

  isSnapShotSelected$ = new BehaviorSubject<{
    selected: boolean;
    currentLegend: boolean;
    snapShotLegend: boolean;
  }>({ selected: false, currentLegend: true, snapShotLegend: true });

  showGrid$ = new BehaviorSubject(false);

  vendorCurrencyEnabled$: Observable<boolean>;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPIBehavior$ = new BehaviorSubject<GridApi | undefined>(undefined);

  activitiesWithoutMapping: listActivitiesWithInvoiceMappingsQuery[] | null = null;

  postProcessPopup: (params: PostProcessPopupParams) => void = (params: PostProcessPopupParams) => {
    const columnId = params.column ? params.column.getId() : undefined;
    if (columnId === 'account' || columnId === 'dept' || columnId === 'po') {
      const ePopup = params.ePopup;
      let oldTopStr = ePopup.style.top;
      let oldLeftStr = ePopup.style.left;
      // remove 'px' from the string (AG Grid uses px positioning)
      oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'));
      oldLeftStr = oldLeftStr.substring(0, oldLeftStr.indexOf('px'));
      const oldTop = parseInt(oldTopStr);
      const oldLeft = parseInt(oldLeftStr);
      const newTop = oldTop + 39;
      const newLeft = oldLeft + 35;
      ePopup.style.top = newTop + 'px';
      ePopup.style.left = newLeft + 'px';
    }
  };

  autoGroupColumnDef: ColDef = {
    headerName: 'Activities',
    headerClass: 'activities-header',
    headerComponent: AgBudgetGroupHeaderComponent,
    headerComponentParams: {
      expandLevel: () => (this.selectedVendor.value ? -1 : 1),
      template: `Activities`,
      localStorageKey: BEAttributesLSKey,
      afterAttrToggle: () => this.loadBudgetGridData(),
    } as AgBudgetAttributeComponentParams,
    minWidth: 250,
    width: 250,
    field: 'activity_name',
    cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
    pinned: 'left',
    resizable: true,
    cellRenderer: AgBeCheckboxGroupRendererComponent,
    cellRendererParams: <ICheckboxCellRendererParams>{
      tooltipField: 'activity_name',
      checkboxTooltip: () => (this.selectedVendor.value ? '' : checkboxTooltip),
      checkboxContainerClass: 'pr-[10px]',
    },
    comparator: (_, __, nodeA, nodeB) => {
      if (!nodeA.aggData) {
        return 0;
      }
      return nodeA.aggData.current_lre - nodeB.aggData.current_lre;
    },
  };

  gridOptions: GridOptions = {
    suppressPropertyNamesCheck: true,
    tooltipShowDelay: 500,
    rowSelection: 'multiple',
    groupSelectsChildren: true,
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      cellClassRules: {
        [AuxExcelStyleKeys.BORDER_BOTTOM]: (params) => {
          return (
            !params.node.group &&
            params.node.lastChild &&
            !!params.node.parent?.lastChild &&
            this.isTotalHidden
          );
        },
      },
    },
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    suppressColumnVirtualisation: true,
    suppressCellFocus: true,
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    isRowSelectable: () => {
      return !!this.selectedVendor.value;
    },
    columnDefs: [],
    excelStyles: [
      ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
      ...AuxExcelStyles,
      {
        ...GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        id: 'trial_name',
        borders: {
          borderBottom: {
            color: 'black',
            lineStyle: 'Continuous',
            weight: 1,
          },
        },
      },
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#094673', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid' },
        alignment: { horizontal: 'Center' },
      },
      {
        id: 'budget-cost',
        dataType: 'Number',
        numberFormat: { format: AuxExcelFormats.Cost },
      },
      {
        id: 'budgetCostNoSymbol',
        dataType: 'Number',
        numberFormat: { format: AuxExcelFormats.Units },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
      {
        id: 'budget-percent',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: AuxExcelFormats.Percent },
      },
      {
        id: 'budget-percent-no-mult',
        dataType: 'Number',
        numberFormat: { format: AuxExcelFormats.PercentWithout100Mult },
      },
      {
        id: 'budget-units',
        alignment: { horizontal: 'Right' },
        numberFormat: { format: AuxExcelFormats.Units },
      },
      {
        id: 'total_row_header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      },
      {
        id: 'total_row',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        numberFormat: { format: AuxExcelFormats.Units },
      },
      {
        id: 'total_row_percent',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
        interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
        dataType: 'Number',
        numberFormat: { format: AuxExcelFormats.Percent },
      },
    ],
    getRowClass: (params: RowClassParams): string => {
      let childrenIndex;
      if (this.selectedVendor.value === '') {
        childrenIndex = Utils.getParentIndex(params.node);
      } else if (params.node.level === 1) {
        childrenIndex = params.node.childIndex;
      } else {
        childrenIndex = Utils.getParentIndex(params.node, 1);
        if (params.node.level >= 2) {
          const getParentNode = ({
            node,
            levelToReturn,
          }: {
            node?: IRowNode | null;
            levelToReturn: number;
          }): IRowNode => {
            if (node?.level === levelToReturn) {
              return node;
            }
            return getParentNode({ node: node?.parent, levelToReturn });
          };

          const node = getParentNode({ node: params.node, levelToReturn: 2 });
          childrenIndex = node.childIndex;
          const isEven = (node.parent?.childIndex || 0) % 2;
          if (isEven === 1) {
            return childrenIndex % 2
              ? TableConstants.STYLE_CLASSES.IS_ODD
              : TableConstants.STYLE_CLASSES.IS_EVEN;
          }
        }
      }

      return childrenIndex % 2
        ? TableConstants.STYLE_CLASSES.IS_EVEN
        : TableConstants.STYLE_CLASSES.IS_ODD;
    },
    getRowStyle: (params: RowClassParams) => {
      // total row
      if (params.node.level < 0) {
        return <RowStyle>{
          display: 'none',
        };
      }
      return {};
    },
  };

  compareToValue?: string = undefined;

  pendingChangesLoading = new BehaviorSubject(false);

  invoicesTotalLoading = new BehaviorSubject(false);

  wpLoading = new BehaviorSubject(false);

  showBudgetGraph = localStorage.getItem('showBudgetGraph')
    ? localStorage.getItem('showBudgetGraph') === 'true'
    : true;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name_label':
          return 490;
        case 'group0':
          return 280;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  periodTypes = [
    { label: 'Month', value: PeriodType.PERIOD_MONTH },
    { label: 'Quarter', value: PeriodType.PERIOD_QUARTER },
    { label: 'Year', value: PeriodType.PERIOD_YEAR },
  ];

  selectedPeriodType = new FormControl(
    this.launchDarklyService.flags$.getValue().client_preference_budget_period_type
  );

  gridAPI?: GridApi;

  gridOptions$ = new BehaviorSubject<GridOptions>(this.gridOptions);

  gridData$ = new BehaviorSubject<ExtendedBudgetData[]>([]);

  @ViewChildren('budgetFilters') budgetFilters!: QueryList<TemplateRef<unknown>>;

  showAnalyticsSection$: Observable<boolean>;

  showBudgetTypeSelect$: Observable<boolean>;

  isYearsOpen = false;

  isCustomOpen = false;

  areUnsavedChanges = false;

  highlightedCustom = new BehaviorSubject<number | null>(null);

  selectedYear!: string;

  selectedCustom$ = new BehaviorSubject('');

  selectedCustomIndex: number | null = null;

  customValues$ = new BehaviorSubject<(UserCustomView & { showLine: boolean })[] | null>(null);

  years: { enabled: boolean; label: number }[] = [];

  budgetGridYears: number[] | null = null;

  canvasRefresh$ = new Subject();

  canvasDatasets$ = new BehaviorSubject<ChartDataset<'bar', number[]>[]>([]);

  budgetCanvas$: Observable<ChartConfiguration<'bar', number[]>> = combineLatest([
    this.budgetQuery.select(['header_data', 'budget_data']),
    this.canvasRefresh$.pipe(startWith('')),
  ]).pipe(
    map(([state]) => {
      const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
      const columns = ['Services', 'Investigator', 'Pass-through'];

      const activeOrganizationId = this.organizationQuery.getActive()?.id;
      const groupedData = groupBy(
        activeOrganizationId
          ? state.budget_data?.filter((bd) => bd.vendor_id === activeOrganizationId)
          : state.budget_data,
        'cost_category'
      );
      const timelineHeaders = this.getTimelineHeaders(
        state.header_data.find((x) => x.group_name === 'Timeline')?.date_headers
      );

      const monthLabels = timelineHeaders.months.filter((str) => {
        if (!auxilius_start_date) {
          return true;
        }

        return dayjs(new Date(`01/${str.replace('-', '/').toUpperCase()}`)).isSameOrAfter(
          dayjs(auxilius_start_date).date(1)
        );
      });

      let xAxisPeriodLabels = monthLabels;
      const selectedPeriod = this.selectedPeriodType.value as PeriodType;
      if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
        xAxisPeriodLabels = timelineHeaders.quarters;
      } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
        xAxisPeriodLabels = timelineHeaders.years;
      }
      this.isSnapShotSelected$.next({
        selected: !!this.compareToValue,
        currentLegend: true,
        snapShotLegend: true,
      });

      const datasets: ChartDataset<'bar', BudgetChartData>[] = [];

      const colors: Partial<ChartDataset<'bar'>>[] = [
        'rgba(9, 91, 149, 1)',
        'rgba(9, 91, 149, 0.7)',
        'rgba(9, 91, 149, 0.4)',
        'rgba(35,98,98,1)',
        'rgba(35,98,98,0.7)',
        'rgba(35,98,98,0.4)',
      ].map((c) => {
        return {
          backgroundColor: c,
          borderColor: c,
        };
      });

      const setDataSet = (isSnapshot = false) => {
        datasets.push(
          {
            label: 'Services',
            data: xAxisPeriodLabels.map(() => {
              return { data: 0, discountData: 0 };
            }),
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
          },
          {
            label: 'Investigator',
            data: xAxisPeriodLabels.map(() => {
              return { data: 0, discountData: 0 };
            }),
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
          },
          {
            label: 'Pass-through',
            data: xAxisPeriodLabels.map(() => {
              return { data: 0, discountData: 0 };
            }),
            stack: !isSnapshot ? 'stack 0' : 'stack 1',
            type: 'bar',
            hidden: isSnapshot
              ? !this.isSnapShotSelected$.getValue().snapShotLegend
              : !this.isSnapShotSelected$.getValue().currentLegend,
          }
        );
        columns.map((amtType) => {
          if (groupedData[amtType]) {
            let amtTypeIndex = -1;
            datasets.forEach((item, i) => {
              if (item.label === amtType) {
                amtTypeIndex = i;
              }
            });
            let loopIndex = 0;
            for (const month of monthLabels) {
              let headerStrConversion = month;
              let timelinePeriods = monthLabels;
              if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
                headerStrConversion = `Q${dayjs(
                  this.parseBudgetMonthToDate(month)
                ).quarter()} ${dayjs(this.parseBudgetMonthToDate(month)).format('YYYY')}`;
                timelinePeriods = timelineHeaders.quarters;
              } else if (selectedPeriod === PeriodType.PERIOD_YEAR) {
                headerStrConversion = dayjs(this.parseBudgetMonthToDate(month)).format('YYYY');
                timelinePeriods = timelineHeaders.years;
              }

              const applyData = (arr: number[], index: number) => {
                arr.forEach((v, i) => {
                  datasets[index].data[i].discountData = v;
                });
              };
              const periodIndex = timelinePeriods.indexOf(headerStrConversion);
              if (!isSnapshot) {
                const n = decimalRoundingToNumber(
                  sumBy(groupedData[amtType], `EXPENSE_FORECAST_USD::${month}`) || 0,
                  2
                );

                const wp = decimalRoundingToNumber(
                  sumBy(groupedData[amtType], `EXPENSE_WP_USD::${month}`) || 0,
                  2
                );

                if (amtType === 'Services') {
                  const arr = this.getDiscountDataForCanvas(
                    xAxisPeriodLabels,
                    selectedPeriod,
                    groupedData,
                    isSnapshot
                  );
                  applyData(arr, amtTypeIndex);
                }

                if (periodIndex !== -1 && amtTypeIndex !== -1) {
                  const discountAmount = this.getDiscountAmountForCanvas(
                    amtType,
                    selectedPeriod,
                    datasets,
                    amtTypeIndex,
                    periodIndex,
                    loopIndex
                  );
                  datasets[amtTypeIndex].data[periodIndex].data += n + wp + discountAmount;
                }
              } else {
                if (amtType === 'Services') {
                  const arr = this.getDiscountDataForCanvas(
                    xAxisPeriodLabels,
                    selectedPeriod,
                    groupedData,
                    isSnapshot
                  );
                  applyData(arr, amtTypeIndex);
                }
                const n =
                  sumBy(groupedData[amtType], `EXPENSE_FORECAST_USD::${month}::SNAPSHOT`) || 0;
                const wp = sumBy(groupedData[amtType], `EXPENSE_WP_USD::${month}::SNAPSHOT`) || 0;

                if (periodIndex !== -1 && amtTypeIndex !== -1) {
                  const discountAmount = this.getDiscountAmountForCanvas(
                    amtType,
                    selectedPeriod,
                    datasets,
                    amtTypeIndex,
                    periodIndex,
                    loopIndex
                  );

                  if (selectedPeriod === PeriodType.PERIOD_MONTH) {
                    datasets[amtTypeIndex].data[periodIndex].data += decimalRoundingToNumber(
                      /*
                        In snapshot.service getMonthsSnapshotActuals FORECAST numbers are turned into WP numbers,
                        so for the purposes of the monthly graph, we only need one of them
                      */
                      (wp || n) + discountAmount,
                      2
                    );
                  } else {
                    datasets[amtTypeIndex].data[periodIndex].data += decimalRoundingToNumber(
                      n + wp + discountAmount,
                      2
                    );
                  }
                }
              }
              loopIndex += 1;
            }
          }
        });
      };

      setDataSet();
      if (this.compareToValue) {
        setDataSet(true);
      }

      const datasets2 = datasets.map((x, i) => {
        return {
          ...x,
          ...colors[i],
          data: x.data.map((y) => y.data),
        };
      });

      this.canvasDatasets$.next(datasets2);
      return <ChartConfiguration<'bar', number[]>>{
        type: 'bar',
        data: {
          labels: xAxisPeriodLabels,
          datasets: datasets2,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              axis: 'y',
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => {
                  return Utils.currencyFormatter(value as number, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title(item) {
                  return item.map((x) => x.dataset.label || '');
                },
                label: (tooltipItem) => {
                  const dataset = tooltipItem.dataset;
                  const yLabel = tooltipItem.parsed.y;
                  if (dataset.label === 'Services') {
                    const rawDataset = datasets[tooltipItem.datasetIndex];

                    const discountAmount =
                      rawDataset?.data[tooltipItem.dataIndex].discountData || 0;
                    const discountAmountFormatted = Utils.currencyFormatter(discountAmount, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });

                    // for the tooltip, we want to show to original value of services so we subtract the discounted amount since the yLabel is services + discount
                    const servicesAmount = Utils.currencyFormatter(yLabel - discountAmount, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });

                    const totalAmount = Utils.currencyFormatter(yLabel, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    });

                    return `${totalAmount} - Services: ${servicesAmount}, Discount: ${discountAmountFormatted}`;
                  }

                  return Utils.currencyFormatter(yLabel, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
            },
            legend: {
              display: !this.isSnapShotSelected$.getValue().selected,
            },
          },
        },
      };
    })
  );

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  userHasEditPermissions = false;

  editButtonTooltips = {
    allVendor: 'Select a vendor to edit',
    emptyTable: 'First budget for vendor must be created via template.',
    unauthorized: 'Not permissioned to Edit Budget',
    noTimeline: MessagesConstants.TIMELINE_MUST_BE_ENTERED,
  };

  isChangeOrdersWorkflowLocked = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS
  );

  refreshGrid$ = new Subject();

  actualsToDateFeatureFlag = false;

  constructor(
    private budgetStore: BudgetStore,
    private budgetService: BudgetService,
    private budgetGridService: BudgetGridService,
    public budgetQuery: BudgetQuery,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private cdr: ChangeDetectorRef,
    private launchDarklyService: LaunchDarklyService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private eventService: EventService,
    private mainQuery: MainQuery,
    private snapshotService: SnapshotService,
    public authService: AuthService,
    private HeaderDropdownService: BudgetEnhancedHeaderDropdownService,
    private route: ActivatedRoute,
    private router: Router,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private eventTrackerService: EventTrackerService,
    private eventQuery: EventQuery
  ) {
    this.budgetStore.setLoading(true);

    this.setUserPermissions();

    this.selectedVendor.valueChanges.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.activitiesModal.vendor_id.set(v || '');
      localStorage.setItem('selectedVendor', v || '');
      this.cdr.markForCheck();
    });

    effect(() => {
      const isProcessing = this.eventQuery.selectProcessingEvent(
        EventType.INLINE_EDIT_BUDGET_ACTIVITIES
      )();

      const isBudgetUploadProcessing = this.eventQuery.selectProcessingEvent(
        EventType.BUDGET_TEMPLATE_UPLOADED
      )();

      if (isProcessing === false || isBudgetUploadProcessing === false) {
        this.activitiesModal.refreshCategories();
        this.refreshGrid$.next(true);
      }
    });

    this.vendorCurrencyEnabled$ = launchDarklyService.select$((flags) => {
      return flags.vendor_currency;
    });

    this.snapshotService.getSnapshotList().pipe(takeUntilDestroyed()).subscribe();

    this.compareToValue = undefined;

    this.gqlService
      .getTrialPreference$(TrialPreferenceType.BUDGET_GRID_YEARS)
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(isEqual),
        tap((prefBudgetGridYears) => {
          this.budgetGridYears = prefBudgetGridYears?.data?.value
            ? (JSON.parse(prefBudgetGridYears?.data?.value) as Array<number>)
            : null;
        }),
        switchMap(() => {
          this.selectedCustom$.next('');

          return this.listCustomUserView();
        })
      )
      .subscribe();

    this.budgetQuery
      .selectLoading()
      .pipe(takeUntilDestroyed())
      .subscribe((loading) => {
        if (!loading) {
          this.loadBudgetGridData();
        }
      });

    combineLatest([this.mainQuery.select('trialKey'), this.gridAPI$.pipe(take(1), startWith(null))])
      .pipe(
        switchMap(() => this.launchDarklyService.select$((flags) => flags.budget_unit_of_measure))
      )
      .pipe(takeUntilDestroyed())
      .subscribe((bool) => {
        uomHide$.next(!bool);
        if (this.gridAPI) {
          AgSetColumnsVisible({
            gridApi: this.gridAPI,
            keys: ['uom'],
            visible: bool,
          });
        }
      });

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_budget_analytics
    );

    this.showBudgetTypeSelect$ = launchDarklyService.select$((flags) => flags.section_budget_type);

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.pendingChangesLoading.next(true);
            this.wpLoading.next(true);
            this.invoicesTotalLoading.next(true);

            return rxMerge(
              this.budgetService.getPendingChanges().pipe(
                tap(() => {
                  this.pendingChangesLoading.next(false);
                })
              ),
              this.budgetService.getBudgetWorkPerformed().pipe(
                tap(() => {
                  this.wpLoading.next(false);
                })
              ),
              this.budgetService.getInvoicesTotal().pipe(
                tap(() => {
                  this.invoicesTotalLoading.next(false);
                })
              )
            );
          }
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    // reset any older selected vendors.
    this.organizationStore.setActive(null);

    this.listCustomUserView();

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_BUDGET],
      })
      .pipe(takeUntilDestroyed())
      .subscribe((x) => {
        this.userHasEditPermissions = x;
      });
  }

  getEditButtonTooltip(
    userHasEditPermissions: boolean,
    timelineExist: boolean,
    isChangeOrdersWorkflowLocked: boolean
  ) {
    if (!userHasEditPermissions) {
      return this.editButtonTooltips.unauthorized;
    }

    if (this.selectedVendor.value === '') {
      return this.editButtonTooltips.allVendor;
    }

    if (!timelineExist) {
      return this.editButtonTooltips.noTimeline;
    }

    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    if (this.gridData$.getValue().length === 0) {
      return this.editButtonTooltips.emptyTable;
    }

    return '';
  }

  static agCurrencyFormatter(val: ValueFormatterParams) {
    if (val.data) {
      if (val.data.expense_note && (val.colDef.field || '').indexOf('direct_cost') >= 0) {
        return val.data.expense_note;
      }
    }

    if (val.value) {
      if (!Number.isNaN(val.value)) {
        return Utils.currencyFormatter(val.value);
      }
    }

    return Utils.zeroHyphen;
  }

  onTrackerIdChanged(trackerId: string) {
    this.progressTrackerId.set(trackerId);
    if (!trackerId) {
      this.refreshGrid$.next(true);
    }
  }

  ngOnInit() {
    this.selectedBudgetCurrencyType$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => {
      this.isVendorCurrency = x === BudgetCurrencyType.VENDOR;
    });

    // Subscribing to modelUpdated changes
    // so that cellRenderers have a way to "refresh",
    // even if agInit isn't called.
    // This is required for supporting auto-qa attributes
    // on tables with grouped rows.
    this.modelUpdatedListener();

    this.actualsToDateFeatureFlag = this.route.snapshot.data['feature'];

    this.organizationQuery
      .selectActive()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vendor) => {
        if (vendor) {
          this.setInvoicesWithoutMapping(vendor.id);
        }
      });

    combineLatest([this.organizationQuery.selectActive(), this.budgetQuery.select('budget_type')])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(0))
      .subscribe(() => {
        // each of the observables above will trigger a budget reloading,
        // so we need to hard refresh the ag-grid to fix the total row issue
        // if we keep the showGrid as true when we call the loadBudgetGridData method
        // we can still see the old grid in the html. Method will refresh the grid itself but
        // this will cause the grid to blink so to fix that
        // I'm setting this variable false here
        this.showGrid$.next(false);
      });

    this.selectedPeriodType.valueChanges
      .pipe(startWith(this.selectedPeriodType.value))
      .subscribe(() => {
        this.budgetStore.update({ ...this.budgetQuery.getValue() });
        this.canvasRefresh$.next(null);
      });

    this.selectedPeriodType.patchValue(PeriodType.PERIOD_MONTH);

    this.refreshGrid$
      .pipe(startWith(true))
      .pipe(
        switchMap(() => {
          return this.organizationService.getListWithTotalBudgetAmount().pipe(
            tap(async (vendorsWithBudget) => {
              const vendors = this.organizationQuery.getAllVendors();
              const { vendorId, modalVendorId } = await firstValueFrom(this.route.queryParams);
              if (vendors.length === 1) {
                this.organizationStore.setActive(vendors[0].id);
                this.selectedVendor.setValue(vendors[0].id);
              } else {
                this.numberOfVendorCurrencies = uniq(
                  vendorsWithBudget.data
                    ?.filter(
                      (x) =>
                        x.organization_type === OrganizationType.ORGANIZATION_VENDOR &&
                        x.current_budget_versions.length > 0
                    )
                    .map((x) => x.currency)
                ).length;
                // reset any older selected vendors.
                const selectedVendorId = localStorage.getItem('selectedVendor');
                const anyEditActivities = localStorage.getItem('anyEditActivities');
                if (selectedVendorId && anyEditActivities) {
                  this.organizationStore.setActive(selectedVendorId);
                  this.selectedVendor.setValue(selectedVendorId);
                  localStorage.removeItem('anyEditActivities');
                } else {
                  const targetVendorId = modalVendorId || vendorId;
                  this.organizationStore.setActive(targetVendorId || null);
                  this.selectedVendor.setValue(targetVendorId || '');
                }
              }
              if (modalVendorId) {
                const ref = this.activitiesModal.openModal({
                  rows: [],
                  budgetData: this.gridData$.getValue(),
                  isActivityTabVisible: true,
                  uomHide: uomHide$.getValue(),
                  editMode: false,
                  vendorId: modalVendorId,
                });
                if (ref) {
                  this.onModalClose(ref);
                }
                this.router.navigate([], {
                  queryParams: { modalVendorId: null },
                  queryParamsHandling: 'merge',
                });
              }
            })
          );
        })
      )
      .pipe(
        switchMap(() => {
          return this.budgetService.getBudgetDataForEBGV2();
        }),
        switchMap(() => {
          if (this.compareToValue) {
            return this.snapshotService.getBudgetSnapshots(this.compareToValue);
          }

          return of();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.workflowService.getWorkflowList().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  onVendorSelected(vendorId: string) {
    this.organizationStore.setActive(vendorId || null);
  }

  onDataRendered(e: FirstDataRenderedEvent) {
    // only show the total row if toggled to USD, a specific vendor is selected, or there is only one currency
    this.gridAPI$.next(e.api);
    this.gridAPI = e.api;
    if (
      this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
      this.selectedVendor.value !== '' ||
      this.numberOfVendorCurrencies === 1
    ) {
      this.setBottomTotalRow(this.gridAPI);
    } else {
      this.gridAPI?.setGridOption('pinnedBottomRowData', []);
      this.isTotalHidden = true;
    }
    this.onSelect();
  }

  setBottomTotalRow(gridApi: GridApi) {
    this.isTotalHidden = false;
    const displayColumns = {
      ...gridApi?.getDisplayedRowAtIndex(gridApi?.getDisplayedRowCount() - 1)?.aggData,
      po_value: 0,
      dept_value: 0,
      account_value: 0,
    };

    Object.keys(displayColumns).forEach((key) => {
      const varPercSuffix = 'VAR_PERC::SNAPSHOT';
      if (key.includes('::VAR_COST::SNAPSHOT') && displayColumns[key]) {
        const keySetPrefix = key.split('::VAR_COST::SNAPSHOT')[0];
        const varPercKey = `${keySetPrefix}::${varPercSuffix}`;
        displayColumns[varPercKey] = displayColumns[`${keySetPrefix}::SNAPSHOT`]
          ? decimalDivide(
              displayColumns[`${keySetPrefix}::VAR_COST::SNAPSHOT`],
              displayColumns[`${keySetPrefix}::SNAPSHOT`],
              4
            )
          : 0;
      }
    });

    displayColumns.var_percent = decimalMultiply(
      decimalDivide(displayColumns.var_amount, displayColumns.baseline, 4),
      100,
      4
    );

    const budgetSum = decimalAdd(displayColumns.wp_cost || 0, displayColumns.remaining_cost || 0);

    displayColumns.wp_percentage = decimalMultiply(
      decimalDivide(displayColumns.wp_cost, budgetSum),
      100,
      2
    );

    displayColumns.remaining_percentage = decimalDifference(100, displayColumns.wp_percentage, 2);

    gridApi?.setGridOption('pinnedBottomRowData', [
      merge(
        {
          activity_name: 'Total',
        },
        displayColumns
      ),
    ]);
  }

  onGridReady(event: GridReadyEvent) {
    this.gridAPIBehavior$.next(event.api);
    this.toggleCategoriesByRouteParams();
  }

  onFilterChanged(e: FilterChangedEvent) {
    this.setBottomTotalRow(e.api);
  }

  onBudgetUploadClick(vendorId?: string) {
    this.overlayService.openPopup<
      { vendorId?: string; onSuccess: (processEventId: string) => void },
      unknown,
      BudgetUploadComponent
    >({
      content: BudgetUploadComponent,
      settings: {
        header: 'Upload Budget',
        primaryButton: {
          label: 'Upload Budget',
          action: (instance) => instance?.onUpload(),
          disabled: (instance) => !!instance?.uploadDisabled,
        },
      },
      data: {
        vendorId,
        onSuccess: (processEventId: string) => {
          this.progressTrackerId.set(processEventId);
        },
      },
    });
  }

  findFirstExpenseForecastColId(
    columns: Array<ColDef | ColGroupDef> | undefined
  ): string | undefined {
    if (!columns) {
      return undefined;
    }

    for (const column of columns) {
      if ('colId' in column && column.colId && column.colId.includes('EXPENSE_FORECAST')) {
        return column.colId;
      } else if (this.isColGroupDef(column) && column.children) {
        const colId = this.findFirstExpenseForecastColId(column.children);
        if (colId) {
          return colId;
        }
      }
    }

    return undefined;
  }

  async toggleCategoriesByRouteParams() {
    const { toggle } = await firstValueFrom(this.route.queryParams);

    if (!toggle) return;

    if (this.gridAPI) {
      this.gridAPI.forEachNode((node) => {
        if (
          node.level === 0
            ? true
            : !!node.key && node.key.toLowerCase().includes(toggle) && node.level === 1
        ) {
          node.expanded = true;
        }
      });
      this.gridAPI.onGroupExpandedOrCollapsed();
    }

    // Clear router params
    this.router.navigate([], {
      queryParams: {
        scrollTo: null,
        vendorId: null,
        toggle: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  async scrollToColumnByRouteParams() {
    const { scrollTo } = await firstValueFrom(this.route.queryParams);

    if (!scrollTo) return;

    if (scrollTo === 'forecast') {
      const gridInstance = this.gridAPIBehavior$.getValue();
      if (gridInstance) {
        const allColumns = gridInstance.getColumnDefs();
        const forecastColId = this.findFirstExpenseForecastColId(allColumns);
        if (forecastColId) {
          this.gridAPIBehavior$.getValue()?.ensureColumnVisible(forecastColId, 'start');
        }
      }
    } else {
      this.gridAPIBehavior$.getValue()?.ensureColumnVisible(scrollTo, 'start');
    }

    // Clear router params
    this.router.navigate([], {
      queryParams: {
        scrollTo: null,
        vendorId: null,
        toggle: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  async onBudgetExportClick() {
    const vendorName = this.organizationQuery.getActive()?.name;

    if (!vendorName && !this.columnDefs.find((cd) => cd.headerName === 'Cost Category')) {
      this.columnDefs.splice(1, 0, {
        headerName: 'Cost Category',
        field: 'cost_category',
        rowGroup: true,
        hide: true,
      });

      this.gridAPI?.setGridOption('columnDefs', this.columnDefs);
    }

    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const hasView = false;
    const viewName = hasView ? '_VIEWHERE' : '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');
    const fileName = vendorName
      ? `${trialName}_${vendorName}${viewName}_Total Budget_${dateStr}.xlsx`
      : `${trialName}${viewName}_Total Budget_${dateStr}.xlsx`;

    const totalData = this.gridAPI?.getPinnedBottomRow(0)?.data;

    const columnKeys = this.budgetExportColumnIDs().filter(
      (key) =>
        key !== 'EXPENSE_QUOTE::LATEST' &&
        !key.startsWith('spacerColumn') &&
        key !== 'contract_direct_cost_currency'
    );

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: {
              value: 'Total',
              type: 'String',
            },
            styleId: [
              'total_row_header',
              AuxExcelStyleKeys.BORDER_LEFT,
              AuxExcelStyleKeys.BORDER_BOTTOM,
            ],
          },
        ],
      },
    ];

    let totalRowStyleId = 'total_row';
    if (!this.isVendorCurrency) {
      totalRowStyleId = 'total_row_USD';
    }
    if (this.selectedVendor.value && this.isVendorCurrency) {
      const orgCurrency = this.organizationQuery.getActive()?.currency;
      if (orgCurrency) {
        totalRowStyleId = `total_row_${orgCurrency}`;
      }
    }
    if (this.numberOfVendorCurrencies === 1) {
      const orgCurrency = this.organizationQuery.getAllVendors()[0].currency;
      if (orgCurrency) {
        totalRowStyleId = `total_row_${orgCurrency}`;
      }
    }

    const vendorsColumns = ['display_label', 'group0', 'activity_name_label'];

    [
      'activity_id',
      'cost_category',
      ...vendorsColumns,
      ...(this.gridAPI
        ?.getAllDisplayedColumns()
        .map((col) => col.getColId())
        .filter(
          (colId) => !colId.startsWith('ag-Grid-AutoColumn') && !colId.startsWith('spacerColumn')
        ) || []),
    ].forEach((colId) => {
      const isPercentCol = [
        'var_percent',
        'wp_percentage',
        'remaining_percentage',
        '::VAR_PERC::SNAPSHOT',
        '::VAR_PERC',
      ].some((col) => !!colId.match(col));
      let safeValue = 0;
      if (
        this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
        this.selectedVendor.value ||
        this.numberOfVendorCurrencies === 1
      ) {
        safeValue = ![
          'uom',
          'unit_num',
          'unit_cost',
          'contract_unit_cost',
          'remaining_unit_num',
          'wp_unit_num',
        ].includes(colId)
          ? totalData[colId] || 0
          : 0;
      }
      const divider = colId.includes('::VAR_PERC') ? 1 : 100;
      const value = isPercentCol ? decimalDivide(safeValue, divider) : safeValue;

      appendContent[0].cells.push({
        data: { value: `${value}`, type: 'Number' },
        styleId: [
          isPercentCol ? 'total_row_percent' : totalRowStyleId,
          AuxExcelStyleKeys.BORDER_BOTTOM,
          colId && this.isColumnHasStyleClass(colId, AuxExcelStyleKeys.BORDER_RIGHT)
            ? AuxExcelStyleKeys.BORDER_RIGHT
            : '',
          colId && this.isColumnHasStyleClass(colId, AuxExcelStyleKeys.BORDER_LEFT)
            ? AuxExcelStyleKeys.BORDER_LEFT
            : '',
        ],
      });
    });

    const exportOptions: ExcelExportParams = {
      ...this.excelOptions,
      columnKeys,
      fileName,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const coldId = params.column.getColId();
        const costCategory = params.node?.data.cost_category;

        if (coldId.includes('unit') && costCategory === 'Discount') {
          return '0';
        }

        if (coldId.includes('uom') && !params.value) {
          return Utils.zeroHyphen;
        }

        const isPercentColumn = ['wp_percentage', 'remaining_percentage'].some((key) =>
          coldId.endsWith(key)
        );

        if (isPercentColumn) {
          return `${params.value / 100}`;
        }

        if (coldId.endsWith('VAR_COST') && isNaN(params.value)) {
          return '0';
        }

        return params.value;
      },
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trialName}`, type: 'String' },
              mergeAcross: appendContent[0].cells.length - 1,
              styleId: 'trial_name',
            },
          ],
        },
      ],
      appendContent:
        this.selectedBudgetCurrencyType$.getValue() === BudgetCurrencyType.USD ||
        this.selectedVendor.value ||
        this.numberOfVendorCurrencies === 1
          ? appendContent
          : [],
    } as ExcelExportParams;

    this.gridAPI?.exportDataAsExcel(exportOptions);
  }

  private isColumnHasStyleClass(colId: string, className: string): boolean {
    const headerClass = this.gridAPI?.getColumn(colId)?.getColDef().headerClass;

    if (headerClass) {
      if (isArray(headerClass)) {
        return headerClass.includes(className);
      }

      if (headerClass === className) {
        return true;
      }
    }

    return false;
  }

  async onColumnChooser() {
    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        { columns?: VisibleColumns },
        VisibleColumns,
        ColumnChooserComponent
      >({
        content: ColumnChooserComponent,
        settings: {
          header: 'Customize Budget View',
          primaryButton: {
            label: 'Confirm',
            action: (instance) => instance?.onConfirm(),
          },
        },
        data: { columns: JSON.parse(JSON.stringify(this.visibleColumns)) },
      }).afterClosed$
    );

    if (resp.data) {
      this.visibleColumns = resp.data;
      this.loadBudgetGridData(true);
      this.areUnsavedChanges = true;
      this.selectedCustom$.next('');
    }
  }

  closeList() {
    this.isYearsOpen = false;
  }

  closeCustomList() {
    this.isCustomOpen = false;
  }

  openList() {
    this.isYearsOpen = true;
  }

  highlightCustom(index: number): void {
    this.highlightedCustom.next(index);
  }

  openCustomList() {
    this.isCustomOpen = true;

    if (this.selectedCustom$.getValue() != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }

    if (this.selectedCustomIndex != null) {
      this.highlightedCustom.next(this.selectedCustomIndex);
    } else {
      this.highlightedCustom.next(0);
    }
    this.cdr.detectChanges();
  }

  yearChanged($event: boolean, label: number) {
    const year = this.years.find((el) => el.label === label);
    if (year) {
      year.enabled = $event;
    }
    this.saveBudgetYears();
    this.loadBudgetGridData(true);
    this.setSelectedYear();
  }

  customChanges(item: Omit<UserCustomView, '__typename' | 'id' | 'is_custom'>) {
    const index = this.highlightedCustom.getValue();
    if (index != null || item) {
      if (this.selectedCustom$.getValue() !== item.name) {
        this.selectedCustom$.next(item.name);
        this.gridData$.next([]);
        this.gridAPI?.showLoadingOverlay();
        const customValues = this.customValues$.getValue();
        const selectedIndex = customValues?.findIndex((x) => x.name === item.name) ?? -1;
        if (customValues && selectedIndex !== -1) {
          const selData = customValues[selectedIndex];
          const activeTrial = this.mainQuery.getSelectedTrial()?.id;
          try {
            const localItem = localStorage.getItem(`customView`) || '{}';
            if (activeTrial) {
              const letItem = { ...JSON.parse(localItem) };
              letItem[activeTrial] = { id: selData.id, name: selData.name };
              localStorage.setItem(`customView`, JSON.stringify(letItem));
            }
          } catch (e) {
            console.error(e);
          }
          this.selectedCustomIndex = selectedIndex;
          const visCol = JSON.parse(JSON.parse(selData?.metadata));
          if (Object.prototype.hasOwnProperty.call(visCol, 'overall_budget')) {
            this.visibleColumns = visCol;
            this.gridAPI?.showLoadingOverlay();
            setTimeout(() => {
              this.loadBudgetGridData(true);
              setTimeout(() => {
                this.gridAPI?.hideOverlay();
              }, 500);
            }, 0);
          }
        }
      } else {
        this.selectedCustomIndex = index;
      }
    }
    this.closeCustomList();
    this.gridAPI?.hideOverlay();
  }

  async editCustom(item: UserCustomView & { showLine: boolean }) {
    const overlay = await firstValueFrom(
      this.overlayService.openPopup<
        { columns?: VisibleColumns },
        VisibleColumns,
        ColumnChooserComponent
      >({
        content: ColumnChooserComponent,
        settings: {
          header: 'Customize Budget View',
          primaryButton: {
            label: 'Confirm',
            action: (instance) => instance?.onConfirm(),
          },
        },
        data: { columns: JSON.parse(JSON.parse(item.metadata)) },
      }).afterClosed$
    );

    const resp = this.overlayService.openPopup<
      unknown,
      { label?: string },
      BudgetCustomUpdateComponent
    >({
      content: BudgetCustomUpdateComponent,
      settings: {
        header: 'Name Custom View',
        primaryButton: {
          label: 'Confirm',
          action: (instance) => instance?.saveText(),
        },
      },
      data: {
        textName: item.name,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.label && overlay.data) {
      const flag = await this.budgetService.updateUserCustomView({
        id: item.id,
        name: event.data.label,
        metadata: JSON.stringify(overlay.data),
      } as UpdateUserCustomViewInput);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...item, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.overlayService.success();
      }
    }
  }

  refreshTable = () => {
    this.loadBudgetGridData(true);
  };

  compareDropdownChange(value: string) {
    this.compareToValue = value;
    this.HeaderDropdownService.resetGroupColumnChanges();
    if (value === null) {
      this.refreshGrid$.next(true);
    }
  }

  async saveCustomUserView() {
    const user = await this.authService.getLoggedInUser();
    const resp = this.overlayService.openPopup<
      unknown,
      { label?: string },
      BudgetCustomCreateComponent
    >({
      content: BudgetCustomCreateComponent,
      settings: {
        header: 'Name Custom View',
        primaryButton: {
          label: 'Save',
          action: (instance) => instance?.saveText(),
        },
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.label) {
      const data: CreateUserCustomViewInput = {
        name: event.data.label,
        user_id: user?.getSub() || '',
        metadata: JSON.stringify(this.visibleColumns),
        view_location: ViewLocation.VIEW_LOCATION_BUDGET_GRID,
      };
      const flag = await this.budgetService.saveUserCustomView(data);
      if (flag) {
        await this.listCustomUserView();
        this.customChanges({ ...data, name: event.data.label });
        setTimeout(() => {
          this.loadBudgetGridData(true);
        }, 0);
        this.areUnsavedChanges = false;
        this.overlayService.success();
      }
    }
  }

  async removeCustom(item: UserCustomView) {
    this.overlayService.openPopup<
      { message: string },
      boolean | undefined,
      ConfirmationModalComponent
    >({
      content: ConfirmationModalComponent,
      data: {
        message: `Are you sure you want to remove ${item?.name}?`,
      },
      settings: {
        header: 'Remove Custom View',
        primaryButton: {
          label: 'Remove',
          action: async (instance) => {
            instance?.ref.close();
            const response = await this.budgetService.removeUserCustomView(item.id);
            if (response) {
              await this.listCustomUserView();
              setTimeout(() => {
                this.loadBudgetGridData(true);
              }, 0);
              this.overlayService.success();
            }
          },
        },
      },
    });
  }

  // The modelUpdated event might
  // be called many times in succession.
  // This listener will limit those calls so that
  // cellRenderers receive the latest event only.
  modelUpdatedListener(): void {
    this.modelUpdated$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(250))
      .subscribe((modelUpdated) => this.modelUpdatedDebounced$.next(modelUpdated));
  }

  modelUpdated(): void {
    this.modelUpdated$.next(true);
  }

  private parseBudgetMonthToDate(period: string) {
    return dayjs(`01/${period.replace('-', '/')}`);
  }

  private currentQuarter(current_period: string): string {
    const date = this.parseBudgetMonthToDate(current_period);
    return `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
  }

  private currentYear(current_period: string): string {
    return `${this.parseBudgetMonthToDate(current_period).year()}`;
  }

  private sortingForDefault(
    customValues: (UserCustomView & {
      showLine: boolean;
    })[]
  ) {
    const data: (UserCustomView & {
      showLine: boolean;
    })[] = [];
    const nCustomValues = customValues.filter((x) => x.is_custom);
    customValues.forEach((x) => {
      if (x.is_custom) {
        return;
      }
      switch (x.name) {
        case 'Monthly':
          data[0] = { ...x, showLine: true };
          break;
        case 'Quarterly':
          data[1] = x;
          break;
        case 'Yearly':
          data[2] = x;
          break;
        case 'Historical Only - Months':
          data[3] = x;
          break;
        case 'Historical Only - Quarters':
          data[4] = x;
          break;
        case 'Historical Only - Years':
          data[5] = x;
          break;
        case 'Forecast Only - Months':
          data[6] = x;
          break;
        case 'Forecast Only - Quarters':
          data[7] = x;
          break;
        case 'Forecast Only - Years':
          data[8] = x;
          break;
        default:
          break;
      }
    });
    return [...nCustomValues, ...data];
  }

  private async listCustomUserView() {
    const data = (await this.budgetService.listUserCustomView()) as (UserCustomView & {
      showLine: boolean;
    })[];
    if (data) {
      this.customValues$.next([]);
      const sortingForDefault = this.sortingForDefault(data);
      const sortData = sortingForDefault.sort((x, y) => {
        if (x.is_custom && y.is_custom) {
          return Utils.alphaNumSort(x.name.toUpperCase(), y.name.toUpperCase());
        }
        return 0;
      });
      this.customValues$.next(sortData);
      try {
        const activeTrial = this.mainQuery.getSelectedTrial()?.id;
        const localItem = localStorage.getItem(`customView`);
        const localView = localItem && JSON.parse(localItem);
        if (localView && activeTrial && localView[activeTrial]) {
          const localIndex = sortData.findIndex((x) => x.name === localView[activeTrial].name);
          if (localIndex !== -1) {
            this.highlightedCustom.next(localIndex);
            this.selectedCustomIndex = localIndex;
            this.selectedCustom$.next(sortData[localIndex].name);
            this.visibleColumns = JSON.parse(JSON.parse(sortData[localIndex].metadata));
          } else {
            if (localItem != null) {
              const remItem = JSON.parse(localItem);
              delete remItem[activeTrial];
              localStorage.setItem(`customView`, JSON.stringify(remItem));
            }

            this.defaultChooserSelection(sortData);
          }
        } else {
          this.defaultChooserSelection(sortData);
        }
      } catch {
        this.defaultChooserSelection(sortData);
      }
    }
  }

  private defaultChooserSelection(
    data: (UserCustomView & {
      showLine: boolean;
    })[]
  ) {
    const indexV = data.findIndex((x) => x.name === 'Monthly');
    if (indexV !== -1) {
      this.selectedCustom$.next(data[indexV].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[indexV].metadata));
      this.highlightedCustom.next(indexV);
      this.selectedCustomIndex = indexV;
    } else {
      this.selectedCustom$.next(data[0].name);
      this.visibleColumns = JSON.parse(JSON.parse(data[0].metadata));
      this.highlightedCustom.next(0);
      this.selectedCustomIndex = 0;
    }
  }

  private async saveBudgetYears() {
    const years = [...this.years].reduce((acc: number[], el) => {
      if (!acc.includes(el.label) && el.enabled) {
        acc.push(el.label);
      }
      return acc;
    }, []);

    await firstValueFrom(
      this.gqlService.setTrialPreference$({
        preference_type: TrialPreferenceType.BUDGET_GRID_YEARS,
        value: JSON.stringify(years),
      })
    );

    this.budgetGridYears = years;
  }

  private setSelectedYear() {
    let numberOfYearsEnabled = 0;
    this.years.forEach((year) => {
      if (year.enabled) {
        numberOfYearsEnabled += 1;
      }
    });
    if (numberOfYearsEnabled === 0) {
      this.selectedYear = 'None';
    } else if (numberOfYearsEnabled < this.years.length) {
      this.selectedYear = `${numberOfYearsEnabled} Selected`;
    } else {
      this.selectedYear = 'All';
    }
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return (col as ColDef).colId !== undefined;
  }

  private isColGroupDef(column: ColDef | ColGroupDef): column is ColGroupDef {
    return 'children' in column;
  }

  private budgetExportColumnIDs() {
    let colIds = [] as string[];

    (this.gridAPI?.getColumnDefs() || []).forEach((columnDef) =>
      colIds.push(...this.getColumnIds(columnDef))
    );
    colIds = colIds.filter(
      (ci) =>
        ci !== 'group1' &&
        ci !== 'group2' &&
        ci !== 'group3' &&
        ci !== 'group4' &&
        ci !== 'group5' &&
        isNaN(Number(ci)) // Filter spacing rows
    );
    return colIds;
  }

  private getColumnIds(def: ColDef | ColGroupDef) {
    const colIds = [] as string[];
    let str = '';
    const disallowed_types = ['EXPENSE_WP::TO_DATE'];
    if (this.isColDef(def)) {
      str = def.colId || '';
      if (disallowed_types.indexOf(str) === -1) {
        colIds.push(str);
      }
    }

    if ((def as ColGroupDef).children !== undefined && disallowed_types.indexOf(str) === -1) {
      (def as ColGroupDef).children.forEach((child) => {
        if (!this.isColDef(child) || !child.hide) {
          colIds.push(...this.getColumnIds(child));
        }
      });
    }
    return colIds;
  }

  private getVarCost(actuals: number, plan: number): number {
    return actuals - plan;
  }

  private getVarPerc(varCost: number, plan: number): number {
    return plan ? round(varCost / plan, 2) : 0;
  }

  private loadBudgetGridData(refresh = false) {
    this.gridAPI = undefined;
    this.showGrid$.next(false);
    const { budget_data, header_data } = this.budgetQuery.getValue();

    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
    const activeOrganizationId = this.organizationQuery.getActive()?.id;
    const [aggregated_budget_data, aggregated_header_data] = this.aggregateQuartersAndYears(
      (activeOrganizationId
        ? budget_data?.filter((bd) => bd.vendor_id === activeOrganizationId)
        : budget_data) || [],
      header_data
    );

    const attributes = getAttributeColumns({
      attributes: aggregated_budget_data.map((z) => z.attributes || []),
    });

    const defs: (ColDef | ColGroupDef)[] = [];

    const attr = attributeColumnDef(attributes, BEAttributesLSKey, true);

    defs.push(attr);

    defs.push(TableConstants.SPACER_COLUMN);

    defs.push(
      overallBudgetColumnDef(
        this.visibleColumns.overall_budget,
        this.selectedBudgetCurrencyType$.getValue(),
        this.compareToValue
      )
    );

    const el = (aggregated_header_data as RequireSome<BudgetHeader, 'date_headers'>[]).find(
      (x) => x.group_name === 'Work Performed'
    );
    const forecastHeader = (
      aggregated_header_data as RequireSome<BudgetHeader, 'date_headers'>[]
    ).find((x) => x.group_name === 'Forecast');

    if (!refresh) {
      const hYears = (el?.date_headers || []).reduce((acc: number[], col_header) => {
        const headerName = col_header.split('-').pop();
        if (headerName !== '' && !acc.includes(Number(headerName))) {
          acc.push(Number(headerName));
        }
        return acc;
      }, []);
      if (Array.isArray(this.budgetGridYears)) {
        this.years = hYears.map((year) => {
          return { label: year, enabled: !!this.budgetGridYears?.includes(year) };
        });
      } else {
        this.years = hYears.map((year, index) => ({
          label: year,
          enabled: auxilius_start_date ? true : index === hYears.length - 1,
        }));
      }
      this.setSelectedYear();
    }
    if (el) {
      let actualsColDefs: (ColDef | ColGroupDef)[] = el.date_headers
        .filter((col_header) => {
          const year = col_header.split('-').pop();
          const enabled = this.years.find((h) => h.label === Number(year))?.enabled;
          if (!forecastHeader) {
            return enabled;
          }
          const currentForecast = forecastHeader.date_headers[0];
          return (
            enabled &&
            col_header !== this.currentQuarter(currentForecast) &&
            col_header !== this.currentYear(currentForecast)
          );
        })
        .filter((col_header) => {
          if (!isNaN(Number(col_header))) {
            return this.visibleColumns.historicals.years;
          }
          if (col_header.startsWith('Q')) {
            return this.visibleColumns.historicals.quarters;
          }
          return this.visibleColumns.historicals.months;
        })
        .map((col_header) => {
          let headerName: string;
          if (col_header.startsWith('Q')) {
            headerName = col_header.replace('-', ' ');
          } else if (!isNaN(Number(col_header))) {
            headerName = col_header;
          } else {
            const date = this.parseBudgetMonthToDate(col_header);
            headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;
          }

          const fieldNamePrefix = this.compareToValue ? '::SNAPSHOT' : '';
          const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

          const snapshotColumnParams = this.compareToValue
            ? {
                headerGroupComponent: AgHeaderDropdownComponent,
                headerGroupComponentParams: {
                  toggleCb: this.HeaderDropdownService.registerGroupColumnChange.bind(
                    this.HeaderDropdownService
                  ),
                },
              }
            : {};

          return {
            ...snapshotColumnParams,
            headerName,
            headerClass: 'ag-header-align-center justify-center',
            children: [
              {
                headerName: 'Actuals',
                headerClass: [
                  'ag-header-align-center',
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                field: `${el.expense_type}::${col_header}`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: (p: CellClassParams) => [
                  getCellClass(this.selectedBudgetCurrencyType$.getValue())(p),
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${col_header}::SNAPSHOT`
                  : `${col_header}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: !this.compareToValue,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: 'Var ($)',
                field: `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: !this.compareToValue,
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: 'Var (%)',
                field: `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueGetter: this.getVarSnapshotPercent(
                  `${el.expense_type}::${col_header}::SNAPSHOT`,
                  `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                  `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`
                ),
                valueFormatter: (params: ValueFormatterParams) =>
                  Utils.percentageFormatter(
                    Math.abs(
                      this.getVarSnapshotPercent(
                        `${el.expense_type}::${col_header}::SNAPSHOT`,
                        `${filedNameAffix}${col_header}::VAR_COST${fieldNamePrefix}`,
                        `${filedNameAffix}${col_header}::VAR_PERC${fieldNamePrefix}`
                      )(params)
                    )
                  ),
                headerClass: ['ag-header-align-center', AuxExcelStyleKeys.BORDER_RIGHT],
                cellRenderer: VariationStatusComponent,
                cellClass: [
                  'ag-cell-align-right',
                  'budget-percent',
                  AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                hide: !this.compareToValue,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
        });

      if (
        auxilius_start_date &&
        (this.visibleColumns.historicals.months || this.visibleColumns.historicals.quarters)
      ) {
        actualsColDefs = [
          {
            headerName: '',
            headerClass: ['ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark'],
            colId: 'auxilius_start',
            children: [
              {
                headerName: 'Auxilius Start',
                headerClass: 'ag-header-align-center',
                field: 'trial_to_date',
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
            ],
          },
          TableConstants.SPACER_COLUMN,
          ...actualsColDefs.filter((col) => {
            if (col.headerName?.startsWith('Q')) {
              const [month, year] = col.headerName?.split(' ') || [];
              const monthStr = `${+month.replace('Q', '') * 3}`.padStart(2, '0');
              return dayjs(new Date(`${monthStr}/01/${year}`)).isSameOrAfter(
                dayjs(auxilius_start_date).date(1),
                'month'
              );
            }
            if (!Number.isNaN(Number(col.headerName))) {
              return true;
            }
            return dayjs(
              new Date(`01/${col.headerName?.toUpperCase().replace(' ', '/')}`)
            ).isSameOrAfter(dayjs(auxilius_start_date).date(1));
          }),
        ];
      }

      if (forecastHeader) {
        const currentForecast = forecastHeader.date_headers[0];
        // set current period closed months + QTD + YTD (spacers around)
        if (
          this.parseBudgetMonthToDate(currentForecast).month() % 3 &&
          actualsColDefs.length &&
          this.visibleColumns.historicals.months
        ) {
          const currentPeriodClosedMonths: (ColDef | ColGroupDef)[] = [
            actualsColDefs.pop() as ColDef | ColGroupDef,
          ];
          while (
            currentPeriodClosedMonths[0]?.headerName &&
            this.parseBudgetMonthToDate(currentPeriodClosedMonths[0]?.headerName).month() % 3 &&
            actualsColDefs.length
          ) {
            currentPeriodClosedMonths.unshift(actualsColDefs.pop() as ColDef | ColGroupDef);
          }
          actualsColDefs.push(...currentPeriodClosedMonths);
        }

        const snaphotPrefix = this.compareToValue ? '::SNAPSHOT' : '';
        const filedNameAffix = this.compareToValue ? 'EXPENSE_WP::' : '';

        const snapshotColumnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                toggleCb: this.HeaderDropdownService.registerGroupColumnChange.bind(
                  this.HeaderDropdownService
                ),
              },
            }
          : {};

        if (this.visibleColumns.historicals.quarters) {
          const qtd = {
            headerName: `${this.currentQuarter(currentForecast).replace('-', ' ')} (QTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: [
                  'ag-header-align-center',
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                field: `${el.expense_type}::${this.currentQuarter(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: (p: CellClassParams) => [
                  getCellClass(this.selectedBudgetCurrencyType$.getValue())(p),
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentQuarter(currentForecast)}${snaphotPrefix}`
                  : `${this.currentQuarter(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: !this.compareToValue,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: '$',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_COST${snaphotPrefix}`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: !this.compareToValue,
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: '%',
                field: `${filedNameAffix}${this.currentQuarter(
                  currentForecast
                )}::VAR_PERC${snaphotPrefix}`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: ['ag-header-align-center', AuxExcelStyleKeys.BORDER_RIGHT],
                cellRenderer: VariationStatusComponent,
                cellClass: [
                  'ag-cell-align-right',
                  'budget-percent',
                  AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                hide: !this.compareToValue,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(qtd);
        }
        const year = this.parseBudgetMonthToDate(currentForecast).year();
        if (this.visibleColumns.historicals.years) {
          const ytd = {
            headerName: `${year} (YTD)`,
            headerClass: 'ag-header-align-center justify-center',
            ...snapshotColumnParams,
            children: [
              {
                headerName: 'Actuals',
                headerClass: [
                  'ag-header-align-center',
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                field: `${el.expense_type}::${this.currentYear(currentForecast)}`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                hide: false,
                cellClass: (p: CellClassParams) => [
                  getCellClass(this.selectedBudgetCurrencyType$.getValue())(p),
                  AuxExcelStyleKeys.BORDER_LEFT,
                  this.compareToValue ? '' : AuxExcelStyleKeys.BORDER_RIGHT,
                ],
              },
              {
                ...TableConstants.dynamicColumnProps(this.compareToValue || 'Plan'),
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(currentForecast)}${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::PLAN`,
                aggFunc: 'sum',
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
                minWidth: cellSize.xLarge,
                hide: true,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
              },
              {
                headerName: '$',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_COST${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_COST`,
                width: cellSize.xLarge,
                minWidth: cellSize.xLarge,
                aggFunc: 'sum',
                headerClass: 'ag-header-align-center',
                cellRenderer: VariationStatusComponent,
                cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
                hide: true,
                valueFormatter: agBudgetCurrencyFormatter(
                  this.selectedBudgetCurrencyType$.getValue()
                ),
              },
              {
                headerName: '%',
                field: this.compareToValue
                  ? `${el.expense_type}::${this.currentYear(
                      currentForecast
                    )}::VAR_PERC${snaphotPrefix}`
                  : `${this.currentYear(currentForecast)}::VAR_PERC`,
                width: cellSize.large,
                minWidth: cellSize.large,
                valueFormatter: Utils.agPercentageFormatter,
                aggFunc: 'sum',
                headerClass: ['ag-header-align-center', AuxExcelStyleKeys.BORDER_RIGHT],
                cellRenderer: VariationStatusComponent,
                cellClass: [
                  'ag-cell-align-right',
                  'budget-percent',
                  AuxExcelStyleKeys.BORDER_RIGHT,
                ],
                hide: true,
              },
            ].filter((col) => (this.compareToValue ? true : col.headerName === 'Actuals')),
          } as ColGroupDef;
          actualsColDefs.push(ytd);
        }
      }

      if (
        this.visibleColumns.historicals.months ||
        this.visibleColumns.historicals.quarters ||
        this.visibleColumns.historicals.years
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }

      defs.push(...actualsColDefs);

      defs.push(TableConstants.SPACER_COLUMN);
      const actuals = actualsToDateColumnDef(
        this.visibleColumns.actuals_to_date,
        this.selectedBudgetCurrencyType$.getValue()
      ) as ColGroupDef;
      if (!this.actualsToDateFeatureFlag) {
        actuals.children = actuals.children.slice(1);
      }
      defs.push(actuals, TableConstants.SPACER_COLUMN);
      defs.push(
        remainingBudgetColDef(
          this.visibleColumns.remaining_budget,
          this.selectedBudgetCurrencyType$.getValue()
        )
      );
      if (
        this.visibleColumns.remaining_budget.costs ||
        this.visibleColumns.remaining_budget.perc ||
        this.visibleColumns.remaining_budget.units
      ) {
        defs.push(TableConstants.SPACER_COLUMN);
      }
    }

    if (forecastHeader) {
      const currentForecast = forecastHeader.date_headers[0];

      const currentPeriodChildren = [currentForecast];
      let slice = 1;
      while (
        currentPeriodChildren[currentPeriodChildren.length - 1] &&
        (this.parseBudgetMonthToDate(
          currentPeriodChildren[currentPeriodChildren.length - 1]
        ).month() +
          1) %
          3
      ) {
        if (!forecastHeader.date_headers[slice]) {
          break;
        }
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      if (
        (this.visibleColumns.current_period.quarters || this.visibleColumns.forecast.quarters) &&
        forecastHeader.date_headers[slice]?.startsWith('Q')
      ) {
        currentPeriodChildren.push(forecastHeader.date_headers[slice]);
        slice += 1;
      }

      const currentPeriodView = this.getCurrentPeriodView(currentPeriodChildren);

      currentPeriodView.forEach((forecastMonth) => {
        defs.push(forecastMonth);
      });

      // if we are showing the current period add space
      if (currentPeriodView.some((col: ColDef) => !col.hide)) {
        defs.push(TableConstants.SPACER_COLUMN);
      }

      if (!refresh) {
        const fYears = forecastHeader.date_headers.slice(1).reduce((acc: number[], col_header) => {
          const headerName = col_header.split('-').pop();
          if (headerName !== '' && !acc.includes(Number(headerName))) {
            acc.push(Number(headerName));
          }
          return acc;
        }, []);

        let arr: { label: number; enabled: boolean }[];
        if (Array.isArray(this.budgetGridYears)) {
          arr = fYears.map((year) => {
            return { label: year, enabled: !!this.budgetGridYears?.includes(year) };
          });
        } else {
          arr = fYears.map((year, index) => ({ label: year, enabled: index < 2 }));
        }

        this.years = uniqBy([...this.years, ...arr], 'label');
      }

      const year = this.parseBudgetMonthToDate(currentForecast).year();

      defs.push(...this.renderSnapshotForecast(forecastHeader.date_headers, slice, year));
    }

    const updatedColumns = this.updateExportHeaderGroupClasses(defs);

    const colSize = this.selectedVendor.value ? 350 : 250;
    this.autoGroupColumnDef = {
      ...this.autoGroupColumnDef,
      headerComponentParams: {
        ...this.autoGroupColumnDef.headerComponentParams,
        columnsToCollapse: attr.children.map((x: ColDef) => x.colId || x.field),
      },
      width: colSize,
      minWidth: colSize,
    };
    this.gridOptions$.next({
      ...this.gridOptions$.getValue(),
      columnDefs: [...this.defaultColumns, ...updatedColumns],
      autoGroupColumnDef: this.autoGroupColumnDef,
    });

    this.columnDefs = [...this.defaultColumns, ...updatedColumns];
    this.setSelectedYear();

    setTimeout(() => {
      this.showGrid$.next(true);
      this.gridData$.next(aggregated_budget_data);
      this.cdr.markForCheck();
    }, 0);
  }

  async setInvoicesWithoutMapping(vendor_id: string) {
    const queryInput = { vendor_id: vendor_id };
    const { success, data } = await firstValueFrom(
      this.gqlService.listActivitiesWithInvoiceMappings$(queryInput)
    );
    this.activitiesWithoutMapping = success && data ? data : null;
  }

  async updateColumns({
    deletedColumns,
    renamedColumns,
    vendor_id,
    notes,
    supporting_document_s3_bucket_keys,
    transaction_id,
  }: {
    deletedColumns: string[];
    renamedColumns: { oldValue: string; newValue: string }[];
    vendor_id: string;
    notes: string;
    supporting_document_s3_bucket_keys: string[] | null;
    transaction_id: string;
  }) {
    const proms: Promise<unknown>[] = [];
    if (deletedColumns.length) {
      deletedColumns.forEach((name) => {
        proms.push(
          firstValueFrom(
            this.gqlService.removeBudgetAttribute$({
              vendor_id,
              name,
              budget_type: BudgetType.BUDGET_PRIMARY,
              note: notes,
              supporting_document_s3_bucket_keys: supporting_document_s3_bucket_keys,
              transaction_id,
            })
          )
        );
      });
    }

    if (renamedColumns.length) {
      renamedColumns.forEach(({ oldValue, newValue }) => {
        proms.push(
          firstValueFrom(
            this.gqlService.renameBudgetAttribute$({
              vendor_id,
              name: oldValue,
              new_name: newValue,
              budget_type: BudgetType.BUDGET_PRIMARY,
              note: notes,
              supporting_document_s3_bucket_keys: supporting_document_s3_bucket_keys,
              transaction_id,
            })
          )
        );
      });
    }

    if (proms.length) {
      await Promise.allSettled(proms);
    }
  }

  async updateActivities(
    entity_id: string,
    rows: BeActivitiesAttributesModalRowData[],
    isCategoryListChanged: boolean,
    temporaryCategories: BeInlineCategoryDropdownOption[],
    notes: string,
    supporting_document_s3_bucket_keys: string[] | null
  ) {
    const trialId = this.mainQuery.getValue().trialKey;
    const trialVendorPath = `>${trialId}>${entity_id}`;
    const createdActivities: ActivityWithAttributes[] = [];
    const updatedActivities: ActivityWithAttributes[] = [];
    const deletedActivities: ActivityWithAttributes[] = [];

    rows.forEach((row) => {
      let activity_type: ActivityType;
      let activity_sub_type: ActivitySubType | undefined;
      let category_full_path: string;
      if (isCategoryListChanged) {
        category_full_path =
          temporaryCategories.find((category) => category.id === row.category)?.fullPath || '';
      } else {
        category_full_path =
          this.activitiesModal.categories()?.find((category) => category.id === row.category)
            ?.fullPath || '';
      }

      switch (row.activity_type) {
        default:
          activity_type = ActivityType.ACTIVITY_SERVICE;
          break;
        case 'ACTIVITY_SERVICE':
        case 'ACTIVITY_INVESTIGATOR':
        case 'ACTIVITY_PASSTHROUGH':
        case 'ACTIVITY_DISCOUNT':
          activity_type = row.activity_type as ActivityType;
          break;
        case 'ACTIVITY_INVESTIGATOR_PATIENT_VISITS':
        case 'ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES':
        case 'ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES':
          activity_type = ActivityType.ACTIVITY_INVESTIGATOR;
          activity_sub_type = row.activity_type as ActivitySubType;
          break;
      }
      const newData = {
        id: row.id,
        name: row.activity_name || '',
        display_label: row.display_label || '',
        unit_cost: <number>row.unit_cost || 0,
        unit_num: <number>row.unit_num || 0,
        uom: row.uom || '',
        category_id: row.category,
        activity_type,
        activity_sub_type,
        attributes: Object.entries(row.attributes).map(([key, value]) => {
          return {
            attribute_name: key.startsWith('custom_attr_')
              ? decodeURIComponent(atob(key.split('custom_attr_')[1]))
              : key,
            attribute_value: value,
          };
        }),
        category_full_path: `${trialVendorPath}${category_full_path}`,
      };

      if (row.deleted) {
        deletedActivities.push(newData);
        return;
      }

      if (!row.changed || !row.activity_name) {
        return;
      }
      if (row.isGenerated) {
        createdActivities.push({ ...newData, id: '' });
      } else {
        updatedActivities.push(newData);
      }
    });

    localStorage.setItem('anyEditActivities', 'true');

    if (
      createdActivities.length ||
      updatedActivities.length ||
      deletedActivities.length ||
      isCategoryListChanged
    ) {
      const categories: { [key: string]: AuxBudgetCategoryData[] } = {};
      if (isCategoryListChanged) {
        const filteredCategories: BeInlineCategoryDropdownOption[] = temporaryCategories
          .map((category, index) => ({
            ...category,
            sourceIndex: index + 2,
          }))
          .filter((category) => {
            return (
              category.categoryType !== CategoryType.CATEGORY_INVESTIGATOR &&
              category.categoryType !== CategoryType.CATEGORY_DISCOUNT &&
              category.fullPath !== ''
            );
          });

        filteredCategories.forEach((category) => {
          categories[`/${category.categoryType}/${trialVendorPath}${category.fullPath}`] = [
            {
              category_type: category.categoryType || CategoryType.CATEGORY_SERVICE,
              name: category.name,
              full_path: `${trialVendorPath}${category.fullPath}`,
              previous_full_path: category.isRenamed
                ? this.getCategoryPreviousFullPath(category, trialVendorPath)
                : '', // only populate if the name of the category was updated
              parent_path: `${trialVendorPath}${category.path}`,
              trial_id: trialId,
              vendor_id: entity_id || '',
              display_order: 0,
              source_index: category.sourceIndex || 0,
              //fields with default values
              item_label: '0',
              display_label: '',
              item_order: 0,
              item_count: 0,
              direct_expenses_note: '',
              attributes: '[{}]',
              categories: '[{}]',
              __typename: 'AuxBudgetCategoryData',
            },
          ];
        });
      }
      const tracking_id = uuidv4();
      if (
        await this.eventService.triggerEvent({
          type: EventType.INLINE_EDIT_BUDGET_ACTIVITIES,
          entity_type: EntityType.ORGANIZATION,
          entity_id,
          tracking_id,
          payload: JSON.stringify({
            categories,
            createActivities: createdActivities,
            updateActivities: updatedActivities,
            deleteActivities: deletedActivities,
            note: notes,
            supporting_document_s3_bucket_keys: supporting_document_s3_bucket_keys,
          }),
        })
      ) {
        this.eventTrackerService.trackEvent(tracking_id);
        this.overlayService.success('Budget update started');
      }

      return true;
    }

    return false;
  }

  private getCategoryPreviousFullPath(
    category: BeInlineCategoryDropdownOption,
    trialVendorPath: string
  ): string {
    const originalCategory = this.activitiesModal
      .categories()
      ?.find((origCategory) => origCategory.id === category.id);

    return originalCategory ? `${trialVendorPath}${originalCategory.fullPath}` : '';
  }

  private updateExportHeaderGroupClasses(
    columns: (ColDef | ColGroupDef)[]
  ): (ColDef | ColGroupDef)[] {
    let exportColumnIndex = 0;
    return columns.map((column) => {
      if (!this.isSpacerColumn(column) && !this.isAllChildrenHiddenInHeaderGroup(column)) {
        let headerClass = '';

        if (column.headerClass) {
          if (isArray(column.headerClass)) {
            headerClass = column.headerClass.reduce((accum, className) => {
              return `${accum} ${className}`;
            }, '');
          } else if (isString(column.headerClass)) {
            headerClass = column.headerClass;
          }
        }

        exportColumnIndex += 1;

        return {
          ...column,
          headerClass: [
            headerClass,
            AuxExcelStyleKeys.BORDER_LEFT,
            AuxExcelStyleKeys.BORDER_RIGHT,
            !isEven(exportColumnIndex) ? AuxExcelStyleKeys.ALTERNATE : '',
          ],
        };
      }

      return column;
    });
  }

  private isSpacerColumn(column: ColDef | ColGroupDef): boolean {
    if (Object.prototype.hasOwnProperty.call(column, 'colId')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return column.colId === 'spacerColumn';
    }

    return false;
  }

  private isAllChildrenHiddenInHeaderGroup(column: ColDef | ColGroupDef): boolean {
    if (Object.prototype.hasOwnProperty.call(column, 'children')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return column.children.every((col) => col.hide === true);
    }

    return false;
  }

  private getCurrentPeriodView(currentPeriod: string[]) {
    return currentPeriod
      .filter((period) =>
        period.startsWith('Q')
          ? this.visibleColumns.current_period.quarters
          : this.visibleColumns.current_period.months
      )
      .map<ColDef | ColGroupDef>((child) => {
        let cHeaderName: string;

        if (child.startsWith('Q')) {
          cHeaderName = child.split('-').join(' ');
        } else {
          cHeaderName = `${Utils.SHORT_MONTH_NAMES[this.parseBudgetMonthToDate(child).month()]} ${
            child.split('-')[1]
          }`;
        }

        const columnParams = this.compareToValue
          ? {
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                toggleCb: this.HeaderDropdownService.registerGroupColumnChange.bind(
                  this.HeaderDropdownService
                ),
              },
            }
          : {};

        return {
          ...columnParams,
          headerName: cHeaderName,
          headerClass: [
            this.compareToValue
              ? 'flex items-center justify-center future'
              : 'ag-header-align-center future',
          ],
          colId: 'currentPeriod',
          children: this.getForecastSubColumns(child, !!this.compareToValue),
        };
      });
  }

  private renderSnapshotForecast(date_headers: string[], slice: number, currentYear: number) {
    return date_headers
      .slice(slice)
      .filter((col_header) => {
        const year = col_header.split('-').pop();
        return this.years.find((e) => e.label === Number(year))?.enabled;
      })
      .filter((col_header) => {
        if (!isNaN(Number(col_header))) {
          return this.visibleColumns.forecast.years;
        }
        if (col_header.startsWith('Q')) {
          return this.visibleColumns.forecast.quarters;
        }
        return this.visibleColumns.forecast.months;
      })
      .map((forecastMonth) => {
        let headerName: string;
        let expandAll = false;
        if (forecastMonth.startsWith('Q')) {
          headerName = forecastMonth.replace('-', ' ');
        } else if (!isNaN(Number(forecastMonth))) {
          headerName = forecastMonth;
        } else {
          const date = this.parseBudgetMonthToDate(forecastMonth);
          headerName = `${Utils.SHORT_MONTH_NAMES[date.month()]} ${date.year()}`;

          expandAll = date.year() === currentYear;
        }

        const forecastHeaderParams = this.compareToValue
          ? {
              headerClass: [
                'ag-header-align-center bg-aux-gray-dark aux-black border-aux-gray-dark gray-dark-header-group flex items-center justify-center',
              ],
              headerGroupComponent: AgHeaderDropdownComponent,
              headerGroupComponentParams: {
                iconClass: 'text-black',
                toggleCb: this.HeaderDropdownService.registerGroupColumnChange.bind(
                  this.HeaderDropdownService
                ),
              },
            }
          : {
              headerClass: [
                'ag-header-align-center bg-aux-gray-dark aux-black gray-dark-header-group',
              ],
            };

        return {
          ...forecastHeaderParams,
          headerName,
          children: this.getForecastSubColumns(
            forecastMonth,
            this.compareToValue ? expandAll : false
          ),
        } as ColGroupDef;
      });
  }

  private getForecastSubColumns(
    forecastMonth: string,
    expandHiddenColumns: boolean
  ): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: 'Forecast',
        headerClass: [
          'ag-header-align-center',
          AuxExcelStyleKeys.BORDER_LEFT,
          !expandHiddenColumns ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        field: `EXPENSE_FORECAST::${forecastMonth}`,
        aggFunc: 'sum',
        valueFormatter: agBudgetCurrencyFormatter(this.selectedBudgetCurrencyType$.getValue()),
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        hide: false,
        cellClass: (p) => [
          getCellClass(this.selectedBudgetCurrencyType$.getValue())(p),
          AuxExcelStyleKeys.BORDER_LEFT,
          !expandHiddenColumns ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
      },
      {
        ...TableConstants.dynamicColumnProps(this.compareToValue || ''),
        field: `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
        aggFunc: 'sum',
        valueFormatter: agBudgetCurrencyFormatter(this.selectedBudgetCurrencyType$.getValue()),
        minWidth: cellSize.xLarge,
        hide: !expandHiddenColumns,
        cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
      },
      {
        headerName: 'Var ($)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
        width: cellSize.xLarge,
        minWidth: cellSize.xLarge,
        aggFunc: 'sum',
        headerClass: 'ag-header-align-center',
        cellRenderer: VariationStatusComponent,
        cellClass: getCellClass(this.selectedBudgetCurrencyType$.getValue()),
        hide: !expandHiddenColumns,
        valueFormatter: agBudgetCurrencyFormatter(this.selectedBudgetCurrencyType$.getValue()),
      },
      {
        headerName: 'Var (%)',
        field: `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`,
        width: cellSize.large,
        minWidth: cellSize.large,
        valueGetter: this.getVarSnapshotPercent(
          `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
          `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
          `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`
        ),
        valueFormatter: (params: ValueFormatterParams) =>
          Utils.percentageFormatter(
            Math.abs(
              this.getVarSnapshotPercent(
                `EXPENSE_FORECAST::${forecastMonth}::SNAPSHOT`,
                `EXPENSE_FORECAST::${forecastMonth}::VAR_COST::SNAPSHOT`,
                `EXPENSE_FORECAST::${forecastMonth}::VAR_PERC::SNAPSHOT`
              )(params)
            )
          ),
        headerClass: [
          'ag-header-align-center',
          expandHiddenColumns ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        cellRenderer: VariationStatusComponent,
        cellClass: [
          'ag-cell-align-right',
          'budget-percent',
          expandHiddenColumns ? AuxExcelStyleKeys.BORDER_RIGHT : '',
        ],
        hide: !expandHiddenColumns,
      },
    ];
  }

  private getTimelineHeaders = (monthYears: string[] | undefined) => {
    if (!monthYears) {
      return { months: [], quarters: [], years: [] };
    }
    return {
      months: monthYears,
      quarters: [
        ...new Set(
          monthYears.map((date) => {
            return `Q${dayjs(this.parseBudgetMonthToDate(date)).quarter()} ${dayjs(
              this.parseBudgetMonthToDate(date)
            ).format('YYYY')}`;
          })
        ),
      ],
      years: [
        ...new Set(
          monthYears.map((date) => {
            return dayjs(this.parseBudgetMonthToDate(date)).format('YYYY');
          })
        ),
      ],
    };
  };

  private periodSortingFunction(a: string, b: string) {
    const a_year = a.split('-').pop();
    const b_year = b.split('-').pop();
    if (Number(a_year) < Number(b_year)) {
      return -1;
    }
    if (Number(a_year) > Number(b_year)) {
      return 1;
    }

    if (a.split('-').length > b.split('-').length) {
      return -1;
    }
    if (a.split('-').length < b.split('-').length) {
      return 1;
    }

    const a_index = period_sorting.findIndex((el) => el.toUpperCase() === a.split('-').shift());
    const b_index = period_sorting.findIndex((el) => el.toUpperCase() === b.split('-').shift());
    if (a_index < b_index) {
      return -1;
    }
    if (a_index === b_index) {
      return 0;
    }
    return 1;
  }

  private aggregateQuartersAndYears(
    budget_data: ExtendedBudgetData[],
    header_data: RequireSome<BudgetHeader, 'date_headers'>[]
  ) {
    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();
    const f_header = header_data.find((el) => el.expense_type === 'EXPENSE_FORECAST');
    const forecast_header = {
      ...f_header,
      date_headers: Object.assign([], f_header?.date_headers),
    };
    const h_header = header_data.find((el) => el.expense_type === 'EXPENSE_WP');
    const historical_header = {
      ...h_header,
      date_headers: Object.assign([], h_header?.date_headers),
    };
    const remaining_header = header_data.filter(
      (el) => el.expense_type !== 'EXPENSE_FORECAST' && el.expense_type !== 'EXPENSE_WP'
    );
    const bud_data: ExtendedBudgetData[] = budget_data.map((bd) => {
      const obj: { [key: string]: number } = {};
      Object.keys(bd)
        .filter((key) => key.startsWith('EXPENSE_FORECAST::') && !key.endsWith('::SNAPSHOT'))
        .forEach((key) => {
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          if (isNaN(date.year())) {
            return;
          }
          const yearKey = `EXPENSE_FORECAST::${date.year()}` as const;
          if (!bd[yearKey]) {
            if (obj[yearKey]) {
              obj[yearKey] += <number>bd[key];
            } else {
              obj[yearKey] = <number>bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
                forecast_header?.date_headers.push(`${date.year()}`);
              }
            }
          }

          const quarterStr = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarterKey = `EXPENSE_FORECAST::${quarterStr}`;
          if (!bd[quarterKey]) {
            if (obj[quarterKey]) {
              obj[quarterKey] += <number>bd[key];
            } else {
              obj[quarterKey] = <number>bd[key];
              if (!forecast_header?.date_headers.find((h: string) => h === quarterStr)) {
                forecast_header?.date_headers.push(
                  `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`
                );
              }
            }
          }
        });
      const desc = Object.getOwnPropertyDescriptor(forecast_header, 'date_headers');
      if (desc?.writable) {
        forecast_header?.date_headers.sort(this.periodSortingFunction);
      }

      Object.keys(bd)
        .filter(
          (key) =>
            key.startsWith('EXPENSE_WP::') &&
            key !== 'EXPENSE_WP::TO_DATE' &&
            !key.endsWith('::SNAPSHOT')
        )
        .forEach((key) => {
          const value = <number>bd[key];
          const splitkey = key.split('::');
          const date = dayjs(`01/${splitkey[1].replace('-', '/')}`);
          if (isNaN(date.year())) {
            return;
          }

          // YTD - Actuals
          const year_key = `EXPENSE_WP::${date.year()}`;
          if (!bd[year_key]) {
            if (obj[year_key]) {
              obj[year_key] += value;
            } else {
              obj[year_key] = value;
            }
            if (!historical_header?.date_headers.find((h: string) => h === `${date.year()}`)) {
              historical_header?.date_headers.push(`${date.year()}`);
            }
          }

          // this part is for the calculation of the quarter which includes the month of the auxilius start date
          let keysOfMonthsBeforeAuxStart: string[] = [];
          if (auxilius_start_date) {
            keysOfMonthsBeforeAuxStart =
              this.budgetGridService.getMonthsBeforeAuxiliusStartForQuarterCalc(
                auxilius_start_date
              );
          }
          if (keysOfMonthsBeforeAuxStart.includes(key)) {
            return;
          }

          // QTD Actuals
          const quarter_str = `Q${Math.floor(date.month() / 3) + 1}-${date.year()}`;
          const quarter_key = `EXPENSE_WP::${quarter_str}`;

          if (!bd[quarter_key]) {
            if (obj[quarter_key]) {
              obj[quarter_key] += value;
            } else {
              obj[quarter_key] = value;
              if (!historical_header?.date_headers.find((h: string) => h === quarter_str)) {
                historical_header?.date_headers.push(quarter_str);
              }
            }
          }

          const forecast_quarter_key = `EXPENSE_FORECAST::${splitkey[1]}`;
          const plan = <number>bd[forecast_quarter_key] || 0;
          const var_cost = value - plan;
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_COST`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_COST`] = var_cost;
          }
          if (!bd[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`]) {
            obj[`EXPENSE_WP::${splitkey[1]}::VAR_PERC`] =
              isNaN(var_cost / plan) || !plan ? 0 : var_cost / plan;
          }

          const plan_quarter = <number>bd[`EXPENSE_FORECAST::Q${quarter_str}::`];
          const var_cost_quarter = obj[quarter_key] - plan_quarter;
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_COST`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_COST`] = var_cost_quarter;
          }
          if (!bd[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`]) {
            obj[`EXPENSE_WP::Q${quarter_str}::VAR_PERC`] =
              isNaN(var_cost_quarter / plan_quarter) || !plan_quarter
                ? 0
                : var_cost_quarter / plan_quarter;
          }
          const plan_year = <number>bd[`EXPENSE_FORECAST::${date.year}::`];
          const var_cost_year = obj[year_key] - plan_quarter;
          obj[`EXPENSE_WP::${date.year()}::VAR_COST`] = var_cost_year;
          obj[`EXPENSE_WP::${date.year()}::VAR_PERC`] =
            isNaN(var_cost_year / plan_year) || !plan_year ? 0 : var_cost_year / plan_year;
        });

      const setDataForHiddenColumn = (
        selector: string,
        callback: (date: dayjs.Dayjs, key: string) => void
      ) => {
        Object.keys(bd)
          .filter((key) => key.endsWith(selector) && !key.startsWith('TO_DATE::'))
          .forEach((key) => {
            const splitkey = key.split('::');
            const date = dayjs(`01/${splitkey[0].replace('-', '/')}`);
            if (Number.isNaN(date.year())) {
              return;
            }

            callback(date, key);
          });
      };

      setDataForHiddenColumn('::PLAN', (date: dayjs.Dayjs, key: string) => {
        const planKey = `${date.year()}::PLAN`;

        obj[planKey] = (obj[planKey] || 0) + <number>bd[key];
      });

      setDataForHiddenColumn('::VAR_COST', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varCostKey = `${date.year()}::VAR_COST`;

        const actuals = obj[`EXPENSE_WP::${date.year()}`] || 0;

        obj[`${varCostKey}`] = actuals - obj[planKey];
      });

      setDataForHiddenColumn('::VAR_PERC', (date: dayjs.Dayjs) => {
        const planKey = `${date.year()}::PLAN`;
        const varPercKey = `${date.year()}::VAR_PERC`;

        const varCost = obj[`${date.year()}::VAR_COST`] || 0;

        obj[`${varPercKey}`] = this.getVarPerc(varCost, obj[planKey]);
      });

      // Fill data for quarters
      Object.keys({ ...obj })
        .filter((key) => key.startsWith('EXPENSE_WP::Q'))
        .forEach((key) => {
          const [quarter] = key.match(/Q\d-\d{4}$/) || [];
          if (!quarter) {
            return;
          }

          const quarterNumber = +quarter[1];

          const [quarterYear] = quarter.match(/\d{4}$/) || [];

          if (!quarterYear) {
            return;
          }

          const plan = Object.keys({ ...bd })
            .filter((field) => field.match(new RegExp(`-${quarterYear}::PLAN`)))
            .filter((field) => dayjs(field.replace('::PLAN', '')).quarter() === quarterNumber)
            .reduce((sum, planKey) => {
              return sum + <number>bd[planKey];
            }, 0);

          const quarter_key = `EXPENSE_WP::${quarter}`;
          const planKey = `${quarter}::PLAN`;
          const varCostKey = `${quarter}::VAR_COST`;
          const varPerc = `${quarter}::VAR_PERC`;

          const varCost = this.getVarCost(obj[quarter_key], plan);

          obj[planKey] = plan;
          obj[varCostKey] = varCost;
          obj[varPerc] = this.getVarPerc(varCost, plan);
        });

      const h_desc = Object.getOwnPropertyDescriptor(historical_header, 'date_headers');
      if (h_desc?.writable) {
        historical_header?.date_headers.sort(this.periodSortingFunction);
      }

      const extraAttributes = Array.isArray(bd.attributes)
        ? bd.attributes.reduce(
            (acc, a) => {
              if (a.attribute_name && a.attribute_value) {
                acc[getEncodedAttributeName(a.attribute_name)] = a.attribute_value;
              }

              return acc;
            },
            {} as Record<string, string>
          )
        : {};

      return { ...bd, ...obj, ...extraAttributes };
    });
    const headers: RequireSome<BudgetHeader, 'date_headers'>[] = Object.assign(remaining_header, [
      historical_header,
      forecast_header,
    ]);
    return [bud_data, headers] as const;
  }

  openSnapshotModal = () => {
    this.overlayService.openPopup({
      modal: SnapshotModalComponent,
      settings: {
        header: 'Name Budget Snapshot',
      },
    });
  };

  chartLegendClick(isCurrent = false) {
    this.isSnapShotSelected$.next({
      selected: this.isSnapShotSelected$.getValue().selected,
      currentLegend: isCurrent
        ? !this.isSnapShotSelected$.getValue().currentLegend
        : this.isSnapShotSelected$.getValue().currentLegend,
      snapShotLegend: isCurrent
        ? this.isSnapShotSelected$.getValue().snapShotLegend
        : !this.isSnapShotSelected$.getValue().snapShotLegend,
    });
    const data = this.canvasDatasets$.getValue().map((x, index) => {
      if (index === 0 || index === 1 || index === 2) {
        return { ...x, hidden: !this.isSnapShotSelected$.getValue().currentLegend };
      }
      return { ...x, hidden: !this.isSnapShotSelected$.getValue().snapShotLegend };
    });
    this.canvasDatasets$.next(data);
  }

  onToggleBudgetGraph = () => {
    this.showBudgetGraph = !this.showBudgetGraph;
    localStorage.setItem('showBudgetGraph', `${this.showBudgetGraph}`);
  };

  getVarSnapshotPercent =
    (snapshotActualsKey: string, varCostKey: string, percentKey: string) =>
    (params: ValueFormatterParams | ValueGetterParams) => {
      let percent = (params.data || {})[percentKey] || 0;

      if (params.node?.aggData) {
        const { aggData } = params.node;

        const actuals = aggData[snapshotActualsKey];

        if (!actuals) {
          return percent;
        }

        percent = decimalDivide(aggData[varCostKey], actuals, 2);
      }

      return percent;
    };

  /*
    The purpose of this method is to group up the monthly discount data in monthlyData and return one discount value for each xAxisPeriodLabels.
    For example, if selectedPeriod was QUARTER and trial timeline is Jan 2022 to Oct 2023, then xAxisPeriodLabels would have Q1 2022, Q2 2022 ... Q4 2023,
    so this method would return an array with 8 elements.
  */
  private getDiscountDataForCanvas(
    xAxisPeriodLabels: string[],
    selectedPeriod: PeriodType,
    monthlyData: Dictionary<ExtendedBudgetData[]>,
    isSnapshot: boolean
  ): number[] {
    const snapshot = isSnapshot ? '::SNAPSHOT' : '';

    return xAxisPeriodLabels.map((periodLabel) => {
      if (selectedPeriod === PeriodType.PERIOD_QUARTER) {
        const [quarter, year] = periodLabel.split(' ');
        const quarterNumber = parseInt(quarter.replace('Q', ''));
        const startDate = dayjs().year(parseInt(year)).quarter(quarterNumber).startOf('quarter');
        let totalSum = 0;
        // Iterate through all 3 months of the quarter
        for (let i = 0; i < 3; i++) {
          const month = startDate.add(i, 'month').format('MMM-YYYY').toUpperCase();
          const wpKey = `EXPENSE_WP_USD::${month}${snapshot}`;
          const forecastKey = `EXPENSE_FORECAST_USD::${month}${snapshot}`;

          totalSum +=
            sumBy(monthlyData.Discount, wpKey) || sumBy(monthlyData.Discount, forecastKey) || 0;
        }
        return totalSum;
      }
      if (selectedPeriod === PeriodType.PERIOD_YEAR) {
        const year = parseInt(periodLabel, 10);

        const startDate = dayjs().year(year).startOf('year');

        let totalSum = 0;
        for (let i = 0; i < 12; i++) {
          // Iterate through all 12 months of the year
          const month = startDate.add(i, 'month').format('MMM-YYYY').toUpperCase();
          const wpKey = `EXPENSE_WP_USD::${month}${snapshot}`;
          const forecastKey = `EXPENSE_FORECAST_USD::${month}${snapshot}`;
          totalSum +=
            sumBy(monthlyData.Discount, wpKey) || sumBy(monthlyData.Discount, forecastKey) || 0;
        }
        return totalSum;
      }
      return (
        sumBy(monthlyData.Discount, `EXPENSE_WP_USD::${periodLabel}${snapshot}`) ||
        sumBy(monthlyData.Discount, `EXPENSE_FORECAST_USD::${periodLabel}${snapshot}`) ||
        0
      );
    });
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPLOAD_BUDGET],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasUploadBudgetPermission]) => {
        this.userHasUploadBudgetPermission = userHasUploadBudgetPermission;
      });
  }

  private getDiscountAmountForCanvas(
    amtType: string,
    selectedPeriod: PeriodType,
    datasets: ChartDataset<'bar', BudgetChartData>[],
    amtTypeIndex: number,
    periodIndex: number,
    loopIndex: number
  ) {
    let discountAmount = 0;
    if (amtType === 'Services') {
      if (selectedPeriod === PeriodType.PERIOD_MONTH) {
        discountAmount = datasets[amtTypeIndex].data[periodIndex].discountData;
      }
      if (loopIndex % 3 === 0 && selectedPeriod === PeriodType.PERIOD_QUARTER) {
        discountAmount = datasets[amtTypeIndex].data[periodIndex].discountData;
      }
      /*
      let's say the timeline is Jan 2022 to Oct 2023, loopIndex will go from 0 to 21 (12 + 9) for each month in the years,
      and if the selectedPeriod is PERIOD_YEAR then periodIndex will go from 0 to 1 for each year.
      So in this case datasets[amtTypeIndex].discountData will have two values (one for each year).
      When loopIndex is 0, we'll return the first value in discountData, and when loopIndex is 12, we'll return the second.
    */
      if (loopIndex % 12 === 0 && selectedPeriod === PeriodType.PERIOD_YEAR) {
        discountAmount = datasets[amtTypeIndex].data[periodIndex].discountData;
      }
    }
    return discountAmount;
  }

  onSelect() {
    this.editButtonsDisabled.set(this.gridAPI?.getSelectedNodes().length === 0);
  }
}
