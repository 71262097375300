import { MonthStats } from '../period-close.component';
import { FormControl } from '@angular/forms';
import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { QuarterCloseChecklistPeriodCloseService } from '../tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { map, first, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OverlayService } from '@shared/services/overlay.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { selectWithGuardChangesModalCheck } from '@shared/utils/select-option';
import { Option } from '@shared/types/components.type';
import { UnsavedChangesHelperService } from '@shared/services/unsaved-changes-helper.service';

@Component({
  selector: 'aux-period-close-header',
  templateUrl: './period-close-header.component.html',
  styleUrl: './period-close-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseHeaderComponent {
  public router = inject(Router);

  private periodCloseService = inject(QuarterCloseChecklistPeriodCloseService);

  private unsavedChangesHelperService = inject(UnsavedChangesHelperService);

  private overlayService = inject(OverlayService);

  @ViewChild('selectedQuarterEl') selectedQuarterEl?: NgSelectComponent;

  @Input() quarters: Option[] = [];

  @Input() selectedQuarter!: FormControl;

  @Input() months$ = new BehaviorSubject<MonthStats[]>([]);

  async selectQuarter(event: MouseEvent, value: string): Promise<void> {
    selectWithGuardChangesModalCheck(
      event,
      value,
      this.unsavedChangesHelperService.getUnsavedChangesDecisionByRouterUrl(),
      this.overlayService,
      this.selectedQuarter,
      this.selectedQuarterEl
    );
  }

  quarterCloseChecklistTitle$(): Observable<string> {
    return combineLatest([
      this.periodCloseService.isCurrentQuarterSelected.pipe(first()),
      this.periodCloseService.selectedQuarterMonthChanged$.pipe(startWith(null)),
    ]).pipe(
      map(() => {
        const date =
          (this.router.url.includes('adjustments') ||
            this.router.url.includes('checklist') ||
            this.router.url.includes('journal-entries')) &&
          this.periodCloseService.persistedQuarterMonth
            ? dayjs(this.periodCloseService.persistedQuarterMonth)
            : dayjs(this.periodCloseService.selectedQuarterMonth);

        return date.isValid() ? date.format('MMMM YYYY') : '';
      })
    );
  }
}
