import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  ElementRef,
  inject,
  OnDestroy,
  signal,
  viewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { OrganizationService } from '@models/organization/organization.service';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import {
  BudgetType,
  EventType,
  PermissionType,
  TrialImplementationStatus,
  listTrialsQuery,
  TrialPhase,
} from '@shared/services/gql.service';
import { switchMap, tap } from 'rxjs/operators';

import { TrialsService } from '@models/trials/trials.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '@models/event/event.service';
import { AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { TrialModel } from '@models/trials/trials.store';
import { OverlayService } from '@shared/services/overlay.service';
import { MainStore } from '@shared/store/main/main.store';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { SettingsService } from './settings.service';
import { MainQuery } from '@shared/store/main/main.query';
import { Utils } from '@shared/utils/utils';
import { AuthService } from '@shared/store/auth/auth.service';
import { TrialsQuery } from '@models/trials/trials.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Option } from '@shared/types/components.type';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'aux-settings',
  templateUrl: './settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  saveBtn = viewChild<ElementRef<HTMLButtonElement>>('saveButton');

  tabs: TabGroupConfig[] = [
    { label: 'Users', route: ROUTING_PATH.SETTINGS.USERS },
    {
      label: 'User Permissions',
      route: ROUTING_PATH.SETTINGS.USER_PERMISSIONS,
      show: this.launchDarklyService.select$((flags) => flags.tab_user_permissions),
    },
    {
      label: 'Exchange Rates',
      route: ROUTING_PATH.SETTINGS.EXCHANGE_RATES,
      show: this.launchDarklyService.select$((flags) => flags.tab_exchange_rates),
    },
    {
      label: 'Integrations',
      route: ROUTING_PATH.SETTINGS.INTEGRATIONS,
      show: this.launchDarklyService.select$((flags) => flags.tab_integrations),
    },
    {
      label: 'Expense Defaults',
      route: ROUTING_PATH.SETTINGS.EXPENSE_DEFAULTS,
      show: this.launchDarklyService.select$((flags) => flags.expense_defaults),
    },
  ];

  currentTrial: listTrialsQuery | undefined;

  editMode = new BehaviorSubject(false);

  hasTrialSettingsPermission$ = new BehaviorSubject<boolean>(false);

  editButtonDisabledTooltip = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  workspaceEnum = Utils.TRIAL_WORKSPACE_TYPE_OPTIONS;

  workspaceTypeOptions = Object.entries(Utils.TRIAL_WORKSPACE_TYPE_OPTIONS).map(([key, label]) => ({
    label,
    key,
  }));

  implementationStatusOptions = Object.entries(Utils.TRIAL_IMPLEMENTATION_STATUS_OPTIONS).map(
    ([key, label]) => ({
      label,
      key,
    })
  );

  therapyAreaOptions: Option[] = [];

  validImplemenatonStatusOptions = this.implementationStatusOptions.slice(1);

  fg = this.formBuilder.group({
    auxilius_start_date: null,
    short_name: ['', Validators.required],
    indication: ['', Validators.required],
    therapy_area: null,
    trial_phase: null,
    program: null,
    project: null,
    implementation_status: [null, this.implementationStatusValidator()],
    workspace_type: [null, Validators.required],
  });

  initialFgValue = this.fg.value;

  trialPhaseOptions: Option<TrialPhase | 'TRIAL_PHASE_PHASE_1_2'>[] = [
    {
      label: 'Pre Clinical',
      value: TrialPhase.TRIAL_PHASE_PRE_CLINICAL,
    },
    {
      label: 'Phase 1',
      value: TrialPhase.TRIAL_PHASE_PHASE_1,
    },
    {
      label: 'Phase 1/2',
      value: 'TRIAL_PHASE_PHASE_1_2',
    },
    {
      label: 'Phase 2',
      value: TrialPhase.TRIAL_PHASE_PHASE_2,
    },
    {
      label: 'Phase 3',
      value: TrialPhase.TRIAL_PHASE_PHASE_3,
    },
    {
      label: 'Phase 4',
      value: TrialPhase.TRIAL_PHASE_PHASE_4,
    },
  ];

  hasChanges = signal<boolean>(false);

  requiredAreasEmpty = signal<boolean>(false);

  saveButtonTooltip = computed(() => {
    return !this.hasChanges() && !this.requiredAreasEmpty() ? 'No changes to save.' : '';
  });

  saveButtonDisabled = computed(() => {
    return !this.hasChanges() || this.requiredAreasEmpty();
  });

  constructor(
    private vendorsService: OrganizationService,
    private trialsService: TrialsService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private eventService: EventService,
    private formBuilder: UntypedFormBuilder,
    private authQuery: AuthQuery,
    private mainStore: MainStore,
    private mainQuery: MainQuery,
    public service: SettingsService,
    private trialUserService: TrialUserService,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    public trialsQuery: TrialsQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.trialUserService.get().pipe(takeUntilDestroyed()).subscribe();

    this.hasPermission();

    this.vendorsService
      .getListWithTotalBudgetAmount(BudgetType.BUDGET_PRIMARY)
      .pipe(takeUntilDestroyed())
      .subscribe();

    this.mainStore.update({ fullPage: true });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(
        takeUntilDestroyed(),
        switchMap(([{ trial_id }, trialKey]) => {
          const trialId = trialKey ? trialKey : trial_id;
          return combineLatest([this.authQuery.isLoggedIn$, of({ trial_id: trialId })]);
        }),
        switchMap((arr) => {
          return combineLatest([of(arr), this.trialsService.get()]);
        })
      )
      .subscribe(([[, { trial_id }], { data }]) => {
        if (data?.length) {
          this.setCurrentTrial(data.find((trial) => trial.id === trial_id) || data[0]);
        }
      });

    this.fg.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.checkChangesFromForm();
    });

    this.fg.controls['therapy_area'].valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      if (value && value.length === 0) {
        this.fg.controls['therapy_area'].markAsTouched();
      }
    });

    this.authQuery.isLoggedIn$
      .pipe(
        takeUntilDestroyed(),
        switchMap((x: boolean) => {
          if (x) {
            return this.trialsService.get().pipe(
              tap(async ({ data }) => {
                if (data?.length) {
                  const { queryParams } = this.route.snapshot;
                  let trialKey = queryParams.trial;
                  if (
                    queryParams.trial &&
                    data.filter((d) => d.id === queryParams.trial).length !== 0
                  ) {
                    trialKey = queryParams.trial;
                  } else {
                    trialKey = this.authQuery.getValue().trial_id;
                  }
                  this.setCurrentTrial(data.find((trial) => trial.id === trialKey) || data[0]);
                }
              })
            );
          }
          return of([]);
        })
      )
      .subscribe();

    this.initTherapyAreaOptions();
  }

  async initTherapyAreaOptions(): Promise<void> {
    this.therapyAreaOptions = await this.trialsService.getTherapyAreaOptions();
    this.changeDetectorRef.detectChanges();
  }

  setCurrentTrial(currTrial: listTrialsQuery) {
    this.currentTrial = {
      ...currTrial,
      nct_id:
        currTrial.nct_id === null || currTrial.nct_id === undefined
          ? currTrial.name
          : currTrial.nct_id,
    };
    this.patchFormValue();
    this.editMode.next(false);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.fg.touched) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onEditMode() {
    this.editMode.next(true);
  }

  patchFormValue(): void {
    this.fg.patchValue({
      ...this.currentTrial,
      trial_phase: isEqual(this.currentTrial?.trial_phase, [
        TrialPhase.TRIAL_PHASE_PHASE_1,
        TrialPhase.TRIAL_PHASE_PHASE_2,
      ])
        ? 'TRIAL_PHASE_PHASE_1_2'
        : this.currentTrial?.trial_phase[0],
    });
    this.fg.markAsUntouched();
    this.fg.markAsPristine();
  }

  editModeCancel() {
    this.fg.reset();
    this.patchFormValue();
    this.editMode.next(false);
  }

  getTrialPhaseValue(): string | TrialPhase | undefined {
    return isEqual(this.currentTrial?.trial_phase, [
      TrialPhase.TRIAL_PHASE_PHASE_1,
      TrialPhase.TRIAL_PHASE_PHASE_2,
    ])
      ? 'TRIAL_PHASE_PHASE_1_2'
      : this.currentTrial?.trial_phase[0];
  }

  async onSaveEdits() {
    const {
      short_name,
      indication,
      therapy_area,
      trial_phase,
      auxilius_start_date,
      program,
      project,
      implementation_status,
      workspace_type,
    } = this.fg.value;
    if (this.fg.valid) {
      if (this.currentTrial) {
        const trial = {
          auxilius_start_date,
          short_name,
          indication,
          therapy_area,
          trial_phase,
          program,
          project,
          implementation_status,
          workspace_type,
        } as Partial<TrialModel>;
        const success = await this.trialsService.update(this.currentTrial?.id, {
          ...trial,
          trial_phase:
            trial_phase === 'TRIAL_PHASE_PHASE_1_2'
              ? [TrialPhase.TRIAL_PHASE_PHASE_1, TrialPhase.TRIAL_PHASE_PHASE_2]
              : [trial_phase],
        });
        if (success) {
          this.currentTrial = {
            ...this.currentTrial,
            auxilius_start_date,
            short_name,
            indication,
            therapy_area,
            trial_phase:
              trial_phase === 'TRIAL_PHASE_PHASE_1_2'
                ? [TrialPhase.TRIAL_PHASE_PHASE_1, TrialPhase.TRIAL_PHASE_PHASE_2]
                : [trial_phase],
            program,
            project,
            implementation_status,
            workspace_type,
          };
        }
        this.editMode.next(false);
        this.fg.markAsUntouched();
        this.fg.markAsPristine();
        this.checkChangesFromForm();
      }
    }
  }

  hasPermission() {
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_TRIAL_SETTINGS],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((perm) => this.hasTrialSettingsPermission$.next(perm));
  }

  implementationStatusValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      const forbidden = control.value === TrialImplementationStatus.IMPLEMENTATION_STATUS_BLANK;
      return forbidden ? { blankStatus: { value: control.value } } : null;
    };
  }

  triggerSave(): void {
    this.saveBtn()?.nativeElement.click();
  }

  checkChangesFromForm() {
    if (this.fg.pristine) {
      this.initialFgValue = this.fg.value;
    }
    this.hasChanges.set(
      !Object.keys(this.initialFgValue).every((key) => {
        const fgValue = this.fg.value[key] ? this.fg.value[key] : null;
        const initialValue = this.initialFgValue[key] ? this.initialFgValue[key] : null;
        return fgValue === initialValue;
      })
    );

    const requiredAreas = ['short_name', 'indication', 'implementation_status', 'workspace_type'];

    this.requiredAreasEmpty.set(
      requiredAreas.some((key) => !this.fg.value[key] || this.fg.value[key].length === 0)
    );
  }

  trialShortNameValidators: ValidatorFn[] = [
    (control) => {
      const value: string = control.value;
      if (!value) {
        return { required: true };
      }

      const exists = !!this.trialsQuery
        .getAll()
        .find((trial) => trial.short_name?.toLowerCase() === value.toLowerCase());

      return exists && this.currentTrial?.short_name !== value ? { duplicateTrial: true } : null;
    },
  ];

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
  }
}
