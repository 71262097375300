import { Component, Input, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Option } from '@shared/types/components.type';

@Component({
  selector: 'aux-forecast-table-grid-period-patient',
  templateUrl: './forecast-table-grid-period-patient.component.html',
  styleUrls: ['./forecast-table-grid-period-patient.component.scss'],
})
export class ForecastTableGridPeriodPatientComponent {
  @Input() tooltip = '';

  @Input() selectedPeriodPatientOption = '';

  @Input() periodPatientOptions: Option<string>[] = [];

  @ViewChild('gridPeriodPatient') public input!: NgSelectComponent;
}
