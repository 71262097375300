import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { DriverSiteGroup } from '@shared/services/gql.service';

export type SiteCurveState = EntityState<
  DriverSiteGroup & {
    showLine: boolean;
    site_group_ids: string[];
    site_group_id: string;
  }
>;

export type SiteCurveModel = getEntityType<SiteCurveState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-curve', idKey: 'driver_setting_id' })
export class SiteCurveStore extends EntityStore<SiteCurveState> {
  constructor() {
    super({});
  }
}
