import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

export interface AgExpenseDefaultGroupHeaderComponentParams extends IHeaderParams {
  template: string;
  headerPage: string;
}

@Component({
  template: `
    <div
      class="flex items-center justify-left space-x-[10px]"
      [ngClass]="{
        '-mt-[48px]': params.headerPage === 'budget',
        '-mt-[40px]': params.headerPage === 'quarter-close'
      }"
    >
      <div class="flex items-center justify-center space-x-[5px]">
        <button (click)="toggleExpand(false)">
          <span [auxTooltip]="'Expand All Rows'">
            <aux-icon name="ChevronsDown" />
          </span>
        </button>

        <button (click)="toggleExpand(true)">
          <span [auxTooltip]="'Collapse All Rows'">
            <aux-icon name="ChevronsUp" />
          </span>
        </button>
      </div>
      <div class="text-aux-black text-base" [innerHTML]="params.template"></div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgExpenseDefaultGroupHeaderComponent implements IHeaderAngularComp {
  params!: AgExpenseDefaultGroupHeaderComponentParams;

  agInit(params: AgExpenseDefaultGroupHeaderComponentParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  toggleExpand(isExpanded: boolean) {
    if (isExpanded) {
      this.params.api.forEachNode((node) => {
        node.expanded = false;
      });
      this.params.api.onGroupExpandedOrCollapsed();
    } else {
      this.params.api.forEachNode((node) => {
        node.expanded = true;
      });
      this.params.api.onGroupExpandedOrCollapsed();
    }
  }
}
