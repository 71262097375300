import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ClosingPageState {
  adjustmentHasUnsavedChanges: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'closing-page' })
export class ClosingPageStore extends Store<ClosingPageState> {
  constructor() {
    super({
      adjustmentHasUnsavedChanges: false,
    });
  }
}
