@if (isOcrItemsEnabled() || isIntegrationItemsEnabled() || isMapActivitiesEnabled()) {
  <div class="text-xl font-semibold mb-4">Activities</div>

  @if (isMapActivitiesEnabled()) {
    <div class="text-sm mb-4">
      Review Invoice Line Items from different sources and map to budget activities.
    </div>

    <div class="flex">
      <div class="mr-10">
        <div class="text-aux-gray-dark-100">Invoice Total</div>
        <div class="font-bold">
          {{
            utils.currencyFormatter(
              invoice().expense_amounts.invoice_total.value,
              {},
              invoice().organization.currency
            )
          }}
        </div>
      </div>

      <div class="mr-10">
        <div class="text-aux-gray-dark-100">Allocated Services Total</div>
        <div class="font-bold">
          {{
            utils.currencyFormatter(
              invoiceAllocatedTotals$.getValue().ACTIVITY_SERVICE,
              {},
              invoice().organization.currency
            )
          }}
        </div>
      </div>

      <div class="mr-10">
        <div class="text-aux-gray-dark-100">Allocated Discount Total</div>
        <div class="font-bold">
          {{
            utils.currencyFormatter(
              invoiceAllocatedTotals$.getValue().ACTIVITY_DISCOUNT,
              {},
              invoice().organization.currency
            )
          }}
        </div>
      </div>

      <div class="mr-10">
        <div class="text-aux-gray-dark-100">Allocated Passthrough Total</div>
        <div class="font-bold">
          {{
            utils.currencyFormatter(
              invoiceAllocatedTotals$.getValue().ACTIVITY_PASSTHROUGH,
              {},
              invoice().organization.currency
            )
          }}
        </div>
      </div>

      <div>
        <div class="text-aux-gray-dark-100">Allocated Investigator Total</div>
        <div class="font-bold">
          {{
            utils.currencyFormatter(
              invoiceAllocatedTotals$.getValue().ACTIVITY_INVESTIGATOR,
              {},
              invoice().organization.currency
            )
          }}
        </div>
      </div>
    </div>
  }

  <aux-tab-group>
    @if (isMapActivitiesEnabled()) {
      <aux-tab label="Map Activities" pendoTag="map-activities-tab">
        <aux-map-activities-tab
          [invoice]="invoice()"
          [invoiceAllocatedTotals$]="invoiceAllocatedTotals$"
        />
      </aux-tab>
    }

    @if (isOcrItemsEnabled()) {
      <aux-tab label="Line Items (via PDF Parser)">
        <aux-invoice-items-via-pdf [invoice]="invoice()" />
      </aux-tab>
    }
    @if (isIntegrationItemsEnabled()) {
      <aux-tab label="Line Items (via Integration)">
        <aux-invoice-items-tab [invoice]="invoice()" [items]="invoice().line_items || []" />
      </aux-tab>
    }
  </aux-tab-group>
}
