import {
  CellClassParams,
  Column,
  EditableCallbackParams,
  FillOperationParams,
  GridOptions,
  HeaderClassParams,
  ICellEditorParams,
  IRowNode,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import { ArrElement, Utils } from '@shared/utils/utils';
import {
  Currency,
  InvoicePrepaidCandidate,
  listUserNamesWithEmailQuery,
} from '@shared/services/gql.service';
import dayjs from 'dayjs';
import { isString } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';
import { AgInvoiceDropdownComponent } from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/ag-invoice-dropdown/ag-invoice-dropdown.component';
import {
  AdjustmentsToPrepaidErrorRow,
  AdjustmentsToPrepaidRow,
  PrepaidTypeLabel,
} from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/adjustments-to-prepaid.model';
import {
  AgActionsComponent,
  AgActionsComponentParams,
} from '@shared/ag-components/ag-actions/ag-actions.component';
import {
  NewValueParams,
  ValueSetterParams,
} from '@ag-grid-community/core/dist/esm/es6/entities/colDef';
import { AgDatePickerComponent } from '@shared/ag-components/ag-date-picker/ag-date-picker.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AgExpandableGroupHeaderComponent } from '@pages/closing-page/tabs/quarter-close-adjustments/ag-expandable-group-header.component';
import {
  AgPrepaidTypeDropdownComponent,
  PrepaidTypeOptions,
} from '@pages/vendor-payments-page/tabs/prepaids/prepaid-detail/adjustments-to-prepaid/ag-prepaid-type-dropdown/ag-prepaid-type-dropdown.component';
import { decimalAdd } from '@shared/utils/floating-math';

const prepaidDateValueSetter = (field: keyof AdjustmentsToPrepaidRow) => {
  return (params: ValueSetterParams) => {
    if (params.newValue === null) {
      params.data[field] = null;
      return true;
    }

    if (dayjs(params.newValue).isValid()) {
      params.data[field] = dayjs(params.newValue).format('YYYY-MM-DD');
      return true;
    }

    return false;
  };
};

export const AdjustmentsToPrepaidGridOptions = (
  vendorCurrency: Currency,
  getAuthor: (id: string) => listUserNamesWithEmailQuery,
  isAdmin: boolean,
  editMode$: BehaviorSubject<boolean>,
  invoiceOptions: InvoicePrepaidCandidate[],
  getCellClasses: (classes?: string[]) => (params: CellClassParams) => string[],
  recalculateCells: (params: NewValueParams) => void,
  onCellValueChanged: (params: NewValueParams) => void,
  checkError: (
    params: CellClassParams<AdjustmentsToPrepaidRow>,
    cause: ArrElement<AdjustmentsToPrepaidErrorRow['causes']>
  ) => boolean,
  removeRow: (rowNode: IRowNode) => void,
  isCellEditable: (
    params: EditableCallbackParams<AdjustmentsToPrepaidRow> | AgActionsComponentParams
  ) => boolean,
  tooltipValueGetter: (params: ITooltipParams<AdjustmentsToPrepaidRow>) => string,
  currentOpenMonth: string
): GridOptions => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  noRowsOverlayComponent: TableConstants.NO_ROWS_MESSAGE,
  getRowId: ({ data }) => data.id,
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  suppressCellFocus: false,
  suppressMenuHide: true,
  fillHandleDirection: 'y',
  fillOperation: (params: FillOperationParams) => {
    if (params.column.getColId() === 'prepaid_type' || params.column.getColId() === 'invoice_id') {
      return params.currentCellValue;
    }

    if (
      params.column.getColId() === 'accrual_period' ||
      params.column.getColId() === 'payment_date' ||
      params.column.getColId() === 'invoice_date'
    ) {
      return params.values[0];
    }
    return false;
  },
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    headerClass: getHeaderClasses(editMode$),
    editable: (params) => editMode$.getValue() && !params.node.rowPinned && isCellEditable(params),
  },
  columnDefs: [
    {
      ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {};
        }

        return { component: AgActionsComponent, params };
      },
      cellRendererParams: (params: AgActionsComponentParams) => ({
        deleteClickFN: ({ rowNode }: { rowNode: IRowNode }) => removeRow(rowNode),
        deleteButtonDisabled$: of(!isCellEditable(params)),
        tooltipText$: of(
          !isCellEditable(params) ? MessagesConstants.PREPAIDS.ADJUSTMENT_IN_CLOSED_MONTH : ''
        ),
      }),
      maxWidth: 30,
      hide: !editMode$.getValue(),
    },
    {
      headerName: 'Prepaid Impact',
      headerClass: ['ag-header-align-center'],
      children: [
        {
          headerName: 'Type',
          field: 'prepaid_type',
          colId: 'prepaid_type',
          valueFormatter: Utils.dashFormatter,
          minWidth: 140,
          cellEditor: AgPrepaidTypeDropdownComponent,
          valueGetter: (params: ValueGetterParams) => {
            if (params.node?.rowPinned === 'bottom') {
              return params.data.prepaid_type;
            }

            return (
              PrepaidTypeLabel[params.data.prepaid_type as keyof typeof PrepaidTypeLabel] || ''
            );
          },
          valueSetter: (params) => {
            const newValue = !PrepaidTypeLabel[
              params.data.prepaid_type as keyof typeof PrepaidTypeLabel
            ]
              ? PrepaidTypeOptions.find(({ label }) => label === params.newValue)?.value
              : params.newValue;
            params.data.prepaid_type = newValue || null;
            return true;
          },
          onCellValueChanged: (params: NewValueParams) => {
            recalculateCells(params);
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) => checkError(params, 'prepaid_type'),
          },
          cellClass: getCellClasses(),
          tooltipValueGetter: (params) => tooltipValueGetter(params),
          suppressFillHandle: true,
        },
        {
          headerName: 'Total Impact to Prepaid',
          headerTooltip: 'Total Impact to Prepaid',
          field: 'total_impact_to_prepaid',
          colId: 'total_impact_to_prepaid',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          valueGetter: (params) => {
            return decimalAdd(
              params.data.short_term_amount || 0,
              params.data.long_term_amount || 0,
              2
            );
          },
          minWidth: 150,
          width: 150,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          editable: false,
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'total_impact_to_prepaid_increase') ||
              checkError(params, 'total_impact_to_prepaid_amortize') ||
              checkError(params, 'total_impact_to_prepaid_reclass') ||
              checkError(params, 'sum_cost_category_not_equal_total_impact'),
          },
        },
        {
          headerName: 'Short Term',
          headerTooltip: 'Short Term',
          field: 'short_term_amount',
          colId: 'short_term_amount',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 150,
          width: 150,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            recalculateCells(params);
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'short_term_increase') ||
              checkError(params, 'short_term_amortize'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Long Term',
          headerTooltip: 'Long Term',
          field: 'long_term_amount',
          colId: 'long_term_amount',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 150,
          width: 150,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            recalculateCells(params);
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'long_term_increase') || checkError(params, 'long_term_amortize'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
      ],
    },
    {
      headerName: 'Prepaid Cost Category',
      headerClass: ['ag-header-align-center'],
      children: [
        {
          headerName: 'Services',
          headerTooltip: 'Services',
          field: 'services_amount',
          colId: 'services_amount',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 125,
          width: 125,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'cost_category_amortize_increase') ||
              checkError(params, 'amortize_services_amount_positive') ||
              checkError(params, 'increase_services_amount_negative') ||
              checkError(params, 'sum_cost_category_not_equal_total_impact'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Passthrough',
          headerTooltip: 'Passthrough',
          field: 'passthrough_amount',
          colId: 'passthrough_amount',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 125,
          width: 125,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'cost_category_amortize_increase') ||
              checkError(params, 'amortize_passthrough_amount_positive') ||
              checkError(params, 'increase_passthrough_amount_negative') ||
              checkError(params, 'sum_cost_category_not_equal_total_impact'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Investigator',
          headerTooltip: 'Investigator',
          field: 'investigator_amount',
          colId: 'investigator_amount',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 125,
          width: 125,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) =>
              checkError(params, 'cost_category_amortize_increase') ||
              checkError(params, 'amortize_investigator_amount_positive') ||
              checkError(params, 'increase_investigator_amount_negative') ||
              checkError(params, 'sum_cost_category_not_equal_total_impact'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
      ],
    },
    {
      headerName: 'Invoice Details',
      headerClass: ['ag-header-align-center'],
      headerGroupComponent: AgExpandableGroupHeaderComponent,
      headerGroupComponentParams: {
        collapsedByDefault: true,
        localStorageKey: 'adjustments_to_prepaid_invoice_details',
        expandableCols: [
          'invoice_total',
          'total_expense_per_invoice',
          'invoice_date',
          'payment_date',
        ],
        filterCols: (column: Column) => {
          return !['invoice_id', 'accrual_period'].includes(column.getId());
        },
      },
      children: [
        {
          headerName: 'Invoice #',
          field: 'invoice_id',
          colId: 'invoice_id',
          valueFormatter: (params) => {
            return (params.value && params.data.invoice_no) || Utils.zeroHyphen;
          },
          minWidth: 200,
          cellEditor: AgInvoiceDropdownComponent,
          cellEditorParams: {
            options: invoiceOptions.length > 0 ? [{ id: null }, ...invoiceOptions] : [],
          },
          valueSetter: (params) => {
            const newValue = isString(params.newValue)
              ? invoiceOptions.find(({ id }) => id === params.newValue)
              : params.newValue;

            if (newValue?.id) {
              params.data.invoice_id = newValue.id;
              params.data.invoice_no = newValue.invoice_no;
              params.data.accrual_period = newValue.accrual_period;
              params.data.invoice_total = newValue.amount_total;
              params.data.invoice_date = newValue.invoice_date;
              params.data.payment_date = newValue.payment_date;
            } else {
              params.data.invoice_id = null;
              params.data.invoice_no = null;
              params.data.accrual_period = null;
              params.data.invoice_total = null;
              params.data.invoice_date = null;
              params.data.payment_date = null;
            }

            return true;
          },
          onCellValueChanged: (params: NewValueParams) => {
            recalculateCells(params);
            onCellValueChanged(params);
          },
          cellClass: getCellClasses(),
          cellClassRules: {
            'border-aux-error': (params) => checkError(params, 'increase_no_invoice'),
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
          comparator: (_, __, nodeA, nodeB) => {
            return (nodeA.data['invoice_no'] || '')
              .toLowerCase()
              .localeCompare((nodeB.data['invoice_no'] || '').toLowerCase());
          },
          suppressFillHandle: true,
        },
        {
          headerName: 'Posting Period',
          headerTooltip: 'Posting Period',
          field: 'accrual_period',
          colId: 'accrual_period',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? dayjs(params.value).format('MMMM YYYY') : Utils.zeroHyphen;
          },
          minWidth: 195,
          width: 195,
          suppressSizeToFit: true,
          cellEditor: AgDatePickerComponent,
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          cellEditorParams: (params: ICellEditorParams) => ({
            type: 'month',
            ignoreValidations: true,
            value: params.value || currentOpenMonth,
          }),
          cellClass: getCellClasses(['text-right']),
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          cellClassRules: {
            'border-aux-error': (params) => checkError(params, 'accrual_period_closed_month'),
          },
          cellDataType: 'text',
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Invoice Total',
          headerTooltip: 'Invoice Total',
          field: 'invoice_total',
          colId: 'invoice_total',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 125,
          width: 125,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          onCellValueChanged: (params: NewValueParams) => {
            recalculateCells(params);
            onCellValueChanged(params);
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Total Expense per Invoice',
          headerTooltip: 'Total Expense per Invoice',
          field: 'total_expense_per_invoice',
          colId: 'total_expense_per_invoice',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(params, vendorCurrency);
          },
          minWidth: 125,
          width: 125,
          cellClass: getCellClasses(['text-right']),
          cellDataType: 'number',
          editable: false,
        },
        {
          headerName: 'Invoice Date',
          headerTooltip: 'Invoice Date',
          field: 'invoice_date',
          colId: 'invoice_date',
          cellDataType: false,
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? dayjs(params.value).format('MM/DD/YYYY') : Utils.zeroHyphen;
          },
          valueSetter: prepaidDateValueSetter('invoice_date'),
          minWidth: 195,
          width: 195,
          suppressSizeToFit: true,
          cellEditor: AgDatePickerComponent,
          cellClass: getCellClasses(['text-right']),
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
        {
          headerName: 'Payment Date',
          headerTooltip: 'Payment Date',
          field: 'payment_date',
          colId: 'payment_date',
          cellDataType: false,
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? dayjs(params.value).format('MM/DD/YYYY') : Utils.zeroHyphen;
          },
          valueSetter: prepaidDateValueSetter('payment_date'),
          minWidth: 195,
          width: 195,
          suppressSizeToFit: true,
          cellEditor: AgDatePickerComponent,
          cellClass: getCellClasses(['text-right']),
          cellClassRules: {
            'border-aux-error': (params) => checkError(params, 'payment_date_closed_month'),
          },
          onCellValueChanged: (params: NewValueParams) => {
            onCellValueChanged(params);
          },
          tooltipValueGetter: (params) => tooltipValueGetter(params),
        },
      ],
    },
    {
      headerName: 'Created',
      headerClass: ['ag-header-align-center'],
      children: [
        {
          headerName: 'Created at',
          headerTooltip: 'Created at',
          field: 'create_date',
          colId: 'create_date',
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value ? dayjs(params.value).format('MM/DD/YYYY') : Utils.zeroHyphen;
          },
          minWidth: 150,
          width: 150,
          cellClass: getCellClasses(['text-right']),
          editable: false,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Created by',
          headerTooltip: 'Created by',
          field: 'created_by',
          colId: 'created_by',
          valueFormatter: (params: ValueFormatterParams) =>
            Utils.agUserFormatter(getAuthor(params.data.created_by), isAdmin),
          minWidth: 150,
          width: 150,
          tooltipValueGetter: (params) => params.valueFormatted,
          cellClass: getCellClasses(),
          editable: false,
          suppressFillHandle: true,
        },
      ],
    },
  ],
});

const getHeaderClasses =
  (editMode$: BehaviorSubject<boolean>) =>
  (params: HeaderClassParams): string | string[] => {
    if (!params.column?.getColId()) {
      return '';
    }

    if (
      params.column.getColId() === 'prepaid_type' ||
      params.column.getColId() === 'short_term_amount' ||
      params.column.getColId() === 'long_term_amount' ||
      params.column.getColId() === 'services_amount' ||
      params.column.getColId() === 'passthrough_amount' ||
      params.column.getColId() === 'investigator_amount' ||
      params.column.getColId() === 'invoice_id' ||
      params.column.getColId() === 'accrual_period' ||
      params.column.getColId() === 'invoice_total' ||
      params.column.getColId() === 'invoice_date' ||
      params.column.getColId() === 'payment_date'
    ) {
      return editMode$.getValue()
        ? ['ag-header-edit-mode', 'ag-header-align-center']
        : ['ag-header-align-center'];
    }

    return ['ag-header-align-center'];
  };
