import { setTimeout } from './timer';
import { callMonitored } from './monitor';
import { noop } from './utils/functionUtils';
/**
 * Instruments a method on a object, calling the given callback before the original method is
 * invoked. The callback receives an object with information about the method call.
 *
 * Note: it is generally better to instrument methods that are "owned" by the object instead of ones
 * that are inherited from the prototype chain. Example:
 * * do:    `instrumentMethod(Array.prototype, 'push', ...)`
 * * don't: `instrumentMethod([], 'push', ...)`
 *
 * @example
 *
 *  instrumentMethod(window, 'fetch', ({ target, parameters, onPostCall }) => {
 *    console.log('Before calling fetch on', target, 'with parameters', parameters)
 *
 *    onPostCall((result) => {
 *      console.log('After fetch calling on', target, 'with parameters', parameters, 'and result', result)
 *    })
 *  })
 */
export function instrumentMethod(targetPrototype, method, onPreCall) {
  var original = targetPrototype[method];
  var instrumentation = createInstrumentedMethod(original, onPreCall);
  var instrumentationWrapper = function () {
    if (typeof instrumentation !== 'function') {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return instrumentation.apply(this, arguments);
  };
  targetPrototype[method] = instrumentationWrapper;
  return {
    stop: function () {
      if (targetPrototype[method] === instrumentationWrapper) {
        targetPrototype[method] = original;
      } else {
        instrumentation = original;
      }
    }
  };
}
function createInstrumentedMethod(original, onPreCall) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return function () {
    var parameters = arguments;
    var result;
    var postCallCallback;
    callMonitored(onPreCall, null, [{
      target: this,
      parameters: parameters,
      onPostCall: function (callback) {
        postCallCallback = callback;
      }
    }]);
    if (typeof original === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      result = original.apply(this, parameters);
    }
    if (postCallCallback) {
      callMonitored(postCallCallback, null, [result]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  };
}
export function instrumentSetter(targetPrototype, property, after) {
  var originalDescriptor = Object.getOwnPropertyDescriptor(targetPrototype, property);
  if (!originalDescriptor || !originalDescriptor.set || !originalDescriptor.configurable) {
    return {
      stop: noop
    };
  }
  var stoppedInstrumentation = noop;
  var instrumentation = function (target, value) {
    // put hooked setter into event loop to avoid of set latency
    setTimeout(function () {
      if (instrumentation !== stoppedInstrumentation) {
        after(target, value);
      }
    }, 0);
  };
  var instrumentationWrapper = function (value) {
    originalDescriptor.set.call(this, value);
    instrumentation(this, value);
  };
  Object.defineProperty(targetPrototype, property, {
    set: instrumentationWrapper
  });
  return {
    stop: function () {
      var _a;
      if (((_a = Object.getOwnPropertyDescriptor(targetPrototype, property)) === null || _a === void 0 ? void 0 : _a.set) === instrumentationWrapper) {
        Object.defineProperty(targetPrototype, property, originalDescriptor);
      }
      instrumentation = stoppedInstrumentation;
    }
  };
}
