<div class="text-lg font-bold mb-4">Manage Balance Sheet Accounts</div>

<div class="w-[622px]">
  <div class="flex items-center space-x-4">
    <div>
      <p class="mb-2 text-xs">Vendor</p>
      <ng-select
        placeholder="Select"
        id="vendor"
        class="w-[290px]"
        [formControl]="vendorControl"
        [appendTo]="'body'"
        [searchable]="true"
        [clearable]="false"
      >
        <ng-option *ngFor="let vendor of vendors$ | async" [value]="vendor.vendor_id">
          <span [title]="vendor.name">{{ vendor.name }}</span>
        </ng-option>
      </ng-select>
    </div>

    <aux-checkbox [(ngModel)]="isApplyToAllVendors" class="mt-[18px]">
      <div class="text-sm">Apply to All Vendors</div>
    </aux-checkbox>
  </div>

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">Accrued Liabilities:</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.accruedLiabilities"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">Short Term Prepaid:</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.prepaidExpenses"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <h4 class="mb-3 text-sm font-bold text-aux-black">Long Term Prepaid:</h4>

  <aux-entity-form-group-row
    [formGroup]="form.controls.longTermPrepaid"
    [disabled]="!vendorControl.value"
  />

  <hr class="my-4" />

  <ng-container *ngIf="isApManualClearingVisible$ | async">
    <h4 class="mb-3 text-sm font-bold text-aux-black">AP Manual (Clearing):</h4>

    <aux-entity-form-group-row
      [formGroup]="form.controls.accountsPayable"
      [disabled]="!vendorControl.value"
    />
  </ng-container>
</div>
