@if (!customCurvesQuery.curves().length) {
  <div class="flex flex-col justify-center items-center mt-4 mb-4 custom-curves-no-rows-overlay">
    <div class="text-2xl font-bold">No Custom Curves Exist!</div>
    <div class="mt-1 text-lg">Create custom curves to use as custom forecast drivers.</div>
    <aux-button
      label="Curve"
      icon="Plus"
      class="create-curve__button mt-5"
      pendoTag="custom-curves-create-button"
      (click)="onCreateButtonClick()"
      [disabled]="!hasModifyCustomCurvePermission()"
      [auxTooltip]="noPermTooltip()"
    />
  </div>
} @else {
  <div class="flex justify-center items-center custom-curves-no-rows-overlay">No Rows To Show</div>
}
