import { sendToExtension, timeStampNow } from '@datadog/browser-core';
import { RecordType, IncrementalSource } from '../../types';
import * as replayStats from '../replayStats';
import { assembleIncrementalSnapshot } from './assembly';
import { initObservers } from './observers';
import { createElementsScrollPositions } from './elementsScrollPositions';
import { initShadowRootsController } from './shadowRootsController';
import { startFullSnapshots } from './startFullSnapshots';
export function record(options) {
  var emit = options.emit,
    configuration = options.configuration,
    lifeCycle = options.lifeCycle;
  // runtime checks for user options
  if (!emit) {
    throw new Error('emit function is required');
  }
  var emitAndComputeStats = function (record) {
    emit(record);
    sendToExtension('record', {
      record: record
    });
    var view = options.viewContexts.findView();
    replayStats.addRecord(view.id);
  };
  var elementsScrollPositions = createElementsScrollPositions();
  var mutationCb = function (mutation) {
    emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.Mutation, mutation));
  };
  var inputCb = function (s) {
    return emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.Input, s));
  };
  var shadowRootsController = initShadowRootsController(configuration, {
    mutationCb: mutationCb,
    inputCb: inputCb
  });
  var stopFullSnapshots = startFullSnapshots(elementsScrollPositions, shadowRootsController, lifeCycle, configuration, flushMutations, function (records) {
    return records.forEach(function (record) {
      return emitAndComputeStats(record);
    });
  }).stop;
  function flushMutations() {
    shadowRootsController.flush();
    flushMutationsFromObservers();
  }
  var _a = initObservers(configuration, {
      lifeCycle: options.lifeCycle,
      configuration: configuration,
      elementsScrollPositions: elementsScrollPositions,
      inputCb: inputCb,
      mediaInteractionCb: function (p) {
        return emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.MediaInteraction, p));
      },
      mouseInteractionCb: function (mouseInteractionRecord) {
        return emitAndComputeStats(mouseInteractionRecord);
      },
      mousemoveCb: function (positions, source) {
        return emitAndComputeStats(assembleIncrementalSnapshot(source, {
          positions: positions
        }));
      },
      mutationCb: mutationCb,
      scrollCb: function (p) {
        return emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.Scroll, p));
      },
      styleSheetCb: function (r) {
        return emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.StyleSheetRule, r));
      },
      viewportResizeCb: function (d) {
        return emitAndComputeStats(assembleIncrementalSnapshot(IncrementalSource.ViewportResize, d));
      },
      frustrationCb: function (frustrationRecord) {
        return emitAndComputeStats(frustrationRecord);
      },
      focusCb: function (data) {
        return emitAndComputeStats({
          data: data,
          type: RecordType.Focus,
          timestamp: timeStampNow()
        });
      },
      visualViewportResizeCb: function (data) {
        emitAndComputeStats({
          data: data,
          type: RecordType.VisualViewport,
          timestamp: timeStampNow()
        });
      },
      viewEndCb: function (viewEndRecord) {
        flushMutations();
        emitAndComputeStats(viewEndRecord);
      },
      shadowRootsController: shadowRootsController
    }),
    stopObservers = _a.stop,
    flushMutationsFromObservers = _a.flush;
  return {
    stop: function () {
      shadowRootsController.stop();
      stopObservers();
      stopFullSnapshots();
    },
    flushMutations: flushMutations,
    shadowRootsController: shadowRootsController
  };
}
