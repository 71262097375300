import { inject } from '@angular/core';
import { combineLatest, firstValueFrom } from 'rxjs';
import { BudgetType, GqlService, listCategorySettingsQuery } from '@shared/services/gql.service';
import {
  CompareBudgetVersion,
  CompareGridData,
} from '@widgets/compare-budget/compare-budget.component';
import { BudgetDataType } from '@features/budget/services/budget-grid.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { BudgetService } from '@features/budget/services/budget.service';
import { getEncodedAttributeName } from '@features/budget-attributes/services/budget-attributes.service';
import { Maybe } from '@shared/utils/utils';

// todo(AUXI-7398) look at if we can use it in compare grid widget to get rid of duplicate code
export const injectChangeOrderGridFetchBudgetData = (
  gqlService = inject(GqlService),
  budgetService = inject(BudgetService)
) => {
  const fetchBudgetData = async ({
    organization,
    from,
    to,
    changeOrderID,
  }: {
    organization: OrganizationModel;
    from: CompareBudgetVersion;
    to: Maybe<CompareBudgetVersion>;
    changeOrderID: string;
  }) => {
    const fromProm = firstValueFrom(
      gqlService
        .listBudgetGrid$({
          in_month: false,
          budget_type: from.budget_type,
          vendor_id: organization.id,
          budget_version_id: from.budget_version_id,
        })
        .pipe(budgetService.budgetCacheMechanism())
    );
    const toProm = to
      ? firstValueFrom(
          combineLatest([
            gqlService
              .listBudgetGrid$({
                in_month: false,
                budget_type: to.budget_type,
                vendor_id: organization.id,
                budget_version_id: to.budget_version_id,
              })
              .pipe(budgetService.budgetCacheMechanism()),
            gqlService.listCategorySettings$({
              vendor_id: organization.id,
              budget_type: BudgetType.BUDGET_CHANGE_ORDER,
              change_order_id: changeOrderID,
            }),
          ])
        )
      : Promise.resolve([
          { success: true, data: null, errors: [] },
          { success: true, data: null, errors: [] },
        ]);

    const [fromResp, [toResp, categories]] = await Promise.all([fromProm, toProm]);

    const budget_data: CompareGridData[] = [];
    const last_budget_data: CompareGridData[] = [];

    if (!fromResp.success || !toResp.success || !categories.success) {
      return {
        budget_data,
        doesBudgetDataHaveMissingActivities: false,
      };
    }

    const added_activities: Set<string> = new Set<string>();

    let doesBudgetDataHaveMissingActivities = false;
    const from_data = fromResp.data?.budget_data || [];
    const to_data = toResp.data?.budget_data || [];

    for (let i = 0; i < from_data.length; i++) {
      const from_current_row: BudgetDataType = from_data[i];
      let current_activity_id = '';
      if (
        from_current_row?.activity_name &&
        from_current_row?.activity_id &&
        from_current_row?.group_index !== null // group index can be 0
      ) {
        current_activity_id = from_current_row?.activity_id;
        added_activities.add(`${current_activity_id}`);
      }

      const to_current_row: BudgetDataType | null =
        to_data.filter((toBudgetData) => toBudgetData.activity_id === current_activity_id)[0] ||
        null;

      const variance_unit =
        (to_current_row?.unit_num || 0) - (from_current_row?.unit_num || 0) || 0;
      const variance_unit_cost =
        (to_current_row?.contract_unit_cost || 0) - (from_current_row?.contract_unit_cost || 0) ||
        0;
      const variance_total_cost =
        (to_current_row?.contract_direct_cost || 0) -
          (from_current_row?.contract_direct_cost || 0) || 0;
      const variance_total_percent = !from_current_row?.contract_direct_cost
        ? 0
        : (variance_total_cost || 0) / (from_current_row?.contract_direct_cost || 1);

      if (from_current_row && !to_current_row) {
        doesBudgetDataHaveMissingActivities = true;
      }

      const getField = <T extends keyof BudgetDataType>(field: T): BudgetDataType[T] => {
        return to_current_row ? to_current_row[field] : from_current_row[field];
      };

      let category_id = from_current_row?.category_id || '';

      if (to_current_row && categories.data) {
        const keys = ['group0', 'group1', 'group2', 'group3', 'group4'] as const;
        let category = categories.data;
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const cat_name = to_current_row[key];

          if (!cat_name) {
            break;
          }

          const sub_cat = category.categories.find(
            (c) => c.name === cat_name
          ) as listCategorySettingsQuery;
          if (!sub_cat) {
            // throw error
            break;
          }
          category = sub_cat;
        }

        if (category.id !== categories.data.id) {
          category_id = category.id;
        }
      }

      const data = {
        ...from_current_row,
        category_id,
        from_row: from_current_row,
        to_row: to_current_row,
        from_activity_no: from_current_row?.activity_no || '',
        from_unit: from_current_row?.unit_num || 0,
        from_unit_cost: from_current_row?.contract_unit_cost || 0,
        from_total_cost: from_current_row?.contract_direct_cost || 0,
        to_activity_no: to_current_row?.activity_no || '',
        to_unit: to_current_row?.unit_num || 0,
        to_unit_cost: to_current_row?.contract_unit_cost || 0,
        to_total_cost: to_current_row?.contract_direct_cost || 0,
        to_display_label: to_current_row?.display_label || '',
        to_uom: to_current_row?.uom || '',
        variance_unit,
        variance_unit_cost,
        variance_total_cost,
        variance_total_percent,
        missing_activity: from_current_row && !to_current_row,
        changed: !!variance_unit || !!variance_unit_cost || !!variance_total_cost,
        activity_name: getField('activity_name'),
        activity_name_label: getField('activity_name_label'),
        group0: getField('group0'),
        group1: getField('group1'),
        group2: getField('group2'),
        group3: getField('group3'),
        group4: getField('group4'),
        attributes: getField('attributes'),
      };

      if (to_current_row) {
        if (
          from_current_row.group0 !== to_current_row.group0 ||
          from_current_row.group1 !== to_current_row.group1 ||
          from_current_row.group2 !== to_current_row.group2 ||
          from_current_row.group3 !== to_current_row.group3 ||
          from_current_row.group4 !== to_current_row.group4
        ) {
          last_budget_data.push(data);
          continue;
        }
      }

      budget_data.push(data);
    }
    budget_data.push(...last_budget_data);

    for (let i = 0; i < to_data.length; i++) {
      const to_row = to_data[i];
      if (
        to_row.activity_name &&
        to_row.activity_id &&
        to_row.group_index !== null &&
        !added_activities.has(`${to_row.activity_id}`)
      ) {
        budget_data.push({
          ...to_row,
          to_row,
          from_activity_no: '',
          from_unit: 0,
          from_unit_cost: 0,
          from_total_cost: 0,
          to_activity_no: to_row?.activity_no || '',
          to_unit: to_row?.unit_num || 0,
          to_unit_cost: to_row?.contract_unit_cost || 0,
          to_total_cost: to_row?.contract_direct_cost || 0,
          to_display_label: to_row?.display_label || '',
          to_uom: to_row?.uom || '',
          variance_unit: to_row.unit_num || 0,
          variance_unit_cost: to_row.contract_unit_cost || 0,
          variance_total_cost: to_row.contract_direct_cost || 0,
          variance_total_percent: 0,
          missing_activity: false,
          changed:
            !!to_row.unit_num || !!to_row.contract_unit_cost || !!to_row.contract_direct_cost,
        });
      }
    }

    budget_data.sort((a, b) => {
      const aIndex = a.cost_category_ordering || 0;
      const bIndex = b.cost_category_ordering || 0;
      return aIndex - bIndex;
    });

    return {
      budget_data: <CompareGridData[]>budget_data
        .map((row: CompareGridData) =>
          row.cost_category === 'Discount'
            ? {
                ...row,
                from_unit: 0,
                from_unit_cost: 0,
                to_unit: 0,
                to_unit_cost: 0,
                variance_unit: 0,
                variance_unit_cost: 0,
              }
            : row
        )
        .map((row) => {
          const extraAttributes = row.attributes?.reduce(
            (acc, a) => {
              if (a.attribute_name && a.attribute_value) {
                acc[getEncodedAttributeName(a.attribute_name)] = a.attribute_value;
              }

              return acc;
            },
            {} as Record<string, string>
          );

          return { ...row, ...extraAttributes };
        }),
      doesBudgetDataHaveMissingActivities: to ? doesBudgetDataHaveMissingActivities : false,
    };
  };

  return fetchBudgetData;
};
