import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  BudgetCompareMenuFlatten$,
  BudgetCompareMenuVendorChangeFn,
  BudgetCompareMenuVendorFormControl,
  BudgetCompareMenuVendorLoading,
  defaultVendorChangeFn,
} from '../../models/budget-compare-menu.models';
import { VendorDropdownComponent } from '@shared/components/vendor-dropdown/vendor-dropdown.component';

@Component({
  selector: 'aux-budget-compare-menu-vendor',
  templateUrl: './budget-compare-menu-vendor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [VendorDropdownComponent, ReactiveFormsModule],
})
export class BudgetCompareMenuVendorComponent {
  @Input() vendorFormControl: BudgetCompareMenuVendorFormControl;

  @Input() vendorList: BudgetCompareMenuFlatten$<typeof this.organizationQuery.allVendors$>;

  @Input() vendorLoading: BudgetCompareMenuVendorLoading;

  @Input() vendorChangeFn: BudgetCompareMenuVendorChangeFn;

  constructor(private organizationQuery: OrganizationQuery) {
    this.vendorFormControl = new FormControl<string>('', { nonNullable: true });
    this.vendorList = [];
    this.vendorLoading = false;
    this.vendorChangeFn = defaultVendorChangeFn;
  }
}
