import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { AsyncPipe, NgIf } from '@angular/common';

interface AgVendorEstimateCellParams extends ICellRendererParams {
  editMode$: BehaviorSubject<boolean>;
  isVendorEstimateLocked$: BehaviorSubject<boolean>;
  hasEditVendorEstimatePermission$: BehaviorSubject<boolean>;
  dataPendoId: string;
}

@Component({
  selector: 'aux-ag-vendor-estimate-cell',
  template: `
    <div
      *ngIf="disabled"
      class="absolute w-full h-full left-0 bg-transparent"
      auxTooltip
      [template]="
        (params.editMode$ | async) === true &&
        (params.hasEditVendorEstimatePermission$ | async) !== true
          ? permissionTemplate
          : (params.isVendorEstimateLocked$ | async) === true
            ? noteTemplate
            : undefined
      "
    ></div>

    <ng-template #noteTemplate>
      <div class="space-y-1">
        {{ tooltipText }}
      </div>
    </ng-template>

    <ng-template #permissionTemplate>
      <div class="space-y-1">
        {{ doNotHavePermissionMsg }}
      </div>
    </ng-template>

    <div>
      <span>{{ params.valueFormatted }}</span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [TooltipDirective, NgIf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgVendorEstimateCellComponent implements ICellRendererAngularComp {
  params!: AgVendorEstimateCellParams;

  readonly tooltipText = MessagesConstants.VENDOR_ESTIMATES_LOCKED;

  readonly doNotHavePermissionMsg = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  @HostBinding('style.opacity')
  get opacity(): number {
    return this.disabled ? 0.7 : 1;
  }

  get disabled(): boolean {
    const inEditMode = this.params.editMode$ && this.params.editMode$.value;
    const hasPermission =
      this.params.hasEditVendorEstimatePermission$ &&
      this.params.hasEditVendorEstimatePermission$.value;
    const notInTotalGroupRow =
      !this.params.node.group && this.params.node.data.activity_name !== 'Total';
    const vendorEstimateLocked =
      this.params.isVendorEstimateLocked$ && this.params.isVendorEstimateLocked$.value;
    if (!hasPermission) {
      return inEditMode;
    } else {
      return inEditMode && notInTotalGroupRow && vendorEstimateLocked;
    }
  }

  refresh(): boolean {
    return false;
  }

  agInit(params: AgVendorEstimateCellParams) {
    this.params = params;
    params.eGridCell.setAttribute('data-pendo-id', params.dataPendoId);
  }
}
