<div class="flex space-between mb-6 mt-6 gap-10">
  <div class="task-instructions w-full">
    <aux-checklist-notes-editor
      [type]="Notes.TaskInstructions"
      [checklistAdminPermission]="checklistAdminPermission"
      [workflow$]="workflow$"
    ></aux-checklist-notes-editor>
  </div>
  <div class="separator w-px min-w-px"></div>
  <div class="month-review-notes w-full">
    <aux-checklist-notes-editor
      [type]="Notes.MonthlyReviewNotes"
      [checklistAdminPermission]="checklistAdminPermission"
      [workflow$]="workflow$"
    ></aux-checklist-notes-editor>
  </div>
</div>
