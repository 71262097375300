<div
  *ngIf="!!option"
  class="group relative py-[8px] leading-[17px] hover:bg-aux-blue-light-50 font-medium snap-end rounded-[4px] mb-px"
  [ngClass]="{
    'cursor-pointer': !option.disabled,
    'cursor-not-allowed': option.disabled,
    'bg-aux-blue-light-50': isAddMode || isEditMode
  }"
>
  <div class="flex items-center relative">
    <div
      *ngIf="isEditCategoryBtnVisible()"
      class="absolute left-0.5 flex items-center opacity-0 group-hover:opacity-100 cursor-pointer"
    >
      <aux-icon name="Pencil" class="text-aux-blue" (click)="onEditMode()" />
    </div>

    <div
      *ngIf="!isEditMode"
      class="flex-grow truncate"
      [ngStyle]="{
        paddingLeft: option.indent * 13 + 'px'
      }"
      [auxTooltip]="
        option.disabledTooltip
          ? option.disabledTooltip
          : opt.offsetWidth < opt.scrollWidth
            ? option.name
            : ''
      "
      [auxTooltipPositions]="tooltipPositions"
      #opt
      (click)="onDropdownSelected()"
    >
      {{ option.name }}
    </div>

    <div
      *ngIf="isAddCategoryBtnVisible()"
      class="flex items-center px-1.5 opacity-0 group-hover:opacity-100 cursor-pointer"
    >
      <aux-icon name="Plus" class="text-aux-blue" (click)="onAddMode()" />
    </div>
  </div>

  <div
    *ngIf="isAddMode || isEditMode"
    class="px-1.5"
    [ngClass]="{
      'pt-[8px]': isAddMode
    }"
  >
    <input
      #categoryInput
      id="categoryNameControl"
      [formControl]="categoryNameControl"
      type="text"
      [ngStyle]="{
        paddingLeft: (option.indent + (isAddMode ? 1 : 0)) * 13 + 'px'
      }"
      class="inline-category-control w-full border-0 outline-0 focus:shadow-none focus:shadow-transparent placeholder-italic font-normal"
      placeholder="New Category Name"
      (keyup.enter)="updateCategoryList()"
      (keyup.tab)="updateCategoryList()"
    />
  </div>

  <div
    *ngIf="isAddMode || isEditMode"
    class="flex absolute right-0 top-full bg-aux-blue-light-50 z-[1] shadow-sm leading-none cursor-pointer"
  >
    <div
      class="p-0.5"
      [ngClass]="{
        'opacity-30': isUpdateCategoryDisabled()
      }"
      [auxTooltip]="updateCategoryBtnTooltip()"
      (click)="updateCategoryList()"
    >
      <aux-icon name="Check" class="text-aux-green-dark" />
    </div>

    <div class="p-0.5 border-l" (click)="offUpdateMode()">
      <aux-icon name="X" class="text-aux-red-dark" />
    </div>
  </div>
</div>
