import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@shared/services/launch-darkly.service';
import { BudgetPageComponent } from './budget-page.component';
import { CompareNewComponent } from './tabs/compare-new/compare-new.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { CisLogComponent } from './tabs/cis-log/cis-log.component';
import { BudgetLibraryComponent, BudgetEnhancedComponent } from './tabs';
import { ChangeOrderComponent } from './tabs/change-order/change-order.component';
import { ChangeOrderDetailComponent } from './tabs/change-order-detail/change-order-detail.component';

export const BUDGET_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.BUDGET.LIBRARY,
    component: BudgetLibraryComponent,
    data: {
      feature: FeatureFlag.BUDGET_LIBRARY,
    },
  },
  {
    path: ROUTING_PATH.BUDGET.CHANGE_LOG,
    component: CisLogComponent,
    data: {
      feature: FeatureFlag.BUDGET_CHANGE_LOG,
    },
  },
  {
    path: ROUTING_PATH.BUDGET.CHANGE_ORDER,
    component: ChangeOrderComponent,
    data: {
      feature: FeatureFlag.BUDGET_CHANGE_ORDER,
    },
  },
  {
    path: `${ROUTING_PATH.BUDGET.CHANGE_ORDER}/:id`,
    component: ChangeOrderDetailComponent,
    data: {
      feature: FeatureFlag.BUDGET_CHANGE_ORDER,
    },
  },
  {
    path: ROUTING_PATH.BUDGET.COMPARE,
    component: CompareNewComponent,
    data: {
      feature: FeatureFlag.COMPARE,
    },
  },
];

export const buildBudgetRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.BUDGET.INDEX,
      component: BudgetEnhancedComponent,
      data: {
        feature: featureFlags?.budget_actuals_to_date_units,
      },
    },
    ...buildRoutesByFeatureFlags(featureFlags, BUDGET_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.BUDGET.INDEX,
    component: BudgetPageComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.BUDGET.INDEX }],
  };
};
