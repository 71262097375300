import { Component, inject } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';
import { InputComponent } from '@shared/components/input/input.component';

@Component({
  template: `
    <form [formGroup]="formGroup" (submit)="onSubmit()">
      <aux-modal primaryButtonType="submit">
        <p>{{ ref.data.message }}</p>
        <aux-input
          [label]="ref.data.textAreaLabel || 'Reason'"
          formControlName="reason"
          [textArea]="true"
          [rows]="2"
          class="text-xs mt-4"
          [validators]="'required'"
        />
      </aux-modal>
    </form>
  `,
  standalone: true,
  imports: [ModalComponent, ReactiveFormsModule, InputComponent],
})
export class ConfirmationWithReasonModalComponent {
  readonly ref = inject(CustomOverlayRef);

  private readonly formBuilder = inject(UntypedFormBuilder);

  formGroup = this.formBuilder.group({
    reason: ['', Validators.required],
  });

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.ref.close(this.formGroup.value);
  }
}
