<ng-container>
  <div>
    <aux-export-excel-button
      [gridAPI]="exportGridApi"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams"
      [onExportSuccess]="onExportSuccess"
      [hidden]="exporting$ | async"
      pendoTag="data-streaming-demo-excel-export-button"
    />
    <ng-container *ngIf="exporting$ | async">
      <div class="border-2 h-8 m-auto mt-10 spinner w-8"></div>
    </ng-container>
  </div>
  <form [formGroup]="gridFiltersFormGroup">
    <div class="flex items-start filters">
      <div class="max-w-sm w-full">
        <div class="h-5 mb-1"></div>
        <aux-input
          placeholder="Search"
          formControlName="search"
          icon="Search"
          class="w-full"
        ></aux-input>
      </div>
      <div class="pl-4">
        <span class="text-xs mb-1">Document Type:</span>
        <ng-select
          class="w-60 tabular-nums"
          [multiple]="true"
          [clearable]="true"
          [searchable]="false"
          placeholder="All"
          formControlName="documentTypes"
          [items]="documentTypeFilterOptions"
          bindValue="value"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length === 1">
              <div class="flex" *ngFor="let item of items | slice: 0 : 1">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label overflow-hidden text-ellipsis">{{
                  $any(item).label
                }}</span>
              </div>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Selected</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="pl-4">
        <span class="text-xs mb-1">Vendor:</span>
        <ng-select
          class="w-60 tabular-nums"
          [multiple]="true"
          [clearable]="true"
          [searchable]="false"
          placeholder="All"
          formControlName="vendors"
          [items]="vendorFilterOptions"
          bindValue="value"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length === 1">
              <div class="flex" *ngFor="let item of items | slice: 0 : 1">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label overflow-hidden text-ellipsis">{{
                  $any(item).label
                }}</span>
              </div>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Selected</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="pl-4">
        <span class="text-xs mb-1">Site:</span>
        <ng-select
          class="w-60 tabular-nums"
          [multiple]="true"
          [clearable]="true"
          [searchable]="false"
          placeholder="All"
          formControlName="sites"
          [items]="siteFilterOptions"
          bindValue="value"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngIf="items.length === 1">
              <div class="flex" *ngFor="let item of items | slice: 0 : 1">
                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                <span class="ng-value-label overflow-hidden text-ellipsis">{{
                  $any(item).label
                }}</span>
              </div>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{ items.length }} Selected</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="pl-4">
        <span class="text-xs mb-1">From:</span>
        <aux-input
          [type]="'date'"
          placeholder="YYYY-MM-DD"
          formControlName="dateFrom"
          class="w-36"
        ></aux-input>
      </div>
      <div class="pl-4">
        <span class="text-xs mb-1">To:</span>
        <aux-input
          [type]="'date'"
          placeholder="YYYY-MM-DD"
          formControlName="dateTo"
          class="w-36"
        ></aux-input>
      </div>
      <button
        class="pt-8 pl-4 focus:outline-none btn--hyperlink whitespace-nowrap"
        (click)="onResetForm()"
      >
        Clear All
      </button>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="datasource.loading$ | async">
  <div class="border-2 h-8 m-auto mt-10 spinner w-8"></div>
</ng-container>

<!--
  You can't use an ngIf directive with a grid that utilizes the DatasourceService.
  When the grid starts loading data (datasource.loading$ = true) the ngIf would remove the grid from the DOM. Then when
  the data fetch is complete, the ag grid component would be recreated and it would start this cycle all over again every second.

  So, we have to use the [hidden] attribute instead. This way the grid that currently owns the datasource remains intact
  when the grid is made invisible. And, then it can successfully process the data fetched by the datasource.

  imho we should prefer [hidden] whenever we need to make the ag-grid invisible while data is being downloaded from the backend.
  I mean it doesn't feel like it is worth destroying the grid and then recreating it 100 milliseconds later when the backend
  request is completed. With ngIf the grid will initialize again i.e. it just pays the overhead cost of creating a brand new
  grid again and again (apply grid options, invoke ongridready again, other handlers ...).
-->
<div [hidden]="datasource.loading$ | async" style="margin-top: 10px; width: 100%">
  <ag-grid-angular
    class="ag-theme-aux document-library-table tabular-nums"
    domLayout="autoHeight"
    [gridOptions]="$any(gridOptions$ | async)"
    (gridReady)="onGridReady($event)"
    (sortChanged)="onSortChanged()"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    auxStickyGrid
  >
  </ag-grid-angular>
  <aux-pagination-panel
    *ngIf="(datasource.totalRows$ | async) || 0"
    [paginationPageSize]="gridApi.paginationGetPageSize()"
    [gridApi]="gridApi"
    [totalItems]="(datasource.totalRows$ | async) || 0"
  />
</div>

<!--
  Additional hidden grid for exports
-->
<div [hidden]="true">
  <ag-grid-angular
    class="ag-theme-aux document-library-table tabular-nums"
    [gridOptions]="$any(exportGridOptions$ | async)"
  >
  </ag-grid-angular>
</div>
