import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import { ChangeLogItemStatus } from '@shared/services/gql.service';
import { ChangeLogItemStatusConstants } from '@shared/constants/status.constants';

@Component({
  template: `
    <div
      class="flex items-center whitespace-nowrap"
      [ngClass]="{
        'justify-center': params.data.change_log_item_status === this.noStatus
      }"
    >
      <div class="w-3 h-3 rounded-full mr-1" [ngClass]="bgClass"></div>
      <div [ngClass]="textClass">{{ status }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogItemStatusComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  noStatus = ChangeLogItemStatus.STATUS_NO_STATUS;

  STATUS_CONFIG = ChangeLogItemStatusConstants.config;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const status: ChangeLogItemStatus | string = params.data.change_log_item_status || '';

    const types = this.STATUS_CONFIG.find((config) => {
      return status === config.value;
    });
    this.bgClass = types?.circleClass || '';
    this.textClass = types?.textClass || '';
    this.status = types?.text || Utils.zeroHyphen;
  }
}
