@if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
  <aux-workflow-panel
    className="my-4"
    [workflowName]="workflowName"
    [processing]="iCloseMonthsProcessing()!"
    [hasUserPermissions]="userHasLockPrepaidPermission()"
  />
}

@if (loadingGridData()) {
  <aux-table-skeleton />
} @else {
  <div class="flex justify-end mb-[20px]">
    <aux-export-excel-button
      classList="ml-auto"
      [clickFN]="onExport.bind(this)"
      [spinnerSize]="7"
      pendoTag="prepaid-summary-excel-export-button"
    />
  </div>

  <ag-grid-angular
    domLayout="autoHeight"
    class="ag-theme-aux tabular-nums w-full"
    [rowData]="gridData()"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    auxStickyGrid
  />
}
