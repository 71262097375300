<div
  *ngFor="let task of tasks; index as index"
  class="px-3 py-4 flex items-center justify-between hover:bg-aux-blue-light-50 cursor-pointer"
  [ngClass]="{
    'bg-aux-gray-light': index % 2 === 0
  }"
  (click)="navigateToTask(task)"
>
  <div>
    <p>{{ task.task_header }}</p>
    <p class="text-sm">{{ task.trial_name }}</p>
    <p *ngIf="task.organization_name" class="text-sm">Vendor: {{ task.organization_name }}</p>
  </div>
  <div class="flex items-center">
    <div>
      <p class="text-sm text-right">{{ task.create_date | timeago }}</p>
    </div>
    <aux-icon name="ChevronRight" class="ml-3 text-aux-blue" />
  </div>
</div>

<div *ngIf="!tasks.length" class="flex flex-col items-center justify-center h-full">
  <span class="ml-3 text-aux-blue">
    <!-- todo stroke-linecap="round" stroke-linejoin="round" -->
    <aux-icon name="Check" class="text-aux-gray-dark stroke-[10px]" />
  </span>
  <p class="text-sm text-aux-gray-darkest mt-4">{{ noTasksMessage }}</p>
</div>
