import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { UntypedFormControl } from '@angular/forms';
import { BudgetSnapshotType, EventType } from '@shared/services/gql.service';
import { EventQuery } from '@models/event/event.query';
import { switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { SnapshotService } from '@features/budget/services/snapshot.service';
import { BudgetStore } from '@models/budget/budget.store';
import { SnapshotModalComponent } from '@features/snapshot-modal/snapshot-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'aux-compare-dropdown',
  templateUrl: './compare-dropdown.component.html',
  styles: [
    `
      ::ng-deep .ng-option-selected::after {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareDropdownComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() initialValue?: string;

  @Input() disabled?: boolean;

  formControl = new UntypedFormControl();

  loading$ = this.snapshotService.loading$;

  snapshots$ = this.snapshotService.getSnapShotVersions();

  BUDGET_SNAPSHOT_USER_CREATED = BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED;

  @Input() refreshTable!: VoidFunction;

  @Output() valueChange = new EventEmitter<string>();

  constructor(
    private snapshotService: SnapshotService,
    private overlayService: OverlayService,
    private budgetStore: BudgetStore,
    public overlay: Overlay,
    private eventQuery: EventQuery
  ) {}

  ngOnInit(): void {
    this.eventQuery
      .selectProcessingEvent$(EventType.CREATE_TRIAL_BUDGET_SNAPSHOT)
      .subscribe((isProcessing) => {
        if (isProcessing === false) {
          this.overlayService.showFieldTooltip(
            'compare-snapshot-dropdown',
            'Snapshot successfully created'
          );
          firstValueFrom(this.snapshotService.getSnapshotList());
        }
      });
    this.formControl.setValue(this.initialValue);

    this.snapshots$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((options) => {
          const isValueExist = options.find(({ value }) => value === this.formControl.value);

          if (!isValueExist) {
            this.formControl.setValue(undefined);
          }
        })
      )
      .subscribe();

    this.loading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((loading) => {
      if (loading || this.disabled) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    });

    this.formControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((snapshotName) => {
          this.valueChange.emit(snapshotName);
          if (!snapshotName) {
            return of();
          }

          return this.snapshotService.getBudgetSnapshots(snapshotName);
        })
      )
      .subscribe();
  }

  groupByFn(option: {
    label: string;
    value: string;
    create_date: string;
    snapshot_type: string;
    id: string;
  }) {
    switch (option.snapshot_type) {
      case BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED:
        return 'MANUAL SNAPSHOTS';
      default:
        return 'AUTOMATIC SNAPSHOTS ';
    }
  }

  async editSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();
    const name = snapshot.label;
    const response = await firstValueFrom(
      this.overlayService.openPopup<{ name?: string }, { name?: string }>({
        modal: SnapshotModalComponent,
        settings: { header: 'Name Budget Snapshot' },
        data: { name },
      }).afterClosed$
    );
    if (response.data?.name) {
      const n = response.data.name;
      await this.emitUpdatedValue(
        name,
        n,
        () => {
          return this.snapshotService.updateSnapshots(snapshot, n);
        },
        () => this.formControl.setValue(n)
      );
    }
  }

  private async emitUpdatedValue(
    value: string,
    newName: string | undefined,
    callback: () => Promise<void>,
    updateControlCallback?: VoidFunction
  ) {
    if (value === this.formControl.value) {
      this.valueChange.emit(newName);
      this.budgetStore.setLoading(true);

      if (updateControlCallback) {
        updateControlCallback();
      }

      await callback();
      this.refreshTable();
    } else {
      await callback();
    }

    this.budgetStore.setLoading(false);
  }

  async deleteSnapshot(
    event: Event,
    snapshot: {
      label: string;
      value: string;
      create_date: string;
      snapshot_type: string;
      id: string;
    }
  ) {
    event.stopPropagation();

    this.overlayService.openPopup<
      { message: string },
      boolean | undefined,
      ConfirmationModalComponent
    >({
      content: ConfirmationModalComponent,
      data: {
        message: `Are you sure you want to remove ${snapshot.label} plan?`,
      },
      settings: {
        header: 'Remove Plan',
        primaryButton: {
          label: 'Remove',
          action: async (instance) => {
            instance?.ref.close();
            await this.emitUpdatedValue(
              snapshot.label,
              undefined,
              () => this.snapshotService.removeBudgetSnapshots(snapshot),
              () => {
                if (this.formControl.value === snapshot.label) {
                  this.snapshotService.setOriginalBudgetData();
                }
              }
            );
          },
        },
      },
    });
  }
}
