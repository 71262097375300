import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@shared/services/launch-darkly.service';
import { CanDeactivateGuard } from '@shared/guards/can-deactivate.guard';

import { QuarterCloseComponent } from './tabs/quarter-close/quarter-close.component';
import { ReconciliationComponent } from './tabs/reconciliation/reconciliation.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { PeriodCloseComponent } from './period-close.component';
import { QuarterCloseAdjustmentsComponent } from './tabs/quarter-close-adjustments/quarter-close-adjustments.component';
import { NotesHistoryComponent } from './tabs/notes-history/notes-history.component';
import { QuarterCloseChecklistComponent } from './tabs/quarter-close-checklist/quarter-close-checklist.component';
import { JournalEntriesComponent } from './tabs/journal -entries/journal-entries.component';
import { redirectNestingRoutesByFeatureFlag } from '@shared/services/routing.service';
import { QuarterCloseChecklistGuard } from './tabs/quarter-close-checklist/quarter-close-checklist.guard';
import { QuarterCloseAdjustmentsGuard } from './tabs/quarter-close-adjustments/quarter-close-adjustments.guard';
import { QuarterCloseGuard } from './tabs/quarter-close/quarter-close.guard';

export const PERIOD_CLOSE_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.CLOSING.ADJUSTMENTS,
    component: QuarterCloseAdjustmentsComponent,
    data: {
      feature: FeatureFlag.IN_MONTH_ADJUSTMENTS,
    },
    canDeactivate: [CanDeactivateGuard],
    canActivate: [QuarterCloseAdjustmentsGuard],
  },
  {
    path: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
    component: QuarterCloseComponent,
    data: {
      feature: FeatureFlag.MONTH_AND_QUARTER_CLOSE,
    },
    canDeactivate: [CanDeactivateGuard],
    canActivate: [QuarterCloseGuard],
  },
  {
    path: ROUTING_PATH.CLOSING.RECONCILIATION,
    component: ReconciliationComponent,
    data: {
      feature: FeatureFlag.CLOSING_RECONCILIATION,
    },
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.CLOSING.JOURNAL_ENTRIES,
    component: JournalEntriesComponent,
    data: {
      feature: FeatureFlag.JOURNAL_ENTRIES,
    },
    canDeactivate: [CanDeactivateGuard],
  },
];

export const buildPeriodCloseRoutes = (featureFlags: Flags | null, lastUrl?: string): Route => {
  const allRoutes: Routes = [
    {
      path: ROUTING_PATH.CLOSING.CHECKLIST,
      component: QuarterCloseChecklistComponent,
      canActivate: [QuarterCloseChecklistGuard],
      canDeactivate: [CanDeactivateGuard],
      data: {
        feature: FeatureFlag.CHECKLIST_TAB,
      },
    },
    {
      path: ROUTING_PATH.CLOSING.NOTES,
      component: NotesHistoryComponent,
    },
    ...PERIOD_CLOSE_ROUTES_FF,
  ];
  const enabledRoutes: Routes = [...buildRoutesByFeatureFlags(featureFlags, allRoutes)];

  const redirectTo = redirectNestingRoutesByFeatureFlag(enabledRoutes, allRoutes, lastUrl || '', {
    firstPriorRoute: {
      isActive: !!(featureFlags || {})['nav_budget'],
      redirectTo: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
    },
    secondPriorRoute: {
      isActive: !!(featureFlags || {})['nav_document_library'],
      redirectTo: `/${ROUTING_PATH.DOCUMENTS}`,
    },
  });
  return {
    path: ROUTING_PATH.CLOSING.INDEX,
    component: PeriodCloseComponent,
    children: [...allRoutes, redirectTo],
  };
};
