import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ActivityType } from 'aws-sdk/clients/swf';
import { MapActivitiesGridRow } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';

export type AgBudgetActivityDropdownParams = ICellRendererParams & {
  options: { activity_type: ActivityType; label: string; value: string }[];
  validateCell: (isInvalidValue: boolean, data: MapActivitiesGridRow, node: IRowNode) => void;
  updateTotals: () => void;
};

@Component({
  selector: 'aux-ag-budget-activity-dropdown',
  templateUrl: 'ag-budget-activity-dropdown.component.html',
  styleUrl: 'ag-budget-activity-dropdown.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    NgIf,
    FormsModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    TooltipDirective,
    NgClass,
  ],
})
export class AgBudgetActivityDropdownComponent implements ICellRendererAngularComp {
  params!: AgBudgetActivityDropdownParams;
  formControl = new FormControl();

  @ViewChild('select', { static: true }) select!: NgSelectComponent;

  refresh(): boolean {
    return false;
  }

  agInit(params: AgBudgetActivityDropdownParams): void {
    this.params = params;
    this.formControl.setValue(params.data.activity_id || null);
  }

  getValue(): string | null {
    return this.formControl.value;
  }

  onChange(): void {
    const newValue = this.params.options.find(({ value }) => value === this.formControl.value);

    this.params.node.updateData({
      ...this.params.node.data,
      activity_name: newValue ? newValue.label : null,
      activity_type: newValue ? newValue.activity_type : null,
      activity_id: this.formControl.value,
    });

    this.params.validateCell(
      !this.formControl.value || !this.params.data.amount,
      this.params.node.data,
      this.params.node
    );

    this.params.updateTotals();
  }
}
