import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@shared/store/auth/auth.service';
import type { AllowedRolesPermissions } from '@shared/types/auth.type';

@Directive({
  selector: '[auxAuthorize]',
  standalone: true,
})
export class AuthorizeDirective {
  private readonly destroyRef = inject(DestroyRef);

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {}

  private adjustView(visible: boolean) {
    if (visible) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    this.ref.detectChanges();
  }

  @Input() set auxAuthorize(allowedRolesPermissions: AllowedRolesPermissions) {
    this.authService
      .isAuthorized$(allowedRolesPermissions)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((authorized) => this.adjustView(authorized));
  }
}
