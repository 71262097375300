import { TableConstants } from '@shared/constants/table.constants';
import { Utils } from '@shared/utils/utils';
import {
  CellClickedEvent,
  GridOptions,
  ICellRendererParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { NoPrepaidsOverlayComponent } from '@pages/vendor-payments-page/tabs/prepaids/no-prepaids-overlay/no-prepaids-overlay.component';
import { AgTooltipComponent } from '@shared/components/ag-tooltip/ag-tooltip.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AgIconLinkCellComponent } from '@shared/ag-components/ag-icon-link-cell/ag-icon-link-cell.component';

export const PrepaidsGridOptions = (
  onVendorClick: (event: CellClickedEvent) => void
): GridOptions => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  noRowsOverlayComponent: NoPrepaidsOverlayComponent,
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    resizable: true,
  },
  columnDefs: [
    {
      headerName: 'Vendor',
      field: 'vendor_id',
      valueFormatter: Utils.dashFormatter,
      minWidth: 140,
      cellDataType: 'text',
      cellRenderer: AgIconLinkCellComponent,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          rowReverse: true,
          icons: [
            {
              showIcon: params.data.has_incomplete_details,
              icon: 'AlertTriangle',
              iconClass: 'text-aux-red-dark',
              iconTooltip: MessagesConstants.PREPAIDS.PREPAID_NEED_YOUR_ATTENTION,
            },
          ],
        };
      },
      valueGetter: (params: ValueGetterParams) => {
        if (params.node?.rowPinned === 'bottom') {
          return params.data.vendor_id;
        }

        return params.data?.vendor_name || '';
      },
      onCellClicked: (event: CellClickedEvent) => onVendorClick(event),
      cellClass: (value) => {
        const classes: string[] = ['text-left', 'cursor-pointer'];
        if (!value.node.rowPinned) {
          if (value.data.vendor_id !== null) {
            classes.push('aux-link');
          } else {
            classes.push('aux-link-zero-hyphen');
          }
        }
        return classes;
      },
    },
    {
      headerName: 'Total Prepaid Balance',
      field: 'total_prepaid_balance',
      valueFormatter: (params) => {
        return Utils.agCurrencyFormatter(params, params.data.vendor_currency);
      },
      minWidth: 200,
      cellDataType: 'number',
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
    },
    {
      headerName: 'Short Term',
      field: 'short_term_amount',
      valueFormatter: (params) => {
        return Utils.agCurrencyFormatter(params, params.data.vendor_currency);
      },
      minWidth: 140,
      cellDataType: 'number',
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
    },
    {
      headerName: 'Long Term',
      field: 'long_term_amount',
      valueFormatter: (params) => {
        return Utils.agCurrencyFormatter(params, params.data.vendor_currency);
      },
      minWidth: 140,
      cellDataType: 'number',
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
    },
    {
      headerName: 'Terms',
      field: 'terms',
      cellRenderer: AgTooltipComponent,
      minWidth: 200,
      cellClass: ['!block', TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT],
    },
  ],
});
