@if (this.loading() || this.activitiesSaving() || this.notesSaving()) {
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
} @else {
  @if (this.gridData.length) {
    <div class="flex justify-end py-3 space-x-2">
      <aux-export-excel-button
        classList="mr-1"
        [clickFN]="onExport.bind(this)"
        [spinnerSize]="7"
        pendoTag="map-activities-excel-export-button"
      />
      <aux-button
        variant="primary"
        label="Edit"
        data-pendo-id="map-activities-button"
        icon="Pencil"
        [auxTooltip]="btnTooltip()"
        [disabled]="!!btnTooltip()"
        (clickEmit)="openMapActivitiesModal()"
      ></aux-button>
    </div>

    <ag-grid-angular
      auxGridDynamicHeight
      class="ag-theme-aux unselectable tabular-nums w-full"
      [rowData]="gridData"
      [gridOptions]="gridOptions()"
      (gridReady)="onGridReady($event)"
    />
  } @else {
    <aux-map-activities-no-rows
      [invoice]="invoice()"
      [btnTooltip]="btnTooltip()"
      [lastUpdated]="lastUpdated()"
      [noteId]="noteId"
      [noteMessage]="noteControl.value || ''"
      (saveActivities)="saveActivities($event)"
    />
  }

  @if (this.noteControl.value) {
    <div class="w-[500px] mt-[30px]">
      <div class="flex items-center justify-between space-x-1">
        <span class="mr-1">Comments</span>

        <span class="text-aux-gray-dark-2"> {{ this.noteControl.value.length || 0 }} / 1000 </span>
      </div>

      <aux-input
        placeholder="Enter comments here..."
        [textArea]="true"
        [rows]="5"
        [readonly]="true"
        resize="none"
        class="mt-1 textarea-input"
        [maxlength]="1000"
        [formControl]="noteControl"
      />
    </div>
  }

  @if (lastUpdatedList.length && (this.noteControl.value || this.gridData.length)) {
    <div class="mt-2">Last Updated: {{ lastUpdated() }}</div>
  }
}
