import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { OverlayService } from '@shared/services/overlay.service';
import { UploadDocumentsDialogComponent } from '@pages/vendor-payments-page/tabs/invoices/upload-documents-dialog/upload-documents-dialog.component';
import { firstValueFrom } from 'rxjs';

@Component({
  standalone: true,
  selector: 'aux-invoice-items-no-rows',
  template: `
    <div class="h-[150px] flex items-center justify-center">
      No rows to show. &nbsp;<span class="text-aux-blue underline cursor-pointer" (click)="upload()"
        >Upload</span
      >&nbsp; PDF and designate as Invoice for Auxilius line item parsing.
    </div>
  `,
  imports: [],
  styles: [],
})
export class InvoiceItemsNoRowsComponent {
  invoice = input.required<InvoiceModel>();
  overlayService = inject(OverlayService);

  @Output() closeDialog = new EventEmitter();

  async upload() {
    await firstValueFrom(
      this.overlayService.openPopup<
        { invoice: InvoiceModel },
        unknown[],
        UploadDocumentsDialogComponent
      >({
        content: UploadDocumentsDialogComponent,
        settings: {
          header: 'Upload Document for Invoice',
          primaryButton: {
            disabled: (instance) => !instance?.hasChanges,
            action: async (instance) => await instance?.onSave(),
          },
        },
        data: { invoice: this.invoice() },
      }).afterClosed$
    );

    this.closeDialog.emit();
  }
}
