import { OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PortfolioTaskListComponent } from '@features/portfolio-task-list/portfolio-task-list.component';
import { UserTasksQuery } from '@models/user-tasks';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  selector: 'aux-trial-tasks',
  templateUrl: './trial-tasks.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    OverlayModule,
    AsyncPipe,
    NgClass,
    IconComponent,
    PortfolioTaskListComponent,
    NgStyle,
  ],
})
export class TrialTasksComponent {
  isOpen = false;

  noMessage = 'You have no tasks to complete at this time';

  trialTasks$ = this.userTasksQuery.getCurrentTrialTasks();

  constructor(private userTasksQuery: UserTasksQuery) {}

  toggleOpenList = (value: boolean) => {
    this.isOpen = value;
  };
}
