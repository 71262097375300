import { MainService } from '@shared/store/main/main.service';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  merge,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LoggedInUser } from '@shared/store/auth/LoggedInUser';
import dayjs from 'dayjs';
import { cloneDeep, groupBy, last, uniq } from 'lodash-es';
import {
  AmountType,
  Approval,
  BudgetData,
  CategoryType,
  Currency,
  DocumentType,
  EventType,
  GqlService,
  InvoiceStatus,
  listDocumentsQuery,
  listTrialTimelineQuery,
} from '@shared/services/gql.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OverlayService } from '@shared/services/overlay.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationService } from '@models/organization/organization.service';
import { EventService } from '@models/event/event.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { Router } from '@angular/router';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { ObjectFromList, Utils } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';

import { MainQuery } from '@shared/store/main/main.query';
import { MainStore } from '@shared/store/main/main.store';
import { InvoiceService } from '../vendor-payments-page/tabs/invoices/state/invoice.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowService } from '@shared/store/workflow/workflow.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { InvoiceAmounts } from '../vendor-payments-page/tabs/invoices/state/invoice.model';
import { QuarterCloseChecklistPeriodCloseService } from './tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { EventQuery } from '@models/event/event.query';
import { ApiService } from '@shared/services/api.service';
import { QCGridData } from '@pages/closing-page/tabs/quarter-close/quarter-close.component';
import { Option } from '@shared/types/components.type';
import { ApprovalState } from '@models/approval/approval.model';
import { ApprovalQuery } from '@models/approval/approval.query';
import { ExtendedBudgetData, BudgetState } from '@models/budget/budget.model';
import { BudgetQuery } from '@models/budget/budget.query';
import { BudgetService } from '@features/budget/services/budget.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { getEncodedAttributeName } from '@features/budget-attributes/services/budget-attributes.service';

Utils.extendDayjs();

export type MonthCloseTableRowData = {
  account_value: string;
  po_value: string;
  dept_value: string;
  adjustment_obj: Record<string, number>;
  accrual_adjusted_obj: Record<string, number>;
  invoice_amounts: Record<string, number>;
  net_accruals: Record<string, number>;
  initial_wp_obj: Record<string, number>;
  eom_accruals: Record<string, number>;
  eom_accrual: Record<string, number>;
  eom_prepaid: Record<string, number>;
  eom_unpaid: Record<string, number>;
  eom_accrual_debit: Record<string, number>;
  eom_accrual_credit: Record<string, number>;
  eom_prepaid_debit: Record<string, number>;
  eom_prepaid_credit: Record<string, number>;
  monthly_wp_obj: Record<string, number>;
  monthly_invoice_ltd: Record<string, number>;
  wp_ltd_start: number;
  invoice_ltd_start: number;
  starting_accruals: number;
  starting_accrual: number;
  starting_prepaid: number;
  invoice_ltd_end: number;
  invoice_unpaid_ltd_end: number;
  wp_ltd_end: number;
  invoice_ltd: number;
  ending_accruals: number;
  [permission: `${string}::${string}`]: boolean;
  [permission: `${string}::${string}::disabled`]: boolean;
  [attributes: `custom_attr_${string}`]: string;
} & ExtendedBudgetData;

type MonthCloseBottomData = Pick<
  MonthCloseTableRowData,
  | 'adjustment_obj'
  | 'accrual_adjusted_obj'
  | 'forecast_obj'
  | 'invoice_amounts'
  | 'net_accruals'
  | 'initial_wp_obj'
  | 'eom_accruals'
  | 'eom_accrual'
  | 'eom_prepaid'
  | 'eom_unpaid'
  | 'eom_accrual_debit'
  | 'eom_accrual_credit'
  | 'eom_prepaid_debit'
  | 'eom_prepaid_credit'
  | 'monthly_wp_obj'
  | 'monthly_invoice_ltd'
  | 'account_value'
  | 'po_value'
  | 'dept_value'
  | 'attributes'
>;

export interface InvestigatorEstimate {
  patient: number;
  other: number;
  overhead: number;
  total_all: number;
  show_adjustment?: boolean;
}

export interface MonthStats {
  date: string;
  eom_accruals: number;
  status: 'Closed' | 'Future' | 'Open';
  allOfThemClosed?: boolean;
  auditPackages?: listDocumentsQuery['items'];
}

export interface QuarterDate {
  parsedDate: dayjs.Dayjs;
  date: string;
  iso: string;
}

export interface QuarterStartData {
  wp_ltd: number;
  invoice_ltd: number;
  starting_accrual: number;
  starting_prepaid: number;
}

export interface QuarterEndData {
  wp_ltd: number;
  invoice_ltd: number;
  ending_accruals: number;
  invoice_unpaid_ltd: number;
}

@Component({
  selector: 'aux-period-close',
  templateUrl: './period-close.component.html',
  styles: [
    `
      .month-name {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  upperShorNameMonths = Utils.SHORT_MONTH_NAMES.map((month) => month.toUpperCase());

  months: QuarterDate[] = [];

  monthsStat$ = new BehaviorSubject<MonthStats[]>([]);

  auditPackages$ = new BehaviorSubject<listDocumentsQuery['items']>([]);

  trialKey = this.mainQuery.getSelectedTrial()?.id || '';

  closeMonthProcessingMessage =
    'The period close process is currently running. It can take up to 5 minutes. You can navigate away from this page. ' +
    'You will receive an email notification when complete, and an Audit Package will be available in the Document Library.';

  columnsForMonth = [
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
    'eom_accrual_debit',
    'eom_accrual_credit',
    'eom_prepaid_debit',
    'eom_prepaid_credit',
    'monthly_wp_obj',
    'monthly_invoice_ltd',
    'eom_accrual',
    'eom_prepaid',
    'eom_unpaid',
  ] as const;

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  invoicesLoading$ = new BehaviorSubject(false);

  invoices$ = new BehaviorSubject<
    Record<
      string,
      {
        id: string;
        accrual_period: string;
        organization_id: string;
        invoice_status: InvoiceStatus;
        expense_amounts: InvoiceAmounts;
        payment_date: string;
      }[]
    >
  >({});

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  closedMonthColumns = [
    'forecast_obj',
    'adjustment_obj',
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
    'eom_accrual_debit',
    'eom_accrual_credit',
    'eom_prepaid_debit',
    'eom_prepaid_credit',
    'eom_accrual',
    'eom_prepaid',
    'eom_unpaid',
    'initial_wp_obj',
    'monthly_wp_obj',
    'monthly_invoice_ltd',
  ] as const;

  bottomRowData$ = new BehaviorSubject<MonthCloseBottomData>(this.emptyBottomData());

  quarter_start_data: QuarterStartData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    starting_accrual: 0,
    starting_prepaid: 0,
  };

  quarter_end_data: QuarterEndData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    ending_accruals: 0,
    invoice_unpaid_ltd: 0,
  };

  currencies: Set<Currency> = new Set();

  loadingSPT$ = new BehaviorSubject(false);

  loading$ = combineLatest([
    this.budgetQuery.selectLoading(),
    this.loadingSPT$,
    this.invoicesLoading$,
  ]).pipe(map((boolArr) => boolArr.some((bool) => bool)));

  selectedCategoryType$ = new BehaviorSubject<CategoryType>(CategoryType.CATEGORY_SERVICE);

  refresh$ = new Subject();

  refreshNgOnInit$ = new Subject();

  selectedMonth$ = new BehaviorSubject<{
    month: string;
    category: string;
    vendor: string;
  } | null>(null);

  quarters: string[] = [];

  quarterOptions: Option[] = [];

  quartersObj: Record<string, QuarterDate[]> = {};

  quartersObjUpdated$ = new Subject();

  selectedQuarter = new FormControl('');

  currentQuarter = '';

  currentMonth = '';

  getWorkflowDate?: string;

  getWorkflowSub?: Subscription;

  tabs: TabGroupConfig[] = [
    {
      label: 'Checklist',
      route: ROUTING_PATH.CLOSING.CHECKLIST,
      show: toObservable(this.launchDarklyService.$select((flags) => flags.checklist_tab)),
    },
    {
      label: 'In-Month Adjustments',
      route: ROUTING_PATH.CLOSING.ADJUSTMENTS,
      show: this.launchDarklyService.select$((flags) => flags.tab_in_month_adjustments),
    },
    {
      label: 'Month & Quarter Close',
      route: ROUTING_PATH.CLOSING.QUARTER_CLOSE,
      show: this.launchDarklyService.select$((flags) => flags.month_quarter_close_tab),
    },
    { label: 'Notes & History', route: ROUTING_PATH.CLOSING.NOTES, show: of(false) },
    {
      label: 'Reconciliation',
      route: ROUTING_PATH.CLOSING.RECONCILIATION,
      show: of(this.launchDarklyService.flags$.getValue().tab_budget_cash_management),
    },
    {
      label: 'Journal Entries',
      route: ROUTING_PATH.CLOSING.JOURNAL_ENTRIES,
      show: this.launchDarklyService.select$((flags) => flags.tab_journal_entries),
    },
  ];

  // when we change the trial we need to reset selectedQuarter
  // so this variable is holding the latest value for selected trial.
  lastSelectedQuarterValue: string | null = null;

  invoice_statuses: Array<InvoiceStatus> = [
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_IN_QUEUE,
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
  ];

  runningOnInit = false;

  monthClosed$ = new BehaviorSubject(false);

  gridInit = combineLatest([
    this.loading$,
    this.budgetQuery.select(),
    this.approvalQuery.select(),
    this.invoices$,
  ]).pipe(
    takeUntilDestroyed(this.destroyRef),
    switchMap(([loading, budget_state, approval_state, groupedInvoices]) => {
      if (loading) {
        return EMPTY;
      }

      return from(this.authService.getLoggedInUser()).pipe(
        map((user) => {
          return {
            user,
            budget_state,
            approval_state,
            groupedInvoices,
          };
        })
      );
    })
  );

  gridData$ = this.gridInit.pipe(
    map((params) => this.mapFn(params)),
    shareReplay(1)
  );

  newQCGridDataLoading$ = new BehaviorSubject(false);
  newQCGridData$ = new BehaviorSubject<QCGridData[]>([]);

  timeline$ = new BehaviorSubject<listTrialTimelineQuery[]>([]);

  onSelectionChanged({ node: selectedRow, column }: ICellRendererParams) {
    const period = column?.getColId().split('_')[0];
    if (!period) {
      return;
    }
    if (selectedRow.group) {
      this.organizationStore.setActive(selectedRow.key);
      // When vendor's pencil selected, this'll be unsetable.
      this.selectedCategoryType$.next(CategoryType.CATEGORY_UNDETERMINED);
    } else {
      const data: BudgetData = selectedRow.data;
      this.organizationStore.setActive(data.vendor_id || '');
      this.selectedCategoryType$.next(
        `CATEGORY_${data.cost_category?.replace('AMOUNT_', '')}` as CategoryType
      );
    }

    const month =
      this.quartersObj[this.selectedQuarter.value || ''][+period.replace('qmonth', '')[0] - 1];

    this.selectedMonth$.next({
      month: month.parsedDate.date(1).format('YYYY-MM-DD'),
      category: this.selectedCategoryType$.getValue(),
      vendor: this.organizationQuery.getActiveId() || '',
    });

    this.router.navigate([ROUTING_PATH.CLOSING.INDEX, ROUTING_PATH.CLOSING.ADJUSTMENTS]);
  }

  constructor(
    public organizationQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService,
    private budgetService: BudgetService,
    public budgetQuery: BudgetQuery,
    private eventService: EventService,
    private approvalQuery: ApprovalQuery,
    private mainService: MainService,
    private authService: AuthService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private router: Router,
    public authQuery: AuthQuery,
    private mainStore: MainStore,
    private invoiceService: InvoiceService,
    private workflowQuery: WorkflowQuery,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private workflowService: WorkflowService,
    private eventQuery: EventQuery,
    private apiService: ApiService
  ) {
    this.mainStore.update({ fullPage: true });

    this.eventQuery
      .selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH)
      .pipe(takeUntilDestroyed())
      .subscribe((isProcessing) => {
        if (!this.runningOnInit && isProcessing === false) {
          this.downloadAuditPackages();
          this.refreshGridData();
        }
      });

    merge(
      this.eventService.select$(EventType.UPDATE_MONTH_ACCRUALS),
      this.eventService.select$(EventType.UPDATE_QUARTER_ACCRUALS)
    )
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (
          !this.runningOnInit &&
          !this.eventQuery.isEventProcessing(EventType.CLOSE_TRIAL_MONTH)
        ) {
          this.refreshGridData();
        }
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          return this.gqlService.listTrialTimeline$();
        })
      )
      .pipe(takeUntilDestroyed())
      .subscribe(({ success, data }) => {
        if (success && data) {
          this.timeline$.next(data);
        } else {
          this.timeline$.next([]);
        }
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.currencies.clear();
          return this.downloadAuditPackages();
        })
      )
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.bottomRowData$.next(this.emptyBottomData());
        this.lastSelectedQuarterValue = null;
      });

    combineLatest([
      this.selectedQuarter.valueChanges.pipe(startWith('')),
      this.quartersObjUpdated$.pipe(startWith(null)),
    ])
      .pipe(
        filter(() => !this.newQCGridDataLoading$.getValue()),
        switchMap(() => {
          const v = this.selectedQuarter.value;
          if (v) {
            this.newQCGridDataLoading$.next(true);
            return this.gqlService.listQuarterCloseExpenses$({
              current_quarter: v.replace('-', ' '),
              vendor_id: null,
              rollup_discount: true,
              trial_currency: false,
            });
          }
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe(({ success, data, errors }) => {
        if (success && data) {
          this.newQCGridData$.next(
            data.map((row) => {
              let activity_name = row.cost_category;
              switch (row.cost_category) {
                case AmountType.AMOUNT_SERVICE:
                  activity_name = 'Services';
                  break;
                case AmountType.AMOUNT_PASSTHROUGH:
                  activity_name = 'Pass-through';
                  break;
                case AmountType.AMOUNT_INVESTIGATOR:
                  activity_name = 'Investigator';
                  break;
                default:
                  break;
              }

              const attributes = JSON.parse(row.attributes || '[]') as QCGridData['attributes'];

              const extraAttributes =
                attributes.reduce(
                  (acc, a) => {
                    if (a.attribute_name && a.attribute_value) {
                      acc[getEncodedAttributeName(a.attribute_name)] = a.attribute_value;
                    }

                    return acc;
                  },
                  {} as Record<string, string>
                ) || {};

              return { ...row, attributes, activity_name, ...extraAttributes };
            })
          );
        } else {
          this.newQCGridData$.next([]);
          console.error(errors);
        }
        this.newQCGridDataLoading$.next(false);

        this.setMonthStats();
      });
  }

  emptyBottomData() {
    return {
      adjustment_obj: {},
      accrual_adjusted_obj: {},
      forecast_obj: {},
      invoice_amounts: {},
      net_accruals: {},
      initial_wp_obj: {},
      eom_accruals: {},
      eom_accrual: {},
      eom_prepaid: {},
      eom_unpaid: {},
      eom_accrual_debit: {},
      eom_accrual_credit: {},
      eom_prepaid_debit: {},
      eom_prepaid_credit: {},
      monthly_wp_obj: {},
      monthly_invoice_ltd: {},
      account_value: '',
      po_value: '',
      dept_value: '',
      attributes: [],
    };
  }

  mapFn(
    {
      user,
      budget_state,
      approval_state,
      groupedInvoices,
    }: {
      user: LoggedInUser | null;
      budget_state: BudgetState;
      approval_state: ApprovalState;
      groupedInvoices: Record<
        string,
        {
          id: string;
          accrual_period: string;
          organization_id: string;
          invoice_status: InvoiceStatus;
          expense_amounts: InvoiceAmounts;
          payment_date: string;
        }[]
      >;
    },
    mergeDiscountWithServices = false
  ) {
    const { budget_data, budget_info, header_data } = budget_state;

    if (!budget_info?.[0]) {
      return [];
    }

    const { current_month } = budget_info[0];

    let quarters: string[] = [];
    const quartersObj: Record<
      string,
      {
        parsedDate: dayjs.Dayjs;
        date: string;
        iso: string;
      }[]
    > = {};
    let currentQuarter = '';

    header_data
      .filter((x) => x.group_name === 'Timeline')[0]
      .date_headers.forEach((date) => {
        const parsedDate = this.parseBudgetHeaderDate(date);
        const q = parsedDate.quarter();
        const year = parsedDate.year();
        const str = `Q${q}-${year}`;
        quartersObj[str] ||= [];
        const iso = parsedDate.format('YYYY-MM-DD');

        if (current_month?.slice(0, 7) === iso.slice(0, 7)) {
          currentQuarter = str;
        }
        quartersObj[str].push({
          parsedDate,
          date,
          iso,
        });
        quarters.push(str);
      });
    quarters = uniq(quarters);

    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();

    const current_quarter_index = quarters.findIndex((q) => q === currentQuarter);
    this.quarters = quarters.slice(0, current_quarter_index + 1).reverse();

    if (auxilius_start_date) {
      this.quarters = this.quarters.filter((q) => {
        const monthIndex = quartersObj[q].length - 1;

        return (
          quartersObj[q][monthIndex].parsedDate.isSameOrAfter(auxilius_start_date, 'month') ||
          quartersObj[q][monthIndex].parsedDate.isSameOrAfter(current_month, 'month')
        );
      });
    }

    this.quartersObj = quartersObj;
    this.currentQuarter = currentQuarter;
    this.currentMonth = current_month || '';

    this.quarterOptions = this.quarters.map<Option>((quarter) => {
      const formattedQuarter = quarter.replace('-', ' ');

      return {
        label: `${formattedQuarter}${quarter === this.currentQuarter ? ' (Current)' : ''}`,
        value: quarter,
      };
    });

    if (!this.lastSelectedQuarterValue) {
      this.selectedQuarter.setValue(currentQuarter, { emitEvent: false });
      this.updateIsCurrentQuarterSelected(currentQuarter);
      this.onSelectedQuarterChange(currentQuarter);
    }

    this.quartersObjUpdated$.next(null);

    this.quarter_start_data = {
      wp_ltd: 0,
      invoice_ltd: 0,
      starting_accrual: 0,
      starting_prepaid: 0,
    };

    this.quarter_end_data = {
      wp_ltd: 0,
      invoice_ltd: 0,
      ending_accruals: 0,
      invoice_unpaid_ltd: 0,
    };

    const newBudgetData = !mergeDiscountWithServices
      ? budget_data
      : this.mergeServicesWithDiscount(budget_data);

    const months = this.quartersObj[this.selectedQuarter.value || ''];

    const firstMonth = dayjs(months[0].iso).startOf('quarter');
    const secondMonth = firstMonth.add(1, 'month');
    const thirdMonth = firstMonth.add(2, 'month');
    // some quarters don't have 3 months, so we are adding them manually
    const quarterMonths: QuarterDate[] = [
      {
        parsedDate: firstMonth,
        date: firstMonth.format('MMM-YYYY').toUpperCase(),
        iso: firstMonth.format('YYYY-MM-DD'),
      },
      {
        parsedDate: secondMonth,
        date: secondMonth.format('MMM-YYYY').toUpperCase(),
        iso: secondMonth.format('YYYY-MM-DD'),
      },
      {
        parsedDate: thirdMonth,
        date: thirdMonth.format('MMM-YYYY').toUpperCase(),
        iso: thirdMonth.format('YYYY-MM-DD'),
      },
    ];
    const timeline_start = quarterMonths[0].iso;

    const data: MonthCloseTableRowData[] = newBudgetData.map((row) => {
      this.currencies.add((row.contract_direct_cost_currency as Currency) || Currency.USD);
      const approvals: Approval[] = [];
      const usedApprovals: Approval[] = [];
      const arr: {
        name: string;
        permission: string;
        checked: boolean;
        disabled: boolean;
      }[] = [];
      const rules = this.launchDarklyService.flags$.getValue().approval_rule_in_month.rule;

      if (approval_state.approvals) {
        approval_state.approvals.forEach((approval) => {
          if (row.vendor_id === approval.vendor_id && row.cost_category === approval.category) {
            approvals.push(approval);
          }
        });
      }

      for (let i = 0; i < rules.length; i++) {
        let checked = false;
        let disabled = false;
        const { name, permission_type } = rules[i];

        for (let index = 0; index < approvals.length; index++) {
          if (approvals[index].permission === permission_type) {
            usedApprovals.push(...approvals.splice(index));
            checked = true;
            disabled = true;
            break;
          }
        }

        if (!checked && user && !user.IsSysAdmin()) {
          const isAuthorized = this.authService.isAuthorizedSync(user, {
            permissions: [permission_type],
          });

          if (isAuthorized) {
            disabled = usedApprovals.some(({ aux_user_id, permission }) => {
              return aux_user_id === user.getSub() && permission === permission_type;
            });
          } else {
            disabled = true;
          }
        }

        arr.push({
          name,
          permission: permission_type,
          checked,
          disabled: checked || disabled,
        });
      }

      const obj = arr.reduce(
        (acc, { name, permission, disabled, checked }) => {
          acc[`${name}::${permission}`] = checked;
          acc[`${name}::${permission}::disabled`] = disabled;

          return acc;
        },
        {} as Record<string, boolean>
      );

      // const date = budget_info[0].current_month?.slice(0, 7);
      const invoices = groupedInvoices[row.vendor_id || ''] || [];

      let invoice_ltd_start = 0;

      let invoice_ltd_end = 0;

      const eom_unpaid: Record<string, number> = {};

      const invoice_amounts = invoices.reduce(
        (i_acc, val) => {
          const period = dayjs(val.accrual_period).format('MMM-YYYY').toUpperCase();
          const is_old = dayjs(timeline_start).diff(val.accrual_period) > 0;

          let total = 0;
          if (row.cost_category === 'Services') {
            const discountValue = mergeDiscountWithServices
              ? val.expense_amounts.discount_total.value
              : 0;
            total = val.expense_amounts.services_total.value + discountValue;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Discount' && !mergeDiscountWithServices) {
            total = val.expense_amounts.discount_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Investigator') {
            total = val.expense_amounts.investigator_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Pass-through') {
            total = val.expense_amounts.pass_thru_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }

          if (val.invoice_status !== InvoiceStatus.STATUS_DECLINED) {
            quarterMonths.forEach((mon) => {
              const mPeriod = dayjs(mon.iso).format('MMM-YYYY').toUpperCase();
              const isSameBefore = dayjs(mon.iso)
                .date(1)
                .isSameOrAfter(dayjs(val.accrual_period).date(1));
              if (isSameBefore) {
                if (val.payment_date) {
                  const isPaySameBefore = dayjs(mon.iso)
                    .date(1)
                    .isSameOrAfter(dayjs(val.payment_date).date(1));
                  if (isPaySameBefore) {
                    return;
                  }
                }
                eom_unpaid[mPeriod] = (eom_unpaid[mPeriod] || 0) + total;
              }
            });
          }

          return i_acc;
        },
        {} as Record<string, number>
      );

      let forecast_obj = row.forecast_obj as Record<string, number>;
      const adjustment_obj = row.adjustment_obj as Record<string, number>;
      const initial_wp_obj: Record<string, number> = {};
      const monthly_wp_obj: Record<string, number> = {};
      const monthly_invoice_ltd: Record<string, number> = {};

      const accrual_obj = cloneDeep(row.accrual_obj) as Record<string, number>;
      const accrual_adjusted_obj = cloneDeep(row.accrual_adjusted_obj) as Record<string, number>;

      Object.keys(adjustment_obj).forEach((month) => {
        if (!accrual_obj[month]) {
          accrual_obj[month] = 0;
        }
        if (!accrual_adjusted_obj[month]) {
          accrual_adjusted_obj[month] = 0;
        }
      });

      if (row.cost_category === 'Investigator') {
        const investigator_forecast_obj: Record<string, number> = {};

        forecast_obj = { ...forecast_obj, ...investigator_forecast_obj };
      }
      let wp_ltd_end = row.wp_ltd;
      const starting_accruals = row.wp_ltd - invoice_ltd_start;
      const starting_accrual = starting_accruals > 0 ? starting_accruals : 0;
      const starting_prepaid = starting_accruals > 0 ? 0 : Math.abs(starting_accruals);

      this.quarter_start_data.wp_ltd += row.wp_ltd;
      this.quarter_start_data.invoice_ltd += invoice_ltd_start;
      this.quarter_start_data.starting_accrual += starting_accrual;
      this.quarter_start_data.starting_prepaid += starting_prepaid;

      quarterMonths.forEach((month) => {
        initial_wp_obj[month.date] =
          accrual_obj && Object.prototype.hasOwnProperty.call(accrual_obj, month.date)
            ? accrual_obj[month.date]
            : forecast_obj[month.date];

        if (dayjs(month.iso).date(1).isAfter(dayjs(current_month).date(1))) {
          accrual_adjusted_obj[month.date] = initial_wp_obj[month.date];
          adjustment_obj[month.date] = 0;
        }

        wp_ltd_end += accrual_adjusted_obj[month.date] ? accrual_adjusted_obj[month.date] : 0;
      });

      const eom_accruals: Record<string, number> = {};
      const eom_accrual: Record<string, number> = {};
      const eom_prepaid: Record<string, number> = {};
      const eom_accrual_debit: Record<string, number> = {};
      const eom_accrual_credit: Record<string, number> = {};
      const eom_prepaid_debit: Record<string, number> = {};
      const eom_prepaid_credit: Record<string, number> = {};
      const net_accruals = Object.keys(accrual_adjusted_obj).reduce(
        (acc, val) => {
          return {
            ...acc,
            [val]: (row.accrual_adjusted_obj[val] || 0) - (invoice_amounts[val] || 0),
          };
        },
        {} as Record<string, number>
      );

      quarterMonths.forEach((month, index) => {
        invoice_ltd_end += invoice_amounts[month.date] || 0;

        eom_accruals[month.date] =
          starting_accruals +
          quarterMonths.slice(0, index + 1).reduce((acc, m) => {
            return acc + (net_accruals[m.date] || 0);
          }, 0);

        eom_accrual[month.date] = eom_accruals[month.date] > 0 ? eom_accruals[month.date] : 0;
        eom_prepaid[month.date] =
          eom_accruals[month.date] > 0 ? 0 : Math.abs(eom_accruals[month.date]);
      });

      this.months = months;

      invoice_ltd_end += invoice_ltd_start;
      const invoice_unpaid_ltd_end = eom_unpaid[last(quarterMonths)?.date || ''] || 0;

      const ending_accruals = wp_ltd_end - invoice_ltd_end;

      this.quarter_end_data.invoice_unpaid_ltd += invoice_unpaid_ltd_end;
      this.quarter_end_data.ending_accruals += ending_accruals;
      this.quarter_end_data.wp_ltd += wp_ltd_end;
      this.quarter_end_data.invoice_ltd += invoice_ltd_end;

      const account_value =
        row?.attributes?.find((x) => x.attribute === 'account_no')?.attribute_value || '';

      const po_value = row?.attributes?.find((x) => x.attribute === 'po_no')?.attribute_value || '';

      const dept_value =
        row?.attributes?.find((x) => x.attribute === 'department')?.attribute_value || '';

      const extraAttributes =
        row.attributes?.reduce(
          (acc, a) => {
            if (a.attribute_name && a.attribute_value) {
              acc[getEncodedAttributeName(a.attribute_name)] = a.attribute_value;
            }

            return acc;
          },
          {} as Record<string, string>
        ) || {};

      quarterMonths.forEach((month, index) => {
        const wp = accrual_adjusted_obj[month.date] || 0;
        const ir = invoice_amounts[month.date] || 0;
        if (index === 0) {
          //first month of the quarter
          monthly_wp_obj[month.date] = (row.wp_ltd || 0) + wp;
          monthly_invoice_ltd[month.date] = (invoice_ltd_start || 0) + ir;
        } else {
          //second month of the quarter
          monthly_wp_obj[month.date] = monthly_wp_obj[quarterMonths[index - 1].date] + wp;
          monthly_invoice_ltd[month.date] = monthly_invoice_ltd[quarterMonths[index - 1].date] + ir;
        }
      });

      const monthStart: Record<string, number> = {};
      const monthEnd: Record<string, number> = {};
      quarterMonths.forEach((month, index) => {
        if (index === 0) {
          monthStart[month.date] = starting_accruals || 0;
        } else {
          monthStart[month.date] = monthEnd[quarterMonths[index - 1].date];
        }
        monthEnd[month.date] = monthStart[month.date] + (net_accruals[month.date] || 0);
      });

      const getPrepaidAndAccrualAccountBalance = (netAccrual: number) => {
        return {
          prepaid: netAccrual < 0 ? -netAccrual : 0,
          accrual: netAccrual > 0 ? netAccrual : 0,
        };
      };
      quarterMonths.forEach((month) => {
        const netAccrualAtMonthStart = monthStart[month.date];
        const netAccrualAtMonthClose = monthEnd[month.date];
        const startingAccountBalances = getPrepaidAndAccrualAccountBalance(netAccrualAtMonthStart);
        const endingAccountBalances = getPrepaidAndAccrualAccountBalance(netAccrualAtMonthClose);
        const deltaPrepaid = endingAccountBalances.prepaid - startingAccountBalances.prepaid;
        const deltaAccrual = endingAccountBalances.accrual - startingAccountBalances.accrual;
        eom_accrual_credit[month.date] = deltaAccrual > 0 ? deltaAccrual : 0;
        eom_accrual_debit[month.date] = deltaAccrual < 0 ? -deltaAccrual : 0;
        eom_prepaid_credit[month.date] = deltaPrepaid < 0 ? -deltaPrepaid : 0;
        eom_prepaid_debit[month.date] = deltaPrepaid > 0 ? deltaPrepaid : 0;
      });

      return <MonthCloseTableRowData>{
        ...row,
        ...obj,
        wp_ltd_start: row.wp_ltd,
        invoice_ltd_start,
        starting_accruals,
        starting_accrual,
        starting_prepaid,
        forecast_obj,
        adjustment_obj,
        invoice_amounts,
        net_accruals,
        eom_accruals,
        eom_accrual,
        eom_prepaid,
        eom_unpaid,
        eom_accrual_debit,
        eom_accrual_credit,
        eom_prepaid_debit,
        eom_prepaid_credit,
        initial_wp_obj,
        invoice_ltd_end,
        invoice_unpaid_ltd_end,
        wp_ltd_end,
        ending_accruals,
        monthly_wp_obj,
        monthly_invoice_ltd,
        account_value,
        dept_value,
        po_value,
        ...extraAttributes,
      };
    });

    return this.getBudgetDataWithSummary(data, quarterMonths);
  }

  mergeServicesWithDiscount(budget_data: ExtendedBudgetData[]) {
    const discountRows: Record<string, ExtendedBudgetData> = {};
    return budget_data
      .filter((row) => {
        if (row.cost_category === 'Discount') {
          discountRows[row.vendor_id || ''] = row;
          return false;
        }
        return true;
      })
      .map((row) => {
        if (row.cost_category === 'Services' && row.vendor_id && discountRows[row.vendor_id]) {
          const discountRow = discountRows[row.vendor_id];
          const keys = [
            'direct_cost',
            'contract_direct_cost',
            'accrual',
            'forecast',
            'total_monthly_accrual',
            'adjustment',
            'wp_ltd',
          ] as const;
          const obj_keys = [
            'forecast_obj',
            'accrual_obj',
            'accrual_adjusted_obj',
            'adjustment_obj',
            'accrual_override',
          ] as const;
          return {
            ...row,
            ...keys.reduce(
              (acc, key) => {
                acc[key] = (row[key] || 0) + (discountRow[key] || 0);
                return acc;
              },
              {} as ObjectFromList<typeof keys, number>
            ),
            ...obj_keys.reduce(
              (acc, key) => {
                acc[key] = {};
                Object.entries(row[key]).forEach(([month, v]) => {
                  acc[key][month] = v + (discountRow[key][month] || 0);
                });
                return acc;
              },
              {} as Record<string, Record<string, number>>
            ),
          };
        }
        return row;
      });
  }

  private parseBudgetHeaderDate(date: string): dayjs.Dayjs {
    const [month, year] = date.split('-');

    const monthNumber = this.upperShorNameMonths.indexOf(month) + 1;
    const strMonth = `${monthNumber}`.padStart(2, '0');

    return dayjs(`03/${strMonth}/${year}`, 'DD/MM/YYYY');
  }

  getBudgetDataWithSummary(data: MonthCloseTableRowData[], quarterMonths: QuarterDate[]) {
    return data.map((row) => {
      return this.columnsForMonth.reduce((accum, rowKey) => {
        return <MonthCloseTableRowData>{
          ...accum,
          [rowKey]: {
            ...row[rowKey],
            total: ['eom_accruals', 'eom_accrual', 'eom_prepaid', 'eom_unpaid'].includes(rowKey)
              ? accum[rowKey][quarterMonths[quarterMonths.length - 1].date]
              : quarterMonths.reduce((sum, { date }) => {
                  sum += accum[rowKey][date] || 0;
                  return sum;
                }, 0),
          },
        };
      }, row);
    });
  }

  refreshGridData() {
    this.runningOnInit = true;
    setTimeout(() => {
      this.monthClosed$.next(false);
      this.refreshNgOnInit$.next(null);
      this.runningOnInit = false;
    }, 5000);
  }

  ngOnInit(): void {
    this.refreshNgOnInit$
      .pipe(
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          this.organizationStore.setActive(null);
          return merge(
            this.authQuery.adminUser$.pipe(
              tap((event) => {
                this.periodCloseService.isAdminUser.next(event);
              })
            ),
            this.organizationService.listIdOrganizations$().pipe(
              switchMap(() => {
                this.invoicesLoading$.next(true);

                return combineLatest([
                  this.gqlService
                    .listInvoicesForReconciliation$({
                      invoice_statuses: this.invoice_statuses,
                      deposit: false,
                    })
                    .pipe(
                      map(({ success, data, errors }) => {
                        // Store the ungrouped invoices to support
                        // the Review Invoices, Prepaids, and Credit Memos checklist row.
                        // The checklist row requires knowing the number
                        // of invoices, and their current statuses.
                        this.periodCloseService.ungroupedInvoices = data || [];

                        this.invoices$.next(
                          groupBy(
                            (data || [])
                              .filter((invoice) => invoice.accrual_period)
                              .map(
                                ({
                                  id,
                                  accrual_period,
                                  organization,
                                  invoice_status,
                                  expense_amounts,
                                  payment_date,
                                }) => {
                                  return {
                                    id,
                                    accrual_period: accrual_period || '',
                                    organization_id: organization.id,
                                    invoice_status: invoice_status || '',
                                    expense_amounts:
                                      this.invoiceService.mapExpenseAmountToObject(expense_amounts),
                                    payment_date: payment_date || '',
                                  };
                                }
                              ),
                            'organization_id'
                          ) as Record<
                            string,
                            {
                              id: string;
                              accrual_period: string;
                              organization_id: string;
                              invoice_status: InvoiceStatus;
                              expense_amounts: InvoiceAmounts;
                              payment_date: string;
                            }[]
                          >
                        );
                        if (!(success && data)) {
                          this.overlayService.error(errors);
                        }

                        this.invoicesLoading$.next(false);
                      })
                    ),
                ]);
              })
            ),
            this.refresh$.pipe(
              startWith(null),
              switchMap(() => {
                return combineLatest([
                  this.selectedQuarter.valueChanges.pipe(
                    distinctUntilChanged(),
                    startWith(null),
                    switchMap((selectedQuarter) => {
                      if (selectedQuarter) {
                        this.updateIsCurrentQuarterSelected(selectedQuarter);
                      }

                      this.lastSelectedQuarterValue = selectedQuarter;
                      this.onSelectedQuarterChange(selectedQuarter as string);

                      return this.budgetService.getInMonthBudgetData(
                        selectedQuarter ? this.quartersObj[selectedQuarter][0].iso : null
                      );
                    })
                  ),
                  this.budgetService.getMonthCloseApprovals(),
                ]);
              })
            ),
            this.gridData$.pipe(
              tap((rows) => {
                const { obj } = this.generatePinnedBottomData(
                  rows,
                  <Currency>this.currencies.values().next().value
                );

                if (this.currencies.size === 1) {
                  this.bottomRowData$.next(obj);
                } else {
                  this.bottomRowData$.next(this.emptyBottomData());
                }
              })
            )
          );
        })
      )
      .subscribe();

    this.iCloseMonthsProcessing$
      .pipe(takeUntilDestroyed(this.destroyRef), skip(1), distinctUntilChanged())
      .subscribe((isProcessing) => {
        if (!isProcessing) {
          firstValueFrom(this.mainService.getOpenMonth$());
        }
      });
  }

  updateIsCurrentQuarterSelected(selectedQuarter: string) {
    const isCurrentQuarterSelected = selectedQuarter === this.currentQuarter;

    this.periodCloseService.selectedQuarterMonth = isCurrentQuarterSelected
      ? this.currentMonth
      : last(this.quartersObj[selectedQuarter])?.iso || '';

    this.periodCloseService.persistedQuarterMonth = '';

    this.periodCloseService.currentOpenMonth = this.currentMonth;

    this.periodCloseService.quarterMonths = this.quartersObj[this.selectedQuarter.value || ''];

    this.periodCloseService.isCurrentQuarterSelected.next(isCurrentQuarterSelected);
  }

  onSelectedQuarterChange(selectedQuarter: string): void {
    if (selectedQuarter) {
      const workflowDate = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url))
        .format('MMM-YYYY')
        .toUpperCase();

      if (this.getWorkflowDate === workflowDate) {
        return;
      }

      this.getWorkflowSub?.unsubscribe();
      this.getWorkflowDate = workflowDate;

      if (!this.router.url.includes(ROUTING_PATH.CLOSING.CHECKLIST)) {
        this.getWorkflowSub = this.workflowService.getWorkflowList(workflowDate).subscribe();
      }
    }
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
    this.getWorkflowSub?.unsubscribe();
    this.periodCloseService.resetAllState();
  }

  generatePinnedBottomData(rows: MonthCloseTableRowData[], currency: Currency) {
    const data = rows.reduce(
      (acc, row) => {
        this.closedMonthColumns.forEach((field) => {
          for (const period of Object.keys(row[field])) {
            if (field === 'eom_accrual') {
              acc.eom_accruals_trial_currency[period] =
                (acc.eom_accruals_trial_currency[period] || 0) +
                (row.eom_accrual[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else if (field === 'eom_prepaid') {
              acc.eom_prepaid_trial_currency[period] =
                (acc.eom_prepaid_trial_currency[period] || 0) +
                (row.eom_prepaid[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else if (field === 'eom_unpaid') {
              acc.eom_unpaid_trial_currency[period] =
                (acc.eom_unpaid_trial_currency[period] || 0) +
                (row.eom_unpaid[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else {
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            }
          }
        });
        return acc;
      },
      {
        forecast_obj: {},
        adjustment_obj: {},
        accrual_adjusted_obj: {},
        invoice_amounts: {},
        net_accruals: {},
        eom_accruals: {},
        eom_accruals_trial_currency: {},
        eom_accrual: {},
        eom_prepaid: {},
        eom_unpaid: {},
        eom_prepaid_trial_currency: {},
        eom_unpaid_trial_currency: {},
        eom_accrual_debit: {},
        eom_accrual_credit: {},
        eom_prepaid_debit: {},
        eom_prepaid_credit: {},
        initial_wp_obj: {},
        monthly_wp_obj: {},
        monthly_invoice_ltd: {},
      } as {
        forecast_obj: Record<string, number>;
        adjustment_obj: Record<string, number>;
        accrual_adjusted_obj: Record<string, number>;
        invoice_amounts: Record<string, number>;
        net_accruals: Record<string, number>;
        eom_accruals: Record<string, number>;
        eom_accruals_trial_currency: Record<string, number>;
        eom_accrual: Record<string, number>;
        eom_prepaid: Record<string, number>;
        eom_unpaid: Record<string, number>;
        eom_prepaid_trial_currency: Record<string, number>;
        eom_unpaid_trial_currency: Record<string, number>;
        eom_accrual_debit: Record<string, number>;
        eom_accrual_credit: Record<string, number>;
        eom_prepaid_debit: Record<string, number>;
        eom_prepaid_credit: Record<string, number>;
        initial_wp_obj: Record<string, number>;
        monthly_wp_obj: Record<string, number>;
        monthly_invoice_ltd: Record<string, number>;
        [k: string]: Record<string, number>;
      }
    );

    const { ending_accruals, starting_accrual_balance } = rows.reduce(
      (acc, row) => {
        acc.ending_accruals += row.ending_accruals;
        acc.starting_accrual_balance += row.starting_accruals;
        return acc;
      },
      {
        ending_accruals: 0,
        starting_accrual_balance: 0,
      }
    );

    const analytics = {
      initial_wp: 0,
      total_adjustments: 0,
      invoices_received: 0,
      ending_accruals,
      starting_accrual_balance,
    };

    (this.quartersObj[this.selectedQuarter.value || ''] || []).forEach((month) => {
      analytics.initial_wp += data.initial_wp_obj[month.date] || 0;
      analytics.total_adjustments += data.adjustment_obj[month.date] || 0;
    });

    analytics.invoices_received = this.quarter_end_data.invoice_ltd;

    const obj = {
      ...data,
      wp_ltd_start: this.quarter_start_data.wp_ltd,
      invoice_ltd_start: this.quarter_start_data.invoice_ltd,
      starting_accrual: this.quarter_start_data.starting_accrual,
      starting_prepaid: this.quarter_start_data.starting_prepaid,
      ending_accruals: this.quarter_end_data.ending_accruals,
      wp_ltd_end: this.quarter_end_data.wp_ltd,
      invoice_ltd_end: this.quarter_end_data.invoice_ltd,
      invoice_unpaid_ltd_end: this.quarter_end_data.invoice_unpaid_ltd,
      cost_category: 'Total',
      currency,
      account_value: '',
      po_value: '',
      dept_value: '',
    };

    const eom_net_accrual_trial_currency: Record<string, number> = {};
    Object.keys(obj.eom_accruals_trial_currency).forEach((month) => {
      eom_net_accrual_trial_currency[month] =
        obj.eom_accruals_trial_currency[month] - obj.eom_prepaid_trial_currency[month];
    });

    return {
      obj,
      analytics,
    };
  }

  async downloadAuditPackages() {
    const files = await this.apiService.getFilesByFilters(
      `trials/${this.trialKey}/`,
      undefined,
      undefined,
      DocumentType.DOCUMENT_MONTHLY_AUDIT_PACKAGE,
      undefined
    );
    if (files) {
      this.auditPackages$.next(files);
    }
  }

  filterQuarterPackagesToMonths(date: string, fileName: string) {
    const dateLowerCase = date.toLowerCase();
    const fileNameLowerCase = fileName.toLowerCase();
    const yearCheck = fileName.includes(dayjs(date).year().toString());
    return (
      yearCheck &&
      ((dateLowerCase.includes('march') && fileNameLowerCase.includes('q1')) ||
        (dateLowerCase.includes('june') && fileNameLowerCase.includes('q2')) ||
        (dateLowerCase.includes('september') && fileNameLowerCase.includes('q3')) ||
        (dateLowerCase.includes('december') && fileNameLowerCase.includes('q4')))
    );
  }

  filterPackagesByMonth(date: string) {
    return this.auditPackages$
      .getValue()
      .filter((ap) => {
        if (ap.bucket_key && ap.name) {
          const bucket_key = ap.bucket_key.replace('public/', '');
          const dateForMonthlyAuditPackages = `${dayjs(date).format('MMM-YYYY')}`;
          const dateForAccrualSummary = `${dayjs(date).format('MMMM-YYYY').toLowerCase()}`;
          return (
            bucket_key.includes(dateForMonthlyAuditPackages) ||
            (bucket_key.toLowerCase().includes('accrual') &&
              (this.filterQuarterPackagesToMonths(dateForAccrualSummary, ap.name) ||
                bucket_key.includes(dateForAccrualSummary)))
          );
        }
        return false;
      })
      .map((val) => {
        return { ...val, bucket_key: val.bucket_key.replace('public/', '') };
      });
  }

  private setMonthStats() {
    const data = this.newQCGridData$.getValue();
    const months = data.reduce(
      (acc, row) => {
        return {
          month1: acc.month1 + (row.qmonth1_net_position_tc || 0),
          month2: acc.month2 + (row.qmonth2_net_position_tc || 0),
          month3: acc.month3 + (row.qmonth3_net_position_tc || 0),
        };
      },
      {
        month1: 0,
        month2: 0,
        month3: 0,
      }
    );
    const monthsArr = [months.month1, months.month2, months.month3];

    const currentMonth = dayjs(this.currentMonth);

    const newMonthStats = this.months.map(({ date }, index) => {
      let status = 'Open';

      const parserDateFormat = date.charAt(0).toUpperCase() + date.slice(1).toLowerCase();
      const month = dayjs(parserDateFormat, 'MMM-YYYY');

      if (month.isBefore(currentMonth, 'month')) {
        status = 'Closed';
      }

      if (month.isAfter(currentMonth, 'month')) {
        status = 'Future';
      }

      // todo(prepaid) remove this after test
      // const whichMonth = (parsedDate.month() || 0) % 3;

      return <MonthStats>{
        date: dayjs(`01-${date}`).toISOString(),
        eom_accruals: monthsArr[index],
        status: status as MonthStats['status'],
      };
    });

    const areAllMonthsClosed = newMonthStats.every((month) => month.status === 'Closed');
    const monthsWithPackages = newMonthStats.map((monthStat) => {
      return {
        ...monthStat,
        allOfThemClosed: areAllMonthsClosed,
        auditPackages: this.filterPackagesByMonth(monthStat.date),
      };
    });
    this.monthsStat$.next(monthsWithPackages);
  }
}
