<ng-select
  class="w-full tabular-nums"
  [formControl]="formControl"
  [clearable]="true"
  [searchable]="true"
  [items]="params.options"
  [searchFn]="search"
  bindLabel="invoice_no"
  bindValue="id"
  appendTo="body"
>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    @if (!item.id) {
      <p class="pr-4 text-sm font-bold leading-5 overflow-hidden text-ellipsis">
        {{ utils.zeroHyphen }}
      </p>
    } @else {
      <div [auxTooltip]="tooltip(item)">
        <p class="pr-4 text-sm font-bold leading-5 overflow-hidden text-ellipsis">
          {{ item.invoice_no }}
        </p>

        <p class="pr-4 text-sm italic leading-5 overflow-hidden text-ellipsis">
          {{ totalAmount(item) }}
        </p>

        <p class="pr-4 text-sm italic leading-5 overflow-hidden text-ellipsis">
          {{ accrualPeriod(item) }}
        </p>
      </div>
    }
  </ng-template>
</ng-select>
