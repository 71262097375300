import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { ApiService } from '@shared/services/api.service';
import { TemplateType } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Maybe } from '@shared/utils/utils';

@Component({
  selector: 'aux-download-template-button',
  template: `
    <div class="btn--hyperlink cursor-pointer flex justify-start" (click)="downloadFile()">
      @if (showIcon) {
        <aux-icon name="Download" class="mr-1" />
      }

      <div class="self-end">{{ label }}</div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class DownloadTemplateButtonComponent {
  apiService = inject(ApiService);

  overlayService = inject(OverlayService);

  @Input({ required: true }) label!: string;

  @Input({ required: true }) templateType!: TemplateType;

  @Input() showIcon = true;

  @Input() vendorId: Maybe<string> = null;

  @Input() templatePayload?: string;

  @Input() fileName?: Maybe<string> = null;

  async downloadFile() {
    const { success, data } = await this.apiService.getTemplatePath(
      this.vendorId ?? null,
      this.templateType,
      this.templatePayload
    );
    if (!(success && data)) {
      this.overlayService.error(MessagesConstants.FILE.PROBLEM_DOWNLOADING_TEMPLATE);
    } else {
      await this.apiService.downloadFileFromPath(data.id, this.fileName ?? '');
    }
  }
}
