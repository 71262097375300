<div class="overflow-auto" style="max-height: 80vh">
  <aux-warning-vendor-estimate
    *ngIf="showEstimateWarningBanner$ | async"
    class="max-w-[720px] block"
    (linkClick)="onLinkClick()"
  />

  <div class="grid mt-4 w-[720px]">
    <div class="flex justify-between flex-col">
      <div *ngIf="errorMessage()" class="mb-4 p-5 font-medium bg-aux-error text-white rounded-md">
        {{ errorMessage() }}
      </div>

      <div class="mx-0.5 grid grid-cols-2 gap-5">
        <div class="flex flex-col justify-between space-y-2">
          <div>
            <div class="mb-2 text-xs"><span class="text-aux-error font-bold">* </span>Vendor</div>
            <ng-select
              placeholder="Select"
              id="vendor"
              [formControl]="selectedVendor"
              [appendTo]="'body'"
              [searchable]="true"
              [clearable]="false"
            >
              <ng-option
                *ngFor="let vendor of vendorsQuery.allReceivesEstimateVendors$ | async"
                [value]="vendor.id"
              >
                <span [title]="vendor.name">{{ vendor.name }}</span>
              </ng-option>
            </ng-select>
          </div>
          <div class="mb-4">
            <div class="mb-2 text-xs"><span class="text-aux-error font-bold">* </span>Month</div>
            <ng-select
              placeholder="Select"
              id="months"
              [formControl]="selectedMonth"
              [appendTo]="'body'"
              [searchable]="true"
              [clearable]="false"
            >
              <ng-option *ngFor="let month of availableMonths$" [value]="month.date">
                <span [title]="month.name">{{ month.name }}</span>
              </ng-option>
            </ng-select>
          </div>
          <aux-input label="Short Name" [formControl]="shortName" />
          <aux-input label="Notes/Comments/Context" [formControl]="notes" />
          <aux-input label="Total Estimate Amount" placeholder="$" [formControl]="totalAmount" />
        </div>
        <div>
          <aux-file-manager
            #manager
            class="h-32"
            [fetchFilesOnInit]="false"
            [pathFn]="pathFn"
            [eager]="false"
            [metadata]="metadata"
            [showSuccessOnUpload]="true"
          />
          <div class="mt-2 text-xs">Uploaded Files</div>
          <ng-container *ngIf="fileManager?.fileQuery?.selectAll() | async as files">
            <ng-container *ngIf="files.length">
              <ng-container *ngFor="let file of files; let last = last" class="border-b">
                <div
                  class="flex justify-between items-center py-2"
                  [ngClass]="{ 'border-b': !last }"
                >
                  <div class="items-start text-xs break-all">
                    {{ file.fileName }}
                  </div>

                  <button
                    class="btn rounded-full border-0 shadow-none p-0 w-6 h-6"
                    type="button"
                    (click)="removeFile(file)"
                  >
                    <aux-icon name="Trash" class="text-aux-error" />
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
