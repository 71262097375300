import { Component, ViewChildren } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { NgIf } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SharedModule } from '@shared/shared.module';

export interface AgInlineHeaderComponentParams extends IHeaderParams {
  onBlur(name: string): void;
  getHeaderNames: () => Record<string, string>;
  onRemoveColumn: () => Promise<void>;
  autoFocus: boolean;
}

@Component({
  standalone: true,
  template: `
    <div class="w-full h-full flex items-center justify-between">
      <aux-input
        *ngIf="edit"
        #inputComponent
        inputClassName="inline-header-input"
        class="w-full"
        (auxClickOutside)="onBlur()"
        (keydown.escape)="onBlur()"
        (keydown.enter)="onBlur()"
        (keydown.tab)="onBlur()"
        (keydown.shift.tab)="onBlur()"
        [(ngModel)]="headerName"
        [autofocus]="true"
      />
      <div
        *ngIf="!edit"
        (click)="onEdit()"
        class="pl-2 flex-grow h-full truncate inputTrigger leading-[30.5px] text-center"
        [auxTooltip]="headerName"
      >
        {{ headerName }}
      </div>

      <div class="h-full px-2 border-l cursor-pointer flex items-center" (click)="removeColumn()">
        <aux-icon class="flex-shrink-0 text-aux-error" name="Trash" />
      </div>
    </div>
  `,
  imports: [NgIf, InputComponent, FormsModule, SharedModule, IconComponent],
  styles: [
    `
      :host {
        @apply w-full h-full;
      }

      ::ng-deep .inline-header-input {
        height: 30.5px;
      }
    `,
  ],
})
export class AgInlineHeaderComponent implements IHeaderAngularComp {
  @ViewChildren(InputComponent) inp?: InputComponent;

  params!: AgInlineHeaderComponentParams;

  edit = false;

  headerName = '';

  agInit(params: AgInlineHeaderComponentParams): void {
    this.params = params;
    if (params.autoFocus) {
      this.edit = true;
    }
    this.headerName = params.getHeaderNames()[params.column.getColId()];
  }

  refresh() {
    return true;
  }

  onEdit() {
    this.edit = !this.edit;
  }

  onBlur() {
    this.onEdit();
    this.params.onBlur(this.headerName);
  }

  removeColumn() {
    this.params.onRemoveColumn();
  }
}
