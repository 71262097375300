import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconComponent } from '@shared/components/icon/icon.component';
import { EditableListDropdownItem } from './editable-list-dropdown-item.model';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-editable-list-dropdown',
  templateUrl: './editable-list-dropdown.component.html',
  styleUrls: ['editable-list-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSelectModule, NgFor, NgIf, NgClass, IconComponent, FormsModule, TooltipDirective],
})
export class EditableListDropdownComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  editDisabled = input(false);

  deleteDisabled = input(false);

  createDisabled = input(false);

  editDisabledTooltip = input('');

  deleteDisabledTooltip = input('');

  createDisabledTooltip = input('');

  @Input() dropdownFormControl = new FormControl('');

  @Input() items: EditableListDropdownItem[] = [];

  @Input() label = '';

  @Input() id = '';

  @Input() classList = '';

  @Input() createBtnLabel = '';

  @Output() edit = new EventEmitter<EditableListDropdownItem>();

  @Output() delete = new EventEmitter<EditableListDropdownItem>();

  @Output() create = new EventEmitter();

  formControlValue: string | null = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dropdownFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.formControlValue = value || null;
        this.changeDetectorRef.detectChanges();
      });
  }

  onEdit(event: Event, item: EditableListDropdownItem): void {
    console.log('onEdit');
    event.stopPropagation();
    if (item.disabled || this.editDisabled()) {
      return;
    }

    this.edit.emit(item);
  }

  onDelete(event: Event, item: EditableListDropdownItem): void {
    event.stopPropagation();
    if (item.disabled || this.deleteDisabled()) {
      return;
    }

    this.delete.emit(item);
  }

  onCreate(): void {
    if (this.createDisabled()) {
      return;
    }
    this.create.emit();
  }

  select(value: string): void {
    this.dropdownFormControl.setValue(value);
  }
}
