import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  signal,
  DestroyRef,
  inject,
} from '@angular/core';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ExportType, Utils } from '@shared/utils/utils';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import {
  EntityType,
  EventType,
  GqlService,
  listPermissionsQuery,
  PermissionType,
} from '@shared/services/gql.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { MainQuery } from '@shared/store/main/main.query';
import dayjs from 'dayjs';
import { OverlayService } from '@shared/services/overlay.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import { AgTooltipComponent } from '@features/inline-budget/ag-tooltip.component';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetGroupHeaderComponent,
} from '@features/ag-budget-group-header/ag-budget-group-header.component';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { AgGroupCheckboxComponent, ICheckboxCellParams } from './ag-group-checkbox.component';
import { CommonModule } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { FormsModule } from '@angular/forms';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { SaveChangesComponent } from '@features/save-changes/save-changes.component';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { EventService } from '@models/event/event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilArrayItemChanged } from '@datorama/akita';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';

dayjs.extend(utc);
dayjs.extend(timezone);

type UserPermissionsGridData = listPermissionsQuery & {
  [k: string]: boolean;
};

@Component({
  selector: 'aux-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrl: './user-permissions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AgGridAngular,
    InputComponent,
    FormsModule,
    TableSkeletonComponent,
    SaveChangesComponent,
    ExportExcelButtonComponent,
    FileManagerComponent,
    StickyGridDirective,
  ],
  standalone: true,
})
export class UserPermissionsComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  permissions$ = new BehaviorSubject<listPermissionsQuery[]>([]);

  isSysAdmin$ = this.authService.isAuthorized$({
    sysAdminsOnly: true,
  });

  loggedInUserHasAdministration$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS],
  });

  reset$ = new BehaviorSubject(null);

  matrixUsers$ = this.trialUserQuery
    .selectAll()
    .pipe(
      map((users) => {
        return users
          .filter((user) => {
            // todo: remove this when we get superAdmin flag
            return user.email ? !user.email.includes('@auxili.us') : true;
          })
          .sort((x, y) => {
            return Utils.alphaNumSort(
              `${x.given_name} ${x.family_name}` as string,
              `${y.given_name} ${y.family_name}` as string
            );
          });
      })
    )
    .pipe(distinctUntilArrayItemChanged());

  gridData$ = new BehaviorSubject<UserPermissionsGridData[]>([]);

  gridAPI = signal<GridApi | undefined>(undefined);

  loading = signal(true);

  autoGroupColumnDef: ColDef = {
    headerName: 'Permission',
    headerClass: 'permission-header',
    headerComponent: AgBudgetGroupHeaderComponent,
    headerComponentParams: {
      columnsToCollapse: [] as string[],
      expandLevel: () => 2,
      template: `Permission`,
      localStorageKey: LocalStorageKey.USER_PERMISSION_STORAGE,
      alignHeaderOneRowHeight: true,
      templateClasses: ['text-[16px] font-semibold'],
      quickFilterText: true,
    } as AgBudgetAttributeComponentParams,
    cellRendererParams: {
      suppressCount: true,
    },
    minWidth: 400,
    width: 400,
    field: 'permission',
    cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT, 'custom-group-icon'],
    pinned: 'left',
    resizable: true,
  };

  gridOptions: GridOptions<UserPermissionsGridData> = {
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDefaultExpanded: -1,
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      tooltipComponent: AgTooltipComponent,
    },
    suppressPropertyNamesCheck: true,
    autoGroupColumnDef: this.autoGroupColumnDef,
    groupAllowUnbalanced: true,
    tooltipShowDelay: 0,
    suppressColumnVirtualisation: true,
    suppressCellFocus: true,
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    initialGroupOrderComparator: ({ nodeA, nodeB }) => {
      if (nodeA.key === 'Checklist') {
        return -1;
      }
      if (nodeB.key === 'Checklist') {
        return 1;
      }
      if (nodeA.key === 'Invoices') {
        return -1;
      }
      if (nodeB.key === 'Invoices') {
        return 1;
      }
      return 0;
    },
    rowClassRules: {
      'custom-group-background': (params) => !!params.node.group && params.node.level === 0,
    },
    getRowHeight: (params) => {
      if (params.node.group && params.node.level === 0) {
        return 50;
      }
      return 35;
    },
  };

  gridOptions$ = new BehaviorSubject<GridOptions<UserPermissionsGridData>>(this.gridOptions);

  defaultColumns: ColDef[] = [
    {
      field: 'permission',
      hide: true,
    },
    {
      field: 'permission_group',
      rowGroup: true,
      hide: true,
    },
    {
      field: 'permission_sub_group',
      rowGroup: true,
      hide: true,
    },
  ];

  changedValues: {
    sub: string;
    permission: string;
    value: boolean;
  }[] = [];

  constructor(
    private trialUserQuery: TrialUserQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private trialUserService: TrialUserService,
    private overlayService: OverlayService,
    private eventService: EventService
  ) {
    combineLatest([
      this.gridData$,
      // this.matrixUsers$,
      this.isSysAdmin$,
      this.loggedInUserHasAdministration$,
    ])
      .pipe(debounceTime(0), takeUntilDestroyed())
      .subscribe(async ([data, isSysAdmin, loggedInUserHasAdministration]) => {
        const users = await firstValueFrom(this.matrixUsers$);
        this.loading.set(true);

        const userSub = this.authQuery.getValue().sub;
        const columnDefs: (
          | ColDef<UserPermissionsGridData>
          | ColGroupDef<UserPermissionsGridData>
        )[] = [...this.defaultColumns];
        this.changedValues = [];
        if (users.length && data.length) {
          columnDefs.push({
            headerName: 'User',
            headerClass: TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER,
            children: users.map((user) => {
              return {
                headerName: `${user.given_name} ${user.family_name?.[0]?.toUpperCase()}.`,
                field: user.sub,
                headerClass: [
                  TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER,
                  'ag-header-truncate',
                ],
                headerTooltip: `${user.given_name} ${user.family_name?.[0]?.toUpperCase()}.`,
                cellClass: [
                  TableConstants.STYLE_CLASSES.CELL_VERTICAL_HORIZONTAL_ALIGN_CENTER,
                  '!flex',
                ],
                minWidth: 100,
                cellRenderer: AgGroupCheckboxComponent,
                cellRendererParams: <ICheckboxCellParams>{
                  isEditable: (node, field) => {
                    if (isSysAdmin) {
                      return true;
                    }

                    if (!loggedInUserHasAdministration) {
                      return false;
                    }

                    if (
                      node?.data?.permission_type ===
                      PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS
                    ) {
                      return false;
                    }
                    return field !== userSub;
                  },
                  checkboxTooltip: () => {
                    return '';
                  },
                  onChange: (node: IRowNode<UserPermissionsGridData>, field, value) => {
                    if (node.data) {
                      const permission = node.data.id;
                      const index = this.changedValues.findIndex(
                        (v) => v.sub === field && v.permission === permission
                      );
                      const obj = {
                        permission,
                        value,
                        sub: field,
                      };
                      if (index !== -1) {
                        this.changedValues.splice(index, 1);
                      } else {
                        this.changedValues.push(obj);
                      }
                    }
                  },
                },
              };
            }),
          });
        }
        this.gridOptions$.next(<GridOptions<UserPermissionsGridData>>{
          ...this.gridOptions,
          columnDefs,
        });

        // await for ag-grid handling new columns
        setTimeout(() => {
          this.loading.set(false);
        }, 1000);
      });
  }

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => this.gqlService.listPermissions$()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((x) => {
        const permissions: listPermissionsQuery[] = [];
        const administration_permissions: listPermissionsQuery[] = [];

        x.data?.forEach((x) => {
          if (x.permission_group === 'Administration') {
            administration_permissions.push(x);
          } else {
            permissions.push(x);
          }
        });

        this.permissions$.next([...permissions, ...administration_permissions]);
      });

    combineLatest([this.permissions$, this.matrixUsers$, this.reset$])
      .pipe(
        map(([permissions, users]) => {
          this.gridData$.next(
            users.length
              ? permissions.map((permission) => {
                  const userPerms = users.reduce(
                    (acc, user) => {
                      acc[user.sub] = user.permissions[permission.id] === 'E';
                      return acc;
                    },
                    {} as Record<string, boolean>
                  );

                  return <UserPermissionsGridData>{
                    ...permission,
                    permission_group: permission.permission_group || '',
                    permission_sub_group: permission.permission_sub_group || '',
                    ...userPerms,
                  };
                })
              : []
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.changedValues.length) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  gridReady(event: GridReadyEvent) {
    this.gridAPI.set(event.api);

    this.gridAPI()?.sizeColumnsToFit();
  }

  async onExport() {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const trialId = this.mainQuery.getSelectedTrial()?.id || '';
    const fileTimestamp = dayjs().format('YYYY.MM.DD-HHmmss');
    const currentTz = dayjs.tz.guess();

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: trialId,
        payload: JSON.stringify({
          export_type: ExportType.PERMISSIONS,
          filename: `auxilius_${trialShortName}_User_Permissions_${fileTimestamp}`,
          export_entity_id: trialId || '',
          json_properties: {
            fileTimestamp: `${fileTimestamp} ${currentTz}`,
          },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  onDiscardChanges() {
    this.reset$.next(null);
  }

  onSaveChanges = async () => {
    const ref = this.overlayService.loading();
    await this.trialUserService.batchProcessPermissionUpdates(this.changedValues);
    ref.close();
  };
}
