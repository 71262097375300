import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { File } from '@shared/components/file-manager/state/file.model';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OverlayService } from '@shared/services/overlay.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map, switchMap } from 'rxjs/operators';
import {
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  TemplateType,
} from '@shared/services/gql.service';
import { ApiService } from '@shared/services/api.service';
import { Utils } from '@shared/utils/utils';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { EventService as GlobalEventService } from '@models/event/event.service';
import { InvoiceService } from '../state/invoice.service';
import { INVOICE_STATUSES, InvoiceModel } from '../state/invoice.model';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceQuery } from '../state/invoice.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-new-invoice-dialog',
  templateUrl: './new-invoice-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewInvoiceDialogComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('singleFileManager') singleFileManager: FileManagerComponent | undefined;

  @ViewChild('bulkFileManager') bulkFileManager: FileManagerComponent | undefined;

  filteredPONumbers$ = this.purchaseOrdersQuery.selectAll();

  pdf = 'application/pdf';

  path = '';

  files$: Observable<File[]> | undefined;

  bulkFiles$: Observable<File[]> | undefined;

  obj: { [k: string]: 'invoice' | 'supporting' } = {};

  selectedVendor = new UntypedFormControl('', [Validators.required]);

  selectedPOReference = new UntypedFormControl(null);

  loading$ = new BehaviorSubject(false);

  oneInvoiceSelected = false;

  updateValidation = new Subject();

  mode: 'edit' | 'new' = 'new';

  invoice?: InvoiceModel;

  tabs = [{ label: 'Single Invoice' }, { label: 'Bulk Upload' }];

  activeIndex = 0;

  errorMessage = signal('');

  invoiceTemplateType = TemplateType.BULK_INVOICE_TEMPLATE;

  constructor(
    public ref: CustomOverlayRef<unknown, { mode: 'edit' | 'new'; id: string; vendorId?: string }>,
    public organizationQuery: OrganizationQuery,
    public organizationService: OrganizationService,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private invoiceService: InvoiceService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private invoiceQuery: InvoiceQuery,
    private eventService: EventTrackerService,
    private globalEventService: GlobalEventService
  ) {
    this.organizationService
      .get()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.ref.data?.vendorId) {
          this.selectedVendor.setValue(this.ref.data.vendorId);
          this.onChangeVendor(this.ref.data.vendorId);
        }
      });

    if (this.ref.data?.id) {
      this.mode = this.ref.data.mode;
      const invoice = this.invoiceQuery.getEntity(this.ref.data.id);
      if (invoice) {
        this.invoice = invoice;
        this.selectedVendor.setValue(invoice.organization.id);
        this.selectedPOReference.setValue(invoice.po_reference);
        this.selectedVendor.disable();
        this.selectedPOReference.disable();
      }
    }
  }

  getFilePath(vendor_sub: string, invoice_sub: string, type: 'invoice' | 'supporting') {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendor_sub}/invoices/${invoice_sub}/${type}/`;
  }

  getBulkFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/bulk-invoice-templates/`;
  }

  async onUpload() {
    this.selectedVendor.markAsDirty();
    this.selectedVendor.markAsTouched();
    this.selectedVendor.updateValueAndValidity();
    if (
      this.singleFileManager &&
      !this.loading$.getValue() &&
      (this.mode === 'new' ? this.selectedVendor.valid : true)
    ) {
      this.loading$.next(true);
      let success = false;
      let errors: string[] = [];
      let data = null;
      const files = this.singleFileManager.fileQuery.getAll();
      const filesWithUpdatedBucketKey: File[] = [];

      if (this.mode === 'new') {
        const invoiceId = Utils.uuid();
        const bucket_keys = [];

        for (const file of files) {
          const bucket_key = `${this.getFilePath(
            this.selectedVendor.value,
            invoiceId as string,
            this.obj[file.id]
          )}${file.bucket_key}`;

          bucket_keys.push(bucket_key);
          filesWithUpdatedBucketKey.push({
            ...file,
            bucket_key,
          });
        }
        const resp = await this.invoiceService.add({
          id: invoiceId,
          organization_id: this.selectedVendor.value,
          po_reference: this.selectedPOReference.value,
          bucket_keys,
        });
        success = resp.success;
        errors = resp.errors;
        data = resp.data;
      } else if (this.invoice) {
        success = true;
        data = { id: this.invoice.id };
      }

      if (success && data) {
        for (const file of filesWithUpdatedBucketKey) {
          this.singleFileManager.fileStore.update(file.id, file);
        }

        const filesSuccess = await this.singleFileManager.fileService.uploadFiles({
          status: INVOICE_STATUSES.STATUS_IN_QUEUE,
        });

        if (filesSuccess) {
          if (this.mode === 'new' && this.oneInvoiceSelected) {
            const invoice = files.find((file) => this.obj[file.id] === 'invoice') || files[0];
            const tracking_id = Utils.uuid();

            const { success: eventSuccess, errors: eventErrors } = await firstValueFrom(
              this.globalEventService.processEvent$({
                type: EventType.INVOICE_CREATED,
                entity_type: EntityType.INVOICE,
                entity_id: data.id,
                tracking_id,
                bucket_key: `public/${this.getFilePath(
                  this.selectedVendor.value,
                  data.id as string,
                  this.obj[invoice.id]
                )}${invoice.bucket_key}`,
              })
            );

            if (eventSuccess) {
              this.eventService.trackEvent(tracking_id);
              this.overlayService.success(`Invoice successfully created!`);
            } else {
              for (const file of files) {
                this.apiService.removeFile(
                  `${this.getFilePath(
                    this.selectedVendor.value,
                    data.id as string,
                    this.obj[file.id]
                  )}${file.bucket_key}`
                );
              }
              this.overlayService.error(eventErrors);
            }
          } else if (this.mode === 'new' && !this.oneInvoiceSelected) {
            await this.invoiceService.update({
              ...data,
              invoice_status: InvoiceStatus.STATUS_PENDING_REVIEW,
              audit_record_create: true,
            } as InvoiceModel);
            await firstValueFrom(
              this.globalEventService.processEvent$({
                type: EventType.INVOICE_PENDING_REVIEW,
                entity_type: EntityType.INVOICE,
                entity_id: data.id,
              })
            );

            this.overlayService.success(`Invoice successfully created!`);
          } else {
            this.overlayService.success(`Invoice successfully updated!`);
          }

          this.ref.close(true);
        }
      } else {
        this.overlayService.error(errors);
      }
    }
    this.loading$.next(false);
  }

  onChangeVendor(selectedVendorId: string) {
    this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
      map((value) => {
        return value.filter((po) => {
          if (selectedVendorId) {
            return po.organization_id === selectedVendorId;
          }
          return po;
        });
      })
    );
  }

  removeFile(file: File) {
    if (this.singleFileManager) {
      this.singleFileManager.removeFile(file);
    }
  }

  removeBulkFile(file: File) {
    if (this.bulkFileManager) {
      this.bulkFileManager.removeFile(file);
    }
  }

  ngAfterViewInit() {
    if (this.singleFileManager) {
      this.files$ = this.singleFileManager.fileQuery.selectAll();
      this.updateValidation
        .pipe(
          switchMap(() => this.files$ as Observable<File[]>),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((files) => {
          files.forEach((file) => {
            if (this.obj[file.id] === undefined) {
              this.obj[file.id] = 'supporting';
            }
          });

          this.oneInvoiceSelected = files.some((file) => this.obj[file.id] === 'invoice');
        });

      this.updateValidation.next(null);
    }

    if (this.bulkFileManager) {
      this.bulkFiles$ = this.bulkFileManager.fileQuery.selectAll();
    }
  }

  async uploadInvoice() {
    if (this.activeIndex === 0) {
      await this.onUpload();
    } else {
      await this.onUploadBulk();
    }
  }

  onTabChange(index: number) {
    this.activeIndex = index;
  }

  async onUploadBulk() {
    this.errorMessage.set('');

    if (this.bulkFileManager && !this.loading$.getValue()) {
      const files = this.bulkFileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage.set(MessagesConstants.FILE.NEED_UPLOAD_FILE);
        return;
      }

      if (files.length > 1) {
        this.errorMessage.set(MessagesConstants.FILE.MAX_ONE_FILE);
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getBulkFilePath()}${file.bucket_key}`;

      this.bulkFileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.bulkFileManager.fileService.uploadFiles();

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.globalEventService.processEvent$({
            type: EventType.BULK_INVOICE_TEMPLATE_UPLOADED,
            entity_type: EntityType.INVOICE,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
        );
        if (success) {
          this.overlayService.success();
        } else {
          this.apiService.removeFile(key);
          this.overlayService.error(errors, undefined, true);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
