import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MonthlyReviewNote } from '@shared/store/quarter-close-notes/quarter-close-notes.store';
import { MonthlyReviewRecordComponent } from './monthly-review-record/monthly-review-record.component';

@Component({
  selector: 'aux-monthly-review-notes',
  templateUrl: './monthly-review-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MonthlyReviewRecordComponent],
})
export class MonthlyReviewNotesComponent {
  @Input() records!: MonthlyReviewNote[] | null;

  get sortedRecords(): MonthlyReviewNote[] {
    return (
      this.records?.sort(({ created: createdA }, { created: createdB }) => {
        const a = new Date(createdA).getTime();
        const b = new Date(createdB).getTime();
        return b - a;
      }) || []
    );
  }
}
