import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Utils } from '@shared/utils/utils';

export interface ForecastState {
  activitiesByDriver: {
    [k in 'Time' | 'Site' | 'Patient']: { cost: number; costStr: string; percentage: number };
  };
  unforecastedCosts: number;
  hasCustomCurves: boolean;
  hasPatientCurves: boolean;
  hasSiteCurves: boolean;
}

export function createInitialState(): ForecastState {
  return {
    activitiesByDriver: {
      Time: { cost: 0, costStr: Utils.zeroHyphen, percentage: 0 },
      Patient: { cost: 0, costStr: Utils.zeroHyphen, percentage: 0 },
      Site: { cost: 0, costStr: Utils.zeroHyphen, percentage: 0 },
    },
    unforecastedCosts: 0,
    hasCustomCurves: false,
    hasPatientCurves: false,
    hasSiteCurves: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'forecast' })
export class ForecastStore extends Store<ForecastState> {
  constructor() {
    super(createInitialState());
  }
}
