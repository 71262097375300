import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from '@shared/components/input/input.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { CustomValidators } from '@shared/components/base-form-control/custom-validators';
import { CustomDriverType, DistributionType } from '../../constants/custom-curves.constants';
import { CustomCurve, CustomCurvesStore } from '../../state/custom-curves.state';
import { CurveType, CustomType, DriverType, GqlService } from '@shared/services/gql.service';
import { firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import { CustomCurvesQuery } from '../../state/custom-curves.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface CreateEditCustomDriverInputData {
  existingNames: string[];
  name?: string;
  type?: CustomType;
  id?: string;
}

@Component({
  selector: 'aux-custom-driver-create-edit-modal',
  templateUrl: './custom-driver-create-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputComponent, NgSelectModule, ReactiveFormsModule, ButtonComponent],
})
export class CustomDriverCreateEditModalComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  private readonly customCurvesStore = inject(CustomCurvesStore);

  private readonly customCurvesQuery = inject(CustomCurvesQuery);

  nameControl = new FormControl<string>('');

  selectedType = new FormControl<string | null>(null, Validators.required);

  nameValidators = [];

  distributionTypes = Object.values(DistributionType);

  saving = signal(false);

  fetchingDistributions = signal(true);

  allowChangeType = signal(false);

  initialName = signal('');

  ref = inject(CustomOverlayRef<CustomCurve, CreateEditCustomDriverInputData>);

  private readonly gqlService = inject(GqlService);

  private readonly overlayService = inject(OverlayService);

  private readonly disableTypeEdit = effect(
    () => {
      if (!!this.initialName() && !this.allowChangeType()) {
        this.selectedType.disable();
      } else {
        this.selectedType.enable();
      }
    },
    { allowSignalWrites: true }
  );

  ngOnInit(): void {
    if (this.ref.data?.id) {
      this.gqlService
        .listDriverCustomDistribution$(this.ref.data.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((distributions) => {
          const existingDistributions = distributions.data || [];
          this.allowChangeType.set(
            existingDistributions.every((distribution) => distribution.custom_amount == 0) ||
              !existingDistributions.length
          );
          this.fetchingDistributions.set(false);
        });
    }

    if (this.ref.data?.name) {
      this.initialName.set(this.ref.data.name);
    }

    if (this.ref.data?.type) {
      const getDistributionLabel = (object: { [key: string]: string }, value: string) => {
        return Object.keys(object).find((key) => object[key] === value);
      };

      this.selectedType.setValue(
        getDistributionLabel(CustomDriverType, this.ref.data.type) as string
      );
    }

    this.nameControl.setValue(this.initialName());
  }

  async onSave(): Promise<void> {
    if (this.nameControl.invalid || this.selectedType.invalid) {
      return;
    }

    if (this.ref.data?.name) {
      await this.onUpdate();
      return;
    }

    await this.onCreate();
  }

  async onUpdate(): Promise<void> {
    this.saving.set(true);
    const resp = await firstValueFrom(
      this.gqlService.updateCustomGroup$({
        id: this.ref.data?.id as string,
        name: this.nameControl.value as string,
        type: CustomDriverType[this.selectedType.value as string],
        rank_order: 0,
      })
    );

    if (resp.errors?.length) {
      this.overlayService.error(resp.errors);
      this.saving.set(false);
      return;
    }

    const elementToEdit = this.customCurvesQuery
      .curves()
      .find((curve) => curve.custom_group_id === this.ref.data?.id);

    this.customCurvesStore.updatePartialy({
      curveToCreateOrEdit: {
        ...elementToEdit,
        custom_type: resp.data?.type,
        name: resp.data?.name,
      },
    });
    this.overlayService.success(`${resp.data?.name} successfully updated!`);
    this.ref.close();
  }

  async onCreate(): Promise<void> {
    this.saving.set(true);
    const resp = await firstValueFrom(
      this.gqlService.createCustomGroup$({
        name: this.nameControl.value as string,
        type: CustomDriverType[this.selectedType.value as string],
        rank_order: 0,
      })
    );

    if (resp.errors?.length) {
      this.overlayService.error(resp.errors);
      this.saving.set(false);
      return;
    }

    if (!resp.data || !resp.success) {
      return;
    }

    const settingsResp = await firstValueFrom(
      this.gqlService.createDriverSetting$({
        custom_group_id: resp.data.id,
        driver_type: DriverType.DRIVER_CUSTOM,
      })
    );

    if (settingsResp.errors?.length) {
      this.overlayService.error(settingsResp.errors);
      this.saving.set(false);
      return;
    }

    const curveToCreate = {
      curve_type: CurveType.NET,
      custom_group_id: resp.data.id,
      custom_type: resp.data.type,
      driver_setting_id: settingsResp.data?.id,
      name: resp.data.name,
    };

    this.customCurvesStore.updatePartialy({ curveToCreateOrEdit: curveToCreate });
    this.overlayService.success(MessagesConstants.CUSTOM_CURVES.SUCCESSFULLY_CREATED);
    this.ref.close(curveToCreate);
  }

  get nameControlValidators(): ValidatorFn[] {
    return [
      Validators.required,
      CustomValidators.valueNotInList(this.existingNames, 'duplicatedCustomCurveName'),
    ];
  }

  get name(): string | undefined {
    return this.ref?.data?.name;
  }

  get saveDisabled(): boolean {
    return this.nameControl.invalid || this.selectedType.invalid || this.saving();
  }

  private get existingNames(): string[] {
    const names = this.ref?.data?.existingNames || [];
    const driverName = this.ref?.data?.name;
    return driverName ? names.filter((name) => name !== driverName) : names;
  }
}
