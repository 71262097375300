import { Utils } from '@shared/utils/utils';
import { TrialInsightsPatientCostKey } from '../../trial-insights/models/trial-insights-table.model';

export const rowData = [
  {
    buttonKey: TrialInsightsPatientCostKey.SITE,
    leftHeader: 'Site Name',
    leftSubheader: 'Primary Investigator',
    rightHeader: Utils.currencyFormatter(1250),
    rightSubheader: '10 Patients',
  },
];
