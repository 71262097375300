import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { getEventTrackerQuery } from '@shared/services/gql.service';

export type EventStoreState = EntityState<getEventTrackerQuery & { _id: string }>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'events', idKey: '_id' })
export class EventStore extends EntityStore<EventStoreState> {
  constructor() {
    super({});
  }
}
