<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async) && (showNoData$ | async)">
  <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
    <aux-icon name="FileDescription" />
    <div class="text-xl font-bold">No Investigator Data to Review</div>
  </div>
</ng-container>

<ng-container *ngIf="!$any(loading$ | async) && (showNoData$ | async) === false">
  <div>
    <span class="font-bold text-base"> Sites Level Actuals </span>

    <span class="ml-4 text-aux-gray-lightest text-base">
      Last Source Data Refresh: {{ lastSourceDataRefreshDate | date: 'MMMM d, y, h:mm a' }}
    </span>
  </div>

  <div class="my-4 text-sm flex justify-between items-center space-x-8">
    <div class="flex space-x-8">
      <div class="flex items-center">
        <div class="mr-2">Month:</div>
        <ng-select
          class="w-72 tabular-nums"
          bindValue="value"
          bindLabel="label"
          [clearable]="false"
          [searchable]="false"
          [items]="monthList"
          [(ngModel)]="selectedMonthFC"
        />
      </div>

      <div class="flex space-x-2 items-center">
        <div>Count</div>
        <aux-toggle [value]="isDisplayCosts" (change)="display$.next($event ? 'costs' : 'count')" />
        <div>Costs</div>
      </div>

      <div class="flex items-center">
        <aux-toggle-currency
          [value]="isContractedCurrency"
          (nameSelectedCurrency)="selectedVisibleCurrency$.next($event)"
        />
      </div>
    </div>

    <div class="flex items-center">
      <div class="mr-2">Version:</div>
      <ng-select
        class="w-72 tabular-nums mr-4"
        placeholder="Versions"
        [clearable]="false"
        [(ngModel)]="selectedVersion"
        (change)="onVersionChange()"
      >
        <ng-option *ngFor="let version of versions$ | async" [value]="version.id">
          {{ version.create_date | date: 'MM.dd.YYYY HH:mm' }}
        </ng-option>
      </ng-select>

      <aux-export-excel-button
        [gridAPI]="gridAPI"
        [excelOptions]="gridOptions"
        [ignoreColsId]="['site_id']"
        [getDynamicExcelParamsCallback]="getDynamicExcelParams"
        pendoTag="investigator-detail-excel-export-button"
      />
    </div>
  </div>

  <ag-grid-angular
    id="investigatorDetailsGrid"
    class="ag-theme-aux mb-4 tabular-nums"
    [rowData]="gridData$ | async"
    [gridOptions]="$any(gridOptions$ | async)"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="sizeColumnsToFit()"
    (gridSizeChanged)="sizeColumnsToFit()"
  />

  <a class="aux-link mb-16 text-xs" [routerLink]="[patientTrackerLink]">
    View Patient Level Reference Table
  </a>
</ng-container>
