import { Component, computed, input } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { PatientCurveModel } from '@pages/forecast-accruals-page/tabs/forecast/state/patient-curve/patient-curve.store';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormErrorDirective } from '@shared/directives/form-error.directive';
import { listDriverSiteGroupsQuery } from '@shared/services/gql.service';

@Component({
  selector: 'aux-patient-curves-dropdown',
  templateUrl: './patient-curves-dropdown.component.html',
  styleUrl: './patient-curves-dropdown.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, FormErrorDirective],
})
export class PatientCurvesDropdownComponent {
  controlName = input.required<string>();

  curveControl = input.required<AbstractControl>();

  curves = input.required<(PatientCurveModel | listDriverSiteGroupsQuery)[]>();

  name = input.required<string>();

  validators = input<string>();

  patientOptions = computed(() => {
    let flag = false;

    return this.curves().map((curve) => {
      if (!curve.is_blended && !flag) {
        flag = true;
        return {
          name: curve.name,
          value: curve.name,
          showLine: true,
        };
      }

      return {
        name: curve.name,
        value: curve.name,
        showLine: false,
      };
    });
  });

  onBlur() {
    this.curveControl().updateValueAndValidity();
  }
}
