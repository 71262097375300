import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { toSignal } from '@angular/core/rxjs-interop';
import { TrialUserStore, TrialUserState } from './trial-user.store';
import { Utils } from '@shared/utils/utils';

export const sortByUserName = (user1: TrialUserState, user2: TrialUserState) => {
  const fullName1 = `${user1.given_name} ${user1.family_name}`.toLowerCase();
  const fullName2 = `${user2.given_name} ${user2.family_name}`.toLowerCase();
  return Utils.alphaNumSort(fullName1, fullName2);
};

@Injectable({ providedIn: 'root' })
export class TrialUserQuery extends QueryEntity<TrialUserState> {
  users = toSignal(
    this.selectAll({
      filterBy: (user) => (user.email ? !user.email.includes('@auxili.us') : true),
      sortBy: sortByUserName,
    }),
    { requireSync: true }
  );

  constructor(protected store: TrialUserStore) {
    super(store);
  }
}
