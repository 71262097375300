import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { Utils } from '@shared/utils/utils';
import { MapActivitiesModalTotalRowComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal-total-row/map-activities-modal-total-row.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { ButtonComponent } from '@shared/components/button/button.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  FillOperationParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import {
  ActionType,
  BudgetType,
  GqlService,
  listBudgetActivitiesQuery,
  ModifyInvoiceActivityMapping,
} from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { InputComponent } from '@shared/components/input/input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { getMapActivitiesGridColumns } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.constants';
import { NewValueParams } from '@ag-grid-community/core/dist/esm/es6/entities/colDef';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MapActivitiesGridRow,
  MapActivitiesModalParams,
  MapActivitiesModalReturnData,
  MapActivitiesTotals,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { MapActivitiesModalService } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { firstValueFrom } from 'rxjs';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { v4 as uuidv4 } from 'uuid';
import { AgTooltipComponent } from '@features/inline-budget/ag-tooltip.component';
import {
  WarningModalComponent,
  WarningModalParams,
} from '@features/inline-budget/warning-modal.component';

@Component({
  standalone: true,
  selector: 'aux-map-activities-modal',
  templateUrl: 'map-activities-modal.component.html',
  styleUrl: 'map-activities-modal.component.scss',
  imports: [
    MapActivitiesModalTotalRowComponent,
    ButtonComponent,
    AgGridAngular,
    InputComponent,
    ReactiveFormsModule,
    TooltipDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapActivitiesModalComponent {
  private overlayService = inject(OverlayService);
  private gqlService = inject(GqlService);
  ref = inject(CustomOverlayRef<MapActivitiesModalReturnData, MapActivitiesModalParams>);
  private mapActivitiesModalService = inject(MapActivitiesModalService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private launchDarklyService = inject(LaunchDarklyService);
  protected readonly utils = Utils;
  readonly defaultRowCount = 5;
  loading = true;
  removedRows: MapActivitiesGridRow[] = [];
  invalidRows: Record<string, MapActivitiesGridRow> = {};
  validRows: Record<string, MapActivitiesGridRow> = {};
  initialNoteMessage = this.ref.data?.noteMessage || '';

  noteControl = new FormControl('');
  invoice = this.ref.data?.invoice || ({} as InvoiceModel);
  gridAPI!: GridApi;
  gridData: MapActivitiesGridRow[] = [];
  budgetActivityOptions: listBudgetActivitiesQuery[] = [];

  isMapActivitiesEnabled = this.launchDarklyService.$select((f) => f.map_invoice_to_activities_tab);
  totalsEqual = computed(() => {
    return this.invoice.expense_amounts.invoice_total.value === this.totals().invoice_total;
  });
  allocatedAmountsMatch = computed(() => {
    return (
      (this.invoice.expense_amounts.discount_total.value || 0) ===
        this.totals().ACTIVITY_DISCOUNT &&
      (this.invoice.expense_amounts.investigator_total.value || 0) ===
        this.totals().ACTIVITY_INVESTIGATOR &&
      (this.invoice.expense_amounts.pass_thru_total.value || 0) ===
        this.totals().ACTIVITY_PASSTHROUGH &&
      (this.invoice.expense_amounts.services_total.value || 0) === this.totals().ACTIVITY_SERVICE
    );
  });

  totals = signal<MapActivitiesTotals>({
    invoice_total: 0,
    ACTIVITY_SERVICE: 0,
    ACTIVITY_DISCOUNT: 0,
    ACTIVITY_PASSTHROUGH: 0,
    ACTIVITY_INVESTIGATOR: 0,
  });

  validateCell = (isInvalidValue: boolean, data: MapActivitiesGridRow, node: IRowNode): void => {
    if (data.isNewRow && !data.activity_type && !data.amount && !data.invoice_item_description) {
      delete this.validRows[data.id];
      delete this.invalidRows[data.id];
    } else {
      if (isInvalidValue) {
        this.invalidRows = {
          ...this.invalidRows,
          [data.id]: data,
        };
        delete this.validRows[data.id];
      } else {
        delete this.invalidRows[data.id];
        this.validRows = {
          ...this.validRows,
          [data.id]: data,
        };
      }
    }

    this.setBottomData();
    this.updateTotals();

    if (node) {
      this.gridAPI.refreshCells({
        rowNodes: [node],
        force: true,
      });
    }
  };

  onCellValueChanged = (params: NewValueParams | FillOperationParams): void => {
    if (Object.prototype.hasOwnProperty.call(params, 'data')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.validateCell(!params.data.amount || !params.data.activity_id, params.data, params.node);
    }

    if (Object.prototype.hasOwnProperty.call(params, 'rowNode')) {
      this.validateCell(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !params.rowNode.data.amount || !params.rowNode.data.activity_id,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params.rowNode.data,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params.rowNode
      );
    }
  };

  gridOptions = <GridOptions>{
    ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
    tooltipShowDelay: 0,
    defaultColDef: {
      tooltipComponent: AgTooltipComponent,
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      editable: (params) => !params.node.rowPinned,
    },
    enableFillHandle: true,
    fillOperation: (params) => {
      let index = params.rowNode.rowIndex;
      if (
        (params.column.getColId() === 'invoice_item_description' ||
          params.column.getColId() === 'activity_id') &&
        index != null
      ) {
        switch (params.direction) {
          case 'up':
            index += 1;
            break;
          case 'down':
            index -= 1;
            break;
          case 'left':
          case 'right':
            return false;
        }
        const firstFocusedRowIndex = params.api.getFocusedCell()?.rowIndex;
        if (index < params.api.getDisplayedRowCount() - 1 && firstFocusedRowIndex !== undefined) {
          const starterNode = params.api.getDisplayedRowAtIndex(firstFocusedRowIndex);

          let data = {};

          if (params.column.getColId() === 'invoice_item_description') {
            data = {
              ...params.rowNode.data,
              invoice_item_description: starterNode
                ? starterNode.data.invoice_item_description
                : params.rowNode.data.invoice_item_description,
            };
          }

          if (params.column.getColId() === 'activity_id') {
            const newValue = this.budgetActivityOptions.find(
              ({ id }) =>
                id ===
                (starterNode ? starterNode.data.activity_id : params.rowNode.data.activity_id)
            );

            data = {
              ...params.rowNode.data,
              activity_name: newValue ? newValue.name : null,
              activity_type: newValue ? newValue.activity_type : null,
              activity_id: newValue ? newValue.id : null,
            };
          }
          params.rowNode.updateData(data);
          this.onCellValueChanged(params);
          this.gridAPI.refreshCells({ rowNodes: [params.rowNode] });
        }
      }
      return false;
    },
  };
  columnDefs: (ColGroupDef | ColDef)[] = [];

  constructor() {
    this.ref.canDeactivate = this.canDeactivate;

    effect(() => {
      const isMapActivitiesEnabled = this.isMapActivitiesEnabled();

      if (!isMapActivitiesEnabled) {
        this.ref.close();
      }
    });

    this.gqlService
      .listBudgetActivities$({
        vendor_id: this.invoice.organization.id,
        budget_type: BudgetType.BUDGET_PRIMARY,
      })
      .pipe(takeUntilDestroyed())
      .subscribe((listBudgetActivitiesResponse) => {
        if (this.ref.data && this.ref.data?.items.length > 0) {
          this.gridData = this.ref.data?.items.map((item) => {
            if (
              item.invoice_item_description &&
              !this.mapActivitiesModalService
                .invoiceItemOptions()
                .some((option) => option.value === item.invoice_item_description)
            ) {
              this.mapActivitiesModalService.addInvoiceItemOptions({
                id: uuidv4(),
                value: item.invoice_item_description,
              });
            }

            return {
              ...item,
              isNewRow: false,
            };
          });
        } else {
          for (let i = 0; i < this.defaultRowCount; i++) {
            this.gridData.push({
              id: Utils.uuid(),
              invoice_item_description: '',
              activity_id: '',
              activity_name: '',
              activity_type: null,
              amount: 0,
              isNewRow: true,
              display_order: -1,
            });
          }
        }

        if (listBudgetActivitiesResponse.success) {
          this.budgetActivityOptions = listBudgetActivitiesResponse.data || [];
        }

        this.columnDefs = getMapActivitiesGridColumns(
          this.overlayService,
          this.invoice.organization.currency,
          this.validateCell,
          this.onCellValueChanged,
          this.budgetActivityOptions,
          this.removeRow,
          this.isInvalidRow,
          this.updateTotals
        );

        this.noteControl.setValue(this.ref.data?.noteMessage || '');
        this.noteControl.markAsPristine();

        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
    this.mapActivitiesModalService.initInvoiceItemOptions(this.invoice);
  }

  addNewRow(): void {
    this.gridAPI.applyTransaction({
      add: [
        {
          id: Utils.uuid(),
          invoice_item_description: '',
          activity_id: '',
          activity_name: '',
          activity_type: '',
          amount: 0,
          isNewRow: true,
        },
      ],
    });
    this.gridAPI.sizeColumnsToFit();
    this.gridAPI.ensureIndexVisible(this.gridAPI.getRenderedNodes().length - 1, 'bottom');
  }

  removeRow = (rowNode: IRowNode): void => {
    if (!rowNode.data.isNewRow) {
      this.removedRows.push(rowNode.data);
    }

    delete this.invalidRows[rowNode.data.id];
    delete this.validRows[rowNode.data.id];

    this.gridAPI.applyTransaction({ remove: [rowNode.data] });
    this.setBottomData();
    this.updateTotals();
  };

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;
    api.sizeColumnsToFit();
    this.setBottomData();
    this.updateTotals();
  }

  async save(): Promise<void> {
    this.gridAPI.selectAll();

    if (this.gridAPI.getSelectedRows().length === 0 && this.removedRows.length > 0) {
      const itemsToRemove: ModifyInvoiceActivityMapping[] = [];

      this.removedRows.forEach((row) => {
        itemsToRemove.push({
          action_type: ActionType.ACTION_TYPE_REMOVE,
          activity_id: row.activity_id,
          amount: row.amount,
          id: row.id,
          invoice_id: this.invoice.id,
          invoice_item_description: row.invoice_item_description,
          vendor_id: this.invoice.organization.id,
        });
      });

      const isNotesChanged = !this.noteControl.pristine;

      this.reset();

      this.ref.close({
        isNotesChanged: isNotesChanged,
        notesMessage: this.noteControl.value || '',
        itemsToSave: itemsToRemove,
      });
      return;
    }

    if (!this.allocatedAmountsMatch()) {
      const ref = this.overlayService.open<'cancel' | 'confirm', WarningModalParams>({
        baseComponent: WarningModalComponent,
        data: {
          header: 'Invoice Allocation Does Not Match',
          message:
            'The Invoice allocation to cost categories do not match the cost category totals from the invoice. Are you sure you want to proceed with this activity mapping?',
          maxWidth: 455,
          cancelLabel: 'Go Back',
          confirmLabel: 'Yes, Proceed',
        },
      });

      const { data: refData } = await firstValueFrom(ref.afterClosed$);

      if (refData !== 'confirm') {
        return;
      }
    }

    const itemsToSave: ModifyInvoiceActivityMapping[] = [];

    if (this.removedRows.length > 0) {
      this.removedRows.forEach((row) => {
        itemsToSave.push({
          action_type: ActionType.ACTION_TYPE_REMOVE,
          activity_id: row.activity_id,
          amount: row.amount,
          id: row.id,
          invoice_id: this.invoice.id,
          invoice_item_description: row.invoice_item_description,
          vendor_id: this.invoice.organization.id,
        });
      });
    }
    let maxDisplayOrder = 0;
    this.gridData.forEach(
      (gd) =>
        (maxDisplayOrder = gd.display_order > maxDisplayOrder ? gd.display_order : maxDisplayOrder)
    );
    let i = maxDisplayOrder;
    if (Object.keys(this.validRows).length) {
      Object.keys(this.validRows).forEach((key) => {
        itemsToSave.push({
          action_type: this.validRows[key].isNewRow
            ? ActionType.ACTION_TYPE_CREATE
            : ActionType.ACTION_TYPE_UPDATE,
          activity_id: this.validRows[key].activity_id,
          amount: this.validRows[key].amount,
          id: this.validRows[key].id,
          invoice_id: this.invoice.id,
          invoice_item_description: this.validRows[key].invoice_item_description,
          vendor_id: this.invoice.organization.id,
          display_order: this.validRows[key].isNewRow ? i : this.validRows[key].display_order,
        });
        i++;
      });
    }
    const isNotesChanged = !this.noteControl.pristine;

    this.reset();

    this.ref.close({
      isNotesChanged: isNotesChanged,
      notesMessage: this.noteControl.value || '',
      itemsToSave: itemsToSave,
    });
  }

  isInvalidRow = (params: CellClassParams): boolean => {
    return !!this.invalidRows[params.data.id];
  };

  isSaveButtonDisabled(): boolean {
    return this.hasChanges()
      ? !!Object.keys(this.invalidRows).length ||
          (!this.isAllActivitiesDeleted() && !this.totalsEqual())
      : true;
  }

  saveButtonTooltip(): string {
    if (this.hasChanges()) {
      if (Object.keys(this.invalidRows).length) {
        return MessagesConstants.RESOLVE_ERRORS_TO_CONTINUE;
      } else if (!this.isAllActivitiesDeleted() && !this.totalsEqual()) {
        return MessagesConstants.MAP_ACTIVITIES.AMOUNT_INVOICE_TOTALS_NOT_MATCH;
      } else return '';
    }

    return 'No changes to save.';
  }

  private isAllActivitiesDeleted(): boolean {
    if (
      this.removedRows.length > 0 &&
      !Object.keys(this.invalidRows).length &&
      !Object.keys(this.validRows).length
    ) {
      const oldRows = this.gridAPI.getRenderedNodes().filter(({ data }) => !data.isNewRow) || [];
      return oldRows.length === 0;
    }

    return false;
  }

  private canDeactivate = async () => {
    if (this.hasChanges()) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  };

  private hasChanges(): boolean {
    return (
      this.noteControl.value !== this.initialNoteMessage ||
      this.removedRows.length > 0 ||
      !!Object.keys(this.invalidRows).length ||
      !!Object.keys(this.validRows).length
    );
  }

  private reset(): void {
    this.initialNoteMessage = this.noteControl.value || '';
    this.removedRows = [];
    this.invalidRows = {};
    this.validRows = {};
  }

  updateTotals = () => {
    const calculatedTotals: MapActivitiesTotals = {
      invoice_total: this.totals().invoice_total,
      ACTIVITY_SERVICE: 0,
      ACTIVITY_DISCOUNT: 0,
      ACTIVITY_PASSTHROUGH: 0,
      ACTIVITY_INVESTIGATOR: 0,
    };
    this.gridAPI.selectAll();
    this.gridAPI.getSelectedRows().forEach((row) => {
      if (row.activity_type) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        calculatedTotals[row.activity_type] =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          calculatedTotals[row.activity_type] + row.amount;
      }
    });
    this.totals.set(calculatedTotals);
  };

  private setBottomData(): void {
    const totals = this.calculatePinnedBottomData();

    this.totals.set({ ...this.totals(), invoice_total: totals.amount });

    this.gridAPI.setGridOption('pinnedBottomRowData', [
      {
        invoice_item_description: 'Total',
        ...totals,
      },
    ]);
  }

  private calculatePinnedBottomData(): {
    amount: number;
  } {
    const columnsWithAggregation = ['amount'] as const;

    this.gridAPI.selectAll();
    return this.gridAPI.getSelectedRows().reduce(
      (acc, row) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          const additionalVal = row[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = currentVal + additionalVal;
          }
        }

        return acc;
      },
      {
        amount: 0,
      }
    );
  }
}
