<div class="font-inter">
  <div class="w-screen max-w-xl"></div>
  <div class="text-xs mb-2">Select phase:</div>

  <div class="flex">
    <div *ngFor="let phase of phases" class="flex items-center flex-1">
      <input
        name="phases"
        type="radio"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        [id]="phase.cat_id"
        [value]="phase"
        [(ngModel)]="selectedPhase"
      />
      <label class="ml-2" [for]="phase.cat_id">
        <span class="block text-sm font-medium text-gray-700">{{ phase.name }}</span>
      </label>
    </div>
  </div>

  <div *ngIf="selectedPhase && selectedPhase.name !== 'Custom'" class="flex items-center mt-8">
    <div class="bg-aux-gray-light border p-4 rounded-md w-32 font-medium text-sm">
      {{ selectedPhase.firstMilestone?.name }}
      <span class="block text-xs">({{ selectedPhase.firstMilestone?.start_date | date }})</span>
    </div>
    <aux-icon name="ArrowRight" class="mx-4" />
    <div class="bg-aux-gray-light border p-4 rounded-md w-32 font-medium text-sm">
      {{ selectedPhase.lastMilestone?.name }}
      <span class="block text-xs">({{ selectedPhase.lastMilestone?.end_date | date }})</span>
    </div>
  </div>

  <div
    *ngIf="!selectedPhase || selectedPhase.name === 'Custom'"
    class="grid grid-cols-2 mt-6 gap-5"
  >
    <div class="space-y-2 h-96 overflow-auto p-2">
      <div class="text-xs">Select starting milestone/date:</div>

      <div class="flex items-center flex-1" (click)="selectDateInputRadio(true)">
        <input
          name="start-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [value]="customMilestoneDatepickerType.START_DATE"
          [(ngModel)]="selectedCustomStartMilestone"
        />

        <div class="ml-2">
          <aux-input
            class="w-40"
            [type]="'date'"
            [placeholder]="formControlConstants.PLACEHOLDER.DATE"
            [formControl]="customStartDateControl"
            [disabled]="isCustomStartDateControlDisabled()"
            [dateCompareValidation]="trialTimelinePeriod"
            dateCompareError="trialTimelinePeriod"
          />
        </div>
      </div>

      <div *ngFor="let milestone of allMilestones" class="flex items-center flex-1">
        <input
          name="start-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [id]="'start-' + milestone.id"
          [value]="milestone"
          [(ngModel)]="selectedCustomStartMilestone"
        />

        <label class="ml-2" [for]="'start-' + milestone.id">
          <span class="block text-sm font-medium text-gray-700">{{ milestone.name }}</span>

          <span class="block text-xs font-medium text-gray-700">
            ({{ milestone.start_date | date }})
          </span>
        </label>
      </div>
    </div>

    <div class="space-y-2 h-96 overflow-auto p-2">
      <div class="text-xs">Select ending milestone/date:</div>

      <div class="flex items-center flex-1" (click)="selectDateInputRadio(false)">
        <input
          name="end-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [value]="customMilestoneDatepickerType.END_DATE"
          [(ngModel)]="selectedCustomEndMilestone"
        />
        <div class="ml-2">
          <aux-input
            class="w-40"
            [type]="'date'"
            [placeholder]="formControlConstants.PLACEHOLDER.DATE"
            [formControl]="customEndDateControl"
            [disabled]="isCustomEndDateControlDisabled()"
            [dateCompareValidation]="trialTimelinePeriod"
            dateCompareError="trialTimelinePeriod"
          />
        </div>
      </div>

      <div *ngFor="let milestone of allMilestones" class="flex items-center flex-1">
        <input
          name="end-milestone-date"
          type="radio"
          class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
          [id]="'end-' + milestone.id"
          [value]="milestone"
          [(ngModel)]="selectedCustomEndMilestone"
        />

        <label class="ml-2" [for]="'end-' + milestone.id">
          <span class="block text-sm font-medium text-gray-700">{{ milestone.name }}</span>

          <span class="block text-xs font-medium text-gray-700">
            ({{ milestone.end_date | date }})
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
