<div class="flex mt-4 mb-2">
  <div class="shrink-0 w-[3px] bg-[#084673]"></div>
  <div class="content flex-col grow">
    <div class="ml-3 flex justify-between items-start">
      <span class="header italic"> {{ author }} - {{ record.created | date }}</span>
      <aux-icon
        name="Trash"
        class="remove text-aux-error"
        (click)="!removeTooltip && !pending && onRemove()"
        [class.cursor-pointer]="!removeTooltip"
        [class.cursor-not-allowed]="!!removeTooltip"
        [class.opacity-70]="!!removeTooltip"
        [auxTooltip]="removeTooltip"
      />
    </div>
    <div class="ml-3 max-w-[80%] whitespace-pre-wrap">{{ record.text }}</div>
  </div>
</div>
