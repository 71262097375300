import { computed, Directive, inject } from '@angular/core';
import { EventType, WorkflowStep } from '@shared/services/gql.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventQuery } from '@models/event/event.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MessagesConstants } from '@shared/constants/messages.constants';

@Directive({
  standalone: true,
})
export class CurveWorkflowDirective {
  workflowQuery = inject(WorkflowQuery);
  eventQuery = inject(EventQuery);
  launchDarklyService = inject(LaunchDarklyService);

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES
  );

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isClosingPanelEnabled = this.launchDarklyService.$select(
    (flags) => flags.closing_checklist_toolbar
  );

  isQuarterCloseEnabled = this.workflowQuery.isWorkflowAvailable;

  isLocked = computed(() => {
    if (this.isClosingPanelEnabled() && this.isQuarterCloseEnabled()) {
      return this.iCloseMonthsProcessing() || this.isPatientsFinalized();
    }
    return false;
  });

  tooltip = computed(() => {
    if (this.isLocked()) {
      return MessagesConstants.SITE_PATIENT_CUSTOM_CURVES_LOCKED_TEXT;
    }
    return '';
  });
}
