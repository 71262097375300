<div
  class="bg-aux-brown-beige flex justify-between items-center py-4 px-8 font-bold"
  [ngStyle]="{
    'background-color': timelineBannerStatus === 'warning' ? '#FFCA80' : ''
  }"
  [ngClass]="{ 'bg-aux-error text-white': timelineBannerStatus === 'error' }"
>
  <div style="width: 0px"></div>
  <div class="flex items-center">
    <aux-icon name="AlertTriangle" />
    <span class="ml-2"
      >{{ fp }}
      <span
        class="cursor-pointer"
        [ngClass]="{
          'aux-link': timelineBannerStatus === 'warning',
          'underline text-white': timelineBannerStatus === 'error'
        }"
        (click)="navigateToTimeline()"
        data-pendo-id="pendo-timeline-banner-link"
        >extend timeline</span
      >
      {{ sp }}</span
    >
  </div>
  <span
    class="cursor-pointer flex items-center"
    data-pendo-id="pendo-timeline-banner-close"
    (click)="hideBanner()"
  >
    <aux-icon name="X"></aux-icon
  ></span>
</div>
