import { Currency } from '@shared/services/gql.service';
import {
  GridOptions,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import { MapActivityType } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { Utils } from '@shared/utils/utils';
import { AgTooltipComponent } from '@features/inline-budget/ag-tooltip.component';

export const getMapActivitiesTabGridOptions = (vendorCurrency: Currency): GridOptions => {
  return <GridOptions>{
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    tooltipShowDelay: 0,
    stopEditingWhenCellsLoseFocus: false,
    defaultColDef: {
      tooltipComponent: AgTooltipComponent,
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      editable: () => false,
      resizable: true,
    },
    columnDefs: [
      {
        headerName: 'Invoice Item',
        field: 'invoice_item_description',
        tooltipField: 'invoice_item_description',
        minWidth: 240,
        cellClass: ['!block', 'truncate', 'text-left'],
        comparator: (a, b) => {
          return Utils.localeAlphaNumSort(a || '', b || '');
        },
      },
      {
        headerName: 'Budget Activity',
        field: 'activity_id',
        tooltipField: 'activity_name',
        valueGetter: (params: ValueGetterParams) => {
          return params.data?.activity_name || '';
        },
        minWidth: 240,
        cellClass: ['!block', 'truncate', 'text-left'],
      },
      {
        headerName: 'Cost Category',
        field: 'activity_type',
        tooltipValueGetter: (params: ITooltipParams) =>
          params.data?.activity_type
            ? MapActivityType[params.data?.activity_type as keyof typeof MapActivityType]
            : '',
        valueFormatter: (params: ValueFormatterParams) => {
          return params.data?.activity_type
            ? MapActivityType[params.data?.activity_type as keyof typeof MapActivityType]
            : '';
        },
        minWidth: 240,
        cellClass: ['!block', 'truncate', 'text-left'],
      },
      {
        headerName: 'Total',
        field: 'amount',
        valueFormatter: (params) => {
          return Utils.agCurrencyFormatter(params, vendorCurrency);
        },
        tooltipValueGetter: (params: ITooltipParams) =>
          params.data?.amount ? Utils.agCurrencyFormatter(params.data?.amount, vendorCurrency) : '',
        minWidth: 240,
        cellClass: ['!block', 'ag-cell-align-right', 'truncate'],
      },
    ],
  };
};
