import { FilterEntity, TableFilter, TableService } from '@shared/services/table.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  DestroyRef,
  inject,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowNode,
  RowSelectedEvent,
  GridApi,
  ValueFormatterParams,
  IRowNode,
  SortModelItem,
  CellClassParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { Document, SortField } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { AgActionsComponent } from '@shared/ag-components/ag-actions/ag-actions.component';
import { ApiService, AwsFile } from '@shared/services/api.service';
import { DatePipe } from '@angular/common';
import { OverlayService } from '@shared/services/overlay.service';
import { TableConstants } from '@shared/constants/table.constants';
import { DocumentLibraryFile, DocumentLibraryService } from '../document-library.service';
import { AgLoadingCellComponent } from '@shared/ag-components/ag-loading-cell/ag-loading-cell.component';
import { isEqual, isNumber, pick } from 'lodash-es';
import { AuxExcelStyleKeys, GetExcelStyle } from '@shared/utils';
import { AgDropdownComponent, AgInputComponent } from '@shared/ag-components/ag-controls';
import { DocumentLibraryConstants } from '../document-library.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '@shared/services/gql.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SitesQuery } from '@models/sites/sites.query';

@Component({
  selector: 'aux-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLibraryComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  gridAPI!: GridApi;

  @Input() formGroup!: UntypedFormGroup;

  gridData$ = new BehaviorSubject<Document[]>([]);

  @Input() inputChangeHandler!: () => void;

  @Input() rowSelectedHandler!: (event?: RowSelectedEvent<Document>) => void;

  @Input() isExternalFilterPresent: (() => boolean) | undefined;

  @Input() removedRows$!: BehaviorSubject<string[]>;

  @Input() doesExternalFilterPass: ((node: IRowNode<Document>) => boolean) | undefined;

  @Output() deleteRowEvent = new EventEmitter<string>();

  @Output() filterChange = new EventEmitter<
    Pick<DocumentLibraryFile, 'description' | 'document_type_id' | 'vendor_id' | 'site_id' | 'id'>[]
  >();

  @Output() rollbackDeleteRowEvent = new EventEmitter<RowNode>();

  @Output() rowDataChangedEvent = new EventEmitter();

  private controlCellClassNames = 'flex flex-col justify-center !items-stretch';

  gridOptions$ = new BehaviorSubject<GridOptions>({});

  overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;

  totalItems$ = new BehaviorSubject(10);

  prevFilters: Record<string, TableFilter> = {};

  loadingCellRenderer = AgLoadingCellComponent;

  disabledDeleteButton$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  enabledDeleteButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private nonDeletableDocuments = [
    DocumentType.DOCUMENT_VENDOR_BUDGET,
    DocumentType.DOCUMENT_SITE_BUDGET,
    DocumentType.DOCUMENT_VENDOR_ESTIMATE,
    DocumentType.DOCUMENT_MANUAL_TRANSACTION_SUPPORT,
  ];

  constructor(
    private authQuery: AuthQuery,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private documentLibraryService: DocumentLibraryService,
    private sitesQuery: SitesQuery
  ) {}

  ngOnInit() {
    this.gridOptions$.next({
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      rowHeight: 45,
      pagination: true,
      paginationPageSize: 10,
      suppressPaginationPanel: true,
      rowModelType: 'serverSide',
      isRowSelectable: (node) => !!node?.data?.is_metadata_editable,
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      onModelUpdated: (event: ModelUpdatedEvent) => {
        if (event.api.getDisplayedRowCount() === 0) {
          event.api.showNoRowsOverlay();
        } else {
          event.api.hideOverlay();
        }

        if (this.gridData$.getValue().length) {
          this.overlayNoRowsTemplate = TableConstants.NO_ROWS_ON_FILTERING_MESSAGE;
        }
      },
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        resizable: true,
      },
      columnDefs: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          maxWidth: 35,
          width: 35,
          minWidth: 35,
          cellClass: this.getRowClassesDeleteIndicatorRow([]),
        },
        {
          headerName: '',
          field: 'actions',
          suppressMenu: true,
          cellClass: this.getRowClassesDeleteIndicatorRow(['cell-justify-center']),
          cellRendererSelector: (params) => ({ component: AgActionsComponent, params }),
          cellRendererParams: (params: ICellRendererParams) => {
            return {
              hideDownloadButton: false,
              hideEditButton: true,
              deletedRows: this.removedRows$,
              downloadClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
                const ref = this.overlayService.loading();
                await this.apiService.downloadFile({
                  key: rowNode.data.bucket_key.replace('public/', ''),
                  fileName: rowNode.data.name,
                } as AwsFile);

                ref.close();
              },
              deleteClickFN: async ({ rowNode }: { rowNode: RowNode }) => {
                if (!this.removedRows$.getValue().includes(rowNode.data.id)) {
                  this.deleteRowEvent.emit(rowNode.data.id);
                } else {
                  this.rollbackDeleteRowEvent.emit(rowNode);
                }

                this.gridAPI.redrawRows({ rowNodes: [rowNode] });
              },
              deleteButtonDisabled$:
                this.nonDeletableDocuments.includes(params?.data?.document_type_id) &&
                !params?.data?.is_metadata_editable
                  ? this.enabledDeleteButton$
                  : this.disabledDeleteButton$,
            };
          },
          maxWidth: 75,
        },
        {
          headerName: 'Document Short Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'description',
          tooltipField: 'description',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgInputComponent,
          cellRendererParams: {
            formGroup: this.formGroup,
            changeHandler: this.inputChangeHandler,
            placeholder: 'Description',
          },
        },
        {
          headerName: 'File Name',
          headerClass: 'ag-header-align-center font-bold',
          field: 'name',
          tooltipField: 'name',
          valueGetter: (params) => params.data?.name || '',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-left']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'value'),
        },
        {
          headerName: 'Document Type',
          headerClass: 'ag-header-align-center font-bold',
          field: 'document_type_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.getDocumentOptions(),
            changeHandler: this.inputChangeHandler,
            disableTooltip: DocumentLibraryConstants.DISABLED_INPUT_TOOLTIP,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.getDocumentOptions(),
              params?.data?.document_type_id
            ),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(
              this.documentLibraryService.getDocumentOptions(),
              params?.data?.document_type_id
            ),
        },
        {
          headerName: 'Vendor',
          headerClass: 'ag-header-align-center font-bold',
          field: 'vendor_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.documentLibraryService.vendors,
            changeHandler: this.inputChangeHandler,
            disableTooltip: DocumentLibraryConstants.DISABLED_INPUT_TOOLTIP,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params?.data?.vendor_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.vendors, params?.data?.vendor_id),
        },
        {
          headerName: 'Site',
          headerClass: 'ag-header-align-center font-bold',
          field: 'site_id',
          cellClass: this.getRowClassesDeleteIndicatorRow([this.controlCellClassNames]),
          cellRenderer: AgDropdownComponent,
          cellRendererParams: {
            options: this.sitesQuery.selectSiteOptions()(),
            changeHandler: this.inputChangeHandler,
            bindLabel: 'siteName',
            disableTooltip: DocumentLibraryConstants.DISABLED_INPUT_TOOLTIP,
          },
          tooltipValueGetter: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params?.data?.site_id),
          getQuickFilterText: (params) =>
            Utils.getOptionLabel(this.documentLibraryService.sites, params?.data?.site_id),
        },
        {
          headerName: 'Date Uploaded',
          headerClass: 'ag-header-align-center font-bold',
          field: 'create_date',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-right']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          maxWidth: 180,
          tooltipValueGetter: (params) =>
            new DatePipe('en-US').transform(params?.data?.create_date, 'MM/dd/YYYY, h:mm a'),
          valueFormatter: (val: ValueFormatterParams) => {
            return new DatePipe('en-US').transform(val.value, 'MM/dd/YYYY, h:mm a') || '';
          },
        },
        {
          headerName: 'Uploaded by',
          headerClass: 'ag-header-align-center font-bold',
          cellClass: this.getRowClassesDeleteIndicatorRow(['text-left']),
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          field: 'created_by',
          tooltipValueGetter: (params) =>
            Utils.agUserFormatter(params?.data?.updatedByName, this.authQuery.isAuxAdmin()),
          valueFormatter: (params) =>
            Utils.agUserFormatter(params?.data?.updatedByName, this.authQuery.isAuxAdmin()),
        },
      ],
      excelStyles: [
        GetExcelStyle(AuxExcelStyleKeys.HEADER),
        GetExcelStyle(AuxExcelStyleKeys.FIRST_ROW),
        {
          id: 'cell',
          alignment: { horizontal: 'Left' },
          font: { fontName: 'Arial', size: 11 },
        },
      ],
    });

    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (params['documentType']) {
        const documentTypesControl = this.formGroup.get('documentTypes');
        if (documentTypesControl) {
          documentTypesControl.setValue([params['documentType']]);
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      }
      if (params['site']) {
        const sitesControl = this.formGroup.get('sites');
        if (sitesControl) {
          sitesControl.setValue([params['site']]);
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      }
      if (params['dateFrom']) {
        const dateFromControl = this.formGroup.get('dateFrom');
        if (dateFromControl) {
          dateFromControl.setValue(params['dateFrom']);
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      }
      if (params['dateTo']) {
        const dateToControl = this.formGroup.get('dateTo');
        if (dateToControl) {
          dateToControl.setValue(params['dateTo']);
          this.router.navigate([], { queryParams: {}, replaceUrl: true });
        }
      }
    });
  }

  getGridContext() {
    return {
      formGroup: this.formGroup,
    };
  }

  refreshFormControls(data: DocumentLibraryFile[], resetFormBeforeReinitialize: boolean) {
    if (this.gridAPI) {
      this.createFormControls(data, resetFormBeforeReinitialize);
      this.gridAPI.refreshCells({ force: true });
    }
  }

  private getRowClassesDeleteIndicatorRow =
    (classes: string[]) =>
    (cellClassParams: CellClassParams): string[] => {
      const markedToDeleteRow = this.removedRows$.getValue().includes(cellClassParams?.data?.id);

      return markedToDeleteRow ? [...classes, 'removed-row'] : classes;
    };

  private createFormControls(data: DocumentLibraryFile[], resetFormBeforeReinitialize: boolean) {
    if (resetFormBeforeReinitialize) {
      const tableFormControl = this.formGroup.controls.table as UntypedFormArray;
      if (tableFormControl.controls) {
        tableFormControl.clear();
      }
    }

    const gridRows = ((this.formGroup.controls?.table as UntypedFormArray)?.controls ||
      []) as AbstractControl[];

    const formArray = new UntypedFormArray(gridRows);

    data.forEach(
      ({ description, document_type_id, vendor_id, site_id, id, is_metadata_editable }) => {
        formArray.push(
          this.fb.group({
            description: new UntypedFormControl({
              value: description || '',
              disabled: false,
            }),
            document_type_id: new UntypedFormControl({
              value: document_type_id,
              disabled: !is_metadata_editable,
            }),
            vendor_id: new UntypedFormControl({
              value: vendor_id,
              disabled: !is_metadata_editable,
            }),
            site_id: new UntypedFormControl({ value: site_id, disabled: !is_metadata_editable }),
            id,
          })
        );
      }
    );

    this.formGroup.setControl('table', formArray);
  }

  getSortingModel(sortModel: SortModelItem[]): SortField[] {
    return sortModel.map(({ colId, sort }) => ({
      field: colId,
      descending: sort === 'desc',
    }));
  }

  paginationChange() {
    this.gridAPI.deselectAll();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.gridAPI.setGridOption('serverSideDatasource', {
      getRows: (params) => {
        this.resetTableStateBeforePagination();

        const { search, dateFrom, dateTo, documentTypes, sites, vendors } =
          this.formGroup.getRawValue();

        const filterModel: Record<string, FilterEntity> = {
          document_type_id: {
            type: 'text',
            value: documentTypes,
          },
          vendor_id: {
            type: 'text',
            value: vendors,
          },
          site_id: {
            type: 'text',
            value: sites,
          },
          create_date: {
            type: 'date',
            value: [dateFrom, dateTo],
          },
        };

        const filters = TableService.getServerSideFilters(filterModel);

        this.documentLibraryService
          .getDocumentsWithPagination({
            start_row: params.request.startRow || 0,
            end_row: params.request.endRow || 100,
            search_text: search,
            sort_model: this.getSortingModel(params.request.sortModel),
            filter_model: JSON.stringify(filters),
          })
          .subscribe((gridData) => {
            if (gridData.data) {
              if (isNumber(gridData.totalItems)) {
                this.totalItems$.next(gridData.totalItems);
              }

              const allGridData =
                params.request.startRow === 0
                  ? gridData.data
                  : this.gridData$.getValue().concat(gridData.data);
              this.gridData$.next(allGridData);
              params.success({ rowData: gridData.data, rowCount: this.totalItems$.getValue() });
              this.gridAPI?.hideOverlay();
            } else {
              params.success({ rowData: [], rowCount: 0 });
              this.gridData$.next([]);
              this.gridAPI?.showNoRowsOverlay();
            }

            const noFilterChange = isEqual(this.prevFilters, filters);

            this.refreshFormControls(gridData.data, !noFilterChange);

            if (!noFilterChange) {
              this.prevFilters = { ...filters };

              const list = gridData.data.map((file) => {
                const transformFile = pick(file, [
                  'description',
                  'document_type_id',
                  'vendor_id',
                  'site_id',
                  'id',
                ]);

                transformFile.description = transformFile.description || '';

                return transformFile;
              });
              this.filterChange.emit(list);
            }
          });
      },
    });

    this.gridAPI.sizeColumnsToFit();
  }

  private resetTableStateBeforePagination() {
    this.gridAPI.deselectAll();
    this.rowSelectedHandler();
  }
}
