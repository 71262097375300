import { Injectable } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { InvoiceStatus, PaymentStatus } from '@shared/services/gql.service';
import { get } from 'lodash-es';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { PurchaseOrdersQuery } from '../purchase-orders/state/purchase-orders.query';

@Injectable({
  providedIn: 'root',
})
export class InvoicesGridFormatterService {
  constructor(private purchaseOrdersQuery: PurchaseOrdersQuery) {}

  static getInvoiceStatusStyles(invoiceStatus: string) {
    let bgClass;
    let textClass;
    let label;

    switch (invoiceStatus) {
      case InvoiceStatus.STATUS_PENDING_APPROVAL:
        bgClass = 'bg-aux-blue-light-200';
        textClass = 'text-aux-blue-light-200';
        label = 'Pending Approval';

        break;
      case InvoiceStatus.STATUS_APPROVED:
        bgClass = 'bg-aux-green-dark';
        textClass = 'text-aux-green-dark';
        label = 'Approved';

        break;
      case InvoiceStatus.STATUS_DECLINED:
        bgClass = 'bg-aux-red-dark';
        textClass = 'text-aux-red-dark';
        label = 'Declined';

        break;
      case InvoiceStatus.STATUS_PENDING_REVIEW:
        bgClass = 'bg-aux-gray-darkest';
        textClass = 'text-aux-gray-darkest';
        label = 'Pending Review';

        break;
      case InvoiceStatus.STATUS_IN_QUEUE:
        bgClass = 'bg-aux-gray-darkest';
        textClass = 'text-aux-gray-darkest';
        label = 'Pending In Queue';

        break;
      default:
        bgClass = '';
        textClass = '';
        label = Utils.zeroHyphen;

        break;
    }

    return { bgClass, textClass, label };
  }

  static getInvoicePaymentStatusStyles(status: string | null) {
    let bgClass;
    let textClass;
    let label;

    switch (status) {
      case PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL:
        bgClass = 'bg-aux-green-dark';
        textClass = 'text-aux-green-dark';
        label = 'Paid';

        break;
      case PaymentStatus.PAYMENT_STATUS_DECLINED:
      case PaymentStatus.PAYMENT_STATUS_SCHEDULED:
      case PaymentStatus.PAYMENT_STATUS_PARTIAL_PAYMENT:
      case PaymentStatus.PAYMENT_STATUS_UNPAID:
        bgClass = 'bg-aux-red-dark';
        textClass = 'text-aux-red-dark';
        label = 'Unpaid';

        break;
      default:
        bgClass = '';
        textClass = '';
        label = Utils.zeroHyphen;

        break;
    }

    return { bgClass, textClass, label };
  }

  getFormattedPurchaseOrder = (value: string) => {
    const category = this.purchaseOrdersQuery.getEntity(value);

    return (
      (category?.po_number === '0' || category?.po_number === '-'
        ? Utils.zeroHyphen
        : category?.po_number) || Utils.zeroHyphen
    );
  };

  getFormattedPaymentStatus(paymentStatus: PaymentStatus): string {
    if (paymentStatus === PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL) {
      return 'Paid';
    }

    if (paymentStatus) {
      return 'Unpaid';
    }

    return Utils.zeroHyphen;
  }

  getFormattedInvoiceStatus(invoiceStatus: InvoiceStatus): string {
    const mapInvoiceStatus = new Map<InvoiceStatus, string>([
      [InvoiceStatus.STATUS_APPROVED, 'Approved'],
      [InvoiceStatus.STATUS_DECLINED, 'Declined'],
      [InvoiceStatus.STATUS_PENDING_APPROVAL, 'Pending Approval'],
      [InvoiceStatus.STATUS_PENDING_REVIEW, 'Pending Review'],
      [InvoiceStatus.STATUS_IN_QUEUE, 'Pending In Queue'],
    ]);

    return mapInvoiceStatus.get(invoiceStatus) || Utils.zeroHyphen;
  }

  getFormatterCreateAuthor = (
    val: ValueFormatterParams,
    userFormatter: (value: string) => string
  ): string => {
    const data_source_id = get(val, 'data.data_source_id');

    if (data_source_id === 'DATA_SOURCE_AUXILIUS') {
      return userFormatter(val.value);
    }
    return `${Utils.readableDataSource(data_source_id)} Integration`;
  };
}
