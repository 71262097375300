import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FileManagerUploadedFilesComponent } from '@features/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { InputComponent } from '@shared/components/input/input.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RemoveSiteBudgetComponent } from './remove-site-budget.component';
import { NgIf } from '@angular/common';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { listSiteBudgetVersionsQuery } from '@shared/services/gql.service';
import { File } from '@shared/components/file-manager/state/file.model';
import { Maybe } from '@shared/utils/utils';
import { FormErrorDirective } from '@shared/directives/form-error.directive';
import { ButtonItemOption } from '@shared/components/button-list/button-list.component';
import { MainQuery } from '@shared/store/main/main.query';

@Component({
  selector: 'aux-site-budget-version-panel',
  templateUrl: './site-budget-version-panel.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    IconComponent,
    NgSelectModule,
    FileManagerComponent,
    FileManagerUploadedFilesComponent,
    RemoveSiteBudgetComponent,
    NgIf,
    FormErrorDirective,
  ],
})
export class SiteBudgetVersionPanelComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @ViewChild('fileManager') fileManager?: FileManagerComponent;

  @ViewChild('siteBudgetNameInput') siteBudgetNameInput!: InputComponent;

  @Output() fileManagerInstance = new EventEmitter<FileManagerComponent>();

  @Output() removeFileEvent = new EventEmitter<File>();

  @Output() removeSiteBudgetEvent = new EventEmitter<string>();

  editMode = input.required<boolean>();

  @Input({ required: true }) siteBudgetForm!: FormGroup;

  @Input({ required: true }) siteId!: string;

  @Input() siteBudgetName: Maybe<string>;

  siteBudgeOptionList = input.required<listSiteBudgetVersionsQuery[]>();

  siteBudgeOptionList$ = toObservable(this.siteBudgeOptionList);

  baseLineSiteBudget = signal(true);

  @Input({ required: true }) protocolVersionList!: ButtonItemOption[];

  constructor(private mainQuery: MainQuery) {
    effect(() => {
      this.updateDocumentsBySiteBudgetId(this.siteBudgetForm.getRawValue().siteBudgetVersion);
    });
  }

  ngOnInit(): void {
    const fc = this.siteBudgetForm.get('siteBudgetVersion');
    if (!fc) {
      return;
    }
    combineLatest([fc.valueChanges, this.siteBudgeOptionList$])
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe(([value, loadingFilters]) => {
        if (value && !!loadingFilters.length) {
          this.updateDocumentsBySiteBudgetId(value);

          const siteBudget = this.siteBudgeOptionList().find(({ id }) => id === value);

          const isBaseline = siteBudget?.is_baseline;

          this.baseLineSiteBudget.set(!!isBaseline);

          const note = siteBudget?.notes?.length ? siteBudget?.notes[0] : null;

          this.siteBudgetForm.patchValue({
            siteBudgetName: siteBudget?.name ?? null,
            note: note?.message ?? null,
            noteId: note?.id ?? null,
            effectiveDate: siteBudget?.effective_date ?? null,
          });
        }
      });
  }

  private updateDocumentsBySiteBudgetId(budgetId: string) {
    const documents = (
      this.siteBudgeOptionList().find(({ id }) => id === budgetId)?.documents || []
    ).map((file) => ({
      ...file,
      fileName: file.name,
      uploaded: true,
    }));

    this.fileManager?.fileStore.set(documents as unknown as File[]);
  }

  fileManagerReady(fileManager: FileManagerComponent) {
    this.fileManagerInstance.emit(fileManager);
  }

  pathFn = () => {
    const { trialKey } = this.mainQuery.getValue();
    const { siteBudgetVersion } = this.siteBudgetForm.getRawValue();

    return `trials/${trialKey}/sites/${this.siteId}/${siteBudgetVersion}/contracts/`;
  };

  removeFile(file: File) {
    this.removeFileEvent.emit(file);
  }

  removeSiteBudget(success: boolean) {
    if (!success) return;

    const { siteBudgetVersion } = this.siteBudgetForm.getRawValue();
    this.removeSiteBudgetEvent.emit(siteBudgetVersion);
  }
}
