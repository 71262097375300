import { Component, ChangeDetectionStrategy, OnInit, signal } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import {
  BudgetSnapshotType,
  BudgetType,
  EntityType,
  EventType,
} from '@shared/services/gql.service';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { OverlayService } from '@shared/services/overlay.service';
import { firstValueFrom } from 'rxjs';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { EventService as GlobalEventService } from '@models/event/event.service';
import { InputComponent } from '@shared/components/input/input.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';

@Component({
  selector: 'aux-snapshot-modal',
  template: `
    <form [formGroup]="snapshotForm" (ngSubmit)="!ref.data?.name ? onSave() : onUpdate()">
      <aux-modal
        primaryButtonType="submit"
        [loading]="submitting()"
        [primaryButtonDisabled]="submitting()"
      >
        <div class="font-inter w-[380px]">
          <aux-input formControlName="snapshotName" placeholder="Name" validators="required" />
        </div>
      </aux-modal>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule, ButtonComponent, ModalComponent],
})
export class SnapshotModalComponent implements OnInit {
  submitting = signal(false);

  snapshotForm = this.fb.group({
    snapshotName: ['', [Validators.required]],
  });

  constructor(
    public ref: CustomOverlayRef<unknown, { name?: string }>,
    private overlayService: OverlayService,
    private fb: UntypedFormBuilder,
    private eventService: EventTrackerService,
    private globalEventService: GlobalEventService
  ) {}

  ngOnInit(): void {
    if (this.ref.data?.name) {
      this.snapshotForm.setValue({
        snapshotName: this.ref.data.name,
      });
    }
  }

  onUpdate = () => {
    this.ref.close({ name: this.snapshotForm.value.snapshotName });
  };

  onSave = async () => {
    if (this.snapshotForm.valid) {
      this.submitting.set(true);

      const { success, errors, data } = await firstValueFrom(
        this.globalEventService.processEvent$({
          type: EventType.CREATE_TRIAL_BUDGET_SNAPSHOT,
          entity_type: EntityType.TRIAL,
          entity_id: '',
          payload: JSON.stringify({
            budget_type: BudgetType.BUDGET_SNAPSHOT,
            merge_type: 'MERGE_BUDGET_SNAPSHOT',
            user_description: this.snapshotForm.value.snapshotName,
            snapshot_type: BudgetSnapshotType.BUDGET_SNAPSHOT_USER_CREATED,
          }),
        })
      );

      if (data) {
        this.eventService.trackEvent(data.id);
      }

      if (success) {
        this.ref.close();
      } else {
        this.overlayService.error(errors);
      }

      this.submitting.set(false);
    }
  };
}
