import {
  ValueFormatterParams,
  CellRendererSelectorResult,
  CellClassParams,
  ICellRendererParams,
  ITooltipParams,
} from '@ag-grid-community/core';

import { Utils } from '@shared/utils/utils';
import {
  Currency,
  InvestigatorTransactionSourceType,
  PatientProtocolType,
} from '@shared/services/gql.service';
import { CurrencyToggle } from '@shared/types/components.type';
import { BehaviorSubject } from 'rxjs';
import { TableConstants } from '@shared/constants/table.constants';
import { InvestigatorTransactionsDataRow } from '../models/investigator-transaction-form.model';
import { AgCollapsibleHeaderComponent } from '@shared/ag-components/ag-collapsible-header/ag-collapsible-header.component';
import {
  AgDatepickerComponent,
  AgDropdownComponent,
  AgInputComponent,
} from '@shared/ag-components/ag-controls';
import { AgInputMaskComponent } from '@shared/ag-components/ag-controls/ag-input-mask.component';
import { Option } from '@shared/types/components.type';
import { AgDotsComponent } from '@shared/ag-components/ag-dots/ag-dots.components';
import { MessagesConstants } from '@shared/constants/messages.constants';
import {
  GetTransactionColsArgs,
  OverrideGridOptions,
} from '../models/investigator-transaction-grid.model';

export const CATEGORY_OPTIONS: Option[] = [
  {
    label: 'Patient Visit',
    value: PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
  },
  {
    label: 'Patient Invoiceable',
    value: PatientProtocolType.PATIENT_PROTOCOL_OTHER,
  },
  {
    label: 'Site Invoiceable',
    value: PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
  },
];

const suppressKeyboardEvent = () => true;

const isStaticMode = (mode?: string) => mode === 'static';

const MIN_WIDTH_EDITABLE_CELL = 170;

const getCellClasses = (
  classes: string[],
  cellClassParams: CellClassParams,
  savedTransactionIds: string[] = []
): string[] => {
  return isStaticMode(cellClassParams.data?.mode) &&
    !savedTransactionIds.includes(cellClassParams.data.id)
    ? classes
    : [...classes, 'flex flex-col !justify-center !items-stretch new-cell'];
};

const cellRerenderWithControl = (
  params: ICellRendererParams,
  control: CellRendererSelectorResult
) => {
  return !isStaticMode(params.data?.mode) ? control : undefined;
};

const tooltipValueGetter =
  (propertyName: keyof InvestigatorTransactionsDataRow) =>
  (params: ITooltipParams): string => {
    return isStaticMode(params?.data?.mode) ? params?.data[propertyName] : '';
  };

export const getInvestigatorTransactionCols = ({
  isContractCurrency,
  isEDCColumnBeShown,
  showSourceColumn,
  showSiteBudgetVersionColumn,
  isSiteColumnCollapsed$,
  protocolVersionOptions$,
  siteOptions,
  patientGroups$,
  updateSiteFields,
  updatePatientIdField,
  editRow,
  savedTransactionIds$,
  removeRow,
  transactionMode$,
  isPatientsFinalized,
  updateTransactionPermission,
}: GetTransactionColsArgs): OverrideGridOptions => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    resizable: true,
    minWidth: 100,
    suppressKeyboardEvent,
  },
  columnDefs: [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'sort_to_top_ids',
      colId: 'sort_to_top_ids',
      hide: true,
    },
    {
      field: 'create_date',
      colId: 'create_date',
      hide: true,
    },
    {
      headerName: 'Completion Date',
      field: 'activity_date',
      colId: 'activity_date',
      tooltipValueGetter: tooltipValueGetter('activity_date'),
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgDatepickerComponent,
          params: { validators: 'required', size: 'compact' },
        }),
    },
    {
      headerName: 'Total Cost (USD)',
      field: 'total_cost',
      colId: 'total_cost',
      minWidth: 100,
      tooltipValueGetter: (params) =>
        Utils.agCurrencyFormatter({ value: params.value } as ValueFormatterParams, Currency.USD),
      cellClass: (params) =>
        getCellClasses(
          [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
          params,
          savedTransactionIds$.getValue()
        ),
      valueFormatter: (params) => Utils.agCurrencyFormatter(params, Currency.USD),
    },
    {
      headerName: 'Total Cost (Contract)',
      field: 'contract_amount',
      colId: 'contract_amount',
      tooltipValueGetter: (params) => {
        if (!isStaticMode(params.data?.mode)) {
          return '';
        }

        return Utils.agMultipleCurrencyFormatter(
          new BehaviorSubject<CurrencyToggle>(CurrencyToggle.CONTRACTED)
        )({
          value: params.value,
          data: {
            ...params.data,
            contractCurrency: params.data.contract_curr,
          },
        });
      },
      cellClass: (params) => getCellClasses([], params, savedTransactionIds$.getValue()),
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      cellClassRules: {
        [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]: (params) => params.data?.mode === 'static',
      },

      hide: !isContractCurrency,
      valueFormatter: (params) =>
        Utils.agMultipleCurrencyFormatter(
          new BehaviorSubject<CurrencyToggle>(CurrencyToggle.CONTRACTED)
        )({
          ...params,
          data: {
            ...params.data,
            contractCurrency: params.data.contract_curr,
          },
        }),
      cellRendererSelector: (params) => {
        const currencySign = Utils.currencyFormatter(1, {}, params.data.contract_curr).replace(
          /\d*(\.)*/gi,
          ''
        );

        return cellRerenderWithControl(params, {
          component: AgInputMaskComponent,
          params: {
            placeholder: 'Enter Amount',
            allowNegative: true,
            currencySign,
            size: 'compact',
          },
        });
      },
    },
    {
      headerName: 'Contract Currency',
      field: 'contract_curr',
      colId: 'contract_curr',
      tooltipField: 'contract_curr',
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      hide: !isContractCurrency,
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Exchange Rate',
      field: 'exchange_rate',
      colId: 'exchange_rate',
      tooltipField: 'exchange_rate',
      cellClass: (params) =>
        getCellClasses(
          [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
          params,
          savedTransactionIds$.getValue()
        ),
      valueFormatter: Utils.dashFormatter,
      hide: !isContractCurrency,
    },
    {
      headerName: 'Site #',
      field: 'site_no',
      colId: 'site_no',
      tooltipValueGetter: tooltipValueGetter('site_no'),
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      headerComponent: AgCollapsibleHeaderComponent,
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      headerComponentParams: {
        collapsibleColumns: ['site_name', 'site_address', 'country', 'investigator_name'],
        collapsedByDefault: true,
        isColumnCollapsed$: isSiteColumnCollapsed$,
      },
      sortable: true,
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgDropdownComponent,
          params: {
            options: siteOptions().map(({ title, value, subTitle }) => ({
              label: title,
              value,
              subTitle,
            })),
            changeHandler: (option?: { value: string }) => {
              updateSiteFields(params.data.id, option?.value);
            },
            classes: 'small',
            searchable: true,
          },
        }),
    },
    {
      headerName: 'Site Name',
      field: 'site_name',
      colId: 'site_name',
      tooltipField: 'site_name',
      hide: true,
      sortable: true,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Site Address',
      field: 'site_address',
      colId: 'site_address',
      tooltipField: 'site_address',
      sortable: true,
      hide: true,
      cellClass: (params) =>
        getCellClasses(
          ['text-left', 'cell-left', 'whitespace-nowrap overflow-hidden text-ellipsis !block'],
          params,
          savedTransactionIds$.getValue()
        ),
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Country',
      field: 'country',
      colId: 'country',
      tooltipField: 'country',
      hide: true,
      sortable: true,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Primary Investigator',
      field: 'investigator_name',
      colId: 'investigator_name',
      tooltipField: 'investigator_name',
      hide: true,
      sortable: true,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: (params) => {
        return Utils.dashFormatter({
          ...params,
          value: params.value?.trim(),
        });
      },
    },
    {
      headerName: 'Category',
      field: 'patient_protocol_category',
      colId: 'patient_protocol_category',
      tooltipValueGetter: tooltipValueGetter('patient_protocol_category'),
      sortable: true,
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgDropdownComponent,
          params: {
            options: CATEGORY_OPTIONS,
            changeHandler: (option?: { value: string }) => {
              updatePatientIdField(params.data.id, option?.value);
            },
            classes: 'small',
          },
        }),
    },
    {
      headerName: 'Patient ID',
      field: 'external_patient_id',
      colId: 'external_patient_id',
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      tooltipValueGetter: tooltipValueGetter('external_patient_id'),
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgInputComponent,
          params: {
            placeholder: 'Type here',
            size: 'compact',
          },
        }),
    },
    {
      headerName: 'Patient Group',
      field: 'patient_group_id',
      colId: 'patient_group_id',
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      tooltipValueGetter: tooltipValueGetter('patient_group_id'),
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgDropdownComponent,
          params: {
            options: patientGroups$.getValue().map(({ id, name }) => ({ value: id, label: name })),

            classes: 'small',
          },
        }),
    },
    {
      headerName: 'Protocol Version',
      field: 'patient_protocol_version_name',
      colId: 'patient_protocol_version_name',
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      tooltipValueGetter: tooltipValueGetter('patient_protocol_version_name'),
      sortable: true,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgDropdownComponent,
          params: {
            options: protocolVersionOptions$
              .getValue()
              .map(({ id, name }) => ({ value: id, label: name })),
            classes: 'small',
          },
        }),
    },
    {
      headerName: 'Site Budget Version',
      field: 'site_budget_version_name',
      colId: 'site_budget_version_name',
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      tooltipValueGetter: tooltipValueGetter('site_budget_version_name'),
      sortable: false,
      hide: !showSiteBudgetVersionColumn,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: MIN_WIDTH_EDITABLE_CELL,
      suppressKeyboardEvent,
      tooltipValueGetter: tooltipValueGetter('description'),
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      cellRendererSelector: (params) =>
        cellRerenderWithControl(params, {
          component: AgInputComponent,
          params: {
            placeholder: 'Type here',
            size: 'compact',
          },
        }),
    },
    {
      headerName: 'Source',
      field: 'source_name',
      colId: 'source_name',
      tooltipField: 'Source',
      sortable: true,
      hide: !showSourceColumn,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Created Date',
      field: 'source_created_date',
      colId: 'source_created_date',
      tooltipField: 'EDCCreatedDate',
      hide: !isEDCColumnBeShown,
      cellClass: (params) =>
        getCellClasses(['text-left', 'cell-left'], params, savedTransactionIds$.getValue()),
      valueFormatter: Utils.dashFormatter,
      minWidth: 120,
    },
    {
      headerName: '',
      cellRenderer: AgDotsComponent,
      cellClass: (params) => getCellClasses([], params, savedTransactionIds$.getValue()),
      cellRendererParams: (params: ICellRendererParams) => {
        const sourceType = params.data.source_type;
        const createMode = params.data.mode === 'create';
        const staticMode = params.data.mode === 'static';

        const disabled =
          sourceType !== InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL ||
          (transactionMode$.getValue() === 'create' && staticMode);

        const options = [];

        if (
          sourceType !==
            InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_SITE_INVOICEABLE_SCHEDULE &&
          !createMode
        ) {
          options.push({
            title: 'Edit',
            onClick: () => {
              const id = params.data.id;
              if (id) {
                editRow(id);
              }
            },
          });
        }

        let tooltip = '';
        if (
          sourceType !== InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL
        ) {
          tooltip = 'In-App editing is not supported for this investigator transaction source.';
        } else if (
          !updateTransactionPermission() &&
          sourceType === InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL
        ) {
          tooltip = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
        } else if (
          isPatientsFinalized() &&
          sourceType === InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL
        ) {
          tooltip = MessagesConstants.PATIENT_TRACKER_CLOSED;
        }

        return {
          disabled: disabled || isPatientsFinalized() || !updateTransactionPermission(),
          tooltip,
          options: [
            ...options,
            {
              title: 'Delete',
              onClick: () => {
                const id = params.data.id;

                if (id) {
                  removeRow(id, createMode);
                }
              },
              optionClass: 'text-aux-red-dark',
            },
          ],
        };
      },
      field: 'dots',
      pinned: 'right',
      maxWidth: 40,
    },
  ],
});
