import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { WorkflowStep } from '@shared/services/gql.service';

export class DirtyStep {
  constructor(
    public isMonthlyReviewNotesDirty: boolean,
    public isTaskInstructionDirty: boolean
  ) {}

  get someEditorDirty(): boolean {
    return this.isMonthlyReviewNotesDirty || this.isTaskInstructionDirty;
  }
}

export interface TaskInstruction {
  text: string;
  id?: string;
  updated?: string | Date;
  isDefault?: boolean;
}

export interface MonthlyReviewNote {
  created: Date | string;
  createdBy: string;
  text: string;
  id: string;
  workflowId?: string;
}

export interface QuarterCloseNotesStoreData {
  dirty: DirtyStep;
  monthlyReviewNotesCount: number;
  taskInstruction: TaskInstruction;
  monthlyReviewNotes: MonthlyReviewNote[];
}

export interface QuarterCloseNotesState {
  notes: {
    [key: string]: QuarterCloseNotesStoreData;
  };
  selectedPeriod: string | null;
}

export const DEFAULT_TASK_INSTRUCTIONS = {
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_DISCOUNTS]: {
    text: 'Where applicable, confirm or recalculate each vendor’s discount for the month. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS]: {
    text: 'Please click link to In-Month Adjustments page, and review each vendor’s detailed expense information for the open month by using the vendor drop down. Granular activities are included for each vendor with (1) actuals to date, (2) remaining amount, (3) prior month’s actuals, (4) forecast expense for current month (if configured), (5) vendor estimate (if received), and (6) “evidence based” real-time patient visit costs (if configured & applicable). Supporting vendor documentation is available for download by selecting the download icon in the vendor estimate section’s kebab menu. If adjustments are required, select edit in the top right corner and adjust the expense estimate directly, adding notes or supporting info. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CURVES]: {
    text: 'FP&A/ClinOps to review dynamic forecast inputs and assumptions including any changes to patient enrollment curves, site activation curves, or custom curves. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_FORECAST_METHODOLOGY]: {
    text: 'FP&A/ClinOps to review dynamic forecast settings to ensure no changes are required to forecast drivers. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_TIMELINE]: {
    text: 'FP&A/ClinOps to review dynamic forecast inputs and assumptions including any changes to timeline. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_CHANGE_ORDERS]: {
    text: 'Please click through corresponding links and add any missing inputs if needed. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES]: {
    text: 'This table will show all vendor estimates received. Please confirm all vendor estimates have been shared, and if any have not been provided, please load via the link on the page. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES]: {
    text: 'Please review invoices and prepaids, leveraging vendor & posting period filters as needed. Any necessary updates can be made by selecting “Edit” and updating fields accordingly. If additional invoices or Credit Memos have been received, please add directly by selecting “Add New”. Once completed, select Lock.',
  },
  [WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER]: {
    text: 'Confirm life-to-date patient visit and invoiceable data is provided, and review this data within Auxilius (this step is particularly important if EDC data is being leveraged for expense estimates). Once completed, select Lock.',
  },
};

export function createInitialState(): QuarterCloseNotesState {
  return {
    selectedPeriod: null,
    notes: {},
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'quarter-close-notes' })
export class QuarterCloseNotesStore extends Store<QuarterCloseNotesState> {
  constructor() {
    super(createInitialState());
  }

  updateTaskInstruction(workflowId: string, taskInstruction: TaskInstruction): void {
    this.updateWorkflowNotesData(workflowId, { taskInstruction });
  }

  updateMonthlyReviewNotes(workflowId: string, monthlyReviewNotes: MonthlyReviewNote[]): void {
    this.updateWorkflowNotesData(workflowId, { monthlyReviewNotes });
  }

  updateMonthlyReviewNotesCount(workflowId: string, monthlyReviewNotesCount: number): void {
    this.updateWorkflowNotesData(workflowId, { monthlyReviewNotesCount });
  }

  updateDirtyState(workflowId: string, dirty: DirtyStep): void {
    this.updateWorkflowNotesData(workflowId, { dirty });
  }

  removeMonthlyReviewNote(workflowId: string, id: string): void {
    this.updateWorkflowNotesData(workflowId, {
      monthlyReviewNotesCount: this.getValue().notes[workflowId].monthlyReviewNotesCount - 1,
      monthlyReviewNotes: (this.getValue().notes[workflowId].monthlyReviewNotes || []).filter(
        (note) => note.id !== id
      ),
    });
  }

  addMonthlyReviewNote(workflowId: string, monthlyReviewNote: MonthlyReviewNote): void {
    this.updateWorkflowNotesData(workflowId, {
      monthlyReviewNotesCount: this.getValue().notes[workflowId].monthlyReviewNotesCount + 1,
      monthlyReviewNotes: [
        ...(this.getValue().notes[workflowId].monthlyReviewNotes || []),
        monthlyReviewNote,
      ],
    });
  }

  private updateWorkflowNotesData = (
    workflowId: string,
    data: Partial<QuarterCloseNotesStoreData>
  ) =>
    this.update((state: QuarterCloseNotesState) => ({
      ...state,
      notes: {
        ...state.notes,
        [workflowId]: {
          ...state.notes[workflowId],
          ...data,
        },
      },
    }));
}
