import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OverlayService } from '@shared/services/overlay.service';
import { RiskAlertDialogComponent } from './risk-alert-dialog/risk-alert-dialog.component';

export interface RiskCardAlert {
  color: string;
  message: string;
}
@Component({
  selector: 'aux-risk-card',
  templateUrl: './risk-card.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskCardComponent {
  @Input() alerts: RiskCardAlert[] = [];

  constructor(private overlayService: OverlayService) {}

  onManage() {
    this.overlayService.open({ content: RiskAlertDialogComponent });
  }
}
