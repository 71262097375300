<div class="font-inter w-[325px]">
  <div
    *ngIf="{ isAmendmentView: ref.data?.modalView === 'amendment' } as vars"
    class="flex flex-col"
  >
    <div>
      <aux-input
        #protocolVersion
        label="Protocol Name"
        validators="required"
        [formControl]="protocolVersionFc"
        [placeholder]="vars.isAmendmentView ? 'Amendment Name/Version' : 'Protocol Version Name'"
        [showRequiredAsterisk]="true"
        auxTrimInput
      />
    </div>

    <div *ngIf="ref.data?.protocolVersionOptions && vars.isAmendmentView" class="mt-4">
      <div class="mb-1 text-xs" [ngClass]="{ 'text-aux-gray-dark': protocolVersionFc.invalid }">
        Copy From (optional)
      </div>
      <ng-select
        placeholder="Select version"
        bindLabel="label"
        bindValue="value"
        [clearable]="true"
        [searchable]="false"
        [formControl]="copyFromVersionFc"
        [appendTo]="'body'"
        [items]="ref.data!.protocolVersionOptions"
      />
    </div>

    <div class="mt-4">
      <aux-input
        label="Protocol Version Effective Date"
        [formControl]="effectiveDateFc"
        placeholder="YYYY-MM-DD"
        [type]="'date'"
      />
    </div>

    <aux-file-manager
      #fileManager
      class="h-28 bg-aux-gray-light mt-4"
      [fetchFilesOnInit]="ref.data?.modalView === 'edit'"
      [pathFn]="pathFn"
      [eager]="false"
      [metadata]="metadata"
      [showSuccessOnUpload]="true"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <aux-icon name="Upload" />
        <div class="ml-3">
          <p class="text-lg">Drag & Drop Documents Here or <span class="aux-link">Browse</span></p>
        </div>
      </div>
    </aux-file-manager>
    <aux-file-manager-uploaded-files *ngIf="fileManager" [fileManager]="fileManager" />
  </div>
</div>
