import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'lodash-es';

@Pipe({
  name: 'groupBy',
  standalone: true,
})
export class GroupByPipe implements PipeTransform {
  transform<T>(collection: Array<T> | null, property: string): { key: string; value: Array<T> }[] {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return [];
    }

    const groupedCollection = groupBy(collection, property);

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map((key) => ({ key, value: groupedCollection[key] }));
  }
}
