import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Utils } from '@shared/utils/utils';
import dayjs from 'dayjs';
import { InvoicePrepaidCandidate, PrepaidType } from '@shared/services/gql.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { MessagesConstants } from '@shared/constants/messages.constants';

type AgInvoiceDropdownParams = ICellRendererParams & {
  options: AgInvoiceDropdownOption[];
};

type AgInvoiceDropdownOption = InvoicePrepaidCandidate & {
  textToSearch?: string;
  disabled: boolean;
};

@Component({
  selector: 'aux-ag-invoice-dropdown',
  templateUrl: 'ag-invoice-dropdown.component.html',
  styleUrl: 'ag-invoice-dropdown.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    NgIf,
    FormsModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    TooltipDirective,
  ],
})
export class AgInvoiceDropdownComponent implements ICellRendererAngularComp {
  readonly utils = Utils;

  readonly launchDarklyService = inject(LaunchDarklyService);
  readonly mainQuery = inject(MainQuery);

  params!: AgInvoiceDropdownParams;
  formControl = new FormControl();

  refresh(): boolean {
    return false;
  }

  agInit(params: AgInvoiceDropdownParams): void {
    this.params = params;

    if (params.options.length > 0) {
      this.params.options = params.options.map((invoice) => ({
        ...invoice,
        textToSearch: `${invoice.invoice_no} ${invoice.amount_total ? this.totalAmount(invoice) : ''} ${invoice.accrual_period ? this.accrualPeriod(invoice) : ''}`,
        disabled: this.isOptionDisabled(invoice),
      }));
    }
    this.formControl.setValue(params.value);
  }

  totalAmount(item: InvoicePrepaidCandidate): string {
    return item.amount_total ? Utils.currencyFormatter(item.amount_total) : Utils.zeroHyphen;
  }

  accrualPeriod(item: InvoicePrepaidCandidate): string {
    return item.accrual_period ? dayjs(item.accrual_period).format('MMMM YYYY') : Utils.zeroHyphen;
  }

  getValue(): string {
    return this.formControl.value;
  }

  search(term: string, item: AgInvoiceDropdownOption) {
    return item.textToSearch?.toLowerCase().includes(term.toLowerCase());
  }

  tooltip(item: AgInvoiceDropdownOption): string {
    return item.disabled ? MessagesConstants.PREPAIDS.INCREASE_ONLY_PAID_INVOICE : '';
  }

  private isOptionDisabled(item: AgInvoiceDropdownOption): boolean {
    if (!item.id) {
      return false;
    }

    if (this.launchDarklyService.flags$.getValue().journal_entry_ap_manual_clearing) {
      if (this.params.data.prepaid_type === PrepaidType.PREPAID_TYPE_INCREASE) {
        return (
          !item.payment_date ||
          dayjs(item.payment_date).isAfter(
            dayjs(this.mainQuery.getValue().currentOpenMonth).endOf('month')
          )
        );
      }
    }

    return false;
  }
}
