<div class="flex gap-2 filters flex-wrap" [formGroup]="form">
  <ng-content select="[bulkEditBlock]" />

  <div class="max-w-sm w-full">
    <div class="h-4 mb-1"></div>
    <aux-input placeholder="Search" formControlName="search" icon="Search" class="w-full" />
  </div>
  <div>
    <span class="block text-xs mb-1">Vendor:</span>
    <ng-select
      class="tabular-nums big-dropdown"
      style="width: 350px"
      placeholder="All"
      formControlName="vendors"
      bindValue="value"
      [multiple]="true"
      [searchable]="true"
      [items]="invoiceService.vendorOptions"
    >
      <ng-template let-item="item" ng-option-tmp>
        <div
          class="block truncate"
          #text
          [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).label : ''"
          [auxTooltipPositions]="optionTooltipPositions"
        >
          {{ item.label }}
        </div>
      </ng-template>
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        @if (items.length === 1) {
          <div class="ng-value">
            @for (item of items | slice: 0 : 1; track $any(item).label) {
              <div class="flex">
                <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
                <span
                  class="ng-value-label overflow-hidden text-ellipsis max-w-[200px]"
                  #text
                  [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).label : ''"
                  [auxTooltipPositions]="optionTooltipPositions"
                >
                  {{ $any(item).label }}
                </span>
              </div>
            }
          </div>
        } @else if (items.length > 1) {
          <div class="ng-value">
            <span class="ng-value-label">{{ items.length }} Selected</span>
          </div>
        }
      </ng-template>
    </ng-select>
  </div>
  <div>
    <span class="block text-xs mb-1">Invoice Date:</span>
    <aux-input
      placeholder="YYYY-MM-DD"
      formControlName="invoiceDate"
      class="w-36"
      [type]="'date'"
    />
  </div>
  <div>
    <span class="block text-xs mb-1">Posting Period:</span>
    <ng-select
      class="w-60 tabular-nums"
      placeholder="All Months"
      formControlName="accrualPeriod"
      bindValue="value"
      [multiple]="true"
      [searchable]="true"
      [items]="invoiceService.accrualPeriodOptions"
    >
      <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
        @if (items.length === 1) {
          <div class="ng-value">
            @for (item of items | slice: 0 : 1; track $any(item).label) {
              <div class="flex">
                <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
                <span class="ng-value-label overflow-hidden text-ellipsis">{{
                  $any(item).label
                }}</span>
              </div>
            }
          </div>
        } @else if (items.length > 1) {
          <div class="ng-value">
            <span class="ng-value-label">{{ items.length }} Selected</span>
          </div>
        }
      </ng-template>
    </ng-select>
  </div>
  @if (optionalFilters().is_deposit) {
    <div>
      <aux-multi-select-dropdown
        class="block w-32"
        [prefix]="editMode() ? '' : 'X'"
        placeholder="All"
        label="Deposit"
        [items]="depositItems"
        [formControlName]="'is_deposit'"
        [bindLabel]="'label'"
        [prefixClick]="onRemoveOptionalFilter('is_deposit')"
        [bindValue]="'value'"
        [multiple]="false"
      />
    </div>
  }
  @if (optionalFilters().has_prepaid) {
    <div>
      <aux-multi-select-dropdown
        class="block w-32"
        [prefix]="editMode() ? '' : 'X'"
        placeholder="All"
        label="Prepaid Impact"
        [items]="prepaidItems"
        [formControlName]="'has_prepaid'"
        [bindLabel]="'label'"
        [prefixClick]="onRemoveOptionalFilter('has_prepaid')"
        [bindValue]="'value'"
        [multiple]="false"
      />
    </div>
  }
  @if (optionalFilters().does_invoice_mappings_match_total) {
    <div>
      <aux-multi-select-dropdown
        class="block w-32"
        [prefix]="editMode() ? '' : 'X'"
        placeholder="All"
        label="Mapped to Activities"
        [items]="mappedToActivitiesItems"
        [formControlName]="'does_invoice_mappings_match_total'"
        [bindLabel]="'label'"
        [prefixClick]="onRemoveOptionalFilter('does_invoice_mappings_match_total')"
        [bindValue]="'value'"
        [multiple]="false"
      />
    </div>
  }
  @if (optionalFilters().services_period) {
    <div>
      <aux-multi-select-dropdown
        class="block w-60"
        [prefix]="editMode() ? '' : 'X'"
        placeholder="All"
        label="Service Period"
        [items]="invoiceService.servicePeriodOptions"
        [formControlName]="'services_period'"
        [bindLabel]="'label'"
        [prefixClick]="onRemoveOptionalFilter('services_period')"
        [bindValue]="'value'"
        [multiple]="true"
        [attr.data-pendo-id]="'pendo-service-period-dropdown'"
      />
    </div>
  }

  @if (showAddFilter()) {
    <div class="flex flex-col">
      <div class="h-4 mb-1"></div>
      <aux-button
        class="block flex-1"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        variant="hyperlink"
        [disabled]="form.disabled"
        (auxClickOutside)="isAddFiltersOpen.set(false)"
        (clickEmit)="isAddFiltersOpen.set(true)"
        label="+ Add Filter"
      />
    </div>

    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isAddFiltersOpen()"
    >
      <ul
        tabindex="-1"
        class="bg-white border border-aux-gray-dark text-base overflow-auto mt-2 rounded w-56"
      >
        @if (!optionalFilters().is_deposit && depositFilterFF()) {
          <li
            class="text-gray-900 cursor-pointer select-none relative flex flex-col p-2.5 hover:bg-aux-gray-light"
            (click)="onAddOptionalFilter('is_deposit')"
          >
            <p class="font-inter">Deposit</p>
          </li>
        }
        @if (!optionalFilters().has_prepaid && prepaidFilterFF()) {
          <li
            class="text-gray-900 cursor-pointer select-none relative flex flex-col p-2.5 hover:bg-aux-gray-light"
            (click)="onAddOptionalFilter('has_prepaid')"
          >
            <p class="font-inter">Prepaid</p>
          </li>
        }
        @if (!optionalFilters().does_invoice_mappings_match_total) {
          <li
            class="text-gray-900 cursor-pointer select-none relative flex flex-col p-2.5 hover:bg-aux-gray-light"
            (click)="onAddOptionalFilter('does_invoice_mappings_match_total')"
          >
            <p class="font-inter">Mapped to Activities</p>
          </li>
        }
        @if (!optionalFilters().services_period) {
          <li
            class="text-gray-900 cursor-pointer select-none relative flex flex-col p-2.5 hover:bg-aux-gray-light"
            (click)="onAddOptionalFilter('services_period')"
          >
            <p class="font-inter">Service Period</p>
          </li>
        }
      </ul>
    </ng-template>
  }
  <div class="flex flex-col">
    <div class="h-4 mb-1"></div>
    <aux-button
      class="block flex-1"
      variant="hyperlink"
      [disabled]="form.disabled"
      (clickEmit)="resetAllFilters()"
      label="Clear All"
    />
  </div>

  <ng-content select="[togglesBlock]" />

  <ng-content select="[saveBlock]" />
</div>
