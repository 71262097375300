import { Injectable } from '@angular/core';
import { CreateDriverSiteSettingInput, GqlService } from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@shared/services/overlay.service';
import { omit } from 'lodash-es';

import { DriverSiteSettingModel, DriverSiteStore } from './driver-site.store';
import { batchPromises } from '@shared/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DriverSiteService {
  constructor(
    private driverSiteStore: DriverSiteStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.driverSiteStore.setLoading(true);
        return this.gqlService.listDriverSiteSetting$().pipe(
          tap(({ success, errors, data }) => {
            this.driverSiteStore.set([]);

            if (success && data) {
              this.driverSiteStore.set(data);
            } else {
              this.overlayService.error(errors);
            }
            this.driverSiteStore.setLoading(false);
          })
        );
      })
    );
  }

  async upsert(driverSiteSettings: (CreateDriverSiteSettingInput & { id?: string })[]) {
    const promises: Promise<void>[] = [];
    const allErrors: string[][] = [];

    for (const driverSiteSetting of driverSiteSettings) {
      if (driverSiteSetting.id) {
        const prom = firstValueFrom(
          this.gqlService.updateDriverSiteSetting$({
            id: driverSiteSetting.id,
            ...driverSiteSetting,
          })
        ).then(({ success, data, errors }) => {
          if (success && data) {
            this.driverSiteStore.update(data.id, data);
          } else {
            allErrors.push(errors);
          }
        });

        promises.push(prom);
      } else {
        const prom = firstValueFrom(
          this.gqlService.createDriverSiteSetting$({
            ...omit(driverSiteSetting, 'id'),
          })
        ).then(({ success, data, errors }) => {
          if (success && data) {
            this.driverSiteStore.add(data);
          } else {
            allErrors.push(errors);
          }
        });

        promises.push(prom);
      }
    }

    await batchPromises(promises, (p) => p);

    return allErrors;
  }

  add(driverSite: DriverSiteSettingModel) {
    this.driverSiteStore.add(driverSite);
  }

  update(id: string, driverSite: Partial<DriverSiteSettingModel>) {
    this.driverSiteStore.update(id, driverSite);
  }

  async remove(ids: string[]) {
    const promises = [];
    const allErrors: string[][] = [];
    for (const id of ids) {
      const prom = firstValueFrom(this.gqlService.removeDriverSiteSetting$(id)).then(
        ({ success, errors }) => {
          if (success) {
            this.driverSiteStore.remove(id);
          } else {
            allErrors.push(errors);
          }
        }
      );
      promises.push(prom);
    }

    await batchPromises(promises, (p) => p);

    return allErrors;
  }
}
