import { ICellRenderer, ICellRendererParams } from '@ag-grid-community/core';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';

@Component({
  standalone: true,
  selector: 'aux-ag-check-rerender',
  template: `<aux-icon
    [name]="value ? 'Check' : 'X'"
    [ngClass]="value ? 'text-aux-green-dark' : 'text-aux-red-dark-2'"
  />`,
  imports: [IconComponent, NgClass],
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCheckRerenderComponent implements ICellRenderer {
  value = false;

  agInit(params: ICellRendererParams) {
    this.value = params.value;
  }

  refresh(): boolean {
    return false;
  }
}
