import { Injectable } from '@angular/core';
import { SortOrder } from '@shared/services/gql.service';
import { GenericOperator } from '@shared/services/operator.service';
import { BehaviorSubject, combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '@models/trial-insights/trial-insights.query';
import {
  TrialInsightsPaymentMilestoneResponseType,
  TrialInsightsState,
} from '@models/trial-insights/trial-insights-store.model';
import {
  TIRSChartLabel,
  TIRSChartType,
  TrialInsightsRemainingSpendChartService,
} from './remaining-spend-over-time-chart.service';
import {
  TrialInsightsRemainingSpendKey,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';
import { Utils } from '@shared/utils/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import { flatten, isEqual, uniq } from 'lodash-es';
import { TrialInsightsRemainingSpendTableService } from './remaining-spend-over-time-table.service';
import { RemainingSpendOverTimeConstants } from './remaining-spend-over-time.const';

type state = Partial<TrialInsightsState['remainingSpend']>;
type results = [state['data'], state['totalRemainingSpend'], TrialInsightsRemainingSpendKey];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsRemainingSpendKey.REMAINING_SPEND,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsRemainingSpendQueryService extends GenericTrialInsightsQuery<TIRSChartType> {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public chartService: TrialInsightsRemainingSpendChartService,
    public tableService: TrialInsightsRemainingSpendTableService
  ) {
    super({
      trialInsightsQuery,
      slice: 'remainingSpend',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processRemainingSpend$(): Observable<results> {
    return combineLatest([
      this.data as Observable<TrialInsightsPaymentMilestoneResponseType[]>,
      this.trialInsightsQuery.select((state) => state.remainingSpend.totalRemainingSpend),
      this.selectedKey as BehaviorSubject<TrialInsightsRemainingSpendKey>,
    ]).pipe(distinctUntilChanged(isEqual), this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const [data, totalRemainingSpend, selectedKey] = sourceValue;
      this.totalAmount.next(Utils.currencyFormatter(totalRemainingSpend || 0));

      this.renderTable(data || [], selectedKey);
      this.renderChart(data || []);

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  private renderChart(data: results[0]): void {
    const remainingList: TIRSChartLabel = [];
    const labels: string[][] = [];

    (data || []).forEach((item) => {
      labels.push(item.remaining_spend_time_series.map(({ period }) => period));
      remainingList.push(item.remaining_spend_time_series.map(({ amount }) => amount));
    });

    const datasets = this.chartService.createDatasets(remainingList);

    const sortedQuarters = uniq(flatten(labels))
      .sort((a, b) => Utils.dateSort(a, b, true))
      .map((date) => Utils.getQuarterDateString(date));

    const chartOptions = this.chartService.createChart(datasets, sortedQuarters);

    this.chartOptions.next(chartOptions);
  }

  private renderTable(data: results[0], selectedKey: TrialInsightsRemainingSpendKey): void {
    const tableData: TrialInsightsTableRowData[] = (data || []).map((item, index) => {
      return {
        buttonKey: TrialInsightsRemainingSpendKey.REMAINING_SPEND,
        leftSubheader: '',
        leftHeader: item.entity_name,
        rightSubheader: Utils.percentageFormatter(item.remaining_spend.percentage || 0),
        rightHeader: Utils.currencyFormatter(item.remaining_spend.amount),
        color:
          RemainingSpendOverTimeConstants.chartLineColor[index] ||
          RemainingSpendOverTimeConstants.chartLineColor[0],
      };
    });

    const tableOptions = this.tableService.createTable(selectedKey, tableData);
    this.tableOptions.next(tableOptions);
  }
}
