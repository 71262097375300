import { Component, computed, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FormControlConstants } from '@shared/constants/form-control.constants';

@Component({
  selector: 'aux-input-error-message',
  standalone: true,
  template: `
    @if (errorMessage()) {
      <div class="text-aux-error pl-3 text-sm mt-0.5">
        <div>{{ errorMessage() }}</div>
      </div>
    }
  `,
})
export class InputErrorMessageComponent {
  errors = input<ValidationErrors | null>();

  errorMessage = computed(() => {
    const errors = this.errors();

    if (!errors) {
      return '';
    }

    const arrayOfErrors = Object.entries(errors);

    const [lastErrorKey, lastErrorValue] = arrayOfErrors[arrayOfErrors.length - 1];

    if (!lastErrorValue) return;

    return (
      FormControlConstants.FORM_ERRORS[
        lastErrorKey as keyof typeof FormControlConstants.FORM_ERRORS
      ] ?? ''
    );
  });
}
