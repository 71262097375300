import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuxQuickSightDashboardType, GqlService } from '@shared/services/gql.service';

@Component({
  selector: 'aux-quicksight-dashboard',
  templateUrl: './quicksight-dashboard.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSightDashboardComponent implements OnInit {
  constructor(private gqlService: GqlService) {}

  ngOnInit() {
    this.getDashboardURL();
  }

  getDashboardURL() {
    this.gqlService
      .generateEmbeddedQuickSightURL$(AuxQuickSightDashboardType.PORTFOLIO)
      .pipe(take(1))
      .subscribe(({ data }) => this.getDashboard(data?.url || ''));
  }

  async getDashboard(embeddedURL: string) {
    const containerDiv = document.getElementById('dashboardContainer') || '';
    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: '850px',
      width: '100%',
      resizeHeightOnSizeChangedEvent: true,
    };
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
    embeddingContext.embedDashboard(frameOptions);
  }
}
