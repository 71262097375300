import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { DocumentLibraryService } from '../documents/document-library.service';
import { Option } from '@shared/types/components.type';
import { Document, DocumentType } from '@shared/services/gql.service';
import {
  convertLocalDateToIsoTimestamp,
  incrementDateValueByOneDay,
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
} from '@shared/utils/server-side-datasource';
import {
  ColDef,
  ColGroupDef,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { TableConstants } from '@shared/constants/table.constants';
import { Utils } from '@shared/utils/utils';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { DatasourceService } from '@shared/services/datasource.service';
import { AuxExcelStyleKeys, AuxExcelStyles } from '@shared/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-data-streaming-demo-1',
  templateUrl: './data-streaming-demo-1.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataStreamingDemo1Component implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  readonly datasource = this.datasourceService.documentDatasource;

  serverSideFilters: ServerSideFilterInfo<Document>[] = [
    {
      targetPropertyName: 'search_text',
      inputPropertyName: 'search',
      transformFunction: (v: unknown) => (typeof v === 'string' ? v.trim() : v),
    },
    {
      column: 'document_type_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'documentTypes',
    },
    {
      column: 'vendor_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'vendors',
    },
    {
      column: 'site_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'sites',
    },
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'dateFrom',
      transformFunction: (v: unknown) => convertLocalDateToIsoTimestamp(v),
    },
    {
      column: 'create_date',
      type: ServerSideColumnFilterType.IsLessThan,
      inputPropertyName: 'dateTo',
      transformFunction: (v: unknown) =>
        convertLocalDateToIsoTimestamp(incrementDateValueByOneDay(v)),
    },
  ];

  gridFiltersFormGroup = this.untypedFormBuilder.group(
    this.serverSideFilters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.inputPropertyName]: [],
      }),
      {}
    )
  );

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<Array<ServerSideSortOrder<Document>>>([]);

  gridOptions$ = new BehaviorSubject<GridOptions>({});

  exportGridOptions$ = new BehaviorSubject<GridOptions>({});

  exportGridApi!: GridApi;

  exporting$ = new BehaviorSubject<boolean>(false);

  overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;

  documentTypeFilterOptions: Option<DocumentType>[] = [];

  gridApi!: GridApi;

  get vendorFilterOptions(): Option[] {
    return this.documentLibraryService.vendors;
  }

  get siteFilterOptions(): Option[] {
    return this.documentLibraryService.sites;
  }

  constructor(
    private authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private documentLibraryService: DocumentLibraryService,
    private datasourceService: DatasourceService,
    private untypedFormBuilder: UntypedFormBuilder
  ) {
    this.datasource.refresh$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.refreshGridData();
    });

    this.datasource.aggregation$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.refreshPinnedBottomRow();
    });

    const formWatcherInfo = this.datasourceService.applyTransformations(
      this.gridFiltersFormGroup,
      this.serverSideFilters
    );
    this.filterValues$.next(formWatcherInfo.currentValue);
    formWatcherInfo.valueChangesObservable.pipe(takeUntilDestroyed()).subscribe((v) => {
      this.filterValues$.next(v);
    });

    this.mainQuery
      .select('trialKey')
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => {
          this.documentTypeFilterOptions = this.documentLibraryService.getDocumentOptions();
          this.gridFiltersFormGroup.get('vendors')?.reset();
          this.gridFiltersFormGroup.get('sites')?.reset();
          return this.documentLibraryService.getRequiredDictionaries();
        })
      )
      .subscribe();
  }

  private getBaseGridOptions(forExport: boolean): GridOptions {
    const columnDefs: (ColDef | ColGroupDef)[] = [];

    if (forExport) {
      columnDefs.push({
        headerName: 'Document Short Name',
        headerClass: 'ag-header-align-center font-bold',
        field: 'description',
        cellClass: 'text-left ag-cell-align-center',
        tooltipField: 'description',
      });
    }

    if (this.authQuery.getValue().is_admin) {
      columnDefs.push({
        headerName: 'File Name',
        headerClass: 'ag-header-align-center font-bold',
        field: 'name',
        cellClass: 'text-left ag-cell-align-center',
        tooltipField: 'name',
      });
    }

    columnDefs.push({
      headerName: 'Document Type',
      headerClass: 'ag-header-align-center font-bold',
      field: 'document_type_id',
      cellClass: 'text-left ag-cell-align-center',
      valueFormatter: (params) =>
        Utils.getOptionLabel(this.documentTypeFilterOptions, params.value),
    });

    columnDefs.push({
      headerName: 'Vendor',
      headerClass: 'ag-header-align-center font-bold',
      field: 'vendor_id',
      cellClass: 'text-left ag-cell-align-center',
      valueFormatter: (params) =>
        Utils.getOptionLabel(this.documentLibraryService.vendors, params.value),
    });

    columnDefs.push({
      headerName: 'Site',
      headerClass: 'ag-header-align-center font-bold',
      field: 'site_id',
      cellClass: 'text-left ag-cell-align-center',
      valueFormatter: (params) =>
        Utils.getOptionLabel(this.documentLibraryService.sites, params.value),
    });

    columnDefs.push({
      headerName: 'Date Uploaded',
      headerClass: 'ag-header-align-center font-bold',
      field: 'create_date',
      cellClass: 'text-right ag-cell-align-center',
      maxWidth: 180,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleString() : Utils.zeroHyphen,
    });

    columnDefs.push({
      headerName: 'Uploaded by',
      headerClass: 'ag-header-align-center font-bold',
      field: 'created_by',
      cellClass: 'text-left ag-cell-align-center',
      valueFormatter: (params) => {
        const user = this.documentLibraryService.users?.find((u) => u.sub === params.value);
        return Utils.agUserFormatter(user, this.authQuery.isAuxAdmin());
      },
    });

    return {
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      rowHeight: 45,
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        cellClass: TableConstants.STYLE_CLASSES.CELL_VERTICAL_HORIZONTAL_ALIGN_CENTER,
        resizable: true,
      },
      columnDefs,
    } as GridOptions;
  }

  ngOnInit() {
    this.gridOptions$.next({
      ...this.getBaseGridOptions(false),
      rowModelType: 'infinite',
      maxConcurrentDatasourceRequests: 1,
      pagination: true,
      paginationPageSize: 20,
      suppressPaginationPanel: true,
      onModelUpdated: (event) => {
        if (event.api.getDisplayedRowCount() === 0) {
          event.api.showNoRowsOverlay();
        } else {
          event.api.hideOverlay();
        }
      },
    });

    this.exportGridOptions$.next({
      ...this.getBaseGridOptions(true),
      excelStyles: [
        // todo fix
        ...AuxExcelStyles.filter((s) =>
          (
            [
              AuxExcelStyleKeys.HEADER,
              AuxExcelStyleKeys.FIRST_ROW,
              AuxExcelStyleKeys.CELL_RIGHT,
            ] as string[]
          ).includes(s.id)
        ),
        {
          id: 'cell',
          alignment: { horizontal: 'Left' },
          font: { fontName: 'Arial', size: 11 },
        },
      ],
      onGridReady: (event: GridReadyEvent) => {
        this.exportGridApi = event.api;
      },
    });
  }

  refreshGridData() {
    this.gridApi.setGridOption('serverSideDatasource', this.datasource);
  }

  refreshPinnedBottomRow() {
    const pinnedBottomRowData: Array<unknown> = [];
    if (this.datasource.currentServerInput) {
      if (this.datasource.totalRows) {
        pinnedBottomRowData.push(this.datasource.aggregation);
      }
    }

    this.gridApi.setGridOption('pinnedBottomRowData', pinnedBottomRowData);
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridApi = api;
    this.onSortChanged();

    this.datasource.initialize({
      untilDestroyedPipeOperator: takeUntilDestroyed(this.destroyRef),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
    });

    this.gridApi.sizeColumnsToFit();
  }

  onSortChanged() {
    const currentSortModel = this.datasourceService.getCurrentSortModel<Document>(this.gridApi);
    this.sortModel$.next(currentSortModel);
  }

  onResetForm() {
    this.gridFiltersFormGroup.reset();
  }

  getDynamicExcelParams = async (): Promise<ExcelExportParams> => {
    this.exporting$.next(true);

    const data = await this.datasource.downloadAll();

    this.exportGridApi.getColumns()?.forEach((col) => col.setSort(null));

    this.exportGridApi.setGridOption('rowData', data.items);

    const columnOptionsLookup = (<ColDef[]>[
      {
        colId: 'description',
        width: 180,
      },
      {
        colId: 'name',
        width: 300,
      },
      {
        colId: 'document_type_id',
        valueFormatter: (v: string) => Utils.getOptionLabel(this.documentTypeFilterOptions, v),
      },
      {
        colId: 'vendor_id',
        valueFormatter: (v: string) => Utils.getOptionLabel(this.documentLibraryService.vendors, v),
      },
      {
        colId: 'site_id',
        valueFormatter: (v: string) => Utils.getOptionLabel(this.documentLibraryService.sites, v),
      },
      {
        colId: 'create_date',
        width: 120,
        valueFormatter: (v: string) => (v ? new Date(v).toLocaleString() : Utils.zeroHyphen),
      },
      {
        colId: 'created_by',
        valueFormatter: (v: string) => {
          const user = this.documentLibraryService.users?.find((u) => u.sub === v);
          return Utils.agUserFormatter(user, this.authQuery.isAuxAdmin());
        },
      },
    ]).reduce(
      (acc, curr) => ({ ...acc, [curr.colId as string]: curr }),
      {} as Record<string, ColDef>
    );

    return {
      author: 'Auxilius',
      fontSize: 11,
      sheetName: 'Document Library',
      fileName: 'auxilius-document-library.xlsx',
      columnWidth(params) {
        const columnOptions = columnOptionsLookup[params.column?.getColId() || ''];
        if (Number.isFinite(columnOptions?.width)) {
          return columnOptions.width;
        }
        return 225;
      },
      processCellCallback: (params): string => {
        const columnOptions = columnOptionsLookup[params.column?.getColId() || ''];
        if (typeof columnOptions?.valueFormatter === 'function') {
          return columnOptions.valueFormatter(params.value);
        }
        return params.value;
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${this.mainQuery.getSelectedTrial()?.short_name || ''}`,
                type: 'String',
              },
              mergeAcross: 6,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: {
                value: `User: ${this.authQuery.getValue()?.email || ''}`,
                type: 'String',
              },
              mergeAcross: 6,
              styleId: 'cell-right',
            },
          ],
        },
      ],
      //
    } as ExcelExportParams;
  };

  onExportSuccess = (): void => {
    this.exportGridApi.setGridOption('rowData', []);
    this.exporting$.next(false);
  };
}
