import { Component, Input, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ForecastTableGridMethodChange } from '../../models/forecast-table-grid.model';
import { EditableListDropdownItem } from '@shared/components/editable-list-dropdown/editable-list-dropdown-item.model';

@Component({
  selector: 'aux-forecast-table-grid-method-patient',
  templateUrl: './forecast-table-grid-method-patient.component.html',
  styleUrls: ['./forecast-table-grid-method-patient.component.scss'],
})
export class ForecastTableGridMethodPatientComponent {
  @ViewChild('gridMethodPatient') public input!: NgSelectComponent;

  @Input() categoryId = '';

  @Input() primarySettingsId = '';

  @Input() isChild = false;

  @Input() placeholder = '';

  @Input() tooltip = '';

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() onMethodChange!: ForecastTableGridMethodChange;

  @Input() patientCurves: EditableListDropdownItem[] = [];

  @Input() selectedPatientCurve = '';

  dropdownOpen = false;

  select(event: string): void {
    this.onMethodChange(event, this.primarySettingsId, {
      name: !this.isChild ? 'category' : 'activity',
      prop: 'driver_setting',
      id: this.categoryId,
    });
  }

  onDropdownOpen() {
    this.dropdownOpen = true;
  }

  onDropdownClose() {
    this.dropdownOpen = false;
  }
}
