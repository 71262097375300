import { Injectable } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { flattenDeep, min } from 'lodash-es';
import { RemainingSpendOverTimeConstants } from './remaining-spend-over-time.const';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';
import { ChartDataset, ElementOptionsByType, TooltipLabelStyle } from 'chart.js/dist/types';
import {
  ChartConfiguration,
  ChartOptions,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';

export type TIRSChartLabel = number[][];
export type TIRSChartType = 'line';

@Injectable()
export class TrialInsightsRemainingSpendChartService
  implements TrialInsightsChartModel<TIRSChartType, TIRSChartLabel>
{
  minYAxes = 0;

  createDatasets = (remainingSpends: TIRSChartLabel): ChartDataset<TIRSChartType>[] => {
    const flattenSpends = flattenDeep(remainingSpends);
    this.minYAxes = min(flattenSpends) || 0;

    const chartLineColor = RemainingSpendOverTimeConstants.chartLineColor;

    return remainingSpends.map((spends, id) => {
      return <ChartDataset<TIRSChartType>>{
        id,
        xAxisID: `x${id + 1}`,
        data: spends,
        backgroundColor: 'white',
        borderColor: chartLineColor[id],
        hoverBackgroundColor: chartLineColor[id],
        borderWidth: 5,
        pointBackgroundColor: chartLineColor[id],
        pointBorderColor: chartLineColor[id],
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: chartLineColor[id],
        pointHoverBorderColor: chartLineColor[id],
      };
    });
  };

  createChart = (
    datasets?: ChartDataset<TIRSChartType>[],
    labels?: string[]
  ): ChartConfiguration<TIRSChartType> => {
    return {
      type: 'line',
      options: this.chartOptions(),
      data: {
        labels: labels || [],
        datasets: datasets || [],
      },
    };
  };

  chartOptions = (): ChartOptions<TIRSChartType> => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      interaction: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        ...this.xAxesOptions().scales,
        ...this.yAxesOptions().scales,
      },
      plugins: this.pluginOptions(),
    };
  };

  tooltipOptions = (): DeepPartial<TooltipOptions<TIRSChartType>> => {
    return {
      bodySpacing: 6,
      boxPadding: 6,
      callbacks: {
        labelColor(tooltipItem): TooltipLabelStyle | void {
          return {
            backgroundColor: tooltipItem.dataset.borderColor as string,
            borderColor: tooltipItem.dataset.borderColor as string,
          };
        },
        label: (tooltipItem) => {
          if (!tooltipItem.raw) {
            return '';
          }

          return Utils.currencyFormatter(<number>tooltipItem.raw);
        },
      },
    };
  };

  pluginOptions = (): DeepPartial<PluginOptionsByType<TIRSChartType>> => {
    return {
      tooltip: this.tooltipOptions(),
      legend: {
        display: false,
      },
    };
  };

  elementOptions = (): DeepPartial<ElementOptionsByType<TIRSChartType>> => {
    return {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    };
  };

  xAxesOptions = (): DeepPartial<ScaleChartOptions<TIRSChartType>> => {
    return {
      scales: {
        x1: {
          beginAtZero: true,
          title: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: true,
          },
        },
        x2: {
          display: false,
        },
        x3: {
          display: false,
        },
        x4: {
          display: false,
        },
        x5: {
          display: false,
        },
      },
    };
  };

  yAxesOptions = (): DeepPartial<ScaleChartOptions<TIRSChartType>> => {
    return {
      scales: {
        y: {
          display: true,
          position: 'right',
          beginAtZero: false,
          min: this.minYAxes,
          border: {
            display: false,
          },
          ticks: {
            display: true,
            callback: (value) => {
              if (value === 0 && this.minYAxes !== 0) {
                return;
              }

              return value ? Utils.currencyFormatter(value as number) : `${value}$`;
            },
          },
        },
      },
    };
  };
}
