import {
  Component,
  ChangeDetectionStrategy,
  DestroyRef,
  WritableSignal,
  inject,
  Injector,
} from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { AgCellWrapperComponent } from '@shared/ag-components/ag-cell-wrapper/ag-cell-wrapper.component';
import { WorkflowModel } from '@shared/store/workflow/workflow.store';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

export type AgQuarterCloseAdjustmentParams = ICellRendererParams & {
  current_month: boolean;
  adjustmentWorkflow$: Observable<WorkflowModel>;
  isWorkflowAvailable$: Observable<boolean>;
  userHasAdjustPermission$: Observable<boolean>;
  onClicked: (params: ICellRendererParams) => void;
  inMonthTabIsAvailable: WritableSignal<boolean>;
};

@Component({
  template: `
    <div
      class="flex items-center w-full px-[10px]"
      *ngIf="{ disabled: disabled$ | async } as obj2"
      (click)="onClick()"
    >
      <div class="grow"></div>
      <div [attr.auto-qa]="autoTestAttribute">{{ params.valueFormatted }}</div>
      <div
        class="flex ml-1.5"
        *ngIf="!params.node.rowPinned"
        [ngClass]="{
          'cursor-pointer text-aux-blue': !obj2.disabled,
          'cursor-not-allowed text-aux-gray': obj2.disabled
        }"
      >
        <ng-template #edit>
          <span
            data-id="edit-icon-inmonth-adjustment"
            *ngIf="params.current_month"
            [auxTooltip]="
              obj2.disabled && params.inMonthTabIsAvailable() ? discountDisabledMessage : ''
            "
          >
            <aux-icon name="Pencil" />
          </span>
        </ng-template>
        <ng-template #eye>
          <aux-icon name="Eye" />
        </ng-template>
        <ng-container
          *ngIf="{
            isQuarterCloseEnabled: params.isWorkflowAvailable$ | async,
            adjustmentWorkflow: params.adjustmentWorkflow$ | async,
            userHasAdjustPermission: params.userHasAdjustPermission$ | async
          } as obj"
        >
          <ng-container *ngIf="obj.adjustmentWorkflow && obj.isQuarterCloseEnabled">
            <ng-container
              [ngTemplateOutlet]="edit"
              *ngIf="!obj.adjustmentWorkflow.properties.locked && obj.userHasAdjustPermission"
            />
            <ng-container
              [ngTemplateOutlet]="eye"
              *ngIf="
                !params.current_month ||
                obj.adjustmentWorkflow.properties.locked ||
                !obj.userHasAdjustPermission
              "
            />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(params.isWorkflowAvailable$ | async) === false">
          <ng-container [ngTemplateOutlet]="edit" />
          <ng-container [ngTemplateOutlet]="eye" *ngIf="!params.current_month" />
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgQuarterCloseAdjustmentComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  private readonly destroyRef = inject(DestroyRef);

  params!: AgQuarterCloseAdjustmentParams;

  private injector = inject(Injector);

  disabled$ = new BehaviorSubject(false);

  discountDisabledMessage = 'Manual adjustments for Discount can be made in Period Close Checklist';

  agInit(params: AgQuarterCloseAdjustmentParams): void {
    this.params = params;

    combineLatest([
      params.userHasAdjustPermission$,
      params.adjustmentWorkflow$,
      params.inMonthTabIsAvailable
        ? toObservable(params.inMonthTabIsAvailable, { injector: this.injector })
        : EMPTY,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasPermission, w, inMonthTabIsAvailable]) => {
        const disabled =
          (this.params.current_month &&
            this.params.data?.cost_category === 'Discount' &&
            w?.properties?.locked === false &&
            userHasPermission) ||
          !inMonthTabIsAvailable;
        this.disabled$.next(disabled);
      });
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  onClick() {
    if (this.disabled$.getValue()) {
      return;
    }
    this.params.onClicked(this.params);
  }
}
