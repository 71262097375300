import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { GridReadyEvent, GridApi, GetRowIdFunc, IRowNode } from '@ag-grid-community/core';
import { distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';
import {
  EventType,
  InvestigatorTransaction,
  PermissionType,
  WorkflowStep,
  batchCreateInvestigatorTransactionsMutation,
  GqlService,
  InvestigatorTransactionSourceType,
  EntityType,
} from '@shared/services/gql.service';
import { EventService } from '@models/event/event.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import {
  CATEGORY_OPTIONS,
  getInvestigatorTransactionCols,
} from './constants/investigator-transactions-grid-cols';
import { InvestigatorTransactionsService } from './services/investigator-transactions.service';
import { ImportPatientDataModalComponent } from './components/import-patient-data-modal/import-patient-data-modal.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { AuthService } from '@shared/store/auth/auth.service';
import { EventQuery } from '@models/event/event.query';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AgSetColumnsVisible,
  ServerSideColumnFilterType,
  ServerSideFilterInfo,
  ServerSideSortOrder,
} from '@shared/utils';
import { DatasourceService } from '@shared/services/datasource.service';
import { FormBuilder } from '@angular/forms';
import { isEqual } from 'lodash';
import { FormArray, FormGroup } from '@angular/forms';
import { File } from '@shared/components/file-manager/state/file.model';
import { InvestigatorTransactionTableFormService } from './services/investigator-transactions-table-form.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { RemoveTransactionModalComponent } from './components/remove-transaction-modal/remove-transaction-modal.components';
import { MainQuery } from '@shared/store/main/main.query';
import { ExportType } from '@shared/utils/utils';
import { InvestigatorTransactionsSaveModalComponent } from './components/investigator-transactions-save-modal/investigator-transactions-save-modal.component';
import { AuxButtonGroup } from '@shared/components/button-group/button-group.component';
import { BulkUploadModalComponent } from './components/bulk-upload-modal/bulk-upload-modal.component';
import {
  TransactionMode,
  InvestigatorTransactionsDataRow,
} from './models/investigator-transaction-form.model';
import { DataStreamUtils } from '@shared/utils/datastream.utils';
import { fetchInProgressEvents } from '@features/progress-tracker/utils/fetch-in-progress-events';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SitesQuery } from '@models/sites/sites.query';

@Component({
  selector: 'aux-investigator-transactions',
  templateUrl: './investigator-transactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorTransactionsComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  readonly workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  workflowQuery = inject(WorkflowQuery);

  siteQuery = inject(SitesQuery);

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  showSourceColumn$ = this.launchDarklyService.select$(
    (flags) => flags.investigator_transactions_source_column
  );

  showSiteBudgetVersionColumn$ = this.launchDarklyService.select$(
    (flags) => flags.site_budget_version_column
  );

  protocolVersionOptions$ = this.investigatorTransactionsService.protocolVersionOptions$;

  siteOptions = this.siteQuery.selectSiteOptions();

  patientGroupOptions$ = this.investigatorTransactionsService.patientGroupOptions$;

  editMode$ = new BehaviorSubject<TransactionMode>(null);

  addTransactionDisabled$ = new BehaviorSubject(false);

  transactionForm = new FormGroup({
    table: new FormArray([]),
  });

  isCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent$(EventType.CLOSE_TRIAL_MONTH);

  savedTransactionIds$ = new BehaviorSubject<string[]>([]);

  gridAPI!: GridApi;

  private tableFormInstance!: InvestigatorTransactionTableFormService;

  isSiteColumnCollapsed$ = new BehaviorSubject(true);

  isContractSelected$ = new BehaviorSubject(false);

  submitInProgress = signal(false);

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  totalRecords$ = new BehaviorSubject(0);

  showBottomTotals$ = new BehaviorSubject(false);

  sysAdminOnly = this.authService.$isAuthorized({
    sysAdminsOnly: true,
  });

  actionGroupButtons = computed<AuxButtonGroup[]>(() => [
    {
      name: 'Single Transaction',
      onClick: () => this.addTransaction(),
      disabledTooltip: this.updateTransactionTooltip(),
      disabled: !this.updateTransactionPermission(),
    },
    {
      name: 'Bulk Transactions',
      onClick: () => this.openBulkTransactionModal(),
      disabled: !this.updateTransactionPermission(),
      disabledTooltip: this.updateTransactionTooltip(),
    },
    {
      name: 'Import EDC Data',
      onClick: () => this.openImportPatientDataModal(),
      disabled: !this.sysAdminOnly(),
      disabledTooltip: !this.sysAdminOnly()
        ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
        : '',
      show: this.launchDarklyService.$flags().import_edc_data,
    },
  ]);

  serverSideFilters: ServerSideFilterInfo<InvestigatorTransaction>[] = [
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'activity_date',
      type: ServerSideColumnFilterType.IsGreaterThanOrEqualTo,
      inputPropertyName: 'start_activity_date',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'activity_date',
      type: ServerSideColumnFilterType.IsLessThanOrEqualTo,
      inputPropertyName: 'end_activity_date',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'site_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'site_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_group_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_group_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_protocol_version_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_version_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_id',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_ids',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'description',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'descriptions',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'country',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'countries',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'patient_protocol_sub_type',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_sub_types',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'source',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'sources',
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: 'description',
      type: ServerSideColumnFilterType.OneOf,
      inputPropertyName: 'patient_protocol_names',
    },
  ];

  gridFiltersFormGroup = this.formBuilder.group(
    this.serverSideFilters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.inputPropertyName]: [null],
      }),
      {}
    )
  );

  addButtonGroupOverlayPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  isPatientsFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  patientsFinalizedText = computed(() =>
    this.isPatientsFinalized() ? MessagesConstants.PATIENT_TRACKER_CLOSED : ''
  );

  updateTransactionTooltip = computed(() =>
    !this.updateTransactionPermission() ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION : ''
  );

  updateTransactionPermission = this.authService.$isAuthorized({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_ADD_TRANSACTION],
  });

  workflow = this.workflowQuery.getWorkflowByStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER
  );

  visitCostsEnabled$ = new BehaviorSubject(false);

  sortModel$ = new BehaviorSubject<ServerSideSortOrder<InvestigatorTransaction>[]>([]);

  gridOptions$ = combineLatest([
    this.isContractSelected$,
    this.launchDarklyService.select$((flags) => flags.investigator_transactions_edc_created_date),
    this.showSourceColumn$,
    this.showSiteBudgetVersionColumn$,
    this.savedTransactionIds$,
  ]).pipe(
    map(([isSelected, isEDCColumnBeShown, showSourceColumn, showSiteBudgetVersionColumn]) => {
      return getInvestigatorTransactionCols({
        isContractCurrency: isSelected,
        isEDCColumnBeShown,
        showSourceColumn,
        showSiteBudgetVersionColumn,
        isSiteColumnCollapsed$: this.isSiteColumnCollapsed$,
        protocolVersionOptions$: this.protocolVersionOptions$,
        siteOptions: this.siteOptions,
        patientGroups$: this.patientGroupOptions$,
        updateSiteFields: (rowId: string, id?: string) => {
          this.tableFormInstance.updateSiteFields(
            rowId,
            id || '',
            this.investigatorTransactionsService.siteMap,
            this.investigatorTransactionsService.getSiteAddress.bind(
              this.investigatorTransactionsService
            ),
            this.investigatorTransactionsService.getPrincipalInvestigatorName.bind(
              this.investigatorTransactionsService
            )
          );
        },
        updatePatientIdField: (rowId: string, category?: string) =>
          this.tableFormInstance.updateValidationByCategory(rowId, category || ''),
        editRow: (rowId: string) => {
          this.toggleEditMode('edit');

          this.tableFormInstance.editRow(rowId, {
            protocolVersionOptions: this.protocolVersionOptions$.getValue(),
            patientGroups: this.patientGroupOptions$.getValue(),
            siteOptions: this.siteOptions(),
            categoryOptions: CATEGORY_OPTIONS,
          });
        },
        removeRow: this.removeRow,
        savedTransactionIds$: this.savedTransactionIds$,
        transactionMode$: this.editMode$,
        isPatientsFinalized: this.isPatientsFinalized,
        updateTransactionPermission: this.updateTransactionPermission,
      });
    })
  );

  filterLoading$ = new BehaviorSubject(true);

  userHasLockPatientDataPermission = false;

  readonly datasource = this.datasourceService.transactionDatasource;

  totalCost$ = new BehaviorSubject(0);

  lastSourceRefreshDate$ = new BehaviorSubject('');

  getRowId?: GetRowIdFunc = undefined;

  bulkUploadProcessing = this.mainQuery.$selectProcessingEvent(
    EventType.MANUAL_INVESTIGATOR_TRANSACTIONS_TEMPLATE_UPLOADED
  );

  bulkProcessingMessage =
    'Bulk Manual Investigator Transactions are processing. This may take a minute. You can navigate away from this page. You will be notified when complete.';

  totalRecords = signal(0);

  showExportFull = signal(false);

  progressTrackerId = fetchInProgressEvents(
    EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED,
    this.destroyRef
  );

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private investigatorTransactionsService: InvestigatorTransactionsService,
    private overlayService: OverlayService,
    private eventService: EventService,
    private authQuery: AuthQuery,
    private launchDarklyService: LaunchDarklyService,
    private authService: AuthService,
    private eventQuery: EventQuery,
    private router: Router,
    private route: ActivatedRoute,
    private datasourceService: DatasourceService,
    private formBuilder: FormBuilder
  ) {
    this.setUserPermissions();

    effect(() => {
      this.isPatientsFinalized();
      this.updateTransactionPermission();
      const manualTransactionRows: IRowNode[] = [];

      this.gridAPI?.forEachNode((node) => {
        if (
          node?.data?.source_type ===
          InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_MANUAL
        ) {
          manualTransactionRows.push(node);
        }
      });

      this.gridAPI?.redrawRows({
        rowNodes: manualTransactionRows,
      });
    });

    this.savedTransactionIds$
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(isEqual))
      .subscribe((savedTransactionIds) => {
        this.datasource.updateOptions({
          overrideFilter: {
            sort_to_top_ids: savedTransactionIds,
          },
        });
      });

    this.getRowId = (params) => params.data.id;
  }

  ngOnInit() {
    this.fetchFilters();

    this.launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => {
        this.visitCostsEnabled$.next(enabled);

        if (this.tableFormInstance) {
          this.tableFormInstance.refreshAllFormRows();
        }
      });

    [
      EventType.UPDATE_INVESTIGATOR_TRANSACTIONS,
      EventType.MANUAL_INVESTIGATOR_TRANSACTIONS_TEMPLATE_UPLOADED,
    ].forEach((event) => {
      this.eventService
        .select$(event)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.datasource.forceRefresh();
        });
    });

    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      const startDate = params['startDate'] || '';
      const endDate = params['endDate'] || '';

      if (startDate || endDate) {
        this.investigatorTransactionsService.addInMonthAdjustmentsFilters(startDate, endDate, '');
        this.router.navigate([], { queryParams: {}, replaceUrl: true });
      }
    });
  }

  onTrackerIdChanged(trackerId: string) {
    this.progressTrackerId.set(trackerId);
    if (!trackerId) {
      this.datasource.forceRefresh();
    }
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.datasource.initialize({
      untilDestroyedPipeOperator: takeUntilDestroyed(this.destroyRef),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
      parseFunction: (items) =>
        this.investigatorTransactionsService.getPatientTransactionsGridData(items),
    });

    // Default sorting
    this.gridAPI.applyColumnState({
      state: [{ colId: 'activity_date', sort: 'desc', sortIndex: 1 }],
    });

    this.datasource.loading$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((value) => !value),
        skip(1) // Skip initial loading state
      )
      .subscribe(() => {
        this.tableFormInstance.reinitializeTableRows();
      });

    this.datasource.refresh$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(
        switchMap(() =>
          this.datasourceService.retrieveTransactionAnalytics({
            filter_model: this.datasource.currentServerInput?.filter_model || {},
          })
        )
      )
      .subscribe(({ aggregation, total_rows }) => {
        this.totalCost$.next(aggregation?.total_cost || 0);
        this.totalRecords.set(isNaN(Number(total_rows)) ? 0 : Number(total_rows));
        this.showExportFull.set(DataStreamUtils.showExportFullDataset(Number(total_rows)));

        this.lastSourceRefreshDate$.next(aggregation?.latest_source_date || '');
      });

    this.tableFormInstance = new InvestigatorTransactionTableFormService(
      this.transactionForm as FormGroup,
      api,
      this.visitCostsEnabled$
    );

    combineLatest([
      this.launchDarklyService.select$((flags) => flags.investigator_transactions_site_address),
      this.isSiteColumnCollapsed$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isSiteAddressEnabled, isSiteColumnCollapsed]) => {
        if (!isSiteColumnCollapsed) {
          AgSetColumnsVisible({
            gridApi: this.gridAPI,
            keys: ['site_address'],
            visible: isSiteAddressEnabled,
          });
          this.gridAPI.sizeColumnsToFit();
        }
      });
  }

  private toggleLoaders(isLoading: boolean) {
    this.filterLoading$.next(isLoading);
  }

  private openImportPatientDataModal() {
    this.overlayService.openPopup<
      { onSuccess: (id: string) => void },
      unknown,
      ImportPatientDataModalComponent
    >({
      content: ImportPatientDataModalComponent,
      settings: {
        header: 'Upload Patient Data',
        primaryButton: {
          label: 'Upload',
          action: (instance) => instance?.onUpload(),
        },
      },
      data: {
        onSuccess: (id: string) => {
          this.progressTrackerId.set(id);
        },
      },
    });
  }

  private openBulkTransactionModal() {
    this.overlayService.openPopup<undefined, undefined, BulkUploadModalComponent>({
      content: BulkUploadModalComponent,
      settings: {
        header: 'Upload Bulk Transactions',
        primaryButton: {
          label: 'Upload',
          action: (instance) => instance?.uploadTemplate(),
          disabled: (instance) => !!instance?.uploadDisabled(),
        },
      },
    });
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([userHasLockPatientDataPermission]) => {
        this.userHasLockPatientDataPermission = userHasLockPatientDataPermission;
      });
  }

  toggleEditMode(mode: TransactionMode) {
    const prevEditMode = this.editMode$.getValue();

    this.editMode$.next(mode);

    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['total_cost'],
      visible: !mode,
    });

    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['exchange_rate', 'contract_curr'],
      visible: this.isContractSelected$.getValue() && !mode,
    });

    if (!this.isContractSelected$.getValue()) {
      AgSetColumnsVisible({
        gridApi: this.gridAPI,
        keys: ['contract_amount'],
        visible: !!mode,
      });
    }

    if (mode) {
      this.gridFiltersFormGroup.disable();
    }

    this.gridAPI.sizeColumnsToFit();

    if (this.editMode$.getValue() !== prevEditMode) {
      this.gridAPI.redrawRows();
    }
  }

  cancelChanges = (softRefresh = false) => {
    if (!softRefresh) {
      this.gridAPI?.refreshServerSide({ purge: true });
    }

    this.toggleEditMode(null);

    this.tableFormInstance.clearForm();

    this.gridFiltersFormGroup.enable();

    this.addTransactionDisabled$.next(false);
  };

  goToFistPage() {
    if (this.gridAPI.paginationGetCurrentPage() !== 0) {
      this.gridAPI?.paginationGoToFirstPage();
    }
  }

  addTransaction = () => {
    this.goToFistPage();
    this.toggleEditMode('create');

    const latestProtocolVersion = this.protocolVersionOptions$
      .getValue()
      .find(({ is_current }) => is_current);

    this.tableFormInstance.addEntity(latestProtocolVersion);

    this.checkAvailabilityAddNewEntity();
  };

  private async fetchFilters() {
    this.toggleLoaders(true);

    await firstValueFrom(this.investigatorTransactionsService.getTransactionFilters());

    this.toggleLoaders(false);
  }

  getGridContext = () => {
    return {
      formGroup: this.transactionForm,
    };
  };

  saveTransactions = async () => {
    this.tableFormInstance.markAllFieldsAsTouched();

    if (this.transactionForm.invalid) {
      const errorMessage = this.tableFormInstance.getTableErrorMessage();
      this.overlayService.error(errorMessage);
      return;
    }

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        undefined,
        { note: string; documents: File[] },
        InvestigatorTransactionsSaveModalComponent
      >({
        content: InvestigatorTransactionsSaveModalComponent,
        settings: {
          header: 'Save Manual Transaction',
          primaryButton: {
            action: (instance) => instance?.save(),
            disabled: (instance) =>
              !!(instance?.noteFormControl.invalid || instance?.uploadingFiles()),
          },
        },
      }).afterClosed$
    );

    if (!resp?.data) return;

    this.submitInProgress.set(true);

    const allFormValues: InvestigatorTransactionsDataRow[] =
      this.transactionForm.getRawValue().table;

    const uploadedDocumentBucketPaths = resp.data.documents.map(({ bucket_key }) => bucket_key);

    const transactionsToUpdate = allFormValues.filter(({ mode }) => mode === 'edit');
    const transactionsToSave = allFormValues.filter(({ mode }) => mode === 'create');

    const updatesPromises: Promise<unknown>[] = [];

    if (transactionsToUpdate.length) {
      updatesPromises.push(
        this.investigatorTransactionsService.updateTransactions(
          transactionsToUpdate,
          resp.data.note,
          uploadedDocumentBucketPaths
        )
      );
    }

    if (transactionsToSave.length) {
      updatesPromises.push(
        this.investigatorTransactionsService.saveTransaction(
          transactionsToSave,
          resp.data.note,
          uploadedDocumentBucketPaths
        )
      );
    }

    const res = await Promise.allSettled(updatesPromises);

    this.storeSavedTransactions(res[updatesPromises.length - 1], transactionsToSave.length);
    this.fetchFilters();
    this.datasource.forceRefresh();

    if (this.editMode$.getValue() === 'create') {
      this.updateSortColumnsForNewTransactions();
    }

    this.cancelChanges(true);

    setTimeout(() => {
      this.submitInProgress.set(false);
      this.overlayService.success('Successfully saved!');
    }, 1000);
  };

  private updateSortColumnsForNewTransactions() {
    const appliedSortColumns = this.gridAPI
      .getColumnState()
      .filter(({ sort }) => !!sort)
      .map(({ colId }) => colId);

    this.gridAPI.applyColumnState({
      state: [
        ...appliedSortColumns.map((colId) => ({
          colId,
          sort: null,
        })),
        { colId: 'sort_to_top_ids', sort: 'desc', sortIndex: 1 },
        { colId: 'create_date', sort: 'desc', sortIndex: 2 },
      ],
    });
  }

  private storeSavedTransactions(
    savedTransactionsResponse: PromiseSettledResult<Awaited<unknown>>,
    transactionsToSaveCount: number
  ) {
    if (transactionsToSaveCount) {
      if (savedTransactionsResponse.status === 'fulfilled') {
        const ids = (
          savedTransactionsResponse.value as GraphqlResponse<
            batchCreateInvestigatorTransactionsMutation[]
          >
        ).data?.map(({ id }) => id);

        if (ids?.length) {
          this.savedTransactionIds$.next([...this.savedTransactionIds$.getValue(), ...ids]);
        }
      }
    }
  }

  checkAvailabilityAddNewEntity() {
    this.addTransactionDisabled$.next(
      this.tableFormInstance.getTableFormArray().length >= this.gridAPI.paginationGetPageSize()
    );
  }

  paginationChange() {
    this.checkAvailabilityAddNewEntity();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.transactionForm.controls.table.controls.length) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }
    return true;
  }

  private removeRow = async (rowId: string, createMode: boolean) => {
    const modal = this.overlayService.openPopup<
      { createMode: boolean },
      { note: string; documents: File[]; remove: true },
      RemoveTransactionModalComponent
    >({
      content: RemoveTransactionModalComponent,
      settings: {
        header: 'Delete row',
        primaryButton: {
          label: 'Delete',
          variant: 'negative',
          action: (instance) => instance?.deleteTransaction(),
          disabled: (instance) =>
            !!(!instance?.ref.data?.createMode && instance?.noteFormControl.invalid),
        },
      },
      data: { createMode },
    });

    const response = await firstValueFrom(modal.afterClosed$);

    if (createMode && response.data?.remove) {
      this.tableFormInstance.removeTableRow(rowId);
      return;
    }

    if (response.data) {
      const { success } = await this.investigatorTransactionsService.removeTransaction(
        rowId,
        response.data.note,
        response.data.documents.map(({ bucket_key }) => bucket_key)
      );

      if (success) {
        this.tableFormInstance.removeRow(rowId);
        this.datasource.forceRefresh();
        this.fetchFilters();
      }
    }
  };

  onExportInvestigatorTransactions = (trialName: string, dateStr: string) => {
    return firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVESTIGATOR_TRANSACTIONS,
          filename: `${trialName}_auxilius-investigator-transactions__${dateStr}`,
          sort_model: this.datasource.currentServerInput?.sort_model || [],
          filter_model: this.datasource.currentServerInput?.filter_model || [],
        }),
      })
    );
  };
}
