import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@shared/services/launch-darkly.service';
import { CanDeactivateGuard } from '@shared/guards/can-deactivate.guard';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { InvestigatorComponent } from './investigator.component';
import { InvestigatorDetailComponent } from './investigator-detail/investigator-detail.component';
import { SitesComponent } from './sites/sites.component';
import { PatientTrackerComponent } from './patient-tracker/patient-tracker.component';
import { InvestigatorSummaryComponent } from './investigator-summary/investigator-summary.component';
import { PatientProtocolComponent } from './patient-protocol';
import { InvestigatorTransactionsComponent } from './investigator-transactions/investigator-transactions.component';
import { PatientProtocolEditComponent } from './patient-protocol-edit/patient-protocol-edit.component';
import { PatientGroupsComponent } from '../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/patient-groups.component';
import { SiteInfoComponent } from './site/site-info/site-info.components';
import { SiteBudgetComponent, SiteComponentComponent } from './site';

const INVESTIGATOR_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_SUMMARY,
    component: InvestigatorSummaryComponent,
    data: {
      feature: FeatureFlag.INVESTIGATOR_SUMMARY,
    },
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS,
    component: InvestigatorTransactionsComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      feature: FeatureFlag.INVESTIGATOR_TRANSACTIONS,
    },
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER,
    component: PatientTrackerComponent,
    data: {
      feature: FeatureFlag.PATIENT_TRACKER,
    },
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET.INDEX,
    component: PatientProtocolComponent,
    data: {
      feature: FeatureFlag.PATIENT_BUDGET,
    },
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY,
    data: {
      feature: FeatureFlag.PROTOCOL_ENTRY,
    },
    component: PatientProtocolEditComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.PATIENT_GROUPS,
    component: PatientGroupsComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      feature: FeatureFlag.FORECAST_DRIVERS,
    },
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.SITES.INDEX,
    component: SitesComponent,
    data: {
      feature: FeatureFlag.INVESTIGATOR_SITES,
    },
  },
  {
    path: `${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}/:id`,
    component: SiteComponentComponent,
    children: [
      {
        path: ROUTING_PATH.INVESTIGATOR.SITES.INFO,
        component: SiteInfoComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ROUTING_PATH.INVESTIGATOR.SITES.SITE_BUDGET,
        component: SiteBudgetComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_DETAIL,
    component: InvestigatorDetailComponent,
    data: {
      feature: FeatureFlag.SITES_INVESTIGATOR_DETAIL,
    },
  },
];

export const buildInvestigatorRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    ...buildRoutesByFeatureFlags(featureFlags, INVESTIGATOR_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.INVESTIGATOR.INDEX,
    component: InvestigatorComponent,
    children: [
      ...enabledRoutes,
      {
        path: '**',
        redirectTo: (enabledRoutes.length && enabledRoutes[0].path) || '/',
      },
    ],
  };
};
