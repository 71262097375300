import { Router } from '@angular/router';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';

export type FeatureFlagTabs = { [key: string]: null | keyof Flags };

export abstract class BasicRedirectToNextTabGuard {
  protected prefix = '';

  protected abstract featureFlag: keyof Flags;

  protected abstract defaultNavigateUrl: string;

  protected abstract routerPathsAndFeatureFlags: FeatureFlagTabs;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const tabEnabled = this.launchDarklyService.$select(
      (flags) => flags[this.featureFlag]
    )() as boolean;

    if (tabEnabled) {
      return tabEnabled;
    }

    this.navigateToNextTab();

    return false;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }

  private navigateToNextTab(): void {
    const flags = Object.values(this.routerPathsAndFeatureFlags);
    const targetIndex = flags.indexOf(this.featureFlag);
    const nextTab = Object.entries(this.routerPathsAndFeatureFlags).find((element, index) => {
      const flag = element[1];
      if (index > targetIndex) {
        return flag ? this.launchDarklyService.$flags()[flag] : true;
      }
    });

    this.router.navigateByUrl(nextTab ? `${this.prefix}/${nextTab[0]}` : this.defaultNavigateUrl);
  }
}
