import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@shared/components/icon/icon.component';
import { VendorWorkflowDirective } from '@pages/vendor-payments-page/directives/vendor-workflow.directive';

type VendorGridActionParams = ICellRendererParams & {
  deleteClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  editClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
};

@Component({
  selector: 'aux-vendor-grid-action',
  template: `
    <div class="flex items-center justify-center space-x-2">
      @if (ctx.userHasVendorPermission()) {
        <button
          class="text-aux-blue p-0 w-8 h-8 flex justify-center items-center"
          type="button"
          (click)="onEditClick()"
        >
          <aux-icon name="Pencil" />
        </button>
      }
      <button
        class="flex justify-center items-center p-0 w-8 h-8"
        [ngClass]="{
          'opacity-50 cursor-not-allowed': ctx.disabledUI()
        }"
        type="button"
        [auxTooltip]="ctx.vendorActionTooltip()"
        [disabled]="ctx.disabledUI()"
        (click)="onDeleteClick()"
      >
        <aux-icon name="Trash" class="text-aux-error" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, NgClass, TooltipDirective, IconComponent, AsyncPipe],
  hostDirectives: [
    {
      directive: VendorWorkflowDirective,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorGridActionComponent implements ICellRendererAngularComp {
  params!: VendorGridActionParams;

  ctx = inject(VendorWorkflowDirective);

  refresh(): boolean {
    return false;
  }

  agInit(params: VendorGridActionParams): void {
    this.params = params;
  }

  onEditClick() {
    if (this.params.editClickFN) {
      this.params.editClickFN({ rowNode: this.params.node });
    }
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }
}
