import { MainQuery } from '@shared/store/main/main.query';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { cloneDeep, flatten } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NavigationMenuCost, NavigationMenuItem } from './constants/navigation-menu.const';
import { combineLatest } from 'rxjs';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { SharedModule } from '@shared/shared.module';
import { NavigationMenuService } from './services/navigation-menu.service';
import dayjs from 'dayjs';
import { BadgeComponent } from '@shared/components/badge/badge.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideVertical', [
      state(
        '*',
        style({
          height: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
        })
      ),
      transition('* => *', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')]),
    ]),
  ],
  standalone: true,
  providers: [NavigationMenuService],
  imports: [CommonModule, SharedModule, IconComponent, RouterLink, TooltipDirective],
})
export class NavigationMenuComponent {
  private launchDarklyService = inject(LaunchDarklyService);

  private activatedRoute = inject(ActivatedRoute);

  private router = inject(Router);

  private navigationMenuService = inject(NavigationMenuService);

  private cdr = inject(ChangeDetectorRef);

  private mainQuery = inject(MainQuery);

  routerConfig: NavigationMenuItem[] = NavigationMenuCost.ROUTER_CONFIG;

  numberOfChangeOrdersInPendingApproval$ = this.mainQuery.select('numberOfCOsInPendingApproval');

  private THIRD_LEVEL_ROUTE = 3;

  dataSource$ = combineLatest([
    this.launchDarklyService.flags$,
    this.numberOfChangeOrdersInPendingApproval$,
    this.mainQuery.select('currentOpenMonth'),
    this.mainQuery.select('trialEndDate'),
  ]).pipe(
    distinctUntilChanged(
      (
        [oldFlags, oldCount, oldOpenPeriod, oldEndDate],
        [newFlags, newCount, newOpenPeriod, newEndDate]
      ) => {
        const usedFlags = this.getRouteFeatureFlagList(this.routerConfig);

        return (
          flatten(usedFlags).every((flag) => oldFlags[flag] === newFlags[flag]) &&
          oldCount === newCount &&
          oldOpenPeriod === newOpenPeriod &&
          oldEndDate === newEndDate
        );
      }
    ),
    map(([flags, badgeCount, currentOpenMonth, trialEndDate]) => {
      const configWithFlags = this.filterRouterConfigByFlags(cloneDeep(this.routerConfig), flags);

      this.setCurrentPeriodToPeriodCloseRouter(configWithFlags, currentOpenMonth, trialEndDate);

      const changeOrderConfig = configWithFlags
        .find((x) => x.url === `/${ROUTING_PATH.BUDGET.INDEX}`)
        ?.subRoutes.find(
          (x) => x.url === `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`
        );
      if (changeOrderConfig && changeOrderConfig.badge) {
        changeOrderConfig.badge.amount.next(badgeCount);
      }
      return configWithFlags;
    })
  );

  expanded: Record<string, boolean> = {};

  constructor() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this.cdr.markForCheck();
      }
    });

    this.mainQuery
      .select('trialKey')
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.expanded = {};
        this.selectDefaultActiveRoute();
      });

    this.navigationMenuService
      .getNumberOfCOsInPendingApproval()
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  hasChild = (node: NavigationMenuItem): boolean => {
    return !!node.subRoutes && node.subRoutes.length > 0;
  };

  isDivider = (node: NavigationMenuItem): boolean => !!node.divider;

  isCollapsable = (node: NavigationMenuItem): boolean =>
    !node.singleRoute && node.subRoutes.length === 0 && !node.divider;

  singleRoute = (node: NavigationMenuItem): boolean => {
    return !!node?.singleRoute;
  };

  isActiveLink(routerLink: string) {
    const parentRoute = this.activatedRoute.snapshot?.firstChild?.url[0].path;

    const isActiveIndexRoute = parentRoute === routerLink.replace('/', '');

    if (isActiveIndexRoute) {
      return true;
    }

    const parsedUrl = this.getTwoLevelRoutePath(routerLink);
    const parsedCurrentRoute = this.getTwoLevelRoutePath(this.router.url);

    return parsedCurrentRoute === parsedUrl;
  }

  selectDefaultActiveRoute() {
    const parentRoute = this.activatedRoute.snapshot?.firstChild?.url[0].path;

    const defaultExpandKey = this.routerConfig.find(({ url }) =>
      url.includes(parentRoute || '')
    )?.url;

    if (defaultExpandKey) {
      this.expanded[defaultExpandKey] = !this.expanded[defaultExpandKey];
    }
  }

  filterRouterConfigByFlags(config: NavigationMenuItem[], flags: Flags): NavigationMenuItem[] {
    return config.filter((item) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'flagKey')) {
        return true;
      }

      if (item.flagKey && flags[item.flagKey]) {
        if (item.subRoutes.length > 0) {
          item.subRoutes = this.filterRouterConfigByFlags(item.subRoutes || [], flags);
        }
        return true;
      }
      return false;
    });
  }

  getRouteFeatureFlagList(routerConfig: NavigationMenuItem[]): (keyof Flags)[] {
    return routerConfig
      .map((navNode) => {
        return navNode.subRoutes.length
          ? [navNode.flagKey, ...this.getRouteFeatureFlagList(navNode.subRoutes)]
          : navNode.flagKey;
      })
      .filter((x) => x) as (keyof Flags)[];
  }

  onItemSelected(item: NavigationMenuItem) {
    if (item.subRoutes && item.subRoutes.length) {
      this.expanded[item.url] = !this.expanded[item.url];
    }
  }

  private getTwoLevelRoutePath(route: string) {
    const subRoutes = route.match(/\/[a-z | (\-)]*/g);

    if (!subRoutes?.length) return;

    if (subRoutes?.length >= this.THIRD_LEVEL_ROUTE) {
      return `${subRoutes[0]}${subRoutes[1]}`;
    }

    return route.replace(/\?trial=.*/, '');
  }

  private setCurrentPeriodToPeriodCloseRouter(
    configWithFlags: NavigationMenuItem[],
    currentOpenMonth: string,
    trialEndDate: string
  ) {
    const periodCloseRouterIndex = configWithFlags.findIndex(({ name }) => name === 'Period Close');

    if (periodCloseRouterIndex === -1 || !currentOpenMonth || !trialEndDate) return;

    const openMonthObj = dayjs(currentOpenMonth);

    // compare month and year
    if (openMonthObj.isAfter(trialEndDate, 'month')) return;

    const formattedDate = openMonthObj.format('MMM YYYY');

    configWithFlags[periodCloseRouterIndex].primaryBadge = {
      component: BadgeComponent,
      inputs: { title: formattedDate, className: 'uppercase font-bold' },
    };
  }
}
