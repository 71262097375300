import { OverlayService } from '@shared/services/overlay.service';
import { InvestigatorContractedAmountService } from './investigator-contracted-amount.service';
import { Injectable } from '@angular/core';
import {
  CreateSiteContractSettingInput,
  GqlService,
  InvestigatorForecastSource,
  UpdateInvestigatorForecastInput,
  listOrganizationsWithInvestigatorCostCategoriesQuery,
  listSiteContractSettingsQuery,
} from '@shared/services/gql.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MainQuery } from '@shared/store/main/main.query';
import { InvestigatorAveragesService } from './investigator-averages.service';
import {
  AVERAGES_SOURCE_INITIAL_VALUES,
  PATIENT_VISITS_SOURCE_INITIAL_VALUE,
} from '../investigator-forecast.const';
import {
  ContractedInvestigatorAmountValues,
  InvestigatorForecastValues,
} from '../investigator-forecast.types';
import { decimalAdd, decimalDifference, decimalDivide, decimalMultiply } from '@shared/utils';
import { first } from 'lodash-es';
import { Utils } from '@shared/utils/utils';
import { InvestigatorPatientVisits } from './investigator-patient-visits.service';

@Injectable({ providedIn: 'root' })
export class InvestigatorForecastService {
  averagesValues$ = new BehaviorSubject<listSiteContractSettingsQuery[]>([]);

  isLoadingSettings$ = new BehaviorSubject(false);

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private investigatorContractedAmountService: InvestigatorContractedAmountService,
    private investigatorAveragesService: InvestigatorAveragesService,
    private investigatorPatientVisits: InvestigatorPatientVisits
  ) {}

  getVendorListWithInvestigatorCosts(): Observable<
    listOrganizationsWithInvestigatorCostCategoriesQuery[]
  > {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => this.gqlService.listOrganizationsWithInvestigatorCostCategories$()),
      map((response) => {
        return response?.data || [];
      })
    );
  }

  getInvestigatorForecastContractedAmounts(vendorId: string) {
    return this.gqlService.listInvestigatorForecastContractedAmounts$(vendorId).pipe(
      map((response) => {
        const expenses = response.data || [];

        const sortedExpensesByDate = expenses.sort(
          ({ source_last_updated: date1 }, { source_last_updated: date2 }) =>
            Utils.dateSort((date1 || '1970-01-01') as string, (date2 || '1970-01-01') as string)
        );

        return {
          values: this.investigatorContractedAmountService.getSummaryValues(expenses),
          sourceLastUpdate: first(sortedExpensesByDate)?.source_last_updated || null,
        };
      })
    );
  }

  getInvestigatorForecastAveragesAmounts(
    vendorId: string,
    contractedValues: ContractedInvestigatorAmountValues
  ) {
    return this.gqlService.listSiteContractSettings$(vendorId).pipe(
      map(({ data, errors }) => {
        if (errors.length || !data?.length) {
          this.averagesValues$.next([]);
          this.overlayService.error(errors);
          return {
            values: AVERAGES_SOURCE_INITIAL_VALUES,
            sourceLastUpdate: null,
          };
        }

        this.averagesValues$.next(data as listSiteContractSettingsQuery[]);

        return {
          values: this.investigatorAveragesService.getInitialSummaryValues(
            data as listSiteContractSettingsQuery[],
            contractedValues
          ),
          sourceLastUpdate: first(data)?.source_last_updated || null,
        };
      })
    );
  }

  getInvestigatorForecastPatientVisitsAmounts(vendorId: string, spendToDate: number) {
    return this.gqlService.listActualPatientVisitSettings$(vendorId).pipe(
      map(({ data, errors }) => {
        if (errors.length || !data?.length) {
          this.averagesValues$.next([]);
          this.overlayService.error(errors);
          return {
            values: PATIENT_VISITS_SOURCE_INITIAL_VALUE,
            sourceLastUpdate: null,
          };
        }

        return {
          values: this.investigatorPatientVisits.getInitialSummaryValues(data, spendToDate),
          sourceLastUpdate: first(data)?.source_last_updated || null,
        };
      })
    );
  }

  updateInvestigatorForecast$(
    vendorId: string,
    data: Omit<UpdateInvestigatorForecastInput, 'organization_id'>
  ) {
    return this.gqlService.updateInvestigatorForecast$({
      organization_id: vendorId,
      ...data,
    });
  }

  async savePatientVisits(formValues: InvestigatorForecastValues) {
    const { vendorId, patientVisits } = formValues;
    if (vendorId == null) {
      return;
    }

    const patientSettingsPromise = firstValueFrom(
      this.gqlService.createActualPatientVisitSetting$({
        organization_id: vendorId,
        invoiceables_percent: patientVisits?.invoiceablesPerc || 0,
        overhead_percent: decimalDifference(100, patientVisits?.invoiceablesDistribution || 0),
        other_percent: patientVisits?.invoiceablesDistribution || 0,
        discontinued_percent: patientVisits?.totalDiscontinuedPerc || 0,
        remaining_patients_to_discontinue_percent:
          patientVisits?.remainingPatientsToDiscontinuePerc || 0,
        remaining_patients_to_discontinue_cost:
          patientVisits?.remainingPatientsToDiscontinueCost || 0,
      })
    );
    const otherAmount = decimalMultiply(
      patientVisits.forecastedRemainingInvoiceables || 0,
      decimalDivide(patientVisits?.invoiceablesDistribution || 0, 100),
      2
    );

    const overheadAmount = decimalDifference(
      patientVisits.forecastedRemainingInvoiceables || 0,
      otherAmount,
      2
    );

    const patientVisitsAmount = decimalDifference(
      patientVisits.totalForecastedInvestigatorAmountThroughEndOfTrial,
      decimalAdd(otherAmount, overheadAmount, 2),
      2
    );

    const updateInvestigatorForecastPromise = firstValueFrom(
      this.updateInvestigatorForecast$(vendorId, {
        source: InvestigatorForecastSource.ACTUAL_PATIENT_VISITS,
        patient_visits_amount: patientVisitsAmount,
        other_amount: otherAmount,
        overhead_amount: overheadAmount,
      })
    );

    return Promise.all([patientSettingsPromise, updateInvestigatorForecastPromise]);
  }

  saveSiteContractSetting$(
    vendorId: string,
    data: Omit<CreateSiteContractSettingInput, 'organization_id'>
  ) {
    return this.gqlService.createSiteContractSetting$({
      organization_id: vendorId,
      ...data,
    });
  }

  async saveSiteContractAverages(formValues: InvestigatorForecastValues) {
    const { vendorId, averages } = formValues;
    if (vendorId == null) {
      return;
    }

    const createSiteContractPromise = firstValueFrom(
      this.saveSiteContractSetting$(vendorId, {
        average_patient_cost: averages?.averagePatientCost || 0,
        invoiceables_percent: averages?.invoiceables || 0,
        other_percent: averages?.otherInvoiceablesPerc || 0,
        overhead_percent: averages?.overheadInvoiceablesPerc || 0,
        total_forecasted_patients: averages?.totalForecastedPatients || 0,
      })
    );

    const otherAmount = decimalMultiply(
      averages.forecastedInvoiceables || 0,
      decimalDivide(averages?.otherInvoiceablesPerc || 0, 100),
      2
    );

    const overheadAmount = decimalDifference(averages.forecastedInvoiceables || 0, otherAmount, 2);

    const updateInvestigatorForecastPromise = firstValueFrom(
      this.updateInvestigatorForecast$(vendorId, {
        source: InvestigatorForecastSource.SITE_CONTRACT_AVERAGES,
        patient_visits_amount: averages?.forecastedVisitCosts || 0,
        other_amount: otherAmount,
        overhead_amount: overheadAmount,
      })
    );

    return Promise.all([createSiteContractPromise, updateInvestigatorForecastPromise]);
  }
}
