import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MainQuery } from '@shared/store/main/main.query';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { Observable } from 'rxjs/internal/Observable';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

enum RouterLinks {
  Clinical = 'clinicalRouterLink',
  Finance = 'financeRouterLink',
}

@Component({
  selector: 'aux-trial-insights-nav',
  templateUrl: './trial-insights-nav.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsNavComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  userName = '';

  userOrganization = '';

  path = ROUTING_PATH.TRIAL_INSIGHTS;

  clinicalRouterLink = `/${this.path.INDEX}/${this.path.CLINICAL}`;

  financeRouterLink = `/${this.path.INDEX}/${this.path.FINANCE}`;

  showFinanceButton$: Observable<boolean>;

  showClinOpsButton$: Observable<boolean>;

  RouterLinks = RouterLinks;

  activeLink: RouterLinks = RouterLinks.Clinical;

  constructor(
    private authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {
    this.showFinanceButton$ = this.launchDarklyService.select$((flags) => flags.finance_dashboard);
    this.showClinOpsButton$ = this.launchDarklyService.select$((flags) => flags.clin_ops_dashboard);
    combineLatest([this.showClinOpsButton$, this.showFinanceButton$])
      .pipe(takeUntilDestroyed())
      .subscribe(([clin_ops, finance]) => {
        if (!clin_ops && finance) {
          this.router.navigateByUrl(this.financeRouterLink);
        }
        if (clin_ops && !finance) {
          this.router.navigateByUrl(this.clinicalRouterLink);
        }
      });
  }

  ngOnInit(): void {
    this.getUserInfo();
    const defaultVal = this.router.url.includes(RouterLinks.Finance);
    this.activeLink = defaultVal ? RouterLinks.Finance : RouterLinks.Clinical;
  }

  getUserInfo(): void {
    // User header info
    this.authQuery
      .select(['given_name', 'trial_id'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ given_name }) => {
        const trial = this.mainQuery.getSelectedTrial();

        this.userName = given_name;
        this.userOrganization = trial?.sponsor_organization.name || '';
      });
  }

  setActive(link: RouterLinks): void {
    this.activeLink = link;
  }

  isActive(link: RouterLinks): boolean {
    return this.activeLink === link;
  }
}
