@if (componentVisible && !consoleTracker) {
  <div class="p-4 w-full min-h-[94px] border border-[#E9EAEE] rounded-lg space-y-4">
    <div class="flex items-center gap-4">
      <div class="font-bold">{{ componentCaption }}</div>
      <div class="text-sm text-aux-gray-darkest">({{ progressBarPercent }}%)</div>
    </div>

    <aux-progress-bar [value]="progressBarPercent" />

    @if (startedAt) {
      <div class="text-sm text-aux-gray-darkest">
        Started at {{ startedAt }} by {{ triggeredBy }}
      </div>
    }
  </div>
}
