import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Utils } from '@shared/utils/utils';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { HideLastNamePipe } from '@shared/pipes/hide-last-name.pipe';
import { NgClass } from '@angular/common';

@Component({
  selector: 'aux-first-name-show',
  templateUrl: './first-name-show.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TooltipDirective, HideLastNamePipe, NgClass],
})
export class FirstNameShowComponent {
  tooltip = '';

  name = '';

  _text = '';

  _showHyphenIfEmpty = false;

  @Input() className?: string = '';

  @Input() set text(text: string) {
    this._text = text;
    this.updateName();
    this.updateTooltip();
  }

  get text() {
    return this._text;
  }

  @Input() set showHyphenIfEmpty(bool: boolean) {
    this._showHyphenIfEmpty = bool;
    this.updateName();
    this.updateTooltip();
  }

  get showHyphenIfEmpty() {
    return this._showHyphenIfEmpty;
  }

  updateTooltip() {
    let name = this.name;
    if (name === 'Auxilius Expert' || name === Utils.zeroHyphen) {
      name = '';
    }
    this.tooltip = name;
  }

  updateName() {
    this.name = this.text || (this.showHyphenIfEmpty ? Utils.zeroHyphen : '');
  }
}
