import { ValueFormatterParams } from '@ag-grid-community/core';
import { BudgetCurrencyType } from '@models/budget-currency/budget-currency.model';
import { Currency } from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';

export const agBudgetCurrencyFormatter =
  (selectedBudgetCurrencyType: BudgetCurrencyType) =>
  (params: ValueFormatterParams): string => {
    let currencyName = Currency.USD;
    if (params?.data) {
      const { contract_direct_cost_currency } = params.data;
      const isContractedCurrency = selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR;
      currencyName = isContractedCurrency ? contract_direct_cost_currency : Currency.USD;
      if (params.data.expense_note && (params.colDef.field || '').indexOf('direct_cost') >= 0) {
        return params.data.expense_note;
      }
    } else if (params?.node?.aggData && selectedBudgetCurrencyType === BudgetCurrencyType.VENDOR) {
      currencyName = params.node.aggData.contract_direct_cost_currency;
    }

    if (params?.value) {
      if (!Number.isNaN(params.value)) {
        return Utils.agCurrencyFormatter(params.value, currencyName);
      }
    }
    return Utils.zeroHyphen;
  };
