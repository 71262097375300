import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, ColumnGroup, IHeaderGroupParams } from '@ag-grid-community/core';
import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { AgHeaderExpandComponent } from 'src/app/pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';

import { PeriodCloseComponent } from '../../period-close.component';
import { AuxIconName } from '@shared/components/icon/icon';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { WorkflowStep } from '@shared/services/gql.service';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface MenuItem {
  icon: AuxIconName;
  title: string;
  textColor?: string;
  onClick: VoidFunction;
}

interface AgAdjustmentVendorEstimateHeaderColumnParams extends IHeaderGroupParams {
  columnGroup: ColumnGroup;
  removeVendorEstimateLoading$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimate$: BehaviorSubject<boolean>;
  isEditMode$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimateSupportingDoc$: BehaviorSubject<boolean>;
  isSelectedMonthOpenOrFuture$: BehaviorSubject<boolean>;
  isSelectedMonthOpen$: BehaviorSubject<boolean>;
  collapsedByDefault: boolean;
  onDeleteVendorEstimate: VoidFunction;
  onDownloadVendorEstimate: VoidFunction;
  filterCols: (column: Column) => boolean;
  onEditAdjustments: VoidFunction;
  onUploadVendorEstimate: VoidFunction;
  localStorageKey: string;
  hasEditVendorEstimatePermission$: BehaviorSubject<boolean>;
  onToggle?: () => void;
}
@Component({
  template: `
    <ng-container
      *ngVar="{
        isEditMode: params.isEditMode$ | async,
        isWorkflowLocked: isWorkflowLocked$ | async,
        isVendorEstimatesLocked: isVendorEstimatesLocked$ | async,
        isSelectedMonthOpen: params.isSelectedMonthOpen$ | async,
        editVEPerm: hasEditVendorEstimatePermission$ | async
      } as obj"
    >
      <ng-container *ngVar="obj.isWorkflowLocked || obj.isVendorEstimatesLocked as isEditDisabled">
        <div
          class="flex items-center justify-center"
          *ngIf="{ menuItems: menuItems$ | async } as data"
        >
          <div>Vendor Estimate</div>
          <aux-button
            *ngIf="(params.isSelectedMonthOpen$ | async) === true"
            variant="tertiary"
            [disabled]="!obj.editVEPerm || isEditDisabled"
            classList="p-[4px] ml-[10px]"
            [auxTooltip]="
              getTooltip(obj.editVEPerm, obj.isWorkflowLocked, obj.isVendorEstimatesLocked)
            "
            (clickEmit)="obj.editVEPerm && !isEditDisabled && params.onEditAdjustments()"
            icon="Pencil"
          />
          <aux-menu
            #vendorEstimateMenu
            class="ml-[10px]"
            className="p-[4px]"
            [showMenuForExternal]="true"
            *ngIf="!!data.menuItems?.length"
          >
            <button
              type="button"
              role="menuitem"
              *ngFor="let item of data.menuItems"
              class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 p-[8px] text-sm w-full flex items-center focus:outline-none max-w-[370px]"
              [class.opacity-70]="
                isDelete(item) &&
                (obj.editVEPerm ? isEditDisabled && obj.isSelectedMonthOpen : true)
              "
              [class.cursor-not-allowed]="
                isDelete(item) &&
                (obj.editVEPerm ? isEditDisabled && obj.isSelectedMonthOpen : true)
              "
              [auxTooltip]="
                isDelete(item)
                  ? getTooltip(obj.editVEPerm, obj.isWorkflowLocked, obj.isVendorEstimatesLocked)
                  : ''
              "
              (click)="
                onItemClick(item, obj.isWorkflowLocked, obj.isVendorEstimatesLocked, obj.editVEPerm)
              "
            >
              <div class="flex space-x-4">
                <aux-icon
                  [name]="item.icon"
                  class="text-aux-blue"
                  [ngClass]="item.textColor"
                  style="display: flex"
                />
                <div class="text-left">
                  <p class="text-base text-aux-blue" [ngClass]="item.textColor">{{ item.title }}</p>
                </div>
              </div>
            </button>
          </aux-menu>

          <aux-icon
            (click)="toggleExpand()"
            [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
            class="ml-1 cursor-pointer"
          />
          <ng-container *ngIf="params.removeVendorEstimateLoading$ | async">
            <div class="border-8 h-8 m-auto spinner w-8"></div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      ::ng-deep div,
      ::ng-deep button {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      ::ng-deep button:focus {
        box-shadow: none !important;
      }

      ::ng-deep .options {
        border-radius: 0;
        border: 1px solid #bacad0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentVendorEstimateHeaderComponent
  extends AgHeaderExpandComponent
  implements IHeaderGroupAngularComp
{
  params!: AgAdjustmentVendorEstimateHeaderColumnParams;

  @ViewChild('vendorEstimateMenu') vendorEstimateMenu!: MenuComponent;

  isWorkflowLocked$ = this.workflowQuery.getLockStatusByWorkflowStepType$(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
  );

  isVendorEstimatesLocked$ = this.workflowQuery.getLockStatusByWorkflowStepType$(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES
  );

  visible = true;

  hasEditVendorEstimatePermission$ = new BehaviorSubject<boolean>(false);

  doNotHavePermissionMessage = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  private readonly menuItems: MenuItem[] = [
    {
      title: 'Send Vendor Estimate Support',
      icon: 'Send',
      onClick: () => this.params.onUploadVendorEstimate(),
    },
    {
      title: 'Download Vendor Estimate',
      icon: 'Download',
      onClick: () => this.params.onDownloadVendorEstimate(),
    },
    {
      title: 'Delete Vendor Estimate',
      icon: 'Trash',
      textColor: 'text-aux-red-dark',
      onClick: () => this.params.onDeleteVendorEstimate(),
    },
  ];

  menuItems$ = new BehaviorSubject(this.menuItems);

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private workflowQuery: WorkflowQuery
  ) {
    super();
  }

  agInit(params: AgAdjustmentVendorEstimateHeaderColumnParams): void {
    this.params = params;
    this.visible = !params.collapsedByDefault;
    this.filterCols = this.params.filterCols;
    this.initializeExpandCols();
    this.filterMenuItems();

    this.params.hasEditVendorEstimatePermission$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((perm) => {
        this.hasEditVendorEstimatePermission$.next(perm);
      });
  }

  isDelete(item: MenuItem): boolean {
    return item.title === 'Delete Vendor Estimate';
  }

  onItemClick(
    item: MenuItem,
    isWorkflowLocked: boolean,
    isVendorEstimatesLocked: boolean,
    hasPermission: boolean
  ) {
    const disableDeleteButton = hasPermission
      ? (isWorkflowLocked || isVendorEstimatesLocked) && this.params.isSelectedMonthOpen$.value
      : true;
    if (!this.isDelete(item) || (this.isDelete(item) && !disableDeleteButton)) {
      item.onClick();
      this.vendorEstimateMenu.close();
    }
  }

  filterMenuItems() {
    combineLatest([
      this.params.removeVendorEstimateLoading$,
      this.params.doesSelectedMonthHasVendorEstimate$,
      this.params.doesSelectedMonthHasVendorEstimateSupportingDoc$,
      this.params.isSelectedMonthOpenOrFuture$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          removeVendorEstimateLoading,
          doesSelectedMonthHasVendorEstimate,
          doesSelectedMonthHasVendorEstimateSupportingDoc,
          isSelectedMonthOpenOrFuture,
        ]) => {
          const filteredMenuItems = [];

          if (isSelectedMonthOpenOrFuture) {
            filteredMenuItems.push(this.menuItems[0]);
          }

          if (doesSelectedMonthHasVendorEstimateSupportingDoc) {
            filteredMenuItems.push(this.menuItems[1]);
          }

          if (
            !removeVendorEstimateLoading &&
            doesSelectedMonthHasVendorEstimate &&
            isSelectedMonthOpenOrFuture
          ) {
            filteredMenuItems.push(this.menuItems[2]);
          }

          this.menuItems$.next(filteredMenuItems);
        }
      );
  }

  refresh(): boolean {
    return false;
  }

  getTooltip(hasPermission: boolean, isLocked: boolean, isVendorEstimatesLocked: boolean) {
    if (!hasPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isVendorEstimatesLocked && this.params.isSelectedMonthOpen$.value) {
      return MessagesConstants.VENDOR_ESTIMATES_LOCKED;
    }

    if (isLocked && this.params.isSelectedMonthOpen$.value) {
      return MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }
}
