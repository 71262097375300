import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
} from '@angular/core';
import { AuxIconName } from '@shared/components/icon/icon';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'aux-checklist-row-title',
  templateUrl: './checklist-row-title.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, TooltipDirective, ButtonComponent],
})
export class ChecklistRowTitleComponent {
  pendoID = input('');

  icon = input.required<AuxIconName>();

  title = input('');

  complete = input('0');

  total = input('0');

  isLoadingList = input(false);

  disabledLock = input(false);

  tooltip = input('');

  @Output() lockAllEmit = new EventEmitter<void>();

  sectionCompleted = computed(() => {
    const complete = this.complete();
    const total = this.total();
    return complete !== '0' && complete === total;
  });
}
