<!-- Title Row -->
<div class="border bg-aux-gray-light h-[50px] flex p-3 items-center text-aux-black">
  <!-- Icon -->
  <aux-icon [name]="icon()" />

  <!-- Title and Counts -->
  <span class="ml-1 flex-grow font-normal"> {{ title() }} ({{ complete() }}/{{ total() }})</span>

  <!-- Complete button -->
  @if (sectionCompleted()) {
    <div class="font-bold text-aux-green-dark flex items-center mr-2">
      <span class="mr-1">Complete</span>
      <aux-icon name="Check" />
    </div>
  } @else if (!isLoadingList() && total() && total() !== '0') {
    <!-- Lock All button -->
    <div [auxTooltip]="tooltip()">
      <aux-button
        variant="hyperlink"
        [attr.data-pendo-id]="pendoID()"
        label="Lock All"
        (clickEmit)="lockAllEmit.emit()"
        [disabled]="disabledLock()"
        icon="Lock"
      />
    </div>
  }
</div>
