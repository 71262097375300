@if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
  <aux-workflow-panel
    className="mb-4"
    [workflowName]="workflowName"
    [processing]="iCloseMonthsProcessing()!"
    [hasUserPermissions]="userHasLockInvoicesPermission()"
  />
}

<div class="flex justify-between items-center">
  <div class="flex items-center">
    <h1 class="text-2xl font-bold">Invoice Library</h1>

    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkRequireCostBreakdown($event)"
        [checked]="showRequireCostBreakdown()"
      >
        Require Cost Breakdown
      </aux-checkbox>
    </div>
    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkRequireAccrualPeriod($event)"
        [checked]="showRequireAccrualPeriod()"
      >
        Require Posting Period
      </aux-checkbox>
    </div>
    @if (showUnpaidInvoiceCheckbox()) {
      <div class="ml-7">
        <aux-checkbox
          class="mt-3 mb-3 text-sm"
          [disabled]="editMode()"
          (customChange)="checkUnpaidInvoices($event)"
          [checked]="showUnpaidInvoices()"
          data-pendo-id="unpaid-invoices-filter"
        >
          Unpaid Invoices
        </aux-checkbox>
      </div>
    }
    <div class="ml-7">
      <aux-checkbox
        class="mt-3 mb-3 text-sm"
        [disabled]="editMode()"
        (customChange)="checkInvalidCostCategorization($event)"
        [checked]="showInvalidCostCategorization()"
      >
        Invalid Cost Categorization
      </aux-checkbox>
    </div>
  </div>

  <div class="grid grid-flow-col gap-x-1.5">
    <aux-invoices-sync-buttons />

    <aux-export-excel-button
      [clickFN]="onExportInvoices.bind(this)"
      [spinnerSize]="7"
      pendoTag="invoices-excel-export-button"
    />

    <aux-button
      classList="pl-3"
      variant="primary"
      label="Add New"
      [auxTooltip]="invoiceLockTooltip()"
      [onClick]="this.onNewInvoice.bind(this)"
      [disabled]="isInvoiceFinalized() || iCloseMonthsProcessing() || editMode()"
      icon="Plus"
    />
  </div>
</div>

<aux-invoice-filters class="mt-2" [form]="invoiceService.filtersForm" [editMode]="editMode()">
  <div bulkEditBlock class="flex items-end flex-wrap h-[52.5px]">
    @if (editMode()) {
      <aux-button
        variant="secondary"
        label="Bulk Edit"
        classList="mr-4"
        [auxTooltip]="invoiceLockTooltip()!"
        [disabled]="
          savingChanges() ||
          isInvoiceFinalized() ||
          iCloseMonthsProcessing() ||
          !selectedRows().length
        "
        icon="Pencil"
        [spinnerSize]="7"
        [onClick]="onBulkApplyButtonClick"
      />
      <aux-button
        variant="negative"
        label="Delete"
        classList="mr-4"
        icon="Trash"
        [auxTooltip]="deleteButtonTooltip()"
        [disabled]="
          savingChanges() ||
          isInvoiceFinalized() ||
          iCloseMonthsProcessing() ||
          !selectedRows().length ||
          !userHasDeleteInvoicePermission()
        "
        [onClick]="removeInvoices"
        [spinnerSize]="7"
      />
    }
  </div>

  <div togglesBlock class="flex items-end flex-wrap h-[52.5px]">
    @if (vendorCurrencyEnabled() && !vendorHasOneCurrency) {
      <div class="mb-[2px]">
        <aux-toggle-budget-currency
          [value]="isVendorCurrency()"
          (nameSelectedCurrency)="selectedBudgetCurrencyType.set($event)"
        />
      </div>
    }
  </div>

  <div saveBlock class="ml-auto flex items-end justify-between">
    @if (editMode()) {
      <div class="grid grid-flow-col gap-x-1.5">
        <aux-button
          variant="secondary"
          label="Cancel"
          icon="X"
          [onClick]="onCancel"
          [disabled]="savingChanges()"
        />
        <aux-button
          variant="success"
          label="Save"
          icon="Check"
          [disabled]="!hasChanges()"
          [onClick]="onSave"
          [spinnerSize]="7"
        />
      </div>
    }

    @if (!editMode()) {
      <aux-button
        variant="secondary"
        label="Edit"
        icon="Pencil"
        [auxTooltip]="editButtonTooltip()"
        [disabled]="
          isInvoiceFinalized() || iCloseMonthsProcessing() || !userHasEditInvoicePermission()
        "
        [onClick]="enableEditMode"
      />
    }
  </div>
</aux-invoice-filters>

<aux-pagination-grid
  class="block mt-4"
  [idTable]="'invoiceLibraryGrid'"
  [gridOptions]="$any(gridOptions())"
  [dataSource]="datasource"
  [filterForm]="invoiceService.filtersForm"
  [serverSideFilters]="serverSideFilters"
  [filterValues$]="filterValues$"
  [sortModel$]="sortModel$"
  [hidePinnedRow]="true"
  [enableFillHandle]="editMode()"
  [loading]="listVendorAmountTypesLoading()"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="firstDataRendered($event)"
  (paginationChange)="paginationChange()"
  (cellValueChanged)="cellValueChanged($event)"
  (rowSelected)="rowSelected($event)"
  (selectionChanged)="selectionChanged($event)"
  (modelUpdated)="modelUpdated($event)"
/>

@if (showExportFull()) {
  <aux-export-full-dataset-button
    class="flex justify-end"
    [datasource]="datasource"
    [totalRows]="totalRows()"
    [processEvent]="processExportEvent"
  />
}
