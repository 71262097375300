import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from '@ag-grid-community/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForecastTableGridComponent } from './forecast-table-grid.component';
import { ForecastTableGridCategoryComponent } from './components/forecast-table-grid-category/forecast-table-grid-category.component';
import { ForecastTableGridDriverComponent } from './components/forecast-table-grid-driver/forecast-table-grid-driver.component';
import { ForecastTableGridMethodComponent } from './components/forecast-table-grid-method/forecast-table-grid-method.component';
import { ForecastTableGridMethodPatientComponent } from './components/forecast-table-grid-method-patient/forecast-table-grid-method-patient.component';
import { ForecastTableGridMethodSiteComponent } from './components/forecast-table-grid-method-site/forecast-table-grid-method-site.component';
import { ForecastTableGridMethodTimeComponent } from './components/forecast-table-grid-method-time/forecast-table-grid-method-time.component';
import { ForecastTableGridPeriodComponent } from './components/forecast-table-grid-period/forecast-table-grid-period.component';
import { ForecastTableGridPeriodPatientComponent } from './components/forecast-table-grid-period-patient/forecast-table-grid-period-patient.component';
import { ForecastTableGridPeriodSiteComponent } from './components/forecast-table-grid-period-site/forecast-table-grid-period-site.component';
import { ForecastTableGridPeriodTimeComponent } from './components/forecast-table-grid-period-time/forecast-table-grid-period-time.component';
import { ForecastTableGridPeriodTimeModalComponent } from './components/forecast-table-grid-period-time-modal/forecast-table-grid-period-time-modal.component';
import { ForecastTableGridCategoryToggleService } from './services/forecast-table-grid-category-toggle.service';
import { ForecastTableGridCategoryService } from './services/forecast-table-grid-category.service';
import { ForecastTableGridDriverService } from './services/forecast-table-grid-driver.service';
import { ForecastTableGridMethodService } from './services/forecast-table-grid-method.service';
import { ForecastTableGridPeriodService } from './services/forecast-table-grid-period.service';
import { ForecastTableGridRemainingService } from './services/forecast-table-grid-remaining.service';
import { ForecastTableGridTotalService } from './services/forecast-table-grid-total.service';
import { ForecastTableGridUnitsService } from './services/forecast-table-grid-units.service';
import { ForecastTableGridUomService } from './services/forecast-table-grid-uom.service';
import { ForecastTableGridService } from './services/forecast-table-grid.service';
import { ForecastTableGridCategoryCheckService } from './services/forecast-table-grid-category-check.service';
import { ForecastTableGridColumnsService } from './services/forecast-table-grid-columns.service';
import { ForecastTableGridCategoryLoggingService } from './services/forecast-table-grid-category-loading.service';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SharedModule } from '@shared/shared.module';
import { ForecastTableGridMethodCustomCurveComponent } from './components/forecast-table-grid-method-custom-curve/forecast-table-grid-method-custom-curve.component';
import { ForecastTableGridPeriodCustomCurveComponent } from './components/forecast-table-grid-period-custom-curve/forecast-table-grid-period-custom-curve.component';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { ModalComponent } from '@shared/components/modals/modal/modal.component';

const standaloneComponents = [
  ForecastTableGridPeriodCustomCurveComponent,
  ForecastTableGridMethodCustomCurveComponent,
  ModalComponent,
] as const;

@NgModule({
  imports: [
    AgGridAngular,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    IconComponent,
    SharedModule,
    ...standaloneComponents,
    StickyGridDirective,
  ],
  providers: [
    ForecastTableGridService,
    ForecastTableGridColumnsService,
    ForecastTableGridCategoryService,
    ForecastTableGridTotalService,
    ForecastTableGridRemainingService,
    ForecastTableGridUnitsService,
    ForecastTableGridUomService,
    ForecastTableGridDriverService,
    ForecastTableGridMethodService,
    ForecastTableGridPeriodService,
    ForecastTableGridCategoryService,
    ForecastTableGridCategoryCheckService,
    ForecastTableGridCategoryToggleService,
    ForecastTableGridCategoryLoggingService,
  ],
  declarations: [
    ForecastTableGridComponent,
    ForecastTableGridCategoryComponent,
    ForecastTableGridDriverComponent,
    ForecastTableGridMethodComponent,
    ForecastTableGridMethodPatientComponent,
    ForecastTableGridMethodSiteComponent,
    ForecastTableGridMethodTimeComponent,
    ForecastTableGridPeriodComponent,
    ForecastTableGridPeriodPatientComponent,
    ForecastTableGridPeriodTimeComponent,
    ForecastTableGridPeriodTimeModalComponent,
    ForecastTableGridPeriodSiteComponent,
  ],
  exports: [ForecastTableGridComponent],
})
export class ForecastTableGridModule {}
