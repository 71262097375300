import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-workflow-panel-edc-notification-confirmation',
  template: `
    <div class="flex flex-col items-start space-y-1 w-screen max-w-[460px]">
      <div class="mb-8">
        You are about to send an email notification to the customer to let them know that their
        EDC/investigator data is ready for review. It will also change the status of this step to
        Available.
      </div>
      <span>Are you sure you want to send this email notification?</span>
    </div>
  `,
  standalone: true,
  imports: [IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPanelEdcNotificationConfirmationComponent {
  private readonly ref = inject(CustomOverlayRef<{ success: boolean }>);

  sendNotification() {
    this.ref.close({ success: true });
  }
}
