import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { TrialInsightsFinancePatientsEnrolledTableService } from './patients-enrolled-table.service';
import { TrialInsightsFinancePatientsEnrolledQueryService } from './patients-enrolled-query.service';
import {
  TIFPEChartType,
  TrialInsightsFinancePatientsEnrolledChartService,
} from './patients-enrolled-chart.service';
import { TrialInsightsFinancePatientsEnrolledStoreService } from './patients-enrolled-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-trial-insights-finance-enrolled-patients',
  templateUrl: './trial-insights-finance-enrolled-patients.component.html',
  styleUrls: [
    '../../trial-insights.component.scss',
    './trial-insights-finance-enrolled-patients.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TrialInsightsFinancePatientsEnrolledQueryService],
})
export class TrialInsightsFinanceEnrolledPatientsComponent extends GenericTrialInsightsComponent<TIFPEChartType> {
  constructor(
    public tableService: TrialInsightsFinancePatientsEnrolledTableService,
    public chartService: TrialInsightsFinancePatientsEnrolledChartService,
    public storeService: TrialInsightsFinancePatientsEnrolledStoreService,
    public queryService: TrialInsightsFinancePatientsEnrolledQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Remaining Investigator Spend',
      color: '#3D444B',
      exceedMessage: 'Patients Enrolled (actual) has exceeded Patients Enrolled (expected)',
      chartService,
      tableService,
      queryService,
      cdr,
    });
  }

  subscribeToData = () => {
    this.storeService
      .getRemainingInvestigatorInfo$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
    this.queryService
      .getRemainingInvestigatorInfo$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  };
}
