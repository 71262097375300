<div class="forecast-table-grid-select-container">
  <ng-select
    class="forecast-table-grid-select forecast-custom-driver-method-dropdown"
    [appendTo]="'body'"
    [searchable]="false"
    [clearable]="true"
    [disabled]="disabled()"
    [auxTooltip]="tooltip()"
    [items]="customCurves()"
    [class.is-invalid]="isInvalid"
    [(ngModel)]="selectedCurve"
    (change)="modelChange($event)"
    placeholder="Select"
    bindLabel="name"
    bindValue="driver_setting_id"
  />
</div>
