import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Flags, LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { BasicRedirectToNextTabGuard } from '@shared/guards/basic-redirect-to-next-tab.guard';
import { PERIOD_CLOSE_ROUTES_FEATURE_FLAGS } from '../quarter-close-adjustments/quarter-close-adjustments.guard';

@Injectable({
  providedIn: 'root',
})
export class QuarterCloseChecklistGuard extends BasicRedirectToNextTabGuard {
  protected prefix = `/${ROUTING_PATH.CLOSING.INDEX}`;

  protected featureFlag = 'checklist_tab' as keyof Flags;

  protected defaultNavigateUrl = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`;

  protected routerPathsAndFeatureFlags = PERIOD_CLOSE_ROUTES_FEATURE_FLAGS;

  constructor(launchDarklyService: LaunchDarklyService, router: Router) {
    super(launchDarklyService, router);
  }
}
