<div class="flex justify-between items-center mt-10 mb-4">
  <div class="text-xl font-semibold">Adjustments to Prepaid</div>

  <div class="flex justify-end space-x-2">
    <aux-button
      variant="secondary"
      label="Adjustment"
      icon="CirclePlus"
      [onClick]="onAddAdjustment"
      [disabled]="isEditDisabled()"
      [auxTooltip]="prepaidLockTooltip()"
    />

    @if (editMode$.getValue()) {
      <aux-button variant="secondary" label="Cancel" icon="X" [onClick]="onCancel" />

      <aux-button
        variant="success"
        label="Save"
        class="ml-1"
        icon="Check"
        [disabled]="!hasChanges()"
        [onClick]="onSaveAll"
      />
    } @else {
      <aux-button
        variant="secondary"
        label="Edit"
        icon="Pencil"
        [onClick]="onEdit"
        [disabled]="isEditDisabled()"
        [auxTooltip]="prepaidLockTooltip()"
      />
      <aux-export-excel-button
        classList="ml-1"
        [clickFN]="onExport.bind(this)"
        [spinnerSize]="7"
        pendoTag="prepaid-summary-excel-export-button"
      />
    }
  </div>
</div>

@if (loadingGridData()) {
  <aux-table-skeleton />
} @else {
  <ag-grid-angular
    domLayout="autoHeight"
    class="ag-theme-aux unselectable tabular-nums w-full"
    [rowData]="gridData()"
    [gridOptions]="gridOptions"
    [enableFillHandle]="editMode$ | async"
    (gridReady)="onGridReady($event)"
    auxStickyGrid
  />
}
