import { Component, ChangeDetectionStrategy, inject, DestroyRef, signal } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { AgSetColumnsVisible } from '@shared/utils';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon/icon.component';
import { NgClass, NgStyle } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';

export interface AgBudgetAttributeComponentParams extends IHeaderParams {
  columnsToCollapse: string[];
  localStorageKey: string;
  expandLevel: () => number;
  afterAttrToggle?: (() => void) | ((params: AgBudgetAttributeComponentParams) => void);
  alignHeaderOneRowHeight?: boolean;
  templateClasses?: string[];
  quickFilterText: boolean;
  showCheckbox?: {
    disabled: boolean;
    tooltip: string;
  };
}

@Component({
  template: `
    @if (params.quickFilterText) {
      <div class="absolute top-[-100%] left-0 right-0 px-[10px] pt-[2px]">
        <aux-input
          icon="Search"
          iconPosition="left"
          placeholder="Search"
          labelClassName="flex"
          [formControl]="searchControl"
        />
      </div>
    }

    @if (params.showCheckbox) {
      <div
        class="w-[48px] h-full flex items-center justify-center border-r border-[var(--ag-border-color)]"
      >
        <aux-checkbox
          [disabled]="params.showCheckbox.disabled"
          [auxTooltip]="params.showCheckbox.tooltip"
          [checked]="!!isSelected()"
          [indeterminate]="isSelected() === undefined"
          (customChange)="onCheckboxChange()"
          [hideLabel]="true"
        />
      </div>
    }

    <div
      class="flex items-center justify-center space-x-[10px]"
      [ngClass]="{ 'h-full': !params.alignHeaderOneRowHeight }"
      [ngStyle]="{
        width: params.showCheckbox ? 'calc(100% - 48px)' : '100%'
      }"
    >
      <div class="flex items-center justify-center space-x-[5px]">
        <button (click)="toggleRowExpand(false)" class="flex">
          <aux-icon name="ChevronsDown" [auxTooltip]="'Expand All Rows'" />
        </button>

        <button (click)="toggleRowExpand(true)" class="flex">
          <aux-icon name="ChevronsUp" [auxTooltip]="'Collapse All Rows'" />
        </button>
      </div>

      <div class="flex items-center justify-center space-x-1">
        <div [ngClass]="templateClasses" [innerHTML]="params.template"></div>

        @if (params.columnsToCollapse.length) {
          <aux-icon
            class="cursor-pointer ml-1"
            (click)="toggleExpand()"
            [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
          />
        }
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    NgClass,
    InputComponent,
    TooltipDirective,
    ReactiveFormsModule,
    CheckboxComponent,
    NgStyle,
  ],
})
export class AgBudgetGroupHeaderComponent implements IHeaderAngularComp {
  private readonly destroyRef = inject(DestroyRef);

  params!: AgBudgetAttributeComponentParams;

  visible = true;

  templateClasses = ['text-aux-black', 'text-base'];

  searchControl = new FormControl('');

  isSelected = signal<boolean | undefined>(false);

  agInit(params: AgBudgetAttributeComponentParams): void {
    this.params = params;
    if (params.templateClasses) {
      this.templateClasses = params.templateClasses;
    }

    this.visible = params.columnsToCollapse?.some((c) => {
      // sometimes func doesn't return value
      // So collapse doesn't show correctly
      return params.api.getColumn(c)?.isVisible();
    });

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.params.api.setQuickFilter(value || '');
    });

    if (this.params.showCheckbox) {
      this.params.api.addEventListener('rowSelected', this.onSelectionChanged);
    }
  }

  onSelectionChanged = () => {
    const parent = this.params.api.getDisplayedRowAtIndex(0)?.parent;
    if (parent) {
      const allSelected = (parent.childrenAfterGroup || []).every((node) => node.isSelected());
      if (allSelected) {
        this.isSelected.set(true);
      } else {
        this.isSelected.set(
          (parent.childrenAfterGroup || []).some(
            (node) => node.isSelected() || node.isSelected() === undefined
          )
            ? undefined
            : false
        );
      }
    }
  };

  toggleExpand() {
    this.visible = !this.visible;
    AgSetColumnsVisible({
      gridApi: this.params.api,
      keys: this.params.columnsToCollapse,
      visible: this.visible,
    });
    if (this.params.localStorageKey)
      localStorage.setItem(this.params.localStorageKey, this.visible.toString());

    if (this.params.afterAttrToggle) {
      this.params.afterAttrToggle(this.params);
    }
  }

  toggleRowExpand(isExpanded: boolean) {
    this.params.api.forEachNode((node) => {
      if (isExpanded) {
        node.expanded = false;
      } else {
        const lvl = this.params.expandLevel();
        node.expanded = lvl !== -1 ? node.level < lvl : true;
      }
    });
    this.params.api.onGroupExpandedOrCollapsed();
  }

  refresh(): boolean {
    return false;
  }

  destroy() {
    if (this.params.showCheckbox) {
      this.params.api.removeEventListener('rowSelected', this.onSelectionChanged);
    }
  }

  onCheckboxChange() {
    if (this.isSelected()) {
      this.params.api.deselectAll();
    } else {
      this.params.api.selectAll();
    }
  }
}
