import { Injectable } from '@angular/core';
import {
  ChartConfiguration,
  ChartDataset,
  ElementOptionsByType,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipLabelStyle,
  TooltipOptions,
} from 'chart.js';
import { Utils } from '@shared/utils/utils';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';
import { min } from 'lodash-es';

export type PMChartLabel = number[];
export type PMChartType = 'line';

@Injectable()
export class TrialInsightsPaymentMilestoneChartService
  implements TrialInsightsChartModel<PMChartType, PMChartLabel>
{
  private minYAxes = 0;

  createDatasets = (paymentMilestones: PMChartLabel): ChartDataset<PMChartType>[] => {
    this.minYAxes = min(paymentMilestones) || 0;

    const chartLineColor = '#438C63';

    return [
      {
        data: paymentMilestones,
        backgroundColor: ['transparent'],
        borderColor: chartLineColor,
        hoverBackgroundColor: chartLineColor,
        borderWidth: 5,
        pointBackgroundColor: chartLineColor,
        pointBorderColor: chartLineColor,
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: chartLineColor,
        pointHoverBorderColor: chartLineColor,
      },
    ];
  };

  createChart = (
    datasets?: ChartDataset<PMChartType>[],
    labels?: string[]
  ): ChartConfiguration<PMChartType> => {
    return {
      type: 'line',
      options: this.chartOptions(),
      data: {
        datasets: datasets || [],
        labels: labels || [],
      },
    };
  };

  chartOptions = (): ChartConfiguration<PMChartType>['options'] => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      scales: {
        ...this.xAxesOptions().scales,
        ...this.yAxesOptions().scales,
      },
      plugins: this.pluginOptions(),
    };
  };

  tooltipOptions = (): DeepPartial<TooltipOptions<PMChartType>> => {
    return {
      boxPadding: 6,
      callbacks: {
        labelColor(tooltipItem): TooltipLabelStyle | void {
          return {
            backgroundColor: tooltipItem.dataset.borderColor as string,
            borderColor: tooltipItem.dataset.borderColor as string,
          };
        },
        label: (tooltipItem) => {
          if (!tooltipItem.raw) {
            return '';
          }

          return Utils.currencyFormatter(<number>tooltipItem.raw);
        },
      },
    };
  };

  pluginOptions = (): DeepPartial<PluginOptionsByType<PMChartType>> => {
    return {
      legend: {
        display: false,
      },
      tooltip: this.tooltipOptions(),
    };
  };

  elementOptions = (): DeepPartial<ElementOptionsByType<PMChartType>> => {
    return {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    };
  };

  xAxesOptions = (): DeepPartial<ScaleChartOptions<PMChartType>> => {
    return {
      scales: {
        x: {
          beginAtZero: true,
          title: {
            display: false,
          },
          ticks: {
            display: true,
          },
          grid: {
            display: false,
          },
        },
      },
    };
  };

  yAxesOptions = (): DeepPartial<ScaleChartOptions<PMChartType>> => {
    return {
      scales: {
        y: {
          display: true,
          position: 'right',
          border: {
            display: false,
          },
          min: this.minYAxes,
          beginAtZero: false,
          ticks: {
            display: true,
            callback: (value) => {
              return value ? Utils.currencyFormatter(<number>value) : `${value}$`;
            },
          },
        },
      },
    };
  };
}
