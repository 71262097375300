import { GridApi, IRowNode } from '@ag-grid-community/core';

export function AgGridGetData<T>(gridApi: GridApi) {
  const values: T[] = [];

  gridApi.forEachNode(({ data }) => {
    values.push({
      ...data,
    });
  });

  return values;
}

export function agGridAllVisibleData<T>(gridApi: GridApi): T[] {
  const allRows: T[] = [];

  gridApi.forEachNodeAfterFilter((rowNode: IRowNode) => {
    if (!rowNode?.aggData) {
      allRows.push(rowNode.data);
    }
  });

  return allRows;
}
