import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  inject,
  Input,
  input,
  signal,
} from '@angular/core';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { MapActivitiesNoRowsComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-no-rows/map-activities-no-rows.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {
  MapActivitiesModalParams,
  MapActivitiesModalReturnData,
  MapActivitiesTotals,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { ExportType, Utils } from '@shared/utils/utils';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { getMapActivitiesTabGridOptions } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities.constans';
import {
  EntityType,
  EventType,
  GqlService,
  listInvoiceActivityMappingsQuery,
  listUserNamesWithEmailQuery,
  NoteType,
  PermissionType,
  WorkflowStep,
} from '@shared/services/gql.service';
import { GridDynamicHeightDirective } from '@shared/directives/grid-dynamic-height.directive';
import { ButtonComponent } from '@shared/components/button/button.component';
import { decimalAdd } from '@shared/utils';
import dayjs, { Dayjs } from 'dayjs';
import { MapActivitiesTabGridRow } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-tab.model';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '@shared/components/input/input.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { MapActivitiesModalComponent } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.component';
import { OverlayService } from '@shared/services/overlay.service';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AuthService } from '@shared/store/auth/auth.service';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { MainQuery } from '@shared/store/main/main.query';
import { ExportExcelButtonComponent } from '@features/export-excel-button/export-excel-button.component';
import { EventService } from '@models/event/event.service';
import { isEqual } from 'lodash-es';
import { ChangeOrderSharedService } from '@pages/budget-page/tabs/change-order/state/change-order-shared.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'aux-map-activities-tab',
  templateUrl: 'map-activities-tab.component.html',
  imports: [
    MapActivitiesNoRowsComponent,
    AgGridAngular,
    GridDynamicHeightDirective,
    ButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    ExportExcelButtonComponent,
    TooltipDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapActivitiesTabComponent {
  @Input() invoiceAllocatedTotals$!: BehaviorSubject<MapActivitiesTotals>;

  private gqlService = inject(GqlService);
  private overlayService = inject(OverlayService);
  authService = inject(AuthService);
  eventService = inject(EventService);
  workflowQuery = inject(WorkflowQuery);
  mainQuery = inject(MainQuery);
  changeOrderSharedService = inject(ChangeOrderSharedService);

  invoice = input.required<InvoiceModel>();

  loading = signal(true);
  activitiesSaving = signal(false);
  notesSaving = signal(false);
  gridAPI!: GridApi;
  gridData: MapActivitiesTabGridRow[] = [];
  lastUpdatedList: Dayjs[] = [];
  lastUpdatedListWithUsers: { date: Dayjs; user: string }[] = [];
  noteId = '';
  gridOptions = computed(() => {
    const invoice = this.invoice();
    return getMapActivitiesTabGridOptions(invoice.organization.currency);
  });

  hasEditPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });
  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );
  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;

  btnTooltip = computed(() => {
    if (!this.hasEditPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (this.isInvoiceFinalized()) {
      return this.invoiceLockTooltip();
    }

    return '';
  });

  noteControl = new FormControl({ value: '', disabled: true });

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  constructor() {
    toObservable(this.invoice)
      .pipe(
        switchMap((invoice) => {
          return combineLatest([
            this.gqlService.listInvoiceActivityMappings$({ invoice_id: invoice.id || '' }),
            this.gqlService.listNotes$({
              entity_id: invoice.id || '',
              entity_type: EntityType.INVOICE_ACTIVITY_MAPPING,
            }),
          ]);
        })
      )
      .subscribe(([listInvoiceActivityMappingsResponse, listNotesResponse]) => {
        if (
          listInvoiceActivityMappingsResponse.success &&
          listInvoiceActivityMappingsResponse.data
        ) {
          this.implementActivitiesData(listInvoiceActivityMappingsResponse.data);
        }

        if (listNotesResponse.success && listNotesResponse.data?.length) {
          this.lastUpdatedList.push(dayjs(listNotesResponse.data[0].update_date));
          this.lastUpdatedListWithUsers.push({
            date: dayjs(listNotesResponse.data[0].update_date),
            user: listNotesResponse.data[0].updated_by,
          });
          this.noteId = listNotesResponse.data[0].id;
          this.noteControl.setValue(listNotesResponse.data[0].message || '');
        }

        this.loading.set(false);
      });

    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed())
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.changeOrderSharedService.users.set(user.sub, user);
        });
      });
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
    this.setBottomData();
    setTimeout(() => {
      this.gridAPI.sizeColumnsToFit();
    }, 200);
  }

  lastUpdated(): string {
    if (this.lastUpdatedList.length > 0) {
      const maxDate = dayjs.max(this.lastUpdatedList);
      const userId = this.lastUpdatedListWithUsers.find((user) =>
        isEqual(user.date, maxDate)
      )?.user;
      const userName = this.changeOrderSharedService.userFormatter(userId);

      return dayjs(maxDate)?.format('MMMM DD, YYYY [at] HH:mm') + ` by ${userName}` || '';
    }
    return '';
  }

  async openMapActivitiesModal(): Promise<void> {
    const ref = this.overlayService.openPopup<
      MapActivitiesModalParams,
      MapActivitiesModalReturnData,
      MapActivitiesModalComponent
    >({
      content: MapActivitiesModalComponent,
      settings: {
        header: 'Map Activities',
        primaryButton: {
          disabled: (instance) => !!instance?.isSaveButtonDisabled(),
          tooltip: (instance) => instance?.saveButtonTooltip() || '',
          action: (instance) => instance?.save(),
        },
      },
      data: {
        invoice: this.invoice(),
        lastUpdated: this.lastUpdated(),
        items: this.gridData,
        noteId: this.noteId,
        noteMessage: this.noteControl.value || '',
      },
    });

    const { data } = await firstValueFrom(ref.afterClosed$);

    if (data) {
      this.saveActivities(data);
    }
  }

  saveActivities(data: MapActivitiesModalReturnData): void {
    if (data.itemsToSave && data.itemsToSave?.length > 0) {
      this.activitiesSaving.set(true);
      this.gqlService
        .batchModifyInvoiceActivityMappings$({
          invoice_activity_mappings: data.itemsToSave,
          transaction_id: Utils.uuid(),
        })
        .subscribe((result) => {
          if (result.success) {
            this.implementActivitiesData(result.data || []);
            this.activitiesSaving.set(false);
          }
        });
    }

    if (data.isNotesChanged) {
      this.notesSaving.set(true);
      if (this.noteId) {
        if (data.notesMessage) {
          this.gqlService
            .updateNote$({
              id: this.noteId,
              message: data.notesMessage || '',
            })
            .subscribe((result) => {
              if (result.success) {
                this.getNotes();
                this.notesSaving.set(false);
              }
            });
        } else {
          this.gqlService.removeNote$(this.noteId).subscribe((result) => {
            if (result.success) {
              this.noteId = '';
              this.getNotes();
              this.notesSaving.set(false);
            }
          });
        }
      } else {
        this.gqlService
          .createNote$({
            entity_id: this.invoice().id || '',
            entity_type: EntityType.INVOICE_ACTIVITY_MAPPING,
            note_type: NoteType.NOTE_TYPE_GENERAL,
            message: data.notesMessage || '',
          })
          .subscribe((result) => {
            if (result.success) {
              this.getNotes();
              this.notesSaving.set(false);
            }
          });
      }
    }
  }

  async onExport() {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name || '';

    const result = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.invoice().id,
        payload: JSON.stringify({
          export_type: ExportType.INVOICE_ACTIVITY_MAPPINGS,
          filename: `auxilius_invoice-mapping-${trialShortName}_${this.invoice().invoice_no}_${dayjs().format(
            'YYYY.MM.DD-HHmmss'
          )}`,
          export_entity_id: this.invoice().id || '',
        }),
      })
    );
    if (result.success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(result.errors);
    }
  }

  implementActivitiesData(data: listInvoiceActivityMappingsQuery[]): void {
    const calculatedTotals: MapActivitiesTotals = {
      invoice_total: 0,
      ACTIVITY_SERVICE: 0,
      ACTIVITY_DISCOUNT: 0,
      ACTIVITY_PASSTHROUGH: 0,
      ACTIVITY_INVESTIGATOR: 0,
    };

    this.gridData = data
      ? data.map((item) => {
          if (item.activity_type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            calculatedTotals[item.activity_type] =
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              calculatedTotals[item.activity_type] + item.amount;
          }

          this.lastUpdatedList.push(dayjs(item.update_date));
          this.lastUpdatedListWithUsers.push({
            date: dayjs(item.update_date),
            user: item.updated_by,
          });
          return {
            id: item.id,
            invoice_item_description: item.invoice_item_description || '',
            activity_type: item.activity_type,
            activity_id: item.activity_id,
            activity_name: item.activity_name,
            amount: item.amount,
            display_order: item.display_order,
          };
        })
      : [];
    this.invoiceAllocatedTotals$.next(calculatedTotals);
  }

  private getNotes(): void {
    this.loading.set(true);
    this.gqlService
      .listNotes$({
        entity_id: this.invoice().id || '',
        entity_type: EntityType.INVOICE_ACTIVITY_MAPPING,
      })
      .subscribe((result) => {
        if (result.success && result.data?.length) {
          this.lastUpdatedList.push(dayjs(result.data[0].update_date));
          this.lastUpdatedListWithUsers.push({
            date: dayjs(result.data[0].update_date),
            user: result.data[0].updated_by,
          });
          this.noteId = result.data[0].id;
          this.noteControl.setValue(result.data[0].message || '');
        } else {
          this.noteId = '';
          this.noteControl.setValue('');
        }
        this.loading.set(false);
      });
  }

  private setBottomData(): void {
    const totals = this.calculatePinnedBottomData();
    this.gridAPI.setGridOption('pinnedBottomRowData', [
      {
        invoice_item_description: 'Total',
        ...totals,
      },
    ]);
  }

  private calculatePinnedBottomData(): {
    amount: number;
  } {
    const columnsWithAggregation = ['amount'] as const;

    this.gridAPI.selectAll();
    return this.gridAPI.getSelectedRows().reduce(
      (acc, row) => {
        for (const key of columnsWithAggregation) {
          const currentVal = acc[key];
          const additionalVal = row[key];
          if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
            acc[key] = decimalAdd(currentVal, additionalVal);
          }
        }

        return acc;
      },
      {
        amount: 0,
      }
    );
  }
}
