<div class="flex justify-between">
  <div class="flex items-start mb-4">
    <aux-input
      class="w-64 mr-4 mt-5"
      placeholder="Search"
      icon="Search"
      [(ngModel)]="quickFilterText"
      (change)="setTotalRowData()"
    />

    <form class="flex space-x-3" [formGroup]="investigatorSummaryFiltersForm">
      <aux-site-select-dropdown formControlName="site_ids" class="w-64" titleKey="name" />

      <aux-multi-select-dropdown
        placeholder="Select"
        label="Patient ID:"
        formControlName="patient_ids"
        class="w-52 mr-3"
        bindLabel="external_patient_id"
        bindValue="external_patient_id"
        [searchable]="true"
        [items]="$any(patientOptions$ | async)"
      />
    </form>
  </div>
  <div class="flex items-center">
    <aux-export-excel-button
      [gridAPI]="gridAPI"
      [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
      pendoTag="investigator-summary-excel-export-button"
    />
  </div>
</div>
<div class="relative">
  <ag-grid-angular
    class="ag-theme-aux"
    domLayout="autoHeight"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [quickFilterText]="quickFilterText"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="gridSizeChanged()"
    (sortChanged)="sortChanged$.next(null)"
    auxStickyGrid
  />

  <ng-container *ngIf="(gridRefresh$ | async) === true">
    <div class="absolute inset-0 bg-white flex">
      <div class="border-8 h-32 m-auto spinner w-32"></div>
    </div>
  </ng-container>
</div>
