import { Route, Routes } from '@angular/router';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@shared/services/launch-darkly.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { TrialInsightsClinicalComponent } from './components/trial-insights-clinical/trial-insights-clinical.component';
import { TrialInsightsFinanceComponent } from './components/trial-insights-finance/trial-insights-finance.component';
import { TrialInsightsComponent } from './trial-insights.component';

export const TRIAL_INSIGHTS_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.TRIAL_INSIGHTS.CLINICAL,
    component: TrialInsightsClinicalComponent,
    data: {
      feature: FeatureFlag.TRIAL_INSIGHTS,
    },
  },
  {
    path: ROUTING_PATH.TRIAL_INSIGHTS.FINANCE,
    component: TrialInsightsFinanceComponent,
    data: {
      feature: FeatureFlag.TRIAL_INSIGHTS,
    },
  },
];

export const buildTrialInsightsRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    ...buildRoutesByFeatureFlags(featureFlags, TRIAL_INSIGHTS_ROUTES_FF),
  ];

  const shouldDisplay = featureFlags ? featureFlags[FeatureFlag.TRIAL_INSIGHTS] : false;
  const canMatch = [() => shouldDisplay];

  return {
    path: ROUTING_PATH.TRIAL_INSIGHTS.INDEX,
    component: TrialInsightsComponent,
    children: [...enabledRoutes, { path: '**', redirectTo: ROUTING_PATH.TRIAL_INSIGHTS.CLINICAL }],
    canMatch,
  };
};
