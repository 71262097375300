<div class="pointer-events-auto">
  <div>No Data Found.</div>

  <div>
    To create a Prepayment for a vendor, check “Prepaid Impact” for an Invoice in the
    <a class="aux-link text-base cursor-pointer" [routerLink]="[invoiceLibraryPage]"
      >Invoice Library</a
    >.
  </div>
</div>
