<form [formGroup]="form" (ngSubmit)="onApply()">
  <aux-modal primaryButtonType="submit">
    <div class="w-screen max-w-2xl">
      <div class="flex">
        <div class="w-56">
          <span class="text-xs mb-1">Document Type:</span>
          <ng-select
            placeholder="Select"
            [multiple]="false"
            [appendTo]="'body'"
            [searchable]="false"
            [clearable]="true"
            formControlName="documentType"
            [items]="documentTypeOptions"
            bindValue="value"
            bindLabel="label"
          />
        </div>
        <div class="w-56 pl-4">
          <span class="text-xs mb-1">Vendor:</span>
          <ng-select
            placeholder="Select"
            [multiple]="false"
            [appendTo]="'body'"
            [searchable]="false"
            [clearable]="true"
            formControlName="vendor"
            [items]="documentLibrary.vendors"
            bindValue="value"
            bindLabel="label"
          />
        </div>
        <div class="w-56 pl-4">
          <span class="text-xs mb-1">Site:</span>
          <ng-select
            placeholder="Select"
            [multiple]="false"
            [appendTo]="'body'"
            [searchable]="false"
            [clearable]="true"
            formControlName="site"
            [items]="documentLibrary.sites"
            bindValue="value"
            bindLabel="label"
          />
        </div>
      </div>
    </div>
  </aux-modal>
</form>
