import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { firstValueFrom } from 'rxjs';
import { GqlService, listUserNamesWithEmailQuery } from '@shared/services/gql.service';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { MainQuery } from '@shared/store/main/main.query';
import { QuarterCloseChecklistPeriodCloseService } from '../../../../services/quarter-close-checklist-period-close.service';
import { QuarterCloseNotesQuery } from '@shared/store/quarter-close-notes/quarter-close-notes.query';
import {
  MonthlyReviewNote,
  QuarterCloseNotesStore,
} from '@shared/store/quarter-close-notes/quarter-close-notes.store';
import {
  ConfirmationActionModalData,
  ConfirmationActionModalComponent,
} from '@shared/components/modals/confirmation-action-modal/confirmation-action-modal.components';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-monthly-review-record',
  templateUrl: './monthly-review-record.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, TooltipDirective, AsyncPipe, DatePipe],
})
export class MonthlyReviewRecordComponent {
  private readonly destroyRef = inject(DestroyRef);

  @Input() record!: MonthlyReviewNote;

  pending = false;

  private readonly periodCloseService = inject(QuarterCloseChecklistPeriodCloseService);

  private readonly gqlService = inject(GqlService);

  private readonly authQuery = inject(AuthQuery);

  private readonly quarterCloseNotesStore = inject(QuarterCloseNotesStore);

  private readonly overlayService = inject(OverlayService);

  private readonly mainQuery = inject(MainQuery);

  private readonly quarterCloseNotesQuery = inject(QuarterCloseNotesQuery);

  async onRemove(): Promise<void> {
    this.pending = true;

    const ref = this.overlayService.openPopup<ConfirmationActionModalData, boolean>({
      modal: ConfirmationActionModalComponent,
      data: {
        width: '370px',
        skipKeywordConfirmation: true,
        executeActionText: 'Do you want to proceed?',
        message: 'This will permanently delete the Monthly Review Note.',
      },
      settings: {
        header: 'Delete Monthly Review Note?',
        primaryButton: {
          label: 'Delete',
          variant: 'negative',
        },
      },
    });

    const { data: refData } = await firstValueFrom(ref.afterClosed$);

    if (refData) {
      this.gqlService
        .removeNote$(this.record.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(({ errors, data }) => {
          if (errors.length) {
            this.overlayService.error(errors);
            this.pending = false;
          } else if (data) {
            this.quarterCloseNotesStore.removeMonthlyReviewNote(
              this.record.workflowId as string,
              this.record.id
            );
          }
        });
    } else {
      this.pending = false;
    }
  }

  get removeTooltip(): string {
    const author = this.mainQuery
      .userMap()
      .get(this.record.createdBy) as listUserNamesWithEmailQuery;
    if (author.sub !== this.authQuery.user().sub) {
      return 'You can only delete your own notes.';
    }
    if (this.periodCloseService.currentOpenMonth !== this.quarterCloseNotesQuery.selectedPeriod()) {
      return 'Monthly Review Notes cannot be deleted from closed months.';
    }
    return '';
  }

  get author(): string {
    const author = this.mainQuery
      .userMap()
      .get(this.record.createdBy) as listUserNamesWithEmailQuery;
    return author.email.includes('@auxili.us') && !this.authQuery.isAuxAdmin()
      ? 'Auxilius Expert'
      : `${author.given_name} ${author.family_name.slice(0, 1)}.`;
  }
}
