<div class="font-inter w-[500px]">
  <div class="mb-4 text-lg">
    Are you sure you want to delete <span class="font-bold">this row</span>? If you delete, it will
    be permanently lost.
  </div>

  @if (!ref.data?.createMode) {
    <aux-investigator-transaction-supporting-section
      #supportingSection
      [noteFormControl]="noteFormControl"
    />
  }
</div>
