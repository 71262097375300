import { CanvasComponentOptions } from '@shared/components/canvas-chart/canvas-chart.component';
import { ChartDataset } from 'chart.js';
import { CustomType } from '@shared/services/gql.service';
import { Distribution } from '../custom-drivers.component';
import { Utils } from '@shared/utils/utils';

export const DistributionType = {
  Units: 'Units',
  Dollars: 'Dollars',
  '%': 'Percentage (%)',
};

export const CustomDriverType = {
  [DistributionType.Units]: CustomType.CUSTOM_GROUP_UNITS,
  [DistributionType.Dollars]: CustomType.CUSTOM_GROUP_DOLLARS,
  [DistributionType['%']]: CustomType.CUSTOM_GROUP_PERCENTAGE,
} as const;

export class CustomCurvesConstants {
  static multiChartOptions(
    unit: string,
    data: Distribution[],
    labels: string[]
  ): CanvasComponentOptions {
    const colors = [
      {
        color: '#095b95',
        border: false,
      },
      {
        color: '#236262',
        border: true,
      },
    ].map(({ color, border }) => {
      return <ChartDataset<'line'>>{
        pointStyle: 'rectRounded',
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        borderWidth: border ? 3 : 0,
        pointHoverBorderWidth: 4,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHitRadius: 4,
      };
    });
    const lineData = data.every((distribution) => distribution.custom_amount == 0)
      ? []
      : data.reduce((acc: number[], el: Distribution, index: number) => {
          acc.push(
            index ? Number(el.custom_amount) + acc[index - 1] : Number(el.custom_amount) || 0
          );
          return acc;
        }, []);

    return {
      data: {
        labels,
        datasets: [
          {
            ...colors[0],
            data: data.map((x) => x.custom_amount || 0),
            label: `Planned ${unit}`,
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
          {
            ...colors[1],
            data: lineData,
            label: `Cumulative ${unit}`,
            type: 'line',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: (value) => (lineData.length || !value ? value : Utils.zeroHyphen),
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label(tooltipItem): string | string[] | void {
                const value = Number(tooltipItem.raw) || 0;
                return `${tooltipItem.datasetIndex === 0 ? 'Planned Monthly' : 'Cumulative'} ${unit}: ${value.toLocaleString(
                  'en-US',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}${unit === '%' ? '%' : ''}`;
              },
            },
          },
        },
      },
      legend: { bottom: true, right: false, left: false, top: false },
      type: 'line',
    };
  }
}
