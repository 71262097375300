import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GatherContractsRoutes } from '../../checklist-section-gather-contracts/gather-contracts-routes';

@Component({
  selector: 'aux-gather-contract-row-title',
  template: `
    Pending
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="GatherContractsRoutes.CHANGE_ORDER"
    >
      Change Orders </span
    >,
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="GatherContractsRoutes.PURCHASE_ORDERS"
      >Purchase Orders</span
    >, New
    <span class="btn--hyperlink px-0 cursor-pointer" [routerLink]="GatherContractsRoutes.VENDORS"
      >Vendors</span
    >, or
    <span class="btn--hyperlink px-0 cursor-pointer" [routerLink]="GatherContractsRoutes.BUDGET"
      >Budgets</span
    >
  `,
  standalone: true,
  styles: [
    `
      :host {
        flex-grow: 1;

        .link {
          color: rgba(9, 91, 149);
        }
      }
    `,
  ],
  imports: [RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GatherContractRowTitleComponent {
  readonly GatherContractsRoutes = GatherContractsRoutes;
}
