@if (
  (periodCloseComponent.loading$ | async) === true ||
  (loading$ | async) === true ||
  vendorEstimatesLoading
) {
  <div class="border-8 h-32 m-auto my-40 spinner w-32"></div>
} @else {
  <aux-table-panel class="px-7 py-7 mb-2 adjustment-panel">
    <div class="flex flex-col space-y-2 p-[15px]">
      <div class="flex justify-end">
        <div class="flex items-center space-x-4">
          <div class="flex">
            <aux-input
              placeholder="Search"
              [formControl]="search"
              iconPosition="left"
              icon="Search"
              class="w-[273px]"
              [inputClassName]="'h-[40px]'"
              hotKey="/"
              #searchInput
            />
          </div>

          <div class="border-r d-block h-10 spacing"></div>

          <aux-export-excel-button
            [clickFN]="onExport.bind(this)"
            [spinnerSize]="7"
            pendoTag="quarter-close-adjustments-excel-export-button"
          />

          @if ((editMode$ | async) === false) {
            <div
              *auxAuthorize="{
                permissions: [
                  'PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS',
                  'PERMISSION_EDIT_VENDOR_ESTIMATE'
                ]
              }"
              [auxTooltip]="$any(editButtonTooltip$ | async)"
            >
              <aux-button
                [disabled]="$any(editButtonDisabled$ | async)"
                (click)="onEditMode()"
                variant="primary"
                icon="Pencil"
                label="Edit"
              />
            </div>
          }

          @if ((editMode$ | async) === true) {
            <ng-container>
              <aux-button variant="secondary" icon="X" label="Cancel" (click)="onCancel()" />
              <aux-button
                *ngIf="(editMode$ | async) === true"
                label="Save"
                variant="success"
                [disabled]="editedRows.size === 0"
                [auxTooltip]="
                  editedRows.size === 0 ? 'You need to make one or more changes to save' : ''
                "
                [onClick]="onSave.bind(this)"
                icon="Check"
              />
            </ng-container>
          }
        </div>
      </div>
      <div class="flex justify-start">
        <div class="flex items-center space-x-2">
          <ng-select
            #monthSelect
            class="w-52 month-select"
            [formControl]="selected_month"
            [clearable]="false"
            [items]="months"
            bindLabel="label"
            bindValue="iso"
          >
            <ng-template let-item="item" ng-option-tmp>
              <div (click)="selectMonth($event, item.iso)" class="text-sm option-wrapper">
                {{ item.label }}
              </div>
            </ng-template>
          </ng-select>

          <aux-vendor-dropdown
            [formControl]="selected_vendor"
            [vendors]="$any(vendors$ | async)"
            [beforeSelect]="beforeFilterChanges((saveCheck$ | async)!!)"
            (onChange)="onCategoryDropdownChange()"
            [width]="260"
          />

          <ng-select
            class="w-44"
            bindLabel="label"
            bindValue="value"
            [formControl]="selected_category"
            [clearable]="false"
            [items]="categories"
          />
          <ng-select
            class="w-44"
            placeholder="Materiality: All"
            bindLabel="label"
            bindValue="value"
            [ngClass]="{
              'show-placeholder': selected_threshold.value === ''
            }"
            [formControl]="selected_threshold"
            [clearable]="false"
            [items]="materialityThresholds"
          />
        </div>
      </div>
    </div>

    @if (gridUpdated && !vendorEstimatesLoading) {
      <ag-grid-angular
        style="min-width: 1000px; max-width: 100%"
        class="ag-theme-aux tabular-nums w-full adjustment-table flat-table-theme"
        domLayout="autoHeight"
        [quickFilterText]="search.value ?? ''"
        fillHandleDirection="y"
        [gridOptions]="$any(gridOptions$ | async)"
        [rowData]="$any(filteredGridData$ | async)"
        [enableFillHandle]="(editMode$ | async) === true"
        [groupAllowUnbalanced]="true"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (filterChanged)="onFilterChanged()"
        [overlayNoRowsTemplate]="showCantEdit ? cantEditMessage : undefined"
        auxStickyGrid
      />
    }
  </aux-table-panel>
}
