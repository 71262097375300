import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { GridApi, ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '@shared/components/input/input.component';
import {
  ExtendableOptionsDropdownComponent,
  Option,
} from '@shared/components/extendable-options-dropdown/extendable-options-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MapActivitiesLineItemOption,
  MapActivitiesModalService,
} from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.service';
import { NgClass } from '@angular/common';
import { MapActivitiesGridRow } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.model';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';

export type AgExtendableOptionsDropdownParams = ICellRendererParams & {
  validateCell: (isInvalidValue: boolean, data: MapActivitiesGridRow, node: IRowNode) => void;
};

@Component({
  selector: 'aux-ag-extendable-options-dropdown',
  templateUrl: './ag-extendable-options-dropdown.component.html',
  styleUrl: './ag-extendable-options-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    ExtendableOptionsDropdownComponent,
    NgSelectModule,
    NgClass,
  ],
})
export class AgExtendableOptionsDropdownComponent implements ICellRendererAngularComp {
  mapActivitiesModalService = inject(MapActivitiesModalService);
  launchDarklyService = inject(LaunchDarklyService);
  params!: AgExtendableOptionsDropdownParams;
  formControl = new FormControl('');
  gridApi!: GridApi;

  updateOptions = effect(() => {
    this.mapActivitiesModalService.invoiceItemOptions();
  });

  isAutoMapActivityAmount = this.launchDarklyService.$select(
    (flags) => flags.automap_amount_invoice_activity
  );

  stopEditing(): void {}

  agInit(params: AgExtendableOptionsDropdownParams): void {
    this.params = params;
    this.gridApi = params.api;
    this.formControl.setValue(params.value);
  }

  getValue() {
    return this.formControl.value;
  }

  refresh(): boolean {
    return false;
  }

  noAction(): void {}

  get initialSearchTerm(): string {
    return '';
  }

  onAddOption(option: Option): void {
    this.onChange();
    this.mapActivitiesModalService.addInvoiceItemOptions(option);
    this.params.api.refreshCells({ force: true });
  }

  onChange(newValueOption?: MapActivitiesLineItemOption | string): void {
    if (typeof newValueOption !== 'string') {
      if (this.isAutoMapActivityAmount() && newValueOption?.isExistingItem) {
        this.params.node.updateData({
          ...this.params.node.data,
          invoice_item_description: this.formControl.value,
          amount: newValueOption.initialItemValue,
        });
      } else {
        this.params.node.updateData({
          ...this.params.node.data,
          invoice_item_description: this.formControl.value,
        });
      }
    }

    this.params.validateCell(
      !this.params.data.amount || !this.params.data.activity_id,
      this.params.node.data,
      this.params.node
    );
  }
}
