<div *ngIf="title" class="text-xl font-semibold mb-4">
  {{ title }}
</div>

<div class="p-4 rounded-lg bg-aux-gray-light flex">
  <aux-input
    #notesTextArea
    class="flex-grow"
    inputClassName="resize-none max-h-64 border-0 bg-transparent !outline-none !shadow-none w-full !max-w-full !text-lg text-aux-gray-darkest !font-normal"
    resize="false"
    [textArea]="true"
    [rows]="1"
    [formControl]="notesControl"
    [placeholder]="placeholder"
    [textareaAutoresize]="true"
    [disabled]="!editMode"
  />

  <div class="w-[150px] min-w-[150px] flex justify-end items-start pt-0.5">
    @if (editMode) {
      <aux-button class="mr-2" variant="primary" label="Save" (click)="onSave()" />

      <aux-button variant="secondary" classList="text-sm" label="Cancel" (click)="cancel()" />
    } @else {
      <aux-button
        variant="custom"
        icon="Pencil"
        svgAttributes="text-aux-blue"
        [classList]="classListForEdit"
        [ngClass]="{
          'opacity-50 cursor-not-allowed': !hasEditPermission
        }"
        type="button"
        [auxTooltip]="!hasEditPermission ? disabledTooltipText : ''"
        [disabled]="!hasEditPermission"
        (click)="edit()"
      />
    }
  </div>
</div>
