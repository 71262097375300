import { Component, computed, inject, Signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { OverlayService } from '@shared/services/overlay.service';
import { EntityType, EventType, WorkflowStep } from '@shared/services/gql.service';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { EventQuery } from '@models/event/event.query';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventService } from '@models/event/event.service';

interface ButtonConfig {
  id: string;
  flag: Signal<string>;
  onClick: () => Promise<void>;
}

const MessagesConstants = {
  BILL_COM_RETRIEVAL: 'Beginning Bill.com data retrieval...',
  COUPA_RETRIEVAL: 'Beginning Coupa data retrieval...',
  DYNAMICS365_RETRIEVAL: 'Beginning Dynamics 365 data retrieval...',
  DYNAMICS365_FO_RETRIEVAL: 'Beginning Dynamics 365 F&O data retrieval...',
  NETSUITE_RETRIEVAL: 'Beginning Netsuite data retrieval...',
  ORACLE_FUSION_RETRIEVAL: 'Beginning Oracle Fusion data retrieval...',
  QUICKBOOKS_RETRIEVAL: 'Beginning Quickbooks Online data retrieval...',
  SAGE_INTACCT_RETRIEVAL: 'Beginning Sage Intacct data retrieval...',
};

const getIntegrationButtons = (): ButtonConfig[] => {
  const launchDarklyService = inject(LaunchDarklyService);
  const eventService = inject(EventService);
  const overlayService = inject(OverlayService);

  const sendEvent = async (eventType: EventType, msg: string) => {
    await firstValueFrom(
      eventService.processEvent$({
        type: eventType,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );

    overlayService.success(msg);
  };

  return [
    {
      id: 'bill-com',
      flag: launchDarklyService.$select((flags) => flags.cronjob_bill_com_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_BILL_COM, MessagesConstants.BILL_COM_RETRIEVAL);
      },
    },
    {
      id: 'coupa',
      flag: launchDarklyService.$select((flags) => flags.cronjob_coupa_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_COUPA, MessagesConstants.COUPA_RETRIEVAL);
      },
    },
    {
      id: 'dynamics365',
      flag: launchDarklyService.$select((flags) => flags.cronjob_dynamics365_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_DYNAMICS365, MessagesConstants.DYNAMICS365_RETRIEVAL);
      },
    },
    {
      id: 'dynamics365-fo',
      flag: launchDarklyService.$select((flags) => flags.cronjob_dynamics365_fo_integration),
      onClick: async () => {
        await sendEvent(
          EventType.REFRESH_DYNAMICS365_FO,
          MessagesConstants.DYNAMICS365_FO_RETRIEVAL
        );
      },
    },
    {
      id: 'netsuite',
      flag: launchDarklyService.$select((flags) => flags.cronjob_netsuite_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_NETSUITE, MessagesConstants.NETSUITE_RETRIEVAL);
      },
    },
    {
      id: 'oracle-fusion',
      flag: launchDarklyService.$select((flags) => flags.cronjob_oracle_fusion_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_ORACLE_FUSION, MessagesConstants.ORACLE_FUSION_RETRIEVAL);
      },
    },
    {
      id: 'quickbooks-online',
      flag: launchDarklyService.$select((flags) => flags.cronjob_quickbooks_online_integration),
      onClick: async () => {
        await sendEvent(
          EventType.REFRESH_QUICKBOOKS_ONLINE,
          MessagesConstants.QUICKBOOKS_RETRIEVAL
        );
      },
    },
    {
      id: 'sage-intacct',
      flag: launchDarklyService.$select((flags) => flags.cronjob_sage_intacct_integration),
      onClick: async () => {
        await sendEvent(EventType.REFRESH_SAGE_INTACCT, MessagesConstants.SAGE_INTACCT_RETRIEVAL);
      },
    },
  ];
};

@Component({
  standalone: true,
  selector: 'aux-invoices-sync-buttons',
  template: `
    @for (button of buttons; track button.id) {
      @if (button.flag()) {
        <aux-button
          [attr.data-id]="button.id"
          icon="CloudDownload"
          label="Sync Invoices"
          variant="secondary"
          [auxTooltip]="invoiceLockTooltip()"
          [disabled]="isButtonDisabled()"
          [onClick]="button.onClick.bind(this)"
        />
      }
    }
  `,
  imports: [ButtonComponent, TooltipDirective, AsyncPipe],
  styles: [
    `
      :host {
        @apply flex flex-wrap gap-1.5;
      }
    `,
  ],
})
export class InvoicesSyncButtonsComponent {
  workflowQuery = inject(WorkflowQuery);
  eventQuery = inject(EventQuery);

  buttons: ButtonConfig[] = getIntegrationButtons();

  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;

  iCloseMonthsProcessing = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isInvoiceFinalized = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  isButtonDisabled = computed(() => this.iCloseMonthsProcessing() || this.isInvoiceFinalized());
}
