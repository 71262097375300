import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-forecast-accrual-page',
  templateUrl: './forecast-accruals-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastAccrualsPageComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Forecast Methodology',
      show: toObservable(
        this.launchDarklyService.$select((flags) => flags.forecast_methodology_tab)
      ),
      route: ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY,
    },
    {
      label: 'Timeline',
      route: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX,
    },
    {
      label: 'Custom Drivers',
      show: toObservable(this.launchDarklyService.$select((flags) => flags.custom_drivers)),
      route: ROUTING_PATH.FORECAST_ROUTING.CUSTOM_DRIVERS,
    },
    {
      label: 'Patient Drivers',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_drivers),
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX,
    },
    {
      label: 'Site Drivers',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_drivers),
      route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX,
    },
    {
      label: 'Investigator Forecast',
      route: ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST,
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_forecast),
    },
    {
      label: 'Study Specifications',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_studyspecifications),
      route: ROUTING_PATH.FORECAST_ROUTING.STUDY_SPECIFICATIONS,
    },
  ];

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
