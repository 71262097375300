<section class="flex justify-between items-center mb-4">
  <aux-card
    topText="Total (USD)"
    [middleText]="totalCost$ | async | money"
    className="min-w-[230px]"
  />
  <div class="flex items-center space-x-4">
    <p *ngIf="!!(lastSourceRefreshDate$ | async)">
      Updated: {{ lastSourceRefreshDate$ | async | date: 'MMMM d, y, h:mm a' }}
    </p>
    <div class="h-7 w-px bg-aux-gray-dark" *ngIf="!!(lastSourceRefreshDate$ | async)"></div>
    <aux-checkbox
      class="text-sm"
      [checked]="(isContractSelected$ | async)!"
      [disabled]="!!(editMode$ | async)!"
      (customChange)="toggleContractCurrency($event)"
    >
      Show Contracted Currency
    </aux-checkbox>
    <aux-exchange-link />
    <div class="h-7 w-px bg-aux-gray-dark"></div>
    <aux-export-excel-button
      [clickFN]="onExportInvestigatorTransactions.bind(this)"
      [loading]="isBtnLoading('export') | async"
      [spinnerSize]="7"
      pendoTag="investigator-transactions-excel-export-button"
    />
  </div>
</section>
