<aux-button
  [variant]="'secondary'"
  icon="FileExport"
  [label]="title"
  [onClick]="exportExcel"
  [className]="className"
  [loading]="loading"
  [disabled]="disabled"
  [spinnerSize]="spinnerSize"
  [attr.data-pendo-id]="pendoTag"
/>
