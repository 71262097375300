<form class="flex flex-col h-full bg-white border border-aux-gray-dark" [formGroup]="filterForm">
  <div class="flex items-center justify-between py-2 px-4">
    <div class="flex items-center">
      <aux-icon name="Checkbox" class="mr-2" />
      <p class="font-bold text-lg">Tasks</p>
    </div>

    <div class="flex">
      <ng-select
        formControlName="trial"
        class="select w-40"
        bindValue="value"
        placeholder="Trial"
        [items]="trialOptions"
        [searchable]="false"
        [appendTo]="'body'"
      />

      <ng-select
        class="select w-40 ml-1.5"
        formControlName="type"
        bindValue="value"
        placeholder="Type"
        [items]="taskTypeOptions"
        [searchable]="false"
        [appendTo]="'body'"
      />
    </div>
  </div>
  <aux-portfolio-task-list
    *ngIf="{
      filteredUserListTasks: (filteredUserListTasks$ | async) || [],
      originalUserListTasks: (originalUserListTasks$ | async) || []
    } as obj"
    class="grow overflow-y-auto h-full"
    [tasks]="obj.filteredUserListTasks"
    [noTasksMessage]="
      !obj.filteredUserListTasks.length && obj.originalUserListTasks.length
        ? noTasksMessages.FILTER
        : noTasksMessages.NO_TASKS
    "
  />
</form>
