import { Component } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { SitesQuery } from '@models/sites/sites.query';
import { Maybe, Utils } from '@shared/utils/utils';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { getSiteWorkflow } from '../site-workflow';
import { OverlayService } from '@shared/services/overlay.service';
import { isString } from 'lodash';

@Component({
  selector: 'aux-site-info',
  templateUrl: './site-info.components.html',
})
export class SiteInfoComponent {
  editMode$ = new BehaviorSubject(false);

  siteWorkflow = getSiteWorkflow();

  site$ = this.sitesQuery.selectActive().pipe(
    map(([site]) => ({
      ...site,
      investigator_name: this.vendorQuery.getEntity(site?.managed_by_id)?.name,
      primary_investigator_name: this.getPrimaryInvestigatorName(
        site?.investigator?.family_name,
        site?.investigator?.given_name
      ),
      address_2: this.getAddressLineWithCorrectComma(
        site?.address_line_1,
        site?.address_line_2,
        site?.address_line_3
      ),
      address_3: this.getAddressLineWithCorrectComma(site?.city, site?.state, site?.zip),
      country_name: Utils.getCountryName(site?.country || ''),
    }))
  );

  siteChanged = false;

  constructor(
    private sitesQuery: SitesQuery,
    private vendorQuery: OrganizationQuery,
    private overlayService: OverlayService
  ) {}

  editMode = () => {
    this.editMode$.next(true);
  };

  getPrimaryInvestigatorName(familyName?: string, givenName?: string) {
    let fullName = '';

    if (givenName) {
      fullName += givenName;
    }

    if (familyName) {
      fullName += ` ${familyName}`;
    }

    return fullName.trim();
  }

  private getAddressLineWithCorrectComma(...rest: Maybe<string>[]): string {
    return rest
      .filter((value) => isString(value) && !!value)
      .reduce(
        (accum, address, index) => (index === 0 ? address : `${accum}, ${address}`.trim()),
        ''
      ) as string;
  }

  siteDataChanged(changed: boolean) {
    this.siteChanged = changed;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.editMode$.value && this.siteChanged) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }

    return true;
  }
}
