import { Injectable } from '@angular/core';
import { listInvoicesForReconciliationQuery } from '@shared/services/gql.service';
import dayjs from 'dayjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { QuarterDate } from '../../../period-close.component';
import { QuarterCloseChecklistWorkflowService } from './quarter-close-checklist-workflow.service';

@Injectable()
export class QuarterCloseChecklistPeriodCloseService {
  constructor(private workflowService: QuarterCloseChecklistWorkflowService) {}

  // Only set by period-close
  isAdminUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Set by both period-close and quarter-close-checklist
  // period-close:
  // Determines header title and pulls latest workflow
  // quarter-close-checklist:
  // Determines header title, pulls latest workflow, etc..
  selectedQuarterMonth = '';

  selectedQuarterMonthChanged$ = new Subject();

  // Set by both quarter-close-checklist and in-month adjustments
  // 1. selectedQuarterMonth reflects the current page's actual month selection
  // 2. persistedQuarterMonth reflects the last selected month from
  //    the quarter-close-checklist and in-month-adjustments month dropdowns
  persistedQuarterMonth = '';

  // Set by both period-close and quarter-close-checklist
  // period-close:
  // Is only set for quarter-close-checklist purposes
  // quarter-close-checklist:
  // Determines quarterMonths and isPastQuarterMonth
  currentOpenMonth = '';

  // Only set by period-close
  // Is only set for quarter-close-checklist purposes
  // (to determine actual quarter months)
  quarterMonths: QuarterDate[] = [];

  // Only set by period-close
  // Is only set for quarter-close-checklist purposes
  // (to set as a class variable)
  isCurrentQuarterSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Only set by period-close
  // Is only set for quarter-close-checklist purposes
  // (to calculate invoice quick view)
  ungroupedInvoices: listInvoicesForReconciliationQuery[] = [];

  // The 'selectedQuarterMonth' is different
  // for each of the period-close-tabs.
  // Using this getter allows us to set different
  // values from each page, but return custom values
  getSelectedQuarterMonth(route: string): string {
    if (route.includes('quarter-close') || route.includes('reconciliation')) {
      return this.getLatestMonth();
    } else if (route.includes('adjustments') || route.includes('journal-entries')) {
      return this.getInMonthAdjustmentsMonth();
    } else {
      return this.getChecklistMonth();
    }
  }

  getInMonthAdjustmentsMonth(): string {
    if (!this.persistedQuarterMonth) {
      return this.workflowService.parseToStartOfMonth(this.selectedQuarterMonth);
    }

    return this.workflowService.parseToStartOfMonth(this.persistedQuarterMonth);
  }

  // quarter-close-checklist doesn't allow
  // selecting a month after the current open month.
  getChecklistMonth(): string {
    if (!this.persistedQuarterMonth) {
      return this.workflowService.parseToStartOfMonth(this.selectedQuarterMonth);
    }

    if (this.isAfterOpenMonth(this.persistedQuarterMonth)) {
      return this.getLatestMonth();
    } else {
      return this.workflowService.parseToStartOfMonth(this.persistedQuarterMonth);
    }
  }

  getLatestMonth(): string {
    // Return default
    if (!this.quarterMonths.length) {
      return this.workflowService.parseToStartOfMonth(this.selectedQuarterMonth);
    }

    // Return latest month on past quarter
    if (!this.isCurrentQuarterSelected.getValue()) {
      const lastIndex = this.quarterMonths.length - 1;
      const lastMonth = this.quarterMonths[lastIndex].iso;

      return this.workflowService.parseToStartOfMonth(lastMonth);
    }

    // Return default
    if (!this.currentOpenMonth) {
      return this.workflowService.parseToStartOfMonth(this.selectedQuarterMonth);
    }

    // Return latest open month on current quarters

    const currentOpenMonth = dayjs(this.currentOpenMonth).date(1);

    const filteredQuarterMonths = this.quarterMonths.filter((month) => {
      return !month.parsedDate.date(1).isAfter(currentOpenMonth);
    });

    const lastIndex = filteredQuarterMonths.length - 1;
    const lastMonth = this.quarterMonths[lastIndex].iso;

    return this.workflowService.parseToStartOfMonth(lastMonth);
  }

  isAfterOpenMonth(selectedMonth: string): boolean {
    const currentMonth = this.currentOpenMonth;
    const currentMonthDate = dayjs(currentMonth).month();
    const selectedMonthDate = dayjs(selectedMonth).month();

    return selectedMonthDate > currentMonthDate;
  }

  resetAllState() {
    this.selectedQuarterMonth = '';
    this.persistedQuarterMonth = '';
    this.currentOpenMonth = '';
    this.quarterMonths = [];
    this.isCurrentQuarterSelected.next(true);
    this.ungroupedInvoices = [];
  }
}
