import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { EventService } from '@models/event/event.service';
import { MainQuery } from '@shared/store/main/main.query';
import { TrialInsightsStore } from '@models/trial-insights/trial-insights.store';
import { BudgetService } from '@features/budget/services/budget.service';
import { SnapshotService } from '@features/budget/services/snapshot.service';
import { GenericBudgetCompareMenuComponent } from '@widgets/budget-compare-menu/classes/budget-compare-menu.class';

@Component({
  selector: 'aux-trial-insights-finance-bva-chart-menu',
  templateUrl: './bva-chart-menu.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceBvaChartMenuComponent
  extends GenericBudgetCompareMenuComponent
  implements OnInit
{
  constructor(
    public cdr: ChangeDetectorRef,
    public mainQuery: MainQuery,
    public eventService: EventService,
    public organizationService: OrganizationService,
    public organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    public snapshotService: SnapshotService,
    public budgetService: BudgetService,
    public trialInsightsStore: TrialInsightsStore
  ) {
    super({
      cdr,
      mainQuery,
      eventService,
      organizationService,
      organizationStore,
      organizationQuery,
      snapshotService,
      budgetService,
      trialInsightsStore,
    });
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }
}
