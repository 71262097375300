import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonToggleItem } from './button-toggle-item.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aux-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, AsyncPipe, NgClass],
})
export class ButtonToggleGroupComponent {
  @Input() value$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input() items: ButtonToggleItem[] = [];

  isActive(itemValue: string): boolean {
    return itemValue === this.value$.getValue();
  }
}
