import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  ExcelExportParams,
  GridApi,
  GridOptions,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { combineLatest, firstValueFrom, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { ButtonComponent } from '@shared/components/button/button.component';
import { AuthService } from '@shared/store/auth/auth.service';
import {
  ActivityType,
  Currency,
  EntityType,
  EventType,
  getInvoiceDetailsGridQuery,
  GqlService,
  PermissionType,
  UpdateInvoiceDetailInput,
} from '@shared/services/gql.service';
import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { TableConstants } from '@shared/constants/table.constants';
import { Utils } from '@shared/utils/utils';
import { removeSymbolsFromString } from '@shared/utils/formatter.utils';
import { OverlayService } from '@shared/services/overlay.service';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { StickyGridDirective } from '@shared/directives/sticky-grid/sticky-grid.directive';
import { AuxExcelStyles, decimalAdd } from '@shared/utils';
import { MainQuery } from '@shared/store/main/main.query';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { EventService } from '@models/event/event.service';
import { MainStore } from '@shared/store/main/main.store';
import { invoiceDisabledTooltip } from '@pages/vendor-payments-page/tabs/invoices/invoices.component';

import { InvoiceItemsComponent } from './invoice-items.component';
import { InvoiceItemsNoRowsComponent } from './invoice-items-no-rows.component';
import { EventQuery } from '@models/event/event.query';
import { InvoiceFormUtils } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-form.utils';
import { MapActivitiesModalService } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-modal/map-activities-modal.service';

export interface InvoiceItemColumnInfo {
  headerName: string;
  field: string;
  type: InvoiceItemColumnType;
}

export enum InvoiceItemColumnType {
  INVOICE_ID = 'INVOICE_ID',
  LINE_ITEM_ID = 'LINE_ITEM_ID',
  ITEM = 'ITEM',
  OTHER = 'OTHER',
  QUANTITY = 'QUANTITY',
  PRICE = 'PRICE',
  UNIT_PRICE = 'UNIT_PRICE',
  PRODUCT_CODE = 'PRODUCT_CODE',
}

const getValueFormatter = (columnType: InvoiceItemColumnType, currency: Currency) => {
  if (
    columnType === InvoiceItemColumnType.UNIT_PRICE ||
    columnType === InvoiceItemColumnType.PRICE
  ) {
    return {
      valueFormatter: (params: ValueFormatterParams) => {
        return Utils.agCurrencyFormatterAccounting(params, currency || Currency.USD, true);
      },
    };
  }

  return {
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.node?.rowPinned === 'bottom' && !params.value) {
        return Utils.zeroHyphen;
      }
      return;
    },
  };
};

const currencyCodes = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
  'INR',
  'JPY',
  'CHF',
  'AUD',
  'CNY',
  'BZR',
  'SEK',
  'HKD',
];

const parseColumnDefs = (
  columnDefs: string,
  currency: Currency
): (ColDef & { field: string })[] => {
  return JSON.parse(columnDefs)
    .sort((a: { field: string }, b: { field: string }) => a.field.localeCompare(b.field))
    .map(
      (column: InvoiceItemColumnInfo) =>
        <ColDef>{
          ...column,
          cellClass: (params: CellClassParams) => {
            if (
              params.colDef.type === InvoiceItemColumnType.UNIT_PRICE ||
              params.colDef.type === InvoiceItemColumnType.PRICE
            ) {
              return [`budgetCost${currency}`, TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT];
            }

            if (params.colDef.type === InvoiceItemColumnType.QUANTITY) {
              return [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT];
            }

            return ['!block text-left max-w truncate'];
          },
          tooltipField: column.field,
          tooltipValueGetter: (params: { valueFormatted: string }) => {
            return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
          },
          ...getValueFormatter(column.type, currency),
          resizable: true,
          sortable: false,
        }
    );
};

const refactorToNumbers = (value: string): string => {
  const refactoredValue = removeSymbolsFromString(value, [...currencyCodes, '$', ' ', ',']);

  return Utils.isNumber(refactoredValue) ? refactoredValue : '';
};

const calculatePinnedBottomData = (
  gridData: Record<
    string,
    {
      value: string | number;
    }
  >[],
  columnsToUpdate: string[]
) => {
  const obj = columnsToUpdate.reduce(
    (accum, value) => {
      return {
        ...accum,
        [value]: {
          value: 0,
        },
      };
    },
    {} as Record<
      string,
      {
        value: number;
      }
    >
  );

  return gridData.reduce((acc, value) => {
    for (const key of columnsToUpdate) {
      const currentVal = acc[key]?.value;
      const additionalVal = value[key]?.value;

      if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
        acc[key].value = decimalAdd(currentVal, additionalVal);
      }
    }

    return acc;
  }, obj);
};

@Component({
  standalone: true,
  selector: 'aux-invoice-items-via-pdf',
  template: `
    @if (loading()) {
      <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
    } @else {
      <div class="my-4">
        @if (isOldInvoice()) {
          <aux-invoice-items-tab [invoice]="invoice()" [items]="invoice().ocr_line_items || []" />
        } @else {
          <div class="my-4 flex flex-row-reverse">
            <aux-button
              variant="secondary"
              label="Export"
              icon="FileExport"
              [disabled]="gridData().length === 0 || editMode()"
              (click)="getDynamicExcelParams()"
            />

            <div class="flex">
              @if (editMode()) {
                <aux-button
                  variant="secondary"
                  label="Cancel"
                  icon="X"
                  class="mr-2"
                  (click)="onCancel()"
                />

                <aux-button
                  variant="success"
                  label="Save"
                  icon="Check"
                  class="mr-2"
                  [disabled]="!hasChanges()"
                  [onClick]="onSave"
                />
              } @else {
                <aux-button
                  variant="secondary"
                  label="Edit"
                  icon="Pencil"
                  class="mr-2"
                  [disabled]="isEditBtnDisabled()"
                  [auxTooltip]="getEditBtnTooltip()"
                  (click)="editMode.set(true)"
                />
              }
            </div>
          </div>

          @if (gridData().length > 0) {
            <ag-grid-angular
              class="ag-theme-aux tabular-nums"
              domLayout="autoHeight"
              [rowData]="gridData()"
              [gridOptions]="gridOptions()"
              [enableFillHandle]="editMode()"
              (gridReady)="gridAPI.set($event.api)"
              (firstDataRendered)="autosize()"
              (cellValueChanged)="onCellValueChanged($event)"
              auxStickyGrid
            />
          } @else {
            <aux-invoice-items-no-rows [invoice]="invoice()" />
          }
        }
      </div>
    }
  `,
  imports: [
    AgGridAngular,
    ButtonComponent,
    TooltipDirective,
    InvoiceItemsComponent,
    InvoiceItemsNoRowsComponent,
    StickyGridDirective,
  ],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceItemsViaPdfComponent implements OnInit {
  authService = inject(AuthService);

  authQuery = inject(AuthQuery);

  gqlService = inject(GqlService);

  overlayService = inject(OverlayService);

  mainQuery = inject(MainQuery);

  workflowQuery = inject(WorkflowQuery);

  eventService = inject(EventService);

  mainStore = inject(MainStore);

  eventQuery = inject(EventQuery);

  private mapActivitiesModalService = inject(MapActivitiesModalService);

  private readonly destroyRef = inject(DestroyRef);

  invoice = input.required<InvoiceModel>();

  gridAPI = signal<GridApi | null>(null);
  gridDataLoading = signal(true);
  editMode = signal(false);
  colDefs = signal<ColDef[]>([]);
  gridData = signal<
    Record<
      string,
      {
        value: string | number;
      }
    >[]
  >([]);
  gridChangeList = signal<UpdateInvoiceDetailInput[]>([]);

  invoiceLockTooltip = this.workflowQuery.invoiceLockTooltip;

  eventProcessing = computed(() => {
    return this.eventQuery.selectProcessingEvent(EventType.INVOICE_DOCUMENT_UPLOADED)();
  });

  hasEditPermission = this.authService.$isAuthorized({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });

  hasChanges = computed(() => {
    return this.gridChangeList().length > 0;
  });
  gridOptions = computed(() => {
    const columnDefs = this.colDefs();

    return untracked(
      () =>
        <GridOptions>{
          defaultColDef: {
            ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
          },
          ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
          columnDefs: columnDefs.map((def) => {
            return {
              ...def,
              flex: 1,
              editable: (cell: EditableCallbackParams) => {
                if (
                  cell.node.group ||
                  (cell.node.data.activity_type === ActivityType.ACTIVITY_DISCOUNT &&
                    cell.colDef.field !== 'vendor_estimate_amount' &&
                    cell.colDef.field !== 'vendor_estimate_amount_ltd') ||
                  cell.node?.isRowPinned()
                ) {
                  return false;
                }

                return this.editMode();
              },
            };
          }),
          enableRangeSelection: true,
          suppressCellFocus: false,
          excelStyles: [
            ...AuxExcelStyles,
            ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
          ],
        }
    );
  });
  isOldInvoice = computed(() => {
    const invoice = this.invoice();
    const gridData = this.gridData();
    const items = invoice.ocr_line_items || [];
    return gridData.length < 1 && items.length > 0;
  });
  loading = computed(() => this.eventProcessing() || this.gridDataLoading());
  statusCheck = computed(() => {
    const trial = this.mainQuery.selectedTrial();
    const { currentOpenMonth } = this.mainQuery.mainState();
    const invoice = this.invoice();

    return InvoiceFormUtils.isAccrualPeriodInClosedMonth({ invoice, trial, currentOpenMonth });
  });

  isEditBtnDisabled = computed(() => {
    const isAdmin = this.authQuery.adminUser();

    return (
      (!this.hasEditPermission() && !isAdmin) ||
      !!this.invoiceLockTooltip() ||
      this.statusCheck() ||
      this.gridData().length < 1
    );
  });

  getEditBtnTooltip = computed(() => {
    const isAdmin = this.authQuery.adminUser();
    if (!isAdmin && !this.hasEditPermission()) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (this.statusCheck()) {
      return invoiceDisabledTooltip;
    }

    return this.invoiceLockTooltip();
  });

  editModeEffect = effect(() => {
    const gridAPI = this.gridAPI();
    const editMode = this.editMode();
    const colDefs = this.colDefs();
    if (!gridAPI) {
      return;
    }
    if (editMode) {
      gridAPI.startEditingCell({
        rowIndex: 0,
        colKey: colDefs[0].field || '',
      });
    }
    gridAPI.refreshCells({ force: true });
  });
  setBottomData = effect(() => {
    const gridAPI = this.gridAPI();
    const gridData = this.gridData();
    this.gridChangeList();

    if (!gridAPI) {
      return;
    }

    untracked(() => {
      const col = this.colDefs()[0].field?.replace('.value', '') || '';
      const pinnedBottomData = calculatePinnedBottomData(gridData, this.columnsWithNumbers);
      gridAPI.setGridOption('pinnedBottomRowData', [
        {
          [col]: {
            value: 'Total',
          },
          ...pinnedBottomData,
        },
      ]);
    });
  });

  private columnsWithNumbers: string[] = [];
  private initialGridData: { [p: string]: { value: number | string } }[] = [];

  constructor() {
    combineLatest([
      toObservable(this.invoice),
      this.eventQuery.selectProcessingEvent$(EventType.INVOICE_DOCUMENT_UPLOADED),
    ])
      .pipe(
        filter(([, eventProcessing]) => eventProcessing === false),
        tap(() => this.gridDataLoading.set(true)),
        switchMap(([invoice]) =>
          this.gqlService.getInvoiceDetailsGrid$(EntityType.INVOICE_LINE, invoice.id)
        ),
        takeUntilDestroyed()
      )
      .subscribe((resp) => this.parseInvoiceGridResponse(resp));
  }

  ngOnInit(): void {
    this.initGrid();
  }

  parseInvoiceGridResponse({ errors, data }: GraphqlResponse<getInvoiceDetailsGridQuery>) {
    if (!data) {
      this.overlayService.error(errors);
      return;
    }

    const columnDefs = parseColumnDefs(
      data.column_defs || '[]',
      this.invoice().organization.currency
    );

    this.colDefs.set(columnDefs);

    this.columnsWithNumbers = columnDefs
      .filter(
        (column) =>
          column.type === InvoiceItemColumnType.UNIT_PRICE ||
          column.type === InvoiceItemColumnType.PRICE
      )
      .map((col) => col.field.replace('.value', ''));

    const gridData = (
      JSON.parse(data.row_data || '[]') as Record<
        string,
        {
          value: number | string;
        }
      >[]
    ).map((row) => {
      const obj: Record<
        string,
        {
          value: number | string;
        }
      > = {};

      try {
        this.columnsWithNumbers.forEach((column) => {
          const value = row[column]?.value;

          if (value) {
            obj[column] = {
              ...row[column],
              value: refactorToNumbers(value as string),
            };
          }
        });
      } catch (error) {
        console.error(error);
      }

      return {
        ...row,
        ...obj,
      };
    });

    let firstPriceColumn: (ColDef & { field: string }) | undefined;
    firstPriceColumn = columnDefs.find((column) => column.type === InvoiceItemColumnType.PRICE);
    if (!firstPriceColumn) {
      firstPriceColumn = columnDefs.find(
        (column) =>
          column.headerName === 'Amount' ||
          column.headerName === 'Total' ||
          column.headerName === 'Dollar' ||
          column.headerName === 'Actual Amount'
      );
    }
    this.mapActivitiesModalService.setLineItemOptions(
      gridData,
      firstPriceColumn?.field.replace('.value', '') || ''
    );
    this.gridData.set(gridData);
    this.initialGridData = cloneDeep(gridData);
    this.gridDataLoading.set(false);
  }

  autosize() {
    this.gridAPI()?.sizeColumnsToFit();
  }

  onCancel() {
    this.editMode.set(false);
    this.gridChangeList.set([]);
    this.gridData.set(cloneDeep(this.initialGridData));
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    const colId = event.colDef.field?.replace('.value', '') || '';
    const cellId = event.data[colId].id;
    const index = this.gridChangeList().findIndex((item) => item.id === cellId);
    if (index === -1) {
      this.gridChangeList.set([
        ...this.gridChangeList(),
        {
          id: cellId,
          entity_field_value: event.newValue,
          entity_field_source: 'MANUAL',
        },
      ]);
    } else {
      this.gridChangeList.set(
        this.gridChangeList().map((item) => {
          if (item.id === cellId) {
            item.entity_field_value = event.newValue;
          }
          return item;
        })
      );
    }

    // if (
    //   event.colDef.type === InvoiceItemColumnType.UNIT_PRICE ||
    //   event.colDef.type === InvoiceItemColumnType.PRICE
    // ) {
    //   this.setPinnedBottomRowData();
    // }
  }

  getDynamicExcelParams() {
    const trial = this.mainQuery.getSelectedTrial();
    const gridAPI = this.gridAPI();
    const invoice = this.invoice();
    if (!trial || !gridAPI) {
      return;
    }
    const totals = gridAPI.getPinnedBottomRow(0)?.data || {};

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: {
              value: 'Total',
              type: 'String',
            },
            styleId: ['total_row_header'],
          },
        ],
      },
    ];

    gridAPI
      .getAllDisplayedColumns()
      .filter((key) => key.getColId() !== 'col_1.value')
      .forEach((col) => {
        if (
          col.getColDef().type === InvoiceItemColumnType.UNIT_PRICE ||
          col.getColDef().type === InvoiceItemColumnType.PRICE
        ) {
          appendContent[0].cells.push({
            data: {
              value: `${totals[col.getColId().replace('.value', '')].value}`,
              type: 'Number',
            },
            styleId: [`total_row_${invoice.organization.currency || Currency.USD}`],
          });
        } else {
          appendContent[0].cells.push({
            data: { value: '', type: 'String' },
            styleId: ['total_row'],
          });
        }
      });

    const exportOptions: ExcelExportParams = {
      author: 'Auxilius',
      fontSize: 11,
      sheetName: 'Invoice Line Items',
      allColumns: true,
      skipPinnedBottom: true,
      fileName: `${invoice.invoice_no}_${invoice.organization.name}_${trial.short_name}_Auxilius_Parsed_Invoice_Line_Items.xlsx`,
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: appendContent[0].cells.length - 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: appendContent,
    };

    gridAPI.exportDataAsExcel(exportOptions);
  }

  onSave = async () => {
    this.editMode.set(false);

    const { success, data } = await firstValueFrom(
      this.gqlService.updateInvoiceDetail$(this.gridChangeList())
    );

    if (success && data) {
      this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);
      this.initGrid();
    } else {
      this.gridData.set(cloneDeep(this.initialGridData));
    }
    this.gridChangeList.set([]);
  };

  canDeactivate() {
    return !this.hasChanges();
  }

  private initGrid(): void {
    this.gqlService
      .getInvoiceDetailsGrid$(EntityType.INVOICE_LINE, this.invoice().id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.gridDataLoading.set(true))
      )
      .subscribe((resp) => this.parseInvoiceGridResponse(resp));
  }
}
