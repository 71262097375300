import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl } from '@angular/forms';
import { EditableListDropdownItem } from '@shared/components/editable-list-dropdown/editable-list-dropdown-item.model';
import { NgOption } from '@ng-select/ng-select';
import { Option } from '@shared/components/extendable-options-dropdown/extendable-options-dropdown.component';

@Component({
  selector: 'aux-forms',
  templateUrl: './forms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsComponent {
  value = 100;

  form = this.fb.group({
    slider: 100,
  });

  sliderOptions = {
    floor: 0,
    ceil: 250,
  };

  selectedOption = new UntypedFormControl();

  datePicked = new UntypedFormControl();

  editableListDropdownControl = new FormControl('');

  editableListOptions: EditableListDropdownItem[] = [
    {
      name: 'Item 1',
      value: 'Item 1',
      showLine: false,
      isEditable: true,
      isDeletable: true,
    },
    {
      name: 'Item 2',
      value: 'Item 2',
      showLine: false,
      isEditable: true,
      isDeletable: true,
    },
    {
      name: 'Item 3',
      value: 'Item 3',
      showLine: true,
      isEditable: false,
      isDeletable: true,
    },
    {
      name: 'Item 4',
      value: 'Item 4',
      showLine: false,
      isEditable: true,
      isDeletable: false,
    },
  ];

  multiSelectDropdownOptions: NgOption[] = [
    {
      value: 'Item 1',
      title: 'Item 1',
      subTitle: 'Item 1',
      textToSearch: 'Item 1',
    },
    {
      value: 'Item 2',
      title: 'Item 2',
      subTitle: 'Item 2',
      textToSearch: 'Item 2',
    },
    {
      value: 'Item 3',
      title: 'Item 3',
      subTitle: 'Item 3',
      textToSearch: 'Item 3',
    },
    {
      value: 'Item 4',
      title: 'Item 4',
      subTitle: 'Item 4',
      textToSearch: 'Item 4',
    },
  ];

  option = '';

  textInput = '';

  textInputWithDescription = '';

  searchBar = '';

  textFieldFilledOut = 'Filled out field';

  extendableOptionsDropdownItems = [
    {
      value: 'option 1',
      id: 'option 1',
    },
    {
      value: 'option 2',
      id: 'option 2',
    },
    {
      value: 'option 3',
      id: 'option 3',
    },
  ];

  extOptControl = new FormControl();

  constructor(private fb: FormBuilder) {}

  onAddOption(option: Option): void {
    this.extendableOptionsDropdownItems.push({
      value: option.value,
      id: option.value,
    });
  }

  noAction(): void {}

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((x, i) => i + 1);
  }

  editableListAction(): void {}
}
