<div class="px-4 py-7">
  <div class="flex flex-row flex-grow items-start gap-5">
    <!-- Title -->
    <div class="flex flex-grow mb-6 items-start">
      <aux-icon name="Checkbox" class="mr-2.5" />
      <h3 class="quarter-close-checklist-title">
        {{ quarterCloseChecklistTitle$(workflowList) | async }}
      </h3>
    </div>

    <!-- Month Dropdown -->
    <div class="flex flex-row mb-6 items-center">
      <span class="mr-2">Select Month</span>
      <ng-select
        #selectMonthEl
        class="w-64 month-select"
        [multiple]="false"
        [clearable]="false"
        [formControl]="selectedQuarterMonthFormControl"
        [items]="quarterMonths"
        bindValue="value"
        bindLabel="label"
      >
        <ng-template let-item="item" ng-option-tmp>
          <div
            (click)="selectMonth($event, item.value, item.disabled)"
            [title]="item.label"
            class="text-sm option-wrapper"
          >
            {{ item.label }}
          </div>
        </ng-template>
      </ng-select>
    </div>
    <!-- Export Button -->
    <div class="flex justify-between mb-6">
      <div class="flex items-center">
        <div *ngVar="isBtnLoading('export') | async as isExportProcessing" class="col-span-1">
          <aux-export-excel-button
            className="ml-1"
            [clickFN]="onExportChecklist.bind(this)"
            [loading]="isExportProcessing"
            [disabled]="isExportProcessing"
            [spinnerSize]="7"
            pendoTag="quarter-close-checklist-excel-export-button"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Section: Gather Documents -->
  <div class="relative">
    <!-- Loader -->
    <div *ngIf="isLoadingList" class="checklist-container-disabled w-full h-full absolute">
      <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        icon="Files"
        pendoID="checklist-gather-docments-lock-all"
        [title]="sectionTitles.GatherDocuments"
        [total]="workflowService.sectionTotals(sections.GatherDocuments, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.GatherDocuments, workflowList)"
        (lockAllEmit)="lockAllWorkflows(sections.GatherDocuments)"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          (!isAdminUser &&
            workflowService.isAnyWorkflowUnlockedAndHasNoPermissions(
              sections.GatherDocuments,
              workflowList,
              permissionList
            )) ||
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          isPastQuarterMonth
        "
      />

      <!-- Pending Change Orders, Purchase Orders, New Vendors, or Budgets -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherContracts"
        [checklistComponent]="rowComponents.GatherContracts"
        [rowId]="rows.GatherContracts"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherContracts, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherContracts) | async) || false"
        [addPoReportUploadClick]="onAddPoReportUploadClick"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistContractsPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_CONTRACTS"
      />

      <!-- Review Invoices -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherInvoices"
        [rowId]="rows.GatherInvoices"
        [selectedMonth]="selectedQuarterMonthFormControl.value"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherInvoices, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherInvoices) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistInvoicesPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_INVOICES"
      >
        <aux-checklist-row-info-gather-invoices
          [isLoadingList]="isLoadingList"
          [numberOfInvoices]="numberOfInvoices"
          [amountInvoiced]="amountInvoiced"
        />
      </aux-checklist-row-info>

      <!-- Review Vendor Estimates -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherEstimates"
        [checklistComponent]="rowComponents.GatherEstimates"
        [rowId]="rows.GatherEstimates"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherEstimates, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherEstimates) | async) || false"
        [vendorEstimateUploadClick]="onAddVendorEstimateUploadClick"
        [vendorEstimateSummaries]="vendorEstimateSummaries"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistVendorEstimatesPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_VENDOR_ESTIMATES"
        (toggleOpened)="reviewVendorEstimateOpened()"
      />

      <!-- Confirm Investigator Data -->
      <aux-checklist-row-info
        [title]="rowTitles.GatherPatients"
        [checklistComponent]="rowComponents.GatherPatients"
        [queryParams]="selectedPeriodQuery()"
        [rowId]="rows.GatherPatients"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.GatherPatients, workflowList)"
        [open]="(toggleService.isRowOpen(rows.GatherPatients) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistPatientDataPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA"
        [detailRowContainerClassName]="'checklist-row-info-detail-container w-full mt-4 py-1 px-4'"
      />
    </div>
  </div>

  <!-- Section: Confirm Forecast -->
  <div class="relative">
    <!-- Loader -->
    <div *ngIf="isLoadingList" class="checklist-container-disabled w-full h-full absolute">
      <div class="border-8 h-32 m-auto mt-28 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        icon="ChartBar"
        pendoID="checklist-confirm-forecast-lock-all"
        [title]="sectionTitles.ConfirmForecast"
        [total]="workflowService.sectionTotals(sections.ConfirmForecast, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.ConfirmForecast, workflowList)"
        (lockAllEmit)="lockAllWorkflows(sections.ConfirmForecast)"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          (!isAdminUser &&
            workflowService.isAnyWorkflowUnlockedAndHasNoPermissions(
              sections.ConfirmForecast,
              workflowList,
              permissionList
            )) ||
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          isPastQuarterMonth
        "
      />

      <!-- Confirm Trial Timeline -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastTimeline"
        [rowId]="rows.ForecastTimeline"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastTimeline, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastTimeline) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistTrialTimelinePermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_TRIAL_TIMELINE"
      />

      <!-- Site, Patient, & Custom Curves -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastCurves"
        [rowId]="rows.ForecastCurves"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastCurves, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastCurves) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistPatientSiteCurvePermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES"
      />

      <!-- Forecast Methodology -->
      <aux-checklist-row-info
        [title]="rowTitles.ForecastMethodology"
        [rowId]="rows.ForecastMethodology"
        [disabledLock]="isClosedMonthsProcessing || !isCurrentQuarterSelected || isPastQuarterMonth"
        [workflow]="workflowService.workflow(rowTitles.ForecastMethodology, workflowList)"
        [open]="(toggleService.isRowOpen(rows.ForecastMethodology) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistForecastMethodologyPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_FORECAST_METHODOLOGY"
      />
    </div>
  </div>

  <!-- Section: Confirm and Close -->
  <div class="relative">
    <!-- Loader -->
    <div *ngIf="isLoadingList" class="checklist-container-disabled w-full h-full absolute">
      <div class="border-8 h-32 m-auto mt-28 spinner w-32"></div>
    </div>

    <div class="checklist-container mb-8">
      <!-- Title -->
      <aux-checklist-row-title
        icon="FileCheck"
        pendoID="checklist-confirm-close-lock-all"
        [title]="sectionTitles.ConfirmClose"
        [total]="workflowService.sectionTotals(sections.ConfirmClose, workflowList)"
        [complete]="workflowService.sectionCompletedTotals(sections.ConfirmClose, workflowList)"
        (lockAllEmit)="lockAllWorkflows(sections.ConfirmClose)"
        [isLoadingList]="isLoadingList"
        [disabledLock]="
          (!isAdminUser &&
            workflowService.isAnyWorkflowUnlockedAndHasNoPermissions(
              sections.ConfirmClose,
              workflowList,
              permissionList
            )) ||
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          isLockingAll ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            sections.ConfirmClose,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete
          )
        "
      />

      <!-- Confirm Vendor Expenses -->
      <aux-checklist-row-info
        [title]="rowTitles.CloseExpenses"
        [rowId]="rows.CloseExpenses"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            rows.CloseExpenses,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete
          )
        "
        [workflow]="workflowService.workflow(rowTitles.CloseExpenses, workflowList)"
        [open]="(toggleService.isRowOpen(rows.CloseExpenses) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [hasUserPermissions]="permissionList.userHasChecklistVendorExpensesPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_VENDOR_EXPENSES"
      />

      <!-- Confirm Discounts -->
      <aux-checklist-row-info
        [title]="rowTitles.CloseDiscounts"
        [rowId]="rows.CloseDiscounts"
        [checklistComponent]="rowComponents.CloseDiscounts"
        [disabledLock]="
          isClosedMonthsProcessing ||
          !isCurrentQuarterSelected ||
          !gatherDocumentsSectionComplete ||
          !confirmForecastSectionComplete ||
          !closeExpensesRowComplete ||
          isPastQuarterMonth
        "
        [tooltip]="
          workflowService.disabledLockTooltip(
            rows.CloseDiscounts,
            isClosedMonthsProcessing ||
              !isCurrentQuarterSelected ||
              !gatherDocumentsSectionComplete ||
              !confirmForecastSectionComplete ||
              !closeExpensesRowComplete
          )
        "
        [workflow]="workflowService.workflow(rowTitles.CloseDiscounts, workflowList)"
        [open]="(toggleService.isRowOpen(rows.CloseDiscounts) | async) || false"
        [changeLockStatus]="changeLockStatus"
        [detailRowContainerClassName]="''"
        [hasUserPermissions]="permissionList.userHasChecklistDiscountsPermission"
        [isPastQuarterMonth]="isPastQuarterMonth"
        [checklistAdminPermission]="permissionList.userHasChecklistAdminPermission"
        [permissionType]="PermissionType.PERMISSION_CHECKLIST_DISCOUNTS"
      />
    </div>
  </div>
</div>

<aux-checklist-banner
  *ngIf="isCurrentQuarterSelected && isSelectedMonthOpen()"
  [disabled]="isChecklistBannerDisabled()"
/>
