<div
  class="flex items-center justify-center space-x-[10px] w-full flex-grow"
  [ngClass]="{ 'h-full': !params.alignHeaderOneRowHeight }"
>
  <div class="flex items-center justify-center space-x-[5px]">
    <button (click)="toggleRowExpand(false)" class="flex">
      <aux-icon name="ChevronsDown" [auxTooltip]="'Expand All Rows'" />
    </button>

    <button (click)="toggleRowExpand(true)" class="flex">
      <aux-icon name="ChevronsUp" [auxTooltip]="'Collapse All Rows'" />
    </button>
  </div>

  <div class="flex items-center justify-center space-x-1">
    <div [ngClass]="templateClasses" [innerHTML]="params.template"></div>
    <aux-icon
      *ngIf="params.columnsToCollapse.length"
      class="cursor-pointer ml-1"
      (click)="toggleExpand()"
      [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
    />
  </div>
</div>
