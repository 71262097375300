import { InvoiceModel } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.model';
import { MapActivitiesTabGridRow } from '@pages/vendor-payments-page/tabs/invoices/invoice-detail/invoice-tabs/map-activities/map-activities-tab.model';
import { ModifyInvoiceActivityMapping } from '@shared/services/gql.service';

export interface MapActivitiesGridRow extends MapActivitiesTabGridRow {
  isNewRow: boolean;
}

export enum MapActivityType {
  ACTIVITY_DISCOUNT = 'Discount',
  ACTIVITY_INVESTIGATOR = 'Investigator',
  ACTIVITY_PASSTHROUGH = 'Passthrough',
  ACTIVITY_SERVICE = 'Service',
}

export interface MapActivitiesModalParams {
  invoice: InvoiceModel;
  lastUpdated: string;
  items: MapActivitiesTabGridRow[];
  noteId: string;
  noteMessage: string;
}

export interface MapActivitiesModalReturnData {
  isNotesChanged?: boolean;
  notesMessage?: string;
  itemsToSave?: ModifyInvoiceActivityMapping[];
}

export interface MapActivitiesTotals {
  invoice_total: number;
  ACTIVITY_SERVICE: number;
  ACTIVITY_DISCOUNT: number;
  ACTIVITY_PASSTHROUGH: number;
  ACTIVITY_INVESTIGATOR: number;
}
