import { inject, Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  GqlService,
  listDriverCustomDistributionQuery,
  listDriverCustomGroupsQuery,
} from '@shared/services/gql.service';
import { map, Observable, tap } from 'rxjs';
import { OverlayService } from '@shared/services/overlay.service';

export type CustomCurve = Partial<listDriverCustomGroupsQuery>;

export interface CustomCurvesState {
  curves: CustomCurve[];
  currentDistributions: listDriverCustomDistributionQuery[];
  curveToCreateOrEdit: CustomCurve | null;
  selectedCurve: CustomCurve | null;
}

export const customCurvesInitialState = (): CustomCurvesState => {
  return {
    curves: [],
    currentDistributions: [],
    curveToCreateOrEdit: null,
    selectedCurve: null,
  };
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'custom-curves' })
export class CustomCurvesStore extends Store<CustomCurvesState> {
  private readonly gqlService = inject(GqlService);

  private readonly overlayService = inject(OverlayService);

  constructor() {
    super(customCurvesInitialState());
  }

  getDriverCustomDistribution(): Observable<listDriverCustomDistributionQuery[]> {
    return this.gqlService
      .listDriverCustomDistribution$(this.getValue().selectedCurve?.custom_group_id as string)
      .pipe(
        tap((listDistributionsResponse) => {
          if (listDistributionsResponse.errors?.length) {
            this.overlayService.error(listDistributionsResponse.errors);
          } else {
            this.updatePartialy({
              currentDistributions: listDistributionsResponse.data || [],
            });
          }
        }),
        map((listDistributionsResponse) => listDistributionsResponse.data || [])
      );
  }

  getCustomDriverGroups(): Observable<listDriverCustomGroupsQuery[]> {
    return this.gqlService.listDriverCustomGroups$().pipe(
      tap((listGroupsResponse) => {
        if (listGroupsResponse.errors?.length) {
          this.overlayService.error(listGroupsResponse.errors);
        } else {
          this.updatePartialy({
            curves: listGroupsResponse.data || [],
          });
        }
      }),
      map((listGroupsResponse) => listGroupsResponse.data || [])
    );
  }

  updatePartialy(partial: Partial<CustomCurvesState>): void {
    this.update((state) => ({
      ...state,
      ...partial,
    }));
  }
}
