@if (isQuarterCloseEnabled() && isClosingPanelEnabled()) {
  <aux-workflow-panel
    className="mb-7"
    [workflowName]="workflowName"
    [processing]="iCloseMonthsProcessing()"
    [hasUserPermissions]="userHasLockInvestigatorDataPermission()"
  />
}

@if ((isProtocolVersionLoading$ | async) === false) {
  <aux-protocol-section
    className="my-4"
    [versionOptions]="protocolVersionOptions"
    [patientOptions]="patientOptions"
    [isVersionControlEditable]="true"
    [isAmendmentDisabled]="(editModeGrid$ | async) === true"
    [template]="actionButtons"
    [hasChanges]="hasChanges"
    [userHasUpdateProtocolEntryPermission]="userHasUpdateProtocolEntryPermission()"
    (valuesChange)="onChangePatientGroup($event)"
    (formReady)="onProtocolFormReady($event)"
    (amendmentClick)="createProtocol($event)"
    (editProtocolVersion)="editProtocolVersion($event)"
    (deleteProtocolVersion)="deleteProtocolVersion($event)"
  />
}

<ng-template #actionButtons>
  <div *ngIf="(isProtocolVersionLoading$ | async) === false" class="flex justify-end space-x-2">
    @if (!protocolVersionOptions.length) {
      <aux-button
        label="Create Protocol"
        variant="secondary"
        icon="Plus"
        [disabled]="isPatientsFinalized() || !userHasUpdateProtocolEntryPermission()"
        [auxTooltip]="noPermissionTooltip() || patientTrackerLockedTooltip()"
        [spinnerSize]="7"
        [onClick]="createProtocol"
      />
    }

    @if (editModeGrid$ | async) {
      <aux-button
        variant="primary"
        label="Add Entry"
        [disabled]="$any(loading$ | async)"
        [onClick]="onAddPatientProtocol.bind(this)"
      ></aux-button>

      <div class="mx-2.5 border-l border-l-aux-gray-dark border-solid"></div>

      <aux-button variant="secondary" label="Cancel" icon="X" [onClick]="cancelEditMode" />

      <aux-button
        variant="success"
        label="Save"
        class="ml-1"
        icon="Check"
        [disabled]="!hasChanges"
        [onClick]="onSaveAll"
      />
    } @else if (protocolVersionOptions.length) {
      <aux-button
        label="Edit"
        icon="Pencil"
        [disabled]="isPatientsFinalized() || !userHasUpdateProtocolEntryPermission()"
        [auxTooltip]="noPermissionTooltip() || patientTrackerLockedTooltip()"
        [onClick]="editGrid"
      />
    }
  </div>
</ng-template>

<ng-container *ngIf="$any(loading$ | async)">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="!$any(loading$ | async)">
  <ag-grid-angular
    id="patientBudgetEntryGrid"
    class="ag-theme-aux tabular-nums relative"
    [class.grid-animate]="gridAnimation"
    [domLayout]="domLayout"
    [ngStyle]="{
      height: domLayout === 'normal' ? tableHeightInEditMode() : ''
    }"
    [gridOptions]="$any(gridOptions$ | async)"
    [rowData]="$any(gridData$ | async)"
    [enableFillHandle]="(editModeGrid$ | async) === true"
    [rowDragManaged]="true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (gridSizeChanged)="gridSizeChanged()"
    auxStickyGrid
  />
  <p class="text-right" [ngClass]="{ 'mt-3': (editModeGrid$ | async) === false }">
    Total: {{ totalItems() }}
  </p>
</div>
