<div>
  <p class="mb-4">
    Are there any pending
    <span
      class="btn--hyperlink px-0 cursor-pointer"
      [routerLink]="GatherContractsRoutes.CHANGE_ORDER"
      >Change Orders?</span
    >
  </p>
</div>

<div class="flex items-center mb-4">
  <div>
    <p>
      Are there any new or changing
      <span
        class="btn--hyperlink px-0 cursor-pointer"
        [routerLink]="GatherContractsRoutes.PURCHASE_ORDERS"
        >Purchase Orders</span
      >?
    </p>
  </div>

  <div class="gather-estimates-container ml-2">
    <!-- Upload section -->
    <div *ngIf="!data.disabledLock" class="gather-estimates-upload-section">
      <button type="button" class="btn--secondary pl-3" (click)="data.addPoReportUploadClick()">
        <aux-icon name="Plus" />
        <span class="ml-2">Add PO Report</span>
      </button>
    </div>

    <!-- Upload-disabled section -->
    <div *ngIf="data.disabledLock" class="gather-estimates-upload-section">
      <button
        type="button"
        class="btn--secondary gather-estimates-upload-section-disabled pl-3"
        disabled
        [auxTooltip]="messagesConstants.LOCKED_FOR_PERIOD_CLOSE"
      >
        <aux-icon name="Plus" />
        <span class="ml-2">Add PO Report</span>
      </button>
    </div>
  </div>
</div>

<div>
  <p class="mb-2">
    Are there any new
    <span class="btn--hyperlink px-0 cursor-pointer" [routerLink]="GatherContractsRoutes.VENDORS"
      >Vendors</span
    >
    or
    <span class="btn--hyperlink px-0 cursor-pointer" [routerLink]="GatherContractsRoutes.BUDGET"
      >Budget</span
    >
    updates?
  </p>
</div>
