import { Component, ChangeDetectionStrategy, Signal } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { MessagesConstants } from '@shared/constants/messages.constants';

export interface AgPoActionsProps {
  deleteClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  editClickFN: undefined | ((params: { rowNode: IRowNode }) => void);
  downloadClickFN: undefined | ((params: { rowNode: IRowNode }) => void);

  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  hideDownloadButton?: boolean;

  userHasPermission$: BehaviorSubject<boolean>;
  isChangeOrdersWorkflowLocked: Signal<boolean>;
}

type AgPoActionsParams = ICellRendererParams & AgPoActionsProps;

@Component({
  template: `
    <div class="flex items-center justify-center space-x-2">
      <button
        class="btn rounded-full p-0 w-8 h-8"
        [ngClass]="{ 'opacity-30 cursor-not-allowed': !params.data.file }"
        [disabled]="!params.data.file"
        type="button"
        (click)="onDownloadClick()"
        *ngIf="!params.hideDownloadButton && params.downloadClickFN"
      >
        <aux-icon name="Download" />
      </button>

      <button
        class="btn text-aux-blue rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onEditClick()"
        *ngIf="!params.hideEditButton && params.editClickFN"
        [ngClass]="{
          'cursor-not-allowed opacity-50':
            (params.userHasPermission$ | async) === false || params.isChangeOrdersWorkflowLocked()
        }"
        [disabled]="
          (params.userHasPermission$ | async) === false || params.isChangeOrdersWorkflowLocked()
        "
        [auxTooltip]="
          btnTooltip(
            (params.userHasPermission$ | async) === false,
            params.isChangeOrdersWorkflowLocked()
          )
        "
      >
        <aux-icon name="Pencil" />
      </button>

      <button
        class="btn rounded-full p-0 w-8 h-8"
        type="button"
        (click)="onDeleteClick()"
        *ngIf="!params.hideDeleteButton && params.deleteClickFN"
        [ngClass]="{
          'cursor-not-allowed opacity-50':
            (params.userHasPermission$ | async) === false || params.isChangeOrdersWorkflowLocked()
        }"
        [disabled]="
          (params.userHasPermission$ | async) === false || params.isChangeOrdersWorkflowLocked()
        "
        [auxTooltip]="
          btnTooltip(
            (params.userHasPermission$ | async) === false,
            params.isChangeOrdersWorkflowLocked()
          )
        "
      >
        <aux-icon name="Trash" class="text-aux-error" />
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPoActionsComponent implements ICellRendererAngularComp {
  params!: AgPoActionsParams;

  refresh(): boolean {
    return false;
  }

  onDeleteClick() {
    if (this.params.deleteClickFN) {
      this.params.deleteClickFN({ rowNode: this.params.node });
    }
  }

  onEditClick() {
    if (this.params.editClickFN) {
      this.params.editClickFN({ rowNode: this.params.node });
    }
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  agInit(params: AgPoActionsParams) {
    this.params = params;
  }

  btnTooltip(hasNoPOPermission: boolean, isChangeOrdersWorkflowLocked: boolean): string {
    if (hasNoPOPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isChangeOrdersWorkflowLocked) {
      return MessagesConstants.LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }
}
