export function removeSymbolsFromString(stringValue: string, symbolsToRemove: string[]): string {
  for (const symbol of symbolsToRemove) {
    stringValue = stringValue.replaceAll(symbol, '');
  }

  return stringValue;
}

export const removeSpecialSymbolsFromFileName = (stringValue: string = ''): string =>
  stringValue.replace(/[\\/:*?"<>|#%&+.()]/g, '').replace(/\s+/g, '_');
