import { Component, ChangeDetectionStrategy } from '@angular/core';
import { EventService } from '@models/event/event.service';
import { EntityType, EventType } from '@shared/services/gql.service';
import { TabGroupConfig } from '@features/tab-group/route-tab-group.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'aux-edc-converter',
  templateUrl: './edc-converter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EdcConverterComponent {
  constructor(private eventService: EventService) {}
  // TODO remove this after qa is done testing the email
  async onSendingPendingApprovalReminder() {
    await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.COMPLETE_CHECKLIST_REMINDER,
        entity_type: EntityType.TRIAL,
        entity_id: '',
      })
    );
  }

  tabs: TabGroupConfig[] = [
    {
      label: 'Legacy',
      route: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.LEGACY,
    },
    {
      label: 'New',
      route: ROUTING_PATH.OPS_ADMIN.EDC_CONVERTER.NEW,
    },
  ];
}
