import { Injectable } from '@angular/core';
import { EventService } from '@models/event/event.service';
import { ChangeOrderStatus, EventType, GqlService } from '@shared/services/gql.service';
import { MainQuery } from '@shared/store/main/main.query';
import { MainStore } from '@shared/store/main/main.store';
import { combineLatest, startWith, switchMap, tap } from 'rxjs';

@Injectable()
export class NavigationMenuService {
  constructor(
    private mainQuery: MainQuery,
    private mainStore: MainStore,
    private eventService: EventService,
    private gqlService: GqlService
  ) {}

  getNumberOfCOsInPendingApproval() {
    return combineLatest([
      this.mainQuery.select('trialKey'),
      this.eventService
        .select$(EventType.NUMBER_OF_PENDING_APPROVAL_COS_UPDATED)
        .pipe(startWith(null)),
    ]).pipe(
      switchMap(() => this.gqlService.listChangeOrdersStatuses$()),
      tap((COs) => {
        const numberOfCOsInPendingApproval =
          COs.data?.filter(
            (co) => co.change_order_status === ChangeOrderStatus.STATUS_PENDING_APPROVAL
          ).length || 0;

        this.mainStore.update({ numberOfCOsInPendingApproval });
      })
    );
  }
}
