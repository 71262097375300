import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { AuthService } from '@shared/store/auth/auth.service';
import { EventTrackerService } from '@models/event/event-tracker.service';
import { OverlayService } from '@shared/services/overlay.service';
import { EventType, listTimelineMilestonesQuery, Trial } from '@shared/services/gql.service';
import { EventService } from '@models/event/event.service';
import { AppInitService } from '@shared/services/app-init.service';
import { IdleService } from '@shared/services/idle.service';

import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { BehaviorSubject, EMPTY, Observable, combineLatest } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserTasksService } from '@models/user-tasks';
import { isDev, RequireSome } from '@shared/utils/utils';
import { FormValuesQuery } from '@models/form-values/form-values.query';
import { MainQuery } from '@shared/store/main/main.query';
import { MainStore } from '@shared/store/main/main.store';
import { NewTrialDialogComponent } from '@features/new-trial-dialog/new-trial-dialog.component';
import { MainService } from '@shared/store/main/main.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { Hub } from 'aws-amplify/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { TrialDropdownComponent } from '@features/trial-dropdown/trial-dropdown.component';
import {
  TimelineBannerStatus,
  TimelineService,
} from '@pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';

@Component({
  selector: 'aux-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [],
  styleUrls: ['./main-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnDestroy, OnInit {
  @ViewChild(TrialDropdownComponent) trialDropdown!: TrialDropdownComponent;

  isTrialDropdownSearchable = isDev;

  accountLink = `/${ROUTING_PATH.ACCOUNT.INDEX}`;

  trialFc = new UntypedFormControl('');

  isTrialTasksVisible$ = new BehaviorSubject(this.isTrialTasksVisible(this.route.url));

  trials$!: Observable<
    (
      | (Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
          sponsor_organization: { id: string; name: string };
        })
      | { id: string; short_name: string; onboarding_complete: boolean }
    )[]
  >;

  error = false;

  showProfileSection$: Observable<boolean>;

  showHomeLink$: Observable<boolean>;

  showTaskSection$ = this.launchDarklyService.select$((flags) => flags.section_task_list);

  showRoadmapLink$ = this.launchDarklyService.select$((flags) => flags.section_footer_roadmap);

  showEnvIndicator$ = this.launchDarklyService.select$(
    (flags) => !!flags.environment_indicator.messages.length
  );

  showEnvBanner$ = combineLatest([
    this.launchDarklyService.select$((flags) => !!flags.environment_indicator.messages.length),
    this.mainQuery.select('showEnvBannerWarning'),
  ]).pipe(map(([featureFlag, showEnvBannerWarning]) => featureFlag && showEnvBannerWarning));

  currentRoute$ = new BehaviorSubject<string>('');

  unsub: () => void;

  isCurrentRoutePCPagesOrTimeline = signal<boolean>(false);
  calculatedTimelineBanner = signal<TimelineBannerStatus>(undefined);
  timelineWarningBannerFF = this.launchDarklyService.$select(
    (flags) => flags.timeline_warning_banner
  );
  lsTimelineBannerStatus$ = this.mainQuery.select('showTimelineBannerWarning');
  timelineBannerStatus = computed(() => {
    return !this.isCurrentRoutePCPagesOrTimeline() || !this.timelineWarningBannerFF()
      ? undefined
      : this.calculatedTimelineBanner();
  });

  constructor(
    public authQuery: AuthQuery,
    public trialsQuery: TrialsQuery,
    public mainQuery: MainQuery,
    public mainStore: MainStore,
    private formValuesQuery: FormValuesQuery,
    public authService: AuthService,
    public appInitService: AppInitService,
    private overlayService: OverlayService,
    private eventService: EventService,
    private userTasksService: UserTasksService,
    // don't remove the mainService from constructor
    private mainService: MainService,
    private launchDarklyService: LaunchDarklyService,
    private idleService: IdleService,
    private route: Router,
    private trackEventService: EventTrackerService,
    private timelineService: TimelineService
  ) {
    this.unsub = Hub.listen('auth', async ({ payload }) => {
      if (
        ['tokenRefresh_failure', 'signInWithRedirect_failure', 'signedOut'].includes(payload.event)
      ) {
        await this.route.navigate([`/${ROUTING_PATH.LOGIN}`]);
        window.location.reload();
      }
    });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: true,
      })
      .subscribe((hasPermission) => {
        this.trials$ = this.trialsQuery.selectAll().pipe(
          map((value) => {
            if (hasPermission) {
              return [
                { id: 'NEW_TRIAL', short_name: '+ Add New Trial', onboarding_complete: true },
                ...value,
              ];
            }
            return value;
          })
        );
      });
    this.idleService.disableEvents.next(false);
    this.mainQuery
      .select()
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.error = false;

        if (value.trialKey) {
          if (this.trialFc.value !== value.trialKey) {
            this.trialFc.setValue(value.trialKey);
          }
        } else {
          this.error = true;
        }
      });

    this.eventService
      .select$(EventType.REFRESH_USER_PERMISSIONS)
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => this.authService.updatePermissions$())
      )
      .subscribe();

    this.eventService
      .select$(EventType.TRIAL_CHANGED)
      .pipe(
        switchMap(() => this.mainQuery.select('isTrialLoading')),
        filter((isTrialLoading) => !isTrialLoading),
        takeUntilDestroyed()
      )
      .subscribe(async () => {
        const trial_id = await this.mainStore.getCognitoTrialKey();
        const trialChanged = this.mainQuery.getValue().trialKey !== trial_id;

        if (this.mainQuery.getValue().trialKey !== '' && trialChanged) {
          this.formValuesQuery.resetStore();
        }

        if (trialChanged) {
          this.trialFc.setValue(trial_id, { emitEvent: false });
          await this.mainStore.setTrial();
        }
      });

    this.eventService
      .select$(EventType.ENVIRONMENT_BUILT)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.logout();
      });

    this.trialFc.valueChanges.pipe(takeUntilDestroyed()).subscribe(async (value) => {
      if (this.mainQuery.getValue().trialKey !== value) {
        if (value === 'NEW_TRIAL') {
          const ref = this.overlayService.open<string>({ content: NewTrialDialogComponent });

          ref.afterClosed$.subscribe((newTrialKey) => {
            if (newTrialKey.data) {
              this.trialFc.setValue(newTrialKey.data);
            } else {
              this.trialFc.setValue(this.mainStore.getValue().trialKey);
              this.trialDropdown.setSearchTermManualy('', false);
            }
          });
        } else {
          await this.mainService.setTrial(value);
        }
      }
    });

    this.showProfileSection$ = launchDarklyService.select$((flags) => flags.section_profile);
    this.showHomeLink$ = launchDarklyService.select$((flags) => flags.nav_portfolio);

    this.route.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isTrialTasksVisible$.next(this.isTrialTasksVisible(event.url));
      }
      if (event instanceof NavigationEnd) {
        this.currentRoute$.next(event.urlAfterRedirects);
        const currentRoute = this.currentRoute$.getValue();
        this.isCurrentRoutePCPagesOrTimeline.set(
          currentRoute.includes(`${ROUTING_PATH.CLOSING.INDEX}`) ||
            currentRoute.includes(`${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}`)
        );
      }
    });

    this.showTaskSection$
      .pipe(
        switchMap((show) => {
          if (show) {
            return this.userTasksService.getUserTaskList$();
          }
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    this.mainQuery
      .select('trialKey')
      .pipe(
        takeUntilDestroyed(),
        filter((trialKey) => !!trialKey),
        switchMap(() => this.mainQuery.select('isTrialLoading')),
        filter((loading) => !loading),
        switchMap(() =>
          combineLatest([
            this.mainService.getOpenMonth$(),
            this.mainService.getUserList$(),
            this.timelineService.getTimelineItems(),
          ])
        )
      )
      .subscribe(([, , items]) => {
        this.getTimelineBannerCalculation(items);
      });

    [EventType.TIMELINE_UPDATED, EventType.CLOSE_TRIAL_MONTH].forEach((event) => {
      this.eventService
        .select$(event)
        .pipe(
          takeUntilDestroyed(),
          switchMap(() =>
            combineLatest([
              this.mainService.getOpenMonth$(),
              this.timelineService.getTimelineItems(),
            ])
          )
        )
        .subscribe(([, items]) => {
          this.getTimelineBannerCalculation(items);
        });
    });
  }

  ngOnInit(): void {
    this.trackEventService.trackEventsFromLs();
  }

  ngOnDestroy() {
    this.idleService.disableEvents.next(true);
    this.unsub();
  }

  isTrialTasksVisible(url: string) {
    return ['/home'].indexOf(url) === -1;
  }

  shouldPagePaddingbeAdjusted(url: string) {
    return ['budget', 'forecast', 'investigator', 'vendor-payments'].some((route) =>
      url.includes(route)
    );
  }

  async getTimelineBannerCalculation(
    items: GraphqlResponse<listTimelineMilestonesQuery[]>
  ): Promise<void> {
    const currentOpenMonth = this.mainQuery.currentOpenMonth();
    if (items.data && items.success && currentOpenMonth) {
      const status = await this.timelineService.calculateTimelineBanner(
        items.data,
        currentOpenMonth
      );
      this.calculatedTimelineBanner.set(status);
    }
  }

  async logout(manualLogOut = false) {
    if (manualLogOut) {
      localStorage.setItem(LocalStorageKey.MANUAL_LOG_OUT, 'true');
    }
    localStorage.removeItem(`customView`);
    await this.authService.signOut();
    await this.route.navigate([`/${ROUTING_PATH.LOGIN}`], { queryParams: {} });
  }

  routingHome() {
    if (this.launchDarklyService.flags$.getValue().nav_portfolio) {
      this.route.navigate([`/${ROUTING_PATH.HOME}`]);
    }
  }

  // Periodically compares and syncs the
  // Main Store trialKey to the Cognito claim trial_id
  @HostListener('window:focus', ['$event'])
  async syncTrialKeys(): Promise<void> {
    await this.mainStore.setTrial();
  }
}
