import { Component, inject, WritableSignal, isSignal, TemplateRef, computed } from '@angular/core';
import { CustomOverlayRef } from '@shared/components/overlay/custom-overlay-ref';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SuggestionComponent, SuggestionData } from './suggestion.component';
import { NgTemplateOutlet } from '@angular/common';

export interface SuggestionsModalData {
  description?: string | TemplateRef<unknown>;
  suggestedSteps: SuggestionData[];
}

@Component({
  template: `
    <div class="w-screen max-w-[365px] flex flex-col gap-[10px]">
      @if (data.description) {
        @if (descriptionIsTemplate) {
          <ng-container *ngTemplateOutlet="dscriptionTemplate"></ng-container>
        } @else {
          <div [innerHtml]="data.description" class="mb-[5px]"></div>
        }
      }
      <div class="flex items-center">
        <aux-icon name="AuxLogo" class="mr-2" />
        <div class="font-semibold">Suggested Next Steps</div>
      </div>
      @for (item of data.suggestedSteps; track item.name) {
        @if (!itemHidden(item)) {
          <aux-suggestion [data]="item" (onAction)="onAction(item)" />
        }
      }
      @if (allSuggestionsHidden()) {
        <div class="flex justify-center items-center text-xl font-semibold h-[100px]">
          No suggested steps
        </div>
      }
    </div>
  `,
  standalone: true,
  imports: [IconComponent, SuggestionComponent, NgTemplateOutlet],
})
export class SuggestionsConfirmationModalComponent {
  readonly ref = inject(CustomOverlayRef);

  allSuggestionsHidden = computed(() =>
    this.data.suggestedSteps.every((item) => this.itemHidden(item))
  );

  async onAction(data: SuggestionData): Promise<void> {
    await data.action?.();
    this.ref.close();
  }

  itemHidden(data: SuggestionData): boolean {
    if (isSignal(data.hidden)) {
      return (data.hidden as WritableSignal<boolean>)();
    }

    return !!data.hidden;
  }

  get descriptionIsTemplate(): boolean {
    return this.data.description instanceof TemplateRef;
  }

  get dscriptionTemplate(): TemplateRef<unknown> {
    return this.data.description as TemplateRef<unknown>;
  }

  get data(): SuggestionsModalData {
    return this.ref.data || { suggestedSteps: [] };
  }
}
