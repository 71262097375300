import { Component, ChangeDetectionStrategy, Signal, computed } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { Maybe } from '@shared/utils/utils';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { IconComponent } from '@shared/components/icon/icon.component';
import { SharedModule } from '@shared/shared.module';

export type AgInvoiceActionsComponentParamsInput = {
  downloadClickFN?: (params: { rowNode: IRowNode }) => void;
  downloadLinesClickFN?: (params: { rowNode: IRowNode }) => void;
  uploadClickFN:
    | undefined
    | ((params: { rowNode: IRowNode; instance: AgInvoiceActionsComponent }) => void);
  hideDownloadButton?: boolean;
  disableDownloadButton?: boolean;
  shouldEnableCSVDownload?: boolean;
  isInvoiceFinalized: Signal<boolean>;
  iCloseMonthsProcessing: Signal<boolean>;
  invoiceLockTooltip: Signal<string>;
};

type AgInvoiceActionsComponentParams = ICellRendererParams & AgInvoiceActionsComponentParamsInput;

@Component({
  templateUrl: './ag-invoice-actions.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, AsyncPipe, TooltipDirective, IconComponent, NgClass, NgIf],
})
export class AgInvoiceActionsComponent implements ICellRendererAngularComp {
  params!: AgInvoiceActionsComponentParams;

  disabled = computed(() => {
    return this.params.iCloseMonthsProcessing() || this.params.isInvoiceFinalized();
  });

  fileExist = false;
  enableCSV = false;

  refresh(): boolean {
    return false;
  }

  refreshFileState(file: Maybe<unknown[]>) {
    if (file) {
      if (file.length === 0) {
        this.params.node.data.file = false;
      } else {
        this.params.node.data.file = file[0];
      }
    }
  }

  agInit(params: AgInvoiceActionsComponentParams): void {
    this.params = params;
    if (!this.params.value) {
      this.params.disableDownloadButton = true;
    }

    this.fileExist = !!this.params.node.data.file;

    this.params.shouldEnableCSVDownload =
      (params.node.data.line_items && params.node.data.line_items.length > 0) ||
      (params.node.data.ocr_line_items && params.node.data.ocr_line_items.length > 0);

    this.enableCSV = !!this.params.shouldEnableCSVDownload;
  }

  onDownloadClick() {
    if (this.params.downloadClickFN) {
      this.params.downloadClickFN({ rowNode: this.params.node });
    }
  }

  onDownloadLinesClick() {
    if (this.params.downloadLinesClickFN) {
      this.params.downloadLinesClickFN({ rowNode: this.params.node });
    }
  }

  onUploadClick() {
    if (this.params.uploadClickFN) {
      this.params.uploadClickFN({
        rowNode: this.params.node,
        instance: this,
      });
    }
  }
}
