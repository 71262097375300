import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

type Variants = 'blue';

@Component({
  selector: 'aux-badge',
  template: `<div class="badge-container px-2 py-0.5 border rounded" [ngClass]="className">
    {{ title }}
  </div>`,
  standalone: true,
  imports: [NgClass],
})
export class BadgeComponent {
  @Input({ required: true }) title!: string;

  _variant: Variants = 'blue';

  get variant(): Variants {
    return this._variant || 'blue';
  }

  @Input({ required: true }) set variant(theme: Variants) {
    this._variant = theme;
  }

  _className = '';

  get className() {
    return `${this._className} ${this.variants[this.variant]}`;
  }

  @Input() set className(classes: string) {
    this._className = classes;
  }

  private variants: Record<Variants, string> = {
    blue: 'border-aux-blue-light-4 bg-aux-blue-light-3 text-aux-blue-dark-2',
  };
}
