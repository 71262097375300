import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, ValueFormatterParams } from '@ag-grid-community/core';
import {
  ActivityType,
  AdjustmentType,
  Currency,
  ExpenseNoteType,
  listInMonthExpensesQuery,
  Note,
  NoteType,
  User,
} from '@shared/services/gql.service';
import { Utils } from '@shared/utils/utils';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AUX_ADMIN_USER, EMPTY_UUID, isAuxUser } from '@shared/constants/uuid';

dayjs.extend(utc);

export interface AgAdjustmentColumnParams extends ICellRendererParams {
  users: Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>;
  selectedVendorCurrency: Currency;
  adjustmentDate: string;
}

@Component({
  selector: 'aux-ag-adjustment-column',
  template: ` <div
      *ngIf="$any(note)"
      class="bg-aux-blue-dark absolute -rotate-45 transform"
      style="left: -5px;top: -1px;width: 14px;height: 7px;"
      auxTooltip
      [template]="noteTemplate"
    ></div>
    <ng-template #noteTemplate>
      <div class="space-y-1">
        <div *ngIf="note?.unit_changes && note?.unit_changes?.adjustmentUsername">
          <div *ngIf="note?.unit_changes?.date" class="text-sm">{{ note?.unit_changes?.date }}</div>
          {{ note?.unit_changes?.adjustmentUsername }} adjusted Units from
          {{ note?.unit_changes?.from }} to
          {{ note?.unit_changes?.to }}
          <div class="italic max-w-xs">”{{ note?.unit_changes?.message }}”</div>
          <div *ngIf="note?.unit_changes?.multiple" class="text-sm">More notes available.</div>
        </div>
        <div *ngIf="note?.total_changes && note?.total_changes?.adjustmentUsername">
          <div *ngIf="note?.total_changes?.date" class="text-sm">
            {{ note?.total_changes?.date }}
          </div>
          {{ note?.total_changes?.adjustmentUsername }} adjusted
          {{ note?.total_changes?.adjustmentDate }} Expense from {{ note?.total_changes?.from }} to
          {{ note?.total_changes?.to }}
          <div class="italic max-w-xs">”{{ note?.total_changes?.message }}”</div>
          <div *ngIf="note?.total_changes?.multiple" class="text-sm">More notes available.</div>
        </div>
        <div
          *ngIf="
            note?.historical_adjustment_changes &&
            note?.historical_adjustment_changes?.adjustmentUsername
          "
        >
          <div *ngIf="note?.historical_adjustment_changes?.date" class="text-sm">
            {{ note?.historical_adjustment_changes?.date }}
          </div>
          {{ note?.historical_adjustment_changes?.adjustmentUsername }} adjusted Historical
          Adjustment from {{ note?.historical_adjustment_changes?.from }} to
          {{ note?.historical_adjustment_changes?.to }}
          <div class="italic max-w-xs">”{{ note?.historical_adjustment_changes?.message }}”</div>
          <div *ngIf="note?.historical_adjustment_changes?.multiple" class="text-sm">
            More notes available.
          </div>
        </div>
      </div>
    </ng-template>

    <div [class]="valueColorClass">
      <span *ngIf="shouldShowPlusSign">+</span> <span>{{ params.valueFormatted }}</span>
    </div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentColumnComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  shouldShowPlusSign = false;

  valueColorClass = '';

  note:
    | {
        unit_changes?: {
          date: string;
          username: string;
          message: string;
          multiple: boolean;
          from: string;
          to: string;
          adjustmentUsername?: string;
        };
        total_changes?: {
          date: string;
          username: string;
          message: string;
          multiple: boolean;
          adjustmentDate: string;
          from: string;
          to: string;
          adjustmentUsername?: string;
        };
        historical_adjustment_changes?: {
          date: string;
          username: string;
          message: string;
          multiple: boolean;
          from: string;
          to: string;
          adjustmentUsername?: string;
        };
      }
    | undefined;

  refresh(): boolean {
    return false;
  }

  agInit(params: AgAdjustmentColumnParams) {
    this.params = params;

    const val = Number((params.value || 0).toFixed(2));

    if (val > 0) {
      this.shouldShowPlusSign = true;
      this.valueColorClass = 'text-aux-error';
    } else if (val < 0) {
      this.valueColorClass = 'text-aux-green';
    }

    const activity_id = params.data?.activity_id as listInMonthExpensesQuery['activity_id'];
    const adjustment = params.data
      ?.manual_adjustment as listInMonthExpensesQuery['manual_adjustment'];
    const historical_adjustment = params.data
      ?.historical_adjustment as listInMonthExpensesQuery['historical_adjustment'];
    if (params.data?.notes && (adjustment || historical_adjustment)) {
      const notes = params.data.notes.filter(
        (n: { entity_id: string; note_type: NoteType; expense_note_type: ExpenseNoteType }) =>
          n.entity_id === activity_id && n.note_type === NoteType.NOTE_TYPE_EXPENSE
      ) as Note[];
      if (notes.length) {
        const manual_adjustment_notes = notes.filter((note) =>
          note.expense_note_types.includes(ExpenseNoteType.EXPENSE_NOTE_MANUAL_ADJUSTMENT)
        );
        const historical_adjustment_notes = notes.filter((note) =>
          note.expense_note_types.includes(ExpenseNoteType.EXPENSE_NOTE_HISTORICAL_ADJUSTMENT)
        );
        if (
          adjustment &&
          this.params.data.activity_type !== ActivityType.ACTIVITY_DISCOUNT &&
          manual_adjustment_notes.length > 0
        ) {
          const { previous_amount, adjustment_type, unit_cost, amount, updated_by } = adjustment;
          const adjustmentUser = params.users.get(updated_by || '');

          const adjustmentUsername = isAuxUser(updated_by || '')
            ? 'Auxilius'
            : `${adjustmentUser?.given_name} ${adjustmentUser?.family_name}`;

          const from = previous_amount || 0;
          const to = amount || 0;

          const unit_changes = unit_cost
            ? {
                from: Utils.decimalFormatter(from / unit_cost),
                to: Utils.decimalFormatter(to / unit_cost),
              }
            : {
                from: '',
                to: '',
              };

          const total_changes = {
            from: Utils.agCurrencyFormatterAccounting(
              {
                value: from,
              } as ValueFormatterParams,
              params.selectedVendorCurrency
            ),
            to: Utils.agCurrencyFormatterAccounting(
              {
                value: to,
              } as ValueFormatterParams,
              params.selectedVendorCurrency
            ),
          };

          const note = manual_adjustment_notes[0];

          const noteUser = params.users.get(note.created_by);

          const username = isAuxUser(note.created_by)
            ? 'Auxilius'
            : `${noteUser?.given_name} ${noteUser?.family_name}`;

          const total_note = {
            ...total_changes,
            multiple: notes.length > 1,
            date: dayjs(note.create_date).utc().format('YYYY-MM-DD [at] HH:mm [UTC]'),
            username,
            message: Utils.unscrubUserInput(note.message),
            adjustmentDate: dayjs(params?.adjustmentDate).format('MMM YYYY'),
            adjustmentUsername,
          };

          const unit_note = {
            ...unit_changes,
            multiple: notes.length > 1,
            date: dayjs(note.create_date).utc().format('YYYY-MM-DD [at] HH:mm [UTC]'),
            adjustmentDate: dayjs(params?.adjustmentDate).format('MMM YYYY'),
            username,
            message: Utils.unscrubUserInput(note.message),
            adjustmentUsername,
          };

          switch (adjustment_type) {
            case AdjustmentType.ADJUSTMENT_AMOUNT:
              this.note = {
                total_changes: total_note,
              };
              break;
            case AdjustmentType.ADJUSTMENT_UNIT:
              this.note = {
                ...this.note,
                unit_changes: unit_note,
              };
              break;
            default:
              this.note = {
                ...this.note,
                total_changes: total_note,
              };
              break;
          }
        }
        if (historical_adjustment && historical_adjustment_notes.length) {
          const { previous_amount, amount, updated_by } = historical_adjustment;
          const adjustmentUser = params.users.get(updated_by || '');

          const adjustmentUsername = isAuxUser(updated_by || '')
            ? 'Auxilius'
            : `${adjustmentUser?.given_name} ${adjustmentUser?.family_name}`;

          const from = previous_amount || 0;
          const to = amount || 0;

          const historical_adjustment_changes = {
            from: Utils.agCurrencyFormatterAccounting(
              {
                value: from,
              } as ValueFormatterParams,
              params.selectedVendorCurrency
            ),
            to: Utils.agCurrencyFormatterAccounting(
              {
                value: to,
              } as ValueFormatterParams,
              params.selectedVendorCurrency
            ),
          };

          const note = historical_adjustment_notes[0];

          const noteUser = params.users.get(note.created_by);

          const username = isAuxUser(note.created_by)
            ? 'Auxilius'
            : `${noteUser?.given_name} ${noteUser?.family_name}`;

          const historical_adjustment_note = {
            ...historical_adjustment_changes,
            multiple: notes.length > 1,
            date: dayjs(note.create_date).utc().format('YYYY-MM-DD [at] HH:mm [UTC]'),
            username,
            message: Utils.unscrubUserInput(note.message),
            adjustmentUsername,
          };

          this.note = { ...this.note, historical_adjustment_changes: historical_adjustment_note };

          if (
            note.note_type === NoteType.NOTE_TYPE_EXPENSE &&
            [EMPTY_UUID, AUX_ADMIN_USER].includes(note.created_by) &&
            this.params.data.activity_type === ActivityType.ACTIVITY_INVESTIGATOR &&
            this.params.data.tma_source !== 'Evidence Based' &&
            this.params.data.tma_source !== 'None'
          ) {
            this.note = undefined;
          }
        }
      }
    }
  }
}
