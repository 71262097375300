<div class="w-screen max-w-[280px]"></div>
<div class="grid">
  <div class="flex justify-between flex-col">
    <div *ngIf="errorMessage()" class="mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage() }}
    </div>

    <div>
      <div
        class="btn--hyperlink cursor-pointer flex justify-center mb-4"
        (click)="downloadPatientTrackerTemplate()"
      >
        Download the template
      </div>

      <aux-file-manager
        #manager
        class="h-48"
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
      />
    </div>
  </div>

  <div></div>

  <div class="max-h-60 overflow-auto mt-4">
    <aux-file-viewer
      [fileManager]="manager"
      [disableFirstFileMargin]="true"
      [onlyShowUploaded]="false"
    />
  </div>
</div>
