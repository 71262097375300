import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { MainQuery } from '@shared/store/main/main.query';
import { switchMap } from 'rxjs/operators';
import {
  GqlService,
  StoredDocument,
  StoredDocumentProcessStatus,
} from '@shared/services/gql.service';
import { InputComponent } from '@shared/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { SitesQuery } from '@models/sites/sites.query';
import { TableConstants } from '@shared/constants/table.constants';
import { ApiService, AwsFile } from '@shared/services/api.service';
import { OverlayService } from '@shared/services/overlay.service';
import { Converted_File_Document_Types, ConvertedFilesGridRow } from './converted-files.model';
import { getConvertedFilesGridOptions } from './converted-files.constants';
import { TableSkeletonComponent } from '@shared/components/table-skeleton/table-skeleton.component';
import { OpsAdminUtils } from '../../utils/ops-admin.utils';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ButtonComponent } from '@shared/components/button/button.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'aux-converted-files',
  templateUrl: './converted-files.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    FormsModule,
    ButtonComponent,
    NgIf,
    NgClass,
    AgGridAngular,
    AsyncPipe,
    TableSkeletonComponent,
  ],
})
export class ConvertedFilesComponent {
  private readonly destroyRef = inject(DestroyRef);

  private gridAPI!: GridApi;

  searchValue = '';

  loadingGridData = true;

  downloadFile = async (downloadLink: string, fileName: string): Promise<void> => {
    const ref = this.overlayService.loading();

    await this.apiService.downloadFile({
      key: downloadLink,
      fileName: fileName,
    } as AwsFile);

    ref.close();
  };

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      resizable: true,
    },
    columnDefs: getConvertedFilesGridOptions(this.downloadFile),
  } as GridOptions;

  gridData$ = new BehaviorSubject<ConvertedFilesGridRow[]>([]);

  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private sitesQuery: SitesQuery,
    private apiService: ApiService,
    private overlayService: OverlayService
  ) {
    this.onTrialChange();
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
  }

  autosize(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  refreshGridData(): void {
    this.loadingGridData = true;
    const trialId = this.mainQuery.getValue().trialKey;

    this.gqlService
      .fetchStoredDocuments$({
        page: {
          offset: 0,
        },
        trial_id: trialId,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        if (result.success && result.data) {
          this.gridData$.next(this.mapConvertedFilesGridData(result.data.items));
        } else {
          this.gridData$.next([]);
        }
        this.loadingGridData = false;
      });
  }

  private onTrialChange(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(async () => this.refreshGridData()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private mapConvertedFilesGridData(convertedFiles: StoredDocument[]): ConvertedFilesGridRow[] {
    return convertedFiles.map((file: StoredDocument) => {
      const sourceDocuments = JSON.parse(file.source_documents);
      const originalFileDownloadLink =
        sourceDocuments.length > 0 ? sourceDocuments[0].bucket_key : '';
      let convertedFileDownloadLink = '';
      let convertedFileName = '';

      if (file.process_status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
        const processed_docs = JSON.parse(file.processed_documents || '[]') as {
          default?: string;
          bucket_key: string;
        }[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const default_docs: any[] = processed_docs.filter((doc: any) => doc.default);
        convertedFileDownloadLink =
          default_docs.length > 0
            ? default_docs[0].bucket_key
            : JSON.parse(file.processed_documents || '')[0].bucket_key;
        convertedFileName = convertedFileDownloadLink.substr(
          convertedFileDownloadLink.lastIndexOf('/') + 1
        );
      }

      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document_type: Converted_File_Document_Types[file.document_type_id],
        site: file.site_id ? this.sitesQuery.getEntity(file.site_id)?.name : '',
        converted_file: convertedFileName,
        converted_file_download_link: OpsAdminUtils.prepareDownloadLink(convertedFileDownloadLink),
        status: file.process_status,
        original_file: file.file_name || '',
        original_file_download_link: OpsAdminUtils.prepareDownloadLink(originalFileDownloadLink),
        date_converted: this.dateConverted(file),
      };
    });
  }

  private dateConverted(file: StoredDocument): string {
    if (file.process_status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
      return file.process_date || '';
    }

    return '';
  }
}
