import {
  Component,
  Input,
  forwardRef,
  QueryList,
  ContentChildren,
  AfterContentInit,
  input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormsModule,
  NG_VALIDATORS,
} from '@angular/forms';
import { CommonModule, NgClass } from '@angular/common';
import { RadioButtonComponent } from './radio-button.component';
import { BaseFormControlComponent } from '../base-form-control/base-form-control';
import { FormErrorDirective } from '@shared/directives/form-error.directive';

@Component({
  selector: 'aux-radio-button-group',
  template: `
    <fieldset
      class="radio-group flex w-fit"
      [ngClass]="{ 'space-x-4': display() === 'row', 'space-y-4 flex-col': display() === 'column' }"
    >
      <ng-content />
    </fieldset>
  `,
  styles: [
    `
      :host ::ng-deep .is-invalid.radio-group input[type='radio'] {
        @apply border-red-500;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, NgClass, FormErrorDirective],
})
export class RadioButtonGroupComponent
  extends BaseFormControlComponent<string>
  implements ControlValueAccessor, AfterContentInit
{
  @Input() name!: string;

  @ContentChildren(RadioButtonComponent) radios!: QueryList<RadioButtonComponent>;

  display = input<'row' | 'column'>('row');

  private defaultValue: string | null = null;

  ngAfterContentInit() {
    this.radios.forEach((radio) => {
      radio.name = this.name;
      if (this.defaultValue) {
        radio.setSelectedValue(this.defaultValue);
      }

      radio.onChange = (value: string) => {
        this.selectRadio(value);
      };
    });
  }

  selectRadio(value: string) {
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value: string): void {
    if (value) {
      this.defaultValue = value;
      this.selectRadio(value);
    }
  }

  onTouched: () => void = () => {};

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
