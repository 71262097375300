export enum BudgetCurrencyType {
  USD = 'usd',
  VENDOR = 'vendor',
}

export interface BudgetCurrencyState {
  currency: BudgetCurrencyType;
}

export function createInitialState(): BudgetCurrencyState {
  return {
    currency: BudgetCurrencyType.VENDOR,
  };
}
