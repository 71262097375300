import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

import {
  injectNgControl,
  NoopValueAccessorDirective,
} from '@shared/directives/noop-value-accessor.directive';
import { TypedNgChanges } from '@shared/utils';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { TooltipDirective } from '@shared/directives/tooltip.directive';

@Component({
  standalone: true,
  selector: 'aux-vendor-dropdown',
  template: `
    <ng-select
      #select
      [style.width]="width + 'px'"
      [clearable]="false"
      [formControl]="ngControl.control"
      bindLabel="name"
      bindValue="id"
      [items]="options"
      [loading]="loading"
      class="text-base"
      [auxTooltip]="
        (lbl?.nativeElement?.offsetWidth || 0) < (lbl?.nativeElement?.scrollWidth || 0) &&
        !select.isOpen
          ? $any(select.selectedItems[0])?.label || ''
          : ''
      "
      [auxTooltipPositions]="selectedOptionTooltipPositions"
    >
      <ng-template let-item="item" ng-option-tmp>
        <div
          class="block truncate option-wrapper"
          #text
          [auxTooltip]="text.offsetWidth < text.scrollWidth ? item.name : ''"
          [auxTooltipPositions]="optionTooltipPositions"
          (click)="clickOption($event, item.id)"
        >
          {{ item.name }}
        </div>
      </ng-template>
      <ng-template let-item="item" ng-label-tmp>
        <div class="block truncate" #lbl>
          {{ item.name }}
        </div>
      </ng-template>
    </ng-select>
  `,
  imports: [
    AsyncPipe,
    NgSelectModule,
    ReactiveFormsModule,
    NgForOf,
    NgIf,
    TooltipDirective,
    JsonPipe,
  ],
  styleUrl: './vendor-dropdown.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDropdownComponent implements OnChanges, AfterViewInit {
  @ViewChild('lbl') lbl?: ElementRef<HTMLDivElement>;

  @ViewChild('select') select?: NgSelectComponent;

  @Input() beforeSelect?: (nextValue: string | null) => boolean | Promise<boolean>;

  @Input() width = 350;

  ngControl = injectNgControl();

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  selectedOptionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'top',
      originX: 'start',
      overlayY: 'bottom',
      overlayX: 'start',
      offsetX: 0,
      offsetY: 0,
    },
  ];

  options: {
    id: string;
    name: string;
  }[] = [];

  @Input() vendors: {
    id: string;
    name: string;
  }[] = [];

  @Input() showAllOption = false;
  @Input() loading = false;
  @Input() appendTo = '';

  // eslint-disable-next-line
  @Output() onChange = new EventEmitter<string>();

  private changeDetector = inject(ChangeDetectorRef);

  ngOnChanges(changes: TypedNgChanges<VendorDropdownComponent>) {
    const vendors = changes.vendors?.currentValue || this.vendors;
    const showAllOption = changes.showAllOption?.currentValue || this.showAllOption;

    this.options =
      vendors.length > 1 && showAllOption ? [{ id: '', name: 'All' }, ...vendors] : vendors;

    this.changeDetector.markForCheck();
  }

  ngAfterViewInit(): void {
    // after render get the scrollWidth and offsetWidth
    setTimeout(() => {
      this.changeDetector.markForCheck();
    }, 0);
  }

  async clickOption(event: MouseEvent, value: string) {
    event.preventDefault();
    event.stopPropagation();

    if (this.beforeSelect && !(await this.beforeSelect(value))) {
      return;
    }

    this.ngControl.control.setValue(value);
    this.onChange.emit(value);

    if (this.select) {
      this.select.changeEvent?.emit(value);
      this.select.isOpen = false;
      this.select.closeEvent?.emit(true);
    }
  }
}
