import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeagoFormatter, TimeagoModule } from 'ngx-timeago';
import { AgGridAngular } from '@ag-grid-community/angular';
import { PortfolioTasksComponent } from './portfolio-tasks';
import { PortfolioCompleteComponent } from './portfolio-complete/portfolio-complete.component';
import { PortfolioStatusComponent } from './portfolio-status/portfolio-status.component';
import { PortfolioDashboardComponent } from './portfolio-dashboard.component';
import { DaysAgoFormatter } from './days-ago-pipe.formatter';
import { NgChartsModule } from 'ng2-charts';
import { FeaturesModule } from '@features/features.module';
import { FileManagerComponent } from '@shared/components/file-manager/file-manager.component';
import { SharedModule } from '@shared/shared.module';
import { QuickSightDashboardComponent } from './quicksight-dashboard/quicksight-dashboard.component';

@NgModule({
  declarations: [
    PortfolioTasksComponent,
    PortfolioCompleteComponent,
    PortfolioStatusComponent,
    PortfolioDashboardComponent,
    QuickSightDashboardComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    AgGridAngular,
    FileManagerComponent,
    NgChartsModule,
    TimeagoModule.forRoot({
      formatter: {
        provide: TimeagoFormatter,
        useClass: DaysAgoFormatter,
      },
    }),
    SharedModule,
    FeaturesModule,
  ],
})
export class PortfolioDashboardModule {}
