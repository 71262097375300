import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExportType, KeysMatching, Maybe, Utils } from '@shared/utils/utils';
import {
  CellClickedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  EditableCallbackParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  RowClassParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter as filterRxjs,
  firstValueFrom,
  from,
  of,
  Subject,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  ActivityType,
  AdjustmentType,
  AmountType,
  batchCreateBudgetExpensesMutation,
  batchCreateNotesMutation,
  BudgetExpenseData,
  BudgetExpenseInput,
  CreateNoteInput,
  Currency,
  DocumentType,
  EntityType,
  EventType,
  ExpenseNoteType,
  ExpenseSourceType,
  ExpenseType,
  GqlService,
  InvoiceCategoryTotals,
  listDiscountExpensesQuery,
  listDocumentsQuery,
  listExpenseSourceSettingsQuery,
  listInMonthExpensesQuery,
  listTrialVendorTimelineQuery,
  listUserNamesWithEmailQuery,
  Note,
  NoteType,
  PermissionType,
  updateAccrualsMutation,
  User,
  VendorEstimateSummary,
  WorkflowStep,
} from '@shared/services/gql.service';
import dayjs, { Dayjs } from 'dayjs';
import { OverlayService } from '@shared/services/overlay.service';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { formatDate } from '@angular/common';
import { OrganizationQuery } from '@models/organization/organization.query';

import { filter, includes, isEqual, isUndefined, map as _map, merge, some, uniq } from 'lodash-es';
import { TableService } from '@shared/services/table.service';
import {
  getActivitiesColumnDefs,
  getCurrentForecastColumnDefs,
  getEvidenceBasedColumnDefs,
  getInvoicesColumnDefs,
  getPreviousMonthColumnDefs,
  getVendorEstimateColumnDefs,
  uomHide$,
} from './column-defs';
import {
  InvestigatorEstimate,
  PeriodCloseComponent,
  QuarterDate,
} from '../../period-close.component';
import {
  AdjustmentModalComponent,
  AdjustmentModalResponseType,
} from './adjustment-modal/adjustment-modal.component';
import { MainQuery } from '@shared/store/main/main.query';
import {
  AgAdjustmentColumnComponent,
  AgAdjustmentColumnParams,
} from './ag-adjustment-column.component';
import { WorkflowQuery } from '@shared/store/workflow/workflow.query';
import { SupportModalComponent } from './support-modal/support-modal.component';
import { MessagesConstants } from '@shared/constants/messages.constants';
import { AgAdjustmentPrevMonthHeaderComponent } from './ag-adjustment-prev-month-header.component';
import { NoteModalComponent, NoteModalResponseType } from './note-modal/note-modal.component';
import { AgAdjustmentVendorEstimateHeaderComponent } from './ag-adjustment-vendor-estimate-header.component';
import { QuarterCloseChecklistVendorService } from '../quarter-close-checklist/services/quarter-close-checklist-vendor.service';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import {
  EvidenceBasedHeaderGetMonthVendor,
  EvidenceBasedHeaderGetVendorCurrency,
} from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.model';
import { TableConstants } from '@shared/constants/table.constants';
import { AgExpandableGroupHeaderComponent } from './ag-expandable-group-header.component';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { DiscountExpenseDetail } from '../quarter-close-checklist/components/checklist-section-discount/checklist-section-discount.component';
import { QuarterCloseAdjustmentsService } from './quarter-close-adjustments.service';
import { VariationStatusComponent } from 'src/app/pages/design-system/tables';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { ApiService } from '@shared/services/api.service';
import { AddVendorEstimateUploadComponent } from '../quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { EventService } from '@models/event/event.service';
import {
  AgEditFirstRow,
  AgSetColumnsVisible,
  AuxExcelStyleKeys,
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalMultiply,
} from '@shared/utils';
import { AgAdjustmentLinkHeaderComponent } from './ag-adjustment-link-header';
import { LaunchDarklyService } from '@shared/services/launch-darkly.service';
import { AgAdjustmentDiscountTooltipComponent } from './ag-adjustment-discount-tooltip.component';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { AgSelectEditorOptionRendererComponent } from '@shared/ag-components/ag-select-editor-option-renderer/ag-select-editor-option-renderer.component';
import { AuthService } from '@shared/store/auth/auth.service';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { EMPTY_UUID } from '@shared/constants/uuid';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ClosingPageStore } from '@shared/store/closing-page/closing-page.store';
import { selectWithGuardChangesModalCheck } from '@shared/utils/select-option';
import { BudgetQuery } from '@models/budget/budget.query';
import { BudgetService } from '@features/budget/services/budget.service';
import {
  AgBudgetGroupHeaderComponent,
  AgBudgetAttributeComponentParams,
} from '@features/ag-budget-group-header/ag-budget-group-header.component';
import {
  getAttributeColumns,
  attributeColumnDef,
  getEncodedAttributeName,
} from '@features/budget-attributes/services/budget-attributes.service';
import { QuarterCloseAdjustmentGridData } from './quarter-close-adjustments.model';
import { agGridAllVisibleData } from '@shared/utils/ag-grid-data.utils';
import { ConfirmationModalComponent } from '@shared/components/modals/confirmation-modal/confirmation-modal.component';
import { InvoiceService } from '@pages/vendor-payments-page/tabs/invoices/state/invoice.service';

export enum Expense_Source {
  'Forecast' = 'Forecast',
  'Evidence Based' = 'Evidence Based',
  'Vendor Estimate' = 'Vendor Estimate',
  Invoice = 'Invoice',
  Manual = 'Manual',
  None = 'None',
}

type InvoiceExpenseSourceFilterType = {
  ACTIVITY_DISCOUNT: boolean;
  ACTIVITY_INVESTIGATOR: boolean;
  ACTIVITY_PASSTHROUGH: boolean;
  ACTIVITY_SERVICE: boolean;
};

const expenseSourceMapping = {
  [ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE]: Expense_Source['Vendor Estimate'],
  [ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED]: Expense_Source['Evidence Based'],
  [ExpenseSourceType.EXPENSE_SOURCE_INVOICE]: Expense_Source.Invoice,
  [ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT]: Expense_Source.Manual,
  [ExpenseSourceType.EXPENSE_SOURCE_FORECAST]: Expense_Source.Forecast,
  [ExpenseSourceType.EXPENSE_SOURCE_NONE]: Expense_Source.None,
};

const expenseSourceReverseMapping = {
  [Expense_Source['Vendor Estimate']]: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
  [Expense_Source['Evidence Based']]: ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED,
  [Expense_Source.Invoice]: ExpenseSourceType.EXPENSE_SOURCE_INVOICE,
  [Expense_Source.Manual]: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
  [Expense_Source.Forecast]: ExpenseSourceType.EXPENSE_SOURCE_FORECAST,
  [Expense_Source.None]: ExpenseSourceType.EXPENSE_SOURCE_NONE,
};

export const spacerColumn = (colId?: string): ColDef | ColGroupDef => ({
  headerClass: 'ag-invisible',
  cellClass: 'ag-invisible',
  colId: colId,
  children: [
    { headerClass: 'ag-invisible', cellClass: 'ag-invisible', colId, width: 5, maxWidth: 5 },
  ],
});

type CalculableColumns = KeysMatching<QuarterCloseAdjustmentGridData, number>;

@Component({
  selector: 'aux-quarter-close-adjustments',
  templateUrl: './quarter-close-adjustments.component.html',
  styleUrls: [
    './quarter-close-adjustments.component.scss',
    './quarter-close-adjustments-table.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseAdjustmentsComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);

  selected_month = new FormControl('');

  selected_vendor = new FormControl('');

  search = new FormControl('');

  editMode$ = new BehaviorSubject(false);

  isVendorEstimatesLocked$ = new BehaviorSubject(false);

  removeVendorEstimateLoading$ = new BehaviorSubject(false);

  selectedVendorCurrency = Currency.USD;

  showUnitTotals$ = new BehaviorSubject(false);

  isVendorEstimateExpandChange$ = new BehaviorSubject(true);

  hideVendorEstimatePercentLTD$ = new BehaviorSubject(true);

  fillGridTimeout?: NodeJS.Timeout;

  hideVendorEstimateAmountLTD$ = new BehaviorSubject(true);

  vendorEstimates$ = new BehaviorSubject([] as VendorEstimateSummary[]);

  doesSelectedMonthHasVendorEstimate$ = combineLatest([
    this.vendorEstimates$,
    this.selected_vendor.valueChanges.pipe(startWith(this.selected_vendor.value)),
  ]).pipe(
    map(() => {
      return this.doesSelectedMonthHasVendorEstimate();
    })
  );

  doesSelectedMonthHasVendorEstimate = () => {
    const estimates = this.vendorEstimates$.getValue();
    const selectedVendor = this.selected_vendor.value || '';
    return !!estimates.filter(
      (estimate) => estimate.organization_id === selectedVendor && estimate.vendor_estimate_exists
    ).length;
  };

  vendorEstimateSupportingDocUploaded$ = new Subject<void>();

  vendorEstimateSupportingDoc$ = new BehaviorSubject<listDocumentsQuery['items']>([]);

  @ViewChild('monthSelect') monthSelect?: NgSelectComponent;

  doesSelectedMonthHasVendorEstimateSupportingDoc$ = combineLatest([
    this.vendorEstimateSupportingDoc$,
    this.selected_month.valueChanges.pipe(startWith(this.selected_month.value)),
  ]).pipe(
    map(([estimates]) => {
      const selectedMonth = this.selected_month.value || '';
      return !!estimates.filter(
        (estimate) => estimate.target_date?.slice(0, 7) === selectedMonth.slice(0, 7)
      ).length;
    })
  );

  isSelectedMonthOpen$ = new BehaviorSubject<boolean>(false);

  isSelectedMonthOpenOrFuture$ = new BehaviorSubject<boolean>(false);

  private isSelectedMonthFuture = false;

  getNonEditableCellClasses = TableService.getNonEditableCellClasses(this.editMode$);

  getEditableCellClasses = TableService.getEditableCellClasses(this.editMode$);

  getEditableHeaderClasses = TableService.getEditableHeaderClasses(this.editMode$);

  currencyFormatter = (params: ValueFormatterParams) => {
    return Utils.agCurrencyFormatterAccounting(params, this.selectedVendorCurrency);
  };

  userHasAdjustPermission$ = new BehaviorSubject<boolean>(false);

  hasEditVendorEstimatePermission$ = new BehaviorSubject<boolean>(false);

  vendors$ = this.budgetQuery.select('budget_info').pipe(
    map((data) => {
      const allVendors = this.organizationQuery.getAllVendors();
      return data.map((info) => ({
        name: allVendors.find((vendor) => vendor.id === info.vendor_id)?.name || info.name,
        id: info.vendor_id,
      }));
    })
  );

  months: (QuarterDate & { label: string })[] = [];

  selected_category = new FormControl('');

  selected_threshold = new FormControl('');

  defaultCategories: { label: string; value: ActivityType | '' }[] = [
    { label: 'All', value: '' },
    { label: 'Services', value: ActivityType.ACTIVITY_SERVICE },
    { label: 'Discount', value: ActivityType.ACTIVITY_DISCOUNT },
    { label: 'Pass-through', value: ActivityType.ACTIVITY_PASSTHROUGH },
    { label: 'Investigator', value: ActivityType.ACTIVITY_INVESTIGATOR },
  ];

  categories: { label: string; value: ActivityType | '' }[] = this.defaultCategories;

  materialityThresholds: { label: string; value: number | string }[] = [
    { label: 'All', value: '' },
    { label: '>$100,000', value: 100_000 },
    { label: '>$50,000', value: 50_000 },
    { label: '>$25,000', value: 25_000 },
    { label: '>$10,000', value: 10_000 },
    { label: '>$5,000', value: 5_000 },
  ];

  editedRows = new Set<string>();

  editedOldGridRow = new Map<
    string,
    {
      tma_amount: number;
      tma_unit_cost: number;
      historical_adjustment_amount: number;
      vendor_estimate_amount: number;
    }
  >();

  vendorEstimateChangedRows = new Set<{ activity_id: string; time: Dayjs }>();

  vendorEstimatePercentChangedRows = new Set<{ activity_id: string; time: Dayjs }>();

  vendorEstimateAmountLtdChangedRows = new Set<{ activity_id: string; time: Dayjs }>();

  vendorEstimateEffectedRows = new Set<string>();

  vendorEstimateSelectableCategories = new Set<ActivityType>();

  unitChangedRows = new Set<string>();

  totalChangedRows = new Set<string>();

  sourceChangedRows = new Set<string>();

  manualsToBeDeleted = new Set<string>();

  historicalAdjustmentToBeDeleted = new Set<string>();

  historicalAdjustmentChangedRows = new Set<string>();

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  // Allows AgAdjustmentEvidenceBasedHeader to
  // get the currently selected values for the
  // Month and Vendor form control (filters)

  getSelectedMonthAndVendor: EvidenceBasedHeaderGetMonthVendor = () => {
    const month = this.selected_month.value || '';
    const vendor = this.selected_vendor.value || '';

    return [month, vendor];
  };

  getSelectedVendorCurrency: EvidenceBasedHeaderGetVendorCurrency = () => {
    return this.organizationQuery.getEntity(this.selected_vendor.value)?.currency || Currency.USD;
  };

  gridOptions$ = new BehaviorSubject<GridOptions | null>(null);

  gridApi$ = new BehaviorSubject<GridApi | null>(null);

  loading$ = new BehaviorSubject(true);

  gridDataProcessing$ = new BehaviorSubject(false);

  afterOnSave = new BehaviorSubject(false);

  invoiceCategoryTotalsDefault: InvoiceCategoryTotals = {
    service_total: 0,
    discount_total: 0,
    investigator_total: 0,
    passthrough_total: 0,
  } as InvoiceCategoryTotals;

  invoiceCategoryTotals: InvoiceCategoryTotals = this.invoiceCategoryTotalsDefault;

  inMonthExpenses$ = new BehaviorSubject<listInMonthExpensesQuery[]>([]);

  discountExpenses$ = new BehaviorSubject<listDiscountExpensesQuery[]>([]);

  estimate$ = new BehaviorSubject<Record<string, Record<string, InvestigatorEstimate>>>({});

  gridData$ = new BehaviorSubject<QuarterCloseAdjustmentGridData[]>([]);

  filteredGridData$ = this.selected_threshold.valueChanges.pipe(
    startWith(this.selected_threshold.value),
    switchMap((selectedThreshold) => {
      return this.gridData$.pipe(
        map((data) => {
          if (!selectedThreshold) {
            return data;
          }

          return data.filter((row) => {
            return (
              this.editedRows.has(row.activity_id) ||
              row.tma_amount > +selectedThreshold ||
              row.current_forecast_amount > +selectedThreshold ||
              row.vendor_estimate_amount > +selectedThreshold ||
              row.vendor_estimate_amount_ltd > +selectedThreshold ||
              row.evidence_based_amount > +selectedThreshold ||
              row.invoices_amount > +selectedThreshold
            );
          });
        })
      );
    }),
    shareReplay(1)
  );

  bottomRowData$ = new BehaviorSubject<Record<CalculableColumns, number>>(
    {} as Record<CalculableColumns, number>
  );

  saveCheck$ = new BehaviorSubject(false);

  isWorkflowLocked$ = this.workflowQuery.getLockStatusByWorkflowStepType$(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
  );

  isSelectedCategoryDiscount$ = this.selected_category.valueChanges.pipe(
    startWith(this.selected_category.value),
    map(() => this.selected_category.value === ActivityType.ACTIVITY_DISCOUNT)
  );

  editButtonDisabled$ = new BehaviorSubject(false);

  editButtonTooltip$ = new BehaviorSubject('');

  expenseSettings: Array<listExpenseSourceSettingsQuery> = [];

  gridUpdated = true;
  vendorEstimatesLoading = true;

  timelines$ = new BehaviorSubject<listTrialVendorTimelineQuery[]>([]);
  timelineLoading$ = new BehaviorSubject(false);
  showCantEdit = false;
  cantEditMessage =
    'Month was not closed in the Auxilius Product. Budget and vendor expense data not available on In-Month Adjustments.';

  invoiceExpenseSourceFilter = signal<InvoiceExpenseSourceFilterType>({
    ACTIVITY_DISCOUNT: false,
    ACTIVITY_INVESTIGATOR: false,
    ACTIVITY_PASSTHROUGH: false,
    ACTIVITY_SERVICE: false,
  });

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private budgetQuery: BudgetQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private mainQuery: MainQuery,
    private workflowQuery: WorkflowQuery,
    private organizationQuery: OrganizationQuery,
    private budgetService: BudgetService,
    private vendorService: QuarterCloseChecklistVendorService,
    public sitesQuery: SitesQuery,
    private sitesService: SitesService,
    private cdr: ChangeDetectorRef,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private documentLibraryService: DocumentLibraryService,
    private apiService: ApiService,
    private eventService: EventService,
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    private route: ActivatedRoute,
    private closingPageStore: ClosingPageStore,
    private invoiceService: InvoiceService
  ) {
    this.invoiceService.initialize().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.filteredGridData$.pipe(takeUntilDestroyed()).subscribe(async () => {
      await this.generatePinnedBottomData();
      this.updateBottomData();
    });

    this.saveCheck$.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe((val) => {
      this.closingPageStore.update({ adjustmentHasUnsavedChanges: val });
    });

    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.timelines$.next([]);
          this.timelineLoading$.next(true);
          return this.gqlService.listTrialVendorTimeline$();
        }),
        takeUntilDestroyed()
      )
      .subscribe(({ data }) => {
        if (data) {
          this.timelines$.next(data);
        }
        this.timelineLoading$.next(false);
      });

    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_VENDOR_ESTIMATE],
      }),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([adjustmenPerm, venderEPerm]) => {
        this.userHasAdjustPermission$.next(adjustmenPerm);
        this.hasEditVendorEstimatePermission$.next(venderEPerm);
      });

    combineLatest([
      this.quarterCloseAdjustmentsService.selectedMonthValue$,
      this.quarterCloseAdjustmentsService.selectedVendorValue$,
      this.quarterCloseAdjustmentsService.selectedCategoryValue$,
    ])
      .pipe(
        map(([selectedMonth, selectedVendor]) => {
          if (selectedMonth) {
            if (this.months.find((x) => x.iso === selectedMonth)) {
              this.selected_month.setValue(selectedMonth);
            }
          }
          if (selectedVendor) {
            this.selected_vendor.setValue(selectedVendor);
          }
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    combineLatest([
      this.isWorkflowLocked$,
      this.isSelectedMonthOpen$,
      this.isSelectedCategoryDiscount$,
      this.userHasAdjustPermission$,
      this.hasEditVendorEstimatePermission$,
      this.isVendorEstimatesLocked$,
    ])
      .pipe(
        map(
          ([
            isWorkflowLocked,
            isSelectedMonthOpen,
            isSelectedCategoryDiscount,
            adjustPerm,
            editVEPerm,
            isVendorEstimatesLocked,
          ]) => {
            const disabled =
              isWorkflowLocked ||
              !isSelectedMonthOpen ||
              isSelectedCategoryDiscount ||
              (!adjustPerm && editVEPerm && isVendorEstimatesLocked);

            this.editButtonDisabled$.next(disabled);

            let message = '';
            if (!isSelectedMonthOpen) {
              message = MessagesConstants.CHANGES_UNABLE_SINCE_MONTH_CLOSED;
            } else if (isSelectedCategoryDiscount) {
              message = MessagesConstants.CANT_ADJUST_DISCOUNT;
            } else if (isWorkflowLocked) {
              message = MessagesConstants.VENDOR_EXPENSES_LOCKED;
            } else if (
              !adjustPerm &&
              editVEPerm &&
              isVendorEstimatesLocked &&
              isSelectedMonthOpen
            ) {
              message = MessagesConstants.VENDOR_ESTIMATES_LOCKED;
            }

            this.editButtonTooltip$.next(message);
          }
        ),
        takeUntilDestroyed()
      )
      .subscribe();
    this.isWorkflowLocked$.pipe(takeUntilDestroyed()).subscribe((bool) => {
      if (bool && this.editMode$.getValue()) {
        this.onCancel();
      }
    });
    combineLatest([this.selected_category.valueChanges, this.sitesService.get(), this.gridApi$])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (
          this.selected_category.value === ActivityType.ACTIVITY_DISCOUNT &&
          this.editMode$.getValue()
        ) {
          this.onCancel();
        }
        this.updateCategoryFiltering();
      });

    this.mainQuery
      .select('userList')
      .pipe(takeUntilDestroyed())
      .subscribe((users) => {
        users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });

    this.selected_vendor.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe(() => {
        if (this.selected_vendor.value) {
          this.quarterCloseAdjustmentsService.updateFormControlValues(
            '',
            this.selected_vendor.value
          );
        }
      });

    this.selected_category.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe(() => {
        this.quarterCloseAdjustmentsService.updateSelectCategory(
          this.selected_category.value || ''
        );
      });

    this.search.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(), debounceTime(1000))
      .subscribe(async () => {
        const filteredData: QuarterCloseAdjustmentGridData[] = [];

        this.gridApi$.value?.forEachNodeAfterFilter((node) => {
          if (node.data) {
            filteredData.push(node.data);
          }
        });

        await this.generatePinnedBottomData(null, filteredData);
        this.updateBottomData();
      });

    combineLatest([
      this.selected_vendor.valueChanges.pipe(
        startWith(this.selected_vendor.value),
        distinctUntilChanged()
      ),
      this.vendorEstimateSupportingDocUploaded$.pipe(startWith(null)),
      this.eventService.select$(EventType.NEW_TASK).pipe(startWith(null)),
    ])
      .pipe(
        switchMap(([org_id]) => {
          if (org_id) {
            const filterModel = {
              document_type_id: {
                filterType: 'text',
                type: 'equals',
                filter: DocumentType.DOCUMENT_VENDOR_ESTIMATE_SUPPORT,
              },
              vendor_id: {
                filterType: 'text',
                type: 'equals',
                filter: org_id,
              },
            };
            return from(this.apiService.getDocumentLibraryList(JSON.stringify(filterModel)));
          }
          return of([]);
        }),
        tap((data: listDocumentsQuery['items']) => {
          this.vendorEstimateSupportingDoc$.next(data || []);
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    combineLatest([
      this.selected_month.valueChanges.pipe(
        startWith(this.selected_month.value),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        switchMap(([selected_month]) => {
          this.vendorEstimatesLoading = true;
          if (selected_month && !this.router.url.includes(ROUTING_PATH.CLOSING.CHECKLIST)) {
            this.quarterCloseAdjustmentsService.updateFormControlValues(selected_month);
            return from(
              this.gqlService.listVendorEstimateSummaries$(dayjs(selected_month).format('MMM-YYYY'))
            );
          }
          return of({ data: [] });
        }),
        tap(({ data }) => {
          this.vendorEstimates$.next(data || []);
          this.vendorEstimatesLoading = false;
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    combineLatest([
      this.selected_month.valueChanges.pipe(
        startWith(this.selected_month.value),
        distinctUntilChanged()
      ),
      this.selected_vendor.valueChanges.pipe(
        startWith(this.selected_vendor.value),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(([month, vendor]) => {
          this.showCantEdit = false;
          if (this.editMode$.getValue()) {
            this.onCancel();
          }
          this.selectedVendorCurrency =
            this.organizationQuery.getEntity(this.selected_vendor.value)?.currency || Currency.USD;
          this.loading$.next(true);
          if (month && vendor) {
            // Persist selected month to sync with quarter-close-checklist
            this.periodCloseService.persistedQuarterMonth = month;
            this.periodCloseService.selectedQuarterMonthChanged$.next(null);

            const formattedMonth = dayjs(month).format('MMM-YYYY').toUpperCase();
            return this.timelineLoading$.pipe(
              filterRxjs((x) => !x),
              switchMap(() => this.timelines$),
              switchMap((timelines) => {
                const selectedMonth = dayjs(month).startOf('month').format('YYYY-MM-DD');
                const timeline = timelines.find((t) => {
                  return t.vendor_id === vendor && t.timeline_month === selectedMonth;
                });

                const nonvendor_timeline = timelines.find((t) => {
                  return t.vendor_id === null && t.timeline_month === selectedMonth;
                });

                if (
                  (timeline &&
                    (timeline.month_close_source === EventType.BUDGET_MONTH_CLOSED ||
                      timeline.month_close_source === EventType.BUDGET_TEMPLATE_UPLOADED)) ||
                  (nonvendor_timeline &&
                    (nonvendor_timeline.month_close_state === 'OPEN' ||
                      nonvendor_timeline.month_close_state === 'FUTURE' ||
                      nonvendor_timeline.month_close_state === 'CLOSED'))
                ) {
                  this.showCantEdit = false;
                } else {
                  this.showCantEdit = true;

                  this.expenseSettings = [];
                  this.inMonthExpenses$.next([]);
                  this.invoiceCategoryTotals = this.invoiceCategoryTotalsDefault;
                  this.discountExpenses$.next([]);
                  this.parseExpenses();
                  this.loading$.next(false);

                  return EMPTY;
                }

                return combineLatest([
                  this.gqlService.listInMonthExpenses$({
                    organization_id: vendor,
                    period: formattedMonth,
                  }),
                  this.gqlService.listDiscountExpenses$({
                    amount_types: [AmountType.AMOUNT_DISCOUNT],
                    period: formattedMonth,
                    organization_id: vendor,
                  }),
                  this.gqlService.listExpenseSourceSettings$({
                    organization_id: vendor,
                    period: dayjs(month).format('YYYY-MM-DD'),
                  }),
                  this.gqlService.getInvoiceCategoryTotals$({
                    period: formattedMonth,
                    vendor_id: vendor,
                  }),
                ]);
              })
            );
          }

          this.loading$.next(false);

          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe(([{ data, errors }, discountExpenses, expenseSettings, invoiceCategoryTotals]) => {
        const err = errors || discountExpenses.errors;
        if (err) {
          this.overlayService.error(err);
        }

        this.expenseSettings = expenseSettings.data || [];
        this.isSelectedMonthOpen$.next(
          dayjs(this.periodCloseComponent.currentMonth)
            .date(1)
            .isSame(dayjs(this.selected_month.value).date(1))
        );
        this.isSelectedMonthFuture = dayjs(this.periodCloseComponent.currentMonth)
          .date(1)
          .isBefore(dayjs(this.selected_month.value).date(1));
        this.isSelectedMonthOpenOrFuture$.next(
          dayjs(this.periodCloseComponent.currentMonth)
            .date(1)
            .isSameOrBefore(dayjs(this.selected_month.value).date(1))
        );

        if (invoiceCategoryTotals.success && invoiceCategoryTotals.data) {
          this.invoiceCategoryTotals = invoiceCategoryTotals.data;
        } else {
          this.invoiceCategoryTotals = this.invoiceCategoryTotalsDefault;
        }

        this.inMonthExpenses$.next(data || []);
        this.discountExpenses$.next(discountExpenses.data || []);
        this.parseExpenses();
        // this.generatePinnedBottomData();

        this.loading$.next(false);
      });

    combineLatest([this.shouldShowEvidenceColumns$, this.vendorEstimates$])
      .pipe(takeUntilDestroyed())
      .subscribe(([shouldShowEB]) => {
        this.gridUpdated = false;
        this.gridOptions$.next(
          this.getGridOptions(dayjs(this.selected_month.value).format('MMM YYYY'), !shouldShowEB)
        );
        setTimeout(() => {
          this.gridUpdated = true;
          this.cdr.detectChanges();
        }, 0);
      });

    combineLatest([
      this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
      this.periodCloseComponent.selectedQuarter.valueChanges.pipe(
        startWith(this.periodCloseComponent.selectedQuarter.value)
      ),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.updateMonths();
      });
    this.gridData$.pipe(takeUntilDestroyed()).subscribe((gd) => {
      if (gd.length > 0) {
        const usedActivityTypes: ActivityType[] = [];
        gd.forEach((element) => {
          if (!usedActivityTypes.includes(element.activity_type)) {
            usedActivityTypes.push(element.activity_type);
          }
        });
        this.categories = this.defaultCategories.filter(
          (category) =>
            category.value === '' || usedActivityTypes.includes(category?.value as ActivityType)
        );
        //reset selected category to all if vendor does not have that category
        if (!this.categories.some((category) => category.value === this.selected_category.value)) {
          this.selected_category.setValue(this.defaultCategories[0].value);
        }
      }
    });

    combineLatest([this.gridApi$.pipe(take(1), startWith(null)), this.mainQuery.select('trialKey')])
      .pipe(
        switchMap(() =>
          combineLatest([
            this.launchDarklyService.select$((flags) => flags.adjustments_unit_of_measure),
            this.launchDarklyService.select$((flags) => flags.adjustments_unit_totals),
            combineLatest([
              this.launchDarklyService.select$((flags) => flags.vendor_estimate_percent_ltd),
              this.launchDarklyService.select$((flags) => flags.vendor_estimate_dollars_ltd),
              this.isVendorEstimateExpandChange$,
            ]),
            this.launchDarklyService.select$((flags) => flags.vendor_estimate_percent_ltd),
            this.launchDarklyService.select$((flags) => flags.vendor_estimate_dollars_ltd),
          ])
        ),
        takeUntilDestroyed()
      )
      .subscribe(
        ([
          uom,
          showUnitTotals,
          [showVendorEstimatePercentColumn, showVendorEstimateAmountColumn],
        ]) => {
          uomHide$.next(!uom);
          this.hideVendorEstimatePercentLTD$.next(!showVendorEstimatePercentColumn);

          this.hideVendorEstimateAmountLTD$.next(!showVendorEstimateAmountColumn);

          const api = this.gridApi$.getValue();
          if (api) {
            AgSetColumnsVisible({
              gridApi: api,
              keys: ['uom'],
              visible: uom,
            });

            const isVendorEstimateExpanded = !!api
              .getColumn('vendor_estimate_percentage')
              ?.isVisible();

            if (isVendorEstimateExpanded) {
              AgSetColumnsVisible({
                gridApi: api,
                keys: ['vendor_estimate_percent_ltd'],
                visible: showVendorEstimatePercentColumn,
              });
              AgSetColumnsVisible({
                gridApi: api,
                keys: ['vendor_estimate_amount_ltd'],
                visible: showVendorEstimateAmountColumn,
              });
            }
          }
          this.showUnitTotals$.next(showUnitTotals);
        }
      );
  }

  ngOnInit(): void {
    this.workflowQuery
      .getLockStatusByWorkflowStepType$(WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_VENDOR_ESTIMATES)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isVendorEstimatesLocked) =>
        this.isVendorEstimatesLocked$.next(isVendorEstimatesLocked)
      );

    this.shouldShowEvidenceColumns$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((shouldShowEvidenceColumns) => {
        this.shouldShowEvidenceColumns = shouldShowEvidenceColumns;
      });

    this.periodCloseComponent.selectedMonth$
      .pipe(
        distinctUntilChanged(),
        switchMap((data) => {
          if (data) {
            const { month, category, vendor } = data;
            return of({
              month: dayjs(month).date(3).format('YYYY-MM-DD'),
              category: category.replace('CATEGORY', 'ACTIVITY'),
              vendor,
            });
          }
          return combineLatest([
            this.vendors$,
            this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
          ]).pipe(
            switchMap(([vendors]) => {
              if (!this.months.length) {
                const bool = this.updateMonths();
                if (!bool) {
                  return EMPTY;
                }
              }

              // If navigating from Period Close Checklist, we
              // need to filter by vendor id.

              // First, we need to confirm the incoming vendor id is available.
              // If it isn't, we'll default to the original functionality:
              // (selecting the first available vendor in the vendor array).

              const { filterByVendorId } = this.vendorService;
              let selectedVendorId = '';
              let IsVendorIdParamPresent = false;

              if (filterByVendorId) {
                IsVendorIdParamPresent = true;
                const vendorIds = vendors.map((vendor) => vendor.id);

                if (vendorIds.includes(filterByVendorId)) {
                  selectedVendorId = filterByVendorId;
                }

                this.vendorService.filterByVendorId = '';
              }

              if (!selectedVendorId) {
                selectedVendorId = vendors[0].id || '';
              }

              const persistedMonth = this.periodCloseService.getSelectedQuarterMonth(
                this.router.url
              );
              const persistedQuarterMonth = dayjs(persistedMonth).date(3).format('YYYY-MM-DD');

              const obj = {
                month: persistedQuarterMonth,
                category: this.selected_category.value,
                vendor: selectedVendorId,
              } as { month: string; category: string; vendor: string };

              // find out if we have selected month, category, vendor
              const overrideDefaultIfNecessary = (
                field: keyof typeof obj,
                fc: FormControl,
                arr: string[]
              ) => {
                if (fc.value) {
                  const bool = !!arr.filter((val) => val === fc.value).length;
                  if (bool) {
                    if (IsVendorIdParamPresent && field === 'vendor') {
                      obj[field] = selectedVendorId;
                    } else {
                      obj[field] = fc.value;
                    }
                  }
                }
              };
              overrideDefaultIfNecessary(
                'month',
                this.selected_month,
                this.months.map((m) => m.iso)
              );
              overrideDefaultIfNecessary(
                'category',
                this.selected_category,
                this.categories.map((c) => c.value)
              );
              overrideDefaultIfNecessary(
                'vendor',
                this.selected_vendor,
                vendors.map((v) => v.id || '')
              );
              return of(obj);
            })
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ month, category, vendor }) => {
        this.selected_month.setValue(month);
        this.selected_category.setValue(category);
        this.selected_vendor.setValue(vendor);
        // This is here to prevent issues like AUXI-3632 and AUXI-3631
        this.periodCloseComponent.selectedMonth$.next(null);
      });

    this.updateFiltersOnRouterQueryParams();

    this.mainQuery
      .select('trialKey')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.editMode$.next(false);
        this.saveCheck$.next(false);
      });
  }

  ngOnDestroy(): void {
    this.closingPageStore.update({ adjustmentHasUnsavedChanges: false });
  }

  private clearRouterQueryParams() {
    this.router.navigate([], {
      queryParams: {
        vendorId: null,
        editMode: null,
        currentOpenMonth: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  getCellClass() {
    const expanded = localStorage.getItem(LocalStorageKey.CLOSING_PAGE_VENDOR_ESTIMATE);
    return expanded ? [AuxExcelStyleKeys.BORDER_LEFT] : [];
  }

  getGridOptions(totalDate: string, hideEvidence = false) {
    const attributes = getAttributeColumns({
      attributes: this.gridData$.getValue().map((z) => z.attributes || []),
    });

    const defs: (ColDef | ColGroupDef)[] = [];

    const attr = attributeColumnDef(
      attributes,
      LocalStorageKey.IN_MONTH_ACTIVITIES_HEADER_COLLAPSED,
      true
    );

    defs.push(attr);

    return {
      ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
      suppressPropertyNamesCheck: true,
      stopEditingWhenCellsLoseFocus: true,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.event.key === 'Tab') {
            return this.tabToNextCell(params);
          }

          if (!params.editing) {
            switch (params.event.key) {
              // delete button code 46 - backspace button code 8
              case 'Backspace':
              case 'Delete':
                if (this.editMode$.getValue()) {
                  TableService.clearCellRange(params, (_, startIndex, endIndex) => {
                    let columnsToClear: string[] = [];
                    if (
                      [
                        'vendor_estimate_percent_ltd',
                        'vendor_estimate_amount',
                        'vendor_estimate_amount_ltd',
                      ].includes(params.column.getColId())
                    ) {
                      columnsToClear = [
                        'vendor_estimate_amount',
                        'vendor_estimate_amount_ltd',
                        'vendor_estimate_percentage',
                        'vendor_estimate_unit',
                        'vendor_estimate_percent_ltd',
                      ];
                    } else if (params.column.getColId() === 'historical_adjustment_amount') {
                      columnsToClear = ['historical_adjustment_amount'];
                    } else if (
                      ['tma_percentage', 'tma_unit', 'tma_amount'].includes(
                        params.column.getColId()
                      )
                    ) {
                      columnsToClear = ['tma_percentage', 'tma_unit', 'tma_amount'];
                    }
                    TableService.clearCells(
                      startIndex,
                      endIndex,
                      columnsToClear,
                      this.gridApi$.getValue() as GridApi
                    );

                    for (let i = startIndex; i <= endIndex; i++) {
                      const row = params.api.getModel().getRow(i);
                      const rowData = row?.data;

                      if (rowData) {
                        switch (params.column.getColId()) {
                          case 'vendor_estimate_percent_ltd':
                            this.vendorEstimatePercentChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount, // historical adjustment
                              rowData.vendor_estimate_amount, // old value
                              0,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'vendor_estimate_amount':
                            this.vendorEstimateChangedRows.add({
                              activity_id: rowData.activity_id,
                              time: dayjs(),
                            });
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount, // historical adjustment
                              rowData.vendor_estimate_amount, // old value
                              0,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'vendor_estimate_amount_ltd':
                            this.vendorEstimateAmountLtdChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount, // historical adjustment
                              rowData.vendor_estimate_amount, // old value
                              0,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'historical_adjustment_amount':
                            this.historicalAdjustmentChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              0, // historical adjustment
                              rowData.historical_adjustment_amount, // old value
                              rowData.vendor_estimate_amount,
                              rowData.tma_unit,
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'tma_unit':
                            this.unitChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount,
                              rowData.vendor_estimate_amount,
                              rowData.tma_unit, // old value
                              0, // number of units
                              rowData.tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                          case 'tma_amount':
                          default:
                            this.totalChangedRows.add(rowData.activity_id);
                            this.updateDynamicFields(
                              0, // new value
                              rowData.activity_id,
                              params.column.getColId(),
                              rowData.tma_unit_cost,
                              rowData.historical_adjustment_amount,
                              rowData.vendor_estimate_amount,
                              rowData.tma_amount, // old value
                              rowData.tma_unit,
                              0, // tma_amount,
                              rowData.total_amount,
                              rowData.tma_source,
                              rowData
                            );
                            break;
                        }
                        this.editedRows.add(rowData.activity_id);
                      }
                    }

                    this.saveCheck$.next(true);
                    this.cdr.detectChanges();
                  });
                }

                this.forceCalculateCategoryLvlRows();

                return true;
              default:
                return false;
            }
          }
          return false;
        },
      },
      groupIncludeTotalFooter: false,
      suppressAggFuncInHeader: true,
      groupDefaultExpanded: 1,
      groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
      suppressColumnVirtualisation: true,
      columnDefs: [
        {
          minWidth: 250,
          width: 250,
          field: 'activity_name',
          tooltipField: 'activity_name',
          resizable: true,
          headerName: 'Activities',
          headerComponent: AgBudgetGroupHeaderComponent,
          headerComponentParams: {
            expandLevel: () => -1,
            template: `Activities`,
            localStorageKey: LocalStorageKey.IN_MONTH_ACTIVITIES_HEADER_COLLAPSED,
            columnsToCollapse: attr.children.map((x: ColDef) => x.colId || x.field),
          } as AgBudgetAttributeComponentParams,
          cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
          cellRendererParams: {
            suppressCount: true,
          },
          showRowGroup: true,
          pinned: 'left',
          cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
        },
        ...defs,
        ...getActivitiesColumnDefs(
          this.getNonEditableCellClasses,
          this.selectedVendorCurrency,
          this.showUnitTotals$,
          this.selected_month.value || ''
        ),
        {
          headerName: 'Previous Month',
          headerClass: ['ag-header-align-center'],
          headerGroupComponent: AgAdjustmentPrevMonthHeaderComponent,
          headerGroupComponentParams: {
            collapsedByDefault: true,
            selected_month: this.selected_month,
            localStorageKey: 'closing_page_adjustment_prev_month',
            expandableCols: ['prev_month_unit'],
          },
          children: getPreviousMonthColumnDefs(
            this.getNonEditableCellClasses,
            this.selectedVendorCurrency,
            this.showUnitTotals$
          ),
        },
        ...getCurrentForecastColumnDefs(
          this.getNonEditableCellClasses,
          this.selectedVendorCurrency,
          this.showUnitTotals$
        ),
        ...(this.vendorEstimateColumnChecker()
          ? [
              {
                headerName: 'Vendor Estimate',
                groupId: 'vendorEstimate',
                headerClass: ['ag-header-align-left blue-header header-marker'],
                headerGroupComponent: AgAdjustmentVendorEstimateHeaderComponent,
                headerGroupComponentParams: {
                  collapsedByDefault: true,
                  expandableCols: [
                    'vendor_estimate_percentage',
                    'vendor_estimate_unit',
                    'vendor_estimate_percent_ltd',
                    'vendor_estimate_amount_ltd',
                  ],
                  filterCols: (column: Column) => {
                    this.isVendorEstimateExpandChange$.next(true);

                    const columns = this.hideVendorEstimatePercentLTD$.getValue()
                      ? ['vendor_estimate_percent_ltd']
                      : [];

                    return (
                      ['vendor_estimate_amount', 'vendor_estimate_amount_ltd', ...columns].indexOf(
                        column.getColId()
                      ) === -1
                    );
                  },
                  removeVendorEstimateLoading$: this.removeVendorEstimateLoading$,
                  doesSelectedMonthHasVendorEstimate$: this.doesSelectedMonthHasVendorEstimate$,
                  doesSelectedMonthHasVendorEstimateSupportingDoc$:
                    this.doesSelectedMonthHasVendorEstimateSupportingDoc$,
                  isSelectedMonthOpenOrFuture$: this.isSelectedMonthOpenOrFuture$,
                  isSelectedMonthOpen$: this.isSelectedMonthOpen$,
                  isEditMode$: this.editMode$,
                  onDeleteVendorEstimate: () => {
                    this.removeBudgetVendorEstimate();
                  },
                  onEditAdjustments: async () => {
                    const hasAdjustmentPermission = await firstValueFrom(
                      this.userHasAdjustPermission$
                    );
                    const hasEditVendorEstimatePermission =
                      this.hasEditVendorEstimatePermission$.getValue();

                    if (
                      !this.editMode$.getValue() &&
                      (hasAdjustmentPermission || hasEditVendorEstimatePermission)
                    ) {
                      this.onEditMode();
                    }
                  },
                  onDownloadVendorEstimate: () => {
                    this.onDownloadVendorEstimates();
                  },
                  onUploadVendorEstimate: () => {
                    this.onUploadVendorEstimate();
                  },
                  localStorageKey: LocalStorageKey.CLOSING_PAGE_VENDOR_ESTIMATE,
                  hasEditVendorEstimatePermission$: this.hasEditVendorEstimatePermission$,
                  onToggle: this.onToggle,
                },
                cellClass: this.getCellClass(),
                children: getVendorEstimateColumnDefs(
                  this.getNonEditableCellClasses,
                  this.getSelectedVendorCurrency,
                  this.editMode$,
                  this.showUnitTotals$,
                  this.hideVendorEstimatePercentLTD$,
                  this.hideVendorEstimateAmountLTD$,
                  this.hasEditVendorEstimatePermission$,
                  this.isVendorEstimatesLocked$
                ),
              },
            ]
          : []),
        ...getInvoicesColumnDefs(
          this.selectedVendorCurrency,
          this.invoiceCategoryTotals,
          this.getNonEditableCellClasses,
          `${dayjs(this.selected_month.value).format('YYYY-MM')}-01` ===
            this.periodCloseComponent.currentMonth,
          this.redirectFromInvoiceColumn,
          this.doesInvoiceMappingsMatchTotal
        ),
        ...(hideEvidence
          ? []
          : getEvidenceBasedColumnDefs(
              this.getNonEditableCellClasses,
              this.editMode$,
              this.getSelectedMonthAndVendor,
              this.selectedVendorCurrency,
              this.showUnitTotals$
            )),
        ...([
          {
            headerName: 'Total Expense',
            headerClass: ['ag-header-align-center green-header header-marker'],
            headerGroupComponent: AgExpandableGroupHeaderComponent,
            headerGroupComponentParams: {
              collapsedByDefault: true,
              localStorageKey: 'closing_page_total_monthly_accrual',
              filterCols: (column: Column) => {
                return ['total_monthly_expense', 'expense_ltd'].indexOf(column.getColId()) === -1;
              },
              expandableCols: [
                'tma_percentage',
                'tma_unit',
                'tma_amount',
                'variance_to_forecast',
                'tma_source',
                'historical_adjustment_amount',
                'total_adjustment',
                'notes',
                'support',
              ],
            },
            children: [
              {
                headerName: 'Monthly % Complete',
                field: 'tma_percentage',
                headerClass: ['ag-header-align-right'],
                cellClass: this.getNonEditableCellClasses([
                  'percent',
                  TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
                ]),
                minWidth: 100,
                width: 100,
                hide: true,
                valueGetter: (params) => {
                  // For Category/Group and Discount rows
                  if (!params.data) {
                    const { total_amount = 0, total_monthly_expense = 0 } =
                      params.node?.aggData || {};

                    return (total_monthly_expense / total_amount) * 100;
                  }

                  return params.data.tma_percentage;
                },
                valueFormatter: ({ value }) =>
                  Utils.percentageFormatter((value || 0) / 100, { maximumFractionDigits: 4 }),
              },
              {
                headerName: 'Monthly Units',
                field: 'tma_unit',
                hide: true,
                headerTooltip: !this.userHasAdjustPermission$.getValue()
                  ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                  : '',
                cellClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableCellClasses(['budget-units', 'ag-cell-align-right'])
                  : this.getNonEditableCellClasses(['budget-units', 'ag-cell-align-right']),
                headerClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableHeaderClasses(['ag-header-align-right'])
                  : this.getNonEditableCellClasses(['ag-header-align-right']),
                width: 85,
                minWidth: 70,
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                  hasPermission: this.userHasAdjustPermission$,
                },
                valueFormatter: ({ value }) => Utils.decimalFormatter(value),
                valueParser: (params) => {
                  return params.newValue === params.oldValue
                    ? params.oldValue
                    : Utils.castStringToNumber(params.newValue).toString();
                },
                editable: (params: EditableCallbackParams) =>
                  TableService.isEditableCellWithExtraPermission(
                    this.editMode$,
                    this.userHasAdjustPermission$
                  )(params),
                aggFunc: this.showUnitTotals$.getValue() ? 'sum' : '',
              },
              {
                headerName: `${totalDate} Monthly Expense ($)`,
                field: 'tma_amount',
                headerTooltip: !this.userHasAdjustPermission$.getValue()
                  ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                  : '',
                cellClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableCellClasses([
                      `budgetCost${this.selectedVendorCurrency}`,
                      'ag-cell-align-right',
                    ])
                  : this.getNonEditableCellClasses([
                      `budgetCost${this.selectedVendorCurrency}`,
                      'ag-cell-align-right',
                    ]),
                headerClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableHeaderClasses(['ag-header-align-right'])
                  : this.getNonEditableCellClasses(['ag-header-align-right']),
                hide: true,
                width: 165,
                minWidth: 100,
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                  hasPermission: this.userHasAdjustPermission$,
                },
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => {
                  return params.newValue === params.oldValue
                    ? params.oldValue
                    : Utils.castStringToNumber(params.newValue).toString();
                },
                editable: (params) =>
                  TableService.isEditableCellWithExtraPermission(
                    this.editMode$,
                    this.userHasAdjustPermission$
                  )(params),
                aggFunc: 'sum',
              },
              {
                headerName: 'Variance to Forecast',
                field: 'variance_to_forecast',
                width: 145,
                minWidth: 100,
                aggFunc: 'sum',
                hide: true,
                headerClass: 'ag-header-align-right',
                cellRenderer: VariationStatusComponent,
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                ]),
                valueParser: (params) => Number(params.newValue),
                valueFormatter: this.currencyFormatter,
              },
              {
                headerName: 'Expense Source',
                field: 'tma_source',
                hide: true,
                headerTooltip: !this.userHasAdjustPermission$.getValue()
                  ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                  : '',
                headerClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableHeaderClasses(['ag-header-align-left'])
                  : this.getNonEditableCellClasses(['ag-header-align-left']),
                width: 145,
                minWidth: 100,
                headerComponent: AgAdjustmentLinkHeaderComponent,
                cellClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableCellClasses([`!text-left`])
                  : this.getNonEditableCellClasses([`budgetCost${this.selectedVendorCurrency}`]),
                cellEditor: 'agRichSelectCellEditor',
                suppressFillHandle: true,
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                  hasPermission: this.userHasAdjustPermission$,
                },
                cellEditorParams: (params: ICellEditorParams) => {
                  const isVendorEstimateAvailable =
                    !!params.data.vendor_estimate ||
                    this.vendorEstimateSelectableCategories.has(params.data.activity_type);
                  const shouldShowEB =
                    params.data.activity_type === ActivityType.ACTIVITY_INVESTIGATOR &&
                    params.data.evidence_based_exist;
                  const shouldShowManual = this.sourceChangedRows.has(params.data.activity_id)
                    ? false
                    : !!params.data.manual_adjustment ||
                      this.unitChangedRows.has(params.data.activity_id) ||
                      this.totalChangedRows.has(params.data.activity_id);
                  const shouldShowForecast =
                    !!params.data.is_forecasted || this.costCategoryHasForecastSelected(params);
                  const shouldShowInvoice =
                    this.invoiceExpenseSourceFilter()[
                      params.data.activity_type as keyof InvoiceExpenseSourceFilterType
                    ];
                  return {
                    values: [
                      shouldShowForecast ? Expense_Source.Forecast : null,
                      isVendorEstimateAvailable ? Expense_Source['Vendor Estimate'] : null,
                      shouldShowEB ? Expense_Source['Evidence Based'] : null,
                      shouldShowInvoice ? Expense_Source.Invoice : null,
                      shouldShowManual ? Expense_Source.Manual : null,
                    ].filter((x) => x),
                    cellRenderer: AgSelectEditorOptionRendererComponent,
                  };
                },
                editable: (params) =>
                  TableService.isEditableCellWithExtraPermission(
                    this.editMode$,
                    this.userHasAdjustPermission$
                  )(params),
                valueFormatter: (params: ValueFormatterParams) => {
                  if (!params.value) {
                    return Utils.zeroHyphen;
                  }
                  return params.value;
                },
              },
              {
                headerName: 'Historical Adjustment',
                field: 'historical_adjustment_amount',
                hide: true,
                headerTooltip: !this.userHasAdjustPermission$.getValue()
                  ? MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION
                  : '',
                cellRenderer: AgAdjustmentDiscountTooltipComponent,
                cellRendererParams: {
                  isInEditMode: this.editMode$,
                  hasPermission: this.userHasAdjustPermission$,
                },
                cellClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableCellClasses([
                      `budgetCost${this.selectedVendorCurrency}`,
                      'ag-cell-align-right',
                    ])
                  : this.getNonEditableCellClasses([
                      `budgetCost${this.selectedVendorCurrency}`,
                      'ag-cell-align-right',
                    ]),
                headerClass: this.userHasAdjustPermission$.getValue()
                  ? this.getEditableHeaderClasses(['ag-header-align-right'])
                  : this.getNonEditableCellClasses(['ag-header-align-right']),
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => {
                  return params.newValue === params.oldValue
                    ? params.oldValue
                    : Utils.castStringToNumber(params.newValue).toString();
                },
                editable: (params) =>
                  TableService.isEditableCellWithExtraPermission(
                    this.editMode$,
                    this.userHasAdjustPermission$
                  )(params),
                aggFunc: 'sum',
              },
              {
                headerName: 'Total Adjustment',
                field: 'total_adjustment',
                headerClass: ['ag-header-align-right'],
                hide: true,
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                cellRenderer: AgAdjustmentColumnComponent,
                cellRendererParams: {
                  users: this.users,
                  selectedVendorCurrency: this.selectedVendorCurrency,
                  adjustmentDate: this.selected_month?.value || '',
                } as AgAdjustmentColumnParams,
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                valueParser: (params) => Number(params.newValue),
                aggFunc: 'sum',
              },
              {
                headerName: 'Monthly Expense incl. Historical Adjustments ($)',
                field: 'total_monthly_expense',
                headerClass: ['ag-header-align-right'],
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                width: 210,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                aggFunc: 'sum',
              },
              {
                headerName: `Expense LTD incl. ${totalDate}`,
                field: 'expense_ltd',
                headerClass: ['ag-header-align-right'],
                cellClass: this.getNonEditableCellClasses([
                  `budgetCost${this.selectedVendorCurrency}`,
                  'ag-cell-align-right',
                  'relative',
                ]),
                width: 145,
                minWidth: 100,
                valueFormatter: this.currencyFormatter,
                aggFunc: 'sum',
              },
              {
                headerName: 'Notes',
                field: 'notes',
                width: 100,
                hide: true,
                minWidth: 70,
                valueFormatter: (params) => {
                  if (params.data && !params.node?.isRowPinned()) {
                    const { length } = params.data.notes || [];
                    return length ? `${length} Note${length > 1 ? 's' : ''}` : 'Add Note';
                  }
                  return '';
                },
                onCellClicked: (event) => this.openNoteDialog(event),
                headerClass: ['ag-header-align-left'],
                cellClass: ['note-cell'],
              },
              {
                headerName: 'Support',
                field: 'support',
                width: 100,
                minWidth: 70,
                hide: true,
                onCellClicked: (event) => this.openSupportDocumentsDialog(event),
                valueFormatter: (params) => {
                  if (params.data && !params.node?.isRowPinned()) {
                    const documentCount = params.data.total_documents || 0;
                    return documentCount
                      ? `${documentCount} Document${documentCount > 1 ? 's' : ''}`
                      : 'Add Document';
                  }

                  return '';
                },
                headerClass: ['ag-header-align-left'],
                cellClass: ['note-cell', '!block'],
              },
            ],
          },
        ] as ColGroupDef[]),
      ],
      getRowClass: (params: RowClassParams): string => {
        return params.node.group ? 'category-row' : '';
      },
      getRowId: (params: GetRowIdParams) => {
        return params.data.activity_id;
      },
      processCellFromClipboard: (params) => {
        return Utils.castStringToNumber(params.value).toString();
      },
      getRowHeight: (params) => {
        if (params.node.group || params.data.activity_name === 'Total') {
          return 48;
        }
        return 40;
      },
    } as GridOptions;
  }

  private vendorEstimateColumnChecker(): boolean {
    if (
      this.organizationQuery
        .allReceivesEstimateVendors()
        .find((vendor) => vendor.id === this.selected_vendor.value)
    ) {
      return true;
    } else {
      return !!this.vendorEstimates$
        .getValue()
        .find((vendor) => vendor.organization_id === this.selected_vendor.value)
        ?.vendor_estimate_exists;
    }
  }

  onToggle = () => {
    this.gridApi$.getValue()?.refreshCells({
      force: true,
      columns: ['vendor_estimate_amount', 'vendor_estimate_amount_ltd'],
    });
  };

  async canDeactivate(): Promise<boolean> {
    if (
      this.saveCheck$.getValue() &&
      this.launchDarklyService.$select((flags) => flags.tab_in_month_adjustments)()
    ) {
      const result = this.overlayService.openUnsavedChangesConfirmation();
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  async openSupportDocumentsDialog(event: CellClickedEvent) {
    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        { current_month: string; entity_id: string; vendor_id: string | null },
        {
          total_documents?: number;
        },
        SupportModalComponent
      >({
        content: SupportModalComponent,
        settings: {
          header: 'Upload Support',
          primaryButton: {
            action: (instance) => {
              instance?.onSaveDocuments();
            },
            pendoTag: 'upload-support-in-month-adjustments',
          },
        },
        data: {
          current_month: `${dayjs(this.selected_month.value).format('YYYY-MM')}-01`,
          entity_id: event.data.activity_id,
          vendor_id: this.selected_vendor.value,
        },
      }).afterClosed$
    );
    if (isUndefined(resp.data?.total_documents)) {
      return;
    }
    this.gridData$.next(
      this.gridData$.getValue().map((row) => {
        return row.activity_id === event.data.activity_id
          ? {
              ...row,
              total_documents: resp.data?.total_documents || 0,
            }
          : row;
      })
    );
  }

  async openNoteDialog(event: CellClickedEvent) {
    const { notes, activity_id } = event.data;

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        { notes: Note[]; users: Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>> },
        NoteModalResponseType,
        NoteModalComponent
      >({
        content: NoteModalComponent,
        settings: {
          header: 'Add Note',
          primaryButton: {
            pendoTag: 'add-note-in-month-adjustments',
            action: (instance) => {
              instance?.close(true);
            },
          },
        },
        data: {
          notes,
          users: this.users,
        },
      }).afterClosed$
    );

    if (!resp.data?.note) {
      return;
    }

    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createNote$({
        metadata: JSON.stringify({
          month: dayjs(this.selected_month.value).format('MMM-YYYY').toUpperCase(),
        }),
        entity_id: activity_id,
        entity_type: EntityType.ACTIVITY,
        note_type: NoteType.NOTE_TYPE_GENERAL,
        message: Utils.scrubUserInput(resp.data.note),
      })
    );
    if (success && data) {
      this.overlayService.success();
      this.gridData$.next(
        this.gridData$.getValue().map((row) => {
          if (row.activity_id === activity_id) {
            return {
              ...row,
              notes: [
                {
                  create_date: dayjs().toISOString(),
                  note_type: NoteType.NOTE_TYPE_GENERAL,
                  message: resp.data?.note,
                  __typename: 'Note',
                  created_by: this.authQuery.getValue().sub,
                  entity_id: activity_id,
                  entity_type: EntityType.ACTIVITY,
                  id: data.id,
                } as Note,
                ...row.notes,
              ],
            };
          }
          return row;
        })
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  async generatePinnedBottomData(
    newVal: QuarterCloseAdjustmentGridData | null = null,
    _gridData: QuarterCloseAdjustmentGridData[] | null = null
  ) {
    const selectedCategory = this.selected_category.value;
    const columns = [
      'total_amount',
      'actuals_to_date',
      'total_remaining',
      'prev_month_amount',
      'current_forecast_amount',
      'vendor_estimate_amount',
      'evidence_based_amount',
      'tma_amount',
      'variance_to_forecast',
      'historical_adjustment_amount',
      'actuals_performed_to_date',
      'total_adjustment',
      'total_monthly_expense',
      'expense_ltd',
      'previous_months_accruals',
      'invoices_amount',
    ] as CalculableColumns[];

    if (this.showUnitTotals$.getValue()) {
      columns.push('units');
      columns.push('tma_unit');
      columns.push('prev_month_unit');
      columns.push('current_forecast_unit');
      columns.push('vendor_estimate_unit');
      columns.push('evidence_based_unit');
    }
    let gridData: QuarterCloseAdjustmentGridData[];
    if (_gridData) {
      gridData = _gridData;
    } else {
      gridData = await firstValueFrom(this.filteredGridData$.pipe(first()));
    }

    const totals = gridData
      .filter((row) => (selectedCategory ? row.activity_type === selectedCategory : true))
      .reduce(
        (acc, val) => {
          const rowValue = newVal?.activity_id === val.activity_id ? newVal : val;
          for (const col of columns) {
            acc[col] = Utils.roundToNumber(acc[col] || 0) + Utils.roundToNumber(rowValue[col] || 0);
          }
          return acc;
        },
        {} as Record<CalculableColumns, number>
      );

    const uniqCostCategoryNames = uniq(
      gridData
        .filter((row) => (selectedCategory ? row.activity_type === selectedCategory : true))
        .map((row) => row.activity_type)
    );

    let invoicesAvailableTotal = 0;

    uniqCostCategoryNames.forEach((costCategort) => {
      invoicesAvailableTotal +=
        costCategort === 'ACTIVITY_SERVICE'
          ? this.invoiceCategoryTotals.service_total
          : costCategort === 'ACTIVITY_DISCOUNT'
            ? this.invoiceCategoryTotals.discount_total
            : costCategort === 'ACTIVITY_INVESTIGATOR'
              ? this.invoiceCategoryTotals.investigator_total
              : costCategort === 'ACTIVITY_PASSTHROUGH'
                ? this.invoiceCategoryTotals.passthrough_total
                : 0;
    });

    totals.invoices_available = invoicesAvailableTotal;

    if (this.launchDarklyService.flags$.getValue().vendor_estimate_percent_ltd) {
      totals.vendor_estimate_percent_ltd = this.getVendorEstimatePercentCompleteLTD(
        totals.vendor_estimate_amount,
        totals.total_amount,
        totals.actuals_performed_to_date,
        totals.previous_months_accruals
      );
    }
    totals.vendor_estimate_amount_ltd = this.getVendorEstimateAmountCompleteLTD(
      totals.vendor_estimate_amount,
      totals.actuals_performed_to_date,
      totals.previous_months_accruals
    );

    this.bottomRowData$.next(totals);
    this.updateBottomData();
  }

  parseExpenses() {
    this.invoiceExpenseSourceFilter.set({
      ACTIVITY_DISCOUNT: false,
      ACTIVITY_INVESTIGATOR: false,
      ACTIVITY_PASSTHROUGH: false,
      ACTIVITY_SERVICE: false,
    });
    this.gridDataProcessing$.next(true);

    const rows = this.inMonthExpenses$.getValue() || [];

    const doesSelectedMonthHasVendorEstimate = some(rows, (row) => row.vendor_estimate !== null);
    const evidence_based_exists = rows.some((row) => !!row.evidence_based);
    const invoices_amount_exists = rows.some((row) => !!row.invoice?.amount);

    const gridData = rows.map((row) => {
      const total_amount = row.direct_cost?.amount || 0;
      const uom = row.direct_cost?.uom || '';
      const actuals_to_date = row.work_performed?.amount || 0;
      const total_remaining = total_amount - actuals_to_date;
      const isDiscountRow = row.activity_type === ActivityType.ACTIVITY_DISCOUNT;
      // const isInvestigatorRow = row.activity_type === ActivityType.ACTIVITY_INVESTIGATOR;
      const vendor_name =
        this.organizationQuery.getVendor(this.selected_vendor?.value || '')?.[0]?.name || '';

      const getColumnValuesForExpense = (
        exp?: Pick<
          BudgetExpenseData,
          | 'amount'
          | 'unit_cost'
          | 'expense_source'
          | 'unit_num'
          | 'amount_percentage_ltd'
          | 'amount_ltd'
        > | null
      ) => {
        const amount = exp?.amount || 0;
        const unit_cost = exp?.unit_cost || 0;
        const perc = total_amount ? (amount / total_amount) * 100 : 0;
        const unit = exp?.unit_num || (amount && unit_cost ? amount / unit_cost : 0);

        return {
          amount,
          perc,
          unit: isDiscountRow ? 0 : unit,
          unit_cost: isDiscountRow ? 0 : unit_cost,
          expense_source: exp?.expense_source,
          amount_ltd: exp?.amount_ltd,
          amount_percentage_ltd: exp?.amount_percentage_ltd,
        };
      };

      const current_forecast = getColumnValuesForExpense(row.forecast);

      const vendor_estimate = getColumnValuesForExpense(row.vendor_estimate);

      if (row.vendor_estimate) {
        if (row.activity_type === ActivityType.ACTIVITY_SERVICE) {
          this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
        }
        if (row.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
          this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
        }
        this.vendorEstimateSelectableCategories.add(row.activity_type);
      }

      const direct_cost = getColumnValuesForExpense(row.direct_cost);

      let tma_source = doesSelectedMonthHasVendorEstimate
        ? Expense_Source['Vendor Estimate']
        : Expense_Source.Forecast;

      const prev_month = getColumnValuesForExpense(row.prev_month_work_performed);

      const evidence_based_exist = evidence_based_exists;
      const invoices_amount_exist = invoices_amount_exists;

      const eba = getColumnValuesForExpense({
        amount: row?.evidence_based?.amount,
        unit_cost: row?.evidence_based?.unit_cost,
      });

      const total_monthly_expense = getColumnValuesForExpense(row.total_monthly_expense);

      /*
        row.manual_adjustment?.amount is just the manual adjustment and does not include historical adjustment,
        so if the monthly expense amount was originally $1000 and the user adjusted it to be $1500,
        manual_adjustment_amount would be $1500. If there was no manual adjustment made, this number will be 0.
      */
      const manual_adjustment_amount = row.manual_adjustment?.amount || 0;

      if (row.manual_adjustment) {
        tma_source = Expense_Source.Manual;
      }

      if (isDiscountRow) {
        current_forecast.perc = 0;
        vendor_estimate.perc = 0;
        const exp = this.discountExpenses$.getValue();
        const { discount_type } = JSON.parse(
          exp[0].manual_adjustment?.expense_detail || '{}'
        ) as DiscountExpenseDetail;
        switch (discount_type) {
          case 'contracted_calculated_discount':
            tma_source = Expense_Source.Forecast;
            break;
          case 'vendor_estimate_discount':
            tma_source = Expense_Source['Vendor Estimate'];
            break;
          case 'custom_calculated_discount':
          case 'custom_discount':
          case 'none':
            tma_source = Expense_Source.Manual;
            break;
        }
      }

      const historical_adjustment_amount = row.historical_adjustment?.amount || 0;

      const accrual = getColumnValuesForExpense(row.accrual);

      // subtracting old amount only if there was a manual adjustment because otherwise total adjustment would just be the historical adjustment
      const total_adjustment = total_monthly_expense.amount - accrual.amount;

      const monthly_expense = row.monthly_expense?.amount || 0;

      const expense_to_date = row.work_performed_to_date?.amount || 0;

      const prev_months_accruals = row.prev_months_accruals?.amount || 0;
      // Backend adds total monthly expense to the expense ltd,
      // so don't we need to add total_monthly_expense when displaying expense ltd for past months
      const expense_ltd_amount =
        expense_to_date +
        (this.isSelectedMonthFuture ? prev_months_accruals : 0) +
        total_monthly_expense.amount;

      const variance_to_forecast = decimalDifference(monthly_expense, current_forecast.amount, 2);

      const extraAttributes =
        row.attributes?.reduce(
          (acc, a) => {
            if (a.attribute_name && a.attribute_value) {
              acc[getEncodedAttributeName(a.attribute_name)] = a.attribute_value;
            }

            return acc;
          },
          {} as Record<string, string>
        ) || {};

      const shouldCalculateVendorEstimatePercentLtdManually =
        !row.vendor_estimate?.amount_percentage_ltd &&
        row.vendor_estimate?.amount_percentage_ltd !== 0;

      const shouldCalculateVendorEstimateAmountLtdManually =
        !row.vendor_estimate?.amount_ltd && row.vendor_estimate?.amount_ltd !== 0;

      if (row.invoice?.amount && row.invoice.amount !== 0) {
        this.invoiceExpenseSourceFilter.update((value) => ({
          ...value,
          [row.activity_type]: true,
        }));
      }

      const data = {
        ...extraAttributes,
        ...row,
        units: direct_cost.unit,
        uom,
        total_amount,
        actuals_to_date,
        actuals_performed_to_date: row.work_performed_to_date?.amount,
        total_remaining,
        vendor_name,
        cost_category_name:
          this.defaultCategories.find(({ value }) => value === row.activity_type)?.label || '',
        prev_month_unit: prev_month.unit,
        prev_month_amount: prev_month.amount,

        current_forecast_percentage: current_forecast.perc,
        current_forecast_unit: current_forecast.unit,
        current_forecast_amount: current_forecast.amount,

        vendor_estimate_percentage: vendor_estimate.perc,
        vendor_estimate_unit: vendor_estimate.unit,
        vendor_estimate_amount: vendor_estimate.amount,

        vendor_estimate_percent_ltd: shouldCalculateVendorEstimatePercentLtdManually
          ? this.getVendorEstimatePercentCompleteLTD(
              vendor_estimate.amount,
              total_amount,
              row.work_performed_to_date?.amount || 0,
              prev_months_accruals
            )
          : row.vendor_estimate?.amount_percentage_ltd,
        vendor_estimate_amount_ltd: shouldCalculateVendorEstimateAmountLtdManually
          ? this.getVendorEstimateAmountCompleteLTD(
              vendor_estimate.amount,
              row.work_performed_to_date?.amount || 0,
              prev_months_accruals
            )
          : row.vendor_estimate?.amount_ltd,

        tma_percentage:
          row.manual_adjustment || row.historical_adjustment
            ? total_amount
              ? ((total_monthly_expense.amount || manual_adjustment_amount) / total_amount) * 100
              : 0
            : total_monthly_expense.perc,
        tma_unit:
          row.manual_adjustment || row.historical_adjustment
            ? (manual_adjustment_amount || monthly_expense) /
              (total_monthly_expense.unit_cost || direct_cost?.unit_cost)
            : total_monthly_expense.unit,

        tma_amount: monthly_expense,
        variance_to_forecast,
        total_adjustment,
        historical_adjustment_amount,
        tma_unit_cost:
          row.forecast || row.vendor_estimate
            ? total_monthly_expense.unit_cost || direct_cost?.unit_cost || 0
            : direct_cost?.unit_cost || 0,
        total_monthly_expense: total_monthly_expense.amount,
        expense_ltd: expense_ltd_amount,
        previous_months_accruals: prev_months_accruals,
        tma_source: accrual.expense_source
          ? expenseSourceMapping[accrual.expense_source]
          : tma_source,

        evidence_based_amount: eba.amount,
        evidence_based_percentage: eba.perc,
        evidence_based_unit: eba.unit,
        evidence_based_exist,

        invoices_available: 0,
        invoices_amount: row.invoice?.amount || 0,
        invoices_percentage: 0,
        invoices_amount_exist,

        total_documents: row.documents.length,
      } as QuarterCloseAdjustmentGridData;

      if (isDiscountRow) {
        data.tma_unit = 0;
      }
      return data;
    });

    this.gridData$.next(gridData);

    this.gridOptions$.next(
      this.getGridOptions(
        dayjs(this.selected_month.value).format('MMM YYYY'),
        !this.shouldShowEvidenceColumns
      )
    );

    this.gridDataProcessing$.next(false);

    setTimeout(() => {
      this.updateCategoryFiltering();
    }, 0);
  }

  async removeBudgetVendorEstimate() {
    const vendors = this.budgetQuery.getValue().budget_info.map((info) => {
      return {
        label: info.name || '',
        value: info.vendor_id || '',
      };
    });

    const vendor = vendors.filter((x) => x.value === this.selected_vendor.value)[0];

    if (!vendor) {
      this.overlayService.error('No vendor found');
      return;
    }

    const selected_month = dayjs(this.selected_month.value).date(1).format('YYYY-MM-DD');

    const formatted_selected_month = formatDate(selected_month, 'MMMM-y', 'en-US');

    const ref = this.overlayService.openPopup({
      content: ConfirmationModalComponent,
      settings: {
        header: 'Remove Vendor Estimate',
        primaryButton: {
          label: 'Remove',
        },
      },
      data: {
        message: `Are you sure you want to remove the ${formatted_selected_month} vendor estimate for ${vendor.label}?`,
      },
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    if (!this.removeVendorEstimateLoading$.getValue() && resp.data) {
      this.removeVendorEstimateLoading$.next(true);
      const vendorEstimate = this.vendorEstimates$
        .getValue()
        .find((estimate) => estimate.organization_id === this.selected_vendor.value);
      if (!vendorEstimate) {
        this.removeVendorEstimateLoading$.next(false);
        this.overlayService.error('No vendor forecast found');
        return;
      }
      this.loading$.next(true);
      const success = await this.budgetService.removeVendorEstimate({
        vendor_id: vendor.value,
        target_month: selected_month,
      });
      if (success) {
        this.overlayService.success(`Successfully removed vendor estimate`);
      }
      this.removeVendorEstimateLoading$.next(false);
    }
  }

  async onDownloadVendorEstimates() {
    const { trialKey } = this.mainQuery.getValue();
    const currentMonth = dayjs(this.selected_month.value).format('MMMM-YYYY');
    const currentVendor = this.selected_vendor.value;
    const { success, data } = await this.apiService.getS3ZipFile(
      `trials/${trialKey}/vendors/vendor-estimate/${currentMonth}`,
      currentVendor
    );
    const vendorName = this.organizationQuery.getVendor(this.selected_vendor.value || '')[0].name;
    if (success && data) {
      const fileName = `${
        this.mainQuery.getSelectedTrial()?.short_name
      }-${vendorName}-${currentMonth}-vendor-estimate`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
  }

  async onUploadVendorEstimate() {
    const ref = this.overlayService.openPopup<
      undefined,
      { vendorId?: string },
      AddVendorEstimateUploadComponent
    >({
      settings: {
        header: 'Send Vendor Estimate Support to Auxilius',
        primaryButton: {
          label: 'Send Vendor Estimate Support',
          action: (instance) => instance?.onUpload(),
        },
      },
      content: AddVendorEstimateUploadComponent,
    });

    const resp = await firstValueFrom(ref.afterClosed$);

    const vendorId = resp.data?.vendorId;

    if (vendorId) {
      this.router.navigate([`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`], {
        queryParams: {
          vendorId,
          editMode: true,
          currentOpenMonth: dayjs(this.periodCloseComponent.currentMonth)
            .add(2, 'day')
            .format('YYYY-MM-DD'),
        },
      });
    }
    /*
      give it three seconds to upload before checking for supporting docs.
      If the NEW_TASK appsync notification doesn't reach the FE, this will ensure that we check for supporting docs
    */
    setTimeout(() => {
      this.vendorEstimateSupportingDocUploaded$.next();
    }, 3000);
  }

  updateMonths() {
    if (this.periodCloseComponent.selectedQuarter.value) {
      const months =
        this.periodCloseComponent.quartersObj[this.periodCloseComponent.selectedQuarter.value];

      const currentMonth = dayjs(this.periodCloseComponent.currentMonth);
      this.months = months.map((m: QuarterDate) => {
        const isClosed = m.parsedDate.isBefore(currentMonth);
        const isOpen = m.parsedDate.date(1).isSame(currentMonth);
        const label = `${m.parsedDate.format('MMMM YYYY')}${isClosed ? ' (Closed)' : ''}${
          isOpen ? ' (Open)' : ''
        }`;
        return { ...m, label };
      });
      return true;
    }
    return false;
  }

  shouldShowEvidenceColumns = false;

  shouldShowEvidenceColumns$ = combineLatest([
    this.sitesQuery.selectAll(),
    this.selected_vendor.valueChanges,
    this.selected_category.valueChanges.pipe(startWith(this.selected_category.value)),
  ]).pipe(
    map(([sites, vendor, category]) => {
      const ids = sites.map((site) => site.managed_by_id);
      return (
        (category === ActivityType.ACTIVITY_INVESTIGATOR || category === '') && ids.includes(vendor)
      );
    })
  );

  async updateCategoryFiltering() {
    const gridApi = this.gridApi$.getValue();
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance('activity_type');
      if (filterInstance) {
        filterInstance.setModel(
          this.selected_category.value
            ? {
                values: [this.selected_category.value],
              }
            : null
        );
      }
      gridApi.onFilterChanged();
    }

    await this.generatePinnedBottomData();
    this.updateBottomData();
  }

  updateBottomData() {
    this.gridApi$.getValue()?.setGridOption('pinnedBottomRowData', [
      merge(
        {
          activity_name: 'Total',
        },
        this.bottomRowData$.getValue()
      ),
    ]);
  }

  async onGridReady({ api }: GridReadyEvent) {
    this.gridApi$.next(api);
    if (this.afterOnSave.getValue()) {
      api.forEachNode((node) => {
        node.expanded = true;
      });
      api.onGroupExpandedOrCollapsed();
      this.gridApi$.next(api);
      this.afterOnSave.next(false);
    }

    await this.goToEditModeByRouteParams();

    this.updateCategoryFiltering();
  }

  private async goToEditModeByRouteParams() {
    const { editMode, vendorId, currentOpenMonth } = await firstValueFrom(this.route.queryParams);
    const isWorkflowLocked = await firstValueFrom(this.isWorkflowLocked$);

    if (
      editMode &&
      this.isSelectedMonthOpen$.getValue() &&
      !isWorkflowLocked &&
      !this.isVendorEstimatesLocked$.value
    ) {
      setTimeout(() => {
        this.onEditMode();
      }, 500);
    }

    if (vendorId || currentOpenMonth || editMode) {
      this.clearRouterQueryParams();
    }
  }

  updateFiltersOnRouterQueryParams() {
    combineLatest([
      this.periodCloseComponent.loading$,
      this.loading$,
      this.gridDataProcessing$,
      this.route.queryParams,
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filterRxjs(
          ([periodCloseLoading, loadingPage, gridDataProcessing]) =>
            periodCloseLoading === false && loadingPage === false && gridDataProcessing === false
        )
      )
      .subscribe(async ([, , , { vendorId, currentOpenMonth }]) => {
        if (vendorId) {
          this.selected_vendor.setValue(vendorId);
        }

        if (currentOpenMonth) {
          this.selected_month.setValue(currentOpenMonth);
        }
      });
  }

  async onCancel() {
    this.editMode$.next(false);
    this.saveCheck$.next(false);
    this.editedRows.clear();
    this.editedOldGridRow.clear();
    this.unitChangedRows.clear();
    this.totalChangedRows.clear();
    this.sourceChangedRows.clear();
    this.manualsToBeDeleted.clear();
    setTimeout(() => {
      this.gridApi$.getValue()?.redrawRows();
      this.gridApi$.getValue()?.refreshHeader();
    }, 0);
    this.historicalAdjustmentChangedRows.clear();
    this.vendorEstimateChangedRows.clear();
    this.vendorEstimatePercentChangedRows.clear();
    this.vendorEstimateAmountLtdChangedRows.clear();
    this.vendorEstimateEffectedRows.clear();
    this.vendorEstimateSelectableCategories.clear();
    this.parseExpenses();
  }

  private getBudgetInfo() {
    const budget = this.budgetQuery.getValue();

    return budget.budget_info.filter((info) => info.vendor_id === this.selected_vendor.value)[0];
  }

  async onSave() {
    const api = this.gridApi$.getValue();
    const activity_ids: string[] = [];

    this.afterOnSave.next(true);

    api?.forEachNode((row) => {
      if (row.data && this.editedRows.has(row.data.activity_id)) {
        activity_ids.push(row.data.activity_id);
      }
    });

    const resp = await firstValueFrom(
      this.overlayService.openPopup<
        { current_month: Maybe<string>; entity_ids: string[]; vendor_id: string | null },
        AdjustmentModalResponseType,
        AdjustmentModalComponent
      >({
        settings: {
          header: 'Save In-Month Adjustments',
          primaryButton: {
            pendoTag: 'save-in-month-adjustments',
            action: (instance) => {
              instance?.close(true);
            },
          },
        },
        content: AdjustmentModalComponent,
        data: {
          current_month: this.getBudgetInfo()?.current_month,
          entity_ids: activity_ids,
          vendor_id: this.selected_vendor.value,
        },
      }).afterClosed$
    );

    if (!resp.data?.note || !api) {
      return;
    }

    const adjustmentData: BudgetExpenseInput[] = [];
    const budget_info = this.getBudgetInfo();

    const adjustments: Record<string, listInMonthExpensesQuery['manual_adjustment']> = {};
    const vendorEstimateAdjustments: Record<string, listInMonthExpensesQuery['vendor_estimate']> =
      {};
    const historicalAdjustments: Record<string, listInMonthExpensesQuery['historical_adjustment']> =
      {};
    api.forEachNode((row) => {
      if (
        row.data &&
        this.editedRows.has(row.data.activity_id) &&
        !this.manualsToBeDeleted.has(row.data.activity_id)
      ) {
        const oldData: {
          tma_amount: number;
          tma_unit_cost: number;
          historical_adjustment_amount: number;
        } = this.editedOldGridRow.get(row.data.activity_id) || {
          historical_adjustment_amount: 0,
          tma_amount: 0,
          tma_unit_cost: 0,
        };

        const isUnitChanged = this.unitChangedRows.has(row.data.activity_id);
        const isTotalChanged = this.totalChangedRows.has(row.data.activity_id);

        /*
          Each editable column in the vendor estimate section has an associated set.
          Each set contains entries that pair an activity ID with the timestamp of the edit.
          The timestamp allows us to identify the most recently edited column,
          so we can send only the latest change to the backend.
        */
        const allVendorEstimateChanges = [
          ...this.vendorEstimateChangedRows,
          ...this.vendorEstimatePercentChangedRows,
          ...this.vendorEstimateAmountLtdChangedRows,
        ]
          .filter((item) => item.activity_id === row.data.activity_id)
          .sort((a, b) => b.time.diff(a.time));

        const mostRecentVendorEstimateChange = allVendorEstimateChanges[0];

        const isHistoricalAdjustmentChanged = this.historicalAdjustmentChangedRows.has(
          row.data.activity_id
        );

        const adjustment_type = isUnitChanged
          ? AdjustmentType.ADJUSTMENT_UNIT
          : AdjustmentType.ADJUSTMENT_AMOUNT;

        if (isUnitChanged || isTotalChanged) {
          adjustments[row.data.activity_id] = {
            id: row.data?.manual_adjustment?.id,
            amount: +row.data.tma_amount,
            adjustment_type,
            previous_amount: oldData?.tma_amount,
            unit_cost: oldData?.tma_unit_cost,
            __typename: 'BudgetExpenseData',
            updated_by: this.authQuery?.getValue()?.sub || '',
          };

          adjustmentData.push({
            budget_version_id: budget_info?.budget_version_id,
            activity_id: row.data.activity_id,
            expense_type_id: ExpenseType.EXPENSE_ACCRUAL_OVERRIDE,
            expense_detail: JSON.stringify({}),
            period_start: budget_info?.current_month,
            period_end: budget_info?.current_month,
            source: 'BASE',
            amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
            amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            amount: +row.data.tma_amount,
            adjustment_type,
            expense_source: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
          });
        }

        if (isHistoricalAdjustmentChanged) {
          historicalAdjustments[row.data.activity_id] = {
            id: row.data?.historical_adjustment?.id,
            amount: +row.data.historical_adjustment_amount,
            previous_amount: oldData?.historical_adjustment_amount,
            unit_cost: null,
            __typename: 'BudgetExpenseData',
            updated_by: this.authQuery?.getValue()?.sub || '',
          };

          adjustmentData.push({
            budget_version_id: budget_info?.budget_version_id,
            activity_id: row.data.activity_id,
            expense_type_id: ExpenseType.EXPENSE_HISTORICAL_ADJUSTMENT,
            expense_detail: JSON.stringify({}),
            period_start: budget_info?.current_month,
            period_end: budget_info?.current_month,
            source: 'BASE',
            amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
            amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            amount: +row.data.historical_adjustment_amount,
            adjustment_type: AdjustmentType.ADJUSTMENT_AMOUNT,
            expense_source: ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT,
          });
        }
        if (mostRecentVendorEstimateChange) {
          vendorEstimateAdjustments[row.data.activity_id] = {
            amount: +row.data.vendor_estimate_amount,
            unit_cost: row.data.direct_cost.unit_cost,
            __typename: 'BudgetExpenseData',
          };

          const { activity_id } = mostRecentVendorEstimateChange;

          let adjustmentEntry;
          if (this.vendorEstimateChangedRows.has(mostRecentVendorEstimateChange)) {
            adjustmentEntry = {
              budget_version_id: budget_info?.budget_version_id,
              activity_id: activity_id,
              expense_type_id: ExpenseType.EXPENSE_VENDOR_ESTIMATE,
              expense_detail: JSON.stringify({}),
              period_start: budget_info?.current_month,
              period_end: budget_info?.current_month,
              source: 'BASE',
              amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
              amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
              amount: +row.data.vendor_estimate_amount,
              adjustment_type: AdjustmentType.ADJUSTMENT_AMOUNT,
              expense_source: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
            };
          } else if (this.vendorEstimateAmountLtdChangedRows.has(mostRecentVendorEstimateChange)) {
            adjustmentEntry = {
              budget_version_id: budget_info?.budget_version_id,
              activity_id: activity_id,
              expense_type_id: ExpenseType.EXPENSE_VENDOR_ESTIMATE,
              expense_detail: JSON.stringify({}),
              period_start: budget_info?.current_month,
              period_end: budget_info?.current_month,
              source: 'BASE',
              amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
              amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
              amount: +row.data.vendor_estimate_amount_ltd,
              adjustment_type: AdjustmentType.ADJUSTMENT_AMOUNT_LTD,
              expense_source: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
            };
          } else if (this.vendorEstimatePercentChangedRows.has(mostRecentVendorEstimateChange)) {
            adjustmentEntry = {
              budget_version_id: budget_info?.budget_version_id,
              activity_id: activity_id,
              expense_type_id: ExpenseType.EXPENSE_VENDOR_ESTIMATE,
              expense_detail: JSON.stringify({}),
              period_start: budget_info?.current_month,
              period_end: budget_info?.current_month,
              amount_type: `AMOUNT_${row.data.activity_type?.slice(9)}` as AmountType,
              source: 'BASE',
              amount_percentage: +row.data.vendor_estimate_percent_ltd,
              adjustment_type: AdjustmentType.ADJUSTMENT_PERCENTAGE_LTD,
              expense_source: ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE,
              amount: 0,
              amount_curr: `CURRENCY_${this.selectedVendorCurrency}`,
            };
          }

          if (adjustmentEntry) {
            adjustmentData.push(adjustmentEntry);
          }
        }
      }
    });

    const sourceChangedRows: {
      id: string;
      newValue: ExpenseSourceType;
      hasAutoAdjustment: boolean;
    }[] = [];
    const manualsToBeDeleted: string[] = [];
    api.forEachNode((row) => {
      if (row.data) {
        if (this.sourceChangedRows.has(row.data.activity_id)) {
          sourceChangedRows.push({
            id: row.data.activity_id,
            newValue: expenseSourceReverseMapping[row.data.tma_source as Expense_Source],
            hasAutoAdjustment: !!row.data.historical_adjustment,
          });
        }
        if (this.manualsToBeDeleted.has(row.data.activity_id) && row.data.manual_adjustment?.id) {
          manualsToBeDeleted.push(row.data.manual_adjustment.id);
        }
      }
    });

    this.loading$.next(true);

    const maxBatchSize = 50;
    let { data, success, errors }: GraphqlResponse<batchCreateBudgetExpensesMutation[]> = {
      data: [],
      success: true,
      errors: [],
    };
    let i = 0;
    while (i < adjustmentData.length) {
      const result = await firstValueFrom(
        this.gqlService.batchCreateBudgetExpenses$(adjustmentData.slice(i, i + maxBatchSize))
      );
      success ||= result.success;
      if (result.success && result.data) {
        data = data.concat(result.data);
      } else {
        errors = errors.concat(result.errors);
      }

      i += maxBatchSize;
    }

    if (success && data) {
      const size = 100;
      const sourceChangedRowsBatches = [];
      for (let j = 0; j < sourceChangedRows.length; j += size) {
        sourceChangedRowsBatches.push(sourceChangedRows.slice(j, j + size));
      }

      const manualsToBeDeletedBatches: string[][] = [];
      for (let j = 0; j < manualsToBeDeleted.length; j += size) {
        manualsToBeDeletedBatches.push(manualsToBeDeleted.slice(j, j + size));
      }

      // Call this before removing any manual adjustments so that the backend can audit the changes
      await Promise.allSettled(
        sourceChangedRowsBatches.map((p) => {
          return firstValueFrom(
            this.gqlService.batchOverrideExpenseSources$({
              organization_id: <string>this.selected_vendor.value,
              period: <string>budget_info.current_month,
              overrides: p.map(({ id, newValue }) => {
                return { activity_id: id, source: newValue };
              }),
            })
          );
        })
      );

      await Promise.allSettled(
        manualsToBeDeletedBatches.map((p) => {
          return firstValueFrom(this.gqlService.batchRemoveBudgetExpenses$(p));
        })
      );

      const activityIds: string[] = [];
      for (const a of adjustmentData) {
        if (a.activity_id) {
          activityIds.push(a.activity_id);
        }
      }
      const vendorEstimateChangesRows = new Set(
        [
          ...this.vendorEstimateChangedRows,
          ...this.vendorEstimatePercentChangedRows,
          ...this.vendorEstimateAmountLtdChangedRows,
        ]
          .reduce((map, item) => map.set(item.activity_id, item), new Map())
          .values()
      );

      // I don't want to update accruals for activities in vendorEstimateChangesRows since they will be updated later
      const filteredActivityIds = activityIds.filter((id) => !vendorEstimateChangesRows.has(id));

      const ids = [...this.historicalAdjustmentToBeDeleted, ...filteredActivityIds];

      const uniqueIdsSet = new Set(ids);

      const uniqueIdsArray = Array.from(uniqueIdsSet);

      if (ids.length) {
        await firstValueFrom(
          this.gqlService.updateAccruals$({
            organization_id: <string>this.selected_vendor.value,
            period: <string>budget_info.current_month,
            activity_types: [],
            activity_ids: uniqueIdsArray,
          })
        );
      }

      await firstValueFrom(this.gqlService.invalidateBudgetCache$());
      const vendorEstimateChangedActivityIds = Array.from(vendorEstimateChangesRows).map(
        (item) => item.activity_id
      );

      if (vendorEstimateChangesRows.size > 0) {
        await this.updateVendorEstimateAccruals(new Set(vendorEstimateChangedActivityIds));
      }

      const updatedActivityIds = Array.from(
        new Set([
          ...this.unitChangedRows,
          ...this.totalChangedRows,
          ...this.sourceChangedRows,
          ...this.historicalAdjustmentChangedRows,
          ...Array.from(vendorEstimateChangedActivityIds),
        ])
      );

      const noteMaxBatchSize = 100;
      const createNoteInputs: CreateNoteInput[] = updatedActivityIds.map((entity_id) => {
        const expense_note_types: ExpenseNoteType[] = [];
        if (this.sourceChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_EXPENSE_SOURCE_OVERRIDE);
        }
        if (this.historicalAdjustmentChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_HISTORICAL_ADJUSTMENT);
        }
        if (this.totalChangedRows.has(entity_id) || this.unitChangedRows.has(entity_id)) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_MANUAL_ADJUSTMENT);
        }
        if (
          Array.from(this.vendorEstimateChangedRows).some(
            (item) => item.activity_id === entity_id
          ) ||
          Array.from(this.vendorEstimatePercentChangedRows).some(
            (item) => item.activity_id === entity_id
          ) ||
          Array.from(this.vendorEstimateAmountLtdChangedRows).some(
            (item) => item.activity_id === entity_id
          )
        ) {
          expense_note_types.push(ExpenseNoteType.EXPENSE_NOTE_VENDOR_ESTIMATE);
        }

        return {
          entity_id,
          entity_type: EntityType.ACTIVITY,
          note_type: NoteType.NOTE_TYPE_EXPENSE,
          message: Utils.scrubUserInput(resp.data?.note || ''),
          expense_note_types,
          metadata: JSON.stringify({
            month: dayjs(this.selected_month.value).format('MMM-YYYY').toUpperCase(),
          }),
        };
      });

      let {
        cData,
        cSuccess,
        cErrors,
      }: {
        cSuccess: boolean;
        cData: batchCreateNotesMutation[] | null;
        cErrors: string[];
      } = {
        cData: [],
        cSuccess: true,
        cErrors: [],
      };
      let j = 0;

      do {
        const result = await firstValueFrom(
          this.gqlService.batchCreateNotes$(createNoteInputs.slice(j, j + noteMaxBatchSize))
        );
        cSuccess ||= result.success;
        if (result.success && result.data) {
          cData = cData.concat(result.data);
        } else {
          cErrors = cErrors.concat(result.errors);
        }

        j += noteMaxBatchSize;
      } while (j < createNoteInputs.length);

      if (cSuccess && cData) {
        this.overlayService.success();
        this.saveCheck$.next(false);
        this.periodCloseComponent.refresh$.next(null);
        await this.onCancel();
      } else {
        this.overlayService.error(cErrors);
      }
    } else {
      this.overlayService.error(errors);
    }

    this.loading$.next(false);
  }

  onEditMode() {
    this.editMode$.next(true);
    // trigger new cell styles
    const gridApi = this.gridApi$.getValue();
    if (!gridApi) {
      return;
    }
    gridApi.redrawRows();
    gridApi.refreshHeader();
    AgEditFirstRow({
      gridApi,
      filterNodes: (nodes) => {
        // ignore discount rows
        let filteredNodes = nodes.filter(
          (n) => !(n.key === 'Discount' || n.parent?.key === 'Discount')
        );

        // ignore investigator rows unless it's specifically selected, or it's the only category
        const categorySet = new Set<string>();
        filteredNodes.forEach((n) => n.key && categorySet.add(n.key));
        if (categorySet.size > 1) {
          filteredNodes = filteredNodes.filter(
            (n) => !(n.key === 'Investigator' || n.parent?.key === 'Investigator')
          );
        }
        return filteredNodes;
      },
      passSpecifiedColumns: (columns) => {
        // pass columns when there is no adjustment permission but have edit VE permission
        const passedColumns = columns.filter((col) => col.getColId() === 'tma_unit');
        const hasNotEditVEPermButAdjutPerm =
          !this.hasEditVendorEstimatePermission$.getValue() &&
          this.userHasAdjustPermission$.getValue();
        return hasNotEditVEPermButAdjutPerm ||
          this.isVendorEstimatesLocked$.getValue() ||
          !this.vendorEstimateColumnChecker()
          ? passedColumns
          : ([] as Column[]);
      },
    });
  }

  tabToNextCell(params: SuppressKeyboardEventParams) {
    params.api.clearFocusedCell();

    setTimeout(() => {
      const editableColumns = params.api
        .getAllDisplayedColumns()
        .filter((col) => !!col.getColDef().editable);

      const currentColumnId = params.column.getColId();
      const index = editableColumns.findIndex((col) => col.getColId() === currentColumnId);
      const isInRowRange = index < editableColumns.length - 1 && index !== -1;

      const findNearestRowToTab = (rowIndex: number): number => {
        const nextIndex = rowIndex + 1;

        const isRootRowLvl = !!params.api.getDisplayedRowAtIndex(nextIndex)?.aggData;

        return isRootRowLvl ? findNearestRowToTab(nextIndex) : nextIndex;
      };

      const nextColumn = isInRowRange ? editableColumns[index + 1] : editableColumns[0];
      const rowIndex = isInRowRange
        ? params.node.rowIndex
        : findNearestRowToTab(params.node.rowIndex ?? 0);

      const nextRow = rowIndex ?? 0;
      const nextCol = nextColumn.getColId() ?? '';

      params.api.clearRangeSelection();
      params.api.startEditingCell({
        colKey: nextCol,
        rowIndex: nextRow,
      });
    }, 100);

    return false;
  }

  async onCellValueChanged(e: CellValueChangedEvent) {
    if (Number.isFinite(+e.newValue) || e.column.getColId() === 'tma_source') {
      this.updateDynamicFields(
        e.newValue,
        e.data.activity_id,
        e.colDef.field,
        e.data.tma_unit_cost,
        e.data.historical_adjustment_amount,
        e.data.vendor_estimate_amount,
        e.oldValue,
        e.data.tma_unit,
        e.data.tma_amount,
        e.data.total_amount, // Current (LRE)
        e.data.tma_source,
        e.data
      );

      if (e.source !== 'rangeService') {
        this.forceCalculateCategoryLvlRows();
      } else {
        clearTimeout(this.fillGridTimeout);

        this.fillGridTimeout = setTimeout(() => {
          this.forceCalculateCategoryLvlRows();
        }, 100);
      }
    }
  }

  // Trigger value formatters / getters to recalculate aggregated rows
  forceCalculateCategoryLvlRows() {
    setTimeout(() => {
      this.gridApi$.getValue()?.redrawRows();
    }, 0);
  }

  costCategoryHasForecasts(activity_type: ActivityType, rows: QuarterCloseAdjustmentGridData[]) {
    return rows
      .filter((row) => row.activity_type === activity_type)
      .map((row) => row.is_forecasted)
      .reduce((acc, currentRow) => acc || currentRow, false);
  }

  costCategoryHasEvidenceBased(
    activity_type: ActivityType,
    rows: QuarterCloseAdjustmentGridData[]
  ) {
    return rows
      .filter((row) => row.activity_type === activity_type)
      .map((row) => row.evidence_based_exist)
      .reduce((acc, currentRow) => acc || currentRow, false);
  }

  shouldVendorEstimateOverride({
    activity_id,
    current_tma_source,
    row,
    isAffected = false,
  }: {
    activity_id: string;
    current_tma_source: Expense_Source;
    row: QuarterCloseAdjustmentGridData;
    isAffected?: boolean;
  }) {
    const setting = this.expenseSettings.find((s) => s.activity_id === activity_id);
    if (!setting) {
      return false;
    }

    const currentSettingIndex = setting.sources.findIndex(
      (x) => x === expenseSourceReverseMapping[current_tma_source]
    );
    const vendorEstimateIndex = setting.sources.findIndex(
      (x) => x === ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE
    );

    const isVendorEstimateHasHigherPriority = vendorEstimateIndex <= currentSettingIndex;

    let shouldUseVendorEstimate = isVendorEstimateHasHigherPriority;
    if (!isVendorEstimateHasHigherPriority && !isAffected) {
      for (const s of setting.sources.slice(0, vendorEstimateIndex).reverse()) {
        switch (s) {
          case ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED:
            shouldUseVendorEstimate = !this.costCategoryHasEvidenceBased(
              row.activity_type,
              this.gridData$.getValue()
            );
            break;
          case ExpenseSourceType.EXPENSE_SOURCE_FORECAST:
            shouldUseVendorEstimate = !this.costCategoryHasForecasts(
              row.activity_type,
              this.gridData$.getValue()
            );
            break;
        }
      }
    }

    if (current_tma_source === Expense_Source['Vendor Estimate']) {
      return true;
    }

    return current_tma_source === Expense_Source.Manual ||
      !setting.default ||
      this.sourceChangedRows.has(activity_id)
      ? false
      : shouldUseVendorEstimate;
  }

  private getVendorEstimatePercentCompleteLTD(
    vendorEstimateAmount: number,
    currentLRE: number,
    actualsToDate: number,
    prevMonthsAccruals: number
  ): number {
    if (!currentLRE) {
      return 0;
    }

    return decimalMultiply(
      decimalDivide(
        decimalAdd(
          decimalAdd(actualsToDate || 0, vendorEstimateAmount || 0),
          prevMonthsAccruals || 0
        ),
        currentLRE
      ),
      100,
      6
    );
  }

  private getVendorEstimateAmountCompleteLTD(
    vendorEstimateAmount: number,
    actualsToDate: number,
    prevMonthsAccruals: number
  ): number {
    return decimalAdd(
      decimalAdd(actualsToDate || 0, vendorEstimateAmount || 0),
      prevMonthsAccruals || 0
    );
  }

  private getVendorEstimateUnit(vendorEstimateAmount: number, tmaUnitCost: number) {
    if (!tmaUnitCost) {
      return 0;
    }

    return decimalDivide(vendorEstimateAmount || 0, tmaUnitCost);
  }

  private getVendorEstimatePercent(vendorEstimateAmount: number, currentLRE: number) {
    if (!currentLRE) {
      return 0;
    }

    return decimalMultiply(decimalDivide(vendorEstimateAmount || 0, currentLRE), 100);
  }

  updateDynamicFields(
    rawNewValue: number,
    activityId: string,
    colDefField: string | undefined,
    pTmaUnitCost: number,
    pHistoricalAdjustmentAmount: number,
    vendor_estimate_amount: number,
    oldValue: number,
    tmaUnit: number,
    tmaAmount: number,
    totalAmount: number,
    tma_source: Expense_Source,
    rowData: QuarterCloseAdjustmentGridData
  ) {
    if (!colDefField) {
      return;
    }

    const precision = colDefField === 'vendor_estimate_percent_ltd' ? 4 : 3;

    const newValue = Utils.roundToNumber(rawNewValue || 0, precision);

    const historicalAdjustmentAmount =
      rowData.historical_adjustment?.adjustment_type ===
        AdjustmentType.ADJUSTMENT_AMOUNT_SYSTEM_GENERATED &&
      colDefField !== 'historical_adjustment_amount'
        ? 0
        : pHistoricalAdjustmentAmount || 0;
    const tmaUnitCost = isNaN(pTmaUnitCost) ? 0 : pTmaUnitCost;

    this.editedRows.add(activityId);

    let newValues: {
      historical_adjustment_amount: number;
      tma_amount: number;
      tma_unit: number;
      vendor_estimate_amount?: number;
    };
    let vendorEstimateAffectedRows: string[] = [];

    if (colDefField !== 'tma_source') {
      this.manualsToBeDeleted.delete(activityId);
    }

    switch (colDefField) {
      case 'vendor_estimate_percent_ltd':
        {
          this.vendorEstimatePercentChangedRows.add({ activity_id: activityId, time: dayjs() });
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
          });

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row: rowData,
          });

          const vendorEstimateAmount = (newValue / 100) * totalAmount - rowData.actuals_to_date;

          newValues = {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: vendorEstimateAmount,
            tma_amount: override ? vendorEstimateAmount : tmaAmount,
            tma_unit: override ? vendorEstimateAmount / tmaUnitCost : tmaUnit,
          };

          let rows: string[] = [];
          this.vendorEstimateSelectableCategories.add(rowData.activity_type);
          if (rowData.activity_type === ActivityType.ACTIVITY_SERVICE) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
            rows = this.getDiscountActivities();
          }
          if (rowData.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
            rows = this.getServiceActivities();
          }
          vendorEstimateAffectedRows = [
            ...this.getAllActivitiesOfTheSameType(new Set([activityId])),
            ...rows,
          ];
        }
        break;
      case 'vendor_estimate_amount_ltd':
        {
          this.vendorEstimateAmountLtdChangedRows.add({ activity_id: activityId, time: dayjs() });
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
          });

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row: rowData,
          });

          const vendorEstimateAmount = newValue - rowData.actuals_to_date;

          newValues = {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: vendorEstimateAmount,
            tma_amount: override ? vendorEstimateAmount : tmaAmount,
            tma_unit: override ? (tmaUnitCost ? vendorEstimateAmount / tmaUnitCost : 0) : tmaUnit,
          };

          let rows: string[] = [];
          this.vendorEstimateSelectableCategories.add(rowData.activity_type);
          if (rowData.activity_type === ActivityType.ACTIVITY_SERVICE) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
            rows = this.getDiscountActivities();
          }
          if (rowData.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
            rows = this.getServiceActivities();
          }
          vendorEstimateAffectedRows = [
            ...this.getAllActivitiesOfTheSameType(new Set([activityId])),
            ...rows,
          ];
        }
        break;
      case 'vendor_estimate_amount':
        {
          this.vendorEstimateChangedRows.add({ activity_id: activityId, time: dayjs() });
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
          });

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row: rowData,
          });

          newValues = {
            historical_adjustment_amount: historicalAdjustmentAmount,
            vendor_estimate_amount: newValue,
            tma_amount: override ? newValue : tmaAmount,
            tma_unit: override ? (tmaUnitCost ? newValue / tmaUnitCost : 0) : tmaUnit,
          };

          let rows: string[] = [];
          this.vendorEstimateSelectableCategories.add(rowData.activity_type);
          if (rowData.activity_type === ActivityType.ACTIVITY_SERVICE) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_DISCOUNT);
            rows = this.getDiscountActivities();
          }
          if (rowData.activity_type === ActivityType.ACTIVITY_DISCOUNT) {
            this.vendorEstimateSelectableCategories.add(ActivityType.ACTIVITY_SERVICE);
            rows = this.getServiceActivities();
          }
          vendorEstimateAffectedRows = [
            ...this.getAllActivitiesOfTheSameType(new Set([activityId])),
            ...rows,
          ];
        }
        break;
      case 'tma_unit':
        {
          const tma_amount = newValue * tmaUnitCost;
          const historical_adjustment_amount = historicalAdjustmentAmount;

          this.unitChangedRows.add(activityId);

          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount,
            tma_amount: tmaUnitCost * oldValue,
            tma_unit_cost: tmaUnitCost,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount,
            vendor_estimate_amount,
            tma_amount,
            tma_unit: newValue,
          };
        }
        break;
      case 'tma_amount':
        {
          const historical_adjustment_amount = historicalAdjustmentAmount;
          const tma_unit = newValue / tmaUnitCost || 0;

          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount,
            tma_amount: oldValue,
            tma_unit_cost: oldValue / tmaUnit,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount,
            vendor_estimate_amount,
            tma_amount: newValue,
            tma_unit,
          };

          this.totalChangedRows.add(activityId);
        }
        break;
      case 'historical_adjustment_amount':
        {
          rowData.notes = [];
          this.historicalAdjustmentChangedRows.add(activityId);
          this.editedOldGridRow.set(activityId, {
            historical_adjustment_amount: oldValue,
            tma_amount: tmaAmount,
            tma_unit_cost: tmaUnitCost,
            vendor_estimate_amount,
          });
          newValues = {
            historical_adjustment_amount: +historicalAdjustmentAmount,
            vendor_estimate_amount,
            tma_amount: tmaAmount,
            tma_unit: tmaUnit,
          };
        }
        break;
      case 'tma_source':
        {
          this.unitChangedRows.delete(activityId);
          this.totalChangedRows.delete(activityId);

          this.sourceChangedRows.add(activityId);
          if ((oldValue as unknown as string) === Expense_Source.Manual) {
            this.manualsToBeDeleted.add(activityId);
          }
          switch (rawNewValue as unknown as Expense_Source) {
            case Expense_Source.Forecast:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.current_forecast_amount,
                tma_unit: rowData.current_forecast_unit,
              };
              break;
            case Expense_Source.Invoice:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.invoices_amount,
                tma_unit: rowData.invoices_amount
                  ? rowData.invoices_amount / rowData.tma_unit_cost
                  : 0,
              };
              break;
            case Expense_Source['Evidence Based']: {
              const historical_adjustment =
                (rowData.evidence_based_to_date?.amount || 0) -
                (rowData.work_performed_to_date?.amount || 0) -
                (rowData.evidence_based?.amount || 0);

              if (historical_adjustment !== 0) {
                rowData.notes = [
                  {
                    __typename: 'Note',
                    id: '',
                    entity_id: rowData.activity_id,
                    expense_note_types: [ExpenseNoteType.EXPENSE_NOTE_HISTORICAL_ADJUSTMENT],
                    entity_type: EntityType.ACTIVITY,
                    note_type: NoteType.NOTE_TYPE_EXPENSE,
                    message: 'Auxilius Generated Historical Adjustment for Lagging EDC Data',
                    created_by: EMPTY_UUID,
                    create_date: dayjs().toISOString(),
                  },
                ];
              }

              newValues = {
                historical_adjustment_amount: historical_adjustment,
                tma_amount: rowData.evidence_based_amount,
                tma_unit: rowData.evidence_based_unit,
              };
              break;
            }
            case Expense_Source['Vendor Estimate']:
              newValues = {
                historical_adjustment_amount: 0,
                tma_amount: rowData.vendor_estimate_amount,
                tma_unit: rowData.vendor_estimate_unit,
              };
              break;
            case Expense_Source.Manual:
              newValues = {
                historical_adjustment_amount: rowData.historical_adjustment_amount,
                tma_amount: rowData.tma_amount,
                tma_unit: rowData.tma_unit,
              };
              break;
          }
        }
        break;
      default:
        break;
    }

    const relatedRows = this.gridData$
      .getValue()
      .filter((r) => r.activity_type === rowData.activity_type);
    const doesSelectedMonthHasVendorEstimate =
      relatedRows.some((row) => row.vendor_estimate !== null) ||
      relatedRows.some((row) =>
        Array.from(this.vendorEstimateChangedRows).some(
          (item) => item.activity_id === row.activity_id
        )
      ) ||
      colDefField === 'vendor_estimate_amount';

    const isVendorEstimatePercentLTDCol = colDefField === 'vendor_estimate_percent_ltd';

    const isVendorEstimateAmountLTDCol = colDefField === 'vendor_estimate_amount_ltd';

    this.gridData$.next(
      this.gridData$.getValue().map((row) => {
        if (row.activity_id === activityId) {
          const total_monthly_expense =
            newValues.tma_amount + +newValues.historical_adjustment_amount;

          const setting = this.expenseSettings.find((x) => x.activity_id === row.activity_id);
          if (!setting) {
            return row;
          }
          let amount = 0;
          if (!this.sourceChangedRows.has(row.activity_id)) {
            let amountSet = false;
            setting.sources.forEach((s) => {
              if (amountSet) {
                return;
              }
              switch (s) {
                case ExpenseSourceType.EXPENSE_SOURCE_EVIDENCE_BASED:
                  if (row.evidence_based_exist) {
                    amount = row.evidence_based_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_INVOICE:
                  if (row.invoices_amount_exist) {
                    amount = row.invoices_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_FORECAST:
                  if (this.costCategoryHasForecasts(row.activity_type, this.gridData$.getValue())) {
                    amount = row.current_forecast_amount;
                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_VENDOR_ESTIMATE:
                  if (
                    doesSelectedMonthHasVendorEstimate ||
                    isVendorEstimatePercentLTDCol ||
                    isVendorEstimateAmountLTDCol
                  ) {
                    amount =
                      isVendorEstimatePercentLTDCol || isVendorEstimateAmountLTDCol
                        ? newValues.vendor_estimate_amount || 0
                        : row.vendor_estimate_amount;

                    amountSet = true;
                  }
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_MANUAL_ADJUSTMENT:
                  break;
                case ExpenseSourceType.EXPENSE_SOURCE_NONE:
                  break;
              }
            });
          } else {
            switch (tma_source) {
              case Expense_Source.Forecast:
                amount = row.current_forecast_amount;
                break;
              case Expense_Source.Invoice:
                amount = row.invoices_amount;
                break;
              case Expense_Source['Evidence Based']:
                amount = row.evidence_based_amount;
                break;
              case Expense_Source['Vendor Estimate']:
                amount = row.vendor_estimate_amount;
                break;
              case Expense_Source.Manual:
                amount = row.manual_adjustment?.amount || 0;
                break;
              case Expense_Source.None:
                break;
            }
          }

          const previous_tma_amount = amount;

          const override = this.shouldVendorEstimateOverride({
            current_tma_source: tma_source,
            activity_id: activityId,
            row,
          });

          const newTmaSource = ['tma_amount', 'tma_unit'].includes(colDefField)
            ? Expense_Source.Manual
            : colDefField === 'tma_source'
              ? (rawNewValue as unknown as Expense_Source)
              : (colDefField === 'vendor_estimate_amount' ||
                    colDefField === 'vendor_estimate_percent_ltd' ||
                    colDefField === 'vendor_estimate_amount_ltd') &&
                  override
                ? Expense_Source['Vendor Estimate']
                : tma_source;

          if (row.tma_source !== newTmaSource) {
            this.historicalAdjustmentToBeDeleted.add(row.activity_id);
          }

          const isVendorEstimateCell = [
            'vendor_estimate_percent_ltd',
            'vendor_estimate_amount_ltd',
            'vendor_estimate_amount',
          ].includes(colDefField);

          const vendor_estimate_unit =
            isVendorEstimateCell &&
            row.activity_type !== ActivityType.ACTIVITY_DISCOUNT &&
            row.tma_source !== Expense_Source.Manual
              ? newValues.tma_unit
              : this.getVendorEstimateUnit(
                  newValues.vendor_estimate_amount ?? 0,
                  row.tma_unit_cost
                );

          const vendor_estimate_percentage = isVendorEstimateCell
            ? this.getVendorEstimatePercent(newValues.vendor_estimate_amount ?? 0, totalAmount)
            : row.vendor_estimate_percentage;

          const vendor_estimate_percent_ltd =
            colDefField === 'vendor_estimate_percent_ltd'
              ? newValue
              : this.getVendorEstimatePercentCompleteLTD(
                  newValues.vendor_estimate_amount || vendor_estimate_amount,
                  totalAmount,
                  rowData.actuals_to_date,
                  rowData.prev_months_accruals?.amount || 0
                );

          const vendor_estimate_amount_ltd =
            colDefField === 'vendor_estimate_amount_ltd'
              ? newValue
              : this.getVendorEstimateAmountCompleteLTD(
                  newValues.vendor_estimate_amount || vendor_estimate_amount,
                  rowData.actuals_to_date,
                  rowData.prev_months_accruals?.amount || 0
                );

          if (tma_source !== newTmaSource && colDefField !== 'tma_source') {
            this.sourceChangedRows.delete(row.activity_id);
          }

          return {
            ...row,
            ...newValues,
            tma_unit: row.activity_type === ActivityType.ACTIVITY_DISCOUNT ? 0 : newValues.tma_unit,
            tma_percentage: totalAmount ? (total_monthly_expense / totalAmount) * 100 : 0,
            vendor_estimate_unit,
            vendor_estimate_percentage,
            vendor_estimate_percent_ltd,
            vendor_estimate_amount_ltd,
            vendor_estimate_unit_cost: row.direct_cost.unit_cost,
            total_adjustment: total_monthly_expense - (previous_tma_amount || 0),
            variance_to_forecast: newValues.tma_amount - row.current_forecast_amount,
            total_monthly_expense,
            tma_source: newTmaSource,
            expense_ltd: total_monthly_expense + row.actuals_to_date,
          };
        } else if (vendorEstimateAffectedRows.includes(row.activity_id)) {
          const override = this.shouldVendorEstimateOverride({
            activity_id: row.activity_id,
            current_tma_source: row.tma_source,
            row,
            isAffected: true,
          });

          if (override && row.tma_source !== Expense_Source['Vendor Estimate']) {
            this.vendorEstimateEffectedRows.add(row.activity_id);
            const isAutoGenerated =
              row.historical_adjustment?.adjustment_type ===
              AdjustmentType.ADJUSTMENT_AMOUNT_SYSTEM_GENERATED;
            return {
              ...row,
              tma_amount: 0,
              tma_percentage: 0,
              tma_unit: 0,
              total_monthly_expense: 0,
              historical_adjustment_amount: isAutoGenerated ? 0 : row.historical_adjustment_amount,
              total_adjustment: isAutoGenerated ? 0 : row.total_adjustment,
              expense_ltd: row.actuals_to_date,
              variance_to_forecast: -row.current_forecast_amount,
              tma_source: Expense_Source['Vendor Estimate'],
            };
          }
        }
        return row;
      })
    );

    this.saveCheck$.next(true);

    // recalculate aggregated rows with new values
    this.gridApi$.getValue()?.refreshClientSideRowModel('aggregate');
  }

  onFilterChanged() {
    const filteredRows: QuarterCloseAdjustmentGridData[] = [];
    this.gridApi$.value?.forEachNodeAfterFilter((node) => {
      if (node.data) {
        filteredRows.push(node.data);
      }
    });
    this.generatePinnedBottomData(null, filteredRows);
  }

  getDiscountActivities() {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(
      rows,
      (row) => row.activity_type === ActivityType.ACTIVITY_DISCOUNT
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  getServiceActivities() {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(
      rows,
      (row) => row.activity_type === ActivityType.ACTIVITY_SERVICE
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  getActivitiesTypes(activityIds: Set<string>) {
    const rows = this.inMonthExpenses$.getValue() || [];

    const filteredRows = filter(rows, (row) => includes(Array.from(activityIds), row.activity_id));

    return uniq(_map(filteredRows, 'activity_type'));
  }

  getAllActivitiesOfTheSameType(activityIds: Set<string>): string[] {
    const rows = this.inMonthExpenses$.getValue() || [];

    // Get the inMonthExpense rows that correspond to the activity ids passed in
    const filteredRows = filter(rows, (row) => includes(Array.from(activityIds), row.activity_id));

    // Get the uniq list of activity types
    const uniqueActivityTypes = uniq(_map(filteredRows, 'activity_type'));

    // Get all activity ids for the types that were found
    const filteredRowsByActivityType = filter(rows, (row) =>
      includes(uniqueActivityTypes, row.activity_type)
    );

    // Get a unique list of activity ids
    return uniq(_map(filteredRowsByActivityType, 'activity_id'));
  }

  costCategoryHasForecastSelected(params: ICellEditorParams) {
    const activity_type = params.data.activity_type as string;

    // Get all same activity type as the same activity type as row
    const allActivitiesSameType = this.gridData$
      .getValue()
      .filter((row) => row.activity_type === activity_type);

    // If there is an activity that already forecast selected before return true else false
    const hasForecastSelected = allActivitiesSameType.some((activity) => !!activity.is_forecasted);

    // Get a unique list of activity ids
    return hasForecastSelected;
  }

  /*
    Since vendor estimate uploads are all or nothing, we need to update every activity under the cost category for the ids provided.
    For example, if just one Services activity is updated, this will call updateAccruals for every Services activity
  */
  async updateVendorEstimateAccruals(activityIdsToSendForUpdate: Set<string>) {
    const doesSelectedMonthHasVendorEstimate = this.doesSelectedMonthHasVendorEstimate();

    let allAffectedActivityIds = this.getAllActivitiesOfTheSameType(activityIdsToSendForUpdate);

    const types = this.getActivitiesTypes(activityIdsToSendForUpdate);

    const isThereServiceInTheTypes = !!types.find(
      (t) => t === ActivityType.ACTIVITY_SERVICE || t === ActivityType.ACTIVITY_DISCOUNT
    );

    const isThereAnyOtherTypes = !!types.find(
      (t) => t !== ActivityType.ACTIVITY_SERVICE && t !== ActivityType.ACTIVITY_DISCOUNT
    );

    const maxBatchSize = 200;
    let { data, success, errors }: GraphqlResponse<updateAccrualsMutation[]> = {
      data: [],
      success: true,
      errors: [],
    };
    let i = 0;

    let shouldSendARequestWithIds = true;
    if (!doesSelectedMonthHasVendorEstimate && isThereServiceInTheTypes) {
      const result = await firstValueFrom(
        this.gqlService.updateAccruals$({
          activity_ids: [],
          activity_types: [ActivityType.ACTIVITY_SERVICE, ActivityType.ACTIVITY_DISCOUNT],
          organization_id: this.selected_vendor.value || '',
          period: this.selected_month.value || '',
        })
      );
      success ||= result.success;
      if (result.success && result.data) {
        data = data.concat(result.data);
      } else {
        errors = errors.concat(result.errors);
      }

      if (isThereAnyOtherTypes) {
        const ids = [...this.getServiceActivities(), ...this.getDiscountActivities()];
        allAffectedActivityIds = allAffectedActivityIds.filter((id) => !ids.includes(id));
      }

      shouldSendARequestWithIds = isThereAnyOtherTypes;
    }

    if (shouldSendARequestWithIds) {
      do {
        const result = await firstValueFrom(
          this.gqlService.updateAccruals$({
            activity_ids: allAffectedActivityIds.slice(i, i + maxBatchSize),
            activity_types: [],
            organization_id: this.selected_vendor.value || '',
            period: this.selected_month.value || '',
          })
        );
        success ||= result.success;
        if (result.success && result.data) {
          data = data.concat(result.data);
        } else {
          errors = errors.concat(result.errors);
        }

        i += maxBatchSize;
      } while (i < allAffectedActivityIds.length);
    }

    if (success) {
      return data;
    } else {
      this.overlayService.error(errors);
      return [];
    }
  }

  async onExport() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const vendor = this.budgetQuery
      .getValue()
      .budget_info.filter((v) => v.vendor_id === this.selected_vendor.value)[0];
    const category = this.categories.filter((c) => c.value === this.selected_category.value)[0];
    const month = dayjs(this.selected_month.value).format('MMMYY');

    const { success, errors } = await firstValueFrom(
      this.eventService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.ORGANIZATION,
        entity_id: vendor.vendor_id || '',
        payload: JSON.stringify({
          export_type: ExportType.IN_MONTH_ADJUSTMENTS,
          filename: `${trialName}_${month}_${vendor.name}_${category.label}_Adjustments.xlsx`,
          export_entity_id: vendor.vendor_id || '',
          json_properties: {
            month: dayjs(this.selected_month.value).format('MMM-YYYY'),
            activity_type: category.value || null,
            materiality_threshold: this.selected_threshold.value,
            activity_ids: agGridAllVisibleData<QuarterCloseAdjustmentGridData>(
              this.gridApi$.value!
            ).map((item) => item.activity_id),
          },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  async selectMonth(event: MouseEvent, value: string) {
    selectWithGuardChangesModalCheck(
      event,
      value,
      this.saveCheck$.value,
      this.overlayService,
      this.selected_month,
      this.monthSelect
    );
  }

  beforeFilterChanges(saveCheck: boolean) {
    return async () => {
      if (saveCheck) {
        const result = this.overlayService.openUnsavedChangesConfirmation();
        const event = await firstValueFrom(result.afterClosed$);
        return !!event.data;
      }

      return true;
    };
  }

  onCategoryDropdownChange(): void {
    this.selected_category.setValue(this.defaultCategories[0].value);
  }

  redirectFromInvoiceColumn = () => {
    const selectedMonth = this.selected_month.value || '';
    const selectedVendor = this.selected_vendor.value || '';
    if (selectedMonth && selectedVendor) {
      const servicePeriodFilter = [`${dayjs(selectedMonth).format('YYYY-MM')}-01`];
      this.invoiceService.setAccrualPeriodsAndVendorFilter([], selectedVendor);
      this.invoiceService.setSelectedServicePeriodOptions(servicePeriodFilter);
      this.router.navigateByUrl(
        `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`
      );
    }
  };

  doesInvoiceMappingsMatchTotal = (params: ICellRendererParams): boolean => {
    if (params.value && !!params.node.aggData) {
      return params.value === params.node.aggData.invoices_amount;
    }

    if (params.data && params.data.invoices_available) {
      return params.data.invoices_available === params.data.invoices_amount;
    }

    return false;
  };
}
