<ng-container *ngIf="vendorsQuery.selectLoading() | async">
  <ng-template #loader>
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-template>
  <ng-container [ngTemplateOutlet]="loader" />
</ng-container>

<ng-container *ngIf="!$any(vendorsQuery.selectLoading() | async)">
  <div class="flex items-center justify-between w-full">
    <div class="text-2xl font-bold">Vendors</div>
    <div class="flex items-center space-x-4">
      <aux-button
        [disabled]="ctx.disabledUI()"
        [auxTooltip]="ctx.vendorActionTooltip()"
        variant="secondary"
        label="Add New Vendor"
        [onClick]="onNewVendor"
        icon="Plus"
      />
      <aux-button
        [disabled]="ctx.disabledUI()"
        [auxTooltip]="ctx.vendorActionTooltip()"
        variant="secondary"
        label="Add Multiple Vendors"
        [onClick]="onAddMultipleVendors"
        icon="Plus"
      />
    </div>
  </div>

  @if (!$any(vendorsQuery.selectLoading() | async)) {
    <div class="flex flex-wrap pb-4 mt-4 w-full">
      <div class="mb-8 w-full">
        <ag-grid-angular
          class="ag-theme-aux vendor-table tabular-nums"
          domLayout="autoHeight"
          id="VendorsGrid"
          [rowData]="gridData$ | async"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          (gridSizeChanged)="sizeColumnsToFit()"
          (firstDataRendered)="onFirstDataRendered()"
          auxStickyGrid
        />
        <aux-pagination-panel
          [gridApi]="gridAPI!"
          [paginationPageSize]="gridOptions.paginationPageSize"
        />
      </div>
    </div>
  }
</ng-container>
